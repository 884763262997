import {oneOf} from 'shared/store';
import {observable, action, computed} from 'mobx';
import Answer from './Answer';
import _ from 'lodash';
import {QAQuestion} from 'stores/questions';

class Question extends QAQuestion {
  @observable id;
  @observable name;
  @observable answer;

  @action merge(other) {
    super.merge(other, {
      questionType: oneOf([
        'text_field',
        'text_area',
        'attachment',
        'multiple_choice',
        'multi_select'
      ]),
      answer: Answer
    });
  }

  @action updateText(value) {
    this.merge({name: value});
  }

  @computed get translatableQuestionType() {
    return `models.job_application_question.question_type.${this.questionType}`;
  }

  @computed get options() {
    return _.get(this.args, 'options');
  }

  @computed get questionName() {
    return this.name;
  }
}

export default Question;
