import {observable, computed, action} from 'mobx';
import {DomainObject} from 'shared/store';
import Collaborator from './Collaborator';
import Question from 'stores/recruiting/Question';
import _ from 'lodash';
import {FunnelStage} from 'stores/recruiting/FunnelStage';
import striptags from 'striptags';
import Location from 'stores/locations/Location';
import Department from 'stores/departments/Department';
import {calendarDate} from 'shared/tools';
import PositionRule from 'stores/recruiting/PositionRule';

class Position extends DomainObject {
  @observable title = '';
  @observable currentStep = 1;
  @observable employmentType = '';
  @observable status = '';
  @observable location =  new Location();
  @observable locationString = '';
  @observable department = new Department();
  @observable candidateCount;
  @observable createdAt;
  @observable contentSection = null;
  @observable hiringCollaborators = [];
  @observable funnelStages = [];
  @observable deleted;
  @observable sendEmailToApplicants = true;
  @observable sendDisqualifiedEmailToApplicants = false;
  @observable allowReferrals = false;
  @observable questions = [];
  @observable rules = [];
  @observable locale;
  @observable requireResume;

  @computed get stages() {
    return _.chain(this.funnelStages)
      .reject(s => ['hired', 'offer'].includes(s.stageType))
      .reject('_destroy')
      .orderBy('order')
      .value();
  }

  @computed get finalStages() {
    return _.filter(this.funnelStages, s => ['hired', 'offer'].includes(s.stageType));
  }

  @computed get isDraft() {
    return this.status === 'draft';
  }

  @computed get isPublished() {
    return this.status === 'published';
  }

  @computed get isInternal() {
    return this.status === 'internal';
  }

  @computed get isClosed() {
    return this.status === 'closed';
  }

  @computed get priority() {
    if (this.isClosed) return 0;
    if (this.isInternal) return 1;
    return 2;
  }

  @computed get description() {
    return _.get(this.contentSection, 'richText', '');
  }

  @computed get departmentView() {
    return _.get(this.department, 'name', '');
  }

  @computed get selectedCollaborators() {
    return _.reject(this.hiringCollaborators, '_destroy');
  }

  @computed get selectedCollaboratorUsers() {
    return _.map(this.selectedCollaborators, 'user');
  }

  @computed get truncatedDescription() {
    const plainText = striptags(this.contentSection.richText);
    return _.chain(plainText)
      .trim('&nbsp;')
      .truncate({length: 60})
      .value();
  }

  @computed get customFunnelStages() {
    return _.orderBy(_.filter(this.funnelStages, {stageType: 'custom'}), 'order');
  }

  @computed get showViewPosting() {
    return this.hasLink('posting');
  }

  @computed get viewPostingLink() {
    return this.link('posting');
  }

  @computed get sortedQuestions() {
    return _.sortBy(
      this.questions,
      'order'
    );
  }

  @computed get createdDate() {
    return calendarDate(this.createdAt);
  }

  @computed get directoryUrl() {
    return this.isDraft ? `/positions/${this.id}` : `/positions/${this.id}/funnel/`;
  }

  @action addCollaborator(collaborator) {
    const collaboratorToRemove = _.find(this.hiringCollaborators, {clientId: collaborator.clientId});
    if (collaboratorToRemove) {
      this.hiringCollaborators.remove(collaboratorToRemove);
    }
    this.hiringCollaborators.push(new Collaborator(collaborator));
  }

  @action removeCollaborator(collaborator) {
    const collaboratorToRemove = _.find(this.hiringCollaborators, {clientId: collaborator.clientId});
    if (collaboratorToRemove.id) {
      collaboratorToRemove._destroy = true;
    } else {
      this.hiringCollaborators.remove(collaboratorToRemove);
    }
  }

  @action merge(other) {
    super.merge(other, {
      hiringCollaborators: [Collaborator],
      funnelStages: [FunnelStage],
      questions: [Question],
      rules: [PositionRule]
    });
  }
}

export default Position;
