/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { observable, action, computed } from 'mobx';
import { observer, PropTypes as MobxP } from 'mobx-react';
import P from 'prop-types';

import {
	NODE_WIDTH,
	ARC_SIZE
} from '../../constants';

@observer class Lines extends Component {
	@computed get translateX(){
		return - this.props.firstNodeLeft + this.props.parentLeft + NODE_WIDTH / 2
	}
	@computed get lines(){
		const lines = [];
		const { nodes, parentLeft, firstNodeLeft, lastNodeLeft } = this.props

		if (parentLeft > firstNodeLeft){
			lines.push({
				id: 0,
				path: `M0 0 a ${ARC_SIZE} ${ARC_SIZE} 0 0 1 ${-ARC_SIZE} ${ARC_SIZE} l -10 0`
			})
		}
		if (parentLeft < lastNodeLeft){
			lines.push({
				id: 1,
				path: `M0 0 a ${ARC_SIZE} ${ARC_SIZE} 0 0 0 ${ARC_SIZE} ${ARC_SIZE} l 10 0`
			})
		}
		if (nodes.find(n => n.left === parentLeft)){
			lines.push({
				id: 2,
				path: `M0 0 l 0 ${ARC_SIZE}`
			})
		}

		return lines
	}

	render(){
		return (
			<Fragment>
				{this.lines.map(l => {
					return <path
						key={l.id}
						d={l.path}
						// need both transform (for edge) and style transform
						// for animations to work for safari + firefox
						transform={`translate(${this.translateX} 0)`}
						style={{
							transform: `translate(${this.translateX}px, 0)`
						}}
						strokeWidth="2"
						stroke="red"
						className="row__arc"
					/>
				})}
			</Fragment>
		)
	}
}

Lines.propTypes = {
	firstNodeLeft: P.number,
	lastNodeLeft: P.number,
	parentLeft: P.number,
	nodes: MobxP.arrayOrObservableArray
}

export default Lines;
