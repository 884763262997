import React from 'react';
import {Button} from 'components';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';

const ActivateEmailModal = observer(({uiState}) => {
  return (
    <Modal isOpen={uiState.activateEmailModalOpen} onHide={() => uiState.closePreviewInvite()} size='md'>
      <div className='clearfix center'>
        <div className='col-10 mx-auto px2'>
          <div className='h2 pt1 medium'>
             <FormattedMessage id='company_onboard.Almost there! Before you can launch we need to confirm your email address.' />
          </div>
          <div className='mt3 mb4'>
            <div className='h5 medium'>
               <FormattedMessage id="company_onboard.We've sent an email to:" />
            </div>
            <div className='h2 my2'>
              {uiState.currentEmployeeEmail}
            </div>
            <div className='h5 jumbo'>
              <FormattedMessage id='company_onboard.Is this the wrong email address?' />
              <a href='/user_settings' className='dodger underline pl1'>
                <FormattedMessage id='company_onboard.Edit email address'/>
              </a>
            </div>
          </div>
          <div className='mt3 pb2 right-align'>
            <Button className='full-width' onClick={() => uiState.resendActivationEmail()}>
              <FormattedMessage id='company_onboard.Resend confirmation email'/>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default ActivateEmailModal;
