import React from 'react';
import {Modal, ModalButtons} from 'components/modals';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const SubmitReviewModal = observer(({isOpen, onSave, onHide, isManagerReview}) => {
  return (
    <Modal isOpen={isOpen} onHide={onHide} size='sm'>
      <div className="mb1 h3 medium">
        <FormattedMessage id='performance_reviews.view.Submit Review'/>
      </div>
      <FormattedMessage id={`performance_reviews.view.${isManagerReview ? 'SUBMIT_MANAGER_REVIEW_WARNING' : 'SUBMIT_REVIEW_WARNING'}`}/>
      <ModalButtons 
        saveCaption='performance_reviews.view.Submit Review'
        onSave={onSave}
        onCancel={onHide}
      />
    </Modal>
  );
});

export default SubmitReviewModal;
