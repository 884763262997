import React from 'react';
import {observer} from 'mobx-react';
import {Route, Switch, Redirect} from 'react-router-dom';
import LearnMore from './LearnMore';
import Confirmation from './Confirmation';
import PaymentInformation from './PaymentInformation';
import {loader, withDisplay} from 'shared/core';
import SubscriptionState from 'company_settings/billing/state/SubscriptionState';
import _ from 'lodash';
import SubNav from 'company_settings/SubNav';

const SubscriptionFlowContainer = observer(({uiState}) => {
  return (
    <div className='Container'>
      <Switch>
        <Route path='/billing/subscription/learn-more' component={LearnMore} />
        <Route path='/billing/subscription/payment' render={() => <PaymentInformation uiState={uiState}/>}/>
        <Route path='/billing/subscription/confirmation' render={() => <Confirmation uiState={uiState}/>}/>
        <Redirect exact from='/billing/subscription' to='/billing/subscription/learn-more'/>
      </Switch>
    </div>
  );
});

async function load({location, history}) {
  const uiState = new SubscriptionState(location, history);
  return {uiState};
}

export default withDisplay(loader(SubscriptionFlowContainer, load), SubNav);
