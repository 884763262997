import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import MyCheckInsContainer from './checkins/employee/MyCheckInsContainer';
import MyCheckInDetailsContainer from './checkins/employee/MyCheckInDetailsContainer';
import MyCheckInResponseContainer from './checkins/employee/MyCheckInResponseContainer';

const CheckInSwitch = ({scope}) => {
  return (
    <Switch>
      <Route exact path='/checkins' render={() => <MyCheckInsContainer scope={scope}/>}/>
      <Route path='/checkins/cycle/:cycleId/reviewer/:reviewerId' component={MyCheckInResponseContainer}/>
      <Route path='/checkins/:checkinId' component={MyCheckInDetailsContainer}/>
      <Redirect from='*' to='/performance/checkins'/>
    </Switch>
  );
};

export default CheckInSwitch;
