import React from 'react';
import {observer} from 'mobx-react';
import {Input, Submit, Panel} from 'components';
import {FormattedMessage} from 'react-intl';

class AddEmployeeForm extends React.Component {
  onSave = () => {
    this.input.focus();
  }

  render() {
    const {uiState} = this.props;
    const {employee, errors} = uiState;

    return (
      <form>
        <Panel>
          <div className='clearfix mxn2'>
            <div className='col col-10'>
              <div className='clearfix'>
                <div className='col col-4 bg-white pl2'>
                  <Input 
                    value={employee.firstName}
                    inputRef={(input) => { this.input = input; }}
                    placeholder='company_onboard.First Name'
                    onChange={(e) => employee.merge({firstName: e.target.value})}
                    className='large js-first-name' 
                  />
                </div>
                <div className='col col-4 bg-white px2'>
                  <Input 
                    value={employee.lastName}
                    placeholder='company_onboard.Last Name'
                    onChange={(e) => employee.merge({lastName: e.target.value})}
                    className='large' 
                  />
                </div>
                <div className='col col-4 bg-white pr2'>
                  <Input 
                    value={employee.invitationEmail}
                    placeholder='company_onboard.Email'
                    onChange={(e) => employee.merge({invitationEmail: e.target.value})}
                    errorMessage={errors.invitationEmail}
                    className='large' 
                  />
                </div>
              </div>
            </div>
            <div className='col col-2 px2'>
              <Submit onClick={() => uiState.saveEmployee(this.onSave)} size='lg' className='full-width' disabled={!uiState.validEmployee}>
                <FormattedMessage id='company_onboard.Add' />
              </Submit>
            </div>
          </div>
        </Panel>
      </form>
    );
  }
}

export default observer(AddEmployeeForm);
