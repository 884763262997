import {DomainObject} from 'shared/store';
import {observable, computed} from 'mobx';
import {fullName} from 'shared/tools';

class ExternalCandidate extends DomainObject {
  @observable id;
  @observable firstName;
  @observable lastName;
  @observable email;
  @observable avatarInitials;
  @observable avatarColour;
  @observable jobTitle;

  @computed get name() {
    return fullName(this);
  }
}

export default ExternalCandidate;
