import {action} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';

class CycleDirectoryState {
  history;
  store = new DomainStore();

  receiveProps({history}) {
    this.history = history;
  }

  @action async addNewCycle() {
    const {model} = await this.store.post(
      endpoints.PERFORMANCE_REVIEWS.CYCLES.DIRECTORY,
      types.PERFORMANCE_REVIEWS.CYCLE
    );

    this.history.push(`/reviews/${model.id}/edit`);
  }

  @action async removeCycle(cycle) {
    await this.store.destroy(cycle);
  }

  cyclePath(cycle) {
    switch (cycle.state) {
      case 'created':
        return `/reviews/${cycle.id}/edit`;
      case 'configured':
        return `/reviews/${cycle.id}/manage`;
      case 'launched':
      case 'ended':
      default:
        return `/reviews/${cycle.id}`;
    }
  }
}

export default CycleDirectoryState;
