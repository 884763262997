import _ from 'lodash';

const formErrors = (response) => {
  const form = {};
  const responseErrors = _.get(response, 'data.errors');

  if (responseErrors) {
    for (let error of responseErrors) {
      const path = error.source.pointer.split('/').slice(3);
      _.set(form, path, error.detail);
    }
  }

  return form;
};

export default formErrors;
