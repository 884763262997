import {DomainObject, oneOf} from 'shared/store';
import {action, observable} from 'mobx';
import Employee from 'stores/employees/Employee';

class BenefitEnrolmentSummary extends DomainObject {
  @observable externalId;
  @observable enrolmentStatus;
  @observable benefitClassName;
  @observable benefitDivisionName;
  @observable healthCoverage;
  @observable dentalCoverage;
  @observable coverage;
  @observable effectiveDate;
  @observable firstEffectiveDate;
  @observable probation;
  @observable probationTimePeriod;
  @observable lastDayOfBenefits;
  @observable terminated;
  @observable canEnrol;
  @observable canUnenrol;
  @observable canEditEnrolment;
  @observable endDate;

  @action merge(other) {
    super.merge(other, {
      employee: Employee,
      probationTimePeriod: oneOf(['days', 'months']),
      _dates: ['effectiveDate', 'firstEffectiveDate', 'lastDayOfBenefits', 'endDate']
    });
  }
}

export default BenefitEnrolmentSummary;
