import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Department',
  'Employee',
  'Employment Status',
  'Employment Type',
  'Location',
  'Manager',
  'Time Off Type'
];

class VacationLiabilityReport extends React.Component {
  render() {
    return (
      <CompositeReport dateFilter='single' filters={filters}>
        <Report name={t('reports.TIME_OFF_LIABILITY_REPORT')}
                url='/reports/vacation_liability'>
        </Report>
      </CompositeReport>
    );
  }
}

export default VacationLiabilityReport;
