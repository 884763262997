import React from 'react';
import {observer} from 'mobx-react';
import {InteractiveContext, Pagination, Avatar, Panel} from 'components';
import QuestionEmptyState from './QuestionEmptyState';
import {endpoints, types} from 'shared/core';
import {SurveyAnswer} from 'stores/surveys';
import {calendarDate} from 'shared/tools';

const Answers = observer(({models}) => {
  if (!models.length) {
    return <QuestionEmptyState/>;
  }

  return (
    <React.Fragment>
      {models.map(({employee, content, submittedAt}, index) =>
        <div className='clearfix flex pb2' key={index}>
          <div className='mr2 pt1'>
            <Avatar employee={employee} size='sm' className='left'/>
          </div>
          <div>
            <div className='mb1'>
              <div className='jumbo'>
                {`${employee.name} · ${calendarDate(submittedAt)}`}
              </div>
            </div>
            <div className='whitespace-pre-line'>
              {content}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
});

const CurrentResults = observer(({uiState, question}) => {
  return (
    <div className='relative'>
      <InteractiveContext
        proxy={{
          type: 'async',
          endpoint: endpoints.SURVEYS.ANSWERS.with(
            { questionId: question.id }
          ),
          modelType: types.SURVEYS.ANSWER,
          model: SurveyAnswer,
          pageSize: 10
        }}
      >
        <Answers/>
        <Pagination/>
      </InteractiveContext>
    </div>
  );
});

const TextAreaSummary = observer(({questionSummary}) => {
  const {question, uiState} = questionSummary;

  return (
    <Panel>
      <Panel.Header title={question.text}/>
      <CurrentResults question={question} uiState={uiState}/>
    </Panel>
  );
});

export default TextAreaSummary;
