import React from 'react';
import {observer} from 'mobx-react';
import {ReorderModal} from 'components/modals';

const ReorderTypesModal = observer(({uiState}) => {
  const {reorderingTypes} = uiState;

  return (
    <ReorderModal
      isOpen={uiState.reorderTypesModalOpen}
      onHide={() => uiState.closeReorderTypesModal()}
      title={'time_off.types.Reorder Types'}
      models={reorderingTypes}
      onSave={() => uiState.saveOrders()}
      onCancel={() => uiState.closeReorderTypesModal()}
    />
  );
});

export default ReorderTypesModal;
