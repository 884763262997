import _ from 'lodash';
// sizing + positioning
export const WIDTH = 192;
export const HEIGHT = 200;
const SIDE_PADDING = 8;
const TOP_PADDING = 24;
const IMAGE_RADIUS = 48;
const IMAGE_NAME_SPACING = 12;
const NAME_TITLE_SPACING = 8;

export const BORDER_COLOR = '#b9bcbe';
export const BORDER_RADIUS = 4;
const AVATAR_BACKGROUND = '#dddddd';
const NAME_COLOR = '#212736';
const TITLE_COLOR = '#637381';
const NAME_FONT_SIZE = 14;
const TITLE_FONT_SIZE = 13;

const AVATAR_COLOURS = {
  'bg-rose': '#FE5E75',
  'bg-tangerine': '#FDBE3E',
  'bg-naked': '#FDBA93',
  'bg-ufo': '#8DDF75',
  'bg-seafoam': '#25DAD3',
  'bg-anakiwa': '#8DD4E7',
  'bg-cerulean': '#275CCC',
  'bg-eggplant': '#7E66E7',
  'bg-bondi': '#0A7986'
};

function drawInitials(doc, avatarData) {
  const cx = avatarData.x + IMAGE_RADIUS;
  const cy = avatarData.y + IMAGE_RADIUS;
  const tx = avatarData.x + IMAGE_RADIUS / 2;
  const ty = avatarData.y + IMAGE_RADIUS / 2;
  doc.save().circle(cx, cy, IMAGE_RADIUS).fill(AVATAR_COLOURS[avatarData.avatarColour] || AVATAR_BACKGROUND);
  if (!avatarData.avatarInitials) return;

  doc.font('Helvetica').fontSize(24).fillColor('#ffffff').text(
    avatarData.avatarInitials, tx + 8, ty + 16
  );
}

function drawAvatar(doc, avatarData) {
  const imgX = avatarData.x;
  const imgY = avatarData.y;
  const cx = imgX + IMAGE_RADIUS;
  const cy = imgY + IMAGE_RADIUS;
  doc.save().circle(cx, cy, IMAGE_RADIUS).fill(AVATAR_BACKGROUND);
  if (!avatarData.thumb) {
    doc.restore(); // probably unnecessary?
    return;
  }
  const thumb = new Buffer(avatarData.thumb);

  try {
    doc.circle(cx, cy, IMAGE_RADIUS).clip().image(thumb, imgX, imgY, {
      width: IMAGE_RADIUS * 2
    }).restore(); // unclip
  } catch (e) {
    doc.restore();
    drawInitials(doc, avatarData);
  }
}

// all avatars have loaded, draw them
export function drawAvatars(doc, avatars) {
  // avatars is a map of employee ids to avatar info (x, y, image arrayBuffer)
  _.forEach(avatars, a => {
    if (a.url) {
      return drawAvatar(doc, a);
    }
    return drawInitials(doc, a);
  });
}
// returns a function that draw employee node and queues up avatar image fetch
export default function createDrawNodeFn(doc, queueAvatar) {
  return function(data, drawBorder) {
    const {x, y} = data;
    // border
    if (drawBorder) {
      doc.roundedRect(x, y, WIDTH, HEIGHT, BORDER_RADIUS).lineWidth(0.5).stroke(BORDER_COLOR);
    }
    // avatar thumbnail
    const imgX = x + WIDTH / 2 - IMAGE_RADIUS;
    const imgY = y + TOP_PADDING;
    queueAvatar(data.id, {
      url: _.get(data, 'links.profilePicture'),
      avatarInitials: data.avatarInitials,
      avatarColour: data.avatarColour,
      x: imgX,
      y: imgY
    });
    // name
    const maxTextWidth = WIDTH - SIDE_PADDING * 2;
    const nameX = x + SIDE_PADDING;
    const nameY = imgY + IMAGE_RADIUS * 2 + IMAGE_NAME_SPACING;
    doc.font('Helvetica').fontSize(NAME_FONT_SIZE).fillColor(NAME_COLOR).text(data.name, nameX, nameY, {
      lineBreak: false,
      width: maxTextWidth,
      height: NAME_FONT_SIZE,
      align: 'center',
      ellipsis: true
    });
    // title
    const titleY = nameY + NAME_TITLE_SPACING + NAME_FONT_SIZE;
    doc.fontSize(TITLE_FONT_SIZE).font('Helvetica').fillColor(TITLE_COLOR).text(data.jobTitle, nameX, titleY, {
      lineBreak: false,
      height: TITLE_FONT_SIZE,
      width: maxTextWidth,
      align: 'center',
      ellipsis: true
    });
  };
}
