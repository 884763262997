import React from 'react';
import {observer} from 'mobx-react';
import Button from 'components/buttons/Button';
import {FormattedMessage} from 'react-intl';

const AddButton = observer(({onAdd, localization, loading}) => {
  if (!onAdd || !localization.addModel) return null;

  return (
    <Button onClick={onAdd} disabled={loading}>
      <FormattedMessage id={localization.addModel}/>
    </Button>
  );
});

export default AddButton;
