import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import FilterComponent from './FilterComponent';

const CandidateStatus = observer(({filter, filterKey, filterUpdated, filterDeleted, Wrapper}) => {
  const options = [
    { label: t('components.tables.fancy_filters.selectors.Qualified'), value: 'active' },
    { label: t('components.tables.fancy_filters.selectors.Disqualified'), value: 'disqualified' }
  ];
  const fallback = t('components.tables.fancy_filters.all_candidates');

  return <FilterComponent filter={filter} filterKey={filterKey} options={options} fallbackTitle={fallback} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
});

export default CandidateStatus;
