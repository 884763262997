import React from 'react';
import {observer} from 'mobx-react';
import {InteractiveTable} from 'components';
import {KeyDate} from 'stores/pa_dashboard';
import {types, endpoints, t} from 'shared/core';
import KeyDateItem from './KeyDateItem';

const COLUMNS = [
  {
    component: KeyDateItem,
    width: 12
  }
];

const KeyDates = observer(({uiState}) => {
  return (
    <InteractiveTable
      uiState={uiState}
      title={t('pa_dashboard.Key Dates and Reminders')}
      columns={COLUMNS}
      showLinks={false}
      searchable={false}
      showHeaders={false}
      hidePaginationInfo
      proxy={{
        type: 'async',
        modelType: types.PA_DASHBOARD.KEY_DATE,
        model: KeyDate,
        endpoint: endpoints.PA_DASHBOARD.KEY_DATES
      }}
    />
  );
});

export default KeyDates;
