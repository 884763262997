import React from 'react';
import {observer} from 'mobx-react';
import {withState, auth, t} from 'shared/core';
import {AnnouncementState} from '../state';
import {Avatar, ActionLinks, HtmlRender, EmojiPicker, ActionLink} from 'components';
import {FormattedMessage} from 'react-intl';
import CommentsList from './comments/CommentsList';
import CommentInput from './comments/CommentInput';
import {fullName, relativeRangeDate, longTimestampDate} from 'shared/tools';
import Reactions from './reactions/Reactions';
import EditCommentModal from './modals/EditCommentModal';
import DeleteCommentModal from './modals/DeleteCommentModal';
import {MAXIMUM_ANNOUNCEMENT_HEIGHT} from '../state/constants';
import AnnouncementVisibilityModal from './modals/AnnouncementVisibilityModal';

const ExpandCommentsAction = observer(({uiState}) => {
  const {allCommentViewModels, commentViewModels} = uiState;

  return (
    <div className='pb2 flex justify-between'>
      <div>
        <ActionLink onClick={() => uiState.expandComments()}>
          <FormattedMessage id={uiState.expandCommentsText}/>
        </ActionLink>
      </div>
      <div className='jumbo'>
        <FormattedMessage
          id='hr_dashboard.feed.announcements.NUMBER_OF_COMMENTS'
          values={{currentCommentCount: commentViewModels.length, totalCount: allCommentViewModels.length}}
        />
      </div>
    </div>
  );
});

const Announcement = observer(({uiState}) => {
  const {
    announcement, commentViewModels, creatingCommentViewModel,
    emojiSelectorOpen, showExpandCommentsAction, expandable, expanded
  } = uiState;

  return (
    <div className='FeedItem'>
      <div className='flex mr2'>
        {announcement.employeeId ?
          <a href={`/employees/${announcement.employeeId}`}>
            <Avatar size='sm' employee={announcement.publishedByUser}/>
          </a> :
          <Avatar size='sm' employee={announcement.publishedByUser}/>
        }
      </div>
      <div className='flex-grow'>
        <div className='pb2 border-bottom'>
          <div className='flex justify-between'>
            <FormattedMessage
              id='hr_dashboard.feed.announcements.POSTED_ANNOUNCEMENT_MESSAGE'
              values={{
                link: announcement.employeeId ? <a href={`/employees/${announcement.employeeId}`}>
                  {fullName(announcement.publishedByUser)}
                </a> :
                <span>{fullName(announcement.publishedByUser)}</span>
              }}
            />
            <div className='DashboardTooltip jumbo'>
              {relativeRangeDate(announcement.publishedAt)}
              <span className='tooltiptext dateTooltip'>
                {longTimestampDate(announcement.publishedAt)}
              </span>
            </div>
          </div>
          {auth.hasAccess('::MANAGE_ANNOUNCEMENTS') && announcement.employees.length > 0 &&
            <div
              className='jumbo clickable flex align-items-center mt1'
              onClick={() => uiState.openAnnouncementVisibilityModal()}
            >
              <i className='material-icons h3 mr1 align-middle'>{'visibility'}</i>
              <FormattedMessage
                id='hr_dashboard.feed.announcements.VISIBLE_TO'
                values={{count: announcement.employees.length}}
              />
            </div>}
          <div className='flex justify-between py1 items-center'>
            <div className='medium'>
              {announcement.name}
            </div>
            <ActionLinks
              model={announcement}
              action={(announcement) => uiState.openEditAnnouncementModal(announcement)}
              onRemoveClicked={(announcement) => uiState.openDeleteAnnouncementModal(announcement)}
            />
          </div>
          <div
            style={{maxHeight: expanded ? null : `${MAXIMUM_ANNOUNCEMENT_HEIGHT}px`}}
            className='overflow-hidden relative mb2'
          >
            <HtmlRender
              html={announcement.description}
              setRef={element => uiState.setRef(element)}
            />
            {expandable && !expanded &&
              <div className='absolute right-0 bottom-0 pl3 read-more-link'>
                <ActionLink onClick={() => uiState.expandDescription()}>
                  <span>{'...'}</span>
                  <FormattedMessage id='hr_dashboard.feed.announcements.see more'/>
                </ActionLink>
              </div>
            }
          </div>
          {announcement.image && <img
            className='max-width-full block mb2'
            src={announcement.imageUrl}
            alt={t('hr_dashboard.feed.announcements.Image')}
          />}
          <EmojiPicker
            className='col pr2'
            onEmojiClick={emoji => uiState.addReaction(emoji.id)}
            onClickOutside={() => uiState.closeEmojiPicker()}
            showPicker={emojiSelectorOpen}
            onPickerClick={() => uiState.toggleEmojiList()}
          />
          <Reactions
            announcement={announcement}
            toggleReaction={(reaction) => uiState.toggleReaction(reaction)}
          />
        </div>
        <div className='pt2'>
          {showExpandCommentsAction && <ExpandCommentsAction uiState={uiState}/>}
          <CommentsList commentViewModels={commentViewModels}/>
          <CommentInput viewModel={creatingCommentViewModel} uiState={uiState} singleLine/>
        </div>
        <AnnouncementVisibilityModal uiState={uiState}/>
        <EditCommentModal uiState={uiState}/>
        <DeleteCommentModal uiState={uiState}/>
      </div>
    </div>
  );
});

export default withState(Announcement, AnnouncementState);
