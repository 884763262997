import {DomainObject, oneOf} from 'shared/store';
import {observable, action, computed} from 'mobx';
import Employee from 'stores/employees/Employee';
import {BenefitClass} from 'stores/benefits';
import {t} from 'shared/core';
import {calendarDate} from 'shared/tools';

class EmployeeBenefitEnrollment extends DomainObject {
  @observable employee;
  @observable benefitClass;
  @observable probation;
  @observable probationTimePeriod;
  @observable enrolmentDate;
  @observable effectiveDate;
  @observable companyInsuranceCarrier;
  @observable status;
  @observable endDate;
  @observable reasonForUnenrolment;
  @observable changeEffectiveDate;

  @action merge(other) {
    super.merge(other, {
      employee: Employee,
      benefitClass: BenefitClass,
      probationTimePeriod: oneOf(['days', 'months']),
      _dates: ['effectiveDate', 'enrolmentDate', 'endDate', 'changeEffectiveDate']
    });
  }

  @computed get translatedStatus() {
    return t(`employee_benefit_enrollments.statuses.${this.status}`);
  }

  @computed get effectiveDateDisplay() {
    return calendarDate(this.effectiveDate);
  }
}

export default EmployeeBenefitEnrollment;
