import {DomainStore} from 'shared/store';
import {auth, endpoints, types} from 'shared/core';
import UserDetails from './UserDetails';

class UserStore extends DomainStore {
  async loadUser() {
    await this._compose([
      endpoints.USER.with(auth.user.id)
    ]);

    return new UserDetails(this._getSingle(types.USER_DETAIL));
  }
}

const singleton = new UserStore();

export default singleton;
