import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {Layout, Panel, IndexTable} from 'components';
import {PlanDirectoryState} from '../state';
import {CarrierIcon} from 'components/benefits';

const CarrierEmptyState = observer(({carriers}) => {
  if (carriers.length > 0) {
    return null;
  }

  return (
    <Panel>
      <Panel.EmptyState message='components.plan_directory.No carriers to display'/>
    </Panel>
  );
});

const ClassEmptyState = observer(() => {
  return (
    <Panel.EmptyState message='components.plan_directory.No benefit classes to display'/>
  );
});

const PlanDirectory = observer(({uiState}) => {
  const {companyInsuranceCarriers, match} = uiState;

  return (
    <Layout>
      <Panel.Stack loose>
        {companyInsuranceCarriers.map(carrier =>
          <IndexTable key={carrier.id} title={carrier.name}>
            {carrier.benefitClasses.map(benefitClass =>
              <IndexTable.Row
                key={benefitClass.id}
                icon={<CarrierIcon carrier={carrier}/>}
                trait='component'
                title={benefitClass.divisionName}
                description={benefitClass.name}
                showRoute={`${match.url}/benefit_class/${benefitClass.id}`}
              />
            )}
            {carrier.benefitClasses.length === 0 && <ClassEmptyState/>}
          </IndexTable>)}
        <CarrierEmptyState carriers={companyInsuranceCarriers}/>
      </Panel.Stack>
    </Layout>
  );
});

export default withState(PlanDirectory, PlanDirectoryState);
