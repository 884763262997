import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {auth} from 'shared/core';

const PopupClosed = observer(() => {
  return (
    <div>
      <FormattedMessage id="employees.google_import.It looks like you didn't finish the Google import. Please try again."/>
    </div>
  );
});

const DomainNotFound = observer(() => {
  return (
    <div>
      <FormattedMessage id='employees.google_import.It looks like your Google account does not belong to a corporate domain that allows external access. Make sure you are not using a personal Gmail account.'/>
    </div>
  );
});

const ApiAccessNotAllowed = observer(() => {
  return (
    <div>
      <div>
        <FormattedMessage id='employees.google_import.Your corporate Google domain settings prevent 3rd party applications from accessing your data. Speak with your administrator about allowing Collage to access your data.'/>
      </div>
      <div className='pt1'>
        <FormattedMessage id='More information:'/>
      </div>
      <div>
        <a href={`https://support.google.com/a/answer/60757?hl=${auth.locale}`} target='_blank'>
          {`https://support.google.com/a/answer/60757?hl=${auth.locale}`}
        </a>
      </div>
    </div>
  );
});

const UnknownError = observer(({uiState}) => {
  const {googleErrorMessage} = uiState;

  return (
    <div>
      <div>
        <FormattedMessage id='employees.google_import.Your Google import failed due to an unexpected error.'/>
      </div>
      <div>
        <FormattedMessage id='employees.google_import.GOOGLE_ERROR' values={{error: googleErrorMessage}}/>
      </div>
    </div>
  );
});

const ErrorMessage = observer(({uiState}) => {
  switch (uiState.error) {
    case 'popup_closed_by_user':
      return <PopupClosed/>;
    case 'notFound':
      return <DomainNotFound/>;
    case 'domainCannotUseApis':
      return <ApiAccessNotAllowed/>;
    default:
      return <UnknownError uiState={uiState}/>;
  }
});

export default ErrorMessage;
