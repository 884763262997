import React from 'react';
import {observer} from 'mobx-react';
import ReviewCycleList from './ReviewCycleList';
import Subnav from '../../../../Subnav';

const ReviewDirectory = observer(({scope}) => {
  return (
    <div>
      <Subnav scope={scope}/>
      <ReviewCycleList/>
    </div>
  );
});

export default ReviewDirectory;
