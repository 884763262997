import React from 'react';
import {observer} from 'mobx-react';
import NavigationIcon from './NavigationIcon';
import CustomIcon from './CustomIcon';
import ComponentIcon from './ComponentIcon';
import PropTypes from 'prop-types';

const Icon = observer(({icon, trait}) => {
  if (!icon) return null;

  switch (trait) {
    case 'navigation': return <NavigationIcon icon={icon}/>;
    case 'custom': return <CustomIcon icon={icon}/>;
    case 'component': return <ComponentIcon icon={icon}/>;
    default:
      throw new Error(`Trait ${trait} not supported.`);
  }
});

const IndexRowNameWithIcon = observer(({model}) => {
  return (
    <div>
      <Icon icon={model.icon} trait={model.trait} />
      <div className='table-cell align-top'>
        <p>{model.title}</p>
        {model.description && <p className='jumbo'>{model.description}</p>}
      </div>
    </div>
  );
});

Icon.propTypes = {
  trait: PropTypes.oneOf(['navigation', 'custom', 'component'])
};

Icon.defaultProps = {
  trait: 'navigation'
};

export default IndexRowNameWithIcon;
