import React from 'react';
import {observer} from 'mobx-react';
import {Route, Switch} from 'react-router-dom';
import OfferTemplatesContainer from './OfferTemplatesContainer';
import OfferTemplateEditor from './OfferTemplateEditor';

const OfferTemplatePage = observer(({match}) => {
  return (
    <Switch>
      <Route exact path={match.path} component={OfferTemplatesContainer}/>
      <Route exact path={`${match.path}/edit/:id?`} component={OfferTemplateEditor}/>
    </Switch>
  );
});

export default OfferTemplatePage;
