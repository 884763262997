import {observable} from 'mobx';

class SuggestionViewModel {
  @observable selected = false;
  @observable data;

  constructor(data) {
    this.data = data;
  }
}

export default SuggestionViewModel;
