import React from 'react';
import {observer} from 'mobx-react';
import {FormLayout, Input} from 'components';
import CountryAndRegionSelects from "components/forms/country_and_region_selects/CountryAndRegionSelects";

const OnboardEmployeeAddressForm = observer(({uiState}) => {
  const {employee, errors} = uiState;

  return (
    <FormLayout>
      <FormLayout.Group>
        <Input label='onboard.employee_info.Address Line 1'
          value={employee.homeAddress1}
          onChange={(e) => employee.merge({homeAddress1: e.target.value})}
          errorMessage={errors.homeAddress1}
          placeholder='onboard.employee_info.Required'/>
        <Input label='onboard.employee_info.Address Line 2'
          value={employee.homeAddress2}
          errorMessage={errors.homeAddress2}
          onChange={(e) => employee.merge({homeAddress2: e.target.value})}
          placeholder='onboard.employee_info.Optional'/>
      </FormLayout.Group>
      <CountryAndRegionSelects
        initialCountryCode={employee.countryCode}
        initialRegionCode={employee.regionCode}
        onChange={newCountryAndRegionCodes => employee.merge(newCountryAndRegionCodes)}
        errors={errors}
        defaultToCanada={true}
        wrapper={FormLayout.Group}
      />
      <FormLayout.Group>
        <Input label='onboard.employee_info.City'
          value={employee.city}
          errorMessage={errors.city}
          onChange={(e) => employee.merge({city: e.target.value})}
          placeholder='onboard.employee_info.Required'/>
        <Input value={employee.postalCode}
          label='onboard.employee_info.Postal Code'
          onChange={(e) => employee.merge({postalCode: e.target.value})}
          placeholder='onboard.employee_info.Required'
          errorMessage={errors.postalCode} />
      </FormLayout.Group>
    </FormLayout>
  );
});

export default OnboardEmployeeAddressForm;
