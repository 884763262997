import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {dateFormatter} from 'shared/tools';

const SharedWithReviewee = observer(({model}) => {
  if (!model.sharedAt) {
    return <FormattedMessage id='performance_reviews.view.Not Shared'/>;
  }

  return <FormattedMessage id='performance_reviews.view.SHARED_ON' values={{date: dateFormatter(model.sharedAt)}}/>;
});

export default SharedWithReviewee;
