import React from 'react';
import {observer} from 'mobx-react';
import {Accordion, EmployeeNameWithAvatar} from 'components';
import ApproveButton from './ApproveButton';
import {t} from 'shared/core';
import _ from 'lodash';

const PayrollHoursSummaryRow = observer(({payrollHoursSummary, viewModel, horizontalScroll, uiState}) => {
  const {showAccordion} = uiState;

  return (
    <tr className='AccordionTableRow flex align-items-middle' onClick={() => viewModel.isExpanded ? viewModel.collapse() : viewModel.expand()}>
      <td className={!horizontalScroll && 'col-4'} style={horizontalScroll ? {minWidth: '25rem'} : {}}>
        <div className='flex align-items-middle'>
          {showAccordion && (viewModel.canExpand ?
            <i className={`mr1 material-icons jumbo row-chevron ${viewModel.isExpanded ? 'expanded' : ''}`}>{'chevron_right'}</i> :
            <div style={{width: 24}} className='mr1'/>)}
          <EmployeeNameWithAvatar employee={payrollHoursSummary.employee}/>
        </div>
      </td>
      <td className={!horizontalScroll && 'col-1'} style={horizontalScroll ? {minWidth: '5rem'} : {}}>{payrollHoursSummary.regular}</td>
      <td className={!horizontalScroll && 'col-1'} style={horizontalScroll ? {minWidth: '5rem'} : {}}>{payrollHoursSummary.overtime}</td>
      <td className={!horizontalScroll && 'col-1'} style={horizontalScroll ? {minWidth: '5rem'} : {}}>{payrollHoursSummary.doubleOvertime}</td>
      <td className={!horizontalScroll && 'col-1'} style={horizontalScroll ? {minWidth: '5rem'} : {}}>{payrollHoursSummary.holidaysTotal}</td>
      <td className={!horizontalScroll && 'col-1'} style={horizontalScroll ? {minWidth: '5rem'} : {}}>{payrollHoursSummary.ptoTotal}</td>
      <td className={!horizontalScroll && 'col-1'} style={horizontalScroll ? {minWidth: '5rem'} : {}}>{payrollHoursSummary.total}</td>
      <td className={horizontalScroll ? 'right-align' : 'col-2 right-align'} style={horizontalScroll ? {minWidth: '10rem'} : {}}>
        <ApproveButton model={payrollHoursSummary} uiState={uiState}/>
      </td>
    </tr>
  );
});

const ProjectRow = observer(({project, payrollHoursSummary, horizontalScroll, uiState}) => {
  const {projects} = uiState;

  return (
    <tr className='AccordionTableRow border-indent border-indent-3 flex align-items-middle'>
      <td className={!horizontalScroll && 'col-4'} style={horizontalScroll ? {minWidth: '5rem', paddingLeft: 92} : {paddingLeft: 92}}>
        {_.get(_.find(projects, {id: project.id}), 'name') || <span className='jumbo'>{t('time_tracking.payroll_hours.(No Project)')}</span>}
      </td>
      <td className={!horizontalScroll && 'col-1'} style={horizontalScroll ? {minWidth: '5rem'} : {}}>{project.regular}</td>
      <td className={!horizontalScroll && 'col-1'} style={horizontalScroll ? {minWidth: '5rem'} : {}}>{project.overtime}</td>
      <td className={!horizontalScroll && 'col-1'} style={horizontalScroll ? {minWidth: '5rem'} : {}}>{project.doubleOvertime}</td>
      <td className={!horizontalScroll && 'col-1'} style={horizontalScroll ? {minWidth: '5rem'} : {}}>{project.holiday}</td>
      <td className={!horizontalScroll && 'col-1'} style={horizontalScroll ? {minWidth: '5rem'} : {}}>{_.isEmpty(project.id) && payrollHoursSummary.ptoTotal}</td>
      <td className={!horizontalScroll && 'col-1'} style={horizontalScroll ? {minWidth: '5rem'} : {}}>{project.total}</td>
      <td className={!horizontalScroll && 'col-2'} style={horizontalScroll ? {minWidth: '10rem'} : {}}/>
    </tr>
  );
});

const PayrollHoursSummaryView = observer(({model, horizontalScroll, uiState}) => {
  const payrollHoursSummary = model.data;
  const {showAccordion} = uiState;

  return (
    <div>
      <PayrollHoursSummaryRow payrollHoursSummary={payrollHoursSummary} viewModel={model} horizontalScroll={horizontalScroll} uiState={uiState}/>
      {showAccordion && <Accordion active={model.isExpanded}>
        {model.projects.map(project => <div className='pt2'>
          <ProjectRow project={project} payrollHoursSummary={payrollHoursSummary} horizontalScroll={horizontalScroll} uiState={uiState}/>
        </div>)}
      </Accordion>}
    </div>
  );
});

export default PayrollHoursSummaryView;
