import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import {EmployeeNameWithAvatar} from 'components';
import _ from 'lodash';

const Employees = observer(({answeredEmployees}) => {
  return answeredEmployees.map(employee => <div className='mb2' key={employee.id}>
    <EmployeeNameWithAvatar employee={employee} size='sm'/>
  </div>);
});

const EmployeeAnswersModal = observer(({modalOpen, uiState}) => {
  const {answeredEmployees, selectedOption} = uiState;

  return (
    <Modal
      size='sm'
      title={selectedOption}
      translateTitle={false}
      isOpen={modalOpen}
      onHide={() => uiState.closeEmployeeAnswersModal()}
    >
      <Employees answeredEmployees={answeredEmployees}/>
    </Modal>
  );
});

export default EmployeeAnswersModal;
