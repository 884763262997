import {DomainObject, oneOf} from 'shared/store';
import {action, computed, observable} from 'mobx';
import GroupBenefitCoverage from './GroupBenefitCoverage';
import BenefitDivision from './BenefitDivision';
import {AttachedLink} from 'stores/links';
import {AttachedDocument} from 'stores/documents';
import _ from 'lodash';

class BenefitClass extends DomainObject {
  @observable name;
  @observable defaultProbation;
  @observable probationTimePeriod;
  @observable fullName;
  @observable divisionName;
  @observable carrierName;
  @observable groupBenefitCoverages;
  @observable benefitDivision;

  @observable attachedLinks;
  @observable attachedDocuments;

  @action merge(other) {
    super.merge(other, {
      benefitDivision: BenefitDivision,
      groupBenefitCoverages: [GroupBenefitCoverage],
      attachedLinks: [AttachedLink],
      attachedDocuments: [AttachedDocument],
      probationTimePeriod: oneOf(['months', 'days'])
    });
  }

  @computed get sortedGroupBenefitCoverages() {
    return _.sortBy(
      this.groupBenefitCoverages, [
        (coverage) => coverage.coverageType !== 'extended_health_care',
        (coverage) => coverage.coverageType !== 'dental',
        (coverage) => coverage.coverageType
      ]
    );
  }
}

export default BenefitClass;
