import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import CompanyIcon from '../img/icon-company@2x.png';
import EmployeeIcon from '../img/icon-employee@2x.png';
import {t} from 'shared/core';

const SignUpSelector = () => {
  return (
    <div className='clearfix my4'>
      <div className='col-12 md-col-10 lg-col-10 mx-auto'>
        <div className='h1 center medium line-height-2'>
          <FormattedMessage id='signup.Who is this account for?'/>
        </div>
        <div className='col-12 mx-auto mt4'>
          <div className='clearfix'>
            <div className='col col-12 sm-col-6 p1'>
              <div className='bg-white bg-hover bg-hover-smoke rounded box-shadow-1'>
                <Link to='/company'>
                  <div className='p3 center'>
                    <img src={CompanyIcon} alt={t('signup.Company')} width='96' className='my2'/>
                    <div className='h2 tuatara medium'>
                      <FormattedMessage id='signup.Company'/>
                    </div>
                    <div className='h3 jumbo pt1 pb2 px2'>
                      <FormattedMessage id="signup.I'm an administrator setting up an account for my company"/>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className='col col-12 sm-col-6 p1'>
              <div className='bg-white bg-hover bg-hover-smoke rounded box-shadow-1'>
                <Link to='/employee'>
                  <div className='p3 center'>
                    <img src={EmployeeIcon} alt={t('signup.Employee')} width='96' className='my2'/>
                    <div className='h2 tuatara medium'>
                      <FormattedMessage id='signup.Employee'/>
                    </div>
                    <div className='h3 jumbo pt1 pb2 px2'>
                      <FormattedMessage id="signup.I'm an employee setting up an account through my employer"/>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(SignUpSelector);
