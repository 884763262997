import {DomainObject, oneOf} from 'shared/store';
import {action, computed, observable} from 'mobx';

class BalanceAdjustmentPolicy extends DomainObject {
  @observable id;
  @observable type;
  @observable parameter;
  @observable limitDays;

  @action merge(other) {
    super.merge(other, {
      type: oneOf([
        'AnnualResetPolicy', 
        'BalanceLimitPolicy',
        'CarryoverPolicy',
        'NoLimitPolicy'
      ])
    });
  }


  @computed get localizationTitle() {
    switch (this.type) {
      case 'AnnualResetPolicy': 
      case 'BalanceLimitPolicy':
      case 'NoLimitPolicy':
        return `models.time_off.balance_adjustment_policy.type.${this.type}.TITLE`;
      case 'CarryoverPolicy':
        const limit = this.limitDays ? 'deadline' : 'no_deadline';
        return `models.time_off.balance_adjustment_policy.type.${this.type}.${limit}.TITLE`;
      default:
        throw new Error(`Unsupported type of ${this.type}.`);
    }
  }

  @computed get localizationDetail() {
    switch (this.type) {
      case 'AnnualResetPolicy': 
      case 'BalanceLimitPolicy':
      case 'NoLimitPolicy':
        return `models.time_off.balance_adjustment_policy.type.${this.type}.DETAILS`;
      case 'CarryoverPolicy':
        const limit = this.limitDays ? 'deadline' : 'no_deadline';
        return `models.time_off.balance_adjustment_policy.type.${this.type}.${limit}.DETAILS`;
      default:
        throw new Error(`Unsupported type of ${this.type}.`);
    }
  }
}

export default BalanceAdjustmentPolicy;
