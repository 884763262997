import React from 'react';

const Track = ({source, target, getTrackProps}) => {
  const trackStyle = {
    left: `${source.percent}%`,
    width: `${target.percent - source.percent}%`
  };

  return (
    <div className='SliderBar-track' style={trackStyle} {...getTrackProps()}/>
  );
};

export default Track;
