import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {SubmitRequestContainer} from 'submit_request';
import PaDashboard from './PaDashboard';

const PaDashboardPage = () => {
  return (
    <Router basename='/'>
      <Switch>
        <Route path='/pa/dashboard' component={PaDashboard}/>
        <Route path='/submit_request' component={SubmitRequestContainer}/>
      </Switch>
    </Router>
  );
};

export default PaDashboardPage;
