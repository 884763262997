import {computed, action} from 'mobx';
import {t} from 'shared/core';
import {sentimentOptions, sentimentTrendsOptions} from './highcharts_options';
import _ from 'lodash';
import QuestionSummaryViewModel from './QuestionSummaryViewModel';
import {dateFormatter} from 'shared/tools';

class SentimentViewModel extends QuestionSummaryViewModel {
  @computed get sentiment() {
    return _.get(this.currentTrend, 'value.average');
  }

  @computed get ratingMood() {
    if (this.sentiment > 3.5) {
      return {
        text: t('surveys.view.charts.tooltip.positive'),
        color: '#80D137'
      };
    } else if (this.sentiment < 2.5) {
      return {
        text: t('surveys.view.charts.tooltip.negative'),
        color: '#F64A6C'
      };
    } else {
      return {
        text: t('surveys.view.charts.tooltip.neutral'),
        color: '#F4AC29'
      };
    }
  }

  @computed get options() {
    return {
      ...sentimentOptions,
      tooltip: {
        useHTML: true,
        hideDelay: 200,
        formatter: function() {
          const percentage = Math.round(this.point.percentage);
          const count = this.point.y;
          const scale = '<strong> ' + t(`surveys.view.charts.tooltip.${this.series.name}`) + '</strong>';

          let tooltip = `<span style="color: ${this.point.color}">\u25CF</span> `;
          tooltip += t('surveys.view.charts.tooltip.PERCENTAGE_ANSWERED', {...{percentage, count, scale}});
          tooltip += '<div style="padding-top: 16px">';
          tooltip += t(`surveys.view.charts.tooltip.${this.series.name}_desc`);
          tooltip += '</div>';

          return tooltip;
        },
        shape: 'rect'
      },
      series: this.series
    };
  }

  @computed get series() {
    if (!this.currentTrend) return null;

    const breakdown = this.currentTrend.value.breakdown;
    const negative = breakdown[1] + breakdown[2];
    const neutral = breakdown[3];
    const positive = breakdown[4] + breakdown[5];

    return [
      positive && { name: 'positive', data: [positive] },
      neutral && { name: 'neutral', data: [neutral] },
      negative && { name: 'negative', data: [negative] }
    ];
  }

  @action onPointClicked(e) {
    this.periodDate = e.point.custom.periodDate;
  }

  @computed get trendsOptions() {
    if (!this.uiState.survey.isRecurring) return;

    const options = sentimentTrendsOptions(
      {onClick: (e) => this.onPointClicked(e)}
    );

    return {
      ...options,
      xAxis: {
        categories: this.trendsCategories
      },
      tooltip: {
        pointFormatter: function() {
          let tooltip = `<b>${Math.round(this.y * 100) / 100}</b> `;
          tooltip += t('surveys.view.charts.trends.sentiment.across');
          tooltip += ` <b>${this.custom.total}</b> `;
          tooltip += t('surveys.view.charts.trends.sentiment.RESPONSES', {total: this.custom.total});

          return tooltip;
        }
      },
      series: this.trendsSeries
    };
  }

  @computed get trendsSeries() {
    return [
      {
        data: _.map(this.trend, (period) => {
          return {
            y: period.value.average,
            custom: { total: period.value.total, periodDate: period.date },
            marker: {
              fillColor: period.value.average > 3.5 ? '#80D137' : period.value.average >= 2.5 ? '#F4AC29' : '#F64A6C',
              radius: 8
            }
          };
        })
      }
    ];
  }

  @computed get trendsCategories() {
    return _.map(this.trend, (period) => dateFormatter(period.date));
  }
}

export default SentimentViewModel;
