import {observable, computed} from 'mobx';
import _ from 'lodash';

class AnnotationInputViewModel {
  @observable annotation;
  @observable value;
  @observable originalFontSize;

  constructor(annotation) {
    this.annotation = annotation;
    this.originalFontSize = _.get(this.annotation, 'annotationArgs.fontSize');
    switch (annotation.annotationArgs.inputType) {
      case 'text':
      case 'textarea':
        this.value = '';
        break;
      case 'checkbox':
        this.value = 'unchecked';
        break;
      default:
        this.value = '';
        break;
    }
  }

  @computed get isEmpty() {
    const {inputType} = this.annotation.annotationArgs;

    switch (this.annotation.annotationArgs.inputType) {
      case 'text':
      case 'textarea':
        return _.isEmpty(this.value);
      case 'checkbox':
        return this.value !== 'checked';
      default:
        throw new Error(`Input type ${inputType} is not supported.`);
    }
  }
  @computed get isValid() {
    return !this.annotation.annotationArgs.isRequired || !this.isEmpty;
  }
}

export default AnnotationInputViewModel;
