import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const PositionEmploymentType = observer(({model}) => {
  const position = model;

  return (
    <FormattedMessage id={position.employmentType}/>
  );
});

export default PositionEmploymentType;
