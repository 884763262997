import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {endpoints, types, t} from 'shared/core';
import {InteractiveTable} from 'components';
import {SegmentCalendar} from 'stores/calendars';
import TimeOffIcon from '../img/time_off@2x.png';
import BirthdaysIcon from '../img/birthday@2x.png';
import WorkAnniversariesIcon from '../img/anniversary@2x.png';

const COLUMNS = [
  {
    width: 11,
    render: model => <SegmentCalendarRow segmentCalendar={model}/>
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'company_settings.calendars.Remove Custom Calendar?',
    subHeader: 'company_settings.calendars.Are you sure you want to remove this custom calendar?'
  },
  emptyState: 'company_settings.calendars.No custom calendars to display',
  interactive: {
    addModel: 'company_settings.calendars.+ New Custom Calendar'
  }
};

const segmentCalendarIcon = (segmentCalendar) => {
  switch(segmentCalendar.calendarType) {
    case 'pto': return TimeOffIcon;
    case 'birthdays': return BirthdaysIcon;
    case 'work_anniversaries': return WorkAnniversariesIcon;
    default: return TimeOffIcon;
  }
};

const SegmentCalendarRow = observer(({segmentCalendar}) => {
  const {name, description} = segmentCalendar;

  return (
    <div>
      <div className='table-cell align-middle pr2'>
        <img src={segmentCalendarIcon(segmentCalendar)} height='16' width='16' role='presentation'/>
      </div>
      <div className='table-cell align-top'>
        <p>{name}</p>
        <p className='jumbo'>{description}</p>
      </div>
    </div>
  );
});

const SegmentCalendarList = observer(({uiState}) => {
  return (
    <InteractiveTable
      uiState={uiState}
      title={t('company_settings.calendars.Custom Calendars')}
      columns={COLUMNS}
      localization={LOCALIZATION}
      onRemove={(segmentCalendar) => uiState.removeSegmentCalendar(segmentCalendar)}
      onAdd={() => uiState.goToEditSegmentCalendar()}
      editRoute='/calendars/edit/:id'
      searchable={false}
      showHeaders={false}
      proxy={{
        type: 'async',
        modelType: types.SEGMENT_CALENDAR,
        model: SegmentCalendar,
        endpoint: endpoints.SEGMENT_CALENDARS
      }}
    />
  );
});

export default SegmentCalendarList;
