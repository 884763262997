import React from 'react';
import {observer} from 'mobx-react';
import {ReorderModal} from 'components/modals';

const ReorderFoldersModal = observer(({modalOpen, uiState}) => {
  return (
    <ReorderModal
      isOpen={modalOpen}
      onHide={() => uiState.closeReorderFoldersModal()}
      title={'company_settings.company_files.Reorder Company File Folders'}
      models={uiState.reorderingFolders}
      onSave={() => uiState.saveFolderOrders()}
      onCancel={() => uiState.closeReorderFoldersModal()}
    />
  );
});

export default ReorderFoldersModal;
