import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {withState} from 'shared/core';
import {OverviewContainer} from './overview';
import {EnrolmentsContainer} from './enrolments';
import {PlansContainer} from './plans';
import {ClientProfileState} from '../state';
import {ReportsPage} from './reports';

const ClientProfilePage = ({uiState}) => {
  const {match} = uiState;

  return (
    <Switch>
      <Route path={`${match.url}/overview`} render={({match, history}) =>
        <OverviewContainer match={match} history={history} parentState={uiState}/>} />
      <Route path={`${match.url}/enrolments`} render={({match, history}) =>
        <EnrolmentsContainer {...{match, history}} parentState={uiState} />}/>
      <Route path={`${match.url}/plans`} render={({match, history}) =>
        <PlansContainer {...{match, history}} parentState={uiState} />}/>
      <Route path={`${match.url}/reports`} render={({match, history}) =>
        <ReportsPage {...{match, history}} parentState={uiState} />}/>
      <Redirect from={`${match.url}/`} to={`${match.url}/overview`} />
    </Switch>
  );
};

export default withState(ClientProfilePage, ClientProfileState);
