import React from 'react';
import {observer} from 'mobx-react';
import {Input, ActionLink, LabelHelper, FormError} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {CustomFieldOption} from 'stores/custom_fields';

const OptionItem = observer(({option, index, field}) => {
  return (
    <div className='table pb1'>
      <div className='table-cell align-middle full-width pr2'>
        <Input
          disabled={!option.isNew}
          value={option.value}
          onChange={e => option.merge({value: e.target.value})}
          className='small'
          placeholder={t('components.question_form.OPTION_NUMBER', {index})}
          translatePlaceholder={false}
        />
      </div>
      <div className='table-cell align-middle right-align'>
        {field.options.length > 1 && <ActionLink onClick={() => field.options.remove(option)}>
          <i className='material-icons h2 align-top submarine'>{'close'}</i>
        </ActionLink>}
      </div>
    </div>
  );
});

const MultipleChoiceForm = observer(({field, errors}) => {
  if (field.fieldType !== 'multiple_choice') return null;

  return (
    <div>
      <LabelHelper label={t('components.question_form.Options')}/>
      {field.sortedOptions.map((option, index) =>
        <OptionItem key={index} index={index + 1} option={option} field={field} />
      )}
      <FormError message={errors.options} />
      <ActionLink onClick={() => field.options.push(new CustomFieldOption())}>
        <FormattedMessage id='components.question_form.+ Add option'/>
      </ActionLink>
    </div>
  );
});

export default MultipleChoiceForm;
