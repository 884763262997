import React from 'react';
import TableIcon from 'img/table-icon@2x.png';
import BarIcon from 'img/graph-icon@2x.png';
import PieIcon from 'img/pie-icon@2x.png';
import {observer} from 'mobx-react';
import {t, auth} from 'shared/core';
import {ReportList, ReportRow, ReportRowWithRedirect} from 'reports/list';
import {Panel, Layout} from 'components';
import ReportsSubnav from './ReportsSubnav';

// Reports index component, containing the directory of all reports.
const ReportsIndex = observer(({uiState, scope}) => {
  return (
    <div>
      <ReportsSubnav scope={scope}/>
      <Layout>
        <Layout.Section secondary>
          <Panel>
            <ReportList caption='Company' details="reports.View profiles of your company's departments, age, gender, and tenure demographics." visible={uiState.showCompanyPanel}>
              <ReportRow name={t('reports.DEPARTMENT_PROFILE_REPORT')} path='/employees/departments' icon={PieIcon} visible={uiState.departmentProfile} />
              <ReportRow name={t('reports.AGE_PROFILE_REPORT')} path='/employees/age-demographics' icon={BarIcon} visible={uiState.ageProfile} />
              <ReportRow name={t('reports.TENURE_PROFILE_REPORT')} path='/employees/tenure' icon={BarIcon} visible={uiState.tenureProfile} />
              <ReportRow name={t('reports.GENDER_PROFILE_REPORT')} path='/employees/gender-demographics' icon={PieIcon} visible={uiState.genderProfile} />
              <ReportRow name={t('reports.GENDER_PROFILE_REPORT')} path='/employees/legal-gender-demographics' icon={PieIcon} visible={uiState.legalGenderProfile} />
            </ReportList>
            <ReportList caption="Headcount & Turnover" details="reports.View historical data on your company's headcount, and employee turnover." visible={uiState.showHeadcountTurnoverPanel}>
              <ReportRow name={t('reports.HEADCOUNT_REPORT')} path='/headcount' icon={BarIcon} visible={uiState.headcount}/>
              <ReportRow name={t('reports.TURNOVER_REPORT')} path='/turnover' icon={BarIcon} visible={uiState.turnover}/>
              <ReportRow name={t('reports.NEW_HIRES_AND_TERMINATIONS_REPORT')} path='/employees/new-hires-and-terminations' icon={BarIcon} visible={uiState.newHiresAndTerminations}/>
            </ReportList>
            <ReportList caption='Employees' details='reports.View all your employee data, including hires, terminations, and emergency contacts.' visible={uiState.showEmployeePanel}>
              <ReportRow name={t('reports.ACTIVE_EMPLOYEES_REPORT')} path='/personnel' icon={TableIcon} visible={uiState.activeEmployees}/>
              <ReportRow name={t('reports.ON_LEAVE_EMPLOYEES_REPORT')} path='/employees-on-leave' icon={TableIcon} visible={uiState.onLeaveEmployees}/>
              <ReportRow name={t('reports.TERMINATED_EMPLOYEES_REPORT')} path='/terminated-employees' icon={TableIcon} visible={uiState.terminatedEmployees}/>
              <ReportRow name={t('reports.EMERGENCY_CONTACT_INFO_REPORT')} path='/emergency-contacts' icon={TableIcon} visible={uiState.emergencyContacts}/>
            </ReportList>
            <ReportList caption='Time Off' details="reports.Update payroll and gain insights from your employees' time off activity." visible={uiState.showTimeOffPanel}>
              <ReportRow name={t('reports.TIME_OFF_BALANCES_REPORT')} path='/time-off/balances' icon={TableIcon} visible={uiState.timeOffBalances}/>
              <ReportRow name={t('reports.TIME_OFF_LIABILITY_REPORT')} path='/time-off/liability' icon={TableIcon} visible={uiState.vacationLiability}/>
              <ReportRow name={t('reports.TIME_OFF_REQUESTS_REPORT')} path='/time-off/requests' icon={TableIcon} visible={uiState.timeOffRequests}/>
              <ReportRow name={t('reports.TIME_OFF_MANUAL_ADJUSTMENTS_REPORT')} path='/time-off/manual-adjustments' icon={TableIcon} visible={uiState.manualAdjustments}/>
              <ReportRow name={t('reports.PAYROLL_RECONCILIATION_REPORT')} path='/time-off/reconciliation' icon={TableIcon} visible={uiState.payrollReconciliation}/>
            </ReportList>
            <ReportList caption='Time Tracking' details="reports.View time tracking data for employees." visible={uiState.showTimeTrackingPanel}>
              <ReportRow name={t('reports.PROJECT_HOURS_REPORT')} path='/time-tracking/project-hours' icon={TableIcon} visible={uiState.projectHours}/>
            </ReportList>
            <ReportList caption='Performance' details="reports.View the results of employees' performance goals" visible={uiState.showPerformancePanel}>
              <ReportRow name={t('reports.EMPLOYEES_GOALS_REPORT')} path='/performance/employee-goals' icon={TableIcon} visible={uiState.employeeGoals}/>
              <ReportRow name={t('reports.CHECKINS')} path='/performance/checkins' icon={TableIcon} visible={uiState.checkins}/>
              <ReportRow name={t('reports.PERFORMANCE_REVIEWS')} path='/performance/performance-reviews' icon={TableIcon} visible={uiState.performanceReviews}/>
            </ReportList>
            <ReportList caption='Surveys' details="reports.View the results of employee surveys" visible={uiState.showSurveysPanel}>
              <ReportRow name={t('reports.SURVEY_RESPONSES')} path='/surveys' icon={TableIcon} visible={uiState.surveys}/>
              <ReportRow name={t('reports.ANONYMOUS_SURVEY_RESPONSES')} path='/anonymous-surveys' icon={TableIcon} visible={uiState.anonymousSurveys}/>
            </ReportList>
            <ReportList caption='Training' details="reports.View the results of employee training programs" visible={uiState.showTrainingPanel}>
              <ReportRow name={t('reports.TRAINING_SUMMARY_REPORT')} path='/training/summary' icon={TableIcon} visible={uiState.trainingSummary}/>
              <ReportRow name={t('reports.TRAINING_RESPONSES_REPORT')} path='/training/responses' icon={TableIcon} visible={uiState.trainingResponses}/>
            </ReportList>
            <ReportList caption='Recruiting' details="reports.View your recruiting data." visible={uiState.showRecruitingPanel}>
              <ReportRow name={t('reports.CANDIDATES')} path='/recruiting/candidates' icon={TableIcon} visible={uiState.candidates}/>
              <ReportRow name={t('reports.CANDIDATES_BY_SOURCE_REPORT')} path='/recruiting/candidates-by-source' icon={PieIcon} visible={uiState.candidatesBySource} />
              <ReportRow name={t('reports.HIRING_VELOCITY')} path='/recruiting/hiring-velocity' icon={TableIcon} visible={uiState.hiringVelocity}/>
            </ReportList>
            <ReportList caption='General' details="reports.General reports to make managing your employees that much easier." visible={uiState.showGeneralPanel}>
              {!auth.company.payrollEnabled && <ReportRowWithRedirect name={t('reports.PAYROLL_UPDATES_REPORT')} path='/payroll_updates' visible={uiState.payrollUpdates}/>}
              <ReportRowWithRedirect name={t('reports.BENEFIT_UPDATES_REPORT')} path='/benefits/updates' visible={uiState.benefitUpdates}/>
              <ReportRow name={t('reports.DEPENDENT_ELIGIBILITY_REPORT')} path='/benefits/dependent-eligibility' icon={TableIcon} visible={uiState.dependentEligibility}/>
            </ReportList>
          </Panel>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default ReportsIndex;
