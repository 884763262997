import React from 'react';
import {observer} from 'mobx-react';
import ReviewReader from 'performance_reviews/components/reviews/components/view/components/review_details/components/ReviewReader';
import ReviewEditor from 'performance_reviews/components/reviews/components/view/components/review_details/components/ReviewEditor';

const ReviewDetails = observer(({review, ...rest}) => {
  if (review.readOnly) {
    return <ReviewReader {...rest} review={review}/>;
  } else {
    return <ReviewEditor {...rest} isFormDirty={rest.uiState.isFormDirty} review={review}/>;
  }
});

export default ReviewDetails;
