import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {Table, Button} from 'components';
import HolidayDay from './HolidayDay';
import HolidayDate from './HolidayDate';
import calendar from '../calendar';
import _ from 'lodash';

const ForEmployees = observer(({model}) => {
  return (model.locationIds ? t('time_off.holidays.Some Employees') : t('time_off.holidays.All Employees'));
});

const AddStatutoryHolidays = observer(({uiState}) => {
  const {locations, year} = uiState;
  const hasCanadianLocation = _.some(locations, l => l.countryCode === 'CA');
  if (!hasCanadianLocation) return null;
  if (!calendar.has(year)) return null;

  return (
    <div className="pt4 pb2 center border-top">
      <Button className="Btn" trait='default' onClick={() => uiState.openCalendarModal()}>
        <FormattedMessage id='time_off.holidays.Add Statutory Holidays'/>
      </Button>
    </div>
  );
});

const COLUMNS = [
  {
    header: 'time_off.holidays.Holiday',
    attribute: 'name',
    width: 4
  },
  {
    header: 'time_off.holidays.Day',
    component: HolidayDay,
    width: 1
  },
  {
    header: 'time_off.holidays.Date',
    component: HolidayDate,
    width: 3
  },
  {
    header: 'time_off.holidays.For',
    component: ForEmployees,
    width: 3
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'time_off.holidays.Delete Holiday',
    subHeader: 'time_off.holidays.Are you sure you want to delete this holiday?'
  },
  emptyState: 'time_off.holidays.No holidays to display'
};

const HolidayTable = observer(({uiState}) => {
  const {sortedHolidays} = uiState;

  return (
    <div className='pt4'>
      <Table
        uiState={uiState}
        models={sortedHolidays}
        columns={COLUMNS}
        localization={LOCALIZATION}
        onRemove={holiday => uiState.deleteHoliday(holiday)}
        onEdit={holiday => uiState.openEditHolidayModal(holiday)}
      />
      <AddStatutoryHolidays uiState={uiState}/>
    </div>
  );
});

export default HolidayTable;
