import React from 'react';
import {observer} from 'mobx-react';

const ReportRowContent = observer(({name, icon}) => {
  return (
    <div>
      <div className='table-cell align-middle'>
        <img src={icon} height='24' role='presentation'/>
      </div>
      <div className='table-cell align-middle pl1'>
        {name}
      </div>
    </div>
  );
});

export default ReportRowContent;
