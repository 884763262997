import React from 'react';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';
import {Label} from 'components';
import Dotdotdot from 'react-dotdotdot';

@observer class TextAreaView extends React.Component {
  @observable expanded = false;

  @action toggleAnswer() {
    const {answer} = this.props;

    if (answer.content) {
      this.expanded = !this.expanded;
    }
  }

  render() {
    const {answer} = this.props;

    return (
      <div>
        <div className='medium h3'>{answer.question.name}</div>
        {!this.expanded &&
          <div className={answer.content && 'clickable'} onClick={() => this.toggleAnswer()}>
            <Dotdotdot clamp={2}>
              <Label model={answer.content}/>
            </Dotdotdot>
          </div>}
        {this.expanded && <div onClick={() => this.toggleAnswer()} className='clickable whitespace-pre-line'>{answer.content}</div>}
      </div>
    );
  }
}

export default TextAreaView;
