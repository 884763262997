import React from 'react';
import {observer} from 'mobx-react';
import {loader} from 'shared/core';
import OnboardDocumentState from '../state/OnboardDocumentState';
import onboardDocumentStore from 'stores/onboard/OnboardDocumentStore';
import FillableDocumentContainer from 'components/pdf/fillable/components/FillableDocumentContainer';

const OnboardEmployeeDocumentsContainer = observer(({uiState}) => {
  return (
    <div className='Container'>
      <FillableDocumentContainer 
        documentViewModels={uiState.documentViewModels}
        callbacks={{
          onAllDocumentsCompleted: uiState.onAllDocumentsCompleted,
          onCurrentPageUpdated: uiState.onCurrentPageUpdated
        }}/>
    </div>
  );
});

async function load() {
  const uiState = new OnboardDocumentState(onboardDocumentStore);
  await uiState.load();
  return { uiState };
}

export default loader(OnboardEmployeeDocumentsContainer, load);
