import {DomainStore} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {t, endpoints, types} from 'shared/core';
import _ from 'lodash';
import config from './config';
import {PerformanceReviewReview, PerformanceReviewType} from 'stores/performance_reviews';
import colours from 'reports/charts/colours';

class SummaryContainerState {
  store = new DomainStore();
  match;
  history;
  scope;
  Highcharts;
  HighchartsReact;

  @observable cycle;
  @observable reviews;
  @observable types;
  @observable currentType;

  @observable reviewerModalOpen = false;
  @observable currentOption;

  receiveProps({match, history, scope, cycle}) {
    this.cycle = cycle;
    this.match = match;
    this.history = history;
    this.scope = scope;
  }

  @action async load() {
    const [highcharts, highchartsReact,] = await Promise.all([
      import('highcharts'),
      import('highcharts-react-official'),
      this.store._compose([
        endpoints.PERFORMANCE_REVIEWS.REVIEWS.all(this.match.params.cycleId),
        endpoints.PERFORMANCE_REVIEWS.TYPES.with(this.match.params.cycleId)
      ])
    ]);

    this.Highcharts = highcharts.default;
    this.HighchartsReact = highchartsReact.default;
    this.reviews = this.store._getAll(types.PERFORMANCE_REVIEWS.REVIEW, PerformanceReviewReview);
    this.types = this.store._getAll(types.PERFORMANCE_REVIEWS.TYPE, PerformanceReviewType);

    this.setCurrentType(_.head(this.types).id);
  }

  @action setCurrentType(typeId) {
    this.currentType = _.find(this.types, { id: typeId });
  }

  @action openReviewersModal(optionHash) {
    this.reviewerModalOpen = true;
    this.currentOption = optionHash;
  }

  @action closeReviewersModal() {
    this.reviewerModalOpen = false;
  }

  @computed get currentTemplate() {
    return _.get(this.currentType, 'template');
  }

  @computed get currentQuestions() {
    const questions = this.cycle.hasEnded ? this.questionsFromAnswers : this.questionsFromTemplate;
    return this.orderQuestions(questions);
  }

  @computed get currentReviews() {
    return _.filter(this.reviews, { type: { id: this.currentType.id }});
  }

  @computed get currentReviewers() {
    return _.chain(this.currentReviews)
      .map('reviewer')
      .uniqBy('id')
      .value();
  }

  @computed get questionsFromAnswers() {
    return _.chain(this.reviews)
      .filter(review => review.type.id === this.currentType.id)
      .map(review => review.answers.toJS())
      .flatten()
      .map(answer => answer.question)
      .uniqBy('id')
      .value();
  }

  @computed get questionsFromTemplate() {
    return _.get(this.currentTemplate, 'questions', []).toJS();
  }

  questionChartGrouping(question) {
    let results = {};
    const options = question.options.map(option => option.value);
    for (const option of options) {
      const percent = this.optionCountForQuestion(question, option) / this.currentReviews.length;
      results[option] = {
        key: option,
        color: colours(question.options)[Object.keys(results).length],
        percentage: Math.round(percent * 100),
        reviewers: []
      };
    }

    this.currentReviews.forEach(review => {
      const answer = _.find(review.answers, { question: { id: question.id } });
      const reviewer = _.find(results[answer.value].reviewers, review.reviewer);

      if (!reviewer) {
        results[answer.value].reviewers.push(review.reviewer);
      }
    });

    return results;
  }

  chartOptionsForQuestion(question) {
    const options = question.options.map(option => option.value);
    const data = this.dataForQuestion(question, options);
    return {
      ...config,
      colors: colours(options),
      xAxis: {
        categories: options
      },
      series: [{
        name: t('performance_reviews.summary.Number of responses'),
        data: data
      }]
    };
  }

  dataForQuestion(question, options) {
    return options.map(option => this.optionCountForQuestion(question, option));
  }

  optionCountForQuestion(question, option) {
    const answers = this.answersForQuestion(question);
    return _.filter(answers, { value: option }).length;
  }

  answersForQuestion(question) {
    const answers = [];
    this.currentReviews.forEach(review =>
      review.answers.map(answer => answers.push(answer))
    );
    return _.filter(answers, { question: { id: question.id }});
  }

  orderQuestions(questions) {
    const orderedQuestions = _.chain(questions)
      .cloneDeep()
      .orderBy(['order', 'id'])
      .value();

    orderedQuestions.forEach((question, index) => {
      question.merge({ order: index });
    });

    return orderedQuestions;
  }
}

export default SummaryContainerState;
