import {observable, action, computed} from 'mobx';
import InterviewGuideQuestion from './InterviewGuideQuestion';
import {QAAnswer} from 'stores/questions';

class InterviewGuideAnswer extends QAAnswer {
  @observable id;
  @observable content;

  @action merge(other) {
    super.merge(other, {
      question: InterviewGuideQuestion
    });
  }

  @computed get value() {
    return this.content;
  }

  @action updateValue(value) {
    this.content = value;
  }
}

export default InterviewGuideAnswer;
