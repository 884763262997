import {observable, action} from 'mobx';

class ReviewTypeViewModel {
  description;

  @observable data;
  @observable checked = false;
  @observable uiState;

  constructor({description, info, data, uiState}) {
    this.data = data;
    this.description = description;
    this.info = info;
    this.uiState = uiState;
  }

  @action toggle() {
    this.checked = !this.checked;
  }
}

export default ReviewTypeViewModel;
