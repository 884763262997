import React from 'react';
import {observer} from 'mobx-react';
import Highlight from 'react-highlighter';

const NavigationSuggestion = observer(({viewModel, query}) => {
  return (
    <a href={viewModel.data.link} className='tuatara text-decoration-none'>
      <div className={`GlobalSearch-item ${viewModel.selected ? 'selected' : ''} `}>
        <div className={`clearfix table full-width ${viewModel.data.detail ? '' : 'py1'}`}>
          <div className='table-cell align-middle col-1 center'>
            <i className='material-icons align-middle h2'>{viewModel.data.args.icon}</i>
          </div>
          <div className='table-cell align-middle col-11 pl2'>
            <div>
              <Highlight search={query}>
                {viewModel.data.caption}
              </Highlight>
            </div>
            <div className='GlobalSearch-item-detail'>
              {viewModel.data.detail}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
});

export default NavigationSuggestion;
