import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {InsightsState} from '../state';
import {Panel, EmojiTitle} from 'components';
import InboxText from './InboxText';
import NewHireText from './NewHireText';
import PTOText from './PTOText';
import JobApplicantText from './JobApplicantText';
import {FormattedMessage} from 'react-intl';

const InsightsSuccess = () => {
  return (
    <Panel className='bg-success border-left--grass'>
      <Panel.Header title={t('hr_dashboard.insights.Insights')}/>
      <div className='clearfix space-y'>
        <FormattedMessage id='hr_dashboard.insights.There aren’t any outstanding items that need your attention. Hooray!'/>
      </div>
    </Panel>
  );
};

const Insights = observer(({uiState}) => {
  const {inboxSummary, newHireSummary, ptoSummary, jobApplicantSummary, Emoji} = uiState;

  if (uiState.hasNoInsights) return <InsightsSuccess/>;

  return (
    <Panel className='border-left--catalina'>
      <Panel.Header title={<EmojiTitle Emoji={Emoji} emojiKey='bulb' title={t('hr_dashboard.insights.Insights')}/>}/>
      <div className='clearfix space-y'>
        <ul className='pl2'>
          <InboxText inboxSummary={inboxSummary}/>
          <NewHireText newHireSummary={newHireSummary}/>
          <PTOText ptoSummary={ptoSummary}/>
          <JobApplicantText jobApplicantSummary={jobApplicantSummary}/>
        </ul>
      </div>
    </Panel>
  );
});

export default withState(Insights, InsightsState);
