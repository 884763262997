import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, EmployeeTableSelector} from 'components';

const Employees = observer(({uiState}) => {
  const {errors} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('time_tracking.manage.policies.edit.steps.Employees')}
        description={t('time_tracking.manage.policies.edit.Select the employees that will be included in this policy.')}
      />
      <EmployeeTableSelector
        employees={uiState.availableEmployees}
        selectedEmployees={uiState.selectedEmployees}
        onChange={employees => uiState.updateSelectedEmployees(employees)}
        errorMessage={errors.employees}
      />
    </div>
  );
});

export default Employees;
