import React from 'react';
import {observer} from 'mobx-react';
import {InteractiveTable, DropdownFilter} from 'components';
import {FormattedMessage} from 'react-intl';
import AddAssigneesModal from './AddAssigneesModal';
import ArchiveCompanyDocumentModal from './ArchiveCompanyDocumentModal';
import UnarchiveCompanyDocumentModal from './UnarchiveCompanyDocumentModal';
import _ from 'lodash';
import CompanyDocument from 'stores/documents/CompanyDocument';
import {endpoints, types, t} from 'shared/core';

const PreviewLink = observer(({model}) => {
  return (
    <a className='TextLink dodger'
       href={`/api/v1/company_documents/${model.id}/preview`}
       target='_blank'>
      <FormattedMessage id='company_settings.documents.Preview' />
    </a>
  );
});

const COLUMNS = [
  {
    header: 'company_settings.documents.Name',
    attribute: 'name',
    width: 6
  },
  {
    header: 'company_settings.documents.Document Type',
    attribute: 'typeView',
    width: 3
  },
  {
    component: PreviewLink,
    width: 2
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'company_settings.documents.Remove Company Document',
    subHeader: 'company_settings.documents.Are you sure you want to remove this company document?',
    body: 'company_settings.documents.Documents already completed by employees will not be removed. Documents assigned to employees but not yet completed will be removed.'
  },
  interactive: {
    addModel: 'company_settings.documents.+ Add a Document',
  },
  emptyState: 'company_settings.documents.No documents to display'
};

const CompanyDocumentsTable = observer(({uiState}) => {
  return (
    <div className='mb2'>
      <InteractiveTable
        title={t('company_settings.documents.Company Documents')}
        uiState={uiState}
        columns={COLUMNS}
        localization={LOCALIZATION}
        editRoute='/documents/edit/:id'
        onRemove={model => uiState.removeCompanyDocument(model)}
        onAdd={() => uiState.openAddDocumentModal()}
        customLinks={model => uiState.customLinksFor(model)}
        onMount={agent => uiState.setInteractiveAgent(agent)}
        sortable={false}
        proxy={{
          type: 'async',
          modelType: types.COMPANY_DOCUMENT,
          model: CompanyDocument,
          endpoint: endpoints.COMPANY_DOCUMENTS.all
        }}
        initialFilter={{
          status: uiState.filterOptions[0].id
        }}
      >
        <DropdownFilter options={uiState.filterOptions} attr='status'  />
      </InteractiveTable>
      <ArchiveCompanyDocumentModal
        isOpen={uiState.archiveDocumentModalOpen}
        onCancel={() => uiState.closeArchiveDocumentModal()}
        onArchive={() => uiState.archiveCompanyDocument()}
      />
      <UnarchiveCompanyDocumentModal
        isOpen={uiState.unarchiveDocumentModalOpen}
        onCancel={() => uiState.closeUnarchiveDocumentModal()}
        onUnarchive={() => uiState.unarchiveCompanyDocument()}
      />
      <AddAssigneesModal
        companyDocument={uiState.editingDocument}
        isOpen={uiState.addAssigneeModalOpen}
        onHide={() => uiState.closeAddAssigneeModal()}
        onSuccess={() => uiState.onAssigneesAdded()} />
    </div>
  );
});

export default CompanyDocumentsTable;
