import React from 'react';
import {observer} from 'mobx-react';
import {Select2, Spinner} from 'components';
import {Modal, ModalButtons} from 'components/modals';
import {t} from 'shared/core';
import _ from 'lodash';

const CandidateTagModal = observer(({uiState}) => {
  const {detailsState, selectedCandidatesCount} = uiState;
  const {selectedTags, availableTags, bulkAddingTags, isLoadingTags, editTagsModalOpen} = detailsState;

  return (
    <Modal
      isOpen={editTagsModalOpen}
      title={bulkAddingTags ? t('recruiting.hiring_funnel.BULK_ADD_TAGS_TITLE', {count: selectedCandidatesCount}) :
        t('recruiting.hiring_funnel.Edit Tags')}
      translateTitle={false}
      size='md'
      onHide={() => detailsState.closeEditTagsModal()}
    >
      {isLoadingTags ?
        <Spinner/> :
        <React.Fragment>
          <Select2
            multiple
            allowTagCreation
            placeholder='recruiting.hiring_funnel.Add candidate tags'
            value={_.map(selectedTags, 'name')}
            onChange={(e) => detailsState.onTagChange(e)}
          >
            {availableTags.map(({name}) => <option value={name} key={name}>{name}</option>)}
          </Select2>
          <ModalButtons
            onSave={() => detailsState.saveTags()}
            onCancel={() => detailsState.closeEditTagsModal()}
          />
        </React.Fragment>
      }
    </Modal>
  );
});

export default CandidateTagModal;
