import {DomainStore} from 'shared/store';
import _ from 'lodash';

class AsyncDataSource {
  endpoints;
  type;
  store = new DomainStore();

  constructor(endpoints, type) {
    this.endpoints = _.isArray ? endpoints : [endpoints];
    this.type = type;
  }

  async load() {
    await this.store._compose(this.endpoints);
    return this.store._getAll(this.type);
  }
}

export default AsyncDataSource;
