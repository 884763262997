import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Modal, ModalButtons} from 'components/modals';
import {Input} from 'components';

const EditPayScheduleModal = observer(({uiState}) => {
  const {errors, editingPaySchedule, editPayScheduleModalOpen} = uiState;

  return (
    <Modal
      isOpen={editPayScheduleModalOpen}
      onHide={() => uiState.closeEditPayScheduleModal()}
      size='sm'
      title={t('time_tracking.manage.pay_schedules.directory.Edit Pay Schedule')}>
      <Input
        value={editingPaySchedule.name}
        errorMessage={errors.name}
        onChange={(e) => editingPaySchedule.merge({name: e.target.value})}
       />
      <ModalButtons onSave={() => uiState.savePaySchedule()} onCancel={() => uiState.closeEditPayScheduleModal()} />
    </Modal>
  );
});

export default EditPayScheduleModal;