import React from 'react';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import UploadedFiles from './UploadedFiles';
import AddMoreFiles from './AddMoreFiles';
import {Dropzone, FormError} from 'components';
import UploadFilesState from 'components/upload/state/UploadFilesState';
import PropTypes from 'prop-types';
import {withState} from 'shared/core';

const MAX_FILE_SIZE = 50 * 1024 * 1024;

const DropzoneOrFiles = observer(({uiState, uploadedFilesComponent, children}) => {
  const UploadedFilesComponent = uploadedFilesComponent;

  if (uiState.hasFiles || uiState.isUploading) {
    return (
      <div className='mb2 mt1'>
        {!!uploadedFilesComponent ? <UploadedFilesComponent/> : <UploadedFiles uiState={uiState}/>}
        {uiState.dropzoneVisible &&
          <Dropzone
            accept={uiState.accept}
            onDrop={files => uiState.addFiles(files)}
            multiple={uiState.multipleFiles}
            children={children}
            maxSize={MAX_FILE_SIZE}
            disabled={uiState.disabled}
          />
        }
        {uiState.canAddFiles && <AddMoreFiles uiState={uiState}/>}
      </div>
    );
  }

  return (
    <div className='mb2 mt1'>
      <Dropzone
        accept={uiState.accept}
        onDrop={files => uiState.addFiles(files)}
        multiple={uiState.multipleFiles}
        children={children}
        maxSize={MAX_FILE_SIZE}
        disabled={uiState.disabled}
      />
      <FormError message={uiState.errorMessage}/>
      <FormError message={uiState.maxFileSizeErrorMessage}/>
    </div>
  );
});

DropzoneOrFiles.propTypes = {
  defaultFiles: MobxPropTypes.observableArray.isRequired,
  onChange: PropTypes.func.isRequired,
  onUploadStarted: PropTypes.func,
  onUploadFinished: PropTypes.func,
  disabled: PropTypes.bool
};

DropzoneOrFiles.defaultProps = {
  maxFiles: -1,
  disabled: false
};

export default withState(DropzoneOrFiles, UploadFilesState);
