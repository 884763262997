import React from 'react';
import {observer} from 'mobx-react';
import {Avatar} from 'components';
import Highlight from 'react-highlighter';

const CandidateSuggestion = observer(({viewModel, query}) => {
  return (
    <a href={viewModel.data.link} className='tuatara text-decoration-none'>
      <div className={`GlobalSearch-item ${viewModel.selected ? 'selected' : ''}`}>
        <div className='clearfix table full-width'>
          <div className='table-cell col-1'>
            <Avatar employee={viewModel.data.args} size='sm'/>
          </div>
          <div className='table-cell pl2 align-middle col-11'>
            <div>
              <Highlight search={query}>
                {viewModel.data.caption}
              </Highlight>
            </div>
            <div className='GlobalSearch-item-detail'>
              {viewModel.data.detail}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
});

export default CandidateSuggestion;
