import React from 'react';
import {observer} from 'mobx-react';
import PayRate from './PayRate';
import Manager from './Manager';
import Location from './Location';
import Department from './Department';
import CustomField from './CustomField';
import TextField from './TextField';
import SelectField from './SelectField';
import DateField from './DateField';
import EMPLOYMENT_TYPES from 'stores/employees/employmentTypes';
import PAY_FREQUENCY_TYPES from 'stores/employees/payFrequencyTypes';
import _ from 'lodash';

const FieldEditor = observer(({field, ...rest}) => {
  if (_.startsWith(field, 'cf')) return <CustomField field={field} {...rest}/>;

  switch (field) {
    case 'pay_rate':
      return <PayRate {...rest}/>;
    case 'manager':
      return <Manager {...rest}/>;
    case 'location_id':
      return <Location {...rest}/>;
    case 'department_id':
      return <Department {...rest}/>;
    case 'employment_type':
      return <SelectField
        field={_.camelCase(field)}
        options={EMPLOYMENT_TYPES.map(type => {
          return {id: type, name: `mass_updates.edit.employment_types.${type}`};
        })}
        {...rest}
      />;
    case 'pay_frequency':
      return <SelectField field={_.camelCase(field)} options={PAY_FREQUENCY_TYPES} {...rest}/>;
    case 'bonus':
    case 'commission':
      return <SelectField field={_.camelCase(field)} options={[{id: true, name: 'Yes'}, {id: false, name: 'No'}]} {...rest}/>;
    case 'job_title':
    case 'hours_per_week':
    case 'annualized_commission':
    case 'first_name':
    case 'last_name':
    case 'invitation_email':
    case 'preferred_name':
    case 'home_address_1':
    case 'home_address_2':
    case 'city':
    case 'postal_code':
    case 'personal_phone':
    case 'personal_email':
    case 'sin':
    case 'work_email':
    case 'work_phone':
    case 'work_phone_extension':
    case 'ec_1_name':
    case 'ec_1_relationship':
    case 'ec_1_phone':
    case 'ec_2_name':
    case 'ec_2_relationship':
    case 'ec_2_phone':
      return <TextField field={_.camelCase(field)} {...rest}/>;
    case 'bonus_structure':
    case 'commission_structure':
      return <TextField field={_.camelCase(field)} textArea {...rest}/>;
    case 'birth_date':
      return <DateField field={_.camelCase(field)} {...rest}/>;
    default:
      return null;
  }
});

export default FieldEditor;
