import React, {useRef} from 'react';
import {Layout, Panel, DropdownSelect, Spinner, ObjectSelect2, FormLayout, InteractiveContext, FancyFilter} from 'components';
import {observer} from 'mobx-react';
import {CycleResultsState} from '../state';
import {t, withState} from 'shared/core';
import useModal from 'shared/hooks/useModal';
import EmployeeAnswersModal from './EmployeeAnswersModal';
import {FilterRow} from 'components/tables/interactive/components/FilterRow';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';
import GraphTabs from './GraphTabs';
import DownloadGraph from './DownloadGraph';

const EmptyState = observer(() => {
  return (
    <div className='center jumbo rounded h5 py2 px1 Table-emptyState'>
      <FormattedMessage id='performance_reviews.view.cycle_results.There are no submitted answers to display'/>
    </div>
  );
});

const BarGraph = observer(({uiState, reportRef}) => {
  const {Highcharts, HighchartsReact, result, cycle} = uiState;

  if (_.isEmpty(result.trend)) return <EmptyState/>;

  return (
    cycle.types.map(type =>
      !_.isEmpty(uiState.trendsForType(type)) && <React.Fragment>
        <Panel.Header title={<span className='h2'>{type.displayKind}</span>}/>
          {uiState.trendsForType(type).map(trend => <div className='mb2'>
            <Panel.Header title={`${trend.questionOrder + 1}. ${trend.questionName}`}/>
            <HighchartsReact ref={reportRef} highcharts={Highcharts} options={uiState.barGraphTrend(trend.answers, trend.questionOptions)}/>
          </div>)}
      </React.Fragment>
    )
  );
});

const Distribution = observer(({uiState, reportRef}) => {
  const {Highcharts, HighchartsReact, result, cycle} = uiState;

  if (_.isEmpty(result.trend)) return <EmptyState/>;

  return (
    cycle.types.map(type =>
      !_.isEmpty(uiState.trendsForType(type)) && <React.Fragment>
        <Panel.Header title={<span className='h2'>{type.displayKind}</span>}/>
          {uiState.trendsForType(type).map(trend => <div className='mb2'>
            <Panel.Header title={`${trend.questionOrder + 1}. ${trend.questionName}`}/>
            <HighchartsReact ref={reportRef} highcharts={Highcharts} options={uiState.distributionTrend(trend.answers, trend.questionOptions)}/>
          </div>)}
      </React.Fragment>
    )
  );
});

const HeatMap = observer(({uiState, reportRef}) => {
  const {Highcharts, HighchartsReact, result, heatmapOptions} = uiState;

  if (_.isEmpty(result.trend.data)) return <EmptyState/>;

  return <HighchartsReact ref={reportRef} highcharts={Highcharts} options={heatmapOptions}/>;
});

const Ninebox = observer(({uiState, reportRef}) => {
  const {Highcharts, HighchartsReact, nineboxOptions, result, cycle, questions, selectedQuestion1, selectedQuestion2, showNinebox} = uiState;

  return (
    <div>
      <FormLayout>
        <FormLayout.Group>
          <ObjectSelect2
            value={selectedQuestion1}
            items={_.reject(questions, {id: _.get(selectedQuestion2, 'id'), typeId: _.get(selectedQuestion2, 'typeId')})}
            groups={cycle.types}
            groupBy={'typeId'}
            groupDisplay={'displayKind'}
            onChange={question => uiState.selectQuestion1(question)}
            hideSelected
            allowClear
            key={`${_.get(selectedQuestion1, 'id')}-${_.get(selectedQuestion1, 'typeId')}` || 'selectedQuestion1'}
            label='performance_reviews.view.cycle_results.Question 1'
          />
          <ObjectSelect2
            value={selectedQuestion2}
            items={_.reject(questions, {id: _.get(selectedQuestion1, 'id'), typeId: _.get(selectedQuestion1, 'typeId')})}
            groups={cycle.types}
            groupBy={'typeId'}
            groupDisplay={'displayKind'}
            onChange={question => uiState.selectQuestion2(question)}
            hideSelected
            allowClear
            key={`${_.get(selectedQuestion2, 'id')}-${_.get(selectedQuestion2, 'typeId')}` || 'selectedQuestion2'}
            label='performance_reviews.view.cycle_results.Question 2'
          />
        </FormLayout.Group>
      </FormLayout>
      {showNinebox &&
        (_.isEmpty(result.trend.data) ?
          <EmptyState/> :
          <div style={{maxWidth: 700, margin: '0 auto'}}>
            <HighchartsReact ref={reportRef} highcharts={Highcharts} options={nineboxOptions}/>
          </div>)
      }
    </div>
  );
});

const GraphView = observer(({uiState, reportRef, isUpdating}) => {
  const {selectedGraph} = uiState;

  if (isUpdating) return <Spinner/>;

  switch (selectedGraph) {
    case 'barGraph':
      return <BarGraph reportRef={reportRef} uiState={uiState}/>;
    case 'distribution':
      return <Distribution reportRef={reportRef} uiState={uiState}/>;
    case 'heatmap':
      return <HeatMap reportRef={reportRef} uiState={uiState}/>;
    case 'ninebox':
      return <Ninebox reportRef={reportRef} uiState={uiState}/>;
    default:
      return null;
  }
});

const CycleResultsContainerWithState = observer(({uiState}) => {
  const employeeAnswersModalOpen = useModal(uiState, 'EmployeeAnswersModal');
  const {endpoint, employees, questions, selectedGraph} = uiState;
  const reportRef = useRef(null);

  const filterOptions = ['review_employee', 'location', 'department', 'employment_type'];
  if (selectedGraph !== 'ninebox') {
    filterOptions.push('review_question');
  }

  return (
    <Layout>
      <Panel>
        <GraphTabs uiState={uiState}/>
        <InteractiveContext
          onRefresh={args => uiState.updateResults(args)}
          uiState={uiState}
          proxy={{
            type: 'async',
            endpoint: endpoint
          }}
          fancyFilterModels={{review_employee: employees, review_question: questions}}
          saveStateToUrl
          fancyFiltersEnabled
        >
          <FilterRow hiddenFancyFilters={selectedGraph === 'ninebox' ? ['review_question'] : []}>
            <FancyFilter filters={filterOptions} />
            <div className='ml2'>
              <DropdownSelect selected={t('reports.Export')}>
                <DownloadGraph
                  name={t(`performance_reviews.view.cycle_results.graph_types.${selectedGraph}`)}
                  reportRef={reportRef}
                />
              </DropdownSelect>
            </div>
          </FilterRow>
          <GraphView uiState={uiState} reportRef={reportRef}/>
        </InteractiveContext>
      </Panel>
      <EmployeeAnswersModal uiState={uiState} modalOpen={employeeAnswersModalOpen}/>
    </Layout>
  );
});

export default withState(CycleResultsContainerWithState, CycleResultsState);
