import React from 'react';
import {observer} from 'mobx-react';
import PerformanceSubnav from 'containers/performance/components/PerformanceSubnav';
import CycleDirectoryWithState from './CycleDirectoryWithState';

const CycleDirectory = observer(({scope}) => {
  return (
    <div>
      <PerformanceSubnav scope={scope}/>
      <CycleDirectoryWithState/>
    </div>
  );
});

export default CycleDirectory;
