import React from 'react';
import {noop} from 'shared/tools';
import * as Sentry from "@sentry/react";

export const loader = (WrappedComponent, load, shouldReload, CustomSpinner, animate) => {
  const Spinner = CustomSpinner || require('../../components').Spinner;

  return class extends React.Component {

    constructor(props) {
      super(props);

      if (typeof load !== 'function') {
        throw new Error('Collage Error 102: load must be a function. (In collage-react-async)');
      }

      if (shouldReload && typeof shouldReload !== 'function') {
        throw new Error('Collage Error 104: shouldReload must be a function. (In collage-react-async)');
      }

      this.state = {ready: !!this.props.ready};
    }

    executeLoad = async (props) => {
      try {
        const result = await load(props);
        this.setState({ready: true, lastResult: {...result}});
        if (props.onLoaded) {
          props.onLoaded(result);
        }
      } catch (e) {
        if (process.env.__DEV__) {
          throw e;
        } else {
          Sentry.captureException(e);
        }
      }
    };

    componentDidUpdate(prevProps) {
      if (shouldReload && shouldReload(prevProps, this.props)) {
        this.setState({ready: false});
        return this.executeLoad(this.props);
      }
    }

    componentDidMount() {
      if (!this.props.ready) {
        return this.executeLoad(this.props);
      }
    }

    render() {
      if (!this.state.ready) {
        return <Spinner />;
      }

      return <div className={animate ? 'is-fading-in' : ''}><WrappedComponent {...this.props} {...this.state.lastResult} /></div>;
    }
  };
};

export const reduxLoader = noop;
