import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {CustomField, EmployeeCustomFields, Panel, ActionLink} from 'components';
import {FormattedMessage} from 'react-intl';

const EmptyState = () => {
  return (
    <Panel>
      <Panel.Header title={t('employees.hire.steps.Custom Fields')}/>
      <div className='center jumbo rounded h5 py2 px1 Table-emptyState'>
        <FormattedMessage id={'employees.hire.custom_fields.No applicable custom fields for this employee. To manage custom fields, visit'}/>
        <ActionLink onClick={() => window.open('/company_settings/custom_fields/personal', '_blank')}>
          <FormattedMessage id='employees.hire.custom_fields.Company Settings'/>
        </ActionLink>
      </div>
    </Panel>
  );
};

const CustomFields = observer(({uiState}) => {
  const {employeePersonalCustomFields, errors} = uiState;

  if (employeePersonalCustomFields.length === 0) {
    return <EmptyState/>;
  }

  return (
    <EmployeeCustomFields employeeCustomFields={employeePersonalCustomFields} category='personal' errors={errors} useFormLayout>
      {(employeeCustomField, error) => (
        <CustomField
          customField={employeeCustomField}
          key={employeeCustomField.id}
          errorMessage={error ? error.value : null}
        />
      )}
    </EmployeeCustomFields>
  );
});

export default CustomFields;
