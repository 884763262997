import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {CustomField, EmployeeCustomFields, Panel, Input, DatePicker, Select2, ObjectSelect2, RadioButton, RadioButtonGroup, TextArea, FormLayout} from 'components';
import cc from 'currency-codes';
import EMPLOYMENT_TYPES from 'stores/employees/employmentTypes';
import PAY_FREQUENCY_TYPES from 'stores/employees/payFrequencyTypes';
import PAY_RATE_UNIT_TYPES from 'stores/employees/payRateUnitTypes';
import {FormattedMessage} from 'react-intl';
import GeneralInfo from './GeneralInfo';
import CommissionForm from 'employees/components/CommissionForm';
import _ from 'lodash';
/*global $*/

const EmployeeEnrolmentCustomFields = observer(({employee, errors}) => {
  return (
    <EmployeeCustomFields employeeCustomFields={employee.employeeCustomFields} category={['personal', 'job']} errors={errors} useFormLayout>
      {(employeeCustomField, error) => (
        <CustomField
          customField={employeeCustomField}
          key={employeeCustomField.id}
          errorMessage={error ? error.value : null}
        />
      )}
    </EmployeeCustomFields>
  );
});

@observer class EmployeeInfoSection extends React.Component {
  payRateInput;

  componentDidMount() {
    $(this.payRateInput).mask('0999999999999999999999.00');
  }

  render() {
    const {employee, locations, errors} = this.props.uiState;

    return (
      <Panel.Stack loose>
        <GeneralInfo uiState={this.props.uiState} />
        <Panel>
          <Panel.Header title={t('benefit_enrolment.enrol.Employment Info')}/>
          <FormLayout>
            <DatePicker
              label='benefit_enrolment.enrol.Enrolment Date'
              withIcon={true}
              value={employee.startDate}
              onChange={value => this.props.uiState.onDateChanged(value)}
              errorMessage={errors.startDate}
              helpLink={{
                explanation: {
                  header: t('benefit_enrolment.enrol.help.What is the Enrolment Date?'),
                  paragraphs: [
                    t('benefit_enrolment.enrol.help.ENROLMENT_DATE_HELP_DESC')
                  ]
                }
              }}
            />
            <Input
              label='benefit_enrolment.enrol.Job Title'
              placeholder={t('benefit_enrolment.enrol.e.g. Marketing Director')}
              translatePlaceholder={false}
              value={employee.jobTitle}
              onChange={e => employee.merge({jobTitle: e.target.value})}
              errorMessage={errors.jobTitle}
            />
            <FormLayout.Group>
              <ObjectSelect2
                label='benefit_enrolment.enrol.Location'
                value={employee.location}
                onChange={value => employee.merge({location: value})}
                items={locations}
                errorMessage={errors.locationId}
              />
              <Select2
                label='benefit_enrolment.enrol.Employment Type'
                value={employee.employmentType}
                onChange={e => employee.merge({employmentType: e.target.value})}
                errorMessage={errors.employmentType}>
                {EMPLOYMENT_TYPES.map(type => <option key={type} value={type}>{t(type)}</option>)}
              </Select2>
            </FormLayout.Group>
          </FormLayout>
        </Panel>
        <Panel>
          <Panel.Header title={t('benefit_enrolment.enrol.Compensation Info')}/>
          <FormLayout>
            <Input
              label='benefit_enrolment.enrol.Pay Rate'
              icon='attach_money'
              placeholder='— —'
              translatePlaceholder={false}
              value={employee.payRate}
              onChange={e => employee.merge({payRate: e.target.value})}
              errorMessage={errors.payRate}
              inputRef={input => this.payRateInput = input }
              connectedRight={
                <Select2
                  value={employee.payRateUnit}
                  onChange={e => employee.merge({payRateUnit: e.target.value})}
                >
                  {PAY_RATE_UNIT_TYPES.map(p => <option value={p.id} key={p.id}>{t(`employees.profile.role.per_unit.${p.id}`)}</option>)}
                </Select2>
              }
            />
            <FormLayout.Group>
              <Select2
                label='benefit_enrolment.enrol.Currency'
                value={employee.payRateCurrency}
                onChange={e => employee.merge({payRateCurrency: e.target.value})}
                errorMessage={errors.payRateCurrency}
                searchable={true}
              >
                {cc.codes().map(c => <option key={c} value={c}>{c}</option>)}
              </Select2>
              <Select2
                label='benefit_enrolment.enrol.Pay Frequency'
                helpMessage='benefit_enrolment.enrol.How often is this employee paid?'
                value={employee.payFrequency}
                onChange={e => employee.merge({payFrequency: e.target.value})}
                errorMessage={errors.payFrequency}
              >
                {PAY_FREQUENCY_TYPES.map(type => <option key={type.id} value={type.id}>{t(type.name)}</option>)}
              </Select2>
            </FormLayout.Group>
            <Input
              label='employees.profile.role.Hours worked per week'
              helpLink={{
                explanation: {
                  header: 'employees.profile.role.hours_per_week_explanation_header',
                  paragraphs: [
                    t('employees.profile.role.hours_per_week_explanation_1', {employee: employee.name}),
                    t('employees.profile.role.hours_per_week_explanation_2')
                  ]
                }
              }}
              value={employee.hoursPerWeek}
              onChange={e => employee.merge({hoursPerWeek: e.target.value})}
              errorMessage={errors.hoursPerWeek}
            />
            <RadioButtonGroup
              label='employees.profile.role.Employee earns a bonus'
              name='bonus'
              selectedValue={employee.bonus}
              onChange={(name, value) => employee.merge({bonus: value})}
            >
              <RadioButton className='table-cell pr2' value={false}><FormattedMessage id='employees.profile.role.No'/></RadioButton>
              <RadioButton className='table-cell pl2' value={true}><FormattedMessage id='employees.profile.role.Yes'/></RadioButton>
            </RadioButtonGroup>
            {employee.bonus &&
              <TextArea
                label='employees.profile.role.Bonus Details'
                value={employee.bonusStructure}
                onChange={(e) => employee.merge({bonusStructure: e.target.value})}
                placeholder='employees.profile.role.Enter the bonus details here'
                minRows={3}
              />
            }
            <CommissionForm model={employee} errors={errors} />
          </FormLayout>
        </Panel>
        <EmployeeEnrolmentCustomFields employee={employee} errors={errors}/>
      </Panel.Stack>
    );
  }
}

export default EmployeeInfoSection;
