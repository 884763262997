import React from 'react';
import {Checkbox} from 'components';
import {observer} from 'mobx-react';
import StyleGuideTitle from './StyleGuideTitle';
import StyleGuideExample from './StyleGuideExample';
import StyleGuideParameterRows from './StyleGuideParameterRows';

const PARAMS = [
  { name: 'checked', type: 'boolean', description: 'Set checkbox to be checked or unchecked', values: '[true, false]' },
  { name: 'disabled', type: 'boolean', description: 'Disable interactions with the checkbox', values: '[true, false]' },
  { name: 'errorMessage', type: 'string/boolean', description: 'Displays errors related to the checkbox', values: 'Ex. translation.error, [true, false]' },
  { name: 'label', type: 'string', description: 'Translatable label that appears beside the checkbox', values: 'Ex. translation.label' },
  { name: 'onChange', type: 'function', description: 'Callback that processes changes to checkbox value', values: 'Returns {value, checked}' }
];

const StyleGuideCheckbox = observer(() => {
  return (
    <div>
      <StyleGuideTitle title='Checkbox' description='Checkboxes are most commonly used to allow users to make a range of selections (zero, one, or multiple). They may also be used to allow users to indicate they agree to specific terms and services.'/>

      <div className='bg-white box-shadow-1 rounded p4 my3'>
        <StyleGuideExample title='Default Checkbox' description='This is the basic usage of a checkbox.'>
          <Checkbox onChange={(checked) => console.log(checked)} checked={false} label='Default'/>
        </StyleGuideExample>

        <StyleGuideExample title='Disabled Checkbox' description='Disable any interaction with the checkbox.'>
          <Checkbox onChange={(checked) => console.log(checked)} checked={false} label='Unselected & Disabled' disabled={true} className='block mb1'/>
          <Checkbox onChange={(checked) => console.log(checked)} checked={true} label='Selected & Disabled' disabled={true} className='block'/>
        </StyleGuideExample>

        <StyleGuideExample title='Error Checkbox' description='Disable any interaction with the checkbox.'>
          <Checkbox onChange={(checked) => console.log(checked)} checked={false} label='Error' errorMessage='There is an error'/>
        </StyleGuideExample>

        <div className='mb3'>
          <div className='h2 medium mb2'>{'Parameters'}</div>
          <div className='clearfix mb1'>
            <div className='col col-3 pr1 h5 caps'>{'Name'}</div>
            <div className='col col-2 pr1 h5 caps'>{'Type'}</div>
            <div className='col col-4 pr1 h5 caps'>{'Description'}</div>
            <div className='col col-3 pl1 h5 caps'>{'Values'}</div>
          </div>
          <ul className='StyleGuide-params list-reset'>
            {PARAMS.map((p, i) => <StyleGuideParameterRows key={i} index={i} {...p}/>)}
          </ul>
        </div>
      </div>
    </div>
  );
});

export default StyleGuideCheckbox;
