import React from 'react';
import {observer} from 'mobx-react';
import {types, withState} from 'shared/core';
import {EditEmploymentRoleContainerState} from '../state';
import AddEmployeeToPayrollModal from './AddEmployeeToPayrollModal';
import PayrollDisabledWarning from './PayrollDisabledWarning';
import JobTimelineEntryForm from './JobTimelineEntryForm';
import JobTimelineEntryFormHeader from './JobTimelineEntryFormHeader';
import {ModalButtons} from 'components/modals';
import {Display, Layout} from 'components';
import {withRouter} from 'react-router-dom';

const EditEmploymentRoleContainer = observer(({uiState}) => {
  const {employee} = uiState;

  return (
    <div>
      <Display
        backPath={`/${employee.id}/job`}
        backTitle={employee.name}
        title={<JobTimelineEntryFormHeader uiState={uiState}/>}
      />
      <Layout>
        <Layout.Section secondary>
          <PayrollDisabledWarning uiState={uiState}/>
          <JobTimelineEntryForm uiState={uiState}/>
          <AddEmployeeToPayrollModal uiState={uiState}/>
          <ModalButtons
            trait={uiState.editRecord._type === types.TERMINATION_RECORD ? 'destructive' : 'primary'}
            saveCaption={uiState.editRecord._type === types.TERMINATION_RECORD ? 'employees.profile.Terminate' : 'Save'}
            onSave={() => uiState.saveEmploymentRecord()}
            onCancel={() => uiState.goBack()}
          />
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withRouter(withState(EditEmploymentRoleContainer, EditEmploymentRoleContainerState));
