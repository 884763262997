import {DomainObject, oneOf} from 'shared/store';
import {observable, action} from 'mobx';
import Employee from 'stores/employees/Employee';

class KeyDate extends DomainObject {
  @observable id;
  @observable type;
  @observable date;
  @observable employee;

  @action merge(other) {
    super.merge(other, {
      employee: Employee,
      _dates: ['date'],
      type: oneOf([
        'leave_cessation', 'new_benefit_enrolment',
        'active_benefit_enrolment', 'benefit_enrolment_cessation'
      ])
    });
  }
}

export default KeyDate;
