import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class AttachedLink extends DomainObject {
  @observable title;
  @observable linkTitle;
  @observable url;
}

export default AttachedLink;
