import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class EmailTemplate extends DomainObject {
  @observable name;
  @observable subject;
  @observable richText;
  @observable lexicalState;
  @observable annotations = [];
}

export default EmailTemplate;
