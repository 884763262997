import {observable, action, computed} from 'mobx';
import amplitude from 'amplitude-js';
import GoogleDirectoryApi from 'employees/google_import/GoogleDirectoryApi';
import GoogleUserViewModel from './GoogleUserViewModel';
import _ from 'lodash';
import {endpoints} from 'shared/core';
import {DomainStore} from 'shared/store';
import {extractGoogleError, reportError} from 'employees/google_import/tools';

class GoogleImportStore extends DomainStore {
  async postUsers(users) {
    return this.post(
      endpoints.EMPLOYEE.BULK_IMPORT,
      'employeeImports',
      {
        employees: users.map(user => user.toJS())
      }
    );
  }
}

class GoogleImportState {
  googleDirectoryApi;
  store = new GoogleImportStore();
  onEmployeesAddedCallback;

  @observable modalOpen = false;
  @observable currentStep;
  @observable googleUsers = [];
  @observable selectAll = false;
  @observable createdEmployees = [];
  @observable duplicateEmployees = [];
  @observable error;
  @observable googleErrorMessage;

  constructor(onEmployeesAddedCallback) {
    this.onEmployeesAddedCallback = onEmployeesAddedCallback;
  }

  @action async launchImport() {
    this.currentStep = 'loading-google-init';
    this.modalOpen = true;
    this.googleDirectoryApi = new GoogleDirectoryApi({
      onUsersReceived: response => this.onUsersReceived(response),
      onError: (e) => this.onError(e)
    });
    const isSignedIn = await this.googleDirectoryApi.load();
    this.currentStep = isSignedIn ? 'loading-google-response' : 'start';
    amplitude.getInstance().logEvent('Employee Import Started', { import_source: 'Google' });
  }

  @action onError(e) {
    this.currentStep = 'finish';
    const {code, message} = extractGoogleError(e);
    this.error = code;
    this.googleErrorMessage = message;
    reportError(this.error);
  }

  @action cancelImport() {
    this.modalOpen = false;
    this.googleDirectoryApi.signOut();
    this.error = '';
    this.googleErrorMessage = '';
  }

  @action toggleSelectAll() {
    this.selectAll = !this.selectAll;
    for (const user of this.googleUsers) {
      user.selected = this.selectAll;
    }
  }

  @action signIn() {
    this.currentStep = 'loading-google-response';
    this.googleDirectoryApi.signIn();
  }

  @action async addEmployees() {
    const {model} = await this.store.postUsers(this.selectedUsers);

    amplitude.getInstance().logEvent('Employee Import Completed', {
      import_source: 'Google',
      total_employees: this.selectedUsers.length,
      duplicate_employees: (model.duplicateEmployees || []).length,
      created_employees: (model.created_employees || []).length
    });
    if (model.duplicateEmployees) {
      this.duplicateEmployees = model.duplicateEmployees;
    }
    if (model.createdEmployees) {
      this.createdEmployees = model.createdEmployees;
      this.onEmployeesAddedCallback(model.createdEmployees);
    }
    this.currentStep = 'finish';
  }

  @action onUsersReceived(response) {
    if (response.status === 200 && response.result && response.result.users) {
      this.googleUsers = response.result.users.map(
        user => new GoogleUserViewModel(user)
      );
    }
    this.currentStep = 'select-employees';
  }

  @action async disconnectGoogleAccount() {
    await this.googleDirectoryApi.disconnect();
    this.currentStep = 'start';
  }

  @computed get selectedUsers() {
    return _.filter(this.googleUsers, 'selected');
  }

  @computed get createdEmployeesCount() {
    return this.createdEmployees.length;
  }

  @computed get duplicateEmployeesCount() {
    return this.duplicateEmployees.length;
  }

  @computed get isSignedIn() {
    return !!this.googleDirectoryApi.getCurrentUser();
  }

  @computed get currentUser() {
    return this.googleDirectoryApi.getCurrentUser();
  }

  @computed get hasError() {
    return !!this.error;
  }
}

export default GoogleImportState;
