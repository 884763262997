import _ from 'lodash';

const DEFAULT_PAGE_SIZE = 25;

class LocalDataProxy {
  models;

  constructor({data, pageSize}) {
    if (!data) {
      throw new Error(`Local data proxy requires a ${data} prop.`);
    }

    this.pageSize = pageSize || DEFAULT_PAGE_SIZE;
    this.models = data;
  }

  async load({pagination}) {
    // TODO: return filter/sorting objects
    return {
      models: _.slice(this.models, (pagination.currentPage - 1) * this.pageSize, pagination.currentPage * this.pageSize),
      pagination: {
        currentPage: pagination.currentPage,
        totalPages: Math.ceil(this.models.length / this.pageSize),
        totalCount: this.models.length,
        pageSize: this.pageSize
      }
    };
  }
}

export default LocalDataProxy;
