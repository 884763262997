import {observable, action} from 'mobx';
import {types, endpoints} from 'shared/core';
import {DomainStore} from 'shared/store';

class EmployeeDirectoryPageState {
  store = new DomainStore();
  @observable scope;

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEE_DIRECTORY.NAVIGATION
    ]);

    this.scope = this.store._getSingle(types.NAVIGATION_SCOPE).tabs;
  }
}

export default EmployeeDirectoryPageState;
