import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import StatutoryHolidayList from './StatutoryHolidayList';

const CalendarModal = observer(({uiState}) => {
  const {statutoryHolidays, errors} = uiState;

  return (
    <Modal
      size="lg"
      onHide={() => uiState.closeCalendarModal()}
      isOpen={uiState.calendarModalOpen}
      title="time_off.holidays.Add Statutory Holidays"
    >
      <StatutoryHolidayList holidays={statutoryHolidays} errors={errors}/>
      <ModalButtons
        onCancel={() => uiState.closeCalendarModal()}
        onSave={(e) => uiState.saveStatutoryHolidays()}
        saveCaption="time_off.holidays.Apply Selected"
      />
    </Modal>
  );
});

export default CalendarModal;
