import React from 'react';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';
import {t, withState} from 'shared/core';
import {observer} from 'mobx-react';
import {Panel, Submit, Layout} from 'components';
import OnboardEmployeeInfoState from '../state/OnboardEmployeeInfoState';
import OnboardEmployeePersonalInformationForm from './OnboardEmployeePersonalInformationForm';
import OnboardEmployeeAddressForm from './OnboardEmployeeAddressForm';
import OnboardEmployeeContactForm from './OnboardEmployeeContactForm';
import OnboardEmployeeCustomFieldsForm from './OnboardEmployeeCustomFieldsForm';

const OnboardEmployeeInfoContainer = observer(({uiState}) => {
  return (
    <Layout>
      <Panel.Stack loose>
        <Panel>
          <Panel.Header title={t('onboard.employee_info.Employee Information')}/>
          <OnboardEmployeePersonalInformationForm uiState={uiState}/>
        </Panel>
        <Panel>
          <Panel.Header title={t('onboard.employee_info.Address')}/>
          <OnboardEmployeeAddressForm uiState={uiState}/>
        </Panel>
        <Panel>
          <Panel.Header title={t('onboard.employee_info.Contact Information')}/>
          <OnboardEmployeeContactForm uiState={uiState}/>
        </Panel>
        {!_.isEmpty(uiState.employee.employeeCustomFields) &&
          <OnboardEmployeeCustomFieldsForm uiState={uiState}/>
        }
      </Panel.Stack>
      <div className='my3'>
        <Submit size='lg' onClick={() => uiState.nextStep()}><FormattedMessage id='onboard.employee_info.Next step'/></Submit>
      </div>
    </Layout>
  );
});

export default withState(OnboardEmployeeInfoContainer, OnboardEmployeeInfoState);
