import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {endpoints, types, t} from 'shared/core';
import {successAlert, redirect} from 'shared/tools';
import {TimeOffPolicy} from 'stores/time_off';

class PolicyDirectoryState {
  store = new DomainStore();

  @observable editingPolicy = {};
  @observable createModalOpen = false;
  @observable renameModalOpen = false;
  @observable deleteModalOpen = false;
  @observable confirmDelete = false;
  @observable errors = {};
  @observable agent;

  @action setInteractiveAgent(agent) {
    this.agent = agent;
  }

  @action openCreateModal() {
    this.editingPolicy = new TimeOffPolicy();
    this.createModalOpen = true;
  }

  @action openRenameModal(policy) {
    this.renameModalOpen = true;
    this.editingPolicy = new TimeOffPolicy(policy);
  }

  @action openDeleteModal(policy) {
    this.deleteModalOpen = true;
    this.editingPolicy = policy;
  }

  @action closeModals() {
    this.createModalOpen = false;
    this.renameModalOpen = false;
    this.deleteModalOpen = false;
    this.confirmDelete = false;
    this.errors = {};
  }

  @action toggleConfirmDelete(checked) {
    this.confirmDelete = checked;
  }

  @action async createPolicy() {
    const {model, errors} = await this.store.post(
      endpoints.TIME_OFF.POLICIES.ALL,
      types.TIME_OFF.POLICY,
      this.editingPolicy
    );

    this.errors = errors;
    if (model) {
      return this.goToEditPolicy(model);
    }
  }

  @action async duplicatePolicy(policy) {
    const {model} = await this.store.post(
      policy.link('duplicate'),
      types.TIME_OFF.POLICY,
      policy
    );

    if (model) {
      await this.agent.refresh();
    }
  }

  @action async updatePolicyName() {
    const {model, errors} = await this.store.patch(
      this.editingPolicy.link('rename'),
      types.TIME_OFF.POLICY,
      this.editingPolicy
    );

    this.errors = errors;
    if (model) {
      await this.agent.refresh();
      this.closeModals();
      this.editingPolicy.merge(model);
      successAlert(t('time_off.policy.Policy has been renamed'));
    }
  }

  @action async deletePolicy() {
    if (!this.confirmDelete) {
      this.errors = {
        confirmDelete: t('time_off.policy.CONFIRMATION_VALIDATION')
      };
      return null;
    }

    await this.store.destroy(this.editingPolicy);
    this.closeModals();
    successAlert(t('time_off.policy.Policy has been deleted'));
  }

  @action async removePolicy(policy) {
    await this.store.destroy(policy);
    this.closeModals();
    successAlert(t('time_off.policy.Policy has been deleted'));
  }

  @action async goToEditPolicy(policy) {
    return redirect(`/time_off/policies/${policy.id}/welcome`);
  }

  customLinksFor(model) {
    const links = [
      {
        text: 'time_off.policy.Edit',
        action: model => this.goToEditPolicy(model),
        order: 0
      },
      {
        text: 'time_off.policy.Rename',
        action: model => this.openRenameModal(model),
        order: 1
      }
    ];

    if (model.hasLink('duplicate')) {
      links.push({
        text: 'time_off.policy.Duplicate',
        action: model => this.duplicatePolicy(model),
        order: 2,
      });
    }

    return links;
  }
}

export default PolicyDirectoryState;
