import React from 'react';
import {observer} from 'mobx-react';
import {ActionLink, Panel, Button, Layout, List, QuickTip, Spinner} from 'components';
import {auth, loader, withDisplay, t} from 'shared/core';
import billingStore from 'stores/billing/BillingStore';
import BillingDetailsState from 'company_settings/billing/state/BillingDetailsState';
import SubNav from 'company_settings/SubNav';
import {FormattedMessage} from 'react-intl';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import CancelPayrollModal from './CancelPayrollModal';
import EditContactDetailsModal from './EditContactDetailsModal';

const CancellationInstructions = observer(({uiState}) => {
  if (auth.company.payrollEnabled) {
    return (
      <FormattedMessage
        id='company_settings.billing.cancel_subscription.PAYROLL_ENABLED'
        values={{
          cancelLink: <ActionLink onClick={() => uiState.openCancelPayrollModal()}>
                        <FormattedMessage id='company_settings.billing.cancel_subscription.cancel your payroll services.'/>
                      </ActionLink>
        }}
      />
    );
  }

  return <FormattedMessage id='company_settings.billing.cancel_subscription.You can request to cancel your subscription by clicking here. You will be asked to confirm first.'/>;
});

const CancelSubscriptionPanel = observer(({uiState}) => {
  const {subscriptionIsActive} = uiState;
  if (!auth.user.admin || !subscriptionIsActive) return null;

  return (
    <Panel>
      <Panel.Header title={t('company_settings.billing.cancel_subscription.Cancel Subscription')}>
      </Panel.Header>
      <div className='flex justify-content-between align-items-middle'>
        <CancellationInstructions uiState={uiState}/>
        <Button trait='destructive' onClick={() => uiState.openCancelSubscriptionModal()} disabled={auth.company.payrollEnabled}>
          <FormattedMessage id='company_settings.billing.cancel_subscription.Request Cancellation'/>
        </Button>
      </div>
    </Panel>
    );
});

const PaymentOutstandingWarning = observer(({uiState}) => {
  const {subscription} = uiState;
  if (!subscription.hasLink('retry')) return null;

  return (
    <div className='mb2'>
      <QuickTip
        trait='caution'
        header={<FormattedMessage id='company_settings.billing.PAYMENT_WARNING_HEADER'/>}
        paragraphs={[
          <FormattedMessage id='company_settings.billing.PAYMENT_WARNING_MESSAGE'/>
        ]}
        actions={[
          {
            caption: t('company_settings.billing.PAYMENT_WARNING_ACTION'),
            onClick: () => uiState.retrySubscription()
          }
        ]}
      />
    </div>
  );
});

const SubscriptionCancelledWarning = observer(({uiState}) => {
  const {subscriptionIsActive, reactivatingSubscription} = uiState;
  if (subscriptionIsActive || reactivatingSubscription) return null;

  return (
    <div className='mb2'>
      <QuickTip
        trait='caution'
        header={<FormattedMessage id='company_settings.billing.CANCELLATION_WARNING_HEADER'/>}
        paragraphs={[
          <FormattedMessage id='company_settings.billing.CANCELLATION_WARNING_MESSAGE'/>
        ]}
        actions={[
          {
            caption: t('company_settings.billing.CANCELLATION_WARNING_ACTION'),
            onClick: () => uiState.reactivateSubscription()
          }
        ]}
      />
    </div>
  );
});


const SubscriptionDetails = observer(({uiState}) => {
  const {subscription, reactivatingSubscription} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('company_settings.billing.Subscription Details')}>
        <Button onClick={() => window.open(subscription.billingPortalLink, '_blank').focus()}>
          <FormattedMessage id='company_settings.billing.View Statements & Billing Details'/>
          <i className='material-icons ml1 h3'>{'launch'}</i>
        </Button>
      </Panel.Header>
      {reactivatingSubscription ?
        <Spinner/> :
        <div className='clearfix table'>
          <List items={subscription.subscriptionItems}/>
        </div>
      }
    </Panel>
  );
});

const BillingDetailsContainer = observer(({uiState}) => {
  const {subscription} = uiState;

  return (
    <Layout>
      <Panel.Stack loose>
        <PaymentOutstandingWarning uiState={uiState}/>
        <SubscriptionCancelledWarning uiState={uiState}/>
        <SubscriptionDetails uiState={uiState}/>
        <ContactDetails uiState={uiState} subscription={subscription}/>
      <CancelSubscriptionPanel uiState={uiState}/>
      </Panel.Stack>
      {auth.company.payrollEnabled ? <CancelPayrollModal uiState={uiState}/> : <CancelSubscriptionModal uiState={uiState}/>}
      <EditContactDetailsModal uiState={uiState}/>
    </Layout>
  );
});

const ContactDetails = observer(({subscription, uiState}) => {
  return (
    <Panel>
      <Panel.Header title={t('company_settings.billing.Contact Details')}>
        <ActionLink onClick={() => uiState.openEditContactDetailsModal()}>
          <FormattedMessage id='company_settings.billing.Edit'/>
        </ActionLink>
      </Panel.Header>
      <div className='clearfix table'>
        <List items={subscription.contactItems}/>
      </div>
    </Panel>
  );
});

async function load() {
  const uiState = new BillingDetailsState(billingStore);
  await uiState.load();
  return {uiState};
}

export default withDisplay(loader(BillingDetailsContainer, load), () => <SubNav title={t('company_settings.subnav.Billing')}/>);
