import React from 'react';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const NotFound = ({title, url}) => {
  return (
    <div className="Container pt3">
      <div className='col col-12 px2 pb3 center'>
        <div className='mx-auto'>
          <div className='pt2 h3'><FormattedMessage id='This page cannot be found'/></div>
          <div className='pt2 h3'><FormattedMessage id='Woops! Something went wrong'/></div>
          <div className='pt2 h3'><FormattedMessage id='Try these links instead:'/></div>
          <div className='pt2'>
            <Link to={url} className='dodger'><FormattedMessage id={title}/></Link>
          </div>
          <div className=''>
            <a href='/' className='dodger'><FormattedMessage id='Dashboard'/></a>
          </div>
        </div>
      </div>
    </div>
  );
};

NotFound.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

export default NotFound;
