import React from 'react';
import {FormattedMessage} from 'react-intl';
import {withState, auth} from 'shared/core';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {Input, ActionLink, FormError, UploadFiles, AttachedDocumentDownload, Spinner, TextArea, FieldHeader} from 'components';
import PublicCandidateFormState from 'containers/recruiting/state/PublicCandidateFormState';
import JobApplicationSubmitted from './JobApplicationSubmitted';
import {JobApplicationQuestions} from 'components/candidate_form';
import {t} from 'shared/core';
import ReCAPTCHA from 'react-google-recaptcha';

const ACCEPTED_RESUME_TYPES = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'text/rtf'
];

const CandidateResume = observer(({uiState, candidate}) => {
  if (uiState.isUploading) return <UploadingResumeSpinner/>;

  return (
    <div className='mb3'>
      <AttachedDocumentDownload
        doc={candidate.resume}
        className='border bg-white rounded p2'
        onRemoveClick={() => candidate.clearResume()}
        removeLink
      />
    </div>
  );
});

const UploadingResumeSpinner = () => {
  return (
    <div className='clearfix table py2 border-bottom'>
      <Spinner size='sm'/>
    </div>
  );
};

const JobApplicationField = observer(({title, placeholder, errors, value, onChange}) => {
  return (
    <div className='my3'>
      <FieldHeader title={title} required={true}/>
      <Input
        value={value} placeholder={placeholder}
        onChange={onChange}
        errorMessage={errors}
      />
    </div>
  );
});

const UploadResumeField = observer(({candidate, uiState, errors}) => {
  return (
    <div>
      <FieldHeader title={t('recruiting.job_application.Attach Resume')} required={true}/>
      <div className='mb3'>
        <UploadFiles
          defaultFiles={candidate.resume ? observable([candidate.resume]) : observable([])}
          onChange={files => candidate.updateResume(files[0])}
          modelType='resume'
          maxFiles={1}
          accept={ACCEPTED_RESUME_TYPES.join()}
          onUploadStarted={() => uiState.onUploadStarted() }
          onUploadFinished={() => uiState.onUploadFinished() }
          uploadedFilesComponent={() => <CandidateResume uiState={uiState} candidate={candidate}/>}
        />
        <FormError message={errors}/>
      </div>
    </div>
  );
});

const JobApplicationForm = observer(({uiState}) => {
  const {candidate, errors, recaptchaRef} = uiState;
  if (uiState.submitted) return <JobApplicationSubmitted match={uiState.match}/>;

  return (
    <div className='Container'>
      <div className='clearfix'>
        <div className='col col-12 md-col-9'>
          <div className='mt1'>
            <h2 className='medium pb2 border-bottom'>
              <FormattedMessage id='recruiting.job_application.Submit your application'/>
            </h2>
            <JobApplicationField
              title={t('recruiting.job_application.First Name')}
              errors={errors.firstName}
              value={candidate.firstName}
              onChange={(e) => candidate.firstName = e.target.value}
            />
            <JobApplicationField
              title={t('recruiting.job_application.Last Name')}
              errors={errors.lastName}
              value={candidate.lastName}
              onChange={(e) => candidate.lastName = e.target.value}
            />
            <JobApplicationField
              title={t('recruiting.job_application.Email Address')}
              errors={errors.email}
              value={candidate.email}
              onChange={(e) => candidate.email = e.target.value}
            />
            <JobApplicationField
              title={t('recruiting.job_application.Phone Number')}
              errors={errors.phoneNumber}
              value={candidate.phoneNumber}
              onChange={(e) => candidate.phoneNumber = e.target.value}
            />
            <UploadResumeField
              candidate={candidate}
              uiState={uiState}
              errors={errors.resume}
            />
          </div>
          <div>
            <JobApplicationQuestions uiState={uiState} candidate={uiState.candidate}/>
          </div>
          <div className='mt4 mb2'>
            <h2 className='medium pb2 border-bottom'>
              <FormattedMessage id='recruiting.job_application.Additional Information'/>
            </h2>
            <div className='my3'>
              <TextArea
                minRows={4}
                value={candidate.additionalInformation || ''}
                onChange={(e) => candidate.additionalInformation = e.target.value}
                placeholder="recruiting.job_application.Add additional notes or anything else you'd like to share"
                className='full-width'
              />
            </div>
          </div>
          <div className='col col-12'>
            <div className='jumbo'>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
                hl={auth.locale}
                onChange={(token) => uiState.submitPublicApplication(token) }
              />
              <FormattedMessage id='recruiting.job_application.GOOGLE_RECAPTCHA_NOTICE'
                values={{
                  privacyPolicy: <a href='https://policies.google.com/privacy' target='_blank'>
                                   <FormattedMessage id='recruiting.job_application.Privacy Policy'/>
                                 </a>,
                  terms: <a href='https://policies.google.com/terms' target='_blank'>
                           <FormattedMessage id='recruiting.job_application.Terms of Service'/>
                         </a>
                }}
              />
            </div>
            <div className='py1'>
              <FormError message={errors.base}/>
            </div>
            <ActionLink onClick={() => uiState.executeRecaptcha()} className='Btn Btn--primary ATS-bottom-button mt2 mb3'>
              <span className='Btn--content' tabIndex='-1'>
                <FormattedMessage id='recruiting.job_application.Submit Application'/>
              </span>
            </ActionLink>
          </div>
        </div>
      </div>
    </div>
  );
});

export default withState(JobApplicationForm, PublicCandidateFormState);
