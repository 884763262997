import React from 'react';
import {observer} from 'mobx-react';
import CompanyDefaultAvatar from 'img/company-icon-white@2x.png';
import PropTypes from 'prop-types';

const CompanyAvatar = observer(({company, size}) => {
  return (
    <div className={`CompanyAvatar CompanyAvatar--${size} ${company.companyColour}`}>
      <img src={CompanyDefaultAvatar} alt='company'/>
    </div>
  );
});

CompanyAvatar.defaultProps = {
  size: 'md'
};

CompanyAvatar.propTypes = {
  size: PropTypes.oneOf(['md', 'lg'])
};

export default CompanyAvatar;
