import {action, observable, computed} from 'mobx';
import SurveyQuestion from './SurveyQuestion';
import {Employee} from 'stores/employees';
import {QAAnswer} from '../questions';
import {generateAnonymousEmployee} from 'shared/tools';

class SurveyAnswer extends QAAnswer {
  @observable content;
  @observable question;
  @observable employee = new Employee(generateAnonymousEmployee());

  @action merge(other) {
    super.merge(other, {
      question: SurveyQuestion
    });
  }

  @action updateValue(value) {
    this.content = value;
  }

  @computed get value() {
    return this.content;
  }
}

export default SurveyAnswer;
