import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Modal} from 'components/modals';
import {observer} from 'mobx-react';
import {Note} from 'components';
import {t} from 'shared/core';

const Notes = observer(({uiState}) => {
  const {activeRequest} = uiState;

  return (
    <div>
      {activeRequest.firstStageNotes && <div className='py2'>
        <Note title={t('employees.profile.time_off.NOTE_BY', {name: activeRequest.firstStageActionedByUser.name})}
              author={activeRequest.firstStageActionedByUser}>
          {activeRequest.firstStageNotes}
        </Note>
      </div>}
      {activeRequest.secondStageNotes && <div className='py2'>
        <Note title={t('employees.profile.time_off.NOTE_BY', {name: activeRequest.secondStageActionedByUser.name})}
              author={activeRequest.secondStageActionedByUser}>
          {activeRequest.secondStageNotes}
        </Note>
      </div>}
    </div>
  );
});

const CreatedByNotes = observer(({author, notes}) => {
  if (!notes) return null;

  if (!author) {
    return (
      <div className='px2 py2 h4 whitespace-pre-wrap'>
        {notes}
      </div>
    );
  }

  return (
    <div className='px2 py2'>
      <Note title={t('employees.profile.time_off.NOTE_BY', {name: author.name})}
            author={author}>
        {notes}
      </Note>
    </div>
  );
});

const RequestNotesModal = observer(({uiState}) => {
  const {activeRequest} = uiState;
  if (!activeRequest) return null;

  return (
    <Modal isOpen={uiState.notesModalOpen}
           onHide={() => uiState.closeNotesModal()} size='md'>
      <div className='h2 medium tuatara mb2 px2'>
        <FormattedMessage id='employees.profile.time_off.Time Off Request Notes'/>
      </div>
      <CreatedByNotes author={activeRequest.createdByUser} notes={activeRequest.notes} />
      <Notes uiState={uiState}/>
    </Modal>
  );
});

export default RequestNotesModal;
