import {DomainObject} from 'shared/store';
import {computed, observable} from 'mobx';
import {FormattedMessage} from 'react-intl';
import React from 'react';
import {fullName} from 'shared/tools';

class GoalCreated extends DomainObject {
  @observable goalTitle;

  @computed get actionDescription() {
    return (
      <div>
        <span className='h3 medium'>
          {fullName(this.user)}
        </span>
        &nbsp;
        <span className='jumbo'>
          <FormattedMessage id='goals.created'/>
        </span>
        &nbsp;
        <span className='medium'>
          {this.goalTitle}
        </span>
      </div>
    );
  }
}

export default GoalCreated;
