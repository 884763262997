import React from 'react';
import {observer} from 'mobx-react';
import {types} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const JobTimelineEntryFormHeader = observer(({uiState}) => {
  const record = uiState.editRecord;
  let translationKey;

  switch (record._type) {
    case types.EMPLOYMENT_RECORD:
      if (uiState.employee.onLeave) {
        translationKey = record.isNew ? 'Return from Leave': 'Edit Employment Record';
      } else {
        translationKey = record.isNew ? 'Create Employment Record': 'Edit Employment Record';
      }
      break;
    case types.TERMINATION_RECORD:
      translationKey = record.isNew ? 'Terminate Employee': 'Edit Termination Record';
      break;
    case types.ON_LEAVE_RECORD:
      translationKey = record.isNew ? 'Place on Leave': 'Edit On Leave Record';
      break;
    case types.REACTIVATION_RECORD:
      translationKey = record.isNew ? 'Reactivate Employee': 'Edit Reactivation Record';
      break;
    default:
      throw new Error(`Entry type ${record._type} is not supported.`);
  }

  return <FormattedMessage id={`employees.profile.role.${translationKey}`}/>;
});

export default JobTimelineEntryFormHeader;
