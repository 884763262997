import React from 'react';
import {observer} from 'mobx-react';
import {Route, Switch} from 'react-router-dom';
import CompanyEmailTemplatesContainer from './CompanyEmailTemplatesContainer';
import CompanyEmailTemplateEditContainer from './CompanyEmailTemplateEditContainer';

const CompanyEmailTemplatePage = observer(({match}) => {
  return (
    <Switch>
      <Route exact path={match.path} component={CompanyEmailTemplatesContainer}/>
      <Route path={`${match.path}/:id`} component={CompanyEmailTemplateEditContainer}/>
    </Switch>
  );
});

export default CompanyEmailTemplatePage;
