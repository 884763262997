import {DomainObject} from 'shared/store';
import {action, observable} from 'mobx';
import {Company} from 'stores/users';

class OktaCredentials extends DomainObject {
  @observable domain;
  @observable secret;
  @observable clientId;
  @observable company;

  @action merge(other) {
    super.merge(other, {
      company: Company
    });
  }}

export default OktaCredentials;
