import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import _ from 'lodash';
import {observable, action} from 'mobx';
import Cycle from 'stores/performance/Cycle';

class CycleState {
  store = new DomainStore();
  history;
  match;

  @observable allCycles = [];
  @observable cycle = null;
  @observable errors = {};

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.PERFORMANCE.CYCLES
    ]);

    this.allEmployees = this.store.getEmployees();
    this.allCycles = _.orderBy(
      this.store._getAll(types.PERFORMANCE.CYCLE).map(c => this.createCycle(c)),
      'sortOrder'
    );
    if(this.match && this.match.params.id) {
      this.selectCycle(this.match.params.id);
    }
  }

  createCycle(json) {
    const cycle = new Cycle(json);
    cycle.init(this.allEmployees);

    return cycle;
  }

  resetCycle = () => {
    this.cycle && this.selectCycle(this.cycle.id);
    this.errors = {};
  };

  selectCycle(id) {
    this.cycle = this.createCycle(_.find(this.allCycles, {id}));
  }
}

export default CycleState;
