import {observable, computed} from 'mobx';
import {DomainObject} from 'shared/store';
import FoldersIcon from 'img/settings/settings-folders@2x.png';

class CompanyFileFolder extends DomainObject {
  @observable name;
  @observable order;

  @computed get icon() {
    return FoldersIcon;
  }

  @computed get key() {
    return `folder-${this.id}`;
  }
}

export default CompanyFileFolder;
