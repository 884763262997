import {observable} from 'mobx';
import {DomainObject} from 'shared/store';

class ContentSection extends DomainObject {
  @observable id;
  @observable positionId = 1;
  @observable richText = '';
  @observable lexicalState;
}

export default ContentSection;
