import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {TimeOffState} from '../state';
import {Button, Panel, Table, EmojiTitle} from 'components';
import _ from 'lodash';
import {redirect} from 'shared/tools';
import {auth} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const DaysAvailable = observer(({model}) => {
  return (
    <div>
      {model.unlimited ?
        <FormattedMessage id='hr_dashboard.time_off.Unlimited'/> :
        <FormattedMessage id='hr_dashboard.time_off.DAYS_VIEW' values={{days: model.balanceView}}/>}
      <div className='waterloo h5'>
        <FormattedMessage id='hr_dashboard.time_off.available'/>
      </div>
    </div>
  );
});

const COLUMNS = [
  {
    width: 8,
    attribute: 'timeOffType.name'
  },
  {
    width: 4,
    component: DaysAvailable
  }
];

const TimeOff = observer(({uiState}) => {
  const {sortedAccounts, Emoji} = uiState;

  return (
    <Panel>
      <Panel.Header title={<EmojiTitle Emoji={Emoji} emojiKey='palm_tree' title={t('hr_dashboard.time_off.My Time Off')}/>}/>
      <Table
        columns={COLUMNS}
        showLinks={false}
        showHeaders={false}
        models={sortedAccounts}
      />
      <Button className='mt1 full-width' onClick={() => redirect(`employees/${auth.employee.id}/time-off/request`)}>
          <FormattedMessage id='hr_dashboard.time_off.Request Time Off'/>
      </Button>
    </Panel>
  );
});

export default withState(TimeOff, TimeOffState);
