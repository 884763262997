import {DomainStore} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {endpoints} from 'shared/core';
import config from 'broker_portal/components/dashboard/tools';
import {t} from 'shared/core';
import _ from 'lodash';

class EmployeesByCarrierChartState {
  store = new DomainStore();
  Highcharts;
  HighchartsReact;

  @observable rows = [];

  @action async load() {
    const [highcharts, highchartsReact,] = await Promise.all([
      import(/* webpackChunkName: 'highcharts-async' */ 'highcharts'),
      import(/* webpackChunkName: 'highcharts-react-official-async' */ 'highcharts-react-official'),
      this.store._compose([
        endpoints.BROKER_PORTAL.DASHBOARD.EMPLOYEES_BY_CARRIER
      ])
    ]);

    this.rows = this.store._getAll('reportRows');
    this.Highcharts = highcharts.default;
    this.HighchartsReact = highchartsReact.default;
  }

  @computed get data() {
    return _.chain(this.rows)
      .orderBy(['values.employees'], ['desc'])
      .map(row => {
        return {
          name: row.values.carrier,
          y: row.values.employees
        };
      })
      .value();
  }

  @computed get categories() {
    return _.map(this.data, r => r.name);
  }

  @computed get chartOptions() {
    return {
      ...config,
      series: [{
        name: t('broker_portal.dashboard.Lives Covered'),
        data: this.data
      }],
      xAxis: {
        categories: this.categories
      }
    };
  }
}

export default EmployeesByCarrierChartState;
