import _ from 'lodash';
import {observable, action} from 'mobx';
import {api, endpoints} from 'shared/core';

class PayrollNotEnabledState {
  @observable interestedInPayrollModalOpen = false;

  @action openInterestedInPayrollModal() {
    this.interestedInPayrollModalOpen = true;
  }

  @action closeInterestedInPayrollModal() {
    this.interestedInPayrollModalOpen = false;
  }

  @action async submit(payrollServiceType) {
    await api.post(endpoints.PAYROLL.INTERESTED, {payrollServiceType, source: 'payroll_page'});
    this.openInterestedInPayrollModal();
  }
}

export default PayrollNotEnabledState;
