import {action} from 'mobx';
import _ from 'lodash';

class ReferralDirectoryState {
  history;

  receiveProps({history}) {
    this.history = history;
  }

  @action goToCandidateCreation(position) {
    this.history.push(`/${position.id}/refer_candidate`);
  }
}

export default ReferralDirectoryState;
