import {DomainObject} from 'shared/store';
import {action, observable} from 'mobx';
import {AttachedDocument} from '../documents';

class BankAccount extends DomainObject {
  @observable nickname;
  @observable accountNumber;
  @observable transitNumber;
  @observable institutionNumber;
  @observable cheque;

  @action merge(other, fields) {
    super.merge(other, {
      cheque: AttachedDocument,
    });
  }
}

export default BankAccount;
