import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {endpoints, types} from 'shared/core';
import {Steps} from 'shared/tools';
import {initialSteps, defaultTypePolicyStep, finalSteps} from './steps';
import _ from 'lodash';
import {TimeOffPolicy} from 'stores/time_off';

class PolicyEditFlowState {
  store = new DomainStore();
  history;
  match;

  @observable policy;
  @observable steps;
  @observable errors = {};

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    await this.store._compose([
      endpoints.TIME_OFF.POLICY.with(this.match.params.id)
    ]);
    this.policy = new TimeOffPolicy(this.store._getSingle(types.TIME_OFF.POLICY));
    this.steps = new Steps(this._initializeSteps());
  }

  @action async onStepChanged(step) {
    // Fill this out for any initialization that needs to be done
    return null;
  }

  @action async onStepSubmitted({location}) {
    switch(location) {
      case 'name':
        return this.submitName();
      default:
        return null;
    }
  }

  @action async submitName() {
    this._submitStep(['name']);
  }

  @action async _submitStep(props) {
    const policy = this.policy.pick(props);
    const {model, errors} = await this.store.patch(policy);

    this.errors = errors;
    if (!model) return null;

    this.policy.update(model);
  }

  _initializeSteps() {
    const steps = initialSteps;

    if (_.isEmpty(this.policy.typePolicies)) {
      steps.push(defaultTypePolicyStep);
    } else {
      steps.push(...this.policy.typePolicies.map(t => {
        return { 
          name: t.type.name,
          translateName: false,
          location: `time_off_rules/${t.id}` 
        };
      }));
    }

    steps.push(...finalSteps);
    return steps.map((s, index) => ({ ...s, index: index + 1 }));
  }
}

export default PolicyEditFlowState;
