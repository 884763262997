import React from 'react';
import {observer} from 'mobx-react';
import {Tag} from 'components';
import {FormattedMessage} from 'react-intl';

const ReviewStatusTag = observer(({cycle}) => {
  switch (cycle.state) {
    case 'created':
      return <Tag><FormattedMessage id='models.performance_reviews.status.created'/></Tag>;
    case 'configured':
      return <Tag><FormattedMessage id='models.performance_reviews.status.configured'/></Tag>;
    case 'launched':
      return <Tag colour='meadow'><FormattedMessage id='models.performance_reviews.status.launched'/></Tag>;
    case 'ended':
    default:
      return null;
  }
});

export default ReviewStatusTag;
