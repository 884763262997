import {observer} from "mobx-react";
import React from "react";
import Select2 from "components/Select2";

const CountrySelect = observer(({uiState}) => {
  return (
    <Select2 searchable
             value={uiState.countryCode}
             label="components.country_and_region_selects.Country"
             placeholder="components.country_and_region_selects.Select Country"
             onChange={e => uiState.onCountryChange(e.target.value)}
             errorMessage={uiState.errors && uiState.errors.countryCode}>
      {uiState.countries.map((c) =>
        <option key={c.id} value={c.code}>{c.name}</option>)}
    </Select2>
  );
});

export default CountrySelect;
