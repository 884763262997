import _ from 'lodash';
import {Steps} from 'shared/tools';

const STEPS = [
  {
    name: 'performance_reviews.edit.Name',
    location: 'name',
  },
  {
    name: 'performance_reviews.edit.Questions',
    location: 'questions',
  },
  {
    name: 'performance_reviews.edit.Participants',
    location: 'participants',
  },
  {
    name: 'performance_reviews.edit.Review',
    location: 'review',
  }
].map((s, i) => ({
  ...s,
  index: i + 1
}));

const singleton = new Steps(STEPS);

export default singleton;
