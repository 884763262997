import React from 'react';
import {observer} from 'mobx-react';
import {Panel, List} from 'components';
import {t} from 'shared/core';
import NoChanges from './NoChanges';
import UpdateSummary from './UpdateSummary';
import ConfirmationModal from './ConfirmationModal';

const Review = observer(({uiState}) => {
  const {updateSummaries} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('benefits.benefit_info.edit.review.Summary of changes')}
        description={t('benefits.benefit_info.edit.Review the summary of your changes.')}
      />
      <div className='mb3'>
        <List items={uiState.generalSummaryItems}/>
      </div>
      {uiState.hasChanges ? updateSummaries.map(u => <UpdateSummary key={u.type} summary={u}/>) : <NoChanges/>}
      <ConfirmationModal uiState={uiState}/>
    </div>
  );
});

export default Review;
