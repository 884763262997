import _ from 'lodash';

class ProxyDescriptor {
  type = 'async';
  modelType;
  endpoint;
  data;

  constructor(props) {
    _.merge(this, props);
  }
}

export default ProxyDescriptor;
