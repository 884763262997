import {observable, computed} from 'mobx';
import {t} from 'shared/core';

class FormElementViewModel {
  @observable id;
  @observable name;
  @observable inputType;
  @observable annotationType = 'input';
  @observable isRequired;

  constructor({name, inputType, isRequired = true}) {
    this.name = name;
    this.inputType = inputType;
    this.isRequired = isRequired;
  }

  static textField() {
    return new FormElementViewModel({
      name: t('components.pdf.annotate.toolbar.Single-line Text'),
      inputType: 'text'
    });
  }

  static optionalTextField() {
    return new FormElementViewModel({
      name: t('components.pdf.annotate.toolbar.Optional Single-line Text'),
      inputType: 'text',
      isRequired: false
    });
  }

  static textAreaField() {
    return new FormElementViewModel({
      name: t('components.pdf.annotate.toolbar.Multi-line Text'),
      inputType: 'textarea'
    });
  }

  static optionalTextAreaField() {
    return new FormElementViewModel({
      name: t('components.pdf.annotate.toolbar.Optional Multi-line Text'),
      inputType: 'textarea',
      isRequired: false
    });
  }

  static checkboxField() {
    return new FormElementViewModel({
      name: t('components.pdf.annotate.toolbar.Checkbox Field'),
      inputType: 'checkbox',
      isRequired: false
    });
  }

  static fromHash(hash) {
    return new FormElementViewModel(hash);
  }

  @computed get label() {
    switch (this.inputType) {
      case 'text':
        return this.isRequired ?
          t('components.pdf.annotate.input_label.Text') :
          t('components.pdf.annotate.input_label.Optional Text');
      case 'textarea':
        return this.isRequired ?
          t('components.pdf.annotate.input_label.Multi-line Text') :
          t('components.pdf.annotate.input_label.Optional Multi-line Text');
      default:
        return '';
    }
  }
}

export default FormElementViewModel;
