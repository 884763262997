import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {endpoints, types} from 'shared/core';
import {Survey} from 'stores/surveys';

class SurveyViewPageState {
  store = new DomainStore();
  match;
  history;

  @observable survey;
  @observable scope;

  receiveProps({match, params}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    await this.store._compose([
      endpoints.SURVEYS.SURVEY.with(this.match.params.id),
      endpoints.SURVEYS.NAVIGATION_FOR_SURVEY.with(this.match.params.id)
    ]);

    this.survey = new Survey(
      this.store._getSingle(types.SURVEYS.SURVEY)
    );

    this.scope = this.store._getSingle(types.NAVIGATION_SCOPE).tabs;
  }

  get defaultPath() {
    if (this.scope.overview) return 'summary';
    if (this.scope.write) return 'write';

    return '/';
  }
}

export default SurveyViewPageState;
