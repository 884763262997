const sentimentOptions = {
  title: null,
  legend: {
    enabled: false
  },
  chart: {
    type: 'bar',
    height: 75,
    marginTop: -5
  },
  colors: ['#80D137', '#F4AC29', '#F64A6C'],
  yAxis: {
    allowDecimals: false,
    endOnTick: false,
    visible: false,
    minPadding: 0,
    maxPadding: 0,
    startOnTick: false,
    tickmarkPlacement: 'on'
  },
  xAxis: {
    visible: false,
    minPadding: 0,
    maxPadding: 0,
    startOnTick: false,
    tickmarkPlacement: 'on'
  },
  plotOptions: {
    series: {
      borderRadius: 3,
      dataLabels: {
        enabled: true,
        inside: true,
        formatter: function() {
          return `${Math.round(this.percentage)}%`;
        },
        style: {
          color: '#FFFFFF',
          fontFamily: 'GT Walsheim, sans-serif',
          fontWeight: 500,
          fontSize: '16px',
          textOutline: 'none',
        }
      },
      pointWidth: 50,
      pointPadding: 0,
      groupPadding: 0,
      cursor: 'pointer',
      stacking: 'normal'
    }
  },
  credits: {
    enabled: false
  }
};

export default sentimentOptions;
