import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import SignUpSelector from './SignUpSelector';
import CompanySignUp from './CompanySignUp';
import EmployeeSignUp from './EmployeeSignUp';
import {FormattedMessage} from 'react-intl';
import {LocaleSelect} from 'components';
import Logo from 'img/collage-logo--blue@2x.png';

const SignUpContainer = ({children}) => {
  return (
    <div>
      <div className='bg-white border-bottom center py1'>
        <a href='https://collage.co' className='inline-block p2'>
          <img src={Logo} alt='Collage' height='26'/>
        </a>
        <div className='absolute py2 pr3 right-0 top-0'>
          <LocaleSelect/>
          <div className='h4 py1 jumbo inline-block ml3'>
            <div className='sm-and-below-hide'>
              <FormattedMessage id='signup.ALREADY_HAVE_ACCOUNT' values={{
                link: <a href='/user_session/new' className='dodger'><FormattedMessage id='signup.Log in'/></a>
              }} />
            </div>
          </div>
        </div>
      </div>
      <div className='ResponsiveContainer'>
        {children}
      </div>
    </div>
  );
};

@observer class RouteSelectorContainer extends React.Component {
  @observable showBackLink;

  componentDidMount() {
    this.showBackLink = this.props.match.isExact;
  }

  render() {
    return (
      <Switch>
        <Route path='/company' render={(props) => (
          <CompanySignUp {...props} demoAccount={true} showBackLink={this.showBackLink}/>
        )}/>
        <Route path='/upload' render={(props) => (
          <CompanySignUp {...props} isSlim={true} redirectUrl={'/upload'}/>
        )}/>
        <Route path='/employee' component={EmployeeSignUp} />
        <Route component={SignUpSelector}/>
      </Switch>
    );
  }
}

const SignUpPage = () => {
  return (
    <SignUpContainer>
      <Router basename='/users/new'>
        <Switch>
          <Route path='/' component={RouteSelectorContainer} />
        </Switch>
      </Router>
    </SignUpContainer>
  );
};

export default observer(SignUpPage);
