import React from 'react';
import {observer} from 'mobx-react';
import ReactSelect from 'components/ReactSelect';
import {observable} from 'mobx';
import DropdownFilterState from './DropdownFilterState';

@observer class DropdownFilter extends React.Component {
  @observable uiState;

  componentWillMount() {
    this.uiState = new DropdownFilterState(this.props);
  }

  render() {
    const {uiState} = this;

    return (
      <div className='DropdownFilter nowrap'>
        <ReactSelect selected={uiState.selected.display}>
          {uiState.options.map(option =>
            <ReactSelect.Option active={uiState.selected.id === option.id}
                                key={option.id} text={option.display}
                                onClick={() => uiState.changeOption(option)}/>)}
        </ReactSelect>
      </div>
    );
  }
}

export default DropdownFilter;
