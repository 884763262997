import {observable, action}  from 'mobx';
import {DomainObject} from 'shared/store';
import {Segment} from 'stores/segmentation';

class VisibilitySetting extends DomainObject {
  @observable settingType;
  @observable enabled;
  @observable segment;

  @action merge(other) {
    super.merge(other, {
      segment: Segment
    });
  }
}

export default VisibilitySetting;
