import {DomainStore} from 'shared/store';
import {types, endpoints} from 'shared/core';

class SecureUploadStore extends DomainStore {
  async postSecureFile(secureFile) {
    return this.post(
      endpoints.SECURE_UPLOAD.NEW,
      types.SECURE_FILE,
      secureFile
    );
  }
}

const singleton = new SecureUploadStore();

export default singleton;
