import React from 'react';
import { observer } from 'mobx-react';
import { DocumentUpload } from 'components';

const DocumentUploadForm = observer(({ uiState }) => {
  const { assigningDocument, errors, acceptedMimeTypes } = uiState;

  return (
    <DocumentUpload
      document={assigningDocument}
      errors={errors}
      accept={acceptedMimeTypes}
      onSave={() => uiState.saveUploadedFile()}
      onCancel={() => uiState.closeAssignDocumentModal()}
    />
  );
});

export default DocumentUploadForm;
