import React from 'react';
import {FormError} from 'components';
import {t} from 'shared/core';

const NoChanges = () => {
  return (
    <div>
      <FormError message={t('benefits.benefit_info.edit.review.No changes made')}/>
    </div>
  );
};

export default NoChanges;
