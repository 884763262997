import React from 'react';
import {observer} from 'mobx-react';
import EmployeeSuggestion from './EmployeeSuggestion';
import CandidateSuggestion from './CandidateSuggestion';
import GenericSuggestion from './GenericSuggestion';
import NavigationSuggestion from './NavigationSuggestion';

const Suggestion = observer(({viewModel, query, translatable=false}) => {
  switch (viewModel.data.suggestionType) {
    case 'employee':
      return <EmployeeSuggestion viewModel={viewModel} query={query}/>;
    case 'candidate':
      return <CandidateSuggestion viewModel={viewModel} query={query}/>;
    case 'navigation':
      return <NavigationSuggestion viewModel={viewModel} query={query}/>;
    default:
      return <GenericSuggestion viewModel={viewModel} translatable={translatable} query={query}/>;
  }
});

export default Suggestion;
