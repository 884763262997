import React from 'react';
import {observer} from 'mobx-react';
import {withState, withDisplay, t} from 'shared/core';
import {Layout, Panel, Button, Input, FormLayout, CopyableField, LabelWithHeader} from 'components';
import SubNav from 'company_settings/SubNav';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';
import {OktaConfigurationContainerState} from 'containers/company_settings/applications/okta/state';
import useModal from 'shared/hooks/useModal';
import SSOConfirmationModal from 'containers/company_settings/applications/components/SSOConfirmationModal';
import {auth} from 'shared/core';

const SsoLoginLink = observer(() => {
  return (
    <div>
      <Panel.Subheader title={t('company_settings.applications.okta.Login URL')}/>
      <FormattedMessage id='company_settings.applications.okta.sso_login_url_explanation'/>
      <div className='mt1 mb2'>
        <CopyableField fieldValue={auth.company.ssoLoginUrl}/>
      </div>
    </div>
  );
});

const SsoEnabled = observer(({company, credentials, openSSOConfirmationModal}) => {
  return (
    <div>
      <SsoLoginLink/>
      <FormLayout>
        <LabelWithHeader
          header={t('company_settings.applications.okta.Company Identifier')}
          model={company.oktaIdentifier}
        />
        <LabelWithHeader
          header={t('company_settings.applications.okta.Domain')}
          model={credentials.domain}
        />
        <LabelWithHeader
          header={t('company_settings.applications.okta.Client ID')}
          model={credentials.clientId}
        />
        <LabelWithHeader
          header={t('company_settings.applications.okta.Secret Key')}
          model={credentials.secret}
        />
      </FormLayout>
      <Panel.Separator/>
      <Panel.Header title={t('company_settings.applications.okta.disable_sso')}/>
      <div className='mb2'>
        <FormattedMessage id='company_settings.applications.sso.disable_explanation'/>
      </div>
      <Button onClick={openSSOConfirmationModal} trait='destructive'>
        <FormattedMessage id='company_settings.applications.Disable'/>
      </Button>
    </div>
  );

});

const SsoDisabled = observer(({company, openSSOConfirmationModal, credentials, errors}) => {
  return (
    <div>
      <div>
        <h3>
          <FormattedMessage id='company_settings.applications.okta.Step 1'/>
        </h3>
        <FormattedMessage id='company_settings.applications.okta.explanation_1'/>
        &nbsp;
        <a href='https://support.collage.co/en/articles/8663526-integrating-with-okta' target='_blank'>
          <FormattedMessage id='company_settings.applications.okta.Learn more'/>
        </a>
      </div>
      <div className='my2'>
        <CopyableField fieldValue={company.oktaIdentifier}/>
      </div>
      <div>
        <h3>
          <FormattedMessage id='company_settings.applications.okta.Step 2'/>
        </h3>
        <FormattedMessage id='company_settings.applications.okta.explanation_2'/>&nbsp;
      </div>
      <div className='my2'>
        <FormLayout>
          <Input
            label='company_settings.applications.okta.Domain'
            value={credentials.domain}
            disabled={company.ssoEnabled}
            errorMessage={errors.domain}
            onChange={(e) => credentials.merge({domain: e.target.value})}
          />
          <Input
            label='company_settings.applications.okta.Client ID'
            value={credentials.clientId}
            disabled={company.ssoEnabled}
            errorMessage={errors.clientId}
            onChange={(e) => credentials.merge({clientId: e.target.value})}
          />
          <Input
            label='company_settings.applications.okta.Secret Key'
            value={credentials.secret}
            disabled={company.ssoEnabled}
            errorMessage={errors.secret}
            onChange={(e) => credentials.merge({secret: e.target.value})}
          />
        </FormLayout>
      </div>
      <div>
        <h3>
          <FormattedMessage id='company_settings.applications.okta.Step 3'/>
        </h3>
        <div className='mb2'>
          <FormattedMessage id='company_settings.applications.okta.explanation_3'/>&nbsp;
          <FormattedMessage id='company_settings.applications.sso.user_directory_explainer' values={{
            link: <a href='/company_settings/users/users' target='_blank'>
              <FormattedMessage id="company_settings.applications.sso.user directory"/>
            </a>
          }}/>
        </div>
      </div>
      <Button onClick={openSSOConfirmationModal}>
        <FormattedMessage id='company_settings.applications.Enable'/>
      </Button>
    </div>
  );
});

const NonOktaSsoEnabled = observer(({nonOktaProviderName}) => {
  return (
    <FormattedMessage
      id='company_settings.applications.ONLY_ONE_SSO'
      values={{provider: _.startCase(nonOktaProviderName)}}
    />
  );
});

const OktaConfigurationContainer = observer(({uiState}) => {
  const ssoConfirmationModal = useModal(uiState, 'SSOConfirmationModal');
  const {credentials, errors} = uiState;
  const {company} = credentials;

  return (
    <Layout>
      <Layout.Section secondary>
        <Panel>
          <Panel.Header title={t('company_settings.applications.okta.Okta Single Sign-On')}/>
          {company.ssoEnabled && company.ssoProviderName !== 'okta' &&
            <NonOktaSsoEnabled nonOktaProviderName={company.ssoProviderName}/>}
          {!company.ssoEnabled &&
            <SsoDisabled
              company={company} credentials={credentials} errors={errors}
              openSSOConfirmationModal={() => uiState.openSSOConfirmationModal()}
            />}
          {company.ssoEnabled &&
            <SsoEnabled company={company} credentials={credentials}
              openSSOConfirmationModal={() => uiState.openSSOConfirmationModal()}
            />}
        </Panel>
        <SSOConfirmationModal uiState={uiState} modalOpen={ssoConfirmationModal} provider={'okta'}/>
      </Layout.Section>
    </Layout>
  );
});

export default withDisplay(
  withState(OktaConfigurationContainer, OktaConfigurationContainerState),
  () => <SubNav title={t('company_settings.subnav.Okta')}/>
);
