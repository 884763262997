import {t} from 'shared/core';

const LOCALIZATION = {
  emptyState: 'training.directory.No employee training programs to display'
};

const getFilterOptions = () => ([
  {
    id: 'incomplete',
    display: t('training.directory.Incomplete Training')
  },
  {
    id: 'complete',
    display: t('training.directory.Completed Training')
  }
]);

export {
  getFilterOptions,
  LOCALIZATION
};
