import React from 'react';
import {observer} from 'mobx-react';
import {loader} from 'shared/core';
import {Panel, FormLayout, Input, Display} from 'components';
import companyDocumentStore from 'stores/documents/CompanyDocumentStore';
import CompanyDocumentEditState from '../state/CompanyDocumentEditState';
import CompanyDocumentEditorFactory from './CompanyDocumentEditorFactory';
import {Select2} from 'components';

const CompanyDocumentEditor = observer(({uiState}) => {
  const {companyDocument, sortedFolders, errors} = uiState;

  return (
    <div>
      <Display backPath='/documents'/>
      <div className='Container pb4'>
        <Panel>
          <FormLayout>
            <Input
              label='company_settings.documents.Company Document Name'
              errorMessage={errors.name}
              type='text'
              value={companyDocument.name}
              onChange={(e) => companyDocument.merge({name: e.target.value})}/>
            <Select2
              label='company_settings.documents.Language'
              value={companyDocument.locale}
              onChange={(e) => companyDocument.merge({locale: e.target.value})}>
              <option value='en'>{'English'}</option>
              <option value='fr'>{'Français'}</option>
            </Select2>
            <Select2
              label='company_settings.documents.Upload to Folder on Completion'
              allowClear
              value={companyDocument.folderId}
              onChange={(e) => companyDocument.merge({folderId: e.target.value || null})}>
              {sortedFolders.map((folder) =>
                <option key={folder.id} value={folder.id}>{folder.name}</option>
              )}
            </Select2>
            <CompanyDocumentEditorFactory uiState={uiState}/>
          </FormLayout>
        </Panel>
      </div>
    </div>
  );
});

async function load({history, match}) {
  const uiState = new CompanyDocumentEditState(companyDocumentStore, history, match);
  await uiState.load();
  return {uiState};
}

export default loader(CompanyDocumentEditor, load);
