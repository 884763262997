import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, Toggle} from 'components';

const EmailPreferencesContainer = observer(({uiState}) => {
  const {user, showUnsubscribeToggle, showRecruitingToggle, showPayrollToggle, showBenefitToggle} = uiState;

  if (!showUnsubscribeToggle && !showRecruitingToggle && !showPayrollToggle && !showBenefitToggle) return null;

  return (
    <Panel>
      <Panel.Header title={t('user_settings.email_preferences.Notification Preferences')}/>
      {showUnsubscribeToggle &&
        <div>
          <div className='table-cell align-middle'>
            <Toggle enabled={user.unsubscribedFromEmails} onChange={value => uiState.updateEmailUnsubscribe(value)}/>
          </div>
          <div className='table-cell align-middle pl2'>
            <FormattedMessage id='user_settings.email_preferences.Unsubscribe from all emails'/>
          </div>
        </div>
      }

      {showRecruitingToggle &&
        <div className='mt2'>
          <div className='table-cell align-middle'>
            <Toggle enabled={user.recruitingEmailNotification}
                    onChange={value => uiState.updateEmailNotification(value)}/>
          </div>
          <div className='table-cell align-middle pl2'>
            <FormattedMessage
              id='user_settings.email_preferences.Receive daily digest of recruiting updates (new candidates, candidates progressing through stages)'/>
          </div>
        </div>
      }

      {showPayrollToggle &&
        <div className='mt2'>
          <div className='table-cell align-middle'>
            <Toggle enabled={user.payrollUpdateEmailNotification}
                    onChange={value => uiState.payrollUpdateEmailNotification(value)}/>
          </div>
          <div className='table-cell align-middle pl2'>
            <FormattedMessage id='user_settings.email_preferences.payroll_updates'/>
          </div>
        </div>
      }

      {showBenefitToggle &&
        <div className='mt2'>
          <div className='table-cell align-middle'>
            <Toggle enabled={user.benefitUpdateEmailNotification}
                    onChange={value => uiState.benefitUpdateEmailNotification(value)}/>
          </div>
          <div className='table-cell align-middle pl2'>
            <FormattedMessage id='user_settings.email_preferences.benefit_updates'/>
          </div>
        </div>
      }
    </Panel>
  );
});

export default EmailPreferencesContainer;
