import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Input, FormLayout} from 'components';
import {t} from 'shared/core';

const Name = observer(({uiState}) => {
  const {cycle, errors} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('performance_reviews.edit.Review Name')}
        description={t('performance_reviews.edit.give_name')} />
      <FormLayout>
        <Input
          placeholder='performance_reviews.edit.e.g., Q4 2018 Review'
          errorMessage={errors.name}
          value={cycle.name}
          label='performance_reviews.edit.Review name'
          onChange={e => cycle.merge({name: e.target.value})}
          className='large'/>
      </FormLayout>
    </div>
  );
});

export default Name;
