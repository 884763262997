import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';

const BenefitClass = observer(({model}) => {
  if (model.enrolmentStatus === 'not_enrolled') return null;

  return <div>{model.benefitClassName}</div>;
});

export default BenefitClass;
