import React from 'react';
import {observer} from 'mobx-react';
import {InteractiveTable, FancyFilter} from 'components';
import CandidateDirectoryState from 'recruiting/state/CandidateDirectoryState';
import {types, endpoints, t} from 'shared/core';
import Candidate from 'stores/recruiting/Candidate';
import CandidateDirectoryName from './CandidateDirectoryName';
import CandidateRater from '../hiring_funnel/CandidateRater';
/*global $*/

const DownloadResumeLink = ({candidate}) => {
  if (!candidate.hasLink('resumeUrl')) return null;

  return (
    <a className='Btn Btn--sm Btn--default Btn--icon' href={candidate.link('resumeUrl')} target='_blank' onClick={e => e.stopPropagation()}>
      <span className='Btn--content' tabIndex='-1'>
        <i className='material-icons h2 waterloo'>{'launch'}</i>
      </span>
    </a>
  );
};

const COLUMNS = [
  {
    header: 'recruiting.candidate_directory.Name',
    component: CandidateDirectoryName,
    width: 3
  },
  {
    header: 'recruiting.candidate_directory.Position',
    attribute: 'position.title',
    width: 2,
    className: 'xs-hide'
  },
  {
    header: 'recruiting.candidate_directory.Source',
    width: 2,
    attribute: 'originView',
    sortBy: 'origin',
    className: 'md-and-below-hide'
  },
  {
    header: 'recruiting.candidate_directory.Application Date',
    attribute: 'date',
    sortBy: 'createdAt',
    width: 2,
    className: 'sm-and-below-hide'
  },
  {
    header: 'recruiting.candidate_directory.Rating',
    render: candidate => <CandidateRater score={candidate.score} />,
    sortBy: 'score',
    width: 2,
    className: 'sm-and-below-hide'
  },
  {
    header: 'recruiting.candidate_directory.Resume',
    render: candidate => <DownloadResumeLink candidate={candidate}/>,
    width: 1
  }
];

function scopeFilters() {
  return [
    {
      scope: 'new',
      display: t('recruiting.candidate_directory.scopes.New')
    },
    {
      scope: 'inactive',
      display: t('recruiting.candidate_directory.scopes.Inactive')
    }
  ];
}

const CandidateListWrapper = observer(({uiState}) => {
  return (
    <div className='Container'>
      <InteractiveTable
        uiState={uiState}
        columns={COLUMNS}
        showLinks={false}
        showPath={candidate => uiState.pathForCandidate(candidate)}
        saveStateToUrl
        fancyFiltersEnabled
        proxy={{
          type: 'async',
          modelType: types.RECRUITING.CANDIDATE_SUMMARY,
          model: Candidate,
          endpoint: endpoints.RECRUITING.CANDIDATES.all
        }}
        scopeOptions={scopeFilters()}
      >
        <FancyFilter filters={['candidate_positions', 'candidate_status', 'candidate_tags', 'candidate_ratings', 'include_candidate_resume_in_search']}/>
      </InteractiveTable>
    </div>
  );
});

export default () => {
  const uiState = new CandidateDirectoryState();
  return <CandidateListWrapper uiState={uiState}/>;
};
