import React from 'react';
import BlueCheckmark from 'img/circle-checkmark--blue@2x.png';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';

const BackToSubmitRequest = observer(({uiState}) => {
  return (
    <Link to='/submit_request' onClick={() => uiState.clearStatus()} className='HoverAnimation-trigger--image TextLink'>
      <span className='align-middle'>
        <FormattedMessage id='submit_request.Submit another request'/>
      </span>
    </Link>
  );
});

const SuccessfullySent = observer(({uiState}) => {
  return(
    <div className={'slide-up clearfix mt4'}>
      <div className='col-6 mx-auto bg-white box-shadow-1 center py4'>
        <img src={BlueCheckmark} alt='checkmark' height='40'/>
        <div className='h2 my2'>
          <FormattedMessage id='submit_request.Submitted!'/>
        </div>
        <div className='jumbo block'>
          <FormattedMessage id='submit_request.Our Benefits Operations team will be in touch soon.'/>
        </div>
        <div className='mt3'>
          <BackToSubmitRequest uiState={uiState}/>
        </div>
      </div>
    </div>
  );
});

export default SuccessfullySent;
