import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';

const EmployeeSignUp = () => {
  return (
    <div className='clearfix my3'>
      <div className='col-12 md-col-8 mx-auto'>
        <Link to='/' className='Link-icon Link-icon--back dodger mb1'>
          <FormattedMessage id='signup.Back'/>
        </Link>
        <div className='bg-white box-shadow-1 p3 mt3'>
          <div className='pt2'>
            <div className='h1 center pb1 medium line-height-2'>
              <FormattedMessage id='signup.Check your email' />
            </div>
            <div className='h3 jumbo p3 center'>
              <div className='py1'>
                <FormattedMessage id='signup.If your company is already using Collage, you should have received an invite email with a registration link.'/>
              </div>
              <div className='py1'>
                <FormattedMessage id="signup.CHECK_EMAIL_NOTICE" values={{
                  email: <span className='tuatara medium'>{'no-reply@collage.co'}</span>
                }}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(EmployeeSignUp);
