import React from 'react';
import {observer} from 'mobx-react';
import {Checkbox} from 'components';
import {FormattedMessage} from 'react-intl';
import Field from './Field';

const Section = observer(({sectionViewModel}) => {
  return (
    <div className='px2 pt2 border-top'>
      <div className='clearfix table'>
        <div className='col-1 table-cell align-middle'>
          <Checkbox checked={sectionViewModel.checked} onChange={() => sectionViewModel.toggleAllChecked()}/>
        </div>
        <div className='col-11 table-cell align-middle'>
          <div className='h3 medium'>
            <FormattedMessage id={`reports.custom.edit.sections.${sectionViewModel.data.group}`}/>
          </div>
        </div>
      </div>
      {sectionViewModel.data.options && sectionViewModel.data.options.map((o, index) =>
        <div key={index} className='clearfix pt2'>
          <div className='col col-1'>&nbsp;</div>
          <label className='col col-11'>
            <div className='table-cell align-middle'>
              <Checkbox checked={sectionViewModel.report.employmentRecordsShowHistory}
                onChange={e => sectionViewModel.updateField(o.field, e.checked)} />
            </div>
            <div className='table-cell align-middle pl1'>
              <FormattedMessage id={`reports.custom.edit.sections.${o.title}`}/>
            </div>
          </label>
        </div>
      )}
      <div className='clearfix py2'>
        <div className='col col-1'>&nbsp;</div>
        <div className='col col-5'>
          {sectionViewModel.firstHalf.map(f => <Field key={f.data.key} fieldViewModel={f} sectionViewModel={sectionViewModel}/>)}
        </div>
        <div className='col col-6'>
          {sectionViewModel.secondHalf.map(f => <Field key={f.data.key} fieldViewModel={f} sectionViewModel={sectionViewModel}/>)}
        </div>
      </div>
    </div>
  );
});

export default Section;
