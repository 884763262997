import React from 'react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const UnarchiveProjectModal = ({uiState, modalOpen}) => {
  return (
    <Modal
      title='time_tracking.manage.projects.unarchive.Unarchive Project'
      size='sm'
      isOpen={modalOpen}
      onHide={() => uiState.closeUnarchiveProjectModal()}
    >
      <div className='mb3 jumbo'>
        <FormattedMessage id={'time_tracking.manage.projects.unarchive.DESCRIPTION'}/>
      </div>
      <ModalButtons
        saveCaption='time_tracking.manage.projects.unarchive.Unarchive'
        onSave={() => uiState.unarchiveProject()}
        onCancel={() => uiState.closeUnarchiveProjectModal()}/>
    </Modal>
  );
};

export default UnarchiveProjectModal;
