import React from 'react';
import {observer} from 'mobx-react';
import {ReorderModal} from 'components/modals';

const ReorderFoldersModal = observer(({modalOpen, uiState}) => {
  const {reorderingFolders} = uiState;

  return (
    <ReorderModal
      isOpen={modalOpen}
      onHide={() => uiState.closeReorderFoldersModal()}
      title={'company_settings.document_folders.Reorder Document Folders'}
      models={reorderingFolders}
      onSave={() => uiState.saveFolderOrders()}
      onCancel={() => uiState.closeReorderFoldersModal()}
    />
  );
});

export default ReorderFoldersModal;
