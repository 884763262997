import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {RequestTimeOffContainerState} from '../state';
import {QuickTip, Display, Layout, Panel, Button} from 'components';
import {FormattedMessage} from 'react-intl';
import RequestForm from './RequestForm';
import RequestSummary from './RequestSummary';
import Usage from './Usage';

const RequestApprovedQuicktip = observer(({request}) => {
  if (request.status === 'rejected') {
    return (
      <div className='mb2'>
        <QuickTip
          trait='info'
          header={<FormattedMessage id='employees.profile.time_off.request.Request Rejected'/>}
          paragraphs={[
            <FormattedMessage id='employees.profile.time_off.request.This time off request has been rejected and is no longer editable.'/>
          ]}
        />
      </div>
    );
  }

  return (
    <div className='mb2'>
      <QuickTip
        trait='info'
        header={<FormattedMessage id='employees.profile.time_off.request.Request Approved'/>}
        paragraphs={[
          <FormattedMessage id='employees.profile.time_off.request.This time off request has been approved and is no longer editable. To make changes, please contact your manager.'/>
        ]}
      />
    </div>
  );
});

const RequestTimeOffContainer = observer(({uiState}) => {
  const {employee, request, canSubmitRequest, loadingRequestSummary, blockedDatesEffective} = uiState;

  return (
    <div>
      <Display
        backPath={`/${employee.id}/time-off`}
        backTitle={employee.name}
        title={t(`employees.profile.time_off.${request.isNew ? 'Request Time Off' : 'Edit Time Off Request'}`)}
      />
      <Layout>
        <Layout.Section>
          {!canSubmitRequest && <RequestApprovedQuicktip request={request}/>}
          <Panel>
            <RequestForm uiState={uiState}/>
          </Panel>
          <Button.Group>
            {canSubmitRequest && <Button disabled={loadingRequestSummary || blockedDatesEffective} onClick={() => uiState.submitRequest()}>
              <FormattedMessage id={`employees.profile.time_off.request.${request.isNew ? 'Send Request' : 'Update Request'}`}/>
            </Button>}
          </Button.Group>
        </Layout.Section>
        <Layout.Section secondary>
          <Panel.Stack loose>
            <Usage uiState={uiState}/>
            <RequestSummary uiState={uiState}/>
          </Panel.Stack>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(RequestTimeOffContainer, RequestTimeOffContainerState);
