import React from 'react';
import {auth, endpoints, types, t, withState} from 'shared/core';
import {observer} from 'mobx-react';
import {InteractiveTable, DropdownFilter, Layout} from 'components';
import {BenefitEnrolmentListState} from '../state';
import {BenefitEnrolmentSummary} from 'stores/benefits';
import COLUMNS from './columns';
import _ from 'lodash';
import {withRouter} from 'react-router-dom';

const HRIS_LOCALIZATION = {
  emptyState: 'benefit_enrolment.No employees to show'
};

const ENROLMENTS_LOCALIZATION = {
  emptyState: 'benefit_enrolment.No employees to show',
  interactive: {
    addModel: 'benefit_enrolment.New Enrolment'
  }
};

const HRISBenefitEnrolmentList = observer(({uiState}) => {
  const {history} = uiState;

  return (
    <Layout>
      <InteractiveTable
        uiState={uiState}
        title={t('benefit_enrolment.Benefit Enrolments')}
        columns={COLUMNS}
        saveStateToUrl={true}
        localization={HRIS_LOCALIZATION}
        onMount={agent => uiState.setInteractiveAgent(agent)}
        history={history}
        customLinks={model => uiState.customLinksFor(model)}
        proxy={{
          type: 'async',
          modelType: types.ENROLMENT_SUMMARY,
          model: BenefitEnrolmentSummary,
          endpoint: endpoints.BENEFIT_ENROLMENTS.DIRECTORY
        }}>
        {!_.isEmpty(uiState.carrierOptions) && <DropdownFilter options={uiState.carrierOptions} attr='carrier'/>}
        <DropdownFilter options={uiState.statusOptions} attr='employee_status'/>
      </InteractiveTable>
    </Layout>
  );
});

const HQBenefitEnrolmentList = observer(({uiState}) => {
  return (
    <Layout>
      <InteractiveTable
        uiState={uiState}
        title={t('benefit_enrolment.Benefit Enrolments')}
        columns={COLUMNS}
        saveStateToUrl={true}
        localization={ENROLMENTS_LOCALIZATION}
        onAdd={() => uiState.navigateToCreateEnrolment()}
        onMount={agent => uiState.setInteractiveAgent(agent)}
        customLinks={model => uiState.customLinksFor(model)}
        proxy={{
          type: 'async',
          modelType: types.ENROLMENT_SUMMARY,
          model: BenefitEnrolmentSummary,
          endpoint: endpoints.BENEFIT_ENROLMENTS.DIRECTORY
        }}>
        {!_.isEmpty(uiState.carrierOptions) && <DropdownFilter options={uiState.carrierOptions} attr='carrier'/>}
        <DropdownFilter options={uiState.statusOptions} attr='employee_status'/>
      </InteractiveTable>
    </Layout>
  );
});

const BenefitEnrolmentList = auth.moduleEnabled('directory') ? HRISBenefitEnrolmentList : HQBenefitEnrolmentList;

export default withRouter(withState(BenefitEnrolmentList, BenefitEnrolmentListState));
