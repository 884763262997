import React from 'react';
import {observer} from 'mobx-react';
import {TrainingList} from './training_list';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {Panel} from 'components';

const Title = observer(() => {
  return (
    <FormattedMessage
      id='employees.hire.training.ONBOARDING_TRAINING_HEADER'
      values={{
        optional: <span className='waterloo'>{t('employees.hire.training.(Optional)')}</span>
      }}
    />
  );
});

const Training = observer(({uiState}) => {
  const {employee} = uiState;
  
  return (
    <div>
      <Panel.Header
        title={<Title/>}
        description={<FormattedMessage id='employees.hire.training.TRAINING_DESC' values={{employee: employee.name}}/>}
      />
      <TrainingList
        onChange={(programs) => uiState.setSelectedTraining(programs)}
        employeeId={uiState.employee.id}
        trainingPrograms={uiState.trainingPrograms}
      />
    </div>
  );
});

export default Training;
