import React from 'react';
import {observer} from 'mobx-react';
import {Panel, DragAndDrop} from 'components';
import {t} from 'shared/core';
import DraggableFieldContent from './DraggableFieldContent';

const OrderFields = observer(({uiState}) => {
  const {report} = uiState;

  return (
    <div>
      <Panel.Header 
        title={t('reports.custom.edit.Field order')}
        description={t('reports.custom.edit.Drag and drop the fields using the handles to specify the order.')} />
      <DragAndDrop models={report.columns} component={DraggableFieldContent}/>
    </div>
  );
});

export default OrderFields;
