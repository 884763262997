import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {PayrollDatesState} from '../state';
import {FormattedMessage} from 'react-intl';
import {calendarDate} from 'shared/tools';
import {Table, Panel, EmojiTitle} from 'components';

const PayrollDate = observer(({model}) => {
  return (
    <div>
      <div className={model.colour}>
        {model.daysFromNow < 0 ?
            <FormattedMessage id='hr_dashboard.payroll_dates.days_ago' values={{daysFromNow: Math.abs(model.daysFromNow)}}/> :
            <FormattedMessage id='hr_dashboard.payroll_dates.days_from_now' values={{daysFromNow: model.daysFromNow}}/>}
      </div>
      <div className='waterloo'>
        {calendarDate(model.date)}
      </div>
    </div>
  );
});

const COLUMNS = [
  {
    width: 7,
    render: model => model.dateName
  },
  {
    width: 5,
    component: PayrollDate
  }
];
  
const PayrollDates = observer(({uiState}) => {
  if (!uiState.payPeriod) {
    return null;
  }

  const {payPeriodToArray, Emoji} = uiState;

  return (
    <Panel className='flex flex-wrap'>
      <Panel.Header title={<EmojiTitle Emoji={Emoji} emojiKey='date' title={t('hr_dashboard.payroll_dates.Important Payroll Dates')}/>}/>
      <Table
        columns={COLUMNS}
        showLinks={false}
        showHeaders={false}
        indexBy='dateName'
        models={payPeriodToArray}
      />
    </Panel>
  );
});

export default withState(PayrollDates, PayrollDatesState);
