const config = {
  title: null,
  legend: { enabled: false },
  credits: { enabled: false },
  colors: [
    '#0e3276',
    '#593583',
    '#8f3385',
    '#be327b',
    '#e23e69',
    '#fa5a50',
    '#ff7e33',
    '#DADA13',
    '#78C712',
    '#2EA270',
    '#2B8790'
  ],
  chart: {
    type: 'column',
    inverted: true,
    spacingRight: -20,
    spacing: [
      0, 0, 0, 0
    ],
    height: 600
  },
  plotOptions: { 
    column: {
      colorByPoint: true,
      pointPadding: 0,
      minPointLength: 2,
      groupPadding: 0,
      borderWidth: 0.5,
      maxPointWidth: 20
    }
  },
  yAxis: {
    title: {
      text: null
    },
    allowDecimals: false
  }
};

export default config;
