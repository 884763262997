const exporting = {
  fallbackToExportServer: false,
  allowHTML: false,
  enabled: false,
  sourceWidth: 960,
  sourceHeight: 540,
  chartOptions: {
    chart: {
      height: 500,
      spacingTop: 150,
      spacingRight: 200,
      spacingLeft: 200
    }
  }
};

function performanceTrendsOptions(trend) {
  return {
    title: null,
    chart: {
      height: 500,
      spacingTop: 50,
      spacingRight: 20
    },
    legend: {
      maxHeight: 77,
      itemStyle: {
        fontSize: '14px',
        fontFamily: 'GT Walsheim, sans-serif',
        color: '#212B36',
        fontWeight: '200'
      }
    },
    yAxis: {
      title: {
        enabled: false
      },
      min: 1,
      max: 5,
      tickInterval: 1
    },
    xAxis: {
      type: 'datetime',
      tickPositions: trend.cycles.length === 1 ? [] : undefined,
      plotLines: trend.cycles.map((cycle, i) => {
        return {
          value: Date.parse(cycle.launchedAt),
          label: {
            text: `<div style="max-width: 120px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">${cycle.name}</div>`,
            useHTML: true,
            rotation: i + 1 < trend.cycles.length ? -20 : 90,
            y: i + 1 < trend.cycles.length ? -4 : 8,
            x: i + 1 < trend.cycles.length ? -5 : 4
          },
          color: '#999999',
          width: 1,
          dashStyle: 'Dash'
        };
      })
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        marker: {
          symbol: 'circle',
          fillColor: '#FFFFFF',
          enabled: true,
          radius: 2.5,
          lineWidth: 1,
          lineColor: null
        }
      }
    },
    exporting
  };
}

export default performanceTrendsOptions;
