import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {EmployeeSelector, FormError} from 'components';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalButtons} from 'components/modals';

const AddEmployeesModal = observer(({uiState}) => {
  const {selectedEmployees, addEmployeeErrors} = uiState;

  return (
    <Modal
      size='sm'
      title={t('mass_updates.edit.Add Employees')}
      isOpen={uiState.addEmployeesModalOpen}
      onHide={() => uiState.closeAddEmployeesModal()}
    >
      <FormattedMessage id='mass_updates.edit.Choose the employees whose salary you would like to update.'/>
      <EmployeeSelector
        selectors={['location', 'department', 'manager', 'employee']}
        selectedEmployees={selectedEmployees}
        onChange={employees => uiState.selectedEmployees = employees}
      />
      <FormError message={addEmployeeErrors.base} />
      <ModalButtons
        onSave={() => uiState.addEmployeesToMassUpdate()}
        onCancel={() => uiState.closeAddEmployeesModal()}
      />
    </Modal>
  );
});

export default AddEmployeesModal;
