import {DomainStore} from 'shared/store';
import {endpoints, types, t, auth} from 'shared/core';
import {action, observable, computed} from 'mobx';
import {BenefitInfo} from 'stores/benefits';
import {Employee} from 'stores/employees';
import CompanyInsuranceCarrier from 'stores/benefits/CompanyInsuranceCarrier';
import {successAlert, redirect} from 'shared/tools';
import _ from 'lodash';

class BenefitInfoContainerState {
  store = new DomainStore();
  scope;
  employeeId;
  changeRequestRedirectUrl;

  @observable benefitInfo;
  @observable companyInsuranceCarriers;
  @observable employee;
  @observable errors = {};

  receiveProps({employeeId, changeRequestRedirectUrl}) {
    this.employeeId = employeeId;
    this.changeRequestRedirectUrl = changeRequestRedirectUrl || '/benefits/benefit_info/:id/edit';
  }

  @action async load() {
    const requests = [
      endpoints.BENEFIT_INFO.with(this.employeeId),
      endpoints.BENEFITS.NAVIGATION,
      endpoints.COMPANY_INSURANCE_CARRIERS.WITH_OPTIONAL,
      endpoints.EMPLOYEE_DETAILS.with(this.employeeId)
    ];

    await this.store._compose(requests);

    this.benefitInfo = new BenefitInfo(
      this.store._getSingle(types.BENEFIT_INFO)
    );
    this.scope = this.store._getSingle(types.NAVIGATION_SCOPE).tabs;

    this.companyInsuranceCarriers = this.store._getAll(
      types.COMPANY_INSURANCE_CARRIER, CompanyInsuranceCarrier
    );
    this.employee = new Employee(this.store._getSingle(types.EMPLOYEE_DETAILS));
  }

  @action async updateBenefitInfo() {
    const {model, errors} = await this.store.patch(
      endpoints.BENEFIT_INFO.with(this.benefitInfo.employeeId),
      types.BENEFIT_INFO,
      this.benefitInfo
    );

    this.errors = errors;

    if (model) {
      this.benefitInfo.update(model);
      successAlert(t('benefit_enrolment.alerts.Updated benefits enrolment information'));
    }
  }

  @action async createChangeRequest() {
    const {model, errors} = await this.store.post(
      endpoints.BENEFITS.BENEFIT_INFO_CHANGE_REQUESTS,
      types.BENEFITS.BENEFIT_INFO_CHANGE_REQUEST,
      { employeeId: this.employeeId }
    );
    this.errors = errors;

    if (model) {
      return redirect(this.changeRequestRedirectUrl.replace(':id', model.id));
    }
  }

  @computed get editable() {
    return this.scope.benefitEnrolments;
  }

  get isSelf() {
    return auth.employee.id === this.employeeId;
  }
}

export default BenefitInfoContainerState;
