import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {Display, Panel, Layout, ActionLink, LexicalEditor} from 'components';
import {ModalButtons} from 'components/modals';
import CompanyEmailTemplateEditState from '../state/CompanyEmailTemplateEditState';

const EmailTemplateVersion = observer(({uiState}) => {
  const {currentVersionLexicalState, currentVersionContent, emailTemplateVersion} = uiState;

  return (
    <Panel>
      <Panel.Header title={emailTemplateVersion.panelHeader}/>
      <div>
        <LexicalEditor
          placeholder={'company_settings.company_email_templates.Add email content'}
          onChange={content => uiState.updateContent(content)}
          mergeFields={uiState.currentMergeFields}
          initialEditorState={currentVersionLexicalState}
          initialHTML={currentVersionContent}
          key={emailTemplateVersion.id}
          disableStyling
        />
      </div>
      <ModalButtons
        onSave={() => uiState.updateCompanyEmailTemplate()}
        onCancel={() => uiState.goBack()}
      />
    </Panel>
  );
});

const CompanyEmailTemplateEditContainer = observer(({uiState}) => {
  const {emailTemplateVersions} = uiState;

  return (
    <div>
      <Display
        title={t(`company_settings.company_email_templates.${uiState.currentTemplate.emailType}`)}
        backTitle={t('company_settings.company_email_templates.Email Templates')}
        backPath='/company_email_templates'
      >
        {emailTemplateVersions.map((version) =>
          <ActionLink
            className={uiState.activateActionLink(version)}
            key={version.id}
            onClick={() => uiState.switchVersion(version)
          }>
            {version.tabHeader}
          </ActionLink>
        )}
      </Display>
      <Layout>
        <div>
          <EmailTemplateVersion uiState={uiState}/>
        </div>
      </Layout>
    </div>
  );
});

export default withState(CompanyEmailTemplateEditContainer, CompanyEmailTemplateEditState);
