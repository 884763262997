import React from 'react';
import {observer} from 'mobx-react';
import {EmployeeCustomFields} from 'components';
import CustomField from 'components/CustomField';
import _ from 'lodash';

const OnboardEmployeeCustomFieldsForm = observer(({uiState}) => {
  const {employee, errors} = uiState;

  return (
    <EmployeeCustomFields employeeCustomFields={employee.employeeCustomFields} category='personal' errors={errors} useFormLayout>
      {(employeeCustomField, error) => (
        <CustomField
          customField={employeeCustomField}
          key={employeeCustomField.id}
          errorMessage={error ? error.value : null}
        />
      )}
    </EmployeeCustomFields>
  );
});

export default OnboardEmployeeCustomFieldsForm;
