import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const CloseProgramModal = observer(({uiState}) => {
  return (
    <Modal
      title='training.directory.close_program.Close Program'
      size='sm'
      isOpen={uiState.closeProgramModalOpen} 
      onHide={() => uiState.exitCloseProgramModal()}
    >
      <div className='mb3 jumbo'>
        <FormattedMessage id={'training.directory.close_program.Closed training programs will no longer be actionable but the results will remain visible to admins.'}/>
      </div>
      <ModalButtons
        saveCaption='training.directory.close_program.Close'
        onSave={() => uiState.closeProgram()}
        onCancel={() => uiState.exitCloseProgramModal()}/>
    </Modal>
  );
});

export default CloseProgramModal;
