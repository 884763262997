import React from 'react';
import {observer} from 'mobx-react';

const TableHeader = observer(({children}) => {
  return (
    <div className='TableHeader'>
      {children}
    </div>
  );
});

export default TableHeader;
