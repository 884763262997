import {observable, action, computed} from 'mobx';
import {QAAnswer} from 'stores/questions';
import AttachedDocument from 'stores/documents/AttachedDocument';
import Question from 'stores/recruiting/Question';
import _ from 'lodash';

class Answer extends QAAnswer {
  @observable id;
  @observable content;
  @observable attachment;

  @action merge(other) {
    super.merge(other, {
      attachment: AttachedDocument,
      question: Question
    });
  }

  @action removeFile() {
    this.attachment = null;
  }

  @action mergeFile(file) {
    this.merge({attachment: file});
  }

  @action updateFile(files) {
    const file = _.head(files);
    file ? this.mergeFile(file) : this.removeFile();
  }

  @action updateValue(value) {
    this.content = value;
  }

  @computed get value() {
    return this.content;
  }

  asArray() {
    return this.content || [];
  }
}

export default Answer;
