import React from 'react';
import {observer} from 'mobx-react';

const Tooltip = observer(({content, children}) => {
  return (
    <div className='Tooltip'>
      {children}
      {content && <span className='tooltiptext dateTooltip'>
        {content}
      </span>}
    </div>
  );
});

export default Tooltip;
