import React from 'react';
import {observer} from 'mobx-react';
import {Select2} from 'components';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';
import _ from 'lodash';

const SelectField = observer(({field, options, allowClear, viewModel}) => {
  const {uiState} = viewModel;

  if (uiState.massUpdate.readOnly && !viewModel.fields.get(field).has('newValue')) {
    return (
      <div className='waterloo'>
        <FormattedMessage id='mass_updates.edit.Not Changed'/>
      </div>
    );
  }

  const previousValue = viewModel.get(field).get('oldValue') ?
    _.find(options, {id: viewModel.get(field).get('oldValue')}).name :
    '--';

  return (
    <div>
      <Select2
        searchable
        allowClear={allowClear}
        value={viewModel.get(field).get('value')}
        disabled={viewModel.disabled}
        onChange={e => viewModel.set(field, e.target.value)}
      >
        {options.map(option => (
          <option key={option.id} value={option.id}>{t(option.name)}</option>
        ))}
      </Select2>
      <div className='jumbo mt1'>
        <FormattedMessage
          id={viewModel.disabled ? 'mass_updates.edit.PREVIOUS_VALUE' : 'mass_updates.edit.CURRENT_VALUE'}
          values={{
            value: t(previousValue)
          }}
        />
      </div>
    </div>
  );
});

export default SelectField;
