import React from 'react';
import {carrierAssetKey} from './tools';

const CarrierIcon = ({carrier}) => {
  return (
    <div className={`ProviderIcon ProviderIcon--${carrierAssetKey(carrier)}`}></div>
  );
};

export default CarrierIcon;
