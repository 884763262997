import {action} from 'mobx';
import {DomainStore} from 'shared/store';
import {successAlert} from 'shared/tools';
import {t} from 'shared/core';
import _ from 'lodash';

class LeaveTypeDirectoryState {
  store = new DomainStore();
  history;

  receiveProps({history}) {
    this.history = history;
  }

  @action async removeLeaveType(leaveType) {
    await this.store.destroy(leaveType);
    successAlert(t('company_settings.leave_types.Leave type deleted'));
  }

  @action goToEditLeaveType(leaveType) {
    const id = _.get(leaveType, 'id', '');
    this.history.push(`/leave_types/edit/${id}`);
  }
}

export default LeaveTypeDirectoryState;
