import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import EmployeeTableSelectorState from '../state/EmployeeTableSelectorState';
import {Spinner, FormLayout, FormError} from 'components';
import TransferButtons from './TransferButtons';

const EmployeeSelector = ({title, employees, values, onSelectionChange, emptyStateMessage}) => (
  <div className="col-5 table-cell" style={{height: '394px'}}>
    <div className='h3 mb2'>{title}</div>
    {employees.length > 0 ? (
      <div className="border-none box-shadow-1 relative">
        <select
          className="full-width full-height border-none"
          style={{maxHeight: '384px'}}
          multiple
          size="8"
          value={values}
          onChange={(e) => onSelectionChange(Array.from(e.target.selectedOptions))}
        >
          {employees.map(employee => (
            <option className='h5 regular border-bottom py2 pl3' key={employee.id} value={employee.id}>
              {employee.name}
            </option>
          ))}
        </select>
      </div>
    ) : (
      <div className="full-width border-none box-shadow-1 relative" style={{height: '386px'}}>
        <div className="abs-center center">
          <div className="h3 jumbo">{emptyStateMessage}</div>
        </div>
      </div>
    )}
  </div>
);

const EmployeeTableSelector = observer(({uiState}) => {
  const {
    canAddAll,
    canRemoveAll,
    canAdd,
    canRemove,
    errorMessage,
    selectedEmployeeIds,
    selectedAvailableEmployeeIds,
  } = uiState;

  return (
    <FormLayout>
      <div className="clearfix table mt2">
        <EmployeeSelector
          title={t('components.employee_table_selector.Available Employees')}
          employees={uiState.availableEmployees}
          values={selectedAvailableEmployeeIds}
          onSelectionChange={(selectedOptions) => uiState.updateSelectedAvailableEmployeeIds(selectedOptions)}
          emptyStateMessage={t('components.employee_table_selector.No available employees')}
        />
        <div className='col-2 table-cell align-middle center'>
          <TransferButtons
            uiState={uiState}
            canAddAll={canAddAll}
            canRemoveAll={canRemoveAll}
            canAdd={canAdd}
            canRemove={canRemove}
          />
        </div>
        <EmployeeSelector
          title={t('components.employee_table_selector.Selected Employees')}
          employees={uiState.selectedEmployees}
          values={selectedEmployeeIds}
          onSelectionChange={(selectedOptions) => uiState.updateSelectedEmployeeIds(selectedOptions)}
          emptyStateMessage={t('components.employee_table_selector.No selected employees')}
        />
      </div>
      <FormError message={errorMessage}/>
    </FormLayout>
  );
});

export default withState(EmployeeTableSelector, EmployeeTableSelectorState, {
  spinner: Spinner
});