import {action, observable} from 'mobx';
import {TaskTemplate} from 'stores/tasks';
import {DomainStore} from 'shared/store';
import {redirect, successAlert} from 'shared/tools';
import {types, endpoints, t} from 'shared/core';
import {onboarding, offboarding, TaskTemplateForm} from 'components/tasks';
import _ from 'lodash';
import {EmployeeGroup} from 'stores/employee_groups';

class TaskEditState {
  store = new DomainStore();
  history;
  match;
  config;

  @observable taskTemplate;
  @observable taskTemplateForm;
  @observable employees;
  @observable employeeGroups;
  @observable errors = {};

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
    this.config = this.match.params.taskType === 'onboarding' ? onboarding : offboarding;
  }

  @action async load() {
    const endpointUrls = [
      endpoints.EMPLOYEES.ALL,
      endpoints.EMPLOYEE_GROUPS
    ];
    const taskTemplateId = _.get(this.match.params, 'id');

    if (taskTemplateId) {
      endpointUrls.push(endpoints.TASK_TEMPLATE.with(taskTemplateId));
    }

    await this.store._compose(endpointUrls);

    this.employees = this.store.getEmployees();
    this.taskTemplate = new TaskTemplate(
      this.store._getSingle(types.TASK_TEMPLATE, { id: taskTemplateId })
    );
    this.taskTemplate.merge({templateType: this.config.templateType});
    this.taskTemplateForm = new TaskTemplateForm(this.taskTemplate, this.employees);
    this.employeeGroups = this.store._getAll(types.EMPLOYEE_GROUP, EmployeeGroup);
  }

  @action async employeeGroupSelected(groupId) {
    if (groupId === 'createNewEmployeeGroup') {
      redirect('/company_settings/employee_groups/new/');
    } else {
      this.taskTemplate.merge({employeeGroupId: groupId});
    }
  }

  @action async saveTaskTemplate() {
    const {model, errors} = this.taskTemplate.isNew ?
      await this.postTaskTemplate() :
      await this.patchTaskTemplate();
    this.errors = errors;

    if (model) {
      this.taskTemplate.update(model);
      this.goBack();
      successAlert(t('company_settings.tasks.alerts.Task template updated.'));
    }
  }

  @action async patchTaskTemplate() {
    return await this.store.patch(this.taskTemplate);
  }

  @action async postTaskTemplate() {
    return await this.store.post(
      endpoints.TASK_TEMPLATES.all,
      types.TASK_TEMPLATE,
      this.taskTemplate
    );
  }

  goBack() {
    this.history.push(`/tasks/${this.config.templateType}`);
  }
}

export default TaskEditState;
