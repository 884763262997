import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Display} from 'components';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

const ReportsSubnav = observer(({scope}) => {
  return (
    <Display title={t('reports.Reports')}>
      {scope.standardReports && <NavLink exact activeClassName='active' to='/'>
        <FormattedMessage id='reports.Standard Reports'/>
      </NavLink>}
      {scope.custom && <NavLink activeClassName='active' to='/custom'>
        <FormattedMessage id='reports.Custom Reports'/>
      </NavLink>}
    </Display>
  );
});

export default ReportsSubnav;
