import {observable, observe, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, t} from 'shared/core';
import {Rule} from 'stores/segmentation';
import EMPLOYMENT_TYPES from 'stores/employees/employmentTypes';
import _ from 'lodash';

class SegmentSelectorState {
  store = new DomainStore();

  @observable employees;
  @observable locations;
  @observable departments;
  @observable errors = {};

  @observable segment;
  @observable selectedLocations = [];
  @observable selectedDepartments = [];
  @observable selectedEmploymentTypes = [];

  receiveProps({onChange, segment}) {
    this.onChange = onChange;
    this.segment = segment;

    this.segment.merge({
      rules: [
        this.segment.locationRule || new Rule({ruleType: 'location'}),
        this.segment.departmentRule || new Rule({ruleType: 'department'}),
        this.segment.employmentTypeRule || new Rule({ruleType: 'employment_type'})
      ]
    });
  }

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.LOCATIONS.ALL,
      endpoints.DEPARTMENTS
    ]);

    this.employees = this.store.getEmployees();
    this.locations = this.store.getLocations();
    this.departments = this.store.getDepartments();
    this.initSelectedValues();

    observe(this, 'segment', () => this.onChange(this.segment), true);
  }

  @action initSelectedValues() {
    this.selectedLocations = _.filter(this.locations, l => _.includes(this.segment.locationRule.parameters, l.id));
    this.selectedDepartments = _.filter(this.departments, d => _.includes(this.segment.departmentRule.parameters, d.id));
    this.selectedEmploymentTypes = _.filter(this.employmentTypes, c => _.includes(this.segment.employmentTypeRule.parameters, c.id));
  }

  @action addLocation(location) {
    this.selectedLocations.push(location);
    this.segment.locationRule.parameters.push(location.id);
  }

  @action removeLocation(location) {
    this.selectedLocations.remove(location);
    this.segment.locationRule.parameters.remove(location.id);
  }

  @action addDepartment(department) {
    this.selectedDepartments.push(department);
    this.segment.departmentRule.parameters.push(department.id);
  }

  @action removeDepartment(department) {
    this.selectedDepartments.remove(department);
    this.segment.departmentRule.parameters.remove(department.id);
  }

  @action onEmploymentTypeChange(e) {
    if (e.action === 'add') {
      this.addEmploymentType(e.value);
    } else if (e.action === 'remove') {
      this.removeEmploymentType(e.value);
    }
  }

  @action addEmploymentType(employmentType) {
    this.selectedEmploymentTypes.push(employmentType);
    this.segment.employmentTypeRule.parameters.push(employmentType.id);
  }

  @action removeEmploymentType(employmentType) {
    this.selectedEmploymentTypes = _.reject(this.selectedEmploymentTypes, {id: employmentType.id});
    this.segment.employmentTypeRule.parameters.remove(employmentType.id);
  }

  @computed get employeeCount() {
    return _.union(
      _.filter(this.employees, e => _.includes(_.map(this.selectedLocations, 'id'), e.location.id)),
      _.filter(this.employees, e => !!e.department && _.includes(_.map(this.selectedDepartments, 'id'), e.department.id)),
      _.filter(this.employees, e => _.includes(_.map(this.selectedEmploymentTypes, 'id'), e.employmentType))
    ).length;
  }

  @computed get employmentTypes() {
    return EMPLOYMENT_TYPES.map(type => {
      return {id: type, name: t(type)};
    });
  }
}

export default SegmentSelectorState;
