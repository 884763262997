import React from 'react';
import {observer} from 'mobx-react';
import Subnav from 'benefits/components/Subnav';
import {BenefitInfoContainer} from 'components/benefits';
import {auth} from 'shared/core';
import {Layout} from 'components';

const MyEnrolmentInfo = observer(({scope}) => {
  return (
    <div>
      <Subnav scope={scope}/>
      <Layout>
        <BenefitInfoContainer employeeId={auth.employee.id}/>
      </Layout>
    </div>
  );
});

export default MyEnrolmentInfo;
