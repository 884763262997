import React from 'react';
import {observer} from 'mobx-react';
import FillablePdfPreview from './FillablePdfPreview';

const PdfViewer = observer(({uiState}) => {
  const {pageNumber, currentDocument, fillableAnnotations} = uiState;
  const {companyDocument} = currentDocument;

  return (
    <FillablePdfPreview
      onPageNumberUpdated={(pageNumber) => uiState.onPageNumberUpdated(pageNumber)}
      onEmployeeSignatureUpdated={signatureData => uiState.onEmployeeSignatureUpdated(signatureData)}
      pageNumber={pageNumber}
      pdfUrl={companyDocument.previewFile.link('url')}
      companyDocument={companyDocument}
      annotations={fillableAnnotations}
    />
  );
});

export default PdfViewer;
