import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const LoginFormHeader = observer(({children, message}) => {

  return (
    <div>
      {children}
      <div className='h1 center medium pb3 pt1'>
        <div className='pb1'>
          <FormattedMessage id={message} />
        </div>
      </div>
    </div>
  );
});

export default LoginFormHeader;
