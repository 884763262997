import React from 'react';
import {observer} from 'mobx-react';
import {ModalButtons, AlertModal} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import {t, auth} from 'shared/core';

const BodyMessage = observer(({uiState}) => {
  if (auth.hasAccess('::MANAGE_BENEFITS')) {
    return (
      <div>
        <FormattedMessage id='employees.profile.ON_LEAVE_BENEFITS_DESCRIPTION'/>
        <a href={`/benefits/benefit_enrolments/${uiState.employeeId}/unenrol`}>
          <FormattedMessage id='employees.profile.ON_LEAVE_BENEFITS_LINK'/>
        </a>
      </div>
    );
  }

  return (
    <div>
      <FormattedMessage id='employees.profile.ON_LEAVE_BENEFITS_DESCRIPTION'/>
      <FormattedMessage id='employees.profile.ON_LEAVE_CONTACT_PLAN_ADMIN'/>
    </div>
  );
});

const OnLeaveWithBenefitsModal = observer(({uiState}) => {
  return (
    <AlertModal
      mode='danger'
      size='sm'
      subHeader={t('employees.profile.Benefits')}
      body={<BodyMessage uiState={uiState}/>}
      onHide={() => uiState.closeOnLeaveModal()}
      isOpen={uiState.onLeaveModalOpen}
      translate={false}
    >
      <ModalButtons
        saveCaption='employees.profile.Next'
        onCancel={() => uiState.closeOnLeaveModal()}
        onSave={() => uiState.placeOnLeave()}
      />
    </AlertModal>
  );
});

export default OnLeaveWithBenefitsModal;
