import React from 'react';
import {observer} from 'mobx-react';
import {withState, types, endpoints} from 'shared/core';
import {EnrolmentsContainerState} from '../state';
import COLUMNS from 'benefits/components/benefit_enrolments/components/directory/components/AdvisorPortalColumns';
import {BenefitEnrolmentSummary} from 'stores/benefits';
import {Layout, InteractiveTable, DropdownFilter} from 'components';
import _ from 'lodash';

const EnrolmentsContainerWithState = observer(({uiState}) => {
  const {parentState, carrierOptions, statusOptions} = uiState;
  const {match} = parentState;

  return (
    <Layout>
      <InteractiveTable
        columns={COLUMNS}
        showLinks={false}
        saveStateToUrl={true}
        proxy={{
          type: 'async',
          modelType: types.ENROLMENT_SUMMARY,
          model: BenefitEnrolmentSummary,
          endpoint: endpoints.BROKER_PORTAL.ENROLMENT_SUMMARIES.withFilter(
            match.params.id
          )
        }}
      >
        {!_.isEmpty(carrierOptions) && <DropdownFilter options={carrierOptions} attr='carrier'/>}
        <DropdownFilter options={statusOptions} attr='employee_status'/>
      </InteractiveTable>
    </Layout>
  );
});

export default withState(EnrolmentsContainerWithState, EnrolmentsContainerState);
