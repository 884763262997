import React from 'react';
import {observer} from 'mobx-react';
import {Input, EmployeeSelector, FormLayout, DatePicker, ObjectSelect2} from 'components';
import {Modal, ModalButtons} from 'components/modals';
import {t} from 'shared/core';

const EditBlockedDatesModal = observer(({uiState, modalOpen}) => {
  const {errors, editingBlockedDates, timeOffTypes} = uiState;

  return (
    <Modal
      isOpen={modalOpen}
      onHide={() => uiState.closeEditBlockedDatesModal()}
      size='md'
      title={editingBlockedDates.isNew ? 'time_off.blocked_dates.Create Blocked Dates' : 'time_off.blocked_dates.Edit Blocked Dates'}
    >
      <FormLayout>
        <Input
          label='time_off.blocked_dates.Name'
          value={editingBlockedDates.name}
          errorMessage={errors.name}
          onChange={(e) => editingBlockedDates.merge({name: e.target.value})}
        />
        <FormLayout.Group>
          <DatePicker
            label='time_off.blocked_dates.Start Date'
            value={editingBlockedDates.startDate}
            onChange={date => editingBlockedDates.merge({startDate: date})}
            errorMessage={errors.startDate}
            options={{endDate: !!editingBlockedDates.endDate ? editingBlockedDates.endDate : null}}
          />
          <DatePicker
            label='time_off.blocked_dates.End Date'
            value={editingBlockedDates.endDate}
            onChange={date => editingBlockedDates.merge({endDate: date})}
            errorMessage={errors.endDate}
            options={{startDate: !!editingBlockedDates.startDate ? editingBlockedDates.startDate : null}}
          />
        </FormLayout.Group>
        <ObjectSelect2
          label='time_off.blocked_dates.Types'
          multiple
          value={editingBlockedDates.types}
          onAdd={type => uiState.addType(type)}
          onRemove={type => uiState.removeType(type)}
          items={timeOffTypes}
          errorMessage={errors.types}
        />
        <EmployeeSelector
          label={t('time_off.blocked_dates.Employees')}
          selectedEmployees={editingBlockedDates.employees}
          onChange={employees => uiState.editingBlockedDates.employees = employees}
        />
      </FormLayout>
      <ModalButtons onSave={() => uiState.saveBlockedDates()} onCancel={() => uiState.closeEditBlockedDatesModal()} />
    </Modal>
  );
});

export default EditBlockedDatesModal;
