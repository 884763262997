import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, AttachedDocumentDownload} from 'components';
import _ from 'lodash';

const Documents = observer(({currentClass}) => {
  const {attachedDocuments} = currentClass;
  if (_.isEmpty(attachedDocuments)) return null;

  return (
    <Panel>
      <Panel.Header title={t('components.coverages_list.Resources')}/>
      {attachedDocuments.map(doc => 
        <div key={doc.id} className='border rounded mb1 py2 pl1'>
          <AttachedDocumentDownload name={doc.title} doc={doc} />
        </div>)}
    </Panel>
  );
});

export default Documents;

