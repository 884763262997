import {action, observable} from 'mobx';
import CompanyDocumentAssignment from 'stores/documents/CompanyDocumentAssignment';
import {endpoints, types} from 'shared/core';
import {DomainStore} from 'shared/store';

class AddAssigneesModalState {
  store = new DomainStore();
  companyDocument;
  onSuccess;

  @observable selectEmployeesModalOpen = false;
  @observable companyDocumentAssignment;
  @observable selectedEmployees = [];
  @observable errors = [];
  @observable loading = false;

  constructor(companyDocument, onSuccess) {
    this.companyDocument = companyDocument;
    this.companyDocumentAssignment = new CompanyDocumentAssignment({companyDocument});
    this.onSuccess = onSuccess;
  }

  @action async load() {
    this.loading = true;
    await this.store._compose([
      endpoints.EMPLOYEES.ALL
    ]);
    this.loading = false;
  }

  @action toggleSelectAllEmployees(value) {
    this.companyDocumentAssignment.selectAllEmployees = value;
    if (value) {
      this.companyDocumentAssignment.employees = [];
    }
  }

  @action setDueDate(dueDate) {
    this.companyDocumentAssignment.dueDate = dueDate;
  }

  @action async saveAssignees() {
    this.companyDocumentAssignment.employees = [];
    this.selectedEmployees.forEach(item => this.companyDocumentAssignment.employees.push(item));
    const {model, errors} = await this.store.post(
      endpoints.DOCUMENT_ACTIONS.with(this.companyDocumentAssignment.companyDocument.id),
      types.COMPANY_DOCUMENT_ASSIGNMENT,
      this.companyDocumentAssignment
    );
    this.errors = errors;

    if (model) {
      this.onSuccess && this.onSuccess();
    }
  }
}

export default AddAssigneesModalState;
