import React from 'react';
import {observer} from 'mobx-react';
import {BenefitEnrolmentForCarrier} from 'components/benefits';
import _ from 'lodash';
import {withState} from 'shared/core';
import BenefitEnrolmentListState from './BenefitEnrolmentListState';
import {Panel} from 'components';

const BenefitEnrolmentList = observer(({uiState, errors}) => {
  const {enrollmentViewModels} = uiState;

  return (
    <div>
      {enrollmentViewModels.map((viewModel, index) =>
        <div key={viewModel.record.companyInsuranceCarrier.id}>
          <Panel.Separator/>
          <BenefitEnrolmentForCarrier
            viewModel={viewModel}
            enrolmentErrors={_.get(errors, `employeeBenefitEnrollments[${index}]`)}
            insuranceNumberErrors={_.get(errors, `employeeInsuranceNumbers[${viewModel.insuranceNumberIndex}]`)}
            allowNotEligible
            showEnrolmentDate={false}
          />
        </div>
      )}
    </div>
  );
});

export default withState(BenefitEnrolmentList, BenefitEnrolmentListState);
