import {observable, action} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints, t} from 'shared/core';
import {Company} from 'stores/company';
import {successAlert} from 'shared/tools';

class JazzHRConfigurationContainerState {
  store = new DomainStore();
  @observable company;
  @observable errors = {};

  @action async load() {
    await this.store._compose([
      endpoints.CURRENT_COMPANY
    ]);

    this.company = new Company(
      this.store._getSingle(types.COMPANY)
    );
  }

  @action async saveCompany() {
    const {model, errors} = await this.store.patch(
      endpoints.COMPANY.with(this.company.id),
      types.COMPANY,
      this.company
    );
    this.errors = errors;
    this.company.update(model);
    if (model) {
      successAlert(t('company_settings.applications.Configuration updated.'));
    }
  }
}

export default JazzHRConfigurationContainerState;
