import React from 'react';
import {observer} from 'mobx-react';
import {Panel, HtmlRender} from 'components';
import {ReviewRow} from 'components/flow';
import {t} from 'shared/core';

const Review = observer(({uiState}) => {
  const {match, trainingProgram} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('training.edit.Review your training program')}
        description={t('training.edit.REVIEW_DESCRIPTION')}
      />
      <ReviewRow
        title='training.edit.Training Name'
        value={trainingProgram.name}
        editRoute={`/admin/programs/${match.params.id}/edit/overview`}
      />
      <ReviewRow
        title='training.edit.Available in Hiring'
        value={trainingProgram.formattedAvailableInHiring}
        editRoute={`/admin/programs/${match.params.id}/edit/overview`}
      />
      <ReviewRow
        title='training.edit.Due Date'
        value={uiState.dueDate}
        editRoute={`/admin/programs/${match.params.id}/edit/overview`}
      />
      <ReviewRow
        title='training.edit.Description'
        value={<HtmlRender html={trainingProgram.description}/>}
        editRoute={`/admin/programs/${match.params.id}/edit/overview`}
      />
      <ReviewRow
        title='training.edit.Documents'
        editRoute={`/admin/programs/${match.params.id}/edit/documents`}
      >
        <ul className='list-reset list-gap-1'>
          {trainingProgram.attachedFiles.map(file =>
            <li key={file.id}>{file.fileName}</li>)}
        </ul>
      </ReviewRow>
      <ReviewRow
        title='training.edit.Questions'
        editRoute={`/admin/programs/${match.params.id}/edit/questions`}
        lastRow
      >
        <ul className='list-reset list-gap-1'>
          {trainingProgram.sortedQuestions.map((q, index) =>
            <li key={index}>{`${index + 1}. ${q.text}`}</li>)}
        </ul>
      </ReviewRow>
    </div>
  );
});

export default Review;
