import {observable, computed} from 'mobx';
import {endpoints, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import {CompanyFile} from 'stores/company_files';
import _ from 'lodash';

class CompanyFilesState {
  Emoji;

  @observable folderlessFiles;
  @observable companyFileFolders;

  receiveProps({folderlessFiles, companyFileFolders, Emoji}) {
    this.folderlessFiles = folderlessFiles;
    this.companyFileFolders = companyFileFolders;
    this.Emoji = Emoji;
  }

  @computed get sortedCompanyFileFolders() {
    return _.sortBy(this.companyFileFolders, 'order');
  }

  @computed get sortedFolderlessFiles() {
    return _.sortBy(this.folderlessFiles, 'order');
  }

  async fetchFilesFor(companyFileFolderId) {
    const store = new DomainStore();
    await store._compose([
      endpoints.COMPANY_FILES.with(companyFileFolderId)
    ]);

    return _.sortBy(store._getAll(types.COMPANY_FILE, CompanyFile), 'order');
  }
}

export default CompanyFilesState;
