import React from 'react';
import {observer} from 'mobx-react';
import {RadioButton, RadioButtonGroup} from 'components';
import {FormattedMessage} from 'react-intl';

const Question = observer(({uiState}) => {
  switch (uiState.confirmationSummary.accrualStart) {
    case 'policy_start_date':
      return (
        <div>
          <div className='pb2'>
            <FormattedMessage id='time_off.policy.edit.confirmation.When do you want this change to take effect?'/>
          </div>
          <RadioButtonGroup
            name='effective_date'
            onChange={(name, type) => uiState.updateEffectiveDateOption(type)}
            selectedValue={uiState.effectiveDateType}
          >
            {uiState.effectiveDateOptions.map(option => (
              <RadioButton className='block pb1' key={option.type} value={option.type}>
                {option.name}
              </RadioButton>
            ))}
          </RadioButtonGroup>
        </div>
      );
    case 'policy_holder_start_date':
      return null;
    default:
      throw new Error(`Accrual start option not supported: ${uiState.accrualStart}`);
  }
});

export default Question;
