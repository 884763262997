/* eslint-disable */
import partition from 'lodash/partition';
import Leaf from './leaf';
import {
// Node,
WIDTH as NODE_WIDTH, HEIGHT as NODE_HEIGHT, BORDER_COLOR } from './node';
import { SIDE_SPACING, TOP_SPACING } from './constants';
export default class Row {
    constructor(parent, employeeMap) {
        this.rows = [];
        this.coords = [0, 0]; // [top, left]
        this.lines = [];
        this.size = null; // [width, height]
        this.employee = parent;
        // map the user to it's data - might need to rethink the data structure
        const children = parent.children.map(childID => employeeMap[childID]);
        // loop through all the children and see if they have any children or siblings
        // if they do, then split them into rows and leafs, if they don't,
        // they're all considered leafs
        const [rowItems, leafItems] = partition(children, child => child.children.length || child.hasSibling);
        if (leafItems.length) {
            this.leaf = new Leaf(leafItems, this);
        }
        if (rowItems.length) {
            // for any children that also have children, convert them to rows
            this.rows = rowItems.map(childWithChildren => {
                return new Row(childWithChildren, employeeMap);
            });
        }
    }
    calculateSize() {
        // set leaf size
        if (this.leaf) {
            this.leaf.calculateSize();
        }
        // recursively get each row to calculate it's size
        this.rows.forEach(r => {
            if (!r.size) {
                r.calculateSize();
            }
        });
        this._setSize();
    }
    _setSize() {
        // width
        let w = 0;
        if (this.leaf) {
            w += this.leaf.size[0];
        }
        this.rows.forEach(r => {
            w += r.size[0];
        });
        // For rows with no children (designated as rows because they have siblings)
        if (this.rows.length === 0 && !this.leaf) {
            w = NODE_WIDTH + SIDE_SPACING
        }
        const totalItems = this.rows.length + (this.leaf ? 1 : 0); // add spacing
        w += (totalItems - 1) * SIDE_SPACING;
        // height
        let h = NODE_HEIGHT + TOP_SPACING;
        const childGroups = this.rows.concat(this.leaf ? this.leaf : []);
        h += childGroups.reduce((max, item) => {
            return Math.max(max, item.size[1]);
        }, 0);
        this.size = [w, h];
    }
    setCoords(coords, depth) {
        this.coords = coords;
        this.depth = depth;
        let x = coords[0];
        let y = coords[1];
        const w = this.size[0];
        this.employeeNodeCoords = {
            x: x + w / 2 - NODE_WIDTH / 2,
            y: y,
        };
        const childRowY = y + NODE_HEIGHT + TOP_SPACING;
        let childRowX = x;
        // recursively set the coords for child rows
        this.rows.forEach(r => {
            r.setCoords([childRowX, childRowY], depth + 1);
            // increase x coord after each row has been placed
            childRowX += r.size[0] + SIDE_SPACING;
        });
        // set leaf coords
        if (this.leaf) {
            this.leaf.setCoords([childRowX, childRowY]);
        }
        // set coordinates for connecting lines
        this.setLineCoordinates();
    }
    // generate lines
    setLineCoordinates() {
        let lines = [];
        if (!this.rows.length && !this.leaf) {
            return this.lines = lines;
        }
        const x = this.employeeNodeCoords.x;
        const y = this.employeeNodeCoords.y;
        const arcSize = TOP_SPACING / 2;
        // vertical line from row employee
        lines.push(`M ${x + NODE_WIDTH / 2} ${y + NODE_HEIGHT} l 0 ${arcSize}`);
        // arcs from rows to parent (current employee in this row)
        const arcs = this.rows.map(r => {
            const rX = r.employeeNodeCoords.x;
            const rY = r.employeeNodeCoords.y;
            const x0 = r.employeeNodeCoords.x + NODE_WIDTH / 2;
            let p = `M ${x0} ${rY} `;
            if (rX < x) {
                const diff = x - rX - arcSize;
                p += `a ${arcSize} ${arcSize} 0 0 1 ${arcSize} ${-arcSize}
					l ${diff} 0
				`;
            }
            else if (x < rX) {
                const diff = x - rX + arcSize;
                p += `a ${arcSize} ${arcSize} 0 0 0 ${-arcSize} ${-arcSize}
					l ${diff} 0
				`;
            }
            else {
                p += `l 0 ${-arcSize}`;
            }
            return p;
        });

        let lastNodeXCoord = 0
        const siblingLines = this.rows.reduce((result, row) => {
            if (row.employee.drawLeftLine) {
                result.push(`M ${row.employeeNodeCoords.x} ${row.employeeNodeCoords.y + NODE_HEIGHT / 2} H ${lastNodeXCoord + NODE_WIDTH}`)
            }
            lastNodeXCoord = row.employeeNodeCoords.x
            return result
        }, []);

        // leaf
        let leafArc = [];
        if (this.leaf) {
            const lX = this.leaf.coords[0];
            const lY = this.leaf.coords[1];
            const x = this.employeeNodeCoords.x + NODE_WIDTH / 2;
            // leaf line x depends on the center of the leaf (x plus leaf width)
            const x0 = lX + this.leaf.size[0] / 2;
            let p = `M ${x0} ${lY} `;
            if (x0 < x) {
                const diff = x - x0 - arcSize;
                p += `a ${arcSize} ${arcSize} 0 0 1 ${arcSize} ${-arcSize}
					l ${diff} 0
				`;
            }
            else if (x0 > x) {
                const diff = x - x0 + arcSize;
                p += `a ${arcSize} ${arcSize} 0 0 0 ${-arcSize} ${-arcSize}
					l ${diff} 0
				`;
            }
            else {
                p += `l 0 ${-arcSize}`;
            }
            leafArc = p;
        }
        this.lines = lines.concat(arcs, leafArc, siblingLines);
    }
    render(doc, drawNode, depth) {
        // draw row master employee
        const node = Object.assign({
            x: this.employeeNodeCoords.x,
            y: this.employeeNodeCoords.y
        }, this.employee);
        drawNode(node, true);
        // recursively draw child rows
        this.rows.forEach(r => {
            r.render(doc, drawNode, depth + 1);
        });
        // draw leaf
        if (this.leaf) {
            this.leaf.render(doc, drawNode);
        }
        // draw connecting lines from employee to rows and leaf
        this.lines.forEach(l => {
            doc.path(l)
                .lineWidth(1)
                .stroke(BORDER_COLOR);
        });
        // this._TESTING_DRAW_BOUNDARIES(doc, depth)
    }
}
