import React from 'react';
import {observer} from 'mobx-react';
import ClientProfileContainer from '../../ClientProfileContainer';
import OverviewContainerWithState from './OverviewContainerWithState';

const OverviewContainer = observer(({match, history, parentState}) => {
  return (
    <ClientProfileContainer {...{match, history, parentState, withAvatar: true}}>
      <OverviewContainerWithState parentState={parentState} />
    </ClientProfileContainer>
  );
});

export default OverviewContainer;
