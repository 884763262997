import {DomainStore} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {setupAutosaveDraft, successAlert} from 'shared/tools';
import {endpoints, types, t} from 'shared/core';
import {Survey, SurveyResponse} from 'stores/surveys';
import _ from 'lodash';

class WriteSurveyContainerState {
  store = new DomainStore();
  match;
  history;

  @observable survey;
  @observable response;
  @observable errors = {};

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    await this.store._compose([
      endpoints.SURVEYS.SURVEY.with(this.match.params.id),
      endpoints.SURVEYS.RESPONSE.with(this.match.params.id)
    ]);

    this.response = new SurveyResponse(
      this.store._getSingle(types.SURVEYS.RESPONSE)
    );

    if (!this.response.readOnly) {
      const questions = _.map(this.response.answers, 'question');
      await setupAutosaveDraft(
        this.response,
        {type: types.SURVEYS.RESPONSE, id: this.match.params.id}
      );
      this.response.regenerateAnswersForQuestions(questions);
    }

    this.survey = new Survey(
      this.store._getSingle(types.SURVEYS.SURVEY)
    );
  }

  @action updateAnswer(answer, option_id) {
    const option = _.find(answer.question.options, { id: option_id });
    answer.merge({option});
  }

  @action autosaveResponse() {
    this.response.autosaver.autosave();
  }

  @action async submitResponse() {
    const {model, errors} = await this.store.patch(
      this.response.link('submit'),
      types.SURVEYS.RESPONSE,
      this.response
    );
    this.errors = errors;

    if (model) {
      this.response.update(model);
      successAlert(t('surveys.view.Successfully submitted'));
    }
  }

  @computed get submitMessage() {
    return this.survey.anonymous ? 'surveys.view.Submit Anonymously' : 'Submit';
  }
}

export default WriteSurveyContainerState;
