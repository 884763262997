import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, FormLayout} from 'components';

const TimeOffTypes = observer(({uiState}) => {
  return (
    <div>
      <Panel.Header
        title={t('time_off.policy.edit.Time Off Types')}
        description={t('time_off.policy.edit.Select the types of time off you would like made available to employees under this policy.')}
      />
      <FormLayout>
        <div></div>
      </FormLayout>
    </div>
  );
});

export default TimeOffTypes;
