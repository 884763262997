import React from 'react';
import {observer} from 'mobx-react';
import CandidateCustomField from './CandidateCustomField';

const CandidateCustomFields = observer(({candidate}) => {
  if (!candidate.sortedAnswers.length) return null;

  return (
    <div className='my2'>
      {candidate.sortedAnswers.map(a =>
        <div key={a.question.id}  className='my2'>
          <CandidateCustomField answer={a}/>
        </div>)}
    </div>
  );
});

export default CandidateCustomFields;
