import FilterRowViewModel from './FilterRowViewModel';
import _ from 'lodash';
import {action, computed, observable} from 'mobx';
import NumericInequalityFilterRow from 'stores/reports/NumericInequalityFilterRow';

class NumericFilterRowViewModel extends FilterRowViewModel {
  mapItem;

  @observable operatorOpen = false;
  @observable inputOpen = false;

  constructor({define}) {
    super(define);
  }

  createRow(row) {
    return new NumericInequalityFilterRow(row);
  }

  @action closeDropdowns() {
    this.closeOperator();
    this.closeInput();
  }

  @action openOperator() {
    this.closeInput();
    this.operatorOpen = true;
  }

  @action closeOperator() {
    this.operatorOpen = false;
    this.resetRow();
  }

  @action openInput() {
    this.closeOperator();
    this.inputOpen = true;
  }

  @action closeInput() {
    this.inputOpen = false;
    this.resetRow();
  }

  @action updateInput(value) {
    this.editingRow.updateValue(value);
  }

  @action updateInputStartRange(value) {
    this.editingRow.updateRangeLowerBound(value);
  }

  @action updateInputEndRange(value) {
    this.editingRow.updateRangeUpperBound(value);
  }

  @action operatorView(operator) {
    switch (operator) {
      case '<=':
        return 'less_than_or_equal_to';
      case '>=':
        return 'more_than_or_equal_to';
      default:
        return 'between';
    }
  }

  @computed get isRangeOperator() {
    return this.editingRow.operator === 'between';
  }

  @computed get inputView() {
    const {value} = this.editingRow;
    if (_.isNil(value)) return [];

    return [value];
  }

  @computed get inputRangeView() {
    const {lower_bound, upper_bound} = this.editingRow.range;
    if (_.isNil(lower_bound) || _.isNil(upper_bound)) return [];

    return [lower_bound, upper_bound];
  }
}

export default NumericFilterRowViewModel;
