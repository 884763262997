import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const DependantsEmptyState = observer(({uiState}) => {
  const {hasDependents} = uiState;
  if (hasDependents) return null;

  return (
    <FormattedMessage id='components.benefits.You have no dependants.'/>
  );
});

export default DependantsEmptyState;
