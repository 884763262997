import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Button} from 'components';

const OfferTask = observer(({uiState}) => {
  const {task, isAssignee} = uiState;

  return (
    <div>
      <div className='mb3 jumbo'>
        <FormattedMessage id='inbox.offer_explanation'/>
      </div>
      {isAssignee && !task.completed && <Button onClick={() => uiState.createEmployee(task)}>
        <FormattedMessage id='inbox.Hire Candidate'/>
      </Button>}
    </div>
  );
});

export default OfferTask;
