import _ from 'lodash';
import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, types, auth} from 'shared/core';
import Employee from 'stores/employees/Employee';
import {PerformanceReviewReview} from 'stores/performance_reviews';

class ResultsState {
  store = new DomainStore();
  match;

  @observable employee;
  @observable reviews;

  receiveProps({match}) {
    this.match = match;
  }

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.EMPLOYEE_DETAILS.with(auth.employee.id),
      endpoints.PERFORMANCE_REVIEWS.REVIEWS_RESULTS.with(this.match.params.cycleId)
    ]);

    this.employee = new Employee(this.store._getSingle(types.EMPLOYEE_DETAILS, { id: auth.employee.id }));
    this.reviews = this.store._getAll(types.PERFORMANCE_REVIEWS.REVIEW, PerformanceReviewReview);
  }

  @computed get hasReviews() {
    return !_.isEmpty(this.reviews);
  }
}

export default ResultsState;
