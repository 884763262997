import React from 'react';

const responsive = (WrappedComponent) => {
  return class extends React.Component {
    componentWillMount() {
      var metaTag = document.createElement('meta');
      metaTag.name = "viewport";
      metaTag.content = "width=device-width, initial-scale=1.0";
      document.getElementsByTagName('head')[0].appendChild(metaTag);
    }

    componentWillUnmount() {
      document.getElementsByTagName('meta')['viewport'].content = null;
    }

    render() {
      return (
        <WrappedComponent {...this.props} />
      );
    }
  };
};

export default responsive;
