import React from 'react';
import {observer} from 'mobx-react';
import {noop} from 'shared/tools';
import LoginContainerWrapper from '../../LoginContainerWrapper';
import LoginFormHeader from '../../LoginFormHeader';
import {Button, Input, FormLayout} from 'components';
import {FormattedMessage} from 'react-intl';

const MFARecoveryCode = observer(({uiState}) => {
  const {recoveryCode} = uiState;

  return (
    <LoginContainerWrapper>
      <LoginFormHeader message='user_settings.authentications.Save your recovery code'/>
      <div className='mb2'>
        <FormattedMessage id='user_settings.authentications.NEW_RECOVERY_CODE_GENERATED'/>
      </div>
      <FormLayout>
        <Input
          label='user_settings.authentications.Recovery Code'
          disabled
          value={recoveryCode.code}
          onChange={noop}
          helpMessage='user_settings.authentications.Download or write down your recovery code and keep it safe.'
        />
      </FormLayout>
      <div className='right-align mt3'>
        <Button trait='default' onClick={() => recoveryCode.downloadCode()}>
          <FormattedMessage id='Download'/>
        </Button>
        <Button className='ml2' onClick={() => uiState.finish()}>
          <FormattedMessage id='components.flow.Finish'/>
        </Button>
      </div>
    </LoginContainerWrapper>
  );
});

export default MFARecoveryCode;
