import {action, computed, observable} from 'mobx';
import {successAlert} from 'shared/tools';
import {t} from 'shared/core';
import Signature from 'stores/signatures/Signature';
import mergeFields from 'stores/documents/mergeFields';
import _ from 'lodash';

class CompanyDocumentEditState {
  store;
  history;
  match;

  @observable companyDocument;
  @observable signatures = [];
  @observable errors = {};
  @observable folders = [];

  constructor(store, history, match) {
    this.store = store;
    this.history = history;
    this.match = match;
  }

  @action async load() {
    this.companyDocument = await this.store.loadCompanyDocument(this.match.params.id);
    this.signatures = await this.store.loadCompanySignatures();
    this.folders = await this.store.loadFolders();
    this.customFields = await this.store.loadCustomFields();
  }

  @action updateContent(content) {
    this.companyDocument.richText = content.html;
    this.companyDocument.lexicalState = content.state;
  }

  @action goBack() {
    this.history.push('/documents');
  }

  @action async saveCompanyDocument() {
    const {model, errors} = await this.store.patch(this.companyDocument);
    this.errors = errors;

    if (model) {
      successAlert(t('company_settings.documents.alerts.Company document saved.'));
      this.goBack();
    }
  }

  @action cancelCompanyDocumentEdit() {
    this.goBack();
  }

  @action async saveRichTextTemplate() {
    return this.saveCompanyDocument();
  }

  @action async savePdfTemplate(annotations) {
    this.companyDocument.annotations = annotations;
    await this.saveCompanyDocument();
  }

  @computed get mergeFields() {
    let customFieldMergeFields;
    customFieldMergeFields = _.chain(this.customFields)
      .filter('employeeCanView')
      .map(customField => {
        return {label: customField.name, key: `custom_field_${customField.id}`, placeholder: `#[${customField.name}]`};
      })
      .sortBy('label')
      .value();
    const documentMergeFields = mergeFields;
    if (customFieldMergeFields.length > 0) {
      documentMergeFields.slice(-1)[0].separator = true;
    }
    return documentMergeFields.concat(customFieldMergeFields);
  }

  @computed get signatureList() {
    return [Signature.employeeSignature(), ...this.signatures];
  }

  @computed get sortedFolders() {
    return _.orderBy(this.folders, 'order');
  }
}

export default CompanyDocumentEditState;
