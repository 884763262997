import React from 'react';
import {FormattedMessage} from 'react-intl';
import {withRouter, Link} from 'react-router-dom';
import {Panel, AvatarList, ActionLink, CheckboxWithLabel, Select2} from 'components';
import {ReviewRow} from 'components/flow';
import PositionSummary from 'recruiting/components/PositionSummary';
import PositionTitleView from 'recruiting/components/PositionTitleView';
import {observer} from 'mobx-react';
import EmailTemplatePreviewModal from 'components/modals/EmailTemplatePreviewModal';
import {t} from 'shared/core';

const PreviewContent = ({position}) => {
  if (position.hasLink('posting')) {
    return <PreviewLink position={position} />;
  } else {
    return <PreviewNotConfigured />;
  }
};

const PreviewNotConfigured = () => {
  return (
    <Link to='/job-site' target='_blank'>
      <FormattedMessage id='recruiting.edit.steps.Configure job site URL to enable previews'/>
    </Link>
  );
};

const PreviewLink = ({position}) => {
  return (
    <a href={position.link('posting')} className='dodger' target='_blank'>{position.link('posting')}</a>
  );
};

const QuestionList = ({questions}) => {
  return (
    <ul className='list-reset list-gap-2'>
      <li><FormattedMessage id='recruiting.edit.steps.First Name'/></li>
      <li><FormattedMessage id='recruiting.edit.steps.Last Name'/></li>
      <li><FormattedMessage id='recruiting.edit.steps.Email'/></li>
      <li><FormattedMessage id='recruiting.edit.steps.Phone Number'/></li>
      {questions.map((question, index) => <li key={index}>
        <span>{question.name}</span>
      </li>)}
    </ul>
  );
};

const RuleList = ({rules}) => {
  return (
    <ul className='list-reset list-gap-2'>
      {rules.map((rule, index) => <li key={index}>
        <span>{rule.question.name}</span>
        <span className='ml1 waterloo'>{rule.formattedDisqualifiers}</span>
      </li>)}
    </ul>
  );
};

const EmailOptions = ({uiState}) => {
  const {position} = uiState;

  return (
    <div>
      <div className='col-12 mb2'>
        <div className='table-cell pr1'>
          <CheckboxWithLabel
            label='recruiting.edit.steps.Send confirmation emails to applicants'
            value={position.sendEmailToApplicants}
            checked={position.sendEmailToApplicants}
            onChange={e => position.merge({sendEmailToApplicants: e.checked})}/>
        </div>
        <div className='table-cell pl1 align-top'>
          <ActionLink onClick={() => uiState.openConfirmationEmailModal()}>
            <FormattedMessage id='recruiting.edit.steps.preview'/>
          </ActionLink>
        </div>
      </div>
      <div className='col-12'>
        <div className='table-cell pr1'>
          <CheckboxWithLabel
            label='recruiting.edit.steps.Automatically send email when disqualifying a candidate.'
            value={position.sendDisqualifiedEmailToApplicants}
            checked={position.sendDisqualifiedEmailToApplicants}
            onChange={e => position.merge({sendDisqualifiedEmailToApplicants: e.checked})}/>
        </div>
        <div className='table-cell pl1 align-top'>
          <ActionLink onClick={() => uiState.openDisqualifiedEmailModal()}>
              <FormattedMessage id='recruiting.edit.steps.preview'/>
            </ActionLink>
        </div>
      </div>
    </div>
  );
};

const Review = observer(({uiState}) => {
  const {position} = uiState;
  return (
    <div>
      <Panel.Header
        title={t('recruiting.edit.steps.Review your job posting before you publish')}
        description={t('recruiting.edit.steps.Hover over a section and click edit to make changes.')}
      />
      <ReviewRow title='recruiting.edit.steps.Job Title' editRoute={`/positions/${position.id}/details`}>
        <PositionTitleView position={position}/>
      </ReviewRow>
      <ReviewRow title='recruiting.edit.steps.Job Details' editRoute={`/positions/${position.id}/details`}>
        <PositionSummary position={position}/>
      </ReviewRow>
      <ReviewRow
        title='recruiting.edit.steps.Job Description'
        value={position.truncatedDescription}
        editRoute={`/positions/${position.id}/description`}
      />
      <ReviewRow title='recruiting.edit.steps.Application Form' editRoute={`/positions/${position.id}/questions`}>
        <QuestionList questions={position.sortedQuestions}/>
      </ReviewRow>
      <ReviewRow title='recruiting.edit.steps.Disqualification Rules' editRoute={`/positions/${position.id}/rules`}>
        <RuleList rules={position.rules}/>
      </ReviewRow>
      <ReviewRow title='recruiting.edit.steps.Hiring Collaborators' editRoute={`/positions/${position.id}/settings`}>
        <AvatarList employees={position.selectedCollaboratorUsers}/>
      </ReviewRow>
      <ReviewRow title='recruiting.edit.steps.Interview Process' editRoute={`/positions/${position.id}/settings`}>
        <ul className='list-reset list-gap-2'>
          <li><FormattedMessage id='recruiting.edit.steps.Sourced'/></li>
          <li><FormattedMessage id='recruiting.edit.steps.Applied'/></li>
          {position.customFunnelStages.map((stage, index) => <li key={index}>
            <FormattedMessage id='recruiting.edit.steps.STAGE_ITEM' values={{index: index + 1, name: stage.name}} />
          </li>)}
          {position.finalStages.length === 2 && <li><FormattedMessage id='recruiting.edit.steps.Offer'/></li>}
          <li><FormattedMessage id='recruiting.edit.steps.Hire'/></li>
        </ul>
      </ReviewRow>
      <ReviewRow title='recruiting.edit.steps.Referrals'>
        <CheckboxWithLabel
            label='recruiting.edit.steps.Allow employees to refer candidates'
            value={position.allowReferrals}
            checked={position.allowReferrals}
            onChange={e => position.merge({allowReferrals: e.checked})}/>
      </ReviewRow>
      <ReviewRow title='recruiting.edit.steps.Emails'>
        <EmailOptions uiState={uiState}/>
      </ReviewRow>
      <ReviewRow title='recruiting.edit.steps.Language'>
        <Select2 value={position.locale} onChange={e => position.merge({locale: e.target.value})}>
          <option value='en'>{'English'}</option>
          <option value='fr'>{'Français'}</option>
        </Select2>
      </ReviewRow>
      <ReviewRow title='recruiting.edit.steps.Visibility'>
        <Select2 value={position.status} onChange={e => position.merge({status: e.target.value})}>
          <option value='published'>{t('recruiting.edit.steps.Public')}</option>
          <option value='internal'>{t('recruiting.edit.steps.Internal')}</option>
        </Select2>
      </ReviewRow>
      <ReviewRow title='recruiting.edit.steps.Preview Job Posting' lastRow>
        <PreviewContent position={position} />
      </ReviewRow>
      <EmailTemplatePreviewModal
        isOpen={uiState.emailPreviewModalOpen}
        onHide={() => uiState.closeEmailPreviewModal()}
        title={uiState.previewModalTitle}
        template={uiState.emailPreview}
      />
    </div>
  );
});

export default withRouter(Review);
