import React from 'react';
import {observer} from 'mobx-react';
import {auth, endpoints, t} from 'shared/core';
import GoalSubnav from 'performance_goals/GoalSubnav';
import {Layout} from 'components';
import {GoalList} from 'performance_goals/components';

const MyGoalsContainer = observer(({match, history, scope}) => {
  return (
    <div>
      <GoalSubnav scope={scope} />
      <Layout>
        <GoalList
          {...{match, history}}
          canCreateGoals={true}
          title={t('goals.My Goals')}
          endpoint={endpoints.PERFORMANCE_GOALS.GOALS_FOR_EMPLOYEE.with(auth.employee.id)}
        />
      </Layout>
    </div>
  );
});

export default MyGoalsContainer;
