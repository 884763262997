import {observable} from 'mobx';
import _ from 'lodash';

class EmptyFormState {
  @observable question;

  receiveProps({question}) {
    this.question = question;

    this.question.args = {};
  }
}

export default EmptyFormState;
