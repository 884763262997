import {observable, action, computed} from 'mobx';
import Session from 'stores/users/Session';
import {resetSessionExpirationTimeout, cancelLoginPending, onLoginShown} from 'login/tools';
import {auth} from 'shared/core';
import $ from 'jquery';

class RenewSessionState {
  store;
  interval;
  expiryDate = new Date();
  context = 'reconnect';

  @observable form;
  @observable errors = {};
  @observable seconds = 60;
  @observable dead = false;
  @observable cancelPending = false;

  constructor(store, expiryDate) {
    this.store = store;
    this.form = new Session();

    this.expiryDate = expiryDate;
    this.seconds = parseInt((this.expiryDate - new Date()) / 1000);
    this.interval = setInterval(() => this.tick(), 1000);
    onLoginShown();
  }

  @action tick() {
    this.seconds = parseInt((this.expiryDate - new Date()) / 1000);

    if (this.seconds > 0) {
      if (cancelLoginPending()) {
        clearInterval(this.interval);
        this.cancelPending = true;
        setTimeout(() => this.die(), 1000);
      }
    } else {
      this.seconds = 0;
      $('main').addClass('LoginBlur');
      clearInterval(this.interval);
    }
  }

  @action async renewSession() {
    clearInterval(this.interval);
    const {model} = await this.store.renewSession();
    if (model) {
      this.die();
    }
  }

  @action async createSession() {
    this.form.currentUserId = auth.user.id;
    this.form.currentTenantKlass = auth.user.currentTenantKlass;
    const {model, errors} = await this.store.postCredentials(this.form);
    this.errors = errors;
    if (model) {
      this.die();
    }
  }

  @action die() {
    $('main').removeClass('LoginBlur');
    this.dead = true;
    $(document.body).remove('#cr-session-expiry');
    resetSessionExpirationTimeout();
  }

  @computed get isExpired() {
    return this.seconds <= 0;
  }

  @computed get authenticityToken() {
    return document.getElementsByName('csrf-token')[0].content;
  }
}

export default RenewSessionState;
