import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';
import {Panel, FormLayout, Input, ActionLink, DatePicker, Select2, ObjectSelect2} from 'components';
import AddRepModal from './AddRepModal';

const BenefitsCarriers = observer(({uiState}) => {
  const {brokerClient, errors} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('broker_portal.clients.edit.Benefits Carriers')}/>
      <FormLayout>
        {brokerClient.benefitsCarriers.map((carrier, index) =>
          <div key={index}>
            <div className='mb2'>
              <div className='table'>
                <div className='col-6 table-cell align-middle medium'>
                  <FormattedMessage id='broker_portal.clients.edit.BENEFITS_CARRIER' values={{index: index + 1}}/>
                </div>
                <div className='col-6 table-cell align-middle right-align'>
                  <ActionLink subdued onClick={() => uiState.removeBenefitsCarrier(carrier)}>
                    <FormattedMessage id='broker_portal.clients.edit.Remove'/>
                  </ActionLink>
                </div>
              </div>
            </div>
            <FormLayout>
              <Select2
                value={carrier.carrier}
                onChange={e => carrier.merge({carrier: e.target.value, insuranceCarrierRep: null})}
                label='broker_portal.clients.edit.Carrier Name'
                errorMessage={_.get(errors, `benefitsCarriers[${index}].carrier`)}
              >
                {uiState.carriers().map(carrier =>
                  <option value={carrier.key} key={carrier.key}>{carrier.name}</option>
                )}
              </Select2>
              <ObjectSelect2
                action={{
                  onClick: () => uiState.openAddRepModal(carrier.carrier),
                  title: t('broker_portal.carrier_reps.edit.+ Add Insurance Carrier Rep')
                }}
                disabled={!carrier.carrier}
                label='broker_portal.clients.edit.Carrier Rep'
                value={carrier.insuranceCarrierRep}
                onChange={value => carrier.merge({insuranceCarrierRep: value})}
                items={uiState.repsForCarrier(carrier.carrier)}
                errorMessage={_.get(errors, `benefitsCarriers[${index}].insuranceCarrierRep`)}
              />
              <Input value={carrier.policyNumber}
                onChange={e => carrier.merge({policyNumber: e.target.value})}
                label='broker_portal.clients.edit.Carrier Policy Number'
                errorMessage={_.get(errors, `benefitsCarriers[${index}].policyNumber`)}
              />
              <DatePicker className='full-width'
                label='broker_portal.clients.edit.Next Renewal Date'
                onChange={date => carrier.merge({nextRenewalDate: date})}
                value={carrier.nextRenewalDate}
                errorMessage={_.get(errors, `benefitsCarriers[${index}].nextRenewalDate`)}
              />
            </FormLayout>
          </div>)}
      </FormLayout>
      <ActionLink onClick={() => uiState.addBenefitsCarrier()}>
        <FormattedMessage id='broker_portal.clients.edit.+ Add Benefits Carrier'/>
      </ActionLink>
      <AddRepModal uiState={uiState} errors={uiState.insuranceCarrierRepErrors}/>
    </Panel>
  );
});

export default BenefitsCarriers;
