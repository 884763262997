import React from 'react';
import {observer} from 'mobx-react';
import ChooseNewPolicy from './ChooseNewPolicy';

@observer class Step extends React.Component {
  render() {
    const {step, uiState} = this.props;

    switch (step) {
      case 'new-policy':
        return <ChooseNewPolicy uiState={uiState}/>;
      default:
        return null;
    }
  }
}

export default Step;
