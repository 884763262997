import ReportColumn from './ReportColumn';
import _ from 'lodash';
// import { map } from 'mobx';

const FIELDS = [
  {
    group: 'employee',
    values: [
      'employee.first_name',
      'employee.last_name',
      'employee.middle_name',
      'employee.preferred_name',
      'employee.preferred_last_name',
      'employee.gender',
      'employee.birth_date',
      'employee.address_1',
      'employee.address_2',
      'employee.city',
      'employee.province',
      'employee.country',
      'employee.postal_code',
      'employee.work_email',
      'employee.personal_email',
      'employee.work_phone',
      'employee.work_phone_extension',
      'employee.personal_phone',
      'employee.onboarding_status'
    ].map(v => new ReportColumn({key: v}))
  },
  {
    group: 'employment_history',
    values: [
      'employee.start_date',
      'employment_history.effective_date',
      'employment_history.employment_type',
      'employment_history.job_title',
      'employment_history.location',
      'employment_history.department',
      'employment_history.manager',
      'employment_history.pay_rate',
      'employment_history.pay_rate_unit',
      'employment_history.pay_rate_currency',
      'employment_history.pay_frequency',
      'employment_history.commission',
      'employment_history.commission_structure',
      'employment_history.bonus',
      'employment_history.bonus_structure',
      'employment_history.hours_per_week',
      'employment_history.leave_type',
      'employment_history.expected_return_date'
    ].map(v => new ReportColumn({key: v})),
    options: [{
      title: 'employment_history.employment_records_show_history',
      field: 'employmentRecordsShowHistory'
    }]
  },
  {
    group: 'termination_records',
    values: [
      'termination_records.reason',
      'termination_records.type',
      'termination_records.last_day_of_work',
      'termination_records.last_day_of_benefits'
    ].map(v => new ReportColumn({key: v}))
  },
  {
    group: 'banking_information',
    values: [
      'banking_information.name',
      'banking_information.account_number',
      'banking_information.transit_number',
      'banking_information.institution_number',
      'employee.sin'
    ].map(v => new ReportColumn({key: v}))
  },
  {
    group: 'emergency_contacts',
    values: [
      'emergency_contacts.contact_1_name',
      'emergency_contacts.contact_1_relationship',
      'emergency_contacts.contact_1_phone',
      'emergency_contacts.contact_2_name',
      'emergency_contacts.contact_2_relationship',
      'emergency_contacts.contact_2_phone'
    ].map(v => new ReportColumn({key: v}))
  },
  {
    group: 'notes',
    values: [
      'notes.content',
      'notes.author'
    ].map(v => new ReportColumn({key: v}))
  },
  {
    group: 'benefits_info',
    values: [
      'benefits_info.has_spouse',
      'benefits_info.spouse_first_name',
      'benefits_info.spouse_last_name',
      'benefits_info.spousal_gender',
      'benefits_info.spousal_health_coverage',
      'benefits_info.spousal_dental_coverage',
      'benefits_info.spousal_vision_coverage',
      'benefits_info.spouse_insurer_name',
      'benefits_info.spouse_birth_date',
      'benefits_info.spouse_marriage_date',
      'benefits_info.gender',
      'benefits_info.health_coverage',
      'benefits_info.dental_coverage',
      'benefits_info.has_canadian_visa_or_permit',
      'benefits_info.has_provincial_health_plan'
    ].map(v => new ReportColumn({key: v}))
  },
  {
    group: 'dependents',
    values: [
      'dependents.first_name',
      'dependents.last_name',
      'dependents.student',
      'dependents.gender',
      'dependents.disabled_adult',
      'dependents.birth_date'
    ].map(v => new ReportColumn({key: v}))
  },
  {
    group: 'training',
    values: [
      'training.name',
      'training.due_date',
      'training.completed_at',
      'training.launched_at',
      'training.description',
      'training.quiz_results'
    ].map(v => new ReportColumn({key: v}))
  }, {
  group: 'time_off',
    values: [
      'time_off_policy.name'
    ].map(v => new ReportColumn({key: v}))
  },
  {
    group: 'benefits_enrolment',
    values: [
      'benefits_enrolment.carrier',
      'benefits_enrolment.division',
      'benefits_enrolment.class',
      'benefits_enrolment.group_id',
      'benefits_enrolment.employee_id',
      'benefits_enrolment.effective_date'
    ].map(v => new ReportColumn({key: v}))
  }
];

export default FIELDS;
