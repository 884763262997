import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {HorizontalScrollState} from '../state';
import ActionLink from 'components/links/ActionLink';

const LeftScrollArrow = observer(({uiState}) => {
  const {showScrollBars, fixedArrows, top} = uiState;
  if (!showScrollBars) return null;

  return (
    <ActionLink
      className={`HorizontalScroll-left ${fixedArrows ? 'HorizontalScroll-fixed' : ''} jumbo`}
      onMouseMove={e => uiState.scrollPosition(e)}
      onMouseEnter={e => uiState.scrollLeft(e)}
      onMouseLeave={() => uiState.clearScrolling()}
    >
      <i className='material-icons' style={{top}}>{'keyboard_arrow_left'}</i>
    </ActionLink>
  );
});

const RightScrollArrow = observer(({uiState}) => {
  const {showScrollBars, fixedArrows, top} = uiState;
  if (!showScrollBars) return null;

  return (
    <ActionLink
      className={`HorizontalScroll-right ${fixedArrows ? 'HorizontalScroll-fixed' : ''} jumbo`}
      onMouseMove={e => uiState.scrollPosition(e)}
      onMouseEnter={e => uiState.scrollRight(e)}
      onMouseLeave={() => uiState.clearScrolling()}
    >
      <i className='material-icons' style={{top}}>{'keyboard_arrow_right'}</i>
    </ActionLink>
  );
});

const Carousel = observer(({children, uiState}) => {
  const {element} = uiState;

  return (
    <div className='HorizontalScroll-carousel' ref={element}>
      {children}
    </div>
  );
});

const HorizontalScroll = observer(({children, uiState}) => {
  return (
    <div className='HorizontalScroll'>
      <LeftScrollArrow uiState={uiState}/>
      <Carousel children={children} uiState={uiState}/>
      <RightScrollArrow uiState={uiState}/>
    </div>
  );
});

HorizontalScroll.defaultProps = {
  fixedArrows: false
};

export default withState(HorizontalScroll, HorizontalScrollState);
