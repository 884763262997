import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {CompanyBenefitsUploadState} from '../state';
import {FormattedMessage} from 'react-intl';
import {Layout, Panel, Button, UploadFiles} from 'components';
import {t} from 'shared/core';
import BhqCompanyContainer from './BhqCompanyContainer';

const CompanyBenefitsUploader = observer(({uiState}) => {
  const {secureFile, errors} = uiState;

  return (
    <BhqCompanyContainer>
      <Layout centered>
        <Layout.Section secondary>
          <Panel>
            <Panel.Header title={t('broker_portal.signup.company.Upload your Benefits Coverage Data')}/>
            <UploadFiles
              defaultFiles={secureFile.awsFiles}
              onUploadStarted={() => uiState.onUploadStarted()}
              onUploadFinished={() => uiState.onUploadFinished()}
              errorMessage={errors.awsFiles}
              isUploading={uiState.isUploading}
              onChange={files => secureFile.merge({awsFiles: files})}
              modelType='broker_portal/benefits_data'
            />
            <Button className='mt2 full-width' disabled={uiState.isUploading} onClick={() => uiState.saveFiles()}>
              <FormattedMessage id='broker_portal.signup.company.Upload your employee data'/>
            </Button>
          </Panel>
        </Layout.Section>
      </Layout>
    </BhqCompanyContainer>
  );
});

export default withState(CompanyBenefitsUploader, CompanyBenefitsUploadState);
