/* eslint-disable */
import React, { Component, Fragment, createRef } from 'react';
import { observable, action, computed} from 'mobx';
import { observer} from 'mobx-react';
import P from 'prop-types';
import clamp from 'lodash/clamp'

import Node from '../node'
import {
	TOP_MARGIN,
	NODE_WIDTH,
} from '../../constants';
import Lines from '../lines';


const HIGHLIGHT_ANIMATION_SPEED = 760;
const HIGHLIGHT_MIN_DURATION = 330;
const HIGHLIGHT_MAX_DURATION = 830;
function highlightDuration(dur){
	return clamp(dur, HIGHLIGHT_MIN_DURATION, HIGHLIGHT_MAX_DURATION)
}
const wait = delay => new Promise(res => setTimeout(res, delay))

@observer class ChartRow extends Component {
	@computed get firstNodeLeft(){
		return this.props.row.nodesWithBounds[0].left;
	}
	@computed get lastNodeLeft(){
		const nodes = this.props.row.nodesWithBounds;
		return nodes[nodes.length - 1].left;
	}

	// highlight stuff
	@observable hlEmployeeID = null;
	highlightLineEl = createRef();
	@observable highlightLinePath = null;
	@action removeHighlight = () => {
		// clear highlighted employee and line, and then go up tree and do the same
		this.highlightLinePath = null;
		this.hlEmployeeID = null;
		if (this.props.removeHighlight){
			this.props.removeHighlight()
		}
	}
	// highlights and calls parent to do the same
	@action.bound async highlight(nodeid, activeHighlightId) {
		this.hlEmployeeID = nodeid;
		this.activeHighlightId = activeHighlightId;

		if (this.props.row.depth < 1) {
			return;
		}

		// draw line on current row
		const node = this.props.row.nodesWithBounds.find(n => n.id === nodeid);
		this.generateHighlight(node.left)
	}

	@action.bound async finishHighlighting() {
		const line = this.highlightLineEl.current
		const len = line.getTotalLength()

		const duration = highlightDuration( len / HIGHLIGHT_ANIMATION_SPEED * 1000 )

		line.style.strokeDasharray = len
		line.style.strokeDashoffset = len
		line.style.animationDuration = `${duration}ms`

		// wait for animation to finish then start on next row above
		await wait(duration + 90)

		if (this.activeHighlightId !== this.props.row.chart.activeHighlightId) return;

		const parentID = this.props.row.parentID
		this.props.highlight(parentID, this.activeHighlightId)
	}
	@action.bound async initHighlight(nodeid){
		if (this.props.row.depth < 1){
			return;
		}
		// register highlight remove function on master tree
		this.props.registerHighlight(this.removeHighlight)

		// clear line from dom to reset animation
		await wait(0)

		// draw
		this.highlight(nodeid, this.props.row.chart.activeHighlightId)
	}
	generateHighlight(nodeLeft){
		// center position of selected node relative to svg
		const nodeCenter = nodeLeft - this.firstNodeLeft + NODE_WIDTH / 2

		const parentLeft = this.props.row.parent.left
		const arcSize = TOP_MARGIN / 2
		const parentNodeCenter = - this.firstNodeLeft + parentLeft + NODE_WIDTH / 2

		let path = ``
		if (parentLeft > nodeLeft){
			const horizontal = parentNodeCenter - nodeCenter - TOP_MARGIN
			path += `
				M${nodeCenter} ${TOP_MARGIN}
				a ${arcSize} ${arcSize} 0 0 1 ${arcSize} ${-arcSize}
				l ${horizontal} 0
				a ${arcSize} ${arcSize} 0 0 0 ${arcSize} ${-arcSize}
			`
		} else if (parentLeft < nodeLeft){
			const horizontal = parentNodeCenter - nodeCenter + TOP_MARGIN
			path += `
				M${nodeCenter} ${TOP_MARGIN}
				a ${arcSize} ${arcSize} 0 0 0 ${-arcSize} ${-arcSize}
				l ${horizontal} 0
				a ${arcSize} ${arcSize} 0 0 1 ${-arcSize} ${-arcSize}
			`
		} else {
			// straight
			path += `
				M${nodeCenter} ${TOP_MARGIN}
				l 0 ${-TOP_MARGIN}
			`
		}

		this.highlightLinePath = path;
	}


	render(){
		const { row } = this.props;
		return (
			<Fragment>
				<div className={`row ${row.depth ? 'type_anim' : ''}`} style={{
					transformOrigin: `${row.parent.left + NODE_WIDTH / 2}px ${row.parentTop + TOP_MARGIN}px`
				}}>
					{row.depth ?
						<Lines
							firstNodeLeft={this.firstNodeLeft}
							lastNodeLeft={this.lastNodeLeft}
							parentTop={row.parentTop}
							rowWidth={row.rowWidth}
							nodes={row.nodesWithBounds}
							parent={row.parent}

							highlightLinePath={this.highlightLinePath}
							highlightLineEl={this.highlightLineEl}
							finishHighlighting={() => this.finishHighlighting()}
						/>
						:
						null
					}

					{/* all nodes in current row */}
					{row.nodesWithBounds.map(node => {
						return <Node
							key={node.id}
							employee={node}
							highlight={this.initHighlight}
							toggleChildren={row.toggleChildRow}

							left={node.left}
							top={node.top}
							isActive={this.hlEmployeeID === node.id}
						/>
					})}
				</div>

				{/* child rows */}
				{row.childRows.map(childRow => {
					return <ChartRow
						key={childRow.parentID}
						row={childRow}

						// highlight stuff
						removeHighlight={this.removeHighlight}
						highlight={this.highlight}
						registerHighlight={this.props.registerHighlight}
					/>
				})}
			</Fragment>
		)
	}
}

ChartRow.propTypes = {
	registerHighlight: P.func.isRequired,
	row: P.shape({

	}).isRequired
}

export default ChartRow;
