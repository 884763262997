const config = {
  title: null,
  legend: {
    enabled: false
  },
  chart: {
    marginTop: 50,
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'column'
  },
  yAxis: {
    allowDecimals: false
  },
  plotOptions: {
    column: {
      size: 200,
      allowPointSelect: true,
      cursor: 'pointer',
      colorByPoint: true,
      dataLabels: {
        connectorWidth: 0,
        distance: -30,
        style: {
          fontFamily: 'GT Walsheim, sans-serif',
          fontWeight: 500,
          fontSize: '16px',
          textOutline: 'none',
        },
        formatter: function() {
          return this.y;
        }
      }
    }
  },
  credits: {
    enabled: false
  }
};

export default config;
