import React, {useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import CloseIcon from 'img/close-icon@2x.png';
import Modal from 'react-modal';
import ActionLink from 'components/links/ActionLink';
import useIsMobile from 'shared/hooks/useIsMobile';
import PropTypes from 'prop-types';

const toggleIntercom = (visible) => {
  if (window.Intercom) {
    window.Intercom('update', {
      "hide_default_launcher": !visible
    });
  }
};

const CollageModal = ({title, size, closeButton, isOpen, onHide, preventCloseOnOverlayClick, imagePreview, translateTitle, children, fullscreenOnMobile}) => {
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isOpen && fullscreenOnMobile && isMobile) {
      toggleIntercom(false);
    } else {
      toggleIntercom(true);
    }

    return () => {
      toggleIntercom(true);
    };
  }, [isOpen, fullscreenOnMobile, isMobile]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onHide}
      className={{
        base: `CollageModalContent ${size} ${imagePreview ? 'CollageImagePreview' : ''} ${fullscreenOnMobile ? 'FullScreenMobile' : ''}`,
        afterOpen: 'in',
        beforeClose: 'out'
      }}
      overlayClassName={{
        base: 'CollageModalOverlay',
        afterOpen: 'in',
        beforeClose: 'out'
      }}
      shouldCloseOnOverlayClick={!!onHide && !preventCloseOnOverlayClick}
      closeTimeoutMS={150}
    >
      <div className='CollageModalInner'>
        <span>
          {closeButton && (
            <ActionLink className='right' aria-label='Close' onClick={onHide}>
              <img src={CloseIcon} className='img-hover' alt='close' width='14px'/>
            </ActionLink>
          )}
          {title &&
            <div className='h2 mb3'>
              {translateTitle && <FormattedMessage id={title}/>}
              {!translateTitle && <span>{title}</span>}
            </div>
          }
        </span>
        {children}
      </div>
    </Modal>
  );
};

CollageModal.defaultProps = {
  size: 'lg',
  closeButton: true,
  translateTitle: true,
  fullscreenOnMobile: false,
  preventCloseOnOverlayClick: false
};

CollageModal.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
};

export default CollageModal;
