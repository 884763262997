import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import BillingSubscription from './BillingSubscription';

class BillingStore extends DomainStore {
  async load() {
    return this._compose([
      endpoints.BILLING.SUBSCRIPTION
    ]);
  }

  getBillingSubscription() {
    return new BillingSubscription(this._getSingle(types.BILLING.SUBSCRIPTION));
  }
}

const singleton = new BillingStore();

export default singleton;
