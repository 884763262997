import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
/*global $*/

const CompanyOnboardLaunchButton = observer(({uiState}) => {
  return (
    <a className='Btn Btn--primary' rel="nofollow" data-method="post" href="/company_onboard/dashboard/complete">
      <span className='Btn--content' tabIndex='-1'>
        <FormattedMessage id='Send Invites'/>
      </span>
    </a>
  );
});

export default CompanyOnboardLaunchButton;
