import React from 'react';
import {observer} from 'mobx-react';
import {loader, t} from 'shared/core';
import {Table, Panel} from 'components';
import EmailTemplateState from 'recruiting/state/EmailTemplateState';
import emailTemplateStore from 'recruiting/state/EmailTemplateStore';
import AddEmailTemplateModal from './AddEmailTemplateModal';

const COLUMNS = [
  {
    header: 'recruiting.email_templates.Name',
    attribute: 'name',
    width: 11
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'recruiting.email_templates.Remove Email Template',
    subHeader: 'recruiting.email_templates.Are you sure you want to remove this email template?',
    body: 'recruiting.email_templates.Email templates already sent to candidates will not be removed.'
  },
  addModel: 'recruiting.email_templates.+ Add an Email Template',
  emptyState: 'recruiting.email_templates.No email templates to display'
};

const EmailTemplateContainerWithState = observer(({uiState}) => {
  const {emailTemplates} = uiState;

  return (
    <div className='Container clearfix'>
      <div className='col-12 lg-col-10'>
        <Panel>
          <Panel.Header title={t('recruiting.email_templates.Email Templates')}/>
          <Table models={emailTemplates}
                 columns={COLUMNS}
                 localization={LOCALIZATION}
                 onRemove={(template) => uiState.removeEmailTemplate(template)}
                 onAdd={() => uiState.openAddEmailTemplateModal()}
                 editRoute='email-templates/:id'/>
        </Panel>
      </div>
      <AddEmailTemplateModal uiState={uiState}/>
    </div>
  );
});

async function load({history}) {
  const uiState = new EmailTemplateState(emailTemplateStore, history);
  await uiState.load();
  return {uiState};
}

export default loader(EmailTemplateContainerWithState, load);
