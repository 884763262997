import React from 'react';
import {observer} from 'mobx-react';
import {Route, Switch} from 'react-router-dom';
import {TemplateDirectory} from './directory';
import {EditTemplateContainer} from './edit';

const TemplatesPage = observer(({scope, history}) => {
  return (
    <Switch>
      <Route exact path='/question_sets' render={() => <TemplateDirectory history={history} scope={scope}/>}/>
      <Route path='/question_sets/:id' component={EditTemplateContainer}/>
    </Switch>
  );
});

export default TemplatesPage;
