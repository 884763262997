import React from 'react';
import ActionLink from './links/ActionLink';
import {FormattedMessage} from 'react-intl';

const FilterDropdownButtons = ({onCancel, onApply}) => {
  return (
    <div className='clearfix table rounded bg-white pt2'>
      <div className='col-6 table-cell align-middle'>
        <ActionLink onClick={onCancel} className='waterloo'>
          <FormattedMessage id='components.filter_dropdown_buttons.Cancel'/>
        </ActionLink>
      </div>
      <div className='col-6 table-cell align-middle right-align'>
        <ActionLink onClick={onApply} className='dodger'>
          <FormattedMessage id='components.filter_dropdown_buttons.Apply'/>
        </ActionLink>
      </div>
    </div>
  );
};

export default FilterDropdownButtons;
