import {action, computed, observable} from 'mobx';
import {DomainStore} from 'shared/store';

class CandidateRaterState {
  store = new DomainStore()

  @observable score;
  @observable onRating;
  @observable mousedOverStar = false;
  @observable mousedOverStarIndex;

  receiveProps({onRating, score}) {
    this.onRating = onRating;
    this.score = score;
  }

  @action onStarMouseEnter(i) {
    if (!this.onRating) {
      return;
    }

    this.mousedOverStar = true;
    this.mousedOverStarIndex = i;
  }

  @action onStarMouseLeave() {
    if (!this.onRating) {
      return;
    }

    this.mousedOverStar = false;
  }

  @action async rateCandidate(score) {
    if (!this.onRating) {
      return;
    }

    this.onRating(score);
  }

  @computed get scoreToDisplay() {
    return this.mousedOverStar ? this.mousedOverStarIndex : this.score;
  }
}

export default CandidateRaterState;
