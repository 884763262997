import React from 'react';
import {observer} from 'mobx-react';
import {endpoints, t, types} from 'shared/core';
import {EmployeeNameWithAvatar, InteractiveTable} from 'components';
import {SurveyEmployeeStatus} from 'stores/surveys';
import {FormattedMessage} from 'react-intl';

const EmployeeName = observer(({model}) => {
  return (
    <EmployeeNameWithAvatar employee={model.employee} />
  );
});

const Status = observer(({model}) => {
  return (
    <FormattedMessage id={`surveys.view.${model.submitted ? 'Submitted' : 'Pending'}`} />
  );
});

const SURVEY_COLUMNS = [
  {
    header: 'surveys.view.Surveyee',
    width: 3,
    component: EmployeeName,
    sortBy: 'employeeName',
  },
  {
    header: 'surveys.view.Status',
    width: 2,
    sortBy: 'submittedAt',
    component: Status
  },
  {
    header: 'surveys.view.Due Date',
    render: model => model.dueDateView,
    width: 3,
    sortBy: 'dueDate'
  },
  {
    header: 'surveys.view.Completed Date',
    render: model => model.submittedAtView,
    width: 3,
    sortBy: 'submittedAt'
  }
];

const ANONYMOUS_SURVEY_COLUMN = [
  {
    header: 'surveys.view.Surveyee',
    width: 11,
    component: EmployeeName
  }
];

const LOCALIZATION = {
  interactive: {
    addModel: 'surveys.view.+ Add Assignees',
  },
  removeModal: {
    header: 'surveys.view.Remove Surveyee',
    subHeader: 'surveys.view.Are you sure you want to remove this participant from the engagement survey?',
    body: 'surveys.view.The survey for this employee will be permanently deleted.'
  }
};

const ResponseTable = observer(({uiState}) => {
  const {match, survey} = uiState;

  const columns = survey.anonymous ?
    ANONYMOUS_SURVEY_COLUMN :
    SURVEY_COLUMNS;

  return (
    <InteractiveTable
      title={t('surveys.view.Assigned Employees')}
      columns={columns}
      localization={LOCALIZATION}
      onMount={agent => uiState.setAgent(agent)}
      onAdd={() => uiState.openSelectEmployeesModal()}
      initialSorting={{employeeName: 'asc'}}
      showAddButton={survey.hasLink('addAssignees')}
      onRemove={model => uiState.removeEmployeeStatus(model)}
      showRoute={model => !survey.anonymous && model.submitted ? `/manage/survey/${survey.id}/response/${model.employee.id}` : null}
      proxy={{
        type: 'async',
        modelType: types.SURVEYS.EMPLOYEE_STATUS,
        model: SurveyEmployeeStatus,
        endpoint: endpoints.SURVEYS.EMPLOYEE_STATUSES.ALL.with(match.params.id)
      }}
    />
  );
});

export default ResponseTable;
