import React from 'react';
import {observer} from 'mobx-react';
import {Button} from 'components';
import ErrorMessage from './ErrorMessage';
import {FormattedMessage} from 'react-intl';

const ErrorScreen = ({uiState}) => {
  return (
    <div>
      <div className='h3 scarlet'>
        <FormattedMessage id='employees.google_import.Import failed'/>
      </div>
      <div className='pt2'>
        <ErrorMessage uiState={uiState}/>
      </div>
      <div className='mt3 right-align'>
        <Button trait='default' onClick={() => uiState.cancelImport()} className='mr2'>
          <FormattedMessage id='employees.google_import.Close'/>
        </Button>
      </div>
    </div>
  );
};

const FinishScreen = ({uiState}) => {
  if (uiState.hasError) {
    return <ErrorScreen uiState={uiState}/>;
  }
  return (
    <div>
      <div className='h3'>
        <FormattedMessage id='employees.google_import.Import complete'/>
      </div>
      {!!uiState.createdEmployeesCount && <div className='h4 mt2'>
        <FormattedMessage
          id='employees.google_import.IMPORT_SUCCESS_RESULT'
          values={{
            createdEmployeesCount: uiState.createdEmployeesCount
          }}
        />
      </div>}
      {!uiState.createdEmployeesCount && <div className='h4 mt2'>
        <FormattedMessage id='employees.google_import.No new employees were imported.'/>
      </div>}
      {!!uiState.duplicateEmployeesCount && <div className='h4 mt2'>
        <FormattedMessage
          id='employees.google_import.IMPORT_DUPLICATES'
          values={{
            duplicateEmployeesCount: uiState.duplicateEmployeesCount
          }}
        />
      </div>}
      {!uiState.duplicateEmployeesCount && !uiState.createdEmployeesCount && <div className='h4 mt2'>
        <FormattedMessage id='employees.google_import.No employees were selected for import.'/>
      </div>}
      <div className='mt3 right-align'>
        <Button trait='default' onClick={() => uiState.cancelImport()} className='mr2'>
          <FormattedMessage id='employees.google_import.Close'/>
        </Button>
      </div>
    </div>
  );
};

export default observer(FinishScreen);
