import _ from 'lodash';
import {action, computed, observable} from 'mobx';
import striptags from 'striptags';
import {DomainObject} from 'shared/store';
import EmployeeTrainingStatusSummary from './EmployeeTrainingStatusSummary';
import {AttachedDocument} from 'stores/documents';
import Question from './Question';
import {calendarDate} from 'shared/tools';
import {t} from 'shared/core';

class TrainingProgram extends DomainObject {
  @observable id;
  @observable name = '';
  @observable description;
  @observable lexicalState;
  @observable availableInHiring = true;
  @observable relativeDueDateUnit = null;
  @observable relativeDueDateValue = null;
  @observable absoluteDueDate = null;
  @observable employeeTrainingStatuses;
  @observable launched;
  @observable launchedAt;
  @observable closed;

  @observable attachedFiles = [];
  @observable questions = [];
  @observable videoUrl = '';

  @action merge(other) {
    super.merge(other, {
      employeeTrainingStatuses: [EmployeeTrainingStatusSummary],
      attachedFiles: [AttachedDocument],
      questions: [Question],
      _dates: ['launchedAt']
    });
  }

  @computed get dueDateValue() {
    return this.relativeDueDateValue || '';
  }

  @computed get launchedAtView() {
    return calendarDate(this.launchedAt);
  }

  @computed get truncatedDescription() {
    const strippedDescription = striptags(this.description);
    return _.chain(strippedDescription)
      .trim('&nbsp;')
      .truncate({length: 120})
      .value();
  }

  @computed get formattedAvailableInHiring() {
    switch (this.availableInHiring) {
      case true: return 'Yes';
      case false: return 'No';
      default: throw new Error('Invalid available in hiring type');
    }
  }

  @computed get formattedDueDate() {
    switch (this.relativeDueDateUnit) {
      case 'weeks': return t('training.edit.weeks', { amount: this.relativeDueDateValue });
      case 'days': return t('training.edit.days', { amount: this.relativeDueDateValue });
      case null: return null;
      default: throw new Error('Invalid due date unit');
    }
  }

  @computed get sortedQuestions() {
    return _.sortBy(this.questions, 'order');
  }

}

export default TrainingProgram;
