import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class BrokerClientPlanAdmin extends DomainObject {
  @observable firstName;
  @observable lastName;
  @observable email;
  @observable phone;
  @observable primary;
}

export default BrokerClientPlanAdmin;
