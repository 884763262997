import {action, observable, computed} from 'mobx';
import {DomainObject} from 'shared/store';
import {dateFormatter, fullName} from 'shared/tools';
import {types, auth} from 'shared/core';
import uuid from 'uuid';
import moment from 'moment';
import {FunnelStage} from './FunnelStage';
import User from 'stores/users/User';
import {AttachedDocument} from 'stores/documents';
import {InterviewGuide, InterviewGuideAnswer} from 'stores/interview_guides';
import striptags from 'striptags';
import _ from 'lodash';

function isEmail(obj) {
  return obj._type === types.RECRUITING.TIMELINE.EMAIL;
}

function isFeedback(obj) {
  return obj._type === types.RECRUITING.TIMELINE.FEEDBACK;
}

function isStageChange(obj) {
  return obj._type === types.RECRUITING.TIMELINE.STAGE_CHANGE;
}

function isQualificationStatusChange(obj) {
  return obj._type === types.RECRUITING.TIMELINE.QUALIFICATION_STATUS_CHANGE;
}

function isPositionChange(obj) {
  return obj._type === types.RECRUITING.TIMELINE.POSITION_CHANGE;
}

function isUpload(obj) {
  return obj._type === types.RECRUITING.TIMELINE.UPLOAD;
}

function isOffer(obj) {
  return obj._type === types.RECRUITING.TIMELINE.OFFER;
}

function isRating(obj) {
  return obj._type === types.RECRUITING.TIMELINE.RATING;
}

function isInterviewGuideResponse(obj) {
  return obj._type === types.RECRUITING.TIMELINE.INTERVIEW_GUIDE_RESPONSE;
}

class TimelineEntry extends DomainObject {
  @observable id;
  @observable user;
  @observable text;
  @observable createdAt;
  @observable funnelStage;
  @observable _type;

  @action merge(other) {
    super.merge(other, {
      funnelStage: FunnelStage,
      user: User,
      attachments: [AttachedDocument],
      interviewGuide: InterviewGuide,
      answers: [InterviewGuideAnswer]
    });
  }

  @computed get date() {
    return dateFormatter(this.createdAt);
  }

  @computed get name() {
    return fullName(this.user);
  }

  @computed get isFeedback() {
    return isFeedback(this);
  }

  @computed get isStageChange() {
    return isStageChange(this);
  }

  @computed get isQualificationStatusChange() {
    return isQualificationStatusChange(this);
  }

  @computed get isPositionChange() {
    return isPositionChange(this);
  }

  @computed get isEmail() {
    return isEmail(this);
  }

  @computed get isUpload() {
    return isUpload(this);
  }

  @computed get isOffer() {
    return isOffer(this);
  }

  @computed get isRating() {
    return isRating(this);
  }

  @computed get isInterviewGuideResponse() {
    return isInterviewGuideResponse(this);
  }
}

class QualificationStatusChange extends TimelineEntry {
  @observable qualificationStatus;

  @computed get isDisqualified() {
    return this.qualificationStatus === 'disqualified';
  }

  constructor(QualificationStatusChange) {
    super(QualificationStatusChange);
    this._type = types.RECRUITING.TIMELINE.QUALIFICATION_STATUS_CHANGE;
  }
}

class StageChange extends TimelineEntry {
  @observable nextFunnelStageName;
  @observable direction;

  @computed get isForward() {
    return this.direction === 'forwards';
  }

  constructor(stageChange) {
    super(stageChange);
    this._type = types.RECRUITING.TIMELINE.STAGE_CHANGE;
  }
}

class TimelineRating extends TimelineEntry {
  @observable score;

  constructor(rating) {
    super(rating);
    this._type = types.RECRUITING.TIMELINE.RATING;
  }
}

class PositionChange extends TimelineEntry {
  @observable oldPositionName;
  @observable newPositionName;

  constructor(positionChange) {
    super(positionChange);
    this._type = types.RECRUITING.TIMELINE.POSITION_CHANGE;
  }
}

class TimelineFeedback extends TimelineEntry {
  @observable sentiment;
  @observable lexicalState;

  constructor(feedback) {
    super(feedback);
    this._type = types.RECRUITING.TIMELINE.FEEDBACK;
  }

  @computed get isPositiveSentiment() {
    return this.sentiment === 'positive';
  }

  @computed get isNegativeSentiment() {
    return this.sentiment === 'negative';
  }
}

class TimelineEmail extends TimelineEntry {
  @observable subject;
  @observable body;
  @observable lexicalState;
  @observable external;
  @observable status;
  @observable externalUserInitials;
  @observable attachments = [];

  constructor(email) {
    super(email);
    this._type = types.RECRUITING.TIMELINE.EMAIL;
  }

  @computed get inbound() {
    return this.direction === 'inbound';
  }

  @computed get outbound() {
    return this.direction === 'outbound';
  }

  @computed get statusColour() {
    if (this.status === 'unopened') {
      return 'jumbo';
    }
    else if (this.status === 'opened') {
      return 'meadow';
    }
    else if (this.status === 'delivery_failed') {
      return 'scarlet';
    }
  }

  @computed get strippedBody() {
    return striptags(this.body);
  }
}

class TimelineUpload extends TimelineEntry {
  @observable text;
  @observable attachments = [];

  constructor(upload) {
    super(upload);
    this._type = types.RECRUITING.TIMELINE.UPLOAD;
  }
}

class OfferAction extends TimelineEntry {
  @observable offerId;
  @observable action;

  constructor(offer) {
    super(offer);
    this._type = types.RECRUITING.TIMELINE.OFFER;
  }
}

class InterviewGuideResponse extends TimelineEntry {
  @observable interviewGuide;
  @observable answers = [];

  constructor(interviewGuideResponse) {
    super(interviewGuideResponse);
    this._type = types.RECRUITING.TIMELINE.INTERVIEW_GUIDE_RESPONSE;
  }

  @computed get sortedAnswers() {
    return _.orderBy(this.answers, ['question.order'], ['asc']);
  }

  @computed get hasAnswerContent() {
    let hasContent = false;
    _.forEach(this.answers, (answer) => {
      if (answer.content) hasContent = true;
    });

    return hasContent;
  }
}

TimelineEntry.fromJS = (json) => {
  if (isFeedback(json)) {
    return new TimelineFeedback(json);
  } else if (isStageChange(json)) {
    return new StageChange(json);
  } else if (isQualificationStatusChange(json)) {
    return new QualificationStatusChange(json);
  } else if (isEmail(json)) {
    return new TimelineEmail(json);
  } else if (isPositionChange(json)) {
    return new PositionChange(json);
  } else if (isUpload(json)) {
    return new TimelineUpload(json);
  } else if (isOffer(json)) {
    return new OfferAction(json);
  } else if (isRating(json)) {
    return new TimelineRating(json);
  } else if (isInterviewGuideResponse(json)) {
    return new InterviewGuideResponse(json);
  }

  throw new Error(`TimelineEntry type ${json._type} is not supported.`);
};

TimelineEntry.createFeedback = (json) => {
  const feedback = new TimelineFeedback({
    id: uuid.v4(),
    user: auth.user,
    createdAt: moment().format()
  });
  feedback.merge(json);
  return feedback;
};

TimelineEntry.createRating = (json) => {
  const rating = new TimelineRating({
    id: uuid.v4(),
    user: auth.user,
    createdAt: moment().format()
  });
  rating.merge(json);
  return rating;
};

TimelineEntry.createEmail = (json) => {
  const email = new TimelineEmail({
    id: uuid.v4(),
    user: auth.user,
    createdAt: moment().format()
  });
  email.merge(json);
  return email;
};

TimelineEntry.createPositionChange = (json) => {
  const change = new PositionChange({
    id: uuid.v4(),
    user: auth.user,
    createdAt: moment().format(),
  });
  change.merge(json);
  return change;
};

TimelineEntry.createUpload = (json) => {
  const change = new TimelineUpload({
    id: uuid.v4(),
    user: auth.user,
    createdAt: moment().format()
  });
  change.merge(json);
  return change;
};

TimelineEntry.createInterviewGuideResponse = (json) => {
  const response = new InterviewGuideResponse({
    id: uuid.v4(),
    user: auth.user,
    createdAt: moment().format()
  });
  response.merge(json);
  return response;
};

export default TimelineEntry;
