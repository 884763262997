import {observable} from 'mobx';
import ExternalRoleViewModel from './ExternalRoleViewModel';
import _ from 'lodash';

class ExternalUserViewModel {
  @observable user;
  @observable roles = [];

  constructor(user, context) {
    this.user = user;
    this.roles = context.allRoles.map(r => new ExternalRoleViewModel(r, this));
  }
}

export default ExternalUserViewModel;
