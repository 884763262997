import React from 'react';
import {FormattedMessage, FormattedDate} from 'react-intl';
import {Modal} from 'components/modals';
import {observer} from 'mobx-react';

const UpcomingHolidaysModal = observer(({uiState}) => {
  const {upcomingHolidays} = uiState;

  return (
    <Modal isOpen={uiState.upcomingHolidaysModalOpen} onHide={() => uiState.closeUpcomingHolidaysModal()} size='md'>
      <div className='h2 medium tuatara mb3 px2'>
        <FormattedMessage id='employees.profile.time_off.Holidays'/>
      </div>

      <div className='clearfix mb2 px2'>
        <div className='col col-7'>
          <div className='h6 waterloo caps medium'>
            <FormattedMessage id='employees.profile.time_off.Holiday'/>
          </div>
        </div>
        <div className='col col-5'>
          <div className='h6 waterloo caps medium'>
            <FormattedMessage id='employees.profile.time_off.Date'/>
          </div>
        </div>
      </div>
      <div className='max-height-500 overflow-auto'>
        {upcomingHolidays.map((holiday) =>
          <div className='clearfix p2 border-top' key={holiday.id}>
            <div className='col col-7 py1'>{holiday.name}</div>
            <div className='col col-5 py1'>
              <div className="clearfix table full-width">
                <div className="col-2 table-cell align-middle">
                  <div className='h6 medium waterloo'>
                    <FormattedDate value={holiday.date} weekday='short'/>
                  </div>
                </div>
                <div className="col-10 table-cell align-middle">
                  {holiday.dateView}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
});

export default UpcomingHolidaysModal;
