import en from './en';
import fr from './fr';
import {auth} from 'shared/core';

function getLocalization() {
  if (process.env.__DEV__) {
    if (auth.locale === 'test') return require('./test');
  }

  switch (auth.locale) {
    case 'en': return en;
    case 'fr': return fr;
    default: throw new Error(`Language ${auth.locale} is not supported by DataTables. Download a language pack for ${auth.locale} from here: https://datatables.net/plug-ins/i18n/`);
  }
}

export default getLocalization();
