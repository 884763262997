import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import GoogleLogo from 'img/google-logo@2x.png';
import OktaLogo from 'img/okta-logo@2x.png';
import MicrosoftLogo from 'img/microsoft-logo@2x.png';
import {Button} from 'components';
import OAuthState from 'components/login/state/OAuthState';

const LOGOS = {
  google: GoogleLogo,
  microsoft: MicrosoftLogo,
  okta: OktaLogo
};

const AuthButton = observer(({uiState, provider}) => {
  const {context} = uiState;
  const fullWidth = ['connect', 'company_sso_setup'].includes(context);

  return (
    <Button
      className={`${fullWidth ? '' : 'full-width'}`}
      size='lg'
      trait='default'
      onClick={() => uiState.onConnectButtonClicked(provider)}
    >
      <img src={LOGOS[provider]} alt='logo' height='18' className='inline-block' style={{margin: '0 24px 0 0'}}/>
      <div className={fullWidth ? '' : 'col col-6'}>
        <FormattedMessage id={`login.${provider.toUpperCase()}_SIGN_IN`}/>
      </div>
    </Button>
  );
});

const OAuthButtons = observer(({uiState, className}) => {
  const {providers} = uiState;

  return <div className={`flex flex-column align-items-start row-gap-2 ${className ? className : ''}`}>
    {providers.map((provider) => <AuthButton provider={provider} uiState={uiState}/> )}
  </div>;
});

export default withState(OAuthButtons, OAuthState);
