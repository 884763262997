import React from 'react';
import {observer} from 'mobx-react';
import {ReportDownloadState} from '../state';
import {withState, t} from 'shared/core';
import DownloadModal from './DownloadModal';
import {DropdownSelect} from 'components';

const ReportDownloadButton = observer(({uiState}) => {
  const DOWNLOAD_OPTIONS = [
    {
      name: 'CSV',
      fileType: 'csv'
    },
    {
      name: 'Excel',
      fileType: 'xlsx'
    }
  ];

  return (
    <div>
      <DropdownSelect selected={t('reports.Export')}>
        {DOWNLOAD_OPTIONS.map(option => {
          return (
            <DropdownSelect.Option
              key={option.fileType}
              onClick={() => uiState.requestDownload(option.fileType)}
              text={`${option.name} (.${option.fileType})`}
            />
          );
        })}
      </DropdownSelect>
      <DownloadModal uiState={uiState}/>
    </div>
  );
});

export default withState(ReportDownloadButton, ReportDownloadState);
