import {observable, action, computed} from 'mobx';
import {t} from 'shared/core';
import {fullName} from 'shared/tools';
import _ from 'lodash';

class TaskTemplateForm {
  @observable taskTemplate;
  @observable employees = [];

  constructor(taskTemplate, employees, hasManager = true) {
    this.taskTemplate = taskTemplate;
    this.employees = employees;
    this.hasManager = hasManager;
  }

  @action update(partialTaskTemplate) {
    this.taskTemplate.merge(partialTaskTemplate);
  }

  @computed get assigneeOptions() {
    const options = [
      {
        value: 'employee',
        name: t('tasks.Employee')
      },
      ...this.employees.map(e => ({
        value: e.id,
        name: fullName(e),
        employee: e
      }))
    ];

    if (this.hasManager) {
      options.unshift(
        {
          value: 'manager',
          name: t('tasks.Manager')
        }
      );
    }

    return options;
  }

  @computed get assignee() {
    if (this.taskTemplate.assigneeType === 'manager' || this.taskTemplate.assigneeType === 'employee') {
      return this.taskTemplate.assigneeType;
    } else if (this.taskTemplate.assignee) {
      return this.taskTemplate.assignee.id;
    }

    return '';
  }

  set assignee(value) {
    if (value === 'manager' || value === 'employee') {
      this.taskTemplate.assigneeType = value;
      this.taskTemplate.assignee = null;
    } else {
      const employee = _.find(this.assigneeOptions, {value}).employee;
      this.taskTemplate.assignee = employee;
      this.taskTemplate.assigneeType = null;
    }

    if (this.offboardAssignedToEmployee && this.selectedDueDateOption === 'offset') {
      this.selectedPreposition = 'before';
    }
  }

  @computed get selectedDueDateOption() {
    if (this.taskTemplate.relativeDueDateValue === 0) {
      return 'zero';
    } else if (this.taskTemplate.relativeDueDateValue) {
      return 'offset';
    } else {
      return 'none';
    }
  }

  set selectedDueDateOption(value) {
    if (value === 'zero') {
      this.taskTemplate.relativeDueDateUnit = 'days';
      this.taskTemplate.relativeDueDateValue = 0;
    } else if (value === 'offset') {
      this.taskTemplate.relativeDueDateUnit = 'days';
      this.taskTemplate.relativeDueDateValue = -1;
    } else {
      this.taskTemplate.relativeDueDateUnit = null;
      this.taskTemplate.relativeDueDateValue = null;
    }
  }

  set relativeDueDateValue(value) {
    let transformedValue;

    if (this.taskTemplate.relativeDueDateValue < 0) {
      transformedValue = -value;
    } else {
      transformedValue = value;
    }

    this.update({relativeDueDateValue: transformedValue});
    if (this.offboardAssignedToEmployee) {
      this.selectedPreposition = !!value ? 'before' : '';
    }
  }

  @computed get selectedUnit() {
    if (!this.taskTemplate.relativeDueDateValue) {
      return '';
    }

    if (!this.taskTemplate.relativeDueDateUnit) {
      this.taskTemplate.relativeDueDateUnit = 'days';
    }

    return this.taskTemplate.relativeDueDateUnit;
  }

  set selectedUnit(value) {
    if (value) {
      if (this.taskTemplate.relativeDueDateValue === null) {
        this.taskTemplate.relativeDueDateValue = -1;
      }
      this.taskTemplate.relativeDueDateUnit = value;
    }
  }

  @computed get availablePrepositions() {
    const prepositions = [{ value: 'before', text: 'before' }];

    if (!this.offboardAssignedToEmployee) {
      prepositions.push({ value: 'from', text: 'after' });
    }

    return prepositions;
  }

  @computed get selectedPreposition() {
    if (this.taskTemplate.relativeDueDateValue > 0) {
      return 'from';
    } else if (this.taskTemplate.relativeDueDateValue < 0) {
      return 'before';
    }
    return '';
  }

  set selectedPreposition(value) {
    if (value === 'before') {
      this.taskTemplate.relativeDueDateValue = -Math.abs(this.taskTemplate.relativeDueDateValue) || -1;
    } else if (value === 'from') {
      this.taskTemplate.relativeDueDateValue = Math.abs(this.taskTemplate.relativeDueDateValue) || 1;
    } else {
      this.taskTemplate.relativeDueDateValue = null;
    }
  }

  @computed get offboardAssignedToEmployee() {
    return this.taskTemplate.templateType === 'offboarding' && this.taskTemplate.assigneeType === 'employee';
  }

  validate() {
    const errors = {};

    if (_.chain(this.taskTemplate.name).trim().isEmpty().value()) {
      errors.name = t('tasks.Task Name is required');
    }

    if (_.chain(this.taskTemplate.description).trim().isEmpty().value()) {
      errors.description = t('tasks.Description is required');
    }

    return errors;
  }
}

export default TaskTemplateForm;
