import React from 'react';
import {FormattedMessage} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {auth} from 'shared/core';
import {Layout} from 'components';

const RecruitingSubnav = () => {
  return (
    <div className='Subnav Subnav--react'>
      <Layout>
        <div className='Subnav-header'><FormattedMessage id='recruiting.subnav.Recruiting'/></div>
        <div className='Subnav-list list-reset'>
          <li><NavLink to='/positions' activeClassName='active'><FormattedMessage id='recruiting.subnav.Positions'/></NavLink></li>
          <li><NavLink to='/candidates' activeClassName='active'><FormattedMessage id='recruiting.subnav.Candidates'/></NavLink></li>
          {auth.hasAccess('::MANAGE_ATS') && <li><NavLink to='/interview-guides' activeClassName='active'><FormattedMessage id='recruiting.subnav.Interview Guides'/></NavLink></li>}
          {auth.hasAccess('::MANAGE_ATS') && <li><NavLink to='/email-templates' activeClassName='active'><FormattedMessage id='recruiting.subnav.Email Templates'/></NavLink></li>}
          {auth.hasAccess('::MANAGE_ATS') && <li><NavLink to='/job-site' activeClassName='active'><FormattedMessage id='recruiting.subnav.Job Site'/></NavLink></li>}
        </div>
      </Layout>
    </div>
  );
};

export default RecruitingSubnav;
