import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';

const SubmitRequest = observer(() => {
  return (
    <Panel>
      <Panel.Header title={t('pa_dashboard.submit_request.Need Help?')}/>
        <Link to='/submit_request'>
          <i className='material-icons h3 dodger align-middle mr1'>{'chat_bubble'}</i>
          <FormattedMessage id='pa_dashboard.submit_request.Submit a request'/>
        </Link>
    </Panel>
  );
});

export default SubmitRequest;
