import React from 'react';
import {observer} from 'mobx-react';
import {types, endpoints, t} from 'shared/core';
import CustomReport from 'stores/reports/CustomReport';
import {ReportName} from 'reports/custom';
import {AvatarList, InteractiveTable} from 'components';
import {Link} from 'react-router-dom';
import ShareReportModal from './ShareReportModal';

const Report = observer(({model}) => {
  return (
    <Link to={`custom/${model.id}`} className='dodger TextLink'>
      <ReportName report={model}/>
    </Link>
  );
});

const AccessibleByUsers = observer(({model}) => {
  return <AvatarList employees={model.usersWithAccess}/>;
});

const LOCALIZATION = {
  emptyState: 'reports.custom.No reports to show',
  interactive: {
    addModel: 'reports.custom.Create report'
  },
  removeModal: {
    header: 'reports.custom.Remove report?',
    subHeader: 'reports.custom.Are you sure you want to remove this report?'
  }
};

const COLUMNS = [
  {
    header: 'reports.custom.Name',
    component: Report,
    width: 8
  },
  {
    header: 'reports.custom.Accessible By',
    component: AccessibleByUsers,
    width: 3
  }
];

const MyReportsTable = observer(({uiState}) => {
  return (
    <React.Fragment>
      <InteractiveTable
        uiState={uiState}
        title={t('reports.custom.My Reports')}
        columns={COLUMNS}
        localization={LOCALIZATION}
        onMount={agent => uiState.setInteractiveAgent(agent)}
        onAdd={() => uiState.createNewReport()}
        onRemove={report => uiState.deleteReport(report)}
        editRoute='/custom/:id/edit'
        customLinks={report => uiState.customLinksFor(report)}
        proxy={{
          type: 'async',
          modelType: types.CUSTOM_REPORT,
          model: CustomReport,
          endpoint: endpoints.CUSTOM_REPORTS.ALL,
          pageSize: 10
        }}
      />
      <ShareReportModal uiState={uiState}/>
    </React.Fragment>
  );
});

export default MyReportsTable;
