import React from 'react';
import {observer} from 'mobx-react';
import BackToIndex from './BackToIndex';
import SubNav from './SubNav';
import DocumentTitle from 'components/DocumentTitle';

const Display = observer(({backTitle, backPath, back, title, toolbar, children, documentTitle, nested, onBackClick}) => {
  const _back = back || !!backTitle || !!backPath || !!onBackClick;
  const docTitle = documentTitle || title;

  return (
    <div className='print-hide'>
      {!!docTitle && <DocumentTitle title={docTitle}/>}
      {_back && <BackToIndex nested={nested} title={backTitle} path={backPath} hasTitle={!!title} onBackClick={onBackClick}/>}
      {!!title && <SubNav title={title} className={_back ? '' : 'pt1'} toolbar={toolbar}>{children}</SubNav>}
    </div>
  );
});

export default Display;
