import React from 'react';
import {observer} from 'mobx-react';
import {Panel, QuickTip} from 'components';
import {t, auth} from 'shared/core';
import TerminationDetailsForm from './TerminationDetailsForm';
import ReassignManagerForm from './ReassignManagerForm';
import BenefitsForm from './BenefitsForm';
import TerminationConfirmationModal from './TerminationConfirmationModal';
import OffboardingTasksForm from './OffboardingTasksForm';

const TerminationQuickTip = observer(({uiState}) => {
  const {editRecord, employee} = uiState;
  if (!editRecord.isNew || !auth.moduleEnabled('directory')) return null;

  return (
    <div className='mb3'>
      <QuickTip
        trait='info'
        header={t('employees.profile.role.TERMINATION_TITLE')}
        paragraphs={[t(`employees.profile.role.${employee.benefits ? 'TERMINATION_DESC_BENEFITS_ENABLED' : 'TERMINATION_DESC'}`)]}
      />
    </div>
  );
});

const EmployeeErrorQuickTip = observer(({error}) => {
  if (!error) return null;

  return (
    <div className='mb3'>
      <QuickTip trait='error' paragraphs={[error]}/>
    </div>
  );
});


const TerminationRecordForm = observer(({uiState}) => {
  return (
    <div>
      <TerminationQuickTip uiState={uiState} />
      <EmployeeErrorQuickTip error={uiState.errors.employee} />
      <Panel.Stack loose>
        <TerminationDetailsForm uiState={uiState} />
        <ReassignManagerForm uiState={uiState} />
        <BenefitsForm uiState={uiState} />
        <OffboardingTasksForm uiState={uiState} />
      </Panel.Stack>
      <TerminationConfirmationModal uiState={uiState} />
    </div>
  );
});

export default TerminationRecordForm;
