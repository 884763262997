import _ from 'lodash';
import queryString from 'query-string';
import {action, computed, observable} from 'mobx';
import {successAlert, openFileStack} from 'shared/tools';
import Candidate from 'stores/recruiting/Candidate';
import CandidateViewModel from 'recruiting/state/CandidateViewModel';
import {t} from 'shared/core';
import Answer from 'stores/recruiting/Answer';
import recruitingStore from 'stores/recruiting/RecruitingStore';

class CandidateFormState {
  store = recruitingStore;
  history;
  match;
  enforceRequiredFields;

  @observable candidateViewModel = new CandidateViewModel(new Candidate());
  @observable errors = {};
  @observable submitted = false;
  @observable position;
  @observable isUploading = false;

  receiveProps({history, match, enforceRequiredFields = true}) {
    this.history = history;
    this.match = match;
    this.enforceRequiredFields = enforceRequiredFields;
  }

  @action async load() {
    this.position = await this.store.loadSingle(this.match.params.id);

    for (const question of this.position.questions) {
      this.candidate.answers.push(new Answer({question: question}));
    }
  }

  @action onUploadStarted() {
    this.isUploading = true;
  }

  @action onUploadFinished() {
    this.isUploading = false;
  }

  @action calculateQuestionRequired(question) {
    return this.enforceRequiredFields && question.required;
  }

  @action async createCandidate() {
    const {errors, model} = await this.store.postCandidate(this.candidate, this.match.params.id);
    if (model) {
      this.history.goBack();
      successAlert(t('recruiting.alerts.Candidate successfully added'));
    }
    this.errors = errors;
  }

  @action async submitApplication() {
    const params = queryString.parse(this.history.location.search);
    if (params.origin) {
      this.candidate.merge({origin: params.origin});
    }
    const {errors, model} = await this.store.postApplication(this.candidate, this.match.params.slug, this.match.params.id);
    if (model) {
      this.submitted = true;
    }
    this.errors = errors;
  }

  @computed get candidate() {
    return this.candidateViewModel.candidate;
  }

  @computed get editingCandidate() {
    return this.candidateViewModel.candidate;
  }

  uploadFileForAnswer(answer) {
    openFileStack({
      storeTo: { path: 'resumes/' }
    }, file => answer.merge({attachment: file}));
  }
}

export default CandidateFormState;
