import _ from 'lodash';
import moment from 'moment';

function convertEnrolmentsToEpochEvents(enrolments) {
  const events = [];

  for (const enrolment of enrolments) {
    const startEpoch = moment(enrolment.firstEffectiveDate).unix();
    if (enrolment.firstEffectiveDate) {
      events.push({epoch: startEpoch, change: 1});
    }

    const endEpoch = enrolment.lastDayOfBenefits ? moment(enrolment.lastDayOfBenefits).unix() : null;
    if (endEpoch) {
      events.push({epoch: endEpoch, change: -1});
    }
  }

  return _.chain(events)
    .groupBy('epoch')
    .map((value, key) => ({epoch: value[0].epoch, change: _.sumBy(value, 'change')}))
    .orderBy('epoch', 'asc')
    .value();
}

function augmentWithRunningTotal(events) {
  let runningTotal = 0;
  for (const e of events) {
    runningTotal += e.change;
    e.runningTotal = runningTotal;
  }
  return events;
}

function computeDataSeries(enrolments) {
  const events = convertEnrolmentsToEpochEvents(enrolments);
  const eventsWithRunningTotal = augmentWithRunningTotal(events);
  return _.map(eventsWithRunningTotal, e => [
    e.epoch *= 1000,
    e.runningTotal
  ]);
}

export default computeDataSeries;

