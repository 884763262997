import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {calendarDate} from 'shared/tools';
import {Employee} from 'stores/employees';
import Survey from './Survey';

class SurveyEmployeeStatus extends DomainObject {
  @observable submittedAt;
  @observable employee;
  @observable dueDate;
  @observable submitted;

  @action merge(other) {
    super.merge(other, {
      employee: Employee,
      survey: Survey,
      _dates: ['submittedAt', 'dueDate']
    });
  }

  @computed get dueDateView() {
    return calendarDate(this.dueDate);
  }

  @computed get submittedAtView() {
    return calendarDate(this.submittedAt);
  }
}

export default SurveyEmployeeStatus;
