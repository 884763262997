function multipleChoiceTrendsOptions(options = {}) {
  return {
    title: null,
    colors: [
      '#00B7B3',
      '#217AF2',
      '#1C17B8',
      '#9A3BE8',
      '#D61CBB',
      '#F64A6C',
      '#FD6C21',
      '#F4AC29',
      '#80D137',
      '#159488',
      '#1FC1B6',
      '#1876F2',
      '#1C16B6',
      '#9836E7',
      '#C71AAD',
      '#F52952',
      '#FD5D0D',
      '#F3A616',
      '#75C82D',
      '#148F83'
    ],
    legend: {
      itemStyle: {
        fontSize: '14px',
        fontFamily: 'GT Walsheim, sans-serif',
        color: '#717B89',
        fontWeight: '200'
      },
      symbolHeight: 10,
      symbolWidth: 10,
      symbolPadding: 10,
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      x: 0,
      y: 0,
      margin: 0,
      itemWidth: 0,
      maxHeight: 90,
      itemMarginTop: 10,
      itemMarginBottom: 0
    },
    chart: {
      type: 'column',
      height: 350
    },
    yAxis: {
      title: {
        enabled: false
      },
      tickInterval: 1,
    },
    xAxis: {
      type: 'datetime'
    },
    plotOptions: {
      column: {
        minPointLength: 2,
        cursor: options.onClick ? 'pointer' : 'auto',
        events: {
          click: options.onClick
        }
      }
    },
    credits: {
      enabled: false
    }
  };
}

export default multipleChoiceTrendsOptions;
