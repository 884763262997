import {action, observable, computed, toJS} from 'mobx';
import {successAlert} from 'shared/tools';
import {DomainStore} from 'shared/store';
import {endpoints, types, t} from 'shared/core';
import {VisibilitySetting} from 'stores/visibility_settings';
import EMPLOYMENT_TYPES from 'stores/employees/employmentTypes';
import _ from 'lodash';

class VisibilitySettingsState {
  store = new DomainStore();

  @observable employees;
  @observable locations;
  @observable departments;
  @observable visibilitySettings;
  @observable editingVisibilitySetting = {};
  @observable editingVisibilitySettingModalOpen = false;
  @observable errors = {};

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.LOCATIONS.ALL,
      endpoints.DEPARTMENTS,
      endpoints.VISIBILITY_SETTINGS
    ]);

    this.employees = this.store.getEmployees();
    this.locations = this.store.getLocations();
    this.departments = this.store.getDepartments();
    this.visibilitySettings = this.store._getAll(types.VISIBILITY_SETTING, VisibilitySetting);
  }

  @action async updateVisibilitySetting(visibilitySetting, value) {
    visibilitySetting.merge({enabled: value});
    const {model, errors} = await this.store.patch(visibilitySetting);
    this.errors = errors;

    if (model) {
      successAlert(t('company_settings.visibility_settings.Visibility setting updated.'));
    }
  }

  @action openEditVisibilitySettingModal(visibilitySetting) {
    this.errors = {};
    this.editingVisibilitySetting = new VisibilitySetting(toJS(visibilitySetting));
    this.editingVisibilitySettingModalOpen = true;
  }

  @action closeVisibilitySettingModal() {
    this.editingVisibilitySettingModalOpen = false;
  }

  @action filterEmptyRules() {
    this.editingVisibilitySetting.segment.rules = _.reject(this.editingVisibilitySetting.segment.rules, r => _.isEmpty(r.parameters));
  }

  @action async saveVisibilitySetting() {
    this.filterEmptyRules();

    const {model, errors} = await this.store.patch(this.editingVisibilitySetting);

    this.errors = errors;

    if (model) {
      await this.load();
      this.closeVisibilitySettingModal();
    }
  }

  @computed get birthdayCelebration() {
    return _.find(this.visibilitySettings, {settingType: 'birthday_celebration'});
  }

  @computed get startDateCelebration() {
    return _.find(this.visibilitySettings, {settingType: 'start_date_celebration'});
  }

  @computed get anniversaryCelebration() {
    return _.find(this.visibilitySettings, {settingType: 'anniversary_celebration'});
  }

  @computed get staffCount() {
    return _.find(this.visibilitySettings, {settingType: 'staff_count'});
  }

  @computed get peopleOffToday() {
    return _.find(this.visibilitySettings, {settingType: 'people_off_today'});
  }

  @computed get peopleDirectory() {
    return _.find(this.visibilitySettings, {settingType: 'people_directory'});
  }

  @computed get employmentTypes() {
    return EMPLOYMENT_TYPES.map(type => {
      return {id: type, name: t(type)};
    });
  }

  formattedRulesForSegment(segment) {
    let selectedRules = [];

    if (segment.locationRule) {
      const selectedLocations = _.filter(this.locations, l => _.includes(segment.locationRule.parameters, l.id));
      selectedRules = _.concat(selectedRules, selectedLocations);
    }

    if (segment.departmentRule) {
      const selectedDepartments = _.filter(this.departments, l => _.includes(segment.departmentRule.parameters, l.id));
      selectedRules = _.concat(selectedRules, selectedDepartments);
    }

    if (segment.employmentTypeRule) {
      const selectedEmploymentTypes = _.filter(this.employmentTypes, l => _.includes(segment.employmentTypeRule.parameters, l.id));
      selectedRules = _.concat(selectedRules, selectedEmploymentTypes);
    }

    return _.map(selectedRules, 'name');
  }
}

export default VisibilitySettingsState;
