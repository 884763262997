import React from 'react';
import {observer} from 'mobx-react';
import BenefitUpdates from './BenefitUpdates';
import Subnav from 'benefits/components/Subnav';

const BenefitUpdatesPage = observer(({scope}) => {
  return (
    <div>
      <Subnav scope={scope}/>
      <BenefitUpdates/>
    </div>
  );
});

export default BenefitUpdatesPage;
