import React from 'react';
import {withState, t} from 'shared/core';
import {observer} from 'mobx-react';
import {Display, Panel, Layout, DatePicker, TextArea, FormLayout, Button, CheckboxWithLabel, FormError} from 'components';
import {EmployeeBenefitUnenrolmentFormState} from '../state';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const EmployeeBenefitUnenrolmentForm = observer(({uiState}) => {
  const {errors, employee, enrolmentViewModels, showUnenrolToggle} = uiState;

  return (
    <div>
      <Display
        backTitle={t('benefits.benefit_info.edit.Benefit Enrolments')}
        backPath={'/benefit_enrolments'}
        title={t('benefit_enrolment.unenrol.REMOVE_FROM_BENEFITS', { name: employee.name })}
      />
      <Layout>
        <Panel.Stack>
          <Panel>
            <Panel.Header
              title={t('benefit_enrolment.unenrol.Terminating Benefits Coverage')}
              description={t('benefit_enrolment.unenrol.unenrol_description')}
            />
          </Panel>
          {enrolmentViewModels.map((enrolment, index) =>
            <Panel key={enrolment.id}>
              <Panel.Subheader title={enrolment.benefitClass.carrierName}/>
              {showUnenrolToggle && <CheckboxWithLabel
                className='my2'
                translatable={false}
                label={
                  t(
                    'benefit_enrolment.unenrol.KEEP_EMPLOYEE_ENROLLED',
                    { name: employee.firstNameDisplay, carrier: enrolment.benefitClass.carrierName }
                  )
                }
                checked={enrolment.selectedToKeep}
                onChange={() => uiState.toggleUnenrol(enrolment)}
              />}
              {!enrolment.selectedToKeep &&
                <FormLayout>
                  <DatePicker
                    value={enrolment.endDate}
                    onChange={date => enrolment.updateEndDate(date)}
                    label='benefit_enrolment.unenrol.Last Day of Benefits'
                  />
                  <TextArea
                    value={enrolment.reasonForUnenrolment}
                    onChange={e => enrolment.updateReasonForUnenrolment(e.target.value)}
                    errorMessage={_.get(errors, `employeeBenefitEnrolments[${index}].reasonForTerminationOfCoverage`)}
                    label='benefit_enrolment.unenrol.Reason for Termination of Coverage'
                  />
                </FormLayout>
              }
            </Panel>
          )}
          <Panel>
            <div className='mb2'>
              <FormError message={errors.base}/>
            </div>
            <div className='flex justify-content-end'>
              <Button onClick={() => uiState.unenrolEmployee()}>
                <FormattedMessage id='benefit_enrolment.unenrol.Save'/>
              </Button>
            </div>
          </Panel>
        </Panel.Stack>
      </Layout>
    </div>
  );
});

export default withState(EmployeeBenefitUnenrolmentForm, EmployeeBenefitUnenrolmentFormState);
