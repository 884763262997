import React from 'react';
import {observer} from 'mobx-react';
import FILTERS from 'reports/custom/edit/state/filters';
import {FilterDropdownMenu, FilterDropdownToggle} from 'components';
import {FormattedMessage} from 'react-intl';

const FilterCategories = observer(({uiState}) => {
  return (
    <div>
      {FILTERS.map(c =>
        <FilterDropdownMenu.Checkbox key={c}
                                     onChange={(e) => uiState.toggleFilterRow(c, e.checked)}
                                     checked={uiState.filterCategorySelected(c)}
                                     className='bg-hover-haze py1 px2 rounded'>
          <FormattedMessage id={`reports.${c}`}/>
        </FilterDropdownMenu.Checkbox>
      )}
    </div>
  );
});

const AddFilterButton = observer(({uiState}) => {
  return (
    <div>
      <FilterDropdownToggle
        isOpen={uiState.categoryFilterOpen}
        onOpen={() => uiState.openCategoryFilter()}
        onCancel={() => uiState.closeCategoryFilter()}>
          <i className='material-icons waterloo h2'>{'filter_list'}</i>
          <span className='pl1'><FormattedMessage id='reports.custom.edit.Add Filter'/></span>
          <i className='material-icons waterloo h3'>{'arrow_drop_down'}</i>
      </FilterDropdownToggle>
      <FilterDropdownMenu position='left'
                          isOpen={uiState.categoryFilterOpen}
                          onCancel={() => uiState.closeCategoryFilter()}
                          showDropdownButtons={false}
                          scrollable={false}>
        <FilterCategories uiState={uiState}/>
      </FilterDropdownMenu>
    </div>
  );
});

export default AddFilterButton;
