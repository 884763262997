import {action, computed, observable} from 'mobx';
import _ from 'lodash';

const LANGUAGE_OPTIONS = [
  {
    id: 'en',
    name: 'English'
  },
  {
    id: 'fr',
    name: 'Français'
  }
];

class EmployeeDocumentState {
  @observable document;

  languageOptions = LANGUAGE_OPTIONS;

  receiveProps({document}) {
    this.document = document;
  }

  @action selectLanguage(option) {
    this.document.locale = option.id;
  }

  @computed get selectedLanguage() {
    return _.find(this.languageOptions, {id: this.document.locale});
  }
}

export default EmployeeDocumentState;
