import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const Header = ({app}) => {
  return (
    <div className='flex align-items-middle'>
      <img className='zapier-app-image mr2' src={app.image} alt={app.title}/>
      {app.title}
    </div>
  );
};

const PopularAppModal = observer(({isOpen, uiState}) => {
  const {selectedPopularApp} = uiState;

  return (
    <Modal
      isOpen={isOpen}
      onHide={() => uiState.closePopularAppModal()}
      size='md'
      title={<Header app={selectedPopularApp}/>}
      translateTitle={false}
    >
      <div className='mb2'>
        <FormattedMessage id={selectedPopularApp.appDescription} values={{link: selectedPopularApp.appLink}}/>
      </div>
      <div className='mb2'>
        <FormattedMessage id={selectedPopularApp.integrationDescription}/>
      </div>
      <ModalButtons
        onSave={() => window.open(selectedPopularApp.url, '_blank').focus()}
        saveCaption='integrations.Integrate'
        onCancel={() => uiState.closePopularAppModal()}
        cancelCaption='integrations.Close'
      />
    </Modal>
  );
});

export default PopularAppModal;
