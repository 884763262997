import React from 'react';
import {observer} from 'mobx-react';
import TemplateDirectoryWithLoader from './TemplateDirectoryWithLoader';
import Subnav from 'performance_reviews/components/Subnav';

const TemplateDirectory = observer(({history, scope}) => {
  return (
    <div>
      <Subnav scope={scope}/>
      <TemplateDirectoryWithLoader history={history}/>
    </div>
  );
});

export default TemplateDirectory;
