import React from 'react';
import {observer} from 'mobx-react';
import {InteractiveTable} from 'components';
import {endpoints, types, t} from 'shared/core';
import AddUserModal from './AddUserModal';
import {BrokerInvitation} from 'stores/broker_portal';

const COLUMNS = [
  {
    header: 'Email Address',
    width: 6,
    attribute: 'email'
  },
  {
    header: 'Status',
    width: 5,
    render: model => t(`broker_portal.broker_settings.${model.status}`)
  }
];

const LOCALIZATION = {
  interactive: {
    addModel: 'broker_portal.broker_settings.Invite User'
  },
  removeModal: {
    header: 'broker_portal.broker_settings.Remove Invitation?',
    subHeader: 'broker_portal.broker_settings.Are you sure you want to remove this invitation?',
    body: 'broker_portal.broker_settings.You will no longer be able to sign up through the email associated to this invitation.'
  }
};

const BrokerInvitationsList = observer(({uiState}) => {
  return (
    <div>
      <InteractiveTable
        sortable={false}
        searchable={false}
        title={t('broker_portal.broker_settings.User Invitations')}
        columns={COLUMNS}
        localization={LOCALIZATION}
        onAdd={() => uiState.openAddUserModal()}
        onRemove={invitation => uiState.removeInvite(invitation)}
        onMount={agent => uiState.setAgent(agent)}
        proxy={{
          type: 'async',
          modelType: types.BROKER_PORTAL.BROKER_INVITATION,
          model: BrokerInvitation,
          endpoint: endpoints.BROKER_PORTAL.BROKER_INVITATIONS
        }}
      />
      <AddUserModal uiState={uiState}/>
    </div>
  );
});

export default BrokerInvitationsList;
