import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class Broker extends DomainObject {
  @observable key;
  @observable name;
  @observable mgaPlus;
}

export default Broker;
