import React from 'react';
import {observer} from 'mobx-react';
import TableIcon from 'img/table-icon@2x.png';
import ReportRowContent from './ReportRowContent';

const ReportRowWithRedirect = observer(({name, path, visible}) => {
  if (!visible) return null;

  return (
    <div className='border-bottom py2 relative'>
      <a className='TextLink dodger' href={path}>
        <ReportRowContent name={name} icon={TableIcon}/>
      </a>
    </div>
  );
});

export default ReportRowWithRedirect;
