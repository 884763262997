import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {HireFlowState} from '../state';
import Step from './steps';
import {Flow} from 'components';
import EmployeeName from './EmployeeName';
import _ from 'lodash';

const HireFlow = observer(({uiState}) => {
  const {employee} = uiState;

  return (
    <Flow
      name={EmployeeName}
      backTitle={t('employees.hire.People')}
      backRoute='/directory'
      steps={uiState.steps}
      component={Step}
      uiState={uiState}
      baseUrl={uiState.isNew ? '/' : `/hire/${uiState.match.params.id}`}
      onStepSubmitted={step => uiState.onStepSubmitted(step)}
      onStepChanged={step => uiState.onStepChanged(step)}
      onDeleted={employee.isNew ? null : () => uiState.deleteDraft()}
      nextEnabled={uiState.nextEnabled}
      localization={{
        removeModal: {
          header: 'employees.hire.Delete Draft Employee',
          subHeader: 'employees.hire.Are you sure you want to delete this draft employee?',
          body: 'employees.hire.All of the progress made to configure this employee will be permanentely lost.'
        }
      }}
    />
  );
});

export default withState(HireFlow, HireFlowState);
