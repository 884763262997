import {action, computed, observable} from 'mobx';
import {successAlert} from 'shared/tools';
import CompanyDocument from 'stores/documents/CompanyDocument';
import {t} from 'shared/core';
import _ from 'lodash';
/*global pdfjsLib*/

class CompanyDocumentUploadState {
  store;
  history;
  match;

  @observable acceptedMimeTypes;
  @observable companyDocument;
  @observable isUploading;
  @observable errors = {};

  constructor(store, history, match) {
    this.store = store;
    this.history = history;
    this.match = match;
    this.companyDocument = new CompanyDocument({documentType: this.match.params.documentType});

    if (this.companyDocument.documentType === 'pdf_template') {
      this.acceptedMimeTypes = 'application/pdf';
    }
  }

  @action async saveCompanyDocument() {
    const {model, errors} = await this.store.postCompanyDocument(this.companyDocument);
    this.errors = errors;

    if (model) {
      successAlert(t('company_settings.documents.alerts.Company document uploaded.'));

      if (model.documentType === 'pdf_template') {
        this.history.push(`/documents/edit/${model.id}`);
      } else {
        this.history.push('/documents');
      }
    }
  }

  @action onUploadStarted() {
    this.isUploading = true;
  }

  @action async onUploadFinished() {
    if (this.companyDocument.documentType === 'pdf_template') {
      await this.validateEncryption();
    }
    this.isUploading = false;
  }

  @action removeFile() {
    this.companyDocument.originalFile = null;
  }

  @action mergeFile(file) {
    this.companyDocument.merge({originalFile: file});
  }

  @action updateFile(files) {
    const file = _.head(files);
    file ? this.mergeFile(file) : this.removeFile();
  }

  @action goBack() {
    this.history.push('/documents');
  }

  @action async validateEncryption() {
    this.errors = {};
    if (!this.companyDocument.originalFile) return;

    try {
      await pdfjsLib.getDocument({url: this.companyDocument.originalFile.preview}).promise;
    } catch (error) {
      if (error.name === 'PasswordException') {
        this.errors = {
          originalFile: 'Document is password protected. Password protected files are not supported for annotation.'
        };
      } else {
        throw error;
      }
    }
  }

  @computed get companyDocuments() {
    return this.companyDocument.originalFile ? observable([this.companyDocument.originalFile]) : observable([]);
  }

  @computed get canSaveDocument() {
    return !!this.companyDocument.originalFile && !this.errors.originalFile;
  }
}

export default CompanyDocumentUploadState;
