import React from 'react';
import {observer} from 'mobx-react';
import {Panel, FormLayout, DatePicker, QuickTip, RadioButtonGroup, RadioButton} from 'components';
import {BenefitEnrolmentList} from 'components/benefits';
import {t, auth} from 'shared/core';
import {CompensationInformation, EmploymentDetails, EmploymentRecordCustomFields, Notes} from './shared';
import {FormattedMessage} from 'react-intl';
import {calendarDate} from 'shared/tools';

const PayrollDetails = observer(({uiState}) => {
  const record = uiState.editRecord;
  const {errors} = uiState;

  if (!record.isNew) return null;
  if (!auth.company.payrollEnabled) return null;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.role.Payroll')}/>
      <RadioButtonGroup
        label='employees.profile.role.Include this employee in payroll?'
        name='payrollEnabled'
        selectedValue={record.payrollEnabled}
        errorMessage={errors.payrollEnabled}
        onChange={(name, value) => record.merge({payrollEnabled: value})}
      >
        <RadioButton className='table-cell pr2' value={false}><FormattedMessage id='employees.profile.role.No'/></RadioButton>
        <RadioButton className='table-cell pl2' value={true}><FormattedMessage id='employees.profile.role.Yes'/></RadioButton>
      </RadioButtonGroup>
    </Panel>
  );
});

const EffectiveDate = observer(({uiState}) => {
  const record = uiState.editRecord;
  const {errors} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.role.Reactivation Date')}/>
      <FormLayout>
        <DatePicker
          label='employees.profile.role.Reactivation Date'
          value={record.effectiveDate}
          onChange={(date) => record.effectiveDate = date}
          errorMessage={errors.effectiveDate}
          options={{startView: 1}}
          className='full-width'
        />
      </FormLayout>
    </Panel>
  );
});

const Explainer = observer(({uiState}) => {
  const record = uiState.editRecord;
  const {employee} = uiState;

  if (!record.effectiveDate) return null;

  const paragraphs = [
    <FormattedMessage
      id='employees.profile.role.reactivation_explainer.line_1'
      values={{date: calendarDate(record.effectiveDate), employeeName: employee.name}}
    />
  ];

  if (!auth.company.enrolmentOnly) {
    paragraphs.push(
      <FormattedMessage id='employees.profile.role.reactivation_explainer.line_2'/>
    );
  }

  return (
    <QuickTip
      trait='caution'
      header={t('employees.profile.role.reactivation_explainer.title', {employeeName: employee.name})}
      paragraphs={paragraphs}
    />
  );
});

const BenefitsPanel = observer(({uiState}) => {
  if (!uiState.editRecord.isNew) return null;
  if (!auth.company.benefitsEnabled) return null;

  return (
    <Panel>
      <Panel.Header
        title={t('employees.hire.benefits.Benefits')}
        description={
          t('employees.hire.benefits.BENEFIT_ENROLMENT_DESC', {employee: uiState.employee.name})
        }
      />
      <BenefitEnrolmentList
        errors={uiState.errors}
        onChange={({enrolments, employeeInsuranceNumbers}) => {
          uiState.setBenefitEnrolments(enrolments);
          uiState.setEmployeeInsuranceNumbers(employeeInsuranceNumbers);
        }}
        employeeBenefitEnrollments={uiState.editRecord.employeeBenefitEnrollments}
        employeeInsuranceNumbers={uiState.editRecord.employeeInsuranceNumbers}
        startDate={uiState.editRecord.effectiveDate}
      />
    </Panel>
  );
});

const ReactivationRecordForm = observer(({uiState}) => {
  const {editRecord, errors} = uiState;

  return (
    <form>
      <Panel.Stack loose>
        <EffectiveDate uiState={uiState}/>
        <EmploymentDetails uiState={uiState}/>
        <CompensationInformation uiState={uiState}/>
        <BenefitsPanel uiState={uiState}/>
        <PayrollDetails uiState={uiState}/>
        <EmploymentRecordCustomFields editRecord={editRecord} errors={errors}/>
        <Notes uiState={uiState}/>
        <Explainer uiState={uiState}/>
      </Panel.Stack>
    </form>
  );
});

export default ReactivationRecordForm;
