import React from 'react';
import {observer} from 'mobx-react';
import {Checkbox} from 'components';

const Field = observer(({fieldViewModel, sectionViewModel}) => {
  return (
    <div className='pt1' key={fieldViewModel.data.key}>
      <label className='clickable'>
        <div className='table-cell align-middle'>
          <Checkbox checked={fieldViewModel.checked} onChange={() => sectionViewModel.toggleFieldChecked(fieldViewModel)}/>
        </div>
        <div className='table-cell align-middle pl1'>
          <span>{fieldViewModel.data.view}</span>
        </div>
      </label>
    </div>
  );
});

export default Field;
