import React from 'react';
import {observer} from 'mobx-react';
import {Button, Select2} from 'components';
import {withState} from 'shared/core';
import {HolidaysState} from '../state';
import {FormattedMessage} from 'react-intl';
import useModal from 'shared/hooks/useModal';
import Holiday from 'stores/holidays/Holiday';
import HolidayTable from './HolidayTable';
import HolidayModal from './HolidayModal';
import CalendarModal from './CalendarModal';
import calendar from '../calendar';

const HolidaysContainerWithState = observer(({uiState}) => {
  const holidayModalOpen = useModal(uiState, 'EditHolidayModal', 'currentHoliday', Holiday, 'errors');

  return (
    <div>
      <div className='Container'>
        <div className='clearfix'>
          <div className='col-12 bg-white border box-shadow-1'>
            <div className='clearfix p3'>
              <div className='col col-3'>
                <div className='flex align-items-middle'>
                  <div className='mr3'>
                  <span className='align-middle h2'>
                    <FormattedMessage id='time_off.holidays.Holidays'/>
                  </span>
                  </div>
                  <Select2 value={uiState.year} onChange={e => uiState.handleYearChange(e.target.value)}>
                    {calendar.years().map(y => <option key={y} value={y.toString()}>{y}</option>)}
                  </Select2>
                </div>
              </div>
              <div className='col col-9'>
                <Button className='right' size='sm' onClick={() => uiState.openEditHolidayModal({date: `${uiState.year}-01-01`})}>
                  <FormattedMessage id='time_off.holidays.+ Add a Holiday'/>
                </Button>
              </div>
              <HolidayTable uiState={uiState}/>
            </div>
            <HolidayModal modalOpen={holidayModalOpen} uiState={uiState}/>
            <CalendarModal uiState={uiState}/>
          </div>
        </div>
      </div>
    </div>
  );
});

export default withState(HolidaysContainerWithState, HolidaysState);
