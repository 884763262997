import React from 'react';
import {observer} from 'mobx-react';
import Subnav from '../../Subnav';
import ResultsContainerWithState from './ResultsContainerWithState';

const ResultsContainer = observer(({cycle, match, scope}) => {
  return (
    <div>
      <Subnav title={cycle.name} match={match} scope={scope}/>
      <ResultsContainerWithState match={match} />
    </div>
  );
});

export default ResultsContainer;
