import {DomainStore} from 'shared/store';
import {action, observable} from 'mobx';
import {endpoints, types, auth} from 'shared/core';
import {UserApiKey} from 'stores/users';

class ZapierConfigurationContainerState {
  store = new DomainStore();

  @observable user;
  @observable selectedUserApiKey = {};
  @observable userApiKeys = [];
  @observable displayApiKey = false;
  @observable errors = {};

  @action async load() {
    await this.store._compose([
      endpoints.USER_API_KEYS.ALL
    ]);

    this.user = auth.user;
    this.userApiKeys = this.store._getAll(types.USER_API_KEY, UserApiKey);
  }

  @action async saveUserApiKey() {
    const {model, errors} = this.selectedUserApiKey.isNew ?
      await this.store.post(endpoints.USER_API_KEYS.ALL, types.USER_API_KEY, this.selectedUserApiKey) :
      await this.store.patch(this.selectedUserApiKey);

    if(model){
      if (!this.selectedUserApiKey.isNew) {
        this.closeEditUserApiKeyModal();
      } else {
        this.selectedUserApiKey = new UserApiKey(model);
        this.displayApiKey = true;
      }
      await this.load();
    }
    this.errors = errors;
  }

  @action async deleteUserApiKey(userApiKey) {
    await this.store.destroy(userApiKey);
    await this.load();
  }

  @action closeEditUserApiKeyModal() {
    this.displayApiKey = false;
  }
}

export default ZapierConfigurationContainerState;
