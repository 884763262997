import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {Panel, Layout, Display} from 'components';
import {CandidateFields, JobApplicationQuestions} from 'components/candidate_form';
import ReferralFormState from '../state';
import {ModalButtons} from 'components/modals';

const ReferralForm = observer(({uiState}) => {
  const {candidate, errors} = uiState;

  return (
    <React.Fragment>
      <Display
        title={t('referrals.referral_form.Refer a Candidate')}
        backTitle={t('referrals.referral_form.Positions')}
        backPath='/'
      />
      <Layout>
        <Panel>
          <Panel.Header title={t('referrals.referral_form.Enter candidate information')}/>
          <CandidateFields candidate={candidate} errors={errors}/>
          <JobApplicationQuestions uiState={uiState} candidate={candidate}/>
          <ModalButtons onSave={() => uiState.createCandidate()} onCancel={() => uiState.history.goBack()}/>
        </Panel>
      </Layout>
    </React.Fragment>
  );
});

export default withState(ReferralForm, ReferralFormState);
