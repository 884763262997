import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {FeedState} from '../state';
import {Button, Panel, EmojiTitle} from 'components';
import {FormattedMessage} from 'react-intl';
import AnnouncementIcon from '../img/announcement@2x.png';
import EditAnnouncementModal from './modals/EditAnnouncementModal';
import DeleteAnnouncementModal from './modals/DeleteAnnouncementModal';
import FeedItems from './FeedItems';

const Feed = observer(({uiState}) => {
  const {
    showCreateAnnouncementButton,
    Emoji,
    nextLink,
    feedItems
  } = uiState;

  return (
    <Panel>
      <Panel.Header title={<EmojiTitle Emoji={Emoji} emojiKey='zap' title={t("hr_dashboard.feed.What's Going On")}/>}>
        {showCreateAnnouncementButton && <Button trait='default' onClick={() => uiState.openNewAnnouncementModal()}>
          <img alt='announcement' className='mr1' src={AnnouncementIcon} height='16'/>
          <FormattedMessage id='hr_dashboard.feed.Post Announcement'/>
        </Button>}
      </Panel.Header>
      <FeedItems loadMore={() => uiState.loadMore()} nextLink={nextLink} feedItems={feedItems} feedState={uiState}/>
      <EditAnnouncementModal uiState={uiState}/>
      <DeleteAnnouncementModal uiState={uiState}/>
    </Panel>
  );
});

export default withState(Feed, FeedState);
