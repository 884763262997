import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import _ from 'lodash';
import {action, observable} from 'mobx';
import CycleState from '../../../../state/cycles/CycleState';

class AdminRevieweesContainerState {
  store = new DomainStore();
  match;

  @observable pairs;
  @observable name;

  receiveProps({match}) {
    this.match = match;
  }

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      `/performance_management/cycles/${this.match.params.id}/reviewees`
    ]);

    this.pairs = this.store._getAll(types.PERFORMANCE.CHECKIN_RELATIONS);
    this.name = await this.getCycleName(this.match.params.id);
  }

  async getCycleName(id) {
    const cycleStore = new CycleState();
    await cycleStore.load();
    cycleStore.selectCycle(id);
    return cycleStore.cycle.title;
  }
}

export default AdminRevieweesContainerState;
