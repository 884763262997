import _ from 'lodash';
import {action, observable} from 'mobx';
import {successAlert, openFileStack} from 'shared/tools';
import {endpoints, t, types} from 'shared/core';
import Location from 'stores/locations/Location';
import Company from 'stores/company/Company';
import {DomainStore} from 'shared/store';
import {Employee} from 'stores/employees';

class CompanySettingsState {
  companyStore;
  countryStore;
  store = new DomainStore();

  @observable company;
  @observable editingCompany = new Company();
  @observable locations = [];
  @observable editCompanyModalOpen = false;
  @observable editLocationModalOpen = false;
  @observable removeLocationModalOpen = false;
  @observable editingLocation = {};
  @observable agent;
  @observable errors = {};

  @observable employees = [];
  @observable countries = [];
  @observable regions = [];
  @observable locationRegions = [];

  constructor(companyStore, countryStore) {
    this.companyStore = companyStore;
    this.countryStore = countryStore;
  }

  @action async load() {
    await this.store._compose([
      endpoints.CURRENT_COMPANY,
      endpoints.LOCATIONS.ALL,
      endpoints.COUNTRIES,
      endpoints.EMPLOYEES.ALL
    ]);

    this.company = new Company(this.store._getSingle(types.COMPANY));
    this.locations = this.store._getAll(types.LOCATION).map(location => new Location(location));
    this.countries = _.sortBy(this.store._getAll(types.COUNTRY), 'name');
    this.employees = this.store._getAll(types.EMPLOYEE).map(employee => new Employee(employee));

    await this.initiateLocations();
  }

  @action async initiateLocations() {
    for (const l of this.locations) {
      await this.appendRegions(l.countryCode);
    }
  }

  @action async saveCompany() {
    const {model, errors} = await this.companyStore.patchCompany(this.editingCompany);
    this.errors = errors;

    if (model) {
      this.company.merge(this.editingCompany);
      this.closeEditCompanyModal();
      successAlert(t('company_settings.general.alerts.Company information saved.'));
    }
  }

  @action async saveLocation() {
    const {model, errors} = this.editingLocation.isNew
      ? await this.companyStore.postLocation(this.editingLocation)
      : await this.companyStore.patchLocation(this.editingLocation);
    this.errors = errors;

    if (model) {
      this.agent.refresh();
      this.appendRegions(model.countryCode);
      this.closeEditLocationModal();
      successAlert(t('company_settings.general.alerts.Location information saved.'));
    }
  }

  @action async deleteLocation(location) {
    await this.companyStore.destroy(location);
    this.removeLocationModalOpen = false;
    this.agent.refresh();
    successAlert(t('company_settings.general.alerts.Location successfully deleted.'));
  }

  @action async appendRegions(countryCode) {
    if (!countryCode) return;

    await this.countryStore.getOrLoadRegions(countryCode);
    this.regions = this.countryStore.getAllRegions().map(l => new Location(l));
  }

  @action async refreshRegionsForLocation(countryCode) {
    if (!countryCode) return;

    this.locationRegions = await this.countryStore.getOrLoadRegions(countryCode);
  }

  @action openEditCompanyModal() {
    this.editingCompany = new Company(this.company);
    this.editCompanyModalOpen = true;
  }

  @action closeEditCompanyModal() {
    this.editCompanyModalOpen = false;
  }

  @action openEditLocationModal(location) {
    this.editLocationModalOpen = true;
    this.editingLocation = new Location(location);
    this.refreshRegionsForLocation(location.countryCode);
  }

  @action closeEditLocationModal() {
    this.editLocationModalOpen = false;
    this.locationRegions = [];
  }

  @action createNewLocation() {
    this.errors = {};
    this.editingLocation = new Location();
    this.editLocationModalOpen = true;
  }

  @action async uploadLogo() {
    openFileStack({
      accept: ['image/*'],
      storeTo: {
        location: 's3',
        path: '/avatars/'
      }
    }, (file) => this.__saveLogo(file));
  }

  @action async __saveLogo(file) {
    this.editingCompany = new Company(this.company);
    this.editingCompany.merge({logo: file});
    this.editingCompany.logo.label = file.label;

    const {model, errors} = await this.companyStore.patchCompany(this.editingCompany);
    this.errors = errors;

    if (model) {
      this.company.merge(model);
      successAlert(t('company_settings.general.alerts.Company logo saved.'));
    }
  }

  @action setInteractiveAgent(agent) {
    this.agent = agent;
  }

  employeesForLocation(location) {
    return _.filter(this.employees, (employee) => employee.location.id === location.id);
  }

  countryView(countryCode) {
    return _.get(_.find(this.countries, { code: countryCode }), 'name');
  }

  regionView(countryCode, regionCode) {
    return _.get(_.find(this.regions, { countryCode: countryCode, code: regionCode }), 'name');
  }
}

export default CompanySettingsState;
