import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const ShareWithRevieweeModal = observer(({uiState, isOpen}) => {
  const {selectedReviewee} = uiState;

  return (
    <AlertModal
      isOpen={isOpen}
      onHide={() => uiState.closeShareWithRevieweeModal()}
      size='md'
      header='performance_reviews.view.Share'
    >
      <div>
        <FormattedMessage
          id='performance_reviews.view.END_REVIEWEE_DESCRIPTION_1'
          values={{revieweeName: _.get(selectedReviewee, 'employee.name')}}
        />
      </div>
      {!selectedReviewee.canViewAllReviews && <div className='mt2'>
        <FormattedMessage
          id='performance_reviews.view.END_REVIEWEE_DESCRIPTION_2'
          values={{revieweeName: _.get(selectedReviewee, 'employee.name')}}
        />
      </div>}
      <ModalButtons
        saveCaption='performance_reviews.view.Share'
        onSave={() => uiState.shareWithReviewee(selectedReviewee)}
        onCancel={() => uiState.closeShareWithRevieweeModal()}
      />
    </AlertModal>
  );
});

export default ShareWithRevieweeModal;
