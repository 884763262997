import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import CheckmarkIcon from '../img/completed-checkmark@2x.png';
import {Panel} from 'components';

const StepOneActive = observer(() => {
  return (
    <div>
      <div className='h3 my1 medium'>
        <FormattedMessage id="company_onboard.You've added your employee info." />
      </div>
      <Link className='TextLink dodger' to='employees'>
        <FormattedMessage id='company_onboard.+ Add More Employees' />
      </Link>
    </div>
  );
});

const StepOneInactive = observer(() => {
  return (
    <div>
      <div className='h3 my1 medium'>
        <FormattedMessage id='company_onboard.Add basic employee info.'/>
      </div>
      <div className='jumbo'>
        <FormattedMessage id="company_onboard.Let's get started by adding basic employee information to Collage." />
      </div>
    </div>
  );
});

const StepOneActiveAction = observer(() => {
  return <img height='55' src={CheckmarkIcon} alt='check' />;
});

const StepOneInactiveAction = observer(() => {
  return (
    <Link className='Btn Btn--primary' to='employees'>
      <FormattedMessage id='company_onboard.Assemble Your Team'/>
    </Link>
  );
});

const StepOne = observer(({uiState}) => {
  return (
    <Panel className='my1'>
      <div className='clearfix table'>
        <div className='col-8 table-cell align-middle pr2'>
          <div className='jumbo'>
             <FormattedMessage id='company_onboard.STEP_NUMBER' values={{number: 1}} />
          </div>
          {uiState.stepOneComplete ? <StepOneActive /> : <StepOneInactive />}
        </div>
        <div className='col-4 table-cell align-middle pl2 center'>
          {uiState.stepOneComplete ? <StepOneActiveAction /> : <StepOneInactiveAction />}
        </div>
      </div>
    </Panel>
  );
});

export default StepOne;
