import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';

const ReportList = observer(({caption, details, children, visible}) => {
  if (!visible) return null;

  return (
    <div className='mb3'>
      <Panel.Header title={t(caption)}/>
      <div className='h4 jumbo pb2'>
        <FormattedMessage id={details}/>
      </div>
      {children}
    </div>
  );
});

export default ReportList;
