import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {AttachedDocument} from '../documents';
import {formattedTimestampDate} from 'shared/tools';
import _ from 'lodash';

class PublicOffer extends DomainObject {
  @observable renderedContent;
  @observable companyName;
  @observable companyLogo;
  @observable firstName;
  @observable lastName;
  @observable email;
  @observable expiresAt;
  @observable offerVersion;
  @observable isAccepted;
  @observable acceptable;
  @observable attachments;

  @action merge(other) {
    super.merge(other, {
      _dates: ['expiresAt'],
      attachments: [AttachedDocument]
    });
  }

  @computed get logoUrl() {
    return _.get(this, 'links.companyLogo');
  }
  
  @computed get expiresAtDate() {
    return formattedTimestampDate(this.expiresAt);
  }
}

export default PublicOffer;
