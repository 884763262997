import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Select2} from 'components';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalButtons} from 'components/modals';

const CreateMassUpdateModal = observer(({modalOpen, uiState}) => {
  const {massUpdateType} = uiState;

  return (
    <Modal
      size='md'
      title={t('mass_updates.directory.Create Mass Update')}
      isOpen={modalOpen}
      onHide={() => uiState.closeCreateMassUpdateModal()}
    >
      <FormattedMessage id="mass_updates.directory.Choose the type of fields you'd like to update."/>
      <Select2
        options={[
          {id: 'job', name: 'mass_updates.directory.Job'},
          {id: 'personal', name: 'mass_updates.directory.Personal'}
        ]}
        value={massUpdateType}
        onChange={e => uiState.updateMassUpdateType(e.target.value)}
      >
        <option value='job'>
          <FormattedMessage id='mass_updates.directory.job'/>
        </option>
        <option value='personal'>
          <FormattedMessage id='mass_updates.directory.personal'/>
        </option>
      </Select2>
      <div className='jumbo mt1'>
        {massUpdateType === 'job' ?
          <FormattedMessage id='mass_updates.directory.EXAMPLE_JOB_FIELDS'/> :
          <FormattedMessage id='mass_updates.directory.EXAMPLE_PERSONAL_FIELDS'/>}
      </div>
      <ModalButtons
        onSave={() => uiState.createMassUpdate()}
        onCancel={() => uiState.closeCreateMassUpdateModal()}
      />
    </Modal>
  );
});

export default CreateMassUpdateModal;
