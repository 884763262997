import React from 'react';
import {observer} from 'mobx-react';
import TasksDirectory from './TasksDirectory';
import {onboarding} from 'components/tasks';

const OnboardingTasksDirectory = observer(({history}) => {
  return (
    <TasksDirectory config={onboarding} history={history}/>
  );
});

export default OnboardingTasksDirectory;
