import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {CalendarEditState} from '../state';
import {Display, Layout, Panel, FormLayout, Input, Select2, HelperPanel, EmployeeSelector, FormError} from 'components';
import {ModalButtons} from 'components/modals';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {LOCALES, CALENDAR_TYPES} from 'stores/calendars';

const LINKS = [
  {
    translations: {
      en: 'http://support.collage.co/time-off/custom-calendars'
    },
    title: 'company_settings.calendars.Custom Calendars'
  }
];

const CalendarEditContainer = observer(({uiState}) => {
  const {segmentCalendar, errors, participantEmployees, subscriptionEmployees} = uiState;

  return (
    <div>
      <Display title={segmentCalendar.name || t('company_settings.calendars.Untitled Calendar')} backPath='/calendars'/>
      <Layout>
        <Layout.Section>
          <Panel.Stack loose>
            <Panel>
              <Panel.Header 
                title={t('company_settings.calendars.Calendar Details')}
                description={t('company_settings.calendars.DESCRIPTION')} />
              <FormLayout>
                <Input value={segmentCalendar.name}
                  onChange={e => segmentCalendar.merge({name: e.target.value})}
                  label='company_settings.calendars.Calendar Name'
                  placeholder='company_settings.calendars.Time Off Toronto'
                  errorMessage={errors.name} />
                <Input value={segmentCalendar.description}
                  onChange={e => segmentCalendar.merge({description: e.target.value})}
                  label='company_settings.calendars.Description'
                  placeholder='company_settings.calendars.Scheduled time off for employees in the Toronto office'
                  errorMessage={errors.description} />
                <Select2
                  className='full-width'
                  label='company_settings.calendars.Calendar Type'
                  value={segmentCalendar.calendarType}
                  onChange={e => segmentCalendar.merge({calendarType: e.target.value})}
                  disabled={!segmentCalendar.isNew}
                  errorMessage={errors.calendarType}
                >
                  {CALENDAR_TYPES.map(calendarType =>
                    <option
                      key={calendarType}
                      value={calendarType}>{t(`models.segment_calendar.calendar_type.${calendarType}`)}
                    </option>
                  )}
                </Select2>
                <Select2
                  className='full-width'
                  label='company_settings.calendars.Language'
                  value={segmentCalendar.locale}
                  onChange={e => segmentCalendar.merge({locale: e.target.value})}
                  errorMessage={errors.locale}
                >
                  {LOCALES.map(locale =>
                    <option key={locale} value={locale}>{t(`models.segment_calendar.locales.${locale}`)}</option>
                  )}
                </Select2>
              </FormLayout>
            </Panel>
            <Panel>
              <Panel.Header title={t('company_settings.calendars.Who will appear in this calendar?')}/>
              <FormLayout>
                <div className='jumbo'>
                  <FormattedMessage id='company_settings.calendars.Select the employees you want to see in this calendar'/>
                </div>
                <EmployeeSelector
                  selectedEmployees={participantEmployees}
                  onChange={employees => uiState.changeParticipantEmployees(employees)}
                />
                <FormError message={errors.participantRules}/>
              </FormLayout>
            </Panel>
            <Panel>
              <Panel.Header title={t('company_settings.calendars.Who can access this calendar?')}/>
              <FormLayout>
                <div className='jumbo'>
                  <FormattedMessage id='company_settings.calendars.Select which employees have access to this calendar'/>
                </div>
                <EmployeeSelector
                  selectedEmployees={subscriptionEmployees}
                  onChange={employees => uiState.changeSubscriptionEmployees(employees)}
                />
                <FormError message={errors.subscriptionRules}/>
              </FormLayout>
            </Panel>
            <ModalButtons
              onSave={() => uiState.saveSegmentCalendar()}
              saveCaption={`company_settings.calendars.${segmentCalendar.isNew ? 'Create' : 'Save'}`}
              onCancel={() => uiState.goBack()}
            />
          </Panel.Stack>
        </Layout.Section>
        <Layout.Section secondary>
          <HelperPanel
            title={t('company_settings.calendars.What is a custom calendar?')}
            descriptionItems={[
              t('company_settings.calendars.A custom calendar is a time off, birthday, or work anniversary calendar for a select group of employees.')
            ]}
            links={LINKS}
          />
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(CalendarEditContainer, CalendarEditState);
