import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {OfferPreviewModal, DropdownSelect, Tag, Button, DocumentTitle, QuickTip, ActionLink} from 'components';
import ToggleQualifyButton from './ToggleQualifyButton';
import EditCandidateModal from '../candidate/EditCandidateModal';
import DeleteCandidateModal from '../candidate/DeleteCandidateModal';
import MovePositionModal from '../candidate/MovePositionModal';
import SendDisqualifiedEmailModal from '../candidate/SendDisqualifiedEmailModal';
import Timeline from './Timeline';
import CandidateDetailsPlaceholder from './CandidateDetailsPlaceholder';
import CandidateDetailsEmptyState from './CandidateDetailsEmptyState';
import FeedbackContainer from './FeedbackContainer';
import ResumePreview from './ResumePreview';
import {t} from 'shared/core';
import CandidateCustomFields from './candidate_custom_fields';
import CandidateEmailModal from './CandidateEmailModal';
import CandidateTagModal from './CandidateTagModal';
import CandidateRater from './CandidateRater';
import SendBulkDisqualifiedEmailModal from 'containers/recruiting/components/candidate/SendBulkDisqualifiedEmailModal';
import useModal from 'shared/hooks/useModal';
import BulkMoveStageModal from 'containers/recruiting/components/candidate/BulkMoveStageModal';

const OnboardLink = observer(({candidate, uiState}) => {
  if (!candidate.hasLink('hire')) return null;
  if (candidate.employeeId) return null;

  return (
    <Button className='Btn Btn--lagoon align-middle mr1' onClick={() => uiState.hireCandidate(candidate)}>
      <FormattedMessage id='recruiting.hiring_funnel.Onboard'/>
    </Button>
  );
});

const DisqualifiedTag = observer(({candidate}) => {
  if (!candidate.disqualified) return null;

  const disqualificationType = candidate.autoDisqualified ?
    'recruiting.hiring_funnel.Auto-Disqualified' :
    'recruiting.hiring_funnel.Disqualified';

  return (
    <div className='table-cell align-middle pl1'>
      <Tag type='rounded' colour='rose'><FormattedMessage id={disqualificationType}/></Tag>
    </div>
  );
});

const SendOfferLink = observer(({candidate, uiState}) => {
  if (!candidate.canSendOffer && !candidate.canEditOffer) return null;
  if (candidate.employeeId) return null;

  return (
    <Button className='Btn Btn--lagoon align-middle mr1' onClick={() => uiState.sendOffer(candidate)}>
      <FormattedMessage id={`recruiting.hiring_funnel.${candidate.canEditOffer ? 'Edit Offer' : 'Send Offer'}`}/>
    </Button>
  );
});

const OtherPositionsQuickTip = observer(({candidate}) => {
  if (candidate.otherPositionsCount === 0){
    return null;
  }

  const quickTipParagraphs = [
    <FormattedMessage
      id="recruiting.hiring_funnel.candidate_has_other_positions"
      values={{
        other_jobs_link: <a href={`/recruiting/candidates?f-search=${encodeURIComponent(candidate.email)}`} target={'_blank'}>
          <FormattedMessage
            id='recruiting.hiring_funnel.other_positions_count'
            values={{otherPositionsCount: candidate.otherPositionsCount}}
          />
        </a>
      }}
    />
  ];

  if (candidate.disqualifiedPositionsCount > 0) {
    quickTipParagraphs.push(
      <FormattedMessage
        id="recruiting.hiring_funnel.candidate_has_disqualified_positions"
        values={{
          disqualifiedPositionsLink: <a className='scarlet' href={`/recruiting/candidates?f-search=${encodeURIComponent(candidate.email)}&f-candidate_status=disqualified`} target={'_blank'}>
            <FormattedMessage
              id='recruiting.hiring_funnel.disqualified_positions_count'
              values={{disqualifiedPositionsCount: candidate.disqualifiedPositionsCount}}
            />
          </a>
        }}
      />
    );
  }

  return (
    <div className='col col-12 mb2'>
      <QuickTip trait='info' paragraphs={quickTipParagraphs}/>
    </div>
  );
});

const CandidateHiredQuickTip = observer(({candidate, uiState}) => {
  if (!candidate.employeeId) return null;

  return (
    <div className='mb2'>
      <QuickTip
        trait='success'
        header={<FormattedMessage id='recruiting.hiring_funnel.CANDIDATE_WAS_HIRED' values={{candidate: candidate.name}}/>}
        paragraphs={[
          t('recruiting.hiring_funnel.candidate_was_hired_explainer')
        ]}
        actions={uiState.candidateHasEmployee && [
          {
            caption: t('recruiting.hiring_funnel.Go to employee'),
            onClick: () => uiState.goToEmployee(candidate.employeeId)
          }
        ]}
      />
    </div>
  );
});

const CandidateTags = observer(({candidate, onClick}) => {
  return (
    <div className='px2 py2'>
      {candidate.candidateTags.map(tag =>
        <Tag colour='dodger' className='mr1' key={tag.id}>{tag.name}</Tag>
      )}
      <ActionLink className='dodger' onClick={() => onClick()}>
        <FormattedMessage id={candidate.candidateTags.length > 0 ? 'recruiting.hints.+ Edit Tags' : 'recruiting.hints.+ Add Tags'}/>
      </ActionLink>
    </div>
  );
});

const CandidateDetails = ({uiState}) => {
  const sendBulkDisqualifiedEmailModalOpen = useModal(uiState, 'SendBulkDisqualifiedEmailModal');
  const bulkMoveStageModalOpen = useModal(uiState, 'BulkMoveStageModal');
  if (uiState.loadingCandidate || uiState.selectFirstPending) return <CandidateDetailsPlaceholder />;

  const {detailsState, position} = uiState;
  const {candidateViewModel, previewedOffer, company, offerPreviewModalOpen} = detailsState;
  if (!candidateViewModel) return <CandidateDetailsEmptyState />;
  const {candidate} = candidateViewModel;

  return (
    <div className='col col-8 box-shadow-1 min-height-800 bg-white'>
      {candidate.name && <DocumentTitle title={candidate.name}/>}
      <div className='is-fading-in fs-hide'>
        <div className='clearfix p3 border-bottom'>
          <CandidateHiredQuickTip candidate={candidate} uiState={uiState}/>
          <div className='px2 pb1'>
            <div className='table-cell align-middle pr1'>
              <div className='h1'>{candidateViewModel.name}</div>
            </div>
            <div className='table-cell align-middle pr1 h1'>
              <CandidateRater
                onRating={(score) => detailsState.rateCandidate(score)}
                score={candidate.score}
              />
            </div>
            <DisqualifiedTag candidate={candidate}/>
          </div>
          <OtherPositionsQuickTip candidate={candidate}/>
          {candidate.referredByUser && <div className='px2 pb1'>
            <i className='material-icons h3 waterloo align-text-top flip-horizontal pl1'>{'reply'}</i>
            <FormattedMessage id='recruiting.hiring_funnel.REFERRED_BY_USER' values={{user: candidate.referredByUser.name}}/>
          </div>}
          <div className='px2 py1'>
            {candidate.email && <div className='table-cell align-middle pr3'>
              <i className='material-icons h3 waterloo align-text-top pr1'>{'email'}</i>
              <a className='h5 align-top TextLink' href={`mailto:${candidate.email}`}>{candidate.email}</a>
            </div>}
            {candidate.phoneNumber && <div className='table-cell align-middle pr3'>
              <i className='material-icons h3 waterloo align-text-top pr1'>{'phone'}</i>
              <span className='h5 align-top'>{candidate.phoneNumber}</span>
            </div>}
          </div>
          <CandidateTags candidate={candidate} onClick={() => detailsState.openEditTagsModal()}/>
          <div className='flex flex-wrap row-gap-2 px2 my2'>
            <div className='mr1'>
              <DropdownSelect selected={candidate.currentStage.name} disabled={detailsState.funnelPickerDisabled}>
                {position.funnelStages.map((stage, index) =>
                  <DropdownSelect.Option key={stage.id} text={stage.name}
                                  active={candidate.currentStage.id === stage.id}
                                  onClick={() => uiState.moveCandidateToStage(stage)}
                                  separator={index === 1 || index === position.funnelStages.length - (position.finalStages.length + 1)} />
                )}
              </DropdownSelect>
            </div>
            <ToggleQualifyButton uiState={uiState} candidateViewModel={candidateViewModel}/>
            <OnboardLink candidate={candidate} uiState={uiState}/>
            <SendOfferLink candidate={candidate} uiState={uiState}/>
            <DropdownSelect className='inline-block' selected={t('recruiting.hiring_funnel.More')} buttonTrait='default'>
              <DropdownSelect.Option key={1} text={t('recruiting.hints.Edit candidate')} icon='create' onClick={() => detailsState.openEditCandidateModal()}/>
              <DropdownSelect.Option key={2} text={t('recruiting.hints.SEND_EMAIL_TO', {name: candidate.name})} icon='email' onClick={() => detailsState.showEmail('')}/>
              <DropdownSelect.Option key={3} text={t('recruiting.hints.Upload files')} icon='note_add' onClick={() => detailsState.showFileUpload()}/>
              {uiState.showMovePositionButton && <DropdownSelect.Option key={4} text={t('recruiting.hints.Move to another position')} icon='login' onClick={() => detailsState.openMovePositionModal()}/>}
              {detailsState.showInterviewGuideButton && <DropdownSelect.Option key={5} text={t('recruiting.hints.Submit an interview guide')} icon='assignment' onClick={() => detailsState.showInterviewGuide()}/>}
              <DropdownSelect.Option key={6} text={t('recruiting.hints.Delete candidate')} icon='delete' onClick={() => detailsState.openDeleteCandidateModal()}/>
            </DropdownSelect>
          </div>
          <div className='col col-12 px2 my2'>
            <FeedbackContainer detailsState={detailsState} />
          </div>
        </div>
        <Timeline uiState={uiState} />
        {candidateViewModel.backgroundInformationVisible && <div className='clearfix p3 px2'>
          <div className='px2'>
            <div className='h2 medium'><FormattedMessage id='recruiting.hiring_funnel.Candidate Information'/></div>
            {candidate.additionalInformation && <div className='my3 whitespace-pre-line whitespace-pre-wrap word-break'>
              {candidate.additionalInformation}
            </div>}
            <CandidateCustomFields candidate={candidate}/>
            <ResumePreview candidate={candidate}/>
          </div>
        </div>}
        <EditCandidateModal uiState={uiState}/>
        <DeleteCandidateModal uiState={uiState}/>
        <CandidateEmailModal uiState={uiState}/>
        <MovePositionModal uiState={uiState}/>
        <SendDisqualifiedEmailModal uiState={uiState}/>
        <SendBulkDisqualifiedEmailModal uiState={uiState} modalOpen={sendBulkDisqualifiedEmailModalOpen}/>
        <BulkMoveStageModal uiState={uiState} modalOpen={bulkMoveStageModalOpen}/>
        <OfferPreviewModal
          offer={previewedOffer}
          company={company}
          isOpen={offerPreviewModalOpen}
          onHide={() => detailsState.closeOfferPreviewModal()}
        />
        <CandidateTagModal uiState={uiState} />
      </div>
    </div>
  );
};

export default observer(CandidateDetails);
