import {DomainObject} from 'shared/store';
import {action, observable} from 'mobx';
import CompanyInsuranceCarrier from './CompanyInsuranceCarrier';

class EmployeeInsuranceNumber extends DomainObject {
  @observable externalId; 
  @observable companyInsuranceCarrier;

  @action merge(other) {
    super.merge(other, {
      companyInsuranceCarrier: CompanyInsuranceCarrier
    });
  }
}

export default EmployeeInsuranceNumber;
