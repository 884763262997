import React from 'react';
import {Button} from 'components';
import {noop, sleep} from 'shared/tools';
import StyleGuideTitle from './StyleGuideTitle';
import StyleGuideExample from './StyleGuideExample';
import StyleGuideParameterRows from './StyleGuideParameterRows';

const PARAMS = [
  { name: 'children*', type: 'string', description: 'Content to display within the button', values: '<FormattedMessage />', },
  { name: 'className', type: 'string', description: 'Additional classNames to style the button', values: 'Ex: ".full-width", ".my3"', },
  { name: 'disabled', type: 'boolean', description: 'Disables a button so it cannot be clicked', values: '[true, false]', },
  { name: 'onClick*', type: 'function()', description: 'Callback performed on click', values: '() => functionName()', },
  { name: 'size', type: 'enum', description: 'Changes size of the button', values: '[sm, lg], default: "md"', },
  { name: 'style', type: 'enum', description: 'Changes button style and context', values: '[primary, destructive], default: "default"', }
];

const StyleGuideButton = () => {
  return (
    <div>
      <StyleGuideTitle title='Buttons' description='Buttons make common actions immediately visible and easy to perform with one click or tap. They can be used for any type of action, including navigation.'/>

      <div className='bg-white box-shadow-1 rounded p4 my3'>
        <StyleGuideExample title='Default Buttons' description='These buttons are used when making temporary actions such as cancelling changes or reverting to a previous action.'>
          <Button trait='default' onClick={() => sleep(1000000)}>
            <span>{'Default'}</span>
          </Button>
          <Button className='mx2' trait='default' onClick={noop} disabled>
            <span>{'Disabled'}</span>
          </Button>
        </StyleGuideExample>

        <StyleGuideExample title='Primary Buttons' description='These buttons are used when making progressive actions such as moving to a next step or saving. (Our system mostly consists of these buttons)'>
          <Button onClick={() => sleep(2000)}>
            <span>{'Primary'}</span>
          </Button>
          <Button className='mx2' onClick={noop} disabled>
            <span>{'Disabled'}</span>
          </Button>
        </StyleGuideExample>

        <StyleGuideExample title='Destructive Buttons' description='These buttons are used when making permanent actions such as removing or deleting. Users should be wary of the action associated with this button.'>
          <Button trait='destructive' onClick={() => sleep(2000)}>
            <span>{'Destructive'}</span>
          </Button>
          <Button className='mx2' trait='destructive' onClick={noop} disabled>
            <span>{'Disabled'}</span>
          </Button>
        </StyleGuideExample>

        <StyleGuideExample title='Sizes' description='Sizes determine the importance of certain actions and the context of which they are in. Bigger buttons symbolize primary actions, whereas smaller buttons symbolize secondary actions.'>
          <Button size='sm' trait='default' onClick={noop}>
            <span>{'Small'}</span>
          </Button>
          <Button className='mx2' trait='default' onClick={noop}>
            <span>{'Default'}</span>
          </Button>
          <Button size='lg' trait='default' onClick={noop}>
            <span>{'Large'}</span>
          </Button>
        </StyleGuideExample>

        <div className='mb3'>
          <div className='h2 medium mb2'>{'Parameters'}</div>
          <div className='clearfix mb1'>
            <div className='col col-3 pr1 h5 caps'>{'Name'}</div>
            <div className='col col-2 pr1 h5 caps'>{'Type'}</div>
            <div className='col col-4 pr1 h5 caps'>{'Description'}</div>
            <div className='col col-3 pl1 h5 caps'>{'Values'}</div>
          </div>
          <ul className='StyleGuide-params list-reset'>
            {PARAMS.map((p, i) => <StyleGuideParameterRows key={i} index={i} {...p} />)}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StyleGuideButton;
