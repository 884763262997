import {DomainObject, oneOf} from 'shared/store';
import {observable, action} from 'mobx';

class PayrollUpdate extends DomainObject {
  @observable id;
  @observable action;
  @observable employee;
  @observable createdAt;
  @observable changes;
  @observable status;

  @action merge(other) {
    super.merge(other, {
      action: oneOf(['employee_onboarded', 'employee_updated', 'employee_terminated', 'employee_reactivated']),
      status: oneOf(['submitted', 'completed'])
    });
  }
}

export default PayrollUpdate;
