import React from 'react';
import {observer} from 'mobx-react';
import {Table} from 'components';

const COLUMNS = [
  {
    header: 'company_settings.reminders.Name',
    attribute: 'targetName',
    width: 4
  },
  {
    header: 'company_settings.reminders.When',
    attribute: 'date',
    width: 4,
  },
  {
    header: 'company_settings.reminders.Sent to',
    attribute: 'recipientNames',
    width: 3
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'company_settings.reminders.Remove Reminder?',
    subHeader: 'company_settings.reminders.Are you sure you want to remove this reminder?',
  },
  addModel: 'company_settings.reminders.+ Add Reminder',
  emptyState: 'company_settings.reminders.No reminders to display'
};

const RemindersTable = observer(({uiState}) => {
  return (
    <Table models={uiState.reminders}
           columns={COLUMNS}
           localization={LOCALIZATION}
           editRoute='/reminders/:id'
           onRemove={reminder => uiState.removeReminder(reminder)}
           onAdd={() => uiState.goToNew()}/>
  );
});

export default RemindersTable;
