import React from 'react';
import {observer} from 'mobx-react';
import {Button} from 'components';
import {FormattedMessage} from 'react-intl';

const SessionExpiring = observer(({uiState}) => {
  return (
    <div>
      <div className=' white bg-java rounded px3 py2 Login-shadow'>
        <div className='h3 medium pt1'>
          <FormattedMessage id='login.Are you still there?'/>
        </div>
        <div className='h5 pb2 pt1'>
          <FormattedMessage
            id="login.SESSION_EXPIRATION"
            values={{
              seconds: <span className='medium'><FormattedMessage id='login.SECONDS' values={{seconds: uiState.seconds}}/></span>
            }}
            />
        </div>
        <div className='py1'>
          <Button className='LoginStayLoggedInBtn' onClick={() => uiState.renewSession()} useDefaultStyles={false}>
            <FormattedMessage id='login.STAY_LOGGED_IN'/>
          </Button>
        </div>
      </div>
    </div>
  );
});

export default SessionExpiring;
