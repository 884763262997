import {action, observable} from 'mobx';
import {TimeOffTransaction} from 'stores/time_off/index';

class ManualAdjustmentTransaction extends TimeOffTransaction {
  @observable adjustBy = 'days';
  @observable adjustment;

  @action merge(other) {
    this.adjustment = other.amount;

    super.merge(other);
  }
}

export default ManualAdjustmentTransaction;
