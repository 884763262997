import React from 'react';
import {withState, t} from 'shared/core';
import {policyLink} from 'shared/tools';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Input, Button, FormLayout, DatePicker, QuickTip} from 'components';
import {EmployeeSignupState} from '../state';
import _ from 'lodash';
import BrokerBrandedContainer from '../../../../BrokerBrandedContainer';
import {LoginOAuth} from 'components/login';

const bhqMarketingCopy = (invite) => ({
  bulletPoints: [
    <FormattedMessage id='broker_portal.signup.employee.marketing_copy_1'/>,
    <FormattedMessage id='broker_portal.signup.employee.marketing_copy_2'/>,
    <FormattedMessage id='broker_portal.signup.employee.marketing_copy_3'/>
  ],
  header: <FormattedMessage id='broker_portal.signup.employee.MARKETING_HEADER' values={{companyName: invite.companyName}}/>
});

const hrMarketingCopy = (invite) => ({
  info: <FormattedMessage id='broker_portal.signup.employee.collage.marketing_copy_1'/>,
  header: <FormattedMessage id='broker_portal.signup.employee.collage.MARKETING_HEADER' values={{companyName: invite.companyName}}/>
});

const marketingCopy = (invite) => {
  if (invite.bhq) return bhqMarketingCopy(invite);

  return hrMarketingCopy(invite);
};

const ErrorMessage = observer(({uiState}) => {
  const {validateSignupFormErrors} = uiState;
  if (_.isEmpty(validateSignupFormErrors)) return null;

  return (
    <QuickTip
      trait='error'
      paragraphs={[
        <FormattedMessage id='broker_portal.signup.employee.details_do_not_match'/>
      ]}
    />
  );
});

const InfoMessage = observer(({uiState}) => {
  const {validateSignupFormErrors} = uiState;
  if (!_.isEmpty(validateSignupFormErrors)) return null;

  return (
    <QuickTip
      trait='caution'
      header={t('broker_portal.signup.employee.enter_your_details_header')}
      paragraphs={[
        <FormattedMessage id='broker_portal.signup.employee.enter_your_details'/>
      ]}
    />
  );
});

const ValidateSignupForm = observer(({uiState}) => {
  const {validateSignupForm, validateSignupFormErrors, birthDateRequired} = uiState;

  return (
    <form>
      <FormLayout>
        <InfoMessage uiState={uiState}/>
        <ErrorMessage uiState={uiState}/>
        <Input
          value={validateSignupForm.lastName}
          label='broker_portal.signup.employee.Last Name'
          onChange={e => validateSignupForm.merge({lastName: e.target.value})}
          errorMessage={validateSignupFormErrors.lastName}
        />
        {birthDateRequired &&
          <DatePicker
            label='broker_portal.signup.employee.Date of Birth'
            value={validateSignupForm.birthDate}
            onChange={birthDate => validateSignupForm.merge({birthDate})}
            options={{startView: 2}}
            errorMessage={validateSignupFormErrors.birthDate}
          />
        }
        <Button className='full-width' onClick={() => uiState.validateInvite()}>
          <FormattedMessage id='broker_portal.signup.employee.Continue'/>
        </Button>
      </FormLayout>
    </form>
  );
});

const SignupForm = observer(({uiState}) => {
  const {form, errors, termsLink} = uiState;

  return (
    <div>
      <div className='h2 medium center mb3'>
        <FormattedMessage id='broker_portal.signup.employee.Create Your Account'/>
      </div>
      <LoginOAuth
        context='user_invitation_signup'
        token={uiState.form.token}
      />
      <form>
        <FormLayout>
          <Input
            value={form.email}
            placeholder='broker_portal.signup.employee.Email'
            onChange={e => form.merge({email: e.target.value})}
            errorMessage={errors.email}
          />
          <Input
            value={form.emailConfirmation}
            placeholder='broker_portal.signup.employee.Confirm Email'
            onChange={e => form.merge({emailConfirmation: e.target.value})}
            errorMessage={errors.emailConfirmation}
          />
          <Input type='password' className='full-width'
            value={form.password}
            placeholder='broker_portal.signup.employee.Password'
            onChange={e => form.merge({password: e.target.value})}
            errorMessage={errors.password}
          />
        </FormLayout>
        <Button className='full-width' onClick={() => uiState.signUp()}>
          <FormattedMessage id='broker_portal.signup.employee.Sign Up'/>
        </Button>
        <div className='mt2 h5 waterloo center'>
          <FormattedMessage id='signup.SERVICE_AGREEMENT_AND_PRIVACY_POLICY' values={{
            terms: <a href={termsLink} target='_blank' className='TextLink dodger'><FormattedMessage id='signup.Terms'/></a>,
            privacyPolicy: <a href={policyLink()} target='_blank' className='TextLink dodger'><FormattedMessage id='signup.Privacy Policy'/></a>
          }}/>
        </div>
      </form>
    </div>
  );
});

const EmployeeSignupForm = observer(({uiState}) => {
  const {invite, invitationValidated} = uiState;

  return (
    <BrokerBrandedContainer
      {...marketingCopy(invite)}
    >
      <div className='center mb3'>
        <div className='wl-big-logo mx-auto'></div>
      </div>
      {invitationValidated && <SignupForm uiState={uiState}/>}
      {!invitationValidated && <ValidateSignupForm uiState={uiState}/>}
    </BrokerBrandedContainer>
  );
});

export default withState(EmployeeSignupForm, EmployeeSignupState);
