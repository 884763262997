import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import QuestionForm from './QuestionForm';

const EditQuestionModal = observer(({
  question,
  isOpen,
  onClose,
  onSave,
  questionTypes,
  localization,
  showRequired,
  showDescription,
  showQuestionType,
  children,
  errorMessage,
  ...rest
}) => {
  return (
    <Modal
      size='md'
      isOpen={isOpen}
      onHide={() => onClose()}
      title={question.isNew ? localization.title.add : localization.title.edit}
    >
      <QuestionForm
        question={question}
        questionTypes={questionTypes}
        localization={localization}
        showRequired={showRequired}
        showDescription={showDescription}
        showQuestionType={showQuestionType}
        errorMessage={errorMessage}
        {...rest}
      />
      <ModalButtons
        saveCaption={question.isNew
          ? 'recruiting.edit.steps.Add'
          : 'recruiting.edit.steps.Update'
        }
        onSave={() => onSave()}
        onCancel={() => onClose()}
      />
    </Modal>
  );
});

EditQuestionModal.defaultProps = {
  localization: {
    title: {
      add: 'recruiting.edit.steps.Add question',
      edit: 'recruiting.edit.steps.Edit question'
    },
    question: 'components.question_form.Question',
    questionType: 'components.question_form.Type',
    required: 'onboard.employee_info.Required',
    description: 'components.question_form.Description'
  },
  showRequired: true,
  showQuestionType: true
};

export default EditQuestionModal;
