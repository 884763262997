import React                              from 'react';
import PropTypes from 'prop-types';
import chartFactory                       from 'reports/charts';
import {fetchReport, shouldRefetchReport} from 'reports/tools';
import {getSeriesAndCategories} from 'reports/charts/tools';
import {Spinner}                          from 'components';
import {loader}                           from 'shared/core';
import _ from 'lodash';
import ReportEmptyState from './ReportEmptyState';

function isEmpty(rows, meta) {
  return _.isEmpty(rows) || _.isEmpty(getSeriesAndCategories(rows, meta).highchartsSeries);
}

const ChartReport = ({Highcharts, HighchartsReact, chartType, rows, meta, options, reportRef}) => {
  if (_.isEmpty(meta)) return null;

  if (isEmpty(rows, meta)) {
    return <ReportEmptyState/>;
  }

  return (
    <div className='py2'>
      <HighchartsReact
        ref={reportRef}
        highcharts={Highcharts}
        options={chartFactory(chartType, {rows, meta}, options)}
      />
    </div>
  );
};

ChartReport.propTypes = {
  children: PropTypes.element,
  rows: PropTypes.array,
  chartType: PropTypes.string,
  meta: PropTypes.object
};

ChartReport.defaultProps = {
  chartType: 'column'
};

const ChartSpinner = () =>
  <div className='Report-empty-placeholder py3'>
    <Spinner />
  </div>;

export default loader(ChartReport, fetchReport, shouldRefetchReport, ChartSpinner);
