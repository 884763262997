import React from 'react';
import Submit from 'components/Submit';
import Button from 'components/buttons/Button';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';

const CancelButton = ({cancelCaption, onCancel, preventDefault, enabled}) => {
  const handleCancel = e => {
    if (preventDefault) {
      e.preventDefault();
    }
    onCancel(e);
  };

  return (
    <Button className='ModalCancelButton mr1' disabled={!enabled} trait='default' type='button' onClick={handleCancel}>
      <FormattedMessage id={cancelCaption}/>
    </Button>
  );
};

const SaveButton = observer(({saveCaption, onSave, enabled, staysDisabledAfterClick, trait}) => {
  return (
    <Submit
      className='ModalSaveButton ml1'
      disabled={!enabled}
      onClick={onSave}
      staysDisabledAfterClick={staysDisabledAfterClick}
      trait={trait}
    >
      <FormattedMessage id={saveCaption}/>
    </Submit>
  );
});

const ModalButtons = observer(({
  children,
  cancelCaption,
  saveCaption,
  onSave,
  onCancel,
  enabled,
  staysDisabledAfterClick,
  trait,
  saveEnabled,
  className
}) => {
  return (
    <div className={`ModalButtons clearfix ${className ? className : 'mt3'}`}>
      {children}
      <CancelButton cancelCaption={cancelCaption} onCancel={onCancel} enabled={enabled}/>
      <SaveButton
        saveCaption={saveCaption}
        onSave={onSave}
        enabled={enabled && saveEnabled}
        staysDisabledAfterClick={staysDisabledAfterClick}
        trait={trait}
      />
    </div>
  );
});

ModalButtons.defaultProps = {
  cancelCaption: 'Cancel',
  saveCaption: 'Save',
  onCancel: () => {},
  onSave: () => {},
  preventDefault: true,
  enabled: true,
  staysDisabledAfterClick: false,
  trait: 'primary',
  saveEnabled: true
};

export default ModalButtons;