import {observable, computed} from 'mobx';

class TrainingViewModel {
  @observable trainingProgram;
  @observable selected = false;

  constructor(trainingProgram) {
    this.trainingProgram = trainingProgram;
  }

  @computed get dueDate() {
    if (this.trainingProgram.relativeDueDateValue === null) return '';

    return `${this.trainingProgram.relativeDueDateValue} ${this.trainingProgram.relativeDueDateUnit} from start date`;
  }

  @computed get name() {
    return this.trainingProgram.name;
  }
}

export default TrainingViewModel;
