import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormLayout, Input, EmployeeSelector} from 'components';
import {t} from 'shared/core';

const EditProjectModal = observer(({uiState, modalOpen}) => {
  const {errors, editingProject} = uiState;

  return (
    <Modal
      isOpen={modalOpen}
      onHide={() => uiState.closeEditProjectModal()}
      size='sm'
      title={editingProject.isNew
        ? 'time_tracking.manage.projects.directory.Create Project'
        : 'time_tracking.manage.projects.directory.Edit Project'
      }
    >
      <FormLayout>
        <Input
          value={editingProject.name}
          label='time_tracking.manage.projects.directory.Name'
          errorMessage={errors.name}
          onChange={(e) => editingProject.merge({name: e.target.value})}
        />
        <EmployeeSelector
          label={t('time_tracking.manage.projects.directory.Employees')}
          tooltip={{
            paragraphs: [t('time_tracking.manage.projects.directory.Assigned employees can log hours to this project in their timesheets')]
          }}
          selectedEmployees={editingProject.employees}
          onChange={employees => uiState.editingProject.employees = employees}
        />
      </FormLayout>
      <ModalButtons onSave={() => uiState.saveProject()} onCancel={() => uiState.closeEditProjectModal()} />
    </Modal>
  );
});

export default EditProjectModal;
