import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Button, CheckboxWithLabel} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import PayrollProviders from 'img/payroll/payroll-providers@2x.png';
import InterestedInPayrollModal from './InterestedInPayrollModal';

const PayrollNotEnabled = observer(({uiState}) => {
  const {notInterestedInPayroll, payrollNotificationSent} = uiState;

  return (
    <div>
      <Panel.Header title={t('employees.hire.payroll.How should payroll be managed for this employee?')}/>
      <div className='flex justify-content-between'>
        <div>
          <FormattedMessage id='employees.hire.payroll.Upgrade Collage to have a dedicated payroll expert run your payroll from beginning to end:'/>
          <div className='ml1 mt1 clearfix'>
            <ul className='CheckmarkList col col-6 pr2'>
              <li><FormattedMessage id='employees.hire.payroll.features.Payroll Data Sync'/></li>
              <li><FormattedMessage id='employees.hire.payroll.features.Pay run setup and execution'/></li>
              <li><FormattedMessage id='employees.hire.payroll.features.ROE preparation and filings'/></li>
              <li><FormattedMessage id='employees.hire.payroll.features.Benefits Deductions'/></li>
              <li><FormattedMessage id='employees.hire.payroll.features.RSP contributions'/></li>
            </ul>
            <ul className='CheckmarkList col col-6'>
              <li><FormattedMessage id='employees.hire.payroll.features.Dedicated Payroll Manager'/></li>
              <li><FormattedMessage id='employees.hire.payroll.features.Year-end reporting, review and audits'/></li>
              <li><FormattedMessage id='employees.hire.payroll.features.Bonuses, commissions and allowances'/></li>
              <li><FormattedMessage id='employees.hire.payroll.features.Pay hourly employees'/></li>
            </ul>
          </div>
        </div>
        <div className='max-width-200 ml3 mr1 jumbo'>
          <FormattedMessage id='employees.hire.payroll.Supported Providers:'/>
          <img className='full-width mt1' src={PayrollProviders} alt='providers'/>
        </div>
      </div>
      {!payrollNotificationSent && <div className='mt2 flex align-items-middle'>
        <Button className='Btn--lagoon mr2' onClick={() => uiState.sendPayrollNotification()} disabled={notInterestedInPayroll}>
          <FormattedMessage id='employees.hire.payroll.Upgrade'/>
        </Button>
        <CheckboxWithLabel
          label="employees.hire.payroll.No, thanks. I'll manage payroll myself."
          checked={notInterestedInPayroll}
          onChange={(e) => uiState.updatePayrollInterest(e.checked)}
        />
      </div>}
      <InterestedInPayrollModal uiState={uiState}/>
    </div>
  );
});

export default PayrollNotEnabled;
