import React from 'react';
import {observer} from 'mobx-react';
import {ObjectSelect2} from 'components';
import {t} from 'shared/core';

const DelegateAssignments = observer(({uiState}) => {
  const {delegateUsers, allowedToDelegate, request, canSubmitRequest} = uiState;

  if (!allowedToDelegate) return null;

  return (
    <ObjectSelect2
      label="employees.profile.time_off.request.While I'm off, delegate time off requests assigned to me to:"
      helpLink={{
        title: t("components.help_link.What's this?"),
        explanation: {
          header: 'employees.profile.time_off.request.DELEGATE_HELP_HEADER',
          paragraphs: [
            t('employees.profile.time_off.request.DELEGATE_HELP_DESC_1'),
            t('employees.profile.time_off.request.DELEGATE_HELP_DESC_2')
          ]
        }
      }}
      value={request.delegateAssignmentsToUser}
      searchable
      onChange={value => request.merge({delegateAssignmentsToUser: value})}
      items={delegateUsers}
      allowClear={true}
      placeholder='employees.profile.time_off.request.Specify a delegate'
      disabled={!canSubmitRequest}
    />
  );
});

export default DelegateAssignments;
