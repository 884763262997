import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';
import {t} from 'shared/core';
import {calendarDate} from 'shared/tools';
import {FormattedMessage} from 'react-intl';
import {QuickTip} from 'components';

const TerminationConfirmationModal = observer(({uiState}) => {
  const {editRecord, employee, terminationDescription} = uiState;

  return (
    <AlertModal
      size='sm'
      header='employees.profile.role.Review Termination Details'
      isOpen={uiState.terminationConfirmationModalOpen}
      onHide={() => uiState.closeTerminationConfirmationModal()}
    >
      <div className='clearfix mxn2 mt2 mb1'>
        <div className='col col-6 px2'>
          <FormattedMessage id='payroll_updates.Employee'/>
        </div>
        <div className='col col-6 px2 jumbo'>
          {employee.name}
        </div>
      </div>
      <div className='clearfix mxn2 mb1'>
        <div className='col col-6 px2'>
          <FormattedMessage id='employees.profile.role.Termination Type'/>
        </div>
        {editRecord.terminationType && <div className='col col-6 px2 jumbo'>
          {t(`models.employee.termination_type.${editRecord.terminationType}`)}
        </div>}
      </div>
      <div className='clearfix mxn2 mb1'>
        <div className='col col-6 px2'>
          <FormattedMessage id='employees.profile.role.Last day of work'/>
        </div>
        <div className='col col-6 px2 jumbo'>
          {calendarDate(editRecord.terminatedAt)}
        </div>
      </div>
      {employee.benefits && <div className='clearfix mxn2 mb1'>
        <div className='col col-6 px2'>
          <FormattedMessage id='employees.profile.role.Last day of benefits'/>
        </div>
        <div className='col col-6 px2 jumbo'>
          {calendarDate(editRecord.lastDayOfBenefits)}
        </div>
      </div>}
      <div className='mt3 mb2'>
        <QuickTip
          trait='error'
          header={t('employees.profile.role.TERMINATE_EMPLOYEE_HTML', {employeeName: employee.name})}
          paragraphs={terminationDescription}
        />
      </div>
      <ModalDangerButtons
        saveCaption='employees.profile.role.Complete Termination'
        onSave={() => uiState.saveRecord()}
        onCancel={() => uiState.closeTerminationConfirmationModal()}
      />
    </AlertModal>
  );
});

export default TerminationConfirmationModal;
