import React from 'react';
import {observer} from 'mobx-react';
import Subnav from '../../Subnav';
import AdminContainerWithState from './AdminContainerWithState';

const AdminContainer = observer(({cycle, match, history, scope}) => {
  return (
    <div>
      <Subnav title={cycle.name} match={match} scope={scope}/>
      <AdminContainerWithState cycle={cycle} match={match} history={history}/>
    </div>
  );
});

export default AdminContainer;
