import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const RemoveCompanyFileFolderModal = observer(({modalOpen, uiState}) => {
  return (
    <AlertModal
      mode='danger'
      size='sm'
      header='company_settings.company_files.Remove Company File Folder?'
      subHeader='company_settings.company_files.Are you sure you want to remove this company file folder?'
      body='company_settings.company_files.This folder and all its files will be deleted.'
      onHide={() => uiState.closeRemoveCompanyFileFolderModal()}
      isOpen={modalOpen}
    >
      <ModalDangerButtons
        saveCaption={'Remove'}
        onCancel={() => uiState.closeRemoveCompanyFileFolderModal()}
        onSave={() => uiState.deleteCompanyFileFolder()}
      />
    </AlertModal>
  );
});

export default RemoveCompanyFileFolderModal;
