import React from 'react';
import {observer} from 'mobx-react';
import {LexicalEditor} from 'components';
import {Panel, FormError} from 'components';
import {t} from 'shared/core';

const Description = observer(({uiState}) => {
  const {position, errors} = uiState;

  return (
    <div>
      <Panel.Header title={t('recruiting.edit.steps.Job Description')}/>
      <LexicalEditor
        placeholder={'recruiting.edit.steps.Add a description...'}
        onChange={description => uiState.updateDescription(description)}
        initialEditorState={position.contentSection.lexicalState}
        initialHTML={position.contentSection.richText}
      />
      <FormError message={errors.contentSection} />
    </div>
  );
});

export default Description;
