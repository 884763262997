import React from 'react';
import {observer} from 'mobx-react';
import NotesContainerWithState from './NotesContainerWithState';
import EmployeeProfileContainer from '../EmployeeProfileContainer';

const NotesContainer = observer(({uiState}) => {
  return (
    <EmployeeProfileContainer uiState={uiState}>
      <NotesContainerWithState parentState={uiState}/>
    </EmployeeProfileContainer>
  );
});

export default NotesContainer;
