import React from 'react';
import {observer} from 'mobx-react';
import {Table, Panel} from 'components';
import OutstandingEnrolmentItem from './OutstandingEnrolmentItem';
import {t} from 'shared/core';

const COLUMNS = [
  {
    component: OutstandingEnrolmentItem,
    width: 12
  }
];

const OutstandingEnrolments = observer(({uiState}) => {
  if (!uiState.outstandingEnrolments.length) return null;

  const {hideProfileLink} = uiState;

  return (
    <Panel>
      <Panel.Header
        title={t('pa_dashboard.Outstanding Enrolments')}
        description={t('pa_dashboard.OUTSTANDING_ENROLMENTS.HQ')}
      />
      <Table
        columns={COLUMNS}
        models={uiState.sortedEnrolments}
        showHeaders={false}
        showLinks={false}
        hideProfileLink={hideProfileLink}
      />
    </Panel>
  );
});

export default OutstandingEnrolments;
