import {observer} from 'mobx-react';
import {EmptyFormState} from '../state';
import {withState} from 'shared/core';

const EmptyForm = observer(() => {
  return null;
});


export default withState(EmptyForm, EmptyFormState);
