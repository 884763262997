import React from 'react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const ArchiveCycleModal = ({uiState, modalOpen}) => {
  return (
    <Modal
      title='performance.Archive Check-In Cycle'
      size='sm'
      isOpen={modalOpen}
      onHide={() => uiState.closeArchiveCycleModal()}
    >
      <div className='mb3 jumbo'>
        <FormattedMessage id={'performance.Archived check-in cycles will no longer be actionable but the check-ins will remain visible to admins.'}/>
      </div>
      <ModalButtons
        saveCaption='performance.Archive'
        onSave={() => uiState.archiveCycle()}
        onCancel={() => uiState.closeArchiveCycleModal()}/>
    </Modal>
  );
};

export default ArchiveCycleModal;
