import {observable, action, computed} from 'mobx';
import axios from 'axios';
import _ from 'lodash';
import POPULAR_APPS from './popularApps';

const CLIENT_ID = process.env.ZAPIER_CLIENT_ID;
const RESULTS_PER_PAGE = 30;

class MarketplaceContainerState {
  @observable search = '';
  @observable zapierResults = {};
  @observable loadingResults = false;
  @observable selectedPopularApp = {};
  @observable page = 1;

  @action async load() {
    await this.fetchResults(`https://api.zapier.com/v1/apps?client_id=${CLIENT_ID}&per_page=${RESULTS_PER_PAGE}`);
  }

  @action async searchApps(search) {
    this.search = search;
    await this.fetchResults(`https://api.zapier.com/v1/apps?client_id=${CLIENT_ID}&per_page=${RESULTS_PER_PAGE}&title_search=${this.search.replace(' ', '+')}`);
    this.page = 1;
  }

  @action async fetchResults(link) {
    this.loadingResults = true;
    const response = await axios.get(link);
    this.zapierResults = response.data;
    this.loadingResults = false;
  }

  @action async prevPage() {
    await this.fetchResults(this.zapierResults.prev_url);
    this.page -= 1;
  }

  @action async nextPage() {
    await this.fetchResults(this.zapierResults.next_url);
    this.page += 1;
  }

  @computed get prevEnabled() {
    return !!this.zapierResults.prev_url;
  }

  @computed get nextEnabled() {
    return !!this.zapierResults.next_url;
  }

  @computed get endEntryIndex() {
    return _.min([this.totalResults, this.zapierResults.page * RESULTS_PER_PAGE]);
  }

  @computed get startEntryIndex() {
    if (!this.totalResults) return 0;

    return (this.zapierResults.page * RESULTS_PER_PAGE) - RESULTS_PER_PAGE + 1;
  }

  @computed get totalResults() {
    return this.zapierResults.total;
  }

  @computed get popularApps() {
    if (this.page !== 1) return [];
    if (!this.search) return POPULAR_APPS;

    return _.filter(POPULAR_APPS, (value) => _.includes(value.title.toLowerCase(), this.search.toLowerCase()));
  }
}

export default MarketplaceContainerState;
