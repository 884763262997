import {DomainObject} from 'shared/store';
import {observable, computed} from 'mobx';

class ForecastedAccountBalance extends DomainObject {
  @observable used;
  @observable available;
  @observable scheduled;
  @observable workDayLength;
  @observable unlimited;

  @computed get usedDays() {
    return Math.round(this.used * 100 / this.workDayLength) / 100;
  }

  @computed get scheduledDays() {
    return Math.round(this.scheduled * 100 / this.workDayLength) / 100;
  }

  @computed get availableDays() {
    return Math.round(this.available * 100 / this.workDayLength) / 100;
  }
}

export default ForecastedAccountBalance;
