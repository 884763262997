import {observable} from 'mobx';
import {t} from 'shared/core';

class SignatureViewModel {
  @observable id;
  @observable name;
  @observable signatureData;
  @observable annotationType;
  @observable signatureId;

  constructor({name, signatureData, signatureId, annotationType, id}) {
    this.name = name;
    this.signatureData = signatureData;
    this.annotationType = annotationType;
    this.signatureId = signatureId;
    this.id = id;
  }

  static fromCompanySignature(companySignature) {
    return new SignatureViewModel({
      name: companySignature.name,
      signatureData: companySignature.signatureData,
      signatureId: companySignature.id,
      annotationType: 'company_signature'
    });
  }

  static employeeSignature(id) {
    return new SignatureViewModel({
      name: t('components.pdf.annotate.toolbar.Employee Signature'),
      annotationType: 'employee_signature',
      id
    });
  }
}

export default SignatureViewModel;
