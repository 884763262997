import React from 'react';
import {observer} from 'mobx-react';
import {CheckboxWithLabel, RadioButtonGroup, RadioButton, FormError} from 'components';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const LocationTable = observer(({uiState}) => {
  const {currentHoliday, locations, errors} = uiState;

  if (!currentHoliday.locationIds) return null;

  return (
    <div className='py1'>
      <div className='p1 border rounded'>
        {locations.map((location) => <div key={location.id}>
          <CheckboxWithLabel
            className='flex align-middle'
            value={location.id}
            checked={currentHoliday.locationIds.includes(location.id)}
            onChange={e => uiState.handleLocationToggle(e)}
            translatable={false}
            label={<div className='flex align-middle'>
              <div>{location.name}</div>
              <div className='align-middle waterloo ml1'>{location.city}</div>
            </div>}
          />
        </div>)}
      </div>
      <FormError message={errors.base}/>
    </div>
  );
});

const HolidayLocationSelector = observer(({uiState}) => {
  const {currentHolidayFor} = uiState;

  return (
    <div>
      <RadioButtonGroup
        name='holiday_for'
        label='time_off.holidays.This Holiday is For'
        selectedValue={currentHolidayFor}
        onChange={(name, value) => uiState.onAllEmployeesChange(value)}
      >
        <RadioButton className='block' value='all'>
          <FormattedMessage id='time_off.holidays.All Employees'/>
        </RadioButton>
        <RadioButton className='block pt1' value='some'>
          <FormattedMessage id='time_off.holidays.Some Employees'/>
        </RadioButton>
      </RadioButtonGroup>
      <LocationTable uiState={uiState}/>
    </div>
  );
});

export default HolidayLocationSelector;
