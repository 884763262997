import React from 'react';
import {observer} from 'mobx-react';
import {Spinner} from 'components';

const LoadingScreen = ({uiState}) => {
  return (
    <div className='height-250'>
      <div className='abs-center'>
        <Spinner />
      </div>
    </div>
  );
};

export default observer(LoadingScreen);

