import React from 'react';
import {observer} from 'mobx-react';
import {QuickTip} from 'components';
import {FormattedMessage} from 'react-intl';
import EmployeeInviteModal from './EmployeeInviteModal';
import _ from 'lodash';

const EmployeeInviteStatus = observer(({uiState}) => {
  const {employee, statusParams, currentRecord} = uiState;

  return (
    <div>
      {statusParams.map((status, index) =>
        <div key={index} className='mb2 fs-hide'>
          <QuickTip
            trait={status.trait}
            actions={status.action}
            paragraphs={[
              <FormattedMessage
                id={status.message}
                values={{
                  email: employee.invitationEmail,
                  terminationDate: _.get(currentRecord, 'effectiveDateView'),
                  leaveType: _.get(currentRecord, 'leaveType.name'),
                  leaveStartDate: _.get(currentRecord, 'effectiveDateView'),
                  leaveEndDate: _.get(currentRecord, 'expectedReturnDateView')
                }}
              />
            ]}
          />
        </div>
      )}
      <EmployeeInviteModal uiState={uiState}/>
    </div>
  );
});

export default EmployeeInviteStatus;
