import _ from 'lodash';
import {action, observable} from 'mobx';
import {successAlert} from 'shared/tools';
import {DomainStore} from 'shared/store';
import {t, types, endpoints} from 'shared/core';

class EmployeesContainerState {
  store = new DomainStore();
  interactiveAgent;

  @observable editingEmployee;
  @observable removeEmployeeModalOpen = false;
  @observable addEmployeeModalOpen = false;
  @observable errors = {};

  setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  @action openRemoveEmployeeModal(employee) {
    this.editingEmployee = employee;
    this.removeEmployeeModalOpen = true;
  }

  @action closeRemoveEmployeeModal() {
    this.editingEmployee = null;
    this.removeEmployeeModalOpen = false;
  }

  @action openAddEmployeeModal(employee) {
    this.editingEmployee = employee;
    this.addEmployeeModalOpen = true;
  }

  @action closeAddEmployeeModal() {
    this.editingEmployee = null;
    this.addEmployeeModalOpen = false;
  }

  @action async toggleEmployeePayroll(payrollEnabled) {
    const employee = {...this.editingEmployee, payrollEnabled, updateCompensation: true};
    const {model, errors} = await this.store.patch(
      `${endpoints.EMPLOYEES.ALL}/${this.editingEmployee.id}`,
      types.EMPLOYEE_DETAILS,
      employee,
    );

    this.errors = errors;
    if (model) {
      this.editingEmployee.update(model);

      if (payrollEnabled) {
        successAlert(t('payroll.employees.ADDED_TO_PAYROLL', {employeeName: this.editingEmployee.name}));
        this.addEmployeeModalOpen = false;
      } else {
        successAlert(t('payroll.employees.REMOVED_FROM_PAYROLL', {employeeName: this.editingEmployee.name}));
        this.removeEmployeeModalOpen = false;
      }

      this.interactiveAgent.refresh();
    }
  }

  customLinksFor(employee) {
    if (employee.payrollEnabled) {
      return [
        {
          text: 'payroll.employees.Remove',
          action: employee => this.openRemoveEmployeeModal(employee)
        }
      ];
    } else {
      return [
        {
          text: 'payroll.employees.Add to Payroll',
          action: employee => this.openAddEmployeeModal(employee)
        }
      ];
    }
  }
}

export default EmployeesContainerState;
