import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Button} from 'components';

const TrainingTask = observer(({uiState}) => {
  const {task, isAssignee} = uiState;

  return (
    <div>
      <div className='mb3 jumbo'>
        <FormattedMessage id='inbox.You have been assigned to a training program. Complete your training to complete this task.'/>
      </div>
      {isAssignee && !task.completed && <Button onClick={() => uiState.viewTraining(task)}>
        <FormattedMessage id='inbox.Complete Training'/>
      </Button>}
    </div>
  );
});

export default TrainingTask;
