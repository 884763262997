import User from './User';
import {observable} from 'mobx';

class UserDetails extends User {
  @observable recruitingEmailNotification;
  @observable payrollUpdateEmailNotification;
  @observable benefitUpdateEmailNotification;
  @observable birthdaySharedInternally;
  @observable unsubscribedFromEmails;
}

export default UserDetails;
