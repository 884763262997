import React, {useEffect, useState} from 'react';
import {Prompt} from 'react-router-dom';
import {t} from 'shared/core';
import {flushSync} from 'react-dom';

/**
 * When using this Higher-Order Component, the Component passed as an argument must have an
 * observable prop isFormDirty, which must be of type boolean.
 */
const warnUnsavedChanges = (Component) => {
  return function (props) {
    const leaveMessage = t('components.warn_unsaved_changes.Are you sure you want to leave with unsaved changes?');

    const [isFormDirty, setIsFormDirty] = useState(false);

    const setFormDirty = (isFormDirty) => {
      flushSync(() => {
        setIsFormDirty(isFormDirty);
      });
    };

    useEffect(() => {
      window.onbeforeunload = isFormDirty && (() => leaveMessage);

      return function cleanup() {
        window.onbeforeunload = null;
      };
    }, [isFormDirty]);

    return (
      <React.Fragment>
        <Component {...props} setFormDirty={(isFormDirty) => setFormDirty(isFormDirty)} />
        <Prompt
          when={isFormDirty}
          message={leaveMessage}
        />
      </React.Fragment>
    );
  };
};

export default warnUnsavedChanges;
