import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {Panel, Button, AutosaveStatus} from 'components';
import {FormattedMessage} from 'react-intl';
import SubmitReviewModal from 'performance_reviews/components/reviews/components/view/components/review_details/components/SubmitReviewModal';
import {QuestionFieldFactory} from 'components/forms';
import ReviewNotes
  from 'containers/performance_reviews/components/reviews/components/view/components/review_details/components/ReviewNotes';

const ReviewEditor = observer(({title, description, review, errors, isOpen, onOpen, onHide, onSubmit, uiState}) => {
  return (
    <Panel className='print-hide'>
      <Panel.Header title={title}/>
      <div className='h4 jumbo'>
        <i className='material-icons h3 align-middle waterloo mr1'>{'visibility'}</i>
        {description}
      </div>
      {review.sortedAnswers.map((answer, index) =>
        <div key={answer.id} className='mt2'>
          <QuestionFieldFactory
            errorMessage={_.get(errors, `answers.${index}.value`)}
            answer={answer}
            required={answer.question.required}
            onChanged={() => uiState.onAnswerChanged(review)}
          />
        </div>
      )}
      <div className='mt3 right-align'>
        <AutosaveStatus autosaver={review.autosaver}/>
        <Button onClick={() => onOpen()} className='ml2'>
          <FormattedMessage id='performance_reviews.view.Submit Review'/>
        </Button>
      </div>
      <ReviewNotes review={review} />
      <SubmitReviewModal
        isManagerReview={review.isManagerReview}
        isOpen={isOpen}
        onHide={() => onHide()}
        onSave={() => onSubmit(review)}
      />
    </Panel>
  );
});

export default ReviewEditor;
