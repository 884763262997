import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class EmployeeOnboard extends DomainObject {
  @observable id;
  @observable currentStep;
  @observable steps;
  @observable accountCreated;
  @observable complete;

  isStepCompleted(step) {
    return step.order < this.currentStep.order;
  }

  isStepCurrent(step) {
    return step.id === this.currentStep.id;
  }
}

export default EmployeeOnboard;
