import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {CompanyAvatar, InteractiveTable} from 'components';
import {endpoints, t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import {calendarDate} from 'shared/tools';

const CompanyItem = observer(({model}) => {
  const {companyId, companyName} = model.values;

  return (
    <div>
      <div className='table-cell align-middle pr2'>
        <CompanyAvatar company={model.values} />
      </div>
      <div className='table-cell align-middle'>
        <Link className='TextLink' to={`/clients/${companyId}`}>{companyName}</Link>
        <div className='jumbo'>
          <FormattedMessage id='broker_dashboard.ENROLMENT_COUNT' values={{employeeCount: model.values.enrolments}}/>
        </div>
      </div>
    </div>
  );
});

const percentageColor = (percentage) => {
  if (percentage > 0) {
    return 'meadow';
  } else if (percentage < 0) {
    return 'scarlet';
  } else {
    return 'jumbo';
  }
};

const percentageSign = (percentage) => {
  if (percentage > 0) {
    return `+${percentage}`;
  } else {
    return percentage;
  }
};

const GrowthPercentage = observer(({model}) => {
  return (
    <div className={`right-align ${percentageColor(model.values.growthPercentage)}`}>
      {`${percentageSign(model.values.growthPercentage)}%`}
    </div>
  );
});

const COLUMNS = [
  {
    header: 'broker_dashboard.Client',
    component: CompanyItem,
    sortBy: 'company',
    width: 10
  },
  {
    header: 'broker_dashboard.Growth',
    component: GrowthPercentage,
    sortBy: 'growth_percentage',
    width: 2
  }
];

const ClientGrowth = observer(({uiState}) => {
  return (
    <InteractiveTable
      uiState={uiState}
      title={t('broker_dashboard.Client Growth')}
      description={t('broker_dashboard.client_growth_helptext', {date: calendarDate(moment().subtract(1, 'months').subtract(1, 'days'))})}
      columns={COLUMNS}
      showLinks={false}
      searchable={false}
      proxy={{
        pageSize: 5,
        type: 'async',
        modelType: 'reportRows',
        endpoint: endpoints.BROKER_PORTAL.DASHBOARD.COMPANIES
      }}
    />
  );
});

export default ClientGrowth;
