import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Cycle',
  'Leader',
  'Department',
  'Location',
  'Manager',
  'Gender',
  'Employee',
  'Employment Status',
  'Employment Type'
];

const CheckinsReport = () => {
  return (
    <CompositeReport filters={filters}>
      <Report name={t('reports.CHECKINS')}
              url='/reports/checkins'>
      </Report>
    </CompositeReport>
  );
};

export default CheckinsReport;
