import _ from 'lodash';
import {action, computed, observable} from 'mobx';
import {successAlert, openFileStack} from 'shared/tools';
import Candidate from 'stores/recruiting/Candidate';
import CandidateViewModel from 'recruiting/state/CandidateViewModel';
import {t, types, endpoints} from 'shared/core';
import Answer from 'stores/recruiting/Answer';
import recruitingStore from 'stores/recruiting/RecruitingStore';

class ReferralFormState {
  store = recruitingStore;
  history;
  match;

  @observable candidateViewModel = new CandidateViewModel(new Candidate());
  @observable errors = {};
  @observable submitted = false;
  @observable position;
  @observable isUploading = false;

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    this.position = await this.store.loadSingle(this.match.params.id);

    for (const question of this.position.questions) {
      this.candidate.answers.push(new Answer({question: question}));
    }
  }

  @action onUploadStarted() {
    this.isUploading = true;
  }

  @action onUploadFinished() {
    this.isUploading = false;
  }

  @action calculateQuestionRequired() {
    return false;
  }

  @action async createCandidate() {
    const {errors, model} = await this.store.post(
      endpoints.RECRUITING.CANDIDATES.REFER.with(this.match.params.id),
      types.RECRUITING.CANDIDATE,
      this.candidate
    );

    if (model) {
      this.history.goBack();
      successAlert(t('referrals.referral_form.Referral successfully submitted'));
    }
    this.errors = errors;
  }

  @computed get candidate() {
    return this.candidateViewModel.candidate;
  }

  uploadFileForAnswer(answer) {
    openFileStack({
      storeTo: { path: 'resumes/' }
    }, file => answer.merge({attachment: file}));
  }
}

export default ReferralFormState;
