import React from 'react';
import {observer} from 'mobx-react';
import {auth} from 'shared/core';
import {Link} from 'react-router-dom';

const CycleName = observer(({cycle}) => {
  if (auth.hasAccess('::MANAGE_PERFORMANCE_MANAGEMENT')) return (
    <span>
      <Link to={`/reviews/${cycle.id}`}>
        {cycle.name}
      </Link>
    </span>
  );

  return (
    <span>{cycle.name}</span>
  );
});

export default CycleName;
