import {DomainObject} from 'shared/store';
import {observable, action} from 'mobx';
import {User} from 'stores/users';

class AnnouncementReaction extends DomainObject {
  @observable id;
  @observable emojiKey;
  @observable user;

  @action merge(other) {
    super.merge(other, {
      user: User
    });
  }
}

export default AnnouncementReaction;
