import React from 'react';
import {FormattedMessage} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Display} from 'components';
import {observer} from 'mobx-react';
import {t} from 'shared/core';

const TimeTrackingSubnav = observer(({scope}) => {
  return (
    <Display title={t('time_tracking.Time Tracking')}>
      {scope.myTimesheet && <NavLink exact to='/' activeClassName='active'><FormattedMessage
        id='time_tracking.subnav.My Timesheet'/></NavLink>}
      {scope.timesheets && <NavLink to='/timesheets' activeClassName='active'><FormattedMessage
        id='time_tracking.subnav.Timesheets'/></NavLink>}
      {scope.payrollHours &&
        <NavLink to='/payroll_hours' activeClassName='active'><FormattedMessage id='time_tracking.subnav.Payroll Hours'/></NavLink>}
      {scope.manage &&
        <NavLink to='/manage' activeClassName='active'><FormattedMessage id='time_tracking.subnav.Manage'/></NavLink>}
    </Display>
  );
});

export default TimeTrackingSubnav;
