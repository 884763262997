import {observable, action} from 'mobx';
import _ from 'lodash';
import amplitude from 'amplitude-js';
import {DomainObject, DomainStore} from 'shared/store';
import {endpoints, types, api} from 'shared/core';
import Company from 'stores/users/Company';

class SignUpForm extends DomainObject {
  @observable firstName;
  @observable lastName;
  @observable email;
  @observable emailConfirmation;
  @observable password;
  @observable termsAccepted = false;
  @observable company;

  @action merge(other) {
    super.merge(other, {
      company: Company
    });
  }
}

class SignUpState {
  store = new DomainStore();
  redirectUrl;

  @observable form = new SignUpForm();
  @observable settingUp = false;
  @observable errors = {};
  @observable isSlim;
  @observable showBackLink;

  constructor({demoAccount, isSlim, redirectUrl, showBackLink}) {
    this.form.merge({
      company: {
        demoAccount
      }
    });

    this.isSlim = isSlim;
    this.redirectUrl = redirectUrl;
    this.showBackLink = showBackLink;
  }

  @action onLoad() {
    amplitude.getInstance().logEvent('Sign Up Viewed');
  }

  @action async submitForm() {
    const {model, errors} = await this.store.post(
      endpoints.USERS.ALL,
      types.USER,
      this.form
    );

    if (_.isEmpty(errors)) {
      amplitude.getInstance().logEvent('Sign Up Completed');

      this.form.merge(model);

      if (this.form.company.demoAccount) {
        this.settingUp = true;
        await this.setupDemoData();
      }

      this.redirectToPlatform();
    } else {
      amplitude.getInstance().logEvent('Sign Up Failed');
    }

    this.errors = errors;
  }

  @action async changeForm(partialForm) {
    this.form.merge(partialForm);
  }

  @action async setupDemoData() {
    await api.post('/setup_accounts');
  }

  redirectToPlatform() {
    window.location = this.redirectUrl || '/';
  }
}

export default SignUpState;
