import React from 'react';
import {FormattedMessage} from 'react-intl';
import {ActionLink, Avatar, Tag, Button, LexicalEditor} from 'components';
import {observer} from 'mobx-react';
import {auth, t} from 'shared/core';

const SaveButton = ({detailsState, entry}) => {
  return (
    <Button size='sm' trait='primary' onClick={async () => detailsState.updateEntry(entry)}>
      <FormattedMessage id='recruiting.hiring_funnel.UPDATE_ENTRY' values={{entry: t('recruiting.hiring_funnel.Feedback')}} />
    </Button>
  );
};

const Feedback = observer(({detailsState}) => {
  const errors = detailsState.newFeedbackErrors;
  const {canUpdateSentiment, editedEntry, hiringFunnelState} = detailsState;
  const {mentions} = hiringFunnelState;

  return (
    <div>
      <LexicalEditor
        placeholder={'recruiting.hiring_funnel.Add Feedback - Type @ to mention a coworker'}
        mentionOptions={mentions}
        onChange={text => detailsState.updateEditedFeedbackText(text)}
        initialEditorState={editedEntry.lexicalState}
        initialHTML={editedEntry.text}
        errorMessage={errors.text}
      />
      {canUpdateSentiment && <div className='py2'>
        <ActionLink onClick={() => detailsState.updateEditedEntry({sentiment: 'positive'})}>
          <Tag type='rounded' colour='haze' className={`TagSelect-positive ${editedEntry.sentiment === 'positive' ? 'selected' : ''} clickable mr1`}>
            <i className='material-icons h3 align-text-top pr1 grass'>{'thumb_up'}</i>
            <FormattedMessage id='recruiting.hiring_funnel.Positive'/>
          </Tag>
        </ActionLink>
        <ActionLink onClick={() => detailsState.updateEditedEntry({sentiment: 'negative'})}>
          <Tag type='rounded' colour='haze' className={`TagSelect-negative ${editedEntry.sentiment === 'negative' ? 'selected' : ''} clickable mr1`}>
            <i className='material-icons h3 align-text-top pr1 scarlet'>{'thumb_down'}</i>
            <FormattedMessage id='recruiting.hiring_funnel.Negative'/>
          </Tag>
        </ActionLink>
      </div>}
    </div>
  );
});

const NewEntry = ({detailsState, sectionViewModel, entry}) => {
  if (!detailsState.isBeingEdited(entry)) return null;

  return (
    <div className='clearfix border-top pt3 px3'>
      <div className='col col-1 pr1'>
        <Avatar employee={auth.user} size='sm' className='left'/>
      </div>
      <div className='col col-11 pl1'>
        <Feedback detailsState={detailsState} />
        <div className='flex align-items-center my2 right'>
          <Button trait='default' size='sm' className='px2' onClick={() => detailsState.cancelInput()}>
            <FormattedMessage id='recruiting.hiring_funnel.Cancel'/>
          </Button>
          <SaveButton entry={entry} detailsState={detailsState} sectionViewModel={sectionViewModel}/>
        </div>
      </div>
    </div>
  );
};

export default observer(NewEntry);
