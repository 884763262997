import React from 'react';
import {observer} from 'mobx-react';
import {withState, t, endpoints, types} from 'shared/core';
import {InteractiveTable, Layout} from 'components';
import SubNav from 'company_settings/SubNav';
import {OfferTemplateSummary} from 'stores/offers';
import OfferTemplatesState from '../state/OfferTemplatesState';

const COLUMNS = [
  {
    header: 'company_settings.offer_templates.Template Name',
    render: model => model.name,
    width: 11
  }
];

const LOCALIZATION = {
  emptyState: 'company_settings.offer_templates.No offer templates to display',
  interactive: {
    addModel: 'company_settings.offer_templates.Create new template',
  },
  removeModal: {
    header: 'company_settings.offer_templates.Remove offer template?',
    subHeader: 'company_settings.offer_templates.Are you sure you want to remove this offer template?',
    body: 'company_settings.offer_templates.This offer template will be permanently deleted.'
  }
};

const OfferTemplatesContainer = observer(({history, uiState}) => {
  return (
    <div>
      <SubNav title={t('company_settings.subnav.Offer Templates')}/>
      <Layout>
        <Layout.Section>
          <InteractiveTable
            title={t('company_settings.offer_templates.Offer Templates')}
            columns={COLUMNS}
            localization={LOCALIZATION}
            onAdd={() => history.push('/offer_templates/edit')}
            onRemove={template => uiState.removeTemplate(template)}
            saveStateToUrl={true}
            searchable={false}
            editRoute='/offer_templates/edit/:id'
            proxy={{
              type: 'async',
              modelType: types.OFFER_TEMPLATE_SUMMARY,
              model: OfferTemplateSummary,
              endpoint: endpoints.OFFER_TEMPLATES
            }}
          />
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(OfferTemplatesContainer, OfferTemplatesState);
