import React from 'react';
import {observer} from 'mobx-react';
import CompanyForms from './CompanyForms';
import {Panel} from 'components';
import {t} from 'shared/core';

const CompanyFormsFacade = observer(() => {
  return (
    <Panel>
      <Panel.Header title={t('pa_dashboard.Forms')}/>
      <CompanyForms/>
    </Panel>
  );
});

export default CompanyFormsFacade;
