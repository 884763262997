import React from 'react';
import ActionLink from './links/ActionLink';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';

const Banner = observer(({children, uiState, dismissable}) => {
  if (uiState.hidden) return null;

  return (
    <div className='Container center bg-meadow white h4 box-shadow-1 rounded'>
      <div className='block mx-auto py2'>
        {children}
        {dismissable && <ActionLink className='white' onClick={() => uiState.dismissBanner()}>
          <i className='material-icons h2 align-middle right'>{'close'}</i>
        </ActionLink>}
      </div>
    </div>
  );
});

Banner.defaultProps = {
  dismissable: true
};

class BannerState {
  @observable hidden;

  @action dismissBanner() {
    this.hidden = true;
  }
}

export default ({children, ...rest}) => {
  const uiState = new BannerState();
  return <Banner children={children} uiState={uiState} {...rest}/>;
};
