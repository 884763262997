import React from 'react';
import {observer} from 'mobx-react';
import {AttachedDocumentDownload, Panel} from 'components';
import {withState} from 'shared/core';
import {CompanyFormsState} from '../state';

const CompanyForms = observer(({uiState}) => {
  const {companyForms} = uiState;

  if (!companyForms.length) return <Panel.EmptyState message='pa_dashboard.forms.No forms to display'/>;

  return (
    <div className='space-y'>
      {companyForms.map(companyForm =>
        <div key={companyForm.id}>
          <AttachedDocumentDownload doc={companyForm} name={companyForm.title}/>
        </div>
      )}
    </div>
  );
});

export default withState(CompanyForms, CompanyFormsState);
