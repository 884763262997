import React from 'react';
import Modal from './Modal';
import ModalButtons from './ModalButtons';
import {observer} from 'mobx-react';
import {DragAndDrop} from 'components/dnd';

const DefaultComponent = observer(({model}) => {
  return <div>{model.name}</div>;
});

const ReorderModal = observer(({title, onHide, isOpen, models, size, component, onSave, onCancel}) => {
  const Component = component || DefaultComponent;

  return (
    <Modal title={title} onHide={onHide} size={size} isOpen={isOpen}>
      <DragAndDrop models={models} component={Component}/>
      <ModalButtons onSave={onSave} onCancel={onCancel}/>
    </Modal>
  );
});

ReorderModal.defaultProps = {
  size: 'md'
};

export default ReorderModal;
