import _ from 'lodash';
import {action, computed, observable} from 'mobx';
import {DomainObject, oneOf} from 'shared/store';
import {t} from 'shared/core';
import AttachedDocument from './AttachedDocument';

class CompanyDocument extends DomainObject {
  @observable id;
  @observable name = t('models.company_document.DEFAULT_NAME');
  @observable signatureRequired;
  @observable previewFile;
  @observable documentType;
  @observable locale;
  @observable order;
  @observable folderId;

  // PDF Template
  @observable annotations = [];
  @observable originalFile;

  // Rich Text Template
  @observable richText = `<br/>`;
  @observable lexicalState;

  @action merge(other) {
    super.merge(other, {
      previewFile: AttachedDocument,
      originalFile: AttachedDocument,
      documentType: oneOf(['rich_text_template', 'pdf_template', 'static_document'])
    });
  }

  @computed get typeView() {
    switch (this.documentType) {
      case 'rich_text_template':
      case 'pdf_template':
      case 'static_document':
        return t(`models.company_document.document_type.${this.documentType}`);
      default:
        throw new Error(`Document type of ${this.documentType} is not supported.`);
    }
  }

  @computed get hasFiles() {
    return !_.isEmpty(this.previewFile);
  }
}

export default CompanyDocument;
