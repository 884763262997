import {JsonApiDataStore, serialize} from './jsonapi-datastore-fork';
import {api, types} from 'shared/core';
import {toJS, action, computed, observable} from 'mobx';
import _ from 'lodash';
import moment from 'moment';

const POSITIVE_INFINITY = Number.POSITIVE_INFINITY || 2147483647;

class Enum {
  values;
  nullable;

  constructor(values, nullable) {
    this.values = values;
    this.nullable = nullable;
  }
}

export const createStore = () => {
  return new JsonApiDataStore();
};

export const getSingle = (response, type) => {
  const store = createStore();
  store.sync(response.data);
  return _.head(store.getAll(type));
};

export const fetchModels = async (url, type, options={}) => {
  const store = createStore();
  const response = await api.get(url, options);
  store.sync(response.data);
  return store.getAll(type);
};

export const fetchModel = async (url, type, options={}) => {
  const store = createStore();
  const response = await api.get(url, options);
  store.sync(response.data);
  return getSingle(response, type);
};

export const serializeModel = (plainObject, type) => {
  return serialize(plainObject, type);
};

export const canDeleteModel = (model) => {
  const methods = _.get(model, 'links.self.meta.methods');
  return methods && _.includes(methods, 'DELETE');
};

export const canPatchModel = (model) => {
  const methods = _.get(model, 'links.self.meta.methods');
  return methods && _.includes(methods, 'PATCH');
};

export const deleteModel = async (model) => {
  const href = _.get(model, 'links.self.href');
  return await api.delete(href);
};

const request = async (url, type, model, func) => {
  const payload = serializeModel(model, type);
  try {
    const response = await func(url, payload);
    return {
      model: getSingle(response, type),
      errors: {}
    };
  } catch (e) {
    if (_.get(e, 'response.status') === 409) {
      return {status: 409};
    }
    if (e.formErrors) {
      return {errors: e.formErrors, status: 422};
    }

    throw e;
  }
};

export const postModel = async (url, type, model = {}) => {
  return await request(url, type, model, api.post);
};

export const patchModel = async (url, type, model) => {
  return await request(url, type, model, api.patch);
};

export class DomainObject {
  @observable links;

  constructor (other) {
    if (other) {
      this.merge(other);
    }
  }

  @action merge(partialModel, relations = {}) {
    if (!partialModel) return;
    _.merge(this, partialModel);

    if (partialModel.employee) {
      const Employee = require('../stores/employees/Employee').default;
      this.employee = new Employee(partialModel.employee);
    }
    if (partialModel.location) {
      const Location = require('../stores/locations/Location').default;
      this.location = new Location(partialModel.location);
    }
    if (partialModel.department) {
      const Department = require('../stores/departments/Department').default;
      this.department = new Department(partialModel.department);
    }
    for (const key of ['createdAt', 'updatedAt', 'deletedAt']) {
      if (partialModel[key]) {
        this.hasDate(key, partialModel);
      }
    }

    _.forOwn(relations, (value, key) => {
      if (key === '_dates') {
        for (const dateProp of relations[key]) {
          this.hasDate(dateProp, partialModel);
        }
      } else if (key === '_numbers') {
        for (const numberProp of relations[key]) {
          this.hasNumber(numberProp, partialModel);
        }
      } else {
        if (_.isArray(value)) {
          this.hasMany(key, partialModel, value[0]);
        } else if (value instanceof Enum) {
          this.hasEnum(key, partialModel, value);
        } else {
          this.hasOne(key, partialModel, value);
        }
      }
    });
  }

  @action update(model) {
    _.forOwn(this, (value, key) => {
      if (_.startsWith(key, '_')) return;

      this[key] = undefined;
    });
    this.merge(model);
  }

  link(linkName) {
    const link = _.get(this.links, `${linkName}.href`);
    if (!link) {
      throw new Error(`There is no ${linkName} link on the object ${JSON.stringify(this)}.`);
    }
    return link;
  }

  hasLink(linkName) {
    return _.has(this.links, `${linkName}.href`);
  }

  equals(other) {
    return this.id === other.id;
  }

  pick(props = []) {
    return new this.constructor(_.pick(this, ['links', 'id', '_type', ...props]));
  }

  hasOne(name, other, _class) {
    if (other[name]) {
      this[name] = new _class(this[name]);
      this[name].merge(other[name]);
    }
  }

  hasMany(name, other, _class) {
    if (other[name]) {
      this[name] = this[name].map(t => new _class(t));
      for (const t of this[name]) {
        if (!t.id) continue;

        const existingItem = _.find(other[name], {id: t.id});
        t.merge(existingItem);
      }
    }
  }

  hasDate(name, other) {
    if (other[name]) {
      this[name] = new Date(moment(other[name]));
    }
  }

  hasNumber(name, other) {
    if (other[name]) {
      this[name] = Number(other[name]);
    }
  }

  hasEnum(name, other, value) {
    if ((other[name] || !value.nullable) && !_.includes(value.values, other[name])) {
      throw Error(`Expected one of the following: ${value.values}. Got instead: ${other[name]}. (${name} is an enum.)`);
    }

    if (other[name]) {
      this[name] = other[name];
    }
  }

  accessLevel(target) {
    if (!_.startsWith(target, '::')) {
      throw new Error(`Target name must be prefixed with two colons. Use model.hasAccess('::${target}').`);
    }

    const dataPermission = _.find(_.get(this, 'meta.dataPermissions'), {name: _.trimStart(target, '::')}) || {};

    switch (dataPermission.accessLevel) {
      case 'view':
        return 'r';
      case 'edit':
        return 'w';
      default:
        return false;
    }
  }

  @computed get canDelete() {
    return canDeleteModel(this);
  }

  @computed get canPatch() {
    return canPatchModel(this);
  }

  @computed get isNew() {
    const id = _.get(this, 'id');
    if (!id) {
      return true;
    }

    return id.indexOf('-') !== -1;
  }

  toJS() {
    return toJS(this);
  }
}

function load(endpoint) {
  if (_.isString(endpoint)) {
    return api.get(endpoint);
  } else if (_.isObject(endpoint) && _.has(endpoint, 'url')) {
    return api.get(endpoint.url, {params: endpoint.params || {}});
  } else {
    throw new Error(`Endpoint must be a string or an object {url, params}. Instead received: ${endpoint}`);
  }
}

export class DomainStore {
  _repository = createStore();

  async _compose(endpoints, options = {}) {
    const _endpoints = _.isArray(endpoints) ? endpoints : [endpoints];
    const requests = _endpoints.map(endpoint => {
      if (options.failSilently === true) {
        return load(endpoint).catch(e => {
          return {
            failedSilently: true, endpoint, e
          };
        });
      }
      return load(endpoint);
    });
    const result = [];
    for (const response of await Promise.all(requests)) {
      if (response.data) {
        result.push(this._repository.syncWithMeta(response.data));
      } else {
        result.push(response);
      }
    }
    return result;
  }

  _getAll(type, filters, _class) {
    const __class = arguments.length === 2 ? filters : _class;
    const __filters = arguments.length === 3 ? filters : null;
    const models = _.filter(this._repository.getAll(type).map(i => __class ? new __class(i) : i), __filters);
    const activeModels = _.reject(models, 'deletedAt');
    return _.orderBy(activeModels, model => parseInt(model.id) || POSITIVE_INFINITY);
  }

  _getSingle(type, filters) {
    return _.head(_.filter(this._getAll(type), filters));
  }

  async __request(endpoint, type, model, func) {
    const modelToSend = _.isFunction(model.toJS) ? model.toJS() : model;
    const payload = serializeModel(modelToSend, type);

    try {
      const response = await func(endpoint, payload);
      if (response.data) {
        this._repository.sync(response.data);
      }
      return {
        model: type && response.data ? this._getSingle(type, {id: getSingle(response, type).id}) : null,
        errors: {}
      };
    } catch (e) {
      if (e.formErrors) {
        return {errors: e.formErrors};
      }

      throw e;
    }
  }

  async post(endpoint, type, model = {}) {
    return this.__request(endpoint, type, model, api.post);
  }

  async patch(endpoint, type, model = {}) {
    if (_.isObject(endpoint)) {
      const _model = endpoint;
      return this.__request(_model.link('self'), _model._type, _model, api.patch);
    } else {
      return this.__request(endpoint, type, model, api.patch);
    }
  }

  async put(endpoint, type, model = {}) {
    return this.__request(endpoint, type, model, api.put);
  }

  async destroy(type, model = {}) {
    if (_.isObject(type)) {
      await deleteModel(type);
      this._repository.destroy(type);
    } else {
      await deleteModel(model);
      this._repository.destroy({_type: type, id: model.id});
    }
  }

  async fetch(endpoint, type, urlParams = {}) {
    const response = await api.get(endpoint, {params: urlParams || {}});
    this._repository.sync(response.data);
    return this._getSingle(type);
  }

  getEmployees() {
    // TODO: move DomainObject into a separate file and use import statement
    const Employee = require('../stores/employees/Employee').default;
    return _.chain(this._getAll(types.EMPLOYEE))
      .map(e => new Employee(e))
      .orderBy('firstName')
      .value();
  }

  getEmployee(employeeId) {
    // TODO: move DomainObject into a separate file and use import statement
    const Employee = require('../stores/employees/Employee').default;
    return new Employee(this._getSingle(types.EMPLOYEE, {id: employeeId}));
  }

  getLocations() {
    // TODO: move DomainObject into a separate file and use import statement
    const Location = require('../stores/locations/Location').default;
    return _.orderBy(this._getAll(types.LOCATION), 'name').map(
      e => new Location(e)
    );
  }

  getDepartments() {
    // TODO: move DomainObject into a separate file and use import statement
    const Department = require('../stores/departments/Department').default;
    return _.orderBy(this._getAll(types.DEPARTMENT), 'name').map(
      e => new Department(e)
    );
  }

  getUsers(options = {}) {
    // TODO: move DomainObject into a separate file and use import statement
    const User = require('../stores/users/User').default;
    return _.orderBy(this._getAll(types.USER).map(
        e => new User(e)
      ), 'name');
  }

  invalidate(type, filters) {
		this._repository.invalidate(type, filters);
  }
}

export function oneOf(values, nullable = true) {
  return new Enum(values, nullable);
}
