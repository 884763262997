import React from 'react';
import {observer} from 'mobx-react';
import {withState, withDisplay, t} from 'shared/core';
import {EmployeeNameWithAvatar, Layout, Panel, Table, Tag} from 'components';
import {FormattedMessage} from 'react-intl';
import SubNav from 'company_settings/SubNav';
import useModal from 'shared/hooks/useModal';
import {ZapierConfigurationContainerState} from '../state';
import {UserApiKey} from 'stores/users';
import EditUserApiKeyModal from './EditUserApiKeyModal';

const UserApiKeyName = observer(({model}) => {
  return (
    <span>
      <div className='table-cell align-middle'>
        {model.label}
      </div>
      {!model.active && <div className='table-cell align-middle pl1'>
        <Tag colour='rose'>
          <FormattedMessage id='company_settings.applications.zapier.Disabled'/>
        </Tag>
      </div>}
    </span>
  );
});

const COLUMNS = [
  {
    header: 'company_settings.applications.zapier.Created By',
    width: 3,
    render: (model => <EmployeeNameWithAvatar employee={model.user} size='sm'/>)
  },
  {
    header: 'company_settings.applications.zapier.Label',
    width: 3,
    component: UserApiKeyName
  },
  {
    header: 'company_settings.applications.zapier.API Key',
    width: 1,
    attribute: 'maskedApiKey'
  },
  {
    header: 'company_settings.applications.zapier.Creation Date',
    width: 2,
    attribute: 'createdAtView'
  },
  {
    header: 'company_settings.applications.zapier.Last Used',
    width: 2,
    attribute: 'lastUsedAtView'
  }
];

const LOCALIZATION = {
  addModel: 'company_settings.applications.zapier.+ Generate New API Key',
  emptyState: 'company_settings.applications.zapier.No API keys to display',
  removeModal: {
    header: 'company_settings.applications.zapier.Remove API Key?',
    subHeader: 'company_settings.applications.zapier.Are you sure you want to remove this API key?',
    body: 'company_settings.applications.zapier.All integrations that were set up with this key will no longer work.'
  }
};

const Info = () => {
  return (
    <Panel className='bg-sky border-left--dodger b1 '>
      <Panel.Header title={t('company_settings.applications.zapier.EXPLANATION_HEADER')}/>
      <div className='clearfix'>
        <FormattedMessage id='company_settings.applications.zapier.EXPLANATION_BODY'/>&nbsp;
        <a className='nowrap' href='https://support.collage.co/en/articles/8725478-setting-up-zapier' target='_blank'><FormattedMessage id='Learn more'/></a>
      </div>
    </Panel>
  );
};

const UserApiKeysList = observer(({uiState}) => {
  const {userApiKeys} = uiState;
  const editUserApiKeyModalOpen = useModal(uiState, 'EditUserApiKeyModal', 'selectedUserApiKey', UserApiKey);

  return (
    <Panel>
      <Panel.Header title={t('company_settings.applications.zapier.API Keys')}/>
      <Table
        uiState={uiState}
        models={userApiKeys}
        columns={COLUMNS}
        localization={LOCALIZATION}
        onAdd={() => uiState.openEditUserApiKeyModal()}
        onEdit={userApiKey => uiState.openEditUserApiKeyModal(userApiKey)}
        onRemove={userApiKey => uiState.deleteUserApiKey(userApiKey)}
      />
      <EditUserApiKeyModal modalOpen={editUserApiKeyModalOpen} uiState={uiState}/>
    </Panel>
  );
});

const ZapierConfigurationContainer = observer(({uiState}) => {
  return (
    <Layout>
      <Panel.Stack loose>
        <Info/>
        <UserApiKeysList uiState={uiState}/>
      </Panel.Stack>
    </Layout>
  );
});

export default withDisplay(
  withState(ZapierConfigurationContainer, ZapierConfigurationContainerState),
  () => <SubNav title={t('company_settings.subnav.Zapier')}/>
);
