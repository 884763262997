import {observable, action, computed} from 'mobx';
import _ from 'lodash';
import FieldViewModel from './FieldViewModel';

class FieldSectionViewModel {
  @observable data;
  @observable report;
  @observable checked = false;
  @observable fields = [];

  constructor(data, report) {
    this.data = data;
    this.report = report;

    this.fields = this.data.values.map(v => new FieldViewModel(v, report));
    this.checked = !!_.find(this.fields, { checked: true });
  }

  @action toggleAllChecked() {
    this.checked = !this.checked;
    for (const f of this.fields) {
      f.checked = this.checked;
    }
  }

  @action updateField(field, value) {
    if (field === 'employmentRecordsShowHistory') {
      this.report.merge({employmentRecordsShowHistory: value});
    }
  }

  @action toggleFieldChecked(fieldViewModel) {
    fieldViewModel.toggleChecked();
    this.checked = !(this.checkedFieldCount === 0);
  }

  @computed get middleIndex() {
    return parseInt(this.data.values.length / 2) + (this.data.values.length % 2);
  }

  @computed get firstHalf() {
    return this.fields.slice(0, this.middleIndex);
  }

  @computed get secondHalf() {
    return this.fields.slice(this.middleIndex);
  }

  @computed get checkedFieldCount() {
    return _.filter(this.fields, { checked: true }).length;
  }
}

export default FieldSectionViewModel;
