import React from 'react';
import {observer} from 'mobx-react';
import {ObjectSelect2, Select2, Panel, DatePicker, FormLayout, QuickTip} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import PredictedAccountsSummary from './PredictedAccountsSummary';

const EffectiveDateOptionSelector = observer(({uiState}) => {
  if (!uiState.newPolicy) return null;

  return (
    <div>
      <Select2
        label='employees.profile.time_off.Effective Date'
        value={uiState.effectiveDateOption}
        onChange={e => uiState.updateEffectiveDateOption(e.target.value)}
      >
        {uiState.effectiveDateOptions.map(option =>
          <option key={option.value} value={option.value}>{option.name}</option>
        )}
      </Select2>
      {uiState.effectiveDateExplainer &&
        <div className='pt3 pb2'>
          <QuickTip
            trait='caution'
            {...uiState.effectiveDateExplainer}
          />
        </div>
      }
    </div>
  );
});

const ChooseNewPolicy = observer(({uiState}) => {
  const {employee, errors, oldPolicy} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('employees.profile.time_off.Choose New Policy')}
        description={<FormattedMessage id='employees.profile.time_off.CHOOSE_NEW_POLICY_EXPLAINER' values={{
          employeeName: employee.name,
          timeOffPolicy: <b>{oldPolicy.name}</b>
        }}/>}
      />
      <FormLayout>
        <ObjectSelect2
          value={employee.timeOffPolicy}
          onChange={value => uiState.setNewPolicy(value)}
          items={uiState.policies}
          errorMessage={errors.timeOffPolicy}
          label='employees.profile.time_off.New Policy'
        />
        <EffectiveDateOptionSelector uiState={uiState}/>
        {uiState.effectiveDateOption === 'custom' &&
          <DatePicker
            label='employees.profile.time_off.Enter Date'
            helpLink={{
              explanation: {
                header: 'employees.profile.time_off.effective_date_explanation_header',
                paragraphs: [
                t('employees.profile.time_off.effective_date_explanation_1'),
                t('employees.profile.time_off.effective_date_explanation_2')
                ]
              }
            }}
            onChange={date => uiState.updateEffectiveDate(date)}
            value={uiState.effectiveDate}
            options={{
              endDate: new Date()
            }}
          />
        }
      </FormLayout>
      <PredictedAccountsSummary uiState={uiState}/>
    </div>
  );
});

export default ChooseNewPolicy;
