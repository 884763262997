import React from 'react';
import {observer} from 'mobx-react';
import {
  Layout,
  AvatarPanel,
  Display,
  Panel,
  ActionLink
} from 'components';
import {t, withState} from 'shared/core';
import {RevieweeResultsState} from '../state';
import {ReviewDetails} from '../../review_details';
import {FormattedMessage} from 'react-intl';
import ReviewList from './ReviewList';
import GoalList from './GoalList';

const EmptyResults = () => {
  return (
    <div className='py4 center' style={{border: '2px dashed #C9CFD1'}}>
      <div className='h1 black medium pt4 mt3'>
        <FormattedMessage id='performance_reviews.view.Results are on the way.'/>
      </div>
      <div className='h3 jumbo pt2 pb4 mb3'>
        <FormattedMessage id="performance_reviews.view.Reviews have not been submitted for this employee."/>
      </div>
    </div>
  );
};

const PrintFriendlyActionLink = observer(() => {
  return (
    <ActionLink className='h4' onClick={() => window.print()}>
      <FormattedMessage id='performance_reviews.view.Printer friendly version'/>
      <i className='material-icons h3 ml1 align-middle inline'>{'print'}</i>
    </ActionLink>
  );
});

const ReviewDetailsContainer = observer(({uiState}) => {
  const {hasViewableReviews, viewableReviews, reviewee, errors} = uiState;

  if (!hasViewableReviews) return <EmptyResults/>;

  return viewableReviews.map(review => (
    <div id={review.id} ref={e => uiState.addRef(e)} className='mb2'>
      <ReviewDetails
        uiState={uiState}
        title={t(uiState.reviewDetailsTitle(review), {
          reviewer: review.reviewer.name
        })}
        description={t(uiState.visibilityNotice(review), {
          employee: reviewee.employee.firstName
        })}
        errors={errors}
        review={review}
        isOpen={uiState.submitReviewModalOpen}
        onOpen={() => uiState.openSubmitReviewModal()}
        onHide={() => uiState.closeSubmitReviewModal()}
        onSubmit={(review) => uiState.submitReview(review)}
        onUnsubmit={(review) => uiState.unsubmitReview(review)}
      />
    </div>
  ));
});

const RevieweeResultsContainer = observer(({uiState, match, cycle}) => {
  const {
    reviewee,
    hasOtherReviewerReviews,
    canViewRevieweeGoals
  } = uiState;
  const {backPath, backTitle} = uiState.getBackButtonDetails(match.params);

  return (
    <div>
      <Display
        module
        title={cycle.name}
        backTitle={t(backTitle)}
        backPath={backPath}
        toolbar={<PrintFriendlyActionLink/>}
      />
      <Layout>
        <Layout.Section secondary>
          <Panel.Stack loose>
            <AvatarPanel employee={reviewee.employee}/>
            {hasOtherReviewerReviews && <ReviewList uiState={uiState}/>}
            {canViewRevieweeGoals && <GoalList uiState={uiState}/>}
          </Panel.Stack>
        </Layout.Section>
        <Layout.Section>
          <ReviewDetailsContainer uiState={uiState}/>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(RevieweeResultsContainer, RevieweeResultsState);
