import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {NotFound} from 'components';
import CheckInSwitch from './CheckInSwitch';
import CyclesSwitch from './CyclesSwitch';
import RevieweesSwitch from './RevieweesSwitch';
import CyclePageState from '../state/cycles/CyclePageState';

const PerformanceManagementPage = observer(({uiState}) => {
  const {scope} = uiState;

  return (
    <Router basename='/performance'>
      <Switch>
        <Route path='/checkins' render={() => <CheckInSwitch scope={scope}/>}/>
        <Route path='/cycles' render={() => <CyclesSwitch scope={scope}/>}/>
        <Route path='/reviewees' render={() => <RevieweesSwitch scope={scope}/>}/>
        {scope.reviewees && <Redirect to='reviewees'/>}
        {scope.cycles && <Redirect to='cycles'/>}
        {scope.checkins && <Redirect to='checkins'/>}
        <Route path='*' render={(props) => <NotFound {...props} title='Performance' url='/' />} />
      </Switch>
    </Router>
  );
});

export default withState(PerformanceManagementPage, CyclePageState);
