import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {UploadFiles, Input, Panel, LexicalEditor, Select2, FormLayout} from 'components';
import mergeFields from 'stores/offers/mergeFields';

const OfferTemplateForm = observer(({uiState}) => {
  const {template, errors} = uiState;

  return (
    <Panel.Stack>
      <Panel>
        <Panel.Header title={
          template.isNew ?
            t('company_settings.offer_templates.New Offer Template') :
            t('company_settings.offer_templates.Edit Offer Template')
          }
        />
        <FormLayout>
          <Input
            label='company_settings.offer_templates.Template Name'
            value={template.name}
            onChange={(e) => uiState.updateTemplateName(e)}
            errorMessage={errors.name}
          />
          <Select2
            label='company_settings.offer_templates.Language'
            value={template.locale}
            onChange={e => uiState.updateTemplateLocale(e)}
          >
            <option value='en'>{'English'}</option>
            <option value='fr'>{'Français'}</option>
          </Select2>
          <LexicalEditor
            label={'company_settings.offer_templates.Offer Letter'}
            onChange={content => uiState.updateContent(content)}
            mergeFields={mergeFields}
            initialEditorState={uiState.template.lexicalState}
            initialHTML={uiState.template.richText}
            enableCustomMergeFields
          />
        </FormLayout>
      </Panel>
      <Panel>
        <Panel.Header title={t('company_settings.offer_templates.Email Attachments')}/>
        <div className='waterloo pb1'>
          <FormattedMessage id='company_settings.offer_templates.Upload files that will be attached to each offer that is sent using this template.'/>
        </div>
        <UploadFiles
          defaultFiles={template.attachments}
          onChange={files => uiState.updateTemplateAttachments(files)}
          modelType='offers/attachment'
        />
      </Panel>
    </Panel.Stack>
  );
});

export default OfferTemplateForm;
