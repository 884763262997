import React from 'react';
import {observer} from 'mobx-react';
import {endpoints, types} from 'shared/core';
import {InteractiveTable, DropdownFilter} from 'components';
import Position from '../../../../../stores/recruiting/Position';
import PositionName from './PositionName';
import PositionEmploymentType from 'recruiting/components/directory/position_list/PositionEmploymentType';

const COLUMNS = [
  {
    header: 'recruiting.position.Title',
    width: 4,
    component: PositionName,
    sortBy: 'title'
  },
  {
    header: 'recruiting.position.Department',
    width: 2,
    attribute: 'department.name',
  },
  {
    header: 'recruiting.position.Location',
    width: 2,
    attribute: 'location.name'
  },
  {
    header: 'recruiting.position.Created Date',
    width: 2,
    attribute: 'createdDate',
    sortBy: 'createdAt'
  },
  {
    header: 'recruiting.position.Employment Type',
    width: 1,
    component: PositionEmploymentType,
    sortBy: 'employmentType'
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'recruiting.directory.Remove Position?',
    subHeader: 'recruiting.directory.Are you sure you want to remove this position?',
    body: 'recruiting.directory.All candidate data associated with this job position will be deleted.'
  }
};

const PositionList = observer(({uiState}) => {
  return (
    <InteractiveTable
      uiState={uiState}
      onRemove={(position) => uiState.destroyPosition(position)}
      localization={LOCALIZATION}
      editRoute='/positions/:id'
      columns={COLUMNS}
      customLinks={position => uiState.customLinksFor(position)}
      showRoute={model => model.directoryUrl}
      proxy={{
        type: 'async',
        modelType: types.RECRUITING.POSITION,
        model: Position,
        endpoint: endpoints.RECRUITING.POSITIONS.DIRECTORY
      }}
    >
      <DropdownFilter options={uiState.statusOptions} attr='status'/>
    </InteractiveTable>
  );
});

export default PositionList;
