import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const AssignmentDetails = observer(({assignment}) => {
  if (assignment.delegatedByUser) {
    return (
      <div>
        <span className='mr1'>{assignment.assigneeUser.name}</span>
        <span className='waterloo'><FormattedMessage id='time_off.requests.ASSIGNMENT_DELEGATED_BY' values={{name: assignment.delegatedByUser.name}}/></span>
      </div>
    );
  }

  return (
    <div>
      {assignment.assigneeUser.name}
    </div>
  );
});

const AssignmentsList = observer(({assignments}) => {
  return (
    <div>
      {assignments.map((assignment, index) =>
        <AssignmentDetails index={index} key={assignment.id} assignment={assignment}/>)
      }
    </div>
  );
});

export default AssignmentsList;
