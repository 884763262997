import {MergeFieldNode} from './MergeFieldNode';

const DATA_ENTITY_TYPE = 'RENDERED_FIELD';

export class RenderedFieldNode extends MergeFieldNode {
  get entityType() {
    return DATA_ENTITY_TYPE;
  }

  static getType() {
    return 'rendered-field';
  }

  static clone(node) {
    return new RenderedFieldNode(node.__text, node.__dataKey, node.__key);
  }

  static importJSON(serializedNode) {
    const node = $createRenderedFieldNode(serializedNode.text, serializedNode.dataKey);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  static importDOM() {
    return {
      span: (node) => {
        if (node.getAttribute('data-entity-type') === DATA_ENTITY_TYPE) {
          return {
            conversion: convertRenderedFieldElement,
            priority: 4
          };
        }
        return null;
      }
    };
  }
}

function convertRenderedFieldElement(domNode) {
  let textContent = domNode.textContent || '';
  let dataKey = domNode.getAttribute('data-key');

  return {
    node: $createRenderedFieldNode(textContent, dataKey)
  };
}

export function $createRenderedFieldNode(text, dataKey) {
  return new RenderedFieldNode(text, dataKey);
}

export function $isRenderedFieldNode(node) {
  return node instanceof RenderedFieldNode;
}
