import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {DragSource, DropTarget} from 'react-dnd';
import {ActionLink, FormError} from 'components';

const stageSource = {
  beginDrag(props) {
    return {
      index: props.stage.order
    };
  }
};

const stageTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.stage.order;

    if (dragIndex === hoverIndex) return;

    props.onStageDrag(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  }
};

const collectSource = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
};

const collectTarget = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};

const Stage = ({stage, onStageDelete, onStageDrag, isDragging, isOver, connectDragSource, connectDropTarget, connectDragPreview, errors = {}, intl}) => {
  return (
    <div className='clearfix table HoverAnimation-trigger'>
      <div className='col-11 table-cell align-middle pr1'>
        {stage.isRequired ? (<div className='p2 my2 bg-athens rounded'>
            <div className='inline-block'>{stage.name}</div>
            <div className='right waterloo'><FormattedMessage id='recruiting.edit.steps.Required'/></div>
          </div>)
        : (connectDragPreview(connectDropTarget(
          <div className={`py1 my2 bg-white border rounded clickable ${isOver ? 'is-over' : ''}`}>
            <div className='clearfix table'>
              <div className='col-11 table-cell align-middle'>
                <input value={stage.name} onChange={(e) => stage.name = e.target.value}
                       className={`border-none full-width ${isOver ? 'is-over' : ''}`}
                       style={{transition: 'none'}} type='text' placeholder={intl.formatMessage({id: 'recruiting.edit.steps.Add stage'})} />
              </div>
              {connectDragSource(<div className='col-1 table-cell align-middle right-align pr1'>
                <i className='material-icons submarine pt1'>{'drag_handle'}</i>
              </div>)}
            </div>
            <div className='pl1'>
              <FormError message={errors.name} />
            </div>
          </div>
        )))}
      </div>
      <div className='col-1 table-cell align-middle pl1'>
        {!stage.isRequired && <div className='HoverAnimation-target--show'>
          <ActionLink onClick={() => onStageDelete(stage)} className='submarine'><i className='material-icons mx1 h2'>{'delete'}</i></ActionLink>
        </div>}
      </div>
    </div>
  );
};

const dragSource = DragSource('stage', stageSource, collectSource)(injectIntl(observer(Stage)));
export default DropTarget('stage', stageTarget, collectTarget)(dragSource);
