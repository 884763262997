import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const EmployeeDisplayId = observer(({carrier, employeeInsuranceNumber}) => {
  if(carrier.carrier === 'sirius' && carrier.dataFeedEnabled) {
    return (
      <div>
        {t('components.coverages_list.Your ID is on your drug card. Please contact your admin if you do not have a drug card.')}
      </div>
    );
  }

  return (
    <div>
      {_.get(employeeInsuranceNumber, 'externalId', t('employee_benefit_enrollments.statuses.pending'))}
    </div>
  );
});

const CardDetails = observer(({uiState}) => {
  const {currentCarrier, currentEnrolment, currentInsuranceNumber} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('components.coverages_list.Card Information')} />
      <div className='clearfix mxn2 mb1'>
        <div className='col col-6 px2 jumbo'>
          <FormattedMessage id='components.coverages_list.Provider'/>
        </div>
        <div className='col col-6 px2'>
          {currentCarrier.name}
        </div>
      </div>
      <div className='clearfix mxn2 mb1'>
        <div className='col col-6 jumbo px2'>
          <FormattedMessage id='components.coverages_list.Group ID'/>
        </div>
        <div className='col col-6 px2'>
          {currentCarrier.externalId}
        </div>
      </div>
      <div>
        <div className='clearfix mxn2 mb1'>
          <div className='col col-6 jumbo px2'>
            <FormattedMessage id='components.coverages_list.Employee ID'/>
          </div>
          <div className='col col-6 px2'>
            <EmployeeDisplayId carrier={currentCarrier} employeeInsuranceNumber={currentInsuranceNumber}/>
          </div>
        </div>
        {currentEnrolment.effectiveDate && <div className='clearfix mxn2 mb1'>
          <div className='col col-6 jumbo px2'>
            <FormattedMessage id='components.coverages_list.Effective Date'/>
          </div>
          <div className='col col-6 px2'>
            {currentEnrolment.effectiveDateDisplay}
          </div>
        </div>}
        <div className='clearfix mxn2 mb1'>
          <div className='col col-6 jumbo px2'>
            <FormattedMessage id='components.coverages_list.Status'/>
          </div>
          <div className='col col-6 px2'>
            {currentEnrolment.translatedStatus}
          </div>
        </div>
      </div>
    </Panel>
  );
});

export default CardDetails;
