import React from 'react';
import BackIcon from 'img/back-arrow@2x.png';
import {FormattedMessage} from 'react-intl';

const BackToIndex = () => {
  return (
    <div className='clearfix mb3 py2 border-bottom'>
      <div className='Container'>
        <ul className='Breadcrumb'>
          <li>
            <a href='/inbox' className='HoverAnimation-trigger--image'>
              <img className='mr1' src={BackIcon} alt='back' height='24px'/>
              <span className='align-middle'>
                <FormattedMessage id='pending_documents.Inbox'/>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BackToIndex;
