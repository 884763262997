import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, FormLayout, Input, ActionLink, FormError} from 'components';
import {FormattedMessage} from 'react-intl';

const PlanAdminInformation = observer(({uiState}) => {
  const {brokerClient, errors} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('broker_portal.clients.edit.Plan Administrator Information')}/>
      <FormLayout>
        {brokerClient.sortedPlanAdmins.map((planAdmin, index) =>
          <div key={index}>
            <div className='mb2'>
              <div className='table'>
                <div className='col-6 table-cell align-middle medium'>
                  <FormattedMessage
                    id='broker_portal.clients.edit.PLAN_ADMIN'
                    values={{index: index + 1}}
                  />
                </div>
                <div className='col-6 table-cell align-middle right-align'>
                  <ActionLink subdued onClick={() => uiState.removePlanAdmin(planAdmin)}>
                    <FormattedMessage id='broker_portal.clients.edit.Remove'/>
                  </ActionLink>
                </div>
              </div>
            </div>
            <div className='mb2'>
              <FormLayout.Group>
                <Input
                  value={planAdmin.firstName}
                  onChange={e => planAdmin.merge({firstName: e.target.value})}
                  label='broker_portal.clients.edit.First Name'
                  errorMessage={_.get(errors, `planAdmins[${index}].firstName`)}
                />
                <Input
                  value={planAdmin.lastName}
                  onChange={e => planAdmin.merge({lastName: e.target.value})}
                  label='broker_portal.clients.edit.Last Name'
                  errorMessage={_.get(errors, `planAdmins[${index}].lastName`)}
                />
              </FormLayout.Group>
            </div>
            <FormLayout.Group>
              <Input
                value={planAdmin.email}
                onChange={e => planAdmin.merge({email: e.target.value})}
                label='broker_portal.clients.edit.Email Address'
                errorMessage={_.get(errors, `planAdmins[${index}].email`)}
              />
              <Input
                value={planAdmin.phone}
                onChange={e => planAdmin.merge({phone: e.target.value})}
                label='broker_portal.clients.edit.Phone Number'
                errorMessage={_.get(errors, `planAdmins[${index}].phone`)}
              />
            </FormLayout.Group>
          </div>
        )}
      </FormLayout>
      <ActionLink onClick={() => uiState.addPlanAdmin()}>
        <FormattedMessage id='broker_portal.clients.edit.+ Add Plan Admin'/>
      </ActionLink>
      <div className='mt2'>
        <FormError message={errors.planAdminInformation}/>
      </div>
    </Panel>
  );
});

export default PlanAdminInformation;
