import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Panel, FormLayout, Input, Select2, RadioButton, RadioButtonGroup, TextArea} from 'components';
import cc from 'currency-codes';
import PAY_FREQUENCY_TYPES from 'stores/employees/payFrequencyTypes';
import {t} from 'shared/core';
import CommissionForm from 'employees/components/CommissionForm';

const CompensationInformation = observer(({uiState}) => {
  const record = uiState.editRecord;
  const {parentState, errors, employee} = uiState;
  if (!parentState.editDataPermission('::COMPENSATION_INFORMATION')) return null;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.role.Compensation')}/>
      <FormLayout>
        <Input
          label='employees.profile.role.Salary (or pay rate)'
          value={record.payRate}
          onChange={e => record.merge({payRate: e.target.value})}
          errorMessage={errors.payRate || errors.payRateUnit}
          connectedRight={
            <Select2
              value={record.payRateUnit}
              onChange={e => record.merge({payRateUnit: e.target.value})}
            >
              {uiState.payRateUnitTypes.map(p => <option value={p.id} key={p.id}>{t(`employees.profile.role.per_unit.${p.id}`)}</option>)}
            </Select2>
          }
        />
        <FormLayout.Group>
          <Select2
            label='employees.profile.role.Pay Currency'
            value={record.payRateCurrency}
            onChange={e => record.merge({payRateCurrency: e.target.value})}
            errorMessage={errors.payRateCurrency}
            searchable
          >
            {cc.codes().map(c => <option key={c} value={c}>{c}</option>)}
          </Select2>
          <Select2
            label='employees.profile.role.Pay Frequency'
            value={record.payFrequency}
            onChange={e => record.merge({payFrequency: e.target.value})}
            allowClear
            helpLink={{
              explanation: {
                header: t('employees.profile.role.pay_frequency_explanation.header'),
                paragraphs: [
                  t('employees.profile.role.pay_frequency_explanation.explanation_1', {employee: employee.name}),
                  t('employees.profile.role.pay_frequency_explanation.explanation_2')
                ]
              }
            }}
          >
            {PAY_FREQUENCY_TYPES.map(p => <option value={p.id} key={p.id}>{t(p.name)}</option>)}
          </Select2>
        </FormLayout.Group>
        <RadioButtonGroup
          label='employees.profile.role.Employee earns a bonus'
          name='bonus'
          selectedValue={record.bonus}
          onChange={(name, value) => record.merge({bonus: value})}
        >
          <RadioButton className='table-cell pr2' value={false}><FormattedMessage id='employees.profile.role.No'/></RadioButton>
          <RadioButton className='table-cell pl2' value={true}><FormattedMessage id='employees.profile.role.Yes'/></RadioButton>
        </RadioButtonGroup>
        {record.bonus &&
          <TextArea
            label='employees.profile.role.Bonus Details'
            value={record.bonusStructure}
            onChange={(e) => record.merge({bonusStructure: e.target.value})}
            placeholder='employees.profile.role.Enter the bonus details here'
            className='full-width' minRows={3}
          />
        }
        <CommissionForm model={record} errors={errors} />
      </FormLayout>
    </Panel>
  );
});

export default CompensationInformation;
