import React from 'react';
import {observer} from 'mobx-react';
import {Route, Switch, Redirect} from 'react-router-dom';
import BillingDetailsContainer from './BillingDetailsContainer';
import SubscriptionFlowContainer from './SubscriptionFlowContainer';
import {auth} from 'shared/core';

const RedirectIfSubscriptionNotEnabled = observer(({params}) => {
  if (auth.company.onboardingCompleted || auth.company.subscriptionEnabled) return (
    <Route exact path='/billing' component={BillingDetailsContainer} />
  );

  return (
    <Redirect exact from='/billing' to='/billing/subscription'/>
  );
});

const BillingSubscriptionPage = observer(() => {
  return (
    <Switch>
      <Route path='/billing/subscription' component={SubscriptionFlowContainer} />
      <RedirectIfSubscriptionNotEnabled/>
    </Switch>
  );
});

export default BillingSubscriptionPage;
