import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, NumberInput, CheckboxWithLabel, RadioButton, RadioButtonGroup, Select2} from 'components';
import {FormattedMessage} from 'react-intl';

const StandardDetails = observer(({uiState}) => {
  const {policy, errors} = uiState;

  if (policy.overtimePolicy !== 'standard') return null;

  return (
    <div className='mt1' style={{marginLeft: '28.8px'}}>
      <div className='mt2'>
        <Select2
          label='time_tracking.manage.policies.edit.Start of the week'
          helpLink={{
            explanation: {
              header: 'time_tracking.manage.policies.edit.Start of the week',
              paragraphs: [t('time_tracking.manage.policies.edit.WEEK_START')]
            }
          }}
          value={policy.weekStartDay}
          onChange={e => policy.weekStartDay = e.target.value}
          errorMessage={errors.weekStartDay}
        >
          <option value={0}>{t('time_tracking.manage.policies.edit.days_of_week.Sunday')}</option>
          <option value={1}>{t('time_tracking.manage.policies.edit.days_of_week.Monday')}</option>
          <option value={2}>{t('time_tracking.manage.policies.edit.days_of_week.Tuesday')}</option>
          <option value={3}>{t('time_tracking.manage.policies.edit.days_of_week.Wednesday')}</option>
          <option value={4}>{t('time_tracking.manage.policies.edit.days_of_week.Thursday')}</option>
          <option value={5}>{t('time_tracking.manage.policies.edit.days_of_week.Friday')}</option>
          <option value={6}>{t('time_tracking.manage.policies.edit.days_of_week.Saturday')}</option>
        </Select2>
      </div>
    </div>
  );
});

const CustomDetails = observer(({uiState}) => {
  const {
    policy,
    dailyOvertimeEnabled,
    weeklyOvertimeEnabled,
    doubleOvertimeEnabled,
    errors
  } = uiState;

  if (policy.overtimePolicy !== 'custom') return null;

  return (
    <div className='mt1' style={{marginLeft: '28.8px'}}>
      <div className='mt2'>
        <Select2
          label='time_tracking.manage.policies.edit.Start of the week'
          helpLink={{
            explanation: {
              header: 'time_tracking.manage.policies.edit.Start of the week',
              paragraphs: [t('time_tracking.manage.policies.edit.WEEK_START')]
            }
          }}
          value={policy.weekStartDay}
          onChange={e => policy.weekStartDay = e.target.value}
          errorMessage={errors.weekStartDay}
        >
          <option value={0}>{t('time_tracking.manage.policies.edit.days_of_week.Sunday')}</option>
          <option value={1}>{t('time_tracking.manage.policies.edit.days_of_week.Monday')}</option>
          <option value={2}>{t('time_tracking.manage.policies.edit.days_of_week.Tuesday')}</option>
          <option value={3}>{t('time_tracking.manage.policies.edit.days_of_week.Wednesday')}</option>
          <option value={4}>{t('time_tracking.manage.policies.edit.days_of_week.Thursday')}</option>
          <option value={5}>{t('time_tracking.manage.policies.edit.days_of_week.Friday')}</option>
          <option value={6}>{t('time_tracking.manage.policies.edit.days_of_week.Saturday')}</option>
        </Select2>
      </div>
      <div className='jumbo my2 clickable'>
        <FormattedMessage id='time_tracking.manage.policies.edit.Choose how many hours must pass for different overtime multipliers to apply.'/>
      </div>
      <div className='flex flex-col md-flex-row row-gap-1'>
        <div className='col col-12 md-col-4'>
          <CheckboxWithLabel
            checked={dailyOvertimeEnabled}
            label={'time_tracking.manage.policies.edit.Daily overtime'}
            onChange={(e) => uiState.toggleDailyOvertime(e.checked)}
          />
          <NumberInput
            max={24}
            min={0}
            disabled={!dailyOvertimeEnabled}
            value={policy.dailyOvertimeThreshold}
            onChange={(e) => policy.dailyOvertimeThreshold = e.target.value}
            errorMessage={errors.dailyOvertimeThreshold}
            connectedRight={
              <div className='InputGroup'>
                <span className='InputGroup-tag small'>{t('time_tracking.manage.policies.edit.hours')}</span>
              </div>
            }
          />
        </div>
        <div className='col col-12 md-col-4 md-pl2'>
          <CheckboxWithLabel
            checked={doubleOvertimeEnabled}
            disabled={!dailyOvertimeEnabled}
            label={'time_tracking.manage.policies.edit.Daily double overtime'}
            onChange={(e) => uiState.toggleDoubleOvertime(e.checked)}
          />
          <NumberInput
            max={24}
            min={0}
            disabled={!doubleOvertimeEnabled}
            value={policy.dailyDoubleOvertimeThreshold}
            onChange={(e) => policy.dailyDoubleOvertimeThreshold = e.target.value}
            errorMessage={errors.dailyDoubleOvertimeThreshold}
            connectedRight={
              <div className='InputGroup'>
                <span className='InputGroup-tag small'>{t('time_tracking.manage.policies.edit.hours')}</span>
              </div>
            }
          />
        </div>
        <div className='col col-12 md-col-4 md-pl2'>
          <CheckboxWithLabel
            checked={weeklyOvertimeEnabled}
            label={'time_tracking.manage.policies.edit.Weekly overtime'}
            onChange={(e) => uiState.toggleWeeklyOvertime(e.checked)}
          />
          <NumberInput
            max={168}
            min={0}
            disabled={!weeklyOvertimeEnabled}
            value={policy.weeklyOvertimeThreshold}
            onChange={(e) => policy.weeklyOvertimeThreshold = e.target.value}
            errorMessage={errors.weeklyOvertimeThreshold}
            connectedRight={
              <div className='InputGroup'>
                <span className='InputGroup-tag small'>{t('time_tracking.manage.policies.edit.hours')}</span>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
});

const OvertimeForm = observer(({uiState}) => {
  const {policy} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('time_tracking.manage.policies.edit.steps.Overtime Rules')}
        description={t('time_tracking.manage.policies.edit.Choose the overtime rules that will be applied to employees within this policy.')} />
      <RadioButtonGroup
        className={'clearfix table'}
        name='overtimePolicy'
        selectedValue={policy.overtimePolicy}
        onChange={(name, value) => uiState.updateOvertimePolicy(value)}
      >
        <div className={`OvertimePolicy ${policy.overtimePolicy === 'standard' ? 'active' : ''}`}>
          <RadioButton className='block mt2' value='standard'>
            <b><FormattedMessage id='time_tracking.manage.policies.edit.overtime_policies.Standard'/></b>
            <div className='mt1 waterloo' style={{marginLeft: '28.8px'}}>
              <FormattedMessage id='time_tracking.manage.policies.edit.overtime_policies.STANDARD_EXPLANATION'/>&nbsp;
              <a href='https://support.collage.co/en/articles/9612600-provincial-overtime-guidelines' target='_blank'>
                <FormattedMessage id='time_tracking.manage.policies.edit.overtime_policies.Learn more'/>
              </a>
            </div>
          </RadioButton>
          <StandardDetails uiState={uiState}/>
        </div>
        <div className={`OvertimePolicy ${policy.overtimePolicy === 'custom' ? 'active' : ''}`}>
          <RadioButton className='block mt2' value='custom'>
            <b><FormattedMessage id='time_tracking.manage.policies.edit.overtime_policies.Custom'/></b>
            <div className='mt1 waterloo' style={{marginLeft: '28.8px'}}><FormattedMessage id='time_tracking.manage.policies.edit.overtime_policies.CUSTOM_EXPLANATION'/></div>
          </RadioButton>
          <CustomDetails uiState={uiState}/>
        </div>
        <div className={`OvertimePolicy ${policy.overtimePolicy === 'exempt' ? 'active' : ''}`}>
          <RadioButton className='block mt2' value='exempt'>
            <b><FormattedMessage id='time_tracking.manage.policies.edit.overtime_policies.Exempt'/></b>
            <div className='mt1 waterloo' style={{marginLeft: '28.8px'}}><FormattedMessage id='time_tracking.manage.policies.edit.overtime_policies.EXEMPT_EXPLANATION'/></div>
          </RadioButton>
        </div>
      </RadioButtonGroup>
    </div>
  );
});

export default OvertimeForm;
