import React from 'react';
import {observer} from 'mobx-react';
import {loader, withDisplay, t} from 'shared/core';
import {Panel} from 'components';
import {Table} from 'components';
import departmentStore from 'stores/departments/DepartmentStore';
import DepartmentState from '../state/DepartmentState';
import EditDepartmentModal from './EditDepartmentModal';
import SubNav from 'company_settings/SubNav';

const COLUMNS = [
  {
    header: 'company_settings.departments.Name',
    attribute: 'name',
    width: 11
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'company_settings.departments.Remove Department',
    subHeader: 'company_settings.departments.Are you sure you want to remove this department?',
    body: 'company_settings.departments.Employees in this department would remain in it until you move them to another department.'
  },
  addModel: 'company_settings.departments.+ Add a Department',
  emptyState: 'company_settings.departments.No departments to display'
};

const DepartmentsContainer = observer(({uiState}) => {
  return (
    <div className='Container mb4'>
      <Panel className='lg-col-10'>
        <Panel.Header title={t('company_settings.departments.Departments')}/>
        <Table models={uiState.departments}
               columns={COLUMNS}
               localization={LOCALIZATION}
               onRemove={department => uiState.removeDepartment(department)}
               onEdit={department => uiState.openEditDepartmentModal(department)}
               onAdd={() => uiState.addDepartment()}/>
      </Panel>
      <EditDepartmentModal uiState={uiState} />
    </div>
  );
});

async function load() {
  const uiState = new DepartmentState(departmentStore);
  await uiState.load();
  return {uiState};
}

export default withDisplay(loader(DepartmentsContainer, load), () => <SubNav title={t('company_settings.subnav.Departments')}/>);
