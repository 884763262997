import {computed, observable} from 'mobx';
import {t} from 'shared/core';

class EnrolmentsContainerState {
  parentState;

  receiveProps({parentState}) {
    this.parentState = parentState;
  }

  @observable statusOptions = [
    {
      id: 'live',
      display: t('models.benefit_enrolment_summary.status.active')
    },
    {
      id: 'terminated',
      display: t('models.benefit_enrolment_summary.status.terminated')
    }
  ];

  @computed get carrierOptions() {
    const {company} = this.parentState;
    return company.companyInsuranceCarriers.map(c => {
      return {
        id: c.id,
        display: c.name
      };
    });
  }
}

export default EnrolmentsContainerState;
