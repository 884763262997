import {action} from 'mobx';
import {DomainStore} from 'shared/store';

class OfferTemplatesState {
  store = new DomainStore();

  @action async removeTemplate(template) {
    await this.store.destroy(template);
  }
}

export default OfferTemplatesState;
