import {DomainStore, DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {endpoints, types} from 'shared/core';
import qs from 'qs';
import {redirect, termsLinkHr, termsLinkBhq} from 'shared/tools';

class SignUpForm extends DomainObject {
  @observable firstName;
  @observable lastName;
  @observable email;
  @observable emailConfirmation;
  @observable password;
  @observable token;
}

class AdminUserSignupState {
  store = new DomainStore();
  history;
  match;

  @observable form = new SignUpForm();
  @observable errors = {};
  @observable invite;

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    const token = qs.parse(location.search.replace('?', ''))['token'];

    await this.store._compose([
      endpoints.ADMIN_INVITATION.with(token)
    ]);
    this.invite = this.store._getSingle(
      types.ADMIN_INVITATION
    );

    this.form.merge({token});
  }

  @action async signUp() {
    const {model, errors} = await this.store.post(
      endpoints.ADMIN_INVITATION.SIGNUP,
      types.USER,
      this.form
    );

    this.errors = errors;

    if (model) {
      return redirect('/');
    }
  }

  @computed get termsLink() {
    return this.invite.bhq ? termsLinkBhq() : termsLinkHr();
  }

  get authenticityToken() {
    return document.getElementsByName('csrf-token')[0].content;
  }
}

export default AdminUserSignupState;
