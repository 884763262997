import React from 'react';
import {observer} from 'mobx-react';
import {DragSource, DropTarget} from 'react-dnd';
import uuid from 'uuid';
import FormError from 'components/FormError';
import {FormattedMessage} from 'react-intl';

const source = {
  beginDrag(props) {
    return {
      index: props.model.order
    };
  }
};

const target = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.model.order;

    if (dragIndex === hoverIndex) return;

    props.uiState.moveModel(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  }
};

const collectSource = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
};

const collectTarget = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};

const DraggableItem = observer(({model, uiState, isDragging, isOver, connectDragSource, connectDropTarget, connectDragPreview, errorMessage, children}) => {
  return (
    <div className='table HoverAnimation-trigger'>
      <div className={`col-12 table-cell align-middle pr1`}>
        {connectDragPreview(connectDropTarget(
          <div className={`py1 my2 bg-white border rounded clickable ${isOver ? 'is-over' : ''}`}>
            <div className='clearfix table'>
              {connectDragSource(<div className='col-1 table-cell align-middle center px2'>
                <i className='material-icons submarine pt1'>{'drag_handle'}</i>
              </div>)}
              <div className={`col-${uiState.hasLinks ? '10' : '11'} table-cell align-middle`}>
                {children}
              </div>
              {(uiState.hasLinks) && <div className='table-cell col-1 align-middle center'>
                <div className='px1 dropdown inline-block px2'>
                  <i className='HoverAnimation-trigger--image material-icons submarine align-top' data-toggle='dropdown'>{'more_horiz'}</i>
                  <ul className='dropdown-menu right-0'>
                    {uiState.onEdit && <li><a href='#' className='py1 px2 regular jumbo' onClick={() => uiState.editModel(model)}>
                      <FormattedMessage id='Edit'/>
                    </a></li>}
                    {uiState.onRemove && <li><a href='#' className='py1 px2 regular jumbo' onClick={() => uiState.removeModel(model)}>
                      <FormattedMessage id='Delete'/>
                    </a></li>}
                  </ul>
                </div>
              </div>}
            </div>
            <div className='ml2'>
              <FormError message={errorMessage} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

const id = uuid.v4();

const dragSource = DragSource(id, source, collectSource)(DraggableItem);
export default DropTarget(id, target, collectTarget)(dragSource);
