import React from 'react';
import {auth} from 'shared/core';
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import GeneralSettingsContainer from './general/components/GeneralSettingsContainer';
import DepartmentsContainer from './departments/components/DepartmentsContainer';
import RolesPage from './permissions';
import CalendarsPage from './calendars/components/CalendarsPage';
import BillingSubscriptionPage from 'company_settings/billing/components/BillingSubscriptionPage';
import CompanyDocumentsPage from 'company_settings/documents/components/CompanyDocumentsPage';
import RemindersPage from 'company_settings/reminders/';
import {LeaveTypesPage} from 'company_settings/leave_types';
import {TasksPage} from 'company_settings/tasks';
import SettingsIndex from './SettingsIndex';
import {UsersPage} from './external_users';
import {JazzHRConfigurationContainer} from './applications';
import {GreenhouseConfigurationContainer} from './applications';
import {SlackConfigurationContainer} from './applications';
import {ZapierConfigurationContainer} from './applications';
import DocumentFoldersContainer from './document_folders/components/DocumentFoldersContainer';
import CompanyEmailTemplatePage from './company_email_templates/components/CompanyEmailTemplatePage';
import OfferTemplatePage from './offer_templates/components/OfferTemplatePage';
import {AuthenticationsContainer} from './authentications';
import {CustomFieldPage} from './custom_fields';
import {EmployeeGroupsPage} from './employee_groups';
import CompanyLinksContainer from './company_links';
import VisibilitySettingsContainer from './visibility';
import CompanyFilesContainer from './company_files';
import {MicrosoftConfigurationContainer} from 'containers/company_settings/applications/microsoft';
import {OktaConfigurationContainer} from 'containers/company_settings/applications/okta';
import {GoogleConfigurationContainer} from 'containers/company_settings/applications/google';

const CompanySettingsContainer = observer(({match}) => {
  return (
    <Switch>
      <Route path='/' exact component={SettingsIndex}/>
      {auth.moduleEnabled('account_management') && auth.hasAccess('::MANAGE_GENERAL_INFO') && <Route path='/general' component={GeneralSettingsContainer} />}
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_GENERAL_INFO') && <Route path='/departments' component={DepartmentsContainer} />}
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_DOCUMENTS') && <Route path='/documents' component={CompanyDocumentsPage} />}
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_FOLDERS') && <Route path='/document_folders' component={DocumentFoldersContainer} />}
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_CALENDARS') && <Route path='/calendars' component={CalendarsPage} />}
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_REMINDERS') && <Route path='/reminders' component={RemindersPage} />}

      {auth.user.admin && <Route path='/company_links' component={CompanyLinksContainer} />}
      {auth.user.admin && <Route path='/company_files' component={CompanyFilesContainer} />}
      {auth.user.admin && <Route path='/visibility' component={VisibilitySettingsContainer} />}
      {auth.user.admin && <Route path='/custom_fields' component={CustomFieldPage} />}
      {auth.moduleEnabled('directory') && auth.user.admin && <Route path='/employee_groups' component={EmployeeGroupsPage} />}
      {auth.hasAccess('::MANAGE_LEAVE_TYPES') && <Route path='/leave_types' component={LeaveTypesPage} />}
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_TASK_TEMPLATES') && <Route path='/tasks' component={TasksPage} />}
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_EMAIL_TEMPLATES') && <Route path='/company_email_templates' component={CompanyEmailTemplatePage} />}
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_OFFER_TEMPLATES') && <Route path='/offer_templates' component={OfferTemplatePage} />}

      {auth.moduleEnabled('directory') && auth.user.admin && <Route path='/permissions' component={RolesPage} />}
      {auth.user.admin && <Route path='/users' component={UsersPage} />}
      {auth.user.admin && <Route path='/authentications' component={AuthenticationsContainer} />}
      {auth.moduleEnabled('billing') && auth.hasAccess('::MANAGE_BILLING') && <Route path='/billing' component={BillingSubscriptionPage} />}

      {auth.user.admin && <Route path='/apps/okta' component={OktaConfigurationContainer} />}
      {auth.user.admin && <Route path='/apps/google' component={GoogleConfigurationContainer} />}
      {auth.user.admin && <Route path='/apps/microsoft' component={MicrosoftConfigurationContainer} />}
      {auth.moduleEnabled('directory') && auth.user.admin && <Route path='/apps/zapier' component={ZapierConfigurationContainer} />}
      {auth.moduleEnabled('directory') && auth.user.admin && <Route path='/apps/slack' component={SlackConfigurationContainer} />}
      {auth.moduleEnabled('directory') && auth.user.admin && <Route path='/apps/jazz_hr' component={JazzHRConfigurationContainer} />}
      {auth.moduleEnabled('directory') && auth.user.admin && <Route path='/apps/greenhouse' component={GreenhouseConfigurationContainer} />}

      {auth.moduleEnabled('billing') && auth.moduleEnabled('account_management') && auth.hasAccess('::MANAGE_BILLING') && <Redirect from={match.url} to='billing'/>}
    </Switch>
  );
});

const CompanySettingsPage = observer(() => {
  return (
    <Router basename='/company_settings'>
      <Route path='/' component={CompanySettingsContainer} />
    </Router>
  );
});

export default CompanySettingsPage;
