import React from 'react';
import {observer} from 'mobx-react';
import {withState, endpoints, types} from 'shared/core';
import {Display, Layout, InteractiveTable, Tag} from 'components';
import {FormattedMessage} from 'react-intl';
import {ViewPayScheduleContainerState} from '../state';
import {TimeTrackingPayPeriod} from 'stores/time_tracking';
import EditPayPeriodModal from './EditPayPeriodModal';

const PayPeriodName = observer(({model, uiState}) => {
  return (
    <div>
      <div className='table-cell align-middle'>
        {model.effectiveDateView}
      </div>
      <div className='table-cell align-middle pl1'>
        {model.isCurrentPeriod && <Tag className='ml1' colour='meadow'>
          <FormattedMessage id='time_tracking.manage.pay_schedules.view.Current Period'/>
        </Tag>}
      </div>
    </div>
  );
});

const COLUMNS = [
  {
    header: 'time_tracking.manage.pay_schedules.view.Pay period',
    width: 11,
    component: PayPeriodName,
    sortBy: 'startDate'
  }
];

const LOCALIZATION = {
  emptyState: 'time_tracking.manage.pay_schedules.view.No pay periods to display',
  interactive: {
    addModel: 'time_tracking.manage.pay_schedules.view.+ Add Next Period'
  },
  removeModal: {
    header: 'time_tracking.manage.pay_schedules.view.Remove Pay Period?',
    subHeader: 'time_tracking.manage.pay_schedules.view.Are you sure you want to remove this pay period?'
  }
};

const ViewPayScheduleContainer = observer(({uiState}) => {
  const {paySchedule, editPayPeriodModalOpen} = uiState;

  return (
    <div className='mb1'>
      <Display backPath='/manage/pay_schedules' title={paySchedule.name}/>
      <Layout>
        <InteractiveTable
          uiState={uiState}
          columns={COLUMNS}
          searchable={false}
          sortable
          localization={LOCALIZATION}
          onAdd={() => uiState.createNextPayPeriod()}
          onMount={agent => uiState.setInteractiveAgent(agent)}
          onRemove={payPeriod => uiState.removePayPeriod(payPeriod)}
          onEdit={payPeriod => uiState.openEditPayPeriodModal(payPeriod)}
          proxy={{
            type: 'async',
            modelType: types.TIME_TRACKING.PAY_PERIOD,
            model: TimeTrackingPayPeriod,
            endpoint: endpoints.TIME_TRACKING.PAY_PERIODS.FOR_PAY_SCHEDULE.with(paySchedule.id),
          }}>
        </InteractiveTable>
        <EditPayPeriodModal uiState={uiState} isOpen={editPayPeriodModalOpen}/>
      </Layout>
    </div>
  );
});

export default withState(ViewPayScheduleContainer, ViewPayScheduleContainerState);
