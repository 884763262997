import React from 'react';
import {observer} from 'mobx-react';
import Label from './Label';

const LabelWithHeader = observer(({header, children, ...props}) => {
  return (
    <div>
      <div className='medium'>{header}</div>
      <Label {...props}>{children}</Label>
    </div>
  );
});

export default LabelWithHeader;
