import React from 'react';
import {observer} from 'mobx-react';
import {ExternalCandidatesContainerState} from '../state';
import {Layout, Display, Panel} from 'components';
import LogoSpinner from 'components/spinners/logo/LogoSpinner';
import {withState, t} from 'shared/core';
import ExternalCandidatesDirectory from './ExternalCandidatesDirectory';

const SyncSpinner = observer(({uiState}) => {
  return (
    <Layout>
      <Panel>
        <div className='h1 center pt3'>{t('integrations.external_candidates.Connecting to JazzHR')}</div>
        <div className='h3 center py2 jumbo'>{t('integrations.external_candidates.Please wait while we connect to your JazzHR account.')}</div>
        <div className='relative py4'>
          <LogoSpinner height='120' width='54' animate={true}/>
        </div>
      </Panel>
    </Layout>
  );
});

const SyncedExternalCandidatesDirectory = observer(({uiState}) => {
  const {syncComplete} = uiState;

  if (syncComplete) {
    return <ExternalCandidatesDirectory uiState={uiState}/>;
  } else {
    return <SyncSpinner uiState={uiState}/>;
  }
});

const ExternalCandidatesContainer = observer(({uiState}) => {
  const {localization, key} = uiState.vendor;

  if (key === 'greenhouse') {
    return (
      <div>
        <Display title={t(localization.name)}/>
        <ExternalCandidatesDirectory uiState={uiState}/>
      </div>
    );
  }

  return (
    <div>
      <Display
        title={t(localization.name)}
      />
      <SyncedExternalCandidatesDirectory uiState={uiState}/>
    </div>
  );
});

export default withState(ExternalCandidatesContainer, ExternalCandidatesContainerState);
