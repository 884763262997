import {action, observable} from 'mobx';
import {endpoints, t, types} from 'shared/core';
import {TimeTrackingPaySchedule} from 'stores/time_tracking';
import {DomainStore} from 'shared/store';
import _ from 'lodash';

class TimesheetsState {
  store = new DomainStore();
  interactiveAgent;

  @observable paySchedules = [];
  @observable selectedPaySchedule = {};
  @observable selectedPayPeriod = {};
  @observable timesheet;
  @observable initialLoad = true;
  @observable DROPDOWN_FILTER_OPTIONS = [
    {id: 'assigned_to_me', display: t('time_tracking.timesheets.Assigned to me')},
    {id: 'all', display: t('time_tracking.timesheets.All')}
  ];

  @action selectTimesheet = (timesheet) => {
    this.timesheet = timesheet;
    this.initialLoad = false;
  }

  @action async load() {
    await this.store._compose([
      endpoints.TIME_TRACKING.PAY_SCHEDULES.ALL
    ]);

    this.paySchedules = this.store._getAll(types.TIME_TRACKING.PAY_SCHEDULE, TimeTrackingPaySchedule);
    if (_.isEmpty(this.paySchedules)) {
      window.location = '/time_tracking';
    } else {
      this.selectPaySchedule(this.paySchedules[0]);
    }
  }

  @action setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  @action selectPaySchedule(paySchedule) {
    this.selectedPaySchedule = paySchedule;
    this.selectPayPeriod(this.selectedPaySchedule.previousPayPeriod);
  }

  @action selectPayPeriod(payPeriod) {
    this.selectedPayPeriod = payPeriod;
  }
}

export default TimesheetsState;
