import React from 'react';
import {observer} from 'mobx-react';
import {Questions, Review, General} from './components';

const Steps = observer(({step, uiState}) => {
  switch (step) {
    case 'general':
      return <General uiState={uiState}/>;
    case 'questions':
      return <Questions uiState={uiState}/>;
    case 'review':
      return <Review uiState={uiState}/>;
    default:
      throw new Error(`Location ${step} is not supported.`);
  }
});

export default Steps;
