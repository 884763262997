import IntlMessageFormat from 'intl-messageformat';
import i18n from 'shared/i18n';

export default (id, values) => {
  const message = i18n.messages[id];
  if (!message && process.env.__DEV__) {
    console.error(`[i18n.t] Cannot format message: "${id}", using message id as fallback.`);
  }
  return new IntlMessageFormat(message || id || '', i18n.locale).format(values);
};
