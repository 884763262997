import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Modal, ModalButtons} from 'components/modals';
import FormLayout from 'components/forms/FormLayout';
import Input from 'components/Input';
import {CheckboxWithLabel} from 'components';

const EditRequireResumeModal = observer(({uiState}) => {
  const {position, editRequireResumeModalOpen} = uiState;

  return (
    <Modal
      size='md'
      isOpen={editRequireResumeModalOpen}
      onHide={() => uiState.closeRequireResumeModal()}
      title={'recruiting.edit.steps.Edit question'}
    >
      <FormLayout>
        <Input
          label={'recruiting.edit.steps.Question'}
          placeholder={'recruiting.edit.steps.Question'}
          value={t('recruiting.edit.steps.Resume')}
          onChange={() => {}}
          disabled
        />
        <CheckboxWithLabel
          checked={position.requireResume}
          label={'recruiting.edit.steps.Require candidates to answer this question'}
          onChange={e => uiState.updateRequireResume(e.checked)}
        />
      </FormLayout>
      <ModalButtons
        saveCaption={'recruiting.edit.steps.Update'}
        onSave={() => uiState.saveRequireResume()}
        onCancel={() => uiState.closeRequireResumeModal()}
      />
    </Modal>
  );
});

export default EditRequireResumeModal;
