import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class CompanySignature extends DomainObject {
  @observable id;
  @observable name;
  @observable signatureData;
}

export default CompanySignature;
