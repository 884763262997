import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import DependantForm from './DependantForm';

const DependantList = observer(({uiState}) => {
  const {benefitInfo, errors, hasDependents} = uiState;

  if (!hasDependents) return null;

  return (
    benefitInfo.dependents.map((dependent, index) => 
      <DependantForm uiState={uiState} dependent={dependent} index={index} key={index} errors={_.get(errors, `dependents[${index}]`, {})}/>)
  );
});

export default DependantList;
