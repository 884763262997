import {observable, computed, action} from 'mobx';
import _ from 'lodash';

class QuizFormState {
  @observable question;

  receiveProps({question}) {
    this.question = question;

    if (_.isEmpty(this.question.options)) {
      _.set(this.question, 'args.options', observable([]));
      this.addOption(true);
    } else {
      this.question.args.options = observable(this.question.args.options);
    }
  }

  @computed get options() {
    return _.get(this.question.args, 'options');
  }

  @action addOption(answer = false) {
    this.question.args.options.push({value: '', answer});
  }

  @action removeOption(option) {
    this.question.args.options.remove(option);
  }

  @action selectAsAnswer(option) {
    _.each(this.options, o => { o.answer = false; });

    const selectedOption = _.find(this.options, o => o.value === option.value);
    if (selectedOption) {
      selectedOption.answer = true;
    }
  }
}

export default QuizFormState;
