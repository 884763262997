import uuid from 'uuid';
import EmployeeSignatureImg from 'img/employee-signature@2x.png';
import TextFieldImg from 'img/text-field@2x.png';
import TextareaFieldImg from 'img/textarea-field@2x.png';
import CheckmarkImg from 'img/check_mark@2x.png';
import _ from 'lodash';

const EMPLOYEE_SIGNATURE_SCALE = 0.3333;
const COMPANY_SIGNATURE_SCALE = 0.3333;
const TEXT_FIELD_SCALE = 0.3333;
const TEXTAREA_FIELD_SCALE = 0.6666;

export async function createEmployeeSignature(descriptor, model = {}, fabric) {
  const state = model.state || {};

  return new Promise(resolve => {
    fabric.util.loadImage(EmployeeSignatureImg, img => {
      const rect = new fabric.Rect({
        id: uuid.v4(),
        top: !isNaN(state.top) ? state.top : 20,
        left: !isNaN(state.left) ? state.left : 20,
        width: state.width || 446,
        height: state.height || 166,
        scaleX: state.scaleX || EMPLOYEE_SIGNATURE_SCALE,
        scaleY: state.scaleY || EMPLOYEE_SIGNATURE_SCALE,
        lockScalingFlip: true,
        hasRotatingPoint: false,
        lockUniScaling: true
      });

      rect.setPatternFill({
        source: img,
        repeat: 'no-repeat'
      });

      rect.setControlsVisibility({
        tr: true,
        bl: true,
        ml: false,
        mt: false,
        mr: false,
        mb: false
      });

      resolve(rect);
    });
  });
}

export async function createCompanySignature(descriptor, model = {}, fabric) {
  const state = model.state || {};

  return new Promise(resolve => {
    fabric.Image.fromURL(descriptor.signatureData, img => {
      img.set({
        top: !isNaN(state.top) ? state.top : 20,
        left: !isNaN(state.left) ? state.left : 20,
        scaleX: state.scaleX || COMPANY_SIGNATURE_SCALE,
        scaleY: state.scaleY || COMPANY_SIGNATURE_SCALE,
        lockScalingFlip: true,
        hasRotatingPoint: false,
        lockUniScaling: true
      });

      img.setControlsVisibility({
        tr: true,
        bl: true,
        ml: false,
        mt: false,
        mr: false,
        mb: false
      });

      resolve(img);
    });
  });
}

export async function createMergeField(descriptor, model, fabric) {
  const state = model.state || {};

  const text = new fabric.Text(descriptor.name, {
    top: !isNaN(state.top) ? state.top : 20,
    left: !isNaN(state.left) ? state.left : 20,
    fontSize: !isNaN(state.fontSize) ? state.fontSize : 12,
    fontFamily: state.fontFamily || 'Helvetica',
    scaleX: state.scaleX || 1,
    scaleY: state.scaleY || 1,
    lockScalingFlip: true,
    hasRotatingPoint: false,
    lockUnitScaling: true
  });

  text.setControlsVisibility({
    tr: true,
    bl: true,
    ml: false,
    mt: false,
    mr: false,
    mb: false
  });

  return text;
}

export async function createInputField(descriptor, model, fabric) {
  const state = model.state || {};

  switch (descriptor.inputType) {
    case 'text':
    case 'textarea':
      return createTextOrTextAreaField(state, descriptor, fabric);
    case 'checkbox':
      return createCheckboxField(state, fabric);
    default:
      throw new Error(`Input type ${descriptor.inputType} is not supported.`);
  }
}

function createTextOrTextAreaField(state, descriptor, fabric) {
  let inputImg,
      height,
      width,
      scale,
      minScaleLimit,
      inputLabel;

    switch (descriptor.inputType) {
      case 'text':
        inputImg = TextFieldImg;
        height = 100;
        width = 304;
        scale = TEXT_FIELD_SCALE;
        minScaleLimit = 0.15;
        inputLabel = descriptor.label;
      break;
      case 'textarea':
        inputImg = TextareaFieldImg;
        height = 200;
        width = 608;
        scale = TEXTAREA_FIELD_SCALE;
        minScaleLimit = 0.4;
        inputLabel = descriptor.label;
        break;
      default:
    }

    return new Promise(resolve => {
      fabric.util.loadImage(inputImg, img => {
        const rect = new fabric.Rect({
          width: state.width || width,
          height: state.height || height,
          fontSize: !isNaN(state.fontSize) ? state.fontSize : 13
        });
  
        rect.setPatternFill({
          source: img,
          repeat: 'no-repeat',
        });
  
        rect.setControlsVisibility({
          tr: true,
          bl: true,
          ml: true,
          mt: true,
          mr: true,
          mb: true
        });
  
        const text = new fabric.Text(inputLabel, {
          fontSize: 13,
          top: 18,
          left: 18,
          scaleX: 1 / scale,
          scaleY: 1 / scale
        });
  
        const group = new fabric.Group([rect, text], {
          id: uuid.v4(),
          fontFamily: state.fontFamily || 'Helvetica',
          fontSize: !isNaN(state.fontSize) ? state.fontSize : 13,
          scaleX: state.scaleX || scale,
          scaleY: state.scaleY || scale,
          lockScalingFlip: true,
          lockUniScaling: false,
          hasRotatingPoint: false,
          minScaleLimit: minScaleLimit,
          top: !isNaN(state.top) ? state.top : 20,
          left: !isNaN(state.left) ? state.left : 20
        });
  
        resolve(group);
      });
    });
}

function createCheckboxField(state, fabric) {
  let inputImg,
      height,
      width,
      scale,
      minScaleLimit,
      backgroundColor,
      strokeWidth;

  inputImg = CheckmarkImg;
  height = 100;
  width = 100;
  scale = TEXT_FIELD_SCALE;
  minScaleLimit = 0.15;
  backgroundColor = 'white';
  strokeWidth = 3;

  return new Promise(resolve => {
    fabric.util.loadImage(inputImg, img => {
      const rect = new fabric.Rect({
        id: uuid.v4(),
        top: !isNaN(state.top) ? state.top : 20,
        left: !isNaN(state.left) ? state.left : 20,
        fontSize: !isNaN(state.fontSize) ? state.fontSize : 13,
        fontFamily: state.fontFamily || 'Helvetica',
        width: state.width || width,
        height: state.height || height,
        scaleX: state.scaleX || scale,
        scaleY: state.scaleY || scale,
        lockScalingFlip: true,
        hasRotatingPoint: false,
        lockUniScaling: false,
        minScaleLimit: minScaleLimit,
        stroke: '#C4CDD5',
        strokeWidth: strokeWidth, 
        backgroundColor: backgroundColor,
      });

      rect.setPatternFill({
        source: img,
        repeat: 'no-repeat',
      });

      rect.setControlsVisibility({
        tr: true,
        bl: true,
        ml: false,
        mt: false,
        mr: false,
        mb: false
      });

      resolve(rect);
    });
  });
}
