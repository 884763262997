import React from 'react';
import {observer} from 'mobx-react';
import {dateFormatter} from 'shared/tools';

const HolidayDate = observer(({model}) => {
  return (
    <div>{dateFormatter(model.date)}</div>
  );
});

export default HolidayDate;
