import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import InputError from './InputError';
import ErrorIcon from 'img/error-field@2x.png';

const InputHelper = observer(({helpMessage, errorMessage, translateHelpMessage}) => {
  return (
    <React.Fragment>
      {errorMessage && <div className='relative'>
        <img src={ErrorIcon} alt='error' height='16' className='inline-block' style={{marginRight: '4px'}}/>
        <InputError className='inline-block' message={errorMessage}/>
      </div>}
      {helpMessage && <div style={{color: '#637381', paddingTop: '4px'}}>
        {translateHelpMessage ? <FormattedMessage id={helpMessage}/> : helpMessage}
      </div>}
    </React.Fragment>
  );
});

export default InputHelper;
