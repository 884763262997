import React from 'react';
import {observer} from 'mobx-react';
import {Label} from 'components';

const MultipleChoiceView = observer(({answer}) => {
  const answers = answer.question.questionType === 'multi_select' ?
                    answer.asArray() :
                    [answer.content];

  return (
    <div>
      <div className='medium h3'>{answer.question.name}</div>
      <div>
        {!answers.length && <Label/>}
        {answers.map((_, index) => (
          <div className='mb1' key={index}>
            <Label model={answers} path={`[${index}]`}/>
          </div>
        ))}
      </div>
    </div>
  );
});

export default MultipleChoiceView;
