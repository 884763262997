import React from 'react';
import {ModalDangerButtons, AlertModal} from 'components/modals';
import {observer} from 'mobx-react';

const DeletePositionModal = ({uiState}) => {
  return (
    <AlertModal mode='danger' size='sm'
                header='recruiting.directory.Remove Position?'
                subHeader='recruiting.directory.Are you sure you want to remove this position?'
                body='recruiting.directory.All candidate data associated with this job position will be deleted.'
                onHide={() => uiState.cancelDeleteModal()} isOpen={!!uiState.deletePosition}>
      <ModalDangerButtons saveCaption='recruiting.directory.Remove' onCancel={() => uiState.cancelDeleteModal()}
                          onSave={() => uiState.confirmDeletePosition()}/>
    </AlertModal>
  );
};

export default observer(DeletePositionModal);
