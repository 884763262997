const PAY_RATE_UNIT_TYPES = [
  'year',
  'hour',
  'month',
  'week',
  'two_weeks',
  'half_month',
  'quarter',
  'piece'
];


export default PAY_RATE_UNIT_TYPES.map(t => ({
  id: t,
  name: `models.employment_record.pay_rate_unit.${t}`
}));
