import React from 'react';
import {observer} from 'mobx-react';
import IndexTableRow from './IndexTableRow';
import IndexRowNameWithIcon from './IndexRowNameWithIcon';
import Panel from 'components/panels/Panel';
import Table from 'components/tables/components/Table';
import _ from 'lodash';
import ChevronRight from 'img/chevron-right@2x.png';

function filterChildren(children, async) {
  const rows = [];
  const components = [];

  React.Children.forEach(children, c => {
    if (!c) return;

    if (c.type === IndexTableRow) {
      rows.push(c);
    } else {
      components.push(c);
    }

  });

  return {rows, components};
}

const Chevron = () => {
  return (
    <img src={ChevronRight} height='16' width='16' role='presentation'/>
  );
};

const COLUMNS = [
  {
    width: 11,
    component: IndexRowNameWithIcon
  },
  {
    width: 1,
    className: 'right-align',
    component: Chevron
  }
];

const IndexTable = observer(({title, async, children, actions}) => {
  const {rows, components} = filterChildren(children, async);

  return (
    <Panel>
      <Panel.Header title={title}>{actions}</Panel.Header>
      {rows.length ?
        <Table
          columns={COLUMNS}
          models={React.Children.map(children, c => _.get(c, 'props'))}
          showLinks={false}
          showHeaders={false}
          indexBy='showRoute'
          clickable
        />
      : null}
      {components.length ? components : null}
    </Panel>
  );
});

/*
use IndexTable to build index pages containing links to various pages within a module.

props:
has no props, but expects an array of IndexTable.Section as children.
=============

IndexTable.Section:
represents a section of the IndexTable and renders a <Panel>.

props:
- title

IndexTable.Section expects an array of IndexTable.Row as children.
============

IndexTable.Row:
represents a row in the IndexTable.

props:
- title
- description
- icon
- path
- route

Note: either path or route must be specified.

*/

export default IndexTable;
