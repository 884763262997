import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {MultipleChoiceFormState} from '../state';
import {FormattedMessage} from 'react-intl';
import ActionLink from 'components/links/ActionLink';
import LabelHelper from 'components/LabelHelper';
import Input from 'components/Input';
import {t} from 'shared/core';

const OptionItem = observer(({uiState, option, index}) => {
  const {options} = uiState;

  return (
    <div className='table pb1'>
      <div className='table-cell align-middle pr2 col-9'>
        <Input
          placeholder={t('components.question_form.OPTION_NUMBER', {index})}
          translatePlaceholder={false}
          className='small'
          value={option.value}
          onChange={e => option.value = e.target.value}
        />
      </div>
      <div className='table-cell align-middle col-3'>
        {options.length > 1 && <ActionLink onClick={() => uiState.removeOption(option)}>
          <i className='material-icons h2 align-top submarine'>{'close'}</i>
        </ActionLink>}
      </div>
    </div>
  );
});

const MultipleChoiceForm = observer(({uiState}) => {
  const {options} = uiState;

  return (
    <div>
      <LabelHelper label={t('recruiting.edit.steps.Possible answers')}/>
      {options.map((option, index) =>
        <OptionItem key={index} uiState={uiState} option={option} index={index + 1}/>)}
      <ActionLink onClick={() => uiState.addOption()}>
        <FormattedMessage id='components.question_form.+ Add option'/>
      </ActionLink>
    </div>
  );
});

export default withState(MultipleChoiceForm, MultipleChoiceFormState);
