import {observable, computed, action, observe} from 'mobx';
import {endpoints, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import TrainingProgramViewModel from './TrainingProgramViewModel';
import {EmployeeTrainingStatus} from 'stores/training';
import _ from 'lodash';

class TrainingListState {
  store = new DomainStore();
  employeeId;
  trainingPrograms;
  onChange;

  @observable employeeTrainingStatuses = [];
  @observable viewModels = [];

  receiveProps({employeeId, trainingPrograms, onChange}) {
    this.employeeId = employeeId;
    this.trainingPrograms = trainingPrograms;
    this.onChange = onChange;
  }

  @action async load() {
    await this.store._compose([
      endpoints.TRAINING.EMPLOYEE_TRAINING_STATUSES.with(this.employeeId)
    ]);

    const employeeTrainingStatuses = this.store._getAll(
      types.TRAINING.EMPLOYEE_TRAINING_STATUS,
      EmployeeTrainingStatus
    );

    this.viewModels = _.map(
      this.trainingPrograms,
      p => new TrainingProgramViewModel({
        data: p,
        selected: _.some(employeeTrainingStatuses, s => s.program.id === p.id)
      })
    );

    observe(this, 'selectedTrainingPrograms', () => this.emitOnChange(), true);
  }

  emitOnChange() {
    if (this.onChange) {
      this.onChange(this.selectedTrainingPrograms);
    }
  }

  @computed get selectedTrainingPrograms() {
    return this.viewModels.filter(p => p.selected).map(v => v.data);
  }
}

export default TrainingListState;
