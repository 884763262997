import React from 'react';
import {noop} from 'shared/tools';
import InputHelper from './InputHelper';
import LabelHelper from './LabelHelper';
import {auth, t} from 'shared/core';
import _ from 'lodash';
import {scrollsIntoViewIfErrors} from 'shared/tools';
import moment from 'moment';
import {HelpLink} from './links';
/*global $ */

// this uses the Bootstrap datepicker plugin included globally on the page.

function getDateFormat(format) {
  if (format === 'month') {
    return 'MM yyyy';
  }

  switch (auth.locale) {
    case 'fr':
      return 'd MM yyyy';
    case 'en':
    default:
      return 'MM d, yyyy';
  }
}

const LOCALIZATION = {
  // taken from https://github.com/uxsolutions/bootstrap-datepicker/blob/master/js/locales/bootstrap-datepicker.fr.js
  fr: {
    days: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
		daysShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
		daysMin: ["d", "l", "ma", "me", "j", "v", "s"],
		months: ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
		monthsShort: ["janv.", "févr.", "mars", "avril", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."],
		today: "Aujourd'hui",
		monthsTitle: "Mois",
		clear: "Effacer",
		weekStart: 1
  }
};

// eslint-disable-next-line
for (const lang in LOCALIZATION) {
  _.set($, `fn.datepicker.dates.${lang}`, LOCALIZATION[lang]);
}

function convertToNativeDate(value) {
  if (!value) return null;

  if (_.isDate(value)) {
    return new Date(value.getFullYear(), value.getMonth(), value.getDate());
  }

  if (moment.isMoment(value) && value.isValid()) {
    return value.toDate();
  }

  return null;
}

class DatePicker extends React.Component {
  static defaultProps = {
    onChange: noop,
    value: new Date(),
    options: {}, // Accepts bootstrap datepicker options
    dateFormat: 'day'
  };

  valueSetByTyping = false;

  updateValue = value => {
    const date = convertToNativeDate(value);
    $(this.dateInput).datepicker('update', date);
  };

  setFirstAllowedDate = startDate => {
    $(this.dateInput).datepicker('setStartDate', startDate);
  }

  setLastAllowedDate = endDate => {
    $(this.dateInput).datepicker('setEndDate', endDate);
  }

  componentDidMount() {
    this.onChangeDebounced = _.debounce(value => this.props.onChange(value), 500);
    $(this.dateInput).datepicker({
      format: getDateFormat(this.props.dateFormat),
      autoclose: true,
      language: auth.locale,
      ...this.props.options
    })
    .on('changeDate', e => this.props.onChange(e.date))
    .on('keyup', e => {
      this.valueSetByTyping = true;
      if (e.target.value === '') {
        this.onChangeDebounced(null);
      } else {
        const date = convertToNativeDate(moment.locale() === 'fr' ? moment(e.target.value, 'D MMMM YYYY') : moment(e.target.value));
        const valueAsDate = convertToNativeDate(this.props.value);
        if (date && (!valueAsDate || date.getTime() !== valueAsDate.setHours(0,0,0,0))) {
          this.onChangeDebounced(date);
        }
      }
    }).on('blur', (e) => {
      const date = convertToNativeDate(moment.locale() === 'fr' ? moment(e.target.value, 'D MMMM YYYY') : moment(e.target.value));
      const valueAsDate = convertToNativeDate(this.props.value);
      if (valueAsDate && (!date || date.getTime() !== valueAsDate.setHours(0,0,0,0))) {
          this.updateValue(this.props.value);
      }
    });

    if (this.props.value) {
      this.updateValue(this.props.value);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {value, options} = nextProps;

    if (this.props.options.endDate !== options.endDate) {
      this.setLastAllowedDate(options.endDate);
    }

    if (this.props.options.startDate !== options.startDate) {
      this.setFirstAllowedDate(options.startDate);
    }

    if (this.valueSetByTyping) {
      this.valueSetByTyping = false;
    } else if (
      (moment.isMoment(this.props.value) && moment.isMoment(value) && !this.props.value.isSame(value)) ||
      (!(moment.isMoment(this.props.value) && moment.isMoment(value)) && this.props.value !== value)
    ) {
      this.updateValue(value);
    }
  }

  render() {
    const {errorMessage, helpMessage, withIcon, label, translateLabel, className, helpLink, action, disabled, optional, tooltip} = this.props;
    const showIcon = errorMessage || withIcon;

    return (
      <div className={errorMessage ? 'Datepicker--hasErrors ' : ''}>
        <LabelHelper label={translateLabel && label ? t(label) : label} action={action} optional={optional} tooltip={tooltip}>
          {helpLink && <HelpLink title={helpLink.title} explanation={helpLink.explanation}/>}
        </LabelHelper>
        <div className={`${showIcon ? 'InputIcon' : ''}`}>
          <input type='text' ref={input => this.dateInput = input}
            onChange={noop} disabled={disabled}
            className={`${className} ${errorMessage ? 'Error-field' : ''}`}/>
          {withIcon && <i className='material-icons submarine'>{'event'}</i>}
        </div>
        <InputHelper errorMessage={errorMessage} helpMessage={helpMessage} />
      </div>
    );
  }
}

DatePicker.defaultProps = {
  withIcon: false,
  placeholder: 'components.dates.DD/MM/YYYY',
  translateLabel: true,
  optional: false,
  options: {},
};

export default scrollsIntoViewIfErrors(DatePicker, '.Datepicker--hasErrors');
