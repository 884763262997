import {observable, action, computed} from 'mobx';
import _ from 'lodash';

class DragAndDropState {
  onAdd;
  onEdit;
  onRemove;
  onMove;

  @observable models = [];

  constructor(models, onAdd, onEdit, onRemove, onMove) {
    this.models = models;
    this.onAdd = onAdd;
    this.onEdit = onEdit;
    this.onRemove = onRemove;
    this.onMove = onMove;
  }

  @action moveModel(fromIndex, toIndex) {
    const dragField = this.sortedModels[fromIndex];
    const hoverField = this.sortedModels[toIndex];

    hoverField.merge({order: fromIndex});
    dragField.merge({order: toIndex});

    if (this.onMove) {
      this.onMove(fromIndex, toIndex);
    }
  }

  @action addModel() {
    const order = this.models.length;
    this.onAdd(order);
  }

  @action editModel(model) {
    this.onEdit(model);
  }

  @action removeModel(model) {
    const result = this.onRemove(model);

    if (result !== false) {
      const laterModels = _.filter(this.models, m => m.order > model.order);
      for (const m of laterModels) {
        m.order -= 1;
      }
    }
  }

  @computed get sortedModels() {
    return _.sortBy(
      this.models,
      'order'
    );
  }

  @computed get hasLinks() {
    return this.onRemove || this.onEdit;
  }
}

export default DragAndDropState;
