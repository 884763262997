import React from 'react';
import {observer} from 'mobx-react';
import {auth, t} from 'shared/core';
import {Panel, FormLayout, Select2} from 'components';

const ReassignManagerForm = observer(({uiState}) => {
  if (!auth.moduleEnabled('directory')) return null;
  const {editRecord: record, employees} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.role.Manager Reassignment')}/>
      <FormLayout>
        <Select2 searchable allowClear
          label='employees.profile.role.Who should subordinates report to?'
          value={record.reassignReportsToEmployeeId}
          onChange={e => record.merge({reassignReportsToEmployeeId: e.target.value})}>
          {employees.map(employee => {
            if (employee.id === record.employee.id) return null;
            return <option key={employee.id} value={employee.id}>{employee.name}</option>;
          })}
        </Select2>
      </FormLayout>
    </Panel>
  );
});

export default ReassignManagerForm;
