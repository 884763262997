import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import {Input} from 'components';
import {noop} from 'shared/tools';

const RecoveryCodeModal = observer(({uiState}) => {
  const {recoveryCode} = uiState;

  return (
    <Modal
      size='md'
      title='user_settings.authentications.Save your recovery code'
      isOpen={uiState.recoveryCodeModalOpen}
      onHide={() => uiState.closeRecoveryCodeModal()}
    >
      <div className='mb2'>
        <FormattedMessage id='user_settings.authentications.NEW_RECOVERY_CODE_GENERATED'/>
      </div>
      <div>
        <Input
          label='user_settings.authentications.Recovery Code'
          disabled
          onChange={() => noop()}
          value={uiState.recoveryCode.code}
          helpMessage='user_settings.authentications.Download or write down your recovery code and keep it safe.'
        />
      </div>
      <ModalButtons
        saveCaption='Download'
        onSave={() => recoveryCode.downloadCode()}
        onCancel={() => uiState.closeRecoveryCodeModal()}
      />
    </Modal>
  );
});

export default RecoveryCodeModal;
