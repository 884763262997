import React from 'react';
import PropTypes from 'prop-types';
/*global $ */

class Checkbox extends React.Component {
  onToggle = (e) => {
    this.props.onChange({
      value: this.props.value,
      checked: e.target.checked
    });
  }

  componentDidMount() {
    $(this.checkbox).iCheck().on('ifToggled', this.onToggle);
  }

  componentDidUpdate() {
    $(this.checkbox).iCheck('update');
  }

  componentWillUnmount() {
    $(this.checkbox).iCheck('destroy').off('ifToggled', this.onToggle);
  }

  render() {
    const {className, disabled} = this.props;

    return (
      <span className={`Checkbox ${className} ${disabled ? 'nonclickable' : ''}`}>
        <input type="checkbox"
               disabled={disabled}
               checked={this.props.checked}
               onChange={this.props.onChange}
               ref={(input) => { this.checkbox = input; }} />
      </span>
    );
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ])
};

Checkbox.defaultProps = {
  className: ''
};

export default Checkbox;
