import React from 'react';
import {observer} from 'mobx-react';
import TableIcon from 'img/table-icon@2x.png';
import ReportRowContent from './ReportRowContent';
import {ActionLink} from 'components';

const ReportRowWithActionLink = observer(({name, onClick, visible}) => {
  if (!visible) return null;

  return (
    <div className='border-bottom py2 relative'>
      <ActionLink onClick={onClick}>
        <ReportRowContent name={name} icon={TableIcon}/>
      </ActionLink>
    </div>
  );
});

export default ReportRowWithActionLink;
