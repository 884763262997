import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {withState} from 'shared/core';
import {Avatar, Panel} from 'components';
import {fullName} from 'shared/tools';
import {Line} from 'rc-progress';
import _ from 'lodash';
import RevieweeListEmptyState from './RevieweesListEmptyState';
import AwaitingTag from '../../AwaitingTag';
import RevieweesContainerState from './state/RevieweesContainerState';
import dataTablesLocalization from 'messages/dataTables';
/*global $*/

const COLOURS_BY_PERCENTAGE = [
  {
    minPercent: 0,
    colour: '#00B7B3',
  },
  {
    minPercent: 70,
    colour: '#FABA4F',
  }
];

const DaysSinceLastCheckIn = ({days, totalDays}) => {
  const percentage = _.min([days * 100 / totalDays, 100]);
  const colour = _.findLast(COLOURS_BY_PERCENTAGE, c => percentage >= c.minPercent).colour;
  return (
    <div>
      <div className='table-cell align-middle'>
        <FormattedMessage id='DAYS_COUNT' values={{count: days}} />
      </div>
      <div style={{width: '100px'}} className='table-cell align-middle relative pl1'>
        <Line className='absolute' percent={percentage} strokeColor={colour} strokeWidth={3} trailWidth={3}/>
      </div>
    </div>
  );
};

const PairRow = ({reviewee, daysSinceLastCheckin, frequency, totalDays, status, id}) => {
  return (
    <tr className='js-link' data-link={`/performance/reviewees/${reviewee.id}`}>
      <td>
        <div className="table-cell align-middle pr2">
          <Avatar size='sm' employee={reviewee}/>
        </div>
        <div className="table-cell align-middle">
          {fullName(reviewee)}
        </div>
        <div className="table-cell align-middle pl1">
          <td><AwaitingTag status={status}/></td>
        </div>
      </td>
      <td><FormattedMessage id={frequency}/></td>
      {daysSinceLastCheckin != null && <td><DaysSinceLastCheckIn days={daysSinceLastCheckin} totalDays={totalDays}/></td>}
      {daysSinceLastCheckin == null && <td><FormattedMessage id='performance.NO_CHECK_INS' /></td>}
    </tr>
  );
};

@observer class RevieweesContainerWithState extends React.Component {
  componentDidMount() {
    $(this.refs.table).dataTable({
      bLengthChange: false,
      language: dataTablesLocalization.EMPLOYEE_SEARCH
    });
  }

  componentWillUnmount() {
    $(this.refs.table).DataTable().destroy();
  }

  render() {
    const {uiState} = this.props;
    const {pairs} = uiState;

    if (_.isEmpty(pairs)) return <RevieweeListEmptyState/>;

    return (
      <div className='Container'>
        <Panel>
          <div className='Table'>
            <table ref='table' className='full-width'>
              <thead>
                <tr>
                  <th><FormattedMessage id="performance.Employee"/></th>
                  <th><FormattedMessage id="performance.Suggested Frequency"/></th>
                  <th><FormattedMessage id="performance.DAYS_SINCE_LAST_CHECK_IN"/></th>
                </tr>
              </thead>
              <tbody>
                {pairs.map((pair) => <PairRow key={pair.id} {...pair} />)}
              </tbody>
            </table>
          </div>
        </Panel>
      </div>
    );
  }
}

export default withState(RevieweesContainerWithState, RevieweesContainerState);
