import {DomainObject} from 'shared/store';
import {action, observable, computed} from 'mobx';
import {calendarDate} from 'shared/tools';
import SurveyQuestion from './SurveyQuestion';
import SurveyResponse from './SurveyResponse';
import SurveyEmployeeStatus from './SurveyEmployeeStatus';
import _ from 'lodash';

class Survey extends DomainObject {
  @observable name;
  @observable launchedAt;
  @observable dueDate;
  @observable questions;
  @observable responses;
  @observable state;
  @observable anonymous;
  @observable currentPeriodDate;
  @observable employeeStatuses;

  @observable completeStatusCount;
  @observable incompleteStatusCount;
  @observable totalStatusCount;

  @action merge(other) {
    super.merge(other, {
      questions: [SurveyQuestion],
      responses: [SurveyResponse],
      employeeStatuses: [SurveyEmployeeStatus],
      _dates: ['launchedAt', 'dueDate']
    });
  }

  @computed get sortedQuestions() {
    return _.sortBy(this.questions, 'order');
  }

  @computed get completedPercentage() {
    return _.ceil((this.completeStatusCount / this.totalStatusCount) * 100);
  }

  @computed get launchedAtView() {
    if (this.currentPeriodDate) {
      return calendarDate(this.currentPeriodDate);
    } else {
      return calendarDate(this.launchedAt);
    }
  }

  @computed get isLaunched() {
    return !!this.launchedAt;
  }

  @computed get isClosed() {
    return this.state === 'closed';
  }

  @computed get isRecurring() {
    return !(this.currentPeriodDate == null);
  }
}

export default Survey;
