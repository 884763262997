import {PAGE_MARGIN, MIN_PDF_WIDTH, MIN_PDF_HEIGHT} from './constants';
import Row from './row';
import createDrawNodeFn, {drawAvatars} from './node';
import axios from 'axios';
export default class Tree {
  constructor(rootEmployeeID, employees) {
    this.avatars = {};
    this.tree = new Row(employees[rootEmployeeID], employees);
  }
  calculateSize() {
    this.tree.calculateSize();
  }
  get size() {
    const s = this.tree.size;
    if (s === null) {
      throw new Error('Tree size is null');
    }
    return [
      s[0] + PAGE_MARGIN * 2,
      s[1] + PAGE_MARGIN * 2
    ];
  }
  get pdfSize() {
    return [
      Math.max(this.size[0], MIN_PDF_WIDTH),
      Math.max(this.size[1], MIN_PDF_HEIGHT)
    ];
  }
  layoutItems() {
    // left and top most coordinates of entire tree
    const x = Math.max((this.pdfSize[0] - this.size[0]) / 2,
    // if tree is as big as pdf, add page_margin as padding
    PAGE_MARGIN);
    const y = PAGE_MARGIN;
    this.tree.setCoords([
      x, y
    ], 0);
  }
  _queueAvatar(id, avatarData) {
    const url = avatarData.url;
    this.avatars[id] = this._fetchAvatar(url).then(img => {
      avatarData.thumb = img;
      return avatarData;
    });
  }
  async _fetchAvatar(url) {
    if (!url) {
      return null;
    }
    try {
      const {data} = await axios.request(
        {
          url: url,
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
          },
          responseType: 'arraybuffer'
        }
      );
      return data;
    } catch (err) {
      console.error('Error fetching avatar image', err);
      return null;
    }
  }
  async draw(doc) {
    // create a draw node function that takes node data, draws it, and fetches
    // avatar which are then batched and all drawn at the end
    const drawNode = createDrawNodeFn(doc, this._queueAvatar.bind(this));
    // render the entire tree (without avatars)
    this.tree.render(doc, drawNode, 0);
    // fetch all avatars
    try {
      const avatars = await hashPromise(this.avatars);
      // finish up by drawing all avatars
      drawAvatars(doc, avatars);
    } catch (err) {
      console.log('Failed to fetch and draw avatars', err);
    }
    function hashPromise(obj) {
      return Promise.all(Object.values(obj)).then(promises => {
        // eslint-disable-next-line
        return Object.keys(obj).reduce((o, key, i) => (o[key] = promises[i], o), {});
      });
    }
  }
}
