import React from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import _ from 'lodash';
import Layout from 'components/layouts/Layout';
import DocumentTitle from 'components/DocumentTitle';

const BackLink = observer(({backTitle, backRoute, backPath}) => {
  if (backRoute) {
    return (
      <Link to={backRoute} className='waterloo h5'>
        {backTitle}
      </Link>
    );
  }

  return (
    <a href={backPath} className='waterloo h5'>
      {backTitle}
    </a>
  );
});

const Breadcrumb = observer(({backTitle, backPath, backRoute, name, ...rest}) => {
  const Name = _.isFunction(name) || _.isObject(name) ? name : () => <span>{name}</span>;

  return (
    <div className='clearfix mb3 py2 border-bottom'>
      <DocumentTitle title={<Name {...rest}/>}/>
      <Layout>
        <div className='Breadcrumb'>
          <div>
            <BackLink backTitle={backTitle} backPath={backPath} backRoute={backRoute}/>
          </div>
          <div>
            <div className='tuatara h5'>
              <span className='mr2'>
                <Name {...rest}/>
              </span>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
});

Breadcrumb.defaultProps = {
  backPath: '/'
};

export default Breadcrumb;
