import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const DeleteCommentModal = observer(({uiState}) => {
  return (
    <AlertModal
      size='sm'
      mode='danger'
      header='hr_dashboard.feed.announcements.Remove Comment?'
      subHeader='hr_dashboard.feed.announcements.Are you sure you want to remove this comment?'
      isOpen={uiState.deleteCommentModalOpen}
      onHide={() => uiState.closeCommentDeleteModal()}>
      <ModalDangerButtons
        onSave={() => uiState.confirmCommentDelete()}
        onCancel={() => uiState.closeCommentDeleteModal()}
      />
    </AlertModal>
  );
});

export default DeleteCommentModal;
