import React from 'react';

function withDisplay(Component, SubNav) {
  return (props) => {
    return (
      <div>
        <SubNav/>
        <Component {...props}/>
      </div>
    );
  };
}

export default withDisplay;
