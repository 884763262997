import {action, observable, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {auth, t, types, endpoints, api} from 'shared/core';
import {Announcement} from 'stores/announcements';
import _ from 'lodash';
import {openFileStack, successAlert} from 'shared/tools';

class FeedState {
  store = new DomainStore();
  Emoji;

  @observable editAnnouncementModalOpen = false;
  @observable editingAnnouncement;
  @observable deletingAnnouncement;
  @observable errors = {};
  @observable selectedEmployees = []
  @observable deleteAnnouncementModalOpen = false;
  @observable announcements = [];
  @observable nextLink = endpoints.ANNOUNCEMENTS;
  @observable loading = false;

  receiveProps({celebrations, Emoji}) {
    this.celebrations = celebrations;
    this.Emoji = Emoji;
  }

  loadMore = async () => {
    const response = await api.get(this.nextLink);
    this.store._repository.sync(response.data);
    this.nextLink = _.get(response, 'data.links.next');
    this.announcements = this.store._getAll(types.ANNOUNCEMENT, Announcement);
  }

  @action openEditAnnouncementModal(announcement) {
    this.editAnnouncementModalOpen = true;
    this.editingAnnouncement = new Announcement(announcement);
  }

  @action openNewAnnouncementModal() {
    const announcement = new Announcement();
    this.openEditAnnouncementModal(announcement);
  }

  @action closeEditAnnouncementModal() {
    this.editAnnouncementModalOpen = false;
    this.selectedEmployees = [];
    this.errors = {};
  }

  @action updateDescription(description) {
    this.editingAnnouncement.description = description.html;
    this.editingAnnouncement.lexicalState = description.state;
  }

  @action updateSelectedEmployees(employees) {
    this.selectedEmployees.replace(employees);
  }

  @action async saveAnnouncement(){
    if (this.editingAnnouncement.isNew) {
      await this.publishAnnouncement();
    } else {
      await this.updateAnnouncement();
    }
  }

  @action async updateAnnouncement() {
    const {model, errors} = await this.store.patch(this.editingAnnouncement);
    this.errors = errors;

    if (model) {
      _.find(this.announcements, {id: this.editingAnnouncement.id}).update(model);
      successAlert(t('hr_dashboard.feed.announcements.Announcement successfully saved'));
      this.closeEditAnnouncementModal();
    }
  }

  @action async postAnnouncement() {
    return await this.store.post(
      endpoints.ANNOUNCEMENTS,
      types.ANNOUNCEMENT,
      this.editingAnnouncement
    );
  }

  @action async openFileStack() {
    openFileStack({
      accept: ['image/*'],
      storeTo: {
        location: 's3',
        path: '/avatars/'
      }
    }, (file) => {
      this.editingAnnouncement.image = file;
    });
  }

  @action async clearImage() {
    this.editingAnnouncement.image = null;
  }

  @action async publishAnnouncement() {
    const {model, errors} = await this.store.post(
      endpoints.ANNOUNCEMENTS,
      types.ANNOUNCEMENT,
      {
        ...this.editingAnnouncement,
        employees: this.selectedEmployees
      }
    );
    this.errors = errors;

    if (model) {
      this.announcements.push(new Announcement(model));
      successAlert(t('hr_dashboard.feed.announcements.Announcement successfully published'));
      this.closeEditAnnouncementModal();
    }
  }

  @action openDeleteAnnouncementModal(announcement) {
    this.deletingAnnouncement = announcement;
    this.deleteAnnouncementModalOpen = true;
  }

  @action closeDeleteAnnouncementModal() {
    this.deleteAnnouncementModalOpen = false;
  }

  @action async confirmAnnouncementDelete() {
    await this.store.destroy(this.deletingAnnouncement);

    _.remove(this.announcements, (announcement) => {
      return announcement.id === this.deletingAnnouncement.id;
    });
    successAlert(t('hr_dashboard.feed.announcements.Deleted announcement.'));
    this.closeDeleteAnnouncementModal();
  }

  @computed get feedItems() {
    return this.celebrations.concat(this.announcements.slice());
  }

  @computed get showCreateAnnouncementButton() {
    return auth.hasAccess('::MANAGE_ANNOUNCEMENTS') || auth.company.employeeAnnouncementsEnabled;
  }
}

export default FeedState;
