import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import ChargifyPaymentForm from './ChargifyPaymentForm';
import {Spinner} from 'components';

const PaymentInformation = observer(({uiState}) => {
  const {redirecting} = uiState;

  return (
    <div className='mxn2'>
      <div className='px2'>
        <div className='bg-white p3 box-shadow-2 rounded'>
          <div className='px3'>
            <div className='h1'>
              <FormattedMessage id="company_settings.billing.Payment Details" />
            </div>
            <div className='h4 jumbo mt2'>
              <FormattedMessage id='company_settings.billing.Enter your credit card information below. Your monthly billing cycle will start based on your agreed upon start date.'/>
            </div>
            <div className='mt3'>
              {!redirecting && <ChargifyPaymentForm onSuccess={() => uiState.onPaymentFormSubmitted()}/>}
              {redirecting && <Spinner />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PaymentInformation;
