import {DomainObject} from 'shared/store';
import {computed, observable} from 'mobx';
import {t} from 'shared/core';
import {calendarDate} from '../../shared/tools';
import {formatCurrency} from '../../shared/core';

class BillingSubscription extends DomainObject {
  @observable billingPortalLink;
  @observable subscriptionStartDate;
  @observable openBalance;
  @observable endOfCurrentBillingPeriod;
  @observable status;
  @observable quantity;
  @observable currency;
  @observable firstName;
  @observable lastName;
  @observable email;
  @observable ccEmails = [];

  @computed get subscriptionItems() {
    return [
      {
        title: t('company_settings.billing.Status'),
        value: this.status
      },
      {
        title: t('company_settings.billing.Subscription Started'),
        value: calendarDate(this.subscriptionStartDate)
      },
      {
        title: t('company_settings.billing.Open Balance'),
        value: formatCurrency(this.currency, this.openBalance)
      },
      {
        title: t('company_settings.billing.End of Current Billing Period'),
        value: calendarDate(this.endOfCurrentBillingPeriod)
      },
      {
        title: t('company_settings.billing.Number of Employees'),
        value: this.quantity
      },
    ];
  }

  @computed get contactItems() {
    return [
      {
        title: t('company_settings.billing.First Name'),
        value: this.firstName
      },
      {
        title: t('company_settings.billing.Last Name'),
        value: this.lastName
      },
      {
        title: t('company_settings.billing.Email'),
        value: this.email
      },
      {
        title: t('company_settings.billing.CC Emails'),
        value: this.ccEmails.join(', ')
      }
    ];
  }
}

export default BillingSubscription;
