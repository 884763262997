import React from 'react';
import {observer} from 'mobx-react';
import {ReorderModal} from 'components/modals';

const ReorderFilesModal = observer(({uiState}) => {
  return (
    <ReorderModal
      isOpen={uiState.reorderFilesModalOpen}
      onHide={() => uiState.closeReorderFilesModal()}
      title={'company_settings.company_files.Reorder Company Files'}
      models={uiState.reorderingFiles}
      onSave={() => uiState.saveFileOrders()}
      onCancel={() => uiState.closeReorderFilesModal()}
    />
  );
});

export default ReorderFilesModal;
