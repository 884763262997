import React from 'react';
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {withState} from 'shared/core';
import {ReviewsPage} from './reviews';
import {TemplatesPage} from './templates';
import {RevieweesPage} from './reviewees';
import {PerformanceTrends} from './performance_trends';
import PerformanceReviewsPageState from '../state/PerformanceReviewsPageState';

const PerformanceReviewsPage = observer(({uiState}) => {
  const {scope} = uiState;

  return (
    <Router basename='/reviews'>
      <Switch>
        {scope.reviews && <Route path='/reviews' render={() => <ReviewsPage scope={scope}/>}/>}
        {scope.templates && <Route path='/question_sets' render={({history}) => <TemplatesPage history={history} scope={scope}/>}/>}
        {scope.performanceTrends && <Route path='/performance_trends' render={() => <PerformanceTrends scope={scope}/>}/>}
        {scope.reviewees && <Route path='/reviewees' render={() => <RevieweesPage scope={scope}/>}/>}
        {scope.reviews && <Redirect exact from='/' to='/reviews'/>}
      </Switch>
    </Router>
  );
});

export default withState(PerformanceReviewsPage, PerformanceReviewsPageState);
