import _ from 'lodash';
import QuestionSummaryViewModel from './QuestionSummaryViewModel';
import {computed} from 'mobx';
import {multipleChoiceTrendsOptions} from './highcharts_options';
import {dateFormatter} from 'shared/tools';

class MultipleChoiceViewModel extends QuestionSummaryViewModel {
  @computed get trendsOptions() {
    if (!this.uiState.survey.isRecurring || !this.uiState.hasTrends) return;

    const config = multipleChoiceTrendsOptions(
      !this.uiState.survey.anonymous && {onClick: (e) => this.uiState.onPointClicked(e, this.question.id)}
    );

    return {
      ...config,
      xAxis: {
        ...config.xAxis,
        categories: this.trendsCategories
      },
      series: this.trendsSeries
    };
  }

  @computed get trendsSeries() {
    const data = {};
    _.each(this.trend, ({date, value}) => {
      _.each(value, ({count, label}) => {
        if (!data.hasOwnProperty(label)) {
          data[label] = [];
        }
        data[label].push({
          custom: { periodDate: date },
          y: count
        });
      });
    });

    // We want to make sure that the current options show up first in the graph
    const currentOptions = _.map(this.question.options, 'value');
    const orderedAnswers = _.union(currentOptions, Object.keys(data));

    return _.map(orderedAnswers, value => {
      return {
        name: value,
        data: data[value]
      };
    });
  }

  @computed get trendsCategories() {
    return _.map(this.trend, (period) => dateFormatter(period.date));
  }
}

export default MultipleChoiceViewModel;
