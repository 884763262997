import {observable, action, computed} from 'mobx';
import {DomainStore, DomainObject} from 'shared/store';
import {endpoints, types} from 'shared/core';
import {redirect} from 'shared/tools';
import _ from 'lodash';
import qs from 'qs';
import {termsLinkHr, termsLinkBhq} from 'shared/tools';

class SignUpForm extends DomainObject {
  @observable email;
  @observable emailConfirmation;
  @observable password;
  @observable invite;
  @observable token;
}

class ValidateSignupForm extends DomainObject {
  @observable lastName;
  @observable birthDate;
  @observable token;

  @action merge(other) {
    super.merge(other, {
      _dates: ['birthDate']
    });
  }
}

class EmployeeSignupState {
  store = new DomainStore();
  history;
  match;

  @observable form = new SignUpForm();
  @observable errors = {};
  @observable invitationValidated = false;
  @observable validateSignupForm = new ValidateSignupForm();
  @observable validateSignupFormErrors = {};

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    const token = qs.parse(location.search.replace('?', ''))['token'];

    await this.store._compose([
      endpoints.USER_INVITATIONS.FIND_INVITATION.with(token)
    ]);
    this.invite = this.store._getSingle(
      types.USER_INVITATION
    );
    this.form.merge({token});
  }

  @action async signUp() {
    const {model, errors} = await this.store.post(
      endpoints.USER_INVITATIONS.SIGNUP,
      types.USER,
      this.form
    );
    this.errors = errors;

    if (model) {
      return redirect('/');
    }

    return null;
  }

  @action async validateInvite() {
    this.validateSignupForm.merge({token: this.form.token});

    const {model, errors} = await this.store.post(
      endpoints.USER_INVITATIONS.VALIDATE_INVITATION,
      types.INVITATION_VALIDATION,
      this.validateSignupForm
    );
    this.validateSignupFormErrors = errors;

    if (model) {
      this.invitationValidated = true;
    }

    return null;
  }

  @computed get termsLink() {
    return this.invite.bhq ? termsLinkBhq() : termsLinkHr();
  }

  @computed get birthDateRequired() {
    return this.invite.birthDateRequired;
  }

  get authenticityToken() {
    return document.getElementsByName('csrf-token')[0].content;
  }
}

export default EmployeeSignupState;
