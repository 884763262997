import React from 'react';
import {observer} from 'mobx-react';
import {Button} from 'components';
import {FormattedMessage} from 'react-intl';

const ActionButtons = observer(({model, uiState}) => {
  return (
    <Button onClick={() => uiState.selectReviewers(model)} trait='default'>
      <FormattedMessage id='performance_reviews.manage.Select reviewers'/>
    </Button>
  );
});

export default ActionButtons;
