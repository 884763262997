import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import InputHelper from './InputHelper';
import Checkbox from './Checkbox';

class CheckboxWithLabel extends React.Component {
  render() {
    const {checked, className, label, onChange, disabled, translatable, value, errorMessage} = this.props;

    return (
      <label className={`clickable ${className}`}>
        <div className='table-cell align-middle'>
          <Checkbox disabled={disabled} checked={checked} onChange={onChange} value={value}/>
        </div>
        <div className='table-cell align-middle pl1'>
          {translatable ? <FormattedMessage id={label}/> : label}
        </div>
        <InputHelper errorMessage={errorMessage} />
      </label>
    );
  }
}

CheckboxWithLabel.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ])
};

CheckboxWithLabel.defaultProps = {
  className: '',
  translatable: true
};

export default CheckboxWithLabel;
