import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Input, DatePicker, TextArea, Select2} from 'components';
import PAY_RATE_UNIT_TYPES from 'stores/employees/payRateUnitTypes';
import cc from 'currency-codes';

const SalaryInput = observer(({uiState, field}) => {
  const {errors} = uiState;

  return (
    <React.Fragment>
        <Input
          label={field.label}
          value={field.value.payRate}
          translateLabel={false}
          onChange={e => uiState.fieldValueChanged(field, {...field.value, payRate: e.target.value})}
          errorMessage={errors.pay_rate}
          connectedRight={
            <Select2
              value={field.value.payRateUnit}
              onChange={e => uiState.fieldValueChanged(field, {...field.value, payRateUnit: e.target.value})}
            >
              {PAY_RATE_UNIT_TYPES.map(p => <option value={p.id} key={p.id}>{t(`offers.editor.fields.per_unit.${p.id}`)}</option>)}
            </Select2>
          }
        />
        <Select2
          label='offers.editor.fields.Pay Currency'
          value={field.value.payRateCurrency}
          onChange={e => uiState.fieldValueChanged(field, {...field.value, payRateCurrency: e.target.value})}
          errorMessage={errors.pay_rate_currency}
          searchable
        >
          {cc.codes().map(c => <option key={c} value={c}>{c}</option>)}
        </Select2>
    </React.Fragment>
  );
});

const OfferFieldFactory = observer(({uiState, field}) => {
  const {errors} = uiState;

  switch (field.fieldType) {
    case 'salary':
      return <SalaryInput uiState={uiState} field={field}/>;
    case 'date':
      return (
        <DatePicker
          key={field.key}
          label={field.label}
          translateLabel={false}
          value={field.value}
          errorMessage={errors[field.key]}
          onChange={(date) => uiState.fieldValueChanged(field, date)}
          tooltip={field.key === 'expires_at' && {
            paragraphs: [
              t('offers.editor.EXPIRES_AT_TOOLTIP')
            ]
          }}
        />
      );
    case 'text_area':
      return (
        <TextArea
          key={field.key}
          label={field.label}
          translateLabel={false}
          value={field.value}
          errorMessage={errors[field.key]}
          onChange={(e) => uiState.fieldValueChanged(field, e.target.value)}
        />
      );
    default:
      return (
        <Input
          key={field.key}
          label={field.label}
          translateLabel={false}
          value={field.value}
          errorMessage={errors[field.key]}
          onChange={(e) => uiState.fieldValueChanged(field, e.target.value)}
        />
      );
  }
});

export default OfferFieldFactory;
