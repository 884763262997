import React, {useEffect} from 'react';
import HiringFunnelSubNav from './HiringFunnelSubNav';
import RecruitingState from 'recruiting/state/RecruitingState';
import {InteractiveContext, FancyFilter} from 'components';
import FancyFilterPills from 'components/tables/interactive/components/fancy_filter/FancyFilterPills';
import {loader, t, types, endpoints} from 'shared/core';
import StageButtonList from './StageButtonList';
import StageContainer from './StageContainer';
import {observer} from 'mobx-react';
import BackToIndex from 'components/navigation/BackToIndex';
import {useRouteMatch} from 'react-router-dom';
import _ from 'lodash';

const CandidateSearchBox = observer(({filter, filterUpdated, filterDeleted}) => {
  return (
    <div className='FilterRow flex align-items-start mt2 py2 px2 border-bottom bg-white box-shadow-1'>
      <div className='flex flex-wrap flex-grow row-gap-2'>
        <div className='dataTables_filter'>
          <input
            type='search'
            className='full-width'
            defaultValue={filter ? filter.get('search') : ''}
            onChange={e => filterUpdated({search: e.target.value})}
            placeholder={t('recruiting.hiring_funnel.Search for a candidate')}
          />
        </div>
        <div className='ml1 flex align-items-start'>
          <FancyFilterPills filter={filter} filterUpdated={filterUpdated} filterDeleted={filterDeleted}/>
        </div>
      </div>
      <div className='FilterGroup-searchable flex align-items-center flex-wrap'>
        <FancyFilter
          filter={filter} filterUpdated={filterUpdated} filterDeleted={filterDeleted}
          filters={['candidate_positions', 'candidate_status', 'candidate_tags', 'candidate_ratings', 'include_candidate_resume_in_search']}
        />
      </div>
    </div>
  );
});

const HiringFunnelContainer = observer(({uiState, ...rest}) => {
  const match = useRouteMatch();

  useEffect(() => {
    const candidateId = _.get(uiState, 'detailsState.candidateViewModel.candidate.id');
    if (candidateId && match.params.candidateId !== candidateId) {
      uiState.selectCandidateById(match.params.candidateId);
    }
  }, [match.params.candidateId]);

  return (
    <div className='Container'>
      <StageButtonList uiState={uiState} {...rest}/>
      <CandidateSearchBox {...rest}/>
      <StageContainer uiState={uiState} ready={true} {...rest}/>
    </div>
  );
});

const HiringFunnelPage = observer(({uiState}) => {
  return (
    <div>
      <BackToIndex title={t('recruiting.Recruiting')} hasTitle/>
      <HiringFunnelSubNav uiState={uiState}/>
      <InteractiveContext
        onMount={agent => uiState.setInteractiveAgent(agent)}
        onRefresh={args => uiState.updateCandidateList(args)}
        onFilterUpdated={() => uiState.onFilterUpdated()}
        initialFilter={uiState.initialFilter}
        updateRequested={uiState.listUpdatePending}
        saveStateToUrl
        proxy={{
          type: 'async',
          modelType: types.RECRUITING.CANDIDATE_SUMMARY,
          modelCreator: c => uiState.createCandidateViewModel(c),
          endpoint: endpoints.RECRUITING.CANDIDATES.FILTERABLE.with(uiState.position.id)
        }}
      >
        <HiringFunnelContainer uiState={uiState}/>
      </InteractiveContext>
    </div>
  );
});

async function load({match}) {
  const uiState = RecruitingState.instance.hiringFunnelState;
  await uiState.load(match.params.id, match.params.candidateId);
  return {uiState};
}

export default loader(HiringFunnelPage, load);
