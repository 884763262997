import React from 'react';
import {observer} from 'mobx-react';
import {withState, withDisplay, t} from 'shared/core';
import {JazzHRConfigurationContainerState} from '../state';
import {Layout, Panel, FormLayout, Input, Button} from 'components';
import SubNav from 'company_settings/SubNav';
import {FormattedMessage} from 'react-intl';

const Instructions = () => {
  return (
    <Panel>
      <Panel.Header title={t('company_settings.applications.jazz_hr.How does it work?')}/>
      <div className='mb2'>
        <FormattedMessage id='company_settings.applications.jazz_hr.explanation_1'/>&nbsp;
        <FormattedMessage id='company_settings.applications.jazz_hr.explanation_2'/>&nbsp;
        <FormattedMessage id='company_settings.applications.jazz_hr.explanation_3'/>&nbsp;
        <a href='http://support.collage.co/integrations/jazzhr-integration-setup' target='_blank'>
          <FormattedMessage id='company_settings.applications.jazz_hr.Learn more'/>
        </a>
      </div>
      <Panel.Header title={t('company_settings.applications.jazz_hr.How to find your JazzHR API key')}/>
      <ul className='ml2'>
        <li><FormattedMessage id='company_settings.applications.jazz_hr.Log in to your JazzHR account.'/></li>
        <li>
          <FormattedMessage id='company_settings.applications.jazz_hr.CLICK_SETTINGS' values={{
            settings: <span className='medium'><FormattedMessage id='company_settings.applications.jazz_hr.Settings'/></span>}}
          />
        </li>
        <li>
          <FormattedMessage id='company_settings.applications.jazz_hr.GO_TO_INTEGRATIONS' values={{
            integrations: <span className='medium'><FormattedMessage id='company_settings.applications.jazz_hr.Integrations'/></span>}}
          />
        </li>
        <li>
          <FormattedMessage id='company_settings.applications.jazz_hr.GRAB_THE_CODE' values={{
            api_key: <span className='medium'><FormattedMessage id='company_settings.applications.jazz_hr.api_key'/></span>}}
          />
        </li>
      </ul>
    </Panel>
  );
};

const JazzHRConfigurationContainer = observer(({uiState}) => {
  const {company, errors} = uiState;

  return (
    <Layout>
      <Layout.Section secondary>
        <Panel.Stack loose>
          <Panel>
            <Panel.Header title={t('company_settings.applications.jazz_hr.Connect your JazzHR account')}/>
            <FormLayout>
              <Input
                value={company.jazzHRApiKey}
                label='company_settings.applications.jazz_hr.JazzHR API key'
                errorMessage={errors.jazzHRApiKey}
                onChange={(e) => company.merge({jazzHRApiKey: e.target.value})}
              />
            </FormLayout>
            <Button onClick={() => uiState.saveCompany()}>
              <FormattedMessage id='company_settings.applications.Save'/>
            </Button>
          </Panel>
          <Instructions/>
        </Panel.Stack>
      </Layout.Section>
    </Layout>
  );
});

export default withDisplay(
  withState(JazzHRConfigurationContainer, JazzHRConfigurationContainerState),
  () => <SubNav title={t('company_settings.subnav.JazzHR')}/>
);
