import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Panel, DownloadLink} from 'components';
import {t} from 'shared/core';
import _ from 'lodash';

const BeneficiaryAssignment = observer(({uiState}) => {
  const {benefitInfo} = uiState;
  return (
    <Panel.Stack loose>
      <Panel>
        <Panel.Header title={t('employees.profile.benefits.Beneficiary Assignment')}/>
        <div className='jumbo'>
          <FormattedMessage id='employees.profile.benefits.In order for your life insurance policy proceeds to go to your beneficiary tax-free, you must:'/>
          <ul className='ml3 my2'>
            <li><FormattedMessage id='employees.profile.benefits.Print out your Beneficiary Assignment form'/></li>
            <li><FormattedMessage id='employees.profile.benefits.Fill it out with ink'/></li>
            <li><FormattedMessage id='employees.profile.benefits.Give it to your employer’s plan administrator for their records'/></li>
          </ul>
          <div className='flex flex-col'>
            {benefitInfo.beneficiaryForms.map(form => (
                <div className={'mt1'}>
                  <DownloadLink
                    href={form.url}
                    title={t('employees.profile.benefits.link_download_beneficiary_named', {name: form.name})}
                  />
                </div>
              ))
            }
          </div>
        </div>
      </Panel>
    </Panel.Stack>
  );
});

export default BeneficiaryAssignment;
