import {action, computed, observable} from 'mobx';
import _ from 'lodash';

class ScopeFilterState {
  filterUpdated;
  @observable options = [];
  @observable filter;

  receiveProps({options, filterUpdated, filter}) {
    this.filterUpdated = filterUpdated;
    this.options = options;
    this.filter = filter;

    this.updateSelectedOptions();
  }

  @action updateSelectedOptions() {
    for (const option of this.options) {
      option.selected = false;
    }

    const scopeCsv = this.filter.get('scopes');
    if (!scopeCsv) return;

    _.each(scopeCsv.split(','), scope => {
      const option = _.find(this.options, {scope});
      if (!option) return;

      option.selected = true;
    });
  }

  @action removeScope(option) {
    option.selected = false;

    this.filterUpdated({
      scopes: _.chain(this.options).filter('selected').map('scope').value().join()
    });
  }

  @computed get noOptionsSelected() {
    return _.isEmpty(_.filter(this.options, 'selected'));
  }
}

export default ScopeFilterState;
