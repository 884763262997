import React from 'react';
import {Accordion} from 'components';
import {observer} from 'mobx-react';
import Metric from './Metric';
import UpdateMetric from './UpdateMetric';

const MetricRow = observer(({model, uiState}) => {
  return (
    <div>
      <Metric
        model={model}
        uiState={uiState}
      />
      <Accordion active={model.isEditing}>
        <UpdateMetric
          model={model}
          onSave={() => uiState.saveMetric(model)}
          saveEnabled={model.editingValue !== model.data.currentValue}
        />
      </Accordion>
    </div>
  );
});

export default MetricRow;
