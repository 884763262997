import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalButtons} from 'components/modals';
import {Input} from 'components';

const AddEmailTemplateModal = observer(({uiState}) => {
  const {emailTemplate, errors} = uiState;

  return (
    <Modal isOpen={uiState.addEmailTemplateModalOpen} onHide={() => uiState.closeAddEmailTemplateModal()} size='sm'>
      <form>
        <div className='h2 medium mb2'>
          <FormattedMessage id='recruiting.email_templates.Create new email template'/>
        </div>
        <Input value={emailTemplate.name} 
               onChange={(e) => emailTemplate.merge({name: e.target.value})}
               placeholder='recruiting.email_templates.Email template name' 
               className='full-width'
               errorMessage={errors.name} />
        <ModalButtons onCancel={() => uiState.closeAddEmailTemplateModal()} 
                      onSave={() => uiState.createEmailTemplate()}/>
      </form>
    </Modal>
  );
});

export default AddEmailTemplateModal;
