import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Label} from 'components';
import {auth} from 'shared/core';
import {calendarDate} from 'shared/tools';

const SinInfo = observer(({uiState}) => {
  const {employee} = uiState;

  if (!auth.company.sinEnabled) return null;

  return (
    <React.Fragment>
      <div className='clearfix mxn1 py1'>
        <div className='col col-6 px1'>
          <FormattedMessage id='employees.profile.banking.Social Insurance Number (SIN)' />
        </div>
        <div className='col col-6 px1'>
          <Label
            model={employee}
            path='sin'
            render={value => uiState.formatMaskedValue(value, uiState.bankingInformationHidden)}
          />
        </div>
      </div>
      {employee.sinExpiryDate && <div className='clearfix mxn1 py1'>
        <div className='col col-6 px1'>
          <FormattedMessage id='employees.profile.banking.Date of Expiry (SIN)'/>
        </div>
        <div className='col col-6 px1'>
          <Label
            model={employee}
            path='sinExpiryDate'
            render={value => uiState.formatMaskedValue(calendarDate(value), uiState.bankingInformationHidden)}
          />
        </div>
      </div>}
    </React.Fragment>
  );
});

export default SinInfo;
