import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, FormLayout, Input} from 'components';

const Details = observer(({uiState}) => {
  const {policy, errors} = uiState;

  return (
    <div>
      <Panel.Header 
        title={t('time_off.policy.edit.steps.Policy Details')} 
        description={t('time_off.policy.edit.Start by setting up the basic details of your policy.')}
      />
      <FormLayout>
        <Input
          value={policy.name}
          onChange={e => policy.merge({name: e.target.value})}
          errorMessage={errors.name}
        />
      </FormLayout>
    </div>
  );
});

export default Details;
