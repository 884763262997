import {t} from 'shared/core';
import exporting from './exporting';

function barGraphOptions() {
  return {
    title: null,
    legend: {
      enabled: false
    },
    chart: {
      type: 'column',
      height: 350
    },
    yAxis: {
      min: 0.5,
      max: 5,
      endOnTick: false,
      startOnTick: false,
      tickInterval: 1,
      title: {
        text: t('performance_reviews.view.cycle_results.Values')
      }
    },
    credits: {
      enabled: false
    },
    exporting
  };
}

export default barGraphOptions;
