import React from 'react';
import {AlertModal, ModalButtons} from 'components/modals';

const UnarchiveCompanyDocumentModal = ({isOpen, onCancel, onUnarchive}) => {
  return (
    <AlertModal
      isOpen={isOpen}
      onHide={onCancel} size='sm'
      header='company_settings.documents.Unarchive Company Document'
      subHeader='company_settings.documents.Are you sure you want to unarchive this company document?'
      body='company_settings.documents.Unarchiving this document will reassign it to all employees who did not complete it before it was archived.'
    >
      <ModalButtons
        onCancel={onCancel}
        saveCaption='company_settings.documents.Unarchive'
        onSave={() => onUnarchive()} />
    </AlertModal>
  );
};

export default UnarchiveCompanyDocumentModal;
