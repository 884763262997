/* eslint-disable */
import { observable, action, computed } from 'mobx';
import {
	NODE_WIDTH,
	NODE_HEIGHT,
	RIGHT_MARGIN,
	TOP_MARGIN
} from '../constants';
import uuid from 'uuid';

/*
	row constructor options:
	- depth
	- parentLeft
	- parentTop
*/

class Row {
	parentID = null;
	depth = 0;
	// employees needs to be observable to be able to set properties
	// on employee objects so children pick it up (ie: setting this.parent.left)
	@observable employees = null;
	@observable nodes = []; // TODO - might not need to make nodes an observable
	@observable nodesWithBounds = [];
	@observable childRows = [];

	@observable parentLeft = 0;
	parentTop = 0;
	activeHighlightId = null;

	@computed get parent(){
		return this.employees[this.parentID] || null;
	}

	constructor(parentID, employees, nodes, calcTree, opts = {}){
		this.parentID = parentID;
		this.employees = employees;
		this.calcTree = calcTree;
		this.nodes = nodes;

		this.depth = opts.depth
		this.parentTop = opts.parentTop
		this.chart = opts.chart
	}

	@action toggleChildRow = id => {
		const row = this.childRows.find(row => row.parentID === id)
		if (row){
			// row exists, remove it
			this.childRows.remove(row)
		} else {
			// create row
			const row = new Row(id, this.employees,
				this.employees[id].children.map(nodeId => this.employees[nodeId]),
				this.calcTree, {
				parentTop: this.parentTop + NODE_HEIGHT + TOP_MARGIN,
				depth: this.depth + 1,
				chart: this.chart
			})
			this.childRows.push(row);
		}
		this.calcTree()
	}

	@computed get childRowsMap(){
		return this.childRows.reduce((map, childRow) => {
			map[childRow.parentID] = childRow
			return map
		}, {});
	}
	@action calcNodesWithBounds(){
		const childRowsMap = this.childRowsMap

		const { parentLeft, parentTop } = this;

		// left starting point depends on parent left and width of current row
		let left = this.depth === 0 ? 0 : parentLeft - this.width / 2 + NODE_WIDTH / 2
		this.left = left

		this.nodesWithBounds = this.nodes.map(node => {
			node.top = parentTop
			if (childRowsMap[node.id]){
				const childRow = childRowsMap[node.id];

				// get width of children and center node based on children width
				const w = childRow.width;
				const l = this.depth === 0 ? left : left + w / 2 - NODE_WIDTH / 2

				// set left for current node and it's child row
				node.left = l
				childRow.parentLeft = l;

				left += w + RIGHT_MARGIN

				// position child row elements
				childRow.calcNodesWithBounds()

			} else {
				node.left = left
				left += NODE_WIDTH + RIGHT_MARGIN
			}
			return node
		})
	}

	// width of current row only
	@computed get rowWidth(){
		const nodes = this.nodesWithBounds
		return Math.abs(nodes[0].left - nodes[nodes.length - 1].left) + NODE_WIDTH
	}

	// width of current row and all children rows
	// useful for layouting tree structure to avoid overlapping
	@computed get width(){
		const childRowsMap = this.childRowsMap

		let width = 0;
		this.nodes.map(node => {
			if (childRowsMap[node.id]){
				const childRow = childRowsMap[node.id];
				const w = childRow.width
				width += w + RIGHT_MARGIN
			} else {
				width += NODE_WIDTH + RIGHT_MARGIN
			}
		})
		width -= RIGHT_MARGIN

		return width
	}

	// expands all row nodes with children up to a max depth
	expandAllChildren(maxDepth){
		if (this.depth === maxDepth){
			return;
		}
		this.nodes.forEach(node => {
			if (node.children && node.children.length){
				this.toggleChildRow(node.id)
			}
			this.childRows.forEach(c => c.expandAllChildren(maxDepth))
		})
	}
}


export default class Chart {
	rootRow = null;
	activeHighlightId = null;

	constructor(rootNode, employees){
		this.rootRow = new Row(rootNode, employees, [employees[rootNode]], this._calcTreeFromRoot, {
			depth: 0,
			parentLeft: 0,
			parentTop: TOP_MARGIN,
			chart: this
		});

		this._calcTreeFromRoot()
	}

	expandTree(maxDepth = 100){
		this.rootRow.expandAllChildren(maxDepth)
	}

	_calcTreeFromRoot = () => {
		// remove any highlights before calculating tree
		this.removeHighlight()

		// lay out and render tree from top down
		this.rootRow.calcNodesWithBounds()
	}

	// registers highlight remove function
	_removeHighlightFn = null
	registerHighlight = fn => {
		this.activeHighlightId = uuid.v4();
		this.removeHighlight()
		this._removeHighlightFn = fn;
	}
	removeHighlight(){
		if (this._removeHighlightFn){
			this._removeHighlightFn();
		}
	}

	getTreeDimensions(){
		let rowLen = 0;
		let minX = 0;
		let maxX = 0;

		function getDimensions(row){
			// update depth
			rowLen = Math.max(rowLen, row.depth);

			// calc max x and y based on first and last node
			const nodes = row.nodesWithBounds
			if (nodes[0]){
				minX = Math.min(minX, nodes[0].left)
			}
			const lastNode = nodes[nodes.length - 1]
			if (lastNode){
				maxX = Math.max(maxX, lastNode.left + NODE_WIDTH)
			}

			// check for any child rows
			row.childRows.map(getDimensions)
		}

		getDimensions(this.rootRow)

		const h = rowLen * NODE_HEIGHT + TOP_MARGIN
		const w = maxX - minX;
		return [w, h]
	}
}
