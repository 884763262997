import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';
import {t} from 'shared/core';

const RemoveDocumentModal = observer(({uiState}) => {
  const {currentDocumentAction, employee} = uiState;
  if (!currentDocumentAction) {
    return null;
  }

  return (
    <AlertModal
      size='sm'
      mode='danger'
      header={t('employees.profile.Remove Onboarding Document')}
      subHeader={
        t(`employees.profile.REMOVE_DOCUMENT_MODAL_SUBHEADER`, {documentName: currentDocumentAction.companyDocument.name, employeeName: employee.name})
      }
      translate={false}
      isOpen={uiState.removeDocumentModalOpen}
      onHide={() => uiState.resetModals()}>
      <ModalDangerButtons
        onSave={() => uiState.confirmRemoveDocument()}
        onCancel={() => uiState.resetModals()}
      />
    </AlertModal>
  );
});

export default RemoveDocumentModal;
