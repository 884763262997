import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const DeleteProgramModal = observer(({uiState}) => {
  return (
    <AlertModal
      size='sm'
      header='training.manage.Delete Training Program'
      subHeader='training.manage.Are you sure you want to delete this training program?'
      isOpen={uiState.deleteProgramModalOpen}
      onHide={() => uiState.closeModals()}
    >
      <ModalDangerButtons
        onSave={() => uiState.destroyProgram()}
        onCancel={() => uiState.closeModals()}
      />
    </AlertModal>
  );
});

export default DeleteProgramModal;
