import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Table} from 'components';
import {t} from 'shared/core';
import OutstandingEnrolmentItem from './OutstandingEnrolmentItem';
import _ from 'lodash';

const COLUMNS = [
  {
    component: OutstandingEnrolmentItem,
    width: 12
  }
];

const OutstandingEnrolments = observer(({uiState}) => {
  if (_.isEmpty(uiState.sortedEnrolments)) return null;

  return (
    <Panel>
      <Panel.Header title={t('pa_dashboard.Outstanding Enrolments')}/>
      <Table
        columns={COLUMNS}
        models={uiState.sortedEnrolments}
        showHeaders={false}
        showLinks={false}
        uiState={uiState}
      />
    </Panel>
  );
});

export default OutstandingEnrolments;
