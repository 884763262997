import {action, computed, observable} from 'mobx';
import _ from 'lodash';
import AnnotationInputViewModel from 'components/pdf/fillable/state/AnnotationInputViewModel';
import SignatureViewModel from 'components/pdf/fillable/state/SignatureViewModel';
import {t} from 'shared/core';
import {successAlert, noop} from 'shared/tools';
import Signature from 'stores/signatures/Signature';

const FILLABLE_ANNOTATION_TYPES = ['input', 'employee_signature'];

class FillablePdfState {
  store;

  @observable documentViewModels = [];
  @observable employeeSignature;
  @observable currentDocument;
  @observable currentAnnotations = [];
  @observable errors = {};
  @observable pageNumber = 1;
  @observable callbacks = {
    onAllDocumentsCompleted: noop
  };

  constructor(store, documentViewModels, callbacks) {
    this.store = store;
    this.callbacks = callbacks;
    this.documentViewModels = documentViewModels;
    this.setInitialDocumentAsCurrent();
    this.employeeSignature = new Signature();
  }

  @action setInitialDocumentAsCurrent() {
    this.setCurrentDocumentViewModel(_.head(this.sortedDocuments));
  }

  @action setCurrentDocumentViewModel(viewModel) {
    this.currentDocument = viewModel;
    const annotations = this.currentDocument.companyDocument.annotations;
    this.currentAnnotations = annotations.map(a => this.createAnnotationViewModel(a));
    this.pageNumber = 1;
    this.errors = {};
  }

  @action async markCurrentDocumentComplete() {
    if (this.currentDocument.checked) {
      this.selectNextDocumentViewModel();
      return null;
    }

    const {companyDocument} = this.currentDocument;

    const {model, errors} = await this.store.markDocumentComplete(
                              companyDocument,
                              this.employeeSignature.signatureData,
                              this.currentAnnotations
                            );
    this.errors = errors;

    if (model) {
      this.currentDocument.checked = true;
      this.selectNextDocumentViewModel();
      this.employeeSignature = new Signature();
      successAlert(t('onboard.documents.alerts.Document marked as complete.'));
    }
  }

  nextDocumentViewModel() {
    const currentIndex = _.findIndex(this.sortedDocuments, {
      companyDocument: {
        id: this.currentDocument.companyDocument.id
      }
    });

    return _.find(this.sortedDocuments.slice(currentIndex), { checked: false }) ||
      _.find(this.sortedDocuments, { checked: false }) || null;
  }

  @action selectNextDocumentViewModel() {
    const nextViewModel = this.nextDocumentViewModel();

    if (nextViewModel) {
      this.setCurrentDocumentViewModel(nextViewModel);
    } else {
      this.processing = true;
      this.callbacks.onAllDocumentsCompleted();
    }
  }

  @action onEmployeeSignatureUpdated(signatureData) {
    this.employeeSignature.signatureData = signatureData;
  }

  @computed get sortedDocuments() {
    return _.orderBy(this.documentViewModels, (d) => { return d.companyDocument.order; });
  }

  @computed get fillableAnnotations() {
    return _.filter(this.currentAnnotations, annotationViewModel =>
      FILLABLE_ANNOTATION_TYPES.includes(annotationViewModel.annotation.annotationType)
    );
  }

  @computed get validInputAnnotations() {
    return _.filter(this.currentAnnotations, (a) => a.isValid );
  }

  @computed get signatureValid() {
    if (!this.currentDocument.companyDocument.signatureRequired) return true;

    return !_.isEmpty(this.employeeSignature.signatureData);
  }

  @computed get allAnnotationsValid() {
    return this.currentAnnotations.length === this.validInputAnnotations.length && this.signatureValid;
  }

  @computed get documentToView() {
    const viewModel = _.find(this.sortedDocuments, (d) => {
      return !d.checked;
    });

    if (viewModel) {
      return viewModel.companyDocument;
    }

    return _.last(this.sortedDocuments).companyDocument;
  }

  @computed get displaySignatureForm() {
    const employee_signature_fields = this.currentAnnotations.filter(annotationViewModel => {
      return annotationViewModel.annotation.annotationType === 'employee_signature';
    });

    return this.currentDocument.companyDocument.signatureRequired &&
           employee_signature_fields.length === 0;
  }

  createAnnotationViewModel(annotation) {
    switch (annotation.annotationType) {
      case 'input':
        return new AnnotationInputViewModel(annotation);
      case 'employee_signature':
        return new SignatureViewModel(annotation);
      default:
        throw new Error(`Annotation type ${annotation.annotationType} is not supported.`);
    }
  }
}

export default FillablePdfState;
