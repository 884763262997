import React from 'react';
import {observer} from 'mobx-react';
import CustomReportFilter from './custom_report_filter';
import {Panel} from 'components';
import {t} from 'shared/core';

const FilterResults = observer(({uiState}) => {
  const {report} = uiState;

  return (
    <div>
      <Panel.Header title={t('reports.custom.edit.Filter results')}/>
      <CustomReportFilter
        report={report}
        filters={report.filter}
        onChange={filter => report.merge({filter})}
      />
    </div>
  );
});

export default FilterResults;
