import React from 'react';
import {withState, t, endpoints, types} from 'shared/core';
import {InteractiveTable, DropdownFilter, Avatar, Button} from 'components';
import {OnboardingContainerState} from '../state';
import {FormattedMessage} from 'react-intl';
import ClockIcon from 'img/clock--red@2x.png';
import {TaskModal} from 'components/modals';
import {Task} from 'stores/tasks';
import {dateFormatter} from 'shared/tools';
import {observer} from 'mobx-react';
import {AddTaskTemplatesModal, EditTaskTemplateModal} from 'components/tasks';


const DueDate = observer(({model}) => {
  if (!model.dueDate) return null;

  return (
    <div>
      <div className='table-cell align-middle pr1'>
        <img src={ClockIcon} className='my1' alt='clock' height='12px'/>
      </div>
      <div className='table-cell align-middle'>
        {dateFormatter(model.dueDate)}
      </div>
    </div>
  );
});

const AssigneeAvatar = observer(({model}) => {
  return (
    <Avatar employee={model.assigneeEmployee} size='sm'/>
  );
});

const NewTaskButton = observer(({uiState}) => {
  if (uiState.employee.terminated) return null;

  return (
    <div className='clearfix'>
      <div className='right pt2'>
        <Button className='pr2' onClick={() => uiState.openAddTaskTemplateModal()}>
          <FormattedMessage id={'employees.profile.onboarding_tab.Select New Onboarding Tasks'}/>
        </Button>
        <Button onClick={() => uiState.openCreateTaskTemplateModal()}>
          <FormattedMessage id={'employees.profile.onboarding_tab.Add New Onboarding Task'}/>
        </Button>
      </div>
    </div>
  );
});

const COLUMNS = [
  {
    header: 'employees.profile.onboarding_tab.Name',
    width: 6,
    attribute: 'name'
  },
  {
    header: 'employees.profile.onboarding_tab.Due Date',
    width: 5,
    component: DueDate,
    sortBy: 'dueDate'
  },
  {
    header: 'employees.profile.onboarding_tab.Assignee',
    width: 1,
    component: AssigneeAvatar
  },
];

function statusFilters() {
  return [
    {
      id: 'incomplete',
      display: t('employees.profile.onboarding_tab.Incomplete Tasks')
    },
    {
      id: 'complete',
      display: t('employees.profile.onboarding_tab.Completed Tasks')
    }
  ];
}

const OnboardingContainerWithState = observer(({uiState}) => {
  const {employee} = uiState;

  return (
    <React.Fragment>
      <InteractiveTable
        columns={COLUMNS}
        showLinks={false}
        saveStateToUrl={true}
        onRowClicked={task => uiState.openTaskModal(task)}
        onMount={agent => uiState.setInteractiveAgent(agent)}
        proxy={{
          type: 'async',
          modelType: types.TASK,
          model: Task,
          endpoint: endpoints.TASKS.ONBOARDING.with(employee.id),
          pageSize: 10
        }}
        localization={{
          emptyState: 'employees.profile.onboarding_tab.No tasks to display'
        }}
      >
        <DropdownFilter options={statusFilters()} attr='status'/>
      </InteractiveTable>
      <NewTaskButton uiState={uiState}/>
      <TaskModal uiState={uiState}/>
      <AddTaskTemplatesModal uiState={uiState}/>
      <EditTaskTemplateModal uiState={uiState}/>
    </React.Fragment>
  );
});

export default withState(OnboardingContainerWithState, OnboardingContainerState);
