import React from 'react';
import {observer} from 'mobx-react';
import {ActionLink, Panel} from 'components';
import _ from 'lodash';

const RootDocumentLink = observer(({title, uiState}) => {
  const {currentFolder} = uiState;

  return (
    <div className='Breadcrumb h3'>
      <ActionLink className='jumbo' onClick={() => uiState.goBack()}>
        {title}
      </ActionLink>
      {currentFolder.name}
    </div>
  );
});

const Breadcrumb = observer(({title, uiState, children}) => {
  const {currentFolderEmpty} = uiState;

  return (
    <Panel.Header title={currentFolderEmpty ? title : <RootDocumentLink title={title} uiState={uiState}/>}>
      {children}
    </Panel.Header>
  );
});

export default Breadcrumb;
