import {observer} from 'mobx-react';
import {t} from 'shared/core';

const EmployeeName = observer(({uiState}) => {
  const {employee} = uiState;
  if (employee.firstName || employee.lastName) return employee.name;

  return t('employees.hire.New Hire');
});

export default EmployeeName;
