import {action, observable, computed} from 'mobx';
import {endpoints, types, t} from 'shared/core';
import {DomainStore} from 'shared/store';
import {
  ATSMergeFields,
  PTOMergeFields,
  PerformanceReviewMergeFields,
  EmployeeInvitationMergeFields,
  CheckInEmployeeMergeFields,
  CheckInLeaderMergeFields
} from '../components/merge_fields';
import CompanyEmailTemplate from 'stores/company_email_templates/CompanyEmailTemplate';
import _ from 'lodash';
import {successAlert} from 'shared/tools';

class CompanyEmailTemplateEditState {
  store = new DomainStore();
  match;
  history;

  @observable emailTemplates;
  @observable editTemplateModalOpen = false;
  @observable emailTemplateVersion = this.emailTemplateVersions[0];
  @observable currentTemplate;

  emailTemplateVersions = [
    {
      id: 'en',
      tabHeader: t('company_settings.company_email_templates.English Version'),
      contentAttribute: 'emailContentEn',
      lexicalStateAttribute: 'lexicalStateEn',
      panelHeader: t('company_settings.company_email_templates.English Email Template')
    },
    {
      id: 'fr',
      tabHeader: t('company_settings.company_email_templates.French Version'),
      contentAttribute: 'emailContentFr',
      lexicalStateAttribute: 'lexicalStateFr',
      panelHeader: t('company_settings.company_email_templates.French Email Template')
    }
  ];

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    const id = this.match.params.id;

    await this.store._compose([
      endpoints.COMPANY_EMAIL_TEMPLATE.with(id)
    ]);

    this.currentTemplate = new CompanyEmailTemplate(this.store._getSingle(types.COMPANY_EMAIL_TEMPLATE, { id }));
  }

  @action goBack() {
    this.history.push('/company_email_templates');
  }

  @action updateContent(content) {
    this.currentTemplate[this.emailTemplateVersion.contentAttribute] = content.html;
    this.currentTemplate[this.emailTemplateVersion.lexicalStateAttribute] = content.state;
  }

  @action async updateCompanyEmailTemplate() {
    const {model, errors} = await this.store.patch(this.currentTemplate);
    this.errors = errors;

    if (model) {
      successAlert(t('company_settings.company_email_templates.alerts.Email Template Saved'));
      this.goBack();
    }
  }

  @action switchVersion(version) {
    this.emailTemplateVersion = version;
  }

  @computed get currentMergeFields() {
    if (!this.currentTemplate) return null;

    switch (this.currentTemplate.emailType) {
      case 'ats_application':
      case 'ats_disqualification':
        return ATSMergeFields;
      case 'pto_policy_invitation':
        return PTOMergeFields;
      case 'performance_review_launch':
        return PerformanceReviewMergeFields;
      case 'employee_invitation':
        return EmployeeInvitationMergeFields;
      case 'checkin_leader_welcome':
        return CheckInLeaderMergeFields;
      case 'checkin_employee_welcome':
      case 'checkin_employee_reminder':
        return CheckInEmployeeMergeFields;
      default:
        return null;
    }
  }

  @computed get currentVersionLexicalState() {
    return this.currentTemplate[this.emailTemplateVersion.lexicalStateAttribute];
  }

  @computed get currentVersionContent() {
    return this.currentTemplate[this.emailTemplateVersion.contentAttribute];
  }

  activateActionLink(version) {
    return version.id === this.emailTemplateVersion.id ? 'active' : '';
  }
}

export default CompanyEmailTemplateEditState;
