import React    from 'react';
import {withState, t} from 'shared/core';
import CheckInSummaryList from '../CheckInSummaryList';
import CheckInSummaryItem from '../CheckInSummaryItem';
import CheckInDetails from '../CheckInDetails';
import RevieweeHeader from '../RevieweeHeader';
import RevieweeDetailsEmptyState from './RevieweeDetailsEmptyState';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {Spinner} from 'components';
import ManagerCheckInState from './state/ManagerCheckInState';
import {Display} from 'components';

const RevieweeDetailsContainer = observer(({uiState}) => {
  return (
    <div>
      <Display backPath='/reviewees' backTitle={t('performance.Check-Ins I Lead')}/>
      <RevieweeHeader reviewee={uiState.checkinStore.selectedReviewee}/>
      {!_.isEmpty(uiState.checkinStore.allCheckins) && <div className='Container'>
        <div className='flex flex-wrap content-stretch bg-white rounded box-shadow-3'>
          <CheckInSummaryList>
            {uiState.checkinStore.allCheckins.map(checkin => <CheckInSummaryItem key={checkin.id} checkin={checkin} onClick={uiState.onCheckInSelected}/>)}
          </CheckInSummaryList>
          {
            uiState.checkinStore.selectedCheckin && !uiState.checkinStore.isLoading &&
              <CheckInDetails
                checkin={uiState.checkinStore.selectedCheckin}
                reviewee={uiState.checkinStore.selectedReviewee}
                onMarkCompleted={uiState.onMarkCompleted}
                onMarkUncompleted={uiState.onMarkUncompleted}
                uiState={uiState}
              />
          }
          {uiState.checkinStore.isLoading && <Spinner />}
        </div>
      </div>}
      {_.isEmpty(uiState.checkinStore.allCheckins) && <RevieweeDetailsEmptyState reviewee={uiState.checkinStore.selectedReviewee}/>}
    </div>
  );
});

export default withState(RevieweeDetailsContainer, ManagerCheckInState);
