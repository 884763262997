import moment from 'moment';
import _ from 'lodash';

export function formatColumn(value, column) {
  switch (column.type) {
    case 'date':
      return moment(value).format(column.format);
    default:
      return value;
  }
}

export function getSeriesAndCategories(rows, meta) {
  const {columns, category, series} = meta;
  const categoryColumn = _.find(columns, c => c.id === category);

  const categories = _.chain(rows)
    .map(`values.${category}`)
    .uniq()
    .map(c => formatColumn(c, categoryColumn))
    .value();

  const highchartsSeries = _.chain(series)
    .map(s => ({
      name: _.find(columns, c => c.id === s).label,
      data: _.map(rows, `values.${s}`)
    }))
    .filter(s => _.size(highchartsSeries) === 1 || _.sum(s.data))
    .value();

  return {categories, highchartsSeries};
}
