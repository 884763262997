import React from 'react';
import {FormattedMessage} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {auth} from 'shared/core';
import {Layout} from 'components';

const TrainingSubnav = () => {
  return (
    <div className='Subnav Subnav--react'>
      <Layout>
        <div className='Subnav-header'><FormattedMessage id='training.subnav.Training'/></div>
        <div className='Subnav-list list-reset'>
          {!!auth.employee.id && <li><NavLink exact to='/programs' activeClassName='active'><FormattedMessage id='training.subnav.My Training'/></NavLink></li>}
          {auth.hasAccess('::MANAGE_TRAINING') && <li><NavLink exact to='/programs/company' activeClassName='active'><FormattedMessage id='training.subnav.Company Training'/></NavLink></li>}
          {auth.hasAccess('::MANAGE_TRAINING') && <li><NavLink to='/admin/programs' activeClassName='active'><FormattedMessage id='training.subnav.Manage'/></NavLink></li>}
        </div>
      </Layout>
    </div>
  );
};

export default TrainingSubnav;
