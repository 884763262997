import React from 'react';
import {observer} from 'mobx-react';
import RadioButton from 'components/RadioButton';
import RadioButtonGroup from 'components/RadioButtonGroup';
import {noop} from 'shared/tools';
import QuickTip from 'components/QuickTip';
import {t} from 'shared/core';
import {FieldHeader, AttachedDocumentDownload, Label} from 'components';
import Description from './Description';
import _ from 'lodash';

const AnswerValue = observer(({ answer }) => {
  return (
    <div className='jumbo whitespace-pre-line pb1'>
      <span>{answer.formattedValue || '--'}</span>
    </div>
  );
});

const DefaultAnswer = observer(({answer}) => {
  return (
    <div className='mb2'>
      <FieldHeader
        title={answer.question.name}
        description={answer.question.description}
        required={answer.question.required}
        className='medium'
      />
      <AnswerValue answer={answer}/>
    </div>
  );
});

const AttachmentView = observer(({answer}) => {
  const {attachment} = answer;

  return (
    <div className='mb2'>
      <Description answer={answer}/>
      <div className='mt1'>
        {attachment
          ? <AttachedDocumentDownload doc={attachment}/>
          : <Label/>}
      </div>
    </div>
  );
});

const OptionView = observer(({answer, option}) => {
  const answerIsOption = answer.value === option.value;

  return (
    <div className='my1'>
      <RadioButton
        trait={answerIsOption && !answer.correct && 'error'}
        disabled={!answerIsOption}
        className='table-cell pr2 align-middle'
        value={option.value}
      >
        {option.value}
        {answerIsOption && answer.correct &&
          <i className='material-icons h3 pl1 dodger align-inherit'>{'check'}</i>}
        {answerIsOption && !answer.correct &&
          <i className='material-icons h3 pl1 scarlet align-middle'>{'close'}</i>}
      </RadioButton>
    </div>
  );
});

const SentimentView = observer(({answer}) => {
  return (
    <div className='mb2'>
      <Description answer={answer} required={answer.question.required} />
      <RadioButtonGroup
        className='RadioButtonGroup-scale mt2'
        selectedValue={answer.value}
      >
        {answer.question.options.map((option, index) =>
          <RadioButton
            key={option.value}
            disabled={option.value !== answer.value}
            value={option.value}
          >
            <div className='Radio-button--scale'>
              <div className='Radio-button-number--scale'>{index + 1}</div>
              <div className='Radio-button-text--scale'>{option.value}</div>
            </div>
          </RadioButton>)
        }
      </RadioButtonGroup>
    </div>
  );
});

const QuizView = observer(({answer}) => {
  const options = answer.question.options;
  if (answer.value && !_.find(options, (o) => o.value === answer.value)) {
    options.push({value: answer.value});
  }

  return (
    <div className='mb2'>
      <Description answer={answer}/>
      <RadioButtonGroup
        name={answer.question.name}
        selectedValue={answer.value}
        onChange={() => noop()}
      >
        {options.map((option, index) =>
          <OptionView key={index} answer={answer} option={option} />)}
      </RadioButtonGroup>
      {!answer.correct &&
        <QuickTip
          trait='default'
          header={t('components.question_form.Correct answer')}
          paragraphs={[answer.question.quizCorrectOption.value]}
      />}
    </div>
  );
});

const AnswerRenderFactory = observer(({answer}) => {
  switch (answer.question.questionType) {
    case 'attachment':
      return <AttachmentView answer={answer}/>;
    case 'text_field':
    case 'text_area':
    case 'multiple_choice':
    case 'multi_select':
    case 'description':
      return <DefaultAnswer answer={answer} />;
    case 'sentiment':
    case 'scored':
      return <SentimentView answer={answer} />;
    case 'quiz':
      return <QuizView answer={answer} />;
    default:
      throw new Error(`Unknown question type of ${answer.question.questionType}`);
  }
});

const AnswerResults = observer(({answers}) => {
  return answers.map(answer =>
    <AnswerRenderFactory answer={answer} />
  );
});

export default AnswerResults;
