import {observable, computed, action} from 'mobx';
import moment from 'moment';

class CommentViewModel {
  uiState;

  @observable data;

  @observable editingComment;

  constructor(data, uiState) {
    this.data = data;
    this.uiState = uiState;
    this.editingComment = this.data.content;
  }

  editCommentClicked() {
    this.uiState.editCommentClicked(this);
  }

  deleteCommentClicked() {
    this.uiState.deleteCommentClicked(this);
  }

  changeEditingComment(comment) {
    this.editingComment = comment;
  }

  submitComment() {
    if (this.uiState.commenting) return;

    this.data.merge({content: this.editingComment});

    if (this.data.id) {
      this.uiState.confirmCommentEdit(this);
    } else {
      this.uiState.submitComment(this);
    }
  }

  @computed get editCommentPlaceholder() {
    if (this.data.id) {
      return 'hr_dashboard.feed.announcements.Edit a comment...';
    } else {
      return 'hr_dashboard.feed.announcements.Add a comment...';
    }
  }

  @computed get editCommentSubmitCaption() {
    if (this.data.id) {
      return 'Save';
    } else {
      return 'hr_dashboard.feed.announcements.Add Comment';
    }
  }

  @action changeComment(comment) {
    this.editingComment = comment;
  }

  @computed get comment() {
    return this.editingComment;
  }

  @computed get createdTimeAgo() {
    return moment(this.data.createdAt).fromNow();
  }

  @computed get updatedTimeAgo() {
    return moment(this.data.updatedAt).fromNow();
  }

  @computed get hasBeenUpdated() {
    return !moment(this.data.updatedAt).isSame(this.data.createdAt);
  }
}

export default CommentViewModel;
