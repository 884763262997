import React from 'react';
import {observer} from 'mobx-react';
import RecruitingSubnav from 'recruiting/components/RecruitingSubnav';
import CandidateList from './CandidateList';

const CandidateDirectoryContainer = observer(() => {
  return (
    <div>
      <RecruitingSubnav/>
      <CandidateList/>
    </div>
  );
});

export default CandidateDirectoryContainer;
