import React, { useState } from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {CompanyFilesState} from '../state';
import {Accordion, AttachedDocumentDownload, Panel, EmojiTitle, Spinner} from 'components';
import FoldersIcon from 'img/settings/settings-folders@2x.png';
import _ from 'lodash';

const Folder = observer(({folder, uiState}) => {
  const [expanded, setExpanded] = useState(false);
  const [hasFetchedFiles, setHasFetchedFiles] = useState(false);
  const [files, setFiles] = useState([]);
  const showExpand = !hasFetchedFiles || !_.isEmpty(files);

  const handleExpand = async () => {
    setExpanded(!expanded);
    if (hasFetchedFiles) return;

    setFiles(await uiState.fetchFilesFor(folder.id));
    setHasFetchedFiles(true);
  };

  return (
    <div key={folder.id} className='mxn1'>
      <div className={`flex align-items-middle medium mb1 user-select-none ${showExpand ? 'clickable' : ''}`} onClick={() => handleExpand()}>
        {showExpand ?
          <i className={`material-icons jumbo folder-chevron ${expanded ? 'expanded' : ''}`}>{'chevron_right'}</i> :
          <div style={{marginLeft: '24px'}}></div>
        }
        <img className='mx1' src={FoldersIcon} height='20' width='20' alt='folder'/>
        {folder.name}
      </div>
      <Accordion active={expanded}>
        {!hasFetchedFiles && <Spinner size='sm'/>}
        {hasFetchedFiles && <div className='flex'>
          <div className='space-y'>
            {files.map(file => <div key={file.id} className='flex'>
              <AttachedDocumentDownload className='ml3' doc={file.file} name={file.name}/>
            </div>)}
          </div>
        </div>}
      </Accordion>
    </div>
  );
});

const CompanyFiles = observer(({uiState}) => {
  const {sortedCompanyFileFolders, sortedFolderlessFiles, Emoji} = uiState;

  if(_.isEmpty(sortedCompanyFileFolders) && _.isEmpty(sortedFolderlessFiles)) return null;

  return (
    <Panel>
      <Panel.Header title={<EmojiTitle Emoji={Emoji} emojiKey='file_folder' title={t('hr_dashboard.company_files.Company Files')}/>}/>
      <div className='CompanyFiles space-y'>
        {sortedCompanyFileFolders.map(companyFileFolder =>
          <Folder folder={companyFileFolder} uiState={uiState}/>
        )}
        {sortedFolderlessFiles.map(file =>
          <div key={file.id}>
            <AttachedDocumentDownload doc={file.file} name={file.name}/>
          </div>
        )}
      </div>
    </Panel>
  );
});

export default withState(CompanyFiles, CompanyFilesState);
