import {DomainObject} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {rangeDate} from 'shared/tools';
import _ from 'lodash';
import User from 'stores/users/User';
import {AnnouncementComment, AnnouncementReaction} from 'stores/announcements';
import Employee from 'stores/employees/Employee';
import {auth} from 'shared/core';
import {AttachedDocument} from 'stores/documents';

class Announcement extends DomainObject {
  @observable id;
  @observable name;
  @observable description;
  @observable lexicalState;
  @observable publishedAt;
  @observable read;
  @observable unread;
  @observable publishedByUser;
  @observable reactions;
  @observable comments;
  @observable image;

  @action merge(other) {
    super.merge(other, {
      publishedByUser: User,
      _dates: ['publishedAt'],
      comments: [AnnouncementComment],
      reactions: [AnnouncementReaction],
      employees: [Employee],
      image: AttachedDocument
    });
  }

  @computed get publishedAtView() {
    return rangeDate(this.publishedAt);
  }

  @computed get hasComments() {
    return !!this.comments.length;
  }

  @computed get groupedReactions() {
    return _(this.reactions)
      .groupBy(r => r.emojiKey)
      .map(value => (
        {
          emoji: value[0].emoji,
          emojiKey: value[0].emojiKey,
          reactions: value
        }
      ))
      .sortBy(value => value.emojiKey)
      .value();
  }

  @computed get imageUrl() {
    if (!this.image) {
      return '';
    }

    return _.get(this.image, 'links.url.href') || `${this.image.label}?policy=${auth.fileStack.uploadPolicy.policy}&signature=${auth.fileStack.uploadPolicy.signature}`;
  }
}

export default Announcement;
