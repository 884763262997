import React from 'react';
import {observer} from 'mobx-react';
import TimeOffSubnav from 'containers/time_off/TimeOffSubnav';
import TimeOffHolidaysContainerWithState
  from 'containers/time_off/components/holidays/components/TimeOffHolidaysContainerWithState';

const HolidaysContainer = observer(({scope}) => {
  return (
    <div>
      <TimeOffSubnav scope={scope} />
      <TimeOffHolidaysContainerWithState scope={scope} />
    </div>
  );
});

export default HolidaysContainer;
