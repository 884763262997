import {DomainObject, oneOf} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {BenefitInfo} from 'stores/benefits';

class BenefitInfoChangeRequest extends DomainObject {
  @observable effectiveDate;
  @observable reasonForChange;
  @observable reasonText;
  @observable benefitInfoChanges;
  @observable updatedBenefitInfo;

  @action merge(other) {
    super.merge(other, {
      _dates: ['effectiveDate'],
      updatedBenefitInfo: BenefitInfo,
      reasonForChange: oneOf([
        'child_birth',
        'marriage',
        'divorce',
        'cohabitation',
        'student_status',
        'other'
      ])
    });
  }

  @computed get otherReason() {
    return this.reasonForChange === 'other';
  }
}

export default BenefitInfoChangeRequest;
