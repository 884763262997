import React from 'react';
import ReactDOM from 'react-dom';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import Modal from 'components/modals/Modal';
import {observable, action} from 'mobx';
import {withState, t} from 'shared/core';

const HelpModal = observer(({explanation, uiState}) => {
  return (
    <Modal
      isOpen={uiState.modalOpen}
      size='sm'
      onHide={() => uiState.hideModal()}
      title={explanation.header}
    >
      {explanation.paragraphs.map((p, index) => (
        <div className='pb1' key={index}>{p}</div>
      ))}
    </Modal>
  );
});

@observer class HelpLink extends React.Component {
  modalHtmlNode;

  constructor(props) {
    super(props);
    this.modalHtmlNode = document.createElement('div');
  }

  render() {
    const {title, explanation, uiState} = this.props;

    return [
      <span key='link' className='HelpLink' onClick={() => uiState.openModal()}>
        {title || t("components.help_link.What's this?")}
      </span>,
      ReactDOM.createPortal(
        <HelpModal explanation={explanation} uiState={uiState}/>,
        this.modalHtmlNode
      )
    ];
  }
}

class HelpLinkState {
  @observable modalOpen = false;

  @action openModal() {
    this.modalOpen = true;
  }

  @action hideModal() {
    this.modalOpen = false;
  }
}

HelpLink.propTypes = {
  explanation: PropTypes.shape({
    header: PropTypes.string.isRequired,
    paragraphs: PropTypes.array.isRequired
  })
};

export default withState(HelpLink, HelpLinkState);
