import React from 'react';
import {observer} from 'mobx-react';
import {Display, Layout, Panel} from 'components';
import UsersList from './UsersList';
import BrokerInvitationsList from './BrokerInvitationsList';
import {withState, t} from 'shared/core';
import {UsersSettingsContainerState} from '../../state';

const UsersSettingsContainer = observer(({uiState}) => {
  return (
    <div>
      <Display
        title={t('broker_portal.broker_settings.Users')}
        backTitle={t('broker_portal.broker_settings.Settings')}
      />
      <Layout>
        <Panel.Stack loose>
          <UsersList uiState={uiState}/>
          <BrokerInvitationsList uiState={uiState}/>
        </Panel.Stack>
      </Layout>
    </div>
  );
});

export default withState(UsersSettingsContainer, UsersSettingsContainerState);
