import {DomainStore} from 'shared/store';
import {action, observable} from 'mobx';
import {endpoints, types, t} from 'shared/core';
import {BrokerInvitation} from 'stores/broker_portal';
import {successAlert} from 'shared/tools';

class UsersSettingsContainerState {
  store = new DomainStore();
  agent;

  @observable addUserModalOpen;
  @observable invitation = new BrokerInvitation();
  @observable errors = {};

  @action setAgent(agent) {
    this.agent = agent;
  }

  @action openAddUserModal() {
    this.addUserModalOpen = true;
  }

  @action closeAddUserModal() {
    this.addUserModalOpen = false;
    this.invitation = new BrokerInvitation();
    this.errors = {};
  }

  @action async sendUserInvitation() {
    const {model, errors} = await this.store.post(
      endpoints.BROKER_PORTAL.BROKER_INVITATIONS,
      types.BROKER_PORTAL.BROKER_INVITATION,
      this.invitation
    );

    this.errors = errors;

    if (model) {
      successAlert(t('broker_portal.broker_settings.Invitation sent to broker.'));
      this.agent.refresh();
      this.closeAddUserModal();
    }
  }

  @action async removeInvite(invitation) {
    await this.store.destroy(invitation);
  }

  @action async removeUser(user) {
    await this.store.destroy(user);
  }
}

export default UsersSettingsContainerState;
