import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {t} from 'shared/core';
import {DragAndDrop, EditQuestionModal, FormError} from 'components';
import QUESTION_TYPES from 'stores/surveys/surveyQuestionTypes';

const QuestionContent = observer(({model}) => {
  return (
    <div>
      <div>
        {model.text && <span className='mr1'>{model.text}</span>}
        <span className='waterloo'>
          {t(`components.question_form.question_types.${model.questionType}`)}
        </span>
        {model.questionType === 'sentiment' && <div className='mt1 medium'>{t('components.question_form.Options')}</div>}
        {model.questionType === 'sentiment' && <div>{model.commaSeparatedOptions}</div>}
      </div>
    </div>
  );
});

const LOCALIZATION = {
  addModel: 'performance.+ Add a Question'
};

const Questions = observer(({uiState}) => {
  const {survey, errors, editQuestionModalOpen, editingQuestion} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('surveys.edit.steps.Questions')}
        description={t('surveys.edit.Choose between multiple choice (multiple and single answer), free text, and sentiment questions.')}
      />
      <DragAndDrop
        models={survey.sortedQuestions}
        component={QuestionContent}
        onAdd={() => uiState.addQuestion()}
        onEdit={(question) => uiState.editQuestion(question)}
        onRemove={(question) => uiState.removeQuestion(question)}
        localization={LOCALIZATION}
        errors={errors}
        errorPath={(index) => `questions[${index}].name`}
      />
      {editingQuestion && <EditQuestionModal
        questionTypes={QUESTION_TYPES}
        isOpen={editQuestionModalOpen}
        onClose={() => uiState.closeEditQuestionModal()}
        onSave={() => uiState.saveQuestion()}
        question={editingQuestion}
        showRequired={true}
        errorMessage={errors.options}
      />}
      <FormError message={errors.base}/>
    </div>
  );
});

export default Questions;
