import React, {useEffect, useRef} from 'react';
import {observer} from 'mobx-react';
import {
  Button,
  AutosaveStatus,
  Avatar,
  HtmlRender,
  LexicalEditor,
  ActionLinks,
  CheckboxWithLabel
} from 'components';
import {FormattedMessage} from 'react-intl';
import {withState, t, auth} from 'shared/core';
import ReviewNotesState from 'containers/performance_reviews/components/reviews/components/view/components/review_details/state/ReviewNotesState';
import {dateFormatter, fullName} from 'shared/tools';

const Note = observer(({note, uiState}) => {
  const noteEl = useRef(null);

  useEffect(() => {
    if (noteEl.current && location.href.includes(`note-${note.id}`)) {
      noteEl.current.scrollIntoView({block: 'center'});
    }
  }, []);
  const {review} = uiState;

  return (
    <div className={`clearfix border-bottom p3 ${(note.visibleToReviewee && !review.currentUserIsReviewee) ? 'bg-caution' : ''}`}>
      <div className='col col-1 pr1'>
        <Avatar employee={note.createdByUser} size='sm' className='left'/>
      </div>
      <div className='col col-11 pl1'>
        <div className='col col-12'>
          {note.canPatch && <ActionLinks model={note} customLinks={() => uiState.customLinksFor(note)}/>}
          <div className='flex'>
            <div className='h3 medium'>
              {fullName(note.createdByUser)}
            </div>
            {!note.visibleToReviewee && <div className='ml2 flex align-items-center jumbo h5'>
              <i className='material-icons h3 mr1'>{'visibility_off'}</i>
              <FormattedMessage id='performance_reviews.view.notes.VISIBILITY_LABEL'
                                values={{name: review.reviewee.employee.name}}/>
            </div>}
          </div>
          <div className='h5 jumbo'>{dateFormatter(note.createdAt)}</div>
        </div>
        <div className='col col-12 pt2 whitespace-pre-line word-break'>
          <HtmlRender html={note.text}/>
        </div>
      </div>
    </div>
  );
});

const NoteEditor = observer(({uiState}) => {
  const {editingNote, editingNoteErrors, review} = uiState;

  return (
    <div className='clearfix border-bottom p3'>
      <div className='col col-1 pr1'>
        <Avatar employee={editingNote.createdByUser} size='sm' className='left'/>
      </div>
      <div className='col col-11 pl1'>
        <LexicalEditor
          editorClassName={(editingNote.visibleToReviewee && !review.currentUserIsReviewee) ? 'bg-caution' : ''}
          placeholder='performance_reviews.view.notes.Add a note'
          onChange={text => uiState.updateEditingNoteText(text)}
          initialEditorState={editingNote.lexicalState}
          initialHTML={editingNote.htmlText}
          errorMessage={editingNoteErrors.text}
        />
        {review.reviewee.employee.id !== auth.employee.id && <div className='p2 flex justify-content-end'>
          <CheckboxWithLabel
            label={t('performance_reviews.view.notes.VISIBILITY_TOGGLE', {name: review.reviewee.employee.name})}
            onChange={(e) => uiState.updateEditingNoteVisibility(e.checked)}
            checked={editingNote.visibleToReviewee}
            translatable={false}
          />
        </div>}
        <div className='p2 right-align'>
          <Button trait='default' size='sm' className='px2' onClick={() => uiState.cancelEditing()}>
            <FormattedMessage id='performance_reviews.view.notes.Cancel'/>
          </Button>
          <Button className='pl2' trait='primary' onClick={() => uiState.updateNote()}>
            <FormattedMessage id='performance_reviews.view.notes.Update note'/>
          </Button>
        </div>
      </div>
    </div>
  );
});

const ReviewNotes = observer(({review, uiState}) => {
  if (!review.isManagerReview) return null;

  const {newNote, newNoteErrors, editingNote} = uiState;

  return (
    <div className='border rounded my3'>
      <div className='px3 py1 bg-athens '>
        <div className='h3 medium inline-block'>
          <FormattedMessage id='performance_reviews.view.notes.Notes'/>
        </div>
      </div>
      {review.notes.map(note => {
        if (editingNote.id === note.id) {
          return <NoteEditor uiState={uiState}/>;
        } else {
          return <Note note={note} uiState={uiState}/>;
        }
      })}
      <div className='print-hide'>
        <LexicalEditor
          className='border-none'
          editorClassName={(newNote.visibleToReviewee && !review.currentUserIsReviewee) ? 'bg-caution' : ''}
          errorClassName={`${(newNote.visibleToReviewee && !review.currentUserIsReviewee) ? 'bg-caution' : ''} pl2 pb1`}
          placeholder='performance_reviews.view.notes.Add a note'
          onChange={text => uiState.updateNewNoteText(text)}
          initialEditorState={newNote.lexicalState}
          initialHTML={newNote.htmlText}
          key={newNote.id}
          errorMessage={newNoteErrors.text}
        />
        {review.reviewee.employee.id !== auth.employee.id && <div className='border-top'>
          <div className='p2 flex justify-content-end'>
            <CheckboxWithLabel
              label={t('performance_reviews.view.notes.VISIBILITY_TOGGLE', {name: review.reviewee.employee.name})}
              onChange={(e) => uiState.updateNewNoteVisibility(e.checked)}
              checked={newNote.visibleToReviewee}
              translatable={false}
            />
          </div>
        </div>}
        <div className='border-top'>
          <div className='p2 right-align'>
            <AutosaveStatus autosaver={newNote.autosaver}/>
            <Button className='pl2' trait='primary' onClick={() => uiState.addNote()}>
              <FormattedMessage id='performance_reviews.view.notes.Add a note'/>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default withState(ReviewNotes, ReviewNotesState);
