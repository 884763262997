import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {ActionLink, Label, Panel} from 'components';
import EditAddressInfoModal from './EditAddressInfoModal';
import {t} from 'shared/core';

const AddressInformation = observer(({uiState}) => {
  const {employee} = uiState;

  return (
    <Panel className='mt2 mb1'>
      <Panel.Header title={t('employees.profile.personal.Address')}>
        {uiState.editDataPermission('::PERSONAL_INFORMATION') &&
          <ActionLink onClick={() => uiState.openAddressInfoModal()}>
            <FormattedMessage id='employees.profile.personal.Edit'/>
          </ActionLink> }
      </Panel.Header>
      <div className='col-12 sm-col-10 pt1'>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Address Line 1'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='homeAddress1'/>
          </div>
        </div>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Address Line 2'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='homeAddress2'/>
          </div>
        </div>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Country'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={uiState} path='countryView'/>
          </div>
        </div>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.State/Province'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={uiState} path='regionView'/>
          </div>
        </div>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.City'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='city'/>
          </div>
        </div>
        <div className='clearfix mxn1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Postal Code'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='postalCode'/>
          </div>
        </div>
      </div>
      <EditAddressInfoModal uiState={uiState} />
    </Panel>
  );
});

export default AddressInformation;
