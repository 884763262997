import React from 'react';
import Spinner from 'components/spinners/Spinner';

const CandidateDetailsPlaceholder = () => {
  return (
    <div className='col col-8 bg-white min-height-800 relative'>
      <div className='abs-center'>
        <Spinner />
      </div>
    </div>
  );
};

export default CandidateDetailsPlaceholder;
