const PAY_FREQUENCY_TYPES = [
  'annually',
  'monthly',
  'semimonthly',
  'biweekly',
  'weekly',
  'daily'
];

export default PAY_FREQUENCY_TYPES.map(t => ({
  id: t,
  name: `models.employment_record.pay_frequency.${t}`
}));
