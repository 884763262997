import _ from 'lodash';
import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import {TrainingProgram} from 'stores/training';
import TrainingViewModel from './TrainingViewModel';

class TrainingListState {
  @observable programs = [];
  store = new DomainStore();

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.TRAINING.PROGRAMS
    ]);

    const trainingPrograms = this.store._getAll(
      types.TRAINING.PROGRAM,
      {
        availableInHiring: true,
        launched: true
      },
      TrainingProgram
    );

    this.programs = trainingPrograms.map(program => new TrainingViewModel(program));
    this.restoreState();
  }

  @computed get selectedPrograms() {
    return this.programs.filter(p => p.selected);
  }

  restoreState() {
    const trainingIds = _.get(window, 'collage.trainingParams.training_ids', []);

    _.each(trainingIds, id => {
      const existingProgram = _.find(this.programs, {trainingProgram: {id}});
      if (existingProgram) {
        existingProgram.selected = true;
      }
    });
  }
}

export default TrainingListState;
