import {action, computed, observable} from 'mobx';
import _ from 'lodash';
import {EarningsFormatter} from 'shared/tools';
import {t} from 'shared/core';

class JobTimelineEntryViewModel {
  @observable record;
  @observable expanded = false;
  @observable isFirst = false;
  @observable isLast = false;
  @observable earningsFormatter;

  constructor(employmentRecord) {
    this.record = employmentRecord;
    this.earningsFormatter = new EarningsFormatter(this.record);
  }

  @action toggleExpanded() {
    this.expanded = !this.expanded;
  }

  @computed get expandedText() {
    return this.expanded ? 'employees.profile.role.- Hide Details' : 'employees.profile.role.+ Show Details';
  }

  @computed get changedByTitle() {
    return this.record.wasUpdated ? 'employees.profile.role.Changed by' : 'employees.profile.role.Created by';
  }

  @computed get hasChangedBySection() {
    return !!this.record.author;
  }

  @computed get insurableEarningsLabel() {
    return t(
      'employees.profile.role.COMPUTED_INSURABLE_EARNINGS',
      {insurableEarnings: this.earningsFormatter.formattedTotalInsurableEarnings}
    );
  }
}

export default JobTimelineEntryViewModel;
