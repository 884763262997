import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {TextArea} from 'components';

const EditEntryNoteModal = observer(({isOpen, uiState}) => {
  const {editingEntry} = uiState;

  return (
    <Modal
      size='sm'
      onHide={() => uiState.closeEditEntryNoteModal()}
      isOpen={isOpen}
      title='time_tracking.timesheet.Edit Note'
    >
      <TextArea
        value={editingEntry.note}
        onChange={e => editingEntry.note = e.target.value}
      />
      <ModalButtons
        onCancel={() => uiState.closeEditEntryNoteModal()}
        onSave={() => uiState.saveEntryNote()}
      />
    </Modal>
  );
});

export default EditEntryNoteModal;
