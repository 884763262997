import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {Table, Panel} from 'components';
import {t} from 'shared/core';
import EditRuleModal from './EditRuleModal';

const COLUMNS = [
  {
    header: 'recruiting.edit.steps.Question',
    attribute: 'question.name',
    width: 6
  },
  {
    header: 'recruiting.edit.steps.Disqualifiers',
    attribute: 'formattedDisqualifiers',
    width: 5
  }
];

const Rules = observer(({uiState}) => {
  const {position} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('recruiting.edit.steps.Auto-Disqualification Rules')}
        description={t('recruiting.edit.steps.AUTO_DISQUALIFICATION_DESCRIPTION')}
      />
      <Table models={position.rules}
             columns={COLUMNS}
             customLinks={uiState.customLinksForRules()}
             localization={uiState.RULES_LOCALIZATION}
             showAddLink={uiState.showAddLink}
             onAdd={() => uiState.editRule()}/>
      <EditRuleModal uiState={uiState}/>
    </div>
  );
});

export default Rules;
