import React from 'react';
import {observer} from 'mobx-react';
import {UploadFiles} from 'components';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const PayrollFileUploadModal = observer(({uiState}) => {
  const {payrollUpload, isUploading, uploadFileModalOpen, errors} = uiState;

  return (
    <Modal isOpen={uploadFileModalOpen} onHide={() => uiState.closeUploadFileModal()} size='md'>
      <div className='clearfix'>
        <div className='h2 pt1 medium'>
           <FormattedMessage id='payroll.uploader.Upload File'/>
        </div>
        <div className='mt3 mb2'>
          <FormattedMessage id="payroll.uploader.Upload employee's hours worked or one off bonuses." />
        </div>
        <div className='clearfix'>
          <UploadFiles
            defaultFiles={payrollUpload.payrollFiles}
            maxFiles={1}
            onUploadStarted={() => uiState.onUploadStarted()}
            onUploadFinished={() => uiState.onUploadFinished()}
            errorMessage={errors.payrollFile}
            isUploading={isUploading}
            onChange={files => payrollUpload.merge({payrollFiles: files})}
            modelType='payroll/payroll_upload'
          />
        </div>
        <ModalButtons
          saveCaption='payroll.uploader.Upload'
          onSave={() => uiState.createPayrollUpload()}
          onCancel={() => uiState.closeUploadFileModal()}
          enabled={!isUploading}
        />
      </div>
    </Modal>
  );
});

export default PayrollFileUploadModal;
