import _ from 'lodash';

class Steps {
  __allSteps;

  constructor(allSteps) {
    this.__allSteps = allSteps;
  }

  all() {
    return this.__allSteps;
  }

  byIndex(index) {
    return this.__allSteps[index];
  }

  byLocation(location) {
    return _.find(this.__allSteps, {location});
  }

  first() {
    return _.head(this.__allSteps);
  }

  last() {
    return _.last(this.__allSteps);
  }

  before({location}) {
    const index = _.findIndex(this.__allSteps, {location});

    if (index <= 0) return null;

    return this.byIndex(index - 1);
  }

  after({location}) {
    const index = _.findIndex(this.__allSteps, {location});

    if (index === -1 || index === this.__allSteps.length - 1) return null;

    return this.byIndex(index + 1);
  }
}

export default Steps;
