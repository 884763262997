import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const EditConfirmationModal = observer(({uiState}) => {
  return (
    <Modal
      size='sm'
      isOpen={uiState.editConfirmationModalOpen}
      onHide={() => uiState.closeEditConfirmationModal()}
      title='employees.profile.Are you sure you want to edit this employment record?'
    >
      <div className='jumbo'>
        <FormattedMessage id='employees.profile.EDIT_CONFIRMATION_MODAL_DESC'/>
      </div> 
      <ModalButtons
        saveCaption='employees.profile.Continue Editing'
        onCancel={() => uiState.closeEditConfirmationModal()}
        onSave={() => uiState.editEntry()} 
      />
    </Modal>
  );
});

export default EditConfirmationModal;
