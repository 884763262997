import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {t} from 'shared/core';
import {FormLayout, Input} from 'components';

const InsuranceNumberForCarrier = observer(({insuranceNumber, carrier, errors = {}}) => {
  if (!insuranceNumber) return null;
  if (!carrier.selfAssignedCertificatesEnabled) return null;

  return (
    <div>
      <FormLayout>
        <Input
          label='components.benefits.insurance_numbers.Insurance Number'
          helpLink={{
            explanation: {
              header: 'components.benefits.insurance_numbers.Insurance Number',
              paragraphs: [t('components.benefits.insurance_numbers.INSURANCE_NUMBER_EXPLANATION')]
            }
          }}
          value={insuranceNumber.externalId}
          onChange={e => insuranceNumber.merge({externalId: e.target.value})}
          errorMessage={_.get(errors, 'externalId')}
        />
      </FormLayout>
    </div>
  );
});

export default InsuranceNumberForCarrier;
