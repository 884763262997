import React from 'react';

const NavigationIcon = ({icon}) => {
  return (
    <div className='table-cell align-top pr2' style={{paddingTop: '4px'}}>
      <div className='bg-zircon rounded' style={{padding: '5px 8px 7px 8px'}}>
        <img src={icon} height='16' width='16' role='presentation' style={{marginBottom: '1px'}}/>
      </div>
    </div>
  );
};

export default NavigationIcon;
