import {types, endpoints} from 'shared/core';
import CompanySignature from 'stores/documents/CompanySignature';
import {DomainStore} from 'shared/store';
import {CustomField} from 'stores/custom_fields';

class AnnotatablePdfStore extends DomainStore {
  async load() {
    await this._compose([
      endpoints.COMPANY_SIGNATURES,
      endpoints.CUSTOM_FIELDS.ALL
    ]);
  }

  getCompanySignatures() {
    return this._getAll(types.COMPANY_SIGNATURE, CompanySignature);
  }

  getCustomFields() {
    return this._getAll(types.CUSTOM_FIELD, CustomField);
  }
}

const singleton = new AnnotatablePdfStore();

export default singleton;
