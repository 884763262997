import React from 'react';
import {t} from 'shared/core';
import {observer} from 'mobx-react';
import {FormLayout, Input, Select2, BirthdayPicker} from 'components';

const OnboardEmployeePersonalInformationForm = observer(({uiState}) => {
  const {employee, errors} = uiState;

  return (
    <FormLayout>
      <FormLayout.Group>
        <Input errorMessage={errors.firstName}
          label='onboard.employee_info.First Name'
          value={employee.firstName}
          onChange={(e) => employee.merge({firstName: e.target.value})}
          placeholder='onboard.employee_info.Required'/>
        <Input errorMessage={errors.lastName}
          label='onboard.employee_info.Last Name'
          value={employee.lastName}
          onChange={(e) => employee.merge({lastName: e.target.value})}
          placeholder='onboard.employee_info.Required'/>
      </FormLayout.Group>
      <FormLayout.Group>
        <Input errorMessage={errors.middleName}
          label='onboard.employee_info.Middle Name'
          value={employee.middleName}
          onChange={(e) => employee.merge({middleName: e.target.value})}
          placeholder='onboard.employee_info.Optional'/>
        <Input errorMessage={errors.preferredName}
          label='onboard.employee_info.Preferred Name'
          value={employee.preferredName}
          tooltip={{
            paragraphs: [
              t('employees.profile.personal.If you would prefer not to have your legal name displayed to other employees in Collage and want to go by another name, add a preferred name.'),
              t('employees.profile.personal.This name will be the name you appear as in Collage, and only admins will be able to see your legal first name.')
            ]
          }}
          onChange={(e) => employee.merge({preferredName: e.target.value})}
          placeholder='onboard.employee_info.Optional'/>
      </FormLayout.Group>
      <FormLayout.Group>
        <Input errorMessage={errors.preferredLastName}
            label='onboard.employee_info.Preferred Last Name'
            value={employee.preferredLastName}
            onChange={(e) => employee.merge({preferredLastName: e.target.value})}
            placeholder='onboard.employee_info.Optional'/>
        <BirthdayPicker
          minAge={14} maxAge={99}
          value={employee.birthDate}
          label='onboard.employee_info.Birth Date'
          onChange={(date) => employee.merge({birthDate: date})}
          errorMessage={errors.birthDate}
          />
      </FormLayout.Group>
      <FormLayout.Group>
        <Select2 value={employee.gender}
          errorMessage={errors.gender}
          onChange={(e) => employee.merge({gender: e.target.value})}
          label='onboard.employee_info.Gender Identity'>
            <option value='male'>{t('onboard.employee_info.genders.Man')}</option>
            <option value='female'>{t('onboard.employee_info.genders.Woman')}</option>
            <option value='other'>{t('onboard.employee_info.genders.Add my gender identity')}</option>
            <option value='prefer_not_to_say'>{t('onboard.employee_info.genders.Prefer not to say')}</option>
        </Select2>
        <div>
          {uiState.customGenderVisible &&
            <Input
              errorMessage={errors.customGender}
              label='onboard.employee_info.Gender'
              value={employee.customGender}
              onChange={(e) => employee.merge({customGender: e.target.value})}
              placeholder='onboard.employee_info.Optional'
            />
          }
        </div>
      </FormLayout.Group>
    </FormLayout>
  );
});

export default OnboardEmployeePersonalInformationForm;
