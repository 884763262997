import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {t, auth} from 'shared/core';
import {Panel, Toggle} from 'components';

const PrivacySettingsContainer = observer(({uiState}) => {
  const {user} = uiState;

  if (auth.company.enrolmentOnly) return null;

  return (
    <Panel>
      <Panel.Header title={t('user_settings.privacy_settings.Privacy Settings')}/>
      <div>
        <div className='table-cell align-middle'>
          <Toggle enabled={user.birthdaySharedInternally}
                  onChange={value => uiState.updateBirthdaySharedInternally(value)}/>
        </div>
        <div className='table-cell align-middle pl2'>
          <FormattedMessage
            id='user_settings.privacy_settings.Show my birthday to colleagues in calendars and the home page.'/>
        </div>
      </div>
    </Panel>
  );
});

export default PrivacySettingsContainer;
