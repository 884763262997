import {DomainObject, oneOf} from 'shared/store';
import {observable, action} from 'mobx';

class TypePolicyConfirmationSummary extends DomainObject {
  @observable nextExpectedAccrualDate;
  @observable policyStartDate;
  @observable accrualStart;
  @observable effectiveDateRequired;

  @action merge(other) {
    super.merge(other, {
      _dates: ['nextExpectedAccrualDate', 'policyStartDate'],
      accrualStart: oneOf(['policy_start_date', 'policy_holder_start_date'])
    });
  }
}

export default TypePolicyConfirmationSummary;
