import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {QuestionFieldFactory} from 'components/forms';

const JobApplicationQuestions = observer(({candidate, uiState}) => {
  const {errors} = uiState;

  return (
    <div>
      {candidate.sortedAnswers.map((answer, index) =>
        <div className='my3' key={answer.question.id}>
          <QuestionFieldFactory
            errorMessage={_.get(errors, `answers.${index}.content`)}
            answer={answer}
            required={uiState.calculateQuestionRequired(answer.question)}
            uiState={uiState}
            modelType={'ats/job_application_answer'}
          />
        </div>
      )}
    </div>
  );
});

export default JobApplicationQuestions;
