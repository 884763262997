import React from 'react';
import {observer} from 'mobx-react';
import ClientProfileContainer from '../../ClientProfileContainer';
import EnrolmentsContainerWithState from './EnrolmentsContainerWithState';

const EnrolmentsContainer = observer(({match, history, parentState}) => {
  return (
    <ClientProfileContainer {...{match, history, parentState}}>
      <EnrolmentsContainerWithState parentState={parentState} />
    </ClientProfileContainer>
  );
});

export default EnrolmentsContainer;
