const VENDORS = [
  {
    key: 'jazz_hr',
    localization: {
      name: 'integrations.vendors.jazz_hr.JazzHR',
      header: 'integrations.vendors.jazz_hr.JazzHR Hired Candidates'
    },
    endpoint: 'jazz_hr'
  },
  {
    key: 'greenhouse',
    localization: {
      name: 'integrations.vendors.greenhouse.Greenhouse',
      header: 'integrations.vendors.greenhouse.Greenhouse Hired Candidates',
    },
    endpoint: 'greenhouse'
  }
];

export default VENDORS;
