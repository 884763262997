import React from 'react';
import {FormattedMessage} from 'react-intl';
import {FormLayout, Panel, Input, ObjectSelect2, Select2, QuickTip, ContactUsLink} from 'components';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import EMPLOYMENT_TYPES from 'stores/employees/employmentTypes';

const IndeedLocationWarning = observer(({missingFields}) => {
  if (missingFields.length === 0) {
    return null;
  }

  return (
    <div className='col col-12 mb2'>
      <QuickTip
        trait='caution'
        header={t('recruiting.edit.steps.This posting might not appear properly on Indeed')}
        paragraphs={[
          <FormattedMessage
            id="recruiting.edit.steps.INDEED_LOCATION_WARNING"
            values={{contactUs: <ContactUsLink/>}}
          />,
          <FormattedMessage id="recruiting.edit.steps.INDEED_MISSING_FIELDS"/>,
          <div className='ml2'>
            <ul>
              {missingFields.map(field => <li>{field}</li>)}
            </ul>
          </div>
        ]}
      />
    </div>
  );
});

const Details = observer(({uiState}) => {
  const {position, errors} = uiState;
  return (
    <div>
      <Panel.Header title={t('recruiting.edit.steps.Job Details')}/>
      <FormLayout>
        <Input
          label='recruiting.edit.steps.Job Title'
          placeholder='recruiting.edit.steps.Add a posting title'
          errorMessage={errors.title}
          value={position.title || ''}
          onChange={e => position.title = e.target.value}
          className='large'
        />
        <ObjectSelect2
          label='recruiting.edit.steps.Location'
          className='large' value={position.location}
          onChange={value => position.location = value}
          items={uiState.locationsWithDeleted}
          placeholder='recruiting.edit.steps.Specify a location' errorMessage={errors.locationId}/>
        <IndeedLocationWarning missingFields={uiState.missingFields}/>
        <ObjectSelect2
          label='recruiting.edit.steps.Department'
          className='large'
          value={position.department}
          onChange={value => position.department = value}
          items={uiState.departmentsWithDeleted}
          placeholder='recruiting.edit.steps.Specify a department' errorMessage={errors.departmentId}/>
        <Select2
          label='recruiting.edit.steps.Type'
          className='large'
          value={position.employmentType || ''}
          onChange={e => position.employmentType = e.target.value}
          placeholder='recruiting.edit.steps.Specify a type' errorMessage={errors.employmentType}>
          {EMPLOYMENT_TYPES.map(type => <option key={type} value={type}>{t(type)}</option>)}
        </Select2>
      </FormLayout>
    </div>
  );
});

export default Details;
