import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {BirthdayPicker, Input, Select2, FormLayout} from 'components';
import {t} from 'shared/core';

const EditBasicInfoModal = injectIntl(observer(({uiState, intl}) => {
  const {editEmployee, errors} = uiState;

  return (
    <Modal isOpen={uiState.basicInfoModalOpen} onHide={() => uiState.resetModals()} size='md'>
      <div className='h2 mb3'><FormattedMessage id='employees.profile.personal.Edit Basic Information'/></div>
      <form>
        <FormLayout>
          <Input
            value={editEmployee.firstName}
            label='employees.profile.personal.First Name'
            errorMessage={errors.firstName}
            onChange={(e) => editEmployee.merge({firstName: e.target.value})}
          />
          <Input
            value={editEmployee.preferredName}
            label='employees.profile.personal.Preferred Name'
            tooltip={uiState.userIsEmployee && {
              paragraphs: [
                t('employees.profile.personal.If you would prefer not to have your legal name displayed to other employees in Collage and want to go by another name, add a preferred name.'),
                t('employees.profile.personal.This name will be the name you appear as in Collage, and only admins will be able to see your legal first name.')
              ]
            }}
            errorMessage={errors.preferredName}
            onChange={(e) => editEmployee.merge({preferredName: e.target.value})}
          />
          <Input
            value={editEmployee.middleName}
            label='employees.profile.personal.Middle Name'
            errorMessage={errors.middleName}
            onChange={(e) => editEmployee.merge({middleName: e.target.value})}
          />
          <Input
            value={editEmployee.lastName}
            label='employees.profile.personal.Last Name'
            errorMessage={errors.lastName}
            onChange={(e) => editEmployee.merge({lastName: e.target.value})}
          />
          <Input
            value={editEmployee.preferredLastName}
            label='employees.profile.personal.Preferred Last Name'
            errorMessage={errors.preferredLastName}
            onChange={(e) => editEmployee.merge({preferredLastName: e.target.value})}
          />
          <Select2
            value={editEmployee.gender}
            label='employees.profile.personal.Gender Identity'
            onChange={(e) => editEmployee.merge({gender: e.target.value})}
          >
            <option value='female'>{intl.formatMessage({id: 'models.employee.gender.female'})}</option>
            <option value='male'>{intl.formatMessage({id: 'models.employee.gender.male'})}</option>
            <option value='other'>{intl.formatMessage({id: 'employees.profile.personal.Add my gender identity'})}</option>
            <option value='prefer_not_to_say'>{intl.formatMessage({id: 'models.employee.gender.prefer_not_to_say'})}</option>
          </Select2>
          {uiState.customGenderVisible &&
            <Input
              value={editEmployee.customGender}
              label='employees.profile.personal.Gender'
              errorMessage={errors.customGender}
              onChange={(e) => editEmployee.merge({customGender: e.target.value})}
            />
          }
          <BirthdayPicker
            minAge={14} maxAge={99}
            value={editEmployee.birthDate}
            label='employees.profile.personal.Birthdate'
            errorMessage={errors.birthDate}
            options={{startView: 2}}
            onChange={(date) => editEmployee.merge({birthDate: date})}
          />
        </FormLayout>
        <ModalButtons onSave={() => uiState.saveBasicInfo()} onCancel={() => uiState.resetModals()} />
      </form>
    </Modal>
  );
}));

export default EditBasicInfoModal;
