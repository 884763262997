import {observable, action, computed} from 'mobx';
import {BenefitClass} from 'stores/benefits';

class EnrolmentViewModel {
  @observable enrolment;
  @observable oldBenefitClass;

  constructor(enrolment) {
    this.enrolment = enrolment;
    this.oldBenefitClass = new BenefitClass(enrolment.benefitClass);
  }

  @action updateBenefitClass(benefitClass) {
    this.enrolment.merge({benefitClass, changeEffectiveDate: new Date()});
  }

  @computed get enrolmentChanged() {
    return this.oldBenefitClass.id !== this.enrolment.benefitClass.id;
  }
}

export default EnrolmentViewModel;
