import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import AddNewModelLink from './AddNewModelLink';
import CustomTableActionLinks from 'components/tables/components/CustomTableActionLinks';

const EmptyState = observer(({uiState, localization, customTableActionLinks}) => {
  const message = localization.emptyState || 'No records to display';

  return (
    <div className='Table'>
      <div className='center jumbo rounded h5 py2 px1 Table-emptyState'>
        <FormattedMessage id={message}/>
      </div>
      <AddNewModelLink uiState={uiState} text={localization.addModel}/>
      <CustomTableActionLinks customTableActionLinks={customTableActionLinks} />
    </div>
  );
});

export default EmptyState;
