import React from 'react';
import {FormattedMessage} from 'react-intl';
import {QuickTip} from 'components';

const NotAuthorized = () => {
  return (
    <div className='m2'>
      <QuickTip
        trait='caution'
        header={<FormattedMessage id='components.not_authorized.Access Denied.'/>}
        paragraphs={[
          <FormattedMessage id="components.not_authorized.You don't have permission to view this page. Contact your administrator to request access."/>
        ]}
      />
    </div>
  );
};

export default NotAuthorized;
