import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {BenefitInfoContainerState} from '../state';
import {Panel} from 'components';
import BeneficiaryAssignment from './BeneficiaryAssignment';
import Dependants from './Dependants';
import PersonalDetails from './PersonalDetails';
import MyEnrolmentInfo from './MyEnrolmentInfo';
import Spouse from './Spouse';

const BenefitInfoContainer = observer(({uiState, showAdminForm, showBeneficiaryAssignment}) => {
  return (
    <Panel.Stack loose>
      <Panel.Stack>
        <MyEnrolmentInfo uiState={uiState}/>
        <PersonalDetails uiState={uiState}/>
        <Spouse uiState={uiState}/>
        <Dependants uiState={uiState}/>
      </Panel.Stack>
      {showBeneficiaryAssignment && (uiState.editable || uiState.isSelf) && <BeneficiaryAssignment uiState={uiState}/>}
    </Panel.Stack>
  );
});

BenefitInfoContainer.defaultProps = {
  showAdminForm: true,
  showBeneficiaryAssignment: true
};

export default withState(BenefitInfoContainer, BenefitInfoContainerState);
