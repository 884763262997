import React from 'react';
import {t} from 'shared/core';
import {NavLink} from 'react-router-dom';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Display} from 'components';

const Subnav = observer(({scope}) => {
  return (
    <Display title={t('performance_reviews.Performance reviews')}>
      {scope.reviews && <NavLink activeClassName='active' to='/reviews'>
        <FormattedMessage id='performance_reviews.Cycles'/>
      </NavLink>}
      {scope.reviewees && <NavLink activeClassName='active' to='/reviewees'>
        <FormattedMessage id='performance_reviews.Reviews'/>
      </NavLink>}
      {scope.templates && <NavLink activeClassName='active' to='/question_sets'>
        <FormattedMessage id='performance_reviews.Question Sets'/>
      </NavLink>}
      {scope.performanceTrends && <NavLink activeClassName='active' to='/performance_trends'>
        <FormattedMessage id='performance_reviews.Performance Trends'/>
      </NavLink>}
    </Display>
  );
});

export default Subnav;
