import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Table, EmployeeNameWithAvatar} from 'components';
import {t} from 'shared/core';
import CompletedIcon from 'img/list-element-complete@2x.png';
import IncompleteIcon from 'img/list-element-incomplete-default@2x.png';

const StatusCheckmark = observer(({submitted}) => {
  return (
    <div className='center'>
      <img src={submitted ? CompletedIcon : IncompleteIcon} width='18' alt='complete'/>
    </div>
  );
});

const COLUMNS = [
  {
    header: 'performance_reviews.view.Name',
    width: 8,
    render: model => <EmployeeNameWithAvatar employee={model.reviewer}/>
  },
  {
    header: 'performance_reviews.view.Submitted',
    width: 4,
    render: model => <StatusCheckmark submitted={model.submitted} />
  }
];

const ReviewList = observer(({uiState}) => {
  const {sortedReviews} = uiState;

  return (
    <Panel className='clearfix print-hide'>
      <Panel.Header title={t('performance_reviews.view.Reviewers')} />
      <Table
        columns={COLUMNS}
        models={sortedReviews}
        showLinks={false}
        indexBy='reviewer.id'
        onRowClicked={model => uiState.scrollToReview(model)}
      />
    </Panel>
  );
});

export default ReviewList;
