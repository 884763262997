import {DomainStore} from 'shared/store';
import {action} from 'mobx';
import {endpoints, types} from 'shared/core';

class CyclePageState {
  store = new DomainStore();
  scope;

  @action async load() {
    await this.store._compose([
      endpoints.PERFORMANCE.CYCLE_NAVIGATION
    ]);

    this.scope = this.store._getSingle(types.NAVIGATION_SCOPE).tabs;
  }
}

export default CyclePageState;
