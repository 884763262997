import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints} from 'shared/core';
import {Company} from 'stores/company';

class GreenhouseConfigurationContainerState {
  store = new DomainStore();
  @observable company;
  @observable errors = {};

  @observable deleteModalOpen = false;

  @action async load() {
    await this.store._compose([
      endpoints.CURRENT_COMPANY
    ]);

    this.company = new Company(
      this.store._getSingle(types.COMPANY)
    );
  }

  @action openDeleteModal() {
    this.deleteModalOpen = true;
  }

  @action cancelDeleteModal() {
    this.deleteModalOpen = false;
  }

  @action async generateGreenhouseKey() {
    const {errors, model} = await this.store.post(
      endpoints.INTEGRATIONS.GREENHOUSE_GENERATE_KEY,
      types.COMPANY
    );

    this.errors = errors;

    if (model) {
      this.company.update(model);
    }
  }

  @action async deleteGreenhouseKey() {
    const {errors, model} = await this.store.post(
      endpoints.INTEGRATIONS.GREENHOUSE_DELETE_KEY,
      types.COMPANY
    );

    this.errors = errors;

    if (model) {
      this.company.update(model);
    }

    this.deleteModalOpen = false;
  }

  @computed get webhookUrl() {
    return `https://secure.collage.co/api/webhooks/greenhouse_hires/${this.company.id}/hire`;
  }

  @computed get greenhouseApiKey() {
    return this.company.greenhouseApiKey;
  }
}

export default GreenhouseConfigurationContainerState;
