import {DomainObject, oneOf} from 'shared/store';
import {observable, action} from 'mobx';
import _ from 'lodash';

class MFAUser extends DomainObject {
  @observable cellphone = '';
  @observable countryCode = '1';
  @observable mfaToken;
  @observable mfaType;
  @observable mfaParameter;

  @action merge(other) {
    super.merge(other, {
      mfaType: oneOf(['sms', 'authenticator'])
    });
  }

  toJS() {
    const json = super.toJS();
    json['mfaPhoneNumber'] = `+${json['countryCode']}${json['cellphone']}`;

    return _.omit(json, ['cellphone', 'countryCode']);
  }
}

export default MFAUser;
