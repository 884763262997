import React from 'react';
import {observer} from 'mobx-react';
import ChooseFields from './choose_fields';
import FilterResults from './filter_results';
import OrderFields from './order_fields';
import Review from './review';

@observer class Step extends React.Component {
  render() {
    const {step, match, uiState} = this.props;

    switch (step) {
      case 'fields':
        return <ChooseFields uiState={uiState}/>;
      case 'reorder':
        return <OrderFields uiState={uiState}/>;
      case 'filters':
        return <FilterResults uiState={uiState}/>;
      case 'review':
        return <Review match={match} uiState={uiState}/>;
      default:
        throw new Error(`Location ${step} is not supported.`);
    }
  }
}

export default Step;
