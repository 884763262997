import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import {Input, Select2, FormError} from 'components';

const FormRow = observer(({name, className, children}) => {
  return (
    <div className={`${className ? className : 'my3'}`}>
      <div className='h4 medium mb1'>
        <FormattedMessage id={name}/>
      </div>
      {children}
    </div>
  );
});

const EditLocationModal = observer(({uiState}) => {
  const {editingLocation, countries, locationRegions, errors} = uiState;

  return (
    <Modal isOpen={uiState.editLocationModalOpen} onHide={() => uiState.closeEditLocationModal()} size='md'>
      <div className='h2 medium mb3'>
        <FormattedMessage id='company_settings.general.Edit Location Information'/>
      </div>
      <form>
        <FormRow name='company_settings.general.Name'>
          <Input value={editingLocation.name}
                 errorMessage={errors.name}
                 onChange={(e) => editingLocation.merge({name: e.target.value})}
           />
        </FormRow>
        <FormRow name='company_settings.general.Address'>
          <Input value={editingLocation.address}
                 errorMessage={errors.address}
                 onChange={(e) => editingLocation.merge({address: e.target.value})}
           />
        </FormRow>
        <FormRow name='company_settings.general.Country'>
          <Select2 value={editingLocation.countryCode}
                   onChange={(e) => { editingLocation.updateCountry(e.target.value); uiState.refreshRegionsForLocation(e.target.value); }}
                   searchable={true}>
            {countries.map((c) => <option key={c.id} value={c.code}>{c.name}</option>)}
          </Select2>
          <FormError message={errors.countryCode}/>
        </FormRow>
        <FormRow name='company_settings.general.Region/Province'>
          <Select2 value={editingLocation.regionCode}
                   onChange={(e) => editingLocation.merge({regionCode: e.target.value})}
                   searchable={true}>
            {locationRegions && locationRegions.map((r) => <option key={r.id} value={r.code}>{r.name}</option>)}
          </Select2>
          <FormError message={errors.regionCode}/>
        </FormRow>
        <FormRow name='company_settings.general.City'>
          <Input value={editingLocation.city}
                 errorMessage={errors.city}
                 onChange={(e) => editingLocation.merge({city: e.target.value})}
           />
        </FormRow>
        <FormRow name='company_settings.general.Postal Code'>
          <Input value={editingLocation.postalCode}
                 errorMessage={errors.postalCode}
                 onChange={(e) => editingLocation.merge({postalCode: e.target.value})}
           />
        </FormRow>
        <ModalButtons onSave={() => uiState.saveLocation()} onCancel={() => uiState.closeEditLocationModal()}/>
      </form>
    </Modal>
  );
});

export default EditLocationModal;
