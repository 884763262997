import {DomainObject} from 'shared/store';
import {observable, action} from 'mobx';

class FeaturePermission extends DomainObject {
  @observable id;
  @observable name;
  @observable enabled;

  @action toggle() {
    this.enabled = !this.enabled;
  }
}

export default FeaturePermission;
