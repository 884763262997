import React from 'react';
import {observer} from 'mobx-react';
import {Input} from 'components';
import StyleGuideTitle from './StyleGuideTitle';
import StyleGuideExample from './StyleGuideExample';
import StyleGuideParameterRows from './StyleGuideParameterRows';

const PARAMS = [
  { name: 'value*', type: 'string', description: 'Value that the input field is capturing', values: 'Ex: domainObject.value' },
  { name: 'className', type: 'string', description: 'Additional classNames to style the button', values: 'Ex: mx-auto, half-width' },
  { name: 'errorMessage', type: 'string', description: 'Form errors associated with this field', values: 'Ex: errors.fieldName' },
  { name: 'inputRef', type: 'function()', description: 'Callback which allows you to set a reference to the input field', values: 'Ex: (input) = this.reference = input' },
  { name: 'label', type: 'string', description: 'Add a label to appear above your text input', values: 'Ex: First Name' },
  { name: 'onChange*', type: 'function()', description: 'Callback which updates the value', values: 'Ex: domainObject.merge({field: e.target.value})' },
  { name: 'placeholder', type: 'string', description: 'Placeholder value for the text field', values: 'Ex: placeholder.translation, 10,000' },
  { name: 'translatePlaceholder', type: 'boolean', description: 'Sets whether placeholder should be translated', values: '[true, false]' },
  { name: 'type', type: 'enum', description: 'Determines the different types of text fields', values: '[text], default: text' }
];

const StyleGuideTextInput = observer(({uiState}) => {
  return (
    <div>
      <StyleGuideTitle title='Text Fields' description='Here are the different ways to use text fields in our app with examples using errors, icons and dropdown lists.'/>

      <div className='bg-white box-shadow-1 rounded p4 my3'>
        <StyleGuideExample title='Default Text Field (with label)' description='This is the default text field used to capture a single line of text.'>
          <Input value={uiState.defaultInputValue} placeholder='E.g. John Doe' translatePlaceholder={false} onChange={e => uiState.defaultInputValue = e.target.value} label='First Name'/>
        </StyleGuideExample>

        <StyleGuideExample title='Disabled Text Field (with help message)' description='This is a disabled text field used for read-only values.'>
          <Input value='Read only values' onChange={e => uiState.value = e.target.value} helpMessage="Here is the help message" disabled/>
        </StyleGuideExample>

        <StyleGuideExample title='Text Field with errors' description='This is a text field displaying errors associated to the value.'>
          <Input value={uiState.inputWithErrorValue} onChange={e => uiState.inputWithErrorValue = e.target.value} errorMessage='This field is mandatory'/>
        </StyleGuideExample>

        <div className='mb3'>
          <div className='h2 medium mb2'>{'Parameters'}</div>
          <div className='clearfix mb1'>
            <div className='col col-3 pr1 h5 caps'>{'Name'}</div>
            <div className='col col-2 pr1 h5 caps'>{'Type'}</div>
            <div className='col col-4 pr1 h5 caps'>{'Description'}</div>
            <div className='col col-3 pl1 h5 caps'>{'Values'}</div>
          </div>
          <ul className='StyleGuide-params list-reset'>
            {PARAMS.map((p, i) => <StyleGuideParameterRows key={i} index={i} {...p}/>)}
          </ul>
        </div>
      </div>
    </div>
  );
});

import {observable} from 'mobx';
class State {
  @observable defaultInputValue = '';
  @observable inputWithErrorValue = '';
}

export default () => {
  const uiState = new State();
  return <StyleGuideTextInput uiState={uiState} />;
};
