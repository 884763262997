import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import _ from  'lodash';
import FilterComponent from './FilterComponent';

const Stars = ({count}) => {
  return (
    <div className='flex h2'>
      {_.times(5, (i) => (
        <div key={i} className={count >= i + 1 ? 'sun' : 'waterloo'} style={{paddingRight: '1px'}}>{'\u2605'}</div>
      ))}
    </div>
  );
};

const CandidateRatings = observer(({filter, filterKey, filterUpdated, filterDeleted, Wrapper}) => {
  const options = [
    { pillTitle: t('components.tables.fancy_filters.selectors.1 Star'), label: <Stars count={1}/>, value: '1' },
    { pillTitle: t('components.tables.fancy_filters.selectors.2 Stars'), label: <Stars count={2}/>, value: '2' },
    { pillTitle: t('components.tables.fancy_filters.selectors.3 Stars'), label: <Stars count={3}/>, value: '3' },
    { pillTitle: t('components.tables.fancy_filters.selectors.4 Stars'), label: <Stars count={4}/>, value: '4' },
    { pillTitle: t('components.tables.fancy_filters.selectors.5 Stars'), label: <Stars count={5}/>, value: '5' }
  ];

  return <FilterComponent filter={filter} filterKey={filterKey} options={options} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
});

export default CandidateRatings;
