import React from 'react';
import {observer} from 'mobx-react';
import Name from './Name';
import AnonymousField from './AnonymousField';

const General = observer(({uiState}) => {
  return (
    <div>
      <Name uiState={uiState}/>
      <AnonymousField uiState={uiState}/>
    </div>
  );
});

export default General;
