const initialSteps = [
  {
    name: 'time_off.policy.edit.steps.Policy Details',
    location: 'details'
  },
  {
    name: 'time_off.policy.edit.steps.Time Off Types',
    location: 'policy_types'
  }
];

const finalSteps = [
  {
    name: 'time_off.policy.edit.steps.Add Employees',
    location: 'add_employees'
  },
  {
    name: 'time_off.policy.edit.steps.Review',
    location: 'review'
  }
];

const defaultTypePolicyStep = {
  name: 'time_off.policy.edit.steps.Time Off Rules',
  location: 'time_off_rules'
};

export { initialSteps, defaultTypePolicyStep, finalSteps };
