import React from 'react';
import {observer} from 'mobx-react';
import {t, withState} from 'shared/core';
import {UserSettingsState} from 'containers/users/settings/state';
import {Display, Layout, Panel} from 'components';
import {
  EmailPreferencesContainer,
  MultiFactorAuthenticationContainer,
  PrivacySettingsContainer,
  SlackIntegration,
  ToggleOAuth
} from 'containers/users/settings';
import AccountPreferences from 'containers/users/settings/components/AccountPreferences';
import Password from 'containers/users/settings/components/Password';

const UsersSettingsContainer = observer(({uiState}) => {
  return (
    <div>
      <Display title={t('user_settings.Personal Settings')}/>
      <Layout>
        <Layout.Section>
          <Panel.Stack loose>
            <AccountPreferences uiState={uiState}/>
            <Password uiState={uiState}/>
            <ToggleOAuth uiState={uiState}/>
            <MultiFactorAuthenticationContainer uiState={uiState}/>
            <SlackIntegration uiState={uiState}/>
            <EmailPreferencesContainer uiState={uiState}/>
            <PrivacySettingsContainer uiState={uiState}/>
          </Panel.Stack>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(UsersSettingsContainer, UserSettingsState);
