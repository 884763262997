import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const DeleteModal = observer(({uiState}) => {
  if (!uiState.deleteEnabled) return null;
  const {title, header, subHeader, body} = uiState.localization.removeModal;

  return (
    <AlertModal 
      size='sm' 
      mode='danger' 
      title={title}
      header={header}
      subHeader={subHeader}
      body={body}
      isOpen={uiState.deleteModalOpen} 
      onHide={() => uiState.closeDeleteModal()}>
      <ModalDangerButtons 
        onSave={() => uiState.confirmDeletion()}
        onCancel={() => uiState.closeDeleteModal()}/>
    </AlertModal>
  );
});

export default DeleteModal;
