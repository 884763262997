import {observable, action} from 'mobx';
import qs from 'qs';
/*global window*/

class SubscriptionState {
  location;
  history;

  @observable redirecting = false;
  @observable origin;

  constructor(location, history, store) {
    this.location = location;
    this.history = history;

    this.origin = this.getOrigin();
  }

  @action onPaymentFormSubmitted() {
    this.redirecting = true;
    window.location = `/company_settings/billing/subscription/confirmation/?back=${this.origin}`;
  }

  getOrigin() {
    const origin = qs.parse(this.location.search.replace('?', ''))['back'];
    return origin || '/company_settings/billing/';
  }
}

export default SubscriptionState;
