import React from 'react';
import {observer} from 'mobx-react';
import {EmployeeNameWithAvatar} from 'components';
import {t} from 'shared/core';

const CandidateDirectoryName = observer(({model, uiState}) => {
  const candidate = model;
  const tags = [];
  if (candidate.hired) {
    tags.push({
      content: t('recruiting.candidate_directory.Hired'),
      colour: 'meadow'
    });
  }

  if (candidate.disqualified) {
    const content = candidate.autoDisqualified
      ? t('recruiting.candidate_directory.Auto-Disqualified')
      : t('recruiting.candidate_directory.Disqualified');

    tags.push({
      content: content,
      colour: 'scarlet'
    });
  }

  return (
    <div>
      <EmployeeNameWithAvatar
        employee={candidate}
        details={[candidate.email]}
        href={uiState.pathForCandidate(candidate)}
        tags={tags}
      />
    </div>
  );
});

export default CandidateDirectoryName;
