import React from 'react';
import {observer} from 'mobx-react';
import MultipleChoiceForm from './MultipleChoiceForm';
import QuizForm from './QuizForm';
import SentimentForm from './SentimentForm';
import AttachmentForm from './AttachmentForm';
import EmptyForm from './EmptyForm';

const QuestionTypeFormFactory = observer(({question, ...rest}) => {
  switch (question.questionType) {
    case 'text_field':
    case 'text_area':
    case 'date':
    case 'description':
      return <EmptyForm question={question} />;
    case 'attachment':
      return <AttachmentForm question={question} {...rest}/>;
    case 'multiple_choice':
    case 'multi_select':
      return <MultipleChoiceForm question={question} />;
    case 'quiz':
      return <QuizForm question={question} />;
    case 'sentiment':
    case 'scored':
      return <SentimentForm question={question}/>;
    default:
      throw new Error(`Question type: ${question.questionType} not supported`);
  }
});

export default QuestionTypeFormFactory;
