import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import LabelHelper from './LabelHelper';
import InputError from './InputError';
import {noop} from 'shared/tools';
import ErrorIcon from 'img/error-field@2x.png';
import {t} from 'shared/core';
import {scrollsIntoViewIfErrors} from 'shared/tools';
import HelpLink from './links/HelpLink';
/*global $ */

const Select2Helper = observer(({helpMessage, translateHelpMessage, errorMessage}) => {
  if (errorMessage) {
    return (
      <div className='relative'>
        <img src={ErrorIcon} alt='error' height='16' className='inline-block' style={{marginRight: '4px'}}/>
        <InputError className='inline-block' message={errorMessage} />
      </div>
    );
  }

  if (helpMessage) {
    return (
      <div style={{color: '#637381', paddingTop: '4px'}}>
        {translateHelpMessage ? <FormattedMessage id={helpMessage} /> : helpMessage}
      </div>
    );
  }

  return null;
});

class Select2 extends React.Component {
  handleSelect = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  handleClear = () => {
    if (this.props.onChange) {
      this.props.onChange({target: {value: null}});
    }
  }

  handleSelecting = (e) => {
    if (this.props.onSelecting) {
      this.props.onSelecting(e);
    }
  }

  componentDidMount() {
    const input = $(this.select2);
    let options = {width: '100%', dropdownAutoWidth : true};

    const {allowClear, dropdownClass, searchable, hideSelected, placeholder, translatePlaceholder, allowTagCreation} = this.props;

    options.placeholder = '- -';
    options.allowClear = allowClear;

    if (allowTagCreation) {
      options.tags = true;
    }

    if (placeholder) {
      if (translatePlaceholder) {
        options.placeholder = t(placeholder);
      } else {
        options.placeholder = placeholder;
      }
    }

    options.dropdownCssClass = dropdownClass;
    if (searchable) {
      options.dropdownCssClass = 'select2-animation';
    }
    if (hideSelected) {
      options.dropdownCssClass += ' select2-hide-selected';
      options.containerCssClass = 'select2-hide-selected';
    }
    if (!searchable) {
      options.minimumResultsForSearch = -1;
    }
    options.language = {
      noResults: () => {
        return t('components.select2.No results found');
      }
    };

    input.on('select2:selecting', this.handleSelecting);
    input.select2 && input.select2(options);
    if (this.props.multiple) {
      input.on('select2:select', this.handleSelectMultiple);
      input.on('select2:unselect', this.handleClearMultiple);
    } else {
      input.on('select2:select', this.handleSelect);
      input.on('select2:unselect', this.handleClear);
    }
  }

  handleSelectMultiple = (e) => {
    if (this.props.onChange) {
      this.props.onChange({action: 'add', value: e.params.data.id});
      e.preventDefault();
    }
  };

  handleClearMultiple = (e) => {
    if (this.props.onChange) {
      this.props.onChange({action: 'remove', value: e.params.data.id});
      e.preventDefault();
    }
  };

  componentDidUpdate() {
    $(this.select2).trigger('change.select2');
  }

  componentWillReceiveProps() {
    $(this.select2).trigger('change.select2');
  }

  componentWillUnmount() {
    const input = $(this.select2);
    input.select2 && input.select2('destroy');
    input.off('select2:select', this.handleSelect);
  }

  render() {
    const {
      children,
      value,
      className,
      disabled,
      errorMessage,
      helpMessage,
      translateHelpMessage,
      label,
      translateLabel,
      multiple,
      helpLink,
      action,
      optional
    } = this.props;

    return (
      <div className={errorMessage ? 'Error-field Select2--hasErrors' : ''}>
        <LabelHelper label={translateLabel && label ? t(label) : label} action={action} optional={optional}>
          {helpLink && <HelpLink title={helpLink.title} explanation={helpLink.explanation}/>}
        </LabelHelper>
        <select value={(value || value === 0) ? value : ''}
                onChange={noop}
                ref={(input) => { this.select2 = input; }}
                className={className}
                multiple={multiple ? 'multiple' : ''}
                name={this.props.name} disabled={disabled}>
          <option></option>
          {children}
        </select>
        <Select2Helper
          translateHelpMessage={translateHelpMessage}
          helpMessage={helpMessage}
          errorMessage={errorMessage}
        />
      </div>
    );
  }
}

Select2.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onSelecting: PropTypes.func,
  searchable: PropTypes.bool,
  allowTagCreation: PropTypes.bool
};

Select2.defaultProps = {
  translatePlaceholder: true,
  translateLabel: true,
  optional: false,
  translateHelpMessage: true,
  allowTagCreation: false
};

export default scrollsIntoViewIfErrors(Select2, '.Select2--hasErrors');
