import React from 'react';
import {observer} from 'mobx-react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {EmployeesContainer} from './components/employees';
import {UploadsContainer} from './components/payroll_uploads';
import {PayPeriodsContainer, PayPeriodEmployeesContainer, EmployeeDiffsContainer} from './components/payroll_updates';

const PayrollEnabledSwitch = observer(({scope}) => {
  return (
    <Switch>
      {scope.updates && <Route exact path='/updates' render={() => <PayPeriodsContainer {...{scope}}/>}/>}
      {scope.updates && <Route path='/updates/pay_period/:period_id?/employee/:employee_id?' component={EmployeeDiffsContainer}/>}
      {scope.updates && <Route path='/updates/pay_period/:period_id?' component={PayPeriodEmployeesContainer}/>}
      {scope.uploads && <Route path='/uploads' render={() => <UploadsContainer {...{scope}}/>}/>}
      {scope.employees && <Route path='/employees' render={() => <EmployeesContainer {...{scope}}/>}/>}
      <Redirect exact from='/' to='/updates'/>
    </Switch>
  );
});

export default PayrollEnabledSwitch;

