import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import Signature from 'signature_pad/dist/signature_pad.js';
import ActionLink from './links/ActionLink';

@observer class SignaturePad extends React.Component {
  canvas;
  signature;

  componentDidMount() {
    const wrapper = document.getElementById('signature-pad');
    this.canvas = wrapper.querySelector('canvas');

    window.onresize = this.__resizeCanvas;
    this.__resizeCanvas(this.props.width, this.props.height);

    this.signature = new Signature(this.canvas, {
      onEnd: () => this.props.onChange(this.signature.toDataURL('image/png'))
    });

    if (this.props.signatureData) {
      this.signature.fromDataURL(this.props.signatureData, {width: this.canvas.width, height: this.canvas.height});
    }
  }

  __resizeCanvas(width, height) {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);

    if (this.canvas) {
      this.canvas.getContext('2d').scale(ratio, ratio);
      this.canvas.width = width || 540;
      this.canvas.height = height || 200;
    }
  }

  clear = () => {
    this.signature.clear();
    this.props.onChange('');
  }

  render() {
    const {width, height, clearStyle} = this.props;

    return (
      <div className='relative bg-haze border' style={{width: width || '540px', height: height || '200px'}}>
        <div style={{pointerEvents: 'none', userSelect: 'none'}} className='absolute bottom-0 left-0 right-0 mb3 px3'>
          <div className='jumbo border-bottom'>{'x'}</div>
          <div className='jumbo center'>
            <FormattedMessage id='components.signature_pad.Click and drag to sign'/>
          </div>
        </div>

        <div id='signature-pad' className={`wrapper ${clearStyle}-align`}>
          <canvas style={{userSelect: 'none'}} className='signature-pad'></canvas>
          <ActionLink className='h5 caps dodger' onClick={() => this.clear()}>
            <FormattedMessage id='components.signature_pad.Clear' />
          </ActionLink>
        </div>
      </div>
    );
  }
}

SignaturePad.defaultProps = {
  clearStyle: 'left'
};

export default SignaturePad;
