import React from 'react';
import {loader, withDisplay, t} from 'shared/core';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {ActionLink, Panel, Label, Button} from 'components';
import CompanySettingsState from '../state/CompanySettingsState';
import companyStore from 'stores/company/CompanyStore';
import countryStore from 'stores/countries/CountryStore';
import EditCompanyModal from './EditCompanyModal';
import SubNav from 'company_settings/SubNav';
import LocationsTable from 'containers/company_settings/general/components/LocationsTable';

const Image = observer(({uiState, logoUrl}) => {
  return (
    <img
      className='block border--dashed mt2 mb1 cover'
      src={logoUrl}
      height={85}
      alt={t('company_settings.general.Company Logo')}
      role='presentation'
      onClick={() => uiState.uploadLogo()}
    />
  );
});

const NoImage = ({uiState}) => {
  return (
    <div className='border--dashed rounded p4 lil-square' onClick={() => uiState.uploadLogo()}></div>
  );
};

const GeneralSettingsContainer = observer(({uiState}) => {
  const {company} = uiState;

  return (
    <div className='Container mb4'>
      <div className="clearfix">
        <div className="col-12 lg-col-10">
          <Panel>
            <Panel.Header title={t('company_settings.general.Company Information')}>
              <ActionLink onClick={() => uiState.openEditCompanyModal()}>
                <FormattedMessage id='company_settings.general.Edit'/>
              </ActionLink>
            </Panel.Header>
            <div className='clearfix'>
              <div className='col-12 lg-col-10'>
                <div className='clearfix mxn1 pb2'>
                  <div className='col col-6 px1'>
                    <FormattedMessage id='company_settings.general.Company Name'/>
                  </div>
                  <div className='col col-6 px1'>
                    <Label model={company} path='name'/>
                  </div>
                </div>
                <div className='clearfix mxn1'>
                  <div className='col col-6 px1'>
                    <FormattedMessage id='company_settings.general.Company Phone Number'/>
                  </div>
                  <div className='col col-6 px1'>
                    <Label model={company} path='phoneNumber'/>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </div>
      </div>
      <div className="clearfix">
        <div className="col-12 lg-col-10 mt3">
          <Panel>
            <Panel.Header title={t('company_settings.general.Company Logo')}/>
            <div className='h6 jumbo'>
              <FormattedMessage id='company_settings.general.LOGO_USAGE'/>
            </div>
            <div className='h6 jumbo mb2'>
              <FormattedMessage id='company_settings.general.Please ensure your image is at least 50px in height. PNG file format is preferred.'/>
            </div>
            <div className='Logo-edit'>
              {company.logoUrl ?
                <Image uiState={uiState} logoUrl={company.logoUrl}/> :
                <NoImage uiState={uiState}/>}
            </div>
            <Button className='mt2' trait='default' size='sm' onClick={() => uiState.uploadLogo()}>
              <FormattedMessage id='company_settings.general.Upload Logo'/>
            </Button>
          </Panel>
        </div>
      </div>
      <div className="clearfix">
        <div className="col-12 lg-col-10 my3">
          <LocationsTable uiState={uiState}/>
        </div>
      </div>
      <EditCompanyModal uiState={uiState} />
    </div>
  );
});

async function load() {
  const uiState = new CompanySettingsState(companyStore, countryStore);
  await uiState.load();
  return {uiState};
}

export default withDisplay(loader(GeneralSettingsContainer, load), () => <SubNav title={t('company_settings.subnav.General Info')}/>);
