import {DomainObject} from 'shared/store';
import {action, computed, observable} from 'mobx';
import _ from 'lodash';

const BULK_EDIT_FIELDS = [
  'pay_rate',
  'manager'
];

class MassUpdate extends DomainObject {
  @observable effectiveDate;
  @observable comment;
  @observable status;
  @observable newValues = {};
  @observable includedFields = [];
  @observable bulkEditValues = {};
  @observable massUpdateType;

  @action merge(other) {
    super.merge(other, {
      _dates: ['effectiveDate'],
    });
  }

  @action addIncludedField(field) {
    if (!this.includedFields.includes(field)) {
      this.includedFields.push(field);
    }
  }

  @action removeIncludedField(field) {
    if (this.includedFields.includes(field)) {
      this.includedFields.remove(field);
    }
  }

  @computed get readOnly() {
    return this.status !== 'in_progress';
  }

  @computed get running() {
    return this.status === 'running';
  }

  @computed get complete() {
    return this.status === 'complete';
  }

  @computed get bulkEditFields() {
    return _.intersection(this.includedFields, BULK_EDIT_FIELDS);
  }
}

export default MassUpdate;
