import React from 'react';
import {observer} from 'mobx-react';
import {QuickTip, DatePicker, FormLayout, Panel, RadioButtonGroup, RadioButton, Input} from 'components';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';

const CoverageFields = observer(({benefitInfo, errors}) => {
  const {hasHealthCoverage, hasDentalCoverage} = benefitInfo;

  return (
    <div>
      <Panel.Header title={t('employee_benefits.benefit_infos.Coverage Details')}
        description={t('employee_benefits.benefit_infos.It is recommended to use the default coverage options unless you or one of your dependants are covered by duplicate benefits.')} />
      <FormLayout>
        {hasHealthCoverage &&
          <RadioButtonGroup name='healthCoverage'
            label='employee_benefits.benefit_infos.Healthcare coverage:'
            selectedValue={benefitInfo.healthCoverage}
            errorMessage={errors.healthCoverage}
            onChange={(name, value) => benefitInfo.merge({healthCoverage: value})}>
            <div className='waterloo mb1'>{benefitInfo.healthCoverageHelperText}</div>
            {benefitInfo.healthCoverageOptions.map(c =>
              <RadioButton key={c} className='block mb1' value={c}>
                <FormattedMessage id={`models.benefit_info.coverage_options.${c}`}/>
              </RadioButton>)}
          </RadioButtonGroup>
        }
        {hasDentalCoverage &&
          <RadioButtonGroup name='dentalCoverage'
            label='employee_benefits.benefit_infos.Dental coverage:'
            selectedValue={benefitInfo.dentalCoverage}
            errorMessage={errors.dentalCoverage}
            onChange={(name, value) => benefitInfo.merge({dentalCoverage: value})}>
            <div className='waterloo mb1'>{benefitInfo.dentalCoverageHelperText}</div>
            {benefitInfo.dentalCoverageOptions.map(c =>
              <RadioButton key={c} className='block mb1' value={c}>
                <FormattedMessage id={`models.benefit_info.coverage_options.${c}`}/>
              </RadioButton>)}
          </RadioButtonGroup>
        }
        <RadioButtonGroup name='hasCanadianVisaOrPermit'
          label='employee_benefits.benefit_infos.Are you in Canada on a work Visa/Permit?'
          selectedValue={benefitInfo.hasCanadianVisaOrPermit}
          errorMessage={errors.hasCanadianVisaOrPermit}
          onChange={(name, value) => benefitInfo.merge({hasCanadianVisaOrPermit: value})}>
          <RadioButton className='table-cell pr2' value={true}>{t('employee_benefits.benefit_infos.Yes')}</RadioButton>
          <RadioButton className='table-cell pl2' value={false}>{t('employee_benefits.benefit_infos.No')}</RadioButton>
        </RadioButtonGroup>
        {benefitInfo.hasCanadianVisaOrPermit && <DatePicker value={benefitInfo.visaOrPermitExpiryDate}
          onChange={date => benefitInfo.merge({visaOrPermitExpiryDate: date})}
          label='employee_benefits.benefit_infos.Work Visa/Permit Expiry Date'
          errorMessage={errors.visaOrPermitExpiryDate}
        />}
        <RadioButtonGroup name='hasProvincialHealthPlan'
          label='employee_benefits.benefit_infos.Are you covered under your Provincial Health Plan?'
          selectedValue={benefitInfo.hasProvincialHealthPlan}
          errorMessage={errors.hasProvincialHealthPlan}
          onChange={(name, value) => benefitInfo.merge({hasProvincialHealthPlan: value})}>
          <RadioButton className='table-cell pr2' value={true}>{t('employee_benefits.benefit_infos.Yes')}</RadioButton>
          <RadioButton className='table-cell pl2' value={false}>{t('employee_benefits.benefit_infos.No')}</RadioButton>
        </RadioButtonGroup>
        {benefitInfo.hasProvincialHealthPlan === false && <QuickTip trait='caution'
          header={t("employee_benefits.benefit_infos.Are you sure you're not covered?")}
          paragraphs={[
            <FormattedMessage id='employee_benefits.benefit_infos.health_plan_explanation_0'/>,
            <FormattedMessage id='employee_benefits.benefit_infos.health_plan_explanation_1'/>,
            <FormattedMessage id='employee_benefits.benefit_infos.health_plan_explanation_2'/>,
            <FormattedMessage id='employee_benefits.benefit_infos.health_plan_explanation_3'
              values={{support: <a href='mailto: support@collage.co'>{'support@collage.co'}</a>}}/>
          ]}
        />}
        <RadioButtonGroup name='taxExempt'
          label='employee_benefits.benefit_infos.Are you exempt from paying taxes?'
          selectedValue={benefitInfo.taxExempt}
          errorMessage={errors.taxExempt}
          onChange={(name, value) => benefitInfo.merge({taxExempt: value})}>
          <RadioButton className='table-cell pr2' value={true}>{t('employee_benefits.benefit_infos.Yes')}</RadioButton>
          <RadioButton className='table-cell pl2' value={false}>{t('employee_benefits.benefit_infos.No')}</RadioButton>
        </RadioButtonGroup>
        {benefitInfo.taxExempt &&
          <Input
            label='benefit_enrolment.INAC Number'
            helpLink={{
              explanation: {
                header: 'benefit_enrolment.INAC Number',
                paragraphs: [t('employee_benefits.benefit_infos.INAC_NUMBER_EXPLANATION')]
              }
            }}
            value={benefitInfo.inacNumber}
            onChange={e => benefitInfo.merge({inacNumber: e.target.value})}
            errorMessage={errors.inacNumber}
            type='number'
          />
        }
      </FormLayout>
    </div>
  );
});

export default CoverageFields;
