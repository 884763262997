import React from 'react';
import {endpoints, types} from 'shared/core';
import {observer} from 'mobx-react';
import FilterComponent from './FilterComponent';
import useFetchModels from 'shared/hooks/useFetchModels';

const CandidateTags = observer(({filter, filterKey, filterUpdated, filterDeleted, Wrapper}) => {
  const [models, isLoading] = useFetchModels(endpoints.RECRUITING.CANDIDATE_TAGS, types.RECRUITING.CANDIDATE_TAG);
  const options = models.map(tag => ({ label: tag.name, value: tag.id }));

  return <FilterComponent
    filter={filter}
    filterKey={filterKey}
    options={options}
    filterUpdated={filterUpdated}
    filterDeleted={filterDeleted}
    Wrapper={Wrapper}
    isLoading={isLoading}
    searchable
  />;
});

export default CandidateTags;
