import {DomainStore} from 'shared/store';
import _ from 'lodash';
import {endpoints, types} from 'shared/core';
import CompanyDocument from 'stores/documents/CompanyDocument';
import Signature from 'stores/signatures/Signature';
import Folder from 'stores/folders/Folder';
import {CustomField} from 'stores/custom_fields';

class CompanyDocumentStore extends DomainStore {
  async loadCompanyDocuments() {
    await this._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.COMPANY_DOCUMENTS.all
    ]);

    return this._getAll(types.COMPANY_DOCUMENT, CompanyDocument);
  }

  async loadCompanyDocument(id) {
    await this.__loadCompanyDocument(id);
    return this.__getCompanyDocument(id);
  }

  __getCompanyDocument(id) {
    const documents = this._getAll(types.COMPANY_DOCUMENT, CompanyDocument);
    return _.find(documents, { id: id });
  }

  async __loadCompanyDocument(id) {
    await this._compose([endpoints.COMPANY_DOCUMENT.with(id)]);
  }

  async postCompanyDocument(companyDocument) {
    return this.post(
      endpoints.COMPANY_DOCUMENTS.all,
      types.COMPANY_DOCUMENT,
      companyDocument
    );
  }

  async loadFolders() {
    await this._compose([endpoints.FOLDERS.ALL]);
    return this._getAll(types.FOLDER, Folder);
  }

  async loadCompanySignatures() {
    await this._compose([endpoints.COMPANY_SIGNATURES]);
    return this._getAll(types.COMPANY_SIGNATURE, Signature);
  }

  async loadCustomFields() {
    await this._compose([endpoints.CUSTOM_FIELDS.ALL]);
    return this._getAll(types.CUSTOM_FIELD, CustomField);
  }
}

const singleton = new CompanyDocumentStore();

export default singleton;
