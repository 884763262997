import React from 'react';
import {observer} from 'mobx-react';
import RenewSessionForm from './RenewSessionForm';
import RenewSessionState from 'login/state/RenewSessionState';
import SessionExpiring from './SessionExpiring';
import sessionStore from 'stores/users/SessionStore';

const RenewSessionContainer = observer(({uiState}) => {
  if (uiState.dead) return null;

  return (
    <div className='is-fading-in'>
      {uiState.isExpired && <div className='LoginOverlay is-fading-in'></div>}
      {uiState.isExpired && <div className='LoginForm'>
        <RenewSessionForm uiState={uiState}/>
      </div>}
      {!uiState.isExpired && <div className={`LoginSessionExpiring is-fading-in ${uiState.cancelPending ? 'is-fading-out-2' : ''}`}>
        <SessionExpiring uiState={uiState}/>
      </div>}
    </div>
  );
});

export default ({expiryDate}) => {
  const uiState = new RenewSessionState(sessionStore, expiryDate);
  return <RenewSessionContainer uiState={uiState}/>;
};
