import React from 'react';
import {noop} from 'shared/tools';
import {observer} from 'mobx-react';
import {RadioButtonGroup, RadioButton} from 'components';
import Description from './Description';

const SentimentField = observer(({answer, required, errorMessage, disabled, onChanged}) => {
  return (
    <div>
      <Description answer={answer} required={required} />
      <RadioButtonGroup
        className='RadioButtonGroup-scale mt2'
        selectedValue={answer.value}
        name={`${answer.question.order}_${answer.question.text}`}
        onChange={(_name, value) => {answer.updateValue(value); onChanged();}}
        errorMessage={errorMessage}
      >
        {answer.question.options.map((option, index) =>
          <RadioButton
            key={option.value}
            disabled={disabled}
            value={option.value}
          >
            <div className='Radio-button--scale'>
              <div className='Radio-button-number--scale'>{index + 1}</div>
              <div className='Radio-button-text--scale'>{option.value}</div>
            </div>
          </RadioButton>)
        }
      </RadioButtonGroup>
    </div>
  );
});

SentimentField.defaultProps = {
  onChanged: noop
};

export default SentimentField;
