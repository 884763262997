import React from 'react';
import {observer} from 'mobx-react';
import {MarketplaceContainerState} from '../state';
import {Layout, Display, Panel, Button, Spinner} from 'components';
import {FormattedMessage} from 'react-intl';
import useModal from 'shared/hooks/useModal';
import {withState, t} from 'shared/core';
import _ from 'lodash';
import PopularAppModal from './PopularAppModal';
import ZapierPill from 'img/integrations/zapier-logo@2x.png';

const SearchBox = observer(({uiState}) => {
  const {search} = uiState;

  return (
    <Panel className='mb3'>
      <input
        type='search'
        className='large'
        defaultValue={search}
        placeholder={t('integrations.Search for an app')}
        onChange={_.debounce(e => uiState.searchApps(e.target.value), 300)}
      />
    </Panel>
  );
});

const Pagination = observer(({uiState}) => {
  const {nextEnabled, prevEnabled, startEntryIndex, endEntryIndex, totalResults} = uiState;

  return (
    <div className='flex align-items-middle justify-content-end mt2 jumbo'>
      <div className='mr1'>
        <FormattedMessage
          id='components.tables.PAGINATION_INFO'
          values={{
            start: startEntryIndex,
            end: endEntryIndex,
            total: totalResults
          }}
        />
      </div>
      <Button trait='default' className='PaginationBtn--left Btn--icon' onClick={() => uiState.prevPage()} disabled={!prevEnabled}>
        <i className="material-icons">{'arrow_drop_up'}</i>
      </Button>
      <Button trait='default' className='PaginationBtn--right Btn--icon' onClick={() => uiState.nextPage()} disabled={!nextEnabled}>
        <i className="material-icons">{'arrow_drop_up'}</i>
      </Button>
    </div>
  );
});

const EmptyState = observer(() => {
  return (
    <Panel>
      <div className='center jumbo rounded h5 py2 px1 mt1 Table-emptyState'>
        <FormattedMessage id={'integrations.No results found'}/>
      </div>
    </Panel>
  );
});

const PopularApp = observer(({app, uiState}) => {
  const onClick = () => {
    app.zapier ?
      window.open(app.url, '_blank').focus() :
      uiState.openPopularAppModal(app);
  };

  return (
    <div onClick={() => onClick()} className='clickable'>
      <Panel className='PopularApp'>
        <div className='flex flex-grow align-items-middle'>
          <img className='zapier-app-image mr2' src={app.image} alt={app.title}/>
          <div className='medium h3'>{app.title}</div>
        </div>
        {app.zapier && <img className='zapier-tag' src={ZapierPill} alt='zapier'/>}
      </Panel>
    </div>
  );
});

const ZapierApp = observer(({app}) => {
  return (
    <div onClick={() => window.open(`https://zapier.com/apps/collage-hr/integrations/${app.slug}`, '_blank').focus()} className='clickable'>
      <Panel className='ZapierApp'>
        <div className='flex'>
          <div className='flex flex-grow align-items-middle mb2'>
            <img className='zapier-app-image mr2' src={app.images.url_64x64} alt={app.slug}/>
            <div className='medium h3'>{app.title}</div>
          </div>
          <img className='zapier-tag' src={ZapierPill} alt='zapier'/>
        </div>
        <div className='jumbo mb3'>{app.description}</div>
        <div className='integrate-link'>
          <FormattedMessage id={`integrations.INTEGRATE`} values={{appName: app.title}}/>
          <i className="material-icons">{'chevron_right'}</i>
        </div>
      </Panel>
    </div>
  );
});

const Integrations = observer(({uiState}) => {
  const {zapierResults, popularApps} = uiState;
  const {objects} = zapierResults;

  if (_.isEmpty(popularApps) && _.isEmpty(objects)) return <EmptyState/>;

  return (
    <div>
      {!_.isEmpty(popularApps) && <React.Fragment>
        <div className='mb2 h2 medium flex justify-content-center'>
          <FormattedMessage id='integrations.Most Popular'/>
        </div>
        <div className='PopularApps mb3'>
          {popularApps.map(app => <PopularApp key={app.title} app={app} uiState={uiState}/>)}
        </div>
      </React.Fragment>}
      {!_.isEmpty(objects) && <React.Fragment>
        <div className='mb2 h2 medium flex justify-content-center'>
          <FormattedMessage id='integrations.All Apps'/>
        </div>
        <div className='ZapierApps'>
          {objects.map(app => <ZapierApp key={app.uuid} app={app}/>)}
        </div>
      </React.Fragment>}
    </div>
  );
});

const MarketplaceContainer = observer(({uiState}) => {
  const {loadingResults} = uiState;
  const popularAppModalOpen = useModal(uiState, 'PopularAppModal', 'selectedPopularApp');

  return (
    <div>
      <Display title={t('integrations.Integrations')}/>
      <Layout>
        <SearchBox uiState={uiState}/>
        {loadingResults && <Spinner/>}
        {!loadingResults && <Integrations uiState={uiState}/>}
        <Pagination uiState={uiState}/>
      </Layout>
      <PopularAppModal isOpen={popularAppModalOpen} uiState={uiState}/>
    </div>
  );
});

export default withState(MarketplaceContainer, MarketplaceContainerState);
