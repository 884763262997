import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Panel, Select2} from 'components';
import {t} from 'shared/core';

const Frequency = observer(({uiState, intl}) => {
  const {cycle, errors} = uiState;
  return (
    <div>
      <Panel.Header title={t('performance.How often should leaders be having Check-Ins?')}/>
      <div className='h4 waterloo mb3 max-width-2'>
        <FormattedMessage id='performance.Select a recurring frequency for this Check-In Cycle.'/>
      </div>
      <div className='h4 medium mb1'>
        <FormattedMessage id='performance.Check-In Frequency'/>
      </div>
      <Select2 name='frequency' placeholder='— select —' value={cycle.frequency || ''} onChange={e => cycle.merge({frequency: e.target.value})} errorMessage={errors.frequency}>
        <option key='Weekly' value='weekly'>{intl.formatMessage({id: 'performance.weekly'})}</option>
        <option key='Biweekly' value='biweekly'>{intl.formatMessage({id: 'performance.biweekly'})}</option>
        <option key='Monthly' value='monthly'>{intl.formatMessage({id: 'performance.monthly'})}</option>
        <option key='Quarterly' value='quarterly'>{intl.formatMessage({id: 'performance.quarterly'})}</option>
        <option key='Semi Annually' value='semi_annually'>{intl.formatMessage({id: 'performance.semi_annually'})}</option>
      </Select2>
    </div>
  );
});

Frequency.propTypes = {
  frequency: PropTypes.string,
  onChange: PropTypes.func,
  onBack: PropTypes.func,
  onContinue: PropTypes.func,
  step: PropTypes.object,
  errors: PropTypes.object
};

export default injectIntl(Frequency);
