import React from 'react';
import {observer} from 'mobx-react';
import {Button, Panel, Table} from 'components';
import DocumentFoldersState from '../state/DocumentFoldersState';
import {FormattedMessage} from 'react-intl';
import {t, withState} from 'shared/core';
import useModal from 'shared/hooks/useModal';
import SubNav from 'company_settings/SubNav';
import EditDocumentFolderModal from './EditDocumentFolderModal';
import ReorderFoldersModal from './ReorderFoldersModal';

const FolderActionButtons = observer(({uiState}) => {
  return (
    <div className='flex justify-content-end'>
      <Button size='sm' className='mr2' trait='default' onClick={() => uiState.openReorderFoldersModal()}>
        <FormattedMessage id='company_settings.document_folders.Reorder Folders'/>
      </Button>
      <Button size='sm' onClick={() => uiState.addDocumentFolder()}>
        <FormattedMessage id='company_settings.document_folders.+ Add a Folder'/>
      </Button>
    </div>
  );
});

const COLUMNS = [
  {
    header: 'company_settings.document_folders.Name',
    attribute: 'name',
    width: 11
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'company_settings.document_folders.Delete Document Folder',
    subHeader: 'company_settings.document_folders.Are you sure you want to delete this folder?',
    body: 'company_settings.document_folders.DELETE_FOLDER_MESSAGE'
  },
  emptyState: 'company_settings.document_folders.No folders to display'
};

const DocumentFoldersContainer = observer(({uiState}) => {
  const {sortedFolders} = uiState;
  const reorderFoldersModalOpen = useModal(uiState, 'ReorderFoldersModal');

  return (
    <div>
      <SubNav title={t('company_settings.subnav.Document Folders')}/>
      <div className='Container mb4'>
        <Panel>
          <Panel.Header title={t('company_settings.document_folders.Document Folders')}>
            <FolderActionButtons uiState={uiState}/>
          </Panel.Header>
          <Table
            uiState={uiState}
            columns={COLUMNS}
            localization={LOCALIZATION}
            models={sortedFolders}
            onRemove={folder => uiState.deleteDocumentFolder(folder)}
            onEdit={folder => uiState.editDocumentFolder(folder)}
          />
        </Panel>
        <ReorderFoldersModal modalOpen={reorderFoldersModalOpen} uiState={uiState}/>
        <EditDocumentFolderModal uiState={uiState}/>
      </div>
    </div>
  );
});

export default withState(DocumentFoldersContainer, DocumentFoldersState);
