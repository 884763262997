import {action, observable} from 'mobx';
import {endpoints, types, t, auth} from 'shared/core';
import {OfferTemplate} from 'stores/offers';
import {DomainStore} from 'shared/store';
import {setupAutosaveDraft, successAlert} from 'shared/tools';

class OfferTemplateEditorState {
  store = new DomainStore();
  history;
  match;

  @observable errors = {};
  @observable template;
  @observable setFormDirty;

  receiveProps({match, history, setFormDirty}) {
    this.setFormDirty = setFormDirty;
    this.history = history;
    this.match = match;
  }

  @action async load() {
    const templateID = this.match.params.id;
    if (templateID) {
      await this.store._compose([
        endpoints.OFFER_TEMPLATE.with(templateID)
      ]);

      this.template = new OfferTemplate(this.store._getSingle(types.OFFER_TEMPLATE, {id: templateID}));
    } else {
      this.template = new OfferTemplate();
      this.template.locale = auth.locale;
      await setupAutosaveDraft(
        this.template,
        {id: 'new', type: types.OFFER_TEMPLATE}
      );
    }

    this.setFormDirty(false);
  }

  @action updateTemplateName(event) {
    this.template.merge({name: event.target.value});
    this.templateChanged();
  }

  @action updateTemplateLocale(event) {
    this.template.merge({locale: event.target.value});
    this.templateChanged();
  }

  @action updateTemplateAttachments(attachments) {
    this.template.merge({attachments: attachments});
    this.templateChanged();
  }

  @action updateContent(content) {
    this.template.richText = content.html;
    this.template.lexicalState = content.state;
    this.templateChanged();
  }

  templateChanged() {
    if (this.template.autosaver) {
      this.template.autosaver.autosave();
    } else {
      this.setFormDirty(true);
    }
  }

  @action async saveTemplate() {
    const {model, errors} = this.template.isNew ?
      await this.store.post(
        endpoints.OFFER_TEMPLATES,
        types.OFFER_TEMPLATE,
        this.template
      ) :
      await this.store.patch(this.template);
    this.errors = errors;

    if (model) {
      this.setFormDirty(false);
      successAlert(t('company_settings.offer_templates.alerts.Template saved.'));
      this.goBack();
    }
  }

  goBack() {
    this.history.push('/offer_templates');
  }
}

export default OfferTemplateEditorState;
