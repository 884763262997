import JobTimelineEntry from './JobTimelineEntry';
import {action, observable, computed} from 'mobx';
import Employee from 'stores/employees/Employee';
import User from 'stores/users/User';
import _ from 'lodash';
import {types} from 'shared/core';
import EmployeeCustomField from '../employee_custom_fields/EmployeeCustomField';

class EmploymentRecord extends JobTimelineEntry {
  @observable jobTitle;
  @observable employmentType;
  @observable location;
  @observable department;
  @observable manager;
  @observable payRateFormatted;
  @observable payRateUnit;
  @observable payFrequency;
  @observable payRate;
  @observable payRateCurrency;
  @observable author;
  @observable comment;
  @observable hoursPerWeek;
  @observable commission;
  @observable commissionStructure;
  @observable bonus;
  @observable bonusStructure;
  @observable annualizedCommissionFormatted;
  @observable annualizedCommission;
  @observable employmentRecordCustomFields;

  @action merge(other, fields) {
    super.merge(other, {
      ...fields,
      manager: Employee,
      author: User,
      employmentRecordCustomFields: [EmployeeCustomField]
    });
    this._type = types.EMPLOYMENT_RECORD;
  }

  @computed get commissionView() {
    return this.commission ? 'Yes' : 'No';
  }

  @computed get bonusView() {
    return this.bonus ? 'Yes' : 'No';
  }

  @computed get hasComment() {
    return !_.isEmpty(this.comment);
  }

  @computed get employmentTypeView() {
    if (this.employmentType) {
      return `models.employment_record.employment_type.${this.employmentType}`;
    }
  }

  @computed get payRateUnitView() {
    if (this.employmentType) {
      return `models.employment_record.pay_rate_unit.${this.payRateUnit}`;
    }
  }
}

export default EmploymentRecord;
