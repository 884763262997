import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Label from './Label';
import Table from './tables/components/Table';
import _ from 'lodash';
import {withState} from 'shared/core';

class ListState {
  @observable items;
  @observable columns = [
    { width: 6, render: model => <div className='jumbo'>{_.get(model, this.titleAttr)}</div> },
    { width: 6, render: model => <Label model={_.get(model, this.valueAttr)}/> }
  ];
  titleAttr;
  valueAttr;

  receiveProps({titleAttr, valueAttr, keyAttr, items}) {
    this.titleAttr = titleAttr || 'title';
    this.valueAttr = valueAttr || 'value';
    this.keyAttr = keyAttr || this.titleAttr;
    this.items = items;
  }
}

const List = observer(({uiState}) => {
  const {items, columns, keyAttr} = uiState;

  return (
    <Table
      columns={columns}
      indexBy={keyAttr}
      trait='xcompact'
      showLinks={false}
      showHeaders={false}
      models={items}
    />
  );
});

// used to display a two-column readon-only list of attributes and values
export default withState(List, ListState);
