import React from 'react';
import {FormattedMessage} from 'react-intl';
import TimelineSection from './TimelineSection';
import {observer} from 'mobx-react';

const Timeline = ({uiState}) => {
  const {detailsState} = uiState;
  if (detailsState.isEmptyTimeline) return null;

  return (
    <div className='clearfix p3 border-bottom'>
      <div className='col col-12 px2'>
        <div className='h2 medium'>
          <FormattedMessage id='recruiting.hiring_funnel.Timeline'/>
        </div>
        {detailsState.timelineSections.map(section =>
            <TimelineSection key={section.stage.id} viewModel={section} detailsState={detailsState}/>)}
      </div>
    </div>
  );
};

export default observer(Timeline);
