import {DomainObject} from 'shared/store';
import {action, observable, computed} from 'mobx';
import TimeOffType from './TimeOffType';
import {Employee} from 'stores/employees';
import {dateSpan} from 'shared/tools';
import _ from 'lodash';

class TimeOffBlockedDates extends DomainObject {
  @observable name;
  @observable startDate;
  @observable endDate;
  @observable employees = [];
  @observable types = [];

  @action merge(other) {
    super.merge(other, {
      _dates: ['startDate', 'endDate'],
      types: [TimeOffType],
      employees: [Employee]
    });
  }

  @computed get typeNames() {
    return _.map(this.types, 'name').join(', ');
  }

  @computed get dateRange() {
    return dateSpan(this.startDate, this.endDate, true);
  }
}

export default TimeOffBlockedDates;
