import _ from 'lodash';
import {observable, computed} from 'mobx';
import {calendarDate} from 'shared/tools';

class PositionViewModel {
  @observable position;

  constructor(position) {
    this.position = position;
  }

  @computed get createdDate() {
    return calendarDate(this.position.createdAt);
  }

  @computed get directoryUrl() {
    return this.position.isDraft ? `/positions/${this.position.id}` : `/positions/${this.position.id}/funnel/`;
  }
}

export default PositionViewModel;
