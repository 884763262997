import {observable, action, computed} from 'mobx';
import _ from 'lodash';

class ExternalRoleViewModel {
  @observable role;
  @observable userViewModel;

  constructor(role, userViewModel) {
    this.role = role;
    this.userViewModel = userViewModel;
  }

  @action toggle() {
    this.checked = !this.checked;
  }

  @computed get checked() {
    return _.includes(this.userViewModel.user.assignedRoles, this.role.id);
  }

  set checked(value) {
    const id = this.role.id;
    const assignedRoles = this.userViewModel.user.assignedRoles;
    const includes = _.includes(assignedRoles, id);

    if (value && !includes) {
      assignedRoles.push(id);
    } else if (!value && includes) {
      assignedRoles.remove(id);
    }
  }
}

export default ExternalRoleViewModel;
