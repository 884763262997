import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {t, auth, withState} from 'shared/core';
import {calendarDate} from 'shared/tools';
import {FormattedMessage} from 'react-intl';
import PayrollUploadTable from './PayrollUploadTable';
import PayrollFileUploadModal from './PayrollFileUploadModal';
import {UploadsDirectoryState} from '../state';

const DownloadLink = observer(({model}) => {
  const download = `${auth.company.name} - PPxx.xlsx`;
  return (
    <div className='mt2'>
      <a href='https://s3.amazonaws.com/collage-public/Payroll_Report_Template.xlsx' target='_blank' className='TextLink dodger' download={download}>
        <FormattedMessage id='payroll.directory.Download Template'/>
      </a>
    </div>
  );
});

const CurrentPayPeriodInformation = observer(({uiState}) => {
  const {currentPayPeriod} = uiState;
  if (currentPayPeriod.isNew) return null;

  const {payNumber, periodStartDate, periodEndDate, cutoffDate, payDate} = currentPayPeriod;

  return (
    <div className='clearfix mt2'>
      <FormattedMessage
        id='payroll.directory.PAY_PERIOD_DESCRIPTION'
        values={{
          payNumber: payNumber,
          startDate: calendarDate(periodStartDate),
          endDate: calendarDate(periodEndDate),
          deadlineDate: calendarDate(cutoffDate),
          payDate: calendarDate(payDate)
        }}
      />
    </div>
  );
});

const PayrollDocumentsInfo = observer(({uiState}) => {
  return (
    <Panel className='mb3 bg-sky border-left--dodger'>
      <Panel.Header title={t('payroll.directory.How to use payroll documents')}/>
      <ManagedPayrollInformation/>
      <SyncedPayrollInformation/>
      <CurrentPayPeriodInformation uiState={uiState}/>
      <DownloadLink/>
    </Panel>
  );
});

const ManagedPayrollInformation = () => {
  if (!auth.company.managedPayroll) return null;

  return (
    <div>
      <div className='clearfix mb2'>
        <FormattedMessage id='payroll.directory.MANAGED_DESCRIPTION'/>
      </div>
      <div className='clearfix'>
        <FormattedMessage id='payroll.directory.TEMPLATE_DESCRIPTION' values={{companyName: auth.unescapedCompanyName}}/>
      </div>
    </div>
  );
};

const SyncedPayrollInformation = () => {
  if (!auth.company.syncedPayroll) return null;

  return (
    <div>
      <div className='clearfix'>
        <FormattedMessage id='payroll.directory.SYNC_DESCRIPTION'/>
      </div>
    </div>
  );
};

const UploadsDirectory = observer(({uiState}) => {
  return (
    <div className='Container'>
      <div className='clearfix'>
        <PayrollDocumentsInfo uiState={uiState}/>
      </div>
      <div className='clearfix'>
        <PayrollUploadTable uiState={uiState}/>
        <PayrollFileUploadModal uiState={uiState}/>
      </div>
    </div>
  );
});

export default withState(UploadsDirectory, UploadsDirectoryState);
