import {action, observable} from 'mobx';
import _ from 'lodash';
import {DomainStore} from 'shared/store';
import {t, endpoints, types} from 'shared/core';
import {successAlert} from 'shared/tools';
import {BrokerClient, BrokerClientInsuranceCarrier, BrokerClientPlanAdmin, InsuranceCarrierRep} from 'stores/broker_portal';
import {PROVIDER_SUMMARIES} from 'shared/constants';

class ClientEditState {
  store = new DomainStore();
  history;
  match;
  isUploading = false;

  @observable brokerClient;
  @observable insuranceCarrierReps;
  @observable errors = {};
  @observable insuranceCarrierRepErrors;

  @observable addRepModalOpen = false;
  @observable newInsuranceCarrierRep = new InsuranceCarrierRep();

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  carriers() {
    return _.sortBy(PROVIDER_SUMMARIES, [(carrier) => carrier.name.toLowerCase()]);
  }

  repsForCarrier(carrier) {
    return _.filter(this.insuranceCarrierReps, {carrier});
  }

  @action async load() {
    const brokerClientId = _.get(this.match.params, 'id');

    const requests = [
      endpoints.BROKER_PORTAL.INSURANCE_CARRIER_REPS
    ];

    if (brokerClientId) {
      requests.push(endpoints.BROKER_PORTAL.BROKER_CLIENT.with(brokerClientId));
    }

    await this.store._compose(requests);

    this.brokerClient = new BrokerClient(
      this.store._getSingle(types.BROKER_PORTAL.BROKER_CLIENT, { id: brokerClientId })
    );

    this.insuranceCarrierReps = this.store._getAll(
      types.BROKER_PORTAL.INSURANCE_CARRIER_REP,
      InsuranceCarrierRep
    );
  }

  @action navigateToClientDirectory() {
    this.history.push('/clients/directory');
  }

  @action addBenefitsCarrier() {
    this.brokerClient.benefitsCarriers.push(
      new BrokerClientInsuranceCarrier({serviceType: 'benefits'})
    );
  }

  @action removeBenefitsCarrier(benefitsCarrier) {
    this.brokerClient.benefitsCarriers.remove(benefitsCarrier);
  }

  @action addPlanAdmin() {
    const primary = !this.brokerClient.planAdmins.length;

    this.brokerClient.planAdmins.push(
      new BrokerClientPlanAdmin({primary})
    );
  }

  @action removePlanAdmin(planAdmin) {
    this.brokerClient.planAdmins.remove(planAdmin);

    if (this.brokerClient.planAdmins.length && planAdmin.primary) {
      _.head(this.brokerClient.planAdmins).merge({primary: true});
    }
  }

  @action onUploadStarted() {
    this.isUploading = true;
  }

  @action onUploadFinished() {
    this.isUploading = false;
  }

  @action async saveBrokerClient() {
    const {model, errors} = this.brokerClient.isNew ?
      await this.createBrokerClient() :
      await this.store.patch(this.brokerClient);

    this.errors = errors;

    if (model) {
      successAlert(t('broker_portal.clients.edit.Client successfully saved'));
      this.navigateToClientDirectory();
    }
  }

  @action async createBrokerClient() {
    return this.store.post(
      endpoints.BROKER_PORTAL.BROKER_CLIENTS,
      types.BROKER_PORTAL.BROKER_CLIENT,
      this.brokerClient
    );
  }

  @action openAddRepModal(carrier) {
    this.newInsuranceCarrierRep = new InsuranceCarrierRep({carrier});
    this.addRepModalOpen = true;
  }

  @action closeAddRepModal() {
    this.addRepModalOpen = false;
    this.insuranceCarrierRepErrors = {};
  }

  @action async saveInsuranceCarrierRep() {
    const {model, errors} = await this.createInsuranceCarrierRep();

    this.insuranceCarrierRepErrors = errors;

    if (model) {
      this.insuranceCarrierReps.push(new InsuranceCarrierRep(model));
      successAlert(t('broker_portal.carrier_reps.edit.Insurance carrier rep successfully saved'));
      this.closeAddRepModal();
    }
  }

  @action createInsuranceCarrierRep() {
    return this.store.post(
      endpoints.BROKER_PORTAL.INSURANCE_CARRIER_REPS,
      types.BROKER_PORTAL.INSURANCE_CARRIER_REP,
      this.newInsuranceCarrierRep
    );
  }
}

export default ClientEditState;
