import {observable, action} from 'mobx';
import _ from 'lodash';

class FieldViewModel {
  @observable data;
  @observable checked = false;

  constructor(data, report) {
    this.data = data;

    const existingField = _.find(report.columns, {key: data.key});
    if (existingField) {
      this.data.merge(existingField);
      this.checked = true;
    }
  }

  @action toggleChecked() {
    this.checked = !this.checked;
  }
}

export default FieldViewModel;
