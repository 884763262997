import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {AbsoluteRedirect} from 'components';
import PlanDirectory from './PlanDirectory';
import BenefitClassCoverages from './BenefitClassCoverages';

const Plans = ({match, history, listEndpoint, viewEndpoint}) => {
  const backPath = match.path;
  return (
    <Switch>
      <Route exact path={`${match.path}/`} render={({match, history}) => 
        <PlanDirectory {...{match, history, endpoint: listEndpoint}}/>} />
      <Route path={`${match.path}/benefit_class/:benefitClassId`} render={({match}) => 
        <BenefitClassCoverages {...{match, backPath, endpoint: viewEndpoint}}/>} />
      <AbsoluteRedirect/>
    </Switch>
  );
};

export default Plans;
