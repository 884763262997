import React from 'react';
import {observer} from 'mobx-react';
import {Button} from 'components';
import {ReportDownloadState} from '../state';
import {FormattedMessage} from 'react-intl';
import DownloadModal from './DownloadModal';
import {withState} from 'shared/core';

const ReportDownloadButton = observer(({caption, uiState, name}) => {
  return (
    <div>
      <Button onClick={() => uiState.requestDownload()}>
        <FormattedMessage id={caption}/>
      </Button>
      <DownloadModal uiState={uiState} name={name}/>
    </div>
  );
});

export default withState(ReportDownloadButton, ReportDownloadState);
