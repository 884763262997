import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {SegmentSelectorState} from '../state';
import {Spinner, FormLayout, ObjectSelect2} from 'components';
import {FormattedMessage} from 'react-intl';

const SegmentSelector = observer(({uiState}) => {
  const {
    locations,
    departments,
    employmentTypes,
    selectedLocations,
    selectedDepartments,
    selectedEmploymentTypes,
    employeeCount
  } = uiState;

  return (
    <FormLayout>
      <ObjectSelect2
        multiple
        value={selectedLocations}
        items={locations}
        onAdd={location => uiState.addLocation(location)}
        onRemove={location => uiState.removeLocation(location)}
        label='components.segment_selector.Locations'
        placeholder='components.segment_selector.Add locations'
      />
      <ObjectSelect2
        multiple
        value={selectedDepartments}
        items={departments}
        onAdd={department => uiState.addDepartment(department)}
        onRemove={department => uiState.removeDepartment(department)}
        label='components.segment_selector.Departments'
        placeholder='components.segment_selector.Add departments'
      />
      <ObjectSelect2
        multiple
        value={selectedEmploymentTypes}
        items={employmentTypes}
        onAdd={employmentType => uiState.addEmploymentType(employmentType)}
        onRemove={employmentType => uiState.removeEmploymentType(employmentType)}
        label='components.segment_selector.Employment Types'
        placeholder='components.segment_selector.Add employment types'
      />
      <div className='mb2 waterloo'>
        <FormattedMessage
          id='components.segment_selector.SELECTED_EMPLOYEE_COUNT'
          values={{employeeCount}}
        />
      </div>
    </FormLayout>
  );
});

export default withState(SegmentSelector, SegmentSelectorState, {
  spinner: Spinner
});
