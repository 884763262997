import React from 'react';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Panel, Button} from 'components';
import ListIcon from 'img/list-icon@1x.png';
import EmployeeIcon from 'img/hr-upgrade/employee-icon@1x.png';
import SearchIcon from 'img/hr-upgrade/search-icon@1x.png';
import GraphIcon from 'img/graph-icon@1x.png';
import PerformanceReviewIcon from 'img/hr-upgrade/performance-review-icon@1x.png';
import TimeOffIcon from 'img/time-off-icon@1x.png';
import MoneyIcon from 'img/hr-upgrade/dollar-icon@1x.png';

const FeaturePanelItems = ({panel1, panel2}) => {
  return (
    <div className='flex flex-col md-flex-row'>
      <div className='col col-12 mb2 md-col-6 md-pr1'>
        <Panel className='full-height grid'>
          <div className='flex md-flex-row align-items-center justify-content-center'>
            <span className='mr3'>
              <img src={panel1.image} alt={panel1.imageAlt}/>
            </span>
            <span>
              <Panel.Header title={t(panel1.header)}/>
              <div className='jumbo'>
                <FormattedMessage id={panel1.body}/><a href={panel1.url} target='_blank'> <FormattedMessage id='hr_upgrade.learn_more.Learn more'/></a>
              </div>
            </span>
          </div>
        </Panel>
      </div>
      <div className='col col-12 mb2 md-col-6 md-pl1'>
        <Panel className='full-height grid'>
          <div className='flex md-flex-row align-items-center justify-content-center'>
            <span className='mr3'>
              <img src={panel2.image} alt={panel2.imageAlt}/>
            </span>
            <span>
              <Panel.Header title={t(panel2.header)}/>
              <div className='jumbo'>
                <FormattedMessage id={panel2.body}/><a href={panel2.url} target='_blank'> <FormattedMessage id='hr_upgrade.learn_more.Learn more'/></a>
              </div>
            </span>
          </div>
        </Panel>
      </div>
    </div>
  );
};

const HrUpgradeFeatures = observer(({uiState}) => {
  return (
    <div>
       <div className='h2 py2'>
        <FormattedMessage id='hr_upgrade.learn_more.What does Collage HR include?'/>
      </div>

      <FeaturePanelItems
        panel1={{
          header: 'hr_upgrade.learn_more.HR Records',
          body: 'hr_upgrade.learn_more.Keep all of your employment data in one place online. Collage HR makes employee records secure, convenient, and compliant.',
          image: ListIcon,
          imageAlt: 'list_icon',
          url: 'https://www.collage.co/employee-management/'
        }}
        panel2={{
          header: 'hr_upgrade.learn_more.Employee Onboarding & Training',
          body: 'hr_upgrade.learn_more.Collect employee data before their first day with automated self-serve employee onboarding.',
          image: EmployeeIcon,
          imageAlt: 'employee_icon',
          url: 'https://www.collage.co/employee-self-onboarding/'
        }}
      />
      <FeaturePanelItems
        panel1={{
          header: 'hr_upgrade.learn_more.Vacation and Leave Management',
          body: 'hr_upgrade.learn_more.Track employee vacation, sick leave or any time of time off. Simplified request and approval process for managers and employees.',
          image: TimeOffIcon,
          imageAlt: 'time_off_icon',
          url: 'https://www.collage.co/time-off-management/'
        }}
        panel2={{
          header: 'hr_upgrade.learn_more.Applicant Tracking System & Job Board',
          body: 'hr_upgrade.learn_more.Post a job to a custom job board, accept applications and sort candidates through custom interview processes.',
          image: SearchIcon,
          imageAlt: 'search_icon',
          url: 'https://www.collage.co/applicant-tracking/'
        }}
      />
      <FeaturePanelItems
        panel1={{
          header: 'hr_upgrade.learn_more.Performance Reviews',
          body: 'hr_upgrade.learn_more.Easily set up and conduct 360 degree performance reviews, recurring checkins, goals and employee surveys.',
          image: PerformanceReviewIcon,
          imageAlt: 'performance_review_icon',
          url: 'https://www.collage.co/performance-management/'
        }}
        panel2={{
          header: 'hr_upgrade.learn_more.HR Reporting',
          body: 'hr_upgrade.learn_more.Create customized, exportable HR reports, or access our collection of pre-built reports with a single click.',
          image: GraphIcon,
          imageAlt: 'graph_icon',
          url: 'https://www.collage.co/hr-reporting/'
        }}
      />

      <Panel className='full-height grid dashed mt2 mb3'>
        <div className='flex md-flex-row align-items-center justify-content-center'>
          <span className='mr3'>
            <img src={MoneyIcon} alt='money_icon'/>
          </span>
          <span>
            <div className='h3 mb1 medium'>
              <FormattedMessage id='hr_upgrade.learn_more.When you upgrade to Collage HR, we can become your payroll department too!'/>
            </div>
            <div className='jumbo'>
              <FormattedMessage id='hr_upgrade.learn_more.Simply use Collage HR to manage your HR data, and watch your payroll get done like magic. Either keep your existing payroll product, or we’ll help you set up a best-in-class solution that syncs with Collage HR. From there, you’ll be assigned a dedicated payroll analyst who will set up and run payroll on your behalf.'/>
            </div>
          </span>
        </div>
      </Panel>

      <div className='mt2 mb3'>
        <Button onClick={() => uiState.submit('managed_payroll') }>
          <FormattedMessage id="hr_upgrade.learn_more.I'm Interested!"/>
        </Button>
      </div>
    </div>
  );
});

export default HrUpgradeFeatures;
