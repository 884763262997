import React from 'react';
import {withRouter} from 'react-router-dom';
import {IndexTable, Display, Layout, Panel} from 'components';
import UsersIcon from 'img/settings/settings-users@2x.png';
import {t} from 'shared/core';

const BrokerSettingsIndex = () => {
  return (
    <IndexTable title={t('broker_portal.broker_settings.General')}>
      <IndexTable.Row
        icon={UsersIcon}
        title={t('broker_portal.broker_settings.Users')}
        description={t('broker_portal.broker_settings.USERS_DESCRIPTION')}
        showRoute='/users'
      />
    </IndexTable>
  );
};

const SettingsIndex = withRouter(({history}) => {
  return (
    <div>
      <Display title={t('broker_portal.broker_settings.Settings')}/>
      <Layout>
        <Layout.Section>
          <Panel.Stack loose>
            <BrokerSettingsIndex history={history}/>
          </Panel.Stack>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default SettingsIndex;
