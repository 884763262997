import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {t, withState} from 'shared/core';
import {Display, Layout, Panel, Button} from 'components';
import ClientInformation from './ClientInformation';
import PlanAdminInformation from './PlanAdminInformation';
import BenefitsCarriers from './BenefitsCarriers';
import AttachedFiles from './AttachedFiles';
import AdditionalInformation from './AdditionalInformation';
import {ClientEditState} from '../state';

const ClientEditContainer = observer(({uiState}) => {
  const {brokerClient} = uiState;

  return (
    <div>
      <Display
        title={t(`broker_portal.clients.edit.${brokerClient.isNew ? 'New' : 'Edit'} Client`)}
        backTitle={t('broker_portal.clients.edit.All Clients')}
        backPath='/clients/directory'
      />
      <Layout>
        <Layout.Section secondary>
          <Panel.Stack loose>
            <ClientInformation uiState={uiState}/>
            <PlanAdminInformation uiState={uiState}/>
            <BenefitsCarriers uiState={uiState}/>
            <AttachedFiles uiState={uiState}/>
            <AdditionalInformation uiState={uiState}/>
          </Panel.Stack>
          <div className='flex justify-content-end'>
            <Button onClick={() => uiState.saveBrokerClient()}>
              <FormattedMessage id='broker_portal.clients.edit.Save'/>
            </Button>
          </div>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(ClientEditContainer, ClientEditState);
