import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import EmailTemplatePreviewModal from 'components/modals/EmailTemplatePreviewModal';
import {Panel, ActionLink, CheckboxWithLabel} from 'components';
import {ReviewRow} from 'components/flow';
import useModal from 'shared/hooks/useModal';

const QuestionList = ({questions}) => {
  return (
    <ul className='list-reset list-gap-2'>
      {_.sortBy(questions, 'order').map((question) =>
        <li key={question.id}>
          <FormattedMessage id='performance.QUESTION_ITEM' values={{index: question.order, question: question.text}} />
        </li>
      )}
    </ul>
  );
};

const EmailList = ({uiState}) => {
  const {cycle} = uiState;

  return (
    <div>
      <div className='mb2'>
        <div className='table-cell pr1'>
          <CheckboxWithLabel
            label='performance.Introduction email to leaders at launch'
            value={cycle.sendManagerWelcomeNotifications}
            checked={cycle.sendManagerWelcomeNotifications}
            disabled={!cycle.isDraft}
            onChange={e => cycle.merge({sendManagerWelcomeNotifications: e.checked})}/>
        </div>
        <div className='table-cell pl1 align-top'>
          <ActionLink onClick={() => uiState.openManagerWelcomeModal()}>
            <FormattedMessage id='performance.preview'/>
          </ActionLink>
        </div>
      </div>
      <div className='mt2'>
        <div className='table-cell pr1'>
          <CheckboxWithLabel
            label='performance.Introduction email to employees at launch'
            value={cycle.sendEmployeeWelcomeNotifications}
            checked={cycle.sendEmployeeWelcomeNotifications}
            disabled={!cycle.isDraft}
            onChange={e => cycle.merge({sendEmployeeWelcomeNotifications: e.checked})}/>
        </div>
        <div className='table-cell pl1 align-top'>
          <ActionLink onClick={() => uiState.openEmployeeWelcomeModal()}>
            <FormattedMessage id='performance.preview'/>
          </ActionLink>
        </div>
      </div>
      <div className='mt2'>
        <div className='table-cell pr1'>
          <CheckboxWithLabel
            label='performance.Notify employees when a Check-In is overdue'
            value={cycle.sendEmployeeNotifications}
            checked={cycle.sendEmployeeNotifications}
            onChange={e => cycle.merge({sendEmployeeNotifications: e.checked})}/>
        </div>
        <div className='table-cell pl1 align-top'>
          <ActionLink onClick={() => uiState.openEmployeeReminderModal()}>
            <FormattedMessage id='performance.preview'/>
          </ActionLink>
        </div>
      </div>
    </div>
  );
};

const Review = observer(({uiState}) => {
  const {match, cycle} = uiState;
  const managerWelcomeModalOpen = useModal(uiState, 'ManagerWelcomeModal');
  const employeeWelcomeModalOpen = useModal(uiState, 'EmployeeWelcomeModal');
  const employeeReminderModalOpen = useModal(uiState, 'EmployeeReminderModal');

  return (
    <div>
      <Panel.Header
        title={t('performance.Review your check-in cycle')}
        description={t('performance.Hover over a section and click edit to make changes.')}
      />
      <ReviewRow title='performance.Name'
        value={cycle.name}
        editRoute={`/cycles/${match.params.id}/name`} />
      <ReviewRow title='performance.Participating'
        editRoute={`/cycles/${match.params.id}/leaders`}
      >
        <FormattedMessage id='performance.LEADER_AND_EMPLOYEE_COUNT' values={{leaderCount: cycle.totalManagers, employeeCount: cycle.totalEmployees}}/>
      </ReviewRow>
      <ReviewRow title='performance.Frequency'
        value={t(`performance.${cycle.frequency}`)}
        editRoute={`/cycles/${match.params.id}/frequency`} />
      <ReviewRow title='performance.Questions' editRoute={`/cycles/${match.params.id}/questions`}>
        <QuestionList questions={cycle.questions}/>
      </ReviewRow>
      <ReviewRow title='performance.Emails' lastRow={true}>
        <EmailList uiState={uiState}/>
      </ReviewRow>
      <EmailTemplatePreviewModal
        title={t('performance.Welcome email to leaders')}
        template={uiState.managerWelcomeEmailPreview}
        isOpen={managerWelcomeModalOpen}
        onHide={() => uiState.closeManagerWelcomeModal()}
      />
      <EmailTemplatePreviewModal
        title={t('performance.Welcome email to employees')}
        template={uiState.employeeWelcomeEmailPreview}
        isOpen={employeeWelcomeModalOpen}
        onHide={() => uiState.closeEmployeeWelcomeModal()}
      />
      <EmailTemplatePreviewModal
        title={t('performance.Check-In reminder to employees')}
        template={uiState.employeeReminderEmailPreview}
        isOpen={employeeReminderModalOpen}
        onHide={() => uiState.closeEmployeeReminderModal()}
      />
    </div>
  );
});

export default Review;
