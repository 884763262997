import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {Label} from 'components';
import {FormattedMessage} from 'react-intl';
import {calendarDate} from 'shared/tools';
import {t, auth} from 'shared/core';
import ChangedBy from './ChangedBy';

const TerminationRecordFields = observer(({uiState, viewModel}) => {
  const {record} = viewModel;
  const {parentState, payrollEnabled} = uiState;

  return (
    <div className='col col-12 sm-col-10'>
      <div className='clearfix pb1'>
        <div className='col col-6'>
          <FormattedMessage id={'employees.profile.role.Last Day of Work'}/>
        </div>
        <div className='col col-6'>
          <Label model={record} path={'lastDayOfWork'} render={value => calendarDate(value)} />
        </div>
      </div>
      {!parentState.userIsEmployee && <div className='clearfix pb1'>
        <div className='col col-6'>
          <FormattedMessage id={'employees.profile.role.Termination Category'}/>
        </div>
        <div className='col col-6'>
          <Label model={record} path={'terminationType'} render={value => t(`models.employee.termination_type.${value}`)}/>
        </div>
      </div>}
      {auth.company.benefitsEnabled && <div className='clearfix pb1'>
        <div className='col col-6'>
          <FormattedMessage id={'employees.profile.role.Last Day of Benefits'}/>
        </div>
        <div className='col col-6'>
          <Label model={record} path={'lastDayOfBenefits'} render={value => calendarDate(value)} />
        </div>
      </div>}
      {auth.moduleEnabled('directory') && !parentState.userIsEmployee && <div className='clearfix pb1'>
        <div className='col col-6'>
          <FormattedMessage id={'employees.profile.role.Reports Reassigned to'}/>
        </div>
        <div className='col col-6'>
          <Label model={record} path={'reassignReportsTo.name'}/>
        </div>
      </div>}
      {!parentState.userIsEmployee && record.roeCode && <div className='clearfix pb1'>
        <div className='col col-6'>
          <FormattedMessage id={'employees.profile.role.ROE Code'}/>
        </div>
        <div className='col col-6'>
          <Label model={record} path={'roeCode'} render={value => t(`models.employee.roe_code.${value}`)}/>
        </div>
      </div>}
      {!parentState.userIsEmployee && record.reasonForQuitting && <div className='clearfix pb1'>
        <div className='col col-6'>
          <FormattedMessage id={'employees.profile.role.Reason for Quitting'}/>
        </div>
        <div className='col col-6'>
          <Label model={record} path={'reasonForQuitting'} render={value => t(`models.employee.reason_for_quitting.${value}`)}/>
        </div>
      </div>}
      {!parentState.userIsEmployee && record.terminatedOnProbation != null && <div className='clearfix pb1'>
        <div className='col col-6'>
          <FormattedMessage id={'employees.profile.role.Was the employee suspended during a probationary period?'}/>
        </div>
        <div className='col col-6'>
          <FormattedMessage id={record.terminatedOnProbation === true ? 'Yes' : 'No'}/>
        </div>
      </div>}
      {!parentState.userIsEmployee && <div className='clearfix pb1'>
        <div className='col col-6'>
          <FormattedMessage id={`${payrollEnabled ? 'employees.profile.role.Comments' : 'employees.profile.role.Reason for Termination'}`}/>
        </div>
        <div className='col col-6'>
          <Label model={record} path={'terminationReason'}/>
        </div>
      </div>}
    </div>
  );
});

const TerminationRecord = observer(({uiState, viewModel}) => {
  const {parentState} = uiState;

  return (
    <div className='clearfix pt3'>
      <TerminationRecordFields uiState={uiState} viewModel={viewModel}/>
      {parentState.viewDataPermission('::ROLE_INFORMATION') && <ChangedBy viewModel={viewModel}/>}
    </div>
  );
});

export default TerminationRecord;
