import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {endpoints, auth} from 'shared/core';
import _ from 'lodash';

class EmployeeNavigationState {
  store = new DomainStore();
  currentEmployee;

  @observable employees = [];

  receiveProps({currentEmployee}) {
    this.currentEmployee = currentEmployee;
  }

  @action async load() {
    if (!auth.user.peopleDirectoryVisibility) return;

    await this.store._compose([endpoints.EMPLOYEES.ALL]);
    this.employees = this.store.getEmployees();
  }

  @action changeEmployee(employeeId) {
    window.location = window.location.pathname.replace(/\d+/, employeeId);
  }
}

export default EmployeeNavigationState;
