import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class PayrollField extends DomainObject {
  @observable complete;
  @observable name;
  @observable newValue;
  @observable oldValue;
}

export default PayrollField;
