import React from 'react';
import {observer} from 'mobx-react';
import {BenefitInfoContainer} from 'components/benefits';
import {auth, t, withState} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {Button} from 'components';
import {BenefitInfoEditFlow} from 'benefits';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {EmployeeOnboardingState} from '../state';

const BenefitInfoReviewContainer = withState(({uiState}) => {
  return (
    <div className='Container'>
      <div className='clearfix mb4'>
        <div className='col-12 sm-col-9 mx-auto'>
          <BenefitInfoContainer
            employeeId={auth.employee.id}
            changeRequestRedirectUrl='/onboard/benefit_info_change_request/:id/edit'
            showAdminForm={false}
            showBeneficiaryAssignment={false}
          />
          <div className='mt2 right-align'>
            <Button staysDisabledAfterClick onClick={() => uiState.updateOnboardingStep()}>
              <FormattedMessage id='onboard.coverage.Next'/>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}, EmployeeOnboardingState);

const BenefitChangeRequestContainer = observer(({match}) => {
  return (
    <BenefitInfoEditFlow
      match={match}
      backTitle={t('components.benefits.Onboarding')}
      backPath={'/onboard/benefit_info_review'}
      baseUrl={`/benefit_info_change_request/${match.params.id}/edit`}
    />
  );
});

const OnboardBenefitInfoReviewPage = observer(() => {
  return (
    <Router basename='/onboard'>
      <Switch>
        <Route path='/benefit_info_review' render={() => <BenefitInfoReviewContainer onboardStep='benefit_info_review'/>}/>
        <Route path='/benefit_info_change_request/:id/edit' component={BenefitChangeRequestContainer}/>
      </Switch>
    </Router>
  );
});

export default OnboardBenefitInfoReviewPage;
