import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalButtons} from 'components/modals';
import {FormError} from 'components';

const AddEmployeeModal = observer(({uiState}) => {
  const {editingEmployee, errors} = uiState;

  if (!editingEmployee) return null;

  return (
    <Modal
      size='sm'
      title='payroll.employees.Add Employee to Payroll?'
      isOpen={uiState.addEmployeeModalOpen}
      onHide={() => uiState.closeAddEmployeeModal()}
    >
      <FormattedMessage
        id='payroll.employees.ADD_EMPLOYEE_CONFIRMATION'
        values={{employeeName: editingEmployee.name}}
      />
      <FormError message={errors.hoursPerWeek || errors.payRateUnit || errors.base} />
      <ModalButtons
        onSave={() => uiState.toggleEmployeePayroll(true)}
        onCancel={() => uiState.closeAddEmployeeModal()}
      />
    </Modal>
  );
});

export default AddEmployeeModal;
