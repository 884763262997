import React from 'react';
import {observer} from 'mobx-react';
import {InteractiveTable, DropdownFilter} from 'components';
import {FormattedMessage} from 'react-intl';
import {TimeOffTransaction} from 'stores/time_off';
import {t, types, endpoints} from 'shared/core';
import {calendarDate} from 'shared/tools';
import _ from 'lodash';
import useModal from 'shared/hooks/useModal';
import EditManualAdjustmentModal from 'containers/employees/profile/components/time_off/components/EditManualAdjustmentModal';
import ManualAdjustmentTransaction from 'stores/time_off/ManualAdjustmentTransaction';

const TransactionAmount = observer(({model}) => {
  if (model.hasAmount) return <span>{model.amount}</span>;

  return <FormattedMessage id='N/A'/>;
});

const RunningTotal = observer(({model}) => {
  if (_.isNil(model.balance)) return null;

  return (
    <div className='waterloo' style={{fontStyle: 'italic'}}>
      <FormattedMessage id='employees.profile.time_off.transactions.TOTAL' values={{balance: model.balance}}/>
    </div>
  );
});

const TransactionAmountWithRunningTotal = observer(({model}) => {
  return (
    <div>
      <div className='pb1'><TransactionAmount model={model}/></div>
      <RunningTotal model={model}/>
    </div>
  );
});

const AccrualPeriodDescription = observer(({model}) => {
  if (!model.accrualPeriodStartDate || !model.accrualPeriodEndDate) return null;

  return (
    <div className='jumbo'>
      <FormattedMessage
        id='employees.profile.time_off.transactions.ACCRUAL_PERIOD'
        values={{
          start_date: calendarDate(model.accrualPeriodStartDate),
          end_date: calendarDate(model.accrualPeriodEndDate)
        }}
      />
    </div>
  );
});

const TimeOffDescription = observer(({model}) => {
  switch (model.transactionType) {
    case 'accrual_transaction':
      return (
        <div>
          <div><FormattedMessage id={`employees.profile.time_off.transactions.${model.transactionType}`}/></div>
          <AccrualPeriodDescription model={model}/>
        </div>
      );
    case 'manual_adjustment_transaction':
      return (
        <div>
          <div><FormattedMessage id={`employees.profile.time_off.transactions.${model.transactionType}`}/></div>
          {model.createdByUser && <div className='jumbo'>
            <FormattedMessage id='employees.profile.time_off.transactions.CREATED_BY' values={{user: model.createdByUser.name}}/>
          </div>}
          <div className='jumbo'>{model.notes}</div>
        </div>
      );
    case 'balance_reset_transaction':
      if (model.reason === 'reactivation') {
        return (
          <div>
            <div><FormattedMessage id={`employees.profile.time_off.transactions.reactivation_reset_transaction`}/></div>
            <div className='jumbo'><FormattedMessage id='employees.profile.time_off.transactions.Reason: employee reactivation'/></div>
          </div>
        );
      }
      return <FormattedMessage id={`employees.profile.time_off.transactions.annual_balance_reset_transaction`}/>;
    case 'carry_over_transaction':
      const description = model.amount > 0 ? 'carry_over_transaction_positive' : 'carry_over_transaction_negative';
      return <FormattedMessage id={`employees.profile.time_off.transactions.${description}`}/>;
    default:
      return <FormattedMessage id={`employees.profile.time_off.transactions.${model.transactionType}`}/>;
  }
});

const PostedAtInEST = observer(({model, uiState}) => {
  const moment = uiState.modules['moment-timezone'].default;

  return calendarDate(
    moment.tz(model.postedAt, 'America/Toronto')
  );
});

const COLUMNS = [
  {
    header: 'employees.profile.time_off.Date',
    component: PostedAtInEST,
    width: 4
  },
  {
    header: 'employees.profile.time_off.Description',
    component: TimeOffDescription,
    width: 5
  },
  {
    header: 'employees.profile.time_off.Days (+/-)',
    render: model => <TransactionAmountWithRunningTotal model={model}/>,
    width: 2,
    className: 'right-align'
  }
];

const LOCALIZATION = {
  emptyState: 'employees.profile.time_off.No transactions to display',
  removeModal: {
    header: 'employees.profile.time_off.Delete Manual Adjustment',
    body: 'employees.profile.time_off.Are you sure you want to delete this manual adjustment?'
  }
};

const TransactionList = observer(({uiState}) => {
  const editManualAdjustmentModalOpen = useModal(
    uiState, 'EditManualAdjustmentModal', 'editingManualAdjustment', ManualAdjustmentTransaction, 'errors'
  );

  return (
    <React.Fragment>
      <InteractiveTable
        uiState={uiState}
        title={t('employees.profile.time_off.Transactions')}
        columns={COLUMNS}
        localization={LOCALIZATION}
        onRemove={model => uiState.deleteManualAdjustment(model)}
        onEdit={model => uiState.openEditManualAdjustmentModal(model)}
        onMount={agent => uiState.setTransactionsInteractiveAgent(agent)}
        searchable={false}
        proxy={{
          type: 'async',
          modelType: types.TIME_OFF.TRANSACTION,
          model: TimeOffTransaction,
          endpoint: endpoints.TIME_OFF.TRANSACTIONS
        }}
        initialFilter={{
          account_id: uiState.accountFilterOptions[0].id
        }}
      >
        <DropdownFilter options={uiState.accountFilterOptions} attr='account_id'/>
      </InteractiveTable>
      <EditManualAdjustmentModal isOpen={editManualAdjustmentModalOpen} uiState={uiState}/>
    </React.Fragment>
  );
});

export default TransactionList;
