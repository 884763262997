import React from 'react';
import PropTypes from 'prop-types';
import Modal from './modals/Modal';
import {observer} from 'mobx-react';
import {observable} from 'mobx';

const PreviewModal = observer(({previewModalOpen, logoUrl, onHide}) => {
  return (
    <Modal
      imagePreview
      size='sm'
      isOpen={previewModalOpen}
      onHide={onHide}
      closeButton={false}>
      <img alt='company logo' src={logoUrl} className='square' height='300'/>
    </Modal>
  );
});

const CompanyLogoContent = ({className, logoUrl}) => {
  const computedClassNames = `CompanyLogo mx-auto block ${className}`;

  return (
    <div className={computedClassNames}>
      <img alt='company logo' className='align-top CompanyLogo-img' src={logoUrl}/>
    </div>
  );
};

class CompanyLogo extends React.Component {
  @observable previewModalOpen = false;

  openPreviewModal = () => {
    this.previewModalOpen = true;
  }

  closePreviewModal = () => {
    this.previewModalOpen = false;
  }

  render() {
    const {company, preview} = this.props;
    const hasPreview = company.logoUrl && preview;

    return (
      <div>
        <div className={`relative print-hide ${hasPreview ? 'clickable' : ''}`} onClick={hasPreview ? () => this.openPreviewModal() : () => {}}>
          <CompanyLogoContent {...this.props} logoUrl={company.logoUrl}/>
        </div>
        <PreviewModal
          logoUrl={company.logoUrl}
          previewModalOpen={this.previewModalOpen}
          onHide={() => this.closePreviewModal()} />
      </div>
    );
  }
}

CompanyLogo.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.oneOf(['auto']),
};

CompanyLogo.defaultProps = {
  mode: 'auto',
  preview: false,
  linkable: false
};

export default observer(CompanyLogo);
