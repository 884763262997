import React              from 'react';
import PropTypes from 'prop-types';
import _                  from 'lodash';
import Clipboard          from 'clipboard';
import uuid               from 'uuid';
import {FormattedMessage} from 'react-intl';

const LinkField = ({clicked, fieldValue, onClick, multiline}) => {
  return (
    <div className={`py1 pr2 border overflow-hidden rounded ${clicked && 'border--dodger'}`}>
      <div className={`mx2 ${multiline ? 'whitespace-pre-line' : 'truncate'}`}>
        <span onClick={onClick}>{fieldValue}</span>
      </div>
    </div>
  );
};

const CopiedTooltip = () => {
  return (
    <span className='Tooltip'>
      <i className='material-icons dodger align-self-center h3'>{'done'}</i>
      <span className='tooltiptext visible dateTooltip mb1'>
        <FormattedMessage id='Copied to Clipboard'/>
      </span>
    </span>
  );
};

class CopyableField extends React.Component {
  id = `js-clipboard-trigger-${uuid.v4()}`
  state = {}

  componentDidMount() {
    const clipboard = new Clipboard(`.${this.id}`, {
      text: (trigger) => {
        return this.props.fieldValue;
      }
    });
    clipboard.on('success', (e) => {
      this.setState({clicked: true});
      _.delay(() => this.setState({clicked: false}), 2000);
    });
  }

  handleTextClick = (e) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(e.currentTarget);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  render() {
    const {fieldValue = '', multiline} = this.props;
    const {clicked} = this.state;

    return (
      <div className='flex'>
        <div className='full-width relative'>
          <LinkField clicked={clicked} fieldValue={fieldValue} onClick={this.handleTextClick} multiline={multiline}/>
          <div className={`flex absolute top-0 right-0 p1 justify-content-end ${multiline ? '' : 'full-height'}`}>
            {clicked ?
              (<CopiedTooltip/>)
              : (<i className={`material-icons submarine align-self-center h3 clickable ${this.id}`}>{'content_copy'}</i>)}
          </div>
        </div>
      </div>
    );
  }
}

CopyableField.propTypes = {
  fieldValue: PropTypes.string.isRequired
};

export default CopyableField;
