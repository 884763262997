import {DomainObject} from 'shared/store';
import {observable} from 'mobx';
import _ from 'lodash';

class BeneficiaryForm extends DomainObject {
  @observable name;
  @observable url;
}

export default BeneficiaryForm;
