import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Button} from 'components';
import {FormattedMessage} from 'react-intl';
import {EmployeeOnboardingState} from '../../state';
import {SpouseFields, GenderField, DependantFields, LanguagePreferenceField} from 'components/benefits';
import {withState, t} from 'shared/core';

const OnboardFamilyStatusContainer = observer(({uiState}) => {
  const {benefitInfo, errors} = uiState;
  if (!benefitInfo) return null;

  return (
    <div className='Container'>
      <div className='clearfix mb4'>
        <div className='col-12 sm-col-9 mx-auto'>
          <Panel>
            <Panel.Header title={t('onboard.family_status.Add your benefits enrollment info')}/>
            <GenderField benefitInfo={benefitInfo} errors={errors}/>
            <LanguagePreferenceField benefitInfo={benefitInfo} errors={errors}/>
            <SpouseFields benefitInfo={benefitInfo} errors={errors}/>
            <DependantFields benefitInfo={benefitInfo} errors={errors}/>
            <div className='mt2 right-align'>
              <Button onClick={() => uiState.saveBenefitInfo()} className='Btn'><FormattedMessage id='onboard.family_status.Next'/></Button>
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
});

export default withState(OnboardFamilyStatusContainer, EmployeeOnboardingState);
