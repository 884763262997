import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {loader, withDisplay, t} from 'shared/core';
import CompanyDocumentDirectoryState from '../state/CompanyDocumentDirectoryState';
import AddCompanyDocumentModal from './AddCompanyDocumentModal';
import EditSignatureModal from './EditSignatureModal';
import companyDocumentStore from 'stores/documents/CompanyDocumentStore';
import signatureStore from 'stores/signatures/SignatureStore';
import CompanyDocumentsTable from './CompanyDocumentsTable';
import CompanySignaturesTable from './CompanySignaturesTable';
import SubNav from 'company_settings/SubNav';
import {Layout, Panel, PanelTooltip, HelperPanel, Button} from 'components';
import TaxDocumentsModal from './TaxDocumentsModal';

const LINKS = [
  {
    translations: {
      en: 'http://support.collage.co/en/articles/2672917-annotating-pdf-documents',
      fr: 'http://support.collage.co/fr/articles/2672917-annoter-des-documents-pdf'
    },
    title: 'company_settings.documents.Annotating PDF Documents'
  },
];

const Info = () => {
  return (
    <Panel className='bg-sky border-left--dodger'>
      <Panel.Header title={t('company_settings.documents.What are company documents?')}/>
      <div className='clearfix'>
        <FormattedMessage id='company_settings.documents.Use company documents to create templates for things like offer letters or employment agreements. You can dynamically insert employee data and request documents to be signed. Company documents can also be used to simply track who has read a file like an employee handbook.'/>&nbsp;
        <a className='nowrap' href='http://support.collage.co/getting-started/starting-with-your-company-documents' target='_blank'><FormattedMessage id='Learn more'/></a>
      </div>
    </Panel>
  );
};

const CompanySignatureHeader = () => {
  return (
    <div>
      <div className='table-cell align-middle'>
        <FormattedMessage id='company_settings.documents.Company Signatures' />
      </div>
      <div className='table-cell align-middle'>
        <PanelTooltip text="company_settings.documents.Add signatures of people who have signing authority and insert them into company documents. For example, the CEO's signature used to sign offer letters."/>
      </div>
    </div>
  );
};

const CollectTaxFormsExplanation = () => {
  return (
    <React.Fragment>
      <div>
        <FormattedMessage id='company_settings.documents.COLLECT_TAX_FORM_EXPLANATION_P1'/>
      </div>
      <div className='my2'>
        <FormattedMessage id='company_settings.documents.COLLECT_TAX_FORM_EXPLANATION_P2'/>
      </div>
      <div>
        <FormattedMessage id='company_settings.documents.COLLECT_TAX_FORM_EXPLANATION_P3'/>
      </div>
    </React.Fragment>
  );
};

const CompanyDocumentsContainer = observer(({uiState}) => {
  return (
    <div>
      <Layout>
        <Layout.Section>
          <Panel.Stack loose>
            <Info />
            <CompanyDocumentsTable uiState={uiState} />
            <Panel>
              <Panel.Header title={<CompanySignatureHeader />}/>
              <CompanySignaturesTable uiState={uiState} />
            </Panel>
            <Panel>
              <Panel.Header title={t('company_settings.documents.Collect Tax Forms')}/>
              <CollectTaxFormsExplanation/>
              <Button className='mt2' onClick={() => uiState.openTaxDocumentsModal()}>
                <FormattedMessage id='company_settings.documents.Send Documents'/>
              </Button>
            </Panel>
          </Panel.Stack>
        </Layout.Section>
        <Layout.Section secondary>
          <HelperPanel links={LINKS}/>
        </Layout.Section>
      </Layout>
      <div>
        <AddCompanyDocumentModal uiState={uiState} />
        <EditSignatureModal uiState={uiState} />
        <TaxDocumentsModal uiState={uiState} />
      </div>
    </div>
  );
});

async function load({history}) {
  const uiState = new CompanyDocumentDirectoryState(companyDocumentStore, signatureStore, history);
  await uiState.load();
  return {uiState};
}

export default withDisplay(loader(CompanyDocumentsContainer, load), () => <SubNav title={t('company_settings.subnav.Documents')}/>);
