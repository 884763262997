import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import {EmployeeNameWithAvatar} from 'components';

const AnnouncementVisibilityModal = observer(({uiState}) => {
  const {announcement, announcementVisibilityModalOpen} = uiState;
  if (!announcementVisibilityModalOpen) return null;

  return (
    <Modal
      size='sm'
      isOpen={announcementVisibilityModalOpen}
      title='hr_dashboard.feed.announcements.Announcement Visibility'
      onHide={() => uiState.closeAnnouncementVisibilityModal()}
    >
      {announcement.employees.map(employee => <div className='my1'>
        <EmployeeNameWithAvatar employee={employee}/>
      </div>
        )}
    </Modal>
  );
});

export default AnnouncementVisibilityModal;
