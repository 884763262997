import React from 'react';
import {observer} from 'mobx-react';
import TaskCompleted from './TaskCompleted';
import PerformanceReviewTask from './PerformanceReviewTask';
import PerformanceSurveyTask from './PerformanceSurveyTask';
import DocumentTask from './DocumentTask';
import TextDescriptionTask from './TextDescriptionTask';
import TrainingTask from './TrainingTask';
import OfferTask from './OfferTask';

function taskItem(task, uiState) {
  switch (task.taskType) {
    case 'review':
      return <PerformanceReviewTask uiState={uiState}/>;
    case 'survey':
      return <PerformanceSurveyTask uiState={uiState}/>;
    case 'document':
      return <DocumentTask uiState={uiState}/>;
    case 'training':
      return <TrainingTask uiState={uiState}/>;
    case 'offer':
      return <OfferTask uiState={uiState}/>;
    case 'onboarding':
    case 'offboarding':
      return <TextDescriptionTask uiState={uiState}/>;
    default:
      return null;
  }
}

const TaskContentSwitch = observer(({uiState}) => {
  const {task} = uiState;

  return (
    <div>
      {taskItem(task, uiState)}
      {task.completed && <div className='mt2'><TaskCompleted uiState={uiState} /></div>}
    </div>
  );
});

const TaskContent = observer(({uiState}) => {
  const {task} = uiState;

  return (
    <div>
      <div className='h2 mb2'>{task.name}</div>
      <TaskContentSwitch uiState={uiState}/>
    </div>
  );
});

export default TaskContent;
