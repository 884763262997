import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {Input} from 'components';

const FormRow = observer(({name, className, children}) => {
  return (
    <div className={`${className ? className : 'my3'}`}>
      <div className='h4 medium mb1'>
        <FormattedMessage id={name}/>
      </div>
      {children}
    </div>
  );
});

const EditEmergencyContactInfoModal = observer(({uiState}) => {
  const {editEmployee, errors} = uiState;

  return (
    <Modal isOpen={uiState.emergencyContactModalOpen} onHide={() => uiState.resetModals()} size='md'>
      <div className='h2 mb3'>
        <FormattedMessage id='employees.profile.emergency.Edit Emergency Contact Information'/>
      </div>
      <form>
        <div className='h3 medium mb2'>
          <FormattedMessage id='employees.profile.emergency.Emergency Contact #1'/>
        </div>
        <FormRow name='employees.profile.emergency.Full Name'>
          <Input value={editEmployee.ec1Name}
                 errorMessage={errors.ec1Name}
                 onChange={(e) => editEmployee.merge({ec1Name: e.target.value})}
           />
        </FormRow>
        <FormRow name='employees.profile.emergency.Relationship'>
          <Input value={editEmployee.ec1Relationship}
                 errorMessage={errors.ec1Relationship}
                 onChange={(e) => editEmployee.merge({ec1Relationship: e.target.value})}
           />
        </FormRow>
        <FormRow name='employees.profile.emergency.Phone'>
          <Input value={editEmployee.ec1Phone}
                 errorMessage={errors.ec1Phone}
                 onChange={(e) => editEmployee.merge({ec1Phone: e.target.value})}
           />
        </FormRow>
        <div className='h3 medium my2'>
          <FormattedMessage id='employees.profile.emergency.Emergency Contact #2'/>
        </div>
        <FormRow name='employees.profile.emergency.Full Name'>
          <Input value={editEmployee.ec2Name}
                 errorMessage={errors.ec2Name}
                 onChange={(e) => editEmployee.merge({ec2Name: e.target.value})}
           />
        </FormRow>
        <FormRow name='employees.profile.emergency.Relationship'>
          <Input value={editEmployee.ec2Relationship}
                 errorMessage={errors.ec2Relationship}
                 onChange={(e) => editEmployee.merge({ec2Relationship: e.target.value})}
          />
        </FormRow>
        <FormRow name='employees.profile.emergency.Phone'>
          <Input value={editEmployee.ec2Phone}
                 errorMessage={errors.ec2Phone}
                 onChange={(e) => editEmployee.merge({ec2Phone: e.target.value})}
          />
        </FormRow>
        <ModalButtons onSave={() => uiState.saveEmergencyContactInfo()} onCancel={() => uiState.resetModals()} />
      </form>
    </Modal>
  );
});

export default EditEmergencyContactInfoModal;
