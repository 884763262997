import React, {useState, useRef, useEffect} from 'react';
import {observer} from 'mobx-react';
import Button from 'components/buttons/Button';
import Checkbox from 'components/Checkbox';
import {FormattedMessage} from 'react-intl';
import {booleanFilters} from 'components/tables/interactive/components/fancy_filter/booleanFilters';

const FancyFilterButton = observer(({isOpen, onOpen, onClose, availableFilters, filter}) => {
  const onToggle = () => {
    return isOpen ? onClose() : onOpen();
  };
  const appliedFiltersCount = Array.from(filter.keys()).filter(f => availableFilters.includes(f)).length;

  return (
    <div className='relative'>
      <Button className='flex justify-content-start' trait='default' onClick={() => onToggle()}>
        <i className='material-icons waterloo h2'>{'filter_list'}</i>
        <span className='pl1'>
          <FormattedMessage id='components.tables.fancy_filters.FILTERS_WITH_COUNT' values={{count: appliedFiltersCount}}/>
        </span>
        <i className='material-icons waterloo h3'>{'arrow_drop_down'}</i>
      </Button>
    </div>
  );
});

const FancyFilterMenu = observer(({isOpen, filter, filterUpdated, filterDeleted, availableFilters}) => {
  return (
    <div className='relative mt3'>
      <div className={`FancyFilterDropdown-container right-0 ${isOpen ? 'is-opened' : 'is-closed'}`} style={{maxHeight: 406}}>
        <div className='FancyFilterDropdown'>
          <div className='FancyFilterDropdown-list' style={{overflowY: 'visible', maxHeight: 'none'}}>
            <FancyFilterOptions filter={filter} filterUpdated={filterUpdated} filterDeleted={filterDeleted} availableFilters={availableFilters}/>
          </div>
        </div>
      </div>
    </div>
  );
});

const FancyFilterOptions = observer(({filter, filterUpdated, filterDeleted, availableFilters}) => {
  const toggleFilter = (filterKey, checked) => {
    if (!filter.has(filterKey) && checked) {
      filterUpdated({[filterKey]: booleanFilters.includes(filterKey) ? true : ''});
    } else if (filter.has(filterKey) && !checked) {
      filterDeleted(filterKey);
    }
  };

  return (
    <div>
      {availableFilters.map(filterKey =>
        <div key={filterKey} className='clearfix table clickable'>
          <label className='full-width'>
            <div className='col-1 table-cell align-middle pr1'>
              <Checkbox checked={filter.has(filterKey)} onChange={(e) => toggleFilter(filterKey, e.checked)}/>
            </div>
            <div className='col-11 table-cell align-middle pl1 word-break'>
              <FormattedMessage id={`components.tables.fancy_filters.keys.${filterKey}`}/>
            </div>
          </label>
        </div>
      )}
    </div>
  );
});

const FancyFilter = observer(({filter, filterUpdated, filterDeleted, filters}) => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const buttonRef = useRef(null);
  const menuRef = useRef(null);

  const isClickOutside = (event) => {
    return !buttonRef.current.contains(event.target) && !menuRef.current.contains(event.target);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (isClickOutside(event)) {
        setFilterMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterMenuOpen]);

  return (
    <React.Fragment>
      <div ref={buttonRef}>
        <FancyFilterButton
          isOpen={filterMenuOpen}
          onOpen={() => setFilterMenuOpen(true)}
          onClose={() => setFilterMenuOpen(false)}
          filter={filter}
          availableFilters={filters}
        />
      </div>
      <div ref={menuRef}>
        <FancyFilterMenu isOpen={filterMenuOpen} filter={filter} filterUpdated={filterUpdated} filterDeleted={filterDeleted} availableFilters={filters}/>
      </div>
    </React.Fragment>
  );
});

export default FancyFilter;
