import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class Department extends DomainObject {
  @observable id;
  @observable name;
}

export default Department;

