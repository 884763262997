import {DomainStore} from 'shared/store';
import {types, endpoints} from 'shared/core';

class SessionStore extends DomainStore {
  async postCredentials(form) {
    return this.post(
      endpoints.SESSIONS.new,
      types.USER,
      form
    );
  }

  async renewSession() {
    return this.post(
      endpoints.SESSIONS.renew,
      types.USER
    );
  }
}

const singleton = new SessionStore();

export default singleton;
