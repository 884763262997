import React from 'react';
import {observer} from 'mobx-react';
import {Tag} from 'components';
import {FormattedMessage} from 'react-intl';

const SurveyAnonymousTag = observer(({survey, className}) => {
  if (!survey.anonymous) return null;

  return (
    <Tag colour='waterloo' className={className}>
      <FormattedMessage id='surveys.view.Anonymous'/>
    </Tag>
  );
});

export default SurveyAnonymousTag;
