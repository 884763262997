import {DomainObject, oneOf} from 'shared/store';
import {observable, action} from 'mobx';

class BenefitUpdate extends DomainObject {
  @observable id;
  @observable action;
  @observable company;
  @observable employee;
  @observable createdAt;
  @observable changes;
  @observable status;
  @observable comment;

  @action merge(other) {
    super.merge(other, {
      action: oneOf(['employee_onboarded', 'employee_updated', 'employee_terminated', 'employee_reactivated', 'employee_enrolled'])
    });
  }
}

export default BenefitUpdate;
