import {DomainStore} from 'shared/store';
import {action, observable} from 'mobx';
import {endpoints, types} from 'shared/core';
import {TimeOffType} from 'stores/time_off';
import _ from 'lodash';

class BlockedDatesState {
  store = new DomainStore();

  @observable errors = {};
  @observable editingBlockedDates = {};
  @observable timeOffTypes;

  @action async load() {
    await this.store._compose([
      endpoints.TIME_OFF.TYPES.ALL
    ]);

    this.timeOffTypes = this.store._getAll(types.TIME_OFF.TYPE, TimeOffType);
  }

  @action setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  @action addType(type) {
    this.editingBlockedDates.types.push(type);
  }

  @action removeType(type) {
    _.remove(this.editingBlockedDates.types, {id: type.id});
  }

  @action async removeBlockedDates(blockedDates) {
    await this.store.destroy(blockedDates);
  }

  @action async saveBlockedDates() {
    const {model, errors} = this.editingBlockedDates.isNew ?
      await this.store.post(endpoints.TIME_OFF.BLOCKED_DATES.ALL, types.TIME_OFF.BLOCKED_DATES, this.editingBlockedDates) :
      await this.store.patch(this.editingBlockedDates);
    this.errors = errors;

    if (model) {
      await this.interactiveAgent.refresh();
      this.closeEditBlockedDatesModal();
    }
  }
}

export default BlockedDatesState;
