import React from 'react';
import Panel from './panels/Panel';
import {FormattedMessage} from 'react-intl';
import {auth, t} from 'shared/core';
import _ from 'lodash';

function localizedLink(translations) {
  return translations[`${auth.locale}`] || translations.en;
}

const HelpfulLink = ({translations, title}) => {
  return (
    <div>
      <hr className='mxn1 my0'/>
      <div className='py2'>
        <a href={localizedLink(translations)} target='_blank'>
          {title}
        </a>
      </div>
    </div>
  );
};

const HelperPanelImg = ({img}) => {
  if (!img) return null;

  return (
    <Panel>
      <img src={img} className='full-width full-height' alt='help'/>
    </Panel>
  );
};

const HelperPanelHeader = ({title, descriptionItems = []}) => {
  if (!title && !descriptionItems.length) return null;

  return (
    <div className='mb2'>
      <Panel.Header title={title}/>
      {descriptionItems.map((d, index) => <div key={index} className='jumbo my1'>{d}</div>)}
    </div>
  );
};

const HelperPanelLinks = ({links}) => {
  if (!links.length) return null;

  return (
    <div className='mb2'>
      <div className='mb1 waterloo'>
        <FormattedMessage id='Helpful Links'/>
      </div>
      {links.map((link, index) =>
        <HelpfulLink key={index} translations={link.translations} title={t(link.title)} />
      )}
    </div>
  );
};

const HelperPanel = ({img, title, descriptionItems, links}) => {
  return (
    <div className='mt2 md-mt0'>
      <Panel.Stack>
        <HelperPanelImg img={img} />
        <Panel>
          <div style={{marginBottom: '-1rem'}}>
            <HelperPanelHeader title={title} descriptionItems={descriptionItems} />
            <HelperPanelLinks links={links} />
          </div>
        </Panel>
      </Panel.Stack>
    </div>
  );
};

HelperPanel.defaultProps = {
  links: []
};

// HelperPanel is a component used to describe the functionality of a feature

// props:

// - img: url of image that describes the feature

// - title: title of feature you are describing

// - descriptionItems: description of the feature. an array of paragraphs

// - links: an array of localized link objects to show up under "Helpful Links"
// ex:
// [
//   {links: {en: "www.collage.co/en", fr: "www.collage.co/fr"}, title: "Collage Website"}
//   {links: {en: "www.google.com/en", fr: "www.google.com/fr"}, title: "Search for answers"}
// ]

export default HelperPanel;
