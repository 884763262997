import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const EndReviewModal = observer(({modalOpen, uiState}) => {
  const {cycle} = uiState;
  const {dueDateView, submittedReviewsCount, totalReviewsCount, submittedReviewsPercentage} = cycle;

  return (
    <AlertModal
      translate={false}
      isOpen={modalOpen}
      onHide={() => uiState.closeEndCycleModal()}
      size='md'
      header={t('performance_reviews.view.Are you sure you want to end this review?')}
      mode='danger'
      body={
        t(
          'performance_reviews.view.END_REVIEW_DESCRIPTION',
          {
            dueDateView: dueDateView,
            submittedReviewsCount: submittedReviewsCount,
            totalReviewsCount: totalReviewsCount,
            submittedReviewsPercentage: submittedReviewsPercentage
          }
        )
      }
    >
      <FormattedMessage id='performance_reviews.view.Ending this cycle will prevent new reviewees from being added. Existing reviews can still be submitted and shared.'/>
      <ModalDangerButtons
        saveCaption={t('performance_reviews.view.End Review Cycle')}
        onSave={() => uiState.endCycle()}
        onCancel={() => uiState.closeEndCycleModal()}
      />
    </AlertModal>
  );
});

export default EndReviewModal;
