import React from 'react';
import {observer} from 'mobx-react';
import ModalButtons from 'components/modals/ModalButtons';
import Modal from 'components/modals/Modal';
import TaskTemplateFields from './TaskTemplateFields';

const EditTaskTemplateModal = observer(({uiState}) => {
  const {config, taskTemplate, taskTemplateForm, errors} = uiState;
  if (!taskTemplate) return null;

  return (
    <Modal
      size='md'
      isOpen={uiState.editTaskTemplateModalOpen}
      onHide={() => uiState.closeEditTaskTemplateModal()}
      title={`tasks.${taskTemplate.isNew ? config.modalTitle : config.modalEditTitle}`}>
      <TaskTemplateFields taskTemplateForm={taskTemplateForm} errors={errors} />
      <ModalButtons
        onSave={() => uiState.saveTaskTemplate()}
        onCancel={() => uiState.closeEditTaskTemplateModal()}/>
    </Modal>
  );
});

export default EditTaskTemplateModal;

