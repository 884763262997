import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {endpoints, types, api} from 'shared/core';
import {Company} from 'stores/company';
import {redirect} from 'shared/tools';

class ClientProfileState {
  store = new DomainStore();
  match;
  history;

  @observable company;

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    await this.store._compose([
      endpoints.BROKER_PORTAL.COMPANY.with(this.match.params.id)
    ]);

    this.company = new Company(this.store._getSingle(types.COMPANY));
  }

  @action async impersonateCompany(company) {
    await api.put(
      endpoints.BROKER_PORTAL.IMPERSONATE_COMPANY.with(company.id)
    );

    return redirect('/');
  }
}

export default ClientProfileState;
