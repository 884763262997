import {action, observable, computed} from 'mobx';
import {Dependent} from 'stores/benefits';
import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import _ from 'lodash';

class DependantFieldsState {
  @observable benefitInfo;
  @observable errors;
  @observable company;

  store = new DomainStore();

  receiveProps({benefitInfo, errors}) {
    this.benefitInfo = benefitInfo;
    this.errors = errors || {};
  }

  @action async load() {
    await this.store._compose([
      endpoints.CURRENT_COMPANY
    ]);

    this.company = this.store._getSingle(types.COMPANY);
  }

  @action addNewDependent() {
    this.benefitInfo.dependents.push(new Dependent());
  }

  @action removeDependent(dependent) {
    this.benefitInfo.dependents.remove(dependent);
  }

  @computed get hasDependents() {
    return !!this.benefitInfo.dependents.length;
  }
}

export default DependantFieldsState;
