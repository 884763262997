import {DomainObject} from 'shared/store';
import {action, observable} from 'mobx';
import Goal from './Goal';

class Employee extends DomainObject {
  @observable firstName;
  @observable lastName;
  @observable avatarColour;
  @observable avatarInitials;
  @observable goals;

  @action merge(other) {
    super.merge(other, {
      goals: [Goal]
    });
  }
}

export default Employee;
