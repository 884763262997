import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Input, ObjectSelect2, DatePicker, Select2, FormLayout} from 'components';
import {t} from 'shared/core';
import EMPLOYMENT_TYPES from 'stores/employees/employmentTypes';

const Employment = observer(({uiState}) => {
  const {employee, errors} = uiState;

  return (
    <div>
      <Panel.Header title={t('employees.hire.employment.Employment Info')}/>
      <FormLayout>
        <FormLayout.Group>
          <Input
            errorMessage={errors.firstName}
            value={employee.firstName}
            label='employees.hire.employment.First Name'
            onChange={e => employee.merge({firstName: e.target.value})}
          />
          <Input
            errorMessage={errors.lastName}
            value={employee.lastName}
            label='employees.hire.employment.Last Name'
            onChange={e => employee.merge({lastName: e.target.value})}
          />
        </FormLayout.Group>
        <DatePicker
          value={employee.startDate}
          onChange={date => employee.merge({startDate: date})}
          errorMessage={errors.startDate}
          label='employees.hire.employment.Start Date'
        />
        <Input
          errorMessage={errors.jobTitle}
          value={employee.jobTitle}
          label='employees.hire.employment.Job Title'
          onChange={e => employee.merge({jobTitle: e.target.value})}
        />
        <FormLayout.Group>
          <ObjectSelect2
            label='employees.hire.employment.Location'
            value={employee.location}
            onChange={value => employee.location = value}
            items={uiState.locationsWithDeleted}
            placeholder='employees.hire.employment.Specify a location'
            errorMessage={errors.locationId}
          />
          <ObjectSelect2
            label='employees.hire.employment.Department'
            value={employee.department}
            onChange={value => employee.department = value}
            items={uiState.departmentsWithDeleted}
            placeholder='employees.hire.employment.Specify a department'
            allowClear={true}
            errorMessage={errors.departmentId}
          />
        </FormLayout.Group>
        <Select2 label='employees.hire.employment.Employment Type'
          value={employee.employmentType}
          errorMessage={errors.employmentType}
          onChange={e => employee.employmentType = e.target.value}>
          {EMPLOYMENT_TYPES.map(i => <option key={i} value={i}>{t(i)}</option>)}
        </Select2>
        <ObjectSelect2
          label='employees.hire.employment.Manager'
          value={employee.manager}
          searchable={true}
          onChange={value => employee.manager = value}
          items={uiState.availableManagers}
          allowClear={true}
          placeholder='employees.hire.employment.Specify a manager'
          errorMessage={errors.manager}
        />
        <ObjectSelect2
          label='employees.hire.employment.Time Off Policy'
          value={employee.timeOffPolicy}
          onChange={value => employee.timeOffPolicy = value}
          items={uiState.timeOffPolicies}
          allowClear={true}
          errorMessage={errors.timeOffPolicy}
         />
      </FormLayout>
    </div>
  );
});

export default Employment;
