import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import _ from 'lodash';
import {action, observable} from 'mobx';

class RevieweesContainerState {
  store = new DomainStore();

  @observable pairs;

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.PERFORMANCE.REVIEWEES
    ]);
    const pairs = this.store._getAll(types.PERFORMANCE.CHECKIN_RELATIONS);

    this.pairs = pairs;
  }
}

export default RevieweesContainerState;
