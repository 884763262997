import {DomainStore} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {endpoints, types} from 'shared/core';
import {SurveyQuestion, SurveyResult} from 'stores/surveys';
import _ from 'lodash';
import SentimentViewModel from './SentimentViewModel';
import TextAreaViewModel from './TextAreaViewModel';
import MultipleChoiceViewModel from './MultipleChoiceViewModel';
import MultiSelectViewModel from './MultiSelectViewModel';

class SummaryContainerState {
  store = new DomainStore();
  match;
  history;
  scope;
  Highcharts;
  HighchartsReact;

  @observable survey;
  @observable results;
  @observable questionSummaries;

  @observable questionId;
  @observable contentFilter;
  @observable reviewersModalOpen = false;

  receiveProps({match, history, scope, survey}) {
    this.match = match;
    this.history = history;
    this.scope = scope;
    this.survey = survey;
  }

  @action async load() {
    const [highcharts, highchartsReact,] = await Promise.all([
      import(/* webpackChunkName: 'highcharts-async' */ 'highcharts'),
      import(/* webpackChunkName: 'highcharts-react-official-async' */ 'highcharts-react-official'),
      this.store._compose([
        endpoints.SURVEYS.RESULTS.with(this.match.params.id)
      ])
    ]);

    this.Highcharts = highcharts.default;
    this.HighchartsReact = highchartsReact.default;

    this.questions = this.store._getAll(
      types.SURVEYS.QUESTION, SurveyQuestion
    );

    this.results = new SurveyResult(this.store._getSingle(types.SURVEYS.RESULT));

    this.questionSummaries = _.map(this.sortedQuestions,
      question => {
        const inputs = {
          question: question,
          trend: this.results.trend,
          uiState: this,
          periodDate: this.results.currentPeriodDate
        };
        switch (question.questionType) {
          case 'multiple_choice':
            return new MultipleChoiceViewModel(inputs);
          case 'multi_select':
            return new MultiSelectViewModel(inputs);
          case 'sentiment':
            return new SentimentViewModel(inputs);
          case 'text_area':
            return new TextAreaViewModel(inputs);
          default:
            throw new Error(`Question type ${question.questionType} not supported.`);
        }
      }
    );
  }

  @action openReviewersModal(questionId, content, periodDateFilter) {
    if (this.survey.anonymous) return null;

    this.periodDateFilter = periodDateFilter;
    this.contentFilter = content;
    this.questionId = questionId;
    this.reviewersModalOpen = true;
  }

  @action onPointClicked(highchartsEvent, questionId) {
    this.openReviewersModal(
      questionId,
      highchartsEvent.point.series.name,
      highchartsEvent.point.custom.periodDate
    );
  }

  @action closeReviewersModal() {
    this.reviewersModalOpen = false;
  }

  @computed get sortedQuestions() {
    return _.sortBy(this.questions, 'order');
  }

  @computed get hasTrends() {
    return !_.isEmpty(this.results.trend);
  }
}

export default SummaryContainerState;
