import {DomainObject} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {Metric} from 'stores/performance_goals';
import {FormattedMessage} from 'react-intl';
import React from 'react';
import {fullName} from 'shared/tools';

class CurrentValueUpdate extends DomainObject {
  @observable value;
  @observable metric;

  @action merge(other) {
    super.merge(other, {
      metric: Metric
    });
  }

  @computed get progressBarItems() {
    return [
      {label: 1, value: this.metric.startValue, color: '#08BAD3'},
      {label: 2, value: this.metric.targetValue - this.value, color: '#EBEFF1'}
    ];
  }

  @computed get actionDescription() {
    return (
      <div>
        <span className='h3 medium'>
          {fullName(this.user)}
        </span>
        &nbsp;
        <span className='jumbo'>
          <FormattedMessage id='goals.updated'/>
        </span>
        &nbsp;
        <span className='medium'>
          {this.metric.name}
        </span>
        &nbsp;
        <span className='jumbo'>
          <FormattedMessage id='goals.to'/>
        </span>
        &nbsp;
        <span className='medium'>
          {this.metric.formattedValue(this.value)}
        </span>
      </div>
    );
  }
}

export default CurrentValueUpdate;
