import {observable, action} from 'mobx';
import {DomainStore, DomainObject} from 'shared/store';
import {endpoints, types} from 'shared/core';
import _ from 'lodash';

class BrokerSignupForm extends DomainObject {
  @observable firstName;
  @observable lastName;
  @observable email;
  @observable emailConfirmation;
  @observable password;
  @observable broker;
  @observable token;
}

class BrokerSignupFormState {
  store = new DomainStore();
  match;

  @observable brokerNotFound = false;
  @observable form = new BrokerSignupForm();
  @observable errors = {};

  receiveProps({match}) {
    this.match = match;
  }

  @action async load() {
    const key = this.match.params.benefitsBroker;
    await this.store._compose([
      endpoints.BROKER.withKey(key)
    ]);
    this.broker = this.store._getSingle(
      types.BROKER
    );
    this.form.merge({
      broker: this.broker,
      token: this.match.params.token
    });
  }

  @action async signUp() {
    const {model, errors} = await this.store.post(
      endpoints.BROKER_PORTAL.BROKER_USERS,
      types.USER,
      this.form
    );
    this.errors = errors;

    if (model) {
      window.location = '/';
    }
  }

  get returnToUrl() {
    return `/advisor${this.match.url}`;
  }
}

export default BrokerSignupFormState;
