import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {Label} from 'components';
import {calendarDate} from 'shared/tools';

const EmploymentRecordCustomFields = observer(({customFieldGroup}) => {
  return _.map(customFieldGroup.employeeCustomFields, employeeCustomField => (
    <div className='clearfix pb1' key={employeeCustomField.id}>
      <div className='col col-6'>
        <Label model={employeeCustomField} path={'name'} translate={false}/>
      </div>
      <div className='col col-6'>
        <Label
          model={employeeCustomField}
          path={'value'}
          render={value => employeeCustomField.isDate ? calendarDate(value) : value}
        />
      </div>
    </div>
  ));
});

const EmploymentRecordCustomFieldGroups = observer(({record, uiState}) => {
  return _.map(uiState.customFieldGroupsForRecord(record), customFieldGroup => (
    <div key={customFieldGroup.id}>
      <h3 className='medium'>{customFieldGroup.name}</h3>
      <EmploymentRecordCustomFields customFieldGroup={customFieldGroup}/>
    </div>
  ));
});

export default EmploymentRecordCustomFieldGroups;
