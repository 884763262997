/* eslint-disable */
import React, { Component} from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import P from 'prop-types';

import {
	NODE_HEIGHT,
	TOP_MARGIN,
} from '../../constants';

@observer class Lines extends Component {
	@computed get translateX(){
		return - this.props.firstNodeLeft + this.props.left
	}
	@computed get path(){
		const { left, right } = this.props

		return `M 0 ${TOP_MARGIN + NODE_HEIGHT / 2} H ${right - left}`
	}

	render(){
		return (
				<path
					d={this.path}
					// need both transform (for edge) and style transform
					// for animations to work for safari + firefox
					transform={`translate(${this.translateX} 0)`}
					style={{
						transform: `translate(${this.translateX}px, 0)`
					}}
					strokeWidth="2"
					className="row__arc"
				/>
		)
	}
}

Lines.propTypes = {
	left: P.number,
	right: P.number,
	firstNodeLeft: P.number,
}

export default Lines;
