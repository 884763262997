import React from 'react';
import {observer} from 'mobx-react';
import ReportsSubnav from 'reports/components/ReportsSubnav';
import CustomReportsIndexWithState from './CustomReportsIndexWithState';

const CustomReportsIndex = observer(({history, scope}) => {
  return (
    <div>
      <ReportsSubnav scope={scope}/>
      <CustomReportsIndexWithState history={history}/>
    </div>
  );
});

export default CustomReportsIndex;
