import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import TOOLTIPS from 'company_settings/permissions/components/tooltips';
import {Panel} from 'components';

const Note = observer(({role}) => {
  const note = TOOLTIPS[role.roleType];
  if (!note) return null;

  return (
    <Panel className='bg-sky border-left--dodger'>
      <Panel.Header title={<FormattedMessage id='company_settings.permissions.permission_layer' values={{role: role.nameView}}/>}/>
      <div className='clearfix'>
        <FormattedMessage id={note.text}/>&nbsp;
        <a className='nowrap' href={note.link} target='_blank'>
          <FormattedMessage id='company_settings.permissions.Learn more'/>
        </a>
      </div>
    </Panel>
  );
});

export default Note;
