import {observable, action, computed} from 'mobx';
import _ from 'lodash';
import {noop} from 'shared/tools';
import $ from 'jquery';

class FillablePdfPreviewState {
  pdfUrl;
  companyDocument;

  @observable annotations = [];
  @observable currentAnnotation;
  @observable pageNumber = 1;
  @observable isLoaded = false;
  @observable markedComplete = false;
  @observable callbacks = {
    onEmployeeSignatureUpdated: noop
  }

  constructor(pdfUrl, annotations, pageNumber, companyDocument) {
    this.pdfUrl = pdfUrl;
    this.annotations = annotations;
    this.pageNumber = pageNumber;
    this.companyDocument = companyDocument;
  }

  @action onLoaded(selectPage) {
    this.selectPage = selectPage;
    this.isLoaded = true;
  }

  @action changePage(pageNumber) {
    this.pageNumber = pageNumber;
    this.setAnnotation(this.nextAnnotationOnCurrentPage);
  }

  @action goToFirstAnnotationOnPage(pageNumber) {
    if(pageNumber === this.pageNumber) {
      this.setAnnotation(this.nextAnnotationOnCurrentPage);
    } else {
      this.selectPage(pageNumber);
    }
  }

  @action goToNextAnnotation() {
    this.setAnnotation(this.nextAnnotationOnCurrentPage);
    if (!this.currentAnnotation) {
      let annotation = _.find(this.sortedAnnotationsByPageNumber, a => this.isNextEmptyAnnotation(a));
      if (annotation) {
        this.goToFirstAnnotationOnPage(annotation.annotation.pageNumber);
      } else {
        annotation = _.find(this.sortedAnnotationsByPageNumber, (a) => !a.isValid);
        if (annotation) {
          this.goToFirstAnnotationOnPage(annotation.annotation.pageNumber);
        } else {
          this.markedComplete = true;
          this.transitionToCompleteButton();
        }
      }
    }
  }

  @action setAnnotation(annotation) {
    this.currentAnnotation = annotation;
    if (annotation) {
      this.transitionToAnnotation();
    }
  }

  @action updateEmployeeSignature(signatureData) {
    this.callbacks.onEmployeeSignatureUpdated(signatureData);

    for (const annotation of this.employeeSignatureAnnotations) {
      annotation.signatureData = signatureData;
    }
  }

  @computed get currentPageAnnotations() {
    return _.filter(this.annotations, { annotation: { pageNumber: this.pageNumber }});
  }

  @computed get sortedAnnotations() {
    return _.sortBy(this.currentPageAnnotations, ['annotation.state.top', 'annotation.state.left']);
  }

  @computed get helperDirectsToDifferentPage() {
    return !this.currentAnnotation || (this.currentAnnotation === _.last(this.sortedAnnotations) && this.currentAnnotation.isValid);
  }

  @computed get readyToComplete() {
    const incompleteAnnotations = _.filter(this.annotations, a => !a.isValid);
    if (incompleteAnnotations.length) {
      return false;
    }

    const lastNonEmptyAnnotationIndex = _.findLastIndex(this.sortedAnnotationsByPageNumber, a => a.isEmpty);
    if (lastNonEmptyAnnotationIndex === -1) {
      return true;
    }

    if (this.currentAnnotation) {
      const {id} = this.currentAnnotation.annotation.annotationArgs;
      const currentIndex = _.findIndex(this.sortedAnnotationsByPageNumber, s => s.annotation.annotationArgs.id === id);
      return currentIndex >= lastNonEmptyAnnotationIndex;
    } else {
      return this.sortedAnnotationsByPageNumber[lastNonEmptyAnnotationIndex].annotation.pageNumber <= this.pageNumber;
    }
  }

  @computed get sortedAnnotationsByPageNumber() {
    return _.sortBy(this.annotations, ['annotation.pageNumber'], ['annotation.state.top'], ['annotation.state.left']);
  }

  @computed get nextAnnotationOnCurrentPage() {
    if (this.currentAnnotation) {
      const {id} = this.currentAnnotation.annotation.annotationArgs;
      const currentIndex = _.findIndex(this.sortedAnnotations, s => s.annotation.annotationArgs.id === id);
      return _.find(this.sortedAnnotations, (a, index) => {
        return a.isEmpty && index > currentIndex;
      });
    } else {
      return _.find(this.sortedAnnotations, a => a.isEmpty);
    }
  }

  @computed get employeeSignatureAnnotations() {
    return _.filter(this.annotations, { annotation: { annotationType: 'employee_signature'} });
  }

  isNextEmptyAnnotation(annotationViewModel) {
    const annotationPageNumber = annotationViewModel.annotation.pageNumber;

    return annotationViewModel.isEmpty && this.pageNumber < annotationPageNumber;
  }

  transitionToAnnotation() {
    const $pdfViewerElement = $('#pdfViewerContainer');
    const topOfAnnotation = $pdfViewerElement.offset().top - $pdfViewerElement.position().top + this.currentAnnotation.annotation.state.top;
    $('html, body').animate({scrollTop: topOfAnnotation - 300}, 300);

    const annotationElement = $(`#${this.currentAnnotation.annotation.annotationArgs.id}`);
    annotationElement.focus();
    if (annotationElement[0] && this.currentAnnotation.annotation.annotationArgs.inputType !== 'checkbox')
      annotationElement[0].click();
  }

  transitionToCompleteButton() {
    const $pdfViewerElement = $('#pdfViewerContainer');
    const bottom = $pdfViewerElement.offset().top - $pdfViewerElement.position().top + $pdfViewerElement.outerHeight(true);
    $('html, body').animate({scrollTop: bottom - 300}, 300);
  }

  registerCallback(event, callback) {
    switch (event) {
      case 'onEmployeeSignatureUpdated':
        this.callbacks[event] = callback;
        break;
      default:
        throw new Error(`Callback ${event} is not supported.`);
    }
  }
}

export default FillablePdfPreviewState;
