import React from 'react';
import {observer} from 'mobx-react';
import SentimentSummary from './SentimentSummary';
import TextAreaSummary from './TextAreaSummary';
import MultipleChoiceSummary from './MultipleChoiceSummary';

const QuestionSummaryFactory = observer(({questionSummary}) => {
  switch (questionSummary.question.questionType) {
    case 'multiple_choice':
    case 'multi_select':
      return <MultipleChoiceSummary questionSummary={questionSummary} />;
    case 'text_area':
      return <TextAreaSummary questionSummary={questionSummary} />;
    case 'sentiment':
      return <SentimentSummary questionSummary={questionSummary} />;
    default:
      throw new Error(`Question type: ${questionSummary.question.questionType} not supported`);
  }
});

export default QuestionSummaryFactory;
