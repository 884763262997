import React from 'react';
import {observer} from 'mobx-react';
import {Name, Reviewers, Reviewees, Frequency, Questions, Review} from './components';

const Steps = observer(({step, uiState}) => {
  switch (step) {
    case 'name':
      return <Name uiState={uiState}/>;
    case 'leaders':
      return <Reviewers uiState={uiState}/>;
    case 'employees':
      return <Reviewees uiState={uiState}/>;
    case 'frequency':
      return <Frequency uiState={uiState}/>;
    case 'questions':
      return <Questions uiState={uiState}/>;
    case 'review':
      return <Review uiState={uiState}/>;
    default:
      throw new Error(`Location ${step} is not supported.`);
  }
});

export default Steps;
