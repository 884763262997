import React from 'react';
import {observer} from 'mobx-react';
import Report from 'reports/components/Report';
import CompositeReport from 'reports/components/CompositeReport';
import {endpoints, t} from 'shared/core';

const CensusReport = observer(({match}) => {
  return (
    <CompositeReport>
      <Report
        name={t('broker_portal.clients.reports.Census Report')}
        url={endpoints.BROKER_PORTAL.REPORTS.with(match.params.id, 'broker_census')}
      />
    </CompositeReport>
  );
});

export default CensusReport;
