import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import SelectReviewers from './SelectReviewers';

const SelectReviewersModal = observer((props) => {
  return (
    <Modal 
      isOpen={props.isOpen} 
      onHide={props.onHide} 
      title='performance_reviews.manage.Select reviewers'>
      <SelectReviewers {...props}/>
    </Modal>
  );
});

export default SelectReviewersModal;
