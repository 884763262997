import React from 'react';
import {observer} from 'mobx-react';
import Subnav from 'benefits/components/Subnav';
import {endpoints} from 'shared/core';
import {Plans} from 'components/benefits';

const CompanyPlansContainer = observer(({scope, match, history}) => {
  return (
    <div>
      <Subnav scope={scope}/>
      <Plans 
        match={match}
        history={history}
        listEndpoint={endpoints.COMPANY_INSURANCE_CARRIERS.WITH_OPTIONAL}
        viewEndpoint={endpoints.BENEFITS.BENEFIT_CLASS}
      />
    </div>
  );
});

export default CompanyPlansContainer;
