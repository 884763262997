import React from 'react';
import {observer} from 'mobx-react';

const TableOverlay = observer(({isUpdating}) => {
  if (!isUpdating) return null;

  return (
    <div className='TableOverlay'>
    </div>
  );
});

export default TableOverlay;
