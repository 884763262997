import React from 'react';
import {observer} from 'mobx-react';
import {InteractiveTable} from 'components';
import {types, endpoints, t} from 'shared/core';
import {RecentActivityItem} from 'pa_dashboard/components/recent_activity';
import {BenefitUpdate} from 'stores/benefits';

const COLUMNS = [
  {
    component: RecentActivityItem,
    width: 12
  }
];

const RecentActivityList = observer(({uiState}) => {
  const {match} = uiState.parentState;

  return (
    <InteractiveTable
      title={t('pa_dashboard.Recent Activity')}
      hideProfileLink={true}
      columns={COLUMNS}
      showLinks={false}
      searchable={false}
      showHeaders={false}
      hidePaginationInfo
      uiState={uiState}
      proxy={{
        type: 'async',
        modelType: types.BENEFITS.UPDATE,
        model: BenefitUpdate,
        endpoint: endpoints.BROKER_PORTAL.BENEFIT_UPDATES.withCompany(match.params.id),
        pageSize: 10
      }}
    />
  );
});

export default RecentActivityList;
