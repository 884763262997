import {DomainObject} from 'shared/store';
import {observable, action} from 'mobx';
import {oneOf} from 'shared/store';

class Rule extends DomainObject {
  @observable ruleType;
  @observable parameters = [];

  @action merge(other, fields) {
    super.merge(other, {
      ...fields,
      type: oneOf(['direct_report', 'employee', 'location', 'department', 'employment_type', 'all_employees'])
    });
  }
}

export default Rule;
