import {observable, action} from 'mobx';
import {PerformanceReviewTemplate} from 'stores/performance_reviews';
import {DomainStore} from 'shared/store';
import {endpoints, types, t} from 'shared/core';

class TemplateState {
  store = new DomainStore();
  history;

  @observable newTemplate;
  @observable errors = {};

  receiveProps({history}) {
    this.history = history;
  }

  @action async createNewTemplate() {
    this.newTemplate = new PerformanceReviewTemplate({
      name: t('performance_reviews.Untitled question set')
    });
    const {model, errors} = await this.store.post(
      endpoints.REVIEW_TEMPLATES.ALL,
      types.PERFORMANCE_REVIEWS.TEMPLATE,
      this.newTemplate
    );
    this.errors = errors;

    if (model) {
      this.goToTemplate(model.id);
    }
  }

  @action async removeTemplate(template) {
    await this.store.destroy(template);
  }

  @action async duplicateTemplate(template) {
    const {model} = await this.store.post(
      endpoints.REVIEW_TEMPLATE.DUPLICATE.with(template.id),
      types.PERFORMANCE_REVIEWS.TEMPLATE
    );

    this.goToTemplate(model.id);
  }

  @action goToTemplate(id) {
    this.history.push(`/question_sets/${id}`);
  }
}

export default TemplateState;
