import {QAAnswer} from 'stores/questions';
import {observable, action} from 'mobx';
import Question from './Question';
import AttachedDocument from 'stores/documents/AttachedDocument';
import _ from 'lodash';

class Answer extends QAAnswer {
  @observable attachment;

  @action merge(other) {
    super.merge(other, {
      attachment: AttachedDocument,
      question: Question
    });
  }

  @action removeFile() {
    this.attachment = null;
  }

  @action mergeFile(file) {
    this.merge({attachment: file});
  }

  @action updateFile(files) {
    const file = _.head(files);
    file ? this.mergeFile(file) : this.removeFile();
  }
}

export default Answer;
