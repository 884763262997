import {DomainObject} from 'shared/store';
import {action, observable, computed} from 'mobx';

class Session extends DomainObject {
  @observable id;
  @observable email;
  @observable password;
  @observable currentUserId;
  @observable cellphone;
  @observable mfaRequired;
  @observable mfaType;
  @observable mfaToken;
  @observable recoveryCode;
  @observable rememberDevice = false;

  @action clearFields() {
    this.merge({
      password: null,
      mfaRequired: false,
      mfaToken: null,
      mfaType: null,
      cellphone: null,
      recoveryCode: null,
      rememberDevice: false
    });
  }

  @computed get isSms() {
    return this.mfaType === 'sms';
  }

  @computed get isAuthenticator() {
    return this.mfaType === 'authenticator';
  }
}

export default Session;
