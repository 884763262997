import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {User} from 'stores/users';
import {fullName} from 'shared/tools';

class CustomReportShare extends DomainObject {
  @observable id;
  @observable user;
  @observable _destroy;

  @action merge(other) {
    super.merge(other, {
      user: User
    });
  }

  @computed get userId() {
    return this.user.id;
  }

  @computed get name() {
    return fullName(this.user);
  }
}

export default CustomReportShare;
