import React from 'react';
import {Button, Panel} from 'components';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import PreviewInviteModal from './PreviewInviteModal';
import ActivateEmailModal from './ActivateEmailModal';

const StepThree = observer(({uiState}) => {
  return (
    <Panel className={`my2 `} style={uiState.stepTwoComplete ? {} : { boxShadow: 'none', backgroundColor: 'transparent', border: 'none' }}>
      <div className='clearfix table'>
        <div className='col-8 table-cell align-middle pr2'>
          <div className={`${uiState.hasEmployees ? 'jumbo' : 'waterloo'}`}>
            <FormattedMessage id='company_onboard.STEP_NUMBER' values={{number: 3}} />
          </div>
          <div className={`h3 py1 medium ${uiState.hasEmployees ? '' : 'jumbo'}`}>
            <FormattedMessage id='company_onboard.Invite Employees.' />
          </div>
          <div className={`${uiState.hasEmployees ? 'jumbo' : 'waterloo'}`}>
            <FormattedMessage id='company_onboard.Invite your employees to update their contact info and populate your employee directory.' />
          </div>
        </div>
        <div className='col-4 table-cell align-middle pl2 center'>
          {uiState.stepTwoComplete && <Button onClick={() => uiState.openPreviewInvite()}>
            <FormattedMessage id='company_onboard.Preview Invite' />
          </Button>}
        </div>
        <PreviewInviteModal uiState={uiState} />
        <ActivateEmailModal uiState={uiState} />
      </div>
    </Panel>
  );
});

export default StepThree;
