import {observable, action} from 'mobx';

class ReminderDirectoryState {
  store;
  history;
  match;

  @observable reminders = [];

  constructor(store, history, match) {
    this.store = store;
    this.history = history;
    this.match = match;
  }

  @action async load() {
    await this.store.load();
    this.reminders = this.store.getReminders();
  }

  @action goToNew() {
    this.history.push('/reminders/new');
  }

  async removeReminder(reminder) {
    await this.store.destroy(reminder);
    await this.load();
  }
}

export default ReminderDirectoryState;
