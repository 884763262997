import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import {InteractiveContext, Pagination, Avatar} from 'components';
import {calendarDate} from 'shared/tools';
import {endpoints, types} from 'shared/core';
import {SurveyAnswer} from 'stores/surveys';
import _ from 'lodash';

const Answers = observer(({models}) => {
  return (
    <React.Fragment>
      {models.map(({employee, submittedAt}, index) =>
        <div className='clearfix flex mb2' key={index}>
          <div className='mr2'>
            <Avatar employee={employee} size='sm' className='left'/>
          </div>
          <div className='flex jumbo align-items-center'>
            {`${employee.name} · ${calendarDate(submittedAt)}`}
          </div>
        </div>
      )}
    </React.Fragment>
  );
});

const ReviewersModal = observer(({uiState}) => {
  const {questionId, contentFilter, periodDateFilter, reviewersModalOpen} = uiState;
  if (!reviewersModalOpen) return null;

  return (
    <Modal
      size='sm'
      title={contentFilter}
      translateTitle={false}
      isOpen={uiState.reviewersModalOpen}
      onHide={() => uiState.closeReviewersModal()}>
      <InteractiveContext
        proxy={{
          type: 'async',
          endpoint: endpoints.SURVEYS.ANSWERS.WITHOUT_PAGINATION.with(
            {
              questionId, contentFilter, periodDateFilter
            }
          ),
          modelType: types.SURVEYS.ANSWER,
          model: SurveyAnswer,
          pageSize: 25
        }}
      >
        <Answers/>
        <Pagination/>
      </InteractiveContext>
    </Modal>
  );
});

export default ReviewersModal;
