import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {withState, endpoints, types, t} from 'shared/core';
import {Layout, InteractiveTable, DropdownFilter, Tag} from 'components';
import {SurveyDirectoryState} from '../state';
import SurveySubnav from 'surveys/SurveySubnav';
import {Survey} from 'stores/surveys';
import {FormattedMessage} from 'react-intl';
import SurveyAnonymousTag from './SurveyAnonymousTag';
import CloseSurveyModal from './CloseSurveyModal';
import RelaunchSurveyModal from './RelaunchSurveyModal';
import ReopenSurveyModal from './ReopenSurveyModal';

const Name = observer(({model, uiState}) => {
  return (
    <div>
      <div className='table-cell align-middle'>
        <Link className='TextLink' to={uiState.surveyPath(model)}>
          {model.name ? model.name : <FormattedMessage id='surveys.Untitled survey'/>}
        </Link>
      </div>
      <div className='table-cell align-middle'>
        {model.state === 'created' && <Tag className='ml1'><FormattedMessage id='surveys.directory.Draft'/></Tag>}
        <SurveyAnonymousTag survey={model} className='ml1'/>
      </div>
    </div>
  );
});

const StatusSummary = observer(({model}) => {
  if (!model.totalStatusCount) {
    return (
      <FormattedMessage id='surveys.directory.No employees have been assigned this survey'/>
    );
  }

  return (
    <FormattedMessage
      id='surveys.directory.COMPLETED_BY_EMPLOYEES'
      values={{
        completeStatusCount: model.completeStatusCount,
        totalStatusCount: model.totalStatusCount
      }}
    />
  );
});

const COLUMNS = [
  {
    header: 'surveys.directory.Name',
    component: Name,
    sortBy: 'name',
    width: 4
  },
  {
    header: 'surveys.directory.Status',
    component: StatusSummary,
    width: 4
  },
  {
    header: 'surveys.directory.Launch Date',
    attribute: 'launchedAtView',
    sortBy: 'launchedAt',
    width: 2
  },
  {
    header: 'surveys.directory.Relaunched',
    render: model => model.relaunched && <FormattedMessage id='surveys.directory.Yes'/>,
    width: 1
  }
];

 const LOCALIZATION = {
  removeModal: {
    header: 'surveys.directory.Remove Survey?',
    subHeader: 'surveys.directory.Are you sure you want to remove this survey?'
  },
  emptyState: 'surveys.directory.No surveys to display',
  interactive: {
    addModel: 'surveys.directory.Create New Survey'
  }
};

function statusFilters() {
  return [
    {
      id: 'active',
      display: t('surveys.directory.Active')
    },
    {
      id: 'closed',
      display: t('surveys.directory.Closed')
    }
  ];
}

const SurveyDirectory = observer(({uiState}) => {
  return (
    <div>
      <SurveySubnav title={t('surveys.Surveys')}/>
      <Layout>
        <InteractiveTable
          searchable
          saveStateToUrl
          uiState={uiState}
          title={t('surveys.Surveys')}
          columns={COLUMNS}
          localization={LOCALIZATION}
          onRemove={survey => uiState.removeSurvey(survey)}
          editRoute='/manage/survey/:id/edit'
          customLinks={survey => uiState.customLinksFor(survey)}
          onMount={agent => uiState.setInteractiveAgent(agent)}
          onAdd={() => uiState.createSurvey()}
          proxy={{
            type: 'async',
            modelType: types.SURVEYS.SURVEY,
            model: Survey,
            endpoint: endpoints.SURVEYS.SURVEYS
        }}>
          {uiState.canViewClosed && <DropdownFilter options={statusFilters()} attr='status'/>}
        </InteractiveTable>
        <CloseSurveyModal uiState={uiState}/>
        <RelaunchSurveyModal uiState={uiState}/>
        <ReopenSurveyModal uiState={uiState}/>
      </Layout>
    </div>
  );
});

export default withState(SurveyDirectory, SurveyDirectoryState);
