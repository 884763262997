import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {SummaryContainerState} from '../state';
import Subnav from '../../Subnav';
import {Layout, Panel, OverviewPanel} from 'components';
import ReviewersModal from './ReviewersModal';
import QuestionSummaryFactory from './QuestionSummaryFactory';

const SummaryContainerWithState = withState(observer(({uiState}) => {
  const {questionSummaries, survey} = uiState;

  return (
    <Layout>
      <Layout.Section>
        <Panel.Stack loose>
          <OverviewPanel
            title='surveys.overview.Survey Overview'
            completed={survey.completeStatusCount}
            total={survey.totalStatusCount}
            items={[
              {
                title: 'components.overview_panel.Launch Date',
                value: survey.launchedAtView
              }
            ]}
            tags={uiState.surveyTags}
          />
          {questionSummaries.map(questionSummary =>
            <QuestionSummaryFactory key={questionSummary.question.id} questionSummary={questionSummary} />)}
        </Panel.Stack>
        <ReviewersModal uiState={uiState} />
      </Layout.Section>
    </Layout>
  );
}), SummaryContainerState);

const SummaryContainer = observer(({match, history, scope, survey}) => {
  return (
    <div>
      <Subnav {...{survey, match, scope}} />
      <SummaryContainerWithState {...{match, history, scope, survey}} />
    </div>
  );
});

export default SummaryContainer;
