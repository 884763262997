import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Button} from 'components';
import {AlertModal} from 'components/modals';

const InterestedInBenefitsModal = observer(({uiState}) => {
  const {employee} = uiState;

  return (
    <AlertModal
      size='sm'
      header='employees.hire.benefits.not_enabled.Great!'
      isOpen={uiState.interestedInBenefitsModalOpen}
      onHide={() => uiState.closeInterestedInBenefitsModal()}
    >
      <div className='mb3 space-y jumbo'>
        <div><FormattedMessage id='employees.hire.benefits.not_enabled.Our benefits team has been notified and will contact you shortly.'/></div>
        <div><FormattedMessage id='employees.hire.benefits.not_enabled.INTEREST_MODAL_MESSAGE' values={{employeeName: employee.name}}/></div>
      </div>
      <Button className='right' onClick={() => uiState.closeInterestedInBenefitsModal()}>
        <FormattedMessage id='employees.hire.benefits.not_enabled.Got it!'/>
      </Button>
    </AlertModal>
  );
});

export default InterestedInBenefitsModal;
