import React from 'react';
import {observer} from 'mobx-react';
import {Button} from 'components';
import {FormattedMessage} from 'react-intl';

const StartScreen = ({uiState}) => {
  return (
    <div className='center'>
      <div className='my3 max-width-1 mx-auto jumbo'>
        <FormattedMessage id='employees.google_import.GOOGLE_IMPORT_START'/>
      </div>
      <Button className='mb3' onClick={() => uiState.signIn()}>
        <FormattedMessage id='employees.google_import.Sign in to Google'/>
      </Button>
    </div>
  );
};

export default observer(StartScreen);
