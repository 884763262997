import React from 'react';
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import SettingsIndex from './SettingsIndex';
import UsersSettingsContainer from './users/UsersSettingsContainer';

const BrokerSettingsContainer = observer(({match}) => {
  return (
    <Switch>
      <Route path='/' exact component={SettingsIndex}/>
      <Route path='/users' component={UsersSettingsContainer}/>
    </Switch>
  );
});

const BrokerSettingsPage = observer(() => {
  return (
    <Router basename='/broker_portal/settings'>
      <Route path='/' component={BrokerSettingsContainer} />
    </Router>
  );
});

export default BrokerSettingsPage;
