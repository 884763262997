import React from 'react';
import {observer} from 'mobx-react';

const PanelHeader = observer(({title, description, actions, children}) => {
  const hasChildren = React.Children.count(children) > 0;

  return (
    <div className='PanelHeader clearfix table'>
      {title && (
        <div className={`col-${hasChildren ? '8' : '12'} table-cell`}>
          <div className='PanelTitle'>
            {title}
            {actions}
          </div>
          {description && (
            <div className='jumbo mt1 whitespace-pre-line'>
              {description}
            </div>
          )}
        </div>
      )}
      {hasChildren && (
        <div className={`col-${title ? '4' : '12'} table-cell align-middle right-align relative`}>
          {children}
        </div>
      )}
    </div>
  );
});

export default PanelHeader;
