import React from 'react';
import {observer} from 'mobx-react';
import moment from 'moment';

const HolidayDay = observer(({model}) => {
  return (
    <div>{moment(model.date).format('ddd')}</div>
  );
});

export default HolidayDay;
