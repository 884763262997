import {DomainObject} from 'shared/store';
import {observable, action} from 'mobx';
import Role from './Role';

class RoleAssignment extends DomainObject {
  @observable id;
  @observable role;

  @action merge(other) {
    super.merge(other, {
      role: Role
    });
  }
}

export default RoleAssignment;
