import {DomainObject, oneOf} from 'shared/store';
import {action, observable, computed} from 'mobx';
import _ from 'lodash';
import {CustomFieldOption} from 'stores/custom_fields';

class EmployeeCustomField extends DomainObject {
  @observable id;
  @observable value;
  @observable type;
  @observable name;
  @observable description;
  @observable required;
  @observable category;
  @observable options = [];
  @observable customFieldGroupId;
  @observable createdAt;
  @observable order;

  @action merge(other) {
    super.merge(other, {
      options: [CustomFieldOption],
      type: oneOf(['text_field', 'text_area', 'date', 'number', 'multiple_choice']),
      _dates: this.type === 'date' ? ['value'] : []
    });
  }

  @computed get sortedOptions() {
    return _.sortBy(this.options, 'id');
  }

  @computed get isDate() {
    return this.type === 'date';
  }
}

export default EmployeeCustomField;
