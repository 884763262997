import {DomainObject} from 'shared/store';
import {observable, computed, action} from 'mobx';
import Question from './Question';
import {t} from 'shared/core';
import _ from 'lodash';

class PositionRule extends DomainObject {
  @observable id;
  @observable question = null;
  @observable disqualifiers = [];

  @action merge(other) {
    super.merge(other, {
      question: Question
    });
  }

  @action validate() {
    const errors = {};

    if (_.isEmpty(this.disqualifiers)) {
      errors.disqualifiers = t("recruiting.edit.steps.errors.position_rule.Must select at least one disqualifier");
    }
    if (!this.question) {
      errors.question = t("recruiting.edit.steps.errors.position_rule.Question can't be blank");
    }

    return errors;
  }

  @computed get formattedDisqualifiers() {
    return _.join(this.disqualifiers, ', ');
  }
}

export default PositionRule;
