import React from 'react';
import {observer} from 'mobx-react';
import Subnav from '../../Subnav';
import CycleResultsContainerWithState from './CycleResultsContainerWithState';

const CycleResultsContainer = observer(({cycle, match, scope}) => {
  return (
    <div>
      <Subnav title={cycle.name} match={match} scope={scope}/>
      <CycleResultsContainerWithState match={match}/>
    </div>
  );
});

export default CycleResultsContainer;
