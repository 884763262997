import {DomainObject} from 'shared/store';
import {action, observable} from 'mobx';
import {Company} from 'stores/users';

class GoogleCredentials extends DomainObject {
  @observable domain;
  @observable company;

  @action merge(other) {
    super.merge(other, {
      company: Company
    });
  }}

export default GoogleCredentials;
