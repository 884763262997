import _ from 'lodash';
import {api} from 'shared/core';
import {createStore} from 'shared/store';
import moment from 'moment';
import hash from 'object-hash/index';
import {toJS} from 'mobx';
import {allowError} from 'shared/tools';

function getReport(response, view) {
  const store = createStore();
  const links = response.data.links || {};
  store.sync(response.data);
  return {
    rows: store.getAll('reportRows'),
    csvUrl: links.export,
    nextLink: links.next,
    meta: response.data.meta,
    reportType: view
  };
}

// takes a value and converts it to a string to be sent to server
export function formatFilterDates(filters) {
  return _.mapValues(filters, value => {
    if (_.isDate(value)) {
      return moment(value).format('YYYY-MM-DD');
    }
    return value;
  });
}

const ROOT_PARAMS = ['date', 'startDate', 'endDate', 'page', 'size', 'filter'];

function transformFilters(filters) {
  const formattedFilters = formatFilterDates(filters);
  const payload = {
    filter: []
  };

  _.forOwn(formattedFilters, (value, key) => {
    if (_.includes(ROOT_PARAMS, key)) {
      payload[_.snakeCase(key)] = value;
    }
  });

  return payload;
}

export async function fetchReport({url, filters, view}) {
  const [
    highcharts,
    highchartsReact,
    highchartsExporting,
    highchartsOfflineExporting,
    response
  ] = await Promise.all([
    import(/* webpackChunkName: 'highcharts-async' */ 'highcharts'),
    import(/* webpackChunkName: 'highcharts-react-official-async' */ 'highcharts-react-official'),
    import('highcharts/modules/exporting'),
    import('highcharts/modules/offline-exporting'),
    api.post(
      url, transformFilters(filters), {
        validateStatus: allowError(504)
      }
    )
  ]);

  if (response.status === 504) {
    return {
      status: 'timeout'
    };
  }

  highchartsExporting.default(highcharts.default);
  highchartsOfflineExporting.default(highcharts.default);

  const report = getReport(response, view);
  return {
    ...report,
    Highcharts: highcharts.default,
    HighchartsReact: highchartsReact.default
  };
}

export function shouldRefetchReport(props, nextProps) {
  const filters = toJS(props.filters);
  const nextFilters = toJS(nextProps.filters);

  return hash(filters) !== hash(nextFilters);
}

export const DEFAULT_DATE = moment().toDate();
export const DEFAULT_START_DATE = moment().startOf('year').toDate();
export const DEFAULT_END_DATE = moment().endOf('year').toDate();
export const RELATIVE_START_DATE = moment().subtract(12, 'months').startOf('month').toDate();
export const CURRENT_MONTH_START_DATE = moment().startOf('month').toDate();
export const RELATIVE_END_DATE = moment().endOf('month').toDate();
