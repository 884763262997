import {observable} from 'mobx';

class CompanyDocumentViewModel {
  @observable uiState;
  @observable companyDocument;
  @observable checked = false;

  constructor(companyDocument, uiState) {
    this.companyDocument = companyDocument;
    this.uiState = uiState;
  }
}

export default CompanyDocumentViewModel;
