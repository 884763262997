import React from 'react';
import {withRouter} from 'react-router-dom';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import {ActionLink} from 'components';
import _ from 'lodash';

const StageButton = withRouter(observer(({stage, filter, filterUpdated, filterDeleted, withSpacer}) => {
  const _handleSelection = (stageId, checked) => {
    if (checked) {
      filterUpdated({candidate_stage: stageId});
    } else {
      filterDeleted('candidate_stage');
    }
  };

  return (
    <ActionLink onClick={() => _handleSelection(stage.id, !_.split(filter.get('candidate_stage'), ',').includes(stage.id))}
             className={`FunnelItem ${_.split(filter.get('candidate_stage'), ',').includes(stage.id) ? 'selected': ''} ${withSpacer ? 'with-spacer' : ''}`}>
        <div className={`FunnelItem-number ${stage.stateChanged}`}>{stage.activeCandidatesCount}</div>
        <div className='FunnelItem-title'>
          {stage.name}
        </div>
        {!stage.isRequired && <div className='FunnelItem-arrow'/>}
    </ActionLink>
  );
}));

class StageButtonList extends React.Component {
  @observable scrollState = {
    showScrolling: false,
    scrolling: null
  }

  componentDidMount() {
    const {stages, finalStages} = this.props.uiState.position;
    const numberOfFunnels = stages.length + finalStages.length;
    const width = this.funnel.getBoundingClientRect().width;
    const stageButtonWidth = 131;
    const dividerWidth = 32;
    const dividerCount = 2;
    const rightMargin = 16;
    if (numberOfFunnels * stageButtonWidth + dividerWidth * dividerCount - rightMargin > width) {
      this.scrollState.showScrolling = true;
    }
  }

  scrollLeft = (e) => {
    e.preventDefault();
    const funnel = this.funnel;
    const scrolling = setInterval(function() {
      funnel.scrollLeft -= 15;
    }, 26);

    this.scrollState.scrolling = scrolling;
  }

  scrollRight = (e) => {
    e.preventDefault();
    const funnel = this.funnel;
    const scrolling = setInterval(function() {
      funnel.scrollLeft += 15;
    }, 26);

    this.scrollState.scrolling = scrolling;
  }

  clearScrolling = () => {
    clearInterval(this.scrollState.scrolling);
  }

  render() {
    const {uiState, filter, filterUpdated, filterDeleted} = this.props;
    const {position} = uiState;
    const {scrollLeft, scrollRight, clearScrolling, scrollState} = this;

    return (
      <div className='Funnel'>
        {scrollState.showScrolling && <ActionLink href='#' className='FunnelScroll-left jumbo' onMouseEnter={scrollLeft} onMouseLeave={clearScrolling}><i className='material-icons'>{'keyboard_arrow_left'}</i></ActionLink>}
        <div className='FunnelCarousel' ref={(funnel) => this.funnel = funnel}>
          {position.funnelStages.map((stage, index) => <StageButton key={stage.id} stage={stage} filter={filter} filterUpdated={filterUpdated} filterDeleted={filterDeleted} withSpacer={index === position.stages.length - 1}/>)}
        </div>
        {scrollState.showScrolling && <ActionLink href='#' className='FunnelScroll-right jumbo' onMouseEnter={scrollRight} onMouseLeave={clearScrolling}><i className='material-icons'>{'keyboard_arrow_right'}</i></ActionLink>}
      </div>
    );
  }
}

export default withRouter(observer(StageButtonList));
