import React from 'react';
import {observer} from 'mobx-react';

const ItemContent = observer(({model, sortedModels, display, component, errors}) => {
  if (!display && !component) {
    throw new Error('Either display or component must be provided.');
  }

  if (display) {
    return (
      <span className='mr1'>
        {model[display]}
      </span>
    );
  }

  const Component = component;

  return (
    <Component sortedModels={sortedModels} model={model} errors={errors}/>
  );
});

export default ItemContent;
