import React from 'react';
import _ from 'lodash';
import {t, types, endpoints, withState} from 'shared/core';
import {observer} from 'mobx-react';
import {InteractiveTable, DropdownFilter} from 'components';
import User from 'stores/users/User';
import UsersState from './UsersState';
import EditUserRolesModal from './EditUserRolesModal';
import PasswordResetModal from './PasswordResetModal';
import ResetMFAModal from './ResetMFAModal';
import UpdateLoginEmailModal from './UpdateLoginEmailModal';
import USER_COLUMNS from './tools';

const EXTERNAL_USER_LIST_LOCALIZATION = {
  removeModal: {
    header: 'company_settings.external_users.Remove External User from Collage?',
    subHeader: 'company_settings.external_users.Are you sure you want to remove this external user from Collage?',
    body: 'company_settings.external_users.This user will no longer have access to Collage.'
  }
};

const UsersContainer = observer(({uiState}) => {
  return (
    <div>
      <InteractiveTable
        searchable
        title={t('company_settings.users.Users')}
        columns={USER_COLUMNS}
        showLinks={true}
        onRemove={(user) => uiState.destroyUser(user)}
        customLinks={user => uiState.linksForUser(user)}
        localization={EXTERNAL_USER_LIST_LOCALIZATION}
        onMount={agent => uiState.setAgent(agent)}
        proxy={{
          type: 'async',
          modelType: types.USER,
          model: User,
          endpoint: endpoints.USER_DIRECTORY,
        }}
      >
        <DropdownFilter options={uiState.filterOptions()} attr='terminated'/>
      </InteractiveTable>
      <EditUserRolesModal uiState={uiState}/>
      <PasswordResetModal uiState={uiState}/>
      <UpdateLoginEmailModal uiState={uiState}/>
      <ResetMFAModal uiState={uiState} />
    </div>
  );
});

export default withState(UsersContainer, UsersState);
