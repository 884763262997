import {observable, action} from 'mobx';

class RevieweeDirectoryState {
  interactiveAgent;
  history;

  @observable cycle;
  @observable reviewerSelectionModalOpen = false;
  @observable selectedReviewee;

  receiveProps({cycle, history}) {
    this.cycle = cycle;
    this.history = history;
  }

  setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  @action selectReviewers(reviewee) {
    this.selectedReviewee = reviewee;
    this.reviewerSelectionModalOpen = true;
  }

  @action closeReviewerSelectionModal() {
    this.reviewerSelectionModalOpen = false;
  }

  @action onReviewersSelected(reviewee) {
    this.reviewerSelectionModalOpen = false;
    this.interactiveAgent.refresh();
  }

  @action goToEditParticipants() {
    this.history.push(`/reviews/${this.cycle.id}/edit/participants`);
  }
}

export default RevieweeDirectoryState;
