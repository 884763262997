import React from 'react';
import Button from './buttons/Button';
import Panel from './panels/Panel';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';
import PropTypes from 'prop-types';
import PlaceholderHelper from 'img/placeholder-helper.png';

const EmptyState = ({img = PlaceholderHelper, title, descriptionItems, callToAction, learnMoreUrl}) => {
  return (
    <Panel>
      <div className='p3 mx1'>
        <img src={img} width='243' className='mb2' alt='empty-state'/>
        <div className='h1 black medium my2'>{title}</div>
        {descriptionItems.map((d, index) => <div key={index} className='h3 jumbo my2 pb1'>{d}</div>)}
        {callToAction && (
          <Button size='lg' onClick={callToAction.onClick}>
            {callToAction.title ? callToAction.title : t('components.empty_state.Get Started')}
          </Button>
        )}
        {learnMoreUrl && (
          <div className='mt2'>
            <a href={learnMoreUrl} target='_blank'>
              <FormattedMessage id='components.empty_state.Learn More'/>
            </a>
          </div>
        )}
      </div>
    </Panel>
  );
};

// EmptyState is a component used to display empty states in a page

// props:

// - img: url of image that describes the feature

// - title: title of the empty state

// - descriptionItems: description of what needs to be done to remove the empty state;
//   an array of paragraphs

// - callToAction: {title, onClick} - describes the CTA button

// ex:
// [
//   {url: "www.collage.co", title: "Collage Website"}
//   {url: "www.google.ca", title: "Search for answers"}
// ]

EmptyState.propTypes = {
  callToAction: PropTypes.shape({
    onClick: PropTypes.func,
    title: PropTypes.string
  })
};

EmptyState.defaultProps = {
  descriptionItems: []
};

export default EmptyState;
