import {action, observable} from 'mobx';
import {DomainObject} from 'shared/store';
import Employee from 'stores/employees/Employee';

class TaxDocumentAssignment extends DomainObject {
  @observable id;
  @observable sendFederalTaxForm = false;
  @observable sendProvincialTaxForm = false;
  @observable employees = [];
  @observable dueDate;

  @action merge(other) {
    super.merge(other, {
      employees: [Employee],
      _dates: ['dueDate']
    });
  }
}

export default TaxDocumentAssignment;
