import React from 'react';
import CandidateForm from './CandidateForm';
import CandidateSubnav from './CandidateSubnav';

const CandidateContainer = ({history, match}) => {
  return (
    <div>
      <CandidateSubnav history={history} />
      <CandidateForm history={history} match={match} enforceRequiredFields={false}/>
    </div>
  );
};

export default CandidateContainer;
