import React from 'react';
import {observer} from 'mobx-react';
import TimeOffSubnav from 'containers/time_off/TimeOffSubnav';
import TimeOffTypesWithStateContainer
  from 'containers/time_off/components/types/components/TimeOffTypesWithStateContainer';

const TypesContainer = observer(({scope}) => {
  return (
    <div>
      <TimeOffSubnav scope={scope} />
      <TimeOffTypesWithStateContainer />
    </div>
  );
});

export default TypesContainer;
