import React from 'react';
import {observer} from 'mobx-react';
import {Layout, InteractiveTable} from 'components';
import {Assignee} from 'components/tasks';
import {DueDateView} from 'components/tasks';
import {types, endpoints, withDisplay, withState, t} from 'shared/core';
import SubNav from 'company_settings/SubNav';
import {TasksDirectoryState} from '../state';
import {TaskTemplate} from 'stores/tasks';

const COLUMNS = [
  {
    header: 'company_settings.tasks.Name',
    attribute: 'name',
    width: 4
  },
  {
    header: 'company_settings.tasks.Assignee',
    component: Assignee,
    width: 4
  },
  {
    header: 'tasks.Due',
    component: DueDateView,
    width: 3
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'company_settings.tasks.Remove Task Template?',
    subHeader: 'company_settings.tasks.Are you sure you want to remove this task template?'
  },
  emptyState: 'company_settings.tasks.No tasks to display',
  interactive: {
    addModel: 'company_settings.tasks.+ Add Task Template'
  }
};

const TasksDirectory = observer(({uiState}) => {
  const {config} = uiState;

  return (
    <Layout>
      <Layout.Section>
        <InteractiveTable
          uiState={uiState}
          title={t(`tasks.${uiState.config.title}`)}
          columns={COLUMNS}
          localization={LOCALIZATION}
          onRemove={(taskTemplate) => uiState.removeTaskTemplate(taskTemplate)}
          onAdd={() => uiState.goToAddTaskTemplate()}
          editRoute={`/tasks/edit/${config.templateType}/:id`}
          proxy={{
            type: 'async',
            modelType: types.TASK_TEMPLATE,
            model: TaskTemplate,
            endpoint: endpoints.TASK_TEMPLATES.withType(config.templateType)
          }}
        />
      </Layout.Section>
    </Layout>
  );
});

export default withDisplay(withState(TasksDirectory, TasksDirectoryState), () => <SubNav title={t('company_settings.subnav.Task Templates')}/>);
