import React from 'react';
import {observer} from 'mobx-react';
import {t, withState, types, endpoints} from 'shared/core';
import {EmptyState, Spinner, Pagination, InteractiveContext} from 'components';
import PayrollUpdateDigest from './PayrollUpdateDigest';
import {PayrollUpdatesListState} from '../state';
import {PayrollUpdate} from 'stores/payroll';

const PayrollUpdatesEmptyState = ({status}) => {
  return (
    <EmptyState
      title={t('payroll_updates.Payroll Updates')}
      descriptionItems={[t(`payroll_updates.empty_state.${status}`)]}
    />
  );
};

const PayrollUpdatePanel = observer(({uiState}) => {
  return (
    uiState.payrollUpdatesGroupedByDate.map(payrollUpdate =>
      <PayrollUpdateDigest key={payrollUpdate.date} uiState={uiState} payrollUpdate={payrollUpdate}/>
    )
  );
});

const PayrollUpdatesList = observer(({uiState}) => {
  const {status, isLoading, payrollUpdatesGroupedByDate} = uiState;

  if (isLoading) return <Spinner/>;
  if (!payrollUpdatesGroupedByDate.length) return <PayrollUpdatesEmptyState status={status}/>;

  return (
    <div className='relative'>
      <InteractiveContext
        proxy={{
          type: 'async',
          modelType: types.PAYROLL_UPDATE,
          model: PayrollUpdate,
          endpoint: endpoints.PAYROLL_UPDATES[uiState.status.toUpperCase()],
          pageSize: 50
        }}
        onRefresh={({data}) => uiState.onPagination(data)}>
        <PayrollUpdatePanel uiState={uiState}/>
        <Pagination />
      </InteractiveContext>
    </div>
  );
});

export default withState(PayrollUpdatesList, PayrollUpdatesListState);
