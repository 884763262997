import {DomainStore, fetchModels} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {endpoints, types, t} from 'shared/core';
import {
  TimeTrackingPolicy,
  TimeTrackingPaySchedule,
  TimeTrackingPayPeriod
} from 'stores/time_tracking';
import {Employee} from 'stores/employees';
import {User} from 'stores/users';
import _ from 'lodash';

class PolicyEditFlowState {
  store = new DomainStore();
  match;
  history;

  @observable policy;
  @observable errors = {};
  @observable availableEmployees = [];
  @observable selectedEmployees = [];
  @observable paySchedules = [];
  @observable selectedPaySchedule;
  @observable newPayPeriod = new TimeTrackingPayPeriod();
  @observable calculatedPayPeriod = new TimeTrackingPayPeriod();
  @observable assignee = {};
  @observable isCreatingNewPaySchedule = false;
  @observable dailyOvertimeEnabled = true;
  @observable weeklyOvertimeEnabled = true;
  @observable doubleOvertimeEnabled = true;

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    await this.store._compose([
      endpoints.TIME_TRACKING.POLICIES.with(this.match.params.id)
    ]);

    this.policy = new TimeTrackingPolicy(this.store._getSingle(types.TIME_TRACKING.POLICY));
    this.initializeOvertimeFlags();
  }

  @action async onStepSubmitted({location}) {
    switch (location) {
      case 'name': return this.submitName();
      case 'employees': return this.submitEmployees();
      case 'overtime': return this.submitOvertime();
      case 'pay_schedule': return this.submitPaySchedule();
      case 'assignee': return this.submitAssignee();
      case 'review': return this.configurePolicy();
      default:
        throw new Error(`Location ${location} is not supported`);
    }
  }

  @action async submitStep(props) {
    const policy = this.policy.pick(props);
    const {model, errors} = await this.store.patch(policy);

    this.errors = errors;
    if (!model) return false;

    this.policy.update(model);
    return true;
  }

  @action async onStepChanged({location}) {
    switch (location) {
      case 'pay_schedule':
        await this.initializePaySchedules();
        break;
      case 'employees':
        await this.initializeEmployees();
        break;
      case 'assignee':
        await this.initializeAssignees();
        break;
      default:
        break;
    }
  }

  @action async submitName() {
    return this.submitStep(['name']);
  }

  @action async submitOvertime() {
    const props = [];

    if (this.dailyOvertimeEnabled) {
      props.push('dailyOvertimeThreshold');
    }

    if (this.weeklyOvertimeEnabled) {
      props.push('weeklyOvertimeThreshold');
    }

    if (this.doubleOvertimeEnabled) {
      props.push('dailyDoubleOvertimeThreshold');
    }

    props.push('overtimePolicy', 'weekStartDay');

    return this.submitStep(props);
  }

  @action async initializeEmployees() {
    this.isCreatingNewPaySchedule = false;
    this.selectedEmployees = this.policy.employees;

    await this.store._compose([
      endpoints.TIME_TRACKING.POLICIES.ELIGIBLE_EMPLOYEES.with(this.policy.id)
    ]);
    this.availableEmployees = this.store._getAll(types.EMPLOYEE, Employee);
  }

  @action async initializePaySchedules() {
    await this.store._compose([
      endpoints.TIME_TRACKING.PAY_SCHEDULES.ALL
    ]);
    this.paySchedules = this.store._getAll(types.TIME_TRACKING.PAY_SCHEDULE).map(e => new TimeTrackingPaySchedule(e, this));
    this.selectedPaySchedule = this.policy.paySchedule;
    if (_.isEmpty(this.paySchedules)) {
      this.isCreatingNewPaySchedule = true;
      this.selectedPaySchedule = new TimeTrackingPaySchedule();
    }
  }

  @action async initializeAssignees() {
    await this.store._compose([
      endpoints.USERS.ACTIVE
    ]);

    const users = this.store._getAll(types.USER).map(u => new User(u));

    this.availableAssignees = [
      {
        id: 'manager', type: 'manager', name: t('time_tracking.manage.policies.edit.Manager')
      },
      ...users.map(u => {
        return { id: u.id, type: 'specific_user', user: u, name: u.name };
      })
    ];

    this.assignee = _.find(this.availableAssignees, a => {
      return (this.policy.assigneeType === 'manager' && a.type === 'manager') ||
        (this.policy.assigneeType === 'specific_user' && a.id === this.policy.assigneeUser.id);
    });
  }

  @action async payScheduleSelected(payScheduleId) {
    if (payScheduleId === '-1') {
      this.isCreatingNewPaySchedule = true;
      this.selectedPaySchedule = new TimeTrackingPaySchedule();
      this.newPayPeriod = new TimeTrackingPayPeriod();
    } else {
      this.isCreatingNewPaySchedule = false;
      this.selectedPaySchedule = _.find(this.paySchedules, {id: payScheduleId});
    }
  }

  @action initializeOvertimeFlags() {
    this.dailyOvertimeEnabled = !!this.policy.dailyOvertimeThreshold;
    this.weeklyOvertimeEnabled = !!this.policy.weeklyOvertimeThreshold;
    this.doubleOvertimeEnabled = !!this.policy.dailyDoubleOvertimeThreshold;
  }

  @action toggleDailyOvertime(enabled) {
    this.dailyOvertimeEnabled = enabled;
    if (!enabled) {
      this.policy.dailyOvertimeThreshold = null;
      if (this.doubleOvertimeEnabled) this.toggleDoubleOvertime(false);
    } else {
      this.policy.dailyOvertimeThreshold = 8;
    }
  }

  @action toggleWeeklyOvertime(enabled) {
    this.weeklyOvertimeEnabled = enabled;
    if (!enabled) {
      this.policy.weeklyOvertimeThreshold = null;
    } else {
      this.policy.weeklyOvertimeThreshold = 40;
    }
  }

  @action toggleDoubleOvertime(enabled) {
    this.doubleOvertimeEnabled = enabled;
    if (!enabled) {
      this.policy.dailyDoubleOvertimeThreshold = null;
    } else {
      this.policy.dailyDoubleOvertimeThreshold = 12;
    }
  }

  @action updateSelectedEmployees(employees) {
    this.selectedEmployees.replace(employees);
  }

  @action updateOvertimePolicy(overtimePolicy) {
    this.policy.overtimePolicy = overtimePolicy;

    if (overtimePolicy !== 'custom') {
      this.toggleWeeklyOvertime(false);
      this.toggleDailyOvertime(false);
      this.toggleDoubleOvertime(false);
    }

    if (overtimePolicy === 'standard' || overtimePolicy === 'custom') {
      this.policy.weekStartDay = this.policy.weekStartDay || 0;
    }

    if (overtimePolicy === 'exempt') {
      this.policy.weekStartDay = null;
    }
  }

  @action async submitEmployees() {
    this.policy.employees = this.selectedEmployees;

    return this.submitStep(['employees']);
  }

  @action selectAssignee(assignee) {
    this.policy.assigneeType = assignee.type;
    this.policy.assigneeUser = assignee.user;
    this.assignee = assignee;
  }

  @action async submitAssignee() {
    return this.submitStep(['assigneeType', 'assigneeUser']);
  }

  @action async calculatePayPeriods() {
    if (this.isCreatingNewPaySchedule && this.newPayPeriod.startDate && this.selectedPaySchedule.frequency) {
      const newPeriods = await this.fetchCalculatedPayPeriods();
      [this.newPayPeriod, this.calculatedPayPeriod] = newPeriods;
    }
  }

  async fetchCalculatedPayPeriods() {
    const periods = await fetchModels(
      endpoints.TIME_TRACKING.PAY_SCHEDULES.CALCULATE_INITIAL_PERIODS.with(
        this.newPayPeriod.startDate.toISOString(),
        this.selectedPaySchedule.frequency
      ),
      types.TIME_TRACKING.PAY_PERIOD
    );
    return _.orderBy(periods.map(model => new TimeTrackingPayPeriod(model)), 'startDate', 'asc');
  }

  @action async submitPaySchedule() {
    if (this.isCreatingNewPaySchedule) {
      this.selectedPaySchedule.payPeriods = [this.newPayPeriod];
    }
    this.policy.paySchedule = this.selectedPaySchedule;

    return this.submitStep(['paySchedule']);
  }

  @action async configurePolicy() {
    this.policy.configuringPolicy = true;
    const modelUpdated = await this.submitStep([
      'configuredAt',
      'configuringPolicy',
      'sendApprovedTimesheetEditedNotification',
      'sendPayPeriodEndedNotification',
      'sendEmployeeTimesheetReminder'
    ]);

    if (modelUpdated) window.location = '/time_tracking/manage';
  }

  @computed get displayPayPeriodDetails() {
    if (!this.selectedPaySchedule) return false;
    if (this.isCreatingNewPaySchedule) {
      return this.newPayPeriod && this.newPayPeriod.startDate && this.newPayPeriod.endDate && this.selectedPaySchedule.frequency;
    }
    return true;
  }

  @computed get latestPayPeriod() {
    if (!this.selectedPaySchedule) return null;
    if (this.isCreatingNewPaySchedule) {
      return this.newPayPeriod;
    }
    return this.selectedPaySchedule.currentPayPeriod || this.selectedPaySchedule.latestPayPeriod;
  }

  @computed get nextPayPeriod() {
    if (!this.selectedPaySchedule) return null;
    if (this.isCreatingNewPaySchedule) return this.calculatedPayPeriod;

    const {nextStartDate, nextEndDate} = this.latestPayPeriod || {};
    return nextStartDate && nextEndDate ? new TimeTrackingPayPeriod({startDate: nextStartDate, endDate: nextEndDate}) : null;
  }
}

export default PolicyEditFlowState;
