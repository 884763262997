import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const DeleteMetricModal = observer(({uiState, modalOpen}) => {
  return (
    <AlertModal
      size='sm'
      mode='danger'
      isOpen={modalOpen}
      onHide={() => uiState.closeDeleteMetricModal()}
      header='goals.Remove Metric?'
      subHeader='goals.Are you sure you want to remove this metric?'
      body='goals.This will also remove any progress updates associated with this metric.'>
      <ModalDangerButtons
        onSave={() => uiState.deleteMetric()}
        onCancel={() => uiState.closeDeleteMetricModal()} />
    </AlertModal>
  );
});

export default DeleteMetricModal;

