import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';

const Placeholder = observer(({placeholder, translate}) => {
  if (translate && _.isString(placeholder)) {
    return <FormattedMessage id={placeholder}/>;
  }

  return placeholder;
});

const Value = observer(({value, translate}) => {
  if (translate) {
    return <FormattedMessage id={value}/>;
  }

  return <span className='whitespace-pre-line'>{value}</span>;
});

const Label = observer(({model, path, render, placeholder, translate, translatePlaceholder}) => {
  const value = path ? _.get(model, path) : model;

  if (!value && value !== 0) {
    return <Placeholder placeholder={placeholder} translate={translatePlaceholder}/>;
  }

  if (render) {
    return <span className='whitespace-pre-line'>{render(value)}</span>;
  }

  return <Value value={value} translate={translate}/>;
});

Label.defaultProps = {
  translate: false,
  translatePlaceholder: true,
  placeholder: <span className='waterloo'>{'——'}</span>
};

export default Label;
