import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const CloseSurveyModal = observer(({uiState}) => {
  return (
    <Modal
      title='surveys.directory.close_survey.Close Survey'
      size='sm'
      isOpen={uiState.closeSurveyModalOpen}
      onHide={() => uiState.closeCloseSurveyModal()}
    >
      <div className='mb3 jumbo'>
        <FormattedMessage id={'surveys.directory.close_survey.Closed surveys will no longer be actionable but the results will remain visible to admins.'}/>
      </div>
      <ModalButtons
        saveCaption='surveys.directory.close_survey.Close'
        onSave={() => uiState.closeSurvey()}
        onCancel={() => uiState.closeCloseSurveyModal()}/>
    </Modal>
  );
});

export default CloseSurveyModal;
