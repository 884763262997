import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {AlertModal, ModalButtons} from 'components/modals';
import {Select2} from 'components';

const BulkMoveStageModal = observer(({uiState, modalOpen}) => {
  const {selectedCandidatesCount, newStageId, newStageOptions} = uiState;

  return (
    <AlertModal
      isOpen={modalOpen}
      onHide={() => uiState.closeBulkMoveStageModal()}
      size='sm'
    >
      <form>
        <div className='h3 pb2'>
          <FormattedMessage id={`recruiting.candidate.MOVE_CANDIDATES_HEADER`} values={{count: selectedCandidatesCount}}/>
        </div>
        <Select2
          value={newStageId}
          label="recruiting.candidate.New Stage"
          onChange={(e) => uiState.newStageId = e.target.value}
        >
          {newStageOptions.map(stage =>
            <option key={stage.id} value={stage.id}>{stage.name}</option>)}
        </Select2>
        <ModalButtons
          onSave={() => uiState.bulkMoveStage()}
          saveCaption='recruiting.candidate.Apply'
          onCancel={() => uiState.closeBulkMoveStageModal()}
        />
      </form>
    </AlertModal>
  );
});

export default BulkMoveStageModal;
