import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom';
import {withState, warnUnsavedChanges, t} from 'shared/core';
import {Avatar, Button, Panel, TextArea} from 'components';
import {fullName} from 'shared/tools';
import AwaitingTag from '../../AwaitingTag';
import MyCheckInDetailsContainerState from './state/MyCheckInDetailsContainerState';
import {Display} from 'components';

const CheckinHeader = observer(({checkin}) => {
  return (
    <div>
      <div className='table-cell align-middle'>
        <FormattedMessage id="performance.Check-In"/>
        <span className='waterloo pl1'>{checkin.date}</span>
      </div>
      <div className='table-cell align-middle px2'>
        <AwaitingTag status={checkin.completed ? null : 'awaiting'} />
      </div>
    </div>
  );
});

const CheckInDetailsForm = observer(({checkin, uiState}) => {
  return (
    <Panel className='lg-col-10'>
      <Panel.Header title={<CheckinHeader checkin={checkin}/>}/>
      {checkin.answers.map((answer, index) =>
        <div className='clearfix mb1' key={index}>
          <div className='medium'>{`${index + 1}. ${answer.question.text}`}</div>
          {
            checkin.completed ?
              (<div className='jumbo my2 fs-hide whitespace-pre-wrap'>{answer.content}</div>) :
              (
                <TextArea
                  value={answer.content || ''}
                  onChange={(e) => uiState.updateAnswerContent(e, answer)}
                  placeholder='performance.Add a response...'
                  className='border-none pl0 full-width jumbo'
                />
            )
          }
        </div>
      )}
      {!checkin.completed && <div className='right-align'>
        <Button onClick={uiState.onSubmit}>
          <FormattedMessage id="performance.Save"/>
        </Button>
      </div>}
      {checkin.completed && checkin.sharedReviewerNotes && <div className='border-top py3 fs-hide'>
        <div className='h3 medium'><FormattedMessage id='performance.Shared Feedback'/></div>
        <div className='h5 waterloo'>
          <FormattedMessage id="performance.FEEDBACK_FROM" values={{name: fullName(checkin.reviewer)}}/>
        </div>
        <div className='clearfix table'>
          <div className='col-1 table-cell align-top pt2'>
            <Avatar employee={checkin.reviewer} size='sm' className='left'/>
          </div>
          <div className='col-11 table-cell pt2 pr2'>
            <div className='h4 whitespace-pre-wrap'>{checkin.sharedReviewerNotes}</div>
          </div>
        </div>
      </div>}
    </Panel>
  );
});

const CheckInDetailsContainer = observer(({uiState}) => {
  const {checkin} = uiState;

  return (
    <div className='fs-hide'>
      <Display backPath='/checkins' backTitle={t('performance.My Check-Ins')}/>
      <div className='Container'>
        <div className='clearfix'>
          <CheckInDetailsForm
            checkin={checkin}
            uiState={uiState}
          />
        </div>
      </div>
    </div>
  );
});

export default warnUnsavedChanges(withState(withRouter(CheckInDetailsContainer), MyCheckInDetailsContainerState));
