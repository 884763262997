import React from 'react';
import {observer} from 'mobx-react';
import {auth} from 'shared/core';
import {Modal} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import ChequeHelper from 'img/cheque.png';
import ChequeHelperFr from 'img/cheque_fr.png';

const ChequeImage = observer(() => {
  let chequeSource = ChequeHelper;

  if (auth.locale === 'fr') {
    chequeSource = ChequeHelperFr;
  }

  return (
    <img src={chequeSource} alt='cheque' className='img-responsive'/>
  );
});

const BankingChequeHelperModal = observer(({uiState}) => {
  return (
    <Modal isOpen={uiState.bankingChequeHelperOpen} onHide={() => uiState.resetModals()} size='lg'>
      <div className='clearfix mxn2'>
        <div className='h2 medium px2 mb3'>
          <FormattedMessage id='employees.profile.banking.Cheque Information'/>
        </div>
        <div className='col col-4 px2'>
          <p>
            <FormattedMessage id='employees.profile.banking.All of the information you need to set up direct deposit can be found at the bottom of a cheque.'/>
          </p>
          <p className='mt1'>
            <FormattedMessage id='employees.profile.banking.See the diagram on the right to find the 5-digit branch number, 3-digit financial institution number, and the 7-digit account number.'/>
          </p>
        </div>
        <div className='col col-8 px2'>
          <ChequeImage/>
        </div>
      </div>
    </Modal>
  );
});

export default BankingChequeHelperModal;
