import React from 'react';
import {FormattedMessage} from 'react-intl';
import EmployeeName from './EmployeeName';
import ManagerAvatar from 'performance_reviews/components/reviews/components/manage/components/reviewees/components/ManagerAvatar';
import ReviewerAvatars from 'performance_reviews/components/reviews/components/manage/components/reviewees/components/ReviewerAvatars';
import SharedWithReviewee from './SharedWithReviewee';

export const MANAGER_REVIEW_COLUMNS = [
  {
    header: 'performance_reviews.view.Reviewee',
    width: 3,
    component: EmployeeName 
  },
  {
    header: 'performance_reviews.manage.Manager',
    width: 1,
    component: ManagerAvatar
  },
  {
    header: 'performance_reviews.manage.Reviewers',
    width: 2,
    component: ReviewerAvatars
  },
  {
    header: 'performance_reviews.view.Status',
    width: 3,
    render: model => <FormattedMessage id='performance_reviews.view.REVIEWS_RECEIVED'
      values={{received: model.completedReviews, total: model.totalReviews}}/>
  },
  {
    header: 'performance_reviews.view.Shared With Reviewee',
    width: 2,
    component: SharedWithReviewee
  }
];

export const NON_MANAGER_REVIEW_COLUMNS = [
  {
    header: 'performance_reviews.view.Reviewee',
    width: 3,
    component: EmployeeName 
  },
  {
    header: 'performance_reviews.manage.Manager',
    width: 2,
    component: ManagerAvatar
  },
  {
    header: 'performance_reviews.manage.Reviewers',
    width: 3,
    component: ReviewerAvatars
  },
  {
    header: 'performance_reviews.view.Status',
    width: 3,
    render: model => <FormattedMessage id='performance_reviews.view.REVIEWS_RECEIVED'
      values={{received: model.completedReviews, total: model.totalReviews}}/>
  }
];
