import React from 'react';

const StyleGuideExample = ({title, description, children}) => {
  return (
    <div className='mb4'>
      <div className='h2 medium mb1'>{title}</div>
      <div className='h3 waterloo max-width-3'>{description}</div>
      <div className='my2'>
        {children}
      </div>
    </div>
  );
};

export default StyleGuideExample;
