import React from 'react';
import {withState} from 'shared/core';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Input, UploadFiles} from 'components';
import {ModalButtons} from 'components/modals';
import {DocumentUploadState} from '../state';

const DocumentUpload = observer(({document, accept, onSave, onCancel, uiState}) => {
  const {files, errors} = uiState;

  return (
    <form>
      <div className='clearfix mx2 py2'>
        <div className='col col-12 mb3'>
          <div className='h6 waterloo caps mb1'>
            <FormattedMessage id='company_settings.documents.Company Document Name'/>
          </div>
          <div className='col col-6'>
            <Input
              value={document.name}
              errorMessage={errors.name}
              onChange={(e) => document.merge({name: e.target.value})}
            />
          </div>
        </div>
        <div className='clearfix mt3'>
          <div className='col col-12'>
            <div className='h6 waterloo caps mb1'>
              <FormattedMessage id='company_settings.documents.Upload your document'/>
            </div>
          </div>
          <div className='col col-12'>
            <UploadFiles
              defaultFiles={files}
              onChange={(files) => uiState.updateFile(files)}
              onUploadStarted={() => uiState.onUploadStarted()}
              onUploadFinished={() => uiState.onUploadFinished()}
              errorMessage={errors.awsFiles}
              isUploading={uiState.isUploading}
              maxFiles={1}
              accept={accept}
              modelType='onboarding_documents'
            />
          </div>
          <div className='col col-12'>
            <ModalButtons
              onSave={() => onSave()}
              saveEnabled={uiState.canSaveDocument}
              onCancel={() => onCancel()}
            />
          </div>
        </div>
      </div>
    </form>
  );
});

export default withState(DocumentUpload, DocumentUploadState);
