import {DomainStore} from 'shared/store';
import {action, observable} from 'mobx';
import {endpoints, types} from 'shared/core';
import {TimeTrackingPaySchedule} from 'stores/time_tracking';

class ManageContainerState {
  store = new DomainStore();
  history;

  @observable editingPaySchedule = {};
  @observable editPayScheduleModalOpen = false;
  @observable editingProject = {};
  @observable errors = {};

  receiveProps({history}) {
    this.history = history;
  }

  setPayScheduleInteractiveAgent(agent) {
    this.payScheduleInteractiveAgent = agent;
  }

  setPolicyInteractiveAgent(agent) {
    this.policyInteractiveAgent = agent;
  }

  setProjectInteractiveAgent(agent) {
    this.projectInteractiveAgent = agent;
  }

  @action async removePaySchedule(paySchedule) {
    await this.store.destroy(paySchedule);
  }

  @action async removeProject(project) {
    await this.store.destroy(project);
  }

  @action async createPolicy() {
    const {model} = await this.store.post(
      endpoints.TIME_TRACKING.POLICIES.ALL,
      types.TIME_TRACKING.POLICY
    );

    this.history.push(`/manage/policies/${model.id}/edit`);
  }

  @action async removePolicy(policy) {
    await this.store.destroy(policy);
    await this.policyInteractiveAgent.refresh();
    await this.payScheduleInteractiveAgent.refresh();
  }

  @action openEditPayScheduleModal(paySchedule) {
    this.errors = {};
    this.editingPaySchedule = new TimeTrackingPaySchedule(paySchedule);
    this.editPayScheduleModalOpen = true;
  }

  @action closeEditPayScheduleModal() {
    this.errors = {};
    this.editPayScheduleModalOpen = false;
  }

  @action async savePaySchedule() {
    const {model, errors} = await this.store.patch(this.editingPaySchedule);
    this.errors = errors;

    if (model) {
      await this.payScheduleInteractiveAgent.refresh();
      await this.policyInteractiveAgent.refresh();
      this.closeEditPayScheduleModal();
    }
  }

  @action async saveProject() {
    const {model, errors} = this.editingProject.isNew ?
      await this.store.post(endpoints.TIME_TRACKING.PROJECTS.DIRECTORY, types.TIME_TRACKING.PROJECT, this.editingProject) :
      await this.store.patch(this.editingProject);
    this.errors = errors;

    if (model) {
      await this.projectInteractiveAgent.refresh();
      this.closeEditProjectModal();
    }
  }

  @action async archiveProject() {
    const {model} = await this.store.post(
      this.editingProject.link('archive'),
      types.TIME_TRACKING.PROJECT
    );

    if (model) {
      this.projectInteractiveAgent.refresh();
      this.closeArchiveProjectModal();
    }
  }

  @action async unarchiveProject() {
    const {model} = await this.store.post(
      this.editingProject.link('unarchive'),
      types.TIME_TRACKING.PROJECT
    );

    if (model) {
      this.projectInteractiveAgent.refresh();
      this.closeUnarchiveProjectModal();
    }
  }

  customLinksForProject(project) {
    const links = [];
    if (project.hasLink('archive')) {
      links.push({
        order: 0,
        text: 'time_tracking.manage.projects.directory.Archive',
        action: project => this.openArchiveProjectModal(project)
      });
    }

    if (project.hasLink('unarchive')) {
      links.push({
        order: 0,
        text: 'time_tracking.manage.projects.directory.Unarchive',
        action: project => this.openUnarchiveProjectModal(project)
      });
    }

    return links;
  }

  paySchedulePath(paySchedule) {
    return `/manage/pay_schedules/${paySchedule.id}/`;
  }
}

export default ManageContainerState;
