const payPeriodsPerYear = (payRateUnit, hoursPerWeek) => {
  switch(payRateUnit) {
    case 'year':
      return 1;
    case 'quarter':
      return 4;
    case 'month':
      return 12;
    case 'week':
      return 52;
    case 'two_weeks':
      return 26;
    case 'half_month':
      return 24;
    case 'hour':
      return hoursPerWeek * 52;
    default:
     return 0;
  }
};

export default payPeriodsPerYear;
