import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const RoleName = observer(({name}) => {
  if (name) return <span>{name}</span>;

  return <FormattedMessage id='company_settings.permissions.Untitled Role'/>;
});

export default RoleName;
