import _ from 'lodash';
import {action, observable, computed}  from 'mobx';
import {DomainObject} from 'shared/store';
import CustomField from './CustomField';

class CustomFieldGroup extends DomainObject {
  @observable id;
  @observable name;
  @observable order;
  @observable category;
  @observable customFields = [];
  @observable employeeCustomFields = [];

  @action merge(other) {
    super.merge(other, {
      customFields: [CustomField]
    });
  }

  @computed get sortedCustomFields() {
    return _.sortBy(this.customFields, ['order']);
  }

  @computed get sortedEmployeeCustomFields() {
    return _.sortBy(this.employeeCustomFields, ['order']);
  }
}

export default CustomFieldGroup;
