import React from 'react';
import {observer} from 'mobx-react';
import MultiSelectFilter from './MultiSelectFilter';
import generateTitle from './generateTitle';
import {Spinner} from 'components';

const FilterComponent = observer(({filter, filterKey, filterUpdated, filterDeleted, options, fallbackTitle, searchable, Wrapper, isLoading = false}) => {
  const selectedValues = filter.get(filterKey) ? filter.get(filterKey).split(',') : [];
  const selectedLabels = selectedValues.map(value => {
    const selectedOption = options.find(option => option.value === value);
    return selectedOption ? (selectedOption.pillTitle || selectedOption.label) : '';
  });

  return (
    <Wrapper title={generateTitle(selectedLabels, filterKey, fallbackTitle)} onRemove={() => filterDeleted(filterKey)}>
      {isLoading ?
        <Spinner/> :
        <MultiSelectFilter options={options} filterKey={filterKey} filter={filter} filterUpdated={filterUpdated} isLoading={isLoading} searchable={searchable}/>
      }
    </Wrapper>
  );
});

export default FilterComponent;
