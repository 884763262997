import React from 'react';
import {observer} from 'mobx-react';
import ReportRowContent from './ReportRowContent';
import {Link} from 'react-router-dom';

const ReportRow = observer(({name, path, icon, visible, children}) => {
  if (!visible) return null;

  return (
    <div className='border-bottom py2 relative'>
      <Link to={`${path}`} className='dodger TextLink'>
        <ReportRowContent name={name} icon={icon} children={children}/>
      </Link>
      <div className='absolute' style={{top: '18px', right: 0}}>
        {children}
      </div>
    </div>
  );
});

export default ReportRow;
