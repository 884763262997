import React from 'react';
import {observer} from 'mobx-react';
import Avatar from './Avatar';
import Panel from './panels/Panel';
import {t} from 'shared/core';
import _ from 'lodash';

const AvatarPanel = observer(({employee}) => {
  let locationAndEmploymentType = '';
  if (_.get(employee, 'location.name')) locationAndEmploymentType += employee.location.name;
  if (_.get(employee, 'employmentType')) {
    locationAndEmploymentType += _.isEmpty(locationAndEmploymentType) ? t(employee.employmentType) : `  ·  ${t(employee.employmentType)}`;
  }
  return (
    <Panel className='clearfix'>
      <Avatar size='lg' className='col col-12 mb2 print-hide' employee={employee}/>
      <div className='col col-12 h3 medium black'>{employee.name}</div>
      <div className='col col-12 jumbo'>{employee.jobTitle}</div>
      <div className='col col-12 jumbo'>{locationAndEmploymentType}</div>
    </Panel>
  );
});

export default AvatarPanel;
