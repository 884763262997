import React from 'react';
import {observer} from 'mobx-react';
import {ActionLink, Panel} from 'components';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';
import Field from './Field';

const MakeChangesLink = observer(({uiState}) => {
  const {benefitInfo} = uiState;

  if (!benefitInfo.canMakeChangeRequest) return null;

  return (
    <ActionLink onClick={() => uiState.createChangeRequest()}>
      <FormattedMessage id='components.benefits.benefit_info.Make Changes'/>
    </ActionLink>
  );
});

const MyEnrolmentInfo = observer(({uiState}) => {
  const {benefitInfo} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.benefits.Enrolment Info')}>
        <MakeChangesLink uiState={uiState}/>
      </Panel.Header>
      <div className='mt2 medium mb2'>
        <FormattedMessage id='employees.profile.benefits.Coverage Details'/>
      </div>
      <Field title='Health Coverage' value={t(`benefit_enrolment.${benefitInfo.healthCoverage}`)}/>
      <Field title='Dental Coverage' value={t(`benefit_enrolment.${benefitInfo.dentalCoverage}`)}/>
      <Field title='Canadian VISA or Permit' value={t(`benefit_enrolment.${benefitInfo.hasCanadianVisaOrPermit}`)}/>
      {benefitInfo.hasCanadianVisaOrPermit && <Field title='VISA/Permit Expiry Date' value={benefitInfo.visaOrPermitExpiryDateView}/>}
      <Field title='Provincial Health Plan' value={t(`benefit_enrolment.${benefitInfo.hasProvincialHealthPlan}`)}/>
    </Panel>
  );
});

export default MyEnrolmentInfo;
