import React from 'react';
import {observer} from 'mobx-react';
import {action, observable} from 'mobx';
import Table from 'components/tables/components/Table';
import InteractiveContext from './InteractiveContext';
import Pagination from './pagination/Pagination';
import Panel from 'components/panels/Panel';
import Header from './header/Header';
import _ from 'lodash';
import {FilterRow} from 'components/tables/interactive/components/FilterRow';

const TableWrapper = observer(({wrapInPanel, children}) => {
  if (wrapInPanel) {
    return (
      <Panel>{children}</Panel>
    );
  }

  return (
    <div>{children}</div>
  );
});

@observer class InteractiveTable extends React.Component {
  @observable isEmpty = false;
  @observable isLoading = true;

  @action handleOnMount = (agent) => {
    this.isEmpty = agent.isEmpty;
    this.props.onMount && this.props.onMount(agent);
    this.isLoading = false;
  };

  render() {
    const {searchable,
      paginatable,
      proxy,
      saveStateToUrl,
      showAddButton,
      onAdd,
      title,
      description,
      // eslint-disable-next-line no-unused-vars
      onMount,
      children,
      empty,
      initialFilter,
      initialSorting,
      hidePaginationInfo,
      fancyFiltersEnabled,
      onFilterUpdated,
      tableActionButtons,
      scopeOptions,
      wrapInPanel,
      ...rest
    } = this.props;
    const {localization} = rest;

    if (this.isEmpty && _.isFunction(empty)) {
      const EmptyState = empty;
      return <EmptyState {...rest} />;
    }

    return (
      <TableWrapper wrapInPanel={wrapInPanel}>
        <Header
          showAddButton={showAddButton}
          onAdd={onAdd}
          actionButtons={tableActionButtons}
          localization={_.get(localization, 'interactive')}
          description={description}
          title={title}
          loading={this.isLoading}
        />
        <div className='Table'>
          <InteractiveContext
            proxy={proxy}
            onMount={this.handleOnMount}
            saveStateToUrl={saveStateToUrl}
            empty={empty}
            initialFilter={initialFilter}
            initialSorting={initialSorting}
            onFilterUpdated={onFilterUpdated}
          >
            <FilterRow searchable={searchable} fancyFiltersEnabled={fancyFiltersEnabled} scopeOptions={scopeOptions}>
              {children}
            </FilterRow>
            <Table {...rest} wrapInTableClass={false}/>
            {paginatable && <Pagination hidePaginationInfo={hidePaginationInfo}/>}
          </InteractiveContext>
        </div>
      </TableWrapper>
    );
  }
}

InteractiveTable.defaultProps = {
  searchable: true,
  paginatable: true,
  sortable: true,
  title: '',
  onAdd: () => {},
  onFilterUpdated: () => {},
  showAddButton: true,
  localization: {},
  hidePaginationInfo: false,
  wrapInPanel: true
};

// Interactive table is a helper component that renders a Table inside an InteractiveContext
// along with a few optional components, such as a SearchBox, Pagination, or various filters (passed in as children).
// InteractiveTable renders a standalone Panel.
// InteractiveTables are used for "big" tables, meaning tables that:
//   - contain a lot of data and use server-side sorting, pagination, and/or search
//   - live in their own Panel, and usually on their own separate age
// Examples include: Employee Directory, Candidate Directory, Review Cycle Directory, etc.

// props:

// - searchable: if true, includes a SearchBox

// - paginatable: if true, includes a Pagination

// - onAdd: fires when the user clicks the "Add" button in the top-right corner
//   if this prop is not provided, the "Add" button is not rendered.

// - title: title to render above the Table

// - description: text to render below the title

// - ...rest: the rest of the props will be passed to the child Table component
//   this means that InteractiveTable accepts any props that Table accepts.

// the following props will be forwarded to InteractiveContext:
// - proxy
// - saveStateToUrl
// - onMount
// - children (<- those are filters)

// note on localization:
// InteractiveTable accepts a localization object and passes it to Table.
// if this object contains an optional attribute 'interactive', it will be
// used to localize the following parts of InteractiveTable:
// - addModel: the caption of the "Add" Button

// note on onAdd:
// note that Table accepts an onAdd prop which is used to render the "Add model" link
// below the table.
// InteractiveTable uses a different design and renders the "Add" button instead,
// located in the top right corner. InteractiveTable will NOT pass the onAdd prop
// to the underlying Table.

export default InteractiveTable;
