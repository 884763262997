import {action, computed, observable} from 'mobx';
import {DomainStore} from 'shared/store';
import {t, endpoints, types} from 'shared/core';
import {successAlert} from 'shared/tools';
import {EmployeeTrainingStatus} from 'stores/training';
import _ from 'lodash';
import {setupAutosaveDraft} from 'shared/tools';

class TrainingProgramsEmployeeContainerState {
  store = new DomainStore();
  match;
  history;

  @observable trainingStatusDetails;
  @observable trainingTask;
  @observable errors = {};

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    const employeeTrainingStatusId = this.match.params.id;

    await this.store._compose([
      endpoints.TRAINING.EMPLOYEE_TRAINING_STATUS.with(employeeTrainingStatusId)
    ]);

    this.trainingStatusDetails = new EmployeeTrainingStatus(
      this.store._getSingle(types.TRAINING.EMPLOYEE_TRAINING_STATUS)
    );

    if (!this.trainingStatusDetails.readOnly) {
      const questions = _.map(this.trainingStatusDetails.answers, 'question');

      await setupAutosaveDraft(
        this.trainingStatusDetails,
        {id: this.trainingStatusDetails.id, type: types.TRAINING.EMPLOYEE_TRAINING_STATUS}
      );
      this.trainingStatusDetails.regenerateAnswersForQuestions(questions);
    }
  }

  @action async completeTraining() {
    const {model, errors} = await this.store.patch(
      this.trainingStatusDetails.link('complete'),
      types.TRAINING.EMPLOYEE_TRAINING_STATUS,
      this.trainingStatusDetails
    );

    this.errors = errors;

    if (model) {
      successAlert(t('training.view.Training successfully completed'));
      await this.load();
    }
  }

  @computed get sortedAnswers() {
    return _.sortBy(this.trainingStatusDetails.answers, 'question.order');
  }
}

export default TrainingProgramsEmployeeContainerState;
