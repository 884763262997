import _ from 'lodash';
import {getSeriesAndCategories} from './tools';
import chart from './chart';
import defaultConfig from './defaultConfig';

function columnChart({rows, meta}, options = {}) {
  const {series} = meta;
  const {categories, highchartsSeries} = getSeriesAndCategories(rows, meta);

  let columnChart = {
    ...defaultConfig(series, options),
    chart: {
      ...chart('column'),
      spacing: [
        0, 0, 0, 0
      ]
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        minPointLength: options.stacking ? 0 : 2,
        stacking: options.stacking,
        maxPointWidth: 50
      }
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      title: {
        text: null
      },
      allowDecimals: false
    },
    series: highchartsSeries
  };

  _.merge(columnChart, options.highcharts);

  return columnChart;
}

export default columnChart;
