import MultiSelectFilterRowViewModel from './MultiSelectFilterRowViewModel';
import DateFilterRowViewModel from './DateFilterRowViewModel';
import _ from 'lodash';
import {auth} from 'shared/core';
import Employee from 'stores/employees/Employee';
import User from 'stores/users/User';
import Location from 'stores/locations/Location';
import Department from 'stores/departments/Department';
import {TimeOffType} from 'stores/time_off';
import {TimeTrackingProject} from 'stores/time_tracking';
import createPayRateViewModel from './createPayRateViewModel';
import createPayRateUnitViewModel from './createPayRateUnitViewModel';

import BrokerReportDataSource from './BrokerReportDataSource';
import CompanyReportDataSource from './CompanyReportDataSource';

function createDataSourceFactory(customReportId) {
  if (auth.broker) {
    return new BrokerReportDataSource(customReportId);
  } else {
    return new CompanyReportDataSource(customReportId);
  }
}

function createRowForCategory(category, customReportId) {
  const dataSourceFactory = createDataSourceFactory(customReportId);

  switch (category) {
    case 'Department':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'department_id',
          operators: ['is', 'is_not']
        },
        dataSource: dataSourceFactory.departmentDataSource(),
        mapItem: (department) => new Department(department)
      });
    case 'Location':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'location_id',
          operators: ['is', 'is_not']
        },
        dataSource: dataSourceFactory.locationDataSource(),
        mapItem: (location) => new Location(location)
      });
    case 'Employment Type':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'employment_type',
          operators: ['is', 'is_not']
        },
        dataSource: dataSourceFactory.employmentTypeDataSource(),
        mapItem: (employmentType) => ({
          id: employmentType,
          name: employmentType
        }),
        translatable: true
      });
    case 'Manager':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'manager_id',
          operators: ['is', 'is_not'],
        },
        dataSource: dataSourceFactory.managerDataSource(),
        mapItem: (employee) => new Employee(employee),
        searchable: true
      });
    case 'Gender':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'gender',
          operators: ['is', 'is_not']
        },
        dataSource: dataSourceFactory.genderDataSource(),
        translatable: true,
        mapItem: (gender) => ({
          id: gender,
          name: gender
        })
      });
    case 'Termination Type':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'termination_type',
          operators: ['is', 'is_not'],
        },
        dataSource: dataSourceFactory.terminationTypeDataSource(),
        translatable: true,
        mapItem: (terminationType) => ({
          id: terminationType,
          name: terminationType
        })
      });
    case 'Start Date':
      return new DateFilterRowViewModel({
        define: {
          category,
          attribute: 'start_date',
          operators: ['<=', '>=', 'between']
        }
      });
    case 'Period Date':
      return new DateFilterRowViewModel({
        define: {
          category,
          attribute: 'period_date',
          operators: ['<=', '>=', 'between']
        }
      });
    case 'Pay Rate':
      return [
        createPayRateViewModel(),
        createPayRateUnitViewModel()
      ];
    case 'Last Day of Work':
      return new DateFilterRowViewModel({
        define: {
          category,
          attribute: 'last_day_of_work',
          operators: ['<=', '>=', 'between']
        }
      });
    case 'Last Day of Benefits':
      return new DateFilterRowViewModel({
        define: {
          category,
          attribute: 'last_day_of_benefits',
          operators: ['<=', '>=', 'between']
        }
      });
    case 'Termination Date':
      return new DateFilterRowViewModel({
        define: {
          category,
          attribute: 'termination_date',
          operators: ['<=', '>=', 'between']
        }
      });
    case 'Due Date':
      return new DateFilterRowViewModel({
        define: {
          category,
          attribute: 'due_date',
          operators: ['<=', '>=', 'between']
        }
      });
    case 'Employee':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'employee_id',
          operators: ['is', 'is_not']
        },
        dataSource: dataSourceFactory.employeeDataSource(),
        mapItem: (employee) => new Employee(employee),
        searchable: true
      });
    case 'Terminated Employee':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'employee_id',
          operators: ['is', 'is_not']
        },
        dataSource: dataSourceFactory.terminatedEmployeeDataSource(),
        mapItem: (employee) => new Employee(employee),
        searchable: true
      });
    case 'Employment Status':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'employment_status',
          operators: ['is', 'is_not']
        },
        dataSource: dataSourceFactory.employmentStatusDataSource(),
        translatable: true,
        mapItem: (employmentStatus) => ({
          id: employmentStatus,
          name: employmentStatus
        })
      });
    case 'Time Off Type':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'type_id',
          operators: ['is', 'is_not']
        },
        dataSource: dataSourceFactory.timeOffTypeDataSource(),
        mapItem: (type) => new TimeOffType(type),
        searchable: true
      });
    case 'Position':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'position_id',
          operators: ['is']
        },
        dataSource: dataSourceFactory.positionDataSource(),
        mapItem: (position) => ({
          id: position.id,
          name: position.title
        }),
        searchable: true
      });
    case 'Cycle':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'cycle_id',
          operators: ['is']
        },
        dataSource: dataSourceFactory.checkinDataSource(),
        mapItem: (cycle) => ({
          id: cycle.id,
          name: cycle.name
        })
      });
    case 'Candidate Status':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'candidate_status',
          operators: ['is']
        },
        dataSource: dataSourceFactory.candidateStatusDataSource(),
        translatable: true,
        mapItem: (candidateStatus) => ({
          id: candidateStatus,
          name: candidateStatus
        })
      });
    case 'Position Status':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'position_status',
          operators: ['is']
        },
        dataSource: dataSourceFactory.positionStatusDataSource(),
        translatable: true,
        mapItem: (positionStatus) => ({
          id: positionStatus,
          name: positionStatus
        })
      });
    case 'Review':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'review_cycle_id',
          operators: ['is']
        },
        dataSource: dataSourceFactory.reviewDataSource(),
        mapItem: (review) => ({
          id: review.id,
          name: review.name
        })
      });
    case 'Reviewer':
    case 'Leader':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'reviewer_employee_id',
          operators: ['is', 'is_not']
        },
        dataSource: dataSourceFactory.employeeDataSource(),
        mapItem: (employee) => new Employee(employee),
        searchable: true
      });
    case 'Created By':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'created_by_user_id',
          operators: ['is', 'is_not']
        },
        dataSource: dataSourceFactory.userDataSource(),
        mapItem: (user) => new User(user),
        searchable: true
      });
    case 'Project':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'project_id',
          operators: ['is', 'is_not']
        },
        dataSource: dataSourceFactory.projectDataSource(),
        mapItem: (project) => new TimeTrackingProject(project),
        searchable: true
      });
    case 'Survey':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'survey_id',
          operators: ['is']
        },
        dataSource: dataSourceFactory.surveyDataSource(),
        mapItem: (survey) => ({
          id: survey.id,
          name: survey.name
        })
      });
    case 'Anonymous Survey':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'survey_id',
          operators: ['is']
        },
        dataSource: dataSourceFactory.anonymousSurveyDataSource(),
        mapItem: (survey) => ({
          id: survey.id,
          name: survey.name
        })
      });
    case 'Program':
      return new MultiSelectFilterRowViewModel({
        define: {
          category,
          attribute: 'program_id',
          operators: ['is']
        },
        dataSource: dataSourceFactory.programDataSource(),
        mapItem: (program) => ({
          id: program.id,
          name: program.name
        })
      });
    case 'Application Date':
      return new DateFilterRowViewModel({
        define: {
          category,
          attribute: 'created_at',
          operators: ['<=', '>=', 'between']
        }
      });
    default:
      throw new Error(`Filter category ${category} not supported.`);
  }
}

export default createRowForCategory;
