import React from 'react';
import {observer} from 'mobx-react';
import {dateFormatter} from 'shared/tools';
import {EmployeeNameWithAvatar} from 'components';
import ClockIcon from 'img/clock--red@2x.png';
import {FormattedMessage} from 'react-intl';
import {auth} from 'shared/core';

const TaskDetails = observer(({uiState}) => {
  const {task} = uiState;
  const showAssignedTo = task.assigneeEmployee.id !== auth.employee.id;
  const showSubjectEmployee = !!task.subjectEmployee &&
    task.subjectEmployee.id !== auth.employee.id &&
    task.subjectEmployee.id !== task.assigneeEmployee.id;
  const showDueDate = !!task.dueDate;
  if (!showAssignedTo && !showSubjectEmployee && !showDueDate) return null;

  return (
    <div className='rounded flex border bg-haze align-top mb3'>
      {showAssignedTo &&
        <div className='inline-block p2'>
          <div className='mb1 jumbo'>
            <FormattedMessage id='inbox.Assigned to'/>
          </div>
          <EmployeeNameWithAvatar employee={task.assigneeEmployee}/>
        </div>
      }
      {showSubjectEmployee &&
        <div className='inline-block p2'>
          <div className='mb1 jumbo'>
            <FormattedMessage id='inbox.Employee'/>
          </div>
          <EmployeeNameWithAvatar employee={task.subjectEmployee}/>
        </div>
      }
      {showDueDate &&
        <div className='inline-block p2'>
          <div className='mb1 jumbo'>
            <FormattedMessage id='inbox.Due Date'/>
          </div>
          <div className='table-cell align-middle pt1'>
            <div className='table-cell align-top pr1'>
             <img src={ClockIcon} alt='clock' height='12px' style={{marginBottom: '4px'}}/>
            </div>
            <div className='table-cell align-top'>
             {dateFormatter(task.dueDate)}
            </div>
          </div>
        </div>
      }
    </div>
  );
});

export default TaskDetails;
