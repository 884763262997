import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {endpoints, types} from 'shared/core';

class PerformanceReviewsPageState {
  store = new DomainStore();

  @observable scope;

  @action async load() {
    await this.store._compose([
      endpoints.PERFORMANCE_REVIEWS.NAVIGATION
    ]);

    this.scope = this.store._getSingle(types.NAVIGATION_SCOPE).tabs;
  }
}

export default PerformanceReviewsPageState;