import React from 'react';
import {Link} from 'react-router-dom';
import BackIcon from 'img/back-arrow@2x.png';
import {FormattedMessage} from 'react-intl';
import {Layout} from 'components';

const BackToIndex = ({backPath}) => {
  return (
    <div className='clearfix mb3 py2 border-bottom'>
      <Layout>
        <ul className='Breadcrumb'>
          <li>
            <Link to={backPath || '/'} className='HoverAnimation-trigger--image'>
              <img className='mr1' src={BackIcon} alt='back' height='24px'/>
              <span className='align-middle'>
                <FormattedMessage id='reports.Reports'/>
              </span>
            </Link>
          </li>
        </ul>
      </Layout>
    </div>
  );
};

export default BackToIndex;
