import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {auth, withState, t} from 'shared/core';
import {Panel, Layout, Display, Button, LivesChart} from 'components';
import {BrokerDashboardState} from '../state';
import {ClientGrowth} from './company_list';
import {ClientsByCarrierChart} from './client_chart';
import {EmployeesByCarrierChart} from './employees_carriers_chart';
import {UpcomingRenewalDates} from './upcoming_renewal_dates';

const AddClientButton = observer(({uiState}) => {
  const {broker} = uiState;

  if (broker.mgaPlus && !auth.user.impersonating) return null;

  return (
    <Button onClick={() => uiState.navigateToNewClient()}>
      <FormattedMessage id='broker_dashboard.+ Add Client'/>
    </Button>
  );
});

const BrokerDashboard = observer(({uiState}) => {
  const {enrolmentDataSeries, showEnrolmentGraph} = uiState;

  return (
    <div>
      <Display
        title={uiState.greeting}
        documentTitle={t('broker_dashboard.Dashboard')}
        toolbar={<AddClientButton uiState={uiState}/>}
      />
      <Layout>
        <Layout.Section>
          <Panel.Stack loose>
            {showEnrolmentGraph && <LivesChart dataSeries={enrolmentDataSeries}/>}
            <ClientsByCarrierChart/>
            <EmployeesByCarrierChart/>
          </Panel.Stack>
        </Layout.Section>
        <Layout.Section secondary>
          <Panel.Stack loose>
            <ClientGrowth/>
            <UpcomingRenewalDates/>
          </Panel.Stack>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(BrokerDashboard, BrokerDashboardState);
