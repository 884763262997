import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [];

const CandidatesBySourceReport = () => {
  return (
    <CompositeReport filters={filters}>
      <Report name={t('reports.CANDIDATES_BY_SOURCE_REPORT')}
              view='chart' chartType='pie'
              url='/reports/candidates_by_source_chart'>
      </Report>
      <Report name={t('reports.Candidates Hire Status by Source')}
              view='chart'
              options={{
                stacking: 'normal'
              }}
              url='/reports/candidates_hire_status_chart'>
      </Report>
    </CompositeReport>
  );
};

export default CandidatesBySourceReport;
