import React from 'react';
import {observer} from 'mobx-react';
import {Panel, ActionLink, Label} from 'components';
import EditPersonalInfoModal from './EditPersonalInfoModal';
import {calendarDate} from 'shared/tools';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';

const PersonalInfo = observer(({uiState}) => {
  const {employee} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.personal.Basic Information')}>
        {uiState.editDataPermission('::PERSONAL_INFORMATION') &&
          <ActionLink onClick={() => uiState.openBasicInfoModal()}>
            <FormattedMessage id='employees.profile.personal.Edit'/>
          </ActionLink>}
      </Panel.Header>
      <div className='col-12 sm-col-10 pt1'>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.First Name'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='firstName'/>
          </div>
        </div>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Preferred Name'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='preferredName'/>
          </div>
        </div>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Last Name'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='lastName'/>
          </div>
        </div>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Preferred Last Name'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='preferredLastName'/>
          </div>
        </div>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Current Gender Identity'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='genderView' translate={true} />
          </div>
        </div>
        <div className='clearfix mxn1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Birthdate'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='birthDate' render={value => calendarDate(value)}/>
          </div>
        </div>
      </div>
      <EditPersonalInfoModal uiState={uiState} />
    </Panel>
  );
});

export default PersonalInfo;
