import React from 'react';
import {observer} from 'mobx-react';
import {TimeTrackingSubnav} from 'containers/time_tracking';
import TimesheetsContainerWithState from 'containers/time_tracking/components/timesheets/components/TimesheetsContainerWithState';

const TimesheetsContainer = observer(({scope}) => {
  return (
    <div>
      <TimeTrackingSubnav scope={scope}/>
      <TimesheetsContainerWithState/>
    </div>
  );
});

export default TimesheetsContainer;
