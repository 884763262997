/* eslint-disable */

/*
	RESOURCES
	- https://www.w3.org/TR/SVG/single-page.html#coords-__svg__SVGMatrix__translate
	- https://www.w3.org/TR/SVG/single-page.html#coords-InterfaceSVGMatrix
	- https://developer.mozilla.org/en-US/docs/Web/API/SVGMatrix
	- http://www.it.hiof.no/~borres/j3d/math/twod/p-twod.html
*/

// zooms based on focal point and mouse wheel delta
export default function zoom(m1, p, delta){
	const z = 1 + delta; // zoom factor: 0.9/1.1

	p = pointTranslate(
		p,
		inverse(m1)
	)

	// compute new scale matrix in current mouse position
	let m2 = initialMatrix()
	m2 = translate(m2, p.x, p.y)
	m2 = scale(m2, z)
	m2 = translate(m2, -p.x, -p.y)

	return multiply(
		m1,
		m2
	)
}

export function initialMatrix(){
	return {
		a: 1,
		b: 0,
		c: 0,
		d: 1,
		e: 0,
		f: 0
	}
}

export function matrixString(matrix){
	return `matrix(
		${matrix.a},
		${matrix.b},
		${matrix.c},
		${matrix.d},
		${matrix.e},
		${matrix.f}
	)`
}

function inverse(m){
	return {
		a: 1 / m.a,
		b: 0,
		c: 0,
		d: 1 / m.a,
		e: m.e / - m.a,
		f: m.f / - m.a
	}
}

function multiply(m1, m2){
	return {
		a: m1.a * m2.a,
		b: 0,
		c: 0,
		d: m1.a * m2.a,
		e: m1.a * m2.e + m1.e,
		f: m1.a * m2.f + m1.f
	}
}

function translate(matrix, dX, dY){
	return multiply(matrix, {
		a: 1,
		b: 0,
		c: 0,
		d: 1,
		e: dX,
		f: dY
	})
}

export function scale(matrix, s){
	return multiply(matrix, {
		a: s,
		b: 0,
		c: 0,
		d: s,
		e: 0,
		f: 0
	})
}

function pointTranslate(p, m){
	return {
		x: m.a * p.x + m.e,
		y: m.a * p.y + m.f
	}
}
