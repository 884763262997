import React from 'react';
import {Display} from 'components';
import {observer} from 'mobx-react';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';

const Subnav = observer(({title, match, scope}) => {
  return (
    <Display title={title} backTitle={t('performance_reviews.Performance reviews')} backPath='/reviews'>
      {scope.overview && <NavLink activeClassName='active' to={`/reviews/${match.params.cycleId}/admin`}>
        <FormattedMessage id='performance_reviews.view.Admin'/>
      </NavLink>}
      {scope.summary && <NavLink activeClassName='active' to={`/reviews/${match.params.cycleId}/summary`}>
        <FormattedMessage id='performance_reviews.view.Summary'/>
      </NavLink>}
      {(scope.write || scope.team) && <NavLink activeClassName='active' to={`/reviews/${match.params.cycleId}/write`}>
        <FormattedMessage id='performance_reviews.view.My Reviews'/>
      </NavLink>}
      {scope.results && <NavLink activeClassName='active' to={`/reviews/${match.params.cycleId}/results`}>
        <FormattedMessage id='performance_reviews.view.My Results'/>
      </NavLink>}
      {scope.cycleResults && <NavLink activeClassName='active' to={`/reviews/${match.params.cycleId}/cycle_results`}>
        <FormattedMessage id='performance_reviews.view.Cycle Results'/>
      </NavLink>}
    </Display>
  );
});

export default Subnav;
