import React from 'react';
import {observer} from 'mobx-react';
import {PanelTooltip, Panel} from 'components';
import {FormattedMessage} from 'react-intl';
import RemindersTable from './RemindersTable';
import ReminderDirectoryState from 'company_settings/reminders/state/ReminderDirectoryState';
import reminderDirectoryStore from 'company_settings/reminders/state/ReminderDirectoryStore';
import {loader, withDisplay} from 'shared/core';
import SubNav from 'company_settings/SubNav';
import {t} from 'shared/core';

const ReminderHeader = () => {
  return (
    <div>
      <div className='table-cell align-middle'>
        <FormattedMessage id='company_settings.reminders.Reminders'/>
      </div>
      <div className='table-cell align-middle'>
        <PanelTooltip text='company_settings.reminders.hint'/>
      </div>
    </div>
  );
};

const RemindersContainer = observer(({uiState}) => {
  return (
    <div className='Container'>
      <Panel className='lg-col-10'>
        <Panel.Header title={<ReminderHeader/>}/>
        <RemindersTable uiState={uiState}/>
      </Panel>
    </div>
  );
});

async function load({history, match}) {
  const uiState = new ReminderDirectoryState(reminderDirectoryStore, history, match);
  await uiState.load();
  return {uiState};
}

export default withDisplay(loader(RemindersContainer, load), () => <SubNav title={t('company_settings.subnav.Reminders')}/>);
