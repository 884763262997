import {observable, computed} from 'mobx';
import {auth} from 'shared/core';
import _ from 'lodash';

class AttachmentFormState {
  @observable question;
  @observable showAttachmentOptions;
  @observable folders = [];

  receiveProps({question, showAttachmentOptions, folders}) {
    this.question = question;
    this.showAttachmentOptions = showAttachmentOptions;
    this.folders = folders;

    this.question.args = {};
  }

  @computed get showFolderSelector() {
    return auth.hasAccess('::MANAGE_DOCUMENTS') || auth.hasAccess('::MANAGE_FOLDERS') || auth.viewData('::DOCUMENTS');
  }
}

export default AttachmentFormState;
