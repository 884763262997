import _ from 'lodash';
import {observable, action, computed, toJS} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, types, auth} from 'shared/core';
import CustomReport from 'stores/reports/CustomReport';
import FIELDS from 'stores/reports/fields';
import BHQFIELDS from 'stores/reports/BHQfields';
import {FieldSectionViewModel} from './fields';
import ReportColumn from 'stores/reports/ReportColumn';
import filterRowFactory from 'reports/state/filterRowFactory';
import {CustomFieldGroup} from 'stores/custom_fields';

const BENEFITS_FIELDS = ['benefits_info', 'dependents', 'benefits_enrolment'];

class ReportEditState {
  store = new DomainStore();
  history;
  match;

  @observable report;
  @observable errors = {};
  @observable fieldSections = [];
  @observable customFieldGroups = [];
  @observable filterRows = [];

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    await this.store._compose([
      endpoints.CUSTOM_REPORT.with(this.match.params.id),
      endpoints.CUSTOM_FIELD_GROUPS.with('personal,job')
    ]);

    this.report = new CustomReport(this.store._getSingle(types.CUSTOM_REPORT));
    this.customFieldGroups = this.store._getAll(types.CUSTOM_FIELD_GROUP).map(c => new CustomFieldGroup(c));
    this.report.currentStep = 5;
    this.errors = {};
  }

  @action async initializeFilters() {
    const filterRows = this.report.filter.map(
      model => filterRowFactory({model, customReportId: this.report.id})
    );
    for (const filter of filterRows) {
      if (filter.load) {
        await filter.load();
        filter.loaded = true;
      }
      const row = _.find(this.report.filter, { attribute: filter.row.attribute });
      filter.viewOnly = true;
      filter.row.merge(row);
      filter.resetRow();
    }

    return filterRows;
  }

  async submitStep(props) {
    const report = this.report.pick(props);
    const {model, errors} = await this.store.patch(report);
    this.errors = errors;
    if (!model) return false;

    this.report.merge(model);
    return true;
  }

  @action initFieldSections() {
    let fields = auth.moduleEnabled('directory') ? FIELDS : BHQFIELDS;

    if (!auth.moduleEnabled('benefits')) {
      fields = _.reject(fields, field => _.includes(BENEFITS_FIELDS, field.group));
    }

    if (!_.isEmpty(this.sortedCustomFields) && !_.find(fields, { group: 'custom_fields' })) {
      fields.push({
        group: 'custom_fields',
        values: this.sortedCustomFields.map(v => new ReportColumn({
          key: `custom_fields.${v.id}`,
          name: v.name
        }))
      });
    }
    this.fieldSections = fields.map(f => new FieldSectionViewModel(f, this.report));
  }

  @action initCustomFields() {
    for (const column of this.report.columns) {
      if (column.group !== 'custom_fields') continue;

      column.name = _.find(this.sortedCustomFields, { id: column.customFieldId }).name;
    }
  }

  @action async submitFields() {
    const selectedColumns = _.chain(this.fieldSections)
      .map(section => toJS(section.fields))
      .flatten()
      .filter('checked')
      .map('data')
      .value();

    const maxOrder = _.get(_.maxBy(selectedColumns, 'order'), 'order') || -1;
    let i = 1;
    for (const c of selectedColumns) {
      if (!c.order && c.order !== 0) {
        c.order = i + maxOrder;
        i += 1;
      }
    }

    this.report.columns = selectedColumns;

    return this.submitStep([
      'name',
      'columns',
      'employmentRecordsShowHistory'
    ]);
  }

  @action async submitOrder() {
    return this.submitStep([
      'columns'
    ]);
  }

  @action async submitFilters() {
    return this.submitStep([
      'filter'
    ]);
  }

  @action async finish() {
    const updatedReport = await this.submitStep([
      'shares'
    ]);

    if (updatedReport) {
      this.history.push(`/custom/${this.report.id}`);
    }
  }

  @action async onStepSubmitted({location}) {
    switch (location) {
      case 'fields': return this.submitFields();
      case 'reorder': return this.submitOrder();
      case 'filters': return this.submitFilters();
      case 'review': return this.finish();
      default:
        throw new Error(`Location ${location} is not supported.`);
    }
  }

  @action async onStepChanged({location}) {
    switch (location) {
      case 'fields':
        this.initFieldSections();
        break;
      case 'reorder':
      case 'review':
        this.initCustomFields();
        break;
      default:
        break;
    }

    if (location === 'review') {
      this.filterRows = await this.initializeFilters();
    }
  }

  @computed get sortedCustomFields() {
    return _.chain(this.customFieldGroups)
      .sortBy('order')
      .map(group => _.sortBy(group.customFields, 'order'))
      .flatten()
      .value();
  }
}

export default ReportEditState;
