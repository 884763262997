import React from 'react';
import {observer} from 'mobx-react';
import {Input, TextArea} from 'components';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const TextField = observer(({field, textArea, viewModel}) => {
  const {uiState} = viewModel;

  if (uiState.massUpdate.readOnly && !viewModel.fields.get(field).has('newValue')) {
    return (
      <div className='waterloo'>
        <FormattedMessage id='mass_updates.edit.Not Changed'/>
      </div>
    );
  }

  const InputComponent = textArea ? TextArea : Input;

  return (
    <div>
      <InputComponent
        value={viewModel.get(field).get('value')}
        onChange={e => viewModel.set(field, e.target.value)}
        disabled={viewModel.disabled}
      />
      <div className='jumbo mt1'>
        <FormattedMessage
          id={viewModel.disabled ? 'mass_updates.edit.PREVIOUS_VALUE' : 'mass_updates.edit.CURRENT_VALUE'}
          values={{
            value: viewModel.get(field).get('oldValue') || '--'
          }}
        />
      </div>
    </div>
  );
});

export default TextField;
