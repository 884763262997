import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, LexicalEditor} from 'components';
import OfferFieldFactory from './OfferFieldFactory';

const OfferFields = observer(({uiState}) => {
  const {offer} = uiState;

  return (
    offer.fields.map((field) => (
      <OfferFieldFactory key={field.key} field={field} uiState={uiState}/>
    ))
  );
});

const OfferDetailsForm = observer(({uiState}) => {
  const {offer, errors} = uiState;

  return (
    <React.Fragment>
      <div className='flex OfferDetailsForm'>
        <div className='col-4'>
          <Panel.Header title={t('offers.editor.Enter Offer Details')}/>
          <div className='OfferDetailsFields px1 mln1'>
            <OfferFields uiState={uiState}/>
          </div>
        </div>
        <div className='col-8 pl2'>
          <LexicalEditor
            className='full-height'
            onChange={content => uiState.updateRenderedContent(content)}
            initialEditorState={offer.lexicalState}
            initialHTML={offer.renderedContent}
            fields={offer.fields}
            errorMessage={errors.rendered_content}
          />
        </div>
      </div>
    </React.Fragment>
  );
});

export default OfferDetailsForm;
