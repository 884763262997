import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const optionColor = (numericValue) => {
  if (numericValue < 3) {
    return '#F64A6C';
  } else if (numericValue === 3) {
    return '#F4AC29';
  } else {
    return '#80D137';
  }
};

const Reviewers = observer(({uiState, option, question, periodDate, currentTrend, numericValue}) => {
  return (
    <div
      onClick={() => uiState.openReviewersModal(question.id, option.value, periodDate)}
      className={`mb2 p2 rounded border ${uiState.survey.anonymous ? '' :'clickable bg-hover-smoke'}`}>
      <div className='mb2'>
        <span
          className='inline-block circle mr1'
          style={{height: '10px', width: '10px', backgroundColor: optionColor(numericValue)}}
        />
        <span className='pr1'>{option.value}</span>
      </div>
      <FormattedMessage
        id='surveys.view.NUMBER_OF_RESPONDENTS'
        values={{respondentNumber: currentTrend.value.breakdown[numericValue]}}
      />
    </div>
  );
});

export default Reviewers;
