import React from 'react';
import {observer} from 'mobx-react';
import {ObjectSelect2, FormError, Panel} from 'components';
import {FormattedMessage, injectIntl} from 'react-intl';
import {t} from 'shared/core';

const Assignees = injectIntl(observer(({uiState, intl}) => {
  const {role, errors} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('company_settings.permissions.Who should be added to this role?')}/>
      <div className='clearfix'>
        <div className='h4 waterloo mb2'>
          <FormattedMessage id='company_settings.permissions.Choose which users should have this role.'/>
        </div>
        <ObjectSelect2 value={role.users}
                      items={uiState.availableUsers}
                      onAdd={value => role.addUser(value)}
                      onRemove={value => role.removeUser(value)}
                      placeholder='company_settings.permissions.Add user' multiple={true} />
        {errors.users && <div className='pt1'>
          <FormError message={errors.users}/>
        </div>}
        <div className='mt2'>
          <FormattedMessage
            id='company_settings.permissions.IMPERSONATION_EXPLAINER'
            values={{
              userDirectoryLink: <a href={`/company_settings/users`} target={'_blank'}>
                <FormattedMessage id="company_settings.permissions.user directory"/>
              </a>
            }}
          />
        </div>
      </div>
    </Panel>
  );
}));

export default Assignees;
