import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {EmployeeNameWithAvatar, CheckboxWithLabel, FormError} from 'components';

const EditUserRolesModal = observer(({uiState}) => {
  const {editingUser, roleAssignmentViewModels, errors} = uiState;

  return (
    <Modal 
      size='sm'
      isOpen={uiState.editUserRolesModalOpen}
      onHide={() => uiState.closeEditUserRolesModal()}
      title='company_settings.users.Edit roles for user'
    >
      <EmployeeNameWithAvatar size='md' employee={editingUser} />
      <div className='mt3'>
        {roleAssignmentViewModels.map(viewModel => <div key={viewModel.role.id} className='my1'>
          <CheckboxWithLabel 
            checked={viewModel.checked}
            onChange={e => viewModel.checked = e.checked}
            label={viewModel.role.name}
            translatable={false}
          />
        </div>)}
      </div>
      <FormError message={errors.user} />
      <ModalButtons
        onSave={() => uiState.saveRoleChanges()}
        onCancel={() => uiState.closeEditUserRolesModal()}/>
    </Modal>
  );
});

export default EditUserRolesModal;
