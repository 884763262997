import React from 'react';
import {observer} from 'mobx-react';
import {FieldHeader} from 'components';
import CustomFieldContentFactory from './CustomFieldContentFactory';


const CustomField = observer(({customField, allowClear, errorMessage}) => {
  return (
    <div>
      <FieldHeader
        title={customField.name}
        description={customField.description}
        required={customField.required}
        descriptionClassName='waterloo whitespace-pre-wrap'
      />
      <CustomFieldContentFactory
        customField={customField}
        type={customField.type}
        value={customField.value}
        onChange={(value) => customField.merge({value})}
        allowClear={allowClear}
        errorMessage={errorMessage}
      />
    </div>
  );
});

CustomField.defaultProps = {
  allowClear: true
};

export default CustomField;
