import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import {useCallback, useEffect, useMemo, useState} from 'react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import {$createMentionNode} from '../nodes/MentionNode';
import _ from 'lodash';
import {EmployeeNameWithAvatar} from 'components';
import {useIsFocused} from 'components/lexical_editor/hooks/useIsFocused';

const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const NAME = '\\b[A-Z][^\\s' + PUNCTUATION + ']';

const CapitalizedNameMentionsRegex = new RegExp('(^|[^#])((?:' + NAME + '{' + 1 + ',})$)');

const TRIGGERS = ['@'].join('');

// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = '[^' + TRIGGERS + PUNCTUATION + '\\s]';

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  '(?:' +
  '\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
  ' |' + // E.g. " " in "Josh Duck"
  '[' +
  PUNCTUATION +
  ']|' + // E.g. "-' in "Salier-Hellendag"
  ')';

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
  '(^|\\s|\\()(' +
  '[' +
  TRIGGERS +
  ']' +
  '((?:' +
  VALID_CHARS +
  VALID_JOINS +
  '){0,' +
  LENGTH_LIMIT +
  '})' +
  ')$',
);

// At most, 5 suggestions are shown in the popup.
const SUGGESTION_LIST_LENGTH_LIMIT = 5;

function checkForCapitalizedNameMentions(text, minMatchLength) {
  const match = CapitalizedNameMentionsRegex.exec(text);
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know its
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[2];
    if (matchingString != null && matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: matchingString,
      };
    }
  }
  return null;
}

function checkForAtSignMentions(text, minMatchLength) {
  let match = AtSignMentionsRegex.exec(text);

  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know its
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(text) {
  const match = checkForAtSignMentions(text, 0);
  return match === null ? checkForCapitalizedNameMentions(text, 3) : match;
}

class MentionTypeaheadOption extends MenuOption {
  constructor(mention) {
    super(mention.user.name);
    this.user = mention.user;
  }
}

function MentionsTypeaheadMenuItem({index, isSelected, onClick, onMouseEnter, option}) {
  let className = 'item';
  if (isSelected) {
    className += ' selected';
  }
  return (
    <li
      key={option.key}
      tabIndex={-1}
      className={className}
      ref={option.setRefElement}
      role='option'
      aria-selected={isSelected}
      id={'typeahead-item-' + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}>
      <EmployeeNameWithAvatar employee={option.user}/>
    </li>
  );
}

export default function MentionsPlugin({mentionOptions}) {
  if (_.isEmpty(mentionOptions)) return null;

  const [editor] = useLexicalComposerContext();
  const editorIsFocused = useIsFocused();
  const [queryString, setQueryString] = useState(null);
  const [editorIsFocusedWithDelay, setEditorIsFocusedWithDelay] = useState(editorIsFocused);
  const [editorFocusOutTimeout, setEditorFocusOutTimeout] = useState(null);

  const mentions = useMemo(() => mentionOptions.filter((mention) =>
    queryString ? mention.name.toLowerCase().includes(queryString.toLowerCase()) : true
  ), [queryString, mentionOptions]);

  useEffect(() => {
    if (editorIsFocused) {
      setEditorIsFocusedWithDelay(true);
      clearTimeout(editorFocusOutTimeout);
    } else {
      setEditorFocusOutTimeout(
        setTimeout(() => {
          setEditorIsFocusedWithDelay(false);
        }, 150),
      );
    }
  }, [editorIsFocused]);

  const options = useMemo(
    () =>
      mentions
        .map(
          (mention) =>
            new MentionTypeaheadOption(mention),
        )
        .slice(0, SUGGESTION_LIST_LENGTH_LIMIT),
    [mentions],
  );

  const onSelectOption = useCallback(
    (selectedOption, nodeToReplace, closeMenu) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(`@${selectedOption.user.name}`, selectedOption.user.id);
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }
        mentionNode.select();
        closeMenu();
      });
    },
    [editor],
  );

  return (
    <LexicalTypeaheadMenuPlugin
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={getPossibleQueryMatch}
      options={options}
      menuRenderFn={(
        anchorElementRef,
        {selectedIndex, selectOptionAndCleanUp, setHighlightedIndex},
      ) =>
        anchorElementRef.current && mentions.length && editorIsFocusedWithDelay
          ? ReactDOM.createPortal(
            <div className='typeahead-popover mentions-menu'>
              <ul>
                {options.map((option, i) => (
                  <MentionsTypeaheadMenuItem
                    index={i}
                    isSelected={selectedIndex === i}
                    onClick={() => {
                      setHighlightedIndex(i);
                      selectOptionAndCleanUp(option);
                    }}
                    onMouseEnter={() => {
                      setHighlightedIndex(i);
                    }}
                    key={option.key}
                    option={option}
                  />
                ))}
              </ul>
            </div>,
            anchorElementRef.current,
          )
          : null
      }
    />
  );
}
