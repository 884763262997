import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class StaffCounts extends DomainObject {
  @observable active;
  @observable onLeave;
  @observable fullTime;
  @observable partTime;
  @observable temp;
  @observable intern;
  @observable volunteer;
  @observable contractor;
  @observable seasonal;
  @observable casual;
}

export default StaffCounts;
