import React from 'react';
import {action} from 'mobx';
import {QuickTip, Layout} from 'components';
import {auth, t, endpoints, withState, api} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {redirect} from 'shared/tools';

const BrokerPortalStopImpersonation = ({uiState}) => {
  return (
    <Layout>
      <div className='pt2'>
        <QuickTip
          trait='caution'
          header={auth.unescapedCompanyName}
          paragraphs={[
            <FormattedMessage id='broker_portal.impersonation.CURRENTLY_ACTING_AS' values={{
              company: auth.unescapedCompanyName
            }}/>
          ]}
          actions={[
            {
              caption: t('broker_portal.impersonation.Return to My Clients'),
              onClick: () => uiState.stopImpersonation(),
              loadingText: t('broker_portal.impersonation.Returning to My Clients...')
            }
          ]}
        />
      </div>
    </Layout>
  );
};

class BrokerPortalStopImpersonationState {
  @action async stopImpersonation() {
    await api.put(endpoints.BROKER_PORTAL.STOP_IMPERSONATING);
    return redirect('/');
  }
}

export default withState(BrokerPortalStopImpersonation, BrokerPortalStopImpersonationState);
