import React from 'react';
import {observer} from 'mobx-react';
import TimeOffSubnav from 'containers/time_off/TimeOffSubnav';
import TimeOffBlockedDatesContainerWithState from './TimeOffBlockedDatesContainerWithState';

const BlockedDatesContainer = observer(({scope}) => {
  return (
    <div>
      <TimeOffSubnav scope={scope}/>
      <TimeOffBlockedDatesContainerWithState/>
    </div>
  );
});

export default BlockedDatesContainer;
