import React from 'react';
import {observer} from 'mobx-react';
import {FormLayout, Select2} from 'components';
import {t} from 'shared/core';

const LanguagePreferenceField = observer(({benefitInfo, errors}) => {
  return (
    <FormLayout>
      <Select2 
        name='languagePreference' 
        value={benefitInfo.languagePreference}
        onChange={e => benefitInfo.merge({languagePreference: e.target.value})}
        errorMessage={errors.languagePreference}
        label='onboard.family_status.Language preference'
        helpLink={{
          explanation: {
            header: t('onboard.family_status.lang_pref_help_title'),
            paragraphs: [t('onboard.family_status.lang_pref_help_text')]
          }
        }}
      >
        <option key='en' value='en'>{t('onboard.family_status.en')}</option>
        <option key='fr' value='fr'>{t('onboard.family_status.fr')}</option>
      </Select2>
    </FormLayout>
  );
});

export default LanguagePreferenceField;
