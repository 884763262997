import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {endpoints, types, t} from 'shared/core';
import {successAlert} from 'shared/tools';
import {Calendar} from 'stores/calendars';
import {Company} from 'stores/company';
import _ from 'lodash';

class CalendarDirectoryState {
  store = new DomainStore();
  history;
  errors = {};

  @observable legacyCalendars = [];
  @observable company;

  receiveProps({history}) {
    this.history = history;
  }

  @action async load() {
    await this.store._compose([
      endpoints.CALENDARS,
      endpoints.CURRENT_COMPANY
    ]);

    this.legacyCalendars = this.store._getAll(types.CALENDAR, Calendar);
    this.company = new Company(
      this.store._getSingle(types.COMPANY)
    );
  }

  @action async toggleLegacyCalendar(legacyCalendar, value) {
    legacyCalendar.merge({enabled: value});

    const {model, errors} = await this.store.put(
      endpoints.CALENDAR.TOGGLE.with(legacyCalendar.id),
      types.CALENDAR,
      legacyCalendar
    );
    this.errors = errors;

    if (model) {
      successAlert(t('company_settings.calendars.Calendar updated!'));
    }
  }

  @action async removeSegmentCalendar(segmentCalendar) {
    await this.store.destroy(segmentCalendar);
    successAlert(t('company_settings.calendars.Deleted custom calendar.'));
  }

  @action goToEditSegmentCalendar(segmentCalendar) {
    const id = _.get(segmentCalendar, 'id', '');
    this.history.push(`/calendars/edit/${id}`);
  }
}

export default CalendarDirectoryState;
