import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import Field from './Field';

const PersonalDetails = observer(({uiState}) => {
  const {benefitInfo} = uiState;

  return (
    <Panel>
      <div className='medium mb2'>
        <FormattedMessage id='employees.profile.benefits.Personal Details'/>
      </div>
      <Field title='Sex at Birth' value={t(`benefit_enrolment.${benefitInfo.gender}`)}/>
      {benefitInfo.languagePreference && <Field
        title='Language Preference'
        value={t(`models.segment_calendar.locales.${benefitInfo.languagePreference}`)}/>}
      <Field title='Tax Exempt' value={t(`benefit_enrolment.${benefitInfo.taxExempt}`)}/>
      {benefitInfo.taxExempt && <Field
        title='INAC Number'
        value={benefitInfo.inacNumber}/>}
    </Panel>
  );
});

export default PersonalDetails;
