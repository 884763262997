import React from 'react';
import {observer} from 'mobx-react';
import {action} from 'mobx';
import {withState} from 'shared/core';
import Spinner from './spinners/Spinner';
import onClickOutside from 'react-onclickoutside';

class EmojiPickerState {
  Picker;

  @action async load() {
    const module = await import(/* webpackChunkName: 'emoji-mart-async' */ 'emoji-mart')
      .catch(e => {
        if (!(/Loading chunk .* failed/.test(e.message))) {
          throw e;
        }
      });

    if (module) {
      this.Picker = module.Picker;
    }
  }
}

@observer class EmojiPicker extends React.Component {
  handleClickOutside = evt => {
    this.props.onClickOutside();
  };

  render() {
    const {onEmojiClick, className, showPicker, onPickerClick, uiState} = this.props;
    const {Picker} = uiState;

    if (!Picker) {
      return null;
    }

    return (
      <div className={className}>
        <div
          className={'bg-hover-smoke bg-haze px1 rounded2 jumbo border border--whale table'}
          onClick={() => onPickerClick()}
        >
          <div className='table-cell'>
            <i
              className='material-icons jumbo clickable align-bottom'
              style={{height: '22px', verticalAlign: 'text-top', fontSize: '20px'}}>
              {'insert_emoticon'}
            </i>
          </div>
          <div className='jumbo table-cell align-middle ml1'>{'+'}</div>
        </div>
        {showPicker &&
          <div className='table-cell align-top absolute z-index-2 mt1'>
            <Picker set='apple' onSelect={emoji => onEmojiClick(emoji)}/>
          </div>
        }
      </div>
    );
  }
}

EmojiPicker.defaultProps = {
  showPicker: false
};

export default withState(onClickOutside(EmojiPicker), EmojiPickerState, {
  spinner: Spinner
});
