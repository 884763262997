import React from 'react';
import PropTypes from 'prop-types';
import {Slider, Handles, Tracks, Rail, Ticks} from 'react-compound-slider';
import Handle from './Handle';
import Track from './Track';
import Tick from './Tick';
import {observer} from 'mobx-react';

const SliderBar = observer(({onChange, step, range, value, showLabels, ticks}) => {
  return (
    <Slider
      className='SliderBar-slider'
      domain={range}
      step={step}
      mode={2}
      values={[value]}
      onChange={values => onChange(values[0])}
    >
      <Rail>
        {({getRailProps}) => (
          <div className='SliderBar-rail' {...getRailProps()}/>
        )}
      </Rail>
      <Handles>
        {({handles, getHandleProps}) => (
          <div className='SliderBar-handles'>
            {handles.map(handle => (
              <Handle key={handle.id} handle={handle} getHandleProps={getHandleProps}/>
            ))}
          </div>
        )}
      </Handles>
        <Tracks right={false}>
          {({tracks, getTrackProps}) => (
            <div className='SliderBar-tracks'>
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      <Ticks count={ticks}>
        {({ticks}) => (
          <div className='SliderBar-ticks'>
            {ticks.map(tick => (
              <Tick key={tick.id} tick={tick} count={ticks.length} showLabels={showLabels}/>
            ))}
          </div>
        )}
      </Ticks>
    </Slider>
  );
});

SliderBar.propTypes = {
  onChange: PropTypes.func.isRequired
};

SliderBar.defaultProps = {
  step: 1,
  range: [0, 100],
  value: 0,
  showLabels: true,
  ticks: 10
};

export default SliderBar;
