import React from 'react';
import {observer} from 'mobx-react';
import {rangeDate} from 'shared/tools';
import {QuickTip, Panel, Spinner, List} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {dateSpan} from 'shared/tools';
import EmptyState from './RequestEmptyState';

const ConcurrentRequestsList = observer(({concurrentRequests}) => {
  return (
    <ul className='pl2'>
      {concurrentRequests.map(timeOffRequest => {
        return <li key={timeOffRequest.id}>
          {`${dateSpan(timeOffRequest.startDate, timeOffRequest.endDate)} (${timeOffRequest.timeOffType.name})`}
        </li>;
      })}
    </ul>
  );
});

const ConcurrentRequests = observer(({uiState}) => {
  const {concurrentRequests} = uiState;

  if (concurrentRequests.length === 0) {
    return null;
  }
  return (
    <div className='mt2'>
      <QuickTip
        trait='info'
        header={<FormattedMessage id='employees.profile.time_off.request.CONCURRENT_HEADER'/>}
        paragraphs={[<ConcurrentRequestsList concurrentRequests={concurrentRequests}/>]}
      />
    </div>
  );
});

const Summary = observer(({uiState}) => {
  const {loadingRequestSummary, summaryItems} = uiState;
  if (loadingRequestSummary) return <Spinner/>;

  return (
    <div>
      <List
        items={summaryItems}
      />
      <ConcurrentRequests uiState={uiState}/>
    </div>
  );
});

const RequestSummary = observer(({uiState}) => {
  const {request} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.time_off.request.summary.REQUEST_SUMMARY', {timeOffType: request.timeOffType.name})}/>
      {request.hasValidDates && <div>
        <Panel.Subheader
          title={t('employees.profile.time_off.request.summary.DATES', {startDate: rangeDate(request.startDate), endDate: rangeDate(request.endDate)})}
        />
        <Summary uiState={uiState}/>
      </div>}
      {!request.hasValidDates && <EmptyState message='employees.profile.time_off.request.Enter a valid start date and end date to see the request summary.'/>}
    </Panel>
  );
});

export default RequestSummary;
