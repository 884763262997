import {observable, action} from 'mobx';
import {DomainStore, DomainObject} from 'shared/store';
import {endpoints, types} from 'shared/core';
import {redirect} from 'shared/tools';
import _ from 'lodash';

class BrokerSignupForm extends DomainObject {
  @observable broker;
  @observable token;
}

class BrokerSignupFormState {
  store = new DomainStore();
  match;

  @observable brokerNotFound = false;
  @observable form = new BrokerSignupForm();
  @observable errors;

  receiveProps({match}) {
    this.match = match;
  }

  @action async load() {
    const key = this.match.params.benefitsBroker;
    await this.store._compose([
      endpoints.BROKER.withKey(key)
    ]);
    this.broker = this.store._getSingle(
      types.BROKER
    );
    this.form.merge({
      broker: this.broker,
      token: this.match.params.token
    });
  }

  @action async acceptInvitation() {
    const {model, errors} = await this.store.post(
      endpoints.MY_ACCOUNTS.ALL,
      types.MY_ACCOUNT,
      this.form
    );

    this.errors = errors;

    if (model) {
      return redirect('/');
    }
  }
}

export default BrokerSignupFormState;
