import React from 'react';
import Dropzone from 'react-dropzone';
import {FormattedMessage} from 'react-intl';
import {Button} from 'components';

const styles = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  left: '0',
  top: '0',
  backgroundColor: 'rgba(255,255,255,0.7)',
  textAlign: 'center',
  color: '#1776F5',
  border: '4px solid #1176F5',
  borderRadius: '6px'
};

class EmployeeDocumentDropzone extends React.Component {
  onOpenClick = () => {
    this.dropzone.open();
  }

  render() {
    const {isUpdating} = this.props;

    return (
      <div className='inline-block'>
        <div className='table-cell align-middle'>
          <Dropzone
            ref={(node) => this.dropzone = node}
            onDragLeave={this.props.onDragLeave}
            onDragEnter={this.props.onDragEnter}
            onDrop={this.props.onDrop}
            activeStyle={styles}
            className='Dropzone'/>
        </div>
        <div className='table-cell align-middle'>
          <Button size='sm' onClick={() => this.onOpenClick()} disabled={isUpdating}>
            <FormattedMessage id='employees.profile.documents.Upload File(s)'/>
          </Button>
        </div>
      </div>
    );
  }
}

export default EmployeeDocumentDropzone;
