import React from 'react';
import {observer} from 'mobx-react';
import RecruitingSubnav from 'recruiting/components/RecruitingSubnav';
import EmailTemplateContainerWithState from 'containers/recruiting/components/email_templates/EmailTemplateContainerWithState';

const EmailTemplateContainer = observer(({history}) => {

  return (
    <div>
      <RecruitingSubnav />
      <EmailTemplateContainerWithState history={history} />
    </div>
  );
});

export default EmailTemplateContainer;
