import React from 'react';
import {observer} from 'mobx-react';
import {Checkbox, InteractiveTable} from 'components';
import {Modal, ModalButtons} from 'components/modals';
import DueDateView from './DueDateView';
import Assignee from './Assignee';
import {endpoints, types} from 'shared/core';
import {TaskTemplate} from 'stores/tasks';
import _ from 'lodash';

const TaskTemplateSelector = observer(({model, uiState}) => {
  return <Checkbox checked={uiState.taskTemplateSelected(model)} onChange={() => uiState.toggleTaskTemplateSelected(model)}/>;
});

const COLUMNS = [
  {
    header: '',
    component: TaskTemplateSelector,
    width: 1
  },
  {
    header: 'tasks.Task Name',
    attribute: 'name',
    width: 4
  },
  {
    header: 'tasks.Assignee',
    component: Assignee,
    width: 4
  },
  {
    header: 'tasks.Due',
    component: DueDateView,
    width: 3
  }
];

const LOCALIZATION = {
  emptyState: 'company_settings.tasks.No tasks to display'
};

const AddTaskTemplatesModal = observer(({uiState}) => {
  const {config, taskTemplatesWithDefaults, hasManager} = uiState;

  return (
    <Modal
      isOpen={uiState.addTaskTemplateModalOpen}
      onHide={() => uiState.closeAddTaskTemplateModal()}
      title={`tasks.${uiState.config.selectTemplatesToUse}`}>
      <InteractiveTable
        showLinks={false}
        indexBy='taskTemplate.clientId'
        columns={COLUMNS}
        localization={LOCALIZATION}
        uiState={uiState}
        proxy={{
          type: 'async',
          modelType: types.TASK_TEMPLATE,
          model: TaskTemplate,
          endpoint: endpoints.TASK_TEMPLATES.withType(config.templateType, _.map(taskTemplatesWithDefaults, 'parentTemplateId'), !hasManager)
        }}
      />
      <ModalButtons
        onSave={() => uiState.saveSelectedTaskTemplates()}
        onCancel={() => uiState.closeAddTaskTemplateModal()}/>
    </Modal>
  );
});

export default AddTaskTemplatesModal;
