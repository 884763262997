import {observable, action, computed} from 'mobx';
import {DomainObject} from 'shared/store';
import {auth} from 'shared/core';
import {OFFICE_CONTENT_TYPES} from 'shared/constants';
import DOCUMENT_TYPES from './documentTypes';
import _ from 'lodash';
import prettyBytes from 'pretty-bytes';

class AttachedDocument extends DomainObject {
  @observable id;
  @observable key;
  @observable fileName;
  @observable fileSize;
  @observable contentType;
  @observable label = '';
  @observable previewUrl;
  @observable title; // TODO: remove this after line 24 is fixed
  @observable attachedDocumentId;

  @action merge(other) {
    super.merge(other);
    // TODO: fix this
    if (other) {
      this.attachedDocumentId = other.id;
      this.id = other.key;
      this.label = other.key;
      this.title = other.label;
    }
  }

  @computed get preview() {
    return this.previewUrl ?
      this.previewUrl : `${this.label}?policy=${auth.fileStack.uploadPolicy.policy}&signature=${auth.fileStack.uploadPolicy.signature}`;
  }

  @computed get documentType() {
    if (_.includes(OFFICE_CONTENT_TYPES, this.contentType)) {
      return DOCUMENT_TYPES.OFFICE;
    } else if (this.contentType === 'application/pdf') {
      return DOCUMENT_TYPES.PDF;
    } else {
      return DOCUMENT_TYPES.OTHER;
    }
  }

  @computed get fileSizeInBytes() {
    return prettyBytes(this.fileSize);
  }
}

export default AttachedDocument;
