import React from 'react';
import {observer} from 'mobx-react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {AdminContainer} from './admin';
import {SummaryContainer} from './summary';
import {TeamContainer} from './team';
import {ResultsContainer} from './results';
import {withState} from 'shared/core';
import {ReviewViewState} from '../state';
import {RevieweeResultsContainer} from './reviewee_results';
import {CycleResultsContainer} from './cycle_results';

const ReviewViewPage = observer(({uiState}) => {
  const {cycle, scope, match, defaultPath} = uiState;

  return (
    <Switch>
      {scope.overview && 
        <Route path={`${match.path}/admin`} render={({match, history}) => <AdminContainer match={match} cycle={cycle} scope={scope} history={history}/>}/>}
      {scope.summary && 
        <Route path={`${match.path}/summary`} render={({match, history}) => <SummaryContainer match={match} cycle={cycle} scope={scope} history={history}/>}/>}
      {(scope.write || scope.team) && 
        <Route path={`${match.path}/write`} render={({match}) => <TeamContainer match={match} cycle={cycle} scope={scope}/>}/>}
      {(scope.write || scope.team || scope.overview) && 
        <Route path={`${match.path}/reviewee/:revieweeId/employee/:employeeId/:revieweeUrlSlug?/:backPath?`} 
          render={({match}) => <RevieweeResultsContainer match={match} cycle={cycle} scope={scope}/>}/>}
      {scope.results && 
        <Route path={`${match.path}/results`} render={({match}) => <ResultsContainer match={match} cycle={cycle} scope={scope}/>}/>}
      {scope.cycleResults && 
        <Route path={`${match.path}/cycle_results`} render={({match}) => <CycleResultsContainer match={match} cycle={cycle} scope={scope}/>}/>}
      <Redirect strict exact from={match.path} to={`${match.url}/${defaultPath}`} />
      <Redirect exact from={match.path} to={`${match.url}${defaultPath}`} />
    </Switch>
  );
});

export default withState(ReviewViewPage, ReviewViewState);
