import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {ObjectSelect2} from 'components';

const EnrolInPolicyModal = observer(({uiState}) => {
  return (
    <Modal
      size='sm'
      title='employees.profile.time_off.Enrol in Time Off Policy'
      isOpen={uiState.enrolInPolicyModalOpen}
      onHide={() => uiState.cancelEnrolInTimeOffPolicyModal()}
    >
      <ObjectSelect2
        label='employees.profile.time_off.Choose New Policy'
        value={uiState.selectedPolicy}
        onChange={value => uiState.setSelectedPolicy(value)}
        items={uiState.policies}
      />
      <ModalButtons
        onSave={() => uiState.enrolInPolicy()}
        onCancel={() => uiState.cancelEnrolInTimeOffPolicyModal()}
      />
    </Modal>
  );
});

export default EnrolInPolicyModal;
