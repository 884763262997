import React, {useEffect, useRef} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {Avatar, ActionLink, HtmlRender} from 'components';
import NewEntry from './NewEntry';
import FeedbackSentiment from './FeedbackSentiment';
import EmailEntry from './EmailEntry';
import InterviewGuideResponseEntry from './InterviewGuideResponseEntry';
import {AttachedDocumentDownload} from 'components';
import CandidateRater from './CandidateRater';

const EntryRightMenu = ({detailsState, entry}) => {
  if (!entry.canDelete && !entry.canPatch) return null;
  return (
    <div className='right'>
      <ActionLink className='dropdown'>
        <i className='material-icons submarine align-middle' data-toggle='dropdown'>{'more_horiz'}</i>
        <ul className='dropdown-menu right-0'>
          <li>
            {entry.canPatch && !entry.isRating && <div onClick={async () => detailsState.editEntry(entry)} className='py1 px3 regular jumbo clickable'>
              <FormattedMessage id='recruiting.hiring_funnel.Edit'/>
            </div>}
            {entry.canDelete && <div onClick={async () => detailsState.deleteEntry(entry)} className='py1 px3 regular jumbo clickable'>
              <FormattedMessage id='recruiting.hiring_funnel.Delete'/>
            </div>}
          </li>
        </ul>
      </ActionLink>
    </div>
  );
};

const StageChangeEntry = injectIntl(({entry, intl}) => {
  return (
    <div className='clearfix border-top p3'>
      <div className='col col-1 pr1'>
        <Avatar employee={entry.user} size='sm' className='left'/>
      </div>
      <div className='col col-11 pl1'>
        <div className='clearfix'>
          <div className='col col-12'>
            <div className='table-cell pr1'>
              <div>
                <span className='h3 waterloo pr1'>
                  <FormattedMessage id='recruiting.hiring_funnel.CANDIDATE_MOVED_TO' values={{moved: entry.isForward ? intl.formatMessage({id: 'recruiting.hiring_funnel.Advanced'}) : intl.formatMessage({id: 'recruiting.hiring_funnel.Reverted'})}}/>
                </span>
                <span className='h3 medium'>{entry.nextFunnelStageName}</span>
              </div>
              <div className='h5 jumbo'>{entry.name}</div>
            </div>
            <div className='table-cell pl1'>
              <div className='h5 jumbo'>{entry.date}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const QualificationStatusChangeEntry = observer(({entry}) => {
  const user = entry.automated ? {} : entry.user;

  return (
    <div className='clearfix border-top p3'>
      <div className='col col-1 pr1'>
        <Avatar employee={user} collageAvatar={entry.automated} size='sm' className='left'/>
      </div>
      <div className='col col-11 pl1'>
        <div className='clearfix'>
          <div className='col col-12'>
            <div className='table-cell pr1'>
              <div>
                <span className='h3 medium'>
                  <FormattedMessage id={entry.isDisqualified ? 'recruiting.hiring_funnel.Disqualified' : 'recruiting.hiring_funnel.Qualified' }/>
                </span>
              </div>
            </div>
            <div className='table-cell pl1'>
              <div className='h5 jumbo'>{entry.date}</div>
            </div>
            <div className='h5 jumbo'>
              {entry.automated ?
                <FormattedMessage id='recruiting.hiring_funnel.Automatically disqualified based on rules'/> :
                entry.name
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const PositionChangeEntry = observer(({entry}) => {
  return (
    <div className='clearfix border-top p3'>
      <div className='col col-1 pr1'>
        <Avatar employee={entry.user} size='sm' className='left'/>
      </div>
      <div className='col col-11 pl1'>
        <div className='clearfix'>
          <div className='col col-12'>
            <div className='table-cell pr1'>
              <div>
                <span className='h3 waterloo pr1 whitespace-pre-line'>
                  <FormattedMessage id='recruiting.hiring_funnel.MOVED_FROM' />
                </span>
                <span className='h3 whitespace-pre-line'>
                  <FormattedMessage
                    id='recruiting.hiring_funnel.POSITION_TO_POSITION'
                    values={{
                      oldPosition: entry.oldPositionName,
                      newPosition: entry.newPositionName
                    }}
                  />
                </span>
              </div>
              <div className='h5 jumbo'>{entry.name}</div>
            </div>
            <div className='table-cell pl1'>
              <div className='h5 jumbo'>{entry.date}</div>
            </div>
          </div>
          <div className='col col-12 pt2 whitespace-pre-line'>
            {entry.text}
          </div>
        </div>
      </div>
    </div>
  );
});

const UploadEntry = observer(({detailsState, entry}) => {
  return (
    <div className='clearfix border-top p3'>
      <div className='col col-1 pr1'>
        <Avatar employee={entry.user} size='sm' className='left'/>
      </div>
      <div className='col col-11 pl1'>
        <div className='clearfix'>
          <div className='col col-12'>
            <EntryRightMenu detailsState={detailsState} entry={entry}/>
            <div className='table-cell pr1'>
              <div className='h3 medium'>
                <FormattedMessage id='recruiting.hiring_funnel.Internal Documents'/>
              </div>
              <div className='h5 jumbo'>{entry.name}</div>
            </div>
            <div className='table-cell pl1'>
              <div className='h5 jumbo'>{entry.date}</div>
            </div>
          </div>
          <div className='col col-12 pt2 whitespace-pre-line'>
            {entry.text}
          </div>
          <div className='col col-12 mt2 space-y'>
            {entry.attachments.map(attachment => <AttachedDocumentDownload key={attachment.id} doc={attachment}/>)}
          </div>
        </div>
      </div>
    </div>
  );
});

const OfferLink = observer(({entry, detailsState}) => {
  const {canHireEmployees} = detailsState;
  const {offerId} = entry;

  if (canHireEmployees) {
    return (
      <ActionLink onClick={() => detailsState.openOfferPreviewModal(offerId)}>
        <FormattedMessage id='recruiting.hiring_funnel.Offer'/>
      </ActionLink>
    );
  }

  return (
    <FormattedMessage id='recruiting.hiring_funnel.Offer'/>
  );
});

const OfferEntry = observer(({entry, detailsState, candidate}) => {
  return (
    <div className='clearfix border-top p3'>
      <div className='col col-1 pr1'>
        <Avatar employee={entry.action === 'accept' ? candidate : entry.user} size='sm' className='left'/>
      </div>
      <div className='col col-11 pl1'>
        <div className='clearfix'>
          <div className='col col-12'>
            <div className='table-cell pr1'>
              <div>
                <span className='h3 waterloo pr1'>
                  <FormattedMessage
                    id={`recruiting.hiring_funnel.offer_actions.${entry.action}`}
                    values={{offerLink: <OfferLink detailsState={detailsState} entry={entry}/>}}
                  />
                </span>
              </div>
              <div className='h5 jumbo'>{entry.action === 'accept' ? candidate.name : entry.name}</div>
            </div>
            <div className='table-cell pl1'>
              <div className='h5 jumbo'>{entry.date}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const RatingEntry = observer(({entry, detailsState}) => {
  return (
    <div className='clearfix border-top p3'>
      <div className='col col-1 pr1'>
        <Avatar employee={entry.user} size='sm' className='left'/>
      </div>
      <div className='col col-11 pl1'>
        <div className='clearfix'>
          <div className='col col-12'>
            <EntryRightMenu detailsState={detailsState} entry={entry}/>
            <div className='table-cell pr1'>
              <div className='h3 medium'>
                <FormattedMessage id='recruiting.hiring_funnel.Rating'/>
              </div>
              <div className='h5 jumbo'>{entry.name}</div>
            </div>
            <div className='table-cell pl1'>
              <div className='h5 jumbo'>{entry.date}</div>
            </div>
              <div className='col col-12 pt2'>
                {entry.score ?
                  <CandidateRater score={entry.score}/> :
                  <FormattedMessage id='recruiting.hiring_funnel.Removed a rating'/>
                }
              </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const TimelineEntry = ({candidate, entry, detailsState, sectionViewModel}) => {
  const feedbackEl = useRef(null);

  useEffect(() => {
    if (feedbackEl.current && location.href.includes(`feedback-${entry.id}`)) {
      feedbackEl.current.scrollIntoView({block: 'center'});
    }
  }, []);


  if (entry.isPositionChange) {
    return <PositionChangeEntry entry={entry} />;
  }

  if (entry.isQualificationStatusChange) {
    return <QualificationStatusChangeEntry entry={entry} />;
  }

  if (entry.isStageChange) {
    return <StageChangeEntry entry={entry} />;
  }

  if (entry.isEmail) {
    return <EmailEntry entry={entry} candidate={candidate} detailsState={detailsState} />;
  }

  if (entry.isUpload) {
    return <UploadEntry entry={entry} detailsState={detailsState} />;
  }

  if (entry.isInterviewGuideResponse) {
    return <InterviewGuideResponseEntry entry={entry} detailsState={detailsState} candidate={candidate} />;
  }

  if (detailsState.isBeingEdited(entry)) {
    return <NewEntry detailsState={detailsState} sectionViewModel={sectionViewModel} entry={entry} />;
  }

  if (entry.isOffer) {
    return <OfferEntry entry={entry} candidate={candidate} detailsState={detailsState}/>;
  }

  if (entry.isRating) {
    return <RatingEntry entry={entry} detailsState={detailsState} candidate={candidate} />;
  }

  return (
    <div className='clearfix border-top p3' ref={feedbackEl}>
      <div className='col col-1 pr1'>
        <Avatar employee={entry.user} size='sm' className='left'/>
      </div>
      <div className='col col-11 pl1'>
        <div className='clearfix'>
          <div className='col col-12'>
            <EntryRightMenu detailsState={detailsState} entry={entry}/>
            <div className='table-cell pr1'>
              <div className='h3 medium'>
                <FormattedMessage id='recruiting.hiring_funnel.Feedback'/>
              </div>
              <div className='h5 jumbo'>{entry.name}</div>
            </div>
            <div className='table-cell pl1'>
              <div className='h5 jumbo'>{entry.date}</div>
            </div>
          </div>
          <div className='col col-12 pt2 whitespace-pre-line word-break'>
            <HtmlRender html={entry.text}/>
          </div>
          {entry.isFeedback && entry.sentiment && <FeedbackSentiment isPositive={entry.isPositiveSentiment}/>}
        </div>
      </div>
    </div>
  );
};

export default observer(TimelineEntry);
