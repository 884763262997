import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {CandidateFields, JobApplicationQuestions} from 'components/candidate_form';
import InternalCandidateFormState from 'containers/recruiting/state/InternalCandidateFormState';
import {ModalButtons} from 'components/modals';

const CandidateForm = observer(({uiState}) => {
  const {candidate, errors} = uiState;

  return (
    <form>
      <div className='Container'>
        <div className='clearfix'>
          <div className='col col-12 md-col-9 p3 bg-white box-shadow-1 rounded'>
            <div className='px2'>
              <div className='h2 medium mb2'>
                <FormattedMessage id='recruiting.candidate.Enter candidate information'/>
              </div>
              <CandidateFields candidate={candidate} errors={errors}/>
              <JobApplicationQuestions uiState={uiState} candidate={candidate}/>
              <ModalButtons onSave={() => uiState.createCandidate()} onCancel={() => uiState.history.goBack()}/>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
});

export default withState(CandidateForm, InternalCandidateFormState);
