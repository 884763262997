import React from 'react';
import {noop} from 'shared/tools';
import {observer} from 'mobx-react';
import TextArea from 'components/forms/TextArea';
import Description from './Description';

const TextAreaField = observer(({answer, required, errorMessage, disabled, onChanged}) => {
  return (
    <div>
      <Description answer={answer} required={required} />
      <TextArea
        type='text'
        value={answer.value}
        disabled={disabled}
        onChange={(e) => {answer.updateValue(e.target.value); onChanged();}}
        className='full-width'
        errorMessage={errorMessage}
      />
    </div>
  );
});

TextAreaField.defaultProps = {
  onChanged: noop
};

export default TextAreaField;
