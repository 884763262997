import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {t, types, endpoints} from 'shared/core';
import {MassUpdate} from 'stores/mass_updates';
import _ from 'lodash';
import {setupAutosaveDraft} from 'shared/tools';
import {Employee} from 'stores/employees';
import {CustomField, CustomFieldGroup} from 'stores/custom_fields';
import Location from 'stores/locations/Location';
import Department from 'stores/departments/Department';
import {JOB_FIELDS, PERSONAL_FIELDS} from 'containers/mass_updates/fields';

class MassUpdateEditorState {
  store = new DomainStore();
  match;
  history;

  @observable errors = {};
  @observable addEmployeeErrors = {};
  @observable massUpdate;
  @observable addEmployeesModalOpen = false;
  @observable publishConfirmationModalOpen = false;
  @observable selectedEmployees = [];
  @observable interactiveAgent = [];
  @observable customFieldGroups = [];
  @observable customFields = [];
  @observable bulkEditField = null;
  @observable bulkEditValue = null;
  @observable bulkEditModalOpen = false;
  @observable updatingIncludedFields = false;

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  managerNameForId(id) {
    const manager = _.find(this.employees, {id: String(id)});
    return _.get(manager, 'name');
  }

  @action openBulkEditModal(field) {
    this.bulkEditField = _.camelCase(field);
    this.bulkEditModalOpen = true;
    this.bulkEditValue = this.massUpdate.bulkEditValues.has(this.bulkEditField) ?
      this.massUpdate.bulkEditValues.get(this.bulkEditField) :
      null;
  }

  @action closeBulkEditModal() {
    this.bulkEditModalOpen = false;
  }

  @action openAddEmployeesModal() {
    this.addEmployeesModalOpen = true;
  }

  @action closeAddEmployeesModal() {
    this.addEmployeesModalOpen = false;
  }

  @action openPublishConfirmationModal() {
    this.publishConfirmationModalOpen = true;
  }

  @action closePublishConfirmationModal() {
    this.publishConfirmationModalOpen = false;
  }

  @action async load() {
    await this.store._compose([
      endpoints.MASS_UPDATES.with(this.match.params.id),
      endpoints.EMPLOYEES.ALL,
      endpoints.LOCATIONS.ALL,
      endpoints.DEPARTMENTS
    ]);

    this.massUpdate = new MassUpdate(this.store._getSingle(types.MASS_UPDATE));
    this.employees = this.store._getAll(types.EMPLOYEE, Employee);
    this.locations = this.store._getAll(types.LOCATION, Location);
    this.departments = this.store._getAll(types.DEPARTMENT, Department);
    await this.loadCustomFields();

    if (!this.massUpdate.readOnly) {
      await setupAutosaveDraft(
        this.massUpdate,
        {id: this.massUpdate.id, type: types.MASS_UPDATE}
      );
    }

    this.transformNewPayRates();
    this.massUpdate.newValues = observable.map(this.massUpdate.newValues);
    this.massUpdate.bulkEditValues = observable.map(this.massUpdate.bulkEditValues);
    Array.from(this.massUpdate.newValues.keys()).map((employeeId) => {
      return this.massUpdate.newValues.set(employeeId, observable.map(this.massUpdate.newValues.get(employeeId)));
    });
  }

  @action async loadCustomFields() {
    await this.store._compose([
      endpoints.CUSTOM_FIELD_GROUPS.with(this.massUpdate.massUpdateType)
    ]);

    this.customFieldGroups = this.store._getAll(types.CUSTOM_FIELD_GROUP, CustomFieldGroup);
    this.customFields = this.store._getAll(types.CUSTOM_FIELD, CustomField);
  }

  @action transformNewPayRates() {
    if (this.massUpdate.newPayRates) {
      Object.entries(this.massUpdate.newPayRates).forEach(([employeeId, payRate]) => {
        this.massUpdate.newValues[employeeId] = {payRate};
      });
      delete this.massUpdate.newPayRates;
    }
  }

  @action updateValue(value, id, field) {
    if (!this.massUpdate.newValues.has(id)) {
      this.massUpdate.newValues.set(id, observable.map({}));
    }
    this.massUpdate.newValues.get(id).set(field, value);
    this.massUpdate.autosaver.autosave();
  }

  @action updateMassUpdate(newValues) {
    this.massUpdate.merge(newValues);
    this.massUpdate.autosaver.autosave();
  }

  @action setBulkEditValue(value) {
    this.bulkEditValue = value;
  }

  @action async applyBulkEdit() {
    this.massUpdate.bulkEditValues.set(this.bulkEditField, this.bulkEditValue);
    this.massUpdate.newValues.forEach((values) => {
      values.delete(this.bulkEditField);
    });
    await this.massUpdate.autosaver.autosave();
    await this.interactiveAgent.refresh();
    this.closeBulkEditModal();
  }

  @action async removeMassUpdateEmployee(model) {
    await this.store.destroy(model);

    delete this.massUpdate.newValues[model.id];
    this.massUpdate.autosaver.autosave();
  }

  @action async addEmployeesToMassUpdate() {
    const selectedEmployeeIds = _.map(this.selectedEmployees, 'id');
    const {errors} = await this.store.post(
      `${this.massUpdate.link('createMassUpdateEmployees')}?effective_date=${this.massUpdate.effectiveDate || ''}`,
      types.MASS_UPDATE_EMPLOYEE,
      {employeeIds: selectedEmployeeIds}
    );
    this.massUpdate.autosaver.autosave();

    this.addEmployeeErrors = errors;

    if (_.isEmpty(errors)) {
      this.interactiveAgent.refresh();
      this.closeAddEmployeesModal();
      this.selectedEmployees = [];
    }
  }

  @action async patchIncludedField(massUpdate) {
    await this.store.patch(massUpdate, types.MASS_UPDATE);
    await this.interactiveAgent.refresh();
    await this.massUpdate.autosaver.autosave();
  }

  @action async publishMassUpdate() {
    const {model, errors} = await this.store.post(
      this.massUpdate.link('publish'),
      types.MASS_UPDATE,
      this.massUpdate
    );

    this.errors = errors;

    if (model) {
      this.history.push('/');
    }

    this.closePublishConfirmationModal();
  }

  @action async addIncludedField(field) {
    this.updatingIncludedFields = true;
    const massUpdate = new MassUpdate({includedFields: this.massUpdate.includedFields.toJS(), links: this.massUpdate.links});
    massUpdate.addIncludedField(field);
    await this.patchIncludedField(massUpdate);
    this.massUpdate.addIncludedField(field);
    this.updatingIncludedFields = false;
  }

  @action async removeIncludedField(field) {
    if (this.updatingIncludedFields) return;

    this.updatingIncludedFields = true;
    const massUpdate = new MassUpdate({includedFields: this.massUpdate.includedFields.toJS(), links: this.massUpdate.links});
    massUpdate.removeIncludedField(field);
    await this.patchIncludedField(massUpdate);
    this.massUpdate.removeIncludedField(field);
    this.updatingIncludedFields = false;
  }

  @computed get fields() {
    const defaultFields = _.map(
      this.defaultFields,
      field => {
        return {
          id: field,
          name: t(`mass_updates.edit.fields.${field}`),
          groupId: 'default'
        };
      }
    );

    const customFields = _.chain(this.customFieldGroups)
      .sortBy('order')
      .flatMap(group => group.sortedCustomFields)
      .map(
        customField => {
          return {
            id: `cf${customField.id}`,
            name: customField.name,
            groupId: customField.customFieldGroupId};
        }
      )
      .value();

    return _.concat(defaultFields, customFields);
  }

  @computed get defaultFields() {
    switch (this.massUpdate.massUpdateType) {
      case 'job':
        return JOB_FIELDS;
      case 'personal':
        return PERSONAL_FIELDS;
      default:
        return [];
    }
  }

  @computed get fieldGroups() {
    const defaultGroup = [{id: 'default', name: t(`mass_updates.edit.${this.massUpdate.massUpdateType}`), order: -1}];

    return _.chain(defaultGroup)
      .concat(this.customFieldGroups.toJS().filter(group => group.customFields.length > 0))
      .sortBy('order')
      .value();
  }
}

export default MassUpdateEditorState;
