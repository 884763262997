import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import {EmployeeNameWithAvatar} from 'components';

const ReviewersModal = observer(({uiState}) => {
  const {currentOption} = uiState;
  if (!currentOption) return null;

  return (
    <Modal 
      size='sm' 
      title={currentOption.key}
      translateTitle={false}
      isOpen={uiState.reviewerModalOpen} 
      onHide={() => uiState.closeReviewersModal()}>
      {currentOption.reviewers.map(reviewer => <div key={reviewer.id} className='mb2'>
        <EmployeeNameWithAvatar employee={reviewer}/>
      </div>)}
    </Modal>
  );
});

export default ReviewersModal;
