import {observable} from 'mobx';
import {Metric} from 'stores/performance_goals';

class MetricViewModel {
  @observable data;

  @observable editingValue;
  @observable isEditing = false;

  constructor(data) {
    this.data = new Metric(data);
    this.editingValue = this.data.currentValue;
  }
}

export default MetricViewModel;
