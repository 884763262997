import React from 'react';
import {t} from 'shared/core';
import {observer} from 'mobx-react';
import {Display, Layout} from 'components';
import {withState} from 'shared/core';
import BenefitsNotEnabledState from '../state/BenefitsNotEnabledState';
import InterestedInBenefitsModal from './InterestedInBenefitsModal';
import BenefitsInfo from './BenefitsInfo';

const BenefitsNotEnabled = observer(({uiState}) => {
  return (
    <div>
      <Display title={t('benefit_enrolment.Benefits')}/>
      <Layout>
        <BenefitsInfo uiState={uiState}/>
        <InterestedInBenefitsModal uiState={uiState}/>
      </Layout>
    </div>
  );
});

export default withState(BenefitsNotEnabled, BenefitsNotEnabledState);
