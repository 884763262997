import React from 'react';
import {observer} from 'mobx-react';
import {EmployeeRoleState} from '../state';
import {withState, t} from 'shared/core';
import {Panel} from 'components';
import JobTimelineEntry from './job_timeline_entry';
import DeleteEmploymentRecordModal from './DeleteEmploymentRecordModal';
import {withRouter} from 'react-router-dom';
import AddRecordButton from './AddRecordButton';
import EditConfirmationModal from './EditConfirmationModal';
import OnLeaveWithBenefitsModal from './OnLeaveWithBenefitsModal';

const JobTimeline = observer(({uiState}) => {
  return (
    <div>
      {uiState.sortedEntries.map((viewModel, index) => <JobTimelineEntry key={index} viewModel={viewModel} uiState={uiState}/>)}
      <EditConfirmationModal uiState={uiState}/>
    </div>
  );
});

const EmployeeRoleContainerWithState = observer(({uiState}) => {
  return (
    <Panel.Stack loose>
      <Panel>
        <Panel.Header title={t('employees.profile.role.Job Information')}>
          <AddRecordButton uiState={uiState}/>
        </Panel.Header>
        <JobTimeline uiState={uiState}/>
        <DeleteEmploymentRecordModal uiState={uiState}/>
        <OnLeaveWithBenefitsModal uiState={uiState}/>
      </Panel>
    </Panel.Stack>
  );
});

export default withRouter(withState(EmployeeRoleContainerWithState, EmployeeRoleState));
