import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import ReviewedIcon from 'containers/performance/img/reviewed-checkmark@2x.png';
import UnreviewedIcon from 'containers/performance/img/unreviewed-checkmark@2x.png';

const CheckInSummaryItem = ({checkin, onClick, children}) => {
  return (
    <a href='#' onClick={(e) => { e.preventDefault(); onClick(checkin); }}>
      <div className={`clearfix border-bottom p3 ${checkin.selected ? 'bg-sky border-right--dodger' : 'bg-white'}`}>
        <div className='col-12'>
          <div className='table-cell align-top'>
            <img src={checkin.completed ? ReviewedIcon : UnreviewedIcon} alt='review-icon' height='21px'/>
          </div>
          <div className='table-cell align-middle px2'>
            <span className={`medium ${checkin.selected ? 'dodger' : 'tuatara'}`}><FormattedMessage id='performance.Check-In'/> </span>
            <span className='waterloo'>{checkin.date}</span>
            {children}
          </div>
        </div>
      </div>
    </a>
  );
};

export default observer(CheckInSummaryItem);
