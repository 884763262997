import React from 'react';
import {observer} from 'mobx-react';
import {t, auth} from 'shared/core';
import {Panel, Button, ActionLink, QuickTip} from 'components';
import {redirect} from 'shared/tools';
import {FormattedMessage} from 'react-intl';
import RecoveryCodeModal from './RecoveryCodeModal';

const GenerateLink = observer(({uiState}) => {
  return (
    <ActionLink onClick={() => uiState.generateRecoveryCode()}>
      <FormattedMessage id='user_settings.authentications.generate a new code'/>
    </ActionLink>
  );
});

const DisconnectMFA = observer(({uiState}) => {
  return (
    <Panel>
      <Panel.Header
        title={t('user_settings.authentications.Multi-factor Authentication')}
        description={t('user_settings.authentications.Disconnect 2-step authentication to remove your mobile device as a form of authentication. If your company enabled multi-factor authentication, you will be forced to set one up.')}
      />
      <div className='mb3'>
        <Button trait='default' onClick={() => uiState.disconnectMFA()}>
          <FormattedMessage id='user_settings.authentications.Disable 2-step authentication'/>
        </Button>
      </div>
      <Panel.Separator/>

      <Panel.Header
        title={t('user_settings.authentications.Recovery Code')}
        description={
          <FormattedMessage
            id={'user_settings.authentications.RECOVERY_CODE_DESCRIPTION'}
            values={{generateLink: <GenerateLink uiState={uiState}/>}}
          />
        }
      />
      <RecoveryCodeModal uiState={uiState}/>
    </Panel>
  );
});

const SSOWarning = observer(() => {
  if (auth.user.authenticationProvider) {
    return (
      <QuickTip
        trait='caution'
        header={t('user_settings.authentications.SSO_WARNING')}
        paragraphs={[
          t('user_settings.authentications.SSO_DESC'),
        ]}
      />
    );
  }

  return null;
});

const SetupButton = observer(() => {
  if (auth.user.authenticationProvider) return null;

  return (
    <Button onClick={() => redirect('/user_session/mfa_setup')}>
      <FormattedMessage id='user_settings.authentications.Enable 2-step authentication'/>
    </Button>
  );
});

const ConnectMFA = observer(({uiState}) => {
  return (
    <Panel>
      <Panel.Header
        title={t('user_settings.authentications.Multi-factor Authentication')}
        description={t('user_settings.authentications.Setting up 2-step authentication for your account will force you to provide a secondary form of authentication using a mobile device.')}
      />
      <SSOWarning/>
      <SetupButton/>
    </Panel>
  );
});

const MultiFactorAuthenticationContainer = observer(({uiState}) => {
  if (auth.user.mfaEnabled) {
    return <DisconnectMFA uiState={uiState}/>;
  } else {
    return <ConnectMFA uiState={uiState}/>;
  }
});

export default MultiFactorAuthenticationContainer;
