import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';
import {t} from 'shared/core';

const RemoveEmployeeModal = observer(({uiState}) => {
  const {editingEmployee} = uiState;

  if (!editingEmployee) return null;

  return (
    <AlertModal
      size='sm'
      mode='danger'
      header={t('payroll.employees.Remove Employee from Payroll?')}
      subHeader={t('payroll.employees.REMOVE_EMPLOYEE_CONFIRMATION', {employeeName: editingEmployee.name})}
      isOpen={uiState.removeEmployeeModalOpen}
      onHide={() => uiState.closeRemoveEmployeeModal()}
      translate={false}
    >
      <ModalDangerButtons 
        onSave={() => uiState.toggleEmployeePayroll(false)}
        onCancel={() => uiState.closeRemoveEmployeeModal()}
        saveCaption={t('payroll.employees.Remove')}
      />
    </AlertModal>
  );
});

export default RemoveEmployeeModal;
