import React from 'react';
import {observer} from 'mobx-react';
import {Input} from 'components';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const PayRate = observer(({viewModel}) => {
  const {uiState} = viewModel;

  if (uiState.massUpdate.readOnly && !viewModel.fields.get('payRate').has('newValue')) {
    return (
      <div className='waterloo'>
        <FormattedMessage id='mass_updates.edit.Not Changed'/>
      </div>
    );
  }

  return (
    <div>
      <Input
        value={viewModel.get('payRate', evaluator).get('value')}
        onChange={e => viewModel.set('payRate', e.target.value)}
        disabled={viewModel.disabled}
      />
      <div className='jumbo mt1'>
        <FormattedMessage
          id={viewModel.disabled ? 'mass_updates.edit.PREVIOUS_PAY_RATE' : 'mass_updates.edit.CURRENT_PAY_RATE'}
          values={{
            payRate: viewModel.get('payRate', evaluator).get('oldValue'),
            currency: viewModel.get('payRate', evaluator).get('payRateCurrency'),
            unit: viewModel.get('payRate', evaluator).get('payRateUnit')
          }}
        />
      </div>
    </div>
  );
});

function evaluator(bulkValue, oldValue) {
  const newValue = parseFloat(oldValue) * (1 + bulkValue / 100.0);

  return newValue.toFixed(2);
}

export default PayRate;
