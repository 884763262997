import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import EmailTemplate from 'stores/email_templates/EmailTemplate';

class EmailTemplateStore extends DomainStore {
  async loadEmailTemplates() {
    await this._compose([
      endpoints.EMAIL_TEMPLATES
    ]);

    return this._getAll(types.EMAIL_TEMPLATE, EmailTemplate);
  }

  async postEmailTemplate(emailTemplate) {
    return this.post(
      endpoints.EMAIL_TEMPLATES,
      types.EMAIL_TEMPLATE,
      emailTemplate 
    );
  }

  async loadEmailTemplate(id) {
    await this._compose([
      endpoints.EMAIL_TEMPLATE.with(id)
    ]);

    return new EmailTemplate(this._getSingle(types.EMAIL_TEMPLATE, { id }));
  }
}

const singleton = new EmailTemplateStore();

export default singleton;
