import {DomainObject} from 'shared/store';
import {observable} from 'mobx';
import {t} from 'shared/core';

class RecoveryCode extends DomainObject {
  @observable code;

  downloadCode() {
    const file = new Blob([this.code], {type: 'text/plain'});
    const filename = t('user_settings.authentications.RECOVERY_FILE_NAME');

    if (window.navigator.msSaveOrOpenBlob) { // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    } else { // Others
      const a = document.createElement('a');
      const url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();

      setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }
}

export default RecoveryCode;
