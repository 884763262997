import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const CancelSubscriptionModal = observer(({uiState}) => {
  return (
    <Modal
      size='sm'
      isOpen={uiState.cancelSubscriptionModalOpen}
      onHide={() => uiState.closeCancelSubscriptionModal()}
      title='company_settings.billing.cancel_subscription.Cancel Subscription'
    >
      <div>
        <FormattedMessage id='company_settings.billing.cancel_subscription.cancel_subscription_modal.Clicking confirm will notify our team who will be in touch with you shortly.'/>
      </div>
      <ModalButtons
        onSave={() => uiState.requestSubscriptionCancellation()}
        saveCaption='company_settings.billing.cancel_subscription.cancel_subscription_modal.Confirm'
        trait='destructive'
        onCancel={() => uiState.closeCancelSubscriptionModal()}
      />
    </Modal>
  );
});

export default CancelSubscriptionModal;
