import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class EmployeePlatformUsage extends DomainObject {
  @observable reviewsCount;
  @observable trainingsCount;
  @observable timeOffRequestsCount;
  @observable hiringCollaboratorsCount;
  @observable directReportsCount;
  @observable enrolledInTimeOff;
  @observable enrolledInBenefits;
  @observable enrolledInPayroll;
}

export default EmployeePlatformUsage;
