import _ from 'lodash';
import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import Position from './Position';
import Candidate from 'stores/recruiting/Candidate';
import EmailTemplate from 'stores/email_templates/EmailTemplate';
import {InterviewGuide} from 'stores/interview_guides';
import JobSite from './JobSite';
import {User} from 'stores/users';

class RecruitingStore extends DomainStore {
  async loadAll() {
    await this._compose([
      endpoints.RECRUITING.POSITIONS.COMPLETED
    ]);
  }

  async loadSingle(id) {
    await this._compose([
      endpoints.USERS.ACTIVE,
      endpoints.EMPLOYEES.ALL,
      endpoints.DEPARTMENTS,
      endpoints.LOCATIONS.ALL,
      endpoints.RECRUITING.POSITION.with(id)
    ]);

    return new Position(this._getSingle(types.RECRUITING.POSITION, {id}));
  }

  getAll() {
    const positions = this._getAll(types.RECRUITING.POSITION).map(p => new Position(p));
    return _.orderBy(positions, ['priority', 'createdAt'], ['desc', 'asc']);
  }

  async loadPublicPosition(slug, id) {
    await this._compose([
      endpoints.RECRUITING.PUBLIC_POSITION.with(slug, id)
    ]);

    return new Position(this._getSingle(types.RECRUITING.POSITION, {id}));
  }

  async loadEmailPreview() {
    await this._compose([
      endpoints.COMPANY_EMAIL_TEMPLATES
    ]);

    return this._getSingle(types.COMPANY_EMAIL_TEMPLATE, {emailType: 'ats_application'});
  }

  async postPosition() {
    const {model} = await this.post(endpoints.RECRUITING.POSITIONS.DIRECTORY, types.RECRUITING.POSITION);
    return model.id;
  }

  async destroyPosition(position) {
    await this.destroy(types.RECRUITING.POSITION, position);
  }

  async patchPosition(partialPosition, id) {
    return await this.patch(
      endpoints.RECRUITING.POSITION.with(id),
      types.RECRUITING.POSITION,
      partialPosition
    );
  }

  async postCandidate(candidate, positionId) {
    return this.post(
      endpoints.RECRUITING.CANDIDATES.with(positionId),
      types.RECRUITING.CANDIDATE,
      candidate
    );
  }

  async postApplication(candidate, slug, positionId) {
    return this.post(
      endpoints.RECRUITING.JOB_APPLICATION.with(slug, positionId),
      types.RECRUITING.CANDIDATE,
      candidate
    );
  }

  async patchCandidate(candidate, positionId, candidateId) {
    return this.patch(
      endpoints.RECRUITING.CANDIDATE.with(positionId, candidateId),
      types.RECRUITING.CANDIDATE,
      candidate
    );
  }

  async destroyCandidate(candidate) {
    await this.destroy(types.RECRUITING.CANDIDATE, candidate);
  }

  async loadFullCandidate(positionId, candidateId) {
    await this._compose([
      endpoints.RECRUITING.CANDIDATE.with(positionId, candidateId)
    ]);
    return new Candidate(this._getSingle(types.RECRUITING.CANDIDATE, {id: candidateId}));
  }

  async loadJobSite() {
    await this._compose([
      endpoints.RECRUITING.JOB_SITE.SETTINGS
    ]);
    return new JobSite(this._getSingle(types.RECRUITING.JOB_SITE.SETTING));
  }

  async postEntry(positionId, candidateId, entry) {
    if (entry.isFeedback) {
      return this.post(
        endpoints.RECRUITING.FEEDBACK.NEW.with(positionId, candidateId),
        types.RECRUITING.TIMELINE.FEEDBACK,
        entry
      );
    } else if (entry.isEmail) {
      return this.post(
        endpoints.RECRUITING.EMAIL.NEW.with(positionId, candidateId),
        types.RECRUITING.TIMELINE.EMAIL,
        entry
      );
    } else if (entry.isUpload) {
      return this.post(
        endpoints.RECRUITING.UPLOAD.NEW.with(positionId, candidateId),
        types.RECRUITING.TIMELINE.UPLOAD,
        entry
      );
    } else if (entry.isInterviewGuideResponse) {
      return this.post(
        endpoints.RECRUITING.INTERVIEW_GUIDE_RESPONSE.NEW.with(positionId, candidateId),
        types.RECRUITING.TIMELINE.INTERVIEW_GUIDE_RESPONSE,
        entry
      );
    } else {
      throw new Error(`unknown entry type ${entry.type}`);
    }
  }

  async loadTaggableUsers(positionId) {
    const composed = await this._compose([
      endpoints.RECRUITING.POSITION.LIST_TAGGABLE_USERS.with(positionId)
    ]);

    return composed[0].data.map(user => new User(user));
  }

  async loadEmailTemplates() {
    await this._compose([
      endpoints.EMAIL_TEMPLATES
    ]);

    return this._getAll(types.EMAIL_TEMPLATE, EmailTemplate);
  }

  async loadInterviewGuides() {
    await this._compose([
      endpoints.INTERVIEW_GUIDES
    ]);

    return this._getAll(types.INTERVIEW_GUIDE, InterviewGuide);
  }
}

const singleton = new RecruitingStore();

export default singleton;
