import React from 'react';
import {NavLink} from 'react-router-dom';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';
import {Display, Layout} from 'components';
import ClientAvatar from './ClientAvatar';

const ContentWithAvatar = observer(({parentState, children}) => {
  return (
    <Layout>
      <Layout.Section secondary>
        <ClientAvatar uiState={parentState}/>
      </Layout.Section>
      <Layout.Section>{children}</Layout.Section>
    </Layout>
  );
});

const LayoutContent = observer(({withAvatar, parentState, children}) => {
  if (withAvatar) {
    return <ContentWithAvatar parentState={parentState} children={children}/>;
  } else {
    return children;
  }
});
const ClientProfileContainer = observer(({match, history, parentState, withAvatar = false, children}) => {
  const {company} = parentState;

  return (
    <div>
      <Display
        title={company.name}
        backTitle={t('broker_portal.clients.profile.All Clients')}
        backPath={'/clients/directory'}
      >
        <NavLink to={`${parentState.match.url}/overview`} activeClassName='active'>
          <FormattedMessage id='broker_portal.clients.profile.Overview'/>
        </NavLink>
        <NavLink to={`${parentState.match.url}/enrolments`} activeClassName='active'>
          <FormattedMessage id='broker_portal.clients.profile.Enrolments'/>
        </NavLink>
        <NavLink to={`${parentState.match.url}/plans`} activeClassName='active'>
          <FormattedMessage id='broker_portal.clients.profile.Plans'/>
        </NavLink>
        <NavLink to={`${parentState.match.url}/reports`} activeClassName='active'>
          <FormattedMessage id='broker_portal.clients.profile.Reports'/>
        </NavLink>
      </Display>
      <LayoutContent withAvatar={withAvatar} parentState={parentState} children={children}/>
    </div>
  );
});

export default ClientProfileContainer;
