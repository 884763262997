import {observable, action, computed} from 'mobx';
import Employee from './Employee';
import TimeOffPolicy from 'stores/time_off/TimeOffPolicy';
import _ from 'lodash';
import {TaskTemplate} from 'stores/tasks';
import {CompanyDocument} from 'stores/documents';
import EmployeeOnboard from './EmployeeOnboard';

class EmployeeDetails extends Employee {
  @observable timeOffPolicy;
  @observable onboardingTasks;
  @observable onboardingDocuments;
  @observable finishConfig = false;

  @action merge(other) {
    super.merge(other, {
      timeOffPolicy: TimeOffPolicy,
      onboardingTasks: [TaskTemplate],
      onboardingDocuments: [CompanyDocument],
      onboard: EmployeeOnboard
    });
  }

  @action updateCountry(countryCode) {
    if (this.countryCode !== countryCode) {
      this.regionCode = '';
    }

    this.countryCode = countryCode;
  }

  @computed get genderView() {
    if (this.gender === 'other' && this.customGender) {
      return this.customGender;
    }
    if (this.gender) {
      return `models.employee.gender.${this.gender}`;
    }
  }

  @computed get sortedCustomFields() {
    return _.sortBy(this.employeeCustomFields, ['order']);
  }
}

export default EmployeeDetails;
