import {observable, action, computed} from 'mobx';
import {successAlert} from 'shared/tools';
import {DomainStore} from 'shared/store';
import {endpoints, t} from 'shared/core';
import {onboarding} from 'components/tasks';
import {TaskTemplateForm} from 'components/tasks/store';
import {TaskTemplate} from 'stores/tasks';
import _ from 'lodash';

class OnboardingContainerState {
  store = new DomainStore();
  config = onboarding

  @observable modalOpen = false;
  @observable addTaskTemplateModalOpen = false;
  @observable editTaskTemplateModalOpen = false;

  @observable employee;
  @observable allEmployees = [];
  @observable task;
  @observable taskTemplate;
  @observable selectedTaskTemplates = [];
  @observable taskTemplateForm;

  @observable errors = {};

  receiveProps({parentState}) {
    this.employee = parentState.employee;
  }

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
    ]);
    this.allEmployees = this.store.getEmployees();
  }

  @action openTaskModal(task) {
    this.task = task;
    this.modalOpen = true;
  }

  @action closeTaskModal() {
    this.modalOpen = false;
  }

  @action openAddTaskTemplateModal() {
    this.addTaskTemplateModalOpen = true;
  }

  @action closeAddTaskTemplateModal() {
    this.addTaskTemplateModalOpen = false;
    this.selectedTaskTemplates = [];
  }

  @action async saveSelectedTaskTemplates() {
    await this.store.post(
      endpoints.TASKS.CREATE_FROM_TEMPLATES,
      null,
      {employee: this.employee, onboardingTaskTemplates: this.selectedTaskTemplates}
    );

    await this.agent.refresh();
    this.closeAddTaskTemplateModal();
  }

  @action async uncompleteTask() {
    const {model} = await this.store.patch(
      this.task.link('uncomplete'),
      this.task._type,
      this.task
    );

    if (model) {
      await this.agent.refresh();
      if (model.completed) {
        throw new Error('Failed to uncomplete the task.');
      } else {
        successAlert(t('employees.profile.onboarding_tab.UNCOMPLETED_TASK', {taskName: model.name}));
      }
      this.closeTaskModal();
    }
  }

  @action async completeTask() {
    const {model} = await this.store.patch(
      this.task.link('complete'),
      this.task._type,
      this.task
    );

    if (model) {
      await this.agent.refresh();
      if (model.completed) {
        successAlert(t('employees.profile.onboarding_tab.COMPLETED_TASK', {taskName: model.name}));
      } else {
        throw new Error('Failed to complete the task.');
      }
      this.closeTaskModal();
    }
  }

  @action async deleteTask() {
    await this.store.destroy(this.task);
    await this.agent.refresh();

    successAlert(t('employees.profile.onboarding_tab.TASK_DELETED', {taskName: this.task.name}));
    this.closeTaskModal();
  }

  setInteractiveAgent(agent) {
    this.agent = agent;
  }

  @action openEditTaskTemplateModal(taskTemplate) {
    this.taskTemplate = new TaskTemplate(taskTemplate);
    this.openEditModal();
  }

  @action openCreateTaskTemplateModal() {
    this.taskTemplate = new TaskTemplate({templateType: this.config.templateType});
    this.openEditModal();
  }

  @action openEditModal() {
    this.taskTemplateForm = new TaskTemplateForm(this.taskTemplate, this.allEmployees, this.hasManager);
    this.editTaskTemplateModalOpen = true;
  }

  @action closeEditTaskTemplateModal() {
    this.editTaskTemplateModalOpen = false;
    this.errors = {};
  }

  @action saveTaskTemplate() {
    if (!this.validateTaskTemplate()) return null;

    this.selectedTaskTemplates.push(this.taskTemplate);
    this.saveSelectedTaskTemplates();

    this.closeEditTaskTemplateModal();
  }

  @action validateTaskTemplate() {
    this.errors = this.taskTemplateForm.validate();
    return _.isEmpty(this.errors);
  }

  @action toggleTaskTemplateSelected(taskTemplate) {
    if (this.taskTemplateSelected(taskTemplate)) {
      _.remove(this.selectedTaskTemplates, { clientId: taskTemplate.clientId });
    } else {
      this.selectedTaskTemplates.push(taskTemplate);
    }
  }

  taskTemplateSelected(taskTemplate) {
    return _.some(this.selectedTaskTemplates, { clientId: taskTemplate.clientId });
  }

  @computed get hasManager() {
    return !!this.employee.managerId;
  }
}

export default OnboardingContainerState;
