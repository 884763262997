import React from 'react';
import {observer} from 'mobx-react';
import {AvatarList, Panel} from 'components';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';

const MultipleChoiceResult = observer(({question, uiState}) => {
  const {Highcharts, HighchartsReact, reviews} = uiState;
  if (_.isEmpty(reviews)) {
    return (
      <div className='Table'>
        <Panel.Header title={`${question.order + 1}. ${question.name}`}/>
        <div className='Table-emptyState py2 px1 center rounded jumbo'>
          <FormattedMessage id='There are no results to show.'/>
        </div>
      </div>
    );
  }

  const hash = uiState.questionChartGrouping(question);
  return (
    <div>
      <Panel.Header title={`${question.order + 1}. ${question.name}`}/>
      <div className='clearfix mxn2'>
        <div className='col col-12 md-col-8 px2'>
          <HighchartsReact
            highcharts={Highcharts}
            options={uiState.chartOptionsForQuestion(question)}
          />
        </div>
        <div className='col col-12 md-col-4 px2 mt3'>
          <div className='col-12 sm-col-10 md-col-12'>
            {Object.keys(hash).map(key => {
              const option = hash[key];
                return (
                  <div key={key} onClick={() => uiState.openReviewersModal(option)} className='mb2 p2 rounded border HoverAnimation-trigger--shadow'>
                    <div className='mb2'>
                      <span className='inline-block circle mr1' style={{height: '10px', width: '10px', backgroundColor: option.color}}></span>
                      <span className='pr1'>{key}</span>
                      <span className='waterloo'>{`(${option.percentage}%)`}</span>
                    </div>
                    {!_.isEmpty(option.reviewers) && <div>
                      <AvatarList type='separate' limit={4} employees={option.reviewers}/>
                    </div>}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
});

export default MultipleChoiceResult;
