import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {CompanyDetailsState} from '../state';
import {Panel} from 'components';
import CompanyLogoSection from './CompanyLogoSection';
import StaffCount from './StaffCount';
import _ from 'lodash';

const CompanyDetails = observer(({uiState}) => {
  const {company, staffCount, distinctJobTypes} = uiState;

  return (
    <Panel>
      {uiState.showCompanyLogo && <CompanyLogoSection uiState={uiState}/>}
      {!company.logoUrl && <div className='StaffCount-Header'>
        {company.name}
      </div>}
      <StaffCount staffCount={staffCount} distinctJobTypes={distinctJobTypes}/>
    </Panel>
  );
});

export default withState(CompanyDetails, CompanyDetailsState);
