import React from 'react';
import {observer} from 'mobx-react';
import {Display, Button} from 'components';
import {t} from 'shared/core';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

const AddUserButton = observer(({uiState}) => {
  return (
    <Button onClick={() => uiState.addUser()}>
      <FormattedMessage id='company_settings.users.Invite External User'/>
    </Button>
  );
});

const UserSettingsSubnav = observer(({uiState}) => {
  return (
    <Display
      title={t('company_settings.users.Users')}
      backTitle={t('company_settings.Settings')}
      toolbar={<AddUserButton uiState={uiState}/>}
    >
      <NavLink to='/users/users' activeClassName='active'><FormattedMessage id='company_settings.users.Users'/></NavLink>
      <NavLink to='/users/pending' activeClassName='active'><FormattedMessage id='company_settings.external_users.Pending Invitations'/></NavLink>
    </Display>
  );
});

export default UserSettingsSubnav;
