import React from 'react';
import {observer} from 'mobx-react';
import {withState, endpoints, types, t} from 'shared/core';
import {Layout, Panel, ObjectSelect2, InteractiveContext, Pagination} from 'components';
import {PayrollHoursContainerState, PayrollHoursSummaryViewModel} from '../state';
import {ReportDownloadButton} from '../../download';
import useModal from 'shared/hooks/useModal';
import useIsMobile from 'shared/hooks/useIsMobile';
import ErrorMessageModal from './ErrorMessageModal';
import _ from 'lodash';
import PayrollHoursTable from './PayrollHoursTable';

const PayrollHoursContainerWithState = observer(({uiState}) => {
  const {paySchedules, selectedPaySchedule, selectedPayPeriod} = uiState;
  const errorMessageModalOpen = useModal(uiState, 'ErrorMessageModal', 'selectedPayrollHoursSummary');
  const isMobile = useIsMobile();

  if (_.isEmpty(paySchedules)) return null;

  return (
    <Layout>
      <Panel>
        <Panel.Header title={t('time_tracking.payroll_hours.Payroll Hours')}/>
        <div className='flex flex-wrap mb2 justify-content-between align-items-end'>
          <div className='flex'>
            <div className='mr1'>
              <ObjectSelect2
                label='time_tracking.payroll_hours.Pay Schedule'
                value={selectedPaySchedule}
                onChange={value => uiState.selectPaySchedule(value)}
                items={paySchedules}
              />
            </div>
            <ObjectSelect2
              label='time_tracking.payroll_hours.Pay Period'
              value={selectedPayPeriod}
              onChange={value => uiState.selectPayPeriod(value)}
              items={selectedPaySchedule.sortedPayPeriods}
              display='effectiveDateView'
            />
          </div>
          <ReportDownloadButton
            reportType='payroll_hours'
            name={t('time_tracking.payroll_hours.Payroll Hours')}
            params={{
              payScheduleId: selectedPaySchedule.id, payPeriodId: selectedPayPeriod.id
            }}
          />
        </div>
        <InteractiveContext
          uiState={uiState}
          onMount={agent => uiState.setInteractiveAgent(agent)}
          proxy={{
            type: 'async',
            pageSize: 10,
            modelType: types.TIME_TRACKING.PAYROLL_HOURS_SUMMARY,
            model: PayrollHoursSummaryViewModel,
            endpoint: endpoints.TIME_TRACKING.PAYROLL_HOURS.with(selectedPaySchedule.id, selectedPayPeriod.id)
          }}
        >
          <PayrollHoursTable horizontalScroll={isMobile}/>
          <Pagination/>
        </InteractiveContext>
      </Panel>
      <ErrorMessageModal modalOpen={errorMessageModalOpen} uiState={uiState}/>
    </Layout>
  );
});

export default withState(PayrollHoursContainerWithState, PayrollHoursContainerState);
