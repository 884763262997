import React from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import DefaultLogo from 'img/default-company-logo@2x.png';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const PlaceholderLogo = () => {
  return (
    <div className='placeholder flex align-items-center px2'>
      <img alt='company logo' className='PlaceholderCompanyLogo-img rounded mr2' src={DefaultLogo}/>
      <span className='iron'>
        <FormattedMessage id='components.editable_company_logo.Add Logo'/>
      </span>
    </div>
  );
};

const CompanyLogo = observer(({company, className, onClick}) => {
  return (
    <div onClick={onClick}>
      <div className={`CompanyLogo CompanyLogo--editable clickable ${className}`}>
        {company.logoUrl ?
          <img alt='company logo' className='align-top CompanyLogo-img' src={company.logoUrl}/> :
          <PlaceholderLogo/>  
        }
        <div className='CompanyLogo-edit p2'>
          <i className='material-icons white'>{'mode_edit'}</i>
        </div>
      </div>
    </div>
  );
});

CompanyLogo.propTypes = {
  company: PropTypes.shape({
    className: PropTypes.string
  }),
};

export default CompanyLogo;
