import React from 'react';
import {observer} from 'mobx-react';
import {EmployeeCount, PanelTooltip, Table} from 'components';
import DefaultRoleEditLink from './DefaultRoleEditLink';
import {FormattedMessage} from 'react-intl';
import TOOLTIPS from 'company_settings/permissions/components/tooltips';

const AssignedTo = observer(({role}) => {
  switch (role.roleType) {
    case 'admin':
      return <EmployeeCount count={role.employeeCount}/>;
    case 'manager':
      return <FormattedMessage id='company_settings.permissions.All Managers'/>;
    case 'employee':
      return <FormattedMessage id='company_settings.permissions.All Employees'/>;
    default:
      throw new Error('This only applies to default roles.');
  }
});

const DefaultRoleName = observer(({role}) => {
  const tooltip = TOOLTIPS[role.roleType];
  if (!tooltip) return null;

  return <span><FormattedMessage id={`company_settings.permissions.default_roles.${role.name}`}/><PanelTooltip text={tooltip.text}/></span>;
});

const DEFAULT_ROLE_LIST_COLUMNS = [
  {
    header: 'company_settings.permissions.Name',
    render: model => <DefaultRoleName role={model}/>,
    width: 5
  },
  {
    header: 'company_settings.permissions.Assigned To',
    render: model => <AssignedTo role={model}/>,
    width: 4
  },
  {
    render: model => <DefaultRoleEditLink role={model}/>,
    className: 'right-align',
    width: 3
  }
];

const DefaultRolesList = observer(({uiState}) => {
  return (
    <Table models={uiState.defaultRoles}
           columns={DEFAULT_ROLE_LIST_COLUMNS}
           showLinks={false}/>
  );
});

export default DefaultRolesList;
