import React from 'react';
import {observer} from 'mobx-react';
import TextFieldResult from './TextFieldResult';
import MultipleChoiceResult from './MultipleChoiceResult';

const QuestionSummaryFactory = observer(({question, uiState}) => {
  switch (question.questionType) {
    case 'text_field':
      return <TextFieldResult {...{question, uiState}} />;
    case 'multiple_choice':
      return <MultipleChoiceResult {...{question, uiState}} />;
    default:
      throw new Error(`Unsupported question type: ${question.questionType}`);
  }
});

export default QuestionSummaryFactory;
