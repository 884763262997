import React from 'react';
import LogoSpinner from 'components/spinners/logo/LogoSpinner';

const AccountSetupPage = () => {
  return (
    <div>
      <div className='relative my2 mx-auto' style={{width: '250px'}}>
        {<div style={{height: '255px'}}></div>}
        <LogoSpinner height='120' width='54' animate={true}/>
      </div>
    </div>
  );
};

export default AccountSetupPage;
