import React from 'react';
import {observer} from 'mobx-react';

const PanelStack = observer(({children, loose, className}) => {
  return (
    <div className={`${loose ? 'LoosePanelStack' : 'PanelStack'} ${className}`}>
      {children}
    </div>
  );
});

PanelStack.defaultProps = {
  className: '',
  loose: false
};

// PanelStack is a wrapper component to stack a list of panels
// props:
// - children: must be an array of Panel components
// - className: extra class name styles for the wrapper

export default PanelStack;
