import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Department',
  'Employee',
  'Employment Type',
  'Gender',
  'Location',
  'Manager',
  'Pay Rate',
  'Start Date'
];

const OnLeaveEmployeesReport = () => {
  return (
    <CompositeReport filters={filters}>
      <Report name={t('reports.ON_LEAVE_EMPLOYEES_REPORT')}
              url='/reports/on_leave_employees'>
      </Report>
    </CompositeReport>
  );
};

export default OnLeaveEmployeesReport;
