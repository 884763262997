import {observable, action} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints} from 'shared/core';
import {AttachedDocument} from 'stores/documents';

class CompanyFormState {
  store = new DomainStore();

  @observable companyForms = [];
  
  @action async load() {
    await this.store._compose([
      endpoints.PA_DASHBOARD.FORMS
    ]);

    this.companyForms = this.store._getAll(types.ATTACHED_DOCUMENT, AttachedDocument);
  }
}

export default CompanyFormState;
