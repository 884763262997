import {observable, computed} from 'mobx';
import moment from 'moment';

class BirthdayPickerState {
  @observable minAge;
  @observable maxAge;
  @observable value;
  @observable errorMessage;
  @observable changedAfterErrors;

  receiveProps({minAge, maxAge, value, errorMessage}) {
    this.changedAfterErrors = this.value !== value && this.errorMessage;
    if (this.changedAfterErrors) {
      errorMessage = null;
    }

    this.minAge = minAge || 0;
    this.maxAge = maxAge || 100;
    this.value = value;
    this.errorMessage = errorMessage;
  }

  @computed get age() {
    const today = moment();
    const birth = moment(this.value);

    if (birth.isValid()) {
      return today.diff(birth, 'years');
    } else {
      return '- -';
    }
  }

  @computed get showAge() {
    return !this.errorMessage || this.changedAfterErrors;
  }

  @computed get validAge() {
    if (!this.value) return true;
    return this.age >= this.minAge && this.age <= this.maxAge;
  }
}

export default BirthdayPickerState;
