const EMPLOYMENT_TYPES = [
  'full_time',
  'part_time',
  'temp',
  'intern',
  'volunteer',
  'contractor',
  'seasonal',
  'casual'
];

export default EMPLOYMENT_TYPES;
