import React from 'react';
import {DatePicker} from 'components';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {DEFAULT_START_DATE, DEFAULT_END_DATE} from 'reports/tools';

// this is a common filter component containing two datepickers and a button

class DateRange extends React.Component {
  state = {
    startDate: this.props.defaultStartDate || DEFAULT_START_DATE,
    endDate: this.props.defaultEndDate || DEFAULT_END_DATE,
    dateFilterFormat: this.props.dateFilterFormat || 'day',
  };

  componentDidMount() {
    this.props.onChange({...this.state});
  }

  dateChanged = (date, key) => {
    if (key === 'endDate' && date < this.state.startDate) {
      date = this.state.startDate;
    }

    this.setState(
      {[key]: date || DEFAULT_START_DATE},
      () => this.props.onChange({...this.state})
    );
  };

  componentWillReceiveProps({filters}) {
    const {startDate, endDate} = filters;

    if (startDate) {
      this.setState({startDate});
    }

    if (endDate) {
      this.setState({endDate});
    }
  }

  render() {
    const {startDate, endDate, dateFilterFormat} = this.state;

    return (
      <div className='clearfix table'>
        <div className='col-5 table-cell align-middle'>
          <DatePicker
            withIcon
            className='full-width'
            value={startDate}
            onChange={(date) => this.dateChanged(date, 'startDate')}
            options={ dateFilterFormat === 'month'
              ? {viewMode: 'months', minViewMode: 'months'}
              : {}
            }
            dateFormat={dateFilterFormat}
          />
        </div>
        <div className='col-2 table-cell align-middle center'>
          <FormattedMessage id='reports.to'/>
        </div>
        <div className='col-5 table-cell align-middle'>
          <DatePicker
            withIcon
            className='full-width'
            value={endDate}
            onChange={(date) => this.dateChanged(date, 'endDate')}
            options={ dateFilterFormat === 'month'
              ? {viewMode: 'months', minViewMode: 'months'}
              : {}
            }
            dateFormat={dateFilterFormat}
          />
        </div>
      </div>
    );
  }
}

export default observer(DateRange);
