import React from 'react';
import {Panel} from 'components';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import CheckmarkIcon from '../img/completed-checkmark@2x.png';

const StepTwoButton = observer(({uiState}) => {
  if (!uiState.stepOneComplete) return null;
  const currentLocation = window.location.pathname;

  if (uiState.stepTwoComplete) {
    return <img height='55' src={CheckmarkIcon} alt='check' />;
  }

  if (!uiState.stepTwoComplete) {
    return (
      <a href={`/company_settings/billing/subscription/?back=${currentLocation}`} className='Btn Btn--primary Btn--large mt3'>
        <span className='Btn--content' tabIndex='-1'>
          <FormattedMessage id='company_onboard.Add credit card'/>
        </span>
      </a>
    );
  }
});

const StepTwoDescription = observer(({uiState}) => {
  if (uiState.stepTwoComplete) {
    return (
      <div>
        <div className='h3 py1 medium'>
          <FormattedMessage id="company_onboard.You've added your credit card info."/>
        </div>
        <a className='TextLink dodger' href='/company_settings/billing'>
          <FormattedMessage id='company_onboard.Update card information' />
        </a>
      </div>
    );
  } 

  if (!uiState.stepTwoComplete) {
    return (
      <div>
        <div className={`h3 py1 medium ${uiState.hasEmployees ? '' : 'jumbo'}`}>
          <FormattedMessage id='company_onboard.Add a payment method'/>
        </div>
        <div className={`${uiState.hasEmployees ? 'jumbo' : 'waterloo'}`}>
          <FormattedMessage id="company_onboard.Add your company's credit card information to handle recurring billing statements." />
        </div>
      </div>
    );
  }
});

const StepTwo = observer(({uiState}) => {
  return (
    <div>
      <Panel className='my2' style={uiState.stepOneComplete ? {} : { boxShadow: 'none', backgroundColor: 'transparent', border: 'none' }}>
        <div className='clearfix table'>
          <div className='col-8 table-cell align-middle pr2'>
            <div className={`${uiState.hasEmployees ? 'jumbo' : 'waterloo'}`}>
              <FormattedMessage id='company_onboard.STEP_NUMBER' values={{number: 2}} />
            </div>
            <StepTwoDescription uiState={uiState} />
          </div>
          <div className='col-4 table-cell align-middle pl2 center'>
            <StepTwoButton uiState={uiState} />
          </div>
        </div>
      </Panel>
    </div>
  );
});

export default StepTwo;
