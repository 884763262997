import React from 'react';
import {observer} from 'mobx-react';
import {Button} from 'components';
import {FormattedMessage} from 'react-intl';
import {absoluteDateFormatter} from 'shared/tools';

const ShareButton = observer(({uiState, model}) => {
  const {cycle} = uiState;

  if (!cycle.managerType) {
    return null;
  }

  return (
    <Button
      trait='default'
      size='sm'
      onClick={() => uiState.canShareReviewee(model) ? uiState.openShareWithRevieweeModal(model) : uiState.goToWriteReview(model)}
    >
      <FormattedMessage id={uiState.canShareReviewee(model) ? 'performance_reviews.view.Share' : 'performance_reviews.view.Write'}/>
    </Button>
  );
});

const RevieweeActionButton = observer(({uiState, model}) => {
  const {cycle} = uiState;

  if (model.sharedAt){
    return <FormattedMessage
      id={`performance_reviews.view.${!!cycle.managerType ? 'SHARED_AT_MESSAGE' : 'CLOSED_AT_MESSAGE'}`}
      values={{sharedAt: absoluteDateFormatter(model.sharedAt)}}
    />;
  } else {
    return <ShareButton uiState={uiState} model={model}/>;
  }
});

export default RevieweeActionButton;
