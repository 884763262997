import React from 'react';
import {observer} from 'mobx-react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {EmployeeTimeOffContainer} from './components';
import {RequestTimeOffContainer} from './request';

const EmployeeTimeOffSwitch = withRouter(observer(({match, uiState}) => {
  const {employee} = uiState;

  return (
    <Switch>
      <Route exact path={`${match.url}`} render={() => <EmployeeTimeOffContainer uiState={uiState}/>}/>
      <Route path={`${match.url}/request/:id?`} render={({history, match}) => <RequestTimeOffContainer match={match} history={history} employee={employee}/>}/>
    </Switch>
  );
}));

export default EmployeeTimeOffSwitch;
