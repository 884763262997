import QAQuestion from './QAQuestion';
import {computed} from 'mobx';
import _ from 'lodash';

class QuizQuestion extends QAQuestion {
  @computed get quizCorrectOption() {
    return _.find(this.options, option => _.get(option, 'answer'));
  }
}

export default QuizQuestion;
