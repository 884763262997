import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {Input, FormLayout} from 'components';
import CountryAndRegionSelects from 'components/forms/country_and_region_selects/CountryAndRegionSelects';

const EditAddressInfoModal = observer(({uiState}) => {
  const {editEmployee, errors} = uiState;

  return (
    <Modal isOpen={uiState.addressInfoModalOpen} onHide={() => uiState.resetModals()} size='md'>
      <div className='h2 mb3'><FormattedMessage id='employees.profile.personal.Edit Address Information'/></div>
      <form>
        <FormLayout>
          <Input
            value={editEmployee.homeAddress1}
            label='employees.profile.personal.Address Line 1'
            errorMessage={errors.homeAddress1}
            onChange={(e) => editEmployee.merge({homeAddress1: e.target.value})}
          />
          <Input
            value={editEmployee.homeAddress2}
            label='employees.profile.personal.Address Line 2'
            errorMessage={errors.homeAddress2}
            onChange={(e) => editEmployee.merge({homeAddress2: e.target.value})}
          />
          <CountryAndRegionSelects
            initialCountryCode={editEmployee.countryCode}
            initialRegionCode={editEmployee.regionCode}
            onChange={newCountryAndRegionCodes => editEmployee.merge(newCountryAndRegionCodes)}
            errors={errors}
            wrapper={FormLayout}
          />
          <Input
            value={editEmployee.city}
            label='employees.profile.personal.City'
            errorMessage={errors.city}
            onChange={(e) => editEmployee.merge({city: e.target.value})}
          />
          <Input
            value={editEmployee.postalCode}
            label='employees.profile.personal.Postal Code'
            errorMessage={errors.postalCode}
            onChange={(e) => editEmployee.merge({postalCode: e.target.value})}
          />
        </FormLayout>
        <ModalButtons onSave={() => uiState.saveAddress()} onCancel={() => uiState.resetModals()}/>
      </form>
    </Modal>
  );
});

export default EditAddressInfoModal;
