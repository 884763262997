import {DomainObject} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {fullName} from 'shared/tools';

class AdminInvitation extends DomainObject {
  @observable id;
  @observable firstName;
  @observable lastName;
  @observable assignedRoles;
  @observable email;
  @observable expiresAt;

  @action merge(other) {
    super.merge(other, {
      _dates: ['expiresAt']
    });
  }

  @computed get name() {
    return fullName(this);
  }
}

export default AdminInvitation;
