import React from 'react';
import {observer} from 'mobx-react';
import {FormLayout, Panel, Select2, TextArea, DatePicker} from 'components';
import {t} from 'shared/core';
import {reasonsForChange} from 'stores/benefits';

const Reason = observer(({uiState}) => {
  const {benefitInfoChangeRequest, errors} = uiState;

  return (
    <div>
      <Panel.Header title={t('benefits.benefit_info.edit.reason.Reason for change')}/>
      <FormLayout>
        <Select2 value={benefitInfoChangeRequest.reasonForChange}
          errorMessage={errors.reasonForChange}
          label='benefits.benefit_info.edit.reason.Reason for changes to your benefits information'
          onChange={e => benefitInfoChangeRequest.merge({reasonForChange: e.target.value})}>
          {reasonsForChange.map(reason => <option key={reason} value={reason}>
            {t(`models.benefit_info_change_requests.reasons_for_change.${reason}`)}
          </option>)}
        </Select2>
        {benefitInfoChangeRequest.otherReason && (
          <TextArea
            value={benefitInfoChangeRequest.reasonText}
            onChange={e => benefitInfoChangeRequest.merge({reasonText: e.target.value})}
            label='benefits.benefit_info.edit.reason.If other, explain:'
            errorMessage={errors.reasonText}
          />
        )}
        <DatePicker className='full-width'
          label='benefits.benefit_info.edit.reason.Effective date of change'
          helpMessage={t('benefits.benefit_info.edit.reason.The date when changes take effect.')}
          onChange={date => benefitInfoChangeRequest.merge({effectiveDate: date})}
          value={benefitInfoChangeRequest.effectiveDate}
          errorMessage={errors.effectiveDate}
        />
      </FormLayout>
    </div>
  );
});

export default Reason;
