import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {auth, withState, t} from 'shared/core';
import SubNav from 'company_settings/SubNav';
import {Panel, Toggle, Layout, ActionLink} from 'components';
import VisibilitySettingsState from '../state/VisibilitySettingsState';
import EditVisibilitySettingModal from './EditVisibilitySettingModal';
import _ from 'lodash';

const PreferenceToggle = observer(({uiState, setting}) => {
  const formattedRules = uiState.formattedRulesForSegment(setting.segment);
  let message = `company_settings.visibility_settings.${setting.settingType}`;

  if (setting.settingType === 'people_off_today') {
    message = auth.company.slackEnabled ? `company_settings.visibility_settings.${setting.settingType}_slack` : message;
  }

  return (
    <div>
      <div className='mb2'>
        <div className='table-cell align-middle'>
          <Toggle
            enabled={setting.enabled}
            onChange={value => uiState.updateVisibilitySetting(setting, value)}
          />
        </div>
        <div className='table-cell align-middle pl2'>
          <FormattedMessage id={message}/>
        </div>
      </div>
      {setting.enabled && <div className='pl2 mb2'>
        <div className='flex align-items-center'>
          <div className='right-align medium mr2'>
            <FormattedMessage id='company_settings.visibility_settings.Hidden from:'/>
          </div>
          <div>
            <div className='flex align-items-center'>
              {_.isEmpty(formattedRules) ?
                <FormattedMessage id='company_settings.visibility_settings.None'/> :
                formattedRules.join(', ')
              }
              <ActionLink className='align-middle' onClick={() => uiState.openEditVisibilitySettingModal(setting)}>
                <i className='material-icons h3 submarine inline ml1'>{'edit'}</i>
              </ActionLink>
            </div>
          </div>
        </div>
      </div>}
      {setting.settingType === 'people_directory' && !setting.enabled && <div className='flex right-align align-items-center jumbo pl2 mb2 mr2' style={{height: 24}}>
        <FormattedMessage id='company_settings.visibility_settings.Note: The people directory is always visible to Super Admins'/>
      </div>}
    </div>
  );
});

const VisibilitySettingsContainer = observer(({uiState}) => {
  const {
    birthdayCelebration,
    startDateCelebration,
    anniversaryCelebration,
    staffCount,
    peopleOffToday,
    peopleDirectory
  } = uiState;

  return (
    <div>
      <SubNav title={t('company_settings.subnav.Visibility Preferences')} toolbar={
        <div className='inline-block'>
        </div>
      }/>
      <Layout>
        <Panel.Stack loose>
          <Panel>
            <Panel.Header title={t('company_settings.visibility_settings.People Directory Visibility Preferences')} />
            <PreferenceToggle uiState={uiState} setting={peopleDirectory}/>
          </Panel>
          <Panel>
            <Panel.Header title={t('company_settings.visibility_settings.Home Page Visibility Preferences')} />
            <PreferenceToggle uiState={uiState} setting={staffCount}/>
            <Panel.Separator/>
            <PreferenceToggle uiState={uiState} setting={peopleOffToday}/>
          </Panel>
          <Panel>
            <Panel.Header title={t('company_settings.visibility_settings.Feed Visibility Preferences')} />
            <PreferenceToggle uiState={uiState} setting={birthdayCelebration}/>
            <Panel.Separator/>
            <PreferenceToggle uiState={uiState} setting={startDateCelebration}/>
            <Panel.Separator/>
            <PreferenceToggle uiState={uiState} setting={anniversaryCelebration}/>
          </Panel>
        </Panel.Stack>
      </Layout>
      <EditVisibilitySettingModal uiState={uiState}/>
    </div>
  );
});

export default withState(VisibilitySettingsContainer, VisibilitySettingsState);
