import {action, computed, observable} from 'mobx';
import _ from 'lodash';
import {successAlert, errorAlert} from 'shared/tools';
import {endpoints, types, t, auth} from 'shared/core';
import uuid from 'uuid';
import Document from 'stores/documents/Document';
import CompanyDocument from 'stores/documents/CompanyDocument';
import Folder from 'stores/folders/Folder';
import CompanyDocumentAssignment from 'stores/documents/CompanyDocumentAssignment';
import { DomainStore, fetchModels } from 'shared/store';
import FolderViewModel from './FolderViewModel';
import DocumentViewModel from './DocumentViewModel';

class EmployeeDocumentState {
  NO_FOLDER = [new Folder({id: 0, name: t('employees.profile.documents.(No Folder)')})];

  store = new DomainStore();

  @observable documents = [];
  @observable folders = [];
  @observable editingDocument = {};
  @observable editDocumentModalOpen = false;
  @observable errors = {};
  @observable presigner;
  @observable employee;
  @observable isUpdating = false;
  @observable movingDocument = {};
  @observable documentAction = '';
  @observable companyDocumentAssignment;
  @observable moveDocumentModalOpen = false;
  @observable currentFolder = {};
  @observable allFolders = [];
  @observable sort = {};
  @observable companyDocuments;
  @observable assignDocumentModalOpen = false;
  @observable assigningDocument = {};
  @observable acceptedMimeTypes;
  @observable isUploading = false;
  @observable annotatorModalOpen = false;

  constructor(parentState) {
    this.parentState = parentState;
    this.presigner = endpoints.EMPLOYEE_DOCUMENTS.PRESIGNER.with(this.employeeId);
  }

  @action async load() {
    const docEndpoint = this.currentFolderEmpty ?
      endpoints.EMPLOYEE_DOCUMENTS.with(this.employeeId) :
      endpoints.EMPLOYEE_DOCUMENTS.withFilter(this.employeeId, this.currentFolder.id);

    const composed = await this.store._compose([
      endpoints.FOLDERS.FOR_EMPLOYEE.with(this.employeeId),
      docEndpoint
    ]);

    if (auth.hasAccess('::MANAGE_DOCUMENTS')) {
      this.companyDocuments = await fetchModels(
        endpoints.COMPANY_DOCUMENTS.all,
        types.COMPANY_DOCUMENT
      );
    }

    this.employee = this.store.getEmployee(this.employeeId);

    this.allFolders = composed[0].data.map(f => new Folder(f));
    this.folders = this.currentFolderEmpty ? this.allFolders : [];
    this.documents = composed[1].data.map(d => new Document(d));
  }

  @action openEditDocumentModal(viewModel) {
    this.editingDocument = new Document(viewModel.data);
    this.editDocumentModalOpen = true;
  }

  @action closeEditDocumentModal() {
    this.editingDocument = {};
    this.editDocumentModalOpen = false;
  }

  @action async createDocument(file, key, folder) {
    const doc = new Document({
      key: key,
      fileName: file.name,
      fileSize: file.size,
      fileType: 'Employee File',
      contentType: file.type || 'application/octet-stream',
      shareWithEmployee: false,
      folder: folder
    });

    const {model, errors} = await this.store.post(
      endpoints.EMPLOYEE_DOCUMENTS.with(this.employeeId),
      types.DOCUMENT,
      doc
    );

    if (model) {
      await this.load();
    } else {
      errorAlert(
        t('components.upload.FAILED_TO_UPLOAD', { file: file.name, message: errors.contentType })
      );
    }
  }

  @action async updateDocument() {
    const { model, errors } = await this.store.patch(this.editingDocument);
    this.errors = errors;

    if (model) {
      successAlert(t('employees.profile.documents.alerts.Successfully updated document'));
      await this.load();
      this.closeEditDocumentModal();
    }
  }

  @action async deleteDocument(viewModel) {
    await this.store.destroy(viewModel.data);
    await this.load();
  }

  @action openMoveDocumentModal(viewModel) {
    this.movingDocument = viewModel.data;
    this.moveDocumentModalOpen = true;
  }

  @action closeMoveDocumentModal() {
    this.moveDocumentModalOpen = false;
  }

  @action async moveDocumentToFolder() {
    const { model } = await this.store.patch(this.movingDocument);

    if (model) {
      successAlert(t('employees.profile.documents.alerts.Successfully moved document'));
      await this.load();
      this.closeMoveDocumentModal();
    }
  }

  @action updateSort(sort) {
    this.sort = sort;
  }

  @action openAssignDocumentModal() {
    this.assigningDocument = new CompanyDocument({
      id: uuid.v4(),
      locale: auth.locale,
      folderId: this.currentFolder.id,
    });
    this.documentAction = '';
    this.companyDocumentAssignment = new CompanyDocumentAssignment({
      employees: [this.parentState.employee],
    });
    this.assignDocumentModalOpen = true;
  }

  @action closeAssignDocumentModal() {
    this.assignDocumentModalOpen = false;
    setTimeout(() => {
      this.assigningDocument = {};
      this.errors = {};
    }, 500);
  }

  @action openAnnotatorModal(document) {
    this.assignDocumentModalOpen = false;
    if (document) {
      this.assignDocument = document;
    }
    this.annotatorModalOpen = true;
  }

  @action closeAnnotatorModal() {
    this.annotatorModalOpen = false;
    setTimeout(() => {
      this.assigningDocument = {};
      this.errors = {};
    }, 500);
  }

  @action selectDocumentType(type) {
    this.assigningDocument.merge({ documentType: type });
    this.acceptedMimeTypes = type === 'pdf_template' ? 'application/pdf' : '';
  }

  @action selectDocumentAction(action) {
    this.documentAction = action;
  }

  @action async saveUploadedFile() {
    if (!this.validateCompanyDocument()) return;
    if (this.uploadingPDFTemplate) {
      this.openAnnotatorModal();
    } else {
      await this.assignDocument();
    }
  }

  @action async assignExistingDocument() {
    const {model, errors} = await this.store.post(
      endpoints.DOCUMENT_ACTIONS.with(this.companyDocumentAssignment.companyDocument.id),
      types.COMPANY_DOCUMENT_ASSIGNMENT,
      this.companyDocumentAssignment
    );
    this.errors = errors;

    if (model) {
      this.closeAssignDocumentModal();
      successAlert(t('employees.profile.documents.alerts.Successfully assigned document'));
    }
  }

  @action async addAnnotations(annotations) {
    this.assigningDocument.annotations = annotations;
    if (!this.validateCompanyDocument()) return;
    await this.assignDocument();
  }

  @action async assignDocument() {
    const {model, errors} = await this.store.put(
      endpoints.ASSIGN_COMPANY_DOCUMENT.with(this.employeeId),
      types.COMPANY_DOCUMENT,
      new CompanyDocument(this.assigningDocument)
    );

    this.errors = errors;

    if (model) {
      this.closeAnnotatorModal();
      this.closeAssignDocumentModal();
      successAlert(t('employees.profile.documents.alerts.Successfully assigned document'));
    }
  }

  @action async goBack() {
    this.currentFolder = {};
    this.isUpdating = true;

    await this.load();
    this.isUpdating = false;
  }

  @computed get choosingDocumentAction() {
    return !this.uploadingNewDocument && !this.assigningExistingDocument;
  }

  @computed get choosingDocumentType() {
    return !this.uploadingPDFTemplate && !this.uploadingStaticDocument;
  }

  @computed get uploadingNewDocument() {
    return this.documentAction === 'new';
  }

  @computed get assigningExistingDocument() {
    return this.documentAction === 'existing';
  }

  @computed get uploadingPDFTemplate() {
    return this.assigningDocument.documentType === 'pdf_template';
  }

  @computed get uploadingStaticDocument() {
    return this.assigningDocument.documentType === 'static_document';
  }

  @computed get employeeId() {
    return this.parentState.match.params.id;
  }

  @computed get hasSharedStatusColumn() {
    if (_.isEmpty(this.documents)) return false;

    const doc = _.head(this.documents);
    return doc.hasLink('self') && auth.employee.id !== doc.employee.id;
  }

  @computed get currentFolderEmpty() {
    return _.isEmpty(this.currentFolder);
  }

  @computed get foldersInModalDropdown() {
    const displayedFolders = _.filter(this.allFolders, f => f.id !== this.currentFolder.id);

    return this.currentFolderEmpty ? displayedFolders : [...this.NO_FOLDER, ...displayedFolders];
  }

  @computed get sortedDocuments() {
    if (!this.sortBy) return this.documents;
    const sorted = _.orderBy(this.documents, doc => doc[this.sortBy[0]]);

    return this.sortBy[1] === 'asc' ? sorted : _.reverse(sorted);
  }

  @computed get sortedFolders() {
    return _.orderBy(this.folders, 'order');
  }

  @computed get entries() {
    const documents = this.sortedDocuments.map(d => new DocumentViewModel(d, this));
    const folders = this.sortedFolders.map(f => new FolderViewModel(f, this));

    return [...folders, ...documents];
  }

  @computed get sortBy() {
    return _.chain(this.sort)
      .toPairs()
      .filter(pair => !!pair[1])
      .first()
      .value();
  }

  validateCompanyDocument() {
    this.errors = {};

    if (!this.assigningDocument.name) {
      this.errors.name = t('employees.profile.documents.errors.Name is required.');
    }

    if (_.isEmpty(this.assigningDocument.originalFile)) {
      this.errors.awsFiles = t('employees.profile.documents.errors.A file must be uploaded.');
    }

    return _.isEmpty(this.errors);
  }
}

export default EmployeeDocumentState;
