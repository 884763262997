import React from 'react';
import {observer} from 'mobx-react';
import {Select2} from 'components';
import {FormattedMessage} from 'react-intl';
import {withDeleted} from 'shared/tools';
import _ from 'lodash';

const Location = observer(({viewModel}) => {
  const {uiState} = viewModel;

  if (uiState.massUpdate.readOnly && !viewModel.fields.get('locationId').has('newValue')) {
    return (
      <div className='waterloo'>
        <FormattedMessage id='mass_updates.edit.Not Changed'/>
      </div>
    );
  }

  const locations = withDeleted(uiState.locations, viewModel.data.employee.location);
  const previousLocation = viewModel.get('locationId').get('oldValue') ?
    _.find(locations, {id: viewModel.get('locationId').get('oldValue')}).name :
    '--';

  return (
    <div>
      <Select2
        searchable
        value={viewModel.get('locationId').get('value')}
        disabled={viewModel.disabled}
        onChange={e => viewModel.set('locationId', e.target.value)}
      >
        {locations.map(location => {
          return <option key={location.id} value={location.id}>{location.name}</option>;
        })}
      </Select2>
      <div className='jumbo mt1'>
        <FormattedMessage
          id={viewModel.disabled ? 'mass_updates.edit.PREVIOUS_VALUE' : 'mass_updates.edit.CURRENT_VALUE'}
          values={{
            value: previousLocation
          }}
        />
      </div>
    </div>
  );
});

export default Location;
