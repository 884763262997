import {observable, action} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import {PerformanceReviewCycle} from 'stores/performance_reviews';

class ReviewViewState {
  match;
  history;
  scope;
  store = new DomainStore();

  @observable cycle;

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    await this.store._compose([
      endpoints.PERFORMANCE_REVIEWS.CYCLE.with(this.match.params.cycleId),
      endpoints.PERFORMANCE_REVIEWS.CYCLE_NAVIGATION.with(this.match.params.cycleId)
    ]);

    this.cycle = new PerformanceReviewCycle(
      this.store._getSingle(types.PERFORMANCE_REVIEWS.CYCLE)
    );

    this.scope = this.store._getSingle(types.NAVIGATION_SCOPE).tabs;

    this.redirectIfNotLaunchedYet();
  }

  redirectIfNotLaunchedYet() {
    switch (this.cycle.state) {
      case 'created':
        this.history.push(`/reviews/${this.cycle.id}/edit`);
        break;
      case 'configured':
        this.history.push(`/reviews/${this.cycle.id}/manage`);
        break;
      case 'launched':
      case 'ended':
      default:
        break;
    }
  }

  get defaultPath() {
    if (this.scope.overview) return 'admin';
    if (this.scope.write || this.scope.team) return 'write';
    if (this.scope.results) return 'results';

    return '/';
  }
}

export default ReviewViewState;
