import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, FormLayout, Input} from 'components';

const Name = observer(({uiState}) => {
  const {policy, errors} = uiState;

  return (
    <div>
      <Panel.Header 
        title={t('time_off.policy.edit.Policy Name')} 
        description={t('time_off.policy.edit.Start by giving your policy a name which that easily identifies which employees should be enrolled.')}
      />
      <FormLayout>
        <Input
          value={policy.name}
          onChange={e => policy.merge({name: e.target.value})}
          errorMessage={errors.name}
        />
      </FormLayout>
    </div>
  );
});

export default Name;
