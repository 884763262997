import React from 'react';
import {observer} from 'mobx-react';
import {loader, withDisplay, t} from 'shared/core';
import RolesListState from 'company_settings/permissions/state/RolesListState';
import roleStore from 'stores/users/RoleStore';
import DefaultRolesList from './lists/DefaultRolesList';
import CustomRolesList from './lists/CustomRolesList';
import CompanyFeaturesContainer from 'company_settings/company_features/components/CompanyFeaturesContainer';
import SubNav from 'company_settings/SubNav';
import {Layout, Panel, HelperPanel} from 'components';

const LINKS = [
  {
    translations: {
      en: 'http://support.collage.co/employee-management/roles-and-permissions/roles-permissions-overview',
      fr: 'http://support.collage.co/fr/articles/836928-roles-et-permissions'
    },
    title: 'company_settings.subnav.Roles & Permissions'
  }
];

const RolesContainer = observer(({uiState}) => {
  return (
    <Layout>
      <Layout.Section>
        <Panel.Stack loose>
          <Panel.Stack>
            <Panel>
              <Panel.Header title={t('company_settings.permissions.Default Roles')}/>
              <DefaultRolesList uiState={uiState}/>
            </Panel>
            <Panel>
              <Panel.Header title={t('company_settings.permissions.Custom Roles')}/>
              <CustomRolesList uiState={uiState}/>
            </Panel>
          </Panel.Stack>
          <CompanyFeaturesContainer/>
        </Panel.Stack>
      </Layout.Section>
      <Layout.Section secondary>
        <HelperPanel
          title={t('company_settings.permissions.What is a role?')}
          descriptionItems={[
            t('company_settings.permissions.A role is a group of permissions that control employee access to actions and data. Employees can be assigned multiple roles.')
          ]}
          links={LINKS}
        />
      </Layout.Section>
    </Layout>
  );
});

async function load({history}) {
  const uiState = new RolesListState(roleStore, history);
  await uiState.load();
  return {uiState};
}

export default withDisplay(loader(RolesContainer, load), () => <SubNav title={t('company_settings.subnav.Roles & Permissions')}/>);
