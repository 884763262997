import {DomainObject} from 'shared/store';
import {observable, computed} from 'mobx';
import _ from 'lodash';

class ClientCompany extends DomainObject {
  @observable name;
  @observable status;
  @observable carriers;
  @observable enrolmentCount;
  @observable type;
  @observable companyColour;
  @observable allowsImpersonation;

  @computed get link() {
    if (this.type === 'company') {
      return `/clients/${this.typeId}`;
    } else {
      return `/clients/edit/${this.typeId}`;
    }
  }
}

export default ClientCompany;
