import {observable, action, observe, computed} from 'mobx';
import {TaskTemplate} from 'stores/tasks';
import TaskTemplateForm from '../store/TaskTemplateForm';
import {endpoints, types} from 'shared/core';
import {DomainStore, fetchModels} from 'shared/store';
import _ from 'lodash';

class TaskTemplateSelectorState {
  store = new DomainStore();
  config;
  hasManager;
  onChange;
  employeeId;
  localization;

  @observable taskTemplate;
  @observable taskTemplates = [];
  @observable selectedTaskTemplates = [];
  @observable defaultTaskTemplates = [];
  @observable allEmployees = [];

  @observable addTaskTemplateModalOpen = false;
  @observable editTaskTemplateModalOpen = false;
  @observable errors = {};

  receiveProps({config, v, onChange, employeeId, hasManager = true}) {
    this.config = config;
    this.onChange = onChange;
    this.employeeId = employeeId;
    this.hasManager = hasManager;
    this.localization = {
      addModel: `tasks.${this.config.selectTasksTitle}`,
      emptyState: `tasks.${this.config.emptyState}`
    };
  }

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.TASK_TEMPLATES.with(this.config.templateType, this.employeeId)
    ]);
    this.allEmployees = this.store.getEmployees();
    this.taskTemplates = this.config.fetchTasks ? this.store._getAll(types.TASK_TEMPLATE).map(t => new TaskTemplate(t)) : [];
    this.defaultTaskTemplates = (await fetchModels(
      endpoints.TASK_TEMPLATES.DEFAULT_FOR_EMPLOYEE.with(this.employeeId, this.config.templateType),
      types.TASK_TEMPLATE
    )).map(t => new TaskTemplate({...t, default: true}));

    observe(this, 'taskTemplatesWithDefaults', () => this.emitOnChange(), true);
  }

  @action openAddTaskTemplateModal() {
    this.addTaskTemplateModalOpen = true;
  }

  @action closeAddTaskTemplateModal() {
    this.addTaskTemplateModalOpen = false;
    this.selectedTaskTemplates = [];
  }

  @action saveSelectedTaskTemplates() {
    this.selectedTaskTemplates.forEach(taskTemplate => {
      this.taskTemplates.push(taskTemplate);
    });
    this.closeAddTaskTemplateModal();
  }

  @action openEditTaskTemplateModal(taskTemplate) {
    this.taskTemplate = new TaskTemplate(taskTemplate);
    this.openEditModal();
  }

  @action openCreateTaskTemplateModal() {
    this.taskTemplate = new TaskTemplate({templateType: this.config.templateType});
    this.openEditModal();
  }

  @action openEditModal() {
    this.taskTemplateForm = new TaskTemplateForm(this.taskTemplate, this.allEmployees, this.hasManager);
    this.editTaskTemplateModalOpen = true;
  }

  @action closeEditTaskTemplateModal() {
    this.editTaskTemplateModalOpen = false;
    this.errors = {};
  }

  @action saveTaskTemplate() {
    if (!this.validateTaskTemplate()) return null;

    const existingTaskTemplate = _.find(this.taskTemplatesWithDefaults,
      { clientId: this.taskTemplate.clientId }
    );

    if (existingTaskTemplate) {
      existingTaskTemplate.merge(this.taskTemplate);
      if (existingTaskTemplate.default) {
        existingTaskTemplate.default = false;
        _.remove(this.defaultTaskTemplates, { clientId: existingTaskTemplate.clientId });
        this.taskTemplates.unshift(existingTaskTemplate);
      }
    } else {
      this.taskTemplates.push(this.taskTemplate);
    }

    this.closeEditTaskTemplateModal();
  }

  @action validateTaskTemplate() {
    this.errors = this.taskTemplateForm.validate();
    return _.isEmpty(this.errors);
  }

  @action removeTaskTemplate(taskTemplate) {
    if (taskTemplate.default) {
      _.remove(this.defaultTaskTemplates, { clientId: taskTemplate.clientId });
    } else {
      _.remove(this.taskTemplates, { clientId: taskTemplate.clientId });
    }
  }

  @action toggleTaskTemplateSelected(taskTemplate) {
    if (this.taskTemplateSelected(taskTemplate)) {
      _.remove(this.selectedTaskTemplates, { clientId: taskTemplate.clientId });
    } else {
      this.selectedTaskTemplates.push(taskTemplate);
    }
  }

  taskTemplateSelected(taskTemplate) {
    return _.some(this.selectedTaskTemplates, { clientId: taskTemplate.clientId });
  }

  emitOnChange() {
    if (this.onChange) {
      this.onChange(this.taskTemplatesWithDefaults);
    }
  }

  @computed get taskTemplatesWithDefaults() {
    return this.defaultTaskTemplates.concat(this.taskTemplates);
  }
}

export default TaskTemplateSelectorState;
