import React from 'react';
import {observer} from 'mobx-react';
import {Tag, Panel, CompanyAvatar, Avatar, Button} from 'components';
import {FormattedMessage} from 'react-intl';

const ImpersonationButton = observer(({uiState}) => {
  const {company} = uiState;
  if (!company.allowsImpersonation) return null;

  return (
    <Button size='sm' trait='default' onClick={() => uiState.impersonateCompany(company)}>
      <FormattedMessage id='broker_dashboard.View'/>
    </Button>
  );
});

const EnrolmentOnlyTag = observer(({company}) => {
  return (
    <Tag colour={company.enrolmentOnly ? 'turquoise' : 'dodger'}>
      <FormattedMessage id={`broker_portal.clients.profile.${company.enrolmentOnly ? 'HQ' : 'HR'}`}/>
    </Tag>
  );
});

const ClientAvatar = observer(({uiState}) => {
  const {company} = uiState;

  return (
    <Panel.Stack>
      <Panel>
        <div className='table-cell full-width'>
          <CompanyAvatar size='lg' company={company} />
        </div>
        <div className='table-cell align-top right-align'>
          <EnrolmentOnlyTag company={company} />
          {company.awaitingLaunch && <Tag colour='waterloo'>
            <FormattedMessage id='broker_portal.clients.profile.Awaiting Launch'/>
          </Tag>}
        </div>
        <div className='h3 medium tuatara mt1'>{company.name}</div>
        <div className='jumbo'>
          <FormattedMessage 
            id='broker_portal.clients.profile.EMPLOYEES' 
            values={{employees: company.employeeCount}}
          />
        </div>
        {company.nextRenewalDate && <div className='jumbo'>
          <FormattedMessage
            id='broker_portal.clients.profile.RENEWAL'
            values={{renewalDate: company.nextRenewalDateView}}
          />
        </div>}
        <div className='mt1'>
          <ImpersonationButton uiState={uiState} />
        </div>
      </Panel>
      {company.adminContacts.map(admin => 
        <Panel key={admin.id}>
          <div>
            <div className='pb2 mb1'>
              <div className='table-cell align-middle pr2'>
                <Avatar employee={admin} size='sm'/>
              </div>
              <div className='table-cell align-middle'>
                <div className='medium'>{admin.name}</div>
                <div className='jumbo'>
                  <FormattedMessage id='broker_portal.clients.profile.Administrator'/>
                </div>
              </div>
            </div>
            {admin.phone && <div className='border-top py2'>
              <i className='material-icons h3 submarine align-top pr2'>{'phone'}</i>
              <a href={`tel:${admin.phone}`} className='TextLink'>
                {admin.phone}
              </a>
            </div>}
            {admin.email && <div className='border-top pt2'>
              <i className='material-icons h3 submarine align-top pr2'>{'mail_outline'}</i>
              <a href={`mailto:${admin.email}`} className='TextLink'>
                {admin.email}
              </a>
            </div>}
          </div>
        </Panel>
      )}
    </Panel.Stack>
  );
});

export default ClientAvatar;
