import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {Input} from 'components';

const FormRow = observer(({name, className, children}) => {
  return (
    <div className={`${className ? className : 'my3'}`}>
      <div className='h4 medium mb1'>
        <FormattedMessage id={name}/>
      </div>
      {children}
    </div>
  );
});

const EditContactInfoModal = observer(({uiState}) => {
  const {editEmployee, errors} = uiState;

  return (
    <Modal isOpen={uiState.contactInfoModalOpen} onHide={() => uiState.resetModals()} size='md'>
      <div className='h2 mb3'><FormattedMessage id='employees.profile.personal.Edit Contact Information'/></div>
      <form>
        <FormRow name='employees.profile.personal.Work Email'>
          <Input value={editEmployee.workEmail}
                 icon='business'
                 errorMessage={errors.workEmail}
                 onChange={(e) => editEmployee.merge({workEmail: e.target.value})}
          />
        </FormRow>
        <FormRow name='employees.profile.personal.Personal Email'>
          <Input value={editEmployee.personalEmail}
                 icon='home'
                 errorMessage={errors.personalEmail}
                 onChange={(e) => editEmployee.merge({personalEmail: e.target.value})}
          />
        </FormRow>
        <ModalButtons onSave={() => uiState.saveContactInfo()} onCancel={() => uiState.resetModals()} />
      </form>
    </Modal>
  );
});

export default EditContactInfoModal;
