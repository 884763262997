import React from 'react';
import Button from 'components/buttons/Button';
import {FormattedMessage} from 'react-intl';

const ModalDangerButtons = ({cancelCaption, saveCaption, onSave, onCancel, disabled}) => {
  return <div className='clearfix mt3'>
    <div className='right'>
      <Button className="mr1" trait='default' type='button' onClick={onCancel}>
        <FormattedMessage id={cancelCaption}/>
      </Button>
      <Button className="ml1" trait='destructive' onClick={onSave} disabled={disabled}>
        <FormattedMessage id={saveCaption}/>
      </Button>
    </div>
  </div>;
};

ModalDangerButtons.defaultProps = {
  cancelCaption: 'Cancel',
  saveCaption: 'Delete',
  onCancel: () => {},
  onSave: () => {},
  disabled: false
};

export default ModalDangerButtons;
