import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import {
  PerformanceReviewCycle,
  PerformanceReviewReview,
  PerformanceReviewReviewee
} from 'stores/performance_reviews';
import _ from 'lodash';

class AddRevieweeState {
  onHide;
  onSave;
  cycle;
  employees;
  revieweeEmployees;
  store = new DomainStore();

  @observable isOpen = false;
  @observable reviewee;
  @observable selectedReviewee;
  @observable selectedManager;
  @observable selectedPeers = [];
  @observable errors = {};

  @action receiveProps({isOpen, onHide, cycle, onSave}) {
    this.isOpen = isOpen;
    this.onHide = onHide;
    this.onSave = onSave;
    this.cycle = cycle;
  }

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL
    ]);

    this.employees = this.store.getEmployees();
    this.revieweeEmployees = this.store.getEmployees();

    const reviewee_ids = _.map(this.cycle.reviewees, 'employee.id');
    this.revieweeEmployees = _.differenceWith(this.employees, reviewee_ids,
      ({ id }, reviewee_id) => id === reviewee_id
    );
  }

  @action updateReviewee(reviewee) {
    this.selectedReviewee = reviewee;

    this.selectedManager = null;
    this.selectedPeers = [];

    if (this.selectedReviewee) {
      if (this.selectedReviewee.managerId) {
        this.selectedManager = _.find(this.employees, {id: this.selectedReviewee.managerId});
      }

      this.employees = _.reject(
        this.store.getEmployees(),
        employee => employee.equals(this.selectedReviewee)
      );
    }
  }

  @action addPeer(peer) {
    this.selectedPeers.push(peer);
  }

  @action removePeer(peer) {
    this.selectedPeers.remove(peer);
  }

  @action updateManagerReviewer(manager) {
    this.selectedManager = manager;
  }

  @action async saveReviewee() {

    if (!this.selectedReviewee) {
      return;
    }

    this.reviewee = new PerformanceReviewReviewee({employee: this.selectedReviewee.pick()});

    if (this.selectedManager) {
      this.reviewee.merge({reviewManager: this.selectedManager});
    }

    if (this.cycle.managerType && this.reviewee.reviewManager) {
      const managerReview = _.get(this.currentManagerReview, 'reviewer.id') === this.reviewee.reviewManager.id ?
        this.currentManagerReview :
        new PerformanceReviewReview({
          reviewer: this.reviewee.reviewManager,
          type: this.cycle.managerType
        });

      this.reviewee.reviews.push(managerReview);
    }

    if (this.cycle.peerType) {
      for (const peer of this.selectedPeers) {
        const existingPeerReview = _.find(this.currentPeerReviews, r => r.reviewer.id === peer.id);
        const peerReview = _.isEmpty(existingPeerReview) ?
          new PerformanceReviewReview({reviewer: peer, type: this.cycle.peerType}) :
          existingPeerReview;

          this.reviewee.reviews.push(peerReview);
      }
    }

    const {model, errors} = await this.store.post(
      endpoints.PERFORMANCE_REVIEWS.REVIEWEES.ADMIN.with(this.cycle.id),
      types.PERFORMANCE_REVIEWS.REVIEWEE,
      this.reviewee
    );

    this.errors = errors;
    await this.__updateCycle();

    if (model) {
      this.onSave(model);
    }
  }

  @computed get currentManagerReview() {
    return _.find(this.reviewee.reviews, r => r.type.equals(this.cycle.managerType));
  }

  @computed get currentPeerReviews() {
    return _.filter(this.reviewee.reviews, r => r.type.equals(this.cycle.peerType));
  }

  @computed get showManagerSelector() {
    return !!this.cycle.managerType;
  }

  @computed get showPeerSelector() {
    return !!this.cycle.peerType;
  }

  async __updateCycle() {
    await this.store._compose([
      endpoints.PERFORMANCE_REVIEWS.CYCLE.with(this.cycle.id)
    ]);

    const newCycle = new PerformanceReviewCycle(
      this.store._getSingle(types.PERFORMANCE_REVIEWS.CYCLE)
    );

    this.cycle.update(newCycle);
  }
}

export default AddRevieweeState;
