import {observable, action} from 'mobx';

class GoalTableState {
  parentState;
  sortingUpdated;

  @observable headers = [
    {key: 'goal', label: 'goals.Goal', sortingAllowed: true, sort: ''},
    {key: 'assignee', label: 'goals.Assignee', sortingAllowed: true, sort: ''},
    {key: 'period', label: 'goals.Period', sortingAllowed: true, sort: ''},
    {key: 'status', label: 'goals.Status', sortingAllowed: false, sort: ''},
    {key: 'progress', label: 'goals.Progress', sortingAllowed: true, sort: ''}
  ];

  receiveProps({parentState, sortingUpdated}) {
    this.parentState = parentState;
    this.sortingUpdated = sortingUpdated;
  }

  @action clickHeader(header) {
    if (!header.sortingAllowed) return;

    switch (header.sort) {
      case 'asc':
        this.__sortBy(header, 'desc');
        break;
      case 'desc':
      case '':
        this.__sortBy(header, 'asc');
        break;
      default:
        throw new Error(`Unexpected sort argument ${header.sort}`);
    }
  }

  @action __sortBy(header, direction) {
    const sorting = {};
    for (const h of this.headers) {
      sorting[h.key] = '';
      h.sort = '';
    }
    sorting[header.key] = direction;
    header.sort = direction;

    this.sortingUpdated(sorting);
  }
}

export default GoalTableState;
