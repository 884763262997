import React from 'react';
import {Modal, ModalDangerButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';

const SSOConfirmationModal = observer(({uiState, modalOpen, provider}) => {
  const {company} = uiState;

  return (
    <Modal
      title={`company_settings.applications.${provider}.${company.ssoEnabled ? 'disable_sso' : 'enable_sso'}`}
      size='sm'
      isOpen={modalOpen}
      onHide={() => uiState.closeSSOConfirmationModal()}
    >
      <div className='mb3 jumbo'>
        <FormattedMessage id={`company_settings.applications.${provider}.${company.ssoEnabled ? 'disable_confirmation' : 'enable_confirmation'}`}/>
      </div>
      <ModalDangerButtons
        saveCaption={`company_settings.applications.sso.${company.ssoEnabled ? 'Disable' : 'Enable'}`}
        onSave={() => uiState.toggleSSO()}
        onCancel={() => uiState.closeSSOConfirmationModal()}/>
    </Modal>
  );
});

export default SSOConfirmationModal;
