import {DomainObject} from 'shared/store';
import {observable, action} from 'mobx';
import {AnnouncementComment} from 'stores/announcements';

class EmployeeAnnouncement extends DomainObject {
  @observable actioned;

  @action merge(other) {
    super.merge(other, {
      announcementComments: [AnnouncementComment]
    });
  }
}

export default EmployeeAnnouncement;
