import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import {Button} from 'components';
import {PolicyEditState} from '../state';
import {withState} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';
import Explanation from './Explanation';
import Question from './Question';

const PolicyConfirmationModal = observer(({uiState}) => {
  return (
    <div>
      <Button onClick={() => uiState.showModal()}>
        <FormattedMessage id='time_off.policy.edit.Save & Continue'/>
      </Button>
      <Modal
        size='md'
        isOpen={uiState.modalOpen}
        title='time_off.policy.edit.confirmation.Changing time off policy'
        closeButton={false}
      >
        <Question uiState={uiState}/>
        <div className='mt2'>
          <Explanation uiState={uiState}/>
        </div>
        <div className='pt2 right'>
          <Button onClick={() => uiState.hideModal()} trait='default' className='pr2'>
            <FormattedMessage id='time_off.policy.edit.confirmation.Cancel'/>
          </Button>
          <Button onClick={() => uiState.confirm()} staysDisabledAfterClick disabled={!uiState.confirmEnabled}>
            <FormattedMessage id='time_off.policy.edit.confirmation.Continue'/>
          </Button>
        </div>
      </Modal>
    </div>
  );
});

export default withState(PolicyConfirmationModal, PolicyEditState);
