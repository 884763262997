import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import CustomReportState from 'reports/state/CustomReportState';
import {withState} from 'shared/core';
import FILTERS from 'reports/custom/edit/state/filters';

const CustomReport = ({uiState}) => {
  return (
    <CompositeReport filters={FILTERS} filterRows={uiState.filterRows} disableFilterEditing={uiState.disableFilterEditing}>
      <Report name={uiState.customReport.name}
              url={`/reports/custom/${uiState.match.params.id}`}>
      </Report>
    </CompositeReport>
  );
};

export default withState(CustomReport, CustomReportState);
