import React from 'react';
import {observer} from 'mobx-react';
import {Input, Submit, ActionLink, CheckboxWithLabel} from 'components';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const MFAForm = observer(({uiState}) => {
  const {form} = uiState;

  if (!form.mfaRequired) return null;

  return (
    <div>
      <div className='h1 center medium pb3 pt1'>
        <div className='pb1'>
          <FormattedMessage id='login.Two-step verification' />
        </div>
      </div>
      <div className='h4 jumbo pb3'>
        {form.isSms && <FormattedMessage id='login.SMS_VERIFICATION' values={{phone: form.mfaParameter}} />}
        {form.isAuthenticator && <FormattedMessage id='login.AUTHENTICATOR_VERIFICATION' />}
        <div>
          <FormattedMessage id='login.Enter the code below'/>
        </div>
      </div>
      <form>
        <div className='clearfix'>
          <div className='col col-12 mb1'>
            <Input
              className='large'
              value={form.mfaToken}
              autoFocus={true}
              maxLength='6'
              onChange={e => form.merge({mfaToken: e.target.value})}
            />
          </div>
          <div className='col col-12 mb2'>
            <CheckboxWithLabel
              label='login.REMEMBER_DEVICE'
              value={form.rememberDevice}
              onChange={e => form.merge({rememberDevice: e.checked})}
            />
          </div>
          <div className='col col-12 mb2'>
            <Submit size='lg' className='full-width' onClick={() => uiState.createSession()}>
              <FormattedMessage id='login.Verify'/>
            </Submit>
          </div>
          <div className='col col-12 center'>
            <ActionLink className='ml1' onClick={() => uiState.goToState(uiState.STATES.recoveryCode)}>
              <FormattedMessage id='login.Use a recovery code'/>
            </ActionLink>
          </div>
        </div>
      </form>
    </div>
  );
});

export default MFAForm;
