import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {t} from 'shared/core';
import {SpouseFields} from 'components/benefits';
import _ from 'lodash';

const Spouse = observer(({uiState}) => {
  const {benefitInfo, errors} = uiState;

  return (
    <div>
      <Panel.Header title={t('employee_benefits.benefit_infos.Spouse')}/>
      <SpouseFields benefitInfo={benefitInfo} errors={_.get(errors, 'updatedBenefitInfo') || {}}/>
    </div>
  );
});

export default Spouse;
