import React      from 'react';
import _          from 'lodash';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {Flow} from 'components';
import {Steps} from './steps';
import steps from './state/steps';
import CycleFlowState from './state/CycleFlowState';

@observer class CycleFlow extends React.Component {
  render() {
    const {match, uiState} = this.props;

    return (
      <div>
        <Flow
          name={uiState.cycle.name || t('performance.Untitled Check-In Cycle')}
          backTitle={t('performance.Check-In Cycles')}
          backRoute='/cycles'
          steps={steps}
          component={Steps}
          uiState={uiState}
          baseUrl={`/cycles/${match.params.id}`}
          onStepSubmitted={step => uiState.onStepSubmitted(step)}
        />
      </div>
    );
  }
}

export default withState(CycleFlow, CycleFlowState);
