import {DomainObject} from 'shared/store';
import {observable} from 'mobx';
import _ from 'lodash';

class MassUpdateSummary extends DomainObject {
  @observable payRate;
  @observable previousPayRate;
  @observable employee;
  @observable errorMessage;
  @observable fields = {};
}

export default MassUpdateSummary;
