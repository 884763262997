import React from 'react';
import {observer} from 'mobx-react';
import {auth, t} from 'shared/core';
import {Button, FormLayout, Input, Panel, Select2} from 'components';
import {FormattedMessage} from 'react-intl';

const languageOptions = [
  {
    value: 'en',
    label: 'English'
  },
  {
    value: 'fr',
    label: 'Français'
  },
];

const AccountPreferences = observer(({uiState}) => {
  const {user, errors, notificationEmailOptions} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('user_settings.account_preferences.Account Preferences')}/>
      <FormLayout>
        <Input
          label='user_settings.account_preferences.Login Email'
          onChange={e => user.merge({email: e.target.value})}
          errorMessage={errors.email}
          value={user.email}
          disabled={!!auth.user.authenticationProvider}
        />
        <Select2
          value={user.emailNotificationType}
          label='user_settings.account_preferences.Notification Email'
          onChange={e => user.merge({emailNotificationType: e.target.value})}
        >
          {notificationEmailOptions.map(
            option => <option value={option.value} key={option.label} disabled={option.disabled}>{option.label}</option>
          )}
        </Select2>
        <Select2
          value={user.preferredLocale}
          label='user_settings.account_preferences.Language'
          onChange={e => user.merge({preferredLocale: e.target.value})}
        >
          {languageOptions.map(
            option => <option value={option.value} key={option.label}>{option.label}</option>
          )}
        </Select2>
      </FormLayout>
      <Button onClick={() => uiState.saveAccountPreferences()}>
        <FormattedMessage id='user_settings.account_preferences.Save'/>
      </Button>
    </Panel>
  );
});

export default AccountPreferences;
