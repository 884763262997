import React from 'react';
import {observer} from 'mobx-react';
import {t, withState} from 'shared/core';
import {Display, Layout} from 'components';
import {EmployeeDiffsState} from '../state';
import {PayrollDiffs} from './payroll_diffs';

const EmployeeDiffsContainer = observer(({uiState}) => {
  const {match, payPeriod, employee} = uiState;

  return (
    <div>
      <Display
        backPath={`/updates/pay_period/${match.params.period_id}`}
        backTitle={t('payroll.periods.PAY_PERIOD_NUMBER', { payPeriodNumber: payPeriod.payNumber })}
        title={employee.name}
      />
      <Layout>
        <PayrollDiffs uiState={uiState} />
      </Layout>
    </div>
  );
});

export default withState(EmployeeDiffsContainer, EmployeeDiffsState);
