import {DomainObject} from 'shared/store';
import {observable, action} from 'mobx';
import PayrollSection from 'stores/payroll/PayrollSection';

class PayrollDiff extends DomainObject {
  @observable recordedOn;
  @observable payrollSections;
  @observable newHire;
  @observable terminated;

  @action merge(other) {
    super.merge(other, {
      payrollSections: [PayrollSection]
    });
  }
}

export default PayrollDiff;
