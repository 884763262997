import React from 'react';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

const BrokerEmployeeProfileSubnav = ({uiState}) => {
  const {match} = uiState;
  
  return (
    <ul className='Subnav-list list-reset'>
      <li><NavLink to={`${match.url}/personal`} activeClassName='active'><FormattedMessage id='employees.profile.Personal'/></NavLink></li>
      {(uiState.viewDataPermission('::ROLE_INFORMATION') || uiState.viewDataPermission('::COMPENSATION_INFORMATION')) &&
        <li><NavLink to={`${match.url}/job`} activeClassName='active'><FormattedMessage id='employees.profile.Job'/></NavLink></li>}
      <li><NavLink to={`${match.url}/benefits`} activeClassName='active'><FormattedMessage id='employees.profile.Benefits'/></NavLink></li>
    </ul>
  );
};

export default BrokerEmployeeProfileSubnav;
