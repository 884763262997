import React from 'react';
import {observer} from 'mobx-react';
import {Select2} from 'components';

const AssignToSelector = observer(({taskTemplateForm}) => {
  return (
    <Select2 
      label='tasks.Assign to'
      searchable={true} 
      placeholder='tasks.– select –'
      value={taskTemplateForm.assignee}
      onChange={e => taskTemplateForm.assignee = e.target.value}>
      {taskTemplateForm.assigneeOptions.map(assignee =>
        <option key={assignee.value} value={assignee.value}>{assignee.name}</option>)}
    </Select2>
  );
});

export default AssignToSelector;
