const spousalCoverageTypes = [
  {
    attribute: 'spousalHealthCoverage',
    label: 'components.benefits.spouse_fields.What type of health coverage does your spouse have?'
  },
  {
    attribute: 'spousalDentalCoverage',
    label: 'components.benefits.spouse_fields.What type of dental coverage does your spouse have?'
  },
  {
    attribute: 'spousalVisionCoverage',
    label: 'components.benefits.spouse_fields.What type of vision coverage does your spouse have?'
  }
];

export default spousalCoverageTypes;
