import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Department',
  'Employment Type',
  'Location',
  'Manager'
];

const GenderDemographicsReport = () => {
  return (
    <CompositeReport filters={filters}>
      <Report name={t('reports.Gender Demographics')}
              view='chart' chartType='pie'
              url='/reports/gender_demographics_chart'>
      </Report>
      <Report name={t('reports.Gender Demographics by Department')}
              view='chart'
              options={{
                stacking: 'normal'
              }}
              url='/reports/gender_by_department_chart'>
      </Report>
      <Report name={t('reports.Gender Split by Department')}
              view='chart'
              options={{
                stacking: 'percent'
              }}
              url='/reports/gender_by_department_chart'>
      </Report>
      <Report
        name={t('reports.Gender Demographics')}
        url='/reports/gender_demographics'
      />
    </CompositeReport>
  );
};

export default GenderDemographicsReport;
