import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {CheckboxWithLabel, Input} from 'components';

const EditTypeModal = observer(({uiState}) => {
  const {errors, editingType, editMessage} = uiState;

  return (
    <Modal
      isOpen={uiState.editTypeModalOpen}
      onHide={() => uiState.closeEditTypeModal()}
      size='sm'
      title={editMessage}>
      <Input
        value={editingType.name}
        errorMessage={errors.name}
        onChange={(e) => editingType.merge({name: e.target.value})}
       />
      <CheckboxWithLabel
        className='mt2'
        label='time_off.types.Include in time tracking'
        checked={editingType.includedInTimeTracking}
        onChange={(e) => editingType.includedInTimeTracking = e.checked}
      />
      <ModalButtons onSave={() => uiState.saveType()} onCancel={() => uiState.closeEditTypeModal()} />
    </Modal>
  );
});

export default EditTypeModal;
