import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class InboxSummary extends DomainObject {
  @observable itemsCount;
  @observable personCount;
  @observable overdueCount;
}

export default InboxSummary;
