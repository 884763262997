import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {SurveyFlowState, steps} from '../state';
import {Flow} from 'components';
import {Steps} from './steps';

const SurveyFlow = observer(({uiState}) => {
  const {survey, match} = uiState;

  return (
    <Flow
      name={survey.name || t('surveys.Untitled survey')}
      backTitle={t('surveys.Surveys')}
      backRoute='/manage'
      steps={steps}
      component={Steps}
      uiState={uiState}
      baseUrl={`/manage/survey/${match.params.id}/edit`}
      onStepSubmitted={step => uiState.onStepSubmitted(step)}
    />
  );
});

export default withState(SurveyFlow, SurveyFlowState);
