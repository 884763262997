import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {Switch, Route, Redirect} from 'react-router-dom';
import {SurveyViewPageState} from '../state';
import {AdminContainer} from './admin';
import {SummaryContainer} from './summary';
import {ResponseContainer} from './response';

const SurveyViewPage = observer(({uiState}) => {
  const {match, scope, survey, defaultPath} = uiState;

  return (
    <Switch>
      {scope.overview && <Route path={`${match.path}/admin`}
        render={({match, history}) => <AdminContainer {...{match, history, scope, survey}} />} />}
      {scope.summary && <Route path={`${match.path}/summary`}
        render={({match, history}) => <SummaryContainer {...{match, history, scope, survey}} />} />}
      {scope.viewEmployeeResponse && <Route path={`${match.path}/response/:employeeId`}
          render={({match, history}) => <ResponseContainer {...{match, survey}} />} />}
      <Redirect exact from={match.path} to={`${match.url}${defaultPath}`} />
    </Switch>
  );
});

export default withState(SurveyViewPage, SurveyViewPageState);
