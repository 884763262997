import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {redirect} from 'shared/tools';
import {urls} from 'shared/core';
import {ActionLink} from 'components';

const NewApplicants = ({applicantCount, jobCount}) => {
  if (applicantCount === 0) return null;

  return (
    <span>
      <FormattedMessage id='hr_dashboard.insights.job_applicant.MESSAGE'
        values={{
          applicantCount: applicantCount,
          jobCount: jobCount,
          link: <ActionLink href={urls.CANDIDATE_DIRECTORY.NEW} onClick={() => redirect(urls.CANDIDATE_DIRECTORY.NEW)}>
                  <FormattedMessage id='hr_dashboard.insights.job_applicant.MESSAGE_LINK' values={{num: applicantCount}}/>
                </ActionLink>
        }}
      />
    </span>
  );
};

const StaleApplicants = ({staleApplicantCount}) => {
  if (staleApplicantCount === 0) return null;

  return (
    <span>
      <FormattedMessage id='hr_dashboard.insights.job_applicant.WARNING'
        values={{
          num: staleApplicantCount,
          link: <ActionLink scarlet href={urls.CANDIDATE_DIRECTORY.STALE} onClick={() => redirect(urls.CANDIDATE_DIRECTORY.STALE)}>
                  <FormattedMessage id='hr_dashboard.insights.job_applicant.WARNING_LINK' values={{num: staleApplicantCount}}/>
                </ActionLink>
        }}
      />
    </span>
  );
};

const JobApplicantText = observer(({jobApplicantSummary}) => {
  const {applicantCount, jobCount, staleApplicantCount} = jobApplicantSummary;

  if (staleApplicantCount === 0 && applicantCount === 0) return null;

  return (
    <li>
      <NewApplicants applicantCount={applicantCount} jobCount={jobCount}/>
      <StaleApplicants staleApplicantCount={staleApplicantCount}/>
    </li>
  );
});

export default JobApplicantText;
