import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import RevieweesContainer from './checkins/manager/RevieweesContainer';
import RevieweeDetailsContainer from './checkins/manager/RevieweeDetailsContainer';
import CheckInState from '../state/checkins/CheckInState';

const RevieweesSwitch = observer(({uiState, scope}) => {
  return (
    <Switch>
      <Route exact path='/reviewees' render={() => <RevieweesContainer scope={scope}/>}/>
      <Route path='/reviewees/:employeeId' render={(props) => <RevieweeDetailsContainer {...props} checkinStore={uiState}/>}/>
    </Switch>
  );
});

export default withState(RevieweesSwitch, CheckInState);
