import React from 'react';
import {t, withState} from 'shared/core';
import {observer} from 'mobx-react';
import TrainingHiddenFields from './TrainingHiddenFields';
import TrainingListState from 'employees/hiring/state/TrainingListState';
import TrainingRow from './TrainingRow';
import {FormattedMessage} from 'react-intl';
import {Panel} from 'components';

const TrainingList = observer(({uiState}) => {
  const {programs} = uiState;
  return (
    <Panel>
      <Panel.Header
        title={t('employees.hiring.Training')}
        description={t('employees.hiring.Select training programs for the new employee')}
      />
      <div className='clearfix table border-bottom py1'>
        <div className='col-7 table-cell waterloo h6 pl2'>
          <div className='pl1 ml1 caps'>
            <FormattedMessage id='employees.hiring.Active Programs'/>
          </div>
        </div>
        <div className='col-5 table-cell waterloo h6 caps'>
          <FormattedMessage id='employees.hiring.Required'/>
        </div>
      </div>
      {programs.map((viewModel, index) =>
        <TrainingRow viewModel={viewModel} key={index}/>)}
      <TrainingHiddenFields uiState={uiState}/>
    </Panel>
  );
});

export default withState(TrainingList, TrainingListState);
