import React from 'react';
import {auth, endpoints} from 'shared/core';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Inbox from './Inbox';
import InboxSubnav from './InboxSubnav';

const CompanyInbox = () => {
  return (
    <div>
      <InboxSubnav/>
      <Inbox
        endpoint={endpoints.TASKS.ALL}
        showAvatars
      />
    </div>
  );
};

const PersonalInbox = () => {
  return (
    <div>
      <InboxSubnav/>
      <Inbox
        endpoint={endpoints.EMPLOYEE.TASKS.with(auth.employee.id)}
      />
    </div>
  );
};

const InboxPage = () => {
  return (
    <Router basename='/inbox'>
      <Switch>
        {(auth.viewData('::TASKS')) && <Route path='/company' component={CompanyInbox}/>}
        {auth.user.employee && <Route path='/personal' component={PersonalInbox}/>}
        {(auth.user.admin || auth.viewData('::TASKS')) && <Redirect exact from='/' to='/company'/>}
        {auth.user.employee && <Redirect exact from='/' to='/personal'/>}
      </Switch>
    </Router>
  );
};

export default InboxPage;
