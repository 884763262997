import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Department',
  'Employee',
  'Employment Status',
  'Employment Type',
  'Location',
  'Manager',
  'Time Off Type'
];

const TimeOffRequestsReport = () => {
  return (
    <CompositeReport dateFilter='range' filters={filters}>
      <Report name={t('reports.TIME_OFF_REQUESTS_REPORT')}
              url='/reports/time_off_requests'>
      </Report>
    </CompositeReport>
  );
};

export default TimeOffRequestsReport;
