import React from 'react';
import {observer} from 'mobx-react';
import {types, endpoints, t} from 'shared/core';
import CustomReport from 'stores/reports/CustomReport';
import {ReportName} from 'reports/custom';
import {InteractiveTable} from 'components';
import {Link} from 'react-router-dom';

const Report = observer(({model}) => {
  return (
    <Link to={`custom/${model.id}`} className='dodger TextLink'>
      <ReportName report={model}/>
    </Link>
  );
});

const LOCALIZATION = {
  emptyState: 'reports.custom.No reports to show'
};

const COLUMNS = [
  {
    header: 'reports.custom.Name',
    component: Report,
    width: 8
  },
  {
    header: 'reports.custom.Shared By',
    attribute: 'sharedBy.name',
    width: 4
  }
];

const SharedReportsTable = observer(({uiState}) => {
  return (
    <InteractiveTable
      uiState={uiState}
      title={t('reports.custom.Shared with Me')}
      showLinks={false}
      columns={COLUMNS}
      localization={LOCALIZATION}
      sortable={false}
      proxy={{
        type: 'async',
        modelType: types.CUSTOM_REPORT,
        model: CustomReport,
        endpoint: endpoints.CUSTOM_REPORTS.SHARED,
        pageSize: 10
      }}
    />
  );
});

export default SharedReportsTable;
