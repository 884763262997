import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import FormLayout from 'components/forms/FormLayout';
import Input from 'components/Input';
import Select2 from 'components/Select2';
import {CheckboxWithLabel, LabelHelper, FormError, LexicalEditor} from 'components';
import QuestionTypeFormFactory from './QuestionTypeFormFactory';

const QuestionForm = observer((props) => {
  const {question, questionTypes, localization, showRequired, showDescription, showQuestionType, errorMessage, ...rest} = props;

  return (
    <FormLayout>
      <Input
        label={localization.question}
        placeholder={localization.question}
        value={question.questionName}
        onChange={e => question.updateText(e.target.value)} />
      {showDescription && <div>
        <LabelHelper label={t(localization.description)} />
        <LexicalEditor
          placeholder={'recruiting.edit.steps.Add a description...'}
          onChange={description => question.merge({lexicalState: description.state, description: description.html})}
          initialEditorState={question.lexicalState}
          initialHTML={question.description}
          disableHeaders
        />
      </div>}
      {(question.isNew && showQuestionType) && <Select2
        label={localization.questionType}
        value={question.questionType}
        onChange={e => {question.args = {options: []}; question.merge({questionType: e.target.value});}}>
          {questionTypes.map(type =>
            <option value={type} key={type}>
              {t(`components.question_form.question_types.${type}`)}
            </option>)}
      </Select2>}
      <QuestionTypeFormFactory question={question} {...rest}/>
      <FormError message={errorMessage} />
      {showRequired && <CheckboxWithLabel
        checked={question.required}
        label={localization.required}
        onChange={e => question.merge({required: e.checked})}
      />}
    </FormLayout>
  );
});

export default QuestionForm;
