import React from 'react';
import {observer} from 'mobx-react';
import {t, urls, withState} from 'shared/core';
import {CompanyPlansState} from '../state';
import {IndexTable, Spinner} from 'components';
import {CarrierIcon} from 'components/benefits';

const CompanyPlans = observer(({uiState}) => {
  const {sortedPlans} = uiState;

  return (
    <IndexTable title={t('pa_dashboard.Company Plans')}>
      {sortedPlans.map(plan => (
        <IndexTable.Row
          key={plan.id}
          icon={<CarrierIcon carrier={plan}/>}
          trait='component'
          title={plan.title}
          description={plan.subtitle}
          showPath={urls.BENEFITS.COMPANY_PLAN.with(plan.recordId)}
        />
      ))}
    </IndexTable>
  );
});

export default withState(CompanyPlans, CompanyPlansState, {
  spinner: () => (
    <IndexTable title={t('pa_dashboard.Company Plans')}>
      <Spinner/>
    </IndexTable>
  )
});
