import React from 'react';
import {observer} from 'mobx-react';
import SubNav from 'company_settings/SubNav';
import {withState, t} from 'shared/core';
import {AttachedDocumentDownload, ActionLink, Button, Panel, Table, Layout} from 'components';
import {FormattedMessage} from 'react-intl';
import ReorderFilesModal from './ReorderFilesModal';
import CompanyFileListState from '../state/CompanyFileListState';
import EditCompanyFileModal from './EditCompanyFileModal';
import useModal from 'shared/hooks/useModal';
import MoveCompanyFileModal from './MoveCompanyFileModal';
import EditCompanyFileFolderModal from './EditCompanyFileFolderModal';
import ReorderFoldersModal from './ReorderFoldersModal';
import {CompanyFileFolder, CompanyFile} from 'stores/company_files';
import RemoveCompanyFileFolderModal from './RemoveCompanyFileFolderModal';
import RemoveCompanyFileModal from './RemoveCompanyFileModal';

const RootDocumentLink = observer(({title, uiState}) => {
  const {currentFolder} = uiState;

  return (
    <div className='Breadcrumb h3'>
      <ActionLink className='jumbo' onClick={() => uiState.goToRootFolder()}>
        {title}
      </ActionLink>
      {currentFolder.name}
    </div>
  );
});

const Breadcrumb = observer(({title, uiState}) => {
  const {currentFolder} = uiState;

  return (
    <Panel.Header title={currentFolder ? <RootDocumentLink title={title} uiState={uiState}/> : title}>
      <FileActionButtons uiState={uiState}/>
    </Panel.Header>
  );
});

const FileActionButtons = observer(({uiState}) => {
  return (
    <div className='flex justify-content-end'>
      <Button size='sm' className='mr2' trait='default' onClick={() => uiState.openReorderFilesModal()}>
        <FormattedMessage id='company_settings.company_files.Reorder Files'/>
      </Button>
      <Button size='sm' onClick={() => uiState.openEditCompanyFileModal({companyFileFolderId: uiState.currentFolderId})}>
        <FormattedMessage id='company_settings.company_files.+ Add File'/>
      </Button>
    </div>
  );
});

const FolderActionButtons = observer(({uiState}) => {
  if (uiState.currentFolder) return null;

  return (
    <div className='flex justify-content-end'>
      <Button size='sm' className='mr2' trait='default' onClick={() => uiState.openReorderFoldersModal()}>
        <FormattedMessage id='company_settings.company_files.Reorder Folders'/>
      </Button>
      <Button size='sm' onClick={() => uiState.openEditCompanyFileFolderModal(new CompanyFileFolder())}>
        <FormattedMessage id='company_settings.company_files.+ Add Folder'/>
      </Button>
    </div>
  );
});

const CompanyFileDownload = observer(({model}) => {
  if (!model.file) return null;

  return <AttachedDocumentDownload name={model.file.fileName} doc={model.file} />;
});

const Icon = observer(({model}) => {
  return <img src={model.icon} height='20' width='20' alt={model._type}/>;
});

const COLUMNS = [
  {
    component: Icon,
    width: 1
  },
  {
    header: 'company_settings.company_files.Displayed Name',
    attribute: 'name',
    width: 4
  },
  {
    header: 'company_settings.company_files.File',
    component: CompanyFileDownload,
    width: 6
  }
];

const CompanyFilesContainer = observer(({uiState}) => {
  const {entries, loadingFiles} = uiState;
  const moveCompanyFileModalOpen = useModal(uiState, 'MoveCompanyFileModal', 'selectedCompanyFile', CompanyFile);
  const editCompanyFileFolderModalOpen = useModal(uiState, 'EditCompanyFileFolderModal', 'selectedCompanyFileFolder', CompanyFileFolder);
  const reorderFoldersModalOpen = useModal(uiState, 'ReorderFoldersModal');
  const removeCompanyFileFolderModalOpen = useModal(uiState, 'RemoveCompanyFileFolderModal', 'selectedCompanyFileFolder', CompanyFileFolder);
  const removeCompanyFileModalOpen = useModal(uiState, 'RemoveCompanyFileModal', 'selectedCompanyFile', CompanyFile);

  return (
    <div>
      <SubNav title={t('company_settings.subnav.Company Files')} toolbar={
        <FolderActionButtons uiState={uiState}/>
      }/>
      <Layout>
        <Panel>
          <Breadcrumb title={t('company_settings.subnav.Company Files')} uiState={uiState}/>
          <Table
            indexBy='key'
            isUpdating={loadingFiles}
            columns={COLUMNS}
            models={entries}
            customLinks={model => uiState.customLinksFor(model)}
            onlyShowCustomLinks
            onRowClicked={model => uiState.selectFolder(model)}
          />
        </Panel>
      </Layout>
      <EditCompanyFileModal uiState={uiState}/>
      <ReorderFilesModal uiState={uiState}/>
      <MoveCompanyFileModal modalOpen={moveCompanyFileModalOpen} uiState={uiState}/>
      <EditCompanyFileFolderModal modalOpen={editCompanyFileFolderModalOpen} uiState={uiState}/>
      <ReorderFoldersModal modalOpen={reorderFoldersModalOpen} uiState={uiState}/>
      <RemoveCompanyFileFolderModal modalOpen={removeCompanyFileFolderModalOpen} uiState={uiState}/>
      <RemoveCompanyFileModal modalOpen={removeCompanyFileModalOpen} uiState={uiState}/>
    </div>
  );
});

export default withState(CompanyFilesContainer, CompanyFileListState);
