import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Avatar, Spinner} from 'components';
import _ from 'lodash';
import {withState} from 'shared/core';
import CheckInSummaryList from '../CheckInSummaryList';
import CheckInSummaryItem from '../CheckInSummaryItem';
import CheckInDetails from '../CheckInDetails';
import RevieweeHeader from '../RevieweeHeader';
import AdminRevieweeEmptyState from './AdminRevieweeEmptyState';
import {observer} from 'mobx-react';
import {fullName} from 'shared/tools';
import AdminCheckInState from './state/AdminCheckInState';
import {Display} from 'components';

const AdminRevieweeDetailsContainer = observer(({uiState, match}) => {
  return (
    <div>
      <Display backPath={`/cycles/${match.params.id}/reviewees`}/>
      <RevieweeHeader reviewee={uiState.checkinStore.selectedReviewee}/>
      {!_.isEmpty(uiState.checkinStore.allCheckins) && <div className='Container pt3'>
        <div className='flex flex-wrap content-stretch bg-white rounded box-shadow-3'>
          <CheckInSummaryList>
            {uiState.checkinStore.allCheckins.map(checkin =>
              <CheckInSummaryItem key={checkin.id} checkin={checkin} onClick={uiState.onCheckInSelected}>
                <div className='mt2'>
                  <div className='table-cell align-middle'>
                    <Avatar employee={checkin.reviewer} size='sm'/>
                  </div>
                  <div className='table-cell align-middle px1'>
                    <span className='jumbo'>
                      <FormattedMessage
                        id='performance.WITH_REVIEWER'
                        values={{name: <span className='tuatara'>{fullName(checkin.reviewer)}</span>}}
                      />
                    </span>
                  </div>
                </div>
              </CheckInSummaryItem>
            )}
          </CheckInSummaryList>
          {
            uiState.checkinStore.selectedCheckin && !uiState.checkinStore.isLoading &&
            <CheckInDetails
              reviewee={uiState.checkinStore.selectedReviewee}
              checkin={uiState.checkinStore.selectedCheckin}
              adminView={true}
            />
          }
          {uiState.checkinStore.isLoading && <Spinner />}
        </div>
      </div>}
      {_.isEmpty(uiState.checkinStore.allCheckins) &&
        <AdminRevieweeEmptyState reviewee={uiState.checkinStore.selectedReviewee} cycleId={match.params.id} />
      }
    </div>
  );
});

export default withState(AdminRevieweeDetailsContainer, AdminCheckInState);
