import React from 'react';
import {observer} from 'mobx-react';
import {FieldHeader} from 'components';

const Description = observer(({answer, required}) => {
  return (
    <div>
      <FieldHeader
        title={answer.question.questionName}
        required={required}
        description={answer.question.description}
        className='medium'
      />
    </div>
  );
});

export default Description;
