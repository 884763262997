import NumericFilterRowViewModel from './NumericFilterRowViewModel';

function createPayRateViewModel(model) {
  return new NumericFilterRowViewModel({
    define: {
      category: 'Pay Rate',
      attribute: 'pay_rate',
      operators: ['>=', '<=', 'between']
    }
  });
}

export default createPayRateViewModel;
