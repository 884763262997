import {endpoints, types} from 'shared/core';
import Reminder from 'stores/reminders/Reminder';
import {DomainStore} from 'shared/store';

class ReminderDirectoryStore extends DomainStore {
  async load() {
    await this._compose([
      endpoints.REMINDERS
    ]);
  }

  getReminders() {
    return this._getAll(types.REMINDER, Reminder);
  }
}

const singleton = new ReminderDirectoryStore();

export default singleton;
