import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Review',
  'Reviewer',
  'Department',
  'Location',
  'Manager',
  'Gender',
  'Employee',
  'Employment Status',
  'Employment Type'
];

const PerformanceReviewsReport = () => {
  return (
    <CompositeReport filters={filters}>
      <Report name={t('reports.PERFORMANCE_REVIEWS')}
              url='/reports/performance_reviews'>
      </Report>
    </CompositeReport>
  );
};

export default PerformanceReviewsReport;
