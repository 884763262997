import React from 'react';
import {noop} from 'shared/tools';
import {observer} from 'mobx-react';
import Input from 'components/Input';
import Description from './Description';

const TextField = observer(({answer, required, errorMessage, disabled, onChanged}) => {
  return (
    <div>
      <Description answer={answer} required={required} />
      <Input
        value={answer.value}
        disabled={disabled}
        onChange={(e) => {answer.updateValue(e.target.value); onChanged();}}
        translateLabel={false}
        errorMessage={errorMessage}
      />
    </div>
  );
});

TextField.defaultProps = {
  onChanged: noop
};

export default TextField;
