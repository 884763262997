import React from 'react';
import {observer} from 'mobx-react';
import {Switch, Route} from 'react-router-dom';
import {OnboardingTasksDirectory, OffboardingTasksDirectory} from './directory';
import {TaskEditContainer} from './edit';

const TasksPage = observer(() => {
  return (
    <Switch>
      <Route exact path='/tasks/onboarding' component={OnboardingTasksDirectory} />
      <Route exact path='/tasks/offboarding' component={OffboardingTasksDirectory} />
      <Route path='/tasks/edit/:taskType/:id?' component={TaskEditContainer} />
    </Switch>
  );
});

export default TasksPage;

