import {observable} from 'mobx';
import RecruitingDirectoryState from './RecruitingDirectoryState';
import EditPositionState from './EditPositionState';
import HiringFunnelState from './HiringFunnelState';

class RecruitingState {
  @observable directoryState;
  @observable editState;
  @observable hiringFunnelState;

  constructor (recruitingStore, history, match) {
    if (RecruitingState.instance) {
      return RecruitingState.instance;
    }
    this.directoryState = new RecruitingDirectoryState(
      recruitingStore,
      history,
      match
    );
    this.editState = new EditPositionState(
      recruitingStore,
      history,
      this
    );
    this.hiringFunnelState = new HiringFunnelState(
      recruitingStore,
      history
    );
    RecruitingState.instance = this;
  }

  static instance;
}

export default RecruitingState;
