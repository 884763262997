import {DomainObject, oneOf} from 'shared/store';
import {observable, computed, action} from 'mobx';
import AttachedDocument from 'stores/documents/AttachedDocument';

class ReportDownload extends DomainObject {
  @observable id;
  @observable status;
  @observable reportFile;
  @observable brokerPortalReport;

  @action merge(other) {
    super.merge(other, {
      status: oneOf(['pending', 'successful', 'failed']),
      reportFile: AttachedDocument
    });
  }

  @computed get isReady() {
    return this.status === 'successful' || this.status === 'failed';
  }
}

export default ReportDownload;
