import {action, observable, computed} from 'mobx';
import {PerformanceReviewQuestionWithType, PerformanceReviewResult} from 'stores/performance_reviews';
import {DomainStore} from 'shared/store';
import performanceTrendsOptions from './performanceTrendsOptions';
import {t, endpoints, types} from 'shared/core';
import {Employee} from 'stores/employees';
import _ from 'lodash';

class PerformanceTrendsState {
  store = new DomainStore();

  @observable result = new PerformanceReviewResult({});

  @observable employees = [];
  @observable questions = [];

  @observable groupByQuestions = true;
  @observable groupByEmployees = false;

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.PERFORMANCE_REVIEWS.SCORED_QUESTIONS
    ]);

    this.employees = this.store._getAll(types.EMPLOYEE, Employee);
    this.questions = this.store._getAll(types.PERFORMANCE_REVIEWS.QUESTION_WITH_TYPE, PerformanceReviewQuestionWithType);

    const [
      highcharts,
      highchartsReact,
      highchartsExporting,
      highchartsOfflineExporting
    ] = await Promise.all([
      import(/* webpackChunkName: 'highcharts-async' */ 'highcharts'),
      import(/* webpackChunkName: 'highcharts-react-official-async' */ 'highcharts-react-official'),
      import('highcharts/modules/exporting'),
      import('highcharts/modules/offline-exporting')
    ]);

    this.Highcharts = highcharts.default;
    this.HighchartsReact = highchartsReact.default;
    highchartsExporting.default(highcharts.default);
    highchartsOfflineExporting.default(highcharts.default);
  }

  @action updateResults(interactiveContextState) {
    this.result = new PerformanceReviewResult(interactiveContextState.data);
  }

  @action toggleGroupByQuestions(checked) {
    this.groupByQuestions = checked;
  }

  @action toggleGroupByEmployees(checked) {
    this.groupByEmployees = checked;
  }

  @computed get trendsOptions() {
    const options = performanceTrendsOptions(
      this.result.trend
    );
    const uiState = this;

    return {
      ...options,
      tooltip: {
        useHTML: true,
        pointFormatter: function() {
          const cycleName = _.find(uiState.result.trend.cycles, cycle => Date.parse(cycle.launchedAt) === this.x).name;
          const score = Math.round(this.y * 100) / 100;

          let tooltip = `<div><strong>${cycleName}</strong></div>`;

          if (uiState.groupByEmployees && uiState.groupByQuestions) {
            tooltip += `<div>${t('performance_reviews.performance_trends.Average employee score across all questions:')}</div>`;
            tooltip += `<span>${score}</span>`;
          } else if (uiState.groupByQuestions) {
            tooltip += `<div>${t('performance_reviews.performance_trends.AVERAGE_SCORE_FOR_QUESTION', {question: this.series.name})}</div>`;
            tooltip += `<span>${score}</span>`;
          } else if (uiState.groupByEmployees) {
            tooltip += `<div>${t('performance_reviews.performance_trends.AVERAGE_SCORE_FOR_EMPLOYEE', {employee: this.series.name})}</div>`;
            tooltip += `<span>${score}</span>`;
          } else {
            tooltip += `<div>${t('performance_reviews.performance_trends.EMPLOYEE_SCORE_FOR_QUESTION', {employee: this.series.options.custom.employeeName, question: this.series.options.custom.questionName})}</div>`;
            tooltip += `<span>${score}</span>`;
          }

          return tooltip;

        }
      },
      series: this.trendsSeries
    };
  }

  @computed get trendsSeries() {
    return _.map(this.result.trend.series, series => {
      return {
        name: this.seriesName(series),
        data: _.map(series.answers, (answer) => {
          return [
            Date.parse(answer.launchedAt),
            answer.score
          ];
        }),
        custom: this.seriesCustomValues(series)
      };
    });
  }

  seriesName(series) {
    if (this.groupByQuestions && this.groupByEmployees) {
      return t('performance_reviews.performance_trends.All employees and questions');
    } else if (this.groupByQuestions) {
      return `${series.questionName} (${t(`models.performance_reviews.type.${series.reviewType}`)})`;
    } else if (this.groupByEmployees) {
      return _.get(_.find(this.employees, {id: String(series.employeeId)}), 'name');
    } else {
      return `${_.get(_.find(this.employees, {id: String(series.employeeId)}), 'name')} - ${series.questionName} (${t(`models.performance_reviews.type.${series.reviewType}`)})`;
    }
  }

  seriesCustomValues(series) {
    if (this.groupByQuestions && this.groupByEmployees) {
      return {};
    } else if (this.groupByQuestions) {
      return {
        questionName: `${series.questionName} (${t(`models.performance_reviews.type.${series.reviewType}`)})`
      };
    } else if (this.groupByEmployees) {
      return {
        employeeName: _.get(_.find(this.employees, {id: String(series.employeeId)}), 'name')
      };
    } else {
      return {
        questionName: `${series.questionName} (${t(`models.performance_reviews.type.${series.reviewType}`)})`,
        employeeName: _.get(_.find(this.employees, {id: String(series.employeeId)}), 'name')
      };
    }
  }
}

export default PerformanceTrendsState;
