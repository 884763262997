import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {InteractiveTable} from 'components';
import {t, endpoints, types} from 'shared/core';
import {CompanyInsuranceCarrier} from 'stores/benefits';
import {calendarDate} from 'shared/tools';
import {CarrierIcon} from 'components/benefits';

const CompanyItem = observer(({model}) => {
  const {company} = model;

  return (
    <div>
      <div className='table-cell align-middle pr2'>
        <CarrierIcon carrier={model}/>
      </div>
      <div className='table-cell align-middle'>
        <div>
          <Link className='TextLink' to={`/clients/${company.id}`}>{company.name}</Link>
        </div>
        <div className='jumbo'>{`${calendarDate(model.upcomingRenewalDate)} - ${model.name}`}</div>
      </div>
    </div>
  );
});
const COLUMNS = [
  { component: CompanyItem, width: 12 }
];

const UpcomingRenewalDates = observer(() => {
  return (
    <InteractiveTable
      title={t('broker_dashboard.Upcoming Renewal Dates')}
      columns={COLUMNS}
      showLinks={false}
      showHeaders={false}
      searchable={false}
      proxy={{
        pageSize: 5,
        type: 'async',
        model: CompanyInsuranceCarrier,
        modelType: types.COMPANY_INSURANCE_CARRIER,
        endpoint: endpoints.BROKER_PORTAL.DASHBOARD.UPCOMING_RENEWAL_DATES
      }}
    />
  );
});

export default UpcomingRenewalDates;
