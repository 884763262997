import {observable, action} from 'mobx';

class TrainingProgramViewModel {
  @observable data;
  @observable selected = false;

  constructor({data, selected}) {
    this.data = data;
    this.selected = selected;
  }

  @action toggle() {
    this.selected = !this.selected;
  }
}

export default TrainingProgramViewModel;
