import React from 'react';
import {observer} from 'mobx-react';
import AccountSummariesWithStateContainer from 'containers/time_off/components/account_summaries/components/AccountSummariesWithStateContainer';
import TimeOffSubnav from 'containers/time_off/TimeOffSubnav';

const AccountSummariesContainer = observer(({scope}) => {
  return (
    <div>
      <TimeOffSubnav scope={scope} />
      <AccountSummariesWithStateContainer/>
    </div>
  );
});

export default AccountSummariesContainer;
