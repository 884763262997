import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, ObjectSelect2} from 'components';

const Assignee = observer(({uiState}) => {
  const {assignee, availableAssignees} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('time_tracking.manage.policies.edit.steps.Approver')}
        description={t('time_tracking.manage.policies.edit.Choose the users that will be assigned to approving timesheets for employees in this policy.')} />
      <ObjectSelect2
        value={assignee}
        onChange={assignee => uiState.selectAssignee(assignee)}
        searchable={true}
        items={availableAssignees}
      />
    </div>
  );
});

export default Assignee;
