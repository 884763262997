import React from 'react';
import {observer} from 'mobx-react';
import {Layout, Display, Button, Input, Panel, DragAndDrop, EditQuestionModal, FormError} from 'components';
import {t, withState} from 'shared/core';
import _ from 'lodash';
import InterviewGuideEditState from 'recruiting/state/InterviewGuideEditState';
import {FormattedMessage} from 'react-intl';

const QUESTION_TYPES = [
  'text_area'
];

const LOCALIZATION = {
  addModel: 'recruiting.interview_guides.+ Add a question'
};


const QuestionContent = observer(({model}) => {
  return (
    <div>
      <div>
        {model.name && <span className='mr1'>{model.name}</span>}
        <span className='waterloo'>
          {t(`components.question_form.question_types.${model.questionType}`)}
        </span>
      </div>
    </div>
  );
});

const InterviewGuideEditContainer = observer(({uiState}) => {
  const {interviewGuide, editingQuestion, editQuestionModalOpen, errors} = uiState;

  return (
    <div>
      <Display
        title={interviewGuide.name || t('recruiting.interview_guides.Untitled interview guide')}
        backTitle={t('recruiting.interview_guides.Back')}
        backPath='/interview-guides'/>
      <Layout>
        <Layout.Section>
          <Panel>
            <Panel.Header
              title={t('recruiting.interview_guides.Name')}
              description={t('recruiting.interview_guides.Choose a name for your interview guide that will make it easy to recognize and select when reviewing candidates.')}
            />
            <Input value={interviewGuide.name}
              onChange={e => interviewGuide.merge({name: e.target.value})}
              helpMessage='recruiting.interview_guides.e.g., Sales Representative Interview Guide'
              className='full-width'
              errorMessage={errors.name}
            />
          </Panel>
          <Panel.Stack>
            <Panel className='mt2'>
              <Panel.Header
                title={t('recruiting.interview_guides.Questions')}
                description={t('recruiting.interview_guides.These questions will be asked when using this guide to make notes on a candidate.')}
              />
            </Panel>
            <Panel>
              <DragAndDrop
                models={interviewGuide.questions}
                component={QuestionContent}
                onAdd={(order) => uiState.addNewQuestion(order)}
                onRemove={(question) => uiState.removeQuestion(question)}
                onEdit={(question) => uiState.editQuestion(question)}
                localization={LOCALIZATION}
                errors={errors}
                errorPath={(index) => `questions[${index}].name`}
              />
              {editingQuestion && <EditQuestionModal
                questionTypes={QUESTION_TYPES}
                isOpen={editQuestionModalOpen}
                onClose={() => uiState.closeEditQuestionModal()}
                onSave={() => uiState.updateQuestion()}
                showRequired={false}
                question={editingQuestion}
                errorMessage={errors.options}
              />}
              <FormError message={errors.base}/>
            </Panel>
          </Panel.Stack>
          <div className='mt3 right-align'>
            <Button onClick={() => uiState.saveInterviewGuide()}>
              <FormattedMessage id='recruiting.interview_guides.Save'/>
            </Button>
          </div>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(InterviewGuideEditContainer, InterviewGuideEditState);