import React from 'react';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';
import {Avatar, UploadFiles, TextArea, Button} from 'components';
import {observer} from 'mobx-react';
import {auth} from 'shared/core';

const FileUpload = observer(({detailsState, sectionViewModel, intl}) => {
  if (!sectionViewModel.showFileUpload) return null;

  return (
    <div className='clearfix border-top pt3 px3'>
      <div className='col col-1 pr1'>
        <Avatar employee={auth.user} size='sm' className='left'/>
      </div>
      <div className='col col-11 pl1'>
        <TextArea
          minRows={3}
          value={detailsState.newUploadText}
          onChange={(e) => detailsState.newUploadText = e.target.value}
          placeholder='recruiting.candidate.Description of files'
          className='full-width js-file-uploads'
        />
        <UploadFiles
          modelType='ats/timeline_entries/base'
          defaultFiles={detailsState.newFiles}
          onChange={files => detailsState.newFiles = files}
        />
        <div className='right my2'>
          <div className='table-cell align-middle px2'>
            <Button trait='default' size='sm' onClick={() => detailsState.cancelInput()}>
              <FormattedMessage id='recruiting.hiring_funnel.Cancel'/>
            </Button>
          </div>
          <div className='table-cell align-middle'>
            <Button size='sm' onClick={() => detailsState.addFileUploads()}>
              <FormattedMessage id='Save'/>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default FileUpload;
