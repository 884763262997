import React from 'react';
import {observer} from 'mobx-react';
import {DatePicker, EmailTemplatePreview} from 'components';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const LaunchModal = observer(({uiState}) => {
  const {cycle, errors} = uiState;

  return (
    <Modal isOpen={uiState.launchCycleModalOpen} onHide={() => uiState.closeLaunchCycleModal()} size='md'>
      <div className='clearfix'>
        <div className='h2 pt1 medium'>
           <FormattedMessage id='performance_reviews.manage.Launch Review'/>
        </div>
        <div className='mt3 mb2'>
          <FormattedMessage id="performance_reviews.manage.Please select a date by which all performance reviews should be completed. This date will also be used to trigger automated reminder emails. Once a review is launched it will no longer be editable and reviewers will be able to complete and submit performance reviews." />
        </div>
        <div className='h4 py1 medium'>
          <FormattedMessage id='performance_reviews.manage.Due date'/>
        </div>
        <div className='clearfix pb2 jumbo'>
          <DatePicker
            value={cycle.dueDate}
            onChange={(date) => cycle.merge({dueDate: date})}
            options={{startDate: '0'}}
            className='full-width'
            errorMessage={errors.dueDate}
          />
        </div>
        <div className='h4 py1 medium'>
          <FormattedMessage id='performance_reviews.manage.Launch email preview'/>
        </div>
        <div className='clearfix pb2 jumbo'>
          <FormattedMessage id='performance_reviews.manage.NUMBER_OF_REVIEWERS' values={{reviewerCount: cycle.reviewersCount}}/>
        </div>
        <EmailTemplatePreview template={uiState.emailPreview}/>
        <ModalButtons
          saveCaption='performance_reviews.manage.Launch Review'
          onSave={() => uiState.launchCycle()}
          onCancel={() => uiState.closeLaunchCycleModal()}
          enabled={!uiState.isLaunching}
        />
      </div>
    </Modal>
  );
});

export default LaunchModal;
