import {browserHistory} from 'react-router';
import amplitude from 'amplitude-js';
import {auth} from 'shared/core';
/* global window */
/* global document */

function trackPage() {
  amplitude.getInstance().logEvent('Loaded a Page', {
    'path': location.pathname,
    'referrer': document.referrer,
    'title': document.title,
    'url': location.href
  });
}

const setupAmplitude = () => {
  amplitude.getInstance().init(process.env.AMPLITUDE_API_KEY, null, {
    includeReferrer: true,
    includeUtm: true,
    includeGclid: true
  });

  if (auth.user.id) {
    amplitude.getInstance().setUserId(auth.user.id);

    var userRoles = [];
    if (auth.user.employee) userRoles.push('employee');
    if (auth.user.manager) userRoles.push('manager');
    if (auth.user.admin) userRoles.push('admin');

    var userProperties = {
      anonymous_id: auth.user.anonymous_id,
      role: userRoles,
      name: '',
      email: '',
      company_id: auth.company.id,
      company_name: auth.company.name,
      company_demo_account: auth.company.demoAccount,
      company_onboarded: auth.company.onboardingCompleted,
      company_billing_enabled: auth.company.subscriptionEnabled,
      company_trial_ended: auth.company.trialEnded,
      company_enrolment_only: auth.company.enrolmentOnly,
      benefits_enabled: auth.company.benefitsEnabled,
      payroll_enabled: auth.company.payrollEnabled,
      locale: auth.locale
    };
    amplitude.getInstance().setUserProperties(userProperties);
  }

  trackPage();

  browserHistory.listen(() => {
    trackPage();
  });
};

export {setupAmplitude};
