import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {ReviewRow} from 'components/flow';

const Review = observer(({uiState}) => {
  const {match, cycle} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('performance_reviews.edit.Review your performance review cycle')}
        description={t('performance_reviews.edit.Hover over a section and click edit to make changes.')}
      />
      <ReviewRow
        title='performance_reviews.edit.Review Name'
        value={cycle.name}
        editRoute={`/reviews/${match.params.cycleId}/edit/name`}/>
      <ReviewRow
        title='performance_reviews.edit.Review Type'
        editRoute={`/reviews/${match.params.cycleId}/edit/questions`}
      >
        <ul className='list-reset list-gap-1'>
          {cycle.types.map(type =>
          <li key={type.kind}><FormattedMessage id={`models.performance_reviews.type.${type.kind}`}/></li>)}
        </ul>
      </ReviewRow>
      <ReviewRow
        title='performance_reviews.edit.Participants'
        editRoute={`/reviews/${match.params.cycleId}/edit/participants`}
        lastRow
      >
        <FormattedMessage id='performance_reviews.edit.REVIEWEE_COUNT' values={{reviewees: cycle.reviewees.length}}/>
      </ReviewRow>
    </div>
  );
});

export default Review;
