import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Employment Type',
  'Gender',
  'Location',
  'Manager'
];

const EmployeesByDepartmentReport = () => {
  return (
    <CompositeReport filters={filters}>
      <Report name={t('reports.Employees by Department')}
              view='chart' chartType='pie'
              url='/reports/employees_by_department_chart'>
      </Report>
      <Report
        name={t('reports.Department Demographics')}
        url='/reports/department_demographics'
      />
    </CompositeReport>
  );
};

export default EmployeesByDepartmentReport;
