import React from 'react';
import {NavLink, Route, Switch, Redirect} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {Display, Layout} from 'components';
import {t} from 'shared/core';
import PayrollUpdatesList from './PayrollUpdatesList';

const PayrollUpdatesContainer = () => {
  return (
    <div>
      <Display title={t('payroll_updates.Payroll Updates')}>
        <NavLink activeClassName='active' to='/pending'>
          <FormattedMessage id='payroll_updates.Pending'/>
        </NavLink>
        <NavLink activeClassName='active' to='/completed'>
          <FormattedMessage id='payroll_updates.Completed'/>
        </NavLink>
      </Display>
      <Layout>
        <Switch>
          <Route path='/pending' render={() => <PayrollUpdatesList status='submitted'/>}/>
          <Route path='/completed' render={() => <PayrollUpdatesList status='completed'/>}/>
          <Redirect exact from='/' to='/pending' />
        </Switch>
      </Layout>
    </div>
  );
};


export default PayrollUpdatesContainer;
