import React from 'react';
import Report from '../Report';
import {QuickTip} from 'components';
import CompositeReport from '../CompositeReport';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';

const filters = [
  'Position',
  'Application Date',
  'Position Status'
];

const HiringVelocityReport = () => {
  return (
    <React.Fragment>
      <div className='mb2'>
        <QuickTip
          trait='info'
          header={t('reports.hiring_velocity.Calculating Time-to-hire')}
          paragraphs={[
            <div>
              <FormattedMessage id='reports.hiring_velocity.QUICKTIP_BODY'/>
            </div>
          ]}
        />
      </div>
      <CompositeReport filters={filters}>
        <Report
          view='metric'
          showName={false}
          url='/reports/average_hiring_velocity'
        />
        <Report
          name={t('reports.HIRING_VELOCITY')}
          url='/reports/hiring_velocity'
        />
      </CompositeReport>
    </React.Fragment>
  );
};

export default HiringVelocityReport;
