import React from 'react';
import {observer} from 'mobx-react';

const Panel = observer(({children, className, compact, ...rest}) => {
  return (
    <div className={`Panel ${className} ${compact ? 'compact' : ''}`} {...rest}>
      {children}
    </div>
  );
});

Panel.defaultProps = {
  className: ''
};

// Panels are a uniform component used to wrap pieces of separate content
// It simply renders a div with certain styles and contains a header
// Everything in our app should be wrapped in a Panel component
//
// props:
// - children: all the wrapped content within the Panel
// - className: extra styles typically only used for margin/padding

// Panel Headers are a uniform header style used to style the title text of every Panel
//
// props:
// title - string or React component that shows up as the header of the Panel
// children - React component that appears vertically aligned with the title
//
// notes on title: typically a translated string used with the t() helper function
// notes on children: typically a button that performs a primary
// action like creating a model


export default Panel;
