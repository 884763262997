import {observer} from "mobx-react";
import React from "react";
import Select2 from "components/Select2";

const RegionSelect = observer(({uiState}) => {
  return (
    <Select2 searchable
             value={uiState.regionCode}
             label="components.country_and_region_selects.Region"
             placeholder="components.country_and_region_selects.Select Region"
             onChange={e => uiState.onRegionChange(e.target.value)}
             errorMessage={uiState.errors && uiState.errors.regionCode}>
      {uiState.regions.map((r) =>
        <option key={r.id} value={r.code}>{r.name}</option>)}
    </Select2>
  );
});

export default RegionSelect;
