import React from 'react';
import _ from 'lodash';
import {rangeDate} from 'shared/tools';

const EffectiveDate = ({model}) => {
  if (model.enrolmentStatus === 'not_enrolled' || !model.effectiveDate) return null;

  return <div>{rangeDate(model.effectiveDate)}</div>;
};

export default EffectiveDate;
