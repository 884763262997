import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Breadcrumb from './Breadcrumb';
import SideNavigation from './SideNavigation';
import {Switch, Route, Redirect, withRouter} from 'react-router-dom';
import FlowState from '../state/FlowState';
import StepWithButtons from './StepWithButtons';
import {Layout} from 'components';
import DeleteModal from './DeleteModal';

@observer class Flow extends React.Component {
  @observable uiState;

  componentWillMount() {
    this.uiState = new FlowState({history: this.props.history});
    this.uiState.updateProps(this.props);
  }

  componentWillReceiveProps(props) {
    this.uiState.updateProps(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {uiState} = this;
    const {backTitle, backPath, backRoute, name, steps, component, baseUrl, match, ...rest} = this.props;

    return (
      <div>
        <Breadcrumb name={name} backTitle={backTitle} backPath={backPath} backRoute={backRoute} {...rest}/>
        <Layout condensed>
          <Layout.Section secondary>
            <SideNavigation currentStep={_.get(uiState.activeStep, 'index') || 1} steps={steps}/>
          </Layout.Section>
          <Layout.Section>
            <Switch>
              {steps.all().map(({location, useCustomLayout}) =>
               <Route key={location} path={`${match.path}/${location}`}
                      render={({match}) => <StepWithButtons component={component} match={match} step={location} rest={rest} uiState={uiState} useCustomLayout={useCustomLayout}/>}
               />
              )}
              <Redirect from={baseUrl} to={`${match.url}/${steps.first().location}`}/>
              <Route key='0' path={`${match.path}`} exact
                     render={({match}) => <StepWithButtons component={component} match={match} step={steps.first().location} rest={rest} uiState={uiState} useCustomLayout={steps.first().useCustomLayout}/>}
              />
            </Switch>
           </Layout.Section>
         </Layout>

         <DeleteModal uiState={uiState}/>
      </div>
    );
  }
}

// props:
// note: <Flow/> will use the following props. All other props passed to <Flow/>
// (referred to below as {...rest} props)
// will be passed to the 'name' and 'component' components. e.g.,
// <Flow backRoute='/foo' component={Step} name={Name} uiState={uiState}/>
// will render <Name uiState={uiState}/> and <Step uiState={uiState}/>.

// - name: the name of the model being edited. can be a string or a component.
//   if a component is used, it will receive the {...rest} props.
//   <Flow name={ReportName}/>

// - backTitle: the name of the first element of the Breadcrumb, e.g., 'Reports'.

// - backRoute: a React Router route to go to when user clicks the first element of the Breadcrumb.
//   '/' by default. (renders a <Link/> component from React Router.)

// - backPath: same as backRoute, but uses an absolute path instead.
//   (renders an anchor tag: <a href='/'>.)

// - baseUrl: the base flow url, e.g., /reports/custom/25/edit.
//   the Flow component will automatically redirect to the url of the first step
//   (e.g., /reports/custom/25/edit/details)
//   if navigated to this url.

// - steps: an array of objects {name, location, locations}. name defines the name of the step
//   to be displayed in the navigation. location defines the url. e.g.,
//   {name: 'Details', location: 'details'} with baseUrl set to '/reports/25/edit'
//   will result in a navigation link called Details pointing to '/reports/25/edit/details'.

// - component: the name of the component to render when user navigates to a step.
//   this component will receive the following props:
//   {match, step, ...rest}, where 'match' comes from react router and 'step'
//   is the same as the 'location' value of the currently active step (see steps above).
//   usually it's a factory component:
//   const Step = observer(({step, uiState}) => {
//     switch (step) {
//       case 'foo': return <Foo uiState={uiState};
//       case 'bar': return <Bar uiState={uiState};
//       etc.
//     }
//   })
//   <Foo/> and <Bar/> are components containing your logic and they can receive any
//   props you pass to <Flow/>, in this case, a reference to uiState.

// - onStepSubmitted: an async function (Promise) called when user clicks Continue.
//   if this function returns false, navigation to the next step will be prevented.
//   this function can return an object in the following shape: {nextLocation}
//   if it does, nextLocation will be used to navigate to the next step.

// - onStepChanged: an async function called when the currently active step changes.
//   this function will be called just before the new Step component is mounted.
//   use this callback to perform step-specific initialization.
//   (this is equivalent to defining a componentWillMount in your Step component.)

// - onDeleted: an async function called when the user wants to delete the model.
//   A modal confirming the action will appear and the callback will occur once
//   the user confirms the deletion of the model.

// - localization: an object {back, continue, finish, removeModal} containing translations.
//   all attributes are optional.

// - nextEnabled: flag indicating whether the next button should be enabled. (optional, true by default)
//   use a computed property on your uiState to define any logic around it

Flow.propTypes = {
  steps: PropTypes.any.isRequired,
  component: PropTypes.func.isRequired,
  name: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  backTitle: PropTypes.node.isRequired,
  baseUrl: PropTypes.string.isRequired,
  onStepChanged: PropTypes.func,
  onStepSubmitted: PropTypes.func,
  onDeleted: PropTypes.func,
  localization: PropTypes.object
};

Flow.defaultProps = {
  nextEnabled: true
};

export default withRouter(Flow);
