import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {useEffect} from 'react';
import {$generateNodesFromDOM} from '@lexical/html';
import {$createParagraphNode, $getRoot, $insertNodes, $setSelection} from 'lexical';

export default function LoadStateFromHTMLPlugin(props) {
  const {initialHTML, initialEditorState} = props;

  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!initialHTML) return;
    if (initialEditorState) return;

    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(initialHTML, 'text/html');

      const nodes = $generateNodesFromDOM(editor, dom);

      // The html generated from DraftJS has a couple formations that Lexical chokes on that are fixed by wrapping in paragraph nodes
      // Line breaks are replaced by paragraphs rather than wrapped or else you end up with two empty lines
      const wrappedNodes = nodes.map(node => {
        if (node.getType() === 'linebreak') {
          return $createParagraphNode();
        } else if (node.getType() === 'text' || node.getType() === 'image') {
          const paragraph = $createParagraphNode();
          paragraph.append(node);
          node = paragraph;
        }

        return node;
      });

      $getRoot().select();
      $insertNodes(wrappedNodes);
      $setSelection(null);
    });
  }, []);

  return null;
}
