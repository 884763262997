import React from 'react';
import Select2 from './Select2';
import {observer} from 'mobx-react';
import _ from 'lodash';

/*
 The purpose of this component is to wrap Select2 and allow using a collection of objects as a data source.
 The natural limitation of the <select> and <option> tags is that your values are strings (not objects).
 */

const Options = observer(({items, display, by, groups, groupDisplay, groupBy}) => {
  if (_.isEmpty(groups)) {
    return items.map(item => <option value={_.get(item, by)} key={_.get(item, by)}>{_.get(item, display)}</option>);
  }

  return (
    groups.map(group => (
      <optgroup key={_.get(group, 'id')} label={_.get(group, groupDisplay)}>
        {items.filter(item => _.get(item, groupBy) === group.id).map(item => (
          <option value={_.get(item, by)} key={_.get(item, by)}>{_.get(item, display)}</option>
        ))}
      </optgroup>
    ))
  );
});

@observer class ObjectSelect2 extends React.Component {
  handleChange = (e) => {
    const {multiple, onChange, onAdd, onRemove, items, by} = this.props;

    if (multiple) {
      if (e.action === 'add') {
        onAdd(_.find(items, i => _.get(i, by) === e.value));
      } else if (e.action === 'remove') {
        onRemove(_.find(items, i => _.get(i, by) === e.value));
      }
    } else {
      onChange(_.find(items, i => _.get(i, by) === e.target.value) || null);
    }
  };

  render() {
    const {value, multiple, items, className, display, by, groups, groupDisplay, groupBy, ...rest} = this.props;
    const {handleChange} = this;

    return (
      <Select2
        {...rest}
        value={multiple ? _.map(value, v => _.get(v, by, '')) : _.get(value, by, '')}
        className={`${multiple ? 'select2--multiple' : ''} ${className ? className : ''}`}
        multiple={multiple}
        onChange={handleChange}
      >
        <Options
          items={items}
          display={display}
          by={by}
          groups={groups}
          groupDisplay={groupDisplay}
          groupBy={groupBy}
        />
      </Select2>
    );
  }
}

ObjectSelect2.defaultProps = {
  display: 'name',
  by: 'id',
  allowClear: false,
  groups: [],
  groupDisplay: 'name',
  groupBy: 'groupId',
};

export default ObjectSelect2;
