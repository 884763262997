import React from 'react';
import RecruitingSubnav from 'recruiting/components/RecruitingSubnav';
import RecruitingDirectory from './RecruitingDirectory';

const RecruitingIndex = ({uiState}) => {
  return (
    <div>
      <RecruitingSubnav />
      <div className='Container'>
        <RecruitingDirectory uiState={uiState}/>
      </div>
    </div>
  );
};

export default RecruitingIndex;
