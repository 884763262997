import Annotation from 'stores/documents/Annotation';
import _ from 'lodash';

const TEXT_FIELD_SCALE = 0.3333;

export function fromFabricToModel(fabricAnnotation, canvasWidth, canvasHeight) {
  const annotation = new Annotation({
    top: fabricAnnotation.top / canvasHeight,
    left: fabricAnnotation.left / canvasWidth,
    width: fabricAnnotation.width * fabricAnnotation.scaleX / canvasWidth,
    height: fabricAnnotation.height * fabricAnnotation.scaleY / canvasHeight,
    pageNumber: fabricAnnotation.pageNumber,
    annotationType: fabricAnnotation.descriptor.annotationType,
    state: {
      top: fabricAnnotation.top,
      left: fabricAnnotation.left,
      width: fabricAnnotation.width,
      height: fabricAnnotation.height,
      scaleX: fabricAnnotation.scaleX,
      scaleY: fabricAnnotation.scaleY
    }
  });

  switch (fabricAnnotation.descriptor.annotationType) {
    case 'employee_signature':
      annotation.merge({
        annotationArgs: {
          id: fabricAnnotation.id
        }
      });
      break;
    case 'company_signature':
      annotation.merge({
        annotationArgs: {
          signatureId: fabricAnnotation.descriptor.signatureId
        },
        state: {
          signatureName: fabricAnnotation.descriptor.name,
          signatureData: fabricAnnotation.descriptor.signatureData
        }
      });
      break;
    case 'custom_field':
    case 'variable':
      annotation.merge({
        annotationArgs: {
          name: fabricAnnotation.descriptor.key,
          fontSize: parseInt(fabricAnnotation.fontSize * fabricAnnotation.scaleY),
        },
        state: {
          fontFamily: fabricAnnotation.fontFamily,
          fontSize: fabricAnnotation.fontSize
        }
      });
      break;
    case 'input':
      const fontSize = parseInt(13 * fabricAnnotation.scaleY * (1 / TEXT_FIELD_SCALE));
      const minSize = fabricAnnotation.descriptor.inputType === 'text' ? 10 : 12;

      annotation.merge({
        annotationArgs: {
          fontSize: fontSize < minSize ? minSize : fontSize,
          id: fabricAnnotation.id,
          inputType: fabricAnnotation.descriptor.inputType,
          isRequired: fabricAnnotation.descriptor.isRequired
        },
        state: {
          fontFamily: fabricAnnotation.fontFamily,
          fontSize: fontSize < minSize ? minSize : fontSize
        }
      });
      break;
    default:
      throw new Error(`Annotation type ${fabricAnnotation.descriptor.annotationType} not supported.`);
  }

  return annotation;
}
