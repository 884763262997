import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {DatePicker} from 'components';

const UpdateDueDateModal = observer(({uiState}) => {
  const {dueDate, errors} = uiState;

  return (
    <Modal
      isOpen={uiState.updateDueDateModalOpen}
      onHide={() => uiState.closeUpdateDueDateModal()}
      size='sm'
      title='performance_reviews.view.Update Review Due Date'
    >
      <DatePicker
        className='full-width'
        label='performance_reviews.view.Due Date'
        onChange={date => uiState.setDueDate(date)}
        value={dueDate}
        errorMessage={errors.dueDate}
      />
      <ModalButtons
        saveCaption={'performance_reviews.view.Update'}
        onSave={() => uiState.updateDueDate()}
        onCancel={() => uiState.closeUpdateDueDateModal()}
      />
    </Modal>
  );
});

export default UpdateDueDateModal;
