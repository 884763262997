import React from 'react';
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {auth, loader, t} from 'shared/core';
import {NotFound, DocumentTitle} from 'components';
import RecruitingIndex from './directory/RecruitingIndex';
import RecruitingEditContainer from './edit/RecruitingEditContainer';
import RecruitingState from 'recruiting/state/RecruitingState';
import recruitingStore from 'stores/recruiting/RecruitingStore';
import JobSiteContainer from './job_site/JobSiteContainer';
import CandidateContainer from './candidate/CandidateContainer';
import CandidateDirectoryContainer from './candidate_directory/CandidateDirectoryContainer';
import HiringFunnelPage from './hiring_funnel/HiringFunnelPage';
import EmailTemplateContainer from './email_templates/EmailTemplateContainer';
import EmailTemplateEditContainer from './email_templates/EmailTemplateEditContainer';
import InterviewGuideDirectory from './interview_guides/InterviewGuideDirectory';
import InterviewGuideEditContainer from './interview_guides/InterviewGuideEditContainer';

async function load({history, match}) {
  const uiState = new RecruitingState(recruitingStore, history, match);
  return {match, uiState};
}

const PositionsPage = loader(observer(({uiState, match}) => {
  return (
    <Switch>
      <Route exact path={match.path}
             render={() => <RecruitingIndex uiState={uiState.directoryState} />} />
      <Route exact path={`${match.path}/:id/add-candidate`}
             component={CandidateContainer} />
      <Route path={`${match.path}/:id/funnel/:candidateId/:candidateUrlSlug?`}
             component={HiringFunnelPage} />
      <Route path={`${match.path}/:id/funnel/`}
             component={HiringFunnelPage} />
      {auth.hasAccess('::MANAGE_ATS') && <Route path={`${match.path}/:id`}
             component={RecruitingEditContainer} />}
      <Route render={(props) => <NotFound {...props} title='Recruiting' url='/recruiting' />} />
    </Switch>
  );
}), load);

const EmailTemplatePage = observer(({match}) => {
  return (
    <Switch>
      <Route exact path={match.path} component={EmailTemplateContainer}/>
      <Route path={`${match.path}/:id`} component={EmailTemplateEditContainer}/>
    </Switch>
  );
});

const InterviewGuidePage = observer(({match}) => {
  return (
    <Switch>
      <Route exact path={match.path} component={InterviewGuideDirectory}/>
      <Route path={`${match.path}/edit/:id?`} component={InterviewGuideEditContainer}/>
    </Switch>
  );
});

const RecruitingPage = observer(() => {
  return (
    <DocumentTitle title={t('recruiting.subnav.Recruiting')}>
      <Router basename='/recruiting'>
        <Switch>
          <Route path='/positions' component={PositionsPage} />
          <Route path='/candidates' component={CandidateDirectoryContainer} />
          {auth.hasAccess('::MANAGE_ATS') && <Route path='/email-templates' component={EmailTemplatePage} />}
          {auth.hasAccess('::MANAGE_ATS') && <Route path='/interview-guides' component={InterviewGuidePage} />}
          {auth.hasAccess('::MANAGE_ATS') && <Route path='/job-site' component={JobSiteContainer} />}
          <Redirect exact from='/' to='/positions' />
          <Route render={(props) => <NotFound {...props} title='Recruiting' url='/' />} />
        </Switch>
      </Router>
    </DocumentTitle>
  );
});


export default RecruitingPage;
