import {DomainStore} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {types} from 'shared/core';
import {BenefitClass} from 'stores/benefits';
import _ from 'lodash';

class BenefitClassCoveragesState {
  store = new DomainStore();
  match;
  backPath;
  endpoint;

  @observable currentClass;
  @observable currentCoverage;

  receiveProps({match, backPath, endpoint}) {
    this.match = match;
    this.backPath = backPath;
    this.endpoint = endpoint;
  }

  @action async load() {
    await this.store._compose([
      this.endpoint.with(this.match.params.benefitClassId)
    ]);

    this.currentClass = new BenefitClass(
      this.store._getSingle(types.BENEFIT_CLASS)
    );

    this.currentCoverage = _.head(this.currentClass.sortedGroupBenefitCoverages);
  }

  @action viewCoverage(coverage) {
    this.currentCoverage = coverage;
  }

  @computed get benefitClasses() {
    return [this.currentClass];
  }
}

export default BenefitClassCoveragesState;
