import React from 'react';
import {Modal}    from 'components/modals';
import {observer} from 'mobx-react';
import StartScreen from './StartScreen';
import FinishScreen from './FinishScreen';
import SelectEmployeesScreen from './SelectEmployeesScreen';
import LoadingScreen from './LoadingScreen';
import {FormattedMessage} from 'react-intl';

const ModalContent = observer(({uiState}) => {
  switch (uiState.currentStep) {
    case 'start':
      return <StartScreen uiState={uiState} />;
    case 'loading-google-init':
    case 'loading-google-response':
      return <LoadingScreen uiState={uiState} />;
    case 'select-employees':
      return <SelectEmployeesScreen uiState={uiState} />;
    case 'finish':
      return <FinishScreen uiState={uiState} />;
    default:
      throw new Error(`Unknown state ${uiState.currentStep}.`);
  }
});

const ImportModal = ({uiState}) => {
  return (
    <Modal isOpen={uiState.modalOpen} onHide={() => uiState.cancelImport()}
           size='md' closeButton={false}>
      <div className='h2 mb3 center'>
        <FormattedMessage id='employees.google_import.Import employees from Google'/>
      </div>
      <ModalContent uiState={uiState} />
    </Modal>
  );
};

export default observer(ImportModal);
