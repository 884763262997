import {computed, observable} from 'mobx';
import _ from 'lodash';

class QuestionSummaryViewModel {
  uiState;
  question;
  responses;
  answers;
  @observable periodDate;

  constructor({question, responses, trend, answers, uiState, periodDate}) {
    this.question = question;
    this.responses = responses;
    this.answers = _.filter(answers, {question: {id: this.question.id}});
    this.trend = _.sortBy(trend[this.question.id], 'date');
    this.uiState = uiState;
    this.periodDate = periodDate;
  }

  @computed get currentTrend() {
    return _.find(this.trend, (period) => period.date === this.periodDate);
  }
}

export default QuestionSummaryViewModel;
