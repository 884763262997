import gapi from 'gapi-client';

class GoogleDirectoryApi {
  onUsersReceived;
  onError;

  constructor({onUsersReceived, onError}) {
    this.onUsersReceived = onUsersReceived;
    this.onError = onError;
  }

  load() {
    return new Promise((resolve) => {
      gapi.load('client:auth2', () => {
        this.initClient(resolve);
      });
    });
  }

  initClient(resolve) {
    gapi.client.init({
      apiKey: process.env.GOOGLE_API_KEY,
      clientId: process.env.GOOGLE_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/admin.directory.user.readonly'
    }).then(
      () => {
        this.auth.isSignedIn.listen(isSignedIn => this.updateSigninStatus(isSignedIn));
        this.updateSigninStatus(this.auth.isSignedIn.get());
        resolve(this.auth.isSignedIn.get());
      },
      (reason) => {
        this.onError(reason);
      }
    );
  }

  get auth() {
    return gapi.auth2.getAuthInstance();
  }

  getCurrentUser() {
    try {
      if (this.auth.isSignedIn) {
        return {
          name: this.auth.currentUser.get().getBasicProfile().getName(),
          email: this.auth.currentUser.get().getBasicProfile().getEmail()
        };
      }
    } catch (e) {
      return null;
    }
  }

  async updateSigninStatus(isSignedIn) {
    if (isSignedIn) {
      await this.fetchUsersFromGoogle();
    }
  }

  async signIn() {
    try {
      await this.auth.signIn();
    } catch (e) {
      this.onError(e);
    }
  }

  signOut() {
    this.auth.signOut();
  }

  async fetchUsersFromGoogle() {
    try {
      const response = await gapi.client.request({
        path: 'https://www.googleapis.com/admin/directory/v1/users',
        params: {
          customer: 'my_customer',
          viewType: 'domain_public',
          orderBy: 'givenName'
        }
      });
      this.onUsersReceived(response);
    } catch (e) {
      this.disconnect();
      this.onError(e);
    }
  }

  async disconnect() {
    try {
      this.auth.disconnect();
    } catch (e) {
      this.onError(e);
    }
  }
}

export default GoogleDirectoryApi;
