import React from 'react';
import {observer} from 'mobx-react';
import {VideoPlayer, Panel, UploadFiles, Input, FormError, Button} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const Documents = observer(({uiState}) => {
  const {previewingVideo, trainingProgram, isUploading, errors} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('training.edit.Training Documents')}
        description={t('training.edit.Upload documents related to the training program that employees can view and download.')}
      />
      <UploadFiles
        defaultFiles={trainingProgram.attachedFiles}
        onChange={(files) => trainingProgram.merge({attachedFiles: files})}
        onUploadStarted={() => uiState.onUploadStarted()}
        onUploadFinished={() => uiState.onUploadFinished()}
        errorMessage={errors.attachedFiles}
        isUploading={isUploading}
        modelType='training/program'
      />
      <React.Fragment>
        <Panel.Header
          title={t('training.edit.Training Video')}
          description={t('training.edit.Enter the url of a video related to the training program that employees will see under the program description.')}
        />
        <div className='flex'>
          <div className='flex-grow mr2'>
            <Input
              value={trainingProgram.videoUrl}
              onChange={e => uiState.changeVideoUrl(e.target.value)}
            />
          </div>
          <Button trait='default' onClick={() => uiState.displayVideoPreview()}>
            <FormattedMessage id='training.edit.Preview'/>
          </Button>
        </div>
        {previewingVideo && <VideoPlayer className='mt2' url={trainingProgram.videoUrl}/>}
        <FormError message={errors.video}/>
      </React.Fragment>
    </div>
  );
});

export default Documents;
