import React from 'react';
import {observer} from 'mobx-react';
import {types, auth} from 'shared/core';
import {InteractiveTable, DropdownFilter, ProgressIndicator} from 'components';
import {FormattedMessage} from 'react-intl';
import {Goal} from 'stores/performance_goals';
import {endpoints, t} from 'shared/core';
import {dateSpan} from 'shared/tools';

const GoalItem = observer(({model}) => {
  const goal = model;

  return (
    <div className='mxn2 px2'>
      <div className='pb1'>
        <span className='h3 medium black'>{goal.title}</span>
      </div>
      <div className='pb1'>
        <div className='table-cell jumbo pr1'>
          {`${goal.completeness}%`}
        </div>
        <div className='table-cell full-width'>
          <ProgressIndicator items={goal.progressBarItems} />
        </div>
      </div>
      <div className='jumbo'>
        {dateSpan(goal.startDate, goal.endDate, true)}
      </div>
    </div>
  );
});

const ViewAllLink = observer(({employee}) => {
  const goalsLink = employee.id === auth.employee.id ?
                      `/goals/my-goals` :
                      `/goals/?f-goal_employee=${employee.id}`;

  return (
    <a href={goalsLink} target='_blank'>
      <FormattedMessage id='performance_reviews.view.goals.View all'/>
    </a>
  );
});

const COLUMNS = [
  {
    component: GoalItem,
    width: 12
  }
];

const LOCALIZATION = {
  emptyState: 'performance_reviews.view.goals.No goals to display'
};

const GoalList = observer(({uiState}) => {
  const {reviewee, goalTimePeriodOptions} = uiState;

  return (
    <React.Fragment>
      <InteractiveTable
        title={t('performance_reviews.view.goals.EMPLOYEES_GOALS', {employeeName: reviewee.employee.name})}
        columns={COLUMNS}
        localization={LOCALIZATION}
        tableActionButtons={<ViewAllLink employee={reviewee.employee}/>}
        onRowClicked={model => uiState.goToGoal(model)}
        showLinks={false}
        showHeaders={false}
        proxy={{
          type: 'async',
          modelType: types.PERFORMANCE_GOALS.GOAL,
          model: Goal,
          endpoint: endpoints.PERFORMANCE_GOALS.GOALS_FOR_EMPLOYEE_REVIEWS.with(reviewee.employee.id),
          pageSize: 3
        }}
        initialFilter={{
          time_period: goalTimePeriodOptions[1].id
        }}
      >
        <DropdownFilter attr='time_period' defaultFilter={1} options={goalTimePeriodOptions}/>
      </InteractiveTable>
    </React.Fragment>
  );
});

export default GoalList;
