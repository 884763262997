import React from 'react';
import {observer} from 'mobx-react';
import {Panel, FormLayout, Input, Select2, ObjectSelect2, CheckboxWithLabel} from 'components';
import EMPLOYMENT_TYPES from 'stores/employees/employmentTypes';
import {t, auth} from 'shared/core';

const EmploymentDetails = observer(({uiState}) => {
  const record = uiState.editRecord;
  const {parentState, errors, skipHoursPerWeek} = uiState;
  if (!parentState.editDataPermission('::ROLE_INFORMATION')) return null;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.role.Employment Details')}/>
      <FormLayout>
        <Select2
          label='employees.profile.role.Employment Type'
          value={record.employmentType}
          onChange={e => uiState.updateEmploymentType(e.target.value)}
        >
          {EMPLOYMENT_TYPES.map(e => <option key={e} value={e}>{t(e)}</option>)}
        </Select2>
        <Input
          label='employees.profile.role.Job Title'
          value={record.jobTitle}
          onChange={(e) => record.merge({jobTitle: e.target.value})}
          errorMessage={errors.jobTitle}
        />
        <FormLayout.Group>
          <ObjectSelect2
            label='employees.profile.role.Location'
            value={record.location}
            onChange={value => uiState.updateLocation(value)}
            items={uiState.locationsWithDeleted}
            placeholder='employees.profile.role.Specify a location' errorMessage={errors.location}
          />
          {auth.moduleEnabled('directory') &&
            <ObjectSelect2
              label='employees.profile.role.Department'
              value={record.department}
              onChange={value => uiState.updateDepartment(value)}
              items={uiState.departmentsWithDeleted}
              placeholder='employees.profile.role.Specify a department'
              allowClear={true}
              errorMessage={errors.department}
            />
          }
        </FormLayout.Group>
        {auth.moduleEnabled('directory') &&
          <ObjectSelect2
            label='employees.profile.role.Manager'
            value={record.manager}
            searchable={true}
            onChange={value => record.merge({manager: value})}
            items={uiState.availableManagers}
            allowClear={true}
            placeholder='employees.profile.role.Specify a manager' errorMessage={errors.manager}
          />
        }
        <Input
          label='employees.profile.role.Hours worked per week'
          helpLink={{
            explanation: {
              header: 'employees.profile.role.hours_per_week_explanation_header',
              paragraphs: [
                t('employees.profile.role.hours_per_week_explanation_1', {employee: record.name}),
                t('employees.profile.role.hours_per_week_explanation_2')
              ]
            }
          }}
          value={record.hoursPerWeek}
          onChange={e => record.merge({hoursPerWeek: e.target.value})}
          errorMessage={errors.hoursPerWeek}
          disabled={skipHoursPerWeek}
        />
        <CheckboxWithLabel
          checked={skipHoursPerWeek}
          onChange={e => uiState.toggleSkipHoursPerWeek(e.checked)}
          label='employees.profile.role.This employee does not work a set number of hours per week'
        />
      </FormLayout>
    </Panel>
  );
});

export default EmploymentDetails;
