import React from 'react';
import {Modal, ModalButtons} from 'components/modals';
import {FormLayout, Input} from 'components';
import {observer} from 'mobx-react';

const EditCompanyFileFolderModal = observer(({modalOpen, uiState}) => {
  const {selectedCompanyFileFolder, errors} = uiState;

  const header = selectedCompanyFileFolder.isNew ? 'company_settings.company_files.Add a Company File Folder' : 'company_settings.company_files.Edit Company File Folder';

  return (
    <Modal
      isOpen={modalOpen}
      onHide={() => uiState.closeEditCompanyFileFolderModal()}
      size='md'
      title={header}
    >
      <FormLayout>
        <Input
          label='company_settings.company_files.Name:'
          value={selectedCompanyFileFolder.name}
          onChange={e => selectedCompanyFileFolder.name = e.target.value}
          placeholder='company_settings.company_files.e.g. Company Handbooks'
          errorMessage={errors.name}
        />
      </FormLayout>
      <ModalButtons
        onSave={() => uiState.saveCompanyFileFolder()}
        saveCaption='company_settings.company_files.Save'
        onCancel={() => uiState.closeEditCompanyFileFolderModal()}
        cancelCaption='company_settings.company_files.Cancel'
      />
    </Modal>
  );
});

export default EditCompanyFileFolderModal;
