import React from 'react';
import AddAllEnabledIcon from 'img/add_all_arrow_enabled@2x.png';
import AddAllDisabledIcon from 'img/add_all_arrow_disabled@2x.png';
import RemoveAllEnabledIcon from 'img/remove_all_arrow_enabled@2x.png';
import RemoveAllDisabledIcon from 'img/remove_all_arrow_disabled@2x.png';
import AddEnabledIcon from 'img/add_arrow_enabled@2x.png';
import AddDisabledIcon from 'img/add_arrow_disabled@2x.png';
import RemoveEnabledIcon from 'img/remove_arrow_enabled@2x.png';
import RemoveDisabledIcon from 'img/remove_arrow_disabled@2x.png';

const TransferButtons = ({ uiState, canAddAll, canRemoveAll, canAdd, canRemove}) => {
  return (
    <div>
      <div className='my1'>
        <button
          type="button"
          className="lil-square circle p0 border bg-none"
          onClick={() => uiState.addAllEmployees()}
          disabled={!canAddAll}
        >
          <img
            src={canAddAll ? AddAllEnabledIcon : AddAllDisabledIcon}
            alt='add_all_button'
            width="38"
          />
        </button>
      </div>
      <div className='my1'>
        <button
          type="button"
          className="lil-square circle p0 border bg-none"
          onClick={() => uiState.addEmployees()}
          disabled={!canAdd}
        >
          <img
            src={canAdd ? AddEnabledIcon : AddDisabledIcon}
            alt='add_selected_button'
            width="38"
          />
        </button>
      </div>
      <div className='my1'>
        <button
          type="button"
          className="lil-square circle p0 border bg-none"
          onClick={() => uiState.removeEmployees()}
          disabled={!canRemove}
        >
          <img
            src={canRemove ? RemoveEnabledIcon : RemoveDisabledIcon}
            alt='remove_selected_button'
            width="38"
          />
        </button>
      </div>
      <div className='my1'>
        <button
          type="button"
          className="lil-square circle p0 border bg-none"
          onClick={() => uiState.removeAllEmployees()}
          disabled={!canRemoveAll}
        >
          <img
            src={canRemoveAll ? RemoveAllEnabledIcon : RemoveAllDisabledIcon}
            alt='remove_all_button'
            width="38"
          />
        </button>
      </div>
    </div>
  );
};

export default TransferButtons;
