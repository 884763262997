import {observable} from 'mobx';
import {DomainObject} from 'shared/store';
import _ from 'lodash';

class Presigner extends DomainObject {
  @observable id;
  @observable fields;
  @observable url;

  getAwsRequestParams(file) {
    const data = new FormData();

    const url = this.url;

    const headers = {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment; filename=' + encodeURI(file.name)
    };

    _.toPairs(this.fields).forEach(([key, value]) => { data.append(key, value); });
    data.append('file', file);

    return { url, data, headers };
  }
}

export default Presigner;
