import React from 'react';
import {observer} from 'mobx-react';
import {auth, t} from 'shared/core';
import {FormLayout, Input} from 'components';

const placeholderText = () => {
  if (auth.moduleEnabled('directory')) {
    return 'onboard.employee_info.Required';
  } else {
    return 'onboard.employee_info.Optional';
  }
};

const helpLink = () => {
  if (auth.moduleEnabled('directory')) return null;

  return {
    title: t('onboard.employee_info.Why do we collect this?'),
    explanation: {
      header: t('onboard.employee_info.Why do we collect this?'),
      paragraphs: [t('onboard.employee_info.personal_email_explainer')]
    }
  };
};

const OnboardEmployeeContactForm = observer(({uiState}) => {
  const {employee, errors} = uiState;

  return (
    <FormLayout>
      <FormLayout.Group>
        <Input errorMessage={errors.personalEmail}
          label='onboard.employee_info.Personal Email'
          value={employee.personalEmail} 
          onChange={(e) => employee.merge({personalEmail: e.target.value})}
          placeholder='onboard.employee_info.Required' 
          helpLink={helpLink()}
        />
        <Input errorMessage={errors.personalPhone}
          label='onboard.employee_info.Personal Phone'
          value={employee.personalPhone} 
          onChange={(e) => employee.merge({personalPhone: e.target.value})}
          placeholder={placeholderText()}/>
      </FormLayout.Group>
    </FormLayout>
  );
});

export default OnboardEmployeeContactForm;
