import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import useScrollShadows from '../hooks/useScrollShadows';

function sortingClassName(headerViewModel) {
  if (!headerViewModel.sortingAllowed) return '';
  switch (headerViewModel.sort) {
    case 'asc': return 'sorting_asc';
    case 'desc': return 'sorting_desc';
    default: return 'sorting';
  }
}

const TableHeaders = observer(({showLinks, horizontalScroll, containerRef, uiState}) => {
  const [
    stickyColumnRef,
    showShadow,
    actionLinksRef,
    showActionLinksShadow
  ] = useScrollShadows(horizontalScroll, containerRef);

  return (
    <tr>
      {uiState.headers.map((h, index) => (
        <th
          key={index}
          className={`${h.column.className || ''} ${sortingClassName(h)} ${h.column.sticky ? 'sticky-column' : ''} ${h.column.sticky && showShadow ? 'sticky-shadow' : ''}`}
          style={horizontalScroll ? {minWidth: `${h.column.width * 5}rem`} : {width: `${(h.column.width / 12) * 100}%`}}
          ref={h.column.sticky ? stickyColumnRef : null}
        >
          <span onClick={() => uiState.clickHeader(h)}>
            {h.column.header ? <FormattedMessage id={h.column.header}/> : <span>&nbsp;</span>}
          </span>
        </th>
      ))}
      {showLinks && <th
        className={`${horizontalScroll ? 'action-links-column' : ''} ${horizontalScroll && showActionLinksShadow ? 'sticky-shadow' : ''}`}
        ref={actionLinksRef}
      >
        &nbsp;
      </th>}
    </tr>
  );
});

export default TableHeaders;
