import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class DataPermission extends DomainObject {
  @observable id;
  @observable name;
  @observable descriptions;
  @observable accessLevel = 'no_access';
}

export default DataPermission;
