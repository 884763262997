import React from 'react';
import SlackIcon from 'img/integrations/Integration-Slack@2x.jpg';
import OktaLogo from 'img/okta-logo@2x.png';
import GoogleLogo from 'img/google-logo@2x.png';
import MicrosoftLogo from 'img/microsoft-logo@2x.png';
import JazzHRIcon from 'img/integrations/Integration-JazzHR@2x.jpg';
import GreenhouseIcon from 'img/integrations/Integration-Greenhouse@2x.png';
import IndeedIcon from 'img/integrations/Integration-Indeed@2x.png';

const POPULAR_APPS = [
  {
    title: 'Slack',
    appDescription: 'integrations.native_integrations.slack.APP_INFO',
    integrationDescription: 'integrations.native_integrations.slack.INTEGRATION_INFO',
    appLink: <a href='https://slack.com' target='_blank' className='TextLink'>{'Slack'}</a>,
    url: '/company_settings/apps/slack',
    image: SlackIcon
  },
  {
    title: 'Okta',
    appDescription: 'integrations.native_integrations.okta.APP_INFO',
    integrationDescription: 'integrations.native_integrations.okta.INTEGRATION_INFO',
    appLink: <a href='https://okta.com/products/single-sign-on/' target='_blank' className='TextLink'>{'Okta'}</a>,
    url: '/company_settings/apps/okta',
    image: OktaLogo
  },
  {
    title: 'Google SSO',
    appDescription: 'integrations.native_integrations.google.APP_INFO',
    integrationDescription: 'integrations.native_integrations.google.INTEGRATION_INFO',
    url: '/company_settings/apps/google',
    image: GoogleLogo
  },
  {
    title: 'Microsoft SSO',
    appDescription: 'integrations.native_integrations.microsoft.APP_INFO',
    integrationDescription: 'integrations.native_integrations.microsoft.INTEGRATION_INFO',
    url: '/company_settings/apps/microsoft',
    image: MicrosoftLogo
  },
  {
    title: 'JazzHR',
    appDescription: 'integrations.native_integrations.jazz_hr.APP_INFO',
    integrationDescription: 'integrations.native_integrations.jazz_hr.INTEGRATION_INFO',
    appLink: <a href='https://jazzhr.com/' target='_blank' className='TextLink'>{'JazzHR'}</a>,
    url: '/company_settings/apps/jazz_hr',
    image: JazzHRIcon
  },
  {
    title: 'Greenhouse',
    appDescription: 'integrations.native_integrations.greenhouse.APP_INFO',
    integrationDescription: 'integrations.native_integrations.greenhouse.INTEGRATION_INFO',
    appLink: <a href='https://greenhouse.com/' target='_blank' className='TextLink'>{'Greenhouse'}</a>,
    url: '/company_settings/apps/greenhouse',
    image: GreenhouseIcon
  },
  {
    title: 'Indeed',
    appDescription: 'integrations.native_integrations.indeed.APP_INFO',
    integrationDescription: 'integrations.native_integrations.indeed.INTEGRATION_INFO',
    appLink: <a href='https://indeed.com' target='_blank' className='TextLink'>{'Indeed'}</a>,
    url: '/recruiting/job-site',
    image: IndeedIcon
  },
  {
    title: 'Deputy',
    url: 'https://zapier.com/apps/collage-hr/integrations/deputy',
    image: 'https://zapier-images.imgix.net/storage/services/c4bf55b302e68e9e18864f008c50c7ce.png?auto=format%2Ccompress&fit=crop&h=64&ixlib=python-3.0.0&q=50&w=64',
    zapier: true
  },
  {
    title: 'Harvest',
    url: 'https://zapier.com/apps/collage-hr/integrations/harvest',
    image: 'https://zapier-images.imgix.net/storage/developer_cli/68a58cace89cf0a07ebfe7ae964c2b80.png?auto=format%2Ccompress&fit=crop&h=64&ixlib=python-3.0.0&q=50&w=64',
    zapier: true
  },
  {
    title: 'When I Work',
    url: 'https://zapier.com/apps/collage-hr/integrations/when-i-work',
    image: 'https://zapier-images.imgix.net/storage/developer_cli/3032bcec3d507c920dd2f091b9811598.png?auto=format%2Ccompress&fit=crop&h=64&ixlib=python-3.0.0&q=50&w=64',
    zapier: true
  },
  {
    title: 'Airtable',
    url: 'https://zapier.com/apps/collage-hr/integrations/airtable',
    image: 'https://zapier-images.imgix.net/storage/developer/c6c8c5e300ef0da0e47b3084e5522f20.png?auto=format%2Ccompress&fit=crop&h=64&ixlib=python-3.0.0&q=50&w=64',
    zapier: true
  },
  {
    title: 'monday.com',
    url: 'https://zapier.com/apps/collage-hr/integrations/monday',
    image: 'https://zapier-images.imgix.net/storage/developer/2663f19cb1a591e113356c9ba376a567.png?auto=format%2Ccompress&fit=crop&h=64&ixlib=python-3.0.0&q=50&w=64',
    zapier: true
  }
];

export default POPULAR_APPS;
