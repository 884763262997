import React from 'react';
import {observer} from 'mobx-react';
import {InteractiveTable} from 'components';
import {BenefitUpdate} from 'stores/benefits';
import {types, endpoints, t} from 'shared/core';
import RecentActivityItem from './RecentActivityItem';

const COLUMNS = [
  {
    component: RecentActivityItem,
    width: 12
  }
];

const RecentActivity = observer(({uiState}) => {
  const {hideProfileLink} = uiState;

  return (
    <InteractiveTable
      title={t('pa_dashboard.Recent Activity')}
      columns={COLUMNS}
      showLinks={false}
      searchable={false}
      showHeaders={false}
      hidePaginationInfo
      hideProfileLink={hideProfileLink}
      proxy={{
        type: 'async',
        modelType: types.BENEFITS.UPDATE,
        model: BenefitUpdate,
        endpoint: endpoints.BENEFITS.UPDATES,
        pageSize: 10
      }}
    />
  );
});

export default RecentActivity;
