import _ from 'lodash';
import moment from 'moment';
import {computed, observable, toJS} from 'mobx';

class CheckInSummary {
  @observable selected = false;
  @observable completedAt;

  constructor(checkin) {
    this.merge(checkin);
  }

  @computed get date() {
    return moment(this.createdAt).format('MMMM Do, YYYY');
  }

  @computed get completed() {
    return !!this.completedAt;
  }

  merge(partialCheckin) {
    _.merge(this, partialCheckin);
  }

  toJS() {
    return toJS(this);
  }
}

export default CheckInSummary;
