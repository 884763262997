import {observable, action, computed} from 'mobx';
import _ from 'lodash';
import {DomainStore} from 'shared/store';
import {types, endpoints, auth} from 'shared/core';
import CustomReport from 'stores/reports/CustomReport';
import CustomReportShare from 'stores/reports/CustomReportShare';
import {User} from 'stores/users';

class CustomReportIndexState {
  history;
  agent;
  store = new DomainStore();

  @observable shareReportModalOpen = false;
  @observable selectedReport;
  @observable users = [];

  receiveProps({history}) {
    this.history = history;
  }

  @action async load() {
    await this.store._compose([
      endpoints.USERS.ACTIVE
    ]);

    this.users = _.reject(this.store._getAll(types.USER).map(user => new User(user)), {id: auth.user.id});
  }

  @action async createNewReport() {
    const {model} = await this.store.post(
      endpoints.CUSTOM_REPORTS.ALL,
      types.CUSTOM_REPORT
    );

    this.history.push(`/custom/${model.id}/edit`);
  }

  @action openShareReportModal(report) {
    this.selectedReport = new CustomReport(report);
    this.shareReportModalOpen = true;
  }

  @action closeShareReportModal() {
    this.shareReportModalOpen = false;
    this.selectedReport = null;
  }

  @action setInteractiveAgent(agent) {
    this.agent = agent;
  }

  @action async shareReport() {
    await this.store.patch(
      this.selectedReport.link('share'),
      types.CUSTOM_REPORT,
      this.selectedReport
    );

    this.closeShareReportModal();
    await this.agent.refresh();
  }

  @action async deleteReport(report) {
    await this.store.destroy(report);
    await this.agent.refresh();
  }

  @computed get availableShares() {
    const all = this.users.map(user => new CustomReportShare({user}));
    return _.reject(all, share => _.includes(this.selectedReport.shares, share.user.id));
  }

  customLinksFor(report) {
    if (!report.hasLink('share')) return [];

    return [
      {
        order: 1,
        text: 'reports.custom.Share',
        action: model => this.openShareReportModal(model)
      }
    ];
  }
}

export default CustomReportIndexState;
