import React from 'react';
import {observer} from 'mobx-react';
import useModal from 'shared/hooks/useModal';
import {withState} from 'shared/core';
import {Layout, Panel} from 'components';
import {TimeTrackingSubnav} from 'containers/time_tracking';
import PolicyDirectory from './PolicyDirectory';
import PayScheduleDirectory from './PayScheduleDirectory';
import ProjectDirectory from './ProjectDirectory';
import EditPayScheduleModal from './EditPayScheduleModal';
import EditProjectModal from './EditProjectModal';
import {ManageContainerState} from '../state';
import ArchiveProjectModal from './ArchiveProjectModal';
import UnarchiveProjectModal from './UnarchiveProjectModal';
import {TimeTrackingProject} from 'stores/time_tracking';

const ManageContainer = observer(({scope, uiState}) => {
  const archiveProjectModalOpen = useModal(uiState, 'ArchiveProjectModal', 'editingProject', TimeTrackingProject, 'errors');
  const unarchiveProjectModalOpen = useModal(uiState, 'UnarchiveProjectModal', 'editingProject', TimeTrackingProject, 'errors');
  const editProjectModalOpen = useModal(uiState, 'EditProjectModal', 'editingProject', TimeTrackingProject, 'errors');

  return (
    <div>
      <TimeTrackingSubnav scope={scope}/>
      <Layout>
        <Panel.Stack loose>
          <PolicyDirectory uiState={uiState}/>
          <PayScheduleDirectory uiState={uiState}/>
          <ProjectDirectory uiState={uiState}/>
        </Panel.Stack>
      </Layout>
      <EditPayScheduleModal uiState={uiState}/>
      <EditProjectModal uiState={uiState} modalOpen={editProjectModalOpen}/>
      <ArchiveProjectModal uiState={uiState} modalOpen={archiveProjectModalOpen}/>
      <UnarchiveProjectModal uiState={uiState} modalOpen={unarchiveProjectModalOpen}/>
    </div>
  );
});

export default withState(ManageContainer, ManageContainerState);
