import React from 'react';

const layout = {
  childrenCount: 1,
  centered: false,
  condensed: false
};

const LayoutContext = React.createContext(layout);

export default LayoutContext;
