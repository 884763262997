import {DomainObject} from 'shared/store';
import {action} from 'mobx';
import User from 'stores/users/User';
import _ from 'lodash';

class TimeOffRequestAssignment extends DomainObject {
  @action merge(other) {
    super.merge(other, {
      assigneeUser: User,
      delegatedByUser: User
    });
  }

}

export default TimeOffRequestAssignment;
