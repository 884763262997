import React from 'react';
import {observer} from 'mobx-react';
import {Panel, List, Spinner} from 'components';
import {calendarDate} from 'shared/tools';
import {t} from 'shared/core';
import EmptyState from './RequestEmptyState';

const Usage = observer(({uiState}) => {
  const {request, usageItems, forecastingAccount} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.time_off.request.USAGE', {timeOffType: request.timeOffType.name})}/>
      {request.hasValidDates && <div>
        <Panel.Subheader title={t('employees.profile.time_off.request.AS_OF', {date: calendarDate(request.startDate)})}/>
        {!forecastingAccount &&
          <List
            items={usageItems}
          />
        }
        {forecastingAccount && <Spinner/>}
      </div>}
      {!request.hasValidDates && <EmptyState message='employees.profile.time_off.request.Enter a valid start date and end date to see usage information.'/>}
    </Panel>
  );
});

export default Usage;
