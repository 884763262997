import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {calendarDate} from 'shared/tools';

const ChangedByComment = observer(({record}) => {
  if (!record.hasComment) return null;

  return (
    <div>
      <div className='pt2 waterloo'>
        <FormattedMessage id='employees.profile.role.Comment'/>
      </div>
      <div className='jumbo'>
        {record.comment}
      </div>
    </div>
  );
});

const ChangedBy = observer(({viewModel}) => {
  const {record} = viewModel;
  if (!viewModel.hasChangedBySection) return null;

  return (
    <div className='col col-4'>
      <div className='pr2 h5'>
        <div className='waterloo'>
          <FormattedMessage id={viewModel.changedByTitle}/>
        </div>
        <div className='jumbo'>
          <FormattedMessage
            id='employees.profile.role.CHANGED_BY_SUMMARY'
            values={{
              date: calendarDate(record.updatedAt),
              user: record.author.name
            }}
          />
        </div>
        <ChangedByComment record={record}/>
      </div>
    </div>
  );
});

export default ChangedBy;
