import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {ActionLink, Panel} from 'components';
import BankingChequeHelperModal from './BankingChequeHelperModal';
import EditBankingInfoModal from './EditBankingInfoModal';
import {t} from 'shared/core';
import EmployeeProfileContainer from '../../EmployeeProfileContainer';
import SinInfo from './SinInfo';
import BankingInfo from './BankingInfo';

const ToggleBankingInfoButton = observer(({uiState}) => {
  if (uiState.bankingInformationHidden) {
    return (
      <ActionLink onClick={() => uiState.showBankingInformation()}>
          <FormattedMessage id='employees.profile.banking.Show'/>
      </ActionLink>
    );
  }

  return (
    <ActionLink onClick={() => uiState.hideBankingInformation()}>
        <FormattedMessage id='employees.profile.banking.Hide'/>
    </ActionLink>
  );
});

const BankingContainer = observer(({uiState}) => {
  return (
    <EmployeeProfileContainer uiState={uiState}>
      <div className='fs-hide'>
        <Panel.Stack loose>
          <Panel>
            <Panel.Header title={t('employees.profile.banking.Banking Information')}>
              <ToggleBankingInfoButton uiState={uiState}/>
              {uiState.editDataPermission('::BANKING_INFORMATION') &&
                <ActionLink onClick={() => uiState.openBankingInfoModal()} className='ml2'>
                  <FormattedMessage id='employees.profile.banking.Edit'/>
                </ActionLink>}
            </Panel.Header>
            <SinInfo uiState={uiState}/>
            <BankingInfo uiState={uiState}/>
          </Panel>
          <Panel>
            <Panel.Header title={t('employees.profile.banking.Please Note')}/>
            <div className='pb1 jumbo'>
              <FormattedMessage id='employees.profile.banking.We collect your banking info so that we can easily add you to payroll or other services that require direct deposit.'/>
            </div>
            <div className='pt1 jumbo'>
              <FormattedMessage id="employees.profile.banking.If your payroll or banking information changes, enter your new information here and we'll make sure payroll is updated."/>
            </div>
          </Panel>
        </Panel.Stack>
        <BankingChequeHelperModal uiState={uiState} />
        <EditBankingInfoModal uiState={uiState} />
      </div>
    </EmployeeProfileContainer>
  );
});

export default BankingContainer;
