import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Department',
  'Employment Type',
  'Gender',
  'Location',
  'Manager'
];

const AgeDemographicsReport = ({showDepartmentReport}) => {
  return (
    <CompositeReport filters={filters}>
      <Report name={t('reports.Age Demographics')}
              view='chart'
              url='/reports/age_demographics_chart'>
      </Report>
      {showDepartmentReport &&
        <Report name={t('reports.Age Demographics by Department')}
                view='chart'
                options={{
                  stacking: 'normal'
                }}
                url='/reports/age_by_department_chart'>
        </Report>}
      <Report
        name={t('reports.Age Demographics')}
        url='/reports/age_demographics'
      />
    </CompositeReport>
  );
};

export default AgeDemographicsReport;
