import _ from 'lodash';
import {Question} from 'stores/performance/questions';
import {action} from 'mobx';

export default class UiState {
  @action moveQuestion(cycle, dragIndex, hoverIndex) {
    const dragQuestion = cycle.orderedQuestions[dragIndex - 1];
    const hoverQuestion = cycle.orderedQuestions[hoverIndex - 1];

    dragQuestion.order = hoverIndex;
    hoverQuestion.order = dragIndex;
  }

  @action addQuestion(cycle) {
    cycle.questions.push(new Question({
      order: cycle.orderedQuestions.length + 1,
      text: ''
    }));
  }

  @action deleteQuestion(questions, question) {
    _.remove(questions, question);

    const laterQuestions = _.filter(questions, q => q.order > question.order);
    for (const q of laterQuestions) {
      q.order -= 1;
    }
  }
}
