import React from 'react';
import {observer} from 'mobx-react';
import {auth, t} from 'shared/core';
import {Panel, Button} from 'components';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import SlackLogo from 'img/slack-logo@2x.png';

const ConnectAccount = observer(({uiState}) => {
  return (
    <div>
      <Panel.Header
        title={t('user_settings.slack.Slack')}
        description={t('user_settings.slack.connect_slack_explainer')}
      />
      <form action='/slack/connect_user' method='post'>
        <input type='hidden' name='context' value='user'/>
        <input type='hidden' name='authenticity_token' value={uiState.slackAuthenticityToken}/>
        <button type='submit' className='Btn Btn--lg Btn--default mt2'>
          <span className='Btn--content '>
          <img src={SlackLogo} alt='logo' height='18' className='inline-block' style={{margin: '0 24px 0 0'}}/>
            {t('user_settings.slack.Connect to Slack')}
          </span>
        </button>
      </form>
    </div>
  );
});

const DisconnectInstructionsModal = observer(({uiState}) => {
  if (!uiState.modalOpen) return null;

  return (
    <Modal
      size='md'
      isOpen={uiState.modalOpen}
      onHide={() => uiState.hideModal()}
      title={`user_settings.slack.disconnect_explainer`}
    >
      <FormattedMessage id={`user_settings.slack.disconnect_instructions`}/>
      <ModalButtons
        saveCaption='user_settings.slack.Disconnect'
        onCancel={() => uiState.hideModal()}
        onSave={() => uiState.disconnectSlackAccount()}
      />
    </Modal>
  );
});

const DisconnectAccount = observer(({uiState}) => {
  return (
    <div>
      <Panel.Header
        title={t('user_settings.slack.Slack')}
        description={
          <FormattedMessage id={`user_settings.slack.connected_explainer`}/>
        }
      />
      <Button onClick={() => uiState.showModal()} trait='default'>
        <FormattedMessage id='user_settings.slack.Disconnect'/>
      </Button>
      <DisconnectInstructionsModal uiState={uiState}/>
    </div>
  );
});

const SlackIntegration = observer(({uiState}) => {
  const {user} = uiState;
  if (!auth.company.slackEnabled) return null;

  return (
    <Panel>
      {user.slackEnabled ?
        <DisconnectAccount uiState={uiState}/> :
        <ConnectAccount uiState={uiState}/>
      }
    </Panel>
  );
});

export default SlackIntegration;
