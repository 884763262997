import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import TimeTrackingPayPeriod from './TimeTrackingPayPeriod';
import moment from 'moment';
import _ from 'lodash';

class TimeTrackingPaySchedule extends DomainObject {
  @observable name;
  @observable frequency;
  @observable readOnly;
  @observable payPeriods = [];
  @observable currentPayPeriod;
  @observable latestPayPeriod;

  @action merge(other) {
    super.merge(other, {
      payPeriods: [TimeTrackingPayPeriod],
      currentPayPeriod: TimeTrackingPayPeriod,
      latestPayPeriod: TimeTrackingPayPeriod
    });
  }

  @computed get sortedPayPeriods() {
    return _.orderBy(this.payPeriods, 'startDate', 'desc');
  }

  @computed get previousPayPeriod() {
    return _.find(this.sortedPayPeriods, payPeriod => moment(payPeriod.endDate).isBefore(moment())) ||
      _.find(this.sortedPayPeriods, payPeriod => moment(payPeriod.startDate).isBefore(moment())) ||
      this.sortedPayPeriods[0];
  }
}

export default TimeTrackingPaySchedule;
