import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import FeatureRequestState from './FeatureRequestState';
import {Modal, ModalButtons} from 'components/modals';
import {TextArea} from 'components';
import {FormattedMessage} from 'react-intl';

const FeatureRequestModal = observer(({uiState}) => {
  return (
    <div>
      <div onClick={() => uiState.openModal()}>
        <FormattedMessage id='components.feature_request.Send Feedback'/>
      </div>
      <Modal
        size='md'
        isOpen={uiState.modalOpen}
        title={'components.feature_request.Send Product Feedback'}
        closeButton={false}
        onHide={() => uiState.closeModal()}
      >
          <TextArea
            value={uiState.body}
            name='body'
            minRows={5}
            onChange={e => uiState.setBody(e.target.value)}
            label={'components.feature_request.QUESTION'}
          />
          <ModalButtons
            saveCaption={'components.feature_request.Submit'}
            onCancel={() => uiState.closeModal()}
            onSave={() => uiState.submit()}
          />
      </Modal>
      <Modal
        size='md'
        isOpen={uiState.confirmationModalOpen}
        title={'components.feature_request.Product Feedback Submitted'}
        onHide={() => uiState.closeConfirmationModal()}
      >
        <p><FormattedMessage id='components.feature_request.FEEDBACK_CONFIRMATION_1'/></p>
        <p className='mt1'><FormattedMessage id='components.feature_request.FEEDBACK_CONFIRMATION_2'/></p>
      </Modal>
    </div>
  );
});

export default withState(FeatureRequestModal, FeatureRequestState);
