import React from 'react';
import {observer} from 'mobx-react';
import {endpoints, types, t} from 'shared/core';
import {Layout, InteractiveTable, DropdownFilter} from 'components';
import SurveySubnav from 'surveys/SurveySubnav';
import {SurveyEmployeeStatus} from 'stores/surveys';
import SurveyAnonymousTag from './SurveyAnonymousTag';
import {auth} from 'shared/core';

const Name = observer(({model}) => {
  return (
    <div>
      <div className='table-cell align-middle'>
        {model.survey.name}
      </div>
      <div className='table-cell align-middle'>
        <SurveyAnonymousTag survey={model.survey} className='ml1'/>
      </div>
    </div>
  );
});

const COLUMNS = [
  {
    header: 'surveys.directory.Name',
    component: Name,
    sortBy: 'name',
    width: 6
  },
  {
    header: 'surveys.directory.Completed Date',
    attribute: 'submittedAtView',
    sortBy: 'submittedAt',
    width: 3
  },
  {
    header: 'surveys.directory.Due Date',
    attribute: 'dueDateView',
    sortBy: 'dueDate',
    width: 3
  }
];

const LOCALIZATION = {
  emptyState: 'surveys.directory.No surveys to display',
};

function statusFilters() {
  return [
    {
      id: 'incomplete',
      display: t('surveys.directory.Incomplete')
    },
    {
      id: 'complete',
      display: t('surveys.directory.Complete')
    }
  ];
}

const EmployeeStatusDirectory = observer(() => {
  return (
    <div>
      <SurveySubnav title={t('surveys.Surveys')}/>
      <Layout>
        <InteractiveTable
          searchable={false}
          saveStateToUrl
          title={t('surveys.My Surveys')}
          columns={COLUMNS}
          localization={LOCALIZATION}
          showLinks={false}
          showRoute={employeeStatus => `/write/${employeeStatus.survey.id}`}
          proxy={{
            type: 'async',
            modelType: types.SURVEYS.EMPLOYEE_STATUS,
            model: SurveyEmployeeStatus,
            endpoint: endpoints.SURVEYS.EMPLOYEE_STATUSES.with(auth.employee.id)
          }}
        >
          <DropdownFilter options={statusFilters()} attr='status'/>
        </InteractiveTable>
      </Layout>
    </div>
  );
});

export default EmployeeStatusDirectory;
