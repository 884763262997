import React from 'react';
import {observer} from 'mobx-react';
import {ReorderModal} from 'components/modals';

const ReorderLinksModal = observer(({uiState}) => {
  return (
    <ReorderModal
      isOpen={uiState.reorderLinksModalOpen}
      onHide={() => uiState.closeReorderLinksModal()}
      title={'company_settings.company_links.Reorder Company Links'}
      models={uiState.reorderingLinks}
      onSave={() => uiState.saveLinkOrders()}
      onCancel={() => uiState.closeReorderLinksModal()}
    />
  );
});

export default ReorderLinksModal;
