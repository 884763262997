import {action, computed, observable} from 'mobx';
import DocumentFolder from 'stores/document_folders/DocumentFolder';
import {successAlert} from 'shared/tools';
import {t, endpoints, api, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import _ from 'lodash';

class DocumentFoldersState {
  store = new DomainStore();

  @observable editingFolder = {};
  @observable errors = {};
  @observable editDocumentFolderModalOpen = false;
  @observable editMessage = 'company_settings.document_folders.Edit Document Folder';
  @observable folders = [];
  @observable reorderingFolders = [];

  @action async load() {
    await this.store._compose([
      endpoints.FOLDERS.ALL
    ]);

    this.folders = this.store._getAll(types.FOLDER, DocumentFolder);
  }

  @action async deleteDocumentFolder(folder) {
    await this.store.destroy(folder);
    successAlert(t('company_settings.document_folders.alerts.Folder deleted successfully.'));
    await this.load();
  }

  @action addDocumentFolder() {
    this.editMessage = 'company_settings.document_folders.Add Document Folder';
    this.editDocumentFolderModalOpen = true;
    this.editingFolder = new DocumentFolder();
  }

  @action editDocumentFolder(folder) {
    this.editMessage = 'company_settings.document_folders.Edit Document Folder';
    this.editDocumentFolderModalOpen = true;
    this.editingFolder = new DocumentFolder(folder);
  }

  @action closeEditDocumentFolderModal() {
    this.editDocumentFolderModalOpen = false;
  }

  @action openReorderFoldersModal() {
    this.reorderingFolders = this.folders.map(folder => new DocumentFolder(folder));
  }

  @action async saveFolderOrders() {
    const folderParams = {};
    this.reorderingFolders.forEach(folder => folderParams[folder['id']] = folder['order']);

    await api.patch(endpoints.FOLDERS.REORDER, {'folders': folderParams});

    this.closeReorderFoldersModal();
    await this.load();
  }

  @action async saveDocumentFolder() {
    const {model, errors} = this.editingFolder.isNew ?
      await this.store.post(endpoints.FOLDERS.ALL, types.FOLDER, this.editingFolder) :
      await this.store.patch(this.editingFolder);

    this.errors = errors;

    if (model) {
      await this.load();
      this.closeEditDocumentFolderModal();
      successAlert(t('company_settings.document_folders.alerts.Folder information saved.'));
    }
  }

  @computed get sortedFolders() {
    return _.orderBy(this.folders, 'order');
  }
}

export default DocumentFoldersState;
