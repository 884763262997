import {action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import options from './options';
import {t} from 'shared/core';

class LivesChartState {
  store = new DomainStore();
  Highcharts;
  HighchartsReact;
  dataSeries;

  receiveProps({dataSeries}) {
    this.dataSeries = dataSeries;
  }

  @action async load() {
    const [highcharts, highchartsReact] = await Promise.all([
      import(/* webpackChunkName: 'highcharts-async' */ 'highcharts'),
      import(/* webpackChunkName: 'highcharts-react-official-async' */ 'highcharts-react-official')
    ]);

    this.Highcharts = highcharts.default;
    this.HighchartsReact = highchartsReact.default;
  }

  @computed get livesChartOptions() {
    return {
      ...options,
      series: [{
        name: t('pa_dashboard.lives_chart.Total Portfolio Enrolment'),
        data: this.dataSeries
      }]
    };
  }
}

export default LivesChartState;

