import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalButtons} from 'components/modals';
import {FormLayout, Input, CheckboxWithLabel, Select2, RadioButtonGroup, RadioButton, TextArea, DemoAccountWarning} from 'components';
import MultipleChoiceForm from './MultipleChoiceForm';
import {observer} from 'mobx-react';
import {auth, t} from 'shared/core';

const EditCustomFieldModal = observer(({uiState}) => {
  const {sortedGroups, editFieldModalOpen, newCustomField, isJobCategory, employeeGroups, errors} = uiState;
  if (!editFieldModalOpen) return null;
  const header = newCustomField.isNew ? 'company_settings.custom_fields.Add a Custom Field' : 'company_settings.custom_fields.Edit Custom Field';

  return (
    <Modal isOpen={editFieldModalOpen}
           onHide={() => uiState.closeEditFieldModal()}
           size='md'
           title={header}
    >
      <DemoAccountWarning/>
      <FormLayout>
        <Input label='company_settings.custom_fields.Name'
          value={newCustomField.name}
          onChange={e => newCustomField.name = e.target.value}
          placeholder='company_settings.custom_fields.e.g. Employee ID'
          errorMessage={errors.name}
        />

        {newCustomField.isNew &&
          <Select2 label='company_settings.custom_fields.Type'
            dropDownClass='select2-animation'
            value={newCustomField.fieldType}
            onChange={e => uiState.updateFieldType(e.target.value)}>
            <option value='text_field'>{t('company_settings.custom_fields.Single-line text')}</option>
            <option value='text_area'>{t('company_settings.custom_fields.Multi-line text')}</option>
            <option value='date'>{t('company_settings.custom_fields.Date')}</option>
            <option value='number'>{t('company_settings.custom_fields.Number')}</option>
            <option value='multiple_choice'>{t('company_settings.custom_fields.Multiple choice')}</option>
          </Select2>}

        <div>
          <TextArea
            label='company_settings.custom_fields.Description'
            type='text'
            value={newCustomField.description}
            placeholder={'company_settings.custom_fields.Add a description...'}
            onChange={(e) => newCustomField.merge({description: e.target.value})}
            className='full-width'
          />
        </div>

        <Select2 label='company_settings.custom_fields.Custom Field Group'
          dropDownClass='select2-animation'
          value={newCustomField.customFieldGroupId}
          onChange={e => newCustomField.customFieldGroupId = e.target.value}>
          {sortedGroups.map(group => <option value={group.id} key={group.id}>{group.name}</option>)}
        </Select2>

        <MultipleChoiceForm errors={errors} field={newCustomField} />

        <CheckboxWithLabel
          label='company_settings.custom_fields.Should the employee be able to see this field?'
          onChange={e => newCustomField.merge({employeeCanView: e.checked})}
          checked={newCustomField.employeeCanView}
          disabled={isJobCategory}
        />

        {newCustomField.employeeCanView &&
          <RadioButtonGroup
            name='custom_field'
            label='company_settings.custom_fields.Who will fill out this field?'
            selectedValue={newCustomField.employeeCanEdit}
            onChange={(name, value) => newCustomField.employeeCanEdit = value}
          >
            <RadioButton className='table-cell align-middle' value={false} disabled={isJobCategory}>
              <FormattedMessage id='company_settings.custom_fields.Employer'/>
            </RadioButton>
            <RadioButton className='table-cell align-middle pl2' value={true} disabled={isJobCategory}>
              <FormattedMessage id='company_settings.custom_fields.Employee'/>
            </RadioButton>
          </RadioButtonGroup>}

        <CheckboxWithLabel
          label={newCustomField.employeeCanEdit ?
            'company_settings.custom_fields.Should this field be displayed to the employee during onboarding?' :
            'company_settings.custom_fields.Should this field be displayed to the admin at the time of hiring?'}
          onChange={e => newCustomField.merge({displayOnHire: e.checked})}
          checked={newCustomField.displayOnHire}
          disabled={isJobCategory}
        />

        <CheckboxWithLabel
          label='company_settings.custom_fields.Should this field be displayed automatically in reports?'
          onChange={e => newCustomField.merge({availableInReports: e.checked})}
          checked={newCustomField.availableInReports}
        />

        {auth.company.payrollEnabled && <CheckboxWithLabel
          label='company_settings.custom_fields.Should this field be included in payroll updates?'
          onChange={e => newCustomField.merge({displayInPayroll: e.checked})}
          checked={newCustomField.displayInPayroll}
        />}

        {<CheckboxWithLabel
          label='company_settings.custom_fields.Should completing this field be required?'
          onChange={e => newCustomField.merge({required: e.checked})}
          checked={newCustomField.required}
        />}

        {auth.moduleEnabled('directory') && <Select2
          label='company_settings.custom_fields.For Employees'
          value={newCustomField.employeeGroupId}
          placeholder='company_settings.custom_fields.Select Employee Group'
          onChange={e => uiState.employeeGroupSelected(e.target.value)}
          errorMessage={errors.employeeGroup}
        >
          {employeeGroups.map((group) =>
            <option key={group.id} value={group.id}>{group.name}</option>
          )}
          <option value={'createNewEmployeeGroup'}>{t('company_settings.custom_fields.+ Create New Employee Group')}</option>
        </Select2>}
      </FormLayout>

      <ModalButtons
        onSave={() => uiState.saveCustomField()}
        onCancel={() => uiState.closeEditFieldModal()}
      />
    </Modal>
  );
});

export default EditCustomFieldModal;
