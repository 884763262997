import React from 'react';
import {observer} from 'mobx-react';
import {ActionLink, EmployeeNameWithAvatar, Panel, ProgressIndicator} from 'components';
import {FormattedMessage} from 'react-intl';
import {dateSpan} from 'shared/tools';
import {t} from 'shared/core';
import GoalStatusTag from '../../GoalStatusTag';

const GoalLinks = observer(({uiState}) => {
  const {goal} = uiState;
  if (!goal.canPatch) return null;

  return (
    <div className='px1 dropdown inline-block clickable absolute top-0 right-0'>
      <i className='material-icons submarine align-middle' data-toggle='dropdown'>
        {'more_horiz'}
      </i>
      <ul className='dropdown-menu right-0'>
        <li><ActionLink className='py1 px2 regular jumbo' onClick={() => uiState.goToEditGoal()}>
          <FormattedMessage id='Edit'/>
        </ActionLink></li>
        <li><ActionLink className='py1 px2 regular jumbo' onClick={() => uiState.goToDuplicateGoalPage()}>
          <FormattedMessage id='goals.Duplicate'/>
        </ActionLink></li>
        <li><ActionLink className='py1 px2 regular jumbo' onClick={() => uiState.openDeleteGoalModal(goal)}>
          <FormattedMessage id='Delete'/>
        </ActionLink></li>
      </ul>
    </div>
  );
});

const GoalDetails = observer(({uiState}) => {
  const {goal} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('goals.Goal Overview')}>
        <GoalLinks uiState={uiState}/>
      </Panel.Header>
      <div className='flex mb2 align-items-middle justify-content-between flex-wrap'>
        <EmployeeNameWithAvatar employee={goal.employee} size='sm'/>
        <div>
          <div>{dateSpan(goal.startDate, goal.endDate, true)}</div>
        </div>
      </div>
      <div className='jumbo mb2 whitespace-pre-line'>
        {goal.description}
      </div>
      {goal.parentGoal && <div className='mb3'>
        <div className='medium'>
          <FormattedMessage id='goals.Parent Goal'/>
        </div>
        <ActionLink onClick={() => uiState.goToGoal(goal.parentGoal)}>{goal.parentGoal.title}</ActionLink>
      </div>}
      <div className='flex align-items-middle flex-grow'>
        <div className='pr2 medium h3'>{goal.formattedCompleteness}</div>
        <div className='flex-grow'><ProgressIndicator items={goal.progressBarItems}/></div>
        <div className='ml2'>
          <GoalStatusTag goal={goal}/>
        </div>
      </div>
    </Panel>
  );
});

export default GoalDetails;
