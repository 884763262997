import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {ObjectSelect2} from 'components';
import _ from 'lodash';

const MoveDocumentModal = observer(({uiState}) => {
  const {movingDocument, foldersInModalDropdown} = uiState;

  return (
    <Modal isOpen={uiState.moveDocumentModalOpen} onHide={() => uiState.closeMoveDocumentModal()} size='sm' title='employees.profile.documents.Move Document to Folder'>
      <form>
        <ObjectSelect2
          label='employees.profile.documents.Folder'
          items={foldersInModalDropdown}
          onChange={value => movingDocument.merge({folder: value})}/>
        <ModalButtons onCancel={() => uiState.closeMoveDocumentModal()} onSave={() => uiState.moveDocumentToFolder()} />
      </form>
    </Modal>
  );
});

export default MoveDocumentModal;
