import React from 'react';
import {observer} from 'mobx-react';
import { Panel, Input, FormLayout, Checkbox, RadioButton, RadioButtonGroup, Select2, LexicalEditor } from 'components';
import {t} from 'shared/core';
import {FormattedMessage, injectIntl} from 'react-intl';

const NameInput = observer(({trainingProgram, errors}) => {
  return (
    <Input
      label='training.edit.Training Name'
      errorMessage={errors.name}
      placeholder='training.edit.e.g., Health and Safety Training'
      value={trainingProgram.name}
      onChange={e => trainingProgram.merge({name: e.target.value})}/>
  );
});

const AvailableInHiringCheckbox = observer(({trainingProgram}) => {
  return (
    <label className='mt2'>
      <div className='table-cell align-middle pr1'>
        <Checkbox value={trainingProgram.availableInHiring || false} checked={trainingProgram.availableInHiring || false}
                  onChange={() => trainingProgram.availableInHiring = !trainingProgram.availableInHiring}/>
      </div>
      <div className='table-cell align-middle'>
        <FormattedMessage id='training.edit.Make training applicable to new hires'/>
      </div>
    </label>
  );
});

const DueDateSelector = injectIntl(observer(({uiState, intl}) => {
  const {trainingProgram} = uiState;

  return (
    <div>
      <RadioButtonGroup
        label='Due date'
        name='dueDate'
        selectedValue={uiState.selectedDueDateOption}
        onChange={(name, value) => uiState.selectedDueDateOption = value}>
        <RadioButton className='block my1' value={'none'}><FormattedMessage id='None'/></RadioButton>
        <div className='my1'>
          <RadioButton className='table-cell align-middle pr1' value={'offset'}></RadioButton>
          <div className='table-cell align-middle full-width'>
            <div className='clearfix table mxn1'>
              <div className='col-1 table-cell align-middle pr1'><FormattedMessage id='within'/></div>
              <div className='col-2 table-cell align-middle px1'>
                <Input
                  onChange={e => trainingProgram.relativeDueDateValue = e.target.value}
                  value={trainingProgram.dueDateValue}
                />
              </div>
              <div className='col-3 table-cell align-middle px1'>
                <Select2 dropDownClass='select2-animation'
                         onChange={e => uiState.selectedUnit = e.target.value}
                         value={uiState.selectedUnit}>
                  <option key='days' value='days'>{intl.formatMessage({id: 'days'})}</option>
                  <option key='weeks' value='weeks'>{intl.formatMessage({id: 'weeks'})}</option>
                </Select2>
              </div>
              <div className='col-6 table-cell align-middle px1'>
                <span className='tuatara pr1'><FormattedMessage id='training.edit.of program launch'/></span>
                <span className='waterloo'><FormattedMessage id='training.edit.(or employee start date)'/></span>
              </div>
            </div>
          </div>
        </div>
      </RadioButtonGroup>
    </div>
  );
}));

const Description = observer(({uiState}) => {
  const {trainingProgram, errors} = uiState;

  return (
    <LexicalEditor
      placeholder={'training.edit.Add a description...'}
      onChange={description => uiState.updateDescription(description)}
      initialEditorState={trainingProgram.lexicalState}
      initialHTML={trainingProgram.description}
      errorMessage={errors.description}
    />
  );
});

const Overview = observer(({uiState}) => {
  const {trainingProgram, errors} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('training.edit.Overview')}
      />
      <FormLayout>
        <div>
          <NameInput trainingProgram={trainingProgram} errors={errors}/>
          <AvailableInHiringCheckbox trainingProgram={trainingProgram}/>
        </div>
        <DueDateSelector uiState={uiState}/>
        <Description uiState={uiState}/>
      </FormLayout>
    </div>
  );
});

export default Overview;
