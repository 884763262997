import {observable, action, computed} from 'mobx';
import filterRowFactory from 'reports/state/filterRowFactory';
import _ from 'lodash';

class CustomReportFilterState {
  onChange;

  @observable report;
  @observable filterRows = [];
  @observable categoryFilterOpen = false;
  filters;

  filterCategorySelected(category) {
    return !!_.find(this.filterRows, {category});
  }

  receiveProps({onChange, report, filters}) {
    this.onChange = onChange;
    this.report = report;
    this.filters = filters;
  }

  @action async load() {
    await this.updateFilter(this.filters);
  }

  @action async loadOrInitializeFilter(filter) {
    const existingRow = _.find(this.filterRows, {row: {category: filter.row.category}});

    if (existingRow && existingRow.options) {
      filter.initWithExistingData({data: existingRow.options});
    } else if (filter.load) {
      await filter.load();
      filter.loaded = true;
    }
  }

  @action async toggleFilterRow(filterCategory, checked) {
    if (checked) {
      const filterRows = filterRowFactory({category: filterCategory});
      if (_.isArray(filterRows)) {
        this.filterRows.push(...filterRows);
      } else {
        this.filterRows.push(filterRows);
      }

      this.closeCategoryFilter();
    } else {
      _.remove(this.filterRows, {category: filterCategory});
    }

    this.applyFilters();
  }

  @action async updateFilter(filters) {
    const filterRows = filters.map(model => filterRowFactory({model, customReportId: this.report.id}));
    for (const filter of filterRows) {
      await this.loadOrInitializeFilter(filter);
      const row = _.find(filters, { attribute: filter.row.attribute });
      filter.row.merge(row);
      filter.editingRow.merge(row);
    }

    this.filterRows = filterRows;
  }

  @action closeFilters() {
    this.categoryFilterOpen = false;
    for (const row of this.filterRows) {
      row.closeDropdowns();
    }
  }

  @action openCategoryFilter() {
    this.closeFilters();
    this.categoryFilterOpen = true;
  }

  @action closeCategoryFilter() {
    this.categoryFilterOpen = false;
  }

  @action applyFilters() {
    const filter = _.flatten(
      this.filterRows.map(
        viewModel => viewModel.row.toJS({
          showEmptyValues: true
        })
      )
    );
    this.closeFilters();
    this.onChange && this.onChange(filter);
  }

  @computed get showFilterRows() {
    return !_.isEmpty(this.filterRows);
  }
}

export default CustomReportFilterState;
