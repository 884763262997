import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import CommentViewModel from './CommentViewModel';
import {AnnouncementComment} from 'stores/announcements';
import {types, endpoints, auth} from 'shared/core';
import {MAXIMUM_ANNOUNCEMENT_HEIGHT} from './constants';
import _ from 'lodash';

class AnnouncementState {
  store = new DomainStore();
  @observable showComments = false;
  @observable creatingCommentViewModel = new CommentViewModel(new AnnouncementComment(), this);
  @observable emojiSelectorOpen = false;
  @observable modifyingReaction = false;
  @observable commenting = false;
  @observable editCommentModalOpen = false;
  @observable deleteCommentModalOpen = false;
  @observable announcementVisibilityModalOpen = false;
  @observable openEditAnnouncementModal;
  @observable announcement;
  @observable expanded = false;
  @observable height = 0;
  @observable errors = {};
  @observable descriptionElement;

  receiveProps({announcement, openEditAnnouncementModal, openDeleteAnnouncementModal}) {
    this.announcement = announcement;
    this.openEditAnnouncementModal = openEditAnnouncementModal;
    this.openDeleteAnnouncementModal = openDeleteAnnouncementModal;
  }

  @action async addReaction(emojiKey) {
    if (!this.doesReactionExist(emojiKey)) {
      if (this.modifyingReaction) return null;

      this.modifyingReaction = true;
      await this.store.post(
        endpoints.ANNOUNCEMENT_REACTIONS,
        types.ANNOUNCEMENT_REACTION,
        {
          announcementId: this.announcement.id,
          emojiKey: emojiKey
        }
      );
      await this.reloadAnnouncement();
      this.modifyingReaction = false;
    }

    this.closeEmojiPicker();
  }

  @action async deleteReaction(reaction) {
    if (this.modifyingReaction) return null;

    this.modifyingReaction = true;
    await this.store.destroy(reaction);
    await this.reloadAnnouncement();
    this.modifyingReaction = false;
  }

  @action async reloadAnnouncement() {
    await this.store._compose([
      endpoints.ANNOUNCEMENT.with(this.announcement.id)
    ]);

    const announcement = this.store._getSingle(types.ANNOUNCEMENT, {id: this.announcement.id});

    this.announcement.update(announcement);
  }

  @action toggleReaction(reaction) {
    if (reaction.userReaction) {
      this.deleteReaction(reaction.userReaction);
    } else {
      this.addReaction(reaction.emojiKey);
    }
  }

  @action expandComments() {
    this.showComments = !this.showComments;
  }

  @action async submitComment(commentViewModel) {
    this.commenting = true;

    const {model, errors} = await this.store.post(
      endpoints.ANNOUNCEMENT_COMMENTS,
      types.ANNOUNCEMENT_COMMENT,
      {
        announcementId: this.announcement.id,
        content: commentViewModel.editingComment
      }
    );

    this.errors = errors;

    if (model) {
      await this.reloadAnnouncement();
      commentViewModel.editingComment = '';
    }

    this.commenting = false;
  }

  @action editCommentClicked(comment) {
    this.setCurrentComment(comment);
    this.openCommentEditModal();
  }

  @action deleteCommentClicked(comment) {
    this.setCurrentComment(comment);
    this.openCommentDeleteModal();
  }

  @action openCommentEditModal() {
    this.editCommentModalOpen = true;
    this.errors = {};
  }

  @action openAnnouncementVisibilityModal() {
    this.announcementVisibilityModalOpen = true;
  }

  @action closeAnnouncementVisibilityModal() {
    this.announcementVisibilityModalOpen = false;
  }

  @action openCommentDeleteModal() {
    this.deleteCommentModalOpen = true;
  }

  @action closeCommentEditModal() {
    this.editCommentModalOpen = false;
    this.setCurrentComment(null);
  }

  @action closeCommentDeleteModal() {
    this.deleteCommentModalOpen = false;
    this.setCurrentComment(null);
  }

  @action setCurrentComment(comment) {
    this.editingCommentViewModel = comment;
  }

  @action confirmCommentDelete() {
    this.deleteComment();
    this.closeCommentDeleteModal();
  }

  @action confirmCommentEdit(commentViewModel) {
    this.editComment(commentViewModel);
    this.closeCommentEditModal();
  }

  @action async deleteComment() {
    await this.store.destroy(this.editingCommentViewModel.data);
    await this.reloadAnnouncement();
  }

  @action async editComment(commentViewModel) {
    await this.store.patch(
      endpoints.ANNOUNCEMENT_COMMENT.with(commentViewModel.data.id),
      types.ANNOUNCEMENT_COMMENT,
      commentViewModel.data
    );
    await this.reloadAnnouncement();
  }

  @action toggleEmojiList() {
    this.emojiSelectorOpen = !this.emojiSelectorOpen;
  }

  @action closeEmojiPicker() {
    this.emojiSelectorOpen = false;
  }

  @action async submit() {
    await this.submitComment(this);
  }

  @computed get commentViewModels() {
    return this.showComments ? this.allCommentViewModels : this.latestCommentViewModels;
  }

  @computed get allCommentViewModels() {
    return this.announcement.comments.map(comment => new CommentViewModel(comment, this));
  }

  @computed get latestCommentViewModels() {
    const latestComments = _.takeRight(this.announcement.comments, 2);

    return latestComments.map(comment => new CommentViewModel(comment, this, this));
  }

  @computed get canManageAnnouncement() {
    return auth.hasAccess('::MANAGE_ANNOUNCEMENTS') || auth.user.id === this.announcement.publishedByUser.id;
  }

  @computed get expandCommentsText() {
    return this.showComments ? 'hr_dashboard.feed.announcements.Hide older comments' : 'hr_dashboard.feed.announcements.View previous comments';
  }

  @computed get showExpandCommentsAction() {
    return this.announcement.comments.length > 2;
  }

  doesReactionExist(emoji) {
    return this.announcement.reactions.some(reaction =>
      reaction.user.id === auth.user.id && emoji === reaction.emojiKey
    );
  }

  @action expandDescription() {
    this.expanded = true;
  }

  @computed get expandable() {
    return this.height > MAXIMUM_ANNOUNCEMENT_HEIGHT;
  }

  @action mount() {
    this.descriptionElement.querySelectorAll('img').forEach(img => {
      this.recalculateHeightAfterImageLoad(img);
    });
    this.height = this.descriptionElement.clientHeight;
  }

  @action recalculateHeightAfterImageLoad(img, retryCounter = 0) {
    if (img.complete) {
      this.height = this.descriptionElement.clientHeight;
    } else if (retryCounter < 50) {
      setTimeout(() => this.recalculateHeightAfterImageLoad(img, retryCounter + 1), 200);
    }
  }

  @action setRef(element) {
    this.descriptionElement = element;
  }
}

export default AnnouncementState;
