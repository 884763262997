import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Avatar} from 'components';
import {t} from 'shared/core';

const RevieweeSummary = observer(({reviewee}) => {
  const {employee} = reviewee;

  return (
    <div>
      <Panel.Header title={t('performance_reviews.manage.Reviewee')}/>
      <div className='table-cell align-middle pr1'>
        <Avatar employee={employee} size='sm'/>
      </div>
      <div className='table-cell align-middle pl1'>
        <div>{employee.name}</div>
        <div className='waterloo'>{employee.jobTitle}</div>
      </div>
    </div>
  );
});

export default RevieweeSummary;
