import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints} from 'shared/core';
import {CompanyPlan} from 'stores/pa_dashboard';
import _ from 'lodash';

class CompanyPlansState {
  store = new DomainStore();

  @observable companyPlans = [];

  @action async load() {
    await this.store._compose([
      endpoints.PA_DASHBOARD.COMPANY_PLANS
    ]);

    this.companyPlans = this.store._getAll(types.PA_DASHBOARD.COMPANY_PLAN, CompanyPlan);
  }

  @computed get sortedPlans() {
    return _.orderBy(this.companyPlans, ['createdAt'], ['desc']);
  }
}

export default CompanyPlansState;
