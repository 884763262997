import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import {useHistory} from 'react-router-dom';
import Row from './Row';
import RemoveModal from 'components/modals/RemoveModal';
import EmptyState from './EmptyState';
import TableHeaders from './TableHeaders';
import AddNewModelLink from './AddNewModelLink';
import TableState from 'components/tables/state/TableState';
import TableOverlay from './TableOverlay';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CustomTableActionLinks from 'components/tables/components/CustomTableActionLinks';

const Table = observer((props) => {
  const history = useHistory();
  const [uiState] = useState(() => {
    const uiState = new TableState();
    uiState.receiveProps(props);
    return uiState;
  });

  useEffect(() => {
    uiState.receiveProps({...props, history});
  }, [props, history]);

  const {
    columns,
    models,
    showLinks,
    indexBy,
    localization,
    customLinks,
    wrapInTableClass,
    isUpdating,
    trait,
    showHeaders,
    onRemoveClicked,
    customTableActionLinks,
    onlyShowCustomLinks,
    horizontalScroll,
    ...rest
  } = props;

  if (!horizontalScroll) {
    if (showLinks && _.sumBy(columns, 'width') !== 11) {
      throw new Error('Columns must add up to a width of 11 when showLinks is set to true. (1 column is taken by the dropdown with edit/delete links.)');
    }
    if (!showLinks && _.sumBy(columns, 'width') !== 12) {
      throw new Error('Columns must add up to a width of 12.');
    }
    if (_.find(columns, {sticky: true})) {
      throw new Error('horizontalScroll must be set to true to have a sticky column');
    }
  }

  const {removeModal, addModel, editModel, removeModel} = localization;
  const containerRef = useRef(null);

  if (_.isEmpty(models)) return (
    <EmptyState localization={localization} uiState={uiState} customTableActionLinks={customTableActionLinks} />
  );

  return (
    <div className={wrapInTableClass ? 'Table' : ''}>
      <div className={`${isUpdating ? 'relative' : ''} ${horizontalScroll ? 'overflow-auto' : ''}`} ref={containerRef}>
        <TableOverlay isUpdating={isUpdating}/>
        <table className={horizontalScroll ? 'width-auto min-width-full' : 'full-width'}>
          {showHeaders && (
            <thead>
              <TableHeaders
                showLinks={showLinks}
                horizontalScroll={horizontalScroll}
                containerRef={containerRef}
                uiState={uiState}
              />
            </thead>
          )}
          <tbody>
            {models.map(m =>
              <Row
                {...rest}
                key={_.get(m, indexBy) || _.get(m, 'data.id')}
                model={m}
                showLinks={showLinks}
                onEditClicked={() => uiState.editModel(m)}
                onRemoveClicked={onRemoveClicked || (() => uiState.removeModel(m))}
                onClick={e => uiState.clickRow(e, m)}
                columns={columns}
                editRoute={uiState.editRoute}
                editModelCaption={editModel}
                removeModelCaption={removeModel}
                customLinks={customLinks}
                onlyShowCustomLinks={onlyShowCustomLinks}
                clickable={uiState.isModelClickable(m)}
                trait={trait}
                horizontalScroll={horizontalScroll}
                containerRef={containerRef}
              />
            )}
          </tbody>
        </table>
        <AddNewModelLink uiState={uiState} text={addModel}/>
        <CustomTableActionLinks customTableActionLinks={customTableActionLinks} />
      </div>
      <RemoveModal isOpen={uiState.removeModalOpen}
        onHide={() => uiState.cancelRemove()}
        {...removeModal}
        onConfirm={() => uiState.confirmRemove()}/>
    </div>
  );
});

Table.defaultProps = {
  showLinks: true,
  showAddLink: true,
  indexBy: 'id',
  localization: {},
  customLinks: [],
  wrapInTableClass: true,
  isUpdating: false,
  clickable: false,
  sortable: false,
  trait: 'normal',
  showHeaders: true,
  rowStyle: 'align-middle'
};

Table.propTypes = {
  rowStyle: PropTypes.oneOf(['align-top', 'align-middle'])
};

// Table component

// props:
// - showRoute: a function or a string. if provided value is a function, it will be
//   invoked when a table row is clicked, with the following signature: showRoute(model).
//   this function should return a path to navigate to; if this function returns a falsey value,
//   navigation will not be performed.
//   example: showRoute={model => `/employees/${model.id}`}
//   if showRoute is a string, it will be used as a React Router route to navigate to.
//   it can contain a single special placeholder variable :id, e.g.:
//   showRoute='/employees/:id' <- does the same thing as the example above.
//   note that if you're using this prop, you must pass a reference to history as a prop.
// - showPath: same as showRoute, but works with absolute URLs instead of React Router routes
// - history: React router history object; required if using showRoute.
// - showHeaders: (optional, true by default). if false, the table won't render the headers

export default Table;
