import React from 'react';
import {observer} from 'mobx-react';
import {Avatar} from 'components';
import {FormattedMessage} from 'react-intl';
import {calendarDate} from 'shared/tools';

const OutstandingEnrolmentItem = observer(({model, hideProfileLink}) => {
  const {employee} = model;

  return (
    <div>
      <div className='table-cell align-top pr2' style={{paddingTop: '3px'}}>
        <Avatar employee={employee} size='sm'/>
      </div>
      <div className='table-cell align-top'>
        <FormattedMessage
          id='pa_dashboard.outstanding_enrolments.EMPLOYEE_NOT_ENROLLED.HQ'
          values={{employee: employee.name}}
        />
        {model.sortedEnrolmentsByEnrolmentDate.map(enrolment => {
          return (
            <div className='jumbo' key={enrolment.id}>
              <FormattedMessage
                id='pa_dashboard.outstanding_enrolments.ENROLMENT_WINDOW'
                values={{
                  carrier: enrolment.companyInsuranceCarrier,
                  date: calendarDate(enrolment.enrolmentDate)
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default OutstandingEnrolmentItem;

