import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {TextArea, FormLayout, Select2, Input} from 'components';
import {Modal, ModalButtons} from 'components/modals';
import _ from 'lodash';

const EditManualAdjustmentModal = observer(({uiState, isOpen}) => {
  const {editingManualAdjustment, errors} = uiState;

  return (
    <Modal
      isOpen={isOpen}
      onHide={() => uiState.closeEditManualAdjustmentModal()}
      title='employees.profile.time_off.Edit Manual Adjustment'
      size='sm'
    >
      <FormLayout>
        <Input
          value={editingManualAdjustment.adjustment}
          onChange={e => _.merge(editingManualAdjustment, {adjustment: e.target.value})}
          label='employees.profile.time_off.Amount'
          errorMessage={errors.adjustment}
          connectedRight={
            <Select2
              value={editingManualAdjustment.adjustBy}
              onChange={e => _.merge(editingManualAdjustment, {adjustBy: e.target.value})}
            >
              <option value={'days'}><FormattedMessage id='employees.profile.time_off.Days'/></option>
              <option value={'hours'}><FormattedMessage id='employees.profile.time_off.Hours'/></option>
            </Select2>
          }
        />
        <TextArea
          value={editingManualAdjustment.notes}
          onChange={e => _.merge(editingManualAdjustment, {notes: e.target.value})}
          label='employees.profile.time_off.Adjustment notes'
        />
      </FormLayout>
      <ModalButtons
        onSave={() => uiState.saveManualAdjustment()}
        onCancel={() => uiState.closeEditManualAdjustmentModal()}/>
    </Modal>
  );
});

export default EditManualAdjustmentModal;
