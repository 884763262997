import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {Avatar} from 'components';
import {AvatarListState} from '../state';
import MoreAvatarsModal from './MoreAvatarsModal';
import PropTypes from 'prop-types';

const MoreEmployeesAvatar = observer(({uiState}) => {
  const {limit, moreEmployeesOption, moreModalEnabled, moreAvatar} = uiState;
  if (!moreEmployeesOption) return null;

  return (
    <li className={`${moreModalEnabled ? 'clickable' : ''}`}
      onClick={() => uiState.openMoreAvatarsModal()}
    >
      <Avatar {...moreAvatar} zIndex={uiState.calculateZIndex(limit)} />
    </li>
  );
});

const Avatars = observer(({uiState}) => {
  const {employeeList, linkable, size} = uiState;

  return (
    employeeList.map((e, index) =>
      <li key={e.id} >
        <Avatar linkable={linkable} employee={e} size={size} zIndex={uiState.calculateZIndex(index)}/>
      </li>)
  );
});

const AvatarList = observer(({uiState}) => {
  const {type, avatarListSize} = uiState;

  return (
    <div>
      <ul className={`AvatarList AvatarList--${type} AvatarList--${avatarListSize}`}>
        <Avatars uiState={uiState}/>
        <MoreEmployeesAvatar uiState={uiState}/>
      </ul>
      <MoreAvatarsModal uiState={uiState} />
    </div>
  );
});

// AvatarList helps render a list of employee avatars in a horizontal line

// props:
// - size: determines the size of the avatar to render

// - type: style of avatar list being rendered; (overlapping/spaced out)

// - linkable: clicking on avatars will take you to the employee's profile

// - limit: number of avatars to show
// when the number of employees surpasses the limit,
// the last option will show # of extra employees in the list
// clicking on that option will open a modal with the rest of the employees

AvatarList.propTypes = {
  employees: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  limit: PropTypes.number,
  linkable: PropTypes.bool,
  moreModalEnabled: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md']),
  type: PropTypes.oneOf(['', 'overlap'])
};

AvatarList.defaultProps = {
  size: 'sm',
  type: '',
  moreModalEnabled: false,
  linkable: false
};

export default withState(AvatarList, AvatarListState);
