import {observable, action} from 'mobx';
import {serializeModel, DomainStore} from 'shared/store';
import Session from 'stores/users/Session';
import {types, endpoints, api} from 'shared/core';
import {redirect} from 'shared/tools';
import _ from 'lodash';
import {RecoveryCode} from 'stores/recovery_codes';

class LoginContainerState {
  store = new DomainStore();

  STATES = {
    usernamePassword: 0,
    twoStepAuthentication: 1,
    recoveryCode: 2,
    recoveryCodeDownload: 3
  };

  @observable currentState = 0;
  @observable errors = {};
  @observable form = new Session();
  @observable attemptTokenVerification = false;
  @observable redirectUrl;
  @observable recoveryCode;

  @action async createSession() {
    const payload = serializeModel(this.form);

    try {
      const response = await api.post(endpoints.SESSIONS.new, payload);
      const attributes = response.data.data.attributes;

      switch (response.data.data.type) {
        case 'authenticationResults':
          this.form.merge({
            mfaRequired: true,
            mfaType: attributes.mfaType,
            mfaParameter: attributes.mfaParameter
          });
          this.attemptTokenVerification = true;
          this.goToState(this.STATES.twoStepAuthentication);
          break;
        case 'users':
          this.redirectUrl = response.headers.location;

          if (this.currentState === this.STATES.recoveryCode) {
            await this.generateNewRecoveryCode();
          } else {
            await this.redirectAfterLogin();
          }

          break;
        default:
          throw new Error(`Unexpected response type ${response.data.data}`);
      }
    } catch (e) {
      if (e.formErrors) {
        this.goToState(this.STATES.usernamePassword);
        this.errors = e.formErrors;
        this.attemptTokenVerification = this.form.mfaRequired;
        this.form.clearFields();
      }
    }
  }

  @action async generateNewRecoveryCode() {
    await this.store.post(endpoints.RECOVERY_CODES, types.RECOVERY_CODE);
    this.recoveryCode = new RecoveryCode(
      this.store._getSingle(types.RECOVERY_CODE)
    );
    this.goToState(this.STATES.recoveryCodeDownload);
  }

  @action goToState(state) {
    this.currentState = state;
    this.errors = {};
  }

  @action redirectToForgotPassword() {
    window.location.href = '/password_resets/new';
  }

  @action redirectToSignUp() {
    window.location.href = '/users/new';
  }

  @action async redirectAfterLogin() {
    let url = '/';

    if (this.redirectUrl) {
      url = this.redirectUrl;
    }

    await redirect(url);
  }

  get authenticityToken() {
    return document.getElementsByName('csrf-token')[0].content;
  }
}

export default LoginContainerState;
