import {DomainStore} from 'shared/store';
import {t, endpoints, types} from 'shared/core';
import {action, observable} from 'mobx';
import _ from 'lodash';
import {successAlert} from 'shared/tools';

class AccountSummariesState {
  store = new DomainStore();
  agent;

  @observable timeOffTypes;
  @observable balanceAdjustmentModalOpen = false;
  @observable adjustmentAccount;
  @observable adjustmentNotes;
  @observable errors = {};

  @action async load() {
    const composed = await this.store._compose([
      endpoints.TIME_OFF.TYPES.ALL
    ]);

    this.timeOffTypes = composed[0].data.map(t =>
      ({ id: t.id, display: t.name })
    );
  }

  @action setInteractiveAgent(agent) {
    this.agent = agent;
  }

  @action openBalanceAdjustmentModal(accountSummary) {
    this.adjustmentAccount = accountSummary;
    this.balanceAdjustmentModalOpen = true;
  }

  @action closeBalanceAdjustmentModal() {
    this.balanceAdjustmentModalOpen = false;
    this.errors = {};
    this.adjustmentNotes = '';
  }

  @action updateAdjustment(notes) {
    this.adjustmentNotes = notes;
  }

  @action async saveBalanceAdjustment() {
    const params = {
      ...this.adjustmentAccount,
      notes: this.adjustmentNotes
    };

    const {model, errors} = await this.store.patch(
      this.adjustmentAccount.link('adjustment'),
      types.TIME_OFF.ACCOUNT,
      params
    );

    this.errors = errors;

    if (model) {
      successAlert(t('time_off.account_summaries.Account adjustment saved'));
      this.agent.refresh();
      this.closeBalanceAdjustmentModal();
    }
  }
}

export default AccountSummariesState;
