import React from 'react';
import {observer} from 'mobx-react';
import {Table, Spinner} from 'components';
import {calendarDate} from 'shared/tools';
import DayOffModal from './DayOffModal';
import DayOffHoursAndDays from 'components/DayOffHoursAndDays';
import moment from 'moment';

const EditIcon = observer(({model}) => {
  if (!model.canUpdateHours) return null;

  return (
    <i className='material-icons h2 align-middle submarine pr1'>{'edit'}</i>
  );
});

const DayOffDate = observer(({model}) => {
  const {date} = model;

  return (
    <div>
      <div>
        {calendarDate(date)}
      </div>
      <div className='jumbo'>
        {`${moment(date).format('dddd')} ${model.timeRange ? model.timeRange : ''}`}
      </div>
    </div>
  );
});

const COLUMNS = [
  {
    width: 6,
    component: DayOffDate
  },
  {
    width: 5,
    component: DayOffHoursAndDays
  },
  {
    width: 1,
    component: EditIcon
  }
];

const CustomHours = observer(({uiState}) => {
  const {daysOff, specifyCustomHours, fetchingRequestedDays, canSubmitRequest} = uiState;
  if (!specifyCustomHours) return null;
  if (fetchingRequestedDays) return <Spinner/>;

  return (
    <div style={{marginTop: '-1.25rem'}}>
      <Table
        indexBy='date'
        showHeaders={false}
        showLinks={false}
        models={daysOff}
        columns={COLUMNS}
        onRowClicked={canSubmitRequest ? dayOff => uiState.editHoursFor(dayOff) : null}
      />
      <DayOffModal uiState={uiState}/>
    </div>
  );
});

export default CustomHours;
