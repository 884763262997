import React from 'react';
import {observer} from 'mobx-react';
import CompanyDocumentUploadState from '../state/CompanyDocumentUploadState';
import companyDocumentStore from 'stores/documents/CompanyDocumentStore';
import {Input, Panel, FormLayout, LabelHelper, UploadFiles, FormError, Display} from 'components';
import {ModalButtons} from 'components/modals';
import _ from 'lodash';
import {t} from 'shared/core';

const CompanyDocumentUpload = observer(({uiState}) => {
  const {companyDocument, companyDocuments, errors} = uiState;

  return (
    <div>
      <Display backPath='/documents'/>
      <div className='Container'>
        <Panel>
          <FormLayout>
            <Input value={companyDocument.name}
              label='company_settings.documents.Company Document Name'
              errorMessage={errors.name}
              onChange={(e) => companyDocument.merge({name: e.target.value})} />
            <div className='clearfix'>
              <LabelHelper label={t('company_settings.documents.Upload your document')}/>
              <UploadFiles
                defaultFiles={companyDocuments}
                onChange={(files) => uiState.updateFile(files)}
                onUploadStarted={() => uiState.onUploadStarted()}
                onUploadFinished={() => uiState.onUploadFinished()}
                errorMessage={errors.originalFile}
                isUploading={uiState.isUploading}
                maxFiles={1}
                accept={uiState.acceptedMimeTypes}
                modelType='company_documents/base'
              />
              <FormError messsage={errors.base} />
            </div>
            <ModalButtons
              onSave={() => uiState.saveCompanyDocument()}
              saveEnabled={uiState.canSaveDocument}
              onCancel={() => uiState.goBack()}
            />
          </FormLayout>
        </Panel>
      </div>
    </div>
  );
});

export default ({history, match}) => {
  const uiState = new CompanyDocumentUploadState(companyDocumentStore, history, match);
  return <CompanyDocumentUpload uiState={uiState} />;
};
