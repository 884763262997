import {DomainStore} from 'shared/store';
import {action} from 'mobx';
import {endpoints, types} from 'shared/core';

class TimeOffPageState {
  store = new DomainStore();
  scope;

  @action async load() {
    await this.store._compose([
      endpoints.TIME_OFF.NAVIGATION
    ]);

    this.scope = this.store._getSingle(types.NAVIGATION_SCOPE).tabs;
  }
}

export default TimeOffPageState;
