import React from 'react';
import {observer} from 'mobx-react';
import EmployeeNameWithAvatar from 'components/EmployeeNameWithAvatar';
import {statusTagColour} from 'employees/tools';
import {t} from 'shared/core';

const AdvisorPortalEmployeeName = observer(({model}) => {
  const employee = model.employee || model;
  const tags = [];

  if (employee.onboardingStatus === 'onboarding') {
    tags.push({
      content: t('employees.profile.onboarding'),
      colour: statusTagColour('onboarding')
    });
  }

  return <EmployeeNameWithAvatar employee={employee} tags={tags} />;
});

export default AdvisorPortalEmployeeName;
