import React from 'react';
import {observer} from 'mobx-react';
import {auth} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalButtons} from 'components/modals';
import {calendarDate} from 'shared/tools';

const SendOfferModal = observer(({uiState}) => {
  const {sendOfferModalOpen, firstName, jobTitle, expiresAt} = uiState;

  return (
    <Modal
      title='offers.editor.Send Offer Confirmation'
      size='md'
      isOpen={sendOfferModalOpen}
      onHide={() => uiState.closeSendOfferModal()}
    >
      <FormattedMessage id='offers.editor.send_confirmation_first_line'/>
      <div className='h3 medium mt2'>
        <FormattedMessage id='offers.editor.Offer Email Preview'/>
      </div>
      <div className='bg-haze mt2 p2 border rounded'>
        <p>
          <FormattedMessage id='offers.editor.offer_email_preview.line_1' values={{firstName}}/>
        </p>
        <br/>
        <p>
          <FormattedMessage id='offers.editor.offer_email_preview.line_2'
                            values={{jobTitle, companyName: auth.unescapedCompanyName}}/>
        </p>
        <br/>
        {expiresAt && <React.Fragment>
          <p>
            <FormattedMessage id='offers.editor.offer_email_preview.line_3'
                              values={{
                                expiryDate: <span className='bold'>{calendarDate(expiresAt)}</span>,
                                expiryTime: <span className='bold'><FormattedMessage
                                  id='offers.editor.offer_email_preview.expiry_time'/></span>,
                              }}/>
          </p>
          <br/>
        </React.Fragment>
        }
        <p>
          <FormattedMessage id='offers.editor.offer_email_preview.line_4'/>
        </p>
      </div>
      <ModalButtons
        onSave={() => uiState.sendOffer()}
        saveCaption='offers.editor.Send Offer'
        onCancel={() => uiState.closeSendOfferModal()}
      />
    </Modal>
  );
});

export default SendOfferModal;
