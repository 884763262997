import {observable} from 'mobx';
import {t} from 'shared/core';

class MergeFieldViewModel {
  @observable name;
  @observable key;
  @observable annotationType;

  constructor({name, key, annotationType}) {
    this.name = name;
    this.key = key;
    this.annotationType = annotationType;
  }

  static fromHash({key, label, annotationType}) {
    return new MergeFieldViewModel({
      name: t(label),
      key,
      annotationType: annotationType || 'variable'
    });
  }
}

export default MergeFieldViewModel;
