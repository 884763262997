import React from 'react';
import _ from 'lodash';
import InputError from './InputError';
import ErrorIcon from 'img/error-field@2x.png';

const FormError = ({message, className='', alignRight}) => {
  if (!message || !_.isString(message)) return null;

  return (
    <div className={`relative ${alignRight && 'flex justify-content-end'} ${className}`}>
      <img src={ErrorIcon} alt='error' height='16' className='inline-block' style={{margin: '5px 4px 5px 0'}}/>
      <InputError className={`inline-block ${alignRight && 'relative'}`} message={message} />
    </div>
  );
};

export default FormError;
