import _ from 'lodash';
import CheckIn from 'stores/performance/CheckIn';
import {observable, action} from 'mobx';
import {fetchModel, patchModel, fetchModels} from 'shared/store';
import {endpoints, types} from 'shared/core';

class MyCheckInDetailsContainerState {
  history;
  match;

  @observable checkin;
  @observable setFormDirty;

  receiveProps({match, history, setFormDirty}) {
    this.setFormDirty = setFormDirty;
    this.history = history;
    this.match = match;
  }

  @action async load() {
    this.checkin = new CheckIn(await fetchModel(
      endpoints.PERFORMANCE.CHECKIN.with(this.match.params.checkinId),
      types.PERFORMANCE.CHECKIN
    ));
    this.employees = await fetchModels(
      endpoints.EMPLOYEES.ALL,
      types.EMPLOYEE
    );
    this.setFormDirty(false);
  }

  @action updateAnswerContent(e, answer) {
    this.setFormDirty(true);
    answer.content = e.target.value;
  }

  async patchCheckIn(checkin) {
    await patchModel(
      endpoints.PERFORMANCE.CHECKIN.with(checkin.id),
      types.PERFORMANCE.CHECKIN,
      this.checkin.toJS()
    );
  }

  @action onSubmit = async () => {
    await this.patchCheckIn(this.checkin);
    this.setFormDirty(false);
    this.history.push('/checkins');
  };
}

export default MyCheckInDetailsContainerState;
