import React from 'react';
import {observer} from 'mobx-react';
import {Table, Tag, Button} from 'components';
import {FormattedMessage} from 'react-intl';

const AccountTag = observer(({account}) => {
  switch (account.accountType) {
    case 'broker':
      return <Tag colour='turquoise'><FormattedMessage id='my_accounts.Brokerage'/></Tag>;
    case 'company':
    default:
      return null;
  }
});

const AccountDescription = observer(({model}) => {
  return (
    <div>
      <div>
        <div className='table-cell align-middle'>
          {model.accountName}
        </div>
        <div className='table-cell align-middle pl1'>
          <AccountTag account={model}/>
        </div>
      </div>
      <div className='waterloo'>
        {model.name}
      </div>
    </div>
  );
});

const SwitchButton = observer(({model, uiState}) => {
  return (
    <Button
      trait={model.current ? 'default' : 'primary'}
      disabled={model.current}
      onClick={() => uiState.switchToAccount(model)}
      size='sm'
    >
      {model.current && <FormattedMessage id='my_accounts.Signed In'/>}
      {!model.current && <FormattedMessage id='my_accounts.Sign In'/>}
    </Button>
  );
});

const COLUMNS = [
  {
    component: AccountDescription,
    width: 9
  },
  {
    component: SwitchButton,
    width: 3,
    className: 'right-align'
  }
];

const AccountsList = observer(({uiState}) => {
  const {sortedAccounts} = uiState;

  return (
    <Table
      showLinks={false}
      showHeaders={false}
      models={sortedAccounts}
      columns={COLUMNS}
      uiState={uiState}
    />
  );
});

export default AccountsList;
