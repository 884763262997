import {DomainObject, oneOf} from 'shared/store';
import {observable, action} from 'mobx';

class Annotation extends DomainObject {
  @observable id;
  @observable annotationType;
  @observable top;
  @observable left;
  @observable width;
  @observable height;
  @observable pageNumber;
  @observable annotationArgs;
  @observable state;

  @action merge(other) {
    super.merge(other, {
      annotationType: oneOf([
        'employee_signature',
        'company_signature',
        'variable',
        'custom_field',
        'input'
      ])
    });
  }
}

export default Annotation;
