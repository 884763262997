import {action, observable} from 'mobx';
import {auth, endpoints, t} from 'shared/core';
import {DomainStore} from 'shared/store';
import {successAlert} from 'shared/tools';
import {types} from 'shared/core';
import {User} from 'stores/users';
import _ from 'lodash';
import {RoleAssignmentViewModel} from './state';
import {redirect} from 'shared/tools';

class UsersState {
  store = new DomainStore();
  agent;

  @observable roles = [];
  @observable roleAssignmentViewModels = [];
  @observable editUserRolesModalOpen = false;
  @observable passwordResetModalOpen = false;
  @observable updateLoginEmailModalOpen = false;
  @observable resetMFAModalOpen = false;
  @observable editingUser = {};
  @observable errors = {};

  receiveProps({roles}) {
    this.roles = roles;
  }

  @action setAgent(agent) {
    this.agent = agent;
  }

  @action async destroyUser(user) {
    await this.store.destroy(user);
    successAlert(t('company_settings.external_users.Successfully removed user'));
  }

  @action openEditUserRolesModal(user) {
    this.editUserRolesModalOpen = true;
    this.editingUser = new User(user);
    this.roleAssignmentViewModels = this.roles.map(
      role => new RoleAssignmentViewModel(user, role)
    );
  }

  @action closeEditUserRolesModal(user) {
    this.editUserRolesModalOpen = false;
  }

  @action async saveRoleChanges() {
    const roleAssignments = _.chain(this.roleAssignmentViewModels)
      .filter(viewModel => viewModel.checked)
      .map(viewModel => viewModel.toJS())
      .value();

    const {model, errors} = await this.store.patch(
      this.editingUser.link('roles'),
      types.USER,
      { roleAssignments }
    );

    this.errors = errors;
    if (model) {
      this.closeEditUserRolesModal();
      this.agent.refresh();
    }
  }

  @action openPasswordResetModal(user) {
    this.passwordResetModalOpen = true;
    this.editingUser = new User(user);
  }

  @action closePasswordResetModal() {
    this.passwordResetModalOpen = false;
  }

  @action openResetMFAModal(user) {
    this.resetMFAModalOpen = true;
    this.editingUser = user;
  }

  @action closeResetMFAModal() {
    this.resetMFAModalOpen = false;
  }

  @action openUpdateLoginEmailModal(user) {
    this.updateLoginEmailModalOpen = true;
    this.editingUser = new User(user);
  }

  @action closeUpdateLoginEmailModal() {
    this.updateLoginEmailModalOpen = false;
  }

  @action async sendPasswordReset() {
    const {model, errors} = await this.store.post(
      this.editingUser.link('passwordReset'),
      types.USER
    );

    this.errors = errors;

    if (model) {
      this.closePasswordResetModal();
      successAlert(t('company_settings.users.Password reset email sent.'));
    }
  }

  @action async impersonateUser(user) {
    await this.store.put(
      user.link('impersonate'),
      types.USER
    );

    return redirect('/');
  }

  @action async clearMFAFields() {
    const {model} = await this.store.post(
      endpoints.MFA.RESET_FOR_USER,
      types.USER,
      this.editingUser
    );

    if (model) {
      this.editingUser.update(model);
      this.closeResetMFAModal();
      successAlert(t('company_settings.users.Users 2FA successfully reset.'));
    }
  }

  @action async updateLoginEmail() {
    const {model, errors} = await this.store.patch(
      this.editingUser.link('updateLoginEmail'),
      types.USER_DETAIL,
      this.editingUser
    );
    this.errors = errors;

    if (model) {
      await this.agent.refresh();
      this.closeUpdateLoginEmailModal();
      successAlert(t('company_settings.users.LOGIN_EMAIL_UPDATED', { name: this.editingUser.name }));
    }
  }

  linksForUser(user) {
    const userLinks = [];

    if (user.hasLink('roles')) {
      userLinks.push(
        {
          text: 'company_settings.users.Edit Roles',
          action: user => this.openEditUserRolesModal(user)
        }
      );
    }

    if (user.hasLink('updateLoginEmail')) {
      userLinks.push(
        {
          text: 'company_settings.users.Edit Login Email',
          action: user => this.openUpdateLoginEmailModal(user)
        }
      );
    }

    if (user.hasLink('passwordReset')) {
      userLinks.push(
        {
          text: `company_settings.users.${auth.company.ssoEnabled ? 'Send SSO Connection Email' : 'Send Password Reset'}`,
          action: user => this.openPasswordResetModal(user)
        }
      );
    }

    if (user.mfaType) {
      userLinks.push(
        {
          text: 'company_settings.users.Reset 2FA',
          action: user => this.openResetMFAModal(user)
        }
      );
    }

    if (user.hasLink('impersonate')) {
      userLinks.push(
        {
          translate: false,
          text: t('company_settings.users.VIEW_AS_USER', {user: user.name}),
          action: user => this.impersonateUser(user)
        }
      );
    }

    return userLinks;
  }

  filterOptions() {
    return [{
      id: 'false',
      display: t('employees.directory.Active Employees')
    },
    {
      id: 'true',
      display: t('employees.directory.Terminated Employees')
    }];
  }
}

export default UsersState;
