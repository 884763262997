import React from 'react';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {auth} from 'shared/core';

const EmployeeProfileSubnav = ({uiState}) => {
  const {match} = uiState;

  return (
    <ul className='Subnav-list list-reset'>
      <li><NavLink to={`${match.url}/personal`} activeClassName='active'><FormattedMessage id='employees.profile.Personal'/></NavLink></li>
      {(uiState.viewDataPermission('::ROLE_INFORMATION') || uiState.viewDataPermission('::COMPENSATION_INFORMATION')) &&
        <li><NavLink to={`${match.url}/job`} activeClassName='active'><FormattedMessage id='employees.profile.Job'/></NavLink></li>}
      {uiState.tabs.timeOff &&
        <li><NavLink to={`${match.url}/time-off`} activeClassName='active'><FormattedMessage id='employees.profile.Time Off'/></NavLink></li>}
      {uiState.viewDataPermission('::BANKING_INFORMATION') && (auth.company.bankingEnabled || auth.company.sinEnabled) &&
        <li><NavLink to={`${match.url}/banking`} activeClassName='active'><FormattedMessage id='employees.profile.Banking'/></NavLink></li>}
      {uiState.viewDataPermission('::EMERGENCY_CONTACT_INFORMATION') &&
        <li><NavLink to={`${match.url}/emergency`} activeClassName='active'><FormattedMessage id='employees.profile.Emergency'/></NavLink></li>}
      {uiState.viewDataPermission('::DOCUMENTS') &&
        <li><NavLink to={`${match.url}/documents`} activeClassName='active'><FormattedMessage id='employees.profile.Documents'/></NavLink></li>}
      {uiState.viewDataPermission('::NOTES') &&
        <li><NavLink to={`${match.url}/notes`} activeClassName='active'><FormattedMessage id='employees.profile.Notes'/></NavLink></li>}
      {uiState.tabs.calendar &&
        <li><NavLink to={`${match.url}/calendars`} activeClassName='active'><FormattedMessage id='employees.profile.Calendars'/></NavLink></li>}
      {auth.hasAccess('::MANAGE_ONBOARDING') &&
        <li><NavLink to={`${match.url}/onboarding`} activeClassName='active'><FormattedMessage id='employees.profile.Onboarding'/></NavLink></li>}
    </ul>
  );
};

export default EmployeeProfileSubnav;
