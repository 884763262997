import React from 'react';
import PerformanceSubnav from 'containers/performance/components/PerformanceSubnav';
import MyCheckInsContainerWithState from './MyCheckInsContainerWithState';

const MyCheckInsContainer = ({scope}) => {
  return (
    <div>
      <PerformanceSubnav scope={scope}/>
      <MyCheckInsContainerWithState/>
    </div>
  );
};

export default MyCheckInsContainer;
