import React from 'react';
import PropTypes from 'prop-types';
import InputHelper from './InputHelper';
import LabelHelper from './LabelHelper';
import {scrollsIntoViewIfErrors} from 'shared/tools';
import HelpLink from './links/HelpLink';
import {t} from 'shared/core';

class RadioButtonGroup extends React.Component {
  state = {
    selectedValue: this.props.selectedValue
  }

  getChildContext() {
    const {name, selectedValue, onChange} = this.props;
    return {
      radioButtonGroup: {
        name, selectedValue, onChange
      }
    };
  }

  render() {
    const {children, className, errorMessage, label, helpLink, action, translateLabel} = this.props;
    return (
      <div className={`RadioButtonGroup ${className} ${errorMessage ? 'RadioButtonGroup--hasErrors' : ''}`}>
        {label && <LabelHelper label={translateLabel ? t(label) : label} action={action}>
          {helpLink && <HelpLink title={helpLink.title} explanation={helpLink.explanation}/>}
        </LabelHelper>}
        {children}
        <InputHelper errorMessage={errorMessage}/>
      </div>
    );
  }
}

RadioButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  translateLabel: PropTypes.bool,
  errorMessage: PropTypes.string,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ])
};

RadioButtonGroup.defaultProps = {
  className: '',
  translateLabel: true
};

RadioButtonGroup.childContextTypes = {
  radioButtonGroup: PropTypes.object
};

export default scrollsIntoViewIfErrors(RadioButtonGroup, '.RadioButtonGroup--hasErrors');
