import React from 'react';
import $ from 'jquery';
import {observer} from 'mobx-react';
import ActionLink from './links/ActionLink';

@observer class ReactSelect extends React.Component {
  state = {status: ''};

  titleClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.status === 'is-closed' || !this.state.status) {
      this.setState({status: 'is-open'});

      $(document).one('click', () => {
        this.setState({status: 'is-closed'});
      });
    } else {
      this.setState({status: 'is-closed'});
    }
  };

  render() {
    const {selected, children, className} = this.props;

    return <div className={`ReactSelect ${this.state.status} ${className}`}>
      <div className='ReactSelect-title' onClick={this.titleClicked}>
        {selected}
      </div>
      <ul className='ReactSelect-list list-reset right-align right-0'>
        {children}
      </ul>
    </div>;
  }
}


ReactSelect.Option = observer(({children, text, onClick, active, className}) => {
  const fontColor = active ? 'dodger' : 'black';
  return (
    <li>
      <ActionLink className={`${fontColor} ${className}`} onClick={onClick}>
        {children || text}
      </ActionLink>
    </li>
  );
});

export default ReactSelect;
