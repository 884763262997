import React from 'react';
import {observer} from 'mobx-react';
import {Input, RadioButton, RadioButtonGroup, FormLayout, DatePicker, CheckboxWithLabel} from 'components';
import HolidayLocationSelector from './HolidayLocationSelector';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';

const HolidayModal = observer(({uiState, modalOpen}) => {
  const {currentHoliday, year, errors} = uiState;
  const startDate = moment(`${year}-01-01`).toDate();
  const endDate = moment(`${year}-12-31`).toDate();

  return (
    <Modal
      isOpen={modalOpen}
      onHide={() => uiState.closeEditHolidayModal()}
      size='md'
      title={currentHoliday.isNew ? 'time_off.holidays.Add Holiday' : 'time_off.holidays.Edit Holiday'}
    >
      <FormLayout>
        <Input
          label='time_off.holidays.Holiday Name'
          placeholder="time_off.holidays.e.g., New Year's Day"
          value={currentHoliday.name}
          onChange={e => currentHoliday.merge({name: e.target.value})}
          errorMessage={errors.name}
        />
        <DatePicker
          label='time_off.holidays.Date'
          value={moment(currentHoliday.date)}
          onChange={value => uiState.handleDateChange(value)}
          options={{ maxViewMode: 'months', startDate, endDate }}
          errorMessage={errors.date}
        />
        <CheckboxWithLabel
          label='time_off.holidays.Include in time tracking'
          checked={currentHoliday.includedInTimeTracking}
          onChange={(e) => currentHoliday.includedInTimeTracking = e.checked}
          disabled={currentHoliday.duration === 'half_day'}
        />
        <RadioButtonGroup
          name='duration'
          label='time_off.holidays.Duration'
          selectedValue={currentHoliday.duration}
          onChange={(name, value) => uiState.updateDuration(value)}
          errorMessage={errors.duration}
        >
          <RadioButton className='block' value='full_day'>
            <FormattedMessage id='time_off.holidays.Full Day'/>
          </RadioButton>
          <RadioButton className='block pt1' value='half_day'>
            <FormattedMessage id='time_off.holidays.Half Day'/>
          </RadioButton>
        </RadioButtonGroup>
        <HolidayLocationSelector uiState={uiState}/>
      </FormLayout>
      <ModalButtons onSave={() => uiState.saveHoliday()} onCancel={() => uiState.closeEditHolidayModal()}/>
    </Modal>
  );
});

export default HolidayModal;
