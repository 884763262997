import {computed, observable, action} from 'mobx';
import {DomainStore} from 'shared/store';
import {auth, endpoints, types, t} from 'shared/core';
import _ from 'lodash';
import {PerformanceReviewReview, PerformanceReviewReviewee} from 'stores/performance_reviews';
import {setupAutosaveDraft, successAlert} from 'shared/tools';

class RevieweeResultsState {
  store = new DomainStore();
  match;

  goalTimePeriodOptions = [
    { id: 'past', status: 'past', display: t('goals.Past') },
    { id: 'current', status: 'current', display: t('goals.Current') },
    { id: 'upcoming', status: 'upcoming', display: t('goals.Upcoming') }
  ];

  @observable reviews = [];
  @observable reviewee;
  @observable reviewAnswerRefs = [];
  @observable errors = {};
  @observable submitReviewModalOpen = false;

  receiveProps({match}) {
    this.match = match;
  }

  @action async load() {
    const {params} = this.match;
    await this.store._compose([
      endpoints.PERFORMANCE_REVIEWS.REVIEWS.FOR_REVIEWEE.with(params.cycleId, params.revieweeId)
    ]);

    this.reviews = this.store._getAll(types.PERFORMANCE_REVIEWS.REVIEW, PerformanceReviewReview);
    this.reviewee = new PerformanceReviewReviewee(this.store._getSingle(types.PERFORMANCE_REVIEWS.REVIEWEE, { id: params.revieweeId }));

    this.reviews = await Promise.all(this.reviews.map(async (review) => {
      if (review.reviewer.id !== auth.employee.id) return review;

      if (!review.readOnly) {
        const questions = _.map(review.answers, 'question');

        await setupAutosaveDraft(
          review,
          {id: review.id, type: types.PERFORMANCE_REVIEWS.REVIEW, omittedAttributes: ['reviewee.employee', 'type']}
        );
        review.regenerateAnswersForQuestions(questions);
      }

      return review;
    }));
  }

  @action openSubmitReviewModal() {
    this.submitReviewModalOpen = true;
  }

  @action closeSubmitReviewModal() {
    this.submitReviewModalOpen = false;
  }

  @action goToGoal(goal) {
    window.open(`/goals/view/${goal.id}`, '_blank');
  }

  @action async submitReview(review) {
    const {model, errors} = await this.store.patch(
      review.link('submit'),
      types.PERFORMANCE_REVIEWS.REVIEW,
      review
    );
    this.errors = errors;

    if (model) {
      review.update(model);
      successAlert(t('performance_reviews.view.alerts.Review submitted.'));
    }

    this.closeSubmitReviewModal();
  }

  @action async unsubmitReview(review) {
    const {model, errors} = await this.store.patch(
      review.link('unsubmit'),
      types.PERFORMANCE_REVIEWS.REVIEW,
      review
    );
    this.errors = errors;

    if (model) {
      if (review.autosaver) review.autosaver.clearAutosaver();
      review.update(model);
      await setupAutosaveDraft(
        review,
        {id: review.id, type: types.PERFORMANCE_REVIEWS.REVIEW}
      );
    }
  }

  @action onAnswerChanged(review) {
    review.autosaver.autosave();
  }

  @computed get cycleBackPaths() {
    return ['write', 'admin'];
  }

  @action getBackButtonDetails(params) {
    const BASE_PATH = `/reviews/${params.cycleId}`;
    const BASE_TITLE = 'performance_reviews.view.back_paths';
    const CYCLE_BACKPATH = _.includes(this.cycleBackPaths, params.backPath);
    const REVIEWEE_BACKPATH = 'reviewees' === params.backPath;

    if (CYCLE_BACKPATH) {
      return {
        backPath: `${BASE_PATH}/${params.backPath}`,
        backTitle: `${BASE_TITLE}.${params.backPath}`
      };
    } else if (REVIEWEE_BACKPATH) {
      return {
        backPath: `/${params.backPath}`,
        backTitle: `${BASE_TITLE}.${params.backPath}`
      };
    } else {
      return {
        backPath: `${BASE_PATH}/`,
        backTitle: `${BASE_TITLE}.back`
      };
    }
  }

  @computed get canViewRevieweeGoals() {
    return (!!this.viewableSelfReview ||
             (this.reviewee.reviewManager && this.viewableManagerReview)) &&
             (this.reviewee.employee.id === auth.employee.id ||
               !!this.reviewee.employee.accessLevel('::PERFORMANCE_GOALS'));
  }

  @computed get hasReviews() {
    if (_.isEmpty(this.reviews)) return false;

    return !(_.isEmpty(this.reviewsWithAnswers) && _.isEmpty(this.writableReviews));
  }

  @computed get reviewsWithAnswers() {
    return _.filter(this.otherReviewerReviews, (review) => {
      return !_.isEmpty(review.answers) && review.submitted;
    });
  }

  @computed get sortedReviews() {
    const reviewsWithoutAnswers = _.differenceBy(
      this.otherReviewerReviews,
      this.reviewsWithAnswers,
      'id'
    );

    return [...this.writableReviews, ...this.reviewsWithAnswers, ...reviewsWithoutAnswers];
  }

  @computed get hasOtherReviewerReviews() {
    return !_.isEmpty(this.otherReviewerReviews);
  }

  @computed get hasViewableReviews() {
    return !_.isEmpty(this.viewableReviews);
  }

  @computed get otherReviewerReviews() {
    return _.reject(this.reviews, {reviewer: { id: auth.employee.id }});
  }

  @computed get writableReviews() {
    return _.filter(this.reviews, {reviewer: { id: auth.employee.id }});
  }

  @computed get viewableReviews() {
    return [...this.writableReviews, ...this.reviewsWithAnswers];
  }

  @computed get viewableManagerReview() {
    return _.find(this.viewableReviews, r => r.type.kind === 'manager');
  }

  @computed get viewableSelfReview() {
    return _.find(this.viewableReviews, r => r.type.kind === 'self');
  }

  visibilityNotice(review) {
    if (review.reviewer.id !== auth.employee.id) return;

    const kind = _.get(review, 'type.kind');
    switch(kind) {
      case 'peer':
        return this.reviewee.canViewAllReviews ?
          'performance_reviews.view.PEER_VISIBILITY_NOTICE_NO_WARNING' :
          'performance_reviews.view.PEER_VISIBILITY_NOTICE';
      case 'direct_report':
        return 'performance_reviews.view.DIRECT_REPORT_VISIBILITY_NOTICE';
      case 'manager':
        return 'performance_reviews.view.MANAGER_VISIBILITY_NOTICE';
      default:
        return 'performance_reviews.view.VISIBILITY_NOTICE';
    }
  }

  reviewDetailsTitle(review) {
    if (review.reviewer.id === auth.employee.id) return 'performance_reviews.view.My Feedback';

    return 'performance_reviews.view.REVIEWER_FEEDBACK';
  }

  scrollToReview(model) {
    const review = _.find(this.viewableReviews, { id: model.id });
    if (!review) return;

    const ref = _.find(this.reviewAnswerRefs, ref => ref.id === review.id);

    if (ref) ref.element.scrollIntoView({ behavior: 'smooth' });
  }

  addRef(ref) {
    if (!ref) return;

    const elementWithId = {
      id: ref.id,
      element: ref
    };

    this.reviewAnswerRefs.push(elementWithId);
  }
}

export default RevieweeResultsState;
