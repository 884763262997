import {DomainObject} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {fullName} from 'shared/tools';
import _ from 'lodash';
import {t} from 'shared/core';
import RoleAssignment from './RoleAssignment';

class User extends DomainObject {
  @observable id;
  @observable firstName;
  @observable lastName;
  @observable email;
  @observable avatarColour;
  @observable avatarInitials;
  @observable active;
  @observable provider;

  @observable currentPassword;
  @observable newPassword;
  @observable confirmPassword;

  @observable roleAssignments = [];
  @observable mfaType;
  @observable unsubscribedFromEmails;
  @observable slackEnabled;

  @action merge(other) {
    super.merge(other, {
      roleAssignments: [RoleAssignment]
    });
  }

  @action resetPasswordFields() {
    this.currentPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
  }

  @computed get name() {
    return fullName(this);
  }

  @computed get roleNames() {
    const roleNames = _.map(this.roleAssignments, (assignment) => {
      return assignment.role.nameView;
    });

    if (this.manager) {
      roleNames.unshift(t('company_settings.permissions.default_roles.Manager'));
    }

    return roleNames;
  }
}

export default User;
