import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {RadioButton, RadioButtonGroup, Input, Select2} from 'components';
import {t} from 'shared/core';
/*global $ */

class DueDateSelector extends React.Component {
  componentDidMount() {
    $(this.relativeDueDateInput).mask('000');
  }

  render() {
    const {taskTemplateForm, config, errorMessage} = this.props;
    const {taskTemplate} = taskTemplateForm;

    return (
      <RadioButtonGroup 
        label='tasks.Due date'
        selectedValue={taskTemplateForm.selectedDueDateOption}
        name='selectedDueDateOption'
        onChange={(name, value) => taskTemplateForm.selectedDueDateOption = value}
        errorMessage={errorMessage}
      >
        <RadioButton className='block my2' value={'none'}>
          <FormattedMessage id='tasks.None'/>
        </RadioButton>
        <RadioButton className='block my2' value={'zero'}>
          <FormattedMessage id={`tasks.${config.dueOnDateOption}`}/>
        </RadioButton>
        <div className='my2'>
          <RadioButton className='table-cell align-middle' value={'offset'}></RadioButton>
          <div className='table-cell align-middle full-width'>
            <div className='clearfix mxn1'>
              <div className='col col-2 px1'>
                <Input
                  inputRef={input => this.relativeDueDateInput = input}
                  onChange={e => taskTemplateForm.relativeDueDateValue = e.target.value}
                  value={taskTemplate.dueDateValue} />
              </div>
              <div className='col col-4 px1'>
                <Select2 
                  dropDownClass='select2-animation'
                  value={taskTemplateForm.selectedUnit}
                  onChange={e => taskTemplateForm.selectedUnit = e.target.value}>
                  <option key='days' value='days'>{t('days')}</option>
                  <option key='weeks' value='weeks'>{t('weeks')}</option>
                </Select2>
              </div>
              <div className='col col-4 px1'>
                <Select2 
                  dropDownClass='select2-animation' value={taskTemplateForm.selectedPreposition}
                  onChange={e => taskTemplateForm.selectedPreposition = e.target.value}
                >
                  {taskTemplateForm.availablePrepositions.map((preposition) =>
                    <option key={preposition.value} value={preposition.value}>{t(preposition.text)}</option>)}
                </Select2>
              </div>
              <div className='col col-2 p1'>
                <FormattedMessage id={`tasks.${config.dateString}`}/>
              </div>
            </div>
          </div>
        </div>
      </RadioButtonGroup>
    );
  }
}

export default observer(DueDateSelector);

