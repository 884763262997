import {action, observable, computed} from 'mobx';
import _ from 'lodash';
import {DomainStore} from 'shared/store';
import {auth, endpoints, types} from 'shared/core';
import TimeTrackingPayPeriod from 'stores/time_tracking/PayPeriod';
import moment from 'moment';

class MyTimesheetState {
  @observable payPeriods = [];
  @observable selectedPayPeriod;
  @observable employeeId = _.get(auth, 'employee.id');
  store = new DomainStore();

  @action async load() {
    await this.store._compose([
      endpoints.TIME_TRACKING.PAY_PERIODS.FOR_EMPLOYEE.with(this.employeeId)
    ]);

    this.payPeriods = _.orderBy(this.store._getAll(types.TIME_TRACKING.PAY_PERIOD).map(payPeriod => new TimeTrackingPayPeriod(payPeriod)), 'startDate', 'desc');

    if (_.isEmpty(this.payPeriods)) {
      window.location = '/time_tracking';
    } else {
      this.selectedPayPeriod = this.previousPayPeriod;
    }
  }

  @action selectPayPeriod(payPeriod) {
    this.selectedPayPeriod = payPeriod;
  }

  @computed get sortedPayPeriods() {
    return _.orderBy(this.payPeriods, 'startDate', 'desc');
  }

  @computed get previousPayPeriod() {
    return _.find(this.sortedPayPeriods, payPeriod => moment(payPeriod.endDate).isBefore(moment())) ||
      _.find(this.sortedPayPeriods, payPeriod => moment(payPeriod.startDate).isBefore(moment())) ||
      this.sortedPayPeriods[0];
  }
}

export default MyTimesheetState;
