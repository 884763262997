import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {Layout, Panel, Button} from 'components';
import OnboardingContainerState from '../state/OnboardingContainerState';
import TeamIllustration from '../../../../../img/team@2x.png';
import TimeTrackingFeatures from './TimeTrackingFeatures';

const OnboardingContainer = observer(({uiState}) => {
  return (
    <Layout>
      <Panel.Stack loose>
        <div className='flex flex-col md-flex-row align-items-center justify-content-center mxn2'>
          <div className='col col-12 mb2 mx2 mt3 md-col-6 md-pr1'>
            <div className='h1 py2 medium'>
              <FormattedMessage id='time_tracking.onboarding.Start using your included'/> <span className='dodger'><FormattedMessage id='time_tracking.onboarding.Time Tracking'/></span>
            </div>
            <div className='pb1'>
              <FormattedMessage id='time_tracking.onboarding.ONBOARDING_WELCOME_1'/>
            </div>
            <div className='pt1'>
              <FormattedMessage id='time_tracking.onboarding.ONBOARDING_WELCOME_2'/>
            </div>
            <div className='mt2 mb3 left'>
              <Button size='lg' className='mt2' onClick={() => uiState.createPolicy()}>
                <FormattedMessage id='time_tracking.onboarding.Create a policy'/>
              </Button>
            </div>
          </div>
          <div className='col col-12 my2 mx2 md-col-6 md-pl1 center'>
            <img src={TeamIllustration} width='150px' alt='timesheets_banner'/>
          </div>
        </div>
        <Panel.Separator/>
        <TimeTrackingFeatures uiState={uiState}/>
      </Panel.Stack>
    </Layout>
  );
});

export default withState(OnboardingContainer, OnboardingContainerState);
