import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import InputHelper from 'components/InputHelper';
import LabelHelper from 'components/LabelHelper';
import Textarea from 'react-textarea-autosize';
import HelpLink from 'components/links/HelpLink';
import {t} from 'shared/core';
import {scrollsIntoViewIfErrors} from 'shared/tools';

const TextArea = observer(({
  value, errorMessage, label, placeholder, helpMessage, helpLink, action,
  translateLabel, translatePlaceholder, translateHelpMessage, ...rest
}) => {
  return (
    <div className={errorMessage ? 'TextArea--hasErrors' : ''}>
      <LabelHelper label={label} translateLabel={translateLabel} action={action}>
        {helpLink && <HelpLink title={helpLink.title} explanation={helpLink.explanation}/>}
      </LabelHelper>
      <Textarea
        value={value || ''}
        placeholder={translatePlaceholder && placeholder ? t(placeholder) : placeholder}
        {...rest}
      />
      <InputHelper
        helpMessage={helpMessage}
        errorMessage={errorMessage}
        translateHelpMessage={translateHelpMessage}
      />
    </div>
  );
});

TextArea.propTypes = {
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  translateHelpMessage: PropTypes.bool,
  translatePlaceholder: PropTypes.bool,
  translateLabel: PropTypes.bool
};

TextArea.defaultProps = {
  minRows: 2,
  translateHelpMessage: true,
  translatePlaceholder: true,
  translateLabel: true
};

export default scrollsIntoViewIfErrors(TextArea, '.TextArea--hasErrors');
