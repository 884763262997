import {DomainStore} from 'shared/store';
import {action, observable} from 'mobx';
import {endpoints, types} from 'shared/core';

class MassUpdatesDirectoryState {
  store = new DomainStore();
  history;

  @observable massUpdateType = 'job';

  receiveProps({history}) {
    this.history = history;
  }

  @action goToMassUpdateEdit(massUpdate) {
    this.history.push(`/${massUpdate.id}`);
  }

  @action async removeMassUpdate(model) {
    await this.store.destroy(model);
  }

  @action updateMassUpdateType(value) {
    this.massUpdateType = value;
  }

  @action async createMassUpdate() {
    const {model} = await this.store.post(
      `${endpoints.MASS_UPDATES.ALL}?type=${this.massUpdateType}`,
      types.MASS_UPDATE_SUMMARY
    );

    if (model) {
      this.goToMassUpdateEdit(model);
    }
  }
}

export default MassUpdatesDirectoryState;
