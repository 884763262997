import React from 'react';
import {observer} from 'mobx-react';
import {RadioButton, RadioButtonGroup, ObjectSelect2, Select2, Panel} from 'components';
import {FormattedMessage, injectIntl} from 'react-intl';
import {t} from 'shared/core';
import _ from 'lodash';
import EMPLOYMENT_TYPES from 'stores/employees/employmentTypes';

const DataPermissionRow = observer(({role, dataPermission}) => {
  return (
    <div className='py2'>
      <div className='h3 mb1'>
        <FormattedMessage id={dataPermission.name}/>
      </div>
      <RadioButtonGroup name={`data_permission_${dataPermission.name}`} selectedValue={dataPermission.accessLevel} onChange={(name, value) => dataPermission.accessLevel = value}>
        <div className='my2 px2'>
          <RadioButton value='no_access' className='table-cell align-middle center'/>
          <div className='pl2 table-cell align-middle'>
            <div className='h3 medium'>
              <FormattedMessage id='No access'/>
            </div>
            <FormattedMessage
              id={`company_settings.permissions.${dataPermission.descriptions.noAccess}`}
              values={{
                scope: _.lowerCase(t(role.scope))
              }}/>
          </div>
        </div>
        <div className='my2 px2'>
          <RadioButton value='view' className='table-cell align-middle center'/>
          <div className='pl2 table-cell align-middle'>
            <div className='h3 medium'>
              <FormattedMessage id='View only'/>
            </div>
            <FormattedMessage
              id={`company_settings.permissions.${dataPermission.descriptions.viewOnly}`}
              values={{
                scope: _.lowerCase(t(role.scope))
              }}/>
          </div>
        </div>
        <div className='my2 px2'>
          <RadioButton value='edit' className='table-cell align-middle center'/>
          <div className='pl2 table-cell align-middle'>
            <div className='h3 medium'>
              <FormattedMessage id='View and edit'/>
            </div>
            <FormattedMessage
              id={`company_settings.permissions.${dataPermission.descriptions.viewAndEdit}`}
              values={{
                scope: _.lowerCase(t(role.scope))
              }}/>
          </div>
        </div>
      </RadioButtonGroup>
    </div>
  );
});

const ScopeSelector = injectIntl(observer(({uiState, intl}) => {
  const {role, scopeOptions} = uiState;

  return (
    <div className='my2'>
      <div className='h3'>
        <FormattedMessage id='company_settings.permissions.Users with this role can access information for'/>
      </div>
      <div className='my1 border-bottom'>
        <Select2 value={role.scope} onChange={e => uiState.updateScope(e.target.value)} className='large'>
          {scopeOptions.map(o => <option key={o} value={o}>{intl.formatMessage({id: o})}</option>)}
        </Select2>
      </div>
      <LocationSelector uiState={uiState}/>
      <DepartmentSelector uiState={uiState}/>
      <EmploymentTypeSelector uiState={uiState}/>
    </div>
  );
}));

const LocationSelector = observer(({uiState}) => {
  const {role, errors} = uiState;

  if (role.scope !== 'all_employees_by_location') return null;

  return (
    <div className='clearfix'>
      <div className='h4 waterloo my2'>
        <FormattedMessage id='company_settings.permissions.Choose which location(s) to grant access to'/>
      </div>
      <ObjectSelect2
        value={role.locations}
        items={uiState.locations}
        onAdd={value => role.addLocation(value)}
        onRemove={value => role.removeLocation(value)}
        placeholder='company_settings.permissions.Add location'
        multiple={true}
        errorMessage={errors.locations}
      />
    </div>
  );
});

const DepartmentSelector = observer(({uiState}) => {
  const {role, errors} = uiState;

  if (role.scope !== 'all_employees_by_department') return null;

  return (
    <div className='clearfix'>
      <div className='h4 waterloo my2'>
        <FormattedMessage id='company_settings.permissions.Choose which department(s) to grant access to'/>
      </div>
      <ObjectSelect2
        value={role.departments}
        items={uiState.departments}
        onAdd={value => role.addDepartment(value)}
        onRemove={value => role.removeDepartment(value)}
        placeholder='company_settings.permissions.Add department'
        multiple={true}
        errorMessage={errors.departments}
      />
    </div>
  );
});

const EmploymentTypeSelector = observer(({uiState}) => {
  const {role, errors} = uiState;

  if (role.scope !== 'all_employees_by_employment_type') return null;

  return (
    <div className='clearfix'>
      <div className='h4 waterloo my2'>
        <FormattedMessage id='company_settings.permissions.Choose which employment type(s) to grant access to'/>
      </div>
      <Select2
        value={role.employmentTypes}
        onChange={change => role.handleEmploymentTypeChange(change)}
        placeholder='company_settings.permissions.Add employment type'
        multiple={true}
        errorMessage={errors.employmentTypes}
      >
        {EMPLOYMENT_TYPES.map(employment_type => <option value={employment_type} key={employment_type}>
          <FormattedMessage id={`company_settings.permissions.employment_types.${employment_type}`}/>
        </option>)}
      </Select2>
    </div>
  );
});

const EmployeeDataPermissions = observer(({uiState}) => {
  const {role} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('company_settings.permissions.What employee data access should be permitted?')}/>
      <div className='clearfix'>
        <div className='waterloo mb2'>
          <FormattedMessage id='company_settings.permissions.Choose whether users with this role can view or edit employees data.'/>
        </div>
        <ScopeSelector uiState={uiState}/>
        {role.employeeDataPermissions.map((dataPermission) =>
          <DataPermissionRow key={dataPermission.id} dataPermission={dataPermission} role={role}/>)}
    </div>
  </Panel>
  );
});

export default EmployeeDataPermissions;
