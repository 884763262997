import {action, observable} from 'mobx';
import {DomainObject} from 'shared/store';
import {AttachedDocument} from '../documents';

class OfferTemplate extends DomainObject {
  @observable name;
  @observable richText;
  @observable locale;
  @observable attachments;

  @action merge(other) {
    super.merge(other, {
      attachments: [AttachedDocument]
    });
  }
}

export default OfferTemplate;
