import React from 'react';
import {EmployeeNameWithAvatar} from 'components';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';

const EmployeeTrainingStatus = observer(({completed}) => {
  return (
    <FormattedMessage
      id={completed ? 'training.summary.Complete' : 'training.summary.Incomplete'}
    />
  );
});

export const columnsForProgram = (program) => {
  if (_.isEmpty(program.questions)) {
    return [
      {
        header: 'training.summary.Name',
        render: model => <EmployeeNameWithAvatar employee={model.employee}/>,
        width: 3,
        sortBy: 'name'
      },
      {
        header: 'training.summary.Status',
        render: model => <EmployeeTrainingStatus completed={model.completed}/>,
        width: 2,
        sortBy: 'status'
      },
      {
        header: 'training.summary.Due Date',
        render: model => model.dueDateView,
        width: 3,
        sortBy: 'dueDate'
      },
      {
        header: 'training.summary.Completed Date',
        render: model => model.completedAtView,
        width: 3,
        sortBy: 'completedDate'
      }
    ];
  }

  return [
    {
      header: 'training.summary.Name',
      render: model => <EmployeeNameWithAvatar employee={model.employee}/>,
      width: 3,
      sortBy: 'name'
    },
    {
      header: 'training.summary.Status',
      render: model => <EmployeeTrainingStatus completed={model.completed}/>,
      width: 2,
      sortBy: 'status'
    },
    {
      header: 'training.summary.Due Date',
      render: model => model.dueDateView,
      width: 2,
      sortBy: 'dueDate'
    },
    {
      header: 'training.summary.Completed Date',
      render: model => model.completedAtView,
      width: 2,
      sortBy: 'completedDate'
    },
    {
      header: 'training.summary.Score',
      render: model => model.quizResultsView,
      width: 2
    }
  ];
};
