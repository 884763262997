import {DomainObject} from 'shared/store';
import {t} from 'shared/core';
import {observable, action, computed} from 'mobx';
import TimeOffType from './TimeOffType';
import {rangeDate, calendarDate} from 'shared/tools';
import User from 'stores/users/User';
import {Employee} from 'stores/employees';
import {AttachedDocument} from 'stores/documents';
import TimeOffRequestAssignment from './TimeOffRequestAssignment';
import _ from 'lodash';
import moment from 'moment';

const MAX_YEAR = 2999;
const FIRST_APPROVAL_STAGE = 1;
const SECOND_APPROVAL_STAGE = 2;

class TimeOffRequest extends DomainObject {
  @observable id;
  @observable createdAt;
  @observable startDate;
  @observable endDate;
  @observable status;
  @observable amount;
  @observable timeOffType;
  @observable upcoming = false;
  @observable notes;
  @observable attachments;
  @observable todaysAbsenceDuration;
  @observable todaysStartTime;
  @observable todaysEndTime;
  @observable workDayLength;

  @observable actionNotes;
  @observable firstStageNotes;
  @observable firstStageActionedByUser;
  @observable createdByUser;
  @observable secondStageNotes;
  @observable secondStageActionedByUser;
  @observable firstStageStatus;
  @observable secondStageStatus;
  @observable currentAssignedUser;
  @observable totalApprovalStages;

  @observable employee;
  @observable delegateAssignmentsToUser;

  @action merge(other) {
    super.merge(other, {
      timeOffType: TimeOffType,
      firstStageActionedByUser: User,
      secondStageActionedByUser: User,
      currentAssignments: [TimeOffRequestAssignment],
      attachments: [AttachedDocument],
      currentlyDelegatedByUser: User,
      createdByUser: User,
      employee: Employee,
      delegateAssignmentsToUser: User,
      _dates: ['startDate', 'endDate']
    });
  }

  @computed get currentlyAssignedUsers() {
    return _.map(this.currentAssignments, assignment => assignment.assigneeUser);
  }

  @computed get currentStage() {
    if (this.firstStageActionedByUser) return SECOND_APPROVAL_STAGE;
    return FIRST_APPROVAL_STAGE;
  }

  @computed get datesView() {
    if (this.startDate.getTime() === this.endDate.getTime()) return calendarDate(this.startDate);
    return `${rangeDate(this.startDate)} – ${rangeDate(this.endDate)}`;
  }

  @computed get numberOfNotes() {
    return _.filter([this.notes, this.firstStageNotes, this.secondStageNotes]).length;
  }

  @computed get hasValidDates() {
    return (
      !!this.startDate &&
      !!this.endDate &&
      this.startDate.getFullYear() <= MAX_YEAR &&
      this.endDate.getFullYear() <= MAX_YEAR
    );
  }

  @computed get endDateDatePickerOptions() {
    return {
      startDate: this.startDate,
      endDate: moment(this.startDate).add(1, 'y').subtract(1, 'd').format('MM/DD/YYYY')
    };
  }

  @computed get todaysAbsenceDurationFormatted() {
    const duration_in_seconds = parseInt(this.todaysAbsenceDuration) * 3600;

    if (duration_in_seconds === this.workDayLength) {
      return t('hr_dashboard.out_of_office.full day');
    } else if (duration_in_seconds === this.workDayLength / 2) {
      return t('hr_dashboard.out_of_office.half day');
    } else {
      return `${this.todaysAbsenceDuration} ${t('hr_dashboard.out_of_office.hours')}`;
    }
  }

  @computed get todaysTimeRange() {
    if (!this.todaysStartTime || !this.todaysEndTime) {
      return null;
    }

    return `${moment(this.todaysStartTime).format(t('components.dates.TIMESTAMP_FORMAT'))} - ${moment(this.todaysEndTime).format(t('components.dates.TIMESTAMP_FORMAT'))}`;
  }

  toJS() {
    return {
      ...this,
      startDate: moment(this.startDate).format('DD/MM/YYYY'),
      endDate: moment(this.endDate).format('DD/MM/YYYY')
    };
  }
}

export default TimeOffRequest;
