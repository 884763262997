import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, FormLayout, Input, RadioButtonGroup, RadioButton} from 'components';

const ClientInformation = observer(({uiState}) => {
  const {brokerClient, errors} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('broker_portal.clients.edit.Client Details')}/>
      <FormLayout>
        <Input
          value={brokerClient.name}
          onChange={e => brokerClient.merge({name: e.target.value})}
          label='broker_portal.clients.edit.Client Name'
          errorMessage={errors.name}
        />
        <Input
          type='number'
          value={brokerClient.numberOfLives}
          onChange={e => brokerClient.merge({numberOfLives: e.target.value})}
          label='broker_portal.clients.edit.Number of Lives'
          errorMessage={errors.numberOfLives}
        />
        <RadioButtonGroup name='hasFlexPlan'
          label='broker_portal.clients.edit.Does this company have a flex plan?'
          selectedValue={brokerClient.hasFlexPlan}
          errorMessage={errors.hasFlexPlan}
          onChange={(name, value) => brokerClient.merge({hasFlexPlan: value})}>
          <RadioButton className='table-cell pr2' value={true}>{t('broker_portal.clients.edit.Yes')}</RadioButton>
          <RadioButton className='table-cell' value={false}>{t('broker_portal.clients.edit.No')}</RadioButton>
        </RadioButtonGroup>
      </FormLayout>
    </Panel>
  );
});

export default ClientInformation;
