import {fetchModel, postModel} from 'shared/store';
import {endpoints, types} from 'shared/core';
import CheckIn from 'stores/performance/CheckIn';
import {observable, action} from 'mobx';
import {setupAutosaveDraft} from 'shared/tools';
import _ from 'lodash';

class MyCheckInResponseContainerState {
  history;
  match;

  @observable checkin;

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    this.checkin = new CheckIn(await fetchModel(
      endpoints.PERFORMANCE.CYCLE_CHECKIN.with(this.match.params.cycleId),
      types.PERFORMANCE.CHECKIN
    ));


    const questions = _.map(this.checkin.answers, 'question');
    if (!this.checkin.completed) {
      await setupAutosaveDraft(
        this.checkin,
        {id: this.match.params.cycleId, type: types.PERFORMANCE.CHECKIN}
      );
    }
    this.checkin.regenerateAnswersForQuestions(questions);
  }

  @action updateAnswerContent(e, answer) {
    answer.content = e.target.value;

    this.checkin.autosaver.autosave();
  }

  async postCheckIn(checkin, cycleId) {
    await postModel(
      endpoints.PERFORMANCE.CYCLE_CHECKIN.with(cycleId),
      types.PERFORMANCE.CHECKIN,
      checkin.toJS()
    );
  }

  @action onSubmit = async () => {
    this.checkin.merge({reviewerEmployeeId: this.match.params.reviewerId});

    await this.postCheckIn(this.checkin, this.match.params.cycleId);
    this.history.push('/checkins');
  }
}

export default MyCheckInResponseContainerState;
