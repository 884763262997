import {DomainObject} from 'shared/store';
import {observable} from 'mobx';
import {t} from 'shared/core';
import EmployeeSignatureImg from 'img/employee-signature@2x.png';

class Signature extends DomainObject {
  WIDTH = 250;
  HEIGHT = 100;

  @observable name;
  @observable signatureData;

  static employeeSignature() {
    return new Signature({
      name: t('company_settings.documents.Employee Signature'),
      signatureData: EmployeeSignatureImg
    });
  }
}

export default Signature;
