import React from 'react';
import {observer} from 'mobx-react';
import {Panel, FormLayout, ObjectSelect2, DatePicker} from 'components';
import _ from 'lodash';

const EnrolmentForCarrier = observer(({uiState, enrolmentViewModel, errors}) => {
  const {enrolment} = enrolmentViewModel;

  return (
    <Panel>
      <Panel.Subheader title={enrolment.benefitClass.carrierName} />
      <FormLayout>
        <ObjectSelect2
          value={enrolment.benefitClass}
          display='fullName'
          items={uiState.benefitClassesForCarrier(enrolment)}
          onChange={benefitClass => enrolmentViewModel.updateBenefitClass(benefitClass)}
          label='benefit_enrolment.Benefit Class'
          errorMessage={_.get(errors, 'benefitClassId')}
        />
        {enrolmentViewModel.enrolmentChanged && <DatePicker
          value={enrolment.changeEffectiveDate}
          onChange={changeEffectiveDate => enrolment.merge({changeEffectiveDate})}
          label='benefit_enrolment.Effective Date'
          errorMessage={_.get(errors, 'changeEffectiveDate')}
        />}
      </FormLayout>
    </Panel>
  );
});

export default EnrolmentForCarrier;
