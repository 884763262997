import React from 'react';
import {injectIntl, FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';

const PositionSummary = injectIntl(observer(({position}) => {
  return (
    <span>
      {`${position.locationString} | ${position.departmentView} | `} <FormattedMessage id={position.employmentType}/>
    </span>
  );
}));

export default PositionSummary;
