import React from 'react';
import {observer} from 'mobx-react';
import {t, types, withState} from 'shared/core';
import {Button, Panel, InteractiveContext, SearchBox, Pagination, FancyFilter, DropdownFilter} from 'components';
import FancyFilterPills from 'components/tables/interactive/components/fancy_filter/FancyFilterPills';
import {Goal} from 'stores/performance_goals';
import {GoalListState} from '../state';
import GoalViewModel from '../state/GoalViewModel';
import useModal from 'shared/hooks/useModal';
import DeleteGoalModal from './DeleteGoalModal';
import GoalTable from './GoalTable';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const FilterRow = observer(({showEmployeeFilters, ...rest}) => {
  const filters = ['goal_status', 'goal_employee', 'location', 'department', 'employment_type'];
  const statusOptions = [
    { id: 'all', status: 'all', display: t('goals.All') },
    { id: 'in_progress', status: 'in_progress', display: t('goals.status.In Progress') },
    { id: 'upcoming', status: 'upcoming', display: t('goals.status.Upcoming') },
    { id: 'past_due', status: 'past_due', display: t('goals.status.Past Due') },
    { id: 'complete', status: 'complete', display: t('goals.status.Complete') }
  ];

  return (
    <div className='AccordionTableFilters'>
      <div className='FilterRow flex align-items-middle bg-white'>
        <div className='flex flex-wrap flex-grow row-gap-2'>
          <SearchBox {...rest}/>
          {showEmployeeFilters && <div className='ml1 flex align-items-start'>
            <FancyFilterPills {...rest}/>
          </div>}
        </div>
        <div className='FilterGroup-searchable flex align-items-center flex-wrap ml2'>
          {showEmployeeFilters ?
            <FancyFilter filters={filters} {...rest}/> :
            <DropdownFilter options={statusOptions} attr='goal_status' {...rest}/>}
        </div>
      </div>
    </div>
  );
});

const GoalList = observer(({uiState, title, endpoint, showEmployeeFilters}) => {
  const {canCreateGoals} = uiState;
  const deleteGoalModalOpen = useModal(uiState, 'DeleteGoalModal', 'selectedGoal', Goal);

  return (
    <React.Fragment>
      <Panel>
        <Panel.Header title={title}>
          {canCreateGoals && <Button onClick={() => uiState.goToNewGoalPage()}>
            <FormattedMessage id='goals.Create Goal'/>
          </Button>}
        </Panel.Header>
        <InteractiveContext
          onMount={agent => uiState.setInteractiveAgent(agent)}
          onRefresh={args => uiState.updateInteractiveContextState(args)}
          saveStateToUrl
          uiState={uiState}
          proxy={{
            type: 'async',
            endpoint: endpoint,
            modelType: types.PERFORMANCE_GOALS.GOAL,
            model: GoalViewModel
          }}
        >
          <FilterRow showEmployeeFilters={showEmployeeFilters}/>
          <GoalTable parentState={uiState}/>
          <Pagination/>
        </InteractiveContext>
        <DeleteGoalModal modalOpen={deleteGoalModalOpen} uiState={uiState}/>
      </Panel>
    </React.Fragment>
  );
});

export default withState(GoalList, GoalListState);
