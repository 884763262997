import {auth, types, endpoints} from 'shared/core';
import {action, observable, computed} from 'mobx';
import EmployeeDetails from 'stores/employees/EmployeeDetails';
import {DomainStore} from 'shared/store';
import {redirect} from 'shared/tools';

class OnboardEmployeeInfoState {
  store = new DomainStore();

  @observable employee;
  @observable errors = {};

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEE_DETAILS.with(auth.employee.id)
    ]);

    this.employee = new EmployeeDetails(
      this.store._getSingle(types.EMPLOYEE_DETAILS, {id: auth.employee.id})
    );

  }

  @action async nextStep() {
    const {model, errors} = await this.store.patch(
      endpoints.EMPLOYEE_ONBOARD.EMPLOYEE_INFO.with(this.employee.id),
      types.EMPLOYEE_DETAILS,
      this.employee
    );
    this.errors = errors;

    if (model) {
      return redirect('/onboard');
    }
  }

  @computed get customGenderVisible() {
    return this.employee.gender === 'other';
  }
}

export default OnboardEmployeeInfoState;
