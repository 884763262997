import React from 'react';
import {observer} from 'mobx-react';
import Overview from './Overview';
import Documents from './Documents';
import Questions from './Questions';
import Review from './Review';

const Step = observer(({step, uiState}) => {
  switch (step) {
    case 'overview':
      return <Overview uiState={uiState}/>;
    case 'documents':
      return <Documents uiState={uiState}/>;
    case 'questions':
      return <Questions uiState={uiState}/>;
    case 'review':
      return <Review uiState={uiState}/>;
    default:
      throw new Error(`Location ${step} is not supported.`);
  }
});

export default Step;
