import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Button} from 'components';

const QualifyButton = ({uiState}) => {
  return (
    <Button trait='default' className='align-middle mr1' onClick={() => uiState.qualifyCandidate()}>
      <i className='material-icons meadow mr1'>{'replay'}</i>
      <FormattedMessage id='recruiting.hiring_funnel.Qualify'/>
    </Button>
  );
};

const DisqualifyButton = ({uiState}) => {
  return (
    <Button trait='default' className='align-middle mr1' onClick={() => uiState.disqualifyCandidate()}>
      <i className='material-icons scarlet mr1'>{'block'}</i>
      <FormattedMessage id='recruiting.hiring_funnel.Disqualify'/>
    </Button>
  );
};

const ToggleQualifyButton = ({uiState, candidateViewModel}) => {
  const {candidate} = candidateViewModel;
  if (candidate.disqualified) {
    return <QualifyButton uiState={uiState}/>;
  } else {
    return <DisqualifyButton uiState={uiState}/>;
  }
};

export default ToggleQualifyButton;

