import {action, observable} from 'mobx';
import {endpoints, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import CompanyEmailTemplate from 'stores/company_email_templates/CompanyEmailTemplate';
import _ from 'lodash';

class CompanyEmailTemplatesState {
  store = new DomainStore();

  @observable emailTemplates;

  @action async load() {
    await this.store._compose([
      endpoints.COMPANY_EMAIL_TEMPLATES
    ]);

    this.emailTemplates = _.orderBy(
      this.store._getAll(types.COMPANY_EMAIL_TEMPLATE, CompanyEmailTemplate), 'emailType'
    );
  }
}

export default CompanyEmailTemplatesState;
