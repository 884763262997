import React from 'react';
import {observer} from 'mobx-react';
import {Button, TextArea} from 'components';
import {FormattedMessage} from 'react-intl';

const CommentInput = observer(({viewModel, uiState, singleLine}) => {
  const {errors} = uiState;

  return (
    <div className={singleLine && 'flex'}>
      <div className='flex-grow'>
        <TextArea
          value={viewModel.comment}
          onChange={e => viewModel.changeComment(e.target.value)}
          placeholder={viewModel.editCommentPlaceholder}
          className='full-width'
          minRows={singleLine ? 1 : 3}
          errorMessage={errors.content}
        />
      </div>
      <div className='right-align'>
        <Button className={singleLine ? 'ml2 Btn--icon' : 'mt2'} onClick={() => viewModel.submitComment()}>
          {singleLine ?
            <i className='material-icons'>{'send'}</i> :
            <FormattedMessage id={viewModel.editCommentSubmitCaption}/>
          }
        </Button>
      </div>
    </div>
  );
});

export default CommentInput;
