import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {t} from 'shared/core';
import {TimeOffPolicySelector} from './time_off_policy_selector';

const EmployeeTimeOffContainerNoPolicy = observer(({employee}) => {
  return (
    <Panel>
      <Panel.Header title={t('employees.profile.time_off.Time Off')}/>
      <TimeOffPolicySelector employee={employee}/>
    </Panel>
  );
});

export default EmployeeTimeOffContainerNoPolicy;
