import React from 'react';

const StyleGuideTitle = ({title, description}) => {
  return (
    <div className='bg-white box-shadow-1 rounded p4'>
      <div className='h1 medium'>{title}</div>
      <div className='h2 my2 jumbo max-width-3'>{description}</div>
    </div>
  );
};

export default StyleGuideTitle;
