import {observable, computed} from 'mobx';
import {t} from 'shared/core';
import _ from 'lodash';

class SentimentFormState {
  @observable question;

  load() {
    if (_.isEmpty(this.question.options)) {
      this.question.args.options = this.defaultOptions.map((option) => {
        return {
          value: t(`components.question_form.${this.question.questionType}.${option}`)
        };
      });
    }

    this.question.args.options = observable(this.question.args.options);
  }

  receiveProps({question}) {
    this.question = question;
  }

  @computed get defaultOptions() {
    if (this.question.questionType === 'scored') {
      return ['Very Low', 'Low', 'Average', 'High', 'Very High'];
    }

    return ['Strongly Disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly Agree'];
  }
}

export default SentimentFormState;
