class StaticDataSource {
  data;
  constructor(data) {
    this.data = data;
  }

  async load() {
    return this.data;
  }
}

export default StaticDataSource;
