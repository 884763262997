import {action, observable} from 'mobx';
import SecureFile from 'stores/secure_upload/SecureFile';
import _ from 'lodash';

class SecureUploadState {
  store;
  @observable secureFile;
  @observable submitted = false;
  @observable errors = {};
  @observable isUploading = false;

  constructor(store) {
    this.store = store;
    this.secureFile = new SecureFile();
  }

  @action onUploadStarted() {
    this.isUploading = true;
  }

  @action onUploadFinished() {
    this.isUploading = false;
  }

  validate() {
    this.errors = {};
    if (_.isEmpty(this.secureFile.awsFiles)) {
      this.errors.awsFiles = 'Choose a file to upload';
    }
    return _.isEmpty(this.errors);
  }

  @action async sendToCollage() {
    if (!this.validate()) return null;

    const {model, errors} = await this.store.postSecureFile(this.secureFile);
    this.errors = errors;
    if (model) {
      this.submitted = true;
    }
  }
}

export default SecureUploadState;
