import _ from 'lodash';
import {Steps} from 'shared/tools';

const STEPS = [
  {
    name: 'time_tracking.manage.policies.edit.steps.Name',
    location: 'name'
  },
  {
    name: 'time_tracking.manage.policies.edit.steps.Pay Schedule',
    location: 'pay_schedule'
  },
  {
    name: 'time_tracking.manage.policies.edit.steps.Overtime Rules',
    location: 'overtime'
  },
  {
    name: 'time_tracking.manage.policies.edit.steps.Employees',
    location: 'employees'
  },
  {
    name: 'time_tracking.manage.policies.edit.steps.Approver',
    location: 'assignee'
  },
  {
    name: 'time_tracking.manage.policies.edit.steps.Review',
    location: 'review'
  }
].map((s, i) => ({
  ...s,
  index: i + 1
}));

 const singleton = new Steps(STEPS);

 export default singleton;
