import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {TaskModal} from 'components/modals';
import ClockIcon from 'img/clock--red@2x.png';
import {types, t, withState} from 'shared/core';
import {InteractiveTable, DropdownFilter, Avatar} from 'components';
import {Task} from 'stores/tasks';
import {dateFormatter} from 'shared/tools';
import {InboxState} from '../state';

const Description = observer(({model}) => {
  switch (model.taskType) {
    case 'review':
      return <FormattedMessage id='inbox.REVIEW_TASK'/>;
    case 'survey':
      return <FormattedMessage id='inbox.SURVEY_TASK'/>;
    case 'offer':
      return <FormattedMessage id='inbox.OFFER_TASK'/>;
    default:
      return (
        <FormattedMessage
          id='inbox.TASK_FOR'
          values={{
            taskType: model.taskType ? <FormattedMessage id={model.taskType}/> : '',
            name: <span className='tuatara'>{model.subjectName}</span>
          }}
        />
      );
  }
});

const AssigneeAvatar = observer(({model, showAvatars}) => {
  if (!showAvatars) return null;

  return (
    <Avatar employee={model.assigneeEmployee} size='sm'/>
  );
});

const DueDate = observer(({model}) => {
  if (!model.dueDate) return null;

  return (
    <div>
      <div className='table-cell align-middle pr1'>
        <img src={ClockIcon} className='my1' alt='clock' height='12px'/>
      </div>
      <div className='table-cell align-middle'>
        {dateFormatter(model.dueDate)}
      </div>
    </div>
  );
});

const COLUMNS = [
  {
    header: 'inbox.Name',
    width: 4,
    attribute: 'name'
  },
  {
    header: 'inbox.Description',
    width: 4,
    component: Description
  },
  {
    header: 'inbox.Due Date',
    width: 3,
    component: DueDate,
    sortBy: 'dueDate'
  },
  {
    width: 1,
    className: 'right-align',
    component: AssigneeAvatar
  }
];

function statusFilters() {
  return [
    {
      id: 'incomplete',
      display: t('inbox.incompleted_tasks')
    },
    {
      id: 'complete',
      display: t('inbox.completed_tasks')
    }
  ];
}

function scopeFilters() {
  return [
    {
      scope: 'overdue',
      display: t('inbox.scopes.Overdue')
    }
  ];
}

const Inbox = observer(({uiState, showAvatars, endpoint}) => {
  return (
    <div className='ResponsiveContainer'>
      <InteractiveTable
        columns={COLUMNS}
        showLinks={false}
        saveStateToUrl={true}
        showAvatars={showAvatars}
        onRowClicked={task => uiState.openTaskModal(task)}
        onMount={agent => uiState.setInteractiveAgent(agent)}
        proxy={{
          type: 'async',
          modelType: types.TASK,
          model: Task,
          endpoint
        }}
        localization={{
          emptyState: 'inbox.No tasks to display'
        }}
        scopeOptions={scopeFilters()}
      >
        <DropdownFilter options={statusFilters()} attr='status'/>
      </InteractiveTable>
      <TaskModal uiState={uiState}/>
    </div>
  );
});

export default withState(Inbox, InboxState);
