import _ from 'lodash';
import {action, computed, observable} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, types, auth} from 'shared/core';
import {Company} from 'stores/company';

class CompanyDetailsState {
  store = new DomainStore();
  @observable staffCount;
  @observable company;
  @observable editCompany;

  receiveProps({staffCount, initialCompany}) {
    this.company = initialCompany;
    this.staffCount = staffCount;
  }

  @computed get distinctJobTypes() {
    const jobTypes = [
      this.staffCount.fullTime, this.staffCount.partTime,
      this.staffCount.temp, this.staffCount.intern,
      this.staffCount.volunteer, this.staffCount.contractor,
      this.staffCount.seasonal, this.staffCount.casual
    ];

    return _.filter(jobTypes, job => job).length;
  }

  @action async _patchCompany(fields) {
    const company = this.editCompany.pick(fields);
    const {model, errors} = await this.store.patch(
      endpoints.COMPANY.with(company.id),
      types.COMPANY,
      company
    );
    this.errors = errors;

    if (model) {
      this.company.update(model);
      this.editCompany = new Company(this.company);
    }
  }

  @computed get showCompanyLogo() {
    return this.company.logoUrl || auth.user.admin;
  }
}

export default CompanyDetailsState;
