import {observable, action, computed} from 'mobx';
import {endpoints, types, auth} from 'shared/core';
import _ from 'lodash';
import {DomainStore} from 'shared/store';
import TimeOffPolicy from 'stores/time_off/TimeOffPolicy';
import {redirect} from 'shared/tools';

class TimeOffPolicySelectorState {
  store = new DomainStore();

  @observable employee;
  @observable policy;
  @observable policies = [];
  @observable enrolInPolicyModalOpen = false;
  @observable removeFromPolicyModalOpen = false;
  @observable selectedPolicy = null;

  receiveProps({employee, policy}) {
    this.employee = employee;
    this.policy = policy;
  }

  @action async load() {
    await this.store._compose([
      endpoints.TIME_OFF.POLICIES.CONFIGURED,
    ]);

    this.policies = this.store._getAll(
      types.TIME_OFF.POLICY_SUMMARY,
      TimeOffPolicy
    );
  }

  @action setSelectedPolicy(policy) {
    this.selectedPolicy = policy;
  }

  @action openEnrolInPolicyModal() {
    this.selectedPolicy = this.policies[0];
    this.enrolInPolicyModalOpen = true;
  }

  @action cancelEnrolInTimeOffPolicyModal() {
    this.enrolInPolicyModalOpen = false;
  }

  @action openRemoveFromPolicyModal() {
    this.removeFromPolicyModalOpen = true;
  }

  @action cancelRemoveFromPolicyModal() {
    this.removeFromPolicyModalOpen = false;
  }

  @action async removeFromPolicy() {
    this.employee.timeOffPolicy = null;
    await this.store.patch(this.employee.pick(['timeOffPolicy']));
    return redirect(`/employees/${this.employee.id}/time-off`);
  }

  @action async enrolInPolicy() {
    const employee = this.employee.pick(['timeOffPolicy']);
    employee.timeOffPolicy = this.selectedPolicy;
    await this.store.patch(employee);
    return redirect(`/employees/${this.employee.id}/time-off`);
  }

  @computed get actionLinks() {
    if (!this.policy || !this.timeOffEditable) return [];

    return [
      {
        text: 'employees.profile.time_off.Move to a different policy',
        action: `/${this.employee.id}/change-time-off-policy`
      },
      {
        text: 'employees.profile.time_off.Remove from policy',
        action: () => this.openRemoveFromPolicyModal()
      }
    ];
  }

  @computed get employeePolicies() {
    if (!this.policy) return [];

    return [
      {
        name: _.get(this.policy, 'name')
      }
    ];
  }

  @computed get showEnrolLink() {
    return !this.policy && this.timeOffEditable;
  }

  @computed get timeOffEditable() {
    return !this.employee.terminated && auth.hasAccess('::MANAGE_TIME_OFF');
  }
}

export default TimeOffPolicySelectorState;
