import React from 'react';
import {observer} from 'mobx-react';
import {FormLayout, Select2} from 'components';
import {t} from 'shared/core';

const GenderField = observer(({benefitInfo, errors}) => {
  return (
    <FormLayout>
      <Select2 name='gender' value={benefitInfo.gender}
        onChange={(e) => benefitInfo.merge({gender: e.target.value})}
        placeholder='— select —' errorMessage={errors.gender}
        label='onboard.family_status.Sex at Birth'
      >
        <option key='Male' value='male'>{t('onboard.family_status.Male')}</option>
        <option key='Female' value='female'>{t('onboard.family_status.Female')}</option>
      </Select2>
    </FormLayout>
  );
});

export default GenderField;
