import React from 'react';
import {withState} from 'shared/core';
import {Input, ReactSelect} from 'components';
import {AnnotatablePdfPreview} from 'components/pdf';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {DocumentAnnotationFormState} from '../state';

const DocumentAnnotationForm = observer(({document, errors, onSave, onCancel, uiState}) => {
  return (
    <div className='mt3'>
      <div className='clearfix py2'>
        <div className='col col-12 mb3'>
          <div className='h6 waterloo caps mb1'>
            <FormattedMessage id='company_settings.documents.Company Document Name'/>
          </div>
          <div className='col col-6'>
            <Input
              value={document.name}
              errorMessage={errors.name}
              onChange={(e) => document.merge({name: e.target.value})}
            />
          </div>
        </div>
        <div className='col col-12 mb3'>
          <div className='h6 waterloo caps mb1'>
            <FormattedMessage id='company_settings.documents.Language'/>
          </div>
          <div className='col-2'>
            <ReactSelect selected={uiState.selectedLanguage.name}>
              {uiState.languageOptions.map(option =>
                <ReactSelect.Option
                  active={option.id === uiState.selectedLanguage.id}
                  key={option.id}
                  onClick={() => uiState.selectLanguage(option)}
                >
                  {option.name}
                </ReactSelect.Option>
              )}
            </ReactSelect>
          </div>
        </div>
      </div>
      <AnnotatablePdfPreview
        pdfUrl={document.originalFile.preview}
        errors={errors}
        annotations={document.annotations}
        onSave={(annotations) => onSave(annotations)}
        onCancel={() => onCancel()}
      />
    </div>
  );
});

export default withState(DocumentAnnotationForm, DocumentAnnotationFormState);
