import React from 'react';
import PropTypes from 'prop-types';
import InputHelper from './InputHelper';
import LabelHelper from './LabelHelper';
import {t} from 'shared/core';
import {scrollsIntoViewIfErrors} from 'shared/tools';
import {HelpLink} from './links';

const Input = ({
    value, onChange, errorMessage, className, disabled, helpMessage, translateHelpMessage,
    icon, inputRef, label, type, placeholder, placeholderValues, translatePlaceholder,
    translateLabel, helpLink, connectedRight, connectedLeft, action, hasError, tooltip, ...rest}) => {
  const attributes = {};
  if (placeholder) {
    attributes.placeholder = translatePlaceholder ? t(placeholder, placeholderValues) : placeholder;
  }
  const containerClassName = (connectedLeft || connectedRight)
    ? 'InputConnected' + (connectedLeft ? ' connectedLeft' : ' connectedRight')
    : '';

  return (
    <div className={(icon ? 'InputIcon ' : '') + (errorMessage ? 'Input--hasErrors' : '')}>
      <LabelHelper label={translateLabel && label ? t(label) : label} action={action} tooltip={tooltip}>
        {helpLink && <HelpLink title={helpLink.title} explanation={helpLink.explanation}/>}
      </LabelHelper>
      <div className={containerClassName}>
        {!!connectedLeft && (
          <div className='Input-secondary'>
            {connectedLeft}
          </div>
        )}
        <div className='Input-primary'>
          <input type={type} ref={inputRef}
            value={value || (value === 0 ? 0 : '')} disabled={disabled}
            className={`${className} ${(errorMessage || hasError) ? 'Error-field' : ''}`}
            onChange={(e) => onChange(e)}
            {...attributes}
            {...rest}
          />
          {icon && <i className='material-icons submarine'>{icon}</i>}
        </div>
        {!!connectedRight && (
          <div className='Input-secondary'>
            {connectedRight}
          </div>
        )}
      </div>
      <InputHelper
        errorMessage={errorMessage}
        helpMessage={helpMessage}
        translateHelpMessage={translateHelpMessage}
      />
    </div>
  );
};

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  inputRef: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  translateHelpMessage: PropTypes.bool,
  translatePlaceholder: PropTypes.bool,
  translateLabel: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'search', 'password', 'number']),
  hasError: PropTypes.bool
};

Input.defaultProps = {
  className: '',
  translateHelpMessage: true,
  translatePlaceholder: true,
  translateLabel: true,
  type: 'text',
  hasError: false
};

export default scrollsIntoViewIfErrors(Input, '.Input--hasErrors');
