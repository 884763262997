/* eslint-disable */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import P from 'prop-types';

import zoom from './utils'

@observer class Zoom extends Component {
	componentDidMount(){
		setTimeout(() => {
			const el = this.props.getChartEl()

			if (navigator.userAgent.toLowerCase().includes('webkit')){
				el.addEventListener('wheel', this.scrollZoom, false)
			} else {
				el.addEventListener('DOMMouseScroll', this.scrollZoom, false)
			}
		})
	}

	isZooming = false;

	scrollZoom = e => {
		e.preventDefault();
		e.returnValue = false;

		// weird bug where browser doesn't correctly get mouse x/y offset
		// TODO - fix - bug is when scrolling over top of node (instead of chart bg)
		if (e.offsetX < 0 || e.offsetY < 0){
			return
		}

		// set zoom
		if (!this.isZooming){
			this.isZooming = true;
			requestAnimationFrame(() => {
				let delta;
				if (e.wheelDelta){
					delta = e.wheelDelta / 3600; // chrome/safari
				} else {
					delta = e.detail / -90; // mozilla
				}

				// calculate new matrix and set
				const newMatrix = zoom(
					this.props.matrix,
					{x: e.offsetX, y: e.offsetY},
					delta
				)
				this.props.setMatrix(newMatrix)

				this.isZooming = false
			});
		}
	}

	render(){
		return null
	}
}

Zoom.propTypes = {
	getChartEl: P.func.isRequired,
	matrix: P.object.isRequired,
	setMatrix: P.func.isRequired,
}

export default Zoom;
