import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {endpoints, types, t} from 'shared/core';
import {User} from 'stores/users';
import {successAlert} from 'shared/tools';

class PersonalSettingsContainerState {
  store = new DomainStore();

  @observable user;
  @observable errors = {};

  @action async load() {
    await this.store._compose([endpoints.BROKER_PORTAL.USERS]);
    this.user = new User(this.store._getSingle(types.USER));
  }

  @action async savePreferredLocale() {
    const {model, errors} = await this.store.patch(
      endpoints.BROKER_PORTAL.USER.with(this.user.id),
      types.USER,
      this.user
    );
    this.errors = errors;

    if (model) {
      successAlert(t('broker_portal.personal_settings.Language preferences saved.'));
      location.reload();
    }
  }

  @action async changePassword() {
    const {model, errors} = await this.store.patch(
      endpoints.BROKER_PORTAL.CHANGE_PASSWORD,
      types.USER,
      this.user
    );
    this.errors = errors;
    this.user.resetPasswordFields();

    if (model) {
      successAlert(t('broker_portal.personal_settings.Password successfully changed.'));
    }
  }
}

export default PersonalSettingsContainerState;
