import React from 'react';
import {observer} from 'mobx-react';
import {Avatar} from 'components';
import {urls} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {calendarDate} from 'shared/tools';

const KeyDateItem = observer(({model}) => {
  const {type, date, employee} = model;

  return (
    <div>
      <div className='table-cell align-top pr2' style={{paddingTop: '3px'}}>
        <a href={urls.EMPLOYEE.with(employee.id)}>
          <Avatar employee={employee} size='sm'/>
        </a>
      </div>
      <div className='table-cell align-top'>
        <FormattedMessage
          id={`pa_dashboard.key_dates.types.${type}`}
          values={{
            employee: <a href={urls.EMPLOYEE.with(employee.id)}>{employee.name}</a>
          }}
        />
        <div className='jumbo'>{calendarDate(date)}</div>
      </div>
    </div>
  );
});

export default KeyDateItem;
