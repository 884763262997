import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const RemoveCompanyFileModal = observer(({modalOpen, uiState}) => {
  return (
    <AlertModal
      mode='danger'
      size='sm'
      header='company_settings.company_files.Remove Company File?'
      subHeader='company_settings.company_files.Are you sure you want to remove this company file?'
      onHide={() => uiState.closeRemoveCompanyFileModal()}
      isOpen={modalOpen}
    >
      <ModalDangerButtons
        saveCaption={'Remove'}
        onCancel={() => uiState.closeRemoveCompanyFileModal()}
        onSave={() => uiState.deleteCompanyFile()}
      />
    </AlertModal>
  );
});

export default RemoveCompanyFileModal;
