import {action} from 'mobx';
import PerformanceReviewQuestion from './PerformanceReviewQuestion';
import {QAAnswer} from 'stores/questions';

class PerformanceReviewAnswer extends QAAnswer {
  @action merge(other) {
    super.merge(other, {
      question: PerformanceReviewQuestion
    });
  }
}

export default PerformanceReviewAnswer;
