import React from 'react';
import Modal from './Modal';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';

const Header = ({caption, mode, translate}) => {
  if (!caption) return null;

  return (
    <div className={`h3 medium mb1 ${mode === 'danger' ? 'scarlet' : ''}`}>
      {translate ? <FormattedMessage id={caption}/> : caption}
    </div>
  );
};

const SubHeader = ({caption, translate}) => {
  if (!caption) return null;

  return (
    <div className='h2 medium mb2'>
      {translate ? <FormattedMessage id={caption}/> : caption}
    </div>
  );
};

const Body = ({text, translate}) => {
  if (!text) return null;

  return (
    <div className='h4 mb3 jumbo'>
      {translate ? <FormattedMessage id={text}/> : text}
    </div>
  );
};

const AlertModal = observer(({onHide, isOpen, header, subHeader, body, size, mode, children, translate}) => {
  return (
    <Modal onHide={onHide} size={size} isOpen={isOpen}>
      <Header caption={header} mode={mode} translate={translate}/>
      <SubHeader caption={subHeader} translate={translate}/>
      <Body text={body} translate={translate} />
      {children}
    </Modal>
  );
});

AlertModal.defaultProps = {
  style: 'default',
  size: 'md',
  translate: true
};

export default AlertModal;
