import React from 'react';
import {observer} from 'mobx-react';
import {Display} from 'components';
import {t} from 'shared/core';

const Subnav = observer(({title}) => {
  return (
    <Display
      title={title}
      backTitle={t('performance_reviews.Performance reviews')}
      backPath='/reviews'
    />
  );
});

export default Subnav;
