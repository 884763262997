import React from 'react';
import {observer} from 'mobx-react';
import {Route, Switch} from 'react-router-dom';
import RemindersContainer from './RemindersContainer';
import ReminderEditContainer from './ReminderEditContainer';

const RemindersPage = observer(() => {
  return (
    <Switch>
      <Route exact path='/reminders' component={RemindersContainer}/>
      <Route path='/reminders/:id' component={ReminderEditContainer}/>
    </Switch>
  );
});

export default RemindersPage;
