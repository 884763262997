import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Spinner, ActionLink, FormError} from 'components';
import {observer} from 'mobx-react';

const FileMenu = observer(({file, uiState}) => {
  return (
    <div className='px1 dropdown inline-block clickable'>
      <i className='material-icons submarine align-middle' data-toggle='dropdown'>
        {'more_horiz'}
      </i>
      <ul className='dropdown-menu'>
        <li><ActionLink className='py1 px3 regular jumbo' onClick={() => uiState.removeFile(file)}>
          <FormattedMessage id='components.upload.Delete' />
        </ActionLink></li>
      </ul>
    </div>
  );
});

const FileRow = observer(({file, uiState}) => {
  const url = file.hasLink('url') ? file.link('url') : file.previewUrl;

  return (
    <div>
      <div className='clearfix flex py2 border-bottom'>
        <div className='flex col-1 align-items-center'>
          <i className='material-icons submarine align-middle'>{'insert_drive_file'}</i>
        </div>
        <div className='flex col-10 align-items-center overflow-hidden'>
          <a href={url} target='_blank' className='dodger truncate'>{file.fileName}</a>
        </div>
        <div className='col-1 right-align px1'>
          <FileMenu file={file} uiState={uiState} />
        </div>
      </div>
      <FormError message={uiState.errorMessage}/>
    </div>
  );
});

const UploadedFiles = observer(({uiState}) => {
  const {isUploading, files} = uiState;
  if (!isUploading && !files.length) return null;

  return (
    <div className='mb2'>
      {files.map((file) =>
        <FileRow key={file.id} file={file} uiState={uiState} />
      )}
      {isUploading && <div className='clearfix table py2 border-bottom'>
        <Spinner size='sm' />
      </div>}
      <FormError message={uiState.maxFileSizeErrorMessage}/>
    </div>
  );
});

export default UploadedFiles;
