import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {t} from 'shared/core';
import {Panel, ObjectSelect2, DatePicker, FormLayout, Select2, Input, QuickTip} from 'components';
import {FormattedMessage} from 'react-intl';

const InsuranceNumberForCarrier = observer(({viewModel, errors}) => {
  const {record, insuranceNumberRecord} = viewModel;

  if (!record.companyInsuranceCarrier.selfAssignedCertificatesEnabled) return null;
  if (!insuranceNumberRecord || viewModel.notEligibleSelected || !record.benefitClass) return null;

  return (
    <div>
      <FormLayout>
        <Input
          label='components.benefits.insurance_numbers.Insurance Number'
          helpLink={{
            explanation: {
              header: 'components.benefits.insurance_numbers.Insurance Number',
              paragraphs: [t('components.benefits.insurance_numbers.INSURANCE_NUMBER_EXPLANATION')]
            }
          }}
          value={insuranceNumberRecord.externalId}
          onChange={e => insuranceNumberRecord.merge({externalId: e.target.value})}
          errorMessage={_.get(errors, 'externalId')}
        />
      </FormLayout>
    </div>
  );
});

const BenefitEnrolmentForCarrier = observer(({viewModel, showEnrolmentDate = true, enrolmentErrors = {}, insuranceNumberErrors = {}}) => {
  const {record} = viewModel;
  const {companyInsuranceCarrier} = record;

  return (
    <div>
      <Panel.Subheader title={companyInsuranceCarrier.name}/>
      <FormLayout>
      {showEnrolmentDate && <DatePicker
          value={record.enrolmentDate}
          onChange={date => record.merge({enrolmentDate: date})}
          label='components.benefits.benefit_enrolments.Enrolment Date'
          helpLink={{
            explanation: {
              header: t('components.benefits.help.What is the enrolment date?'),
              paragraphs: [
                t('components.benefits.help.what_is_the_enrolment_date_explanation_1'),
                t('components.benefits.help.what_is_the_enrolment_date_explanation_2')
              ]
            }
          }}
          errorMessage={_.get(enrolmentErrors, 'enrolmentDate')}
        />}
        <ObjectSelect2
          className='full-width'
          label='components.benefits.benefit_enrolments.Benefits Class'
          value={record.benefitClass}
          items={viewModel.benefitClassOptions}
          onChange={value => viewModel.updateBenefitClass(value)}
          errorMessage={_.get(enrolmentErrors, 'benefitClassId')}
        />
        <Select2
          value={viewModel.hasProbation}
          label='components.benefits.Probationary Period'
          onChange={(e) => viewModel.setHasProbation(e.target.value)}
          translateHelpMessage={false}
          disabled={viewModel.notEligibleSelected}
        >
          {viewModel.probationOptions.map(option =>
            <option value={option.value} key={option.text}>{option.text}</option>
          )}
        </Select2>
        {viewModel.showEligibilityMessage &&<div className='mt2'>
          <QuickTip
            trait='info'
            header={t("components.benefits.EFFECTIVE_DATE_FOR_CARRIER", {carrierName: companyInsuranceCarrier.name})}
            paragraphs={[
              <FormattedMessage id='components.benefits.BENEFITS_EFFECTIVE_DATE' values={{ date : viewModel.eligibleDate }}/>
            ]}
          />
        </div>}
        <InsuranceNumberForCarrier viewModel={viewModel} errors={insuranceNumberErrors}/>
      </FormLayout>
    </div>
  );
});

export default BenefitEnrolmentForCarrier;
