import React from 'react';
import EmployeeNavigation from 'employees/navigation';
import {auth} from 'shared/core';
import BrokerEmployeeProfileSubnav from './BrokerEmployeeProfileSubnav';
import EmployeeProfileSubnav from './EmployeeProfileSubnav';
import {DocumentTitle} from 'components';

const EmployeeProfileHeader = ({uiState}) => {
  const {employee} = uiState;

  return (
    <div className='Subnav Subnav--react'>
      <DocumentTitle title={employee.name}/>
      <div className='Container'>
        <div className='py3'>
          <div className='clearfix'>
            <div className='col col-12 sm-col-8'>
              <div className='table-cell align-middle'>
                <EmployeeNavigation currentEmployee={employee}/>
              </div>
            </div>
          </div>
        </div>
        {auth.company.enrolmentOnly
          ? <BrokerEmployeeProfileSubnav uiState={uiState}/>
          : <EmployeeProfileSubnav uiState={uiState}/>
        }
      </div>
    </div>
  );
};

export default EmployeeProfileHeader;
