import {action, observable, computed} from 'mobx';
import {TimeTrackingPayrollHoursSummary} from 'stores/time_tracking';
import _ from 'lodash';

class PayrollHoursSummaryViewModel {
  @observable data;
  @observable isExpanded = false;

  constructor(data) {
    this.data = new TimeTrackingPayrollHoursSummary(data);
  }

  @action expand() {
    this.isExpanded = true;
  }

  @action collapse() {
    this.isExpanded = false;
  }

  @computed get canExpand() {
    return parseInt(this.data.total) > 0 && parseInt(this.data.total) > parseInt(this.data.ptoTotal);
  }

  @computed get projects() {
    return _.chain(this.data.projects)
      .toPairs()
      .map(project => {
        return { id: project[0], ...project[1] };
      })
      .value();
  }
}

export default PayrollHoursSummaryViewModel;
