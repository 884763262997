import React from 'react';
import {observer} from 'mobx-react';
import {Select2} from 'components';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const Manager = observer(({viewModel}) => {
  const {uiState} = viewModel;

  if (uiState.massUpdate.readOnly && !viewModel.fields.get('manager').has('newValue')) {
    return (
      <div className='waterloo'>
        <FormattedMessage id='mass_updates.edit.Not Changed'/>
      </div>
    );
  }

  const previousManager = viewModel.get('manager').get('oldValue') ?
    _.get(_.find(uiState.employees, {id: viewModel.get('manager').get('oldValue')}), 'name') :
    '--';

  return (
    <div>
      <Select2
        searchable
        allowClear
        value={viewModel.get('manager').get('value')}
        disabled={viewModel.disabled}
        onChange={e => viewModel.set('manager', e.target.value)}
      >
        {uiState.employees.map(employee => {
          if (employee.id === viewModel.data.employee.id) return null;
          return <option key={employee.id} value={employee.id}>{employee.name}</option>;
        })}
      </Select2>
      <div className='jumbo mt1'>
        <FormattedMessage
          id={viewModel.disabled ? 'mass_updates.edit.PREVIOUS_VALUE' : 'mass_updates.edit.CURRENT_VALUE'}
          values={{
            value: previousManager
          }}
        />
      </div>
    </div>
  );
});

export default Manager;
