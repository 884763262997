import React from 'react';
import {Modal, ModalButtons} from 'components/modals';
import {FormLayout, Select2} from 'components';
import {observer} from 'mobx-react';
import {t} from 'shared/core';

const MoveCompanyFileModal = observer(({modalOpen, uiState}) => {
  const {sortedCompanyFileFolders, selectedCompanyFile, errors} = uiState;

  return (
    <Modal
      isOpen={modalOpen}
      onHide={() => uiState.closeMoveCompanyFileModal()}
      size='md'
      translateTitle={false}
      title={t('company_settings.company_files.MOVE_FILE_HEADER', { fileName: selectedCompanyFile.name })}
    >
      <FormLayout>
        <Select2
          label='company_settings.company_files.Folder:'
          value={selectedCompanyFile.companyFileFolderId}
          onChange={e => selectedCompanyFile.companyFileFolderId = e.target.value}
          errorMessage={errors.name}
          allowClear={true}
        >
          {sortedCompanyFileFolders.map(folder => (
            <option value={folder.id} key={folder.id}>{folder.name}</option>
          ))}
        </Select2>
      </FormLayout>
      <ModalButtons
        onSave={() => uiState.saveCompanyFile()}
        saveCaption='company_settings.company_files.Save'
        onCancel={() => uiState.closeMoveCompanyFileModal()}
        cancelCaption='company_settings.company_files.Cancel'
      />
    </Modal>
  );
});

export default MoveCompanyFileModal;
