import _ from 'lodash';
import UPDATE_SUMMARY_TYPES from './updateSummaryTypes';

const SPOUSE_FIELDS = [
  'spouseFirstName',
  'spouseLastName',
  'spousalRelationship',
  'spouseMarriageDate',
  'spousalGender',
  'spouseBirthDate',
  'spousalHealthCoverage',
  'spousalDentalCoverage',
  'spousalVisionCoverage',
  'spouseInsurerName',
  'spousePolicyNumber'
];

const COVERAGE_FIELDS = [
  'hasCanadianVisaOrPermit',
  'visaOrPermitExpiryDate',
  'hasProvincialHealthPlan',
  'healthCoverage',
  'dentalCoverage',
  'taxExempt',
  'inacNumber'
];

function extractFields(obj, fields) {
  return _.chain(fields)
    .filter(f => _.has(obj, f))
    .map(f => ({name: obj[f].label, value: obj[f].translatedValue}))
    .value();
}

function createSpouseUpdateSummary(changes) {
  const updatedSpouseFields = extractFields(changes, SPOUSE_FIELDS);
  if (!updatedSpouseFields.length) return null;

  if (_.has(changes, 'hasSpouse')) {
    if (changes.hasSpouse.value) {
      return {
        type: UPDATE_SUMMARY_TYPES.ADDED_SPOUSE,
        fields: updatedSpouseFields
      };
    }

    return {
      type: UPDATE_SUMMARY_TYPES.REMOVED_SPOUSE,
      fields: updatedSpouseFields
    };
  }

  return {
    type: UPDATE_SUMMARY_TYPES.UPDATED_SPOUSE,
    fields: updatedSpouseFields
  };
}

function createDependantUpdateSummary(dependant) {
  const updatedDependantFields = extractFields(
    dependant,
    _.chain(dependant).keys().without('destroy', 'id')
  );
  if (!updatedDependantFields.length) return null;

  if (dependant.destroy) {
    return {
      type: UPDATE_SUMMARY_TYPES.REMOVED_DEPENDANT,
      fields: updatedDependantFields
    };
  }

  if (!dependant.id) {
    return {
      type: UPDATE_SUMMARY_TYPES.ADDED_DEPENDANT,
      fields: updatedDependantFields
    };
  }

  return {
    type: UPDATE_SUMMARY_TYPES.UPDATED_DEPENDANT,
    fields: updatedDependantFields
  };
}

function createCoverageUpdateSummary(changes) {
  const updatedCoverageFields = extractFields(changes, COVERAGE_FIELDS);
  if (!updatedCoverageFields.length) return null;

  return {
    type: UPDATE_SUMMARY_TYPES.UPDATED_COVERAGE,
    fields: updatedCoverageFields
  };
}

function createUpdateSummaries(changes, type) {
  return _.compact([
    createSpouseUpdateSummary(changes),
    ..._.map(changes.dependents, d => createDependantUpdateSummary(d)),
    createCoverageUpdateSummary(changes)
  ]);
}

export default createUpdateSummaries;
