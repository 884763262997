import {action, observable, computed} from 'mobx';
import JobTimelineEntryViewModel from './JobTimelineEntryViewModel';
import _ from 'lodash';
import {auth, endpoints, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import {OnLeaveRecord, TerminationRecord, EmploymentRecord, ReactivationRecord} from 'stores/employees';
import {tabResourceId, groupEmployeeCustomFields} from 'shared/tools';

class EmployeeRoleState {
  store = new DomainStore();
  employeeId;
  history;

  @observable customFieldGroups;
  @observable entries = [];
  @observable deleteRecord = null;
  @observable deleteModalOpen = false;
  @observable onLeaveModalOpen = false;
  @observable scheduledForTermination;
  @observable editConfirmationModalOpen = false;
  @observable editRecord;
  @observable scope = {};

  receiveProps({parentState, history}) {
    this.parentState = parentState;
    this.history = history;
  }

  @action async load() {
    this.employeeId = this.parentState.match.params.id;

    await this.store._compose([
      endpoints.CUSTOM_FIELD_GROUPS.with('job')
    ]);

    this.customFieldGroups = this.store._getAll(types.CUSTOM_FIELD_GROUP);

    await this.refreshRecords();
  }

  async loadEmploymentRecords(employeeId) {
    const store = new DomainStore();

    await store._compose([
      endpoints.EMPLOYEE.EMPLOYMENT_HISTORY.with(employeeId),
      endpoints.EMPLOYEE.EMPLOYMENT_HISTORY.NAVIGATION.with(employeeId),
      endpoints.LOCATIONS.ALL,
      endpoints.DEPARTMENTS,
      endpoints.LEAVE_TYPES
    ]);

    const employmentRecords = store._getAll(types.EMPLOYMENT_RECORD).map(r => new EmploymentRecord(r));
    const terminationRecords = store._getAll(types.TERMINATION_RECORD).map(r => new TerminationRecord(r));
    const onLeaveRecords = store._getAll(types.ON_LEAVE_RECORD).map(r => new OnLeaveRecord(r));
    const reactivationRecords = store._getAll(types.REACTIVATION_RECORD).map(r => new ReactivationRecord(r));
    this.scope = store._getSingle(types.NAVIGATION_SCOPE, {id: tabResourceId('employee', this.employeeId)}).tabs;

    return [...employmentRecords, ...terminationRecords, ...onLeaveRecords, ...reactivationRecords];
  }

  @action async refreshRecords() {
    this.entries = (await this.loadEmploymentRecords(this.employeeId)).map(record => new JobTimelineEntryViewModel(record));
    this.updateFirstAndLast();
    this.currentRecordViewModel.expanded = true;
  }

  @action updateFirstAndLast() {
    const entries = this.sortedEntries;
    const firstRecord = _.head(entries);
    const lastRecord = _.last(entries);
    for (const record of entries) {
      record.isFirst = firstRecord === record;
      record.isLast = lastRecord === record;
    }
  }

  @action createNewRecord() {
    this.history.push(`/${this.employeeId}/job/records/create`);
  }

  @action showDeleteModal(record) {
    this.deleteRecord = record;
    this.deleteModalOpen = true;
  }

  @action openEditConfirmationModal(record) {
    this.editRecord = record;
    this.editConfirmationModalOpen = true;
  }

  @action closeEditConfirmationModal() {
    this.editRecord = null;
    this.editConfirmationModalOpen = false;
  }

  @action editEntry() {
    this.history.push(`/${this.employeeId}/job/records/${this.editRecord.id}`);
  }

  @action cancelDeleteModal() {
    this.deleteRecord = null;
    this.deleteModalOpen = false;
  }

  @action async destroyEmploymentRecord() {
    await this.store.destroy(this.deleteRecord);
    await this.refreshRecords();
    this.deleteModalOpen = false;
  }

  @action terminateEmployee() {
    this.history.push(`/${this.employeeId}/job/records/terminate`);
  }

  @action reactivateEmployee() {
    this.history.push(`/${this.employeeId}/job/records/reactivate`);
  }

  @action handleOnLeave() {
    if (this.parentState.employee.enrolledInBenefits) {
      this.openOnLeaveModal();
    } else {
      this.placeOnLeave();
    }
  }

  @action placeOnLeave() {
    this.history.push(`/${this.employeeId}/job/records/leave`);
  }

  @action openOnLeaveModal() {
    this.onLeaveModalOpen = true;
  }

  @action closeOnLeaveModal() {
    this.onLeaveModalOpen = false;
  }

  customFieldGroupsForRecord(record) {
    return groupEmployeeCustomFields(record.employmentRecordCustomFields, this.customFieldGroups);
  }

  @computed get currentRecordViewModel() {
    return _.find(this.entries, r => r.record.current);
  }

  @computed get sortedEntries() {
    return _.orderBy(this.entries, ['record.effectiveDate'], ['desc']);
  }

  @computed get canTerminate() {
    return this.scope.createTerminationRecord;
  }

  @computed get canReactivate() {
    return this.scope.createReactivationRecord;
  }

  @computed get validActions() {
    const actionKeys = _.keys(this.scope);

    return _.filter(actionKeys, (key) => this.scope[key]);
  }

  @computed get recordType() {
    return _.get(this.deleteRecord, '_type');
  }

  @computed get payrollEnabled() {
    return this.parentState.employee.payrollEnabled && auth.company.payrollEnabled;
  }
}

export default EmployeeRoleState;
