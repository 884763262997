import _ from 'lodash';

const COLOURS_4_OR_LESS = [
  '#21CCC1',
  '#217AF2',
  '#9A3BE8',
  '#F64A6C'
];

const COLOURS_6_OR_LESS = [
  '#21CCC1',
  '#217AF2',
  '#9A3BE8',
  '#F64A6C',
  '#F4AC29',
  '#80D137'
];

const COLOURS_8_OR_LESS = [
  '#21CCC1',
  '#217AF2',
  '#9A3BE8',
  '#D61CBB',
  '#F64A6C',
  '#FD6C21',
  '#F4AC29',
  '#80D137'
];

const COLOURS_11_OR_LESS = [
  '#21CCC1',
  '#217AF2',
  '#1C17B8',
  '#9A3BE8',
  '#D61CBB',
  '#F64A6C',
  '#FD6C21',
  '#F4AC29',
  '#80D137',
  '#159488'
];

const COLOURS_12_OR_MORE = [
  '#21CCC1',
  '#217AF2',
  '#1C17B8',
  '#9A3BE8',
  '#D61CBB',
  '#F64A6C',
  '#FD6C21',
  '#F4AC29',
  '#80D137',
  '#159488',
  '#1FC1B6',
  '#1876F2',
  '#1C16B6',
  '#9836E7',
  '#C71AAD',
  '#F52952',
  '#FD5D0D',
  '#F3A616',
  '#75C82D',
  '#148F83'
];

export const coloursBySize = (size) => {
  if (size <= 4) {
    return [...COLOURS_4_OR_LESS];
  } else if (size <= 6) {
    return [...COLOURS_6_OR_LESS];
  } else if (size <= 8) {
    return [...COLOURS_8_OR_LESS];
  } else if (size <= 11) {
    return [...COLOURS_11_OR_LESS];
  } else {
    return [...COLOURS_12_OR_MORE];
  }
};

export default (series) => {
  return coloursBySize(_.size(series));
};
