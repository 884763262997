import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {noop} from 'shared/tools';
import {FormattedMessage, injectIntl} from 'react-intl';
import {ActionLink, FilterDropdown, RadioButtonGroup, RadioButton} from 'components';
import DateFilter from 'containers/reports/components/DateFilter';
import DateRange from 'containers/reports/components/DateRange';

const SingleDateFilter = observer(({viewModel}) => {
  if (viewModel.isRangeOperator) return null;
  const { editingRow } = viewModel;

  return (
    <DateFilter filters={{date: editingRow.value}}
                onChange={({date}) => viewModel.updateDate(date)}/>
  );
});

const RangeDateFilter = observer(({viewModel}) => {
  if (!viewModel.isRangeOperator) return null;
  const { editingRow } = viewModel;

  return (
    <DateRange filters={{startDate: editingRow.range.lower_bound, endDate: editingRow.range.upper_bound}}
               onChange={({startDate, endDate}) => viewModel.updateDateRange(startDate, endDate)}/>
  );
});

const DateFilterRow = injectIntl(observer(({uiState, viewModel, intl}) => {
  const { editingRow, viewOnly } = viewModel;

  return (
    <div className='py1'>
      <div className='table-cell align-top pr1'>
        <FilterDropdown isOpen={false}
                        onOpen={noop}
                        onCancel={noop}
                        selected={[intl.formatMessage({id: `reports.${viewModel.category}`})]}
                        disabled={true}/>
      </div>
      <div className='table-cell align-top pr1'>
        <FilterDropdown isOpen={viewModel.operatorOpen}
                        onOpen={() => { uiState.closeFilters(); viewModel.openOperator(); }}
                        onCancel={() => viewModel.closeOperator()}
                        selected={[intl.formatMessage({id: `reports.${viewModel.operatorView(editingRow.operator)}`})]}
                        onApply={() => { viewModel.updateRow(); uiState.applyFilters(); }}
                        disabled={viewOnly}>
          <RadioButtonGroup name={`${viewModel.category}_operator`}
                            onChange={(name, value) => viewModel.editOperator(value)}
                            selectedValue={editingRow.operator}>
            {viewModel.operators.map(o =>
              <RadioButton className='block pb1' key={o} value={o}><FormattedMessage id={`reports.${viewModel.operatorView(o)}`}/></RadioButton>
            )}
          </RadioButtonGroup>
        </FilterDropdown>
      </div>
      <div className='table-cell align-top pr1'>
        <FilterDropdown isOpen={viewModel.dateOpen}
                        onOpen={() => { uiState.closeFilters(); viewModel.openDate(); }}
                        onCancel={() => viewModel.closeDate()}
                        selected={viewModel.isRangeOperator ? viewModel.dateRangeView : viewModel.dateView}
                        onApply={() => { viewModel.updateRow(); uiState.applyFilters(); }}
                        separator={<FormattedMessage id='reports.to'/>}
                        disabled={viewOnly}>
          <div className='mb2'>
            <SingleDateFilter viewModel={viewModel}/>
            <RangeDateFilter viewModel={viewModel}/>
          </div>
        </FilterDropdown>
      </div>
      {!viewOnly && <div className='table-cell align-middle pl1'>
        <ActionLink onClick={() => uiState.toggleFilterRow(viewModel.category, false)} className='meadow'>
          <i className='material-icons h3 align-middle'>{'close'}</i>
        </ActionLink>
      </div>}
    </div>
  );
}));

export default DateFilterRow;
