import {observable} from 'mobx';

class CompanyDocumentViewModel {
  @observable uiState;
  @observable companyDocument;
  @observable checked = false;
  @observable selected = false;

  constructor(companyDocument, uiState) {
    this.companyDocument = companyDocument;
    this.uiState = uiState;

    this.selected = !!companyDocument.draftEmployeeId;
  }
}

export default CompanyDocumentViewModel;
