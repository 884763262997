import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {withState} from 'shared/core';
import {Panel, Button} from 'components';
import {CoverageFields} from 'components/benefits';
import {EmployeeOnboardingState} from '../../state';
import {FormattedMessage} from 'react-intl';

const OnboardCoverageContainer = observer(({uiState}) => {
  const {benefitInfo, errors} = uiState;

  return (
    <div className='Container'>
      <div className='clearfix mb4'>
        <div className='col-12 sm-col-9 mx-auto'>
          <Panel>
            <CoverageFields benefitInfo={benefitInfo} errors={errors} />
            <div className='mt2 right-align'>
              <Button onClick={() => uiState.saveBenefitInfo()} className='ml2'>
                <FormattedMessage id='onboard.coverage.Next'/>
              </Button>
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
});

export default withState(OnboardCoverageContainer, EmployeeOnboardingState);
