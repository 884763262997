import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import FilterRow from 'reports/components/filters/FilterRow';

const FilterList = observer(({uiState}) => {
  return (
    <div className='mt2 pt2 border-top'>
      {uiState.showFilterRows && <div>
        <div className='pb1 jumbo'>
          <FormattedMessage id='reports.custom.edit.The report will only include results that match the following criteria'/>
        </div>
        {uiState.filterRows.map(row => <FilterRow key={row.category} uiState={uiState} viewModel={row}/>)}
      </div>}
      {!uiState.showFilterRows && <div className='jumbo'>
        <FormattedMessage id='reports.custom.edit.No filters applied. All results will be included.'/>
      </div>}
    </div>
  );
});

export default FilterList;
