import React from 'react';
import {observer} from 'mobx-react';
import {FormLayout, BirthdayPicker, DatePicker, Input, Select2} from 'components';
import {t} from 'shared/core';

const SpouseForm = observer(({benefitInfo, errors}) => {
  if (!benefitInfo.hasSpouse) return null;

  return (
    <FormLayout>
      <FormLayout.Group>
        <Select2 name='spousalRelationship' value={benefitInfo.spousalRelationship}
          label='components.benefits.Relationship Type'
          errorMessage={errors.spousalRelationship}
          onChange={e => benefitInfo.merge({spousalRelationship: e.target.value})}
        >
          <option value='married'>{t('models.benefit_info.spousal_relationship.married')}</option>
          <option value='common_law'>{t('models.benefit_info.spousal_relationship.common_law')}</option>
          <option value='civil_union'>{t('models.benefit_info.spousal_relationship.civil_union')}</option>
        </Select2>
        <DatePicker className='full-width'
          label={
            benefitInfo.spousalRelationship ?
              t(`models.benefit_info.relationship_dates.${benefitInfo.spousalRelationship}`) : '- -'
          }
          disabled={!benefitInfo.spousalRelationship}
          onChange={date => benefitInfo.merge({spouseMarriageDate: date})}
          value={benefitInfo.spouseMarriageDate}
          options={{startView: 2}}
          errorMessage={errors.spouseMarriageDate}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <Input
          label="components.benefits.Spouse's First Name"
          onChange={e => benefitInfo.merge({spouseFirstName: e.target.value})}
          value={benefitInfo.spouseFirstName}
          errorMessage={errors.spouseFirstName} />
        <Input
          label="components.benefits.Spouse's Last Name"
          onChange={e => benefitInfo.merge({spouseLastName: e.target.value})}
          value={benefitInfo.spouseLastName}
          errorMessage={errors.spouseLastName} />
      </FormLayout.Group>
      <FormLayout.Group>
        <BirthdayPicker className='full-width'
          minAge={16} maxAge={99}
          label="components.benefits.Spouse's Birthdate"
          onChange={date => benefitInfo.merge({spouseBirthDate: date})}
          value={benefitInfo.spouseBirthDate}
          options={{startView: 2}}
          errorMessage={errors.spouseBirthDate}
        />
        <Select2 name='spousalGender' value={benefitInfo.spousalGender}
          label="components.benefits.Spouse's Sex at Birth"
          errorMessage={errors.spousalGender}
          onChange={(e) => benefitInfo.merge({spousalGender: e.target.value})}>
          <option key='Male' value='male'>{t('Male')}</option>
          <option key='Female' value='female'>{t('Female')}</option>
        </Select2>
      </FormLayout.Group>
    </FormLayout>
  );
});

export default SpouseForm;
