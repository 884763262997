import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class Company extends DomainObject {
  @observable phoneNumber;
  @observable employeeCount;
  @observable name;
  @observable locations = [
    {
      regionCode: 'ON',
      countryCode: 'CA',
      name: 'Head Office'
    }
  ];
  @observable demoAccount;
  @observable hqCaRegionCode;
  @observable suggestedHqCaRegionCode;
  @observable payrollEnabled;
  @observable orgChartEnabled;
  @observable jazzHRApiKey;
  @observable greenhouseApiKey;
  @observable bankingEnabled;
  @observable showPTOCalendarType;
}

export default Company;
