import React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {FormattedMessage} from 'react-intl';
import uuid from 'uuid';

import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  $insertNodes
} from 'lexical';
import {$isLinkNode, TOGGLE_LINK_COMMAND} from '@lexical/link';
import {$wrapNodes, $isAtNodeEnd} from '@lexical/selection';
import {$getNearestNodeOfType, mergeRegister} from '@lexical/utils';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode
} from '@lexical/list';
import {$createHeadingNode, $isHeadingNode} from '@lexical/rich-text';
import {Modal, ModalButtons} from '../../modals';
import {Input} from '../../index';

import {DropdownSelect} from 'components';
import {t} from 'shared/core';
import {$createMergeFieldNode} from 'components/lexical_editor/nodes/MergeFieldNode.js';
import {INSERT_IMAGE_COMMAND} from 'components/lexical_editor/plugins/ImagesPlugin';
import ImageUploadModal from 'components/modals/image_upload_modal/ImageUploadModal';

const LowPriority = 1;
const MaxPriority = 4;
const editorLeftMargin = 8;
const editorLeftPadding = 12;
const linkEditorWidth = 300;

function Divider() {
  return <div className='divider'/>;
}

function positionEditorElement(editor, rect) {
  if (rect === null) {
    editor.style.opacity = '0';
    editor.style.top = '-1000px';
    editor.style.left = '-1000px';
  } else {
    editor.style.opacity = '1';
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${
      Math.min(rect.left + window.pageXOffset - editorLeftPadding - editorLeftMargin, window.innerWidth - linkEditorWidth - 10)
    }px`;
  }
}

function FloatingLinkEditor({editor}) {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }
    const editorElem = editorRef.current;

    if (editorElem === null) {
      return;
    }

    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== 'link-input') {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl('');
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <div ref={editorRef} className='link-editor'>
      {isEditMode ? (
        <input
          ref={inputRef}
          className='link-input'
          value={linkUrl}
          type='text'
          onChange={(event) => {
            setLinkUrl(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              if (lastSelection !== null) {
                if (linkUrl !== '') {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                }
                setEditMode(false);
              }
            } else if (event.key === 'Escape') {
              event.preventDefault();
              setEditMode(false);
            }
          }}
        />
      ) : (
        <React.Fragment>
          <div className='link-input read-only'>
            <a href={linkUrl} target='_blank' rel='noopener noreferrer'>
              {linkUrl}
            </a>
            <div
              className='link-edit'
              role='button'
              onClick={() => setEditMode(true)}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

function BlockTypeControls({editor, blockType, disableHeaders}) {
  const formatParagraph = () => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatLargeHeading = () => {
    if (blockType !== 'h1') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h1'));
        }
      });
    } else {
      formatParagraph();
    }
  };

  const formatSmallHeading = () => {
    if (blockType !== 'h2') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode('h2'));
        }
      });
    } else {
      formatParagraph();
    }
  };

  const formatBulletList = () => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  return (
    <React.Fragment>
      <Divider/>
      {!disableHeaders && <button className={`toolbar-item spaced ${blockType === 'h1' ? 'active' : ''}`} onClick={formatLargeHeading}>
        <i className='format large-heading'/>
        <div className='lexical-tooltip'><FormattedMessage id='components.lexical.Header One'/></div>
      </button>}
      {!disableHeaders && <button className={`toolbar-item spaced ${blockType === 'h2' ? 'active' : ''}`} onClick={formatSmallHeading}>
        <i className='format small-heading'/>
        <div className='lexical-tooltip'><FormattedMessage id='components.lexical.Header Two'/></div>
      </button>}
      <button className={`toolbar-item spaced ${blockType === 'ul' ? 'active' : ''}`} onClick={formatBulletList}>
        <i className='format bullet-list'/>
        <div className='lexical-tooltip'><FormattedMessage id='components.lexical.Bullet List'/></div>
      </button>
      <button className={`toolbar-item spaced ${blockType === 'ol' ? 'active' : ''}`} onClick={formatNumberedList}>
        <i className='format numbered-list'/>
        <div className='lexical-tooltip'><FormattedMessage id='components.lexical.Numbered List'/></div>
      </button>
    </React.Fragment>
  );
}

function addCustomMergeField(editor, mergeFieldName) {
  insertMergeField(editor, {
    key: uuid.v4().replaceAll('-', ''),
    placeholder: `#[${mergeFieldName}]`,
  });
}

function insertMergeField(editor, field) {
  editor.update(() => {
    const mergeField = $createMergeFieldNode(
      t(field.placeholder),
      field.key
    );
    $insertNodes([mergeField]);
  });
}

function resizeImage(imgToResize) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  const originalWidth = imgToResize.width;
  const originalHeight = imgToResize.height;

  let resizingFactor = Math.min(600 / originalHeight, 600 / originalWidth);

  if (resizingFactor >= 1) {
    return imgToResize.src;
  }

  canvas.width = originalWidth * resizingFactor;
  canvas.height = originalHeight * resizingFactor;

  context.drawImage(
    imgToResize,
    0,
    0,
    originalWidth * resizingFactor,
    originalHeight * resizingFactor
  );

  return canvas.toDataURL();
}

async function insertLogo(editor, logoUrl) {
  let blob = await fetch(logoUrl).then(r => r.blob());
  await insertImage(editor, blob);
}

async function insertImage(editor, blob) {
  let imageObj = new Image();
  imageObj.onload = () => {
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
      src: resizeImage(imageObj)
    });
  };
  imageObj.src = await new Promise(resolve => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

function insertSignature(editor, signature) {
  editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
    width: signature.WIDTH,
    height: signature.HEIGHT,
    src: signature.signatureData,
    altText: signature.name,
    dataKey: signature.id,
    dataEntityType: signature.isNew ? 'EMPLOYEE_SIGNATURE' : 'COMPANY_SIGNATURE'
  });
}

function AlignmentControls({editor}) {
  return (
    <React.Fragment>
      <Divider/>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
        }}
        className='toolbar-item spaced'
        aria-label='Left Align'
      >
        <i className='format left-align'/>
        <div className='lexical-tooltip'><FormattedMessage id='components.lexical.Left Align'/></div>
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
        }}
        className='toolbar-item spaced'
        aria-label='Center Align'
      >
        <i className='format center-align'/>
        <div className='lexical-tooltip'><FormattedMessage id='components.lexical.Center Align'/></div>
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
        }}
        className='toolbar-item spaced'
        aria-label='Right Align'
      >
        <i className='format right-align'/>
        <div className='lexical-tooltip'><FormattedMessage id='components.lexical.Right Align'/></div>
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify');
        }}
        className='toolbar-item'
        aria-label='Justify Align'
      >
        <i className='format justify-align'/>
        <div className='lexical-tooltip'><FormattedMessage id='components.lexical.Justify Align'/></div>
      </button>
    </React.Fragment>
  );
}

function TextFormattingControls({editor, isBold, isItalic, isStrikethrough, isUnderline, isLink}) {
  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  return (
    <React.Fragment>
      <Divider/>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
        className={'toolbar-item spaced ' + (isBold ? 'active' : '')}
        aria-label='Format Bold'
      >
        <i className='format bold'/>
        <div className='lexical-tooltip'><FormattedMessage id='components.lexical.Bold'/></div>
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
        className={'toolbar-item spaced ' + (isItalic ? 'active' : '')}
        aria-label='Format Italics'
      >
        <i className='format italic'/>
        <div className='lexical-tooltip'><FormattedMessage id='components.lexical.Italic'/></div>
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
        className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}
        aria-label='Format Underline'
      >
        <div className='lexical-tooltip'><FormattedMessage id='components.lexical.Underline'/></div>
        <i className='format underline'/>
      </button>
      <button
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
        }}
        className={
          'toolbar-item spaced ' + (isStrikethrough ? 'active' : '')
        }
        aria-label='Format Strikethrough'
      >
        <i className='format strikethrough'/>
        <div className='lexical-tooltip'><FormattedMessage id='components.lexical.Strikethrough'/></div>
      </button>
      <button
        onClick={insertLink}
        className={'toolbar-item spaced ' + (isLink ? 'active' : '')}
        aria-label='Insert Link'
      >
        <i className='format link'/>
        <div className='lexical-tooltip'><FormattedMessage id='components.lexical.Link'/></div>
      </button>
      {isLink &&
        createPortal(<FloatingLinkEditor editor={editor}/>, document.body)}
    </React.Fragment>
  );
}

function UndoRedoControls({editor}) {
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor]);

  return (
    <React.Fragment>
      <button
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND);
        }}
        className='toolbar-item spaced'
        aria-label='Undo'
      >
        <i className='format undo'/>
        <div className='lexical-tooltip'><FormattedMessage id='components.lexical.Undo'/></div>
      </button>
      <button
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND);
        }}
        className='toolbar-item'
        aria-label='Redo'
      >
        <i className='format redo'/>
        <div className='lexical-tooltip'><FormattedMessage id='components.lexical.Redo'/></div>
      </button>
    </React.Fragment>
  );
}

function InsertMenu({editor, mergeFields, signatures, enableCustomMergeFields, openMergeFieldModal, logoUrl, openUploadImageModal}) {
  return <React.Fragment>
    <Divider/>
    {mergeFields &&
      <div className='flex align-items-center pl1'>
        <DropdownSelect selected={t('components.lexical.Dynamic Fields')} buttonTrait='default'
                        align='left' buttonSize='sm' size='sm'>
          {mergeFields.map((field, index) =>
            <DropdownSelect.Option
              separator={field.separator}
              key={index}
              text={t(field.label)}
              onClick={() => insertMergeField(editor, field)}
            />
          )}
          {enableCustomMergeFields && <DropdownSelect.Option onClick={() => openMergeFieldModal()} text={t('components.lexical.Add New')}/>}
        </DropdownSelect>
      </div>
    }
    {signatures &&
      <div className='flex align-items-center pl1'>
        <DropdownSelect selected={t('components.lexical.Signatures')} buttonTrait='default'
                        align='left' buttonSize='sm'>
          {signatures.map((signature, index) =>
            <DropdownSelect.Option
              key={index}
              onClick={() => insertSignature(editor, signature)}
              text={signature.name}
              separator={signature.annotationType === 'employee_signature'}
            />
          )}
        </DropdownSelect>
      </div>
    }
    <div className='flex align-items-center pl1'>
        <DropdownSelect selected={t('components.lexical.Images')} buttonTrait='default'
                        align='left' buttonSize='sm'>
          {!!logoUrl && <DropdownSelect.Option
            key={0}
            onClick={() => insertLogo(editor, logoUrl)}
            text={t('components.lexical.Company Logo')}
          />}
          <DropdownSelect.Option
            key={1}
            onClick={openUploadImageModal}
            text={t('components.lexical.Upload Image')}
          />
        </DropdownSelect>
      </div>
  </React.Fragment>;
}

export default function ToolbarPlugin(props) {
  const {mergeFields, signatures, enableCustomMergeFields, disableStyling, disableHeaders, logoUrl} = props;

  const [editor] = useLexicalComposerContext();
  const [blockType, setBlockType] = useState('paragraph');
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [customMergeFieldModalOpen, setCustomMergeFieldModalOpen] = useState(false);
  const [customMergeFieldName, setCustomMergeFieldName] = useState('');
  const [uploadImageModalOpen, setUploadImageModalOpen] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }

      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));

      const node = getSelectedNode(selection);
      const parent = node.getParent();
      setIsLink(!!($isLinkNode(parent) || $isLinkNode(node)));
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _editor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    if (disableStyling) {
      return editor.registerCommand(
        FORMAT_TEXT_COMMAND,
        () => {
          return true;
        },
        MaxPriority
      );
    }
  }, []);

  const _onSaveCustomMergeField = () => {
    addCustomMergeField(editor, customMergeFieldName);
    setCustomMergeFieldModalOpen(false);
  };

  const _onSaveImageUpload = async (blob) => {
    await insertImage(editor, blob);
  };

  return (
    <div className='toolbar'>
      <UndoRedoControls editor={editor}/>
      {!disableStyling &&
        <React.Fragment>
          <TextFormattingControls editor={editor} isBold={isBold} isLink={isLink} isItalic={isItalic}
                                  isStrikethrough={isStrikethrough} isUnderline={isUnderline}/>
          <BlockTypeControls editor={editor} blockType={blockType} disableHeaders={disableHeaders}/>
          <AlignmentControls editor={editor}/>
        </React.Fragment>}
      <InsertMenu
        editor={editor} mergeFields={mergeFields} signatures={signatures}
        enableCustomMergeFields={enableCustomMergeFields}
        openMergeFieldModal={() => setCustomMergeFieldModalOpen(true)}
        openUploadImageModal={() => setUploadImageModalOpen(true)}
        logoUrl={logoUrl}
      />
      <Modal
        title='components.lexical.Add Field'
        isOpen={customMergeFieldModalOpen}
        onHide={() => setCustomMergeFieldModalOpen(false)}
        size='md'
      >
        <Input
          label='components.lexical.Field Name'
          value={customMergeFieldName}
          onChange={(e) => setCustomMergeFieldName(e.target.value)}
        />
        <ModalButtons
          onCancel={() => setCustomMergeFieldModalOpen(false)}
          onSave={() => _onSaveCustomMergeField()}
        />
      </Modal>
      <ImageUploadModal
        isOpen={uploadImageModalOpen}
        onHide={() => setUploadImageModalOpen(false)}
        onSave={(blob) => _onSaveImageUpload(blob)}
      />
    </div>
  );
}
