import {DomainObject, oneOf} from 'shared/store';
import {observable, action, computed} from 'mobx';
import CustomField from 'stores/custom_fields/CustomField';
import _ from 'lodash';
import {t} from 'shared/core';
import Recipient from 'stores/reminders/Recipient';

class Reminder extends DomainObject {
  @observable id;
  @observable relativeDateValue = 0;
  @observable relativeDateUnit = 'days';
  @observable reminderType;
  @observable customField;
  @observable recipients = [];
  @observable description;

  @action merge(other) {
    super.merge(other, {
      reminderType: oneOf(
        [
          'custom_field',
          'first_day_of_benefits',
          'last_day_of_benefits',
          'anniversary',
          'birthday',
          'sin_expiry_date',
          'first_day_approved_time_off',
          'first_day_of_work',
          'last_day_of_work',
          'first_day_of_leave'
        ]
      ),
      relativeDateUnit: oneOf(['days', 'weeks', 'months']),
      customField: CustomField,
      recipients: [Recipient]
    });
  }

  @computed get absoluteDateValue() {
    return Math.abs(this.relativeDateValue);
  }

  @computed get targetName() {
    switch (this.reminderType) {
      case 'custom_field':
        return _.get(this.customField, 'name');
      case 'last_day_of_benefits':
      case 'first_day_of_benefits':
      case 'birthday':
      case 'anniversary':
      case 'sin_expiry_date':
      case 'first_day_approved_time_off':
      case 'first_day_of_work':
      case 'last_day_of_work':
      case 'first_day_of_leave':
        return t(`models.reminders.types.${this.reminderType}`);
      default:
        throw new Error(`Reminder type ${this.reminderType} not supported.`);
    }
  }

  @computed get date() {
    if (this.relativeDateValue === 0) {
      return t('company_settings.reminders.On due date');
    }

    let message;
    switch (this.relativeDateUnit) {
      case 'days':
        message = this.relativeDateValue < 0 ? 'RELATIVE_DATE_DAYS_BEFORE' : 'RELATIVE_DATE_DAYS_AFTER';
        break;
      case 'weeks':
        message = this.relativeDateValue < 0 ? 'RELATIVE_DATE_WEEKS_BEFORE' : 'RELATIVE_DATE_WEEKS_AFTER';
        break;
      case 'months':
        message = this.relativeDateValue < 0 ? 'RELATIVE_DATE_MONTHS_BEFORE' : 'RELATIVE_DATE_MONTHS_AFTER';
        break;
      default:
        throw new Error(`Date unit ${this.relativeDateUnit} not supported.`);
    }

    return t(`models.reminders.dates.${message}`, {number: this.absoluteDateValue});
  }

  @computed get  recipientNames() {
    return _.map(this.recipients, 'name').join(', ');
  }
}

export default Reminder;
