import React from 'react';
import Logo from 'img/collage-logo--blue@2x.png';
import TimerIcon from 'img/timer-icon.png';
import {FormattedMessage} from 'react-intl';
import {auth} from 'shared/core';

const Header = () => {
  return (
    <div className='bg-white border-bottom center py1'>
      <a href='https://collage.co' className='inline-block p2'>
        <img src={Logo} alt='Collage' height='26'/>
      </a>
    </div>
  );
};

const ScheduleDemoPanel = () => {
  let calendlyLink;

  switch(auth.locale) {
    case 'en':
      calendlyLink = 'https://calendly.com/collage-sales-to/collage-product-demo';
      break;
    case 'fr':
      calendlyLink = 'https://calendly.com/collage-demo-qa/collage-demo-fr';
      break;
    default:
      throw new Error(`The locale provided: ${auth.locale} does not have a calendly link associated with it.`);
  }

  return (
    <div className='ResponsiveContainer'>
      <div className='clearfix my4 center'>
        <div className='col-12 md-col-8 lg-col-6 max-width-3 mx-auto bg-white rounded box-shadow-2 px4 py4'>
          <div className='mb3'>
            <img src={TimerIcon} alt='Trial Ended' height='135'/>
          </div>
          <div className='col col-12 h2 mb3'>
            <FormattedMessage id='billing_subscriptions.TRIAL_ENDED_DESCRIPTION'/>
          </div>
          <div>
            <a href={calendlyLink}>
              <FormattedMessage id='billing_subscriptions.Request a demo'/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const TrialEndedPage = () => {
  return (
    <div>
      <Header/>
      <ScheduleDemoPanel/>
    </div>
  );
};

export default TrialEndedPage;
