import React from 'react';
import {observer} from 'mobx-react';
import {InteractiveTable, Button, EmployeeNameWithAvatar, Layout, QuickTip} from 'components';
import {types, endpoints, t} from 'shared/core';
import {ExternalCandidate} from 'stores/integrations';
import {FormattedMessage} from 'react-intl';

const LOCALIZATION = {
  emptyState: 'integrations.external_candidates.You have no new external candidates.',
  removeModal: {
    header: 'integrations.external_candidates.Remove External Candidate from Collage?',
    subHeader: 'integrations.external_candidates.Are you sure you want to remove this external candidate?',
    body: 'integrations.external_candidates.This candidate will no longer be synced from JazzHR.'
  }
};

const HireButton = observer(({model, uiState}) => {
  return (
    <Button
      onClick={() => uiState.hireCandidate(model)}
      trait='default'
      staysDisabledAfterClick
    >
      <FormattedMessage id='integrations.external_candidates.Onboard'/>
    </Button>
  );
});

const COLUMNS = [
  {
    header: 'integrations.external_candidates.Name',
    render: model => <EmployeeNameWithAvatar employee={model}/>,
    sortBy: 'name',
    width: 3
  },
  {
    header: 'integrations.external_candidates.Email',
    attribute: 'email',
    className: 'fs-hide',
    width: 3
  },
  {
    header: 'integrations.external_candidates.Title',
    attribute: 'jobTitle',
    width: 3
  },
  {
    component: HireButton,
    width: 2,
    className: 'right-align'
  }
];

const ErrorMessage = observer(({uiState}) => {
  if (!uiState.syncFailed) return null;

  return (
    <div className='pb2'>
      <QuickTip
        header={<FormattedMessage id='integrations.external_candidates.Could not connect to JazzHR'/>}
        trait='error'
        paragraphs={[
          <FormattedMessage id='integrations.external_candidates.There was a problem connecting to JazzHR. If a candidate is missing from the list below, please refresh the page to try again.'/>,
        ]}
      />
    </div>
  );
});

const ExternalCandidatesDirectory = observer(({uiState}) => {
  const {localization} = uiState.vendor;

  return (
    <Layout>
      <ErrorMessage uiState={uiState}/>
      <InteractiveTable
        saveStateToUrl
        uiState={uiState}
        title={t(localization.header)}
        columns={COLUMNS}
        localization={LOCALIZATION}
        onRemove={(candidate) => uiState.destroyCandidate(candidate)}
        proxy={{
          type: 'async',
          modelType: types.INTEGRATIONS.EXTERNAL_CANDIDATE,
          model: ExternalCandidate,
          endpoint: endpoints.INTEGRATIONS.EXTERNAL_CANDIDATES.with(uiState.vendor.endpoint)
        }}
      />
    </Layout>
  );
});

export default ExternalCandidatesDirectory;
