import {action, observable} from 'mobx';
import countryStore from 'stores/countries/CountryStore';


class CountryAndRegionState {
  countryStore = countryStore;

  @observable countries = [];
  @observable regions = [];
  @observable errors
  @observable onChange
  @observable countryCode
  @observable regionCode
  @observable withFormLayoutGroup

  receiveProps({initialCountryCode, initialRegionCode, onChange, errors, defaultToCanada, wrapper}) {
    if (initialCountryCode) {
      this.countryCode = initialCountryCode;
      this.regionCode = initialRegionCode;
    } else if (defaultToCanada) {
      this.countryCode = 'CA';
      onChange({countryCode: 'CA', regionCode: null});
    }
    this.onChange = onChange;
    this.errors = errors;
    this.wrapper = wrapper;
  }

  @action async load() {
    this.countries = await this.countryStore.loadCountries();

    if (this.countryCode) {
      this.regions = await this.countryStore.getOrLoadRegions(this.countryCode);
    }
  }

  @action async onCountryChange(newCountryCode) {
    this.countryCode = newCountryCode;
    this.regionCode = null;
    this.onChange({countryCode: this.countryCode, regionCode: this.regionCode});
    this.regions = await this.countryStore.getOrLoadRegions(this.countryCode);
  }

  @action onRegionChange(newRegionCode) {
    this.regionCode = newRegionCode;
    this.onChange({countryCode: this.countryCode, regionCode: this.regionCode});
  }
}

export default CountryAndRegionState;
