import React from 'react';
import {observer} from 'mobx-react';
import ReactSelect from './ReactSelect';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';

const ObjectReactSelect = observer(({items, value, display, by, className, translatable, onChange}) => {
  return (
    <ReactSelect selected={_.get(value, display, '')} className={className}>
      {items.map(model =>
        <ReactSelect.Option active={_.get(model, by) === _.get(value, by)}
                            key={_.get(model, by)}
                            onClick={() => onChange(model)}>
          {translatable ? <FormattedMessage id={_.get(model, display)}/> : _.get(model, display)}
        </ReactSelect.Option>
      )}
    </ReactSelect>
  );
});

ObjectReactSelect.defaultProps = {
  display: 'name',
  by: 'id',
  className: '',
  items: [],
  translatable: true
};

export default ObjectReactSelect;
