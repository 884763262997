import {action, computed, observable} from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import {DomainStore, fetchModels} from 'shared/store';
import {endpoints, types, t} from 'shared/core';
import {successAlert} from 'shared/tools';
import {PayrollUpdate} from 'stores/payroll';
import animateScrollTo from 'animated-scroll-to';

class PayrollUpdatesListState {
  STATUS_OPTIONS = ['submitted', 'completed']
  store = new DomainStore();
  status;
  errors;

  @observable payrollUpdates = [];
  @observable isLoading = false;

  async receiveProps({status}) {
    if (!!this.status && this.status !== status) {
      this.status = status;
      this.isLoading = true;
      await this.load();
    } else {
      this.status = status;
    }
  }

  @action async load() {
    await this.fetchPayrollUpdates();
  }

  @action async fetchPayrollUpdates() {
    const models = await fetchModels(
      endpoints.PAYROLL_UPDATES[this.status.toUpperCase()],
      types.PAYROLL_UPDATE
    );

    this.isLoading = false;
    this.payrollUpdates = models.map((model) => new PayrollUpdate(model));
  }

  @action async updatePayrollUpdateStatus(payrollUpdate) {
    const newStatus = _.find(this.STATUS_OPTIONS, (option) => option !== this.status);

    const payrollUpdateCopy = new PayrollUpdate(payrollUpdate);
    payrollUpdateCopy.merge({status: newStatus});

    const {model, errors} = await this.store.patch(payrollUpdateCopy);
    this.errors = errors;

    if (model) {
      payrollUpdate.update(model);
      successAlert(t('payroll_updates.alerts.Updated payroll update'));
    }
  }

  @action onPagination(data) {
    this.payrollUpdates = data;
    this.scrollToTopOfPage();
  }

  @computed get payrollUpdatesGroupedByDate() {
    const groupedUpdates = _.groupBy(
      _.filter(this.payrollUpdates, ['status', this.status]),
      payrollUpdate => moment(payrollUpdate.createdAt).format('YYYYMMDD')
    );

    const groups = _.map(groupedUpdates,
      items => ({
        date: items[0].createdAt,
        items
      })
    );

    return _.orderBy(groups, 'date', 'desc');
  }

  scrollToTopOfPage() {
    animateScrollTo(0, {minDuration: 500, maxDuration: 1000});
  }
}

export default PayrollUpdatesListState;
