import React from 'react';
import {observer} from 'mobx-react';
import {Tag} from 'components';
import {FormattedMessage} from 'react-intl';

const GoalStatusTag = observer(({goal}) => {
  if (goal.complete) {
    return(
      <Tag colour='grass'>
        <FormattedMessage id='goals.status.Complete'/>
      </Tag>
    );
  } else if (goal.inProgress) {
    return(
      <Tag colour='meadow'>
        <FormattedMessage id='goals.status.In Progress'/>
      </Tag>
    );
  } else if (goal.upcoming) {
    return (
      <Tag colour='dodger'>
        <FormattedMessage id='goals.status.Upcoming'/>
      </Tag>
    );
  } else {
    return (
      <Tag colour='rose'>
        <FormattedMessage id='goals.status.Past Due'/>
      </Tag>
    );
  }
});

export default GoalStatusTag;
