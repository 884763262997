import CountryAndRegionState from '../state/CountryAndRegionState';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import CountrySelect from './CountrySelect';
import RegionSelect from './RegionSelect';
import React from 'react';


const CountryAndRegionSelects = observer(({uiState}) => {
  return (
    <uiState.wrapper>
      <CountrySelect uiState={uiState}/>
      <RegionSelect uiState={uiState}/>
    </uiState.wrapper>
  );
});

CountryAndRegionSelects.defaultProps = {
  defaultToCanada: false,
  renderChildrenInOwnRows: false,
  wrapper: React.Fragment
};

export default withState(CountryAndRegionSelects, CountryAndRegionState, {noSpinner: true});
