import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalButtons} from 'components/modals';

const PublishConfirmationModal = observer(({uiState}) => {
  const {publishConfirmationModalOpen} = uiState;

  return (
    <Modal
      size='sm'
      title={t('mass_updates.edit.Publish Mass Update')}
      isOpen={publishConfirmationModalOpen}
      onHide={() => uiState.closePublishConfirmationModal()}
    >
      <FormattedMessage id='mass_updates.edit.CONFIRMATION_MODAL_DESCRIPTION' />
      <ModalButtons
        saveCaption='mass_updates.edit.Publish'
        onSave={() => uiState.publishMassUpdate()}
        onCancel={() => uiState.closePublishConfirmationModal()}
      />
    </Modal>
  );
});

export default PublishConfirmationModal;
