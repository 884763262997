import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const DeleteCommentModal = observer(({uiState}) => {
  return (
    <AlertModal
      size='sm'
      mode='danger'
      header='hr_dashboard.feed.announcements.Remove Announcement?'
      subHeader='hr_dashboard.feed.announcements.Are you sure you want to remove this announcement?'
      isOpen={uiState.deleteAnnouncementModalOpen}
      onHide={() => uiState.closeDeleteAnnouncementModal()}>
      <ModalDangerButtons
        onSave={() => uiState.confirmAnnouncementDelete()}
        onCancel={() => uiState.closeDeleteAnnouncementModal()}
      />
    </AlertModal>
  );
});

export default DeleteCommentModal;
