import {action, observable} from 'mobx';
import EmailTemplate from 'stores/email_templates/EmailTemplate';

class EmailTemplateState { 
  store;
  history;

  @observable emailTemplate = new EmailTemplate();
  @observable emailTemplates = [];
  @observable addEmailTemplateModalOpen = false;
  @observable errors = {};

  constructor(store, history) {
    this.store = store;
    this.history = history;
  }

  @action async load() {
    await this.refreshEmailTemplates();
  }

  @action async refreshEmailTemplates() {
    this.emailTemplates = await this.store.loadEmailTemplates();
  }

  @action openAddEmailTemplateModal() {
    this.addEmailTemplateModalOpen = true;
  }

  @action closeAddEmailTemplateModal() {
    this.addEmailTemplateModalOpen = false;
  }

  @action goToEmailTemplateForm(emailTemplate) {
    this.history.push(`email-templates/${emailTemplate.id}`);
  }

  @action async createEmailTemplate() {
    const {model, errors} = await this.store.postEmailTemplate(this.emailTemplate);
    this.errors = errors;

    if (model) {
      this.goToEmailTemplateForm(model);
    }
  }

  @action async removeEmailTemplate(template) {
    await this.store.destroy(template);
    await this.refreshEmailTemplates();
  }
}

export default EmailTemplateState;
