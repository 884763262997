import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {Panel, Input, FormLayout} from 'components';
import {t} from 'shared/core';

const Name = observer(({uiState}) => {
  const {cycle, errors} = uiState;

  return (
    <div>
      <Panel.Header title={t('performance.Name your Check-In Cycle')}/>
      <FormLayout>
        <Input value={cycle.name}
          label='performance.Cycle Name'
          placeholder='performance.e.g. Sales Department'
          onChange={e => cycle.merge({name: e.target.value})}
          errorMessage={errors.name}
        />
      </FormLayout>
    </div>
  );
});

Name.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBack: PropTypes.func,
  onContinue: PropTypes.func,
  step: PropTypes.object,
  errors: PropTypes.object
};

export default Name;
