import React from 'react';
import {observer} from 'mobx-react';
import TrainingSubnav from 'training/components/TrainingSubnav';
import EmployeeTrainingStatusesDirectory from './EmployeeTrainingStatusesDirectory';
import CompanyTrainingStatusesDirectory from './CompanyTrainingStatusesDirectory';

const TrainingStatusesDirectoryContainer = observer(({scopeToEmployee}) => {
  return (
    <div>
      <TrainingSubnav/>
      {scopeToEmployee ?
        <EmployeeTrainingStatusesDirectory/> :
        <CompanyTrainingStatusesDirectory/>
      }
    </div>
  );
});

export default TrainingStatusesDirectoryContainer;
