import {observable, action} from 'mobx';
import {DomainStore} from 'shared/store';
import {t, endpoints, types} from 'shared/core';
import {successAlert} from 'shared/tools';
import {InterviewGuide, InterviewGuideQuestion} from 'stores/interview_guides';
import _ from 'lodash';

class InterviewGuideEditState {
  store = new DomainStore();
  history;
  match;

  @observable interviewGuide;
  @observable editingQuestion;
  @observable editQuestionModalOpen = false;
  @observable errors = {};

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    const modelID = this.match.params.id;

    if (modelID) {
      await this.store._compose([
        endpoints.INTERVIEW_GUIDE.with(modelID)
      ]);

      this.interviewGuide = new InterviewGuide(this.store._getSingle(types.INTERVIEW_GUIDE, {id: modelID}));
    } else {
      this.interviewGuide = new InterviewGuide();
    }
  }

  @action addNewQuestion(order) {
    const newQuestion = new InterviewGuideQuestion(
      {
        name: '',
        order: order,
        questionType: 'text_area'
      }
    );
    this.editQuestion(newQuestion);
  }

  @action removeQuestion(question) {
    this.interviewGuide.questions.remove(question);
  }

  @action editQuestion(question) {
    this.editingQuestion = new InterviewGuideQuestion(question);
    this.editQuestionModalOpen = true;
  }

  @action updateQuestion() {
    const question = _.find(this.interviewGuide.questions, { order: this.editingQuestion.order });

    if (question) {
      question.update(this.editingQuestion);
    } else {
      this.interviewGuide.questions.push(this.editingQuestion);
    }

    this.closeEditQuestionModal();
  }

  @action closeEditQuestionModal() {
    this.editQuestionModalOpen = false;
  }

  @action async saveInterviewGuide() {
    const {model, errors} = this.interviewGuide.isNew ?
      await this.store.post(
        endpoints.INTERVIEW_GUIDES,
        types.INTERVIEW_GUIDE,
        this.interviewGuide
      ) :
      await this.store.patch(this.interviewGuide);
    this.errors = errors;

    if (model) {
      successAlert(t('recruiting.interview_guides.Interview guide updated.'));
      this.history.push('/interview-guides');
    }
  }
}

export default InterviewGuideEditState;
