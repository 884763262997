import React from 'react';
import {observer} from 'mobx-react';
import {Avatar} from 'components';

const ManagerAvatar = observer(({model}) => {
  if (!model.reviewManager) return null;

  return (
    <div className='left'>
      <Avatar size='sm' employee={model.reviewManager} />
    </div>
  );
});

export default ManagerAvatar;
