const REMINDER_RECIPIENTS = [
  {
    type: 'employee',
    name: 'models.reminder_recipients.employee'
  },
  {
    type: 'manager',
    name: 'models.reminder_recipients.manager'
  }
];

export default REMINDER_RECIPIENTS;
