import React from 'react';
import EmployeeTimeOffState from '../state/EmployeeTimeOffState';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import TimeOffBalances from './TimeOffBalances';
import UpcomingTimeOff from './UpcomingTimeOff';
import {FormattedMessage} from 'react-intl';
import {Button, Panel, ActionLink} from 'components';
import PolicySummaryModal from './PolicySummaryModal';
import UnlimitedPolicySummaryModal from './UnlimitedPolicySummaryModal';
import TransactionList from './TransactionList';
import RequestList from './RequestList';
import RequestNotesModal from './RequestNotesModal';
import {TimeOffPolicySelector} from './time_off_policy_selector';
import {withRouter} from 'react-router-dom';

const EmployeeTimeOffContainerWithState = observer(({uiState, history}) => {
  return (
    <Panel.Stack loose>
      <Panel>
        <Panel.Header title={t('employees.profile.time_off.Time Off')}>
          {uiState.showRequestTimeOffButton && <Button onClick={() => uiState.requestTimeOff()}>
            <FormattedMessage id='employees.profile.time_off.Request Time Off'/>
          </Button>}
          {uiState.showRecordTimeOffButton && <Button onClick={() => uiState.requestTimeOff()}>
            <FormattedMessage id='employees.profile.time_off.Request on behalf of'/>
          </Button>}
        </Panel.Header>
        <TimeOffBalances uiState={uiState}/>
        <TimeOffPolicySelector employee={uiState.employee} policy={uiState.policy}/>
        <PolicySummaryModal uiState={uiState}/>
        <UnlimitedPolicySummaryModal uiState={uiState}/>
      </Panel>
      <Panel>
        <Panel.Header title={t('employees.profile.time_off.Upcoming Time Off')}>
          <ActionLink onClick={() => uiState.openUpcomingHolidaysModal()}>
            <FormattedMessage id='employees.profile.time_off.View Holidays'/>
          </ActionLink>
        </Panel.Header>
        <UpcomingTimeOff uiState={uiState}/>
      </Panel>
      <RequestList uiState={uiState}/>
      <TransactionList uiState={uiState}/>
      <RequestNotesModal uiState={uiState}/>
    </Panel.Stack>
  );
});

export default withRouter(withState(EmployeeTimeOffContainerWithState, EmployeeTimeOffState, {
  spinner: Panel.Spinner
}));
