import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import AdminUserSignupState from '../state/AdminUserSignupState';
import {FormattedMessage} from 'react-intl';
import {Input, Button} from 'components';
import BrokerBrandedContainer from '../../../../BrokerBrandedContainer';
import {policyLink} from 'shared/tools';
import {LoginOAuth} from 'components/login';

const bhqMarketingCopy = (invite) => ({
  info: <FormattedMessage id='broker_portal.signup.admin.BHQ_INFO' values={{companyName: invite.companyName}}/>,
  header: <FormattedMessage id='broker_portal.signup.admin.BHQ_HEADER'/>
});

const hrMarketingCopy = (invite) => ({
  info: <FormattedMessage id='broker_portal.signup.admin.HR_INFO' values={{companyName: invite.companyName}}/>,
  header: <FormattedMessage id='broker_portal.signup.admin.HR_HEADER'/>
});

const marketingCopy = (invite) => {
  if (invite.bhq) return bhqMarketingCopy(invite);

  return hrMarketingCopy(invite);
};

const InvitationCopy = ({invite}) => {
  if (invite.bhq) {
    return (
      <FormattedMessage
        id='broker_portal.signup.admin.BHQ_INVITATION'
        values={{brokerName: invite.broker.name, companyName: invite.companyName}}/>
    );
  }

  return <FormattedMessage id='broker_portal.signup.admin.HR_INVITATION' values={{companyName: invite.companyName}}/>;
};

const AdminUserSignupForm = observer(({uiState}) => {
  const {invite, form, errors, termsLink} = uiState;

  return (
    <BrokerBrandedContainer
      {...marketingCopy(invite)}
    >
      <div className='center mb3'>
        <div className='wl-big-logo mx-auto'></div>
      </div>
      <div className='h2 medium center mb2'>
        <FormattedMessage id='broker_portal.signup.admin.Create Your Account'/>
      </div>
      <div className='jumbo center'>
        <InvitationCopy invite={invite}/>
      </div>
      <div className='pt3'>
        <LoginOAuth
          context='admin_invitation_signup'
          token={uiState.form.token}
        />
      </div>
      <form>
        <div className='clearfix mt3 mxn1'>
          <div className='col col-12 sm-col-6 px1 mb2'>
            <Input
              value={form.firstName}
              placeholder='broker_portal.signup.admin.First Name'
              onChange={e => form.merge({firstName: e.target.value})}
              errorMessage={errors.firstName} />
          </div>
          <div className='col col-12 sm-col-6 px1 mb2'>
            <Input
              value={form.lastName}
              placeholder='broker_portal.signup.admin.Last Name'
              onChange={e => form.merge({lastName: e.target.value})}
              errorMessage={errors.lastName} />
          </div>
          <div className='col col-12 px1 mb2'>
            <Input
              value={form.email}
              placeholder='broker_portal.signup.admin.Email'
              onChange={e => form.merge({email: e.target.value})}
              errorMessage={errors.email} />
          </div>
          <div className='col col-12 px1 mb2'>
            <Input
              value={form.emailConfirmation}
              placeholder='broker_portal.signup.admin.Confirm Email'
              onChange={e => form.merge({emailConfirmation: e.target.value})}
              errorMessage={errors.emailConfirmation} />
          </div>
          <div className='col col-12 px1 mb2'>
            <Input type='password' className='full-width'
              value={form.password}
              placeholder='broker_portal.signup.admin.Password'
              onChange={e => form.merge({password: e.target.value})}
              errorMessage={errors.password}/>
          </div>
          <div className='col col-12 px1 mb3'>
            <Button className='full-width' onClick={() => uiState.signUp()}>
              <FormattedMessage id='broker_portal.signup.admin.Sign Up'/>
            </Button>
          </div>
          <div className='col col-12 px1 h5 waterloo center'>
            <FormattedMessage id='signup.SERVICE_AGREEMENT_AND_PRIVACY_POLICY' values={{
              terms: <a href={termsLink} target='_blank' className='TextLink dodger'><FormattedMessage id='signup.Terms'/></a>,
              privacyPolicy: <a href={policyLink()} target='_blank' className='TextLink dodger'><FormattedMessage id='signup.Privacy Policy'/></a>
            }}/>
          </div>
        </div>
      </form>
    </BrokerBrandedContainer>
  );
});

export default withState(AdminUserSignupForm, AdminUserSignupState);
