import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {useRouteMatch} from 'react-router-dom';
import {ViewGoalContainerState} from '../state';
import {Display, Layout, Panel} from 'components';
import GoalDetails from './GoalDetails';
import SubgoalDetails from './SubgoalDetails';
import MetricDetails from './MetricDetails';
import useModal from 'shared/hooks/useModal';
import {Goal} from 'stores/performance_goals';
import Timeline from 'performance_goals/components/view/components/Timeline';
import DeleteGoalModal from 'performance_goals/components/DeleteGoalModal';
import DeleteCommentModal from './DeleteCommentModal';

const ViewGoalContainer = observer(({uiState}) => {
  const {goal} = uiState;
  const deleteGoalModalOpen = useModal(uiState, 'DeleteGoalModal', 'selectedGoal', Goal);
  const match = useRouteMatch();

  useEffect(() => {
    if (match.params.goalId !== goal.id) {
      uiState.load();
    }
  }, [match.params.goalId]);

  return (
    <div className='mb1'>
      <Display onBackClick={() => uiState.onBackClick()} title={goal.title}/>
      <Layout>
        <Panel.Stack loose>
          <GoalDetails uiState={uiState}/>
          <MetricDetails uiState={uiState}/>
          <SubgoalDetails uiState={uiState}/>
          <Timeline uiState={uiState}/>
        </Panel.Stack>
      </Layout>
      <DeleteCommentModal uiState={uiState}/>
      <DeleteGoalModal modalOpen={deleteGoalModalOpen} uiState={uiState}/>
    </div>
  );
});

export default withState(ViewGoalContainer, ViewGoalContainerState);
