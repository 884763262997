import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {EmployeeSelector, FormError} from 'components';
import _ from 'lodash';

const EmployeeSelectorModal = observer(({uiState}) => {
  const {errors} = uiState;

  return (
    <Modal
      size='md'
      isOpen={uiState.selectEmployeesModalOpen}
      onHide={() => uiState.closeModals()}
      title='training.summary.Assign employees to training program'
    >
      <EmployeeSelector onChange={employees => uiState.selectedEmployees = employees} />
      <FormError message={errors.base} />
      <ModalButtons
        onSave={() => uiState.assignEmployees()}
        onCancel={() => uiState.closeModals()}
      />
    </Modal>
  );
});

export default EmployeeSelectorModal;
