import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Avatar, ActionLinks} from 'components';
import {FormattedMessage} from 'react-intl';
import {longTimestampDate} from 'shared/tools';

const NoteRow = ({uiState, note}) => {

  return (
    <Panel>
      <div className='flex pb3 justify-between'>
        <div className='align-middle'>
          <div className='pr2 table-cell align-top'>
            {note.employeeId ?
              <a href={`/employees/${note.employeeId}`}>
                <Avatar size='sm' employee={note.author}/>
              </a> :
              <Avatar size='sm' employee={note.author}/>
            }
          </div>
          <div className='table-cell align-top'>
            <div className='mb1 jumbo'>
              {note.employeeId ?
                <a href={`/employees/${note.employeeId}`}>
                  {note.author.name}
                </a> :
                <span>{note.author.name}</span>
              }
              <span className='h6'>
                <span className='mx1'>{'-'}</span>
                <span className='Tooltip'>
                  {note.createdTimeAgo}
                  <span className='tooltiptext dateTooltip'>
                    {longTimestampDate(note.createdAt)}
                  </span>
                </span>
                {note.hasBeenUpdated &&
                  <span>
                    <span className='mx1'>{'-'}</span>
                    <span className='Tooltip'>
                      <FormattedMessage
                        id="hr_dashboard.feed.announcements.UPDATED_TIME_AGO"
                        values={{timeAgo: note.updatedTimeAgo}}
                      />
                      <span className='tooltiptext dateTooltip'>
                        {longTimestampDate(note.updatedAt)}
                      </span>
                    </span>
                  </span>
                }
              </span>
            </div>
            <div className='whitespace-pre-wrap breakword'>
              {note.content}
            </div>
          </div>
        </div>
        <div className='relative'>
          <ActionLinks
            model={note}
            action={(note) => uiState.openEditModal(note)}
            onRemoveClicked={(note) => uiState.openDeleteModal(note)}
          />
        </div>
      </div>
    </Panel>
  );
};

export default observer(NoteRow);
