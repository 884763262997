const REMINDER_TARGETS = [
  {
    type: 'first_day_of_work',
    name: 'models.reminders.types.first_day_of_work'
  },
  {
    type: 'last_day_of_work',
    name: 'models.reminders.types.last_day_of_work'
  },
  {
    type: 'first_day_approved_time_off',
    name: 'models.reminders.types.first_day_approved_time_off'
  },
  {
    type: 'first_day_of_benefits',
    name: 'models.reminders.types.first_day_of_benefits'
  },
  {
    type: 'last_day_of_benefits',
    name: 'models.reminders.types.last_day_of_benefits'
  },
  {
    type: 'birthday',
    name: 'models.reminders.types.birthday'
  },
  {
    type: 'anniversary',
    name: 'models.reminders.types.anniversary'
  },
  {
    type: 'sin_expiry_date',
    name: 'models.reminders.types.sin_expiry_date'
  },
  {
    type: 'first_day_of_leave',
    name: 'models.reminders.types.first_day_of_leave'
  },
];

export default REMINDER_TARGETS;
