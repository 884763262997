import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import Field from './Field';

const SpouseInfo = observer(({benefitInfo}) => {
  if (!benefitInfo.hasSpouse) {
    return (
      <span className='jumbo'>
        <FormattedMessage id='benefit_enrolment.Employee does not have a spouse.'/>
      </span>
    );
  }

  return (
    <div>
      <Field title="First Name" value={benefitInfo.spouseFirstName}/>
      <Field title="Last Name" value={benefitInfo.spouseLastName}/>
      <Field title="Date of Birth" value={benefitInfo.spouseBirthDateView}/>
      <Field
        title="Spouse's Relationship"
        value={t(`models.benefit_info.spousal_relationship.${benefitInfo.spousalRelationship}`)}
      />
      <Field
        title={`spousal_relationship_dates.${benefitInfo.spousalRelationship}`}
        value={benefitInfo.spouseMarriageDateView}
      />
      <Field title="Sex at Birth" value={t(`benefit_enrolment.${benefitInfo.spousalGender}`)}/>
      <Field title="Spouse's Insurer Name" value={benefitInfo.spouseInsurerName} />
      <Field title="Spouse's Policy Number" value={benefitInfo.spousePolicyNumber} />
      <Field title="Spouse's Health Care" value={t(`benefit_enrolment.${benefitInfo.spousalHealthCoverage}`)}/>
      <Field title="Spouse's Dental Care" value={t(`benefit_enrolment.${benefitInfo.spousalDentalCoverage}`)}/>
      <Field title="Spouse's Vision Care" value={t(`benefit_enrolment.${benefitInfo.spousalVisionCoverage}`)}/>
    </div>
  );
});

const Spouse = observer(({uiState}) => {
  const {benefitInfo} = uiState;

  return (
    <Panel>
      <div className='medium mb2'>
        <FormattedMessage id='benefit_enrolment.details.Spouse'/>
      </div>
      <SpouseInfo benefitInfo={benefitInfo}/>
    </Panel>
  );
});

export default Spouse;
