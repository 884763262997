import {DomainObject} from 'shared/store';
import {action, observable} from 'mobx';
import {PerformanceReviewQuestion, PerformanceReviewReviewee} from 'stores/performance_reviews/index';

class PerformanceReviewResult extends DomainObject {
  @observable trend;
  @observable reviewees;
  @observable questions;

  @action merge(other) {
    super.merge(other, {
      reviewees: [PerformanceReviewReviewee],
      questions: [PerformanceReviewQuestion]
    });
  }
}

export default PerformanceReviewResult;
