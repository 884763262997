import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';

const DueDateView = observer(({model}) => {
  const dueValue = model.relativeDueDateValue;

  if (dueValue < 0) {
    return <FormattedMessage id='tasks.DUE_BEFORE_DATE' values={{
             dueDateValue: model.dueDateValue,
             dueDateUnit: t(`tasks.${model.relativeDueDateUnit}`),
             dateString: t(`tasks.${model.config.dateString}`) }} />;
  } else if (dueValue === 0) {
    return <FormattedMessage id={`tasks.${model.config.dueOnDateOption}`} />;
  } else if (dueValue > 0) {
    return <FormattedMessage id='tasks.DUE_FROM_DATE' values={{
             dueDateValue: model.dueDateValue,
             dueDateUnit: t(`tasks.${model.relativeDueDateUnit}`),
             dateString: t(`tasks.${model.config.dateString}`) }} />;
  }

  return null;
});

export default DueDateView;
