import React from 'react';
import {observer} from 'mobx-react';
import {relativeRangeDate} from 'shared/tools';
import Avatar from 'components/Avatar';

const Celebration = observer(({date, employee, children}) => {
  return (
    <div className='FeedItem align-items-center'>
      <Avatar employee={employee} size='sm' />
      <div className='flex flex-grow align-items-center ml1'>
        {children}
      </div>
      <div className='table-cell col-3 right-align jumbo'>
        {relativeRangeDate(date)}
      </div>
    </div>
  );
});

export default Celebration;
