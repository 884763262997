import {DomainStore} from 'shared/store';
import {t, endpoints, types, api} from 'shared/core';
import {action, observable, computed} from 'mobx';
import _ from 'lodash';
import {successAlert} from 'shared/tools';
import {TimeOffType} from 'stores/time_off';

class TypesState {
  store = new DomainStore();

  @observable timeOffTypes;
  @observable editingType = {};
  @observable editTypeModalOpen = false;
  @observable reorderTypesModalOpen = false;
  @observable errors = {};
  @observable reorderingTypes;
  @observable editMessage = 'time_off.types.Add Type';

  @action async load() {
    await this.store._compose([
      endpoints.TIME_OFF.TYPES.ALL
    ]);

    this.timeOffTypes = this.store._getAll(types.TIME_OFF.TYPE, TimeOffType);
  }

  @action openEditTypeModal(type) {
    this.editTypeModalOpen = true;
    this.editingType = new TimeOffType(type);
  }

  @action addType() {
    this.editMessage = 'time_off.types.Add Type';
    this.openEditTypeModal(new TimeOffType());
  }

  @action closeEditTypeModal() {
    this.editTypeModalOpen = false;
    this.errors = {};
  }

  @action editType(type) {
    this.editMessage = 'time_off.types.Edit Type';
    this.openEditTypeModal(type);
  }

  @action async saveType() {
    const {model, errors} = await (this.editingType.isNew ?
      this.store.post(endpoints.TIME_OFF.TYPES.ALL, types.TIME_OFF.TYPE, this.editingType) :
      this.store.patch(this.editingType));

      this.errors = errors;

    if (model) {
      await this.load();
      this.closeEditTypeModal();
      successAlert(t('time_off.types.Time off type added.'));
    }
  }

  @action async destroyType(type) {
    await this.store.destroy(type);
    await this.load();
  }

  @action openReorderTypesModal() {
    this.reorderingTypes = this.timeOffTypes.map(type => new TimeOffType(type));
    this.reorderTypesModalOpen = true;
  }

  @action closeReorderTypesModal() {
    this.reorderTypesModalOpen = false;
  }

  @action async saveOrders() {
    var typesParams = {};
    this.reorderingTypes.forEach(type => typesParams[type['id']] = type['order']);

    await api.patch(endpoints.TIME_OFF.TYPES.REORDER, typesParams);

    await this.load();
    this.closeReorderTypesModal();
  }

  @computed get orderedTypes() {
    return _.sortBy(this.timeOffTypes, ['order']);
  }
}

export default TypesState;