import React from 'react';
import {observer} from 'mobx-react';
import {FormError, Panel} from 'components';
import {BenefitEnrolmentList} from 'components/benefits';
import BenefitsNotEnabled from './BenefitsNotEnabled';
import {auth, t} from 'shared/core';

const Benefits = observer(({uiState}) => {
  const {employee, errors} = uiState;

  if (!auth.company.benefitsEnabled) return <BenefitsNotEnabled uiState={uiState}/>;

  return (
    <div>
      <Panel.Header
        title={t('employees.hire.benefits.Benefits')}
        description={t('employees.hire.benefits.BENEFIT_ENROLMENT_DESC', {employee: employee.name})}
      />
      <FormError message={errors.base}/>
      <BenefitEnrolmentList
        errors={uiState.errors}
        onChange={({enrolments, employeeInsuranceNumbers}) => {
          uiState.setBenefitEnrolments(enrolments);
          uiState.setEmployeeInsuranceNumbers(employeeInsuranceNumbers);
        }}
        employeeBenefitEnrollments={employee.employeeBenefitEnrollments}
        employeeInsuranceNumbers={employee.employeeInsuranceNumbers}
        startDate={employee.startDate}
      />
    </div>
  );
});

export default Benefits;
