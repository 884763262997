import React from 'react';
import {observer} from 'mobx-react';
import {Switch, Route, NavLink, Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {Display, Tooltip} from 'components';
import {t} from 'shared/core';
import ListIcon from 'img/list-view@2x.png';
import OrgIcon from 'img/org-view@2x.png';

const ShowListLink = observer(() => {
  return (
    <NavLink
      to='/directory'
      activeClassName='active'
      className='Btn Btn--default Btn--icon align-middle'
    >
      <span className='Btn--content' tabIndex='-1'>
        <img src={ListIcon} height='18' alt='directory'/>
      </span>
    </NavLink>
  );
});

const ShowOrgChartLink = observer(() => {
  return (
    <Tooltip content={t('employees.directory.Organizational Chart')}>
      <NavLink
        to='/orgchart'
        activeClassName='active'
        className='Btn Btn--default Btn--icon align-middle'
      >
        <span className='Btn--content' tabIndex='-1'>
          <img src={OrgIcon} height='24' alt='orgchart'/>
        </span>
      </NavLink>
    </Tooltip>
  );
});

const OrgChartToggle = ({scope}) => {
  if (!scope.orgChart) return null;

  return (
    <div className='ml2 inline-block sm-and-below-hide'>
      <Switch>
        <Route path='/directory' component={ShowOrgChartLink}/>
        <Route path='/orgchart' component={ShowListLink}/>
      </Switch>
    </div>
  );
};

const HireButton = observer(({scope}) => {
  if (!scope.hire) return null;

  return (
    <Link to='/hire' className='Btn Btn--primary align-middle'>
      <span className='Btn--content' tabIndex='-1'>
        <FormattedMessage id='employees.directory.Hire'/>
      </span>
    </Link>
  );
});

const Toolbar = observer(({scope}) => {
  return (
    <div>
      <HireButton scope={scope}/>
      <OrgChartToggle scope={scope}/>
    </div>
  );
});

const Subnav = observer(({scope}) => {
  return (
    <Display
      toolbar={<Toolbar scope={scope}/>}
      title={t('employees.directory.People')}
    />
  );
});

export default Subnav;
