import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {carrierAssetKey} from '../../tools';

const CoverageHeader = observer(({currentClass, currentCoverage}) => {
  const {companyInsuranceCarrier} = currentClass.benefitDivision;

  return (
    <Panel className={`ProviderHeader ProviderHeader--${carrierAssetKey(companyInsuranceCarrier)}`}>
      <div className='col-7 white'>
        <div className='h2 mb2 medium'>
          {currentCoverage.isOtherCoverage ? companyInsuranceCarrier.name : currentCoverage.title} 
        </div>
        {!currentCoverage.isOtherCoverage && <div>{currentCoverage.tooltip}</div>}
      </div>
    </Panel>
  );
});

export default CoverageHeader;
