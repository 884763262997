import React from 'react';
import {observer} from 'mobx-react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {auth} from 'shared/core';
import PersonalContainer from './personal/components/PersonalContainer';
import {EmployeeRoleSwitch} from './role';
import BankingContainer from './banking/components/BankingContainer';
import EmergencyContainer from './emergency/components/EmergencyContainer';
import EmployeeDocumentsContainer from './documents/EmployeeDocumentsContainer';
import EmployeeNotesContainer from './notes/NotesContainer';
import {CalendarsContainer} from './calendars';
import {OnboardingContainer} from './onboarding';
import {EmployeeTimeOffSwitch} from './time_off';
import {ChangePolicyFlow} from './time_off/components/time_off_policy_selector';

const HrEmployeeProfileSwitch = observer(({uiState}) => {
  const {match} = uiState;

  return (
    <div>
      <Switch>
        <Route path={`${match.url}/personal`} render={() => <PersonalContainer uiState={uiState} />} />
        {(uiState.viewDataPermission('::ROLE_INFORMATION') || uiState.viewDataPermission('::COMPENSATION_INFORMATION')) &&
          <Route path={`${match.url}/job`} render={() => <EmployeeRoleSwitch uiState={uiState} />} />}
        {uiState.tabs.timeOff &&
          <Route path={`${match.url}/time-off`} render={() => <EmployeeTimeOffSwitch uiState={uiState}/>} />}
        {uiState.viewDataPermission('::BANKING_INFORMATION') && (auth.company.bankingEnabled || auth.company.sinEnabled) &&
          <Route path={`${match.url}/banking`} render={() => <BankingContainer uiState={uiState} />} />}
        {uiState.viewDataPermission('::EMERGENCY_CONTACT_INFORMATION') &&
          <Route path={`${match.url}/emergency`} render={() => <EmergencyContainer uiState={uiState} />} />}
        {uiState.viewDataPermission('::DOCUMENTS') &&
            <Route path={`${match.url}/documents`} render={() => <EmployeeDocumentsContainer uiState={uiState} />} />}
        {uiState.viewDataPermission('::NOTES') &&
          <Route path={`${match.url}/notes`} render={() => <EmployeeNotesContainer uiState={uiState} />} />}
        {uiState.tabs.calendar &&
          <Route path={`${match.url}/calendars`} render={() => <CalendarsContainer uiState={uiState} />} />}
        {auth.hasAccess('::MANAGE_ONBOARDING') &&
          <Route path={`${match.url}/onboarding`} render={() => <OnboardingContainer uiState={uiState} />} />}
        <Route path={`${match.url}/change-time-off-policy`} component={ChangePolicyFlow}/>
        <Redirect from={`${match.url}/`} to={`${match.url}/personal`}/>
      </Switch>
    </div>
  );
});

export default HrEmployeeProfileSwitch;
