import React from 'react';
import {CheckboxWithLabel, EmployeeSelector, DatePicker} from 'components';
import {t} from 'shared/core';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormError} from '../../../../components';

const TaxDocumentsModal = observer(({uiState}) => {
  return (
    <Modal
      isOpen={uiState.taxDocumentsModalOpen}
      onHide={() => uiState.closeTaxDocumentsModal()}
      size='md'
      closeButton={false}
      title='company_settings.documents.Send Documents'
    >
      <form>
        <div>
          <CheckboxWithLabel
            translatable={false}
            label={t('company_settings.documents.federal_tax_form')}
            checked={uiState.sendFederalTaxForm}
            onChange={() => uiState.sendFederalTaxForm = !uiState.sendFederalTaxForm}
          />
        </div>
        <div>
          <CheckboxWithLabel
            translatable={false}
            label={t('company_settings.documents.provincial_tax_form')}
            checked={uiState.sendProvincialTaxForm}
            onChange={() => uiState.sendProvincialTaxForm = !uiState.sendProvincialTaxForm}
          />
        </div>
        <EmployeeSelector
          onChange={employees => uiState.changeSelectedEmployees(employees)}
        />
        <DatePicker
          label='company_settings.documents.Due Date'
          value={uiState.taxFormDueDate}
          onChange={date => uiState.taxFormDueDate = date}
          optional
        />
        <div className='mt2'>
          <FormError message={uiState.errors.base}/>
        </div>
        <ModalButtons saveCaption='company_settings.documents.Send'
                      onSave={() => uiState.sendTaxDocuments()}
                      onCancel={() => uiState.closeTaxDocumentsModal()} />
      </form>
    </Modal>
  );
});

export default TaxDocumentsModal;
