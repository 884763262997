import {action, computed} from 'mobx';
import _ from 'lodash';

class DropdownFilterState {
  filterUpdated;
  attr;
  filter;
  options;

  constructor({attr, filter, filterUpdated, defaultFilter = 0, defaultFilterPropagated, options}) {
    this.filterUpdated = filterUpdated;
    this.attr = attr;
    this.filter = filter;
    this.options = options;

    if (!this.filter.has(this.attr)) {
      this.filter.set(this.attr, options[defaultFilter].id);
    }

    defaultFilterPropagated({
      [this.attr]: options[defaultFilter].id
    });
  }

  @action changeOption(option) {
    this.filterUpdated({
      [this.attr]: option.id
    });
  }

  @computed get selected() {
    return _.find(this.options, {id: this.filter.get(this.attr)});
  }
}

export default DropdownFilterState;
