import React, {useState, useRef, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {observer} from 'mobx-react';
import ModelActionLink from './ModelActionLink';
import _ from 'lodash';

const EditLink = observer(({model, action, editModelCaption}) => {
  return (
    <ModelActionLink text={editModelCaption} action={action} model={model}/>
  );
});

const DeleteLink = observer(({model, onRemoveClicked, removeModelCaption}) => {
  return (
    <ModelActionLink text={removeModelCaption} action={onRemoveClicked} model={model}/>
  );
});

const filterCustomLinks = (customLinks, model) => {
  if (_.isFunction(customLinks)) return customLinks(model);

  return customLinks;
};

const ActionLinks = observer(({model, action, onRemoveClicked, editModelCaption, removeModelCaption, customLinks, onlyShowCustomLinks}) => {
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [showDropdown, setShowDropdown] = useState(false);

  const updateDropdownPosition = () => {
    if (buttonRef.current && dropdownRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const menu = dropdownRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: `${rect.bottom + window.scrollY}px`,
        left: `${rect.left - (menu.width - rect.width) + window.scrollX}px`
      });
    }
  };

  const isClickOutside = e => {
    if (!buttonRef.current) return;

    return !buttonRef.current.contains(e.target) && dropdownRef.current && !dropdownRef.current.contains(e.target);
  };

  const handleClickOutside = e => {
    if (isClickOutside(e)) setShowDropdown(false);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDropdownPosition);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('resize', updateDropdownPosition);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    updateDropdownPosition();
  }, [showDropdown]);

  const data = model.data || {};

  customLinks = filterCustomLinks(customLinks, model);

  if (!model.canPatch && !model.canDelete && !data.canPatch && !data.canDelete && _.isEmpty(customLinks)) {
    return null;
  }

  if (_.isEmpty(customLinks) && onlyShowCustomLinks) {
    return null;
  }

  const links = [];

  if ((model.canPatch || data.canPatch) && action && !onlyShowCustomLinks) {
    links.push({
      order: 0,
      key: 'edit',
      Component: () => <EditLink model={model} action={action} editModelCaption={editModelCaption}/>
    });
  }

  if ((model.canDelete || data.canDelete) && onRemoveClicked && !onlyShowCustomLinks) {
    links.push({
      order: 10,
      key: 'remove',
      Component: () => <DeleteLink model={model} onRemoveClicked={onRemoveClicked} removeModelCaption={removeModelCaption}/>
    });
  }

  links.push(...customLinks
    .map(link => ({
    order: link.order,
    key: link.text,
    Component: () => <ModelActionLink text={link.text} action={link.action} downloadLink={link.downloadLink} model={model} translate={link.translate}/>
  })));

  const onClick = (e) => {
    e.stopPropagation();
    setShowDropdown(!showDropdown);
  };

  return (
    <div onClick={e => onClick(e)} className='action-links right' style={{width: '24px'}}>
      <div ref={buttonRef} className='submarine center clickable user-select-none'>
        <i className='material-icons'>{'more_horiz'}</i>
      </div>
      {showDropdown && createPortal(
        <ul
         className='dropdown-menu block'
         style={{ position: 'absolute', top: dropdownPosition.top, left: dropdownPosition.left, zIndex: 1000 }}
         ref={dropdownRef}
        >
          {_.orderBy(links, 'order').map(l => <li key={l.key}><l.Component/></li>)}
        </ul>,
        document.body
      )}
    </div>
  );
});

ActionLinks.defaultProps = {
  editModelCaption: 'Edit',
  removeModelCaption: 'Remove',
  customLinks: []
};

export default ActionLinks;
