import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {redirect} from 'shared/tools';
import {urls} from 'shared/core';
import {ActionLink} from 'components';

const LateRequests = ({lateRequestsCount}) => {
  if (lateRequestsCount === 0) return null;

  return (
    <span>
      <FormattedMessage id='hr_dashboard.insights.pto.WARNING'
        values={{
          num: lateRequestsCount,
          link: <ActionLink scarlet href={urls.PTO.REQUESTS.LATE} onClick={() => redirect(urls.PTO.REQUESTS.LATE)}>
                  <FormattedMessage id='hr_dashboard.insights.pto.WARNING_LINK' values={{num: lateRequestsCount}}/>
                </ActionLink>
        }}
      />
    </span>
  );
};

const PTOText = observer(({ptoSummary}) => {
  const {requestsCount, lateRequestsCount} = ptoSummary;

  if(requestsCount === 0) return null;

  return (
    <li>
      <FormattedMessage id='hr_dashboard.insights.pto.MESSAGE'
        values={{
          num: requestsCount,
          link: <ActionLink href={urls.PTO.REQUESTS.PENDING} onClick={() => redirect(urls.PTO.REQUESTS.PENDING)}>
                  <FormattedMessage id='hr_dashboard.insights.pto.MESSAGE_LINK' values={{num: requestsCount}}/>
                </ActionLink>
        }}
      />
      <LateRequests lateRequestsCount={lateRequestsCount}/>
    </li>
  );
});

export default PTOText;
