import React from 'react';
import {observer} from 'mobx-react';
import {Input, SignaturePad} from 'components';
import {Modal, ModalButtons} from 'components/modals';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const PADDING = 24;
const INPUT_BORDER_WIDTH = 1;
const MIN_TEXT_FONT_SIZE = 12;
const MIN_TEXTAREA_FONT_SIZE = 12;
const LINE_HEIGHT_RATIO = 1.43;

function getTextWidth(text, fontSize) {
  var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  var context = canvas.getContext("2d");
  context.font = `${fontSize}px GT Walsheim`;
  var metrics = context.measureText(text);
  return metrics.width;
}

@observer class TextField extends React.Component {
  onChange = (e) => {
    const {viewModel} = this.props;
    const {annotationArgs} = viewModel.annotation;

    const inputWidth = this.input.props.style.width - PADDING;
    const value = e.target.value;

    let fontSize = this.input.props.style.fontSize;
    let textWidth = getTextWidth(value, fontSize);


    if (viewModel.value.length < value.length) {
      while (inputWidth < textWidth) {
        if (annotationArgs.fontSize < MIN_TEXT_FONT_SIZE) return;
        annotationArgs.fontSize -= 1;
        textWidth = getTextWidth(value, annotationArgs.fontSize);
      }
    } else {
      while (inputWidth > textWidth) {
        if (viewModel.originalFontSize === annotationArgs.fontSize) break;
        annotationArgs.fontSize += 1;
        textWidth = getTextWidth(value, annotationArgs.fontSize);
      }
    }

    viewModel.value = value;
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.onEnter();
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      this.props.onEnter();
    }
  }

  render() {
    const {viewModel} = this.props;
    const {state, annotationArgs} = viewModel.annotation;
    const placeholder = annotationArgs.isRequired ?
      null :
      'components.pdf.annotate.input_label.(Optional)';

    const height = state.height * state.scaleY + 2;
    const width = state.width * state.scaleX + 1;
    const style = {
      zIndex: 1,
      fontSize: annotationArgs.fontSize,
      left: `${state.left + INPUT_BORDER_WIDTH}px`,
      top: `${parseFloat(state.top) + 2 + 2 * INPUT_BORDER_WIDTH}px`,
      height: height,
      width: width
    };

    // Hacky fix for Internet Explorer not displaying the text if the input box is too small.
    if (height <= 18.1) {
      style.padding = '0 14px';
    }

    return (
      <Input id={annotationArgs.id}
             className='absolute'
             ref={input => this.input = input}
             value={viewModel.value}
             placeholder={placeholder}
             onKeyPress={(e) => this.handleKeyPress(e)}
             onKeyDown={(e) => this.handleKeyDown(e)}
             onChange={(e) => this.onChange(e)}
             onFocus={() => this.props.onFocus(viewModel)}
             style={style}/>
    );
  }
}

@observer class TextareaField extends React.Component {
  onChange = (e) => {
    const {viewModel} = this.props;
    const {annotationArgs} = viewModel.annotation;

    const inputHeight = parseFloat(this.textarea.style.height) - PADDING;
    const inputWidth = parseFloat(this.textarea.style.width) - PADDING;
    const fontSize = parseFloat(this.textarea.style.fontSize);
    const value = e.target.value;

    let textWidth = getTextWidth(value, fontSize);
    let inputRows = Math.floor(inputHeight / (fontSize * 1.43));


    if (viewModel.value.length < value.length) {
      while (inputRows * inputWidth < textWidth) {
        if (annotationArgs.fontSize < MIN_TEXTAREA_FONT_SIZE) return;
        annotationArgs.fontSize -= 1;
        inputRows = this._calculateRows(inputHeight, annotationArgs.fontSize);
        textWidth = getTextWidth(value, annotationArgs.fontSize);
      }
    } else {
      while (inputRows * inputWidth > textWidth) {
        if (viewModel.originalFontSize === annotationArgs.fontSize) break;
        annotationArgs.fontSize += 1;
        inputRows = this._calculateRows(inputHeight, annotationArgs.fontSize);
        textWidth = getTextWidth(value, annotationArgs.fontSize);
      }
    }

    viewModel.value = value;
  }

  onKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      this.props.onEnter();
    }
  }

  _calculateRows(height, fontSize) {
    const lineHeight = fontSize * LINE_HEIGHT_RATIO;
    return Math.floor(height / lineHeight);
  }

  render() {
    const {viewModel} = this.props;
    const {state, annotationArgs} = viewModel.annotation;
    const placeholder = annotationArgs.isRequired ?
      null :
      t('components.pdf.annotate.input_label.(Optional)');

    return (
      <textarea id={annotationArgs.id}
                className='absolute'
                ref={textarea => this.textarea = textarea}
                value={viewModel.value}
                placeholder={placeholder}
                onFocus={() => this.props.onFocus(viewModel)}
                onChange={(e) => this.onChange(e)}
                onKeyDown={(e) => this.onKeyDown(e)}
                style={{
                  zIndex: 1,
                  resize: 'none',
                  overflow: 'hidden',
                  fontSize: annotationArgs.fontSize,
                  left: state.left,
                  top: `${parseFloat(state.top) + 2 + 2 * INPUT_BORDER_WIDTH}px`,
                  height: state.height * state.scaleY + 2,
                  width: state.width * state.scaleX + 1
                }} />
    );
  }
}

@observer class CheckboxField extends React.Component {
  onChange = () => {
    this.props.onFocus(this.props.viewModel);
    switch (this.props.viewModel.value) {
      case 'checked':
        this.props.viewModel.value = 'unchecked';
        break;
      case 'unchecked':
        this.props.viewModel.value = 'checked';
        break;
      default:
        throw new Error(`Invalid checkbox value: ${this.props.viewModel.value}`);
    }
  }

  render() {
    const {viewModel} = this.props;
    const {state, annotationArgs} = viewModel.annotation;
    const fontSize = state.height * state.scaleY + 2;

    return (
      <div  id={annotationArgs.id}
            className='absolute'
            onClick={this.onChange}
            style={{
              zIndex: 1,
              resize: 'none',
              left: state.left,
              top: `${parseFloat(state.top) + 4}px`,
              height: state.height * state.scaleY + 2,
              width: state.width * state.scaleX + 2,
              fontSize: fontSize,
              cursor: 'pointer',
              border: '1px solid #C4CDD5',
              borderRadius: '2px',
              backgroundColor: 'white',
              textAlign: 'center'
            }}>
        {viewModel.value === 'checked' &&
          <div style={{marginTop: -(fontSize * 1.41 - fontSize)/2}}>{'✓'}</div>}
      </div>
    );
  }
}

@observer class EmployeeSignatureField extends React.Component {
  signatureData;
  title = '';

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.onEnter();
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      this.props.onEnter();
    }
  }


  openSignatureModal = () => {
    const {viewModel} = this.props;

    this.props.onFocus(viewModel);
    viewModel.modalOpen = true;
    this.signatureData = viewModel.signatureData;
    this.title = viewModel.isEmpty ?
      'tools.Create new signature' :
      'tools.Update signature';
  }

  closeSignatureModal() {
    this.props.viewModel.modalOpen = false;
  }

  onSignatureSave(signatureData) {
    const {uiState} = this.props;

    uiState.updateEmployeeSignature(signatureData);
    this.closeSignatureModal();
  }

  render() {
    const {viewModel} = this.props;
    const {state, annotationArgs} = viewModel.annotation;

    return (
      <div>
        <div  id={annotationArgs.id}
              className='absolute'
              onClick={this.openSignatureModal}
              tabIndex={-1}
              onKeyPress={(e) => this.handleKeyPress(e)}
              onKeyDown={(e) => this.handleKeyDown(e)}
              style={{
                zIndex: 1,
                left: state.left,
                top: `${parseFloat(state.top) + 4}px`,
                height: state.height * state.scaleY + 2,
                width: state.width * state.scaleX + 2,
                cursor: 'pointer',
              }}>
          {!viewModel.isEmpty && <img src={viewModel.signatureData}
               alt=''
               className='absolute'
               style={{
                 height: state.height * state.scaleY + 2,
                 width: state.width * state.scaleX + 2
               }}>
          </img>}
        </div>

        <Modal size='md' isOpen={viewModel.modalOpen} closeButton={false}>
          <div className='h2 medium mb2'>
            <FormattedMessage id={this.title}/>
          </div>
          <div className='mt3 mb4'>
            <SignaturePad onChange={(dataURL) => this.signatureData = dataURL} clearStyle='left' signatureData={this.signatureData}/>
          </div>
          <ModalButtons saveCaption={'tools.Save'} onSave={() => this.onSignatureSave(this.signatureData)} onCancel={() => this.closeSignatureModal()}/>
        </Modal>
      </div>
    );
  }
}

const AnnotatableInputFactory = observer(({viewModel, onFocus, onEnter, uiState}) => {
  const {annotation} = viewModel;

  switch (annotation.annotationType) {
    case 'employee_signature':
      return getSignatureField(viewModel, uiState, onFocus, onEnter);
    case 'input':
      return getInputField(annotation.annotationArgs.inputType, viewModel, onFocus, onEnter);
    default:
      return null;
  }
});

function getSignatureField(viewModel, uiState, onFocus, onEnter) {
  return <EmployeeSignatureField viewModel={viewModel} uiState={uiState} onFocus={onFocus} onEnter={onEnter}/>;
}

function getInputField(inputType, viewModel, onFocus, onEnter) {
  switch (inputType) {
    case 'text':
      return <TextField viewModel={viewModel} onFocus={onFocus} onEnter={onEnter}/>;
    case 'textarea':
      return <TextareaField viewModel={viewModel} onFocus={onFocus} onEnter={onEnter}/>;
    case 'checkbox':
      return <CheckboxField viewModel={viewModel} onFocus={onFocus} onEnter={onEnter}/>;
    default:
      throw new Error(`Input type ${inputType} is not supported.`);
  }
}

export default AnnotatableInputFactory;
