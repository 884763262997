import React from 'react';
import {observer} from 'mobx-react';
import {auth} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const FillerPositionText = ({staffCount, distinctJobTypes}) => {
  if (distinctJobTypes >= 2) return null;

  if (distinctJobTypes === 1) {
    if (!staffCount.fullTime) return (
      <span className='employment-type-count types'>
        <FormattedMessage
          id='hr_dashboard.staff_count.FULL_TIME'
          values={{num: 0}}
        />
      </span>
    );

    if (!staffCount.partTime) return (
      <span className='employment-type-count types'>
        <FormattedMessage
          id='hr_dashboard.staff_count.PART_TIME'
          values={{num: 0}}
        />
      </span>
    );
  }

  return (
    <span>
      <span className='employment-type-count types'>
        <FormattedMessage
          id='hr_dashboard.staff_count.FULL_TIME'
          values={{num: 0}}
        />
      </span>
      <span className='employment-type-count types'>
        <FormattedMessage
          id='hr_dashboard.staff_count.PART_TIME'
          values={{num: 0}}
        />
      </span>
    </span>
  );
};

const PositionText = ({positionCount, positionID}) => {
  if (!positionCount) return null;

  return (
    <span className='employment-type-count types'>
      <FormattedMessage
        id={positionID}
        values={{num: positionCount}}
      />
    </span>
  );
};

const StaffCount = observer(({staffCount, distinctJobTypes}) => {
  if (!auth.user.staffCountVisibility) {
    return null;
  }

  return (
    <div className='StaffCount pt1'>
      <div>
        <span className='employment-type-count active-staff mr1'><FormattedMessage id='hr_dashboard.staff_count.ACTIVE_STAFF' values={{num: staffCount.active}}/></span>
        {staffCount.onLeave > 0 && <span className='employment-type-count'><FormattedMessage id='hr_dashboard.staff_count.ON_LEAVE_STAFF' values={{num: staffCount.onLeave}}/></span>}
      </div>
      <PositionText positionCount={staffCount.fullTime} positionID='hr_dashboard.staff_count.FULL_TIME'/>
      <PositionText positionCount={staffCount.partTime} positionID='hr_dashboard.staff_count.PART_TIME'/>
      <PositionText positionCount={staffCount.temp} positionID='hr_dashboard.staff_count.TEMP'/>
      <PositionText positionCount={staffCount.intern} positionID='hr_dashboard.staff_count.INTERN'/>
      <PositionText positionCount={staffCount.volunteer} positionID='hr_dashboard.staff_count.VOLUNTEER'/>
      <PositionText positionCount={staffCount.contractor} positionID='hr_dashboard.staff_count.CONTRACTOR'/>
      <PositionText positionCount={staffCount.seasonal} positionID='hr_dashboard.staff_count.SEASONAL'/>
      <PositionText positionCount={staffCount.casual} positionID='hr_dashboard.staff_count.CASUAL'/>
      <FillerPositionText staffCount={staffCount} distinctJobTypes={distinctJobTypes}/>
    </div>
  );
});

export default StaffCount;
