import React from 'react';
import {observer} from 'mobx-react';
import {RadioButton, RadioButtonGroup, Input, Select2, FormError} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
/*global $*/

@observer class ReminderDateSelector extends React.Component {
  componentDidMount() {
    $(this.dueDateInput).mask('000');
  }

  render() {
    const {uiState} = this.props;
    const {reminder, errors} = uiState;

    return (
      <div>
        <RadioButtonGroup
          label='company_settings.reminders.Send reminder'
          name='dueDate'
          selectedValue={uiState.selectedReminderDateOption}
          onChange={(name, value) => uiState.updateDateOption(value)}>
          <RadioButton className='block' value={'zero'}>
            <FormattedMessage id='company_settings.reminders.On due date'/>
          </RadioButton>
          <RadioButton className='block' value={'offset'}>
            <FormattedMessage id='company_settings.reminders.Before or after due date'/>
          </RadioButton>
          {uiState.selectedReminderDateOption === 'offset' &&
            <div>
              <div className='clearfix mxn1'>
                <div className='col col-12 sm-col-4 py1 px1'>
                  <Input
                    onChange={e => uiState.updateRelativeDateValue(e.target.value)}
                    value={reminder.absoluteDateValue}
                    maxLength='3'
                  />
                </div>
                <div className='col col-12 sm-col-4 py1 px1'>
                  <Select2 dropDownClass='select2-animation'
                           value={reminder.relativeDateUnit}
                           onChange={e => reminder.merge({relativeDateUnit: e.target.value})}>
                    <option key='days' value='days'>{t('company_settings.reminders.days')}</option>
                    <option key='weeks' value='weeks'>{t('company_settings.reminders.weeks')}</option>
                    <option key='months' value='months'>{t('company_settings.reminders.months')}</option>
                  </Select2>
                </div>
                <div className='col col-12 sm-col-4 py1 px1'>
                  <Select2 dropDownClass='select2-animation' value={uiState.offset}
                           onChange={e => uiState.updateOffset(e.target.value)}>
                    <option key='after' value={'after'}>{t('company_settings.reminders.after')}</option>
                    <option key='before' value={'before'}>{t('company_settings.reminders.before')}</option>
                  </Select2>
                </div>
              </div>
              <FormError message={errors.relativeDateValue}/>
            </div>
          }
        </RadioButtonGroup>
      </div>
    );
  }
}

export default ReminderDateSelector;
