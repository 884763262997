import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {t} from 'shared/core';
import CarrierIcon from '../../CarrierIcon';

const CoverageItem = observer(({benefitClass, coverage, currentCoverage, onCoverageSelected}) => {
  const {companyInsuranceCarrier} = benefitClass.benefitDivision;
  return (
    <div 
      onClick={() => onCoverageSelected(coverage, benefitClass)} 
      className={`${coverage === currentCoverage ? 'bg-sky border-right--dodger' : ''} bg-hover-haze mxn1 mb1 clickable`}>
      <div className='table full-width p1'>
        <div className='col-2 table-cell align-middle pr1'>
          <CarrierIcon carrier={companyInsuranceCarrier}/>
        </div>
        <div className='col-10 table-cell align-middle pl1'>
          <div className={coverage === currentCoverage ? 'medium dodger' : ''}>{coverage.title}</div> 
          <div className='h5 waterloo'>{companyInsuranceCarrier.name}</div>
        </div>
      </div>
    </div>
  );
});

const CoverageList = observer(({benefitClasses, currentCoverage, onCoverageSelected}) => {
  return (
    <Panel>
      <Panel.Header title={t('components.coverages_list.Benefit Coverages')} />
        {benefitClasses.map(benefitClass => {
          return benefitClass.sortedGroupBenefitCoverages.map(coverage =>
            <CoverageItem
              key={coverage.id}
              benefitClass={benefitClass}
              coverage={coverage}
              currentCoverage={currentCoverage}
              onCoverageSelected={onCoverageSelected}
            />);
        })}
    </Panel>
  );
});

export default CoverageList;
