import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';
import {ActionLink} from 'components';

const LoginErrorMessage = observer(({errorMessage, forgotPassword}) => {
  if (!errorMessage) return null;

  return (
    <div className='center p2 rounded bg-error mb3 tuatara regular'>
      <div>{errorMessage}</div>
      {forgotPassword && <div>
        <ActionLink onClick={() => window.location.href = '/password_resets/new'}>
          <FormattedMessage id='login.Forgot your password?'/>
        </ActionLink>
      </div>}
    </div>
  );
});

export default LoginErrorMessage;
