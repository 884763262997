import {observable, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import _ from 'lodash';

class TimeOffState {
  store = new DomainStore();
  Emoji;

  @observable accounts = [];

  receiveProps({accounts, Emoji}) {
    this.accounts = accounts;
    this.Emoji = Emoji;
  }

  @computed get sortedAccounts() {
    return _.orderBy(this.accounts, 'timeOffType.order');
  }
}

export default TimeOffState;
