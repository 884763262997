import React from 'react';

const EmojiTitle = ({Emoji, emojiKey, title}) => {
  return (
    <div className='flex'>
      {Emoji && <span className='mr1'>
        <Emoji emoji={emojiKey} size={22}/>
      </span>}
      {title}
    </div>
  );
};

export default EmojiTitle;
