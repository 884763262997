import {DomainStore} from 'shared/store';
import Url from 'domurl';
import _ from 'lodash';

const DEFAULT_PAGE_SIZE = 25;

class AsyncDataProxy {
  type;
  Model;
  modelCreator;
  pageSize;
  store = new DomainStore();

  constructor({endpoint, modelType, model, modelCreator, pageSize}) {
    this.endpoint = endpoint;
    this.type = modelType;
    this.Model = model;
    this.modelCreator = modelCreator;
    this.pageSize = pageSize || DEFAULT_PAGE_SIZE;
  }

  constructUrl({endpoint, filter, pagination, sorting}) {
    const url = new Url(endpoint, true);

    this.__addPaginationParams(url, pagination);
    this.__addFilterParams(url, filter);
    this.__addSortingParams(url, sorting);

    return url.toString();
  }

  __addPaginationParams(url, pagination) {
    url.query['page[number]'] = pagination.currentPage;
    url.query['page[size]'] = this.pageSize;
  }

  __addFilterParams(url, filter) {
    _.forOwn(filter, (value, key) => {
      url.query[`filter[${key}]`] = value;
    });
  }

  __addSortingParams(url, sorting) {
    const sortingString = _.chain(sorting)
      .toPairs()
      .filter(pair => pair[1])
      .map(pair => pair[1] === 'asc' ? pair[0] : `-${pair[0]}`)
      .join()
      .value();
    url.query['sort'] = sortingString;
  }

  async load({filter, pagination, sorting}) {
    const url = this.constructUrl({
      endpoint: this.endpoint,
      filter,
      pagination,
      sorting
    });
    const composed = await this.store._compose(url);
    const meta = composed[0].meta || {};
    const data = composed[0].data || [];

    return {
      data: this.__filterData(data),
      pagination: {
        currentPage: meta.currentPage || 0,
        totalCount: meta.totalCount || 0,
        totalPages: meta.totalPages || 0,
        pageSize: this.pageSize
      },
      filter,
      sorting
    };
  }

  __filterData(data) {
    if (!this.type) return data;

    const filtered = _.filter(data, {_type: this.type});
    if (this.Model) {
      return filtered.map(m => new this.Model(m));
    } else if (this.modelCreator) {
      return filtered.map(m => this.modelCreator(m));
    } else {
      return filtered;
    }
  }
}

export default AsyncDataProxy;
