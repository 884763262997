import React from 'react';
import {auth, endpoints, types, t} from 'shared/core';
import Employee from 'stores/employees/Employee';
import {InteractiveTable, FancyFilter} from 'components';
import EmployeeDirectoryName from './EmployeeDirectoryName';
import EmployeeDirectoryStatus from './EmployeeDirectoryStatus';
import employeeUrl from '../employeeUrl';

const COLUMNS = [
  {
    header: 'employees.directory.Name',
    width: auth.user.admin ? 4 : 5,
    component: EmployeeDirectoryName,
    sortBy: 'name'
  },
  {
    header: 'employees.directory.Title',
    width: auth.user.admin ? 2 : 3,
    attribute: 'jobTitle',
    className: 'xs-hide'
  },
  {
    header: 'employees.directory.Department',
    width: 2,
    attribute: 'department.name',
    className: 'md-and-below-hide'
  },
  {
    header: 'employees.directory.Location',
    width: 2,
    attribute: 'location.name',
    className: 'sm-and-below-hide'
  }
];

if (auth.user.admin) {
  COLUMNS.push({
    header: 'employees.directory.Status',
    width: 2,
    component: EmployeeDirectoryStatus,
    sortBy: 'employmentType',
    className: 'md-and-below-hide'
  });
}

function scopeFilters() {
  return [
    {
      scope: 'new',
      display: t('employees.directory.scopes.Not onboarded')
    },
    {
      scope: 'late',
      display: t('employees.directory.scopes.Late onboarding')
    }
  ];
}

function fancyFilters() {
  const filters = ['location', 'department'];

  if (auth.hasAccess('::TERMINATE_EMPLOYEES')) {
    filters.push('employment_status');
  }

  if (auth.user.admin) {
    filters.push('employment_type');
  }

  return filters;
}

const EmployeeList = () => {
  return (
    <div className='Container'>
      <InteractiveTable
        columns={COLUMNS}
        showLinks={false}
        showPath={employeeUrl}
        saveStateToUrl={true}
        fancyFiltersEnabled
        initialFilter={auth.hasAccess('::TERMINATE_EMPLOYEES') ? {employment_status: 'non_terminated'} : {}}
        proxy={{
          type: 'async',
          modelType: types.EMPLOYEE,
          model: Employee,
          endpoint: endpoints.EMPLOYEE_DIRECTORY.SUMMARIES
        }}
        scopeOptions={scopeFilters()}
      >
        <FancyFilter filters={fancyFilters()}/>
      </InteractiveTable>
    </div>
  );
};

export default EmployeeList;
