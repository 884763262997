import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';

const DefaultRoleEditLink = observer(({role}) => {
  if (!role.canPatch) return null;

  switch (role.roleType) {
    case 'admin':
      return (
        <Link className='Btn Btn--primary Btn--sm right' to={`/permissions/${role.id}`}>
          <FormattedMessage id='company_settings.permissions.Edit Assignees'/>
        </Link>
      );
    case 'employee':
    case 'manager':
      return (
        <Link className='Btn Btn--primary Btn--sm right' to={`/permissions/${role.id}`}>
          <FormattedMessage id='company_settings.permissions.Edit Permissions'/>
        </Link>
      );
    default:
      throw new Error('Unknown role type');
  }
});

export default DefaultRoleEditLink;
