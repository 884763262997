import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {ActionLink, Label, Panel} from 'components';
import EditContactInfoModal from './EditContactInfoModal';
import {t} from 'shared/core';

const ContactInformation = observer(({uiState}) => {
  const {employee} = uiState;

  return (
    <Panel className='mt2 mb1'>
      <Panel.Header title={t('employees.profile.personal.Contact Information')}>
        {uiState.editDataPermission('::PERSONAL_INFORMATION') && <ActionLink onClick={() => uiState.openContactInfoModal()}>
          <FormattedMessage id='employees.profile.personal.Edit'/>
        </ActionLink>}
      </Panel.Header>
      <div className='col-12 sm-col-10 pt1'>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Work Email'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='workEmail'/>
          </div>
        </div>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Personal Email'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='personalEmail'/>
          </div>
        </div>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Work Phone'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='workPhone'/>
          </div>
        </div>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Work Phone Extension'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='workPhoneExtension'/>
          </div>
        </div>
        <div className='clearfix mxn1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Personal Phone'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='personalPhone'/>
          </div>
        </div>
      </div>
      <EditContactInfoModal uiState={uiState} />
    </Panel>
  );
});

export default ContactInformation;
