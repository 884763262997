import {DomainObject} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {User} from 'stores/users';
import {timestampDate} from 'shared/tools';
import {CurrentValueUpdate, GoalComment, GoalCreated} from 'stores/performance_goals';
import {types} from 'shared/core';

function isGoalCreated(obj) {
  return obj._type === types.PERFORMANCE_GOALS.TIMELINE.GOAL_CREATED;
}

function isComment(obj) {
  return obj._type === types.PERFORMANCE_GOALS.TIMELINE.COMMENT;
}

function isCurrentValueUpdate(obj) {
  return obj._type === types.PERFORMANCE_GOALS.TIMELINE.CURRENT_VALUE_UPDATE;
}

class TimelineEntry extends DomainObject {
  @observable user;
  @observable createdAt;

  @action merge(other) {
    super.merge(other, {
      user: User,
      _dates: ['createdAt']
    });
  }

  @computed get createdAtView() {
    return timestampDate(this.createdAt);
  }
}

TimelineEntry.fromJS = (json) => {
  if (isComment(json)) {
    return new GoalComment(json);
  } else if (isGoalCreated(json)) {
    return new GoalCreated(json);
  } else if (isCurrentValueUpdate(json)) {
    return new CurrentValueUpdate(json);
  }

  throw new Error(`TimelineEntry type ${json._type} is not supported.`);
};


export default TimelineEntry;
