import React from 'react';
import {observer} from 'mobx-react';
import {Switch, Route} from 'react-router-dom';
import PendingRequestsList from './PendingRequestsList';
import PendingRequestDetails from './PendingRequestDetails';

const PendingRequestPage = observer(({match, scope}) => {
  return (
    <Switch>
      <Route exact path={match.path} render={({history, match}) => <PendingRequestsList match={match} history={history} scope={scope}/>} />
      <Route path={`${match.path}/:id`} render={({history, match}) => <PendingRequestDetails match={match} history={history} scope={scope}/>} />
    </Switch>
  );
});

export default PendingRequestPage;
