import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Input, Submit} from 'components';

const RecoveryCodeForm = observer(({uiState}) => {
  const {form, errors} = uiState;

  return (
    <div>
      <div className='h1 center medium pb3 pt1'>
        <div className='pb1'>
          <FormattedMessage id='login.Enter your recovery code' />
        </div>
      </div>
      <div className='h4 jumbo pb3'>
        <FormattedMessage id='login.RECOVERY_CODE_DESC' />
      </div>
      <form>
        <div className='clearfix'>
          <div className='col col-12 mb3'>
            <Input
              className='large'
              value={form.recoveryCode}
              autoFocus={true}
              onChange={e => form.merge({recoveryCode: e.target.value})}
              errorMessage={errors.base}
            />
          </div>
          <div className='col col-12 mb2'>
            <Submit size='lg' className='full-width' onClick={() => uiState.createSession()}>
              <FormattedMessage id='login.Verify'/>
            </Submit>
          </div>
        </div>
      </form>
    </div>
  );
});


export default RecoveryCodeForm;
