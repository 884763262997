import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {HtmlRender} from 'components';

const OptionalTag = observer(({required}) => {
  if (required) return null;

  return (
    <span className='waterloo'>
      <FormattedMessage id='recruiting.candidate.(Optional)'/>
    </span>
  );
});

const FieldHeader = observer(({title, required, description, className, descriptionClassName}) => {
  return (
    <React.Fragment>
      <label>
        <span className={`${className} mb1 ${required ? '': 'mr1'}`}>{title}</span>
        <OptionalTag required={required}/>
      </label>
      {description && <div className={`${descriptionClassName} mb1`}><HtmlRender html={description}/></div>}
    </React.Fragment>
  );
});

FieldHeader.defaultProps = {
  required: true,
  className: '',
  descriptionClassName: ''
};

export default FieldHeader;
