import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';

const CandidateListEmptyState = observer(() => {
  return (
    <div className='h3 center jumbo p2 block'>
      <FormattedMessage
        id='recruiting.hiring_funnel.No candidates match your search criteria.'/>
    </div>
  );
});

export default CandidateListEmptyState;
