import React from 'react';
import {observer} from 'mobx-react';
import {Employment} from './employment';
import {Compensation} from './compensation';
import {Benefits} from './benefits';
import {BankingAndTax} from './banking_and_tax';
import {Documents} from './documents';
import {Tasks} from './tasks';
import {Review} from './review';
import {Training} from './training';
import {CustomFields} from './custom_fields';
import {JobCustomFields} from './job_custom_fields';
import {PayrollNotEnabled} from './payroll';

@observer class Step extends React.Component {
  render() {
    const {step, uiState} = this.props;

    switch (step) {
      case 'employment':
        return <Employment uiState={uiState}/>;
      case 'compensation':
        return <Compensation uiState={uiState}/>;
      case 'benefits':
        return <Benefits uiState={uiState}/>;
      case 'payroll':
        return <PayrollNotEnabled uiState={uiState}/>;
      case 'job_custom_fields':
        return <JobCustomFields uiState={uiState}/>;
      case 'custom_fields':
        return <CustomFields uiState={uiState}/>;
      case 'training':
        return <Training uiState={uiState}/>;
      case 'banking':
        return <BankingAndTax uiState={uiState}/>;
      case 'documents':
        return <Documents uiState={uiState}/>;
      case 'tasks':
        return <Tasks uiState={uiState}/>;
      case 'review':
        return <Review uiState={uiState}/>;
      default:
        throw new Error(`Location ${step} is not supported.`);
    }
  }
}

export default Step;
