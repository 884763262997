import React from 'react';
import {observer} from 'mobx-react';
import {Layout, Panel} from 'components';
import {t} from 'shared/core';
import {ReportList, ReportRow} from 'reports/list';
import TableIcon from 'img/table-icon@2x.png';
import ClientProfileContainer from '../../../../ClientProfileContainer';

const ReportDirectory = observer(({match, history, parentState}) => {
  return (
    <ClientProfileContainer {...{match, history, parentState}}>
      <Layout>
        <Layout.Section secondary>
          <Panel>
            <ReportList 
              visible={true} 
              caption='broker_portal.clients.reports.Client Reports' 
              details="broker_portal.clients.reports.View reports on a client's employees and enrolments"
            >
              <ReportRow 
                name={t('broker_portal.clients.reports.Census Report')} 
                path={`${match.url}/census`} 
                icon={TableIcon} 
                visible={true} 
              />
            </ReportList>
          </Panel>
        </Layout.Section>
      </Layout>
    </ClientProfileContainer>
  );
});

export default ReportDirectory;
