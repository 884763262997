import {observable, computed} from 'mobx';

class SignatureViewModel {
  @observable annotation;
  @observable modalOpen;
  @observable signatureData;

  constructor(annotation) {
    this.annotation = annotation;
    this.modalOpen = false;
  }

  @computed get isEmpty() {
    return !this.signatureData;
  }

  @computed get isValid() {
    return !this.isEmpty;
  }
}

export default SignatureViewModel;
