import {observable, action} from 'mobx';
import {DomainStore} from 'shared/store';
import _ from 'lodash';
import {endpoints, types, api, auth, t} from 'shared/core';
import {serializeModel} from 'shared/store';
import {successAlert} from 'shared/tools';
import BenefitInfo from 'stores/benefits/BenefitInfo';

class EmployeeOnboardingState {
  store = new DomainStore();

  @observable benefitInfo;
  @observable onboardStep;
  @observable errors = {};

  receiveProps({onboardStep}) {
    this.onboardStep = onboardStep;
  }

  @action async load() {
    await this.store._compose([
      endpoints.BENEFIT_INFO.with(auth.employee.id)
    ]);

    this.benefitInfo = new BenefitInfo(this.store._getSingle(types.BENEFIT_INFO));
  }

  @action async saveBenefitInfo() {
    const {model, errors} = await this.store.patch(this.benefitInfo);
    this.errors = errors;

    if (model) {
      this.benefitInfo.update(model);
      successAlert(t('benefit_enrolment.alerts.Benefit information saved.'));
      if (this.onboardStep) {
        await this.updateOnboardingStep();
      }
    }
  }

  @action async updateOnboardingStep() {
    await api.patch(`employees/${auth.employee.id}/onboard`, serializeModel({step: this.onboardStep}));
    window.location = '/onboard';
  }
}

export default EmployeeOnboardingState;
