import {action, computed} from 'mobx';
import {auth, endpoints, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import {Employee} from 'stores/employees';
import qs from 'qs';
import {observable} from 'mobx';
import {errorAlert} from 'shared/tools';
import {t} from 'shared/core';
import _ from 'lodash';

class SubmitRequestState {
  store = new DomainStore();

  @observable statusCode;
  @observable selectedSubjectIndex;
  @observable otherSubject;
  @observable body;
  @observable errors = {};
  @observable employees;
  @observable selectedEmployee = {};

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.EMPLOYEES.TERMINATED
    ]);

    this.employees = this.store._getAll(types.EMPLOYEE, Employee);

    this.statusCode  = qs.parse(location.search.replace('?', ''))['code'];
    const message = qs.parse(location.search.replace('?', ''))['message'];

    if (this.statusCode && message) {
      errorAlert(t('submit_request.ERROR_MESSAGE', {statusCode: this.statusCode, message}));
    }

    this.selectedSubjectIndex = 0;
  }

  @action clearStatus() {
    this.statusCode = null;
  }

  @action setSubject(value) {
    this.selectedSubjectIndex = value;
  }

  @action setOtherSubject(value) {
    this.otherSubject = value;
  }

  @action setBody(value) {
    this.body = value;
  }

  @action setEmployee(value) {
    this.selectedEmployee = value;
  }

  @action submit() {
    this.setErrors();

    if (_.isEmpty(this.errors)) {
      document.getElementById('submit-request-form').submit();
    }
  }

  @computed get canEditSubject() {
    return this.selectedSubjectOption.key === 'other_subject';
  }

  @computed get subjectWithEmployeeAndCompanyName() {
    const subject = this.canEditSubject ? this.otherSubject : this.selectedSubjectOption.text;
    return `${this.selectedEmployee.name} at ${auth.company.name}: ${subject}`;
  }

  @computed get selectedSubjectOption() {
    return this.subjectOptions()[this.selectedSubjectIndex];
  }

  setErrors() {
    this.errors = {};

    if (!this.selectedSubjectOption) this.errors.subject = t('Subject is required');
    if (this.canEditSubject && !this.otherSubject) this.errors.otherSubject = t('Other subject is required');
    if (!this.body) this.errors.body = t('Body is required');
    if (_.isEmpty(this.selectedEmployee)) this.errors.employee = t('Employee is required');
  }

  subjectOptions() {
    return [
      {
        key: 'extension_severance',
        text: t('submit_request.subject_options.Extension of benefits / Severance'),
      },
      {
        key: 'lost_card',
        text: t('submit_request.subject_options.Lost benefits card'),
      },
      {
        key: 'data_correction',
        text: t('submit_request.subject_options.Data correction'),
      },
      {
        key: 'other_subject',
        text: t('submit_request.subject_options.Other subject'),
      }
    ];
  }
}

export default SubmitRequestState;
