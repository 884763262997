const options = {
  marginTop: 20,
  spacingRight: -20,
  height: 400,
  legend: false,
  credits: {enabled: false},
  title: null,
  colors: [
    '#21CCC1',
    '#217AF2',
    '#9A3BE8'
  ],
  chart: {
    type: 'areaspline',
    zoomType: 'x',
    spacing: [
      12, 0, 0, 0
    ]
  },
  xAxis: {
    type: 'datetime'
  },
  yAxis: {
    title: null,
    allowDecimals: false
  },
  plotOptions: {
    areaspline: {
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1
        },
        stops: [
          [0, '#21CCC1'],
          [1, 'rgba(33, 204, 193, 0.2)']
        ]
      },
    }
  }
};

export default options;
