import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, FormLayout, TextArea, RadioButtonGroup, RadioButton} from 'components';

const AdditionalInformation = observer(({uiState}) => {
  const {brokerClient}  = uiState;

  return (
    <Panel>
      <Panel.Header title={t('broker_portal.clients.edit.Additional Information')}/>
      <FormLayout>
        <TextArea
          label='broker_portal.clients.edit.Comments'
          value={brokerClient.comment}
          onChange={(e) => brokerClient.merge({comment: e.target.value})}
          placeholder='broker_portal.clients.edit.Enter any additional comments here.'
          className='full-width' minRows={3}
        />
        <RadioButtonGroup
          name='interestedInServices'
          label='broker_portal.clients.edit.Is this company interested in HR or Payroll services?'
          selectedValue={brokerClient.interestedInServices}
          onChange={(name, value) => brokerClient.merge({interestedInServices: value})}
        >
          <RadioButton className='table-cell pr2' value={true}>
            {t('broker_portal.clients.edit.Yes')}
          </RadioButton>
          <RadioButton className='table-cell' value={false}>
            {t('broker_portal.clients.edit.No')}
          </RadioButton>
        </RadioButtonGroup>
      </FormLayout>
    </Panel>
  );
});

export default AdditionalInformation;
