import _ from 'lodash';
import {Steps} from 'shared/tools';

let stepsList = [];

  stepsList = [
    {
      name: 'training.edit.Overview',
      location: 'overview'
    },
    {
      name: 'training.edit.Documents',
      location: 'documents'
    },
    {
      name: 'training.edit.Questions',
      location: 'questions'
    },
    {
      name: 'training.edit.Review',
      location: 'review'
    }
  ];

const STEPS = stepsList.map((s, i) => ({ ...s, index: i + 1 }));

const steps = new Steps(STEPS);

export default steps;
