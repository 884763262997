import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Department',
  'Employment Type',
  'Gender',
  'Location',
  'Manager'
];

const TenureReport = ({showDepartmentReport}) => {
  return (
    <CompositeReport filters={filters}>
      <Report
        view='metric'
        showName={false}
        url='/reports/average_tenure'
      />
      <Report
        name={t('reports.Tenure Demographics')}
        view='chart'
        chartType='column'
        url='/reports/tenure_demographics_chart'
      />
      {showDepartmentReport && <Report
        name={t('reports.Tenure by Department')}
        view='chart'
        chartType='column'
        options={{ stacking: 'normal' }}
        url='/reports/tenure_by_department_chart'
      />}
      <Report
        name={t('reports.Tenure')}
        url='/reports/tenure'
      />
    </CompositeReport>
  );
};

export default TenureReport;
