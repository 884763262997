import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import Logo from 'img/collage-logo--blue@2x.png';
import LoginForm from 'containers/login/components/LoginForm';
import LoginContainerWrapper from './LoginContainerWrapper';
import {auth} from 'shared/core';
import OAuthButtons from 'components/login/OAuthButtons';

const RenewSessionForm = observer(({uiState}) => {
  return (
    <LoginContainerWrapper>
      <div className='center py2'>
        <img src={Logo} alt='Collage' height='26px'/>
      </div>
      <div className='pb3 pt1'>
        <div className='h2 pb1 center medium'>
          <FormattedMessage id="login.For security reasons, we've logged you out."/>
        </div>
        <div className='h4 jumbo pb3 center'>
          <FormattedMessage id="login.Log back in to continue where you left off."/>
        </div>
        {
          auth.company.ssoEnabled ?
            <OAuthButtons context='reconnect' providers={[auth.company.ssoProvider]}/>
            :
            <LoginForm uiState={uiState}/>
        }
      </div>
    </LoginContainerWrapper>
  );
});

export default RenewSessionForm;
