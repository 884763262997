import React from 'react';
import {observer} from 'mobx-react';
import {Layout, Panel} from 'components';
import {FormattedMessage} from 'react-intl';

const EmptyCoverages = observer(() => {
  return (
    <Layout>
      <Panel>
        <div className='py4 center h3'>
          <FormattedMessage id='components.coverages_list.No coverages exist for this benefit class'/>
        </div>
      </Panel>
    </Layout>
  );
});

export default EmptyCoverages;
