import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {withRouter} from 'react-router-dom';
import {InteractiveTable, DropdownFilter, Layout, Tag, AvatarList} from 'components';
import {endpoints, types, t} from 'shared/core';
import CycleDirectoryState from '../state/CycleDirectoryState';
import {FormattedMessage} from 'react-intl';
import useModal from 'shared/hooks/useModal';
import ArchiveCycleModal from './ArchiveCycleModal';

const CycleName = observer(({model}) => {
  return (
    <div className='clearfix'>
      <div className='table-cell align-middle pr1'>{model.title}</div>
      <div className='table-cell align-middle'>
        {model.status === 'archived' && <Tag colour='rose'><FormattedMessage id='performance.Archived'/></Tag>}
        {model.status === 'draft' && <Tag><FormattedMessage id='performance.Draft'/></Tag>}
      </div>
    </div>
  );
});

const CycleLeaders = observer(({model}) => {
  if (!model.totalManagers) {
    return (
      <div className='clearfix waterloo'>
        <FormattedMessage id='performance.No managers assigned'/>
      </div>
    );
  }

  return (
    <AvatarList employees={model.managers}/>
  );
});

const CycleEmployees = observer(({model}) => {
  if (!model.totalEmployees) return null;
  
  return (
    <AvatarList employees={model.employees}/>
  );
});

const COLUMNS = [
  {
    header: 'performance.Name',
    width: 3,
    sortBy: 'name',
    component: CycleName
  },
  {
    header: 'performance.Leaders',
    width: 3,
    component: CycleLeaders
  },
  {
    header: 'performance.Employees',
    width: 3,
    component: CycleEmployees
  },
  {
    header: 'performance.Frequency',
    render: model => model.frequency && <FormattedMessage id={`performance.${model.frequency}`}/>,
    width: 2
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'performance.Delete Check-In Cycle',
    subHeader: 'performance.Are you sure you want to delete this check-in cycle?'
  },
  interactive: {
    addModel: 'performance.Create New Check-In Cycle',
  },
  emptyState: 'performance.No check-in cycles to display'
};

function statusFilters() {
  return [
    {
      id: 'active',
      display: t('performance.Active')
    },
    {
      id: 'archived',
      display: t('performance.Archived')
    }
  ];
}

const CycleDirectoryWithState = observer(({uiState}) => {
  const archiveCycleModalOpen = useModal(uiState, 'ArchiveCycleModal', 'selectedCycle');

  return (
    <Layout>
      <InteractiveTable
        title={t('performance.Check-In Cycles')}
        uiState={uiState}
        columns={COLUMNS}
        localization={LOCALIZATION}
        searchable
        saveStateToUrl
        onMount={agent => uiState.setInteractiveAgent(agent)}
        onAdd={() => uiState.createCycle()}
        onRemove={model => uiState.destroyCycle(model)}
        onEdit={model => uiState.goToCycle(model)}
        showRoute={model => model.status === 'draft' ? `/cycles/${model.id}` : `/cycles/${model.id}/reviewees`}
        customLinks={model => uiState.customLinksFor(model)}
        proxy={{
          type: 'async',
          modelType: types.PERFORMANCE.CYCLE,
          modelCreator: c => uiState.buildCycle(c),
          endpoint: endpoints.PERFORMANCE.CYCLES
        }}
      >
        <DropdownFilter options={statusFilters()} attr='status'/>
      </InteractiveTable>
      <ArchiveCycleModal uiState={uiState} modalOpen={archiveCycleModalOpen}/>
    </Layout>
  );
});

export default withRouter(withState(CycleDirectoryWithState, CycleDirectoryState));
