import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Table} from 'components';
import {t} from 'shared/core';

const COLUMNS = [
  {
    header: 'benefits.benefit_info.edit.review.Field',
    width: 6,
    attribute: 'name'
  },
  {
    header: 'benefits.benefit_info.edit.review.New value',
    width: 6,
    render: model => <div className='fs-hide'>{model.value}</div>
  }
];

const UpdateSummary = observer(({summary}) => {
  return (
    <div className='pb2'>
      <Panel.Separator/>
      <Panel.Subheader title={t(`benefits.benefit_info.edit.update_summary_type.${summary.type}`)}/>
      <Table
        indexBy='name'
        trait='xcompact'
        showLinks={false}
        columns={COLUMNS}
        models={summary.fields}
      />
    </div>
  );
});

export default UpdateSummary;
