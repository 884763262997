import React from 'react';
import {observer} from 'mobx-react';
import EmployeeProfileHeader from './EmployeeProfileHeader';
import AvatarSection from './AvatarSection';
import EmployeeInviteStatus from './EmployeeInviteStatus';
import OnboardingSection from './OnboardingSection';
import {Panel} from 'components';
import DirectReportsSection from 'containers/employees/profile/components/DirectReportsSection';

const EmployeeProfileContainer = observer(({uiState, children}) => {
  return (
    <div>
      <EmployeeProfileHeader uiState={uiState}/>
      <div className='Container'>
        <EmployeeInviteStatus uiState={uiState}/>
        <div className='clearfix mxn1'>
          <div className='col col-12 md-col-4 px1 sm-and-below-hide'>
            <Panel.Stack loose>
              <AvatarSection uiState={uiState}/>
              <OnboardingSection uiState={uiState}/>
              <DirectReportsSection uiState={uiState}/>
            </Panel.Stack>
          </div>
          <div className='col col-12 md-col-8 px1'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
});

export default EmployeeProfileContainer;
