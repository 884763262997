import {computed, observable} from 'mobx';
import {t} from 'shared/core';

class NumberInputState {
  @observable value;
  @observable max;
  @observable min;
  @observable onChange;
  @observable backEndErrorMessage;

  receiveProps({value, max, min, onChange, errorMessage}) {
    this.backEndErrorMessage = errorMessage;
    this.value = value;
    this.max = max;
    this.min = min;
    this.onChange = onChange;
  }

  onInputChange(e) {
    return this.onChange(e, this.isValid(e.target.value));
  }

  isValid(value) {
    const number = Number(value);
    if (isNaN(number)) {
      return false;
    }
    if (this.isMaxSet && number > this.max) {
      return false;
    }
    if (this.isMinSet && number < this.min) {
      return false;
    }

    return true;
  }

  @computed get isMaxSet() {
    return this.max || this.max === 0;
  }

  @computed get isMinSet() {
    return this.min || this.min === 0;
  }

  @computed get frontEndErrorMessage() {
    if (this.isValid(this.value) || this.value === '') {
      return null;
    }

    if (this.isMaxSet && this.isMinSet) {
      return t('components.number_input.must_be_number_between', {max: this.max, min: this.min});
    } else if (this.isMaxSet) {
      return t('components.number_input.must_be_number_less_than', {max: this.max});
    } else if (this.isMinSet) {
      return t('components.number_input.must_be_number_greater_than', {min: this.min});
    } else {
      return t('components.number_input.must_be_number');
    }
  }

  @computed get numberInputErrorMessage() {
    return this.frontEndErrorMessage || this.backEndErrorMessage;
  }
}

export default NumberInputState;
