import React from 'react';
import {observer} from 'mobx-react';
import {EmptyState} from 'components';
import {t} from 'shared/core';

const BenefitUpdatesEmptyState = observer(({hasResults}) => {
  return (
    <EmptyState
      title={t('benefits.updates.Benefit Updates')}
      descriptionItems={[
        t(hasResults ? 'benefits.updates.empty_search_info' : 'benefits.updates.empty_state_info')
      ]}
    />
  );
});

export default BenefitUpdatesEmptyState;
