import React from 'react';
import {observer} from 'mobx-react';
import Tag from './Tag';
import Avatar from './Avatar';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

function proportionalTextSize(size) {
  switch (size) {
    case 'xs':
      return 'h5';
    case 'sm':
      return 'h4';
    case 'md':
      return 'h3';
    case 'lg':
      return 'h2';
    default:
      return 'h4';
  }
}

const EmployeeAvatar = observer(({employee, href, to, size}) => {
  if (href) {
    return (
      <a href={href}>
        <Avatar employee={employee} size={size}/>
      </a>
    );
  }

  if (to) {
    return (
      <Link to={to} className='TextLink'>
        <Avatar employee={employee} size={size}/>
      </Link>
    );
  }

  return <Avatar employee={employee} size={size}/>;
});

const EmployeeName = observer(({employee, href, to}) => {
  if (href) {
    return <a href={href}>{employee.name}</a>;
  }

  if (to) {
    return <Link to={to} className='TextLink'>{employee.name}</Link>;
  }

  return <span>{employee.name}</span>;
});

const EmployeeNameWithAvatar = observer(({employee, size, href, to, tags, details}) => {
  return (
    <div className='flex align-items-middle'>
      <div className={`${size === 'xs' ? 'pr1' : 'pr2'}`}>
        <EmployeeAvatar employee={employee} href={href} to={to} size={size}/>
      </div>
      <div className={`${proportionalTextSize(size)} overflow-hidden`}>
        <EmployeeName employee={employee} href={href} to={to}/>
        {details && details.map((detail, index) => <div className='truncate' key={index}>{detail}</div>)}
      </div>
      {tags && <div>
        {tags.map((t, index) => <span key={index} className='pl1'><Tag colour={t.colour}>{t.content}</Tag></span>)}
      </div>}
    </div>
  );
});

// props:
// - employee: Employee. duh
// - href: optional. provide to make name/avatar link to a different url
// - to: optional. provide to make name/avatar link to a react route
// - tags: optional. an array of tag descriptors to add to the employee name
//   each tag is an object {content, colour} and renders a <Tag/> component.
// - size: size of the avatar to display

EmployeeNameWithAvatar.defaultProps = {
  size: 'sm'
};

EmployeeNameWithAvatar.propTypes = {
  employee: PropTypes.object.isRequired,
  tags: PropTypes.array,
  href: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg'])
};

export default EmployeeNameWithAvatar;
