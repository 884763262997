import React from 'react';
import {FormattedMessage} from 'react-intl';
import {AutosaveStatus, Avatar, Button, Note, TextArea} from 'components';
import {observer} from 'mobx-react';
import QuestionsAndAnswers from './QuestionsAndAnswers';
import ReviewedIcon from 'containers/performance/img/reviewed-checkmark@2x.png';
import {fullName} from 'shared/tools';
import {auth, t} from 'shared/core';

const WithReviewer = observer(({checkin}) => {
  return (
    <div className='right'>
      <div className='table-cell align-middle'>
        <Avatar employee={checkin.reviewer} size='sm'/>
      </div>
      <div className='table-cell align-middle pl1'>
        <span className='h6 jumbo'>
          <FormattedMessage id="performance.WITH_REVIEWER" values={{name: <span className='tuatara'>{fullName(checkin.reviewer)}</span>}}/>
        </span>
      </div>
    </div>
  );
});

const ReviewerNoteInput = observer(({title, detail, reviewer, placeholder, value, onChange}) => {
  return (
    <div>
      <div className='table pt3'>
        <div className='col-1 table-cell'></div>
        <div className='col-11 table-cell'>
          <div className='h3 medium'>{title}</div>
          <div className='h5 jumbo mb2'>{detail}</div>
        </div>
      </div>
      <div className='table pb2'>
        <div className='col-1 table-cell align-top'>
          {reviewer && <Avatar employee={reviewer} className='left' size='sm'/>}
        </div>
        <div className='col-11 table-cell'>
          <TextArea
            minRows={3}
            value={value}
            onChange={(e) => onChange(e)}
            placeholder={placeholder}
            translatePlaceholder={false}
            className='full-width'
          />
        </div>
      </div>
    </div>
  );
});

const ReviewerNotes = observer(({checkin, onMarkUncompleted, adminView, reviewee}) => {
  if (!checkin.completed) return null;

  return (
    <div className='border-top'>
      {checkin.sharedReviewerNotes &&
        <div className='py3'>
          <Note title={t('performance.Shared Feedback')}
                detail={t('performance.CAN_SEE_THIS', {name: fullName(reviewee)})}
                author={checkin.reviewer}>
            {checkin.sharedReviewerNotes}
          </Note>
        </div>}
      {checkin.reviewerNotes &&
        <div className='py3'>
          <Note title={t('performance.Internal Notes')}
                detail={t('performance.CANNOT_SEE_THIS', {name: fullName(reviewee)})}
                author={checkin.reviewer}>
            {checkin.reviewerNotes}
          </Note>
        </div>}
      {checkin.canUncomplete && !adminView && <div className='my3 right-align'>
        <AutosaveStatus autosaver={checkin.autosaver} className='mr2' />
        <Button className='Btn' onClick={() => onMarkUncompleted()}>
          <FormattedMessage id="performance.Undo & Edit" />
        </Button>
      </div>}
    </div>
  );
});

const ReviewerForm = observer(({checkin, onMarkCompleted, reviewee, adminView, uiState}) => {
  if (checkin.completed || adminView) return null;

  return (
    <div>
      <ReviewerNoteInput
        title={t('performance.Share Feedback')}
        detail={t('performance.NOTES_VISIBLE_TO', {name: fullName(reviewee)})}
        reviewer={checkin.reviewer}
        placeholder={t('performance.ENTER_FEEDBACK_FOR', {name: fullName(reviewee)})}
        value={checkin.sharedReviewerNotes || ''}
        onChange={(e) => uiState.updateSharedReviewerNotes(e, checkin)}
      />
      <ReviewerNoteInput
        title={t('performance.Internal Notes')}
        detail={t('performance.NOTES_NOT_VISIBLE_TO', {name: fullName(reviewee)})}
        placeholder={t('performance.Add a private note')}
        value={checkin.reviewerNotes || ''}
        onChange={(e) => uiState.updateReviewerNotes(e, checkin)}
      />
      <div className='my3 right-align'>
        <AutosaveStatus autosaver={checkin.autosaver} className='mr2' />
        <Button className='Btn' onClick={() => onMarkCompleted()}>
          <FormattedMessage id="performance.Mark as complete" />
        </Button>
      </div>
    </div>
  );
});

const CheckinFeedback = observer(({checkin, onMarkCompleted, onMarkUncompleted, reviewee, adminView, uiState}) => {
  if (!(checkin.canComplete || checkin.canUncomplete) && !auth.hasAccess('::MANAGE_PERFORMANCE_MANAGEMENT')) return null;

  return (
    <div className='clearfix fs-hide'>
      <ReviewerNotes
        checkin={checkin}
        onMarkUncompleted={onMarkUncompleted}
        reviewee={reviewee}
        adminView={adminView}
      />
      <ReviewerForm
        checkin={checkin}
        onMarkCompleted={onMarkCompleted}
        reviewee={reviewee}
        adminView={adminView}
        uiState={uiState}
      />
    </div>
  );
});

const CheckInDetails = observer(({checkin, adminView, onMarkCompleted, onMarkUncompleted, reviewee, uiState}) => {
  return (
    <div className='col-8 min-height-800 overflow-auto'>
      <div className='clearfix px4'>
        <div className='col col-12 border-bottom'>
          <div className='clearfix table py3'>
            <div className='col-1 table-cell align-middle py2'>
              {checkin.completed && <img src={ReviewedIcon} alt='reviewed-icon' height='25px'/>}
            </div>
            <div className='col-6 table-cell align-middle py2'>
              <span className='h2 medium tuatara'><FormattedMessage id="performance.Check-In"/> </span>
              <span className='h2 waterloo'>{checkin.date}</span>
            </div>
            <div className='col-5 table-cell align-middle py2'>
              {adminView && <WithReviewer checkin={checkin}/>}
            </div>
          </div>
        </div>
        <div className='col col-12 py3'>
          <div className='clearfix table'>
            <div className='col-1 table-cell'></div>
            <div className='col-11 table-cell'>
              <div className='h2 pt2'><FormattedMessage id="performance.Employee Responses"/></div>
              <div className='h5 waterloo my1'><FormattedMessage id="performance.SUBMITTED_DATE" values={{date: checkin.date}} /></div>
              <div className='clearfix'>
                <QuestionsAndAnswers answers={checkin.answers}/>
              </div>
            </div>
          </div>
          <CheckinFeedback
            checkin={checkin}
            onMarkCompleted={onMarkCompleted}
            onMarkUncompleted={onMarkUncompleted}
            reviewee={reviewee}
            adminView={adminView}
            uiState={uiState}
          />
        </div>
      </div>
    </div>
  );
});

export default CheckInDetails;
