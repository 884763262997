import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import VideoThumbnail from '../img/video_thumbnail@2x.png';
import CompanyOnboardLaunchButton from 'company_onboard/components/CompanyOnboardLaunchButton';
import EmailTemplatePreview from "components/EmailTemplatePreview";

const PreviewInviteModal = observer(({uiState}) => {
  return (
    <Modal isOpen={uiState.previewInviteModalOpen} onHide={() => uiState.closePreviewInvite()} size='md'>
      <div className='px2'>
        <div className='h2 pt1 mb3'>
          <FormattedMessage id="company_onboard.Here's your invitation."/>
        </div>
        <EmailTemplatePreview template={uiState.emailPreview}/>
        <div className='clearfix table pt3'>
          <div className='col-8 table-cell'>
            <a href='https://youtu.be/qSuFLN8dpZ4' target='_blank'>
              <div className='table-cell align-middle'>
                <img src={VideoThumbnail} alt='thumbnail' height='100'/>
              </div>
              <div className='table-cell align-middle'>
                <div className='h6 medium waterloo caps'>
                  <FormattedMessage id='company_onboard.Video'/>
                </div>
                <div className='h4 tuatara'>
                  <FormattedMessage id='company_onboard.The Employee Experience'/>
                </div>
                <div className='h5 jumbo'>
                  {'0:41'}
                </div>
              </div>
            </a>
          </div>
          <div className='col-4 table-cell align-bottom'>
            <div className='mt3 pb3 right-align'>
              <CompanyOnboardLaunchButton />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default PreviewInviteModal;
