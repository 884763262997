import React from 'react';
import {Modal, ModalButtons} from 'components/modals';
import {FormLayout, SegmentSelector, FormError} from 'components';
import {observer} from 'mobx-react';

const EditVisibilitySettingModal = observer(({uiState}) => {
  const {
    editingVisibilitySettingModalOpen,
    editingVisibilitySetting,
    errors
  } = uiState;

  return (
    <Modal
      title={'company_settings.visibility_settings.Edit Hidden Employees'}
      isOpen={editingVisibilitySettingModalOpen}
      onHide={() => uiState.closeVisibilitySettingModal()}
      size='md'
    >
      <FormLayout>
        <SegmentSelector
          segment={editingVisibilitySetting.segment}
          onChange={segment => editingVisibilitySetting.segment = segment}
        />
        <FormError message={errors.base} />
      </FormLayout>
      <ModalButtons
        onSave={() => uiState.saveVisibilitySetting()}
        onCancel={() => uiState.closeVisibilitySettingModal()}
      />
    </Modal>
  );
});

export default EditVisibilitySettingModal;
