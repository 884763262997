import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import NoteRow from './NoteRow';

const NoteList = ({uiState}) => {
  return (
    <Panel.Stack loose>
      {uiState.sortedNotes.map((note) => <NoteRow uiState={uiState} key={note.id} note={note} />)}
    </Panel.Stack>
  );
};

export default observer(NoteList);
