import React from 'react';
import {observer} from 'mobx-react';
import {auth, t} from 'shared/core';
import _ from  'lodash';
import FilterComponent from './FilterComponent';

const EmploymentStatus = observer(({filter, filterKey, filterUpdated, filterDeleted, Wrapper}) => {
  const options = [{ label: t('components.tables.fancy_filters.selectors.Active'), value: 'non_terminated' }];

  if (auth.hasAccess('::TERMINATE_EMPLOYEES')) {
    options.push({ label: t('components.tables.fancy_filters.selectors.Terminated'), value: 'terminated' });
  }

  return <FilterComponent filter={filter} filterKey={filterKey} options={options} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
});

export default EmploymentStatus;
