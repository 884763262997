const JOB_FIELDS = [
  'manager',
  'pay_rate',
  'location_id',
  'department_id',
  'job_title',
  'employment_type',
  'pay_frequency',
  'hours_per_week',
  'bonus',
  'bonus_structure',
  'commission',
  'commission_structure',
  'annualized_commission'
];

const PERSONAL_FIELDS = [
  'first_name',
  'last_name',
  'invitation_email',
  'preferred_name',
  'home_address_1',
  'home_address_2',
  'city',
  'postal_code',
  'personal_phone',
  'personal_email',
  'sin',
  'work_email',
  'work_phone',
  'work_phone_extension',
  'birth_date',
  'ec_1_name',
  'ec_1_relationship',
  'ec_1_phone',
  'ec_2_name',
  'ec_2_relationship',
  'ec_2_phone'
];

export {
  JOB_FIELDS,
  PERSONAL_FIELDS
};
