import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import OAuthButtons from 'components/login/OAuthButtons';

const LoginOAuth = observer(({context, token}) => {
  return (
    <div className='center pb3 border-bottom mb3 relative'>
      <OAuthButtons context={context} token={token} />
      <div className='absolute waterloo' style={{bottom: '-10px', background: '#fff', padding: '0 1rem', textTransform: 'uppercase', left: 'calc(50% - 2rem)' }} >
        <FormattedMessage id='login.or'/>
      </div>
    </div>
  );
});

export default LoginOAuth;
