import _ from 'lodash';
import {observable} from 'mobx';
import {t} from 'shared/core';

export function defaultQuestions() {
  return [
    {order: 1, text: t('performance.questions.What have you been focused on?')},
    {order: 2, text: t('performance.questions.How are you feeling about your workload?')},
    {order: 3, text: t('performance.questions.Are you dealing with any challenges or roadblocks?')},
    {order: 4, text: t('performance.questions.Is there anything on your mind you\'d like to share?')},
    {order: 5, text: t('performance.questions.What is going well for you?')}
  ];
}

export class Question {
  id;
  @observable text;
  @observable _destroy;
  @observable order;

  constructor(json) {
    _.merge(this, json);
  }
}
