import {t} from 'shared/core';
import {action, observable} from 'mobx';
import {successAlert} from 'shared/tools';

class CompanyFeaturesState {
  companyStore;
  @observable company;

  constructor(companyStore) {
    this.companyStore = companyStore;
  }

  @action async toggleOrgChart(checked) {
    this.company.orgChartEnabled = checked;
    await this.companyStore.patchCompany(this.company);

    window.collage.alertBox('success', t('company_settings.company_features.Org chart updated!'));
  }

  @action async toggleBankingEnabled(checked) {
    this.company.merge({ bankingEnabled: checked });
    const {model} = await this.companyStore.patchCompany(this.company);

    if (model) {
      this.company.merge(model);
      if (this.company.bankingEnabled) {
        successAlert(t('company_settings.company_features.Banking enabled'));
      } else {
        successAlert(t('company_settings.company_features.Banking disabled'));
      }
    }
  }

  @action async toggleSinEnabled(checked) {
    this.company.merge({ sinEnabled: checked });
    const {model} = await this.companyStore.patchCompany(this.company);

    if (model) {
      this.company.merge(model);
      if (this.company.sinEnabled) {
        successAlert(t('company_settings.company_features.SIN collection enabled'));
      } else {
        successAlert(t('company_settings.company_features.SIN collection disabled'));
      }
    }
  }

  @action async toggleEmployeeAnnouncementsEnabled(checked) {
    this.company.merge({employeeAnnouncementsEnabled: checked});
    const {model} = await this.companyStore.patchCompany(this.company);

    if (model) {
      this.company.merge(model);
      if (this.company.employeeAnnouncementsEnabled) {
        successAlert(t('company_settings.company_features.Employee announcements enabled!'));
      } else {
        successAlert(t('company_settings.company_features.Employee announcements disabled!'));
      }
    }
  }

  @action async toggleShowPtoCalendarType(checked) {
    this.company.merge({showPTOCalendarType: checked});
    const {model} = await this.companyStore.patchCompany(this.company);

    if (model) {
      this.company.merge(model);
      successAlert(t('company_settings.company_features.Display time off types updated!'));
    }
  }

  @action async load() {
    this.company = await this.companyStore.loadCompany();
  }
}

export default CompanyFeaturesState;
