import {action, computed, observable} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, types, t} from 'shared/core';
import {TrainingProgram, EmployeeTrainingStatusSummary} from 'stores/training';
import {successAlert} from 'shared/tools';
import _ from 'lodash';

class TrainingSummaryContainerState {
  store = new DomainStore();
  match;
  history;
  agent;

  @observable trainingProgram;
  @observable deleteProgramModalOpen = false;
  @observable launchProgramModalOpen = false;
  @observable selectEmployeesModalOpen = false;
  @observable reassignTrainingModalOpen = false;
  @observable selectedEmployees = [];
  @observable selectedEmployeeForReassignment = {};
  @observable reassignmentDueDate;
  @observable errors = {};

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    await this.store._compose([
      endpoints.TRAINING.PROGRAM.with(this.trainingProgramId)
    ]);

    this.trainingProgram = new TrainingProgram(
      this.store._getSingle(types.TRAINING.PROGRAM)
    );
  }

  @action setAgent(agent) {
    this.agent = agent;
  }

  @action openDeleteProgramModal() {
    this.deleteProgramModalOpen = true;
  }

  @action openLaunchProgramModal() {
    this.launchProgramModalOpen = true;
  }

  @action openSelectEmployeesModal() {
    this.selectEmployeesModalOpen = true;
  }

  @action openReassignTrainingModal(employee) {
    this.selectedEmployeeForReassignment = employee;
    this.reassignTrainingModalOpen = true;
  }

  @action closeModals() {
    this.launchProgramModalOpen = false;
    this.deleteProgramModalOpen = false;
    this.selectEmployeesModalOpen = false;
    this.reassignTrainingModalOpen = false;

    this.errors = {};
  }

  @action async assignEmployees() {
    const statuses = _.map(this.selectedEmployees, employee => new EmployeeTrainingStatusSummary({employee}));

    const program = new TrainingProgram({
      employeeTrainingStatuses: statuses
    });

    const {model, errors} = await this.store.patch(
      this.trainingProgram.link('addAssignees'),
      types.TRAINING.PROGRAM,
      program
    );

    this.errors = errors;

    if (model) {
      this.agent.refresh();
      this.closeModals();
    }
  }

  @action async reassignEmployee() {
    const status = new EmployeeTrainingStatusSummary({employee: this.selectedEmployeeForReassignment});

    const program = new TrainingProgram({
      employeeTrainingStatuses: [status],
      absoluteDueDate: this.reassignmentDueDate
    });

    const {model, errors} = await this.store.patch(
      this.trainingProgram.link('addAssignees'),
      types.TRAINING.PROGRAM,
      program
    );

    this.errors = errors;

    if (model) {
      this.agent.refresh();
      this.closeModals();
    }
  }

  @action async removeTrainingStatus(status) {
    await this.store.destroy(status);
    await this.load();
  }

  @action async destroyProgram() {
    await this.store.destroy(this.trainingProgram);

    this.closeModals();
    successAlert(t('training.manage.Training program deleted.'));
    this.history.push('/admin/programs');
  }

  navigateToEdit() {
    this.history.push('edit');
  }

  customLinksFor(status) {
    if (status.hasLink('reassignTraining')) {
      return [{
        text: 'training.summary.Reassign training',
        action: status => this.openReassignTrainingModal(status.employee)
      }];
    } else {
      return [];
    }
  }

  @computed get trainingProgramId(){
    return this.match.params.id;
  }

  @computed get programTags() {
    const tags = [];

    if (this.trainingProgram.closed) {
      tags.push({
        colour: 'scarlet',
        content: 'training.summary.Closed'
      });
    }

    return tags;
  }
}

export default TrainingSummaryContainerState;
