import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Layout, Table, Button, Tag} from 'components';
import {withState, t} from 'shared/core';
import {TypesState} from '../state';
import EditTypeModal from './EditTypeModal';
import ReorderTypesModal from './ReorderTypesModal';
import {FormattedMessage} from 'react-intl';

const Name = observer(({model}) => {
  return (
    <span>
      <div className='table-cell align-middle'>{model.name}</div>
      <div className='table-cell align-middle pl1'>
        {!model.canDelete && <Tag className='ml1' colour='meadow'>
          <FormattedMessage id='time_off.types.In Use'/>
        </Tag>}
      </div>
    </span>
  );
});

const ReorderTypesButton = observer(({uiState}) => {
  return (
    <div className='table-cell'>
      <Button size='sm' trait='default' onClick={() => uiState.openReorderTypesModal()}>
        <FormattedMessage id='time_off.types.Reorder'/>
      </Button>
    </div>
  );
});

const AddTypeButton = observer(({uiState}) => {
  return (
    <div className='table-cell'>
      <Button className='ml2' size='sm' onClick={() => uiState.addType()}>
        <FormattedMessage id='time_off.types.+ Add Type'/>
      </Button>
    </div>
  );
});

const COLUMNS = [
  {
    header: 'time_off.types.Name',
    component: Name,
    width: 11
  }
];

const LOCALIZATION = {
  emptyState: 'time_off.types.No types to show',
  removeModal: {
    header: 'time_off.types.Remove Type?',
    subHeader: 'time_off.types.Are you sure you want to remove this type?'
  },
};

const TypesContainerWithState = observer(({uiState}) => {
  const {orderedTypes} = uiState;

  return (
    <div>
      <Layout>
        <Layout.Section>
          <Panel>
            <Panel.Header title={t('time_off.types.Types')}>
              <div className='inline-block'>
                <ReorderTypesButton uiState={uiState}/>
                <AddTypeButton uiState={uiState}/>
              </div>
            </Panel.Header>
            <Table
              models={orderedTypes}
              columns={COLUMNS}
              localization={LOCALIZATION}
              onEdit={type => uiState.editType(type)}
              onRemove={type => uiState.destroyType(type)}
            />
            <EditTypeModal uiState={uiState} />
            <ReorderTypesModal uiState={uiState} />
          </Panel>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(TypesContainerWithState, TypesState);
