import React from 'react';
import {observer} from 'mobx-react';
import {auth, t} from 'shared/core';
import {Button, FormLayout, Input, Panel} from 'components';
import {FormattedMessage} from 'react-intl';

const Password = observer(({uiState}) => {
  if (auth.user.authenticationProvider) return;

  const {errors, passwordValues} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('user_settings.password.Password')}/>
      <FormLayout>
        <Input
          type='password'
          label='user_settings.password.Current Password'
          onChange={e => passwordValues.currentPassword = e.target.value}
          errorMessage={errors.currentPassword}
          value={passwordValues.currentPassword}
        />
        <Input
          type='password'
          label='user_settings.password.New Password'
          onChange={e => passwordValues.password = e.target.value}
          errorMessage={errors.password}
          value={passwordValues.password}
        />
        <Input
          type='password'
          label='user_settings.password.Confirm Password'
          onChange={e => passwordValues.passwordConfirmation = e.target.value}
          errorMessage={errors.passwordConfirmation}
          value={passwordValues.passwordConfirmation}
        />
      </FormLayout>
      <Button onClick={() => uiState.updatePassword()}>
        <FormattedMessage id='user_settings.password.Change Password'/>
      </Button>
    </Panel>
  );
});

export default Password;
