import _ from 'lodash';
import {DomainStore, patchModel} from 'shared/store';
import {endpoints, types} from 'shared/core';
import {observable, action} from 'mobx';
import CheckIn from 'stores/performance/CheckIn';
import CheckInSummary from 'stores/performance/CheckInSummary';
import {setupAutosaveDraft} from 'shared/tools';

class CheckInState extends DomainStore {
  @observable allCheckins = [];
  @observable selectedReviewee;
  @observable errors = {};
  @observable selectedCheckin;
  @observable isLoading = false;

  @action async selectCheckIn(checkin) {
    if (this.isLoading) return null;
    this.isLoading = true;

    for (let c of this.allCheckins) {
      c.selected = false;
    }
    checkin.selected = true;

    await this._compose(endpoints.PERFORMANCE.CHECKIN.with(checkin.id));
    this.selectedCheckin = new CheckIn(this._getSingle(types.PERFORMANCE.CHECKIN, {id: checkin.id}));
    if (!this.selectedCheckin.completed) {
      await setupAutosaveDraft(
        this.selectedCheckin,
        {id: this.selectedCheckin.id, type: types.PERFORMANCE.CHECKIN}
      );
    }
    this.isLoading = false;
  }

  async markComplete(employeeId) {
    await patchModel(
      this.selectedCheckin.link('complete'),
      types.PERFORMANCE.CHECKIN,
      this.selectedCheckin
    );

    await this.loadForManager(employeeId);
  }

  async markUncomplete(employeeId) {
    await patchModel(
      this.selectedCheckin.link('uncomplete'),
      types.PERFORMANCE.CHECKIN,
      this.selectedCheckin
    );

    await this.loadForManager(employeeId);
  }

  async loadForManager(revieweeId) {
    await this._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.PERFORMANCE.REVIEWEE_CHECKINS.with(revieweeId),
    ]);

    this.allCheckins = this.sortAllCheckins();
    this.selectedReviewee = this.getEmployee(revieweeId);
    await this.selectFirstCheckin();
  }

  sortAllCheckins() {
    return _.orderBy(
      this._getAll(types.PERFORMANCE.CHECKIN).map(c => new CheckInSummary(c)),
      ['completedAt', 'createdAt'],
      ['desc', 'desc']
    );
  }

  async selectFirstCheckin() {
    if (_.isEmpty(this.allCheckins)) return null;

    return this.selectCheckIn(this.allCheckins[0]);
  }
}

export default CheckInState;
