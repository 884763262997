import {observable, action, computed} from 'mobx';

class FeaturePermissionViewModel {
  @observable featurePermission;

  constructor(featurePermission) {
    this.featurePermission = featurePermission;
  }

  set checked(value) {
    this.featurePermission.enabled = value;
  }

  @action toggle() {
    this.featurePermission.toggle();
  }

  @computed get checked() {
    return this.featurePermission.enabled;
  }

  @computed get isReportPermission() {
    return this.featurePermission.group === 'reports';
  }
}

export default FeaturePermissionViewModel;
