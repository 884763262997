import {action, observable} from 'mobx';
import {QuizQuestion} from 'stores/questions';
import Folder from 'stores/folders/Folder';

class Question extends QuizQuestion {
  @observable description;
  @observable uploadToEmployeeProfile;
  @observable attachmentFolder;

  @action merge(other) {
    super.merge(other, {
      attachmentFolder: Folder
    });
  }
}

export default Question;
