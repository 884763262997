import React from 'react';
import {ActionLink} from 'components';
import StyleGuideTitle from './StyleGuideTitle';
import StyleGuideExample from './StyleGuideExample';
import StyleGuideParameterRows from './StyleGuideParameterRows';

const ACTIONLINK_PARAMS = [
  { name: 'className', type: 'string', description: 'Additional classNames to style the button', values: 'Ex: ".full-width", ".my3"', },
  { name: 'className', type: 'string', description: 'Additional classNames to style the button', values: 'Ex: ".full-width", ".my3"', },
  { name: 'className', type: 'string', description: 'Additional classNames to style the button', values: 'Ex: ".full-width", ".my3"', },
  { name: 'className', type: 'string', description: 'Additional classNames to style the button', values: 'Ex: ".full-width", ".my3"', },
];

const StyleGuideLinks = () => {
  return (
    <div>
      <StyleGuideTitle title='Links'
                       description='Links are used to embed actions or pathways to more information in a sentence. The color of links gives context as to what kind of actions that links perform. The <ActionLink> component handles both actions that do not require an href and those that do.'/>

      <div className='bg-white box-shadow-1 rounded p4 my3'>
        <StyleGuideExample title='Primary (default) Link' description='These links are primary actions within the context of a card.'>
          <ActionLink className='' onClick={() => console.log('clicked')}>{'Primary Link'}</ActionLink>
        </StyleGuideExample>

        <StyleGuideExample title='White Links' description='These are alternative primary links when the background color of the primary does not contrast well with the default link color.'>
          <div className='rounded bg-dodger p3 inline-block'>
            <ActionLink className='white' onClick={() => console.log('clicked')}>{'Alternative Primary Link'}</ActionLink>
          </div>
        </StyleGuideExample>

        <StyleGuideExample title='Other Links' description='These are available but do not have any defined usages.'>
          <div className='rounded bg-sky p3 inline-block'>
            <ActionLink className='block py1 jumbo' onClick={() => console.log('clicked')}>{'Other Link'}</ActionLink>
            <ActionLink className='block py1 waterloo' onClick={() => console.log('clicked')}>{'Other Link'}</ActionLink>
            <ActionLink className='block py1 submarine' onClick={() => console.log('clicked')}>{'Other Link'}</ActionLink>
            <ActionLink className='block py1 scarlet' onClick={() => console.log('clicked')}>{'Other Link'}</ActionLink>
            <ActionLink className='block py1 meadow' onClick={() => console.log('clicked')}>{'Other Link'}</ActionLink>
            <ActionLink className='block py1 dodger' onClick={() => console.log('clicked')}>{'Other Link'}</ActionLink>
          </div>
        </StyleGuideExample>

        <div className='mb3'>
          <div className='h2 medium mb2'>{'Parameters'}</div>
          <div className='clearfix mb1'>
            <div className='col col-3 pr1 h5 caps'>{'Name'}</div>
            <div className='col col-2 pr1 h5 caps'>{'Type'}</div>
            <div className='col col-4 pr1 h5 caps'>{'Description'}</div>
            <div className='col col-3 pl1 h5 caps'>{'Values'}</div>
          </div>
          <ul className='StyleGuide-params list-reset'>
            {ACTIONLINK_PARAMS.map((p, i) => <StyleGuideParameterRows key={i} index={i} {...p} />)}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StyleGuideLinks;
