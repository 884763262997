import auth from './auth';

const urls = {
  MY_PROFILE: {
    DEFAULT: `/employees/${auth.employee.id}`,
    BENEFITS: `/employees/${auth.employee.id}/benefits`
  },

  EMPLOYEE: {
    with(employeeId) {
      return `/employees/${employeeId}`;
    }
  },

  BENEFITS: {
    COMPANY_PLAN: {
      with(benefitClassId) {
        return `/benefits/company_plans/benefit_class/${benefitClassId}`;
      }
    }
  },

  TRAINING: {
    EMPLOYEE_STATUS: {
      with(employeeTrainingStatusId) {
        return `/training/programs/${employeeTrainingStatusId}`;
      }
    }
  },

  COMPANY_INBOX: {
    ACTIONABLE: '/inbox/company',
    OVERDUE: '/inbox/company?f-scopes=overdue'
  },

  EMPLOYEE_DIRECTORY: {
    NEW: '/employees/directory?f-scopes=new',
    LATE: '/employees/directory?f-scopes=late'
  },

  PTO: {
    REQUESTS: {
      PENDING: '/time_off/pending_requests?f-show=all',
      LATE: '/time_off/pending_requests?f-show=all&f-scopes=late'
    }
  },

  CANDIDATE_DIRECTORY: {
    NEW: '/recruiting/candidates?f-scopes=new',
    STALE: '/recruiting/candidates?f-scopes=inactive'
  }
};

export default urls;
