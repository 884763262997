import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Department',
  'Employee',
  'Employment Status',
  'Employment Type',
  'Location',
  'Manager',
  'Time Off Type',
  'Created By'
];

class ManualAdjustmentsReport extends React.Component {
  render() {
    return (
      <CompositeReport dateFilter='range' filters={filters}>
        <Report name={t('reports.TIME_OFF_MANUAL_ADJUSTMENTS_REPORT')}
                url='/reports/manual_adjustments'>
        </Report>
      </CompositeReport>
    );
  }
}

export default ManualAdjustmentsReport;
