import {DomainObject} from 'shared/store';
import {observable, computed} from 'mobx';
import {fullName} from 'shared/tools';

class CompanyContact extends DomainObject {
  @observable id;
  @observable firstName;
  @observable lastName;
  @observable position;
  @observable email;
  @observable phone;

  @computed get name() {
    return fullName(this);
  }
}

export default CompanyContact;
