import React from 'react';
import $ from 'jquery';
import {observer} from 'mobx-react';
import Button from './buttons/Button';
import ActionLink from './links/ActionLink';

@observer class DropdownSelect extends React.Component {
  state = {status: ''};

  toggleDropdownSelect = (e) => {
    if (this.state.status === 'is-closed' || !this.state.status) {
      this.setState({ status: 'is-open' });

      setTimeout(() => {
        $(document).one('click', (e) => {
          this.setState({ status: 'is-closed' });
        });
      }, 0);
    } else {
      this.setState({ status: 'is-closed' });
    }
  };

  render() {
    const {selected, children, disabled, size, buttonTrait, buttonSize, align} = this.props;
    const {status} = this.state;

    return (
      <div className='relative'>
        <Button disabled={disabled} trait={buttonTrait} size={buttonSize} className={`${status}`} onClick={this.toggleDropdownSelect}>
          <span>{selected}</span>
          <i className='material-icons pl2'>{'arrow_drop_down'}</i>
        </Button>
        {status && <ul className={`DropdownSelect DropdownSelect--${size} ${status} ${align}-0`}>
          {children}
        </ul>}
      </div>
    );
  }
}

DropdownSelect.Option = ({text, onClick, active, separator, icon}) => {
  const colour = active ? 'white' : 'haze';
  return (
    <li className={`${separator ? 'separator' : ''}`}>
      <ActionLink className={`${colour} ${active ? 'selected' : ''}`} onClick={() => onClick()}>
        {icon && <i className='material-icons h2 align-middle mr2'>{icon}</i>}
        <span className='align-middle'>{text}</span>
      </ActionLink>
    </li>
  );
};

DropdownSelect.defaultProps = {
  size: 'md',
  align: 'right'
};

export default DropdownSelect;
