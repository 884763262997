import React from 'react';
import {observer} from 'mobx-react';
import Subnav from '../../../Subnav';
import UploadsDirectory from './UploadsDirectory';

const UploadsContainer = observer(({scope}) => {
  return (
    <div>
      <Subnav scope={scope}/>
      <UploadsDirectory/>
    </div>
  );
});

export default UploadsContainer;
