import React from 'react';
import {observer} from 'mobx-react';
import {Input, FormLayout, DatePicker} from 'components';

const DependantStudentFields = observer(({dependent, errors, uiState}) => {
  if (!dependent.student) return null;

  return (
    <FormLayout>
      <Input value={dependent.institutionName}
        label='components.benefits.Institution'
        onChange={e => dependent.merge({institutionName: e.target.value})}
        errorMessage={errors.institutionName}
      />
      <FormLayout.Group>
        <DatePicker className='full-width'
          label='components.benefits.Education Start Date'
          onChange={date => dependent.merge({educationStartDate: date})}
          value={dependent.educationStartDate}
          options={{
            viewMode: 'months',
            minViewMode: 'months'
          }}
          errorMessage={errors.educationStartDate}
        />
        <DatePicker className='full-width'
          label='components.benefits.Expected Graduation Date'
          onChange={date => dependent.merge({expectedGraduationDate: date})}
          value={dependent.expectedGraduationDate}
          options={{
            viewMode: 'months',
            minViewMode: 'months'
          }}
          errorMessage={errors.expectedGraduationDate}
        />
      </FormLayout.Group>
    </FormLayout>
  );
});

export default DependantStudentFields;
