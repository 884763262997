import React from 'react';
import {observer} from 'mobx-react';
import FormError from 'components/FormError';

const ReadOnlyItem = observer(({children, errorMessage}) => {
  return (
    <div className='clearfix'>
      <div className='col col-12 pr1'>
        <div className='p2 my2 bg-athens rounded'>
          <div className='inline-block'>
            {children}
          </div>
          <div className='ml2'>
            <FormError message={errorMessage} />
          </div>
        </div>
      </div>
      <div className='col col-2'>&nbsp;</div>
    </div>
  );
});

export default ReadOnlyItem;
