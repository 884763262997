import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Input, Submit, Select2} from 'components';
import AccountSetupPage from './AccountSetup';
import _ from 'lodash';
import SignUpState from 'signup/state/SignUpState';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';

const CompanySignUp = observer(({uiState}) => {
  const {errors, form} = uiState;

  return (
    <div className='clearfix my3'>
      <div className='col-12 md-col-8 lg-col-7 max-width-3 mx-auto'>
        {uiState.showBackLink && <Link to='/' className='Link-icon Link-icon--back dodger mb1'>
          <FormattedMessage id='signup.Back'/>
        </Link>}
        <div className='bg-white rounded box-shadow-1 px3 sm-px4 py3 mt3'>
          <div className='pt1'>
            <div className='h1 center pb3 medium line-height-2'>
              <FormattedMessage id='signup.Create your free account' />
            </div>
          </div>
          <form>
            <div className='clearfix'>
              <div className='col col-12 sm-col-6 mb2 mt1 sm-pr2'>
                <Input errorMessage={errors.firstName}
                       onChange={e => uiState.changeForm({firstName: e.target.value})}
                       value={form.firstName} className='large full-width'
                       placeholder='signup.First Name'/>
              </div>
              <div className='col col-12 sm-col-6 mb2 mt1'>
                <Input errorMessage={errors.lastName}
                       onChange={e => uiState.changeForm({lastName: e.target.value})}
                       value={form.lastName} className='large full-width'
                       placeholder='signup.Last Name'/>
              </div>
              <div className='col col-12 mb2 mt1'>
                <Input errorMessage={errors.email}
                       onChange={e => uiState.changeForm({email: e.target.value})}
                       value={form.email} className='large full-width'
                       placeholder='signup.Work Email'/>
              </div>
              <div className='col col-12 mb2 mt1'>
                <Input errorMessage={errors.emailConfirmation}
                       onChange={e => uiState.changeForm({emailConfirmation: e.target.value})}
                       value={form.emailConfirmation} className='large full-width'
                       placeholder='signup.Confirm Email'/>
              </div>
              <div className='col col-12 mb2 mt1'>
                <Input errorMessage={_.get(errors, 'company.phoneNumber')}
                        onChange={e => uiState.changeForm({company: {phoneNumber: e.target.value}})}
                        value={_.get(form, 'company.phoneNumber')} className='large full-width'
                        placeholder='signup.Phone Number'/>
              </div>
              <div className='col col-12 mb2 mt1'>
                <Input errorMessage={_.get(errors, 'company.name')}
                       onChange={e => uiState.changeForm({company: {name: e.target.value}})}
                       value={_.get(form, 'company.name')} className='large full-width'
                       placeholder='signup.Company Name'/>
              </div>
              {!uiState.isSlim &&
                <div className='col col-12 mb2 mt1'>
                  <Select2 name='company.employeeCount' placeholder='signup.# of Employees'
                           className='large'
                           value={_.get(form, 'company.employeeCount')}
                           onChange={e => uiState.changeForm({company: {employeeCount: e.target.value}})}
                           errorMessage={_.get(errors, 'company.employeeCount')}>
                    <option key='1' value='1'>{'1-19'}</option>
                    <option key='20' value='20'>{'20-49'}</option>
                    <option key='50' value='50'>{'50-99'}</option>
                    <option key='100' value='100'>{'100-199'}</option>
                    <option key='200' value='200'>{'200+'}</option>
                  </Select2>
                </div>
              }

              <div className='col col-12 mb2 mt1'>
                <Input type='password' errorMessage={errors.password}
                       onChange={e => uiState.changeForm({password: e.target.value})}
                       value={form.password} className='large full-width'
                       placeholder='signup.Password'/>
              </div>
              <Submit size='lg' className='full-width mt2' onClick={() => uiState.submitForm()}>
                {!uiState.isSlim && <FormattedMessage id='signup.Start Exploring'/>}
                {uiState.isSlim && <FormattedMessage id='signup.Register'/>}
              </Submit>
              <div className='col col-12 h5 mt3 mb1 waterloo center'>
                <FormattedMessage id='signup.SERVICE_AGREEMENT_AND_PRIVACY_POLICY' values={{
                  terms: <a href='https://collage.co/service-agreement' target='_blank' className='TextLink dodger'><FormattedMessage id='signup.Terms'/></a>,
                  privacyPolicy: <a href='https://collage.co/privacy-policy' target='_blank' className='TextLink dodger'><FormattedMessage id='signup.Privacy Policy'/></a>
                }}/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

@observer class CompanySignUpContainer extends React.Component {
  componentDidMount() {
    const {uiState} = this.props;

    uiState.onLoad();
  }

  render() {
    const {uiState} = this.props;

    if (uiState.settingUp) {
      return <AccountSetupPage />;
    } else {
      return <CompanySignUp uiState={uiState}/>;
    }
  }
}

const CompanySignUpContainerState = (props) => {
  const uiState = new SignUpState(props);
  return <CompanySignUpContainer uiState={uiState}/>;
};

CompanySignUpContainerState.defaultProps = {
  isSlim: false,
  showBackLink: false,
  demoAccount: false
};

export default CompanySignUpContainerState;
