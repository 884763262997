import React from 'react';
import {observer} from 'mobx-react';
import {Display, Layout, Panel, Select2} from 'components';
import {withState} from 'shared/core';
import {TaskEditState} from '../state';
import {t} from 'shared/core';
import {ModalButtons} from 'components/modals';
import {TaskTemplateFields} from 'components/tasks';

const TaskEditContainer = observer(({uiState}) => {
  const {taskTemplate, taskTemplateForm, employeeGroups, config, errors} = uiState;

  return (
    <div>
      <Display backPath={`/tasks/${config.templateType}`}/>
      <Layout>
        <Panel>
          <Panel.Header
            title={t(`company_settings.tasks.${config.templateType}.name`)}
            description={t(`company_settings.tasks.${config.templateType}.description`)}
          />
          <TaskTemplateFields taskTemplateForm={taskTemplateForm} errors={errors}/>
          <Select2
            label='company_settings.tasks.Assign by default for'
            value={taskTemplate.employeeGroupId}
            placeholder='company_settings.tasks.Select Employee Group'
            onChange={e => uiState.employeeGroupSelected(e.target.value)}
            helpLink={{
              explanation:
                { header: t('company_settings.tasks.Default Task Assignment'),
                  paragraphs: [
                    t(`company_settings.tasks.${config.templateType}.DEFAULT_ASSIGN_EXPLAINER`),
                    t(`company_settings.tasks.${config.templateType}.DEFAULT_ASSIGN_EXPLAINER_2`)
                  ]
                }
          }}
          >
            {employeeGroups.map((group) =>
              <option key={group.id} value={group.id}>{group.name}</option>
            )}
            <option value={'createNewEmployeeGroup'}>{t('company_settings.tasks.+ Create New Employee Group')}</option>
          </Select2>
          <ModalButtons
            saveCaption={`${taskTemplate.isNew ? 'Create' : 'Save'}`}
            onSave={() => uiState.saveTaskTemplate()}
            onCancel={() => uiState.goBack()}
          />
        </Panel>
      </Layout>
    </div>
  );
});

export default withState(TaskEditContainer, TaskEditState);
