import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {EmployeeSelectorState} from '../state';
import {Spinner, LabelHelper, ActionLink, FormLayout, ObjectSelect2, RadioButton, RadioButtonGroup, EmployeeNameWithAvatar} from 'components';
import {FormattedMessage} from 'react-intl';
import uuid from 'uuid';

const MultiSelector = observer(({uiState, selectors}) => {
  if (uiState.allEmployees) return null;

  const {
    allEmployees,
    employees,
    locations,
    departments,
    managers,
    uniqueSelectedEmployees,
    errors
  } = uiState;

  return (
    <FormLayout>
      {selectors.includes('location') && <ObjectSelect2
        multiple
        items={locations}
        onAdd={location => uiState.selectLocation(location)}
        label='components.employee_selector.By location:'
        placeholder='components.employee_selector.Add employees by location'
      />}
      {selectors.includes('department') && <ObjectSelect2
        multiple
        items={departments}
        onAdd={department => uiState.selectDepartment(department)}
        label='components.employee_selector.By department:'
        placeholder='components.employee_selector.Add employees by department'
      />}
      {selectors.includes('manager') && <ObjectSelect2
        multiple
        items={managers}
        onAdd={employee => uiState.selectManager(employee)}
        label='components.employee_selector.By manager:'
        placeholder='components.employee_selector.Add employees by manager'
      />}
      {selectors.includes('employee') && <ObjectSelect2
        multiple
        items={employees}
        onAdd={employee => uiState.selectEmployee(employee)}
        label='components.employee_selector.By employee:'
        placeholder='components.employee_selector.Add employees'
        errorMessage={errors.assignees}
      />}
      {!allEmployees && <div>
        <div className='mb2'>
          <span className='medium'>
            <FormattedMessage id='components.employee_selector.Selected Employees:' />
          </span>
          <span className='right'>
            <ActionLink subdued onClick={() => uiState.clearEmployees()}>
              <FormattedMessage id='components.employee_selector.Clear all'/>
            </ActionLink>
          </span>
        </div>
        <div className='clearfix mxn2'>
          {uniqueSelectedEmployees.map(employee => <div key={employee.id} className='col col-6 px2 my1 height-10'>
            <div className='table-cell align-middle'>
              <EmployeeNameWithAvatar employee={employee}/>
            </div>
            <div className='table-cell align-middle pl1'>
              <ActionLink onClick={() => uiState.removeEmployee(employee)}>
                <i className='material-icons h3 align-middle submarine'>{'close'}</i>
              </ActionLink>
            </div>
          </div>)}
        </div>
      </div>}
    </FormLayout>
  );
});

const EmployeeSelector = observer(({uiState, selectors, label, tooltip}) => {
  return (
    <div>
      <LabelHelper label={label} tooltip={tooltip}/>
      <RadioButtonGroup name={uuid.v4()}
        onChange={(name, value) => uiState.toggleAllEmployees()}
        selectedValue={uiState.allEmployees}>
        <RadioButton className='block my2' value={true}>
          <FormattedMessage id='components.employee_selector.All employees' />
        </RadioButton>
        <RadioButton className='block my2' value={false}>
          <FormattedMessage id='components.employee_selector.Select employees' />
        </RadioButton>
      </RadioButtonGroup>
      <MultiSelector uiState={uiState} selectors={selectors}/>
    </div>
  );
});

EmployeeSelector.defaultProps = {
  selectors: ['location', 'department', 'employee']
};

export default withState(EmployeeSelector, EmployeeSelectorState, {
  spinner: Spinner
});

// Employee selector allows you to choose segments of employees
// by choosing one of the following categories:
// All or specific employees, departments, locations.

// props:

// - onChange: callback function that will return all selected employees
//
// - selectedEmployees: list of employees who have already been selected
