import React from 'react';
import {observer} from 'mobx-react';
import {Input, Submit} from 'components';
import {FormattedMessage} from 'react-intl';
import {LoginOAuth, LoginErrorMessage} from 'components/login';

const LoginForm = observer(({uiState}) => {
  const {form, context, errors} = uiState;

  return (
    <div>
      <LoginOAuth context={context} />
      <form>
        <LoginErrorMessage
          errorMessage={errors.base}
          forgotPassword={!uiState.attemptTokenVerification}
        />
        <div className='clearfix'>
          <div className='col col-12 mt1 mb2'>
            <Input
              className='large'
              placeholder='login.Email'
              value={form.email}
              autoFocus={true}
              onChange={e => form.merge({email: e.target.value})}
            />
          </div>
          <div className='col col-12 mb3'>
            <Input
              type='password'
              className='large'
              placeholder='login.Password'
              value={form.password}
              onChange={e => form.merge({password: e.target.value})}
            />
          </div>
          <div className='col col-12'>
            <Submit size='lg' className='wl-btn full-width' onClick={() => uiState.createSession()}>
              <FormattedMessage id='login.Log in'/>
            </Submit>
          </div>
        </div>
      </form>
    </div>
  );
});

export default LoginForm;
