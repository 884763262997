import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {OverviewContainerState} from '../state';
import {LivesChart, Panel} from 'components';
import RecentActivityList from './RecentActivityList';
import OutstandingEnrolments from './OutstandingEnrolments';

const OverviewContainerWithState = observer(({uiState}) => {
  const {showEnrolmentGraph, enrolmentDataSeries} = uiState;

  return (
    <Panel.Stack loose>
      <OutstandingEnrolments uiState={uiState} />
      {showEnrolmentGraph && <LivesChart dataSeries={enrolmentDataSeries} />}
      <RecentActivityList uiState={uiState} />
    </Panel.Stack>
  );
});

export default withState(OverviewContainerWithState, OverviewContainerState);
