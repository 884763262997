import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Department',
  'Employment Type',
  'Gender',
  'Location',
  'Manager'
];

const HiresAndTerminationsReport = () => {
  return (
    <CompositeReport dateFilter='range' filters={filters}>
      <Report name={t('reports.NEW_HIRES_AND_TERMINATIONS_REPORT')}
              view='chart'
              url='/reports/hires_and_terminations_chart'>
      </Report>
      <Report url='/reports/hires_and_terminations'
              name={t('reports.NEW_HIRES_AND_TERMINATIONS_REPORT')}
              showName={false}>
      </Report>
    </CompositeReport>
  );
};

export default HiresAndTerminationsReport;
