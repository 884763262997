import {action, observable, computed} from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import {DomainObject, oneOf} from 'shared/store';
import AnniversaryBonus from './AnniversaryBonus';
import BalanceAdjustmentPolicy from './BalanceAdjustmentPolicy';
import TimeOffType from './TimeOffType';

class TimeOffTypePolicy extends DomainObject {
  @observable id;
  @observable accrualFrequency;
  @observable baseAccrualRate;
  @observable units;
  @observable waitPeriod;
  @observable prorationEnabled;
  @observable anniversaryBonuses;
  @observable balanceAdjustmentPolicy;

  @observable totalDaysPerYear;
  @observable accrualRate;
  @observable accrualStart;

  @action merge(other) {
    super.merge(other, {
      type: TimeOffType,
      anniversaryBonuses: [AnniversaryBonus],
      balanceAdjustmentPolicy: BalanceAdjustmentPolicy,
      accrualStart: oneOf([
        'policy_start_date',
        'policy_holder_start_date'
      ])
    });
  }

  yearStartForPolicyHolder(policy, employee) {
    switch (this.accrualStart) {
      case 'policy_holder_start_date':
        return moment(employee.startDate).format('MMMM D');
      case 'policy_start_date':
      default:
        return moment(policy.currentYearStartDate).format('MMMM D');
    }
  }

  @computed get sortedAnniversaryBonuses() {
    return _.sortBy(
      this.anniversaryBonuses,
      'anniversary'
    );
  }
}

export default TimeOffTypePolicy;
