import React from 'react';
import {observer} from 'mobx-react';
import {Label, AttachedDocumentDownload} from 'components';

const AttachmentView = observer(({answer}) => {
  const {attachment} = answer;

  return (
    <div>
      <div className='medium h3'>{answer.question.name}</div>
      <div className='mt1'>
        {attachment
          ? <AttachedDocumentDownload doc={attachment}/>
          : <Label/>}
      </div>
    </div>
  );
});

export default AttachmentView;
