import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints, t} from 'shared/core';
import {Offer, OfferTemplateSummary} from 'stores/offers';
import mergeFields from 'stores/offers/mergeFields';
import {successAlert, redirect, setupAutosaveDraft, withDeleted} from 'shared/tools';
import {OfferTemplate} from 'stores/offers';
import Candidate from 'stores/recruiting/Candidate';
import {Company} from 'stores/company';
import * as queryString from 'querystring';
import _ from 'lodash';

class OfferEditorState {
  store = new DomainStore();
  match;
  history;
  action;

  @observable company;
  @observable offer;
  @observable errors = {};
  @observable templates;
  @observable fetchingTemplateDetails = false;
  @observable sendOfferModalOpen = false;
  @observable showOfferPreviewModal = false;
  @observable candidate;
  @observable isTemplateDirty = false;
  @observable setFormDirty;

  receiveProps({match, history, action, setFormDirty}) {
    this.setFormDirty = setFormDirty;
    this.action = action;
    this.match = match;
    this.history = history;
  }

  @action async load() {
    const compose = [
      endpoints.OFFER_TEMPLATES,
      endpoints.CURRENT_COMPANY
    ];
    const params = queryString.parse(this.history.location.search.replace('?', ''));
    if (this.action === 'edit') {
      compose.push(endpoints.OFFER.with(this.match.params.id));
    } else if (params.candidate_id && params.position_id) {
      compose.push(endpoints.RECRUITING.CANDIDATE.with(params.position_id, params.candidate_id));
      compose.push(endpoints.RECRUITING.POSITION.with(params.position_id));
    }

    await this.store._compose(compose);

    this.templates = this.store._getAll(types.OFFER_TEMPLATE_SUMMARY).map(e => new OfferTemplateSummary(e, this));
    this.company = new Company(this.store._getSingle(types.COMPANY));

    if (this.action === 'edit') {
      this.offer = new Offer(this.store._getSingle(types.OFFER));
    } else {
      if (params.candidate_id && params.position_id) {
        this.candidate = new Candidate(this.store._getSingle(types.RECRUITING.CANDIDATE));
      }
      this.offer = new Offer({fields: []});
      await this.setupOfferDraft();
    }
  }

  async setupOfferDraft() {
    const params = queryString.parse(this.history.location.search.replace('?', ''));
    await setupAutosaveDraft(
      this.offer,
      {
        id: (params.candidate_id && params.position_id && `candidate-${params.candidate_id}-position-${params.position_id}`) || 'new',
        type: types.OFFER
      }
    );
  }

  @action fieldValueChanged(field, value) {
    field.value = value;
    this.offer.fields = this.offer.fields.slice();
  }

  @action setCandidateFieldsOnOffer() {
    if (!this.candidate) return;

    const fields = {
      'first_name': this.candidate.firstName,
      'last_name': this.candidate.lastName,
      'job_title': this.candidate.position.title,
      'email': this.candidate.email
    };

    _.forOwn(fields, (value, key) => {
      let field = _.find(this.offer.fields, {key});
      field.value = value;
    });
  }

  @action templateSelecting(e) {
    if (this.isTemplateDirty) {
      if (window.confirm(t('components.warn_unsaved_changes.Are you sure you want to change your template? All unsaved changes will be lost.'))) {
        if (e.params.args.data.id === 'createNewTemplate') {
          this.setFormDirty(false);
          e.preventDefault();
          redirect('/company_settings/offer_templates/edit/');
        } else {
          this.setFormDirty(true);
          this.isTemplateDirty = false;
        }
      } else {
        e.preventDefault();
      }
    }
  }

  @action async templateSelected(templateID) {
    if (templateID === 'createNewTemplate') {
      redirect('/company_settings/offer_templates/edit/');
    } else {
      const previousOffer = this.offer;
      this.offer = new Offer({
        fields: []
      });

      if (this.action === 'new') {
        await this.setupOfferDraft();
      }

      const template = new OfferTemplate(_.find(this.templates, (template) => template.id === templateID));
      this.offer.offerTemplate = template;
      const templateDetails = await this.fetchTemplateDetails(template);
      this.offer.fields = this.extractFields(templateDetails.richText);

      if (previousOffer) {
        previousOffer.fields.forEach(field => {
          let newField = _.find(this.offer.fields, {key: field.key});
          if (newField) {
            newField.value = field.value;
          }
        });
      }

      this.offer.renderedContent = templateDetails.richText.replaceAll('MERGE_FIELD', 'RENDERED_FIELD');
      this.offer.lexicalState = null;
      if (this.candidate){
        this.offer.candidateId = this.candidate.id;
        this.setCandidateFieldsOnOffer();
      }
      this.offer.attachments = [...templateDetails.attachments];
      this.fetchingTemplateDetails = false;
    }
  }

  @action updateRenderedContent(content) {
    const oldRenderedContent = this.offer.renderedContent;
    this.offer.renderedContent = content.html;
    this.offer.lexicalState = content.state;
    if (oldRenderedContent !== this.offer.renderedContent) {
      this.offerChanged();
    }
  }

  @action updateEditorAttachments(attachments) {
    this.offer.merge({attachments: attachments});
    this.offerChanged();
  }

  offerChanged() {
    this.isTemplateDirty = true;
    if (this.offer.autosaver) {
      this.offer.autosaver.autosave();
    } else {
      this.setFormDirty(true);
    }
  }

  @action async fetchTemplateDetails(template) {
    this.fetchingTemplateDetails = true;

    await this.store.fetch(
      endpoints.OFFER_TEMPLATE.with(template.id),
      types.OFFER_TEMPLATE
    );

    return new OfferTemplate(this.store._getSingle(types.OFFER_TEMPLATE, {id: template.id}));
  }

  @action extractFields(content) {
    const parser = new DOMParser();
    const html = parser.parseFromString(content, "text/html");

    const contentFields = html.querySelectorAll('[data-entity-type=MERGE_FIELD]');
    const fields = this.defaultFields;

    _.forEach(contentFields, (contentField) => {
      if (_.find(fields, (field) => field.key === contentField.dataset.key)) return;

      const mergeField = _.find(mergeFields, (field) => field.key === contentField.dataset.key);
      if (mergeField) {
        fields.push({
          key: mergeField.key,
          label: t(mergeField.label),
          fieldType: mergeField.fieldType,
          value: mergeField.defaultValue || ''
        });
      } else {
        const placeholder = contentField.innerText;
        fields.push({
          key: contentField.dataset.key,
          label: placeholder.substring(2, placeholder.length - 1),
          value: ''
        });
      }
    });

    return fields;
  }

  get defaultFields() {
    return _.chain(mergeFields)
      .filter((mergeField) => mergeField.alwaysInclude)
      .map((mergeField) => {
        return {
          key: mergeField.key,
          label: t(mergeField.label),
          fieldType: mergeField.fieldType,
          value: mergeField.defaultValue || ''
        };
      })
      .value();
  }

  @action openSendOfferModal() {
    this.sendOfferModalOpen = true;
  }

  @action closeSendOfferModal() {
    this.sendOfferModalOpen = false;
  }

  @action async sendOffer() {
    const {model, errors} = this.offer.isNew ?
      await this.store.post(
        endpoints.OFFERS,
        types.OFFER,
        this.offer
      ) :
      await this.store.patch(this.offer);
    this.errors = this.processFieldErrors(errors);

    if (model) {
      this.setFormDirty(false);
      this.isTemplateDirty = false;
      successAlert(t('offers.editor.alerts.Offer sent.'));
      this.history.push('/');
    } else {
      this.closeSendOfferModal();
    }
  }

  getFieldValue(fieldKey) {
    if (!this.offer) {
      return "";
    }
    const field = _.find(this.offer.fields, (field) => field.key === fieldKey);
    if (field) {
      return field.value;
    }
    return "";
  }

  @computed get firstName() {
    return this.getFieldValue('first_name');
  }

  @computed get expiresAt() {
    return this.getFieldValue('expires_at');
  }

  @computed get jobTitle() {
    return this.getFieldValue('job_title');
  }

  processFieldErrors(errors) {
    for(let key of Object.keys(errors)) {
      let field = _.find(this.offer.fields, (field) => field.key === key);
      if (field) {
        errors[key] = errors[key].replace(new RegExp(key, 'i'), field.label);
      } else {
        errors[_.snakeCase(key)] = errors[key];
        delete errors[key];
      }
    }
    return errors;
  }

  @action openOfferPreviewModal() {
    this.offer.expiresAt = this.expiresAt;
    this.showOfferPreviewModal = true;
  }

  @action closeOfferPreviewModal() {
    this.showOfferPreviewModal = false;
  }

  @computed get templatesWithDeleted() {
    return withDeleted(this.templates, _.get(this.offer, 'offerTemplate'));
  }
}

export default OfferEditorState;
