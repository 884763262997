import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {EmployeeSelector, DatePicker, FormError} from 'components';
import _ from 'lodash';

const EmployeeSelectorModal = observer(({uiState}) => {
  const {errors, survey} = uiState;

  return (
    <Modal
      size='md'
      isOpen={uiState.selectEmployeesModalOpen}
      onHide={() => uiState.closeSelectEmployeesModal()}
      title='surveys.view.Assign employees to a survey'
    >
      <div className='mb2'>
        <EmployeeSelector onChange={employees => uiState.selectedEmployees = employees} />
      </div>
      {!survey.isRecurring && <DatePicker
        value={uiState.assigneeDueDate}
        options={{startDate: '0'}}
        onChange={date => uiState.assigneeDueDate = date}
        label='surveys.edit.Due date'
        optional={true}
        errorMessage={errors.dueDate}
      />}
      <FormError message={errors.base} />
      <ModalButtons
        onSave={() => uiState.assignEmployees()}
        onCancel={() => uiState.closeSelectEmployeesModal()}
      />
    </Modal>
  );
});

export default EmployeeSelectorModal;
