import React from 'react';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {Panel, EmployeeNameWithAvatar} from 'components';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';
import ChangesForUpdate from './ChangesForUpdate';

const BenefitUpdateForEmployee = observer(({update}) => {
  return (
    <Panel>
      <Panel.Header title={t(`models.benefit_updates.actions.${update.action}`)}/>
      <div className='clearfix'>
        <div className='col col-4'>
          <div className='black medium mb1'>
            <FormattedMessage id='benefits.updates.Employee'/>
          </div>
          <div>
            <EmployeeNameWithAvatar employee={update.employee}/>
          </div>
          <div className='mt3'>
            <div className='black medium mb1'>
              <FormattedMessage id='benefits.updates.Company'/>
            </div>
            <Link className='TextLink' to={`/clients/${update.company.id}`}>
              {update.company.name}
            </Link>
          </div>
        </div>
        <div className='col col-8'>
          <ChangesForUpdate update={update}/>
        </div>
      </div>
    </Panel>
  );
});

export default BenefitUpdateForEmployee;

