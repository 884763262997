import {observable, computed} from 'mobx';
import {fullName} from 'shared/tools';

class GoogleUserViewModel {
  @observable selected = false;
  @observable firstName = '';
  @observable lastName = '';
  @observable email = '';

  @computed get name() {
    return fullName(this);
  }

  constructor({name, primaryEmail}) {
    this.firstName = name.givenName;
    this.lastName = name.familyName;
    this.email = primaryEmail;
  }

  toJS() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      invitationEmail: this.email
    };
  }
}

export default GoogleUserViewModel;
