import {DomainObject} from 'shared/store';
import {observable, computed, action} from 'mobx';
import {fullName} from 'shared/tools';
import uuid from 'uuid';
import _ from 'lodash';
import {onboarding, offboarding} from 'components/tasks';

class TaskTemplate extends DomainObject {
  config;

  @observable id;
  @observable name = '';
  @observable assigneeType = 'employee';
  @observable relativeDueDateValue = null;
  @observable relativeDueDateUnit = null;
  @observable description;
  @observable lexicalState;
  @observable assignee;
  @observable templateType;
  @observable draftEmployeeId;
  @observable parentTemplateId;
  @observable employeeGroupId;
  @observable default = false;

  @action merge(other) {
    super.merge(other);
    this.clientId = this.id ? this.id : this.clientId ? this.clientId : uuid.v4();
    if (!this.draftEmployeeId) {
      this.parentTemplateId = this.id;
    }

    if (this.templateType === 'onboarding') {
      this.config = onboarding;
    } else {
      this.config = offboarding;
    }
  }

  @computed get isSpecificPerson() {
    return this.assigneeType !== 'manager' && this.assigneeType !== 'employee';
  }

  @computed get assigneeName() {
    if (this.assignee) {
      return fullName(this.assignee);
    }
    return '';
  }

  @computed get assigneeEmployeeId() {
    if (this.assignee) {
      return this.assignee.id;
    }
    return null;
  }

  @computed get dueDateValue() {
    const invalidNumber = _.isNaN(parseInt(this.relativeDueDateValue));
    return invalidNumber ? '' : Math.abs(this.relativeDueDateValue);
  }

  toJS() {
    const json = super.toJS();
    if (json.relativeDueDateValue === 0) {
      json.relativeDueDateUnit = 'days';
    }
    return json;
  }
}

export default TaskTemplate;
