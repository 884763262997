import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints, t} from 'shared/core';
import {successAlert} from 'shared/tools';
import MicrosoftCredentials from 'stores/sso/MicrosoftCredentials';
import {User} from 'stores/users';
import _ from 'lodash';

class MicrosoftConfigurationContainerState {
  store = new DomainStore();
  @observable credentials;
  @observable users = [];

  @action async load() {
    await this.store._compose([
      endpoints.SSO.MICROSOFT_CREDENTIALS
    ]);

    this.credentials = new MicrosoftCredentials(
      this.store._getSingle(types.SSO.MICROSOFT_CREDENTIALS)
    );

    if (!_.isEmpty(this.credentials.organizationDomains)) {
      await this.store._compose([
        endpoints.USERS.ACTIVE_WITH_DOMAIN_MISMATCH.with('microsoft')
      ]);
      this.users = this.store._getAll(types.USER, User);
    }
  }

  @action async toggleSSO() {
    let model;
    if (this.company.ssoEnabled) {
      model = (await this.store.patch(
        endpoints.COMPANY.with(this.company.id),
        types.COMPANY,
        {...this.company, ssoProviderName: null}
      )).model;
    } else {
      model = (await this.store.post(
        endpoints.COMPANY.ENABLE_MICROSOFT_SSO,
        types.COMPANY
      )).model;
    }

    this.company.update(model);
    successAlert(t('company_settings.applications.Configuration updated.'));
    this.closeSSOConfirmationModal();
  }

  @computed get company() {
    return this.credentials.company;
  }
}

export default MicrosoftConfigurationContainerState;
