import React from 'react';
import {observer} from 'mobx-react';
import {EmployeeNameWithAvatar} from 'components';

const EmployeeName = observer(({model, match, backPath}) => {
  const reviewee = model;
  const to = `/reviews/${match.params.cycleId}/reviewee/${reviewee.id}/employee/${reviewee.employee.id}/${reviewee.employee.urlSlug}/${backPath}`; 

  return (
    <EmployeeNameWithAvatar to={to} employee={model.employee}/>
  );
});

export default EmployeeName;
