import {action, observable, computed} from 'mobx';
import {DomainObject} from 'shared/store';
import {fullName} from 'shared/tools';
import _ from 'lodash';
import User from 'stores/users/User';

class Collaborator extends DomainObject {
  @observable id;
  @observable user;
  @observable _destroy;

  @action merge(other) {
    super.merge(other, {
      user: User
    });
  }

  @computed get clientId() {
    return _.get(this.user, 'id', '');
  }

  @computed get name() {
    if (!this.user) {
      return '';
    }

    return fullName(this.user);
  }
}

export default Collaborator;
