import React from 'react';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalButtons} from 'components/modals';
import {EmployeeSelector, FormError, Spinner, DatePicker} from 'components';
import AddAssigneesModalState from 'company_settings/documents/state/AddAssigneesModalState';

const AddAssigneesForm = observer(({uiState, isOpen, onHide}) => {
  const {errors} = uiState;
  
  return (
    <form>
      <div className='jumbo mb2'>
        <FormattedMessage id='company_settings.documents.Choose who will be assigned this document. Assigned employees will be notified with a task to complete the document.'/>
      </div>
      <EmployeeSelector onChange={employees => uiState.selectedEmployees = employees} />
      <DatePicker
        label='company_settings.documents.Due Date'
        value={uiState.companyDocumentAssignment.dueDate}
        onChange={date => uiState.setDueDate(date)}
        errorMessage={uiState.errors.dueDate}
        optional
      />
      <FormError message={errors.base} />
      <ModalButtons
        onSave={() => uiState.saveAssignees()}
        onCancel={onHide}
      />
    </form>
  );
});

const LoadingState = observer(() => {
  return (
    <div className='height-400'>
      <Spinner/>
    </div>
  );
});

@observer class AddAssigneesModal extends React.Component {
  @observable uiState;

  async componentDidMount() {
    this.uiState = new AddAssigneesModalState(this.props.companyDocument, this.props.onSuccess);
    return this.uiState.load();
  }

  componentWillReceiveProps(nextProps) {
    this.uiState.companyDocument = nextProps.companyDocument;
    this.uiState.companyDocumentAssignment.merge({companyDocument: nextProps.companyDocument});
  }

  render() {
    const {isOpen, onHide} = this.props;
    const {uiState} = this;

    if (!uiState) return null;

    return (
      <Modal 
        size='md'  
        isOpen={isOpen}
        onHide={onHide}
        title='company_settings.documents.Assign Document'
      >
        {uiState.loading && <LoadingState/>}
        {!uiState.loading && <AddAssigneesForm uiState={uiState} isOpen={isOpen} onHide={onHide}/>}
      </Modal>
    );
  }
}

export default AddAssigneesModal;
