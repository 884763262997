import React from 'react';
import {observer} from 'mobx-react';
import {Panel, List} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const CoverageDetails = observer(({currentCoverage}) => {
  return (
    <Panel>
      <Panel.Header title={t('components.coverages_list.Details')} />
      <ul className='ml2'>
        {currentCoverage.descriptions.map((description, index) =>
          <li key={index} className='mb1'>{description}</li>)}
      </ul>
      {!_.isEmpty(currentCoverage.subCategories) && <div className='mt3'>
        <div className='clearfix mxn2'>
          <div className='col col-6 px2 h5 waterloo caps'>
            <FormattedMessage id='components.coverages_list.Category'/>
          </div>
          <div className='col col-6 pl1 h5 waterloo caps'>
            <FormattedMessage id='components.coverages_list.Details'/>
          </div>
        </div>
        <List items={currentCoverage.subCategoriesWithIndex} titleAttr='key' keyAttr='index' />
      </div>}
    </Panel>
  );
});

export default CoverageDetails;

