import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Table, Checkbox, InputError} from 'components';
import HolidayDay from './HolidayDay';
import HolidayDate from './HolidayDate';
import {observer} from 'mobx-react';

const Locations = ({locations}) => {
  if (!locations) {
    return <FormattedMessage id='time_off.holidays.All'/>;
  }

  return (
    locations.map((loc, i) =>
      <span key={loc.name}>
        {locations.length === i + 1 ? loc.name : loc.name + ', '}
      </span>
    )
  );
};


const HolidayCheckbox = observer(({model, errors}) => {
  return <div>
    <Checkbox onChange={(e) => model.selected = e.checked} checked={model.selected}/>
    {errors[model.name.toLowerCase()] && <InputError message={errors[model.name.toLowerCase()].date}/>}
  </div>;
});

const COLUMNS = [
  {
    component: HolidayCheckbox,
    width: 1
  },
  {
    header: 'time_off.holidays.Holiday',
    render: holiday => <FormattedMessage id={`time_off.holidays.${holiday.name}`}/>,
    width: 4
  },
  {
    header: 'time_off.holidays.Day',
    component: HolidayDay,
    width: 1
  },
  {
    header: 'time_off.holidays.Date',
    component: HolidayDate,
    width: 3
  },
  {
    header: 'time_off.holidays.Locations',
    render: holiday => <Locations locations={holiday.locations} />,
    width: 3
  }
];

const LOCALIZATION = {
  emptyState: 'time_off.holidays.No holidays available'
};

const StatutoryHolidayList = ({holidays, errors}) => {
  return (
    <div>
      <div>
        <Table
          models={holidays}
          indexBy={'name'}
          columns={COLUMNS}
          localization={LOCALIZATION}
          showLinks={false}
          errors={errors}
        />
      </div>
    </div>
  );
};

export default StatutoryHolidayList;
