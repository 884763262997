import {DomainStore} from 'shared/store';
import {action, observable, computed} from 'mobx';
import {t, endpoints, types} from 'shared/core';
import {Survey, SurveyEmployeeStatus} from 'stores/surveys';
import _ from 'lodash';

class AdminContainerState {
  store = new DomainStore();
  match;
  scope;
  agent;

  @observable survey;
  @observable selectEmployeesModalOpen = false;
  @observable selectedEmployees = [];
  @observable assigneeDueDate;
  @observable errors = {};

  receiveProps({match, scope, survey}) {
    this.match = match;
    this.scope = scope;
    this.survey = survey;
  }

  @computed get progressBarItems() {
    return [
      {
        label: t('performance_reviews.view.Complete'),
        value: this.survey.completeStatusCount,
        color: '#08BAD3'
      },
      {
        label: t('performance_reviews.view.Incomplete'),
        value: this.survey.incompleteStatusCount,
        color: '#EBEFF1'
      }
    ];
  }

  @action setAgent(agent) {
    this.agent = agent;
  }

  @action async fetchSurvey() {
    await this.store._compose([
      endpoints.SURVEYS.SURVEY.with(this.survey.id),
    ]);

    this.survey = new Survey(
      this.store._getSingle(types.SURVEYS.SURVEY)
    );
  }

  @action async removeEmployeeStatus(status) {
    await this.store.destroy(status);
    await this.agent.refresh();
    await this.fetchSurvey();
  }

  @action openSelectEmployeesModal() {
    this.selectEmployeesModalOpen = true;
  }

  @action closeSelectEmployeesModal() {
    this.selectEmployeesModalOpen = false;
    this.errors = {};
  }

  @action async assignEmployees() {
    const employeeStatuses = [];
    for (const employee of this.selectedEmployees) {
      const employeeStatus = new SurveyEmployeeStatus({employee});
      employeeStatus.merge({employeeId: employee.id});
      employeeStatuses.push(employeeStatus);
    }

    const survey = new Survey({
      employeeStatuses: employeeStatuses,
    });

    if (!this.survey.isRecurring) {
      survey.merge({dueDate: this.assigneeDueDate});
    }

    const {model, errors} = await this.store.patch(
      this.survey.link('addAssignees'),
      types.SURVEYS.SURVEY,
      survey
    );

    this.errors = errors;

    if (model) {
      await this.agent.refresh();
      await this.fetchSurvey();
      this.closeSelectEmployeesModal();
    }
  }

  @computed get surveyTags() {
    const tags = [];

    if (this.survey.isClosed) {
      tags.push({
        colour: 'scarlet',
        content: 'surveys.view.Closed'
      });
    }

    return tags;
  }
}

export default AdminContainerState;
