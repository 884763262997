import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {t, endpoints, types, withState} from 'shared/core';
import {Layout, InteractiveTable} from 'components';
import {Employee} from 'stores/employees';
import Subnav from '../../../Subnav';
import {EmployeesContainerState} from '../state';
import AddEmployeeModal from './AddEmployeeModal';
import RemoveEmployeeModal from './RemoveEmployeeModal';

const COLUMNS = [
  {
    header: 'payroll.employees.Name',
    attribute: 'name',
    sortBy: 'name',
    width: 5
  },
  {
    header: 'payroll.employees.Payroll Status',
    render: employee => <FormattedMessage id={`models.employee.payroll_enabled.${employee.payrollEnabled}`}/>,
    width: 6
  }
];

const LOCALIZATION = {
  emptyState: 'payroll.employees.No employees on payroll'
};

const EmployeesContainer = observer(({scope, uiState}) => {
  return (
    <div>
      <Subnav scope={scope}/>
      <Layout>
        <InteractiveTable
          uiState={uiState}
          title={t('payroll.employees.Payroll Employees')}
          columns={COLUMNS}
          localization={LOCALIZATION}
          customLinks={employee => uiState.customLinksFor(employee)}
          onMount={agent => uiState.setInteractiveAgent(agent)}
          sortable={true}
          proxy={{
            type: 'async',
            modelType: types.PAYROLL_EMPLOYEE_SUMMARY,
            model: Employee,
            endpoint: endpoints.PAYROLL_EMPLOYEE_SUMMARIES
          }}
        />
        <AddEmployeeModal uiState={uiState}/>
        <RemoveEmployeeModal uiState={uiState}/>
      </Layout>
    </div>
  );
});

export default withState(EmployeesContainer, EmployeesContainerState);
