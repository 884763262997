import React from 'react';
import {observer} from 'mobx-react';
import {t, withState} from 'shared/core';
import {Display, Layout, Table, Panel} from 'components';
import {PayPeriodEmployeesState} from '../state';
import DetailsPanel from './DetailsPanel';

const PeriodStatus = observer(({model}) => {
  return t('payroll.periods.STATUS_COUNT', {complete: model.completedCount, total: model.totalCount, needsRevision: model.needsRevisionCount});
});

const COLUMNS = [
  {
    header: 'payroll.periods.Employee',
    attribute: 'name',
    width: 6
  },
  {
    header: 'payroll.periods.Status',
    component: PeriodStatus,
    width: 6
  }
];

const LOCALIZATION = {
  emptyState: 'payroll.periods.No changes available for this pay period'
};

const PayPeriodEmployeesContainer = observer(({uiState}) => {
  const {match, payPeriod} = uiState;
  const {employees, notes, payNumber} = payPeriod;

  return (
    <div>
      <Display
        backPath={'/updates'}
        backTitle={t('payroll.Pay Periods')}
        title={t('payroll.periods.PAY_PERIOD_NUMBER', {payPeriodNumber: payNumber})}
      />
      <Layout>
        <Panel.Stack loose>
          <DetailsPanel uiState={uiState} />
          {!!notes &&
            <Panel className='mt2'>
              <Panel.Header title={t('payroll.periods.Payroll Manager Notes')}/>
              <div className='whitespace-pre-wrap'>
                {notes}
              </div>
            </Panel>
          }
          <Panel>
            <Panel.Header title={t('payroll.Updates')}/>
            <Table
              columns={COLUMNS}
              localization={LOCALIZATION}
              models={employees}
              showRoute={model => `/updates/pay_period/${match.params.period_id}/employee/${model.id}`}
              showLinks={false}
            />
          </Panel>
        </Panel.Stack>
      </Layout>
    </div>
  );
});

export default withState(PayPeriodEmployeesContainer, PayPeriodEmployeesState);
