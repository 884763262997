import React from 'react';
import {FormattedMessage} from 'react-intl';
import {auth} from 'shared/core';
import {PositionList} from './position_list';
import PublishPositionModal from './PublishPositionModal';
import {observer} from 'mobx-react';
import {Button} from 'components';

const RecruitingDirectory = observer(({uiState}) => {
  return (
    <div>
      {auth.hasAccess('::MANAGE_ATS') && <div className='clearfix'>
        <div className='right mb3'>
          <Button className='Btn' onClick={() => uiState.createNewPosition()}>
            <FormattedMessage id='recruiting.directory.Create New Position'/>
          </Button>
        </div>
      </div>}
      <PositionList uiState={uiState}/>
      <PublishPositionModal uiState={uiState}/>
    </div>
  );
});

export default RecruitingDirectory;
