import {t} from 'shared/core';
import exporting from './exporting';

function heatmapOptions(heatmapResult) {
  return {
    chart: {
      type: 'heatmap',
      height: heatmapResult.trend.yAxisLabels.length * 25 + 110
    },
    title: null,
    legend: {
      enabled: false
    },
    xAxis: {
      categories: heatmapResult.trend.xAxisLabels
    },
    yAxis: {
      title: {
        text: t('performance_reviews.view.cycle_results.Employees')
      },
      categories: heatmapResult.trend.yAxisLabels
    },
    colorAxis: {
      min: 1,
      max: 5,
      stops: [
        [0, '#E55E5E'],
        [0.499, '#FFBBBB'],
        [0.5, '#AFE1AF'],
        [1, '#41A763']
      ]
    },
    tooltip: {
      useHTML: true,
      formatter: function() {
        const employeeName = heatmapResult.trend.yAxisLabels[this.point.y];
        const score = `<strong>${this.point.value} (${this.point.name})</strong>`;

        let tooltip = `<span>${t('performance_reviews.view.cycle_results.HEATMAP_TOOLTIP', {employeeName, score})}</span>`;
        tooltip += `<br/><span>${heatmapResult.trend.xAxisLabels[this.point.x]}</span>`;

        return tooltip;
      }
    },
    credits: {
      enabled: false
    },
    series: [
      {
        borderWidth: 1,
        data: heatmapResult.trend.data,
        dataLabels: {
          enabled: true,
          color: '#000000'
        }
      }
    ],
    exporting
  };
}

export default heatmapOptions;
