import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import YearSelect from './YearSelect';
import MonthSelect from './MonthSelect';
import DaySelect from './DaySelect';
import FormError from './FormError';

class BirthDateForm extends React.Component {
  componentWillMount() {
    if (!this.props.date) {
      this.handleDateChange(moment());
    }
  }

  handleDayChange = (e) => {
    const date = moment(this.props.date);
    this.handleDateChange(date.date(e.target.value));
  };

  handleMonthChange = (e) => {
    const date = moment(this.props.date);
    this.handleDateChange(date.month(e.target.value));
  };

  handleYearChange = (e) => {
    const date = moment(this.props.date);
    this.handleDateChange(date.year(e.target.value));
  }

  handleDateChange = (date) => {
    if (!_.isUndefined(this.props.index)) {
      this.props.onChange(this.props.index, this.props.name, date.format('YYYY-MM-DD'));
    } else {
      this.props.onChange(this.props.name, date.format('YYYY-MM-DD'));
    }
  };

  render() {
    const date = moment(this.props.date);
    const errorMessage = this.props.errorMessage;

    return (
      <div>
        <div className='clearfix mxn1'>
          <div className='col col-3 px1'>
            <DaySelect date={date} selectedDay={date.date()} onChange={this.handleDayChange} />
          </div>
          <div className='col col-5 px1'>
            <MonthSelect selectedMonth={date.month()} onChange={this.handleMonthChange} />
          </div>
          <div className='col col-4 px1'>
            <YearSelect selectedYear={date.year()} onChange={this.handleYearChange} />
          </div>
        </div>
        <FormError message={errorMessage} />
      </div>
    );
  }
}

export default BirthDateForm;
