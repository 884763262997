import {DomainObject} from 'shared/store';
import {observable, computed} from 'mobx';
import {fullName} from 'shared/tools';

class MyAccount extends DomainObject {
  @observable id;
  @observable firstName;
  @observable lastName;
  @observable accountType;
  @observable accountName;
  @observable current = false;

  @computed get name() {
    return fullName(this);
  }
}

export default MyAccount;
