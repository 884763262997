import {browserHistory} from 'react-router';
import _ from 'lodash';

function fromQuery(query) {
  const result = {
    filter: {},
    sorting: {}
  };

  if (query['p']) {
    result.pagination = {
      currentPage: query['p']
    };
  }

  _.forOwn(query, (value, key) => {
    if (!_.startsWith(key, 'f-')) return;

    result.filter[key.replace('f-', '')] = value;
  });

  _.forOwn(query, (value, key) => {
    if (!_.startsWith(key, 's-')) return;

    result.sorting[key.replace('s-', '')] = value;
  });

  return result;
}

function loadStateFromUrl() {
  const location = browserHistory.getCurrentLocation();
  return fromQuery(location.query);
}

export default loadStateFromUrl;
