import _ from 'lodash';
import colours from './colours';

const legend = (options = {})  => {
  let legend = {
    itemStyle: {
      fontSize: '14px',
      fontFamily: 'GT Walsheim, sans-serif',
      color: '#717B89',
      fontWeight: '200'
    },
    symbolHeight: 10,
    symbolWidth: 10,
    symbolPadding: 10,
    backgroundColor: '#FFFFFF',
    align: 'right',
    verticalAlign: 'middle',
    layout: 'vertical',
    x: -30,
    margin: 55,
    itemWidth: 160,
    itemMarginBottom: 15
  };

  if (options.legendAlignBottom) {
    _.merge(legend, {
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      x: 0,
      y: 0,
      margin: 0,
      itemWidth: 0,
      maxHeight: 90,
      itemMarginTop: 35,
      itemMarginBottom: 0
    });
  }

  if (options.legendDisabled) {
    _.merge(legend, {
      enabled: false
    });
  }

  return legend;
};

const exporting = (options = {})  => {
  let exporting = {
    fallbackToExportServer: false,
    allowHTML: true,
    enabled: false,
    sourceWidth: 960,
    sourceHeight: 540
  };

  return exporting;
};

const defaultConfig = (series, options = {}) => {
  return {
    legend: legend(options),
    credits: {enabled: false},
    title: '',
    colors: colours(series),
    exporting: exporting(options)
  };
};

export default defaultConfig;
