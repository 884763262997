import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Modal, ModalButtons} from 'components/modals';
import {CheckboxWithLabel, ObjectSelect2, FormLayout, LabelHelper, FormError} from 'components';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const EditRuleModal = observer(({uiState}) => {
  const {originalRule, editingRule, editRuleModalOpen, availableDisqualifiers, errors} = uiState;

  return (
    <Modal
      size='md'
      isOpen={editRuleModalOpen}
      onHide={() => uiState.closeRuleModal()}
    >
      <div className='h2 mb2'>
        <FormattedMessage id={originalRule ? 'recruiting.edit.steps.Edit Rule' : 'recruiting.edit.steps.Add Rule'}/>
      </div>
      <div className='h4 mb2 jumbo'>
        <FormattedMessage
          id={originalRule
            ? 'recruiting.edit.steps.Changes to disqualification rules will only apply to future candidates'
            : 'recruiting.edit.steps.New disqualification rules will only apply to future candidates'
          }
        />
      </div>
      <FormLayout>
        <ObjectSelect2
            label='recruiting.edit.steps.Question'
            value={editingRule.question}
            onChange={value => uiState.updateRuleQuestion(value)}
            items={uiState.availableQuestions}
            errorMessage={errors.question}
          />
        {availableDisqualifiers.length > 0 && <div>
          <LabelHelper label={t('recruiting.edit.steps.Disqualifying Answers')}/>
          {availableDisqualifiers.map((disqualifier, index) =>
            <div key={index}>
              <CheckboxWithLabel
                label={disqualifier}
                translatable={false}
                checked={_.includes(editingRule.disqualifiers, disqualifier)}
                onChange={(e) => uiState.toggleDisqualifier(disqualifier, e.checked)}
              />
            </div>
          )}
          <FormError message={errors.disqualifiers}/>
        </div>}
      </FormLayout>
      <ModalButtons
        saveCaption={originalRule
          ? 'recruiting.edit.steps.Update'
          : 'recruiting.edit.steps.Add'
        }
        onSave={() => uiState.saveRule()}
        onCancel={() => uiState.closeRuleModal()}
      />
    </Modal>
  );
});

export default EditRuleModal;
