import React from 'react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const ArchiveCompanyDocumentModal = ({isOpen, onCancel, onArchive}) => {
  return (
    <AlertModal
      isOpen={isOpen}
      onHide={onCancel} size='sm'
      header='company_settings.documents.Archive Company Document'
      subHeader='company_settings.documents.Are you sure you want to archive this company document?'
      body='company_settings.documents.Archiving this document will revoke all incomplete document assignments, but the document will remain accessible to those who have completed it. Document assignments will remain visible to admins.'
    >
      <ModalDangerButtons
        onCancel={onCancel}
        saveCaption='company_settings.documents.Archive'
        onSave={() => onArchive()} />
    </AlertModal>
  );
};

export default ArchiveCompanyDocumentModal;
