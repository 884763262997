import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, Input, FormLayout} from 'components';

const InvitationSection = observer(({uiState}) => {
  const {employee, errors} = uiState;

  return (
    <Panel className='mt2'>
      <Panel.Header title={t('benefit_enrolment.enrol.Invitation')}/>
      <FormLayout>
        <Input
          className='full-width'
          label='benefit_enrolment.enrol.Invitation Email'
          placeholder='alex@example.com'
          translatePlaceholder={false}
          value={employee.invitationEmail}
          onChange={e => employee.merge({invitationEmail: e.target.value})}
          errorMessage={errors.invitationEmail}
        />
      </FormLayout>
    </Panel>
  );
});

export default InvitationSection;
