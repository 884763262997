import React from 'react';
import {NavLink} from 'react-router-dom';
import {auth, t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {Display} from 'components';

const InboxSubnav = () => {
  return (
    <Display title={t('inbox.Inbox')}>
      {auth.user.employee && auth.viewData('::TASKS') && <NavLink to='/personal' activeClassName='active'><FormattedMessage id='inbox.My Inbox'/></NavLink>}
      {auth.viewData('::TASKS') && <NavLink to='/company' activeClassName='active'><FormattedMessage id='inbox.Company Inbox'/></NavLink>}
    </Display>
  );
};

export default InboxSubnav;
