import {observable, action} from 'mobx';
import {auth} from 'shared/core';
import axios from 'axios';

class FeatureRequestState {
  @observable modalOpen = false
  @observable confirmationModalOpen = false;
  @observable body = '';

  @action openModal() {
    this.modalOpen = true;
  }

  @action closeModal() {
    this.modalOpen = false;
  }

  @action openConfirmationModal() {
    this.confirmationModalOpen = true;
  }

  @action closeConfirmationModal() {
    this.confirmationModalOpen = false;
  }

  @action setBody(value = '') {
    this.body = value;
  }

  @action submit() {
    axios.post(
      'https://hooks.zapier.com/hooks/catch/2047013/oue3cez/',
      JSON.stringify({
        user_email: auth.user.email,
        user_name: auth.user.firstName + ' ' + auth.user.lastName,
        company_name: auth.company.name,
        body: this.body
      }), {
        transformRequest: [function (data, headers) {
          delete headers.common['content-Type'];

          return data;
        }]
       });

    this.closeModal();
    this.openConfirmationModal();
    this.setBody();
  }
}

export default FeatureRequestState;
