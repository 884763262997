import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

class ToggleOption extends React.Component {
  optionClicked = () => {
    if (this.props.value !== this.props.enabled) {
      this.props.onClick(this.props.value);
    }
  };

  render() {
    const {caption, value, disabled} = this.props;
    const side = value ? 'left' : 'right';
    const className = `Toggle-option Toggle-option--${side}`;
    return (
      <button className={className} onClick={this.optionClicked} disabled={disabled}>
        <FormattedMessage id={caption}/>
      </button>
    );
  }
}

const Toggle = ({enabled, onChange, disabled}) => {
  return (
    <div className={`Toggle ${enabled ? 'is_enabled' : 'is_disabled'}`}>
      <ToggleOption caption='On' value={true} enabled={enabled} onClick={onChange} disabled={disabled}/>
      <ToggleOption caption='Off' value={false} enabled={enabled} onClick={onChange} disabled={disabled}/>
    </div>
  );
};

Toggle.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Toggle;
