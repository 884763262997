import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import LoginContainerState from '../state/LoginContainerState';
import _ from 'lodash';
import TopNav from './TopNav';
import LoginLocaleSelector from './LoginLocaleSelector';
import LoginContainerWrapper from './LoginContainerWrapper';
import UsernamePasswordForm from './UsernamePasswordForm';
import MFAForm from './MFAForm';
import RecoveryCodeForm from './RecoveryCodeForm';
import RecoveryCodeDownload from './RecoveryCodeDownload';

const LoginFormSelector = observer(({uiState}) => {
  switch (uiState.currentState) {
    case 0:
      return <UsernamePasswordForm uiState={uiState} />;
    case 1:
      return <MFAForm uiState={uiState} />;
    case 2:
      return <RecoveryCodeForm uiState={uiState} />;
    case 3:
      return <RecoveryCodeDownload uiState={uiState} />;
    default:
      throw new Error(`Unknown step for login ${uiState.currentState}`);
  }
});

const LoginContainer = observer(({uiState}) => {
  return (
    <div>
      <TopNav uiState={uiState} />
      <LoginLocaleSelector />
      <LoginContainerWrapper>
        <LoginFormSelector uiState={uiState} />
      </LoginContainerWrapper>
    </div>
  );
});

export default withState(LoginContainer, LoginContainerState);

