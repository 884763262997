import React from 'react';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, Toggle} from 'components';

const LegacyCalendar = observer(({uiState, legacyCalendar, index}) => {
  return (
    <div key={legacyCalendar.id} className={index === 0 ? '' : 'mt2'}>
      <div className='medium mb1'>
        <FormattedMessage id={`models.calendar_type.${legacyCalendar.calendarType}.name`}/>
      </div>
      <Toggle
        enabled={legacyCalendar.enabled}
        onChange={(value) => uiState.toggleLegacyCalendar(legacyCalendar, value)}
      />
    </div>
  );
});

const LegacyCalendarList = observer(({uiState}) => {
  const {legacyCalendars} = uiState;

  return (
    <Panel.Stack>
      <Panel>
        <Panel.Header
          title={t('company_settings.calendars.header')}
          description={t('company_settings.calendars.sub_header')}
        />
      </Panel>
      <Panel>
        {legacyCalendars.map((legacyCalendar, index) =>
          <LegacyCalendar
            key={legacyCalendar.id}
            uiState={uiState}
            legacyCalendar={legacyCalendar}
            index={index}
          />
        )}
      </Panel>
    </Panel.Stack>
  );
});

export default LegacyCalendarList;
