import React from 'react';
import {observer} from 'mobx-react';
import {QuickTip} from 'components';
import {t} from 'shared/core';

const TaskCompleted = observer(({uiState}) => {
  const {task} = uiState;
  const quickTipParams = {
    trait: 'success',
    paragraphs: [t('inbox.This task has been completed.')],
    actions: task.hasLink('uncomplete') ? 
      [{ caption: t('inbox.Undo'), onClick: () => uiState.uncompleteTask() }] : []
  };

  return (
    <QuickTip {...quickTipParams} />
  );
});

export default TaskCompleted;
