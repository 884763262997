import React from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import ActionLink from './links/ActionLink';
import _ from 'lodash';

const QuickTipAction = observer(({action}) => {
  if (action.onClick) {
    return <ActionLink {...action}>{action.caption}</ActionLink>;
  }

  if (action.href) {
    return <a className='TextLink' href={action.href}>{action.caption}</a>;
  }

  throw new Error('action must be provided with either an href or onClick');
});

const QuickTip = observer(({header, paragraphs, trait, actions}) => {
  return (
    <div className={`QuickTip QuickTip--${trait} relative`}>
      <div className='table'>
        <div className={`${_.isEmpty(actions) ? 'col-12' : 'col-10'} col overflow-wrap-anywhere`}>
          {header && <div className='QuickTip-header'>{header}</div>}
          {paragraphs.map((p, index) => <div className='QuickTip-paragraph' key={index}>{p}</div>)}
        </div>
        {!_.isEmpty(actions) && <div className='col-2 col right-align'>
          {actions.map((action, index) =>
            <QuickTipAction key={index} action={action} />)}
        </div>}
      </div>
    </div>
  );
});

QuickTip.propTypes = {
  header: PropTypes.node,
  trait: PropTypes.oneOf(['default', 'info', 'error', 'success', 'caution']),
  paragraphs: PropTypes.array.isRequired,
  actions: PropTypes.array
};

QuickTip.defaultProps = {
  trait: 'default',
  actions: []
};

/*
  Displays a short tip explaining the meaning of an action about to be taken.
*/

export default QuickTip;
