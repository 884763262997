import React from 'react';
import {FormattedMessage} from 'react-intl';

const EmptyState = ({message}) => {
  return (
    <div className='h5 waterloo'>
      <FormattedMessage id={message}/>
    </div>
  );
};

export default EmptyState;
