import React from 'react';
import {observer} from 'mobx-react';
import {CustomFieldContentFactory} from 'components';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const CustomField = observer(({viewModel, field}) => {
  const {uiState} = viewModel;

  if (!viewModel.fields.get(field).get('hasAccessToField')) {
    return (
      <div className='waterloo'>
        <FormattedMessage id='mass_updates.edit.Not Applicable'/>
      </div>
    );
  }

  if (uiState.massUpdate.readOnly && !viewModel.fields.get(field).has('newValue')) {
    return (
      <div className='waterloo'>
        <FormattedMessage id='mass_updates.edit.Not Changed'/>
      </div>
    );
  }
  const customField = _.find(uiState.customFields, {id: field.substring(2)});

  return (
    <div>
      <CustomFieldContentFactory
        allowClear
        customField={customField}
        type={customField.fieldType}
        value={viewModel.get(field).get('value')}
        disabled={viewModel.disabled}
        onChange={value => viewModel.set(field, value)}
      />
      <div className='jumbo mt1'>
        <FormattedMessage
          id={viewModel.disabled ? 'mass_updates.edit.PREVIOUS_VALUE' : 'mass_updates.edit.CURRENT_VALUE'}
          values={{
            value: viewModel.get(field).get('oldValue') || '--'
          }}
        />
      </div>
    </div>
  );
});

export default CustomField;
