import {action, observable} from 'mobx';
import {redirect} from 'shared/tools';
import {auth} from 'shared/core';

class OAuthState {
  @observable context;
  @observable token;
  @observable providers;

  receiveProps({context = 'login', providers = ['google', 'microsoft'], token}) {
    this.context = context;
    this.token = token;
    this.providers = providers;
  }

  @action onConnectButtonClicked(provider) {
    return redirect(`/oauth?provider=${provider}&context=${this.context}${this.token ? `&token=${this.token}` : ''}&identifier=${auth.company.oktaIdentifier}`);
  }
}

export default OAuthState;
