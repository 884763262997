import React from 'react';
import {observer} from 'mobx-react';
import ClientProfileContainer from '../ClientProfileContainer';
import {Plans} from 'components/benefits';
import {endpoints} from 'shared/core';

const PlansContainer = observer(({match, history, parentState}) => {
  const {match: parentMatch} = parentState;
  return (
    <ClientProfileContainer {...{match, history, parentState}}>
      <Plans
        match={match}
        history={history}
        viewEndpoint={endpoints.BROKER_PORTAL.BENEFIT_CLASS}
        listEndpoint={endpoints.BROKER_PORTAL.COMPANY_INSURANCE_CARRIERS.with(
          parentMatch.params.id
        )}
      />
    </ClientProfileContainer>
  );
});

export default PlansContainer;
