import {action, computed, observable} from 'mobx';
import {DomainStore} from 'shared/store';
import {t, auth} from 'shared/core';
import {computeDataSeries} from 'components/charts';
import {BenefitEnrolmentSummary} from 'stores/benefits';
import {types, endpoints} from 'shared/core';

class BrokerDashboardState {
  store = new DomainStore();
  history;
  enrolmentDataSeries;

  @observable broker;

  receiveProps({history}) {
    this.history = history;
  }

  @action async load() {
    await this.store._compose([
      endpoints.BROKER_PORTAL.BENEFIT_ENROLMENTS.ALL,
      endpoints.BROKER_PORTAL.CURRENT_BROKER
    ]);

    this.benefitEnrolments = this.store._getAll(
      types.ENROLMENT_SUMMARY,
      BenefitEnrolmentSummary
    );
    this.enrolmentDataSeries = computeDataSeries(this.benefitEnrolments);
    this.broker = this.store._getSingle(types.BROKER);
  }

  get greeting() {
    const currentHours = new Date().getHours();
    if (currentHours < 12) {
      return t('broker_dashboard.greetings.GOOD_MORNING', {name: auth.user.firstName});
    }
    if (currentHours < 18) {
      return t('broker_dashboard.greetings.GOOD_AFTERNOON', {name: auth.user.firstName});
    }

    return t('broker_dashboard.greetings.GOOD_EVENING', {name: auth.user.firstName});
  }

  @action navigateToNewClient() {
    this.history.push('/clients/new');
  }

  @computed get showEnrolmentGraph() {
    return this.enrolmentDataSeries.length > 1;
  }
}

export default BrokerDashboardState;
