import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {CompanyLinksState} from '../state';
import {Panel, EmojiTitle} from 'components';
import Table from 'components/tables/components/Table';
import _ from 'lodash';

const CompanyLink = observer(({companyLink}) => {
  return (
    <div>
      <img
        alt='favicon'
        src={`https://www.google.com/s2/favicons?sz=24&domain=${companyLink.url}`}
        className='mr1'
        width='24px'
      />
      <a href={companyLink.url} target='_blank'>{companyLink.name}</a>
    </div>
  );
});

const COLUMNS = [
  {
    width: 12,
    render: model => <CompanyLink companyLink={model} />
  }
];

const CompanyLinks = observer(({uiState}) => {
  const {companyLinks, Emoji} = uiState;

  if(_.isEmpty(companyLinks)) return null;

  return (
    <Panel>
      <Panel.Header title={<EmojiTitle Emoji={Emoji} emojiKey='paperclip' title={t('hr_dashboard.company_links.Company Links')}/>}/>
      <Table
        columns={COLUMNS}
        models={companyLinks}
        showHeaders={false}
        showLinks={false}
        uiState={uiState}
      />
    </Panel>
  );
});

export default withState(CompanyLinks, CompanyLinksState);
