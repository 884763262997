import React from 'react';
import {t} from 'shared/core';
import {Link as LinkDeprecated} from 'react-router';
import {Link} from 'react-router-dom';
import {ActionLink} from 'components';
import BackIcon from 'img/dropdown-arrow@2x.png';
import {observer} from 'mobx-react';
import Layout from 'components/layouts/Layout';

const BackLink = observer(({v, children, onBackClick, ...rest}) => {
  if (v === '3') {
    if (process.env.__DEV__) {
      console.warn('React router 3 is deprecated. Be a better person and convert the component using <BackToIndex/> to React router v4.');
    }

    return (
      <LinkDeprecated {...rest}>{children}</LinkDeprecated>
    );
  }

  if (!!onBackClick) {
    return (
      <ActionLink onClick={onBackClick} {...rest}>{children}</ActionLink>
    );

  } else {
    return (
      <Link {...rest}>{children}</Link>
    );
  }
});

const BackToIndex = ({path = '/', title = t('components.navigation.Back'), v = '4', hasTitle = false, nested, onBackClick}) => {
  return (
    <div className={`clearfix ${nested ? '' : 'pt3'}`}>
      <Layout>
        <div className={`BackToIndex ${hasTitle ? 'BackToIndex-hasTitle' : ''}`}>
          <BackLink to={path} onBackClick={onBackClick} v={v} className='HoverAnimation-trigger--image jumbo'>
            <img src={BackIcon} alt='back' height='24px'/>
            <span className='align-middle'>
              {title}
            </span>
          </BackLink>
        </div>
      </Layout>
    </div>
  );
};

export default BackToIndex;
