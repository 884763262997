const MERGE_FIELDS = [
  {
    key: 'position_name',
    label: 'components.lexical.merge_fields.Position Name',
    placeholder: 'components.lexical.merge_fields.#[Position Name]'
  },
  {
    key: 'candidate_first_name',
    label: 'components.lexical.merge_fields.Candidate First Name',
    placeholder: 'components.lexical.merge_fields.#[Candidate First Name]'
  },
  {
    key: 'candidate_last_name',
    label: 'components.lexical.merge_fields.Candidate Last Name',
    placeholder: 'components.lexical.merge_fields.#[Candidate Last Name]'
  },
  {
    key: 'date_candidate_applied',
    label: 'components.lexical.merge_fields.Date Candidate Applied',
    placeholder: 'components.lexical.merge_fields.#[Date Candidate Applied]'
  },
  {
    key: 'current_date',
    label: 'components.lexical.merge_fields.Current Date',
    placeholder: 'components.lexical.merge_fields.#[Current Date]'
  },
  {
    key: 'sender_name',
    label: 'components.lexical.merge_fields.Sender Name',
    placeholder: 'components.lexical.merge_fields.#[Sender Name]'
  }
];

export default MERGE_FIELDS;
