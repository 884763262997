import {action, observable, computed, toJS} from 'mobx';
import {auth, t, endpoints, types} from 'shared/core';
import {fetchModels} from 'shared/store';
import $ from 'jquery';
import _ from 'lodash';
import TimelineSectionViewModel from './TimelineSectionViewModel';
import TimelineEntry from 'stores/recruiting/TimelineEntry';
import Candidate from 'stores/recruiting/Candidate';
import CandidateTag from 'stores/recruiting/CandidateTag';
import InterviewGuideAnswer from 'stores/interview_guides/InterviewGuideAnswer';
import {successAlert, setupAutosaveDraft, redirect} from 'shared/tools';
import {Offer} from 'stores/offers';
import {Company} from 'stores/company';

class CandidateDetailsState {
  hiringFunnelState;
  enforceRequiredFields = false;

  @observable errors = {};
  @observable editCandidateModalOpen = false;
  @observable deleteCandidateModalOpen = false;
  @observable movePositionModalOpen = false;
  @observable createdSection = null;
  @observable newFeedback = TimelineEntry.createFeedback();
  @observable newFeedbackErrors = {};
  @observable editedEntry;
  @observable editedEntryErrors = {};
  @observable newEmail = TimelineEntry.createEmail();
  @observable newEmailErrors = {};
  @observable newInterviewGuide = TimelineEntry.createInterviewGuideResponse();
  @observable newInterviewGuideErrors = {};
  @observable newPositionId = null;
  @observable newPositionChangeText = '';
  @observable entryBeingEdited = null;
  @observable __candidateViewModel;
  @observable editingCandidate;
  @observable emailModalOpen = false;
  @observable openedEmail = {};
  @observable elements = new Map();
  @observable newUploadText = '';
  @observable newFiles = '';
  @observable attachments = [];
  @observable availableTags = [];
  @observable selectedTags = [];
  @observable isLoadingTags = false;
  @observable editTagsModalOpen = false;
  @observable isUploading = false;
  @observable offerPreviewModalOpen = false;
  @observable previewedOffer;
  @observable company;
  @observable mousedOverStar = false;
  @observable mousedOverStarIndex;
  @observable templateCounter = 0;
  @observable bulkAddingTags = false;
  @observable bulkMovingCandidates = false;

  constructor(hiringFunnelState) {
    this.hiringFunnelState = hiringFunnelState;
  }

  @action async loadTags() {
    this.isLoadingTags = true;
    const tags = await fetchModels(
      endpoints.RECRUITING.CANDIDATE_TAGS,
      types.RECRUITING.CANDIDATE_TAG
    );
    this.isLoadingTags = false;
    this.availableTags = tags.map(model => new CandidateTag(model));
  }

  isBeingEdited(entry) {
    return this.entryBeingEdited === entry;
  }

  getOrCreateCurrentSection() {
    let currentSection = this.currentSection;
    if (!currentSection && !this.createdSection) {
      currentSection = new TimelineSectionViewModel(this.candidate);
      currentSection.stage = this.candidate.currentStage;
      this.createdSection = currentSection;
    }
    return currentSection;
  }

  updateEntryState(entry, errors, model) {
    if (model) {
      const isNew = entry.isNew;
      entry.merge(model);

      if(entry.autosaver) {
        entry.autosaver.clearAutosaver();
      }

      this.cancelInput();
      if (isNew) {
        this.candidate.timelineEntries.push(entry);
      }

      return true;
    }

    this.newFeedbackErrors = errors;
  }

  getRefFor(entry) {
    return this.elements.get(entry.id);
  }

  @action setRefFor(entry, r) {
    this.elements.set(entry.id, r);
  }

  @action resetRefs() {
    this.elements = new Map();
  }

  @action emailOverflowing(entry) {
    const ref = this.getRefFor(entry);

    return ref && ref.scrollHeight > 500;
  }

  @action createNewInterviewGuide() {
    redirect('/recruiting/interview-guides/edit/');
  }

  @action async showEmail(subject) {
    this.cancelInput();

    await setupAutosaveDraft(
      this.newEmail,
      {id: this.candidate.id, type: types.RECRUITING.TIMELINE.EMAIL}
    );

    if (subject) {
      this.newEmail.subject = subject;
    }

    const currentSection = this.getOrCreateCurrentSection();
    currentSection.showEmailInput = true;
  }

  @action async showInterviewGuide() {
    this.cancelInput();

    await setupAutosaveDraft(
      this.newInterviewGuide,
      {id: this.candidate.id, type: types.RECRUITING.TIMELINE.INTERVIEW_GUIDE_RESPONSE}
    );

    const currentSection = this.getOrCreateCurrentSection();
    currentSection.showInterviewGuide = true;
  }

  @action async addInterviewGuide() {
    const {errors, model} = await this.hiringFunnelState.saveEntry(this.newInterviewGuide);
    const success = this.updateEntryState(this.newInterviewGuide, errors, model);
    if (success) {
      successAlert(t('recruiting.alerts.Feedback successfully added'));
      this.cancelInterviewGuide();
      await this.setupNewInterviewGuideAutosave();
    }
  }

  @action selectInterviewGuide(guide) {
    if (this.newInterviewGuide.hasAnswerContent) {
      if (window.confirm(t('components.warn_unsaved_changes.Are you sure you want to change your interview guide? All unsaved changes will be lost.'))) {
        this.useInterviewGuide(guide);
      }
    } else {
      this.useInterviewGuide(guide);
    }
  }

  @action useInterviewGuide(guide) {
    this.newInterviewGuide.interviewGuide = guide;
    this.newInterviewGuide.answers = [];

    guide.questions.forEach(question => {
      const answer = new InterviewGuideAnswer();
      answer.merge({question: question});
      this.newInterviewGuide.answers.push(answer);
    });

    this.newInterviewGuide.autosaver.autosave();
  }

  @action async setupNewFeedbackAutosave() {
    if (this.candidate) {
      await setupAutosaveDraft(
        this.newFeedback,
        {id: this.candidate.id, type: types.RECRUITING.TIMELINE.FEEDBACK}
      );
    }
  }

  @action async setupNewInterviewGuideAutosave() {
    if (this.candidate) {
      await setupAutosaveDraft(
        this.newInterviewGuide,
        {id: this.candidate.id, type: types.RECRUITING.TIMELINE.INTERVIEW_GUIDE_RESPONSE}
      );
    }
  }

  @action showFileUpload() {
    this.cancelInput();

    const currentSection = this.getOrCreateCurrentSection();
    currentSection.showFileUpload = true;

    setTimeout(() => {
      $('html, body').animate({scrollTop: $('.js-file-uploads').offset().top - 150}, 500);
      $('.js-file-uploads').focus();
    }, 0);
  }

  @action reply(email) {
    const replyPrefix = t('recruiting.hiring_funnel.reply_prefix');
    const subject = email.subject || '';

    if (subject.startsWith(replyPrefix)) {
      this.showEmail(subject);
    } else {
      this.showEmail(`${replyPrefix} ${subject}`);
    }
  }

  @action calculateQuestionRequired(question) {
    return this.enforceRequiredFields && question.required;
  }

  @action async addEmail() {
    const {errors, model} = await this.hiringFunnelState.saveEntry(this.newEmail);
    const success = this.updateEntryState(this.newEmail, errors, model);
    if (success) {
      successAlert(t('recruiting.alerts.Email sent to candidate'));
    }
    this.newEmailErrors = errors;
  }

  @action selectEmailTemplate(template) {
    if (this.newEmail.subject || this.newEmail.strippedBody) {
      if (window.confirm(t('components.warn_unsaved_changes.Are you sure you want to change your template? All unsaved changes will be lost.'))) {
        this.useEmailTemplate(template);
      }
    } else {
      this.useEmailTemplate(template);
    }
  }

  @action async useEmailTemplate(template) {
    this.newEmail.subject = template.subject;
    this.newEmail.lexicalState = template.lexicalState;
    this.newEmail.body = template.richText;
    this.templateCounter += 1;
    this.newEmail.autosaver.autosave();
  }

  @action async addFeedback() {
    if (!this.canAddNewSentiment) {
      this.newFeedback.sentiment = null;
    }
    const {errors, model} = await this.hiringFunnelState.saveEntry(this.newFeedback);
    const success = this.updateEntryState(this.newFeedback, errors, model);
    if (success) {
      successAlert(t('recruiting.alerts.Feedback successfully added'));
      this.cancelFeedback();
      await this.setupNewFeedbackAutosave();
    }
  }

  @action async addFileUploads() {
    const upload = TimelineEntry.createUpload({text: this.newUploadText, attachments: this.newFiles});
    const {errors, model} = await this.hiringFunnelState.saveEntry(upload);
    const success = this.updateEntryState(upload, errors, model);

    if (success) {
      successAlert(t('recruiting.alerts.Documents successfully added'));
    }
  }

  @action async savePositionChange() {
    this.errors = {};
    const position = _.find(this.hiringFunnelState.availablePositions, { id: this.newPositionId });

    if (this.bulkMovingCandidates) {
      const movedCount = await this.hiringFunnelState.bulkAction({
        newPositionId: this.newPositionId,
        newPositionText: this.newPositionChangeText
      });
      this.closeMovePositionModal();
      successAlert(t('recruiting.alerts.BULK_MOVE', {
        count: movedCount, new: position.title
      }));
    } else {
      await this.hiringFunnelState.changePosition({
        position: position,
        changePositionText: this.newPositionChangeText
      });
    }
  }

  @action async updateEntry(entry) {
    const {errors, model} = await this.hiringFunnelState.saveEntry(this.editedEntry);
    const success = this.updateEntryState(entry, errors, model);
    if (success) {
      successAlert(t('recruiting.alerts.Successfully updated'));
    }
  }

  @action async updateEmail(email) {
    this.newEmail.merge(email);
    this.newEmail.autosaver.autosave();
  }

  @action async updateEmailBody(body) {
    this.newEmail.body = body.html;
    this.newEmail.lexicalState = body.lexicalState;

    if (this.newEmail.autosaver) {
      this.newEmail.autosaver.autosave();
    }
  }

  @action async updateNewFeedbackText(text) {
    this.newFeedback.text = text.html;
    this.newFeedback.lexicalState = text.state;

    if (this.newFeedback.autosaver) {
      this.newFeedback.autosaver.autosave();
    }
  }

  @action async updateNewFeedback(feedback) {
    this.newFeedback.merge(feedback);
    this.newFeedback.autosaver.autosave();
  }

  @action async updateEditedEntry(feedback) {
    this.editedEntry.merge(feedback);
  }

  @action async updateEditedFeedbackText(text) {
    this.editedEntry.text = text.html;
    this.editedEntry.lexicalState = text.state;
  }

  @action async editEntry(entry) {
    this.entryBeingEdited = entry;

    if (entry.isInterviewGuideResponse) {
      this.editedEntry = TimelineEntry.createInterviewGuideResponse(entry);
    } else {
      this.editedEntry = TimelineEntry.createFeedback(entry);
      if (!entry.sentiment && this.stageHasSentiment(entry.funnelStage.id)) {
        this.editedEntry.sentiment = null;
      }
    }
  }

  @action cancelInput() {
    for (const section of this.timelineSections) {
      section.showEmailInput = false;
      section.showFileUpload = false;
      section.showInterviewGuide = false;
      if (this.createdSection) {
        this.createdSection = null;
      }
    }
    this.newEmail = TimelineEntry.createEmail();
    this.newEmailErrors = {};
    this.newInterviewGuide = TimelineEntry.createInterviewGuideResponse();
    this.newInterviewGuideErrors = {};
    this.newUploadText = '';
    this.newFiles = [];
    this.entryBeingEdited = null;
  }

  @action cancelFeedback() {
    this.newFeedback = TimelineEntry.createFeedback();
    this.newFeedbackErrors = {};
  }

  @action cancelInterviewGuide() {
    this.newInterviewGuide = TimelineEntry.createInterviewGuideResponse();
    this.newInterviewGuideErrors = {};
  }

  @action openEditCandidateModal() {
    this.editingCandidate = new Candidate(this.candidate);
    this.editCandidateModalOpen = true;
  }

  @action closeEditCandidateModal() {
    this.editCandidateModalOpen = false;
  }

  @action openDeleteCandidateModal() {
    this.deleteCandidateModalOpen = true;
  }

  @action closeDeleteCandidateModal() {
    this.deleteCandidateModalOpen = false;
  }

  @action openEditTagsModal(bulkAddingTags = false) {
    this.loadTags();
    this.editTagsModalOpen = true;
    this.bulkAddingTags = bulkAddingTags;
    if (bulkAddingTags) {
      this.selectedTags = [];
    } else {
      this.selectedTags = toJS(this.candidate.candidateTags);
    }
  }

  @action closeEditTagsModal() {
    this.editTagsModalOpen = false;
  }

  @action onTagChange(e) {
    if (e.action === 'add') {
      this.addTag(e.value);
    } else if (e.action === 'remove') {
      this.removeTag(e.value);
    }
  }

  @action addTag(tag) {
    this.selectedTags.push(new CandidateTag({name: tag}));
  }

  @action removeTag(tag) {
    _.remove(this.selectedTags, {name: tag});
  }

  @action async saveTags() {
    if (this.bulkAddingTags) {
      const tagAddedCount = await this.hiringFunnelState.bulkAction({addedTags: this.selectedTags});
      this.closeEditTagsModal();
      successAlert(t('recruiting.alerts.BULK_ADD_TAGS', { count: tagAddedCount }));
    } else {
      this.candidate.candidateTags = this.selectedTags;
      const candidate = this.candidate.pick(['candidateTags']);
      const {model} = await this.hiringFunnelState.store.patch(candidate);

      if (model) {
        this.candidate.merge(model);
        this.closeEditTagsModal();
      }
    }
  }

  @action async openOfferPreviewModal(offerId) {
    this.offerPreviewModalOpen = true;
    await this.hiringFunnelState.store._compose([
      endpoints.OFFER.with(offerId),
      endpoints.CURRENT_COMPANY
    ]);
    this.company = new Company(this.hiringFunnelState.store._getSingle(types.COMPANY));
    this.previewedOffer = new Offer(this.hiringFunnelState.store._getSingle(types.OFFER, {id: offerId.toString()}));
  }

  @action closeOfferPreviewModal() {
    this.offerPreviewModalOpen = false;
  }

  @action async deleteEntry(entry) {
    const mostRecentRating = this.candidate.mostRecentRating;

    if (this.createdSection) {
      this.createdSection.entries.remove(entry);
    } else {
      this.candidate.timelineEntries.remove(entry);
    }
    await this.hiringFunnelState.destroyEntry(entry);

    if (entry.isRating && entry.id === mostRecentRating.id) {
      if (this.candidate.mostRecentRating) {
        this.candidate.score = this.candidate.mostRecentRating.score;
      } else {
        this.candidate.score = null;
      }
    }

    successAlert(t('recruiting.alerts.Successfully deleted'));
  }

  @action async deleteCandidate() {
    await this.hiringFunnelState.deleteCandidate(this.candidate);
    this.closeDeleteCandidateModal();
  }

  @action openMovePositionModal(bulkMovingCandidates = false) {
    this.newPositionId = _.head(this.hiringFunnelState.availablePositions).id;
    this.bulkMovingCandidates = bulkMovingCandidates;
    this.movePositionModalOpen = true;
  }

  @action closeMovePositionModal() {
    this.movePositionModalOpen = false;
    this.newPositionId = null;
    this.newPositionChangeText = null;
  }

  @action openEmailModal(email) {
    this.openedEmail = email;
    this.emailModalOpen = true;
  }

  @action closeEmailModal() {
    this.emailModalOpen = false;
  }

  @action onUploadStarted() {
    this.isUploading = true;
  }

  @action onUploadFinished() {
    this.isUploading = false;
  }

  @action async rateCandidate(score) {
    this.candidate.merge({score});
    const candidate = this.candidate.pick(['score']);
    const {model} = await this.hiringFunnelState.store.patch(candidate);

    if (model) {
      this.candidate.merge(model);
      successAlert(t('recruiting.alerts.Candidate successfully rated'));
    }
  }

  @computed get candidateViewModel() {
    return this.__candidateViewModel;
  }

  set candidateViewModel(candidateViewModel) {
    this.createdSection = null;
    this.attachments = [];
    this.__candidateViewModel = candidateViewModel;
  }

  @computed get candidate() {
    if (!this.candidateViewModel) return null;

    return this.candidateViewModel.candidate;
  }

  @computed get timelineSections() {
    const entriesByPosition = _.groupBy(
      this.candidate.timelineEntries, entry => entry.funnelStage.positionId
    );
    const sortedEntriesByPosition = _.reverse(
      _.sortBy(entriesByPosition, entries => _.maxBy(entries, entry => entry.createdAt).createdAt)
    );
    const sectionsByPositions = _.map(sortedEntriesByPosition, entries => {
      const entriesByStage = _.groupBy(entries, entry => entry.funnelStage.id);
      const sections = _.map(entriesByStage, entries =>
        new TimelineSectionViewModel(this.candidate, entries)
      );
      return _.orderBy(sections, ['stage.order'], ['desc']);
    });
    const sections = _.flatten(sectionsByPositions);

    return this.createdSection ? [this.createdSection, ...sections] : sections;
  }

  @computed get totalEmailEntries() {
    const entries = _.flatten(_.map(this.timelineSections, (section) => section.entries.slice()));

    return _.filter(entries, 'isEmail').length;
  }

  @computed get isEmptyTimeline() {
    return !this.timelineSections.length;
  }

  @computed get currentSection() {
    return _.find(
      this.timelineSections, {
        stage: {
          id: this.candidate.currentStage.id
        }
      }
    );
  }

  stageHasSentiment(funnelStageId) {
    return _.chain(this.candidate)
      .get('timelineEntries')
      .filter({
        user: {
          id: auth.user.id
        },
        funnelStage: {
          id: funnelStageId
        },
        isFeedback: true
      })
      .reject({sentiment: null})
      .value().length > 0;
  }

  @computed get canAddNewSentiment() {
    return !this.stageHasSentiment(this.candidate.currentStage.id);
  }

  @computed get canUpdateSentiment() {
    if (this.editedEntry.sentiment) {
      return true;
    }

    return !this.stageHasSentiment(this.entryBeingEdited.funnelStage.id);
  }

  @computed get isPositiveSentiment() {
    return this.newFeedback.sentiment === 'positive';
  }

  @computed get isNegativeSentiment() {
    return this.newFeedback.sentiment === 'negative';
  }

  @computed get funnelPickerDisabled() {
    return _.get(this.candidateViewModel, 'candidate.disqualified', false);
  }

  @computed get displayViewEmailLinks() {
    return this.elements.size === this.totalEmailEntries;
  }

  @computed get showInterviewGuideButton() {
    return (!_.isEmpty(this.hiringFunnelState.interviewGuides) || auth.hasAccess('::MANAGE_ATS'));
  }

  @computed get canHireEmployees() {
    return auth.hasAccess('::HIRE_EMPLOYEES');
  }
}

export default CandidateDetailsState;
