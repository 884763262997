import {observable, action, computed} from 'mobx';
import {api, t, endpoints, types} from 'shared/core';
import {successAlert, errorAlert} from 'shared/tools';
import {BillingSubscription} from 'stores/billing';
import _ from 'lodash';

class BillingDetailsState {
  @observable subscription;
  @observable editSubscription;
  @observable editContactDetailsModalOpen = false;
  @observable cancelPayrollModalOpen = false;
  @observable cancelSubscriptionModalOpen = false;
  @observable reactivatingSubscription = false;
  @observable selectedEmails = [];

  @observable errors = {};

  constructor(store) {
    this.store = store;
  }

  @action async load() {
    await this.store.load();

    this.subscription = this.store.getBillingSubscription();
  }

  @action openCancelPayrollModal() {
   this.cancelPayrollModalOpen = true;
  }

  @action closeCancelPayrollModal() {
   this.cancelPayrollModalOpen = false;
  }

  @action openCancelSubscriptionModal() {
   this.cancelSubscriptionModalOpen = true;
  }

  @action closeCancelSubscriptionModal() {
   this.cancelSubscriptionModalOpen = false;
  }

  @action openEditContactDetailsModal() {
    this.editSubscription = new BillingSubscription(this.subscription);
    this.selectedEmails = this.editSubscription.ccEmails.slice();
    this.editContactDetailsModalOpen = true;
  }

  @action closeEditContactDetailsModal() {
    this.editContactDetailsModalOpen = false;
  }

  @action async notifyPayroll() {
    const {status} = await api.post(endpoints.COMPANY_REQUEST_PAYROLL_CANCELLATION);

    if (status === 201) {
      successAlert(t('company_settings.billing.cancel_subscription.Our team will contact you shortly'));
      this.closeCancelPayrollModal();
    }
  }

  @action async requestSubscriptionCancellation() {
    const {status} = await api.post(endpoints.COMPANY_REQUEST_SUBSCRIPTION_CANCELLATION);

    if (status === 201) {
      successAlert(t('company_settings.billing.cancel_subscription.Our team will contact you shortly'));
      this.closeCancelSubscriptionModal();
    }
  }

  @action async retrySubscription() {
    const {model} = await this.store.patch(
      this.subscription.link('retry'),
      types.BILLING.SUBSCRIPTION
    );

    if (model) {
      this.subscription = new BillingSubscription(model);
      successAlert(t('company_settings.billing.retry_subscription.Payment succeeded.'));
    } else {
      errorAlert(t('company_settings.billing.retry_subscription.Payment failed. Please contact our support team for further assistance.'));
    }
  }

  @action onCcEmailsChange(e) {
    if (e.action === 'add') {
      this.addCcEmail(e.value);
    } else if (e.action === 'remove') {
      this.removeCcEmail(e.value);
    }
  }

  @action addCcEmail(email) {
    this.selectedEmails.push(email);
  }

  @action removeCcEmail(email) {
    _.remove(this.selectedEmails, (existingEmail) => existingEmail === email);
  }

  @action async saveContactDetails() {
    this.editSubscription.ccEmails = this.selectedEmails;
    const {errors, model} = await this.store.patch(
      endpoints.BILLING.UPDATE_CONTACT_DETAILS,
      types.BILLING.SUBSCRIPTION,
      this.editSubscription
    );

    if (model) {
      this.subscription.update(model);
      this.closeEditContactDetailsModal();
    }
    this.errors = errors;
  }

  @action async reactivateSubscription() {
    this.reactivatingSubscription = true;
    const {model} = await this.store.patch(
      this.subscription.link('reactivate'),
      types.BILLING.SUBSCRIPTION
    );
    this.reactivatingSubscription = false;

    if (model) {
      this.subscription = new BillingSubscription(model);
      successAlert(t('company_settings.billing.reactivate_subscription.Subscription reactivated.'));
    } else {
      errorAlert(t('company_settings.billing.reactivate_subscription.Reactivation failed. Please contact our support team for further assistance.'));
    }
  }

  @computed get subscriptionIsActive() {
    return !this.subscription.hasLink('reactivate');
  }
}

export default BillingDetailsState;
