function getCategoryForAttribute(attribute) {
  switch (attribute) {
    case 'department_id': return 'Department';
    case 'location_id': return 'Location';
    case 'employment_type': return 'Employment Type';
    case 'manager_id': return 'Manager';
    case 'gender': return 'Gender';
    case 'termination_type': return 'Termination Type';
    case 'start_date': return 'Start Date';
    case 'last_day_of_work': return 'Last Day of Work';
    case 'last_day_of_benefits': return 'Last Day of Benefits';
    case 'termination_date': return 'Termination Date';
    case 'period_date': return 'Period Date';
    case 'employment_status': return 'Employment Status';
    case 'employee_id': return 'Employee';
    case 'reviewer_employee_id': return 'Reviewer';
    case 'pay_rate':
    case 'pay_rate_unit':
      throw new Error(`Pay rate and pay rate unit are expected to be handled separately. Don't call this fucntion to handle them.`);
    default:
      throw new Error(`Attribute ${attribute} is not supported.`);
  }
}

export default getCategoryForAttribute;
