import React from 'react';
import {observer} from 'mobx-react';
import {Input, Panel} from 'components';
import {injectIntl} from 'react-intl';
import {t} from 'shared/core';

const GeneralInfo = injectIntl(observer(({uiState, intl}) => {
  const {role, errors} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('company_settings.permissions.Role Name')}/>
      <Input errorMessage={errors.name}
            placeholder='company_settings.permissions.e.g., Accountant'
            value={role.name} onChange={e => role.merge({name: e.target.value})}/>
    </Panel>
  );
}));

export default GeneralInfo;
