import {observable, action, computed} from 'mobx';
import {renderPdf} from 'components/pdf/render';
import {noop} from 'shared/tools';
import uuid from 'uuid';
import _ from 'lodash';

const FLOATING_POINT_MATH_OFFSET = 0.000000001;
const SCROLL_BAR_WIDTH = 15;

class PdfPreviewState {
  viewerElement;
  pdfViewer;
  pdfUrl;
  viewerContainerId;
  scrollPosition;
  bodyOverflowY;

  callbacks = {
    onLoaded: noop,
    onPageChanging: noop,
    onPageChanged: noop,
    onPageNumberUpdated: noop
  };

  @observable loading = true;
  @observable pageNumber = 1;
  @observable fullScreen = false;
  @observable includeFabricCanvas;
  @observable errors = {};

  constructor(callbacks, pdfUrl, pageNumber = 1, fullScreenOption, title, includeFabricCanvas) {
    _.merge(this.callbacks, callbacks);
    this.pdfUrl = pdfUrl;
    this.pageNumber = pageNumber;
    this.fullScreenOption = fullScreenOption;
    this.title = title;
    this.includeFabricCanvas = includeFabricCanvas;
    this.viewerContainerId = `viewerContainer-${uuid.v4()}`;

    if (!this.pdfUrl) {
      throw new Error(`Expected a pdf url, but got: ${this.pdfUrl}`);
    }
  }

  onPDFLoad(){
    this.viewerElement = document.getElementById('viewer');

    this.callbacks.onLoaded({
      viewerElement: this.viewerElement,
      selectPage: pageNumber => this.selectPage(pageNumber)
    });

    this.viewerContainer = document.getElementById('pdfViewerContainer');
    this.viewerContainer.style.height = `${this.viewerElement.clientHeight}px`;
    this.viewerContainer.style.width = `${this.viewerElement.clientWidth}px`;

    this.callbacks.onPageChanged(this.pageNumber);

    this.loading = false;
  }

  async load(canvas) {
    this.canvas = canvas;

    const pdfResponse = await renderPdf({
      containerId: this.viewerContainerId,
      url: this.pdfUrl,
      onPDFLoad: () => this.onPDFLoad(),
      onPageChange: (pageNumber) => this.selectPage(pageNumber)
    });
    if (!pdfResponse) return;

    if (_.isEmpty(pdfResponse.errors)) {
      this.pdf = pdfResponse.pdf;
      this.pdfViewer = pdfResponse.viewer;
    } else {
      this.errors = pdfResponse.errors;
    }
  }

  @action async previousPage() {
    if (!this.prevVisible) return null;
    await this.selectPage(this.pageNumber - 1);
  }

  @action async nextPage() {
    if (!this.nextVisible) return null;
    await this.selectPage(this.pageNumber + 1);
  }

  @action async exitFullScreen(){
    this.fullScreen = false;
    this.pdfViewer.currentScaleValue = (this.viewerContainer.clientWidth + FLOATING_POINT_MATH_OFFSET) / (this.pdfViewer._pages[this.pageNumber - 1].width / this.pdfViewer.currentScale);

    this.enableBodyScrollbar();

    this.pdfViewer.page = this.pageNumber;
  }

  @action async enterFullScreen(){
    this.fullScreen = true;

    this.pdfViewer.currentScaleValue = (document.body.clientWidth + FLOATING_POINT_MATH_OFFSET) / (this.pdfViewer._pages[this.pageNumber - 1].width / this.pdfViewer.currentScale);

    this.removeBodyScrollbar();
  }

  removeBodyScrollbar(){
    this.scrollPosition = window.scrollY;
    this.bodyOverflowY = document.body.style.overflowY;
    document.body.style.overflowY = 'hidden';
  }

  enableBodyScrollbar(){
    document.body.style.overflowY = this.bodyOverflowY;
    window.scrollTo(0, this.scrollPosition);
  }

  async selectPage(pageNumber) {
    if(pageNumber !== this.pageNumber){
      this.callbacks.onPageChanging();

      this.pageNumber = pageNumber;
      this.pdfViewer.currentPageNumber = this.pageNumber;

      if(this.fullScreen){
        this.pdfViewer.currentScaleValue = (document.body.clientWidth - SCROLL_BAR_WIDTH + FLOATING_POINT_MATH_OFFSET) / ((this.pdfViewer._pages[this.pageNumber - 1].width / this.pdfViewer.currentScale));
      }else{
        this.pdfViewer.currentScaleValue = (this.viewerContainer.clientWidth + FLOATING_POINT_MATH_OFFSET) / ((this.pdfViewer._pages[this.pageNumber - 1].width / this.pdfViewer.currentScale));
      }

      this.callbacks.onPageChanged(pageNumber);
      if (this.callbacks.onPageNumberUpdated) {
        this.callbacks.onPageNumberUpdated(pageNumber, false);
      }
    }
  }

  @computed get totalPages() {
    return this.pdf.numPages;
  }

  @computed get prevVisible() {
    return this.pageNumber > 1;
  }

  @computed get nextVisible() {
    return this.pageNumber < this.totalPages;
  }
}

export default PdfPreviewState;
