import React from 'react';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Panel, Button} from 'components';
import ManagedPayrollIllustration from 'img/payroll/managed_payroll_illustration@2x.png';
import PayrollSyncIllustration from 'img/payroll/payroll_sync_illustration@2x.png';

const PayrollInfo = observer(({uiState}) => {
  return (
    <div>
      <div className='h2 py2 medium'>
        <FormattedMessage id='payroll.not_enabled.How Can Collage Help?'/>
      </div>
      <div className='flex flex-col md-flex-row'>
        <div className='col col-12 mb2 md-col-6 md-pr1'>
          <Panel className='full-height grid'>
            <div className='center mb4'>
              <Panel.Header title={t('payroll.not_enabled.Payroll Sync')}/>
              <img className='mt2' src={PayrollSyncIllustration} alt='payroll_sync'/>
            </div>
            <div className='mb2'>
              <FormattedMessage id='payroll.not_enabled.Use Collage HR to manage changes in your employee data (like salary or vacation balances), and your payroll system will be updated like magic.'/>
            </div>
            <FormattedMessage id='payroll.not_enabled.This option is best for clients who want to control their pay runs, but want help with administration.'/>
            <div className='mt2 center align-self-end'>
              <Button onClick={() => uiState.submit('payroll_sync') }>
                <FormattedMessage id="payroll.not_enabled.Learn More"/>
              </Button>
            </div>
          </Panel>
        </div>
        <div className='col col-12 mb2 md-col-6 md-pl1'>
          <Panel className='full-height grid'>
            <div className='center mb4'>
              <Panel.Header title={t('payroll.not_enabled.Managed Payroll')}/>
              <img className='mt2' src={ManagedPayrollIllustration} alt='managed_payroll'/>
            </div>
            <div className='mb2'>
              <FormattedMessage id='payroll.not_enabled.Use Collage HR to manage changes in your employee data (like salary or vacation balances), and we will do your entire payroll for you.'/>
            </div>
            <FormattedMessage id='payroll.not_enabled.This option is best for clients who would like to hand off their payroll responsibilities to a team of experts.'/>
            <div className='mt2 center align-self-end'>
              <Button onClick={() => uiState.submit('managed_payroll') }>
                <FormattedMessage id="payroll.not_enabled.Learn More"/>
              </Button>
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
});

export default PayrollInfo;
