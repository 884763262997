import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {MassUpdatesDirectory} from '../directory';
import {MassUpdateEditor} from '../edit';

const MassUpdatesPage = () => {
  return (
    <Router basename='/mass_updates'>
      <Switch>
        <Route exact path='/' component={MassUpdatesDirectory}/>
        <Route exact path='/:id' component={MassUpdateEditor}/>
      </Switch>
    </Router>
  );
};

export default MassUpdatesPage;
