import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class CompanyEmailTemplate extends DomainObject {
  @observable emailContentEn;
  @observable emailContentFr;
  @observable emailType;
}

export default CompanyEmailTemplate;
