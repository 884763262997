import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {Input} from 'components';

const RenameModal = observer(({uiState}) => {
  const {editingPolicy, errors} = uiState;

  return (
    <Modal
      size='sm'
      isOpen={uiState.renameModalOpen}
      onHide={() => uiState.closeModals()}
      title='time_off.policy.Rename Policy'
    >
      <Input 
        value={editingPolicy.name}
        onChange={e => editingPolicy.merge({name: e.target.value})}
        label='time_off.policy.Policy Name'
        errorMessage={errors.name}
      />
      <ModalButtons
        onSave={() => uiState.updatePolicyName()}
        onCancel={() => uiState.closeModals()} />
    </Modal>
  );
});

export default RenameModal;

