import React from 'react';
import {observer} from 'mobx-react';
import {observable, action} from 'mobx';
import {auth, t, withState} from 'shared/core';
import {redirect} from 'shared/tools';
import {Panel, Button} from 'components';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import OAuthButtons from 'components/login/OAuthButtons';

const ConnectYourAccount = () => {
  return (
    <Panel>
      <Panel.Header
        title={t('user_settings.oauth.OAuth')}
        description={t('user_settings.oauth.connect_account_explainer')}
      />
      <OAuthButtons context='connect' />
    </Panel>
  );
};

const DisconnectInstructionsModal = observer(({uiState}) => {
  if (!uiState.modalOpen) return null;

  return (
    <Modal
      size='md'
      isOpen={uiState.modalOpen}
      onHide={() => uiState.hideModal()}
      title={`user_settings.oauth.${auth.user.authenticationProvider}.disconnect_explainer`}
    >
      <FormattedMessage id={`user_settings.oauth.${auth.user.authenticationProvider}.disconnect_instructions`}/>
      <ModalButtons
        saveCaption='user_settings.oauth.Reset Password Now'
        onCancel={() => uiState.hideModal()}
        onSave={() => uiState.goToResetPassword()}
      />
    </Modal>
  );
});

const DisconnectOAuthAccount = observer(({uiState}) => {
  return (
    <Panel>
      <Panel.Header
        title={t('user_settings.oauth.OAuth')}
        description={
          <FormattedMessage id={`user_settings.oauth.${auth.user.authenticationProvider}.connected_explainer`} values={{
            email: <b>{auth.user.email}</b>
          }}/>
        }
      />
      <Button onClick={() => uiState.showModal()} trait='default'>
        <FormattedMessage id='user_settings.oauth.Disconnect'/>
      </Button>
      <DisconnectInstructionsModal uiState={uiState}/>
    </Panel>
  );
});

const ToggleOAuth = observer(({uiState}) => {
  if (auth.company.ssoEnabled) return null;

  if (auth.user.authenticationProvider) {
    return <DisconnectOAuthAccount uiState={uiState}/>;
  }

  return <ConnectYourAccount uiState={uiState}/>;
});

class OAuthState {
  @observable modalOpen = false;

  @action showModal() {
    this.modalOpen = true;
  }

  @action hideModal() {
    this.modalOpen = false;
  }

  @action async goToResetPassword() {
    return redirect('/password_resets/new');
  }

  get authenticityToken() {
    return document.getElementsByName('csrf-token')[0].content;
  }
}

export default withState(ToggleOAuth, OAuthState);
