import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {ReferralDirectory} from '../directory';
import {ReferralForm} from '../candidate';

const ReferralsPage = () => {
  return (
    <Router basename='/referrals'>
      <Switch>
        <Route exact path='/' component={ReferralDirectory}/>
        <Route exact path='/:id/refer_candidate' component={ReferralForm}/>
      </Switch>
    </Router>
  );
};

export default ReferralsPage;
