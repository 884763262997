import React from 'react';
import {observer} from 'mobx-react';
import {fullName} from 'shared/tools';
import {Avatar} from 'components';

const RevieweeHeader = ({reviewee}) => {
  return (
    <div className="mb1">
      <div className="Container">
        <div className='clearfix'>
          <div className='table-cell align-middle'>
            <Avatar employee={reviewee} size='md'/>
          </div>
          <div className='table-cell align-middle px2'>
            <div className="h1 py3 black">{fullName(reviewee)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(RevieweeHeader);

