import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import {groupEmployeeCustomFields} from 'shared/tools';
import _ from 'lodash';

class EmployeeCustomFieldsState {
  store = new DomainStore();

  @observable category;
  @observable employeeCustomFields = [];

  receiveProps({employeeCustomFields, category}) {
    this.employeeCustomFields = employeeCustomFields;
    this.category = category;
  }

  @action async load() {
    await this.store._compose([
      endpoints.CUSTOM_FIELD_GROUPS.with(this.category)
    ]);
  }

  @computed get sortedCustomFieldGroups() {
    return groupEmployeeCustomFields(this.employeeCustomFields, this.store._getAll(types.CUSTOM_FIELD_GROUP));
  }
}

export default EmployeeCustomFieldsState;
