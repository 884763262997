import _ from 'lodash';
import {observable, computed} from 'mobx';
import {EarningsFormatter} from 'shared/tools';
import {t} from 'shared/core';

class CommissionFormState {
  @observable model;
  @observable errors = {};
  @observable earningsFormatter;

  receiveProps({model, errors}) {
    this.model = model;
    this.errors = errors || {};
    this.earningsFormatter = new EarningsFormatter(model);
  }

  @computed get totalYearlyInsurableEarningsError() {
    if (this.earningsFormatter.annualizedSalary === null) {
      return t('employees.hire.compensation.Invalid salary or commission');
    }
  }
}

export default CommissionFormState;