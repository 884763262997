import React from 'react';
import {observer} from 'mobx-react';
import {endpoints, types, t} from 'shared/core';
import {TimeTrackingPolicy} from 'stores/time_tracking';
import {InteractiveTable, Tag, AvatarList} from 'components';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const Name = observer(({model}) => {
  return (
    <div>
      <div className='table-cell align-middle'>
        {model.name ? model.name : <FormattedMessage id='time_tracking.manage.policies.Untitled policy'/>}
      </div>
      <div className='table-cell align-middle'>
        {model.state === 'draft' && <Tag className='ml1'><FormattedMessage id='time_tracking.manage.policies.directory.Draft'/></Tag>}
      </div>
    </div>
  );
});

const Approver = observer(({model}) => {
  if (model.assigneeType === 'manager') return t('time_tracking.manage.policies.directory.Manager');
  if (model.assigneeType === 'specific_user' && model.assigneeUser) return model.assigneeUser.name;
  return null;
});

const PaySchedule = observer(({model}) => {
  if (model.paySchedule) return model.paySchedule.name;
  return null;
});

const COLUMNS = [
  {
    header: 'time_tracking.manage.policies.directory.Policy name',
    width: 4,
    component: Name
  },
  {
    header: 'time_tracking.manage.policies.directory.Pay schedule',
    width: 2,
    component: PaySchedule
  },
  {
    header: 'time_tracking.manage.policies.directory.Approver',
    width: 2,
    component: Approver
  },
  {
    header: 'time_tracking.manage.policies.directory.Employees',
    width: 3,
    render: model => <AvatarList employees={model.employees} moreModalEnabled/>
  }
];

const LOCALIZATION = {
  emptyState: 'time_tracking.manage.policies.directory.No policies to display',
  removeModal: {
    header: 'time_tracking.manage.policies.directory.Remove Policy?',
    subHeader: 'time_tracking.manage.policies.directory.Are you sure you want to remove this policy?'
  },
  interactive: {
    addModel: 'time_tracking.manage.policies.directory.Create New Policy'
  }
};

const PolicyDirectory = observer(({uiState}) => {
  return (
    <InteractiveTable
      title={t('time_tracking.manage.Policies')}
      uiState={uiState}
      columns={COLUMNS}
      saveStateToUrl={true}
      searchable={true}
      onMount={agent => uiState.setPolicyInteractiveAgent(agent)}
      localization={LOCALIZATION}
      onAdd={() => uiState.createPolicy()}
      onRemove={policy => uiState.removePolicy(policy)}
      editRoute='/manage/policies/:id/edit'
      proxy={{
        type: 'async',
        modelType: types.TIME_TRACKING.POLICY,
        model: TimeTrackingPolicy,
        endpoint: endpoints.TIME_TRACKING.POLICIES.ALL
      }}>
    </InteractiveTable>
  );
});

export default PolicyDirectory;
