import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {endpoints, types, t, urls} from 'shared/core';
import {successAlert, redirect} from 'shared/tools';
import {Company} from 'stores/company';

class OfferDirectoryState {
  interactiveAgent;
  store = new DomainStore();
  history;

  @observable company;
  @observable selectedOffer;
  @observable revokeOfferModalOpen = false;
  @observable revokeReasonModalOpen = false;
  @observable offerPreviewModalOpen = false;

  setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  receiveProps({history}) {
    this.history = history;
  }

  @action async load() {
    const compose = [
      endpoints.CURRENT_COMPANY
    ];
    await this.store._compose(compose);

    this.company = new Company(this.store._getSingle(types.COMPANY));
  }

  goToNewOfferPage() {
    this.history.push('new');
  }

  @action async hire(offer) {
    const {model} = await this.store.post(offer.link('hire'), types.EMPLOYEE_DETAILS);
    return redirect(urls.EMPLOYEE.with(model.id));
  }

  @action openRevokeOfferModal(offer) {
    this.selectedOffer = offer;
    this.revokeOfferModalOpen = true;
  }

  @action closeRevokeOfferModal() {
    this.revokeOfferModalOpen = false;
    this.selectedOffer = null;
  }

  @action openRevokeReasonModal(offer) {
    this.selectedOffer = offer;
    this.revokeReasonModalOpen = true;
  }

  @action closeRevokeReasonModal() {
    this.revokeReasonModalOpen = false;
    this.selectedOffer = null;
  }

  @action openOfferPreviewModal(offer) {
    this.selectedOffer = offer;
    this.offerPreviewModalOpen = true;
  }

  @action closeOfferPreviewModal() {
    this.offerPreviewModalOpen = false;
    this.selectedOffer = null;
  }

  @action async revokeOffer() {
    const {model} = await this.store.patch(
      endpoints.OFFER.REVOKE.with(this.selectedOffer.id),
      types.OFFER,
      this.selectedOffer
    );

    if (model) {
      this.interactiveAgent.refresh();
      successAlert(t('offers.directory.revoke_offer.Offer revoked.'));
      this.closeRevokeOfferModal();
    }
  }

  @action async removeOffer(offer) {
    await this.store.destroy(offer);
  }

  customLinksFor(offer) {
    const links = [];
    if (offer.hasLink('hire')) {
      links.push({
        order: 1,
        text: 'offers.directory.hire.Hire',
        action: model => this.hire(model)
      });
    }
    if (offer.hasLink('revoke')) {
      links.push({
        order: 1,
        text: 'offers.directory.revoke_offer.Revoke',
        action: model => this.openRevokeOfferModal(model)
      });
    }
    links.push({
      order: 1,
      text: 'offers.directory.Preview offer',
      action: model => this.openOfferPreviewModal(model)
    });
    if (offer.offerDocument) {
      links.push({
        order: 1,
        text: 'offers.directory.Download offer letter',
        downloadLink: offer.offerDocument.link('url')
      });
    }


    return links;
  }
}

export default OfferDirectoryState;
