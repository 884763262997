import React from 'react';
import {Modal, ModalButtons} from 'components/modals';
import {FormLayout, Input} from 'components';
import {observer} from 'mobx-react';

const EditCompanyLinkModal = observer(({uiState}) => {
  const {editCompanyLinkModalOpen, newCompanyLink, errors} = uiState;
  if (!editCompanyLinkModalOpen) return null;
  const header = newCompanyLink.isNew ? 'company_settings.company_links.Add a Company Link' : 'company_settings.company_links.Edit Company Link';

  return (
    <Modal isOpen={editCompanyLinkModalOpen}
           onHide={() => uiState.closeEditCompanyLinkModal()}
           size='md'
           title={header}>
      <FormLayout>
        <Input label='company_settings.company_links.Link Title:'
          value={newCompanyLink.name}
          onChange={e => newCompanyLink.name = e.target.value}
          placeholder='company_settings.company_links.e.g. Collage'
          errorMessage={errors.name}/>
        <Input label='company_settings.company_links.Hyperlink:'
          value={newCompanyLink.url}
          onChange={e => newCompanyLink.url = e.target.value}
          placeholder='company_settings.company_links.e.g. https://collage.co'
          errorMessage={errors.url}/>
      </FormLayout>
      <ModalButtons
        onSave={() => uiState.saveCompanyLink()}
        saveCaption='company_settings.company_links.Save'
        onCancel={() => uiState.closeEditCompanyLinkModal()}
        cancelCaption='company_settings.company_links.Cancel'
      />
    </Modal>
  );
});

export default EditCompanyLinkModal;
