import * as Sentry from "@sentry/react";
import filestack from 'filestack-js';
import _ from 'lodash';
import {auth} from 'shared/core';

const openFileStack = (params, onUpdate) => {
  const client = filestack.init(process.env.FILESTACK_KEY, auth.fileStack.uploadPolicy);

  const pick = _.defaultsDeep(params, {
    fromSources: [
      'local_file_system',
      'googledrive',
      'onedrive',
      'dropbox',
    ],
    storeTo: {
      location: 's3'
    }
  });

  client.pick(pick).then(response => {
    const file = _.head(response.filesUploaded);
    if (file) {
      onUpdate({
        fileName: file.filename,
        fileSize: file.size,
        contentType: file.mimetype,
        key: file.key,
        label: file.url
      });
    } else {
      Sentry.captureMessage('FileStack upload failed.', {extra: response});
    }
  });
};

export default openFileStack;
