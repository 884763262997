import React from 'react';
import {Panel} from 'components';
import {t} from 'shared/core';
import {TaskTemplateSelector} from 'components/tasks';

const TasksContainer = (props) => {
  return (
    <Panel>
      <Panel.Header title={t(`tasks.${props.config.title}`)} />
      <TaskTemplateSelector {...props}/>
    </Panel>
  );
};

export default TasksContainer;
