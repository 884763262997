import JobTimelineEntry from './JobTimelineEntry';
import {observable, action} from 'mobx';
import Employee from 'stores/employees/Employee';
import {oneOf} from 'shared/store';
import {types} from 'shared/core';

class TerminationRecord extends JobTimelineEntry {
  @observable lastDayOfBenefits;
  @observable lastDayOfWork;
  @observable terminationReason;
  @observable reassignReportsTo;
  @observable terminationType;
  @observable employeeHasBenefits;
  @observable proofOfExtension;
  @observable roeCode;
  @observable reasonForQuitting;
  @observable terminatedOnProbation;

  // Termination fields
  @observable terminatedAt;
  @observable sendToAdmin;
  @observable personalEmail;
  @observable reassignReportsToEmployeeId;
  @observable withinESARequirements;

  @action merge(other) {
    super.merge(other, {
      _dates: ['lastDayOfBenefits', 'lastDayOfWork'],
      terminationType: oneOf(['voluntary', 'involuntary']),
      reassignReportsTo: Employee
    });

    this._type = types.TERMINATION_RECORD;
  }
}

export default TerminationRecord;
