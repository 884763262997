import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {MFASetupContainerState} from '../state';
import MFAOptionSelection from './MFAOptionSelection';
import MFAVerification from './MFAVerification';
import MFAUserCreation from './MFAUserCreation';
import MFARecoveryCode from './MFARecoveryCode';
import Logo from 'img/collage-logo--blue@2x.png';
import {FormattedMessage} from 'react-intl';
import {ActionLink} from 'components';

const MFAStates = observer(({uiState}) => {
  const {STATES} = uiState;

  switch (uiState.currentState) {
    case STATES.optionSelection:
      return <MFAOptionSelection uiState={uiState} />;
    case STATES.userCreation:
      return <MFAUserCreation uiState={uiState} />;
    case STATES.verification:
      return <MFAVerification uiState={uiState} />;
    case STATES.recoveryCode:
      return <MFARecoveryCode uiState={uiState} />;
    default:
      throw new Error('Invalid state for MFA setup');
  }
});

const MFASetupContainer = observer(({uiState}) => {
  return (
    <div>
      <div className='bg-white border-bottom center py1'>
        <a href='/' className='inline-block p2'>
          <img src={Logo} alt='Collage' height='26px'/>
        </a>
        <ActionLink
          className='right TextLink m2'
          onClick={() => uiState.logout()}
        >
          <FormattedMessage id='login.Log out'/>
        </ActionLink>
      </div>
      <MFAStates uiState={uiState} />
    </div>
  );
});

export default withState(MFASetupContainer, MFASetupContainerState);
