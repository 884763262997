import {DomainObject} from 'shared/store';
import {observable, action} from 'mobx';
import InterviewGuideQuestion from './InterviewGuideQuestion';

class InterviewGuide extends DomainObject {
  @observable name;
  @observable questions = [];

  @action merge(other) {
    super.merge(other, {
      questions: [InterviewGuideQuestion]
    });
  }
}

export default InterviewGuide;

