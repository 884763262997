import React from 'react';
import {ActionLink} from 'components';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';

const DocumentActionSelector = observer(({onActionSelected}) => {
  return (
    <div className='col-10 mx-auto'>
      <div className='h3 jumbo pt3 pb2 max-width-2 center'>
        <FormattedMessage id='components.documents.Do you want to assign a new document or assign an existing company document?'/>
      </div>
      <div className='flex flex-col md-flex-row row-gap-2 mxn3 pt3 pb4'>
        <div className='col col-12 md-col-6 px3'>
          <ActionLink onClick={() => onActionSelected('new')} className='full-height block p3 bg-white bg-hover-smoke border rounded center'>
            <div className='mb2'>
              <i className='material-icons h1 dodger'>{'computer'}</i>
            </div>
            <span className='waterloo'>
              <FormattedMessage id='components.documents.Upload a new file'/>
            </span>
          </ActionLink>
        </div>
        <div className='col col-12 md-col-6 px3'>
          <ActionLink onClick={() => onActionSelected('existing')} className='full-height block p3 bg-white bg-hover-smoke border rounded center'>
            <div className='mb2'>
              <i className='material-icons h1 dodger'>{'file_copy'}</i>
            </div>
            <span className='waterloo'>
              <FormattedMessage id='components.documents.Assign an existing document'/>
            </span>
          </ActionLink>
        </div>
      </div>
    </div>
  );
});

export default DocumentActionSelector;
