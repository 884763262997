import React from 'react';
import {observer} from 'mobx-react';
import {types, endpoints, t} from 'shared/core';
import {InteractiveTable} from 'components';
import {User} from 'stores/users';

const COLUMNS = [
  {
    header: 'Name',
    width: 6,
    attribute: 'name'
  },
  {
    header: 'Email Address',
    width: 5,
    attribute: 'email'
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'broker_portal.broker_settings.Remove user',
    subHeader: 'broker_portal.broker_settings.Are you sure you want to remove this user?',
    body: 'broker_portal.broker_settings.This user will no longer have access to the platform.'
  }
};

const UsersList = observer(({uiState}) => {
  return (
    <InteractiveTable
      sortable={false}
      searchable={false}
      title={t('broker_portal.broker_settings.Users')}
      columns={COLUMNS}
      localization={LOCALIZATION}
      onRemove={user => uiState.removeUser(user)}
      proxy={{
        type: 'async',
        modelType: types.USER,
        model: User,
        endpoint: endpoints.BROKER_PORTAL.USERS
      }}
    />
  );
});

export default UsersList;
