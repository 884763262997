import React from 'react';
import {FormattedMessage} from 'react-intl';
import {ActionLink, FormError, Tag, Button, AutosaveStatus, LexicalEditor} from 'components';
import {observer} from 'mobx-react';

const FeedbackContainer = observer(({detailsState}) => {
  const {candidate} = detailsState.candidateViewModel;
  const {canAddNewSentiment, newFeedbackErrors, hiringFunnelState, newFeedback} = detailsState;
  const {mentions} = hiringFunnelState;

  return (
    <div className='border rounded FeedbackContainer'>
      <div className='px3 py1 bg-athens border-bottom'>
        <i className='material-icons h3 jumbo align-text-top pr1'>{'thumbs_up_down'}</i>
        <span className='align-top'><FormattedMessage id='recruiting.hiring_funnel.FEEDBACK_FOR' values={{name: candidate.firstName}}/></span>
      </div>
      <div className='bg-white'>
        <LexicalEditor
          placeholder={'recruiting.hiring_funnel.Add Feedback - Type @ to mention a coworker'}
          mentionOptions={mentions}
          onChange={text => detailsState.updateNewFeedbackText(text)}
          initialEditorState={newFeedback.lexicalState}
          initialHTML={newFeedback.htmlText}
          key={newFeedback.id}
        />
        <div className='relative px3'>
          <FormError message={newFeedbackErrors.text} />
        </div>
      </div>
      {canAddNewSentiment && <div className='border-top bg-white'>
        <div className='px3 py2'>
          <ActionLink onClick={() => detailsState.updateNewFeedback({sentiment: 'positive'})} onBlur={(e) => e.stopPropagation()}>
            <Tag type='rounded' colour='haze' className={`TagSelect-positive ${detailsState.isPositiveSentiment ? 'selected' : ''} clickable mr1`}>
              <div className='table-cell align-middle'>
                <i className='material-icons h4 align-bottom pr1 grass'>{'thumb_up'}</i>
              </div>
              <div className='table-cell align-bottom'>
                <FormattedMessage id='recruiting.hiring_funnel.Positive'/>
              </div>
            </Tag>
          </ActionLink>
          <ActionLink onClick={() => detailsState.updateNewFeedback({sentiment: 'negative'})} onBlur={(e) => e.stopPropagation()}>
            <Tag type='rounded' colour='haze' className={`TagSelect-negative ${detailsState.isNegativeSentiment ? 'selected' : ''} clickable mr1`}>
              <div className='table-cell align-middle'>
                <i className='material-icons h4 align-bottom pr1 scarlet'>{'thumb_down'}</i>
              </div>
              <div className='table-cell align-bottom'>
                <FormattedMessage id='recruiting.hiring_funnel.Negative'/>
              </div>
            </Tag>
          </ActionLink>
          <div className='inline-block align-text-top ml2'>
            <FormError message={newFeedbackErrors.sentiment} />
          </div>
        </div>
      </div>}
      <div className='border-top bg-white'>
        <div className='px3 py2 right-align'>
          <AutosaveStatus autosaver={detailsState.newFeedback.autosaver}/>
          <Button className='pl2' trait='primary' onClick={() => detailsState.addFeedback()}>
            <FormattedMessage id='recruiting.hiring_funnel.Add Feedback'/>
          </Button>
        </div>
      </div>
    </div>
  );
});

export default FeedbackContainer;
