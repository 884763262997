import React from 'react';
import BrokerSignupSvg from './BrokerSignupSvg';
import MarketingCopy from './MarketingCopy';
import {LocaleSelect} from 'components';

const BrokerBrandedContainer = ({bulletPoints, header, info, children}) => {
  return (
    <div className='wl-broker-branded-container table relative'>
      <div className='table-cell align-middle center sm-and-below-hide'>
        <div className='MarketingCopyContainer mx-auto'>
          <div className='p4'>
            <MarketingCopy bulletPoints={bulletPoints} header={header} info={info}/>
          </div>
          <BrokerSignupSvg/>
        </div>
      </div>
      <div className='BrandedFormContainer table-cell align-middle'>
        <div className='absolute right' style={{top: '28px', right: '80px'}}>
          <LocaleSelect/>
        </div>
        <div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default BrokerBrandedContainer;
