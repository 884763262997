import React from 'react';
import {observer} from 'mobx-react';
import {t, withDisplay, withState} from 'shared/core';
import {Button, LabelWithHeader, Layout, List, Panel} from 'components';
import SubNav from 'company_settings/SubNav';
import {FormattedMessage} from 'react-intl';
import CompletedIcon from 'img/list-element-complete@2x.png';
import _ from 'lodash';
import {GoogleConfigurationContainerState} from 'containers/company_settings/applications/google/state';
import SSOConfirmationModal from 'containers/company_settings/applications/components/SSOConfirmationModal';
import useModal from 'shared/hooks/useModal';
import {OAuthButtons} from 'components/login';

const SsoEnabled = observer(({openSSOConfirmationModal, credentials}) => {
  return (
    <div>
      <div className='mb2'>
        <FormattedMessage id='company_settings.applications.sso.sso_enabled_explainer'/>
      </div>
      <LabelWithHeader
        header={t('company_settings.applications.sso.Connected Domain')}
        model={credentials.domain}
      />
      <div className='my2'>
        <FormattedMessage id='company_settings.applications.sso.disable_explanation'/>
      </div>
      <Button onClick={openSSOConfirmationModal}>
        <FormattedMessage id='company_settings.applications.Disable'/>
      </Button>
    </div>
  );
});

const NonGoogleSSO = observer(({company}) => {
  return (
    <FormattedMessage
      id='company_settings.applications.ONLY_ONE_SSO'
      values={{provider: _.startCase(company.ssoProviderName)}}
    />
  );
});

const MismatchedUserList = observer(({users}) => {
  if (_.isEmpty(users)) {
    return (
      <div className='my2 flex align-items-middle'>
        <img
          className='mr1'
          width='16'
          src={CompletedIcon}
          alt='status'
        />
        <FormattedMessage id='company_settings.applications.google.All users match'/>
      </div>
    );
  }

  return (
    <div>
      <div className='my2'>
        <FormattedMessage id='company_settings.applications.google.mismatched_users'/>&nbsp;
      </div>
      <List items={users} titleAttr='name' valueAttr='email'/>
    </div>
  );
});

const SsoDisabled = observer(({users, openSSOConfirmationModal, credentials}) => {
  return (
    <div>
      <div>
        <h3>
          <FormattedMessage id='company_settings.applications.sso.Step 1'/>
        </h3>
        <FormattedMessage id='company_settings.applications.google.explanation_1'/>
        <OAuthButtons className='my2' context='company_sso_setup' providers={['google']}/>
        <LabelWithHeader
          header={t('company_settings.applications.sso.Connected Domain')}
          model={credentials.domain}
        />
      </div>
      <div className='mb2'>
        <h3>
          <FormattedMessage id='company_settings.applications.sso.Step 2'/>
        </h3>
        <FormattedMessage id='company_settings.applications.google.explanation_2'/>&nbsp;
        <FormattedMessage id='company_settings.applications.sso.user_directory_explainer' values={{
          link: <a href='/company_settings/users/users' target='_blank'>
            <FormattedMessage id='company_settings.applications.sso.user directory'/>
          </a>
        }}/>
        {!credentials.isNew && <MismatchedUserList users={users}/>}
      </div>
      <div className='mb2'>
        <h3>
          <FormattedMessage id='company_settings.applications.sso.Step 3'/>
        </h3>
        <div className='mb2'>
          <FormattedMessage id='company_settings.applications.google.explanation_3'/>&nbsp;
          <a href='https://support.collage.co/en/articles/8719515-set-up-google-sso' target='_blank'>
            <FormattedMessage id='company_settings.applications.google.Learn more'/>
          </a>
        </div>
        <Button onClick={openSSOConfirmationModal} disabled={credentials.isNew || !_.isEmpty(users)}>
          <FormattedMessage id='company_settings.applications.Enable'/>
        </Button>
      </div>
    </div>
  );
});

const GoogleConfigurationContainer = observer(({uiState}) => {
  const ssoConfirmationModal = useModal(uiState, 'SSOConfirmationModal');
  const {credentials, users} = uiState;
  const {company} = credentials;

  return (
    <Layout>
      <Layout.Section secondary>
        <Panel>
          <Panel.Header title={t('company_settings.applications.google.Google Single Sign-On')}/>
          {company.ssoEnabled && company.ssoProviderName !== 'google' ?
            <NonGoogleSSO company={company}/>
            :
            company.ssoEnabled ?
              <SsoEnabled
                credentials={credentials}
                openSSOConfirmationModal={() => uiState.openSSOConfirmationModal()}
              />
              :
              <SsoDisabled
                credentials={credentials}
                openSSOConfirmationModal={() => uiState.openSSOConfirmationModal()}
                users={users}
              />
          }
        </Panel>
        <SSOConfirmationModal uiState={uiState} modalOpen={ssoConfirmationModal} provider={'google'}/>
      </Layout.Section>
    </Layout>
  );
});

export default withDisplay(
  withState(GoogleConfigurationContainer, GoogleConfigurationContainerState),
  () => <SubNav title={t('company_settings.subnav.Google')}/>
);
