import {action, computed, observable} from 'mobx';
import TimesheetEntry from 'stores/time_tracking/TimesheetEntry';
import _ from 'lodash';
import {DomainStore, fetchModel, fetchModels} from 'shared/store';
import {api, endpoints, t, types} from 'shared/core';
import {Timesheet, TimeTrackingProject} from 'stores/time_tracking';
import moment from 'moment';
import setupAutosaveModel from 'shared/tools/autosaveModel';

class TimesheetEditorState {
  store = new DomainStore();
  employeeSelectorState;

  @observable timesheet;
  @observable errors = {};
  @observable editingEntry = {};
  @observable viewingEntry = {};
  @observable payPeriods = [];
  @observable projects = [];
  @observable payPeriod;
  @observable employee;

  @observable isLoadingEntries = false;

  async receiveProps({payPeriods, employee, payPeriod, onPayPeriodSelected, employeeSelectorState}) {
    this.employeeSelectorState = employeeSelectorState;
    this.payPeriods = payPeriods;
    this.onPayPeriodSelected = onPayPeriodSelected;
    const currentEmployee = this.employee;
    const currentPayPeriod = this.payPeriod;
    this.employee = employee;
    this.payPeriod = payPeriod;

    if (currentEmployee && (_.get(this.employee, 'id') !== _.get(currentEmployee, 'id') || this.payPeriod.id !== _.get(currentPayPeriod, 'id'))) {
      this.isLoadingEntries = true;
      await Promise.all([
        this.loadEntries(),
        this.loadProjects()
      ]);
      this.isLoadingEntries = false;
    }
  }

  @action async load() {
    await this.store._compose([
      endpoints.TIME_OFF.TYPES.ALL
    ]);

    this.types = this.store._getAll(types.TIME_OFF.TYPE);

    await Promise.all([
      this.loadEntries(),
      this.loadProjects()
    ]);
  }

  @action async loadEntries() {
    this.errors = {};

    this.timesheet = new Timesheet(await fetchModel(
      endpoints.TIME_TRACKING.ENTRIES_FOR_PAY_PERIOD.with(this.payPeriod.id, this.employee.id),
      types.TIME_TRACKING.TIMESHEET
    ));

    let lastEntry = null;
    this.timesheet.entries.forEach(entry => {
      // When retrieving the times we convert to string in UTC so that 9:00am UTC is 9:00a regardless of browser time zone
      // We work with it in the local time on the rest of the page, then just set it to be UTC before sending it back
      if (entry.startTime) {
        entry.startTimeString = moment(entry.startTime).utc().format(t('components.dates.TIMESTAMP_FORMAT'));
      }
      if (entry.endTime) {
        entry.endTimeString = moment(entry.endTime).utc().format(t('components.dates.TIMESTAMP_FORMAT'));
      }

      if (lastEntry && entry.date.toISOString() === lastEntry.date.toISOString()) {
        entry.showDate = false;
        lastEntry.noRowBottomBorder = true;
      }

      lastEntry = entry;

      setupAutosaveModel(entry, returnedEntry => this.updateTimesheetFromEntry(returnedEntry, entry));
    });

    for (let i = 0; moment(this.payPeriod.startDate).add(i, 'd') <= moment(this.payPeriod.endDate); i++) {
      const date = moment(this.payPeriod.startDate).add(i, 'd');
      if (!_.find(this.timesheet.entries, (entry) => entry.date.toISOString() === date.toISOString())) {
        const previousDate = moment(this.payPeriod.startDate).add(i - 1, 'd');
        const previousDateIndex = _.findLastIndex(this.timesheet.entries, (entry) => entry.date.toISOString() === previousDate.toISOString());
        this.timesheet.entries.splice(previousDateIndex + 1, 0, new TimesheetEntry({date: date.toDate()}));
      }
    }
  }

  @action async loadProjects() {
    const projects = await fetchModels(
      endpoints.TIME_TRACKING.PROJECTS.FOR_EMPLOYEE.with(this.employee.id),
      types.TIME_TRACKING.PROJECT
    );

    this.projects = projects.map(project => new TimeTrackingProject(project));
  }

  @action updateTimesheetFromEntry(returnedEntry, entryInMemory) {
    this.updateTotals(returnedEntry);
    if (this.employeeSelectorState && this.timesheet.approved !== returnedEntry.timesheetApproved) {
      this.employeeSelectorState.interactiveAgent.refresh();
    }
    this.timesheet.approved = returnedEntry.timesheetApproved;
    if (returnedEntry.deletedAt || (entryInMemory.endTime && entryInMemory.endTime < moment(this.payPeriod.startDate).utc(true))) {
      return this.removeEntry(entryInMemory);
    }
    entryInMemory.saveStatus = 'saved';
    clearTimeout(entryInMemory.saveStatusTimeout);
    entryInMemory.saveStatusTimeout = setTimeout(() => {
      entryInMemory.saveStatus = 'fadingAway';
      entryInMemory.saveStatusTimeout = setTimeout(() => {
        entryInMemory.saveStatus = null;
      }, 500);
    }, 1000);
  }

  @action addEntry(date) {
    const index = _.findLastIndex(this.timesheet.entries, {date});
    this.timesheet.entries[index].noRowBottomBorder = true;
    this.timesheet.entries.splice(
      index + 1,
      0,
      new TimesheetEntry({date, showDate: false})
    );
  }

  @action async updateEntry(entry, values) {
    entry.merge(values);
    await this.submitChanges(entry);
  }

  setSubmitValuesOnEntry(entry) {
    entry.startTime = entry.startTimeFromString;
    entry.endTime = entry.endTimeFromString;
    entry.payPeriod = this.payPeriod;
  }

  @action async submitChanges(entry) {
    if (entry.isCreating) { return; }

    this.setSubmitValuesOnEntry(entry);

    if (entry.isNew) {
      entry.isCreating = true;
      const {model, errors} = await this.store.post(
        endpoints.TIME_TRACKING.ENTRIES.with(this.employee.id),
        types.TIME_TRACKING.ENTRY,
        entry
      );

      entry.errors = errors;
      if (model) {
        entry.merge(_.pick(model, ['id', 'links', '_type']));
        setupAutosaveModel(entry, returnedEntry => this.updateTimesheetFromEntry(returnedEntry, entry));
        this.setSubmitValuesOnEntry(entry);
        entry.autosaver.autosaveImmediately();
      }
      entry.isCreating = false;
    } else {
      await entry.autosaver.autosave();
    }
  }

  @action updateTotals(timesheet) {
    if (timesheet.totals.calculatedAt > this.timesheet.totals.calculatedAt) {
      this.timesheet.totals = timesheet.totals;
    }
  }

  @action saveEntryNote() {
    this.updateEntry(_.find(this.timesheet.entries, {uuid: this.editingEntry.uuid}), {note: this.editingEntry.note});
    this.closeEditEntryNoteModal();
  }

  @action saveEntryBreak() {
    this.updateEntry(_.find(this.timesheet.entries, {uuid: this.editingEntry.uuid}), {unpaidBreakMinutes: this.editingEntry.unpaidBreakMinutes});
    this.closeEditEntryBreakModal();
  }

  @action async approve() {
    await this.flushPendingEntryAutosaves();
    const {model, errors} = await this.store.post(
      endpoints.TIME_TRACKING.APPROVE.with(this.payPeriod.id, this.employee.id),
      types.TIME_TRACKING.TIMESHEET
    );

    this.errors = errors;
    if (model) {
      this.timesheet.approved = model.approved;
      this.timesheet.entries.forEach(entry => {
        const entryModel = _.find(model.entries, {id: entry.id});

        entry.merge(entryModel);
      });
      if (this.employeeSelectorState) this.employeeSelectorState.interactiveAgent.refresh();
    }
  }

  @action async flushPendingEntryAutosaves() {
    await Promise.all(this.timesheet.entries.map(async entry => {
      if (!entry.autosaver) { return; }

      await entry.autosaver.flush();
    }));
  }

  @action async deleteEntry(entry) {
    if (!entry.isNew) {
      const {data} = await api.post(`${entry.link('destroyEntry')}?pay_period_id=${this.payPeriod.id}`);
      entry.merge(data.data.attributes);
      this.updateTimesheetFromEntry(data.data.attributes, entry);
    } else {
      this.removeEntry(entry);
    }
  }

  @action removeEntry(entry) {
    let indexOfEntry = _.findIndex(this.timesheet.entries, {uuid: entry.uuid});
    const lastIndexOfDate = _.findLastIndex(this.timesheet.entries, {date: entry.date});
    const firstIndexOfDate = _.findIndex(this.timesheet.entries, {date: entry.date});
    if (indexOfEntry === lastIndexOfDate && indexOfEntry > 0) {
      this.timesheet.entries[indexOfEntry - 1].noRowBottomBorder = false;
    }
    if (indexOfEntry === firstIndexOfDate && indexOfEntry + 1 < this.timesheet.entries.length) {
      this.timesheet.entries[indexOfEntry + 1].showDate = true;
    }
    this.timesheet.entries.splice(indexOfEntry, 1);

    if ((indexOfEntry === firstIndexOfDate) && (indexOfEntry === lastIndexOfDate) && entry.date >= this.payPeriod.startDate) {
      this.timesheet.entries.splice(indexOfEntry, 0, new TimesheetEntry({date: entry.date}));
    }
  }

  @computed get entriesAndAbsences() {
    const entriesAndAbsences = this.timesheet.entries.slice();
    this.timesheet.absences.forEach(absence => {
      const index = _.findLastIndex(entriesAndAbsences, {date: absence.date});
      if (index !== -1) {
        entriesAndAbsences[index].noRowBottomBorder = true;
      }
      entriesAndAbsences.splice(index + 1, 0, absence);
    });

    return entriesAndAbsences;
  }

  customLinksFor(entry) {
    if (entry._type === types.TIME_OFF.ABSENCE || this.timesheet.readOnly) {
      return [];
    }

    if (entry.noStartTime) {
      if (_.filter(this.timesheet.entries, ({date: entry.date})).length > 1) {
        return [
          {
            text: 'time_tracking.timesheet.Delete',
            action: entry => this.deleteEntry(entry)
          },
        ];
      } else {
        return [];
      }
    }

    const links = [
      {
        text: 'time_tracking.timesheet.Delete',
        action: entry => this.deleteEntry(entry)
      },
      {
        text: `time_tracking.timesheet.${entry.hasNote ? 'Edit Note' : 'Add Note'}`,
        action: entry => this.openEditEntryNoteModal(entry)
      },
      {
        text: `time_tracking.timesheet.${entry.hasBreak ? 'Edit Unpaid Break' : 'Add Unpaid Break'}`,
        action: entry => this.openEditEntryBreakModal(entry)
      }
    ];

    if (entry.date >= this.payPeriod.startDate) {
      links.push({
        text: 'time_tracking.timesheet.Add New Entry',
        action: entry => this.addEntry(entry.date)
      });
    }

    return links;
  }

  startTimeChangedSinceApproval(entry) {
    return this.timesheet.wasEverApproved && (entry.startTime && !moment(entry.approvedStartTime).isSame(moment(entry.startTime)));
  }

  endTimeChangedSinceApproval(entry) {
    return this.timesheet.wasEverApproved && ((entry.endTime && !moment(entry.approvedEndTime).isSame(moment(entry.endTime))) || (!entry.endTime && entry.approvedEndTime));
  }

  typeNameById(id) {
    return _.find(this.types, {id}).name;
  }

  holidayNameById(id) {
    return _.find(this.timesheet.holidays, {id}).name;
  }
}

export default TimesheetEditorState;
