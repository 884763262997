import React from 'react';
import {observer} from 'mobx-react';
import EmployeeProfileContainer from '../../EmployeeProfileContainer';
import CalendarInstructions from './CalendarInstructions';
import CalendarLinks from './CalendarLinks';
import {t} from 'shared/core';
import {Panel} from 'components';

const EmployeeCalendars = observer(({uiState}) => {
  return (
    <Panel.Stack loose>
      <Panel.Stack>
        <Panel>
          <Panel.Header title={t('employees.profile.calendars.header')}/>
          <Panel.Subheader title={t('employees.profile.calendars.sub_header')}/>
        </Panel>
        <Panel>
          <CalendarLinks uiState={uiState} />
        </Panel>
      </Panel.Stack>
      <Panel>
        <CalendarInstructions uiState={uiState}/>
      </Panel>
    </Panel.Stack>
  );
});

const CalendarsContainer = observer(({uiState}) => {
  return (
    <EmployeeProfileContainer uiState={uiState}>
      <EmployeeCalendars uiState={uiState}/>
    </EmployeeProfileContainer>
  );
});

export default CalendarsContainer;
