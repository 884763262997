import React from 'react';

const sizeToProps = size => {
  return size ? `Spinner-container--${size}` : '';
};

export default ({size}) => {
  return <div className={`${size === 'Btn' ? '' : 'Container'}`}>
    <div className={`Spinner-container ${sizeToProps(size)}`}>
      <div className='Spinner'>
        <div className='Spinner-left'>
          <div className='Spinner-circle'></div>
        </div>
        <div className='Spinner-gap'>
          <div className='Spinner-circle'></div>
        </div>
        <div className='Spinner-right'>
          <div className='Spinner-circle'></div>
        </div>
      </div>
    </div>
  </div>;
};
