import {DomainStore} from 'shared/store';
import {observable, computed} from 'mobx';
import _ from 'lodash';
import {PerformanceReviewReviewerViewModel} from 'stores/performance_reviews';

class TextFieldResultState {
  store = new DomainStore();

  @observable question;
  @observable reviews;
  @observable viewReviews;

  receiveProps({question, uiState}) {
    this.question = question;
    this.reviews = uiState.currentReviews;
  }

  @computed get reviewerGroupedReviews() {
    return _.groupBy(this.reviews, 'reviewer.id');
  }

  @computed get reviewerViewModels() {
    return Object.values(this.reviewerGroupedReviews)
      .map(r => new PerformanceReviewReviewerViewModel(r, this));
  }

  reviewsForReviewer(reviewerId) {
    return _.filter(this.reviews, { reviewer: { id: reviewerId }});
  }
}

export default TextFieldResultState;
