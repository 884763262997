import {observable, action} from 'mobx';
import {successAlert} from 'shared/tools';
import {t} from 'shared/core';

class EmailTemplateEditState {
  store;
  history;
  match;

  @observable emailTemplate;
  @observable errors = {};

  constructor(store, history, match) {
    this.store = store;
    this.history = history;
    this.match = match;
  }

  @action async load() {
    this.emailTemplate = await this.store.loadEmailTemplate(this.match.params.id);
  }

  @action async updateEmailTemplate() {
    const {model, errors} = await this.store.patch(this.emailTemplate);
    this.errors = errors;

    if (model) {
      successAlert(t('company_settings.documents.alerts.Company document saved.'));
      this.goBack();
    }
  }

  @action goBack() {
    this.history.push('/email-templates');
  }
}

export default EmailTemplateEditState;
