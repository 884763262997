const PROVIDER_SUMMARIES = {
  gwl: {
    key: 'gwl',
    name: 'Canada Life',
  },
  manulife: {
    key: 'manulife',
    name: 'Manulife',
  },
  chubb: {
    key: 'chubb',
    name: 'Chubb',
  },
  claim_secure: {
    key: 'claim_secure',
    name: 'ClaimSecure',
  },
  gsc: {
    key: 'gsc',
    name: 'GSC',
  },
  ssq: {
    key: 'ssq',
    name: 'SSQ',
  },
  sunlife: {
    key: 'sunlife',
    name: 'Sun Life',
  },
  benecaid: {
    key: 'benecaid',
    name: 'Benecaid',
  },
  rbc: {
    key: 'rbc',
    name: 'RBC',
  },
  my_hsa: {
    key: 'my_hsa',
    name: 'myHSA',
  },
  empire_life: {
    key: 'empire_life',
    name: 'Empire Life',
  },
  bluecross: {
    key: 'bluecross',
    name: 'Blue Cross',
  },
  desjardins: {
    key: 'desjardins',
    name: 'Desjardins',
  },
  encon: {
    key: 'encon',
    name: 'Victor',
  },
  rwam: {
    key: 'rwam',
    name: 'RWAM',
  },
  industrial_alliance: {
    key: 'industrial_alliance',
    name: 'Industrial Alliance',
  },
  the_cooperators: {
    key: 'the_cooperators',
    name: 'The Co-operators',
  },
  equitable_life: {
    key: 'equitable_life',
    name: 'Equitable Life of Canada',
  },
  best_doctors: {
    key: 'best_doctors',
    name: 'Best Doctors',
  },
  eqcare: {
    key: 'eqcare',
    name: 'EQ Care',
  },
  wello: {
    key: 'wello',
    name: 'Wello',
  },
  allstate: {
    key: 'allstate',
    name: 'Allstate',
  },
  dialogue: {
    key: 'dialogue',
    name: 'Dialogue',
  },
  homewood: {
    key: 'homewood',
    name: 'Homewood Health',
  },
  maple: {
    key: 'maple',
    name: 'Maple',
  },
  parachute: {
    key: 'parachute',
    name: 'Parachute',
  },
  telus_health_virtual_care: {
    key: 'telus_health_virtual_care',
    name: 'TELUS Health Virtual Care',
  },
  nest_wealth: {
    key: 'nest_wealth',
    name: 'Nest Wealth',
  },
  wealthsimple: {
    key: 'wealthsimple',
    name: 'Wealthsimple',
  },
  group_source: {
    key: 'group_source',
    name: 'Group Source',
  },
  medcan: {
    key: 'medcan',
    name: 'Medcan',
  },
  bbd: {
    key: 'bbd',
    name: 'Benefits by Design',
  },
  la_capitale: {
    key: 'la_capitale',
    name: 'La Capitale',
  },
  custom_care: {
    key: 'custom_care',
    name: 'Custom Care',
  },
  group_health: {
    key: 'group_health',
    name: 'GroupHEALTH',
  },
  workplace_options: {
    key: 'workplace_options',
    name: 'Workplace Options',
  },
  sirius: {
    key: 'sirius',
    name: 'Sirius',
  },
  shepell: {
    key: 'shepell',
    name: 'Shepell',
  },
  lifeworks: {
    key: 'lifeworks',
    name: 'LifeWorks',
  },
  medavie_bluecross: {
    key: 'medavie_bluecross',
    name: 'Medavie Blue Cross',
  },
  pacific_bluecross: {
    key: 'pacific_bluecross',
    name: 'Pacific Blue Cross',
  },
  saskatchewan_bluecross: {
    key: 'saskatchewan_bluecross',
    name: 'Saskatchewan Blue Cross',
  },
  alberta_bluecross: {
    key: 'alberta_bluecross',
    name: 'Alberta Blue Cross',
  },
  people_corporation: {
    key: 'people_corporation',
    name: 'People Corporation',
  },
  beneplan: {
    key: 'beneplan',
    name: 'Beneplan',
  }
};

export default PROVIDER_SUMMARIES;
