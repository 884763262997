import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {endpoints, types, t} from 'shared/core';
import {successAlert} from 'shared/tools';
import {Company} from 'stores/company';

class AuthenticationsContainerState {
  store = new DomainStore();

  @observable company;

  @action async load() {
    await this.store._compose([
      endpoints.CURRENT_COMPANY
    ]);

    this.company = new Company(
      this.store._getSingle(types.COMPANY)
    );
  }

  @action async toggleMfaEnabled(mfaEnabled) {
    this.company.merge(mfaEnabled);
    const {model, errors} = await this.store.patch(
      endpoints.COMPANY.with(this.company.id),
      types.COMPANY,
      this.company
    );

    this.errors = errors;

    if (model) {
      this.company.update(model);
      successAlert(t('company_settings.authentications.Multi-step authentication settings updated.'));
    }
  }
}

export default AuthenticationsContainerState;
