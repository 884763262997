import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';

const TaskHiddenFields = ({task, index}) => {
  const {
    id,
    name,
    assigneeType,
    relativeDueDateValue,
    relativeDueDateUnit,
    description,
    templateType
  } = task;
   return (
    <div>
      {id !== null && <input type='hidden' name={`employee[tasks][${index}][id]`} value={id} />}
      {name !== null && <input type='hidden' name={`employee[tasks][${index}][name]`} value={name} />}
      {assigneeType !== null && <input type='hidden' name={`employee[tasks][${index}][assignee_type]`} value={assigneeType} />}
      {task.assigneeEmployeeId !== null && <input type='hidden' name={`employee[tasks][${index}][assignee_employee_id]`} value={task.assigneeEmployeeId} />}
      {relativeDueDateValue !== null && <input type='hidden' name={`employee[tasks][${index}][relative_due_date_value]`} value={relativeDueDateValue} />}
      {relativeDueDateUnit !== null && <input type='hidden' name={`employee[tasks][${index}][relative_due_date_unit]`} value={relativeDueDateUnit} />}
      {description !== null && <input type='hidden' name={`employee[tasks][${index}][description]`} value={description} />}
      {templateType !== null && <input type='hidden' name={`employee[tasks][${index}][template_type]`} value={templateType}/>}
    </div>
  );
};

const HiddenFields = ({taskTemplates}) => {
  if (_.isEmpty(taskTemplates)) return null;

  return (
    <div>
      {taskTemplates.map((taskTemplate, i) => <TaskHiddenFields task={taskTemplate} key={i} index={i} />)}
    </div>
  );
};

export default observer(HiddenFields);
