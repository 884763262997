import React from 'react';
import {t} from 'shared/core';
import {Display, Panel, Layout} from 'components';
import {FormattedMessage} from 'react-intl';
import OfferExpiredImage from 'containers/job_offer/img/offer-expired-image@1x.png';

const JobOfferExpired = ({companyName, logoUrl}) => {
  return (
    <div>
      <Panel className='offer-header'>
        <div className='center'>
          <Display
            documentTitle={t('job_offer.expired.Job offer unavailable')}
          />
          <div className='h1 medium py1'>
            {logoUrl ? <img alt='company logo' className='align-top CompanyLogo-img' src={logoUrl}/> :
                companyName}
          </div>
        </div>
      </Panel>
      <Layout>
        <div className='mt4 mb3 center' style={{fontSize: '44px'}}>
          <FormattedMessage id='job_offer.expired.This offer has expired'/>
        </div>
        <div className='center mb4'><FormattedMessage id='job_offer.expired.Check back with your hiring manager to learn more about the status of your offer'/></div>
        <div className='center mt1'><img src={OfferExpiredImage} alt='expired' className='full-width' style={{maxWidth: '567px'}}/></div>
      </Layout>
    </div>
  );
};

export default JobOfferExpired;
