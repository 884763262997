import FilterRowViewModel from './FilterRowViewModel';
import _ from 'lodash';
import {action, computed, observable} from 'mobx';
import {calendarDate} from 'shared/tools';
import DateInequalityFilterRow from 'stores/reports/DateInequalityFilterRow';

class DateFilterRowViewModel extends FilterRowViewModel {
  mapItem;

  @observable operatorOpen = false;
  @observable dateOpen = false;

  constructor({define}) {
    super(define);
  }

  createRow(row) {
    return new DateInequalityFilterRow(row);
  }

  @action closeDropdowns() {
    this.closeOperator();
    this.closeDate();
  }

  @action openOperator() {
    this.closeDate();
    this.operatorOpen = true;
  }

  @action closeOperator() {
    this.operatorOpen = false;
    this.resetRow();
  }

  @action openDate() {
    this.closeOperator();
    this.dateOpen = true;
  }

  @action closeDate() {
    this.dateOpen = false;
    this.resetRow();
  }

  @action updateDate(date) {
    if (!_.get(this.row, 'value')) {
      this.row.updateValue(date);
    }

    this.editingRow.updateValue(date);
  }

  @action updateDateRange(startDate, endDate) {
    if (!_.get(this.row.range, 'lower_bound')) {
      this.row.range = {
        lower_bound: startDate,
        upper_bound: endDate
      };
    }

    this.editingRow.range = {
      lower_bound: startDate,
      upper_bound: endDate
    };
  }

  @action operatorView(operator) {
    switch (operator) {
      case '<=':
        return 'before';
      case '>=':
        return 'after';
      default:
        return 'between';
    }
  }

  @computed get isRangeOperator() {
    return this.editingRow.operator === 'between';
  }

  @computed get dateView() {
    return [calendarDate(this.editingRow.value)];
  }

  @computed get dateRangeView() {
    return [
      calendarDate(this.editingRow.range.lower_bound),
      calendarDate(this.editingRow.range.upper_bound)
    ];
  }
}

export default DateFilterRowViewModel;
