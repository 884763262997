import React from 'react';
import {FormattedMessage} from 'react-intl';
import {ActionLink} from 'components';
import BackIcon from 'img/back-arrow@2x.png';

const CandidateSubnav = ({history}) => {
  return (
    <div>
      <div className='Container'>
        <div className='h1 py3 my2 black'>
          <FormattedMessage id='recruiting.candidate.Add a Candidate'/>
        </div>
      </div>
      <div className='clearfix mb3 py2 border-bottom border-top'>
        <div className='Container'>
          <ul className='Breadcrumb'>
            <li>
              <ActionLink onClick={() => history.goBack()} className='HoverAnimation-trigger--image'>
                <img className='mr1' src={BackIcon} alt='back' height='24px'/>
                <span className='align-middle'>
                  <FormattedMessage id='recruiting.candidate.Recruiting'/>
                </span>
              </ActionLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CandidateSubnav;
