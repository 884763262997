import React from 'react';
import {withState} from 'shared/core';
import {JobOfferContainerState} from '../state';
import JobOfferExpired from './JobOfferExpired';
import JobOfferAccepted from './JobOfferAccepted';
import {Offer} from 'components';
import {observer} from 'mobx-react';

const JobOfferContainer = observer(({uiState}) => {
  const {jobOffer, displayAnimation, errors} = uiState;

  if (jobOffer.isAccepted) return <JobOfferAccepted companyName={jobOffer.companyName} logoUrl={jobOffer.logoUrl} displayAnimation={displayAnimation}/>;
  if (!jobOffer.acceptable) return <JobOfferExpired companyName={jobOffer.companyName} logoUrl={jobOffer.logoUrl}/>;

  return (
    <Offer
      jobOffer={jobOffer}
      logoUrl={jobOffer.logoUrl}
      onAcceptOffer={() => uiState.acceptOffer()}
      errors={errors}
    />
  );
});

export default withState(JobOfferContainer, JobOfferContainerState);
