import React from 'react';
import {observer} from 'mobx-react';
import {auth} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {Button} from 'components';

const AssignDocumentButton = observer(({uiState}) => {
  const {parentState} = uiState;
  const {employee} = parentState;

  if (!auth.hasAccess('::MANAGE_DOCUMENTS') || employee.terminated) return null;

  return (
    <div className='table-cell align-middle ml2'>
      <Button size='sm' onClick={() => uiState.openAssignDocumentModal()} disabled={uiState.isUpdating}>
        <FormattedMessage id='employees.profile.documents.Assign Document'/>
      </Button>
    </div>
  );
});

export default AssignDocumentButton;
