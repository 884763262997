import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Modal} from 'components/modals';
import {FormLayout, Input, Button} from 'components';
import {observer} from 'mobx-react';
import {ModalButtons} from 'components/modals';

const EditGroupModal = observer(({uiState}) => {
  const {editGroupModalOpen, newCustomFieldGroup, errors} = uiState;
  if (!editGroupModalOpen) return null;
  const header = newCustomFieldGroup.isNew ? 'company_settings.custom_fields.Add a Custom Field Group' : 'company_settings.custom_fields.Edit Custom Field Group';

  return (
    <Modal isOpen={editGroupModalOpen}
           onHide={() => uiState.closeEditGroupModal()}
           size='md'
           title={header}>
      <FormLayout>
        <Input label='company_settings.custom_fields.Name'
          value={newCustomFieldGroup.name}
          onChange={e => newCustomFieldGroup.name = e.target.value}
          placeholder='company_settings.custom_fields.e.g. Education'
          errorMessage={errors.name}/>
      </FormLayout>

      <div className='flex justify-content-between'>
        <div className='mt3'>
          {newCustomFieldGroup.canDelete && <Button onClick={() => uiState.openDeleteGroupModal()} className='mr2' trait='destructive'>
            <FormattedMessage id='company_settings.custom_fields.Remove'/>
          </Button>}
        </div>
        <ModalButtons onCancel={() => uiState.closeEditGroupModal()} onSave={() => uiState.saveGroup()}/>
      </div>
    </Modal>
  );
});

export default EditGroupModal;
