import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import FilterComponent from './FilterComponent';

const EmploymentType = observer(({filter, filterKey, filterUpdated, filterDeleted, Wrapper}) => {
  const options = [
    { label: t('components.tables.fancy_filters.selectors.Full Time'), value: 'full_time' },
    { label: t('components.tables.fancy_filters.selectors.Part Time'), value: 'part_time' },
    { label: t('components.tables.fancy_filters.selectors.Temp'), value: 'temp' },
    { label: t('components.tables.fancy_filters.selectors.Intern'), value: 'intern' },
    { label: t('components.tables.fancy_filters.selectors.Volunteer'), value: 'volunteer' },
    { label: t('components.tables.fancy_filters.selectors.Contractor'), value: 'contractor' },
    { label: t('components.tables.fancy_filters.selectors.Seasonal'), value: 'seasonal' },
    { label: t('components.tables.fancy_filters.selectors.Casual'), value: 'casual' }
  ];

  return <FilterComponent filter={filter} filterKey={filterKey} options={options} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
});

export default EmploymentType;
