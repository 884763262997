import React from 'react';
import {observer} from 'mobx-react';
import {LocaleSelect} from 'components';

const LoginLocaleSelector = observer(({uiState}) => {
  return (
    <div className='absolute py2 pr3 right-0 top-0 z-index-1'>
      <LocaleSelect />
    </div>
  );
});

export default LoginLocaleSelector;
