import React from 'react';
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {withState} from 'shared/core';
import TimeTrackingPageState from 'containers/time_tracking/state/TimeTrackingPageState';
import {ManageContainer} from 'containers/time_tracking/components/manage';
import {ViewPayScheduleContainer} from 'containers/time_tracking/components/manage/components/pay_schedules/components/view';
import {PolicyEditFlow} from 'containers/time_tracking/components/manage/components/policies/components/edit';
import MyTimesheetContainer from 'containers/time_tracking/components/my_timesheet/components/MyTimesheetContainer';
import OnboardingContainer from 'containers/time_tracking/components/learn_more/components/OnboardingContainer';
import TimesheetsContainer from 'containers/time_tracking/components/timesheets/components/TimesheetsContainer';
import PayrollHoursContainer from 'containers/time_tracking/components/payroll_hours/components/PayrollHoursContainer';


const TimeTrackingPage = observer(({uiState}) => {
  const {scope} = uiState;

  return (
    <Router basename='/time_tracking'>
      <Switch>
        {scope.learnMore && <Route path='/learn_more' render={() => <OnboardingContainer scope={scope}/>}/>}
        {scope.learnMore && <Redirect from='*' to='/learn_more'/>}
        {scope.myTimesheet && <Route exact path='/' render={() => <MyTimesheetContainer scope={scope}/>}/>}
        {scope.timesheets && <Route path='/timesheets' render={() => <TimesheetsContainer scope={scope}/>}/>}
        {scope.payrollHours && <Route path='/payroll_hours' render={() => <PayrollHoursContainer scope={scope}/>}/>}
        {scope.manage && <Route path='/manage/policies/:id/edit' component={PolicyEditFlow}/>}
        {scope.manage && <Route path='/manage/pay_schedules/:id' component={ViewPayScheduleContainer}/>}
        {scope.manage && <Route path='/manage' render={({match, history}) => <ManageContainer history={history} match={match} scope={scope}/>}/>}
        {!scope.myTimesheet && scope.timesheets && <Redirect exact from='/' to='/timesheets'/>}
        {!scope.myTimesheet && !scope.timesheets && scope.manage && <Redirect exact from='/' to='/manage'/>}
      </Switch>
    </Router>
  );
});

export default withState(TimeTrackingPage, TimeTrackingPageState);
