import {action} from 'mobx';
import {DomainStore} from 'shared/store';
import {successAlert} from 'shared/tools';
import {t} from 'shared/core';
import _ from 'lodash';

class TasksDirectoryState {
  store = new DomainStore();
  history;
  config;

  receiveProps({history, config}) {
    this.history = history;
    this.config = config;
  }

  @action async removeTaskTemplate(taskTemplate) {
    await this.store.destroy(taskTemplate);
    successAlert(t('company_settings.tasks.alerts.Task template deleted.'));
  }

  @action goToAddTaskTemplate() {
    this.history.push(`/tasks/edit/${this.config.templateType}`);
  }
}

export default TasksDirectoryState;
