import React from 'react';
import {observer} from 'mobx-react';
import {EmployeeNameWithAvatar} from 'components';
import {FormattedMessage} from 'react-intl';
import RevieweeActionButton from './RevieweeActionButton';
import CompletedIcon from 'img/list-element-complete@2x.png';
import IncompleteIcon from 'img/list-element-incomplete-default@2x.png';

const Checkmark = observer(({checked}) => {
  return (
    <div className='flex justify-content-center'>
      <img src={checked ? CompletedIcon : IncompleteIcon} width='18' alt='complete'/>
    </div>
  );
});

const EmployeeReviewsStatus = observer(({model}) => {
  if (!model.totalEmployeeReviews) return <FormattedMessage id='performance_reviews.view.No Employee Reviews'/>;

  return (
    <div className='flex justify-content-center align-items-middle jumbo h5'>
      <div className='mr1'>
        <Checkmark checked={model.completedEmployeeReviews === model.totalEmployeeReviews}/>
      </div>
      <FormattedMessage id='performance_reviews.view.REVIEWS_SUBMITTED' values={{submitted: model.completedEmployeeReviews, total: model.totalEmployeeReviews}}/>
    </div>
  );
});

export const getMyTeamColumns = (cycle) => {
  return [
    {
      header: 'performance_reviews.view.Reviewee',
      width: 3,
      render: model => <EmployeeNameWithAvatar employee={model.employee}/>
    },
    {
      header: 'performance_reviews.view.Employee Reviews',
      width: 2,
      component: EmployeeReviewsStatus,
      className: 'center'
    },
    {
      header: cycle.managerType && 'performance_reviews.view.Manager Review Submitted',
      width: 2,
      render: model => cycle.managerType && <Checkmark checked={model.managerReview.submitted}/>,
      className: 'center'
    },
    {
      header: cycle.managerType && 'performance_reviews.view.Manager Review Shared',
      width: 2,
      render: model => cycle.managerType && <Checkmark checked={model.sharedAt}/>,
      className: 'center'
    },
    {
      width: 2,
      className: 'right-align',
      component: RevieweeActionButton
    }
  ];
};

export const MY_REVIEWS_COLUMNS = [
  {
    header: 'performance_reviews.view.Reviewee',
    width: 4,
    render: model => <EmployeeNameWithAvatar employee={model.reviewee.employee}/>
  },
  {
    header: 'performance_reviews.view.Status',
    width: 3,
    render: model => <FormattedMessage id={model.submitted ? 'performance_reviews.view.Submitted' : 'performance_reviews.view.In Progress'}/>
  },
  {
    header: 'performance_reviews.view.Review Type',
    width: 5,
    render: model => <FormattedMessage id={`models.performance_reviews.type.${model.type.kind}`}/>
  }
];
