import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Button} from 'components';
import {AlertModal} from 'components/modals';

const InterestedInPayrollModal = observer(({uiState}) => {
  return (
    <AlertModal
      size='sm'
      header='payroll.not_enabled.Great!'
      body='payroll.not_enabled.Our payroll team has been notified and will contact you shortly.'
      isOpen={uiState.interestedInPayrollModalOpen}
      onHide={() => uiState.closeInterestedInPayrollModal()}
    >
      <Button className='right' onClick={() => uiState.closeInterestedInPayrollModal()}>
        <FormattedMessage id='payroll.not_enabled.Got it!'/>
      </Button>
    </AlertModal>
  );
});

export default InterestedInPayrollModal;
