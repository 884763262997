import React from 'react';
import {observable} from 'mobx';
import {RadioButton, RadioButtonGroup} from 'components';
import {observer} from 'mobx-react';
import StyleGuideTitle from './StyleGuideTitle';
import StyleGuideExample from './StyleGuideExample';
import StyleGuideParameterRows from './StyleGuideParameterRows';

const RADIOBUTTONGROUP_PARAMS = [
  { name: 'children*', type: 'node', description: 'Group of radiobuttons pertaining to a single field', values: '<RadioButton>' },
  { name: 'onChange*', type: 'function', description: 'Callback to handle when value', values: 'Returns {name, value}' },
  { name: 'name*', type: 'string', description: 'Unique name that represents which field the radiobutton group belongs to', values: 'Ex. employeeName' },
  { name: 'selectedValue', type: 'string, integer, boolean', description: 'Value that is currently selected', values: 'Ex. "Daniel Hwang", 0, true' }
];


const RADIOBUTTON_PARAMS = [
  { name: 'className', type: 'string', description: 'Class names to style radiobuttons', values: 'Ex. absolute' },
  { name: 'children', type: 'node', description: 'Name of the value it is representing', values: '<FormattedMessage>' },
  { name: 'disabled', type: 'boolean', description: 'Disable interactions for a radiobutton', values: '[true, false]' },
  { name: 'value', type: 'string, integer, boolean', description: 'Value pertaining to a radiobutton', values: 'Ex. "Daniel Hwang", 0, true' }
];

const StyleGuideRadiobutton = observer(({uiState}) => {
  return (
    <div>
      <StyleGuideTitle title='Radiobutton' description='Radiobutton are used to allow users to make a single choice from a list of values. A group of radio buttons must be wrapped by a RadioButtonGroup parent component.'/>

      <div className='bg-white box-shadow-1 rounded p4 my3'>
        <StyleGuideExample title='Default Checkbox' description='This is the basic usage of a checkbox.'>
          <RadioButtonGroup selectedValue={uiState.value} name='example' onChange={(a, b) => uiState.value = b}>
            <RadioButton className='block mb1' value='0'>{'Daniel Hwang'}</RadioButton>
            <RadioButton className='block mb1' value='1'>{'Grigory Vorobyev'}</RadioButton>
            <RadioButton className='block mb1' value='2'>{'Martin Sienawski'}</RadioButton>
          </RadioButtonGroup>
        </StyleGuideExample>

        <StyleGuideExample title='Disabled Checkbox' description='Radiobuttons that are disabled and cannot be actioned upon.'>
          <RadioButtonGroup selectedValue='0' name='example2' onChange={(a, b) => uiState.value = b}>
            <RadioButton className='block mb1' value='0' disabled={true}>{'Daniel Hwang'}</RadioButton>
            <RadioButton className='block mb1' value='1' disabled={true}>{'Grigory Vorobyev'}</RadioButton>
            <RadioButton className='block mb1' value='2' disabled={true}>{'Martin Sienawski'}</RadioButton>
          </RadioButtonGroup>
        </StyleGuideExample>

        <div className='mb4'>
          <div className='h2 medium mb2'>{'RadioButtonGroup Parameters'}</div>
          <div className='clearfix mb1'>
            <div className='col col-3 pr1 h5 caps'>{'Name'}</div>
            <div className='col col-2 pr1 h5 caps'>{'Type'}</div>
            <div className='col col-4 pr1 h5 caps'>{'Description'}</div>
            <div className='col col-3 pl1 h5 caps'>{'Values'}</div>
          </div>
          <ul className='StyleGuide-params list-reset'>
            {RADIOBUTTONGROUP_PARAMS.map((p, i) => <StyleGuideParameterRows key={i} index={i} {...p}/>)}
          </ul>
        </div>

        <div className='mb3'>
          <div className='h2 medium mb2'>{'RadioButton Parameters'}</div>
          <div className='clearfix mb1'>
            <div className='col col-3 pr1 h5 caps'>{'Name'}</div>
            <div className='col col-2 pr1 h5 caps'>{'Type'}</div>
            <div className='col col-4 pr1 h5 caps'>{'Description'}</div>
            <div className='col col-3 pl1 h5 caps'>{'Values'}</div>
          </div>
          <ul className='StyleGuide-params list-reset'>
            {RADIOBUTTON_PARAMS.map((p, i) => <StyleGuideParameterRows key={i} index={i} {...p}/>)}
          </ul>
        </div>
      </div>
    </div>
  );
});

class ExampleState {
  @observable value = '0';
}

export default () => {
  const uiState = new ExampleState();
  return <StyleGuideRadiobutton uiState={uiState}/>;
};
