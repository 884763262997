import React from 'react';
import _ from 'lodash';
import Select2 from './Select2';

const DaySelect = ({date, selectedDay = 1, onChange}) => {
  return (
    <Select2 value={selectedDay} onChange={onChange} searchable={true} dropdownClass='select2-animation' placeholder='day'>
      {_.times(date.daysInMonth(), i => <option value={i + 1} key={i + 1}>{i + 1}</option>)}
    </Select2>
  );
};

export default DaySelect;
