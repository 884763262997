import {observable, action} from 'mobx';
import {t, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import {successAlert} from 'shared/tools';

class AdminContainerState {
  agent;
  match;
  history;
  store = new DomainStore();

  @observable cycle;
  @observable updateDueDateModalOpen = false;
  @observable dueDate;
  @observable reviewee;
  @observable errors = {};

  @observable selectedReviewee = {};
  @observable reviewerSelectionModalOpen = false;
  @observable addRevieweeModalOpen = false;

  receiveProps({match, history, scope, cycle}) {
    this.match = match;
    this.history = history;
    this.scope = scope;
    this.cycle = cycle;
  }

  @action openUpdateDueDateModal() {
    this.dueDate = this.cycle.dueDate;
    this.updateDueDateModalOpen = true;
  }

  @action closeUpdateDueDateModal() {
    this.dueDate = null;
    this.updateDueDateModalOpen = false;
  }

  @action setDueDate(date) {
    this.dueDate = date;
  }

  @action openReviewerSelectionModal(reviewee) {
    this.selectedReviewee = reviewee;
    this.reviewerSelectionModalOpen = true;
  }

  @action closeReviewerSelectionModal() {
    this.reviewerSelectionModalOpen = false;
  }

  @action onReviewersSelected() {
    this.interactiveAgent.refresh();
    this.closeReviewerSelectionModal();
  }

  @action openAddRevieweeModal() {
    this.addRevieweeModalOpen = true;
  }

  @action closeAddRevieweeModal() {
    this.addRevieweeModalOpen = false;
  }

  @action onRevieweeAdded() {
    this.interactiveAgent.refresh();
    this.closeAddRevieweeModal();
  }

  setInteractiveAgent(interactiveAgent) {
    this.interactiveAgent = interactiveAgent;
  }

  @action async endCycle() {
    const {model} = await this.store.post(
      this.cycle.link('end'),
      types.PERFORMANCE_REVIEWS.CYCLE,
      this.cycle
    );

    if (model) {
      this.cycle.merge(model);
      this.closeEndCycleModal();
    }
  }

  @action async updateDueDate() {
    const {model, errors} = await this.store.post(
      this.cycle.link('updateDueDate'),
      types.PERFORMANCE_REVIEWS.CYCLE,
      {...this.cycle, dueDate: this.dueDate}
    );

    this.errors = errors;
    if (model) {
      this.cycle.merge(model);
      this.closeUpdateDueDateModal();
    }
  }

  @action async removeReviewee(reviewee) {
    await this.store.destroy(reviewee);
  }

  @action async shareWithReviewee(reviewee) {
    const review = await this.store.post(
      reviewee.link('share'),
      types.PERFORMANCE_REVIEWS.REVIEWEE
    );

    if (review) {
      this.interactiveAgent.refresh();
      this.closeShareWithRevieweeModal();
      successAlert(t('performance_reviews.view.alerts.Review shared.'));
    }
  }

  @action async unshareReviewee(reviewee) {
    const review = await this.store.post(
      reviewee.link('unshare'),
      types.PERFORMANCE_REVIEWS.REVIEWEE
    );

    if (review) {
      this.interactiveAgent.refresh();
      this.closeUnshareRevieweeModal();
      successAlert(t('performance_reviews.view.alerts.Review unshared.'));
    }
  }

  customLinksFor(reviewee) {
    const links = [];

    if (reviewee.hasLink('share')) {
      links.push(
        {
          text: 'performance_reviews.view.Share',
          action: reviewee => this.openShareWithRevieweeModal(reviewee)
        }
      );
    }

    if (reviewee.hasLink('unshare')) {
      links.push(
        {
          text: 'performance_reviews.view.Unshare',
          action: reviewee => this.openUnshareRevieweeModal(reviewee)
        }
      );
    }

    return links;
  }
}

export default AdminContainerState;
