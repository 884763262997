import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {GoalPageState} from './state';
import {
  MyGoalsContainer,
  EditGoalContainer,
  ViewGoalContainer
} from './components';
import { CompanyGoals } from './components/company_goals';

const GoalPage = observer(({uiState}) => {
  const {scope} = uiState;

  return (
    <Router basename='/goals'>
      <Switch>
        {scope.overview &&
          <Route exact path='/' render={({match, history}) => <CompanyGoals {...{match, history, scope}}/>}/>}
        {scope.myGoals &&
          <Route path='/my-goals' render={({match, history}) => <MyGoalsContainer {...{match, history, scope}}/>}/>}
        <Route exact path='/new' component={EditGoalContainer}/>
        <Route exact path='/edit/:goalId' component={EditGoalContainer}/>
        <Route path='/view/:goalId' component={ViewGoalContainer}/>
        {scope.myGoals && <Redirect exact from='/' to='/my-goals'/>}
      </Switch>
    </Router>
  );
});

export default withState(GoalPage, GoalPageState);
