import {observable, action, computed} from 'mobx';
import featurePermissions from 'stores/users/featurePermissions';
import FeaturePermissionViewModel from './FeaturePermissionViewModel';
import {endpoints} from 'shared/core';
import _ from 'lodash';

class RoleDetailState {
  store;
  history;
  allFeaturePermissionsDisabled;

  @observable role;
  @observable errors = {};
  @observable selectAllFeaturePermissions = false;
  @observable availableUsers = [];
  @observable featurePermissions = [];
  @observable locations = [];
  @observable departments = [];

  constructor(store, history) {
    this.store = store;
    this.history = history;
  }

  @action init() {
    this.availableUsers = this.store.getUsers();
    this.featurePermissions = featurePermissions.map(f => new FeaturePermissionViewModel(f));

    for (const f of this.featurePermissions) {
      const existing = _.find(this.role.featurePermissions, {name: f.featurePermission.name});
      if (existing) {
        const hardcoded = f.featurePermission;
        f.featurePermission = existing;
        f.featurePermission.merge(hardcoded);
      } else {
        f.featurePermission.enabled = false;
      }
    }

    this.locations = this.store.getLocations();
    this.departments = this.store.getDepartments();
  }

  @action async load(roleId) {
    this.role = await this.store.loadSingle(roleId);

    await this.store._compose([
      endpoints.LOCATIONS.ALL,
      endpoints.DEPARTMENTS
    ]);

    this.init();

    this.allFeaturePermissionsDisabled = _.every(this.role.featurePermissions, { 'enabled': false });
  }

  @action async saveRole() {
    const {model, errors} = await this.store.patch(this.role);
    this.errors = errors;
    if (model) {
      this.role.update(model);
      this.history.push('/permissions');
    }
  }

  @action toggleReports() {
    const checked = this.reportsChecked;
    for (const viewModel of this.featurePermissions) {
      if (viewModel.isReportPermission) {
        viewModel.checked = !checked;
      }
    }
  }

  @action updateScope(value) {
    this.clearFilters();
    this.role.merge({scope: value});
  }

  @action clearFilters() {
    this.role.locations = [];
    this.role.departments = [];
  }

  @computed get showPermissions() {
    return !this.role.isAdmin;
  }

  @computed get showAssignees() {
    return this.role.isAdmin || this.role.isCustom;
  }

  @computed get showGeneralInfo() {
    return this.role.isCustom;
  }

  @computed get flatPermissions() {
    return _.reject(this.featurePermissions, viewModel => viewModel.featurePermission.group);
  }

  @computed get reportPermissions() {
    return _.chain(this.featurePermissions)
      .filter(viewModel => viewModel.featurePermission.group === 'reports')
      .groupBy(viewModel => viewModel.featurePermission.section)
      .value();
  }

  @computed get reportsChecked() {
    return _.some(this.featurePermissions, viewModel => viewModel.isReportPermission && viewModel.checked);
  }

  @computed get featurePermissionsLocked() {
    return this.role.isManager && this.allFeaturePermissionsDisabled;
  }

  @computed get scopeOptions() {
    let options = ['all_employees', 'direct_reports', 'direct_and_indirect_reports'];
    if (this.role.isCustom) {
      options.splice(
        1, 0, 'all_employees_by_location', 'all_employees_by_department', 'all_employees_by_employment_type'
      );
    }
    return options;
  }
}

export default RoleDetailState;
