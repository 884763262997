import React from 'react';
import {createRoot} from 'react-dom/client';
import i18n from 'shared/i18n';
import {IntlProvider} from 'react-intl';

const renderIfNeeded = (Component, containerId, props = {}) => {
  const domElement = document.getElementById(containerId);
  if (domElement) {
    const root = createRoot(domElement);
    root.render(
      <IntlProvider locale={i18n.locale} messages={i18n.messages}>
        <Component {...props}/>
      </IntlProvider>
    );
  }
};

export default renderIfNeeded;
