import React from 'react';
import {observer} from 'mobx-react';
import {Table} from 'components';

const COLUMNS = [
  {
    header: 'company_settings.documents.Name',
    attribute: 'name',
    width: 11
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'company_settings.documents.Remove Company Signature?',
    subHeader: 'company_settings.documents.Are you sure you want to remove this company signature?'
  },
  addModel: 'company_settings.documents.+ Add a Signature',
  emptyState: 'company_settings.documents.No signatures to display'
};

const CompanySignaturesTable = observer(({uiState}) => {
  const {signatures} = uiState;

  return (
    <Table models={signatures}
          columns={COLUMNS}
          localization={LOCALIZATION}
          onRemove={(signature) => uiState.removeSignature(signature)}
          onAdd={() => uiState.openEditSignatureModal()}
          onEdit={(signature) => uiState.openEditSignatureModal(signature)} />
  );
});

export default CompanySignaturesTable;
