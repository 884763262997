import React from 'react';

const DownloadLink = ({href, title}) => {
  return (
    <a download target='_blank' href={href} className='TextLink dodger'>
      <i className='material-icons h3 align-top pr1'>{'cloud_download'}</i>
      {title}
    </a>
  );
};

export default DownloadLink;
