import React from 'react';
import {ProgressIndicator} from 'components';
import {observer} from 'mobx-react';

const ProgressBar = observer(({model}) => {
  return (
    <div className='flex'>
      <div className='col-5 mr2 right-align'>{model.data.formattedCurrentValue}</div>
      <div className='col-7 pr1'>
        <ProgressIndicator items={model.data.progressBarItems}/>
      </div>
    </div>
  );
});

const Metric = observer(({model, uiState}) => {
  return (
    <div
      className={`flex align-items-middle justify-content-between ${model.data.hasLink('updateProgress') ? 'clickable' : ''}`}
      onClick={() => model.data.hasLink('updateProgress') ? uiState.toggleEditingMetric(model) : null}
    >
      <div className='col-4 medium'>
        {model.data.name}
      </div>
      <div className='flex align-items-middle col-8 md-col-6'>
        <div className='flex-grow'><ProgressBar model={model}/></div>
        {model.data.hasLink('updateProgress') && <div className={`RowChevron flex justify-content-end align-items-middle user-select-none`}>
          <i className={`material-icons jumbo row-chevron ${model.isEditing ? 'expanded' : ''}`}>{'expand_more'}</i>
        </div>}
      </div>
    </div>
  );
});

export default Metric;
