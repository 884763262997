import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import {Button} from 'components';
import {FormattedMessage} from 'react-intl';

const ConfirmationModal = observer(({uiState}) => {
  return (
    <Modal
      isOpen={uiState.confirmationModalOpen}
      onHide={() => uiState.redirectBack()}
      closeButton={false}
      size='md'
      title='benefits.benefit_info.edit.review.confirmation_modal.Change request submitted successfully.'
    >
      <div className='pt1'>
        <FormattedMessage id='benefits.benefit_info.edit.review.confirmation_modal.submitting_change_request_explanation_1'/>
      </div>
      <div className='pt1'>
        <FormattedMessage id='benefits.benefit_info.edit.review.confirmation_modal.submitting_change_request_explanation_2'/>
      </div>
      <div className='pt1 right-align'>
        <Button trait='default' onClick={() => uiState.redirectBack()} staysDisabledAfterClick>
          <FormattedMessage id='benefits.benefit_info.edit.review.confirmation_modal.Close'/>
        </Button>
      </div>
    </Modal>
  );
});

export default ConfirmationModal;
