const reasonsForChange = [
  'child_birth',
  'marriage',
  'divorce',
  'cohabitation',
  'student_status',
  'other'
];

export default reasonsForChange;
