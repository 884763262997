import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Button, ProgressIndicator, ActionLink} from 'components';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';
import EndReviewModal from './EndReviewModal';
import UpdateDueDateModal from './UpdateDueDateModal';
import useModal from 'shared/hooks/useModal';

const ReviewInProgressSummaryPanel = observer(({uiState}) => {
  const {cycle} = uiState;
  const {
    dueDateView,
    completedRevieweesPercentage,
    completedReviewees,
    reviewees,
    startedReviewees
  } = cycle;

  const progressBarItems = [
    { label: t('performance_reviews.view.Completed'), value: completedReviewees, color: '#08BAD3' },
    { label: t('performance_reviews.view.Started'), value: startedReviewees - completedReviewees, color: '#AEE1E8' },
    { label: t('performance_reviews.view.No Progress'), value: reviewees.length - startedReviewees, color: '#EBEFF1' }
  ];

  const endCycleModalOpen = useModal(uiState, 'EndCycleModal');

  return (
    <Panel>
      <Panel.Header title={t('performance_reviews.view.Review Overview')}>
        <Button className='ml2' trait='destructive' onClick={() => uiState.openEndCycleModal()}>
          <FormattedMessage id='performance_reviews.view.End Review Cycle'/>
        </Button>
      </Panel.Header>
      <div className='clearfix col-6'>
        <div className='col col-6'>
          <div className='relative'>
            <div className='medium'>
              <FormattedMessage id='performance_reviews.view.Due Date'/>
            </div>
            <div className='absolute' style={{top: 0, left: '55px'}}>
              <ActionLink onClick={() => uiState.openUpdateDueDateModal()}>
                <i className='material-icons h3 submarine ml1'>{'edit'}</i>
              </ActionLink>
            </div>
          </div>
          <div className='waterloo'>
            {dueDateView}
          </div>
        </div>
        <div className='col col-6'>
          <div className='medium'>
            <FormattedMessage id='performance_reviews.view.Completion'/>
          </div>
          <div className='waterloo'>
            <FormattedMessage
              id='performance_reviews.view.RATIO_AND_PERCENTAGE_COMPLETION'
              values={
                {
                  completedReviewees: completedReviewees,
                  totalReviewees: reviewees.length,
                  completedRevieweesPercentage: completedRevieweesPercentage
                }
              }
            />
          </div>
        </div>
      </div>
      <div className='pt3'>
        <ProgressIndicator items={progressBarItems} displayLegend/>
      </div>
      <EndReviewModal modalOpen={endCycleModalOpen} uiState={uiState}/>
      <UpdateDueDateModal uiState={uiState}/>
    </Panel>
  );
});

export default ReviewInProgressSummaryPanel;
