import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class JobApplicantSummary extends DomainObject {
  @observable applicantCount;
  @observable jobCount;
  @observable staleApplicantCount;
}

export default JobApplicantSummary;
