import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class DocumentFolder extends DomainObject {
  @observable id;
  @observable name;
  @observable order;
}

export default DocumentFolder;

