import {useState, useEffect} from 'react';

export default function () {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof window.matchMedia === 'function') {
      const mediaQuery = window.matchMedia('(max-width: 640px)');
      setIsMobile(mediaQuery.matches);

      const handleResize = (e) => {
        setIsMobile(e.matches);
      };

      if (mediaQuery.addEventListener) {
        mediaQuery.addEventListener('change', handleResize);
      }

      return () => {
        if (mediaQuery.removeEventListener) {
          mediaQuery.removeEventListener('change', handleResize);
        }
      };
    }
  }, []);

  return isMobile;
}
