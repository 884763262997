import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';

const EnrolmentStatus = observer(({uiState, model}) => {
  const status = model.endDate ? 'pending_termination' : model.enrolmentStatus;

  return (
    <div>
      <FormattedMessage id={`benefit_enrolment.enrolment_statuses.${status}`}/>
      {model.enrolmentStatus !== 'not_enrolled' && model.externalId && <div>{`ID: ${model.externalId}`}</div>}
    </div>
  );
});

export default EnrolmentStatus;
