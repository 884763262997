import {action, observable, computed} from 'mobx';
import CompanyDocument from 'stores/documents/CompanyDocument';
import Signature from 'stores/signatures/Signature';
import {t, endpoints, types} from 'shared/core';
import {successAlert} from 'shared/tools';
import TaxDocumentAssignment from 'stores/documents/TaxDocumentAssignment';

class CompanyDocumentDirectoryState {
  interactiveAgent;
  history;
  companyDocumentStore;

  @observable employees = [];
  @observable signatures = [];
  @observable editingSignature = new Signature();
  @observable editingDocument;
  @observable archivingDocument;
  @observable originalSignatureData;
  @observable addDocumentModalOpen = false;
  @observable archiveDocumentModalOpen = false;
  @observable unarchiveDocumentModalOpen = false;
  @observable editSignatureModalOpen = false;
  @observable addAssigneeModalOpen = false;
  @observable taxDocumentsModalOpen = false;
  @observable sendFederalTaxForm = false;
  @observable sendProvincialTaxForm = false;
  @observable sendAllEmployees = false;
  @observable taxFormDueDate = null;
  @observable errors = {};
  @observable uploadMethod = false;
  @observable processing = false;

  setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  constructor(companyDocumentStore, signatureStore, history) {
    this.companyDocumentStore = companyDocumentStore;
    this.signatureStore = signatureStore;
    this.history = history;
  }

  @action async load() {
    this.employees = this.companyDocumentStore.getEmployees();
    await this.refreshSignatures();
  }

  @action openAddDocumentModal() {
    this.processing = false;
    this.addDocumentModalOpen = true;
  }

  @action closeAddDocumentModal() {
    this.addDocumentModalOpen = false;
    this.errors = {};
    this.uploadMethod = false;
  }

  @action openArchiveDocumentModal(model) {
    this.archiveDocumentModalOpen = true;
    this.archivingDocument = model;
  }

  @action closeArchiveDocumentModal() {
    this.archiveDocumentModalOpen = false;
    this.archivingDocument = null;
  }

  @action openUnarchiveDocumentModal(model) {
    this.unarchiveDocumentModalOpen = true;
    this.unarchivingDocument = model;
  }

  @action closeUnarchiveDocumentModal() {
    this.unarchiveDocumentModalOpen = false;
    this.unarchivingDocument = null;
  }

  @action async removeCompanyDocument(model) {
    await this.companyDocumentStore.destroy(model);
  }

  @action async archiveCompanyDocument() {
    const {model} = await this.companyDocumentStore.post(
      this.archivingDocument.link('archive'),
      types.COMPANY_DOCUMENT
    );

    if (model) {
      this.interactiveAgent.refresh();
      this.closeArchiveDocumentModal();
    }
  }

  @action async unarchiveCompanyDocument() {
    const {model} = await this.companyDocumentStore.post(
      this.unarchivingDocument.link('unarchive'),
      types.COMPANY_DOCUMENT
    );

    if (model) {
      this.interactiveAgent.refresh();
      this.closeUnarchiveDocumentModal();
    }
  }

  @action uploadExistingDocuments() {
    this.uploadMethod = true;
  }

  @action async createNewCompanyDocument() {
    const companyDocument = new CompanyDocument({documentType: 'rich_text_template'});
    this.processing = true;
    const {model} = await this.companyDocumentStore.postCompanyDocument(companyDocument);
    this.goToEdit(model.id);
  }

  @action goToEdit(id) {
    this.history.push(`/documents/edit/${id}`);
  }

  @action openEditSignatureModal(signature) {
    this.editSignatureModalOpen = true;

    if (signature) {
      this.editingSignature = new Signature(signature);
      this.originalSignatureData = signature.signatureData;
    } else {
      this.editingSignature = new Signature();
      this.originalSignatureData = null;
    }
  }

  @action closeEditSignatureModal() {
    this.editSignatureModalOpen = false;
    this.errors = {};
  }

  @action async removeSignature(signature) {
    await this.signatureStore.destroy(signature);
    this.signatures.remove(signature);
  }

  @action async saveSignature() {
    const {model, errors} = this.editingSignature.isNew
      ? await this.signatureStore.postSignature(this.editingSignature)
      : await this.signatureStore.patch(this.editingSignature);
    this.errors = errors;

    if (model) {
      this.refreshSignatures(model);
      this.closeEditSignatureModal();
      successAlert(t('company_settings.documents.alerts.Signature successfully saved.'));
    }
  }

  @action async sendTaxDocuments() {
    const {model, errors} = await this.companyDocumentStore.put(
      endpoints.TAX_DOCUMENTS,
      types.TAX_DOCUMENT_ASSIGNMENT,
      new TaxDocumentAssignment({
        employees: this.employees,
        sendFederalTaxForm: this.sendFederalTaxForm,
        sendProvincialTaxForm: this.sendProvincialTaxForm,
        dueDate: this.taxFormDueDate
      })
    );

    this.errors = errors;

    if (model) {
      this.closeTaxDocumentsModal();
      successAlert(t('company_settings.documents.alerts.Documents successfully sent.'));
    }
  }

  @action async refreshSignatures() {
    this.signatures = await this.signatureStore.loadSignatures();
  }

  @action openAddAssigneeModal(viewModel) {
    this.editingDocument = viewModel;
    this.addAssigneeModalOpen = true;
  }

  @action openTaxDocumentsModal() {
    this.clearTaxDocumentModal();
    this.taxDocumentsModalOpen = true;
  }

  @action closeTaxDocumentsModal() {
    this.taxDocumentsModalOpen = false;
    this.clearTaxDocumentModal();
    this.errors = {};
  }

  @action clearTaxDocumentModal() {
    this.employees = [];
    this.taxFormDueDate = null;
    this.sendFederalTaxForm = false;
    this.sendProvincialTaxForm = false;
  }

  @action closeAddAssigneeModal() {
    this.editingDocument = null;
    this.addAssigneeModalOpen = false;
  }

  @action changeSelectedEmployees(employees) {
    this.employees = employees;
  }

  @action onAssigneesAdded() {
    this.closeAddAssigneeModal();
    successAlert(t('company_settings.documents.alerts.Document successfully assigned.'));
  }

  @computed get filterOptions() {
    return [{
      id: 'active',
      display: t('company_settings.documents.Active')
    },
    {
      id: 'archived',
      display: t('company_settings.documents.Archived')
    }];
  }

  customLinksFor(model) {
    const links = [];

    if (model.hasLink('archive')) {
      links.push({
        order: 3,
        text: 'company_settings.documents.Archive',
        action: model => this.openArchiveDocumentModal(model)
      });
    }
    if (model.hasLink('unarchive')) {
      links.push({
        order: 0,
        text: 'company_settings.documents.Unarchive',
        action: model => this.openUnarchiveDocumentModal(model)
      });
    }
    if (model.hasLink('addAssignees')) {
      links.push({
        order: 2,
        text: 'company_settings.documents.Add Assignees',
        action: model => this.openAddAssigneeModal(model)
      });
    }

    links.push({
      order: 1,
      text: 'company_settings.documents.View Assignees',
      action: `/documents/:id/actions`
    });

    return links;
  }
}

export default CompanyDocumentDirectoryState;
