import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Checkbox, QuickTip, Select2} from 'components';
import {t, auth} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const BankingAndTax = observer(({uiState}) => {
  const {employee, errors} = uiState;

  return (
    <div>
      <Panel.Header
        title={auth.company.bankingEnabled || auth.company.sinEnabled ?
          t('employees.hire.collect_info.Collect Banking & Tax Information') :
          t('employees.hire.collect_info.Collect Tax Information')}
      />
      {auth.company.bankingEnabled && <div className='pb2'>
        <div className='table-cell align-top'>
          <Checkbox
            checked={employee.collectPayroll}
            onChange={e => employee.merge({collectPayroll: e.checked})}
          />
        </div>
        <div className='table-cell pl2'>
          <div className='medium'>
            <FormattedMessage id='employees.hire.collect_info.Collect Banking Info'/>
          </div>
          <div className='jumbo'>
            <FormattedMessage id='employees.hire.collect_info.COLLECT_BANKING_INFO' values={{employee: employee.name}}/>
          </div>
        </div>
      </div>}
      {auth.company.sinEnabled && <div className='pb2'>
        <div className='table-cell align-top'>
          <Checkbox
            checked={employee.collectSin}
            onChange={e => employee.merge({collectSin: e.checked})}
          />
        </div>
        <div className='table-cell pl2'>
          <div className='medium'>
            <FormattedMessage id='employees.hire.collect_info.Collect SIN'/>
          </div>
          <div className='jumbo'>
            <FormattedMessage id='employees.hire.collect_info.COLLECT_SIN' values={{employee: employee.name}}/>
          </div>
        </div>
      </div>}
      <div className='table-cell align-top'>
        <Checkbox
          checked={uiState.collectTax}
          onChange={e => employee.merge({collectTax: e.checked})}
          disabled={!uiState.isLocationInCanada}
        />
      </div>
      <div className='table-cell pl2'>
        <div className='medium'>
          <FormattedMessage id='employees.hire.collect_info.Collect Tax Info'/>
        </div>
        {uiState.isLocationInCanada && <div className='jumbo'>
          <FormattedMessage id='employees.hire.collect_info.COLLECT_TAX_INFO' values={{employee: employee.name}}/>
          <div className='mt2'>
            <Select2
              value={employee.taxLocale}
              label='company_settings.documents.Language'
              onChange={e => employee.merge({taxLocale: e.target.value})}
              disabled={!uiState.collectTax}
              errorMessage={errors.taxLocale}
            >
              {uiState.languageOptions.map(option =>
                <option value={option.id} key={option.id}>{option.name}</option>
              )}
            </Select2>
          </div>
          <div className='mt3'>
            <QuickTip trait='info'
              header={<FormattedMessage id="employees.hire.collect_info.FORMS_FILLED_AND_SIGNED"/>}
              paragraphs={[
                <ul className='mt1 ml3'>
                  {uiState.employeeTaxForms.map(form => <li key={form}>{form}</li>)}
                </ul>,
                <a href='http://support.collage.co/en/articles/6723523-how-are-td1-forms-assigned' target='_blank'>
                  <FormattedMessage id='employees.hire.collect_info.Am I sending the correct TD1 to my employee?'/>
                </a>
              ]}
            />
          </div>
          {!uiState.locationHasRegionCode && <div className='mt2'>
            <QuickTip trait='caution'
              header={<FormattedMessage id="employees.hire.collect_info.No provincial tax forms for employee"/>}
              paragraphs={[
                <FormattedMessage id="employees.hire.collect_info.NO_PROVINCIAL_TAX_FORMS" />,
                <FormattedMessage id="employees.hire.collect_info.Federal forms will still be collected." />
              ]}
            />
          </div>}
        </div>}
        {!uiState.isLocationInCanada && <div className='jumbo'>
          <FormattedMessage id='employees.hire.collect_info.OFFICE_NOT_IN_CANADA'/>
        </div>}
      </div>
    </div>
  );
});

export default BankingAndTax;
