import React from 'react';
import {observer} from 'mobx-react';
import {endpoints, t, types} from 'shared/core';
import {DropdownFilter, EmployeeNameWithAvatar, ObjectSelect2, Panel} from 'components';
import {Timesheet} from 'stores/time_tracking';
import InteractiveSelectableList from 'components/tables/interactive/components/InteractiveSelectableList';
import CompletedIcon from 'img/list-element-complete@2x.png';
import IncompleteIcon from 'img/list-element-incomplete-default@2x.png';

const EmployeeItem = observer(({model}) => {
  return (
    <div className='flex align-items-middle justify-content-between'>
      <EmployeeNameWithAvatar employee={model.employee}/>
      <img src={model.approved ? CompletedIcon : IncompleteIcon} width='18' className='mr1' alt='complete'/>
    </div>
  );
});

const COLUMNS = [
  {
    component: EmployeeItem,
    width: 12
  }
];

const EmployeeSelectorPanel = observer(({uiState}) => {
  const {DROPDOWN_FILTER_OPTIONS, paySchedules, selectedPaySchedule, selectedPayPeriod} = uiState;

  return (
    <Panel className='mb2'>
      <div className='mb1'>
        <ObjectSelect2
          label='time_tracking.payroll_hours.Pay Schedule'
          value={selectedPaySchedule}
          onChange={value => uiState.selectPaySchedule(value)}
          items={paySchedules}
        />
      </div>
      <div className='mb3'>
        <ObjectSelect2
          label='time_tracking.payroll_hours.Pay Period'
          value={selectedPayPeriod}
          onChange={value => uiState.selectPayPeriod(value)}
          items={selectedPaySchedule.sortedPayPeriods}
          display='effectiveDateView'
        />
      </div>
      <InteractiveSelectableList
        columns={COLUMNS}
        emptyStateMessage='time_tracking.timesheets.No timesheets to approve'
        onRowClicked={model => uiState.selectTimesheet(model)}
        onEmptyLoad={() => uiState.selectTimesheet(null)}
        title={t('time_tracking.timesheets.Employees')}
        onMount={agent => uiState.setInteractiveAgent(agent)}
        uiState={uiState}
        selectedModel={uiState.timesheet}
        proxy={{
          type: 'async',
          modelType: types.TIME_TRACKING.TIMESHEET,
          model: Timesheet,
          endpoint: endpoints.TIME_TRACKING.EMPLOYEES.with(selectedPayPeriod.id),
          pageSize: 10
        }}
        initialFilter={{
          show: 'assigned_to_me'
        }}
      >
        <DropdownFilter attr='show' options={DROPDOWN_FILTER_OPTIONS}/>
      </InteractiveSelectableList>
    </Panel>
  );
});

export default EmployeeSelectorPanel;
