import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, FormLayout, Input} from 'components';

const GeneralInfo = observer(({uiState}) => {
  const {employee, errors} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('benefit_enrolment.enrol.General Info')}/>
      <FormLayout>
        <FormLayout.Group>
          <Input
            label='benefit_enrolment.enrol.First Name'
            value={employee.firstName}
            onChange={e => employee.merge({firstName: e.target.value})}
            errorMessage={errors.firstName}
          />
          <Input
            label='benefit_enrolment.enrol.Last Name'
            value={employee.lastName}
            onChange={e => employee.merge({lastName: e.target.value})}
            errorMessage={errors.lastName}
          />
        </FormLayout.Group>
      </FormLayout>
    </Panel>
  );
});

export default GeneralInfo;

