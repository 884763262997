import React from 'react';
import {FormattedMessage} from 'react-intl';
import GoogleCalendarImage from 'img/google-calendar-icon@2x.png';
import AppleCalendarImage from 'img/apple-calendar-icon@2x.png';
import MicrosoftCalendarImage from 'img/microsoft-outlook-icon@2x.png';

const InstructionItem = ({translationId}) => {
  return (
    <li className='mt2'>
      <span className='block pl2'><FormattedMessage id={translationId}/></span>
    </li>
  );
};

const CalendarInstructions = ({uiState}) => {
  if (!uiState.hasCalendars) return null;

  return (
    <div>
      <div className='clearfix table mxn2 py2 border-bottom'>
        <div className='col-2 table-cell px2 align-top center sm-and-below-hide'>
          <img role='presentation' src={GoogleCalendarImage} width='80px'/>
        </div>
        <div className='col-12 sm-col-10 table-cell px2'>
          <div className='h2'><FormattedMessage id='employees.profile.calendars.Sync with Google Calendar'/></div>
          <ol className='pl2'>
            <InstructionItem translationId="employees.profile.calendars.GOOGLE_STEP_ONE"/>
            <InstructionItem translationId="employees.profile.calendars.GOOGLE_STEP_TWO"/>
            <InstructionItem translationId="employees.profile.calendars.GOOGLE_STEP_THREE"/>
          </ol>
        </div>
      </div>
      <div className='clearfix table mxn2 py2 border-bottom'>
        <div className='col-2 table-cell px2 align-top center sm-and-below-hide'>
          <img role='presentation' src={AppleCalendarImage} width='80px'/>
        </div>
        <div className='col-12 sm-col-10 table-cell px2'>
          <div className='h2'><FormattedMessage id='employees.profile.calendars.Sync with Apple Calendar'/></div>
          <ol className='pl2'>
            <InstructionItem translationId='employees.profile.calendars.Choose File > New Calendar Subscription.'/>
            <InstructionItem translationId='employees.profile.calendars.Paste the above URL and click Subscribe.'/>
            <InstructionItem translationId='employees.profile.calendars.In the next info dialog box, in the Auto-refresh dropdown menu, select Every Hour in order to keep the calendar up-to-date.'/>
          </ol>
        </div>
      </div>
      <div className='clearfix table mxn2 pt2'>
        <div className='col-2 table-cell px2 align-top center sm-and-below-hide'>
          <img role='presentation' src={MicrosoftCalendarImage} width='80px'/>
        </div>
        <div className='col-12 sm-col-10 table-cell px2'>
          <div className='h2'><FormattedMessage id='employees.profile.calendars.Sync with Microsoft Outlook'/></div>
          <div className='h3 mt3'>
            <FormattedMessage id='employees.profile.calendars.Web Application'/>
          </div>
          <ol className='pl2'>
            <InstructionItem translationId='employees.profile.calendars.OUTLOOK_WEB_STEP_ONE'/>
            <InstructionItem translationId='employees.profile.calendars.OUTLOOK_WEB_STEP_TWO'/>
            <InstructionItem translationId='employees.profile.calendars.OUTLOOK_WEB_STEP_THREE'/>
            <InstructionItem translationId='employees.profile.calendars.OUTLOOK_WEB_STEP_FOUR'/>
            <InstructionItem translationId='employees.profile.calendars.OUTLOOK_WEB_STEP_FIVE'/>
          </ol>
          <div className='h3 mt3'>
            <FormattedMessage id='employees.profile.calendars.Desktop Application'/>
          </div>
          <ol className='pl2'>
            <InstructionItem translationId='employees.profile.calendars.OUTLOOK_DESKTOP_STEP_ONE'/>
            <InstructionItem translationId='employees.profile.calendars.OUTLOOK_DESKTOP_STEP_TWO'/>
            <InstructionItem translationId='employees.profile.calendars.OUTLOOK_DESKTOP_STEP_THREE'/>
            <InstructionItem translationId='employees.profile.calendars.OUTLOOK_DESKTOP_STEP_FOUR'/>
            <InstructionItem translationId='employees.profile.calendars.OUTLOOK_DESKTOP_STEP_FIVE'/>
            <InstructionItem translationId='employees.profile.calendars.OUTLOOK_DESKTOP_STEP_SIX'/>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default CalendarInstructions;
