import React from 'react';
import {Input, SignaturePad} from 'components';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const EditSignatureModal = observer(({uiState}) => {
  const {editingSignature, originalSignatureData} = uiState;

  return (
    <Modal isOpen={uiState.editSignatureModalOpen} onHide={() => uiState.closeEditSignatureModal()} size='md' closeButton={false}>
      <form>
        <div className='h2 medium mb2'>
          {editingSignature.isNew ?
            <FormattedMessage id='company_settings.documents.Create new signature'/> :
            <FormattedMessage id='company_settings.documents.Edit Signature'/>}
        </div>
        <label className='medium mb1'>
          <FormattedMessage id='company_settings.documents.Name'/>
        </label>
        <Input placeholder='company_settings.documents.Signature Name'
               errorMessage={uiState.errors.name}
               value={editingSignature.name}
               onChange={(e) => editingSignature.merge({name: e.target.value})}/>
        <div className='mt3 mb4'>
          {originalSignatureData && <div>
            <div className='medium mb1'>
              <FormattedMessage id='company_settings.documents.Current Signature'/>
            </div>
            <div className='border' style={{width: '540px'}}>
              <img src={originalSignatureData} role='presentation' />
            </div>
          </div>}
        {editingSignature.isNew && <SignaturePad onChange={(dataURL) => editingSignature.merge({signatureData: dataURL})} />}
        </div>
        <ModalButtons saveCaption={editingSignature.isNew ? 'company_settings.documents.Save' : 'company_settings.documents.Update'}
                      onSave={() => uiState.saveSignature()}
                      onCancel={() => uiState.closeEditSignatureModal()} />
      </form>
    </Modal>
  );
});

export default EditSignatureModal;
