import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {Label} from 'components';

const DetailRow = observer(({title, value, className = 'jumbo'}) => {
  return (
    <div className='clearfix my2'>
      <div className='col col-6'>
        {title}
      </div>
      <div className={`col col-6 ${className}`}>
        <Label model={value} />
      </div>
    </div>
  );
});

const AnniversaryBonus = observer(({anniversary, index}) => {
  return (
    <div className={`${index === 0 ? '' : 'mt1'}`}>
      <FormattedMessage
        id='employees.profile.time_off.EXTRA_DAYS_PER_ANNIVERSARY'
        values={{days: anniversary.rateAdjustment, years: anniversary.anniversary}} />
    </div>
  );
});

const BalanceAdjustmentPolicy = observer(({typePolicy, policy, type, employee}) => {
  const {balanceAdjustmentPolicy} = typePolicy;

  return (
    <div>
      <div className='mb1'>
        <FormattedMessage id={balanceAdjustmentPolicy.localizationTitle}/>
      </div>
      <div>
        <FormattedMessage
          id={balanceAdjustmentPolicy.localizationDetail}
          values={{
            typeName: <span className='black medium'>{type.name}</span>,
            date: <span className='black medium'>{typePolicy.yearStartForPolicyHolder(policy, employee)}</span>,
            days: <span className='black medium'>{t('employees.profile.time_off.days', {days: balanceAdjustmentPolicy.parameter})}</span>,
            limit: <span className='black medium'>{t('employees.profile.time_off.days', {days: balanceAdjustmentPolicy.limitDays})}</span>
          }}/>
      </div>
    </div>
  );
});

const PolicySummaryModal = observer(({uiState}) => {
  const {policySummaryAccount, policy, employee} = uiState;
  if (!_.get(policySummaryAccount, 'timeOffType')) return null;
  const {currentTypePolicy} = uiState;
  const {timeOffType} = policySummaryAccount;

  return (
    <Modal isOpen={uiState.policySummaryModalOpen} onHide={() => uiState.closePolicySummaryModals()} size='md'>
      <div>
        <div className='h2 medium'>
          <FormattedMessage id='employees.profile.time_off.POLICY' values={{policy: timeOffType.name}}/>
        </div>
        <DetailRow
          title={t('employees.profile.time_off.Total Days/Year')} className='black medium'
          value={t('employees.profile.time_off.DAYS', {days: currentTypePolicy.totalDaysPerYear})}/>
        <DetailRow
          title={t('employees.profile.time_off.Accrual Rate')} className='black medium'
          value={t('employees.profile.time_off.DAYS_PER', {
            days: currentTypePolicy.accrualRate,
            frequency: t(`models.time_off.type_policy.accrual_frequency.${currentTypePolicy.accrualFrequency}`)
          })}/>
        <hr/>
        <div className='h3 medium mb2'>
          <FormattedMessage id='employees.profile.time_off.Additional Details'/>
        </div>
        <DetailRow
          title={t('employees.profile.time_off.Accrual Start Date')}
          value={currentTypePolicy.yearStartForPolicyHolder(policy, employee)}/>
        <DetailRow
          title={t('employees.profile.time_off.Accrual Frequency')}
          value={t(`models.time_off.type_policy.accrual_frequency.${currentTypePolicy.accrualFrequency}`)}/>
        <DetailRow
          title={t('employees.profile.time_off.Base Days/Year')}
          value={t('employees.profile.time_off.DAYS', {days: currentTypePolicy.baseAccrualRate})}/>
        <DetailRow
          title={t('employees.profile.time_off.Accrual Bonuses')}
          value={currentTypePolicy.sortedAnniversaryBonuses.map((a, index) => <AnniversaryBonus index={index} key={a.id} anniversary={a}/>)}/>
        <DetailRow
          title={t('employees.profile.time_off.Waiting Period')}
          value={t('employees.profile.time_off.DAYS', {days: currentTypePolicy.waitPeriod})}/>
        <DetailRow
          title={t('employees.profile.time_off.Prorated In First Year')}
          value={t(`employees.profile.time_off.${currentTypePolicy.prorationEnabled}`)}/>
        <DetailRow
          title={t('employees.profile.time_off.Carryover Rules')}
          value={
            <BalanceAdjustmentPolicy
              typePolicy={currentTypePolicy}
              policy={policy}
              type={timeOffType}
              employee={employee}
            />
          }/>
      </div>
    </Modal>
  );
});

export default PolicySummaryModal;
