import React from 'react';
import {observer} from 'mobx-react';
import {EmployeeNameWithAvatar} from 'components';
import {t} from 'shared/core';
import employeeUrl from '../employeeUrl';
import {statusTagColour} from 'employees/tools';

const EmployeeDirectoryName = observer(({model}) => {
  const employee = model;
  const tags = employee.statusTags.map(status => {
    return { 
      content: t(`employees.profile.${status}`),
      colour: statusTagColour(status)
    };
  });

  return (
    <EmployeeNameWithAvatar
      employee={employee}
      href={employeeUrl(employee)}
      tags={tags} 
    />
  );
});

export default EmployeeDirectoryName;
