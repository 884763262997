import React from 'react';
import {observer} from 'mobx-react';
import {DropdownSelect, Button} from 'components';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';
import _ from 'lodash';

const LINKS = [
  {
    key: 'createEmploymentRecord',
    text: 'employees.profile.role.Update Employment Info',
    onClick: (uiState) => uiState.createNewRecord()
  },
  {
    key: 'returnFromLeave',
    text: 'employees.profile.role.Return from Leave',
    onClick: (uiState) => uiState.createNewRecord()
  },
  {
    key: 'createOnLeaveRecord',
    text: 'employees.profile.role.Place on Leave',
    onClick: (uiState) => uiState.handleOnLeave(),
    separator: (uiState) => uiState.canTerminate || uiState.canReactivate
  },
  {
    key: 'createTerminationRecord',
    text: 'employees.profile.role.Terminate Employee',
    onClick: (uiState) => uiState.terminateEmployee()
  },
  {
    key: 'createReactivationRecord',
    text: 'employees.profile.role.Reactivate Employee',
    onClick: (uiState) => uiState.reactivateEmployee()
  }
];

const AddRecordOption = observer(({option, uiState}) => {
  const {scope} = uiState;

  if (!scope[option.key]) return null;

  return (
    <DropdownSelect.Option
      text={t(option.text)}
      onClick={() => option.onClick(uiState)}
      separator={option.separator && option.separator(uiState)}
    />
  );
});

const SingleOptionButton = observer(({uiState}) => {
  const option = _.find(LINKS, {key: uiState.validActions[0]});

  return (
    <Button onClick={() => option.onClick(uiState)}>
      <FormattedMessage id={option.text}/>
    </Button>
  );
});

const AddRecordButton = observer(({uiState}) => {
  const {validActions} = uiState;

  if (!validActions.length) return null;
  if (validActions.length === 1) return <SingleOptionButton uiState={uiState}/>;

  return (
    <div>
      <DropdownSelect selected={t('employees.profile.role.Update')}>
        {validActions.map(option =>
          <AddRecordOption key={option} option={_.find(LINKS, {key: option})} uiState={uiState} />
        )}
      </DropdownSelect>
    </div>
  );
});

export default AddRecordButton;
