import React from 'react';
import {observer} from 'mobx-react';
import {Button} from 'components';
import {FormattedMessage} from 'react-intl';

const PerformanceReviewTask = observer(({uiState}) => {
  const {task, isAssignee} = uiState;

  return (
    <div>
      <div className='mb3 jumbo'>
        <FormattedMessage id='inbox.performance_review_explanation'/>
      </div>
      {isAssignee && !task.completed && <Button onClick={() => window.location.href = `/reviews/reviews/${task.relatedTo.id}/write`}>
        <FormattedMessage id='inbox.Write Reviews'/>
      </Button>}
    </div>
  );
});

export default PerformanceReviewTask;
