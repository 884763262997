import React from 'react';
import {observer} from 'mobx-react';
import {Avatar} from 'components';

const AddedEmployee = observer(({employee}) => {
  return (
    <div className='my2'>
      <div className='clearfix bg-white p2 table box-shadow-1 rounded'>
        <div className='col-6 table-cell align-middle'>
          <div className='inline-block'>
            <Avatar employee={employee} />
          </div>
          <div className='h3 medium inline-block pl2'>{employee.name}</div>
        </div>
        <div className='col-6 table-cell align-middle'>
          <div className='h3 medium jumbo'>{employee.invitationEmail}</div>
        </div>
      </div>
    </div>
  );
});

export default AddedEmployee;
