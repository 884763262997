import React from 'react';
import {Layout, Panel, AvatarPanel} from 'components';
import {observer} from 'mobx-react';
import {ResultsState} from '../state';
import {withState} from 'shared/core';
import {ReviewReader} from '../../review_details';
import ReviewerDetails from '../../ReviewerDetails';
import {FormattedMessage} from 'react-intl';

const EmptyResults = () => {
  return (
    <Layout>
      <div className='py4 center' style={{border: '2px dashed #C9CFD1'}}>
        <div className='h1 black medium pt4 mt3'>
          <FormattedMessage id='performance_reviews.view.Your results are on the way.'/>
        </div>
        <div className='h3 jumbo pt2 pb4 mb3'>
          <FormattedMessage id="performance_reviews.view.We'll notify you when your results are in."/>
        </div>
      </div>
    </Layout>
  );
};

const ResultsContainerWithState = observer(({uiState}) => {
  const {employee, reviews, hasReviews} = uiState;

  if (!hasReviews) return <EmptyResults/>;

  return (
    <Layout>
      <Layout.Section secondary>
        <AvatarPanel employee={employee} />
      </Layout.Section>
      <Layout.Section>
        <Panel.Stack>
          {reviews.map(review =>
            <ReviewReader
              key={review.id}
              review={review}
              title={<ReviewerDetails reviewer={review.reviewer} reviewType={review.type.kind}/>}
            />
          )}
        </Panel.Stack>
      </Layout.Section>
    </Layout>
  );
});

export default withState(ResultsContainerWithState, ResultsState);
