import React from 'react';
import {FormattedMessage} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {auth} from 'shared/core';
import {observer} from 'mobx-react';
import {Display} from 'components';

const SubNav = observer(({title, toolbar}) => {
  return (
    <Display
      title={title}
      toolbar={toolbar}
    >
      {!!auth.employee.id && <NavLink exact to='/' activeClassName='active'><FormattedMessage id='surveys.subnav.My Surveys'/></NavLink>}
      {auth.hasAccess('::MANAGE_SURVEYS') && <NavLink to='/manage' activeClassName='active'><FormattedMessage id='surveys.subnav.Manage'/></NavLink>}
    </Display>
  );
});

export default SubNav;
