import _ from 'lodash';
import {browserHistory} from 'react-router';

function toQuery(filter, pagination, sorting, defaultFilter) {
  const query = {};

  if (filter) {
    for (const pair of filter.entries()) {
      if (!pair[1]) continue;
      if (_.get(defaultFilter, pair[0]) === pair[1]) continue;

      query[`f-${pair[0]}`] = pair[1];
    }
  }

  if (sorting) {
    for (const pair of sorting.entries()) {
      if (!pair[1]) continue;

      query[`s-${pair[0]}`] = pair[1];
    }
  }

  if (pagination && pagination.currentPage > 1) {
    query['p'] = pagination.currentPage;
  }

  return query;
}

function saveStateToUrl(filter, pagination, sorting, defaultFilter) {
  const location = browserHistory.getCurrentLocation();
  location.query = toQuery(filter, pagination, sorting, defaultFilter);
  browserHistory.replace(location);
}

export default saveStateToUrl;
