import React from 'react';
import Banner from './Banner';
import _ from 'lodash';
import {auth} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const BannerContent = ({currentLocation}) => {
  if (auth.hasAccess('::MANAGE_BILLING')) {
    return (
      <div className='inline'>
        <span className='pr1'>
          <FormattedMessage id='Collage is free for companies with 10 employees or less. Have more?'/>
        </span>
        <a className='white' href={`/company_settings/billing/subscription/?back=${currentLocation}`}>
          <span className='underline'>
            <FormattedMessage id='Upgrade Now'/>
          </span>
        </a>
      </div>
    );
  } else {
    return (
      <div className='inline'>
        <span className='pr1'>
          <FormattedMessage id='Collage is free for companies with 10 employees or less. Have more?'/>
        </span>
        <FormattedMessage id='Contact your administrator'/>
      </div>
    );
  }
};

const BillingBanner = ({className}) => {
  const canManageBilling = auth.hasAccess('::MANAGE_BILLING');
  const canHireEmployees = auth.hasAccess('::HIRE_EMPLOYEES');
  const companySubscriptionEnabled = auth.company.subscriptionEnabled;
  const companyHasReachedEmployeeLimit = auth.company.activeEmployeeCount >= 10;

  if (auth.company.demoAccount) return null;

  if (!canManageBilling && !canHireEmployees) return null;
  if (companySubscriptionEnabled || !companyHasReachedEmployeeLimit) return null;

  return (
    <div className={className || ''}>
      <Banner>
        <BannerContent currentLocation={window.location.pathname}/>
      </Banner>
    </div>
  );
};

export default BillingBanner;
