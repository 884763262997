import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import LoginContainerWrapper from '../../LoginContainerWrapper';
import LoginFormHeader from '../../LoginFormHeader';
import {Button, RadioButton, RadioButtonGroup} from 'components';

const MFAOptionSelection = observer(({uiState}) => {
  const {mfaUser} = uiState;

  return (
    <LoginContainerWrapper>
      <LoginFormHeader message='login.Two-factor Authentication Required'/>
      <div className='jumbo mb3'>
        <div className='mb2'>
          <FormattedMessage id='login.MFA_SETUP_DESCRIPTION' />
        </div>
        <div>
          <FormattedMessage id='login.MFA_SETUP_DESCRIPTION_2' />
        </div>
      </div>
      <RadioButtonGroup
        name='mfaType'
        selectedValue={mfaUser.mfaType}
        onChange={(name, value) => mfaUser.merge({mfaType: value})}>
        {(!uiState.companyMfaEnabled || uiState.smsEnabled) && <label className='HoverAnimation-trigger block border rounded p2'>
          <RadioButton value='sms' className='table-cell align-middle center pr1'/>
          <div className='table-cell align-middle'>
            <div className='medium'>
              <FormattedMessage id='login.Text Message'/>
            </div>
            <div>
              <FormattedMessage id='login.TEXT_MESSAGE_AUTH'/>
            </div>
          </div>
        </label>}
        {(!uiState.companyMfaEnabled || uiState.authenticatorEnabled) && <label className='HoverAnimation-trigger block border rounded p2 mt2'>
          <RadioButton value='authenticator' className='table-cell align-middle center pr1'/>
          <div className='table-cell align-middle'>
            <div className='medium'>
              <FormattedMessage id='login.Authenticator App'/>
            </div>
            <div>
              <FormattedMessage id='login.AUTHENTICATOR_AUTH'/>
            </div>
          </div>
        </label>}
        <div className='mt3 right-align'>
          <Button
            disabled={!mfaUser.mfaType}
            onClick={() => uiState.goToSetupForType()}
          >
            <FormattedMessage id='components.tables.Next'/>
          </Button>
        </div>
      </RadioButtonGroup>
    </LoginContainerWrapper>
  );
});

export default MFAOptionSelection;
