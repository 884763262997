import React from 'react';

const CustomIcon = ({icon}) => {
  return (
    <div className='table-cell align-top pr2' style={{paddingTop: '4px'}}>
      <img src={icon} height='32' width='32' role='presentation' style={{marginBottom: '1px'}} className='rounded'/>
    </div>
  );
};

export default CustomIcon;
