/* eslint-disable */
import React, { Component } from 'react';
import { observable, action, computed } from 'mobx';
import { observer } from 'mobx-react';
import P from 'prop-types';
import clamp from 'lodash/clamp';

import Pan from './pan-bg';
import ZoomScroll from './zoom';
import { NODE_WIDTH, RIGHT_MARGIN } from './constants';
import zoom, { matrixString, scale, initialMatrix } from './zoom/utils'

import Tree from './tree';
import Row from './render/row'

const MAX_ZOOM = 1.8;
const MIN_ZOOM = 0.18;

const raf = () => new Promise(res => requestAnimationFrame(res));

@observer class OrgChart extends Component {
	@observable tree = null;

	constructor(props){
		super(props);

		// register increase/decrease zoom function on parent
		if (this.props.registerUpdateZoomFn){
			this.props.registerUpdateZoomFn(this.updateZoomByDelta)
		}

		// setup tree
		this.createTree(props.rootNodeID, props.employees)
	}

	async createTree(rootNodeID, employees){
		if (!rootNodeID || !employees){
			throw new Error('Must provide both root node id and employees map')
		}

		this.setupLoopedEmployees(employees, rootNodeID)
		this.tree = new Tree(rootNodeID, employees)
		this.tree.expandTree(40)

		await raf()
		// reset transform (scale and translate)
		this.matrix = initialMatrix()

		const cW = this.chart.offsetWidth
		const cH = this.chart.offsetHeight
		const dimensions = this.tree.getTreeDimensions()

		// zoom out to try to include entire chart in view
		const w = dimensions[0] + RIGHT_MARGIN * 2
		let scale = Math.min(
			cW / w,
			cH / dimensions[1]
		)
		if (scale > 1){
			scale = 1;
		}
		scale = clamp(scale, MIN_ZOOM, MAX_ZOOM)
		this.updateChartScale(scale);

		// determine how much to translate to center tree to chart
		const x = cW / 2 - NODE_WIDTH / 2 * scale
		this.updateChartTranslate(x, 0);
	}

	setupLoopedEmployees(employees, rootNodeID) {
		const loopedEmployees = this.loopedEmployees(employees)
		employees[rootNodeID].children = employees[rootNodeID].children.concat(loopedEmployees.map(employee => employee.id))
	}

	loopedEmployees(employees) {
		const loopedEmployees = {}
		for (let employeeId in employees) {
			const employeeVisitCounts = {}
			this.detectEmployeeLoop(employeeId, employees, loopedEmployees, employeeVisitCounts)
		}

		const loopedEmployeeNodes = []
		const visitedEmployees = {}
		for (let employee of Object.values(loopedEmployees)) {
			if (!(employee.id in visitedEmployees)) {
				this.setupLoopNodes(employee, loopedEmployees, visitedEmployees, loopedEmployeeNodes, employees)
			}
		}

		return loopedEmployeeNodes
	}

	detectEmployeeLoop(employeeId, employees, loopedEmployees, employeeVisitCounts) {
		employeeVisitCounts[employeeId] = (employeeVisitCounts[employeeId] || 0) + 1

		if (employeeVisitCounts[employeeId] === 2) {
			loopedEmployees[employeeId] = employees[employeeId]
			return
		}

		for (let child of employees[employeeId].children) {
			this.detectEmployeeLoop(child, employees, loopedEmployees, employeeVisitCounts)
		}
	}

	setupLoopNodes(employee, loopedEmployees, visitedEmployees, loopedEmployeeNodes, employees) {
		loopedEmployeeNodes.push(employee)
		visitedEmployees[employee.id] = true
		for (let i = 0; i < employee.children.length; i++) {
			const child = employee.children[i]
			if (child in loopedEmployees) {
				employees[child].hasSibling = true;
				if (!(child in visitedEmployees)) {
					employees[child].drawLeftLine = true;
					this.setupLoopNodes(employees[child], loopedEmployees, visitedEmployees, loopedEmployeeNodes, employees);
				}
				employee.children.splice(i, 1);
			}
		}
	}

	componentDidUpdate(prevProps){
		if (prevProps.rootNodeID !== this.props.rootNodeID) {
			// recreate tree
			this.createTree(this.props.rootNodeID, this.props.employees)
		}
	}

	@action updateZoomByDelta = delta => {
		this.setMatrix(
			zoom(this.matrix, {
				x: this.chart.offsetWidth / 2,
				y: this.chart.offsetHeight / 2
			}, delta)
		)
	}

	// matrix + transform stuff
	@observable matrix = initialMatrix()
	@action setMatrix = matrix => {
		if (matrix.a < MIN_ZOOM || matrix.a > MAX_ZOOM){
			return
		}
		this.matrix = matrix;
	}
	@computed get transform(){
		return matrixString(this.matrix)
	}
	// zoom
	@action updateChartScale = s => {
		const m = scale(this.matrix, s)
		this.setMatrix(m);
	}
	// translate
	@action updateChartTranslate = (x, y) => {
		this.matrix.e = x
		this.matrix.f = y
	}
	@computed get translate(){
		return [this.matrix.e, this.matrix.f]
	}

	// chart element
	getChartEl = () => {
		return this.chart
	}

	render(){
		return (
			<div className="org-chart" ref={el => this.chart = el}>
				{/* pan */}
				<Pan
					getChartEl={this.getChartEl}
					translateCoords={this.translate}
					updateChartTranslate={this.updateChartTranslate}
				/>

				{/* zoom */}
				<ZoomScroll
					getChartEl={this.getChartEl}
					matrix={this.matrix}
					setMatrix={this.setMatrix}
				/>

				{/* actual chart content */}
				<div className="org-chart__main" style={{transform: this.transform}}>
					{this.tree ?
						<Row
							row={this.tree.rootRow}
							registerHighlight={this.tree.registerHighlight}
						/>
						:
						null
					}

				</div>
			</div>
		)
	}
}

OrgChart.propTypes = {
	registerUpdateZoomFn: P.func.isRequired,
	rootNodeID: P.string,
	employees: P.object,
	onChange: P.func // unused atm
}

export default OrgChart;
