import {DomainObject} from 'shared/store';
import {calendarDate} from 'shared/tools';
import {action, computed, observable} from 'mobx';

class Holiday extends DomainObject {
  @observable name;
  @observable date;
  @observable duration = 'full_day';
  @observable locationIds;
  @observable includedInTimeTracking = true;

  @action merge(other) {
    super.merge(other, {
      _dates: ['date']
    });
  }

  @computed get dateView() {
    return calendarDate(this.date);
  }
}

export default Holiday;
