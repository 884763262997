import {DomainObject} from 'shared/store';
import {observable, action} from 'mobx';
import PayrollField from 'stores/payroll/PayrollField';

class PayrollSection extends DomainObject {
  @observable name;
  @observable payrollFields;

  @action merge(other) {
    super.merge(other, {
      payrollFields: [PayrollField]
    });
  }
}

export default PayrollSection;
