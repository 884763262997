import React from 'react';

export default ({height, width, animate=true}) => {
  return (
    <div className={`LogoSpinner ${animate ? 'LogoSpinner--animate' : ''}`}>
      <svg width={width} height={height} viewBox="0 0 18 29" version="1.1">
        <title>{'Group'}</title>
        <desc>{'Created with Sketch'}</desc>
        <defs></defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Landing" transform="translate(-135.000000, -40.000000)" fill="#FFFFFF">
            <g id="Group-23" transform="translate(135.000000, 32.000000)">
              <g id="Avatar" transform="translate(0.050847, 7.500000)">
                <g id="Layer_1">
                  <g id="Group" transform="translate(0.119151, 0.500000)">
                    <path d="M8.83683766,15.7862903 C4.29687764,15.7862903 0.600528768,12.2439489 0.600528768,7.89314516 C0.600528768,3.54234143 4.29687764,0 8.83683766,0 C13.3767977,0 17.0731465,3.54234143 17.0731465,7.89314516 C17.0731465,12.2439489 13.3767977,15.7862903 8.83683766,15.7862903 Z M8.83683766,2.72324146 C5.86199833,2.72324146 3.44216634,5.04225176 3.44216634,7.89314516 C3.44216634,10.7440386 5.86199833,13.0630489 8.83683766,13.0630489 C11.811677,13.0630489 14.231509,10.7440386 14.231509,7.89314516 C14.231509,5.04225176 11.811677,2.72324146 8.83683766,2.72324146 Z" className='LogoSpinner-head'></path>
                    <path d="M8.83345572,22.2294609 L2.56407336,28.237631 C2.13131954,28.6523543 1.66527695,28.5034792 0.96621308,27.8441756 C0.278245459,27.184872 0.111801679,26.738247 0.555651758,26.3128898 C0.855250561,26.0257737 5.73760142,21.3468447 7.97904432,19.198791 C8.45618316,18.741532 9.23292079,18.741532 9.71005963,19.198791 C11.9515025,21.3468447 16.8227571,26.0257737 17.1223559,26.3128898 C17.5551098,26.727613 17.3997622,27.1742381 16.7117946,27.8441756 C16.023827,28.5034792 15.5577844,28.6629882 15.1139343,28.237631 L8.83345572,22.2294609 Z" className='LogoSpinner-body'></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
