import React from 'react';
import {observer} from 'mobx-react';
import TimeOffSubnav from 'containers/time_off/TimeOffSubnav';
import PendingRequestDetailsWithState from 'containers/time_off/components/pending_requests/components/PendingRequestDetailsWithState';

const PendingRequestDetails = observer(({scope, match, history}) => {
  return (
    <div>
      <TimeOffSubnav scope={scope} />
      <PendingRequestDetailsWithState match={match} history={history} />
    </div>
  );
});

export default PendingRequestDetails;
