import React from 'react';
import {observer} from 'mobx-react';
import Toolbar from './Toolbar';
import PdfPreview from 'components/pdf/preview/components/PdfPreview';
import AnnotatablePdfPreviewState from 'components/pdf/annotate/state/AnnotatablePdfPreviewState';
import {loader} from 'shared/core';
import annotatablePdfStore from 'components/pdf/annotate/store/AnnotatablePdfStore';
import {ModalButtons} from 'components/modals';
import {FormError} from 'components';
/*global document*/

@observer class AnnotatablePdfPreview extends React.Component {
  fabricCanvas;
  canvas;

  componentDidMount() {
    const {fabric} = this.props.uiState;
    // fabric-canvas is rendered inside <PdfPreview/>
    this.fabricCanvas = new fabric.Canvas('fabric-canvas', { allowTouchScrolling: true });
    this.fabricCanvas.on('selection:created', this.onSelectionCreated);
    this.fabricCanvas.on('selection:cleared', this.onSelectionCleared);
    this.fabricCanvas.renderOnAddRemove = false;
    this.props.uiState.registerCallback('onAnnotationsAdded', this.onAnnotationsAdded);
    this.props.uiState.registerCallback('onPageRefreshed', this.onPageRefreshed);

    document.addEventListener('keydown', this.onKeyDown);

    this.fabricCanvas.on('object:moving', this.onObjectMoving);
    this.fabricCanvas.on('object:scaling', this.onObjectScaling);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (e) => {
    switch (e.key) {
      case 'Backspace':
      case 'Delete':
        const activeObject = this.fabricCanvas.getActiveObject();
        if (activeObject) {
          this.fabricCanvas.remove(activeObject);
          this.props.uiState.onAnnotationDeleted(activeObject);
          this.fabricCanvas.renderAll();
        }
        break;
      default:
        break;
    }
  };

  onSelectionCreated = (e) => {
    this.fabricCanvas.allowTouchScrolling = false;
    this.copyCoords(e.target, this.props.uiState.lastKnownBestCoords);
  }

  onSelectionCleared = () => {
    this.fabricCanvas.allowTouchScrolling = true;
  }

  onObjectMoving = (e) => {
    const obj = e.target;

    if (obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width) {
      return;
    }

    obj.setCoords();
    const rect = obj.getBoundingRect();

    if (rect.top < 0) {
      obj.top = 0;
    } else if (rect.top + rect.height > obj.canvas.height) {
      obj.top = obj.canvas.height - rect.height;
    }

    if (rect.left < 0) {
      obj.left = 0;
    } else if (rect.left + rect.width > obj.canvas.width) {
      obj.left = obj.canvas.width - rect.width;
    }
  }

  onObjectScaling = (e) => {
    const obj = e.target;
    obj.setCoords();
    const boundingRect = obj.getBoundingRect();

    if (this.objResizedOutOfBounds(obj, boundingRect)) {
      this.copyCoords(this.props.uiState.lastKnownBestCoords, obj);
    } else {
      this.copyCoords(obj, this.props.uiState.lastKnownBestCoords);
    }
  }

  copyCoords = (source, target) => {
    target.left = source.left;
    target.top = source.top;
    target.scaleX = source.scaleX;
    target.scaleY = source.scaleY;
    target.width = source.width;
    target.height = source.height;
  }

  objResizedOutOfBounds = (obj, boundingRect) => {
    const right = boundingRect.width + boundingRect.left;
    const bottom = boundingRect.height + boundingRect.top;

    return right >= obj.canvas.width ||
      bottom >= obj.canvas.height ||
      boundingRect.left < 0 ||
      boundingRect.top < 0;
  }

  onAnnotationsAdded = (annotations) => {
    for (const item of annotations) {
      item.objectCaching = false;
      this.fabricCanvas.add(item);
    }
    this.fabricCanvas.renderAll();
  };

  onPageChanging = () => {
    this.fabricCanvas.clear();
  };

  onPageChanged = (pageNumber) => {
    const {uiState} = this.props;

    this.fabricCanvas.setHeight(this.viewerElement.clientHeight);
    uiState.changePage(pageNumber, this.viewerElement.clientWidth, this.viewerElement.clientHeight);
  }

  onPageRefreshed = () => {
    setTimeout(() => this.fabricCanvas.renderAll(), 0);
  }

  onPdfLoaded = ({viewerElement}) => {
    this.viewerElement = viewerElement;
    this.fabricCanvas.setHeight(viewerElement.clientHeight);
    this.props.uiState.onLoaded();
  };

  render() {
    const {uiState, errors} = this.props;

    return (
      <div className='relative'>
        <Toolbar uiState={uiState}/>
        <PdfPreview
          pdfUrl={uiState.pdfUrl}
          includeFabricCanvas
          callbacks={{
            onLoaded: this.onPdfLoaded,
            onPageChanging: this.onPageChanging,
            onPageChanged: this.onPageChanged
          }}
        />
        {errors && <FormError message={errors.originalFile} />}
        <ModalButtons
          onSave={() => uiState.save()}
          onCancel={() => uiState.cancel()}
        />
      </div>
    );
  }
}

async function load({pdfUrl, onSave, onCancel, annotations}) {
  const module = await import(/* webpackChunkName: 'fabric-async' */ 'fabric');
  const uiState = new AnnotatablePdfPreviewState({
    pdfUrl, store: annotatablePdfStore, onSave, onCancel, annotations, fabric: module.fabric
  });
  await uiState.load();
  return {uiState};
}

export default loader(AnnotatablePdfPreview, load);
