import {DomainObject} from 'shared/store';
import {observable, computed} from 'mobx';
import {t} from 'shared/core';

class PerformanceReviewQuestionWithType extends DomainObject {
  @observable id;
  @observable questionName;
  @observable questionOptions;
  @observable type;

  @computed get displayType() {
    return t(`models.performance_reviews.type.${this.type}`);
  }

  @computed get name() {
    return `${this.questionName} (${this.displayType})`;
  }
}

export default PerformanceReviewQuestionWithType;
