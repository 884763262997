import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const PanelEmptyState = observer(({message}) => {
  return (
    <div className='center jumbo rounded h5 py2 px1 PanelEmptyState mxn1'>
      <FormattedMessage id={message}/>
    </div>
  );
});

PanelEmptyState.defaultProps = {
  message: 'No records to display'
};

export default PanelEmptyState;