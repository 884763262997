import React from 'react';
import {observer} from 'mobx-react';
import {auth} from 'shared/core';
import OffboardingTasksContainer from 'employees/offboarding/components/OffboardingTasksContainer';

const OffboardingTasks = observer(({uiState}) => {
  if (!auth.moduleEnabled('directory')) return null;

  return (
    <OffboardingTasksContainer
      hasManager={uiState.employeeHasManager}
      onChange={taskTemplates => uiState.updateSelectedTasks(taskTemplates)}
      employeeId={uiState.employee.id}
    />
  );
});

export default OffboardingTasks;
