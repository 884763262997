import {DomainObject} from 'shared/store';
import {observable, computed, action} from 'mobx';
import Rule from './Rule';
import _ from 'lodash';

class Segment extends DomainObject {
  @observable id;
  @observable rules = [];

  @action merge(other, fields) {
    super.merge(other, {
      ...fields,
      rules: [Rule]
    });
  }

  @computed get locationRule() {
    return _.find(this.rules, r => r.ruleType === 'location');
  }

  @computed get departmentRule() {
    return _.find(this.rules, r => r.ruleType === 'department');
  }

  @computed get employmentTypeRule() {
    return _.find(this.rules, r => r.ruleType === 'employment_type');
  }
}

export default Segment;
