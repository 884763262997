import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints, t} from 'shared/core';
import {successAlert} from 'shared/tools';
import OktaCredentials from 'stores/sso/OktaCredentials';

class OktaConfigurationContainerState {
  store = new DomainStore();
  @observable credentials;
  @observable errors = {};

  @action async load() {
    await this.store._compose([
      endpoints.SSO.OKTA_CREDENTIALS
    ]);

    this.credentials = new OktaCredentials(this.store._getSingle(types.SSO.OKTA_CREDENTIALS));
  }

  @action async toggleSSO() {
    await (this.company.ssoEnabled ? this.clearOktaCredentials() : this.createOktaCredentials());
    this.closeSSOConfirmationModal();
  }

  @action async createOktaCredentials() {
    const {model, errors} = await this.store.post(
      endpoints.SSO.OKTA_CREDENTIALS,
      types.SSO.OKTA_CREDENTIALS,
      this.credentials
    );
    this.errors = errors;
    if (model) {
      this.credentials.update(model);
      successAlert(t('company_settings.applications.Configuration updated.'));
    }
  }

  @action async clearOktaCredentials() {
    const {model} = await this.store.post(
      endpoints.SSO.CLEAR_OKTA_CREDENTIALS,
      types.SSO.OKTA_CREDENTIALS
    );
    this.credentials.update(model);
    successAlert(t('company_settings.applications.Configuration updated.'));
  }

  @computed get company() {
    return this.credentials.company;
  }
}

export default OktaConfigurationContainerState;
