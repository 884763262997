import React from 'react';
import PropTypes from 'prop-types';
import InputHelper from './InputHelper';
import Checkbox from './Checkbox';

class CheckboxWithLabel extends React.Component {
  render() {
    const {checked, className, onChange, disabled, errorMessage, children} = this.props;

    return (
      <div className={`${className}`}>
        <div className='clickable table-cell align-middle'>
          <Checkbox disabled={disabled} checked={checked} onChange={onChange}/>
        </div>
        <div className='table-cell align-middle pl1'>
          {children}
        </div>
        <InputHelper errorMessage={errorMessage} />
      </div>
    );
  }
}

CheckboxWithLabel.propTypes = {
  children: PropTypes.node,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ])
};

CheckboxWithLabel.defaultProps = {
  className: '',
};

export default CheckboxWithLabel;
