import React from 'react';
import {observer} from 'mobx-react';
import {Table} from 'components';
import UpcomingHolidaysModal from './UpcomingHolidaysModal';

const COLUMNS = [
  {
    header: 'employees.profile.time_off.Type',
    attribute: 'timeOffType.name',
    width: 4
  },
  {
    header: 'employees.profile.time_off.Dates',
    attribute: 'datesView',
    width: 7
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'employees.profile.time_off.Delete Time Off Request',
    subHeader: 'employees.profile.time_off.Are you sure you want to delete this time off request?',
    body: 'employees.profile.time_off.The requested time off will be refunded.'
  },
  emptyState: 'employees.profile.time_off.No time off scheduled',
  removeModel: 'employees.profile.time_off.Delete'
};

const UpcomingTimeOff = observer(({uiState}) => {
  const {upcomingRequests} = uiState;

  return (
    <div>
      <Table models={upcomingRequests}
             columns={COLUMNS}
             localization={LOCALIZATION}
             onEdit={request => uiState.redirectToEdit(request)}
             onRemove={request => uiState.deleteUpcomingRequest(request)}/>
      <UpcomingHolidaysModal uiState={uiState} />
    </div>
  );
});

export default UpcomingTimeOff;
