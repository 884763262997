import React from 'react';
import {Button} from 'components';
import GoogleImportState from 'employees/google_import/state/GoogleImportState';
import {observer} from 'mobx-react';
import ImportModal from './ImportModal';
import GoogleLogo from 'img/google_logo@2x.png';
import {FormattedMessage} from 'react-intl';

const GoogleImportContainer = observer(({uiState}) => {
  return (
    <div className='mt2'>
      <Button trait='default' onClick={() => uiState.launchImport()}>
        <div className='table-cell align-middle'>
          <img src={GoogleLogo} alt='google_logo' height='21' className='mr1'/>
        </div>
        <span className='table-cell align-middle'>
          <FormattedMessage id='employees.google_import.Import from Google'/>
        </span>
      </Button>
      <ImportModal uiState={uiState} />
    </div>
  );
});

export default ({onEmployeesAdded}) => {
  const uiState = new GoogleImportState(onEmployeesAdded);
  return <GoogleImportContainer uiState={uiState} />;
};
