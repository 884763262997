import React from 'react';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';
import {CopyableField} from 'components';

const CalendarLinkRow = ({name, link}) => {
  return (
    <div className='clearfix pt2'>
      <div className='col col-12 sm-col-8 pb2'>
        <div className='h4 jumbo mb1'>
          {name}
        </div>
        <CopyableField fieldValue={link}/>
      </div>
    </div>
  );
};

const EmptyState = () => {
  return (
    <div className='clearfix pt2'>
      <div className='col col-8 pb2'>
        <div className='h5 jumbo mb1'>
          <FormattedMessage id='employees.profile.calendars.empty'/>
        </div>
      </div>
    </div>
  );
};

const EmployeeCalendarsList = ({employeeCalendars}) => {
  return (
    <div>
      {employeeCalendars.map(employeeCalendar =>
        <CalendarLinkRow
          key={employeeCalendar.externalId}
          name={t(`models.calendar_type.${employeeCalendar.calendar.calendarType}.name`)}
          link={employeeCalendar.links.calendarFeed}
        />
      )}
    </div>
  );
};
const CalendarSubscriptionsList = ({calendarSubscriptions}) => {
  return (
    <div>
      {calendarSubscriptions.map(calendarSubscription =>
        <CalendarLinkRow
          key={calendarSubscription.externalId}
          name={calendarSubscription.name}
          link={calendarSubscription.links.calendarFeed}
        />
      )}
    </div>
  );
};

const CombinedCalendarList = ({uiState}) => {
  const {employeeCalendars, calendarSubscriptions} = uiState;

  return (
    <div>
      <EmployeeCalendarsList employeeCalendars={employeeCalendars}/>
      <CalendarSubscriptionsList calendarSubscriptions={calendarSubscriptions}/>
    </div>
  );
};

const CalendarLinks = ({uiState}) => {
  if (uiState.hasCalendars) {
    return <CombinedCalendarList uiState={uiState}/>;
  } else {
    return <EmptyState/>;
  }
};

export default CalendarLinks;
