import {DomainStore} from 'shared/store';
import {observable, action, computed} from 'mobx';
import filestack from 'filestack-js';
import {successAlert} from 'shared/tools';
import JobSite from 'stores/recruiting/JobSite';
import InvalidIndeedLocation from 'stores/recruiting/InvalidIndeedLocation';
import _ from 'lodash';
import {auth, endpoints, types, t} from 'shared/core';

class JobSiteState {
  store = new DomainStore();

  @observable jobSite;
  @observable invalidLocations = [];
  @observable errors = {};
  @observable deactivateIndeedModalOpen = false;

  @action async load() {
    await this.store._compose([
      endpoints.RECRUITING.JOB_SITE.SETTINGS
    ]);

    this.jobSite = new JobSite(this.store._getSingle(types.RECRUITING.JOB_SITE.SETTING));

    if (this.jobSite.indeedEnabled) {
      await this.loadInvalidIndeedLocations();
    }
  }

  @action async loadInvalidIndeedLocations() {
    await this.store._compose([
      endpoints.RECRUITING.JOB_SITE.INVALID_INDEED_LOCATIONS
    ]);

    this.invalidLocations = this.store._getAll(types.RECRUITING.JOB_SITE.INVALID_INDEED_LOCATION, InvalidIndeedLocation);
  }

  @action async saveJobSite() {
    const {errors, model} = await this.store.patch(
      endpoints.RECRUITING.JOB_SITE.SETTINGS,
      types.RECRUITING.JOB_SITE.SETTINGS,
      this.jobSite
    );

    if (model) {
      this.jobSite.merge(model);
    }
    this.errors = errors;
  }

  @action async saveForm() {
    await this.saveJobSite();

    if (_.isEmpty(this.errors)) {
      successAlert(t(`recruiting.alerts.Job site successfully updated`));
    }
  }

  @action async saveIndeedForm() {
    this.jobSite.merge({
      indeedEnabled: true,
      indeedApplyEnabled: true
    });
    await this.saveJobSite();

    if (_.isEmpty(this.errors)) {
      await this.loadInvalidIndeedLocations();
      successAlert(t(`recruiting.alerts.Indeed settings successfully updated`));
    }
  }

  @action clearSocialSharingImage() {
    this.jobSite.updateSocialSharingImage(null);
  }

  @action openDeactivateIndeedModal() {
    this.deactivateIndeedModalOpen = true;
  }

  @action closeDeactivateIndeedModal() {
    this.deactivateIndeedModalOpen = false;
  }

  @action async deactivateIndeedIntegration() {
    this.jobSite.merge({
      indeedEnabled: false,
      indeedApplyEnabled: false
    });

    await this.saveJobSite();
    successAlert(t(`recruiting.alerts.Indeed settings successfully updated`));
    this.closeDeactivateIndeedModal();
  }

  @computed get indeedFormSaveCaption() {
    if (this.jobSite.indeedEnabled) {
      return 'recruiting.job_site.Save';
    } else {
      return 'recruiting.job_site.Activate';
    }
  }

  @computed get widgetCodeSnippet() {
    return `<script src='https://assets.secure.collage.co/static/js/embed.js'></script>
      <link rel='stylesheet' href='https://assets.secure.collage.co/static/style/embed.css'>
      <script>
        document.addEventListener('DOMContentLoaded', () => collageEmbed('${this.jobSite.urlSlug}', '${auth.locale}'));
      </script>
      <div id='collage_embed_container'/>`;
  }

  openFileStack() {
    const client = filestack.init(process.env.FILESTACK_KEY, auth.fileStack.uploadPolicy);
    client.pick({
      accept: ['image/*'],
      storeTo: {
        location: 's3',
        path: '/avatars/',
      }
    }).then(response => {
      const file = _.head(response.filesUploaded);
      client.metadata(file.handle, {height: true, width: true}).then(metadata => {
        this.jobSite.updateSocialSharingImage({
          fileName: file.filename,
          fileSize: file.size,
          contentType: file.mimetype,
          key: file.key,
          label: file.url,
          width: metadata.width,
          height: metadata.height
        });
      });
    });
  }
}

export default JobSiteState;
