import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const ReportEmptyState = observer(() => {
  return (
    <div className='py4 center h3 waterloo'>
      <FormattedMessage id='reports.There are no results to show.'/>
    </div>
  );
});

export default ReportEmptyState;
