import React from 'react';
import {observer} from 'mobx-react';
import {withState, withDisplay, t} from 'shared/core';
import {SlackConfigurationContainerState} from '../state';
import {Button, Layout, Panel} from 'components';
import SlackLogo from 'img/slack-logo@2x.png';
import SubNav from 'company_settings/SubNav';
import {FormattedMessage} from 'react-intl';
import DisconnectFromSlackModal
  from 'containers/company_settings/applications/slack/components/DisconnectFromSlackModal';

const ConnectToSlack = observer(({uiState}) => {
  return (
    <Panel>
      <Panel.Header title={t('company_settings.applications.slack.Connect your Slack workspace')}/>
      <FormattedMessage id={`company_settings.applications.slack.CONNECT_INSTRUCTIONS`}/>
      <form action='/slack/connect_admin' method='post'>
        <input type='hidden' name='authenticity_token' value={uiState.authenticityToken} />
        <button type='submit' className='Btn Btn--lg Btn--default mt2'>
          <span className='Btn--content '>
          <img src={SlackLogo} alt='logo' height='18' className='inline-block' style={{margin: '0 24px 0 0'}}/>
            {t('company_settings.applications.slack.Add to Slack')}
          </span>
        </button>
      </form>
    </Panel>
  );
});

const DisconnectFromSlack = observer(({uiState}) => {
  return (
    <Panel>
      <Panel.Header
        title={t('company_settings.applications.slack.Disconnect your Slack workspace')}
        description={
          <FormattedMessage id={`company_settings.applications.slack.DISCONNECT_INSTRUCTIONS`}/>
        }
      />
      <Button onClick={() => uiState.showModal()} trait='default'>
        <FormattedMessage id='company_settings.applications.slack.Disconnect'/>
      </Button>
      <DisconnectFromSlackModal uiState={uiState}/>
    </Panel>
  );
});

const SettingsPanel = observer(({uiState}) => {
  const {company} = uiState;
  if (!company.slackEnabled) return null;

  return (
    <Panel>
      <Panel.Header title={t('company_settings.applications.slack.Settings')}/>
      <FormattedMessage id='company_settings.applications.slack.People Off Today'/>
      <div className='h6 jumbo mb1'>
        <FormattedMessage id='company_settings.applications.slack.PEOPLE_OFF_TODAY_NOTICE'
          values={{
            link: <a href='/company_settings/visibility' target='_blank'>
                    <FormattedMessage id='company_settings.applications.slack.Visibility Preferences'/>
                  </a>
          }}
        />
      </div>
    </Panel>
  );
});


const SlackConfigurationContainer = observer(({uiState}) => {
  const {company} = uiState;

  return (
    <Layout>
      <Layout.Section secondary>
        <Panel.Stack loose>
          <SettingsPanel uiState={uiState}/>
          {company.slackEnabled ? <DisconnectFromSlack uiState={uiState}/> : <ConnectToSlack uiState={uiState}/>}
        </Panel.Stack>
      </Layout.Section>
    </Layout>
  );
});

export default withDisplay(
  withState(SlackConfigurationContainer, SlackConfigurationContainerState),
  () => <SubNav title={t('company_settings.subnav.Slack')}/>
);
