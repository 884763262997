import {Steps} from 'shared/tools';


const STEPS = [
  {
    name: 'performance.cycle.steps.Name',
    location: 'name',
  },

  {
    name: 'performance.cycle.steps.Leaders',
    location: 'leaders',
  },

  {
    name: 'performance.cycle.steps.Employees',
    location: 'employees',
  },

  {
    name: 'performance.cycle.steps.Frequency',
    location: 'frequency',
  },

  {
    name: 'performance.cycle.steps.Questions',
    location: 'questions',
  },

  {
    name: 'performance.cycle.steps.Review',
    location: 'review',
  },
].map((s, i) => ({
  ...s,
  index: i + 1
}));

const singleton = new Steps(STEPS);

export default singleton;
