import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {auth} from 'shared/core';
import {AbsoluteRedirect} from 'components';
import {BenefitEnrolmentDirectory} from './directory';
import {EmployeeBenefitEnrolment} from './details';
import {EmployeeBenefitChangeForm} from './change';
import {EmployeeBenefitEnrolmentForm} from './new';
import {EmployeeBenefitUnenrolmentForm} from './unenrol';
import {BenefitEnrolmentForm} from './enrol';

const BenefitEnrolmentsPage = ({scope, history}) => {
  return (
    <Switch>
      <Route exact path='/benefit_enrolments' render={({history}) => <BenefitEnrolmentDirectory scope={scope} history={history}/>}/>
      <Route exact path='/benefit_enrolments/:id/change' render={({history, match}) => <EmployeeBenefitChangeForm scope={scope} history={history} employeeId={match.params.id}/>}/>
      <Route exact path='/benefit_enrolments/:id/new' render={({history}) => <EmployeeBenefitEnrolmentForm scope={scope} history={history}/>}/>
      {auth.moduleEnabled('directory') && <Route exact path='/benefit_enrolments/:id/unenrol' render={({history, match}) => <EmployeeBenefitUnenrolmentForm scope={scope} history={history} employeeId={match.params.id}/>}/>}
      {auth.moduleEnabled('directory') && <Route path='/benefit_enrolments/:id/:employeeUrlSlug?' component={EmployeeBenefitEnrolment}/>}
      {!auth.moduleEnabled('directory') && <Route exact path='/benefit_enrolments/enrol' render={({history}) => <BenefitEnrolmentForm scope={scope} history={history}/>}/>}
      <AbsoluteRedirect/>
    </Switch>
  );
};

export default BenefitEnrolmentsPage;
