import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Anonymous Survey',
  'Period Date'
];

const AnonymousSurveysReport = () => {
  return (
    <CompositeReport filters={filters}>
      <Report name={t('reports.ANONYMOUS_SURVEY_RESPONSES')}
              url='/reports/anonymous_surveys'>
      </Report>
    </CompositeReport>
  );
};

export default AnonymousSurveysReport;
