import {observable, action} from 'mobx';
import Reminder from 'stores/reminders/Reminder';
import {successAlert} from 'shared/tools';
import {t} from 'shared/core';
import REMINDER_TARGETS from 'stores/reminders/reminderTargets';
import REMINDER_RECIPIENTS from 'stores/reminders/reminderRecipients';
import _ from 'lodash';
import Recipient from 'stores/reminders/Recipient';

class ReminderEditState {
  store;
  reminderId;
  history;

  @observable selectedReminderDateOption = 'zero';
  @observable offset;
  @observable reminder;
  @observable targets = [];
  @observable recipientOptions = [];
  @observable errors = {};
  @observable reminderTarget = {};

  constructor(store, history) {
    this.store = store;
    this.history = history;
  }

  concatReminderTargets(customFields, hardcodedTargets) {
    return [
      ...customFields.map(f => ({
        id: `cf-${f.name}`,
        type: 'custom_field',
        name: f.name,
        model: f
      })),
      ...hardcodedTargets.map(h => ({
        id: h.type,
        type: h.type,
        name: t(h.name)
      }))
    ];
  }

  concatReminderRecipients(users, hardcodedRecipients) {
    return [
      ...hardcodedRecipients.map(r => new Recipient({recipientType: r.type})),
      ...users.map(u => new Recipient({recipientType: 'specific_user', user: u}))
    ];
  }

  @action async load(reminderId) {
    this.reminderId = reminderId;

    await this.store.load(this.reminderId);

    this.reminder = this.store.getReminder(this.reminderId) || new Reminder();
    this.targets = this.concatReminderTargets(
      this.store.getCustomFieldsWithDates(),
      REMINDER_TARGETS
    );
    this.recipientOptions = this.concatReminderRecipients(
      this.store.getUsers(),
      REMINDER_RECIPIENTS
    );
    this.initReminderTarget();
    this.initReminderDateOption();
    this.initReminderDateOffset();
  }

  initReminderTarget() {
    const target = _.find(
      this.targets,
      r => {
        if (r.type !== this.reminder.reminderType) return false;

        switch (this.reminder.reminderType) {
          case 'custom_field':
            return this.reminder.customField.equals(r.model);
          case 'last_day_of_benefits':
          case 'first_day_of_benefits':
          case 'birthday':
          case 'anniversary':
          case 'sin_expiry_date':
          case 'first_day_approved_time_off':
          case 'first_day_of_work':
          case 'last_day_of_work':
          case 'first_day_of_leave':
            return true;
          default:
            throw new Error(`Reminder type ${r.type} not supported.`);
        }
      }
    );

    this.reminderTarget = target;
  }

  initReminderDateOption() {
    if (this.reminder.relativeDateValue !== 0) {
      this.selectedReminderDateOption = 'offset';
    }
  }

  initReminderDateOffset() {
    this.offset = this.reminder.relativeDateValue <= 0 ? 'before' : 'after';
  }

  @action updateWithOffset(value, offset) {
    this.reminder.relativeDateValue = parseInt(value);
    this.offset = offset;

    if (offset === 'before') {
      this.reminder.relativeDateValue *= -1;
    }
  }

  @action updateRelativeDateValue(value) {
    this.updateWithOffset(value, this.offset);
  }

  @action updateOffset(offset) {
    this.updateWithOffset(this.reminder.absoluteDateValue, offset);
  }

  @action updateTarget(target) {
    switch(target.type) {
      case 'custom_field':
        this.reminder.customField = target.model;
        break;
      case 'last_day_of_benefits':
      case 'first_day_of_benefits':
      case 'birthday':
      case 'anniversary':
      case 'sin_expiry_date':
      case 'first_day_approved_time_off':
      case 'first_day_of_work':
      case 'last_day_of_work':
      case 'first_day_of_leave':
        break;
      default:
        throw new Error(`Reminder type ${target.type} not supported.`);
    }

    this.reminder.reminderType = target.type;
    this.reminderTarget = target;
  }

  @action addRecipient(recipient) {
    this.reminder.recipients.push(recipient);
  }

  @action removeRecipient(recipient) {
    _.remove(this.reminder.recipients, {value: recipient.value});
  }

  @action async saveReminder() {
    const {model, errors} = this.reminder.isNew
      ? await this.store.postReminder(this.reminder)
      : await this.store.patch(this.reminder);

    this.errors = errors;

    if (model) {
      successAlert(t('company_settings.reminders.Reminder succesfully saved.'));
      this.history.push('/reminders');
    }
  }

  @action updateDateOption(option) {
    if (this.selectedReminderDateOption === option) return;

    if (option === 'zero') {
      this.reminder.relativeDateValue = 0;
    } else {
      this.reminder.relativeDateValue = -1;
      this.reminder.relativeDateUnit = 'days';
      this.offset = 'before';
    }

    this.selectedReminderDateOption = option;
  }
}

export default ReminderEditState;
