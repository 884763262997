import React from 'react';
import {observer} from 'mobx-react';
import GlobalSearchState from 'global_search/state/GlobalSearchState';
import {Input} from 'components';
import SuggestionList from './SuggestionList';
import globalSearchStore from 'stores/global_search/GlobalSearchStore';

@observer class GlobalSearchContainer extends React.Component {
  onFocus = () => {
    this.input.select();
  };

  render() {
    const {uiState} = this.props;

    return (
      <div className='GlobalSearch wl-bg-primary'>
        <div className='full-height relative'>
          <Input type='search'
                 value={uiState.autoFilledQuery || uiState.query}
                 inputRef={ref => this.input = ref}
                 onFocus={this.onFocus}
                 onChange={e => uiState.updateQuery(e.target.value)}
                 onKeyDown={e => uiState.moveSelection(e)}
                 className='GlobalSearch-input wl-global-search'
                 placeholder='global_search.Search'/>
          {uiState.closeIconVisible && <i onClick={() => uiState.clearQuery()} className='GlobalSearch-close material-icons clickable h3 waterloo absolute'>{'cancel'}</i>}
        </div>
        {uiState.suggestionsVisible && <SuggestionList uiState={uiState}/>}
      </div>
    );
  }
}

export default () => {
  const uiState = new GlobalSearchState(globalSearchStore);
  return <GlobalSearchContainer uiState={uiState}/>;
};
