import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import PerformanceReviewReview from './PerformanceReviewReview';
import Employee from 'stores/employees/Employee';
import _ from 'lodash';

class PerformanceReviewReviewee extends DomainObject {
  @observable id;
  @observable reviews = [];
  @observable employee;
  @observable reviewManager;

  @action merge(other) {
    super.merge(other, {
      employee: Employee,
      reviewManager: Employee,
      reviews: [PerformanceReviewReview]
    });
  }

  @computed get managerReview() {
    return _.find(this.reviews, r => r.type.kind === 'manager');
  }

  @computed get peerReviews() {
    return _.chain(this.reviews)
      .filter(r => r.type.kind === 'peer')
      .value();
  }

  @computed get managerReviewer() {
    return _.get(this.managerReview, 'reviewer');
  }

  @computed get peerReviewers() {
    return _.map(this.peerReviews, 'reviewer');
  }

  @computed get selfReview() {
    return _.find(this.reviews, r => r.type.kind === 'self');
  }

  @computed get selfReviewer() {
    return _.get(this.selfReview, 'reviewer') || [];
  }

  @computed get selfAndPeerReviewers() {
    return _.concat(this.selfReviewer, this.peerReviewers);
  }
}

export default PerformanceReviewReviewee;
