/* eslint-disable */
import React, { Component } from 'react';
import { observable, action, computed } from 'mobx';
import { observer, PropTypes as MobxP } from 'mobx-react';
import P from 'prop-types';

import {
	TOP_MARGIN,
	NODE_WIDTH,
	ARC_SIZE
} from '../../constants';

@observer class Lines extends Component {
	@computed get translateX(){
		return - this.props.firstNodeLeft + this.props.left + NODE_WIDTH / 2
	}

	@computed get path(){
		const m2 = TOP_MARGIN
		const { parentLeft, left } = this.props

		if (parentLeft > left){
			return `M0 ${m2} a ${ARC_SIZE} ${ARC_SIZE} 0 0 1 ${ARC_SIZE} ${-ARC_SIZE}`
		} else if (parentLeft < left){
			return `M0 ${m2} a ${ARC_SIZE} ${ARC_SIZE} 0 0 0 ${-ARC_SIZE} ${-ARC_SIZE}`
		}
		// straight
		return `M0 ${m2} l 0 ${-ARC_SIZE}`
	}

	render(){
		return (
			<path
				d={this.path}
				// need both transform (for edge) and style transform
				// for animations to work for safari + firefox
				transform={`translate(${this.translateX} 0)`}
				style={{
					transform: `translate(${this.translateX}px, 0)`
				}}
				strokeWidth="2"
				className="row__arc"
			/>
		)
	}
}

Lines.propTypes = {
	firstNodeLeft: P.number,
	parentLeft: P.number,
	left: P.number
}

export default Lines;
