import React from 'react';
import {observer} from 'mobx-react';
import {Route, Switch} from 'react-router-dom';
import {CalendarDirectoryContainer} from './directory';
import {CalendarEditContainer} from './edit';

const CalendarsPage = observer(() => {
  return (
    <Switch>
      <Route exact path='/calendars' component={CalendarDirectoryContainer}/>
      <Route path='/calendars/edit/:id?' component={CalendarEditContainer}/>
    </Switch>
  );
});

export default CalendarsPage;
