import {DomainObject} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {dateSpan} from 'shared/tools';

class TimeTrackingPayPeriod extends DomainObject {
  @observable startDate;
  @observable endDate;

  @action merge(other) {
    super.merge(other, {
      _dates: ['startDate', 'endDate'],
    });
  }

  @computed get timeRange() {
    return dateSpan(this.startDate, this.endDate, true);
  }
}

export default TimeTrackingPayPeriod;
