import {observable, action} from 'mobx';
import _ from 'lodash';
import {t, api, types, endpoints} from 'shared/core';
import {successAlert} from 'shared/tools';
import {DomainStore} from 'shared/store';
import {PayrollUpload, PayPeriod} from 'stores/payroll';

class UploadsDirectoryState {
  store = new DomainStore();

  @observable payrollUpload = new PayrollUpload();
  @observable errors = {};
  @observable interestedInPayroll = false;
  @observable submittedPayrollUpload = false;
  @observable isUploading = false;
  @observable uploadFileModalOpen = false;
  @observable currentPayPeriod;

  @action async load() {
    await this.store._compose([endpoints.PAY_PERIOD.CURRENT]);
    this.currentPayPeriod = new PayPeriod(this.store._getSingle(types.PAY_PERIOD));
  }

  setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  validate() {
    this.errors = {};
    if (!this.payrollUpload.payrollFile) {
      this.errors.payrollFile = t('payroll.errors.Choose a file to upload');
    }
    return _.isEmpty(this.errors);
  }

  @action async notifyThatCustomerIsInterestedInPayroll() {
    await api.post(endpoints.PAYROLL.INTERESTED);

    this.interestedInPayroll = true;
  }

  @action async createPayrollUpload() {
    if (!this.validate()) return null;

    const {model, errors} = await this.store.post(
      endpoints.PAYROLL_UPLOAD.NEW,
      types.PAYROLL_UPLOAD,
      this.payrollUpload
    );

    this.errors = errors;

    if (model) {
      this.closeUploadFileModal();
      successAlert(t('payroll.uploader.File successfully uploaded!'));
      this.interactiveAgent.refresh();
    }
  }

  @action onUploadStarted() {
    this.isUploading = true;
  }

  @action onUploadFinished() {
    this.isUploading = false;
  }

  @action openUploadFileModal() {
    this.uploadFileModalOpen = true;
  }

  @action closeUploadFileModal() {
    this.uploadFileModalOpen = false;
  }
}

export default UploadsDirectoryState;
