import React from 'react';
import {Button} from 'components';
import {FormattedMessage} from 'react-intl';

const SignedInAs = ({uiState}) => {
  return (
    <div className='border rounded p2 mb3 box-shadow-1 bg-haze'>
      <div>
        <FormattedMessage
          id='employees.google_import.SIGNED_IN_AS'
          values={{
            name: uiState.currentUser.name,
            email: uiState.currentUser.email
          }}
        />
      </div>
      <div className='mt2'>
        <Button trait='default' onClick={async () => uiState.disconnectGoogleAccount()} size='sm'>
          <FormattedMessage id='employees.google_import.Sign out'/>
        </Button>
      </div>
    </div>
  );
};

export default SignedInAs;
