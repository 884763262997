import {observable, computed, toJS, action} from 'mobx';
import _ from 'lodash';
import {SelectableReviewer, Reviewer} from './reviewers';
import {defaultQuestions, Question} from './questions';
import {DomainObject} from 'shared/store';
import {t} from 'shared/core';

const DEFAULT_CYCLE_NAME = 'performance.Untitled Check-In Cycle';

class Cycle extends DomainObject{
  @observable id;
  @observable name;
  @observable frequency;
  @observable questions;
  @observable sendEmployeeNotifications;
  @observable sendManagerNotifications;
  @observable sendEmployeeWelcomeNotifications;
  @observable sendManagerWelcomeNotifications;
  @observable reviewers;
  @observable selectableReviewers;
  @observable allEmployees;
  @observable status;
  @observable links;

  @computed get managers() {
    const reviewers = toJS(this.reviewers);
    return _.chain(reviewers)
      .map('reviewerId')
      .uniq()
      .map(id => _.find(this.allEmployees, {id}))
      .compact()
      .value();
  }

  @computed get employees() {
    const reviewers = toJS(this.reviewers);
    return _.chain(reviewers)
      .map('employeeIds')
      .flatten()
      .uniq()
      .map(id => _.find(this.allEmployees, {id}))
      .compact()
      .value();
  }

  @computed get orderedQuestions() {
    return _.orderBy(_.reject(this.questions, '_destroy'), 'order');
  }

  @computed get totalManagers() {
    return this.managers.length;
  }

  @computed get totalEmployees() {
    return this.employees.length;
  }

  @computed get url() {
    return `/cycles/${this.id}/`;
  }

  @computed get title() {
    return this.name || t(DEFAULT_CYCLE_NAME);
  }

  @computed get sortOrder() {
    switch (this.status) {
      case 'draft':
        return 0;
      case 'archived':
        return 2;
      default:
        return 1;
    }
  }

  @computed get isDraft() {
    return this.status === 'draft';
  }

  @action init(allEmployees) {
    this.allEmployees = allEmployees;

    if (this.reviewers || this.selectableReviewers) {
      if (_.isEmpty(this.selectableReviewers)) {
        this.selectableReviewers = _.map(allEmployees, m => new SelectableReviewer(m, allEmployees));
      }

      if (_.isEmpty(this.reviewers)) {
        this.reviewers = _.filter(_.map(this.selectableReviewers, r => r.reviewer), r => !!r);
      }

      this.reviewers = this.reviewers.map(r => new Reviewer(r, allEmployees));

      for (const reviewer of this.reviewers) {
        const selectable = _.find(this.selectableReviewers, r => r.manager.id === reviewer.reviewerId);
        if (selectable) {
          selectable.reviewer = reviewer;
        }
        reviewer.employee = _.find(this.allEmployees, {id: reviewer.reviewerId});
      }
    }

    if (this.questions) {
      if (_.isEmpty(this.questions)) {
        this.questions = _.map(defaultQuestions(), q => new Question(q));
      } else {
        this.questions = _.map(this.questions, q => new Question(q));
      }
    }
  }

  @action merge(other) {
    super.merge(other);
  }

  toJS() {
    const self = {...this};
    if (!_.isEmpty(self.reviewers)) {
      self.reviewers = self.reviewers.map(r => r.toJS());
    }
    const json = toJS(observable(self));
    return _.omit(json, ['selectableReviewers', 'managers', 'employees']);
  }
}
export default Cycle;
