import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Modal, ModalButtons} from 'components/modals';
import {QuickTip, FormLayout, DatePicker} from 'components';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';

const EditPayPeriodModal = observer(({uiState, isOpen}) => {
  const {paySchedule, editingPayPeriod, errors} = uiState;

  if (!editingPayPeriod) return null;

  return (
    <Modal
      isOpen={isOpen}
      onHide={() => uiState.closeEditPayPeriodModal()}
      size='sm'
      title={'time_tracking.manage.pay_schedules.view.Edit pay period'}
    >
      <FormLayout>
        <FormLayout.Group>
          <DatePicker
            label='time_tracking.manage.policies.edit.Start Date'
            value={editingPayPeriod.startDate}
            onChange={date => editingPayPeriod.merge({startDate: date})}
            errorMessage={errors.startDate}
            options={
              {
                maxViewMode: 'months',
                startDate: editingPayPeriod.previousStartDate && moment(editingPayPeriod.previousStartDate).add(1, 'days').toDate(),
                endDate: moment(editingPayPeriod.endDate).toDate(),
              }
            }
          />
          <DatePicker
            label='time_tracking.manage.policies.edit.End Date'
            value={editingPayPeriod.endDate}
            onChange={date => editingPayPeriod.merge({endDate: date})}
            errorMessage={errors.endDate}
            options={
              {
                maxViewMode: 'months',
                startDate: moment(editingPayPeriod.startDate).toDate(),
                endDate: editingPayPeriod.nextEndDate && moment(editingPayPeriod.nextEndDate).subtract(1, 'days').toDate(),
              }
            }
          />
        </FormLayout.Group>
      </FormLayout>
      <FormattedMessage
        id='time_tracking.manage.pay_schedules.view.FREQUENCY_MESSAGE'
        values={{frequency: <b>{t(`time_tracking.manage.pay_schedules.view.frequencies.${paySchedule.frequency}`)}</b>}}
      />
      {uiState.updatedDatesMessage && (
        <div className="mt3">
          <QuickTip
            trait="caution"
            paragraphs={[uiState.updatedDatesMessage]}
          />
        </div>
      )}
      <ModalButtons
        onSave={() => uiState.savePayPeriod()}
        onCancel={() => uiState.closeEditPayPeriodModal()}
      />
    </Modal>
  );
});

export default EditPayPeriodModal;
