import {DomainStore} from 'shared/store';
import {observable, action, observe, computed, autorun} from 'mobx';
import _ from 'lodash';
import {endpoints, types} from 'shared/core';
import CompanyInsuranceCarrier from 'stores/benefits/CompanyInsuranceCarrier';
import {EmployeeBenefitEnrollmentViewModel, EmployeeInsuranceNumber} from 'stores/benefits';

class BenefitEnrolmentListState {
  store = new DomainStore();
  onChange;
  employeeBenefitEnrollments;

  @observable enrollmentViewModels = [];
  @observable startDate;
  @observable companyInsuranceCarriers = [];
  @observable employeeInsuranceNumbers;

  receiveProps({onChange, employeeBenefitEnrollments, employeeInsuranceNumbers, startDate}) {
    this.onChange = onChange;
    this.employeeBenefitEnrollments = employeeBenefitEnrollments;
    this.employeeInsuranceNumbers = employeeInsuranceNumbers;
    this.startDate = startDate;
  }

  @action async load() {
    await this.store._compose([
      endpoints.COMPANY_INSURANCE_CARRIERS.WITHOUT_OPTIONAL
    ]);

    this.companyInsuranceCarriers = this.store._getAll(
      types.COMPANY_INSURANCE_CARRIER, CompanyInsuranceCarrier
    );

    this.addMissingEmployeeInsuranceNumbers();
    this.enrollmentViewModels = this.companyInsuranceCarriers.map(carrier => {
      const viewModel = EmployeeBenefitEnrollmentViewModel.createFor(carrier, this.employeeInsuranceNumbers);
      viewModel.record.merge({startDate: this.startDate});

      return viewModel;
    });
    this.restoreEnrolments();

    observe(this, 'startDate', () => this.startDateUpdated());
    autorun(() => this.emitOnChange());
  }

  emitOnChange() {
    if (this.onChange) {
      this.onChange({
        enrolments: this.enrolments,
        employeeInsuranceNumbers: this.insuranceNumbers
      });
    }
  }

  @action startDateUpdated() {
    this.enrollmentViewModels.forEach(enrollmentViewModel => enrollmentViewModel.updateEnrolmentDate(this.startDate));
  }

  restoreEnrolments() {
    for (const enrolment of this.employeeBenefitEnrollments) {
      const carrier = this.findInsuranceCarrierForEnrolment(enrolment);

      if (!carrier) throw new Error(`Can't find insurance carrier with benefit class ${enrolment.benefitClass.id}`);

      const viewModel = _.find(
        this.enrollmentViewModels,
        {record: {companyInsuranceCarrier: carrier}}
      );

      const benefitClass = _.find(carrier.benefitClasses, {id: enrolment.benefitClass.id});

      viewModel.record.merge({
        benefitClass,
        enrolmentDate: enrolment.enrolmentDate,
        probation: enrolment.probation,
        probationTimePeriod: enrolment.probationTimePeriod || benefitClass.probationTimePeriod
      });

      if (viewModel.record.probation === null) {
        viewModel.setHasProbation('waived');
      } else if (viewModel.record.probation >= 0) {
        viewModel.setHasProbation('probation');
      }
    }
  }

  @action addMissingEmployeeInsuranceNumbers() {
    this.companyInsuranceCarriers.forEach((carrier) => {
      const existingInsuranceNumber = _.find(this.employeeInsuranceNumbers, (insuranceNumber) => {
        return insuranceNumber.companyInsuranceCarrier.id === carrier.id;
      });

      if (!existingInsuranceNumber) {
        this.employeeInsuranceNumbers.push(
          new EmployeeInsuranceNumber({companyInsuranceCarrier: carrier})
        );
      }
    });
  }

  findInsuranceCarrierForEnrolment(enrolment) {
    return _.find(
      this.companyInsuranceCarriers,
      c => _.some(c.benefitClasses, {id: enrolment.benefitClass.id})
    );
  }

  @computed get enrolments() {
    return this.enrollmentViewModels.map(m => m.record);
  }

  @computed get insuranceNumbers() {
    return _(this.enrollmentViewModels).map(m => m.insuranceNumber).compact().value();
  }
}

export default BenefitEnrolmentListState;
