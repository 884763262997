import React from 'react';

const StyleGuideParameterRows = ({name, type, description, values, index}) => {
  return (
    <li className={`${index === 0 ? 'border-top' : ''}`}>
      <div className='clearfix table'>
        <div className='col-3 table-cell align-middle pr1 dodger'>{name}</div>
        <div className='col-2 table-cell align-middle pr1 jumbo'>{type}</div>
        <div className='col-4 table-cell align-middle pr1 jumbo'>{description}</div>
        <div className='col-3 table-cell align-middle pl1 jumbo'>{values}</div>
      </div>
    </li>
  );
};

export default StyleGuideParameterRows;
