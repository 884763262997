import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class OptionalCoverageEnrolment extends DomainObject {
  @observable groupBenefitCoverageId;
  @observable coverageTypeTitle;
  @observable carrierName;
  @observable enrolled;
  @observable cost;
}

export default OptionalCoverageEnrolment;
