import React from 'react';
import {observer} from 'mobx-react';
import LoginContainerWrapper from '../../LoginContainerWrapper';
import LoginFormHeader from '../../LoginFormHeader';
import {FormLayout, Input, Button, ActionLink, Select2} from 'components';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';
import {countriesList} from '../tools';

const MFAUserCreation = observer(({uiState}) => {
  const {mfaUser, errors} = uiState;

  return (
    <LoginContainerWrapper>
      <LoginFormHeader message='login.SMS Authentication Setup' />
      <div className='medium'>
        <FormattedMessage id='login.Mobile phone number' />
      </div>
      <div className='mb2'>
        <FormattedMessage id='login.SMS_DESCRIPTION'/>
      </div>
      <FormLayout>
        <Input
          connectedLeft={
            <div className='IntlPhoneSelect'>
              <Select2
                searchable
                onChange={e => mfaUser.merge({countryCode: e.target.value})}
                value={mfaUser.countryCode}
              >
                {countriesList().map(country =>
                  <option key={country.key} value={country.value}>{country.key}</option>)}
              </Select2>
            </div>
          }
          value={mfaUser.cellphone}
          onChange={e => mfaUser.merge({cellphone: e.target.value})}
          errorMessage={errors.base || errors.cellphone}
        />
      </FormLayout>
      <div className='table'>
        <div className='col-6 table-cell align-middle'>
          <ActionLink onClick={() => uiState.goToOptionSelection()}>
            <FormattedMessage id='login.Return to selection'/>
          </ActionLink>
        </div>
        <div className='col-6 table-cell align-middle right-align'>
          <Button onClick={() => uiState.setupSMS()}>
            <FormattedMessage id='components.tables.Next'/>
          </Button>
        </div>
      </div>
    </LoginContainerWrapper>
  );
});

export default MFAUserCreation;
