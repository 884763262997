import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Modal, ModalButtons} from 'components/modals';
import BulkEditInputFactory from 'containers/mass_updates/edit/components/BulkEditInputFactory';
import {FormattedMessage} from 'react-intl';

const BulkEditModal = observer(({uiState}) => {
  const {bulkEditField, bulkEditModalOpen} = uiState;

  return (
    <Modal
      size='sm'
      title={t('mass_updates.edit.Bulk Edit')}
      isOpen={bulkEditModalOpen}
      onHide={() => uiState.closeBulkEditModal()}
    >
      <div className='mb2'>
        <FormattedMessage id={`mass_updates.edit.bulk_edit_explainer.${bulkEditField}`} />
      </div>
      <BulkEditInputFactory uiState={uiState} field={bulkEditField} />
      <ModalButtons
        saveCaption='mass_updates.edit.Apply'
        onSave={() => uiState.applyBulkEdit()}
        onCancel={() => uiState.closeBulkEditModal()}
      />
    </Modal>
  );
});

export default BulkEditModal;
