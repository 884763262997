import {action, observable} from 'mobx';
import {endpoints, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import {InterviewGuide} from 'stores/interview_guides';

class InterviewGuideState {
  store = new DomainStore();
  history;

  @observable interviewGuides = [];
  @observable errors = {};

  constructor(history) {
    this.history = history;
  }

  @action async load() {
    await this.store._compose([
      endpoints.INTERVIEW_GUIDES
    ]);

    this.interviewGuides = this.store._getAll(types.INTERVIEW_GUIDE, InterviewGuide);
  }

  @action async deleteInterviewGuide(model) {
    await this.store.destroy(model);
    await this.load();
  }
}

export default InterviewGuideState;
