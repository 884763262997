import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'components/modals';
import {DocumentActionSelector, DocumentTypeSelector} from 'components';
import DocumentUploadForm from './DocumentUploadForm';
import AddAssigneeForm from './AddAssigneeForm';

const AssignDocumentModal = observer(({choosingDocumentAction, assigningExistingDocument, choosingDocumentType, uiState }) => {
  let content;

  if (choosingDocumentAction) {
    content = <DocumentActionSelector onActionSelected={(action) => uiState.selectDocumentAction(action)}/>;
  } else if (assigningExistingDocument) {
    content = <AddAssigneeForm uiState={uiState} />;
  } else if (choosingDocumentType) {
    content = <DocumentTypeSelector onTypeSelected={(type) => uiState.selectDocumentType(type)}/>;
  } else {
    content = <DocumentUploadForm uiState={uiState} />;
  }

  return (
    <Modal
      size='md'
      closeButton={false}
      isOpen={uiState.assignDocumentModalOpen}
      onHide={() => uiState.closeAssignDocumentModal()}
    >
      {content}
    </Modal>
  );
});

export default AssignDocumentModal;
