import {observable, action, computed} from 'mobx';
import _ from 'lodash';

class FlowState {
  history;
  localization = {};

  @observable activeStep;
  @observable deleteModalOpen = false;
  @observable steps;
  @observable onStepSubmitted;
  @observable onDeleted;
  @observable onStepChanged;
  @observable nextEnabled = true;

  constructor({history, steps}) {
    this.history = history;
  }

  @action updateProps({localization, steps, onStepSubmitted, onDeleted, onStepChanged, nextEnabled}) {
    this.steps = steps;
    this.onStepSubmitted = onStepSubmitted;
    this.onDeleted = onDeleted;
    this.onStepChanged = onStepChanged;
    this.nextEnabled = nextEnabled;

    _.merge(this.localization, localization);
  }

  @action async submitStep() {
    const nextStep = this.steps.after(this.activeStep);

    const nextLocation = await this.__continue();
    if (nextLocation === false || !nextStep) return;

    const nextPath = _.get(nextLocation, 'nextLocation');

    this.history.push(nextPath || nextStep.location);
  }

  @action goBack() {
    this.history.push(this.steps.before(this.activeStep).location);
  }

  @action async __continue() {
    if (!this.onStepSubmitted) return true;

    return this.onStepSubmitted(this.activeStep);
  }

  @action async setCurrentStep(location) {
    this.activeStep = this.steps.byLocation(location);

    if (!this.onStepChanged) return null;

    return this.onStepChanged(this.activeStep);
  }

  @action async openDeleteModal() {
    this.deleteModalOpen = true;
  }

  @action closeDeleteModal() {
    this.deleteModalOpen = false;
  }

  @action async confirmDeletion() {
    return this.onDeleted();
  }

  @computed get backEnabled() {
    return this.steps.first().location !== this.activeStep.location;
  }

  @computed get deleteEnabled() {
    return !!this.onDeleted;
  }

  @computed get isFinalStep() {
    return this.steps.last().location === this.activeStep.location;
  }
}

export default FlowState;
