import {DomainObject} from 'shared/store';
import {observable, computed} from 'mobx';
import {fullName} from 'shared/tools';

class ExternalUser extends DomainObject {
  @observable id;
  @observable firstName;
  @observable lastName;
  @observable isPendingInvitation;
  @observable email;
  @observable assignedRoles = [];

  @computed get name() {
    return fullName(this);
  }

  @computed get status() {
    return this.isPendingInvitation ? 'Invite Pending' : 'Active';
  }
}

export default ExternalUser;
