import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {Input, FormLayout} from 'components';

const EditContactInfoModal = observer(({uiState}) => {
  const {editEmployee, errors} = uiState;

  return (
    <Modal isOpen={uiState.contactInfoModalOpen} onHide={() => uiState.resetModals()} size='md'>
      <div className='h2 mb3'><FormattedMessage id='employees.profile.personal.Edit Contact Information'/></div>
      <form>
        <FormLayout>
          <Input
            value={editEmployee.workEmail}
            label='employees.profile.personal.Work Email'
            icon='business'
            errorMessage={errors.workEmail}
            onChange={(e) => editEmployee.merge({workEmail: e.target.value})}
          />
          <Input
            value={editEmployee.personalEmail}
            label='employees.profile.personal.Personal Email'
            icon='home'
            errorMessage={errors.personalEmail}
            onChange={(e) => editEmployee.merge({personalEmail: e.target.value})}
          />
          <Input
            value={editEmployee.workPhone}
            label='employees.profile.personal.Work Phone'
            icon='business'
            errorMessage={errors.workPhone}
            onChange={(e) => editEmployee.merge({workPhone: e.target.value})}
          />
          <Input
            value={editEmployee.workPhoneExtension}
            label='employees.profile.personal.Work Phone Extension'
            errorMessage={errors.workPhoneExtension}
            onChange={(e) => editEmployee.merge({workPhoneExtension: e.target.value})}
          />
          <Input
            value={editEmployee.personalPhone}
            label='employees.profile.personal.Personal Phone'
            icon='phone_iphone'
            errorMessage={errors.personalPhone}
            onChange={(e) => editEmployee.merge({personalPhone: e.target.value})}
          />
        </FormLayout>
        <ModalButtons onSave={() => uiState.saveContactInfo()} onCancel={() => uiState.resetModals()} />
      </form>
    </Modal>
  );
});

export default EditContactInfoModal;
