import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = ({value, className, disabled, children, trait}, context) => {
  const {name, selectedValue, onChange} = context.radioButtonGroup;

  return (
    <label className={`Radio-button ${className}`}>
      <input
        type='radio'
        name={name}
        value={value}
        onChange={() => onChange(name, value)}
        checked={selectedValue === value}
        disabled={disabled}
      />
      <span className={`Radio-button-circle ${trait}`}></span>
      <span className={`h4 align-top ${disabled ? 'waterloo' : ''}`}>{children}</span>
    </label>
  );
};

RadioButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]),
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  trait: PropTypes.oneOf([
    'default',
    'error'
  ]),
};

RadioButton.defaultProps = {
  trait: 'default'
};

RadioButton.contextTypes = {
  radioButtonGroup: PropTypes.object
};

export default RadioButton;
