import React from 'react';
import Report from '../Report';
import {QuickTip} from 'components';
import CompositeReport from '../CompositeReport';
import {FormattedMessage} from 'react-intl';
import {auth, t} from 'shared/core';

const filters = [
  'Candidate Status',
  'Position'
];

const CandidatesReport = () => {
  return (
    <React.Fragment>
      <div className='mb2'>
        <QuickTip
          trait='info'
          header={t('reports.candidates.Viewing Candidate Answers')}
          paragraphs={[
            <div>
              <FormattedMessage id='reports.candidates.QUICKTIP_BODY'/>
              <a className='nowrap' href={`http://support.collage.co/${auth.locale}/articles/7017026`} target='_blank'><FormattedMessage id='reports.candidates.Learn more.'/></a>
            </div>
          ]}
        />
      </div>
      <CompositeReport filters={filters}>
        <Report name={t('reports.CANDIDATES')}
                url='/reports/candidates'>
        </Report>
      </CompositeReport>
    </React.Fragment>
  );
};

export default CandidatesReport;
