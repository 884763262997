import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import Highlight from 'react-highlighter';

const GenericSuggestion = observer(({viewModel, query, translatable}) => {
  return (
    <a href={viewModel.data.link} className='tuatara text-decoration-none'>
      <div className={`GlobalSearch-item ${viewModel.selected ? 'selected' : ''}`}>
        <Highlight search={query}>
          {translatable ? <FormattedMessage id={`global_search.items.${viewModel.data.caption}`}/> : viewModel.data.caption}
        </Highlight>
      </div>
    </a>
  );
});

export default GenericSuggestion;
