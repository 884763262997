import React from 'react';
import LayoutContext from './LayoutContext';
import LayoutSection from './LayoutSection';
import _ from 'lodash';

const Layout = ({children, centered, condensed}) => {
  let hasSecondary = false;
  let layoutSections = false;

  React.Children.forEach(children, c => {
    if (!c) return;

    if (c.props.secondary) {
      hasSecondary = true;
    }
    if (c.type === LayoutSection) {
      layoutSections = true;
    }
  });

  const layout = {
    childrenCount: React.Children.count(children),
    hasSecondary,
    centered,
    condensed
  };

  if (layout.centered && layout.childrenCount > 1) {
    throw new Error(`Centered layouts can only have one section. You have defined ${layout.childrenCount} sections.`);
  }

  return (
    <LayoutContext.Provider value={layout}>
      <div className='Container'>
        <div className={`clearfix ${layoutSections ? 'mxn1' : ''}`}>
          {children}
        </div>
      </div>
    </LayoutContext.Provider>
  );
};

export default Layout;
