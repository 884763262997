import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Button} from 'components';
import {AlertModal} from 'components/modals';

const InterestedInBenefitsModal = observer(({uiState}) => {
  return (
    <AlertModal
      size='sm'
      header='benefits.not_enabled.Great!'
      body='benefits.not_enabled.Our benefits team has been notified and will contact you shortly.'
      isOpen={uiState.interestedInBenefitsModalOpen}
      onHide={() => uiState.closeInterestedInBenefitsModal()}
    >
      <Button className='right' onClick={() => uiState.closeInterestedInBenefitsModal()}>
        <FormattedMessage id='benefits.not_enabled.Got it!'/>
      </Button>
    </AlertModal>
  );
});

export default InterestedInBenefitsModal;
