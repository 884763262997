import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import TimeOffTypePolicy from './TimeOffTypePolicy';
import _ from 'lodash';

class TimeOffPolicy extends DomainObject {
  @observable id;
  @observable name;
  @observable typePolicies;
  @observable currentYearStartDate;
  @observable workDayLength;
  @observable workWorkDays;
  @observable startDay;
  @observable startMonth;

  @action merge(other) {
    super.merge(other, {
      typePolicies: [TimeOffTypePolicy],
      _dates: ['currentYearStartDate']
    });
  }

  @computed get sortedTypePolicies() {
    return _.sortBy(this.typePolicies, 'type.order');
  }
}

export default TimeOffPolicy;
