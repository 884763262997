import React from 'react';
import _ from 'lodash';
import {Modal} from 'components/modals';
import {observer} from 'mobx-react';
import TaskContent from './TaskContent';
import TaskDetails from './TaskDetails';

const TaskModal = ({uiState}) => {
  const {task, modalOpen} = uiState;
  if (!task) return null;

  return (
    <Modal
      isOpen={modalOpen}
      onHide={() => uiState.closeTaskModal()}
      closeButton={false}
      size='md'
    >
      <TaskDetails uiState={uiState}/>
      <TaskContent uiState={uiState}/>
    </Modal>
  );
};

export default observer(TaskModal);
