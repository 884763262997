import React from 'react';
import {observer} from 'mobx-react';

const BulletPoint = ({text}) => {
  return (
    <div className='table py1'>
      <div className='table-cell align-top bold pr1' style={{color: '#3ACC6C'}}>
        {'✓'}
      </div>
      <div className='table-cell align-top'>
        {text}
      </div>
    </div>
  );
};

const MarketingCopy = observer(({bulletPoints, info, header}) => {
  return (
    <div>
      <div className='h2'>
        {header}
      </div>
      <div className='pt2 jumbo left-align'>
        {!!bulletPoints && bulletPoints.map((bullet, i) => (
          <BulletPoint key={i} text={bullet}/>
        ))}
      </div>
      <div className='pt2 jumbo center'>
        {info}
      </div>
    </div>
  );
});

export default MarketingCopy;
