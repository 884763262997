import React, {useRef, useState} from 'react';
import {Modal, ModalButtons} from 'components/modals';
import {observer} from 'mobx-react';
import {Button, UploadFiles} from 'components';
import {observable} from 'mobx';
import Cropper from 'react-cropper';

const ACCEPTED_IMAGE_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/webp',
  'image/png'
];

const rotate = (cropper, degrees, setIsCropping) => {
  cropper.clear();
  cropper.rotate(degrees);

  // This is necessary to fit the rotated content within the canvas
  // Otherwise the sides will be cut off
  const imgData = cropper.getImageData();
  const containerData = cropper.getContainerData();
  if ([90, 270, -90, -270].includes(imgData.rotate)) {
    const scale = Math.min(
      containerData.height / imgData.width,
      containerData.width / imgData.height,
      1
    );

    cropper.scale(scale);
  } else {
    cropper.scale(1);
  }

  // This is to set the cropper content to the rotated image
  // Otherwise the rotation will be displayed but not registered on save
  cropper.crop();
  cropper.clear();
  setIsCropping(false);
};

const performCrop = (cropper, uploadedImage) => {
  const dataUrl = cropper.getCroppedCanvas().toDataURL(uploadedImage.type);
  cropper.replace(dataUrl);
};

const ImageCropper = observer(({uploadedImage, cropImage, cropperRef}) => {
  const [isCropping, setIsCropping] = useState(false);

  const onCrop = () => {
    setIsCropping(true);
  };

  const ready = () => {
    setIsCropping(false);
  };

  return (
    <React.Fragment>
      <br/>
      <Cropper
        src={cropImage.src}
        style={{height: 400, width: '100%'}}
        ref={cropperRef}
        autoCrop={false}
        autoCropArea={1}
        viewMode={1}
        background={false}
        zoomable={false}
        moveable={false}
        toggleDragModeOnDblclick={false}
        minCropBoxHeight={30}
        minCropBoxWidth={30}
        checkOrientation={false}
        crop={onCrop}
        ready={ready}
      />
      <div className='flex justify-content-center mt2 mb2'>
        {!isCropping && <Button trait='default' className='Btn--icon mr1' onClick={() => cropperRef.current.cropper.crop()}>
          <i className='material-icons'>{'crop'}</i>
        </Button>}
        {isCropping && <Button trait='default' className='Btn--icon mr1' onClick={() => performCrop(cropperRef.current.cropper, uploadedImage)}>
          <i className='material-icons'>{'preview'}</i>
        </Button>}
        <div className='divider'/>
        <Button trait='default' className='Btn--icon mx1' onClick={() => rotate(cropperRef.current.cropper, 90, setIsCropping)}>
          <i className='material-icons'>{'rotate_right'}</i>
        </Button>
        <Button trait='default' className='Btn--icon mr1' onClick={() => rotate(cropperRef.current.cropper, -90, setIsCropping)}>
          <i className='material-icons'>{'rotate_left'}</i>
        </Button>
        <div className='divider'/>
        <Button trait='default' className='Btn--icon ml1' onClick={() => {cropperRef.current.cropper.replace(uploadedImage.preview); cropperRef.current.cropper.reset();}}>
          <i className='material-icons'>{'cached'}</i>
        </Button>
      </div>
    </React.Fragment>
  );
});

const ImageUploadModal = observer(({isOpen, onHide, onSave}) => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [cropImage] = useState(new Image());
  const cropperRef = useRef(null);

  return (
    <Modal
      isOpen={isOpen}
      onHide={() => onHide()}
      size='md'
    >
      {!uploadedImage && <UploadFiles
        defaultFiles={observable([])}
        onChange={files => {setUploadedImage(files[0]); cropImage.src = files[0].preview;}}
        maxFiles={1}
        uploadToAws={false}
        accept={ACCEPTED_IMAGE_TYPES.join()}/>}
      {uploadedImage && <ImageCropper uploadedImage={uploadedImage} cropperRef={cropperRef} cropImage={cropImage}/>}
      <ModalButtons
        saveEnabled={!!uploadedImage}
        onSave={() => new Promise(resolve => {
            cropperRef.current.cropper.getCroppedCanvas().toBlob(
            async (blob) => {
              await onSave(blob);
              setUploadedImage(null);
              onHide();
              resolve();
            });
        })}
        onCancel={() => {
          setUploadedImage(null);
          onHide();
        }}
      />
    </Modal>
  );
});

export default ImageUploadModal;
