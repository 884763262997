import _ from 'lodash';
import {observable, computed, action} from 'mobx';

class AvatarListState {
  @observable moreAvatarsModalOpen = false;
  @observable size = 'sm';
  @observable type = 'overlap';
  @observable limit = 3;
  @observable linkable = false;
  @observable employees;
  @observable moreModalEnabled = false;

  receiveProps({size, type, employees, limit, linkable, moreModalEnabled}) {
    this.size = size || this.size;
    this.type = type || this.type;
    this.limit = limit || this.limit;
    this.linkable = linkable || this.linkable;
    this.employees = employees;
    this.moreModalEnabled = moreModalEnabled;
  }

  @action openMoreAvatarsModal() {
    if (this.moreModalEnabled) {
      this.moreAvatarsModalOpen = true;
    }
  }

  @action closeMoreAvatarsModal() {
    this.moreAvatarsModalOpen = false;
  }

  @computed get moreEmployeesOption() {
    return this.limit < this.employees.length;
  }

  @computed get moreAvatar() {
    return {
      size: this.size,
      className: 'Avatar--more',
      employee: {
        avatarInitials: `+${this.employees.length - this.limit}`,
        avatarColour: 'bg-whale'
      }
    };
  }

  @computed get avatarListSize() {
    return this.size === 'xs' ? 'sm' : this.size;
  }

  @computed get employeeList() {
    return _.take(this.employees, this.limit);
  }

  @computed get moreEmployeesList() {
    return _.difference(this.employees, this.employeeList);
  }

  calculateZIndex(index) {
    if (this.type !== 'overlap') {
      return 'auto';
    }

    return 300 - index;
  }
}

export default AvatarListState;
