import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {endpoints, types} from 'shared/core';
import {Survey, SurveyQuestion} from 'stores/surveys';
import _ from 'lodash';
import QUESTION_TYPES from 'stores/surveys/surveyQuestionTypes';

class SurveyFlowState {
  store = new DomainStore();
  match;
  history;

  @observable survey;
  @observable errors = {};
  @observable editingQuestion;
  @observable editQuestionModalOpen = false;

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    const surveyId = this.match.params.id;
    await this.store._compose([
      endpoints.SURVEYS.SURVEY.with(surveyId)
    ]);

    this.survey = new Survey(this.store._getSingle(types.SURVEYS.SURVEY));
  }

  @action async onStepSubmitted({location}) {
    switch (location) {
      case 'general': return this.submitGeneral();
      case 'questions': return this.submitQuestions();
      case 'review': return this.launchSurvey();
      default:
        throw new Error(`Location ${location} is not supported`);
    }
  }

  @action async submitStep(props) {
    const survey = this.survey.pick(props);
    const {model, errors} = await this.store.patch(survey);

    this.errors = errors;
    if (!model) return false;

    this.survey.update(model);
    return true;
  }

  @action async submitGeneral() {
    return this.submitStep(['name', 'anonymous', 'currentPeriodDate']);
  }

  @action async submitQuestions() {
    return this.submitStep(['questions']);
  }

  @action async submitOrders() {
    return this.submitStep(['questions']);
  }

  @action async launchSurvey() {
    this.survey.launchingSurvey = true;
    const modelUpdated = await this.submitStep(['launchedAt', 'launchingSurvey']);
    if (modelUpdated) this.history.push(`/manage/survey/${this.match.params.id}/admin`);
  }

  @action editQuestion(question) {
    this.editingQuestion = new SurveyQuestion(question);
    this.editQuestionModalOpen = true;
  }

  @action addQuestion() {
    this.editQuestion({
      text: '',
      order: this.survey.questions.length,
      questionType: QUESTION_TYPES[0],
      name: ''
    });
  }

  @action saveQuestion() {
    if (!this.validateQuestion()) return;

    const existingQuestion = _.find(this.survey.questions, q => q.order === this.editingQuestion.order);

    if (existingQuestion) {
      existingQuestion.update(this.editingQuestion);
    } else {
      this.survey.questions.push(this.editingQuestion);
    }
    this.closeEditQuestionModal();
  }

  @action validateQuestion() {
    this.errors = this.editingQuestion.validate();

    return _.isEmpty(this.errors);
  }

  @action closeEditQuestionModal() {
    this.editQuestionModalOpen = false;
  }

  @action removeQuestion(question) {
    this.survey.questions.remove(question);
  }

  @action toggleSurveyAnonymous() {
    this.survey.merge({anonymous: !this.survey.anonymous});
  }
}

export default SurveyFlowState;
