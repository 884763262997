import React from 'react';
import {observer} from 'mobx-react';
import LoginContainerWrapper from '../../LoginContainerWrapper';
import LoginFormHeader from '../../LoginFormHeader';
import {FormLayout, Input, Button, ActionLink} from 'components';
import {FormattedMessage} from 'react-intl';

const AuthenticatorSteps = observer(({mfaUser, qrCode}) => {
  return (
    <div>
      <LoginFormHeader message='login.Authenticator App Setup'/>
      <div className='medium'>
        <FormattedMessage id='login.Steps to setup OTP' />
      </div>
      <ul>
        <li className='ml2 mb1'>
          <div>
            <FormattedMessage id='login.Download the authenticator app of your choice'/>
          </div>
          <div>
            <FormattedMessage id='login.(e.g. Authy, Google, LastPass)'/>
          </div>
        </li>
        <li className='ml2 mb1'>
          <FormattedMessage id='login.Set up a new account'/>
        </li>
        <li className='ml2 mb1'>
          <FormattedMessage id='login.Scan QR code shown below'/>
        </li>
        <li className='ml2 mb1'>
          <FormattedMessage id='login.Enter the verification code shown on authenticator app.'/>
        </li>
      </ul>
      <div className='center'>
        <img alt='QR Code' src={qrCode} />
      </div>
    </div>
  );
});

const SMSSteps = observer(({uiState}) => {
  return (
    <div>
      <LoginFormHeader message='login.Verify your phone'/>
      <div className='medium'>
        <FormattedMessage id='login.Verify your phone number' />
      </div>
      <div className='mb2'>
        <FormattedMessage id='login.Enter the verification code sent to your phone.'/>
      </div>
    </div>
  );
});

const MFAVerification = observer(({uiState}) => {
  const {mfaUser, errors} = uiState;

  return (
    <LoginContainerWrapper>
      {mfaUser.mfaType === 'sms' ?
        <SMSSteps /> : <AuthenticatorSteps mfaUser={mfaUser} qrCode={mfaUser.mfaParameter} />}
      <FormLayout>
        <Input value={mfaUser.mfaToken}
          onChange={e => mfaUser.merge({mfaToken: e.target.value})}
          errorMessage={errors.base || errors.mfaToken}
        />
      </FormLayout>
      <div className='table'>
        <div className='col-6 table-cell align-middle'>
          <ActionLink onClick={() => uiState.goToOptionSelection()}>
            <FormattedMessage id='login.Return to selection'/>
          </ActionLink>
        </div>
        <div className='col-6 table-cell align-middle right-align'>
          <Button onClick={() => uiState.verifyPhoneNumber()}>
            <FormattedMessage id='login.Verify'/>
          </Button>
        </div>
      </div>
    </LoginContainerWrapper>
  );
});

export default MFAVerification;
