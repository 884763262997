import React from 'react';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import ListIcon from 'img/list-icon@1x.png';
import FileIcon from 'img/file-icon@1x.png';
import GraphIcon from 'img/graph-icon@1x.png';
import TimeOffIcon from 'img/time-off-icon@1x.png';

const FeaturePanelItems = ({panel1, panel2}) => {
  return (
    <div className='flex flex-col md-flex-row'>
      <div className='col col-12 mb2 md-col-6 md-pr1'>
        <Panel className='full-height grid'>
          <div className='flex md-flex-row align-items-center justify-content-center'>
            <span className='mr3'>
              <img src={panel1.image} alt={panel1.imageAlt}/>
            </span>
            <span>
              <Panel.Header title={t(panel1.header)}/>
              <div className='jumbo'>
                <FormattedMessage id={panel1.body}/>
              </div>
            </span>
          </div>
        </Panel>
      </div>
      <div className='col col-12 mb2 md-col-6 md-pl1'>
        <Panel className='full-height grid'>
          <div className='flex md-flex-row align-items-center justify-content-center'>
            <span className='mr3'>
              <img src={panel2.image} alt={panel2.imageAlt}/>
            </span>
            <span>
              <Panel.Header title={t(panel2.header)}/>
              <div className='jumbo'>
                <FormattedMessage id={panel2.body}/>
              </div>
            </span>
          </div>
        </Panel>
      </div>
    </div>
  );
};

const TimeTrackingFeatures = observer(({uiState}) => {
  return (
    <div>
       <div className='h2 py2'>
        <FormattedMessage id='time_tracking.onboarding.Features'/>
      </div>

      <FeaturePanelItems
        panel1={{
          header: 'time_tracking.onboarding.Timesheets',
          body: 'time_tracking.onboarding.Keep track of your employee timesheets in one place online. Collage HR makes employee timesheets secure, convenient, and accessible.',
          image: ListIcon,
          imageAlt: 'list_icon'
        }}
        panel2={{
          header: 'time_tracking.onboarding.Custom Policies',
          body: 'time_tracking.onboarding.Create different time tracking policies with custom pay schedules, overtime rules, and timesheet approvers.',
          image: FileIcon,
          imageAlt: 'file_icon'
        }}
        />
      <FeaturePanelItems
        panel1={{
          header: 'time_tracking.onboarding.Overtime Rules',
          body: 'time_tracking.onboarding.Apply the standard provincial labour overtime rules for Canadian employees, or create custom daily and weekly overtime rules.',
          image: TimeOffIcon,
          imageAlt: 'time_off_icon'
        }}
        panel2={{
          header: 'time_tracking.onboarding.Payroll Reports',
          body: 'time_tracking.onboarding.View reports on employee hours for each pay period, and export them with a single click.',
          image: GraphIcon,
          imageAlt: 'graph_icon'
        }}
      />
    </div>
  );
});

export default TimeTrackingFeatures;
