import {action, computed, observable} from 'mobx';
import {auth, t, api, endpoints, types} from 'shared/core';
import Employee from 'stores/employees/Employee';
import {successAlert} from 'shared/tools';
import {DomainStore} from 'shared/store';
import _ from 'lodash';

class CompanyOnboardState {
  store = new DomainStore();
  EMAIL_REGEX = /^[^.@][^@]*@[^.@]+(\.[^.@]+)*\.[^@.]+$/;

  @observable company;
  @observable newEmployees = [];
  @observable employees = [];
  @observable employee = new Employee();
  @observable previewInviteModalOpen = false;
  @observable activateEmailModalOpen = false;
  @observable errors = {};

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.CURRENT_COMPANY,
      endpoints.COMPANY_EMAIL_TEMPLATES,
    ]);

    this.company = this.store._getSingle(types.COMPANY, {id: auth.company.id});
    this.employees = this.store.getEmployees();
    this.emailPreview = this.store._getSingle(types.COMPANY_EMAIL_TEMPLATE, {emailType: 'employee_invitation'});
  }

  @action async saveEmployee(focusInputCallback) {
    const {model, errors} = await this.store.post(
      endpoints.COMPANY_ONBOARD.ADD_EMPLOYEE,
      types.EMPLOYEE_DETAILS,
      this.employee
    );
    this.errors = errors;

    if (model) {
      this.addNewEmployees([model]);
      this.employee = new Employee();
      focusInputCallback();
    }
  }

  @action async resendActivationEmail() {
    await api.post(endpoints.COMPANY_ONBOARD.RESEND_ACTIVATION_EMAIL);
    this.closePreviewInvite();
    successAlert(t('company_onboard.Email confirmation sent.'));
  }

  @action openPreviewInvite() {
    if (auth.user.activated) {
      this.previewInviteModalOpen = true;
    } else {
      this.activateEmailModalOpen = true;
    }
  }

  @action closePreviewInvite() {
    this.previewInviteModalOpen = false;
    this.activateEmailModalOpen = false;
  }

  @action addNewEmployees(employees) {
    const newEmployees = employees.map(e => new Employee(e));
    this.newEmployees = this.newEmployees.concat(newEmployees);
  }

  @computed get currentEmployeeEmail() {
    return _.get(_.find(this.employees, { id: auth.employee.id }), 'invitationEmail');
  }

  @computed get hasEmployees() {
    return this.employees.length > 1 || !!this.newEmployees.length;
  }

  @computed get validEmployee() {
    const firstName = this.employee.firstName;
    const lastName = this.employee.lastName;

    if (!firstName || !lastName) return null;

    return firstName.replace(/\s/g, '') && lastName.replace(/\s/g, '') && this.validEmail;
  }

  @computed get validEmail() {
    return this.EMAIL_REGEX.test(this.employee.invitationEmail);
  }

  @computed get sortedEmployees() {
    return _.orderBy(this.employees, ['firstName'], ['desc']);
  }

  @computed get sortedNewEmployees() {
    return _.orderBy(this.newEmployees, ['firstName'], ['desc']);
  }

  @computed get stepOneComplete() {
    return this.hasEmployees;
  }

  @computed get stepTwoComplete() {
    return auth.company.subscriptionEnabled;
  }
}

export default CompanyOnboardState;
