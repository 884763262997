import DataPermission from './DataPermission';

const dataPermissions = [
  {
    name: 'PERSONAL_INFORMATION',
    descriptions: {
      noAccess: 'PERSONAL_INFORMATION_NO_ACCESS',
      viewOnly: 'PERSONAL_INFORMATION_VIEW_ONLY',
      viewAndEdit: 'PERSONAL_INFORMATION_VIEW_AND_EDIT'
    }
  },
  {
    name: 'ROLE_INFORMATION',
    descriptions: {
      noAccess: 'ROLE_INFORMATION_NO_ACCESS',
      viewOnly: 'ROLE_INFORMATION_VIEW_ONLY',
      viewAndEdit: 'ROLE_INFORMATION_VIEW_AND_EDIT'
    }
  },
  {
    name: 'TIME_OFF',
    descriptions: {
      noAccess: 'TIME_OFF_NO_ACCESS',
      viewOnly: 'TIME_OFF_VIEW_ONLY',
      viewAndEdit: 'TIME_OFF_VIEW_AND_EDIT_WITH_NOTIFICATION'
    }
  },
  {
    name: 'TIME_TRACKING',
    descriptions: {
      noAccess: 'TIME_TRACKING_NO_ACCESS',
      viewOnly: 'TIME_TRACKING_VIEW_ONLY',
      viewAndEdit: 'TIME_TRACKING_VIEW_AND_EDIT'
    }
  },
  {
    name: 'COMPENSATION_INFORMATION',
    descriptions: {
      noAccess: 'COMPENSATION_INFORMATION_NO_ACCESS',
      viewOnly: 'COMPENSATION_INFORMATION_VIEW_ONLY',
      viewAndEdit: 'COMPENSATION_INFORMATION_VIEW_AND_EDIT'
    }
  },
  {
    name: 'BANKING_INFORMATION',
    descriptions: {
      noAccess: 'BANKING_INFORMATION_NO_ACCESS',
      viewOnly: 'BANKING_INFORMATION_VIEW_ONLY',
      viewAndEdit: 'BANKING_INFORMATION_VIEW_AND_EDIT'
    }
  },
  {
    name: 'EMERGENCY_CONTACT_INFORMATION',
    descriptions: {
      noAccess: 'EMERGENCY_CONTACT_INFORMATION_NO_ACCESS',
      viewOnly: 'EMERGENCY_CONTACT_INFORMATION_VIEW_ONLY',
      viewAndEdit: 'EMERGENCY_CONTACT_INFORMATION_VIEW_AND_EDIT'
    }
  },
  {
    name: 'DOCUMENTS',
    descriptions: {
      noAccess: 'DOCUMENTS_NO_ACCESS',
      viewOnly: 'DOCUMENTS_VIEW_ONLY',
      viewAndEdit: 'DOCUMENTS_VIEW_AND_EDIT'
    }
  },
  {
    name: 'NOTES',
    descriptions: {
      noAccess: 'NOTES_NO_ACCESS',
      viewOnly: 'NOTES_VIEW_ONLY',
      viewAndEdit: 'NOTES_VIEW_AND_EDIT'
    }
  },
  {
    name: 'TASKS',
    descriptions: {
      noAccess: 'TASKS_NO_ACCESS',
      viewOnly: 'TASKS_VIEW_ONLY',
      viewAndEdit: 'TASKS_VIEW_AND_EDIT'
    }
  },
  {
    name: 'PERFORMANCE_GOALS',
    descriptions: {
      noAccess: 'PERFORMANCE_NO_ACCESS',
      viewOnly: 'PERFORMANCE_VIEW_ONLY',
      viewAndEdit: 'PERFORMANCE_VIEW_AND_EDIT'
    }
  }
];

export default dataPermissions.map(d => new DataPermission(d));
