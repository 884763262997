import {action, computed, observable} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import CustomReport from 'stores/reports/CustomReport';
import filterRowFactory from './filterRowFactory';
import _ from 'lodash';

class CustomReportState {
  match;
  store = new DomainStore();

  @observable customReport;
  @observable filterRows = [];

  receiveProps({match}) {
    this.match = match;
  }

  @action async load() {
    await this.store._compose([
      endpoints.CUSTOM_REPORT.with(this.match.params.id)
    ]);
    this.customReport = new CustomReport(this.store._getSingle(types.CUSTOM_REPORT));
    await this.initializeFilterRows();
  }

  @action async initializeFilterRows() {
    const filterRows = this.customReport.filter.map(
      model => filterRowFactory({model, customReportId: this.customReport.id})
    );
    for (const filter of filterRows) {
      if (filter.load) {
        await filter.load();
        filter.loaded = true;
      }

      const row = _.find(this.customReport.filter, { attribute: filter.row.attribute });
      if (this.customReport.sharedBy) {
        filter.viewOnly = true;
      }
      filter.row.merge(row);
      filter.editingRow.merge(row);
    }

    this.filterRows = filterRows;
  }

  @computed get disableFilterEditing() {
    return !!this.customReport.sharedBy;
  }
}

export default CustomReportState;
