import {observable, action} from 'mobx';
import PositionViewModel from './PositionViewModel';
import {endpoints, types, t} from 'shared/core';

class RecruitingDirectoryState {
  store;
  history;
  match;

  @observable publishModalOpen = false;
  @observable publishedPosition;

  @observable statusOptions = [
    {
      id: 'active',
      display: t('recruiting.position.status.Active')
    },
    {
      id: 'inactive',
      display: t('recruiting.position.status.Inactive')
    }
  ]

  constructor(recruitingStore, history, match) {
    this.store = recruitingStore;
    this.history = history;
    this.match = match;
  }

  @action async createNewPosition() {
    const id = await this.store.postPosition();
    this.goToEdit(id);
  }

  @action goToEdit(id) {
    this.history.push(`positions/${id}`);
  }

  @action goToPosting(position) {
    window.open(position.viewPostingLink, '_blank');
  }

  @action async destroyPosition(position) {
    await this.store.destroyPosition(position);
  }

  customLinksFor(position) {
    if (!position.hasLink('duplicate')) {
      return [];
    }

    return [{
      order: 1,
      text: 'recruiting.directory.Duplicate',
      action: position => this.duplicatePosition(position)
    }];
  }

  @action async duplicatePosition(position) {
    const {model} = await this.store.post(
      endpoints.RECRUITING.POSITION.DUPLICATE.with(position.id),
      types.RECRUITING.POSITION
    );

    this.goToEdit(model.id);
  }

  @action openPublishModal(position) {
    this.publishModalOpen = true;
    this.publishedPosition = new PositionViewModel(position);
  }

  @action closePublishModal() {
    this.publishModalOpen = false;
  }
}

export default RecruitingDirectoryState;
