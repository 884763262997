import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {loader, t} from 'shared/core';
import EmailTemplateEditState from 'recruiting/state/EmailTemplateEditState';
import emailTemplateStore from 'recruiting/state/EmailTemplateStore';
import {Display, Panel, Input, LexicalEditor} from 'components';
import {ModalButtons} from 'components/modals';
import mergeFields from './mergeFields';

const EmailTemplateEditContainer = observer(({uiState}) => {
  const {emailTemplate, errors} = uiState;

  return (
    <div>
      <Display backTitle={t('recruiting.email_templates.Email Templates')} backPath='/email-templates'/>
      <div className='Container pb4'>
        <Panel>
          <div className='clearfix mb3'>
            <div className='col-12 md-col-6'>
              <Input
                value={emailTemplate.name}
                label='recruiting.email_templates.Name'
                className='full-width'
                onChange={(e) => emailTemplate.merge({name: e.target.value})}
                errorMessage={errors.name} />
            </div>
          </div>
          <div className="clearfix mb2">
            <div className="col-12 md-col-6">
              <Input
                value={emailTemplate.subject}
                label='recruiting.email_templates.Subject'
                className='full-width'
                onChange={(e) => emailTemplate.merge({subject: e.target.value})}
                errorMessage={errors.subject} />
            </div>
          </div>
          <div className="clearfix">
            <div className="col-12">
              <label className='block black'>
                <FormattedMessage id='recruiting.email_templates.Message'/>
              </label>
              <LexicalEditor
                placeholder={'recruiting.email_templates.Add email content'}
                onChange={body => emailTemplate.merge({richText: body.html, lexicalState: body.lexicalState})}
                initialEditorState={emailTemplate.lexicalState}
                initialHTML={emailTemplate.richText}
                mergeFields={mergeFields}
              />
            </div>
          </div>
          <ModalButtons
            onSave={() => uiState.updateEmailTemplate()}
            onCancel={() => uiState.goBack()}
          />
        </Panel>
      </div>
    </div>
  );
});

async function load({history, match}) {
  const uiState = new EmailTemplateEditState(emailTemplateStore, history, match);
  await uiState.load();
  return {uiState};
}

export default loader(EmailTemplateEditContainer, load);
