import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {Button, Display, Layout} from 'components';
import SubmitRequestForm from './SubmitRequestForm';
import SubmitRequestState from '../state/SubmitRequestState';
import {FormattedMessage} from 'react-intl';
import SuccessfullySent from './SuccessfullySent';

const SubmitRequestContainer = observer(({uiState}) => {
  if (uiState.statusCode === 'ok') {
    return <div className='Container'><SuccessfullySent uiState={uiState}/></div>;
  }

  return (
    <div className='col-10 left mb4'>
      <Display
        backTitle={t('Dashboard')}
        backPath={'/pa/dashboard'}
        title={t('submit_request.Request')}
      />
      <Layout>
        <SubmitRequestForm uiState={uiState}/>
        <Button className='right mt2' onClick={() => uiState.submit()}>
          <FormattedMessage id='Submit'/>
        </Button>
      </Layout>
    </div>
  );
});

export default withState(SubmitRequestContainer, SubmitRequestState);
