import React from 'react';
import {observer} from 'mobx-react';
import EmployeeDocumentsContainerWithState from './EmployeeDocumentsContainerWithState';
import EmployeeProfileContainer from '../EmployeeProfileContainer';

const EmployeeDocumentsContainer = observer(({uiState}) => {
  return (
    <EmployeeProfileContainer uiState={uiState}>
      <EmployeeDocumentsContainerWithState parentState={uiState}/>
    </EmployeeProfileContainer>
  );
});

export default EmployeeDocumentsContainer;
