import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';

const Field = observer(({title, value}) => {
  return (
    <div className='clearfix mxn2 mb1'>
      <div className='col col-6 px2 jumbo'>
        <FormattedMessage id={`benefit_enrolment.${title}`}/>
      </div>
      <div className='col col-6 px2'>
        {value ? value : t('benefit_enrolment.null')}
      </div>
    </div>
  );
});

export default Field;
