import {DomainObject} from 'shared/store';
import {t} from 'shared/core';
import {observable, action, computed} from 'mobx';
import Position from './Position';
import Location from '../locations/Location';

class InvalidIndeedLocation extends DomainObject {
  @observable location;
  @observable positions;
  @observable missingFields;

  @action merge(other) {
    super.merge(other, {
      location: Location,
      positions: [Position]
    });
  }

  @computed get missingFieldsView() {
    return this.missingFields.map((field) => t(`recruiting.job_site.invalid_locations.missing_fields.${field}`)).join(', ');
  }
}

export default InvalidIndeedLocation;
