import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Avatar, ActionLink, Button} from 'components';
import {QuestionFieldFactory, AnswerResults} from 'components/forms';
import {t} from 'shared/core';
import _ from 'lodash';

const SaveButton = ({detailsState, entry}) => {
  return (
    <Button size='sm' trait='primary' onClick={async () => detailsState.updateEntry(entry)}>
      <FormattedMessage id='recruiting.hiring_funnel.UPDATE_ENTRY' values={{entry: t('recruiting.hiring_funnel.Feedback')}} />
    </Button>
  );
};

const EditInterviewGuideResponse = observer(({entry, detailsState}) => {
  const errors = detailsState.editedEntryErrors;
  const {editedEntry} = detailsState;

  return (
    <div className='clearfix border-top p3'>
      <div className='col col-1 pr1'>
        <Avatar employee={entry.user} size='sm' className='left'/>
      </div>
      <div className='col col-11 pl1'>
        {editedEntry.sortedAnswers.map((answer, index) =>
          <div className='mb3' key={answer.question.id}>
            <QuestionFieldFactory
              errorMessage={_.get(errors, `answers.${index}.content`)}
              answer={answer}
              required={detailsState.calculateQuestionRequired(answer.question)}
              uiState={detailsState}
            />
          </div>
        )}
        <div className='flex align-items-center my2 right'>
          <Button trait='default' size='sm' className='px2' onClick={() => detailsState.cancelInput()}>
            <FormattedMessage id='recruiting.hiring_funnel.Cancel'/>
          </Button>
          <SaveButton entry={entry} detailsState={detailsState}/>
        </div>
      </div>
    </div>
  );
});

const EntryRightMenu = ({detailsState, entry}) => {
  if (!entry.canDelete && !entry.canPatch) return null;
  return (
    <div className='right'>
      <ActionLink className='dropdown'>
        <i className='material-icons submarine align-middle' data-toggle='dropdown'>{'more_horiz'}</i>
        <ul className='dropdown-menu right-0'>
          <li>
            {entry.canPatch && <div onClick={async () => detailsState.editEntry(entry)} className='py1 px3 regular jumbo clickable'>
              <FormattedMessage id='recruiting.hiring_funnel.Edit'/>
            </div>}
            {entry.canDelete && <div onClick={async () => detailsState.deleteEntry(entry)} className='py1 px3 regular jumbo clickable'>
              <FormattedMessage id='recruiting.hiring_funnel.Delete'/>
            </div>}
          </li>
        </ul>
      </ActionLink>
    </div>
  );
};

const InterviewGuideResponseEntry = observer(({entry, detailsState}) => {
  if (detailsState.isBeingEdited(entry)) {
    return <EditInterviewGuideResponse detailsState={detailsState} entry={entry}/>;
  }

  return (
    <div className='clearfix border-top p3'>
      <div className='col col-1 pr1'>
        <Avatar employee={entry.user} size='sm' className='left'/>
      </div>
      <div className='col col-11 pl1'>
        <div className='clearfix'>
          <div className='col col-12'>
            <EntryRightMenu detailsState={detailsState} entry={entry}/>
            <div className='table-cell pr1'>
              <div className='h3 medium'>{entry.interviewGuide.name}</div>
              <div className='h5 jumbo'>{entry.name}</div>
            </div>
            <div className='table-cell pl1'>
              <div className='h5 jumbo'>{entry.date}</div>
            </div>
          </div>
          <div className='col col-12 pt2 whitespace-pre-line'>
            <AnswerResults answers={entry.sortedAnswers} />
          </div>
        </div>
      </div>
    </div>
  );
});

export default InterviewGuideResponseEntry;
