import {action, observable} from 'mobx';
import CompanyDocumentViewModel from 'stores/documents/CompanyDocumentViewModel';
import _ from 'lodash';
import {api, auth} from 'shared/core';
import {serializeModel} from 'shared/store';

class OnboardDocumentState {
  store;

  @observable documentViewModels = [];

  constructor(store) {
    this.store = store;
  }

  @action async load() {
    const models = await this.store.loadDocuments();
    if (_.isEmpty(models)) await this.onAllDocumentsCompleted();

    this.documentViewModels = models.map(d => new CompanyDocumentViewModel(d));
  }

  @action async onAllDocumentsCompleted() {
    await api.patch(`employees/${auth.employee.id}/onboard`, serializeModel({step: 'sign_documents'}));
    window.location = '/onboard';
  }
}

export default OnboardDocumentState;
