import {t} from 'shared/core';
import exporting from './exporting';

function distributionOptions() {
  return {
    title: null,
    legend: {
      enabled: false
    },
    chart: {
      type: 'column',
      height: 350
    },
    xAxis: {
      min: -1,
      max: 5,
      tickWidth: 0,
      labels: {
        formatter: function() {
          if (this.isFirst || this.isLast) return;

          return this.value;
        }
      }
    },
    yAxis: {
      title: {
        text: t('performance_reviews.view.cycle_results.Number of Answers')
      }
    },
    plotOptions: {
      column: {
        minPointLength: 5,
        pointPadding: 0.01,
        groupPadding: 0,
        cursor: 'pointer'
      }
    },
    credits: {
      enabled: false
    },
    exporting
  };
}

export default distributionOptions;
