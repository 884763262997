import React from 'react';
import {Checkbox} from 'components';

const TrainingRow = ({viewModel}) => {
  return (
    <div className='clearfix table py2 border-bottom HoverAnimation-trigger'>
      <div className='col-7 table-cell align-middle'>
        <div className='table-cell align-middle'>
          <Checkbox value={viewModel.selected} checked={viewModel.selected}
                    onChange={e => viewModel.selected = e.checked}/>
        </div>
        <div className='table-cell align-middle pl2'>
          {viewModel.name}
        </div>
      </div>
      <div className='col-5 table-cell align-middle'>{viewModel.dueDate}</div>
    </div>
  );
};

export default TrainingRow;
