import React from 'react';
import {observer} from 'mobx-react';
import {EmployeeCount, Table} from 'components';
import RoleName from 'company_settings/permissions/components/RoleName';

const CUSTOM_ROLE_LIST_COLUMNS = [
  {
    header: 'company_settings.permissions.Name',
    render: model => <RoleName name={model.name}/>,
    width: 5
  },
  {
    header: 'company_settings.permissions.Assigned To',
    render: model => <EmployeeCount count={model.employeeCount}/>,
    width: 6
  }
];

const CUSTOM_ROLE_LIST_LOCALIZATION = {
  removeModal: {
    header: 'company_settings.permissions.Remove role?',
    subHeader: 'company_settings.permissions.Are you sure you want to remove this role?',
    body: 'company_settings.permissions.All employees assigned to this role will lose the permissions associated with it.'
  },
  addModel: 'company_settings.permissions.+ Add Custom Role',
  emptyState: 'company_settings.permissions.No custom roles to display'
};

const CustomRolesList = observer(({uiState}) => {
  return (
    <Table models={uiState.customRoles}
           columns={CUSTOM_ROLE_LIST_COLUMNS}
           localization={CUSTOM_ROLE_LIST_LOCALIZATION}
           onRemove={role => uiState.destroyRole(role)}
           editRoute='/permissions/:id'
           onAdd={() => uiState.postRole()} />
  );
});

export default CustomRolesList;
