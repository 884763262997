import React from 'react';
import moment from 'moment';
import {dateFormatter} from 'shared/tools';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {CandidateFields, JobApplicationQuestions} from 'components/candidate_form';

const EditCandidateModal = observer(({uiState}) => {
  const {detailsState} = uiState;
  const {editingCandidate, errors} = detailsState;

  if (!editingCandidate) return null;

  return (
    <Modal isOpen={detailsState.editCandidateModalOpen} onHide={() => detailsState.closeEditCandidateModal()} size='md' closeButton={false}>
      <form>
        <div className='h2 mb3'>
          <FormattedMessage id='recruiting.candidate.Edit Candidate'/>
        </div>
        <div className='right-align h5 jumbo'>
          <FormattedMessage id='recruiting.candidate.SOURCED_DATE' values={{date: dateFormatter(moment(editingCandidate.createdAt))}}/>
        </div>
        <CandidateFields candidate={editingCandidate} errors={errors}/>
        <JobApplicationQuestions uiState={detailsState} candidate={editingCandidate}/>
        <ModalButtons onSave={() => uiState.updateCandidate()}
          saveCaption='recruiting.candidate.Update Candidate'
          onCancel={() => detailsState.closeEditCandidateModal()}/>
      </form>
    </Modal>
  );
});

export default EditCandidateModal;
