import React from 'react';
import {observer} from 'mobx-react';
import {InteractiveTable} from 'components';
import AddAssigneesModal from './AddAssigneesModal';
import DocumentAction from 'stores/documents/DocumentAction';
import {endpoints, types} from 'shared/core';

const COLUMNS = [
  {
    header: 'company_settings.documents.Employee Name',
    attribute: 'employee.name',
    width: 5,
    sortBy: 'name'
  },
  {
    header: 'company_settings.documents.Date Sent',
    attribute: 'sentDate',
    width: 2,
    sortBy: 'sentDate'
  },
  {
    header: 'company_settings.documents.Date Completed',
    attribute: 'completedDate',
    width: 2,
    sortBy: 'completedDate'
  },
  {
    header: 'company_settings.documents.Due Date',
    attribute: 'formattedDueDate',
    width: 2,
    sortBy: 'dueDate'
  }
];

const LOCALIZATION = {
  interactive: {
    addModel: 'company_settings.documents.Add Assignees'
  },
  removeModal: {
    header: 'company_settings.documents.Revoke Company Document',
    subHeader: 'company_settings.documents.Are you sure you want to revoke this company document?',
    body: 'company_settings.documents.Revoked company documents will no longer be actionable. Document assignments will remain visible to admins.',
    saveCaption: 'company_settings.documents.Revoke'
  },
  emptyState: 'company_settings.documents.No employees have been assigned this document',
  removeModel: 'company_settings.documents.Revoke'
};

const DocumentActionsTable = observer(({uiState}) => {
  return (
    <div>
      <InteractiveTable
        title={uiState.companyDocument.name}
        uiState={uiState}
        columns={COLUMNS}
        localization={LOCALIZATION}
        searchable={true}
        saveStateToUrl={true}
        onMount={agent => uiState.setInteractiveAgent(agent)}
        onAdd={() => uiState.openAddAssigneeModal()}
        onRemove={model => uiState.destroyDocumentAction(model)}
        customLinks={model => uiState.customLinksFor(model)}
        showAddButton={uiState.companyDocument.hasLink('addAssignees')}
        proxy={{
          type: 'async',
          modelType: types.DOCUMENT_ACTION,
          model: DocumentAction,
          endpoint: endpoints.DOCUMENT_ACTIONS.with(uiState.match.params.id)
        }}
      />
      <AddAssigneesModal
        companyDocument={uiState.companyDocument}
        isOpen={uiState.addAssigneeModalOpen}
        onHide={() => uiState.closeAddAssigneeModal()}
        onSuccess={() => uiState.onAssigneesAdded()}
      />
    </div>
  );
});

export default DocumentActionsTable;
