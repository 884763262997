import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class AnniversaryBonus extends DomainObject {
  @observable id;
  @observable anniversary;
  @observable rateAdjustment;
}

export default AnniversaryBonus;

