import React from 'react';
import {t} from 'shared/core';
import {Display, Panel, Layout} from 'components';
import {FormattedMessage} from 'react-intl';
import OfferAcceptedImage from 'containers/job_offer/img/offer-accepted-image@1x.png';
import ConfettiShot from './ConfettiShot';

const JobOfferAccepted = ({companyName, displayAnimation, logoUrl}) => {
  return (
    <div>
      <Panel className='offer-header'>
        <div className='center'>
          <Display
            documentTitle={t('job_offer.accepted.Job offer accepted')}
          />
          <div className='h1 medium py1'>
            {logoUrl ? <img alt='company logo' className='align-top CompanyLogo-img' src={logoUrl}/> :
              companyName}
          </div>
        </div>
      </Panel>
      <Layout>
        <div className='mt4 mb3 center' style={{fontSize: '44px'}}>
          <FormattedMessage id='job_offer.accepted.Congratulations!'/>
        </div>
        <div className='center mb1'><FormattedMessage id="job_offer.accepted.We are so excited you've decided to join our team!"/></div>
        <div className='center mb4'><FormattedMessage id="job_offer.accepted.Your hiring manager will be in touch with the details of your onboarding."/></div>
        <div className='center mt1'><img src={OfferAcceptedImage} alt='confirmation' className='full-width' style={{maxWidth: '610px'}}/></div>
      </Layout>
      {displayAnimation && <ConfettiShot/>}
    </div>
  );
};

export default JobOfferAccepted;
