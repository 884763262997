import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import {FormattedMessage} from 'react-intl';
import Button from 'components/buttons/Button';
import PaginationState from './PaginationState';

// TODO:
// disable/enable prev/next buttons depending on state
// hide pagination info if no results
@observer class Pagination extends React.Component {
  @observable uiState;

  componentWillMount() {
    this.uiState = new PaginationState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.uiState.updatePagination(nextProps.pagination);
  }

  render() {
    const {uiState} = this;
    if (!uiState.visible) return null;

    return (
      <div className='relative'>
        <div className='dataTables_info'>
          {!this.props.hidePaginationInfo &&
            <FormattedMessage
              id='components.tables.PAGINATION_INFO'
              values={{
                start: uiState.startEntryIndex,
                end: uiState.endEntryIndex,
                total: uiState.pagination.totalCount
              }}
            />
          }
        </div>
        {uiState.buttonsVisible &&
          <div className='absolute right-0 bottom-0'>
            <Button trait='default' className='PaginationBtn--left Btn--icon' onClick={() => uiState.prevPage()} disabled={!uiState.prevEnabled}>
              <i className="material-icons">{'arrow_drop_up'}</i>
            </Button>
            <Button trait='default' className='PaginationBtn--right Btn--icon' onClick={() => uiState.nextPage()} disabled={!uiState.nextEnabled}>
              <i className="material-icons">{'arrow_drop_up'}</i>
            </Button>
          </div>
        }
      </div>
    );
  }
}

export default Pagination;
