import React from 'react';
import {observer} from 'mobx-react';
import {ActionLink} from 'components';
import {FormattedMessage} from 'react-intl';

const SideNavigationItem = observer(({viewModel, disabled, current, active, complete, onClick}) => {
  const {companyDocument} = viewModel;

  return (
    <li>
      {disabled ? <span className='jumbo'>{companyDocument.name}</span> :
        <ActionLink
          onClick={onClick}
          className={`${active ? 'active' : ''} ${current ? 'current': ''} ${complete ? 'complete': ''}`}
        >
          {companyDocument.name}
        </ActionLink>}
    </li>
  );
});

const SideNavigation = observer(({uiState}) => {
  const {currentDocument, sortedDocuments, documentToView} = uiState;

  return (
    <div>
      <div className='h3 medium mb2'>
        <FormattedMessage id='onboard.documents.Documents to read or sign'/>
      </div>
      <ul className='Navigation-sidebar'>
        {sortedDocuments.map((viewModel, index) =>
          <SideNavigationItem key={index} viewModel={viewModel}
            disabled={viewModel.companyDocument.order > documentToView.order}
            active={viewModel.companyDocument.id === currentDocument.companyDocument.id}
            current={viewModel.companyDocument.id === documentToView.id}
            complete={viewModel.checked}
            onClick={() => uiState.setCurrentDocumentViewModel(viewModel)} />)}
      </ul>
    </div>
  );
});

export default SideNavigation;
