import React, {useState, useRef, useEffect} from 'react';
import {observer} from 'mobx-react';
import Button from 'components/buttons/Button';
import {FancyFilterSelector} from './filter_selector';
import _ from 'lodash';

const FancyFilterPillMenu = observer(({isOpen, children}) => {
  return (
    <div className='relative'>
      <div className={`FancyFilterDropdown-container FancyFilterDropdown-pill right-0 ${isOpen ? 'is-opened' : 'is-closed'}`} style={{maxHeight: 406}}>
        <div className='FancyFilterDropdown'>
          <div className='FancyFilterDropdown-list' style={{overflowY: 'visible', maxHeight: 'none'}}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
});

const FancyFilterPill = observer(({title, onRemove, children, booleanFilter}) => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const filterRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setFilterMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterMenuOpen]);

  return (
    <div ref={filterRef}>
      <Button className='mr1' trait='fancy-filter' onClick={() => !booleanFilter && setFilterMenuOpen(!filterMenuOpen)}>
        <div className='inline whitespace-nowrap'>
          {title}
        </div>
        {!booleanFilter && <i className='material-icons inline'>{'arrow_drop_down'}</i>}
        <i className='material-icons inline z-3 h4' onClick={onRemove}>{'close'}</i>
      </Button>
      <FancyFilterPillMenu isOpen={filterMenuOpen}>
        {children}
      </FancyFilterPillMenu>
    </div>
  );
});

const FancyFilterPills = observer(({filter, filterUpdated, filterDeleted, fancyFilterModels, hiddenFancyFilters = []}) => {
  return (
    <div className='flex flex-wrap full-height align-items-center row-gap-1'>
      {_.difference(Array.from(filter.keys()), hiddenFancyFilters).map((filterKey) => (
        <FancyFilterSelector filterKey={filterKey} filter={filter} Wrapper={FancyFilterPill} filterUpdated={filterUpdated} filterDeleted={filterDeleted} models={fancyFilterModels && fancyFilterModels[filterKey]}/>
      ))}
    </div>
  );
});

export default FancyFilterPills;
