import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const DeactivateIndeedModal = observer(({uiState}) => {
  const {deactivateIndeedModalOpen} = uiState;

  return (
    <AlertModal
      size='sm'
      header='recruiting.job_site.Deactivate Indeed Integration'
      subHeader='recruiting.job_site.Are you sure you want to deactivate Indeed integration?'
      body='recruiting.job_site.Jobs created on Collage will no longer be synced with your Indeed account.'
      isOpen={deactivateIndeedModalOpen} 
      onHide={() => uiState.closeDeactivateIndeedModal()}
    >
      <ModalDangerButtons
        onCancel={() => uiState.closeDeactivateIndeedModal()}
        saveCaption='recruiting.job_site.Deactivate'
        onSave={() => uiState.deactivateIndeedIntegration()}
      />
    </AlertModal>
  );
});

export default DeactivateIndeedModal;
