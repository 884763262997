import i18n from 'shared/i18n';

const LOCALE_MAPPING = {
  'en': 'en-CA',
  'fr': 'fr-CA'
};

const formatCurrency = (currency, value) => {
  const locale = LOCALE_MAPPING[i18n.locale] || i18n.locale;
  return new Intl.NumberFormat(
    locale, { style: 'currency', currency: currency, currencyDisplay: 'symbol'}
  ).format(value);
};

export default formatCurrency;
