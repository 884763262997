import React from 'react';
import {Modal} from 'components/modals';
import EmailTemplatePreview from "components/EmailTemplatePreview";
import {observer} from "mobx-react";

const EmailTemplatePreviewModal = observer(({isOpen, onHide, title, template}) => {
  return (
    <Modal
      isOpen={isOpen}
      onHide={() => onHide()}
      size="md"
      title={title}
    >
      <EmailTemplatePreview template={template}/>
    </Modal>
  );
});

export default EmailTemplatePreviewModal;
