import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from './panels';
import {FormattedMessage} from 'react-intl';
import ProgressIndicator from './ProgressIndicator';
import {t} from 'shared/core';
import _ from 'lodash';
import Tag from './Tag';

const OverviewPanel = observer(({title, completed, total, items, tags}) => {
  return (
    <Panel>
      <div className='flex'>
        <div>
          <Panel.Header title={t(title)}/>
        </div>
        {tags.map((tag, index) =>
          <div key={index} className='ml1'>
            <Tag colour={tag.colour}>
              <FormattedMessage id={tag.content}/>
            </Tag>
          </div>
        )}
      </div>
      <div className='col-6'>
        <div className='table'>
          {items.map((item, index) =>
            <div key={index} className='table-cell pr4'>
              <div className='medium'>
                <FormattedMessage id={item.title}/>
              </div>
              <div className='waterloo'>{item.value}</div>
            </div>
          )}
          <div className='table-cell'>
            <div className='medium'>
              <FormattedMessage id='components.overview_panel.Completion'/>
            </div>
            <div className='waterloo'>
              <FormattedMessage
                id='components.overview_panel.COMPLETION'
                values={
                  {
                    completed: completed,
                    total: total,
                    completedPercentage: _.ceil(total ? (completed / total) * 100 : 0)
                  }
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className='mt3'>
        <ProgressIndicator
          displayLegend
          items={[
            {
              label: t('components.overview_panel.Complete'),
              value: completed,
              color:  '#08BAD3'
            },
            {
              label: t('components.overview_panel.Incomplete'),
              value: total - completed,
              color: '#EBEFF1'
            }
          ]}
        />
      </div>
    </Panel>
  );
});

OverviewPanel.defaultProps = {
  title: 'components.overview_panel.Overview',
  completed: 0,
  total: 0,
  items: [],
  tags: []
};

export default OverviewPanel;
