import React, { useState, useEffect, useRef, useMemo } from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Checkbox, Input} from 'components';
import {t} from 'shared/core';
import _ from  'lodash';

const EmptyState = observer(() => {
  return (
    <div>
      <div className='center jumbo rounded h5 py2 px1 Table-emptyState'>
        <FormattedMessage id='components.tables.fancy_filters.No items to display'/>
      </div>
    </div>
  );
});

const MultiSelectFilter = observer(({options, filterKey, filter, filterUpdated, searchable}) => {
  const [isScrollable, setIsScrollable] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const containerRef = useRef(null);
  const containerMaxHeight = 280;

  const _handleCheck = (optionValue, checked) => {
    const newSelected = filter.get(filterKey) ? _.split(filter.get(filterKey), ',') : [];
    checked ? newSelected.push(optionValue) : _.pull(newSelected, optionValue);
    filterUpdated({
      [filterKey]: newSelected.join(',')
    });
  };

  const filteredOptions = useMemo(() => {
    if (!searchValue) return options;

    const filter = _.lowerCase(searchValue);
    return _.reject(options, c => _.lowerCase(c.label).indexOf(filter) === -1);
  }, [options, searchValue]);

  useEffect(() => {
    if (containerRef.current) {
      const contentHeight = containerRef.current.scrollHeight;

      setIsScrollable(contentHeight > containerMaxHeight);
    }
  }, [filteredOptions]);

  return (
    <div>
      <div className='h3 medium pb1'>
        <FormattedMessage id='components.tables.fancy_filters.FILTER_BY' values={{filterName: t(`components.tables.fancy_filters.keys.${filterKey}`)}}/>
      </div>
      {searchable && !_.isEmpty(options) && <div className='pb2'>
        <Input type='search'
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                className='InputSearch full-width'
                placeholder='components.tables.fancy_filters.Search'/>
      </div>}
      {_.isEmpty(filteredOptions) && <EmptyState/>}
      <div style={{maxHeight: `${containerMaxHeight}px`, overflowY: isScrollable ? 'auto' : 'hidden'}} ref={containerRef}>
        {filteredOptions.map((option) =>
          <div key={option.value} className='clearfix table clickable'>
            <label className='full-width'>
              <div className='col-1 table-cell align-middle pr1'>
                <Checkbox checked={_.split(filter.get(filterKey), ',').includes(option.value)} onChange={(e) => _handleCheck(option.value, e.checked)}/>
              </div>
              <div className='col-11 table-cell align-middle pl1 word-break'>
                {option.label}
              </div>
            </label>
          </div>
        )}
      </div>
    </div>
  );
});

export default MultiSelectFilter;
