import React from 'react';
import {observer} from 'mobx-react';
import CandidateStatus from './CandidateStatus';
import CandidateTags from './CandidateTags';
import CandidatePositions from './CandidatePositions';
import CandidateRatings from './CandidateRatings';
import Location from './Location';
import Department from './Department';
import EmploymentStatus from './EmploymentStatus';
import EmploymentType from './EmploymentType';
import GoalEmployee from './GoalEmployee';
import GoalStatus from './GoalStatus';
import {booleanFilters} from '../booleanFilters';
import {t} from 'shared/core';
import ReviewEmployee from './ReviewEmployee';
import ReviewCycle from './ReviewCycle';
import ReviewQuestion from './ReviewQuestion';

const FancyFilterSelector = observer(({filterKey, filter, filterUpdated, filterDeleted, Wrapper, models}) => {
  if (booleanFilters.includes(filterKey)) {
    return <Wrapper
      title={t(`components.tables.fancy_filters.boolean_filter_selected.${filterKey}`)}
      onRemove={() => filterDeleted(filterKey)}
      booleanFilter
    />;
  }

  switch (filterKey) {
    case 'location':
      return <Location filter={filter} filterKey={filterKey} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
    case 'department':
      return <Department filter={filter} filterKey={filterKey} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
    case 'employment_status':
      return <EmploymentStatus filter={filter} filterKey={filterKey} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
    case 'employment_type':
      return <EmploymentType filter={filter} filterKey={filterKey} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
    case 'candidate_status':
      return <CandidateStatus filter={filter} filterKey={filterKey} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
    case 'candidate_tags':
      return <CandidateTags filter={filter} filterKey={filterKey} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
    case 'candidate_positions':
      return <CandidatePositions filter={filter} filterKey={filterKey} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
    case 'candidate_ratings':
      return <CandidateRatings filter={filter} filterKey={filterKey} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
    case 'goal_status':
      return <GoalStatus filter={filter} filterKey={filterKey} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
    case 'goal_employee':
      return <GoalEmployee filter={filter} filterKey={filterKey} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
    case 'review_employee':
      return <ReviewEmployee filter={filter} filterKey={filterKey} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper} models={models}/>;
    case 'review_cycle':
      return <ReviewCycle filter={filter} filterKey={filterKey} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
    case 'review_question':
      return <ReviewQuestion filter={filter} filterKey={filterKey} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper} models={models}/>;
    default:
      return null;
  }
});

export default FancyFilterSelector;
