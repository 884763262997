export default [
  {
    name: "New Year's Day",
    date: "2023-01-01"
  },
  {
    name: "Islander Day",
    date: "2023-02-20",
    appliesTo: ['PE']
  },
  {
    name: "Louis Riel Day",
    date: "2023-02-20",
    appliesTo: ['MB']
  },
  {
    name: "Nova Scotia Heritage Day",
    date: "2023-02-20",
    appliesTo: ['NS']
  },
  {
    name: "Family Day",
    date: "2023-02-20",
    appliesTo: ['AB', 'BC', 'NB', 'ON', 'SK']
  },
  {
    name: "Good Friday",
    date: "2023-04-07",
    doesNotApplyTo: ['QC']
  },
  {
    name: "Easter Monday",
    date: "2023-04-10",
    appliesTo: ['QC']
  },
  {
    name: "Victoria Day",
    date: "2023-05-22",
    doesNotApplyTo: ['NB', 'NS', 'NL']
  },
  {
    name: "National Aboriginal Day",
    date: "2023-06-21",
    appliesTo: ['NT']
  },
  {
    name: "Discovery Day (NL)",
    date: "2023-06-21",
    appliesTo: ['NL']
  },
  {
    name: "St. Jean Baptiste Day",
    date: "2023-06-24",
    appliesTo: ['QC']
  },
  {
    name: "Canada Day",
    date: "2023-07-01"
  },
  {
    name: "Nunavut Day",
    date: "2023-07-09",
    appliesTo: ['NU']
  },
  {
    name: "Civic Holiday",
    date: "2023-08-07",
    appliesTo: ['BC', 'SK', 'ON', 'NB', 'NU', 'NT']
  },
  {
    name: "Alberta Heritage Day",
    date: "2023-08-07",
    appliesTo: ['AB']
  },
  {
    name: "Discovery Day (YT)",
    date: "2023-08-21",
    appliesTo: ['YT']
  },
  {
    name: "Labour Day",
    date: "2023-09-04"
  },
  {
    name: "Thanksgiving",
    date: "2023-10-09",
    doesNotApplyTo: ['NB', 'NS', 'NL']
  },
  {
    name: "Remembrance Day",
    date: "2023-11-11",
    doesNotApplyTo: ['MB', 'ON', 'QC', 'NS']
  },
  {
    name: "Christmas Day",
    date: "2023-12-25"
  },
  {
    name: "Boxing Day",
    date: "2023-12-26",
    appliesTo: ['ON']
  }
];
