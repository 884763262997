const MERGE_FIELDS = [
  {
    key: 'bonus_structure',
    label: 'components.lexical.merge_fields.Bonus Structure',
    placeholder: 'components.lexical.merge_fields.#[Bonus Structure]'
  },
  {
    key: 'commission_structure',
    label: 'components.lexical.merge_fields.Commission Structure',
    placeholder: 'components.lexical.merge_fields.#[Commission Structure]'
  },
  {
    key: 'employee_name',
    label: 'components.lexical.merge_fields.Employee Preferred Name',
    placeholder: 'components.lexical.merge_fields.#[Employee Preferred Name]'
  },
  {
    key: 'employee_legal_name',
    label: 'components.lexical.merge_fields.Employee Name',
    placeholder: 'components.lexical.merge_fields.#[Employee Name]'
  },
  {
    key: 'employment_type',
    label: 'components.lexical.merge_fields.Employment Type',
    placeholder: 'components.lexical.merge_fields.#[Employment Type]'
  },
  {
    key: 'issued_date',
    label: 'components.lexical.merge_fields.Issued Date',
    placeholder: 'components.lexical.merge_fields.#[Issued Date]'
  },
  {
    key: 'job_title',
    label: 'components.lexical.merge_fields.Job Title',
    placeholder: 'components.lexical.merge_fields.#[Job Title]'
  },
  {
    key: 'location_name',
    label: 'components.lexical.merge_fields.Location Name',
    placeholder: 'components.lexical.merge_fields.#[Location Name]'
  },
  {
    key: 'pay_frequency',
    label: 'components.lexical.merge_fields.Pay Frequency',
    placeholder: 'components.lexical.merge_fields.#[Pay Frequency]'
  },
  {
    key: 'pay_rate_unit',
    label: 'components.lexical.merge_fields.Pay Rate Unit',
    placeholder: 'components.lexical.merge_fields.#[Pay Rate Unit]'
  },
  {
    key: 'pay_rate_currency',
    label: 'components.lexical.merge_fields.Pay Rate Currency',
    placeholder: 'components.lexical.merge_fields.#[Pay Rate Currency]'
  },
  {
    key: 'reports_to',
    label: 'components.lexical.merge_fields.Reports to',
    placeholder: 'components.lexical.merge_fields.#[Reports to]'
  },
  {
    key: 'salary',
    label: 'components.lexical.merge_fields.Salary (or pay rate)',
    placeholder: 'components.lexical.merge_fields.#[Salary]'
  },
  {
    key: 'start_date',
    label: 'components.lexical.merge_fields.Start Date',
    placeholder: 'components.lexical.merge_fields.#[Start Date]'
  },
  {
    key: 'signed_date',
    label: 'components.lexical.merge_fields.Signed Date',
    placeholder: 'components.lexical.merge_fields.#[Signed Date]'
  }
];

export default MERGE_FIELDS;
