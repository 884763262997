import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const NoResults = observer(({uiState}) => {
  return (
    <div className='p2'>
      <FormattedMessage
        id='global_search.NO_RESULTS'
        values={{
          query: <span className='medium'>{uiState.query}</span>
        }}
      />
    </div>
  );
});

export default NoResults;
