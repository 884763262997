import React from 'react';
import {observer} from 'mobx-react';
import {types} from 'shared/core';
import EmploymentRecordForm from './EmploymentRecordForm';
import TerminationRecordForm from './TerminationRecordForm';
import OnLeaveRecordForm from './OnLeaveRecordForm';
import ReactivationRecordForm from './ReactivationRecordForm';

const JobTimelineEntryForm = observer(({uiState}) => {
  const record = uiState.editRecord;

  switch (record._type) {
    case types.EMPLOYMENT_RECORD:
      return <EmploymentRecordForm uiState={uiState}/>;
    case types.TERMINATION_RECORD:
      return <TerminationRecordForm uiState={uiState}/>;
    case types.ON_LEAVE_RECORD:
      return <OnLeaveRecordForm uiState={uiState}/>;
    case types.REACTIVATION_RECORD:
      return <ReactivationRecordForm uiState={uiState}/>;
    default:
      throw new Error(`Entry type ${record._type} is not supported.`);
  }
});

export default JobTimelineEntryForm;
