import React from 'react';
import {observer} from 'mobx-react';
import PerformanceSubnav from 'containers/performance/components/PerformanceSubnav';
import RevieweesContainerWithState from './RevieweesContainerWithState';

const RevieweesContainer = observer(({scope}) => {
  return (
    <div>
      <PerformanceSubnav scope={scope} />
      <RevieweesContainerWithState/>
    </div>
  );
});

export default RevieweesContainer;
