import {observable, computed} from 'mobx';
import {fullName, dateFormatter} from 'shared/tools';
import _ from 'lodash';

class CandidateViewModel {
  @observable candidate;
  @observable selected = false;
  @observable stateChanged = false;
  @observable active = false;

  constructor(candidate) {
    this.candidate = candidate;
  }

  @computed get name() {
    return fullName(this.candidate);
  }

  @computed get originDate() {
    return dateFormatter(this.candidate.createdAt);
  }

  @computed get currentStageDate() {
    return dateFormatter(this.candidate.enteredCurrentStageAt) || dateFormatter(this.candidate.createdAt);
  }

  @computed get currentStageName() {
    return this.candidate.currentStage.name;
  }

  @computed get backgroundInformationVisible() {
    return this.candidate.additionalInformation
      || this.candidate.resume
      || !_.isEmpty(this.candidate.answers);
  }
}

export default CandidateViewModel;
