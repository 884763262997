import {action, observable, computed} from 'mobx';
import {t} from 'shared/core';
import AwsFileStore from 'stores/documents/AwsFileStore';
import _ from 'lodash';

const MAX_FILE_SIZE = 50 * 1024 * 1024;

class UploadFilesState {
  store = new AwsFileStore();
  callbacks;
  accept;
  maxFiles = -1;
  modelType;

  @observable files = [];
  @observable errorMessage;
  @observable maxFileSizeErrorMessage;
  @observable isUploading = false;
  @observable dropzoneVisible = true;
  @observable disabled = false;
  @observable uploadToAws;

  receiveProps({
    accept, defaultFiles, maxFiles, isUploading, errorMessage,  onChange,
    onUploadStarted, onUploadFinished, modelType, disabled, uploadToAws = true
  }) {
    this.callbacks = {
      onChange,
      onUploadStarted,
      onUploadFinished
    };
    this.errorMessage = errorMessage;
    this.isUploading = isUploading;
    this.maxFiles = maxFiles;
    this.accept = accept;
    this.modelType = modelType;
    this.disabled = disabled;
    this.uploadToAws = uploadToAws;

    this.files.replace(defaultFiles);
    this.dropzoneVisible = !this.hasFiles && !this.isUploading;
  }

  @action async __uploadFiles(files, modelType) {
    for (const f of files) {
      if (f.size > MAX_FILE_SIZE) {
        this.maxFileSizeErrorMessage = t(
          'components.dropzone.MAX_FILE_SIZE_ERROR_MESSAGE', {maxFileSizeText: '50 MB'}
        );
        return;
      }
    }

    for (const f of files) {
      const awsFile = await this.store.uploadFile(f, modelType);
      this.files.push(awsFile);
    }
  }

  @action async addFiles(files) {
    if (_.isEmpty(files)) return;

    this.isUploading = true;
    this.callbacks.onUploadStarted && this.callbacks.onUploadStarted();
    this.maxFileSizeErrorMessage = '';

    this.hideDropzone();

    if (this.uploadToAws) {
      await this.__uploadFiles(files, this.modelType);
    } else {
      for (const f of files) {
        this.files.push(f);
      }
    }

    this.callbacks.onChange(this.files);

    this.isUploading = false;
    this.callbacks.onUploadFinished && this.callbacks.onUploadFinished();
  }

  @action showDropzone() {
    this.dropzoneVisible = true;
  }

  @action hideDropzone() {
    this.dropzoneVisible = false;
  }

  @action removeFile(file) {
    this.files.remove(file);

    this.callbacks.onChange(this.files);
  }

  @computed get hasFiles() {
    return !!this.files.length;
  }

  @computed get canAddFiles() {
    return !this.dropzoneVisible && this.multipleFiles;
  }

  @computed get multipleFiles() {
    return this.maxFiles !== 1;
  }
}

export default UploadFilesState;
