import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import Panel from '../panels/Panel';
import FormLayout from '../forms/FormLayout';
import EmployeeCustomFieldsState from './EmployeeCustomFieldsState';
import {withState} from 'shared/core';
import _ from 'lodash';

const FieldWrapper = observer(({useFormLayout, children}) => {
  if (useFormLayout) {
    return(
      <FormLayout>
        {children}
      </FormLayout>
    );
  }

  return children;
});

const EmployeeCustomFields = observer(({uiState, children, errors, useFormLayout}) => {
  const {sortedCustomFieldGroups} = uiState;

  return (
    <Panel.Stack loose>
      {sortedCustomFieldGroups.map((group) =>
        <Panel key={group.order}>
          <Panel.Header title={group.name}/>
          <FieldWrapper useFormLayout={useFormLayout}>
            {group.employeeCustomFields.map(f => {
              const error = _.get(errors, `employeeCustomFields.${f.customFieldId}`) || _.get(errors, `employmentRecordCustomFields.${f.customFieldId}`);
              return children(f, error);
            })}
          </FieldWrapper>
        </Panel>
      )}
    </Panel.Stack>
  );
});

EmployeeCustomFields.propTypes = {
  children: PropTypes.func.isRequired
};

export default withState(EmployeeCustomFields, EmployeeCustomFieldsState);
