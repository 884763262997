const CUSTOM_REPORT_FILTERS = [
  'Department',
  'Location',
  'Employment Type',
  'Manager',
  'Employee',
  'Gender',
  'Termination Type',
  'Termination Date',
  'Employment Status',
  'Start Date'
];

export default CUSTOM_REPORT_FILTERS;
