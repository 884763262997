import React from 'react';
import {observer} from 'mobx-react';
import OnboardingContainerWithState from './OnboardingContainerWithState';
import EmployeeProfileContainer from '../../EmployeeProfileContainer';

const OnboardingContainer = observer(({uiState}) => {
  return (
    <EmployeeProfileContainer uiState={uiState}>
      <OnboardingContainerWithState parentState={uiState}/>
    </EmployeeProfileContainer>
  );
});

export default OnboardingContainer;
