import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const UnshareRevieweeModal = observer(({uiState, isOpen}) => {
  const {selectedReviewee} = uiState;

  return (
    <AlertModal
      isOpen={isOpen}
      onHide={() => uiState.closeUnshareRevieweeModal()}
      size='md'
      header='performance_reviews.view.Unshare'
    >
      <div>
        <FormattedMessage
          id='performance_reviews.view.UNSHARE_REVIEWEE_DESCRIPTION'
          values={{revieweeName: _.get(selectedReviewee, 'employee.name')}}
        />
      </div>
      <ModalButtons
        saveCaption='performance_reviews.view.Unshare'
        onSave={() => uiState.unshareReviewee(selectedReviewee)}
        onCancel={() => uiState.closeUnshareRevieweeModal()}
      />
    </AlertModal>
  );
});

export default UnshareRevieweeModal;
