import {DomainObject} from 'shared/store';
import {action, observable, computed} from 'mobx';
import _ from 'lodash';
import {t} from 'shared/core';

class QAQuestion extends DomainObject {
  @observable questionType;
  @observable order;
  @observable text;
  @observable required = false;
  @observable args = {};

  @action updateText(value) {
    this.merge({text: value});
  }

  @action validate() {
    const errors = {};

    if (this.args.options && _.uniqBy(this.args.options, 'value').length !== this.args.options.length) {
      errors.options = t('errors.qa_question.Options must be unique');
    }
    _.forEach(this.args.options, option => {
      if (_.isEmpty(option.value.trim())) {
        errors.options = t("errors.qa_question.Options can't be blank");
      }
    });

    return errors;
  }

  @computed get questionName() {
    return this.text;
  }

  @computed get options() {
    return _.get(this.args, 'options');
  }
}

export default QAQuestion;
