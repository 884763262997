import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {LocaleSelect} from 'components';
import {auth} from 'shared/core';

const SignIn = () => {
  return (
    <FormattedMessage id='signup.ALREADY_HAVE_ACCOUNT' values={{
      link: <a href='/user_session/new' className='TextLink dodger'><FormattedMessage id='signup.Log in'/></a>
    }} />
  );
};

const SignOut = () => {
  return (
    <a href='/user_session' data-method='delete' rel='nofollow' className='TextLink jumbo'>
      <i className='material-icons jumbo h3 pr1 align-middle'>{'keyboard_tab'}</i>
      <FormattedMessage id='broker_portal.signup.company.Sign Out'/>
    </a>
  );
};

const BrokerBrand = observer(() => {
  return (
    <div className='wl-brand-container-signup'>
      <span className='wl-logo wl-logo-white'></span>
      <div className='wl-broker-name-container wl-broker-name-container-white'>
        <span className='wl-broker-name'></span>
      </div>
    </div>
  );
});


const BhqCompanyContainer = ({children}) => {
  return (
    <div>
      <div className='bg-white border-bottom center py1 mb4'>
        <BrokerBrand/>
        <a href='https://collage.co' className='inline-block p2'>
          &nbsp;
        </a>
        <div className='absolute py2 pr3 right-0 top-0'>
          <LocaleSelect/>
          <div className='h4 py1 jumbo inline-block ml3'>
            <div className='sm-and-below-hide'>
              {auth.user.id ? <SignOut /> : <SignIn />}
            </div>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default BhqCompanyContainer;
