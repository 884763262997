import React from 'react';
import {observer} from 'mobx-react';
import {Route, Switch} from 'react-router-dom';
import RoleDetailsContainer from './RoleDetailsContainer';
import RolesContainer from './RolesContainer';

const RolesPage = observer(() => {
  return (
    <Switch>
      <Route exact path='/permissions' component={RolesContainer}/>
      <Route path='/permissions/:id' component={RoleDetailsContainer} />
    </Switch>
  );
});

export default RolesPage;
