import React from 'react';
import {observer} from 'mobx-react';
import {endpoints, types, t} from 'shared/core';
import {TimeTrackingProject} from 'stores/time_tracking';
import {InteractiveTable, DropdownFilter, AvatarList} from 'components';
import _ from 'lodash';

const COLUMNS = [
  {
    header: 'time_tracking.manage.projects.directory.Project name',
    width: 6,
    attribute: 'name'
  },
  {
    header: 'time_tracking.manage.projects.directory.Employees',
    width: 5,
    render: model => <AvatarList employees={model.employees} moreModalEnabled/>
  }
];

const LOCALIZATION = {
  emptyState: 'time_tracking.manage.projects.directory.No projects to display',
  removeModal: {
    header: 'time_tracking.manage.projects.directory.Remove Project?',
    subHeader: 'time_tracking.manage.projects.directory.Are you sure you want to remove this project?',
    body: 'time_tracking.manage.projects.directory.By removing this project, it will be removed from any existing entries that are currently using it.'
  },
  interactive: {
    addModel: 'time_tracking.manage.projects.directory.Create New Project'
  }
};

function statusFilters() {
  return [
    {
      id: 'active',
      display: t('time_tracking.manage.projects.directory.Active')
    },
    {
      id: 'archived',
      display: t('time_tracking.manage.projects.directory.Archived')
    }
  ];
}

const ProjectDirectory = observer(({uiState}) => {
  return (
    <InteractiveTable
      title={t('time_tracking.manage.Projects')}
      uiState={uiState}
      searchable
      columns={COLUMNS}
      onMount={agent => uiState.setProjectInteractiveAgent(agent)}
      localization={LOCALIZATION}
      onAdd={() => uiState.openEditProjectModal(new TimeTrackingProject())}
      onRemove={project => uiState.removeProject(project)}
      onEdit={project => uiState.openEditProjectModal(project)}
      customLinks={project => uiState.customLinksForProject(project)}
      proxy={{
        type: 'async',
        modelType: types.TIME_TRACKING.PROJECT,
        model: TimeTrackingProject,
        endpoint: endpoints.TIME_TRACKING.PROJECTS.DIRECTORY
      }}>
        <DropdownFilter options={statusFilters()} attr='status'/>
    </InteractiveTable>
  );
});

export default ProjectDirectory;
