import React from 'react';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {TabIndentationPlugin} from '@lexical/react/LexicalTabIndentationPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {HeadingNode} from '@lexical/rich-text';
import {$isRootTextContentEmptyCurry} from '@lexical/text';
import {ListItemNode, ListNode, $isListItemNode, $isListNode} from '@lexical/list';
import {LinkNode} from '@lexical/link';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import {$generateHtmlFromNodes} from '@lexical/html';
import {ElementNode, ParagraphNode} from 'lexical';

import ToolbarPlugin from 'components/lexical_editor/plugins/ToolbarPlugin';
import RenderedFieldsPlugin from 'components/lexical_editor/plugins/RenderedFieldsPlugin';
import Theme from 'components/lexical_editor/Theme';
import LoadStateFromHTMLPlugin from 'components/lexical_editor/plugins/LoadStateFromHTMLPlugin';
import {MergeFieldNode} from 'components/lexical_editor/nodes/MergeFieldNode';
import {RenderedFieldNode} from 'components/lexical_editor/nodes/RenderedFieldNode';
import ImagesPlugin from 'components/lexical_editor/plugins/ImagesPlugin';
import {ImageNode} from 'components/lexical_editor/nodes/ImageNode';
import {HelpLink} from 'components/links';
import {FormError, LabelHelper, Spinner} from 'components';
import {FormattedMessage} from 'react-intl';
import {MentionNode} from 'components/lexical_editor/nodes/MentionNode';
import MentionsPlugin from 'components/lexical_editor/plugins/MentionsPlugin';
import {endpoints, types} from 'shared/core';
import useFetchModel from 'shared/hooks/useFetchModel';
import _ from 'lodash';

function Placeholder({placeholder}) {
  if (!placeholder) {
    return null;
  }
  return <div className='editor-placeholder'>
    <FormattedMessage id={placeholder} />
  </div>;
}

// At present (Lexical 0.11.3), alignment and indentation are not properly exported. This fixes that.
const originalExportDOM = ElementNode.prototype.exportDOM;
ElementNode.prototype.exportDOM = function exportDOM(editor) {
  const result = originalExportDOM.apply(this, [editor]);
  if ($isListItemNode(this) || $isListNode(this)) return result;
  return setAlignmentAndIndent(this, result);
};

const originalParagraphExportDOM = ParagraphNode.prototype.exportDOM;
ParagraphNode.prototype.exportDOM = function exportDOM(editor) {
  const result = originalParagraphExportDOM.apply(this, [editor]);
  return setAlignmentAndIndent(this, result);
};

function setAlignmentAndIndent(node, result) {
  const element = result.element;
  if (element) {
    element.style.textAlign = node.getFormatType();

    const indent = node.getIndent();
    if (indent > 0) {
      element.style.textIndent = `${indent * 40}px`;
    }
  }
  return result;
}

export default function Editor(props) {
  const [company, isLoading] = useFetchModel(endpoints.CURRENT_COMPANY, types.COMPANY);

  function onChange(editorState, editor) {
    editorState.read(() => {
      const htmlString = $generateHtmlFromNodes(editor);
      const isComposing = editor.isComposing();
      const currentIsEmpty = editorState.read($isRootTextContentEmptyCurry(isComposing)) && !htmlString.includes('<img');
      props.onChange({state: JSON.stringify(editorState), html: currentIsEmpty ? '' : htmlString});
    });
  }

  const {
    mergeFields,
    initialEditorState,
    signatures,
    action,
    label,
    helpLink,
    placeholder,
    className,
    initialHTML,
    enableCustomMergeFields,
    fields,
    onContentLoaded,
    mentionOptions,
    disableStyling,
    disableHeaders,
    errorMessage,
    editorClassName,
    errorClassName
  } = props;

  const editorConfig = {
    theme: Theme,
    onError(error) {
      throw error;
    },
    // Any custom nodes go here (incl. any nodes used that are from lexical plugins, not just base lexical)
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      LinkNode,
      MergeFieldNode,
      RenderedFieldNode,
      ImageNode,
      MentionNode
    ]
  };

  if (initialEditorState) {
    editorConfig['editorState'] = initialEditorState;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={`LexicalEditor ${className}`}>
      <LabelHelper label={label} action={action} translateLabel>
        {helpLink && <HelpLink title={helpLink.title} explanation={helpLink.explanation}/>}
      </LabelHelper>
      <LexicalComposer
        initialConfig={editorConfig}
      >
        <div className='editor-container full-height'>
          <ToolbarPlugin
            mergeFields={mergeFields}
            signatures={signatures}
            enableCustomMergeFields={enableCustomMergeFields}
            disableStyling={disableStyling}
            disableHeaders={disableHeaders}
            logoUrl={_.get(company, 'links.logo')}
          />
          <div className={`editor-inner ${editorClassName}`}>
            <RichTextPlugin
              contentEditable={<ContentEditable className='editor-input'/>}
              placeholder={<Placeholder placeholder={placeholder}/>}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <OnChangePlugin onChange={onChange} ignoreSelectionChange/>
            <HistoryPlugin/>
            <ListPlugin/>
            <TabIndentationPlugin/>
            <LinkPlugin/>
            <ImagesPlugin/>
            <MentionsPlugin mentionOptions={mentionOptions}/>
            <RenderedFieldsPlugin fields={fields}/>
            <LoadStateFromHTMLPlugin initialHTML={initialHTML} initialEditorState={initialEditorState} onContentLoaded={onContentLoaded}/>
          </div>
        </div>
      </LexicalComposer>
      <FormError message={errorMessage} className={errorClassName}/>
    </div>
  );
}
