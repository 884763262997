import React from 'react';
import {observer} from 'mobx-react';

const TrainingHiddenFields = ({uiState}) => {
  return (
    <div>
      {uiState.selectedPrograms.map((viewModel, index) =>
        <input type='hidden' key={viewModel.trainingProgram.id}
          value={viewModel.trainingProgram.id} name={`employee[training_ids][]`}/>
      )}
    </div>
  );
};

export default observer(TrainingHiddenFields);

