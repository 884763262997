import {DomainObject} from 'shared/store';
import {observable, action} from 'mobx';
import {oneOf} from 'shared/store';
import {Segment} from 'stores/segmentation';

class SegmentCalendar extends DomainObject {
  @observable name;
  @observable description;
  @observable locale;
  @observable calendarType;
  @observable participantSegment;
  @observable subscriptionSegment;

  @action merge(other, fields) {
    super.merge(other, {
      ...fields,
      participantSegment: Segment,
      subscriptionSegment: Segment,
      locale: oneOf(['en', 'fr']),
      calendarType: oneOf(['pto', 'birthdays', 'work_anniversaries'])
    });
  }
}

export default SegmentCalendar;
