import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {SummaryContainerState} from '../state';
import Subnav from '../../Subnav';
import {Layout} from 'components';
import QuestionSummaryList from './QuestionSummaryList';

const SummaryContainerWithState = withState(observer(({uiState}) => {
  return (
    <Layout>
      <QuestionSummaryList uiState={uiState} />
    </Layout>
  );
}), SummaryContainerState);

const SummaryContainer = observer(({cycle, match, history, scope}) => {
  return (
    <div>
      <Subnav title={cycle.name} match={match} scope={scope}/>
      <SummaryContainerWithState {...{cycle, match, history, scope}}/>
    </div>
  );
});

export default SummaryContainer;
