const CHECK_IN_EMPLOYEE_MERGE_FIELDS = [
  {
    key: 'cycle_name',
    label: 'company_settings.company_email_templates.merge_fields.Cycle Name',
    placeholder: 'company_settings.company_email_templates.merge_fields.#[Cycle Name]',
  },
  {
    key: 'cycle_frequency',
    label: 'company_settings.company_email_templates.merge_fields.Cycle Frequency',
    placeholder: 'company_settings.company_email_templates.merge_fields.#[Cycle Frequency]',
  },
  {
    key: 'leader_first_name',
    label: 'company_settings.company_email_templates.merge_fields.Leader First Name',
    placeholder: 'company_settings.company_email_templates.merge_fields.#[Leader First Name]'
  },
  {
    key: 'leader_last_name',
    label: 'company_settings.company_email_templates.merge_fields.Leader Last Name',
    placeholder: 'company_settings.company_email_templates.merge_fields.#[Leader Last Name]'
  },
  {
    key: 'employee_names',
    label: 'company_settings.company_email_templates.merge_fields.Employee Names',
    placeholder: 'company_settings.company_email_templates.merge_fields.#[• Employee Names]'
  }
];

export default CHECK_IN_EMPLOYEE_MERGE_FIELDS;
