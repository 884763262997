import React from 'react';
import {observer} from 'mobx-react';
import {Checkbox, ObjectSelect2} from 'components';
import {t} from 'shared/core';

const ReviewType = observer(({viewModel, errors}) => {
  return (
    <div className='py2 border-bottom'>
      <label className='pt1 clickable full-width'>
        <div className='table-cell align-middle'>
          <Checkbox checked={viewModel.checked}
            onChange={() => viewModel.toggle()}
            className='mb1' />
        </div>
        <div className='table-cell align-middle pl2 full-width'>
          <div className='clearfix table'>
            <div className='col-4 table-cell'>
              <div className='black medium'>
                {t(`models.performance_reviews.type.${viewModel.data.kind}`)}
              </div>
            </div>
            <div className='col-8 table-cell jumbo right-align'>
              <i className='material-icons align-top waterloo h3'>
                {viewModel.data.kind === 'peer' ? 'visibility_off' : 'visibility'}
              </i>
              <span className='ml1'>
                {t(`models.performance_reviews.visibility.${viewModel.data.kind}`)}
              </span>
            </div>
          </div>
          <div className='jumbo'>
            {t(`models.performance_reviews.details.${viewModel.data.kind}`)}
          </div>
        </div>
      </label>
      <div className='ml3 pb1'>
        <ObjectSelect2
          searchable
          placeholder='performance_reviews.edit.Select question set'
          value={viewModel.data.template}
          items={viewModel.uiState.availableTemplates}
          disabled={!viewModel.checked}
          errorMessage={errors.template}
          onChange={template => viewModel.data.merge({template})}
        />
      </div>
    </div>
  );
});

export default ReviewType;
