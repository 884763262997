import React from 'react';
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import OnboardEmployeeDocumentsContainer from './OnboardEmployeeDocumentsContainer';

const OnboardEmployeeDocumentsPage = observer(() => {
  return (
    <Router basename='/onboard/documents'>
      <Route path='/' component={OnboardEmployeeDocumentsContainer}/>
    </Router>
  );
});

export default OnboardEmployeeDocumentsPage;
