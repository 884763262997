import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {t} from 'shared/core';
import {ReviewRow} from 'components/flow';
import FilterRow from 'reports/components/filters/FilterRow';

const Review = observer(({uiState, match}) => {
  const {report} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('reports.custom.edit.Review your custom report configuration')}
        description={t('reports.custom.edit.Hover over a section and click edit to make changes.')}
      />
      <ReviewRow
        title='reports.custom.edit.Report Name'
        value={report.name}
        editRoute={`/custom/${match.params.id}/edit/fields`}
      />
      <ReviewRow title='reports.custom.edit.Report Fields' editRoute={`/custom/${match.params.id}/edit/fields`}>
        <ul className='list-reset list-gap-2'>
          {report.sortedColumns.map((c, index) => <li key={index}>{`${index + 1}. ${c.view}`}</li>)}
        </ul>
      </ReviewRow>
      <ReviewRow title='reports.custom.edit.Report Filters' lastRow editRoute={`/custom/${match.params.id}/edit/filters`}>
        {uiState.filterRows.map(f => <FilterRow key={f.category} uiState={uiState} viewModel={f}/>)}
      </ReviewRow>
    </div>
  );
});

export default Review;
