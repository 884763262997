import {PerformanceReviewType} from 'stores/performance_reviews';
import ReviewTypeViewModel from './ReviewTypeViewModel';

function getPerformanceReviewTypes(uiState) {
  return [
    {
      kind: 'manager',
      info: 'performance_reviews.edit.Reviews submitted by a manager will be shown to the employee.',
      desc: 'performance_reviews.edit.The survey managers will answer about each reviewee.'
    },
    {
      kind: 'self',
      info: 'performance_reviews.edit.Reviews submitted by an employee will be visible their manager.',
      desc: 'performance_reviews.edit.The survey each employee will answer about themselves.'
    },
    {
      kind: 'peer',
      info: 'performance_reviews.edit.Reviews submitted by peers will only be visible to their manager.',
      desc: 'performance_reviews.edit.The survey peers will review about the reviewee.'
    }
  ].map(obj => new ReviewTypeViewModel({
    info: obj.info,
    description: obj.desc,
    data: new PerformanceReviewType({kind: obj.kind}),
    uiState
  }));
}

export default getPerformanceReviewTypes;
