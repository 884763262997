import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';

const ModelValue = observer(({model, column, rest}) => {
  if (column.attribute) {
    const value = _.get(model, column.attribute);

    if (column.translatable) {
      if (_.isObject(value)) {
        return <FormattedMessage {...value}/>;
      }

      return <FormattedMessage id={value}/>;
    }

    return <span>{value}</span>;
  }

  if (column.component) {
    const Component = column.component;
    return <Component model={model} {...rest}/>;
  }

  return column.render(model);
});

export default ModelValue;
