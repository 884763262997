import React from 'react';
import {observer} from 'mobx-react';
import {loader, api, t} from 'shared/core';
import AssignDocumentButton from './AssignDocumentButton';
import EmployeeDocumentDropzone from './EmployeeDocumentDropzone';
import EmployeeDocumentList from './EmployeeDocumentList';
import EditDocumentModal from './EditDocumentModal';
import MoveDocumentModal from './MoveDocumentModal';
import AssignDocumentModal from './assign_document/AssignDocumentModal';
import AssignDocumentEditModal from './assign_document/AssignDocumentEditModal';
import {Panel} from 'components';
import EmployeeDocumentState from './state/EmployeeDocumentState';
import Breadcrumb from './Breadcrumb';
import _ from 'lodash';

const getRequestParams = async (file, presigner) => {
  const data = new FormData();
  const url = presigner.data.url;
  const headers = {
    'Content-Type': 'multipart/form-data',
    'Content-Disposition': 'attachment; filename=' + encodeURI(file.name)
  };

  _.toPairs(presigner.data.fields).forEach(([key, value]) => { data.append(key, value); });
  data.append('file', file);

  return { url, data, headers };
};

const parseXMLKey = (xmlFile) => {
  const parser = new DOMParser();
  const xml = parser.parseFromString(xmlFile.data, 'text/xml');
  return xml.getElementsByTagName('Key')[0].childNodes[0].nodeValue;
};

const EmployeeDocumentButtons = observer(({uiState, parentState, onDrop}) => {
  return (
    <div className='flex right'>
      {parentState.editDataPermission('::DOCUMENTS') && <EmployeeDocumentDropzone isUpdating={uiState.isUpdating} onDrop={onDrop}/>}
      <AssignDocumentButton uiState={uiState}/>
    </div>
  );
});

@observer class EmployeeDocumentsContainerWithState extends React.Component {
  handleDocumentCreated = async (acceptedFiles) => {
    const {uiState} = this.props;
    const {presigner} = uiState;
    const signed = await api.get(presigner);
    const folder = uiState.currentFolder;

    const documentUploads = acceptedFiles.map( async (file) => {
      const {url, data, headers} = await getRequestParams(file, signed);
      const aws = await api.post(url, data, { headers });
      const key = parseXMLKey(aws);
      await uiState.createDocument(file, key, folder);
    });

    await Promise.all(documentUploads);
  }

  render() {
    const {uiState} = this.props;
    const {parentState} = uiState;

    return (
      <Panel>
        <Breadcrumb title={t('employees.profile.documents.Documents')} uiState={uiState}>
          <EmployeeDocumentButtons uiState={uiState} parentState={parentState} onDrop={this.handleDocumentCreated}/>
        </Breadcrumb>
        <EmployeeDocumentList uiState={uiState}/>
        <AssignDocumentModal
          uiState={uiState}
          choosingDocumentAction={uiState.choosingDocumentAction}
          assigningExistingDocument={uiState.assigningExistingDocument}
          choosingDocumentType={uiState.choosingDocumentType}
        />
        <AssignDocumentEditModal uiState={uiState}/>
        <EditDocumentModal uiState={uiState}/>
        <MoveDocumentModal uiState={uiState}/>
      </Panel>
    );
  }
}

async function load({parentState}) {
  const uiState = new EmployeeDocumentState(parentState);
  await uiState.load();
  return { uiState };
}

export default loader(EmployeeDocumentsContainerWithState, load);
