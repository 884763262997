import React from 'react';
import {observer} from 'mobx-react';
import {Accordion, ActionLink, ActionLinks, Avatar, ProgressIndicator} from 'components';
import {dateSpan} from 'shared/tools';
import GoalStatusTag from './GoalStatusTag';

const ProgressBar = observer(({model}) => {
  return (
    <div className='flex'>
      <div className='col-4 jumbo right-align mr1 whitespace-pre'>{model.formattedCompleteness}</div>
      <div className='col-8'>
        <ProgressIndicator items={model.progressBarItems}/>
      </div>
    </div>
  );
});

const GoalName = observer(({goal, viewModel, level, uiState}) => {
  const {canViewNestedGoals} = uiState;

  return (
    <React.Fragment>
      {canViewNestedGoals ?
        <i className={`mr1 material-icons jumbo row-chevron ${viewModel.isExpanded ? 'expanded' : ''}`}>{'chevron_right'}</i> :
        <div className='mr1'/>}
      <div>
        {!!goal.parentGoalTitle && level === 0 && <div className='flex align-items-middle mb1'>
          <i className='jumbo mr1 material-icons h4'>{'arrow_forward'}</i>
          <span className='jumbo'>{goal.parentGoalTitle}</span>
        </div>}
        <ActionLink onClick={() => uiState.goToGoal(goal)}>{goal.title}</ActionLink>
      </div>
    </React.Fragment>
  );
});

const GoalRow = observer(({goal, viewModel, level, uiState}) => {
  const progressBarPaddingLeft = 32 * level + 8;

  return (
    <tr className={`AccordionTableRow ${level === 0 ? '' : `border-indent border-indent-${level}`} flex align-items-middle`} onClick={() => viewModel.isExpanded ? viewModel.collapse() : viewModel.expand()}>
      <td className='goal-column flex align-items-middle' style={{paddingLeft: 25 * level}}>
        <GoalName goal={goal} viewModel={viewModel} level={level} uiState={uiState}/>
      </td>
      <td className='assignee-column'><Avatar employee={goal.employee} size='sm'/></td>
      <td className='period-column'>{dateSpan(goal.startDate, goal.endDate, true)}</td>
      <td className='status-column'><GoalStatusTag goal={goal}/></td>
      <td className='progress-column' style={{paddingLeft: progressBarPaddingLeft}}><ProgressBar model={goal}/></td>
      <td style={{width: `${100/12}%`}}>
        <ActionLinks
          model={goal}
          customLinks={uiState.customLinksFor(goal)}
          onlyShowCustomLinks
        />
      </td>
    </tr>
  );
});

const MetricRow = observer(({metric, level}) => {
  const paddingLeft = 32 + (25 * (level - 1));
  const progressBarPaddingLeft = 32 * level;

  return (
    <tr className={`border-indent border-indent-${level} flex align-items-middle`}>
      <td className='metric-column truncate flex align-items-middle' style={{paddingLeft}}>
        {metric.name}
      </td>
      <td className='assignee-column'/>
      <td className='progress-column' style={{paddingLeft: progressBarPaddingLeft}}><ProgressBar model={metric}/></td>
      <td className='pl2 pr1' style={{width: 48, height: 37}}>
        <div style={{width: 24}}/>
      </td>
    </tr>
  );
});

const GoalView = observer(({model, uiState, level=0}) => {
  const goal = model.data;
  const {canViewNestedGoals} = uiState;

  return (
    <div>
      <GoalRow goal={goal} viewModel={model} level={level} uiState={uiState}/>
      {canViewNestedGoals && <Accordion active={model.isExpanded}>
        {goal.sortedMetrics.map((metric, index) => <div className='pt2'>
          <MetricRow metric={metric} level={level + 1} showBorder={model.showBorder(index)}/>
        </div>)}
        {model.subgoalViewModels.map(subgoal => <div className='pt2'>
          <GoalView model={subgoal} uiState={uiState} level={level + 1}/>
        </div>)}
      </Accordion>}
    </div>
  );
});

export default GoalView;
