import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {ActionLink} from 'components';

const AddMoreFiles = observer(({uiState}) => {
  if (uiState.isUploading) return null;

  return (
    <div className='mt2 left-align'>
      <ActionLink onClick={() => uiState.showDropzone()}>
        <FormattedMessage id='components.upload.+ Add more files'/>
      </ActionLink>
    </div>
  );
});

export default AddMoreFiles;
