import FilterRowViewModel from './FilterRowViewModel';
import MultiSelectFilterRow from 'stores/reports/MultiSelectFilterRow';
import _ from 'lodash';
import {observable, action, computed} from 'mobx';

class MultiSelectFilterRowViewModel extends FilterRowViewModel {
  mapItem;
  dataSource;
  translatable;

  @observable options = [];
  @observable loading = false;
  @observable loaded = false;
  @observable operatorOpen = false;
  @observable optionsOpen = false;
  @observable searchable = false;
  @observable searchFilter = '';

  constructor({define, dataSource, mapItem, translatable, searchable}) {
    super(define);
    this.dataSource = dataSource;
    this.mapItem = mapItem || (item => item);
    this.translatable = translatable;
    this.searchable = searchable;
    this.row.values = [];
    this.editingRow.values = [];
  }

  createRow(row) {
    return new MultiSelectFilterRow(row);
  }

  @action async load() {
    this.loading = true;
    const data = await this.dataSource.load();
    this.options = data.map(this.mapItem);
    this.loading = false;
    this.loaded = true;
  }

  @action initWithExistingData({data}) {
    this.options = data.map(this.mapItem);
    this.loading = false;
    this.loaded = true;
  }

  @action closeDropdowns() {
    this.closeOperator();
    this.closeOptions();
  }

  @action openOperator() {
    this.closeOptions();
    this.operatorOpen = true;
  }

  @action closeOperator() {
    this.operatorOpen = false;
    this.resetRow();
  }

  @action async openOptions() {
    this.closeOperator();
    if (this.loading) return;

    if (!this.loaded) {
      await this.load();
    }

    this.optionsOpen = true;
  }

  @action closeOptions() {
    this.optionsOpen = false;
    this.resetRow();
  }

  @action applySearchFilter(value) {
    this.searchFilter = value;
  }

  @action editValues(value, checked) {
    if (checked) {
      this.editingRow.add(value.id);
    } else {
      this.editingRow.remove(value.id);
    }
  }

  @computed get filteredOptions() {
    if (!this.searchable) return this.options;

    const filter = _.lowerCase(this.searchFilter);
    return _.reject(this.options, c => _.lowerCase(c.name).indexOf(filter) === -1);
  }

  @computed get selectedValues() {
    return _.map(_.sortBy(this.editingRow.values), (id) => {
      return _.find(this.options, {id}).name;
    });
  }
}

export default MultiSelectFilterRowViewModel;
