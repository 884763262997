import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Tag} from 'components';

const PositionStatus = observer(({position}) => {
  switch (position.status) {
    case 'draft':
      return <Tag><FormattedMessage id="recruiting.status.Draft"/></Tag>;
    case 'published':
      return <Tag colour="meadow"><FormattedMessage id="recruiting.status.Published"/></Tag>;
    case 'internal':
      return <Tag colour="waterloo"><FormattedMessage id="recruiting.status.Internal"/></Tag>;
    case 'closed':
      return <Tag colour="rose"><FormattedMessage id="recruiting.status.Closed"/></Tag>;
    default:
      return null;
  }
});

export default PositionStatus;
