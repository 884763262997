import React from 'react';
import ReactSelect from './ReactSelect';
import {observer} from 'mobx-react';
import {action, observable} from 'mobx';
import Url from 'domurl';

const LOCALES = ['en', 'fr'];
const DEFAULT_LOCALE = 'en';

const LocaleSelect = observer(({uiState}) => {
  if (window.auth.user.id) return null;

  return (
    <div className='inline-block'>
      <ReactSelect selected={uiState.currentLocale} className='caps'>
        {LOCALES.map((locale) =>
          <ReactSelect.Option
            key={locale}
            text={locale}
            onClick={() => uiState.toggleLanguage(locale)}
            active={uiState.currentLocale === locale}
            className='caps'/>
        )}
      </ReactSelect>
    </div>
  );
});

class LocaleSelectState {
  @observable currentLocale;

  constructor() {
    this.currentLocale = window.auth.locale || DEFAULT_LOCALE;
  }

  @action toggleLanguage(newLocale) {
    const modifiableLocation = new Url();
    modifiableLocation.query.locale = newLocale;
    window.location = modifiableLocation.toString();
  }
}

export default () => {
  const uiState = new LocaleSelectState();
  return <LocaleSelect uiState={uiState}/>;
};
