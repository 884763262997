import React from 'react';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {Input, Select2} from 'components';

const FormRow = observer(({name, className, children}) => {
  return (
    <div className={`${className ? className : 'my3'}`}>
      <div className='h4 medium mb1'>
        <FormattedMessage id={name}/>
      </div>
      {children}
    </div>
  );
});

const EditAddressInfoModal = observer(({uiState}) => {
  const {editEmployee, countries, regions, errors} = uiState;

  return (
    <Modal isOpen={uiState.addressInfoModalOpen} onHide={() => uiState.resetModals()} size='md'>
      <div className='h2 mb3'><FormattedMessage id='employees.profile.personal.Edit Address Information'/></div>
      <form>
        <FormRow name='employees.profile.personal.Address Line 1'>
          <Input value={editEmployee.homeAddress1}
                 errorMessage={errors.homeAddress1}
                 onChange={(e) => editEmployee.merge({homeAddress1: e.target.value})}
          />
        </FormRow>
        <FormRow name='employees.profile.personal.Address Line 2'>
          <Input value={editEmployee.homeAddress2}
                 errorMessage={errors.homeAddress2}
                 onChange={(e) => editEmployee.merge({homeAddress2: e.target.value})}
          />
        </FormRow>
        <FormRow name='employees.profile.personal.Country'>
          <Select2 value={editEmployee.countryCode}
                   dropdownClass='select2-animation'
                   onChange={(e) => { editEmployee.updateCountry(e.target.value); uiState.refreshRegions(e.target.value); }}
                   searchable={true}>
            {countries.map((c) =>
              <option key={c.id} value={c.code}>{c.name}</option>)}
          </Select2>
        </FormRow>
        <FormRow name='employees.profile.personal.State/Province'>
          <Select2 value={editEmployee.regionCode}
                   dropdownClass='select2-animation'
                   onChange={(e) => editEmployee.merge({regionCode: e.target.value})}
                   searchable={true}>
            {regions && regions.map((r) =>
              <option key={r.id} value={r.code}>{r.name}</option>)}
          </Select2>
        </FormRow>
        <FormRow name='employees.profile.personal.City'>
          <Input value={editEmployee.city}
                 errorMessage={errors.city}
                 onChange={(e) => editEmployee.merge({city: e.target.value})}
          />
        </FormRow>
        <FormRow name='employees.profile.personal.Postal Code'>
          <Input value={editEmployee.postalCode}
                 errorMessage={errors.postalCode}
                 onChange={(e) => editEmployee.merge({postalCode: e.target.value})}
          />
        </FormRow>
        <ModalButtons onSave={() => uiState.saveAddress()} onCancel={() => uiState.resetModals()} />
      </form>
    </Modal>
  );
});

export default EditAddressInfoModal;
