import React from 'react';
import {observer} from 'mobx-react';
import {withState, warnUnsavedChanges} from 'shared/core';
import OfferTemplateEditorState from '../state/OfferTemplateEditorState';
import {AutosaveStatus, Display, Layout} from 'components';
import {ModalButtons} from 'components/modals';
import OfferTemplateForm from './OfferTemplateForm';

const OfferTemplateEditor = observer(({uiState}) => {
  const {template} = uiState;

  return (
    <div>
      <Display backPath='/offer_templates' />
      <Layout>
        <OfferTemplateForm uiState={uiState} />
        <ModalButtons
          onSave={() => uiState.saveTemplate()}
          onCancel={() => uiState.goBack()}
        >
          <AutosaveStatus autosaver={template.autosaver} className='mr2'/>
        </ModalButtons>
      </Layout>
    </div>
  );
});

export default warnUnsavedChanges(withState(OfferTemplateEditor, OfferTemplateEditorState));
