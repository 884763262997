import React from 'react';
import {withState} from 'shared/core';
import {PaDashboardPageState} from '../state';
import {Panel, Layout, Display, Button, LivesChart} from 'components';
import {RecentActivity} from './recent_activity';
import {CompanyContacts} from './company_contacts';
import {CompanyPlans} from './company_plans';
import {CompanyFormsFacade} from './company_forms';
import {KeyDates} from './key_dates';
import {OutstandingEnrolments} from './outstanding_enrolments';
import {SubmitRequest} from './submit_request';
import {t} from 'shared/core';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const EnrolmentToolbar = observer(({uiState}) => {
  return (
    <Button onClick={() => uiState.goToNewEnrolment()}>
      <FormattedMessage id='pa_dashboard.+ New Enrolment'/>
    </Button>
  );
});

const PaDashboard = observer(({uiState}) => {
  const {enrolmentDataSeries, showEnrolmentGraph} = uiState;

  return (
    <div>
      <Display documentTitle={t('pa_dashboard.Dashboard')}
               title={uiState.greeting}
               toolbar={<EnrolmentToolbar uiState={uiState}/>}
      />
      <Layout>
        <Layout.Section>
          <Panel.Stack loose>
            <OutstandingEnrolments uiState={uiState}/>
            {showEnrolmentGraph && <LivesChart dataSeries={enrolmentDataSeries}/>}
            <KeyDates/>
            <RecentActivity uiState={uiState}/>
          </Panel.Stack>
        </Layout.Section>
        <Layout.Section secondary>
          <Panel.Stack loose>
            <CompanyContacts/>
            <CompanyPlans uiState={uiState}/>
            <CompanyFormsFacade/>
            <SubmitRequest/>
          </Panel.Stack>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(PaDashboard, PaDashboardPageState);
