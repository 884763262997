import React from 'react';

const ZERO_PERCENT_PADDING = 1;
const HUNDRED_PERCENT_PADDING = 99;

const calculatePercentage = (percent) => {
  if (percent === 0) {
    return ZERO_PERCENT_PADDING;
  } else if (percent === 100) {
    return HUNDRED_PERCENT_PADDING;
  } else {
    return percent;
  }
};

const Handle = ({handle, getHandleProps}) => {
  const {id, value, percent} = handle;

  const handleStyle = {
    left: `${calculatePercentage(percent)}%`,
  };

  return (
    <div className='SliderBar-handle' style={handleStyle} {...getHandleProps(id)}>
      <div className='h6 center'>{value}</div>
    </div>
  );
};

export default Handle;
