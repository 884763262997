import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const ReopenProgramModal = observer(({uiState, modalOpen}) => {
  return (
    <Modal
      title='training.directory.reopen_program.Reopen Program'
      size='sm'
      isOpen={modalOpen} 
      onHide={() => uiState.closeReopenProgramModal()}
    >
      <div className='mb3 jumbo'>
        <FormattedMessage id={'training.directory.reopen_program.Reopening this training program will reassign it to all employees who did not complete it before it was closed.'}/>
      </div>
      <ModalButtons
        saveCaption='training.directory.reopen_program.Reopen'
        onSave={() => uiState.reopenProgram()}
        onCancel={() => uiState.closeReopenProgramModal()}/>
    </Modal>
  );
});

export default ReopenProgramModal;
