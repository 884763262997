import React from 'react';
import {observer} from 'mobx-react';
import {Switch, Route} from 'react-router-dom';
import {LeaveTypeDirectory} from './directory';
import {LeaveTypeEditContainer} from './edit';

const LeaveTypesPage = observer(() => {
  return (
    <Switch>
      <Route exact path='/leave_types' component={LeaveTypeDirectory} />
      <Route path='/leave_types/edit/:id?' component={LeaveTypeEditContainer} />
    </Switch>
  );
});

export default LeaveTypesPage;
