import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import Select2 from './Select2';

const YearSelect = ({startYear = moment().year(), numberOfYears = 100, selectedYear = moment().year(), onChange}) => {
  return (
    <Select2 value={selectedYear} onChange={onChange} searchable={true} dropdownClass='select2-animation' placeholder='year'>
      {_.times(numberOfYears, i => <option value={startYear - i} key={startYear - i}>{startYear - i}</option>)}
    </Select2>
  );
};

export default YearSelect;
