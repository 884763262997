import React from 'react';
import {AlertModal, ModalDangerButtons} from 'components/modals';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const DeleteCandidateModal = observer(({uiState}) => {
  const {detailsState} = uiState;

  return (
    <AlertModal
      isOpen={detailsState.deleteCandidateModalOpen}
      size='sm'
      onHide={() => detailsState.closeDeleteCandidateModal()}
      closeButton={false}
      header='recruiting.candidate.Delete Candidate?'
      subHeader='recruiting.candidate.Are you sure you want to permanently delete this candidate?'
    >
      <FormattedMessage id="recruiting.candidate.You will not be able to recover this candidate's information."/>
      <ModalDangerButtons
        onSave={() => detailsState.deleteCandidate()}
        onCancel={() => detailsState.closeDeleteCandidateModal()}
      />
    </AlertModal>
  );
});

export default DeleteCandidateModal;
