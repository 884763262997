import {action, computed, observable, observe} from 'mobx';
import CompanyDocumentViewModel from './CompanyDocumentViewModel';
import CompanyDocument from 'stores/documents/CompanyDocument';
import AttachedDocument from 'stores/documents/AttachedDocument';
import {t, auth, endpoints, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import uuid from 'uuid';
import _ from 'lodash';

class OnboardingDocumentState {
  store = new DomainStore();
  onChange;
  employeeId;

  @observable editingCompanyDocument = {};
  @observable companyDocuments = [];
  @observable companyDocumentViewModels = [];
  @observable addDocumentModalOpen = false;
  @observable annotatorModalOpen = false;
  @observable isUploading = false;
  @observable acceptedMimeTypes;
  @observable errors = {};

  @observable existingDocumentModalOpen = false;

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.COMPANY_DOCUMENTS.with(this.employeeId)
    ]);

    this.editingCompanyDocument = new CompanyDocument({id: uuid.v4(), locale: auth.locale});
    this.companyDocumentViewModels = this.store
      ._getAll(types.COMPANY_DOCUMENT, CompanyDocument)
      .map(d => new CompanyDocumentViewModel(d, this));

    observe(this, 'orderedDocuments', () => this.emitOnChange(), true);
  }

  receiveProps({onChange, employeeId, errors}) {
    this.onChange = onChange;
    this.employeeId = employeeId;
    this.errors = errors;
  }

  emitOnChange() {
    if (this.onChange) {
      this.onChange(this.selectedDocuments);
    }
  }

  @action openExistingDocumentsModal() {
    this.existingDocumentModalOpen = true;
  }

  @action closeExistingDocumentsModal() {
    this.existingDocumentModalOpen = false;
    this.unselectedDocuments.forEach(d => d.checked = false);
  }

  @action saveSelectedDocuments() {
    let order = this.selectedDocuments.length;
    const documentsToSave = this.unselectedDocuments.filter(d => d.checked);

    documentsToSave.forEach((viewModel, index) => {
      viewModel.selected = true;
      viewModel.companyDocument.merge({order: order + index});
    });

    this.closeExistingDocumentsModal();
  }

  @action removeSelectedDocument(document) {
    const viewModel = _.find(this.companyDocumentViewModels, { companyDocument: { id: document.id }});
    viewModel.selected = false;
    viewModel.checked = false;
  }

  @computed get selectedDocuments() {
    return this.companyDocumentViewModels
      .filter(d => d.selected)
      .map(d => d.companyDocument);
  }

  @computed get unselectedDocuments() {
    return _.reject(this.companyDocumentViewModels, d => d.selected);
  }

  @computed get orderedDocuments() {
    return _.sortBy(this.selectedDocuments, ['order']);
  }

  @action openAddDocumentModal() {
    this.addDocumentModalOpen = true;
  }

  @action closeAddDocumentModal() {
    this.resetModalParams();
    this.addDocumentModalOpen = false;
    this.errors = {};
  }

  @action selectDocumentType(type) {
    this.editingCompanyDocument.merge({documentType: type});
    this.acceptedMimeTypes = type === 'pdf_template' ? 'application/pdf' : '';
  }

  @action openAnnotatorModal(companyDocument) {
    this.addDocumentModalOpen = false;
    if (companyDocument) {
      this.editingCompanyDocument = companyDocument;
    }
    this.annotatorModalOpen = true;
  }

  @action closeAnnotatorModal() {
    this.resetModalParams();
    this.annotatorModalOpen = false;
  }

  @action resetModalParams() {
    setTimeout(() => {
      this.editingCompanyDocument = new CompanyDocument({id: uuid.v4(), locale: auth.locale});
    }, 500);
  }

  @action saveUploadedFile() {
    if (!this.validateCompanyDocument()) return;
    if (this.uploadingPDFTemplate) {
      this.openAnnotatorModal();
    } else {
      this.appendDocumentToList(this.editingCompanyDocument);
      this.closeAddDocumentModal();
    }
  }

  @action appendDocumentToList(companyDocument) {
    const model = new CompanyDocument(companyDocument);
    const viewModel = new CompanyDocumentViewModel(model, this);

    if (_.find(this.companyDocuments, {id: model.id})) return;

    this.companyDocuments.push(model);
    this.companyDocumentViewModels.push(viewModel);
    viewModel.checked = true;
  }

  @action addAnnotations(annotations) {
    this.editingCompanyDocument.annotations = annotations;
    this.appendDocumentToList(this.editingCompanyDocument);
    this.closeAnnotatorModal();
  }

  @computed get choosingDocumentType() {
    return !this.uploadingPDFTemplate && !this.uploadingStaticDocument;
  }

  @computed get uploadingPDFTemplate() {
    return this.editingCompanyDocument.documentType === 'pdf_template';
  }

  @computed get uploadingStaticDocument() {
    return this.editingCompanyDocument.documentType === 'static_document';
  }

  validateCompanyDocument() {
    this.errors = {};

    if (!this.editingCompanyDocument.name) {
      this.errors.name = t('employees.hiring.documents.errors.Name is required.');
    }

    if (_.isEmpty(this.editingCompanyDocument.originalFile)) {
      this.errors.awsFiles = t('employees.hiring.documents.errors.A file must be uploaded.');
    }

    return _.isEmpty(this.errors);
  }

  restoreState() {
    const documentIds = _.get(window, 'collage.documentParams.company_document_ids', []);
    const companyDocuments = _.get(window, 'collage.documentParams.company_documents', []);

    _.each(documentIds, id => {
      const selectedDocument = _.find(this.companyDocumentViewModels, {companyDocument: {id: id}});
      if (selectedDocument) {
        selectedDocument.checked = true;
      }
    });

    _.each(companyDocuments, doc => {
      const companyDocument = new CompanyDocument({id: uuid.v4()});
      companyDocument.name = doc.name;
      companyDocument.documentType = doc.document_type;
      companyDocument.locale = doc.locale;
      companyDocument.originalFile = new AttachedDocument();
      companyDocument.originalFile.id = doc.original_file.key;
      companyDocument.originalFile.key = doc.original_file.key;
      companyDocument.originalFile.label = doc.original_file.label;
      companyDocument.originalFile.contentType = doc.original_file.content_type;
      companyDocument.originalFile.fileName = doc.original_file.file_name;
      companyDocument.originalFile.fileSize = parseInt(doc.original_file.file_size);
      this.appendDocumentToList(companyDocument);
    });
  }
}

export default OnboardingDocumentState;
