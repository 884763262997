import React from 'react';
import PropTypes from 'prop-types';

const Tag = ({type, colour, className, children}) => {
  return (
    <div className={`Tag Tag--${type} Tag--${colour} ${className} whitespace-pre`}>
      {children}
    </div>
  );
};

Tag.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string
  ]),
  type: PropTypes.string,
  colour: PropTypes.string
};

Tag.defaultProps = {
  type: 'rounded',
  colour: 'dodger',
  className: ''
};

export default Tag;
