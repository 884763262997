import {observable, action, toJS} from 'mobx';
import createDataProxy from './data_proxy';
import _ from 'lodash';
import saveStateToUrl from './saveStateToUrl';
import loadStateFromUrl from './loadStateFromUrl';

class InteractiveContextState {
  proxy;
  onRefresh;
  onMount;
  saveStateToUrl;
  pendingRequest;

  @observable isUpdating = false;
  @observable isLoadingFirstTime = true;
  @observable models = [];
  @observable filter = observable.map();
  @observable pagination = {
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    pageSize: 0
  };
  @observable sorting = observable.map();
  @observable defaultFilter;
  @observable isEmpty = false;
  @observable onFilterUpdated;

  constructor(proxy, onRefresh, onMount, onFilterUpdated, saveStateToUrl) {
    this.onFilterUpdated = onFilterUpdated;
    this.setProxy(proxy);
    this.onRefresh = onRefresh;
    this.onMount = onMount;
    this.saveStateToUrl = saveStateToUrl;
  }

  @action setProxy(proxy) {
    this.proxy = createDataProxy(proxy);
  }

  @action async load({initialFilter, initialSorting}) {
    const state = this.saveStateToUrl ? loadStateFromUrl() : {};

    const filter = _.merge(toJS(initialFilter), toJS(state.filter));
    const pagination = toJS(state.pagination);
    const sorting = _.merge(toJS(initialSorting), toJS(state.sorting));

    await this.refresh({
      filter,
      sorting,
      pagination
    });

    if (this.onMount) {
      this.onMount({
        refresh: () => this.refresh(),
        isEmpty: this.isEmpty,
        models: this.models
      });
    }
  }

  @action async updateFilter(filter) {
    await this.refresh({
      filter,
      pagination: {
        currentPage: 1
      }
    });
    this.onFilterUpdated && this.onFilterUpdated();
  }

  @action async deleteFilter(filterKey) {
    this.filter.delete(filterKey);
    await this.refresh();
    this.onFilterUpdated && this.onFilterUpdated();
  }

  @action async updateSorting(sorting) {
    await this.refresh({
      sorting,
      pagination: {
        currentPage: 1
      }
    });
  }

  @action async updatePagination(pagination) {
    await this.refresh({pagination});
  }

  @action updateDefaultFilter(filter) {
    this.defaultFilter = _.merge(toJS(filter), this.defaultFilter);
  }

  @action async refresh(args = {}) {
    if (this.pendingRequest && this.pendingRequest.cancel) {
      this.pendingRequest.cancel();
    }

    this.pendingRequest = this.executeRefresh(args);

    await this.pendingRequest;
  }

  async executeRefresh(args) {
    this.isUpdating = true;

    const {data, filter, pagination, sorting} = await this.proxy.load({
      filter: _.merge(this.filter.toJSON(), args.filter),
      pagination: _.merge(this.pagination, args.pagination),
      sorting: _.merge(this.sorting.toJSON(), args.sorting)
    });

    this.filter.merge(filter);
    this.sorting.merge(sorting);
    this.pagination = pagination;

    this.updateModels(data);

    this.isUpdating = false;
    this.isLoadingFirstTime = false;

    if (this.onRefresh) {
      this.onRefresh({
        data: data,
        filter: this.filter,
        sorting: this.sorting,
        pagination: this.pagination
      });
    }

    if (this.saveStateToUrl) {
      saveStateToUrl(this.filter, this.pagination, this.sorting, this.defaultFilter);
    }
  }

  @action updateModels(data = []) {
    this.models = data;

    if (_.isEmpty(data) && this.isInDefaultState()) {
      this.isEmpty = true;
    }
  }

  isInDefaultState() {
    if (this.filter) {
      for (const pair of this.filter.entries()) {
        if (!pair[1]) continue;
        if (_.get(this.defaultFilter, pair[0]) === pair[1]) continue;

        return false;
      }
    }

    if (this.sorting) {
      for (const pair of this.sorting.entries()) {
        if (!pair[1]) continue;

        return false;
      }
    }

    if (this.pagination && this.pagination.currentPage > 1) {
      return false;
    }

    return true;
  }
}

export default InteractiveContextState;
