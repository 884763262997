import React from 'react';
import {observer} from 'mobx-react';
import Subnav from './Subnav';
import {withState} from 'shared/core';
import {ManageReviewState} from '../state';
import {RevieweeDirectory} from './reviewees';
import {Layout} from 'components';
import ReviewActionButtons from './ReviewActionButtons';
import DeleteReviewModal from './DeleteReviewModal';
import LaunchModal from './LaunchModal';

const ManageReview = observer(({uiState, history}) => {
  const {cycle} = uiState;

  return (
    <div>
      <Subnav title={cycle.name}/>
      <Layout>
        <RevieweeDirectory cycle={cycle} history={history}/>
        <ReviewActionButtons uiState={uiState}/>
        <DeleteReviewModal uiState={uiState}/>
        <LaunchModal uiState={uiState}/>
      </Layout>
    </div>
  );
});

export default withState(ManageReview, ManageReviewState);
