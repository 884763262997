import {observable, computed, action} from 'mobx';
import _ from 'lodash';

class PerformanceReviewReviewerViewModel {
  @observable reviews;
  @observable uiState;
  @observable expanded;

  constructor(reviews, uiState) {
    this.reviews = reviews;
    this.uiState = uiState;
  }

  @action toggleExpansion() {
    this.expanded = !this.expanded;
  }

  @computed get reviewer() {
    return _.head(this.reviews).reviewer;
  }
}

export default PerformanceReviewReviewerViewModel;
