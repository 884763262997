import {action, computed, observable} from 'mobx';
import {dateToJson} from 'shared/tools';
import {endpoints, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import {TimeOffRequest} from 'stores/time_off';
import moment from 'moment';

class OutOfOfficeState {
  Emoji;

  @observable ptoRequests;
  @observable selectedDate;
  @observable loadingRequests = false;

  receiveProps({ptoRequests, Emoji}) {
    this.ptoRequests = ptoRequests;
    this.Emoji = Emoji;
  }

  @action async load() {
    this.selectedDate = moment();
  }

  @action async selectDate(date) {
    this.selectedDate = moment(date);
    await this.fetchPtoRequestsForDate();
  }

  @computed get formattedSelectedDate() {
    return this.selectedDate.format('DD-MM-YYYY');
  }

  async fetchPtoRequestsForDate() {
    if (!this.selectedDate.isValid()) return;

    this.loadingRequests = true;
    const store = new DomainStore();
    await store._compose([
      endpoints.TIME_OFF.PEOPLE_OFF.with(dateToJson(this.selectedDate.toDate()))
    ]);

    this.ptoRequests = store._getAll(types.TIME_OFF.REQUEST).map(request => new TimeOffRequest(request));
    this.loadingRequests = false;
  }
}

export default OutOfOfficeState;
