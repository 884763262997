import React from 'react';
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import HrEmployeeProfileSwitch from './HrEmployeeProfileSwitch';
import BrokerEmployeeProfileSwitch from './BrokerEmployeeProfileSwitch';
import {EmployeeProfileState} from '../state';
import {Switch} from 'react-router-dom';
import {auth, withState} from 'shared/core';

const ProfileSwitch = observer(({uiState}) => {
  return (
    <Switch>
      {auth.company.enrolmentOnly
        ? <BrokerEmployeeProfileSwitch uiState={uiState}/>
        : <HrEmployeeProfileSwitch uiState={uiState}/>
      }
    </Switch>
  );
});

const EmployeePage = observer(() => {
  return (
    <Router basename='/employees'>
      <Route path='/:id' component={withState(ProfileSwitch, EmployeeProfileState)} />
    </Router>
  );
});

export default EmployeePage;
