import {observable, computed} from 'mobx';
import uuid from 'uuid';
import _ from 'lodash';

class OutstandingEnrolmentItemViewModel {
  @observable id = uuid.v4();
  @observable employeeBenefitEnrollments

  constructor(employeeBenefitEnrollments) {
    this.employeeBenefitEnrollments = employeeBenefitEnrollments;
  }

  @computed get employee() {
    const firstBenefitEnrollment = _.head(this.employeeBenefitEnrollments);
    return _.get(firstBenefitEnrollment, 'employee');
  }

  @computed get earliestEnrolmentDate() {
    return _.head(this.sortedEnrolmentsByEnrolmentDate).enrolmentDate;
  }

  @computed get sortedEnrolmentsByEnrolmentDate() {
    return _.sortBy(this.employeeBenefitEnrollments, 'enrolmentDate');
  }
}

export default OutstandingEnrolmentItemViewModel;
