import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalDangerButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const DeleteNoteModal = ({uiState}) => {
  const {deleteModalOpen} = uiState;

  return (
    <Modal onHide={() => uiState.resetModals()} size="sm" isOpen={deleteModalOpen}>
      <div className="mb1 h3 medium scarlet">
        <FormattedMessage id='employees.profile.notes.Delete Note'/>
      </div>
      <div className="h2 medium mb3">
        <FormattedMessage id='employees.profile.notes.Are you sure you want to delete this note?'/>
      </div>
      <ModalDangerButtons onCancel={() => uiState.resetModals()} onSave={() => uiState.deleteNote()}/>
    </Modal>
  );
};

export default observer(DeleteNoteModal);
