import {action, observable, toJS} from 'mobx';
import {endpoints, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import {EmployeeGroup} from 'stores/employee_groups';
import {Segment} from 'stores/segmentation';
import _ from 'lodash';

class CustomFieldGroupListState {
  store = new DomainStore();
  action;

  @observable editingEmployeeGroup = {};
  @observable editEmployeeGroupModalOpen = false;
  @observable errors = {};

  receiveProps({action}) {
    this.action = action;
  }

  setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  @action async load() {
    if (this.action === 'new') {
      this.openAddEmployeeGroupModal();
    }
  }

  @action openAddEmployeeGroupModal() {
    const employeeGroup = new EmployeeGroup({segment: new Segment()});
    this.openEditEmployeeGroupModal(employeeGroup);
  }

  @action openEditEmployeeGroupModal(employeeGroup) {
    this.errors = {};
    this.editingEmployeeGroup = new EmployeeGroup(toJS(employeeGroup));
    this.editEmployeeGroupModalOpen = true;
  }

  @action closeEditEmployeeGroupModal() {
    this.editEmployeeGroupModalOpen = false;
  }

  @action filterEmptyRules() {
    this.editingEmployeeGroup.segment.rules = _.reject(this.editingEmployeeGroup.segment.rules, r => _.isEmpty(r.parameters) && r.ruleType !== 'all_employees');
  }

  @action async saveEmployeeGroup() {
    this.filterEmptyRules();

    const {model, errors} = this.editingEmployeeGroup.isNew ?
      await this.store.post(endpoints.EMPLOYEE_GROUPS, types.EMPLOYEE_GROUP, this.editingEmployeeGroup) :
      await this.store.patch(this.editingEmployeeGroup);

    this.errors = errors;

    if (model) {
      this.interactiveAgent.refresh();
      this.closeEditEmployeeGroupModal();
    }
  }

  @action async destroyEmployeeGroup(employeeGroup) {
    await this.store.destroy(employeeGroup);
  }
}

export default CustomFieldGroupListState;
