import React from 'react';
import {observer} from 'mobx-react';
import TrainingEditFlowState from '../state/TrainingEditFlowState';
import {Flow} from 'components';
import Step from './steps';
import steps from '../state/steps';
import {withState, t} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const TrainingName = observer(({trainingProgram}) => {
  const {name} = trainingProgram;

  if (!name) {
    return <FormattedMessage id='training.edit.Untitled Training Program'/>;
  }

  return (
    <span>{name}</span>
  );
});

const TrainingEditFlow = observer(({match, uiState}) => {
  const {trainingProgram} = uiState;

  return (
    <Flow
      name={TrainingName}
      backTitle={t('training.Training')}
      backRoute='/admin/programs'
      steps={steps}
      component={Step}
      uiState={uiState}
      baseUrl={`/admin/programs/${match.params.id}/edit`}
      trainingProgram={trainingProgram}
      onStepSubmitted={step => uiState.onStepSubmitted(step)}
      onStepChanged={step => uiState.onStepChanged(step)}
    />
  );
});

export default withState(TrainingEditFlow, TrainingEditFlowState);
