import React from 'react';
import {observer} from 'mobx-react';
import ActionLink from 'components/links/ActionLink';
import {FormattedMessage} from 'react-intl';

const CustomTableActionLinks = observer(({customTableActionLinks}) => {
  if (!customTableActionLinks) return null;

  return (
    customTableActionLinks.map(action => <ActionLink className='TextLink dodger pl2 py1 inline-block' onClick={() => action.onClick()}>
      <FormattedMessage id={action.label}/>
    </ActionLink>)
  );
});

export default CustomTableActionLinks;
