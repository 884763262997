import React from 'react';
import {observer} from 'mobx-react';
import animateScrollTo from 'animated-scroll-to';
/*global window*/
/*global document*/

const HEADER_HEIGHT = 56;
const MARGIN_Y = 28; // 2rem

function scrollRefIntoView(ref) {
  ref.scrollIntoView(true);
  const scrolledY = window.scrollY;
  if (scrolledY) {
    animateScrollTo(scrolledY - HEADER_HEIGHT - MARGIN_Y, {
      minDuration: 500,
      maxDuration: 1500
    });
  }
}

const scrollsIntoViewIfErrors = (WrappedComponent, domSelector) => {
  return @observer class extends React.Component {
    wrapperRef;

    scrollIntoViewIfErrors = () => {
      window.requestAnimationFrame(() => {
        if (this.props.errorMessage) {
          if (!this.wrapperRef.hasErrors) {
            const firstInputWithError = document.querySelector(domSelector);
            if (firstInputWithError.parentElement === this.wrapperRef) {
              scrollRefIntoView(this.wrapperRef);
            }
          }
          this.wrapperRef.hasErrors = true;
        } else if (this.wrapperRef) {
          this.wrapperRef.hasErrors = false;
        }
      });
    };

    render() {
      this.scrollIntoViewIfErrors();

      return (
        <div ref={r => this.wrapperRef = r}>
          <WrappedComponent {...this.props}/>
        </div>
      );
    }
  };
};

export default scrollsIntoViewIfErrors;
