import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {withState, auth} from 'shared/core';
import {Select2} from 'components';
import {EmployeeNavigationState} from '../state';

const EmployeeName = ({currentEmployee}) => {
  return (
    <div className='h1 medium black' style={{borderBottom: '1px dotted #C9CFD1'}}>
      {currentEmployee.name}
    </div>
  );
};

const EmployeeNavigationContainer = observer(({uiState}) => {
  const {employees, currentEmployee} = uiState;

  if (!auth.user.peopleDirectoryVisibility) {
    return <div className='h1 medium black'>{currentEmployee.name}</div>;
  }

  return (
    <div id='employee-navigation'>
      <div className='Employee-nav h1 medium black'>
        <Select2 
          placeholder={currentEmployee.name}
          translatePlaceholder={false}
          onChange={e => uiState.changeEmployee(e.target.value)}
          searchable
          dropdownClass={'Employee-navigation'}>
          {employees.map((employee) =>
            <option key={employee.id} value={employee.id}>{employee.name}</option>
          )}
        </Select2>
      </div>
    </div>
  );
});

export default withState(
  EmployeeNavigationContainer,
  EmployeeNavigationState,
  {
    spinner: EmployeeName
  }
);
