import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import BrokerPortalSideNavigation from './BrokerPortalSideNavigation';
import {ClientsPage} from './clients';
import {PersonalSettingsContainer} from 'broker_portal';
import {SwitchAccountPage} from 'users/switch_account';
import {BrokerDashboard} from './dashboard';
import {BrokerSettingsPage} from './settings';

const BrokerPortalApplication = ({match, params}) => {
  return (
    <div>
      <BrokerPortalSideNavigation />
      <Switch>
        <Route exact path='/dashboard' component={BrokerDashboard}/>
        <Route path='/clients' component={ClientsPage}/>
        <Route path='/personal_settings' component={PersonalSettingsContainer}/>
        <Route path='/settings' component={BrokerSettingsPage}/>
        <Route path='/user_session/switch_account' render={() => <SwitchAccountPage brokerView/>}/>
        <Redirect exact from='/' to='/dashboard'/>
      </Switch>
    </div>
  );
};

const BrokerPortalPage = () => {
  return (
    <Router basename='/broker_portal'>
      <Switch>
        <Route path='/' component={BrokerPortalApplication}/>
      </Switch>
    </Router>
  );
};

export default BrokerPortalPage;
