import React from 'react';
import {observer} from 'mobx-react';
import {Panel, FormLayout, DatePicker} from 'components';
import {t} from 'shared/core';
import {CompensationInformation, EmploymentDetails, EmploymentRecordCustomFields, Notes} from './shared';

const EffectiveDate = observer(({uiState}) => {
  const record = uiState.editRecord;
  const {errors} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.role.Effective Date')}/>
      <FormLayout>
        <DatePicker
          label={record.initial ? 'employees.profile.role.Start Date' : 'employees.profile.role.Effective Date'}
          value={record.effectiveDate}
          onChange={(date) => record.effectiveDate = date}
          errorMessage={errors.effectiveDate}
          options={{startView: 1}}
          className='full-width'
         />
      </FormLayout>
    </Panel>
  );
});

const EmploymentRecordForm = observer(({uiState}) => {
  const {editRecord, errors} = uiState;

  return (
    <form>
      <Panel.Stack loose>
        <EffectiveDate uiState={uiState}/>
        <EmploymentDetails uiState={uiState}/>
        <CompensationInformation uiState={uiState}/>
        <EmploymentRecordCustomFields editRecord={editRecord} errors={errors}/>
        <Notes uiState={uiState}/>
      </Panel.Stack>
    </form>
  );
});

export default EmploymentRecordForm;
