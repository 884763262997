import React from 'react';

const PanelSubheader = ({title}) => {
  return (
    <div className='PanelSubheader'>
      {title}
    </div>
  );
};

export default PanelSubheader;
