import _ from 'lodash';
import {action, observable} from 'mobx';
import CompanyDocumentViewModel from 'stores/documents/CompanyDocumentViewModel';
import {t} from 'shared/core';

class PendingDocumentsState {
  match;

  @observable documentViewModels = [];
  @observable errors = {};

  constructor(store, match) {
    this.store = store;
    this.match = match;
  }

  @action async load() {
    const response = await this.store.loadPendingDocument(this.match.params.id);
    this.handleResponse(response);
  }

  @action handleResponse(response) {
    if (_.get(response, 'response.status') === 404) {
      this.errors.document = t('onboard.documents.errors.This document is not available.');
    } else {
      const companyDocument = new CompanyDocumentViewModel(response);
      this.documentViewModels.push(companyDocument);
    }
  }

  @action onAllDocumentsCompleted() {
    window.location.href = '/inbox';
  }
}

export default PendingDocumentsState;
