import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {ActionLink, InteractiveTable, DropdownFilter} from 'components';
import {t, types, endpoints} from 'shared/core';
import {TimeOffRequest} from 'stores/time_off';
import {calendarDate} from 'shared/tools';

const RequestNotes = observer(({model, uiState}) => {
  const number = model.numberOfNotes;
  if (!number) return <div>&nbsp;</div>;

  return (
    <ActionLink onClick={() => uiState.showNotesModal(model)}>
      <FormattedMessage
        id='employees.profile.time_off.NOTES'
        values={{
          number
        }}
      />
    </ActionLink>
  );
});

const COLUMNS = [
  {
    header: 'employees.profile.time_off.Submitted',
    render: model => calendarDate(model.createdAt),
    width: 2,
    sortBy: 'createdAt'
  },
  {
    header: 'employees.profile.time_off.Requested Dates',
    attribute: 'datesView',
    width: 4,
    sortBy: 'startDate'
  },
  {
    header: 'employees.profile.time_off.Status',
    render: model => <FormattedMessage id={`models.time_off_request.status.${model.status}`}/>,
    width: 2,
    sortBy: 'status'
  },
  {
    header: 'employees.profile.time_off.Notes',
    component: RequestNotes,
    width: 2,
    sortable: false
  },
  {
    header: 'employees.profile.time_off.Days',
    attribute: 'amount',
    width: 1,
    className: 'right-align',
    sortable: false
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'employees.profile.time_off.Delete Time Off Request',
    subHeader: 'employees.profile.time_off.Are you sure you want to delete this time off request?',
    body: 'employees.profile.time_off.The requested time off will be refunded.'
  },
  emptyState: 'employees.profile.time_off.No requests to display',
  removeModel: 'employees.profile.time_off.Delete'
};

const RequestList = observer(({uiState}) => {
  const {employeeId} = uiState;

  return (
    <InteractiveTable
      uiState={uiState}
      title={t('employees.profile.time_off.Requests')}
      columns={COLUMNS}
      localization={LOCALIZATION}
      onRemove={request => uiState.deleteRequest(request)}
      onEdit={request => uiState.redirectToEdit(request)}
      searchable={false}
      showRoute={model => `/${employeeId}/time-off/request/${model.id}`}
      saveStateToUrl
      onMount={agent => uiState.setInteractiveAgent(agent)}
      proxy={{
        type: 'async',
        modelType: types.TIME_OFF.REQUEST,
        model: TimeOffRequest,
        endpoint: endpoints.TIME_OFF.REQUESTS,
        pageSize: 5
      }}
      initialFilter={{
        account_id: uiState.accountFilterOptions[0].id
      }}
    >
      <DropdownFilter options={uiState.accountFilterOptions} attr='account_id'/>
    </InteractiveTable>
  );
});

export default RequestList;
