import {DomainObject, oneOf} from 'shared/store';
import {observable, action} from 'mobx';

class CompanyInsuranceCarrier extends DomainObject {
  @observable name;
  @observable company;
  @observable upcomingRenewalDate;
  @observable selfAssignedCertificatesEnabled;
  @observable tpa;
  @observable dataFeedEnabled;

  @action merge(other) {
    super.merge(other, {
      tpa: oneOf(['sirius', 'hsp'])
    });
  }
}

export default CompanyInsuranceCarrier;
