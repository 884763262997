import {DomainObject, oneOf} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {formatCurrency, formatNumber, formatPercentage, t} from 'shared/core';

class Metric extends DomainObject {
  @observable name;
  @observable startValue;
  @observable currentValue;
  @observable targetValue;
  @observable metricType;
  @observable percentageCompletion;
  @observable completedAt;
  @observable createdAt;
  @observable updatedAt;
  @observable order;

  @action merge(other) {
    super.merge(other, {
      metricType: oneOf(['percentage', 'currency', 'number', 'boolean']),
      _numbers: ['startValue', 'currentValue', 'targetValue', 'percentageCompletion'],
      _dates: ['createdAt', 'updatedAt', 'completedAt']
    });
  }

  @computed get symbol() {
    switch (this.metricType) {
      case 'percentage':
        return '%';
      case 'currency':
        return '$';
      case 'number':
        return '#';
      case 'boolean':
        return null;
      default:
        throw new Error(`Unknown metric type of ${this.metricType}`);
    }
  }

  @computed get formattedCompleteness() {
    return formatPercentage(this.percentageCompletion/100);
  }

  @computed get formattedTargetValue() {
    return this.formattedValue(this.targetValue);
  }

  @computed get formattedCurrentValue() {
    return this.formattedValue(this.currentValue);
  }

  @computed get formattedStartValue() {
    return this.formattedValue(this.startValue);
  }

  formattedValue(value) {
    switch (this.metricType) {
      case 'percentage':
        return formatPercentage(value/100);
      case 'currency':
        return formatCurrency('CAD', value);
      case 'number':
        return formatNumber(value);
      case 'boolean':
        return Number(value) === 1 ? t('goals.Complete') : t('goals.Incomplete');
      default:
        throw new Error(`Unknown metric type of ${this.metricType}`);
    }
  }

  @computed get progressBarItems() {
    return [
      { label: 1, value: this.percentageCompletion, color: '#08BAD3' },
      { label: 2, value: 100 - this.percentageCompletion, color: '#EBEFF1' }
    ];
  }

  @computed get isBoolean() {
    return this.metricType === 'boolean';
  }

  @computed get booleanTrue() {
    return this.isBoolean && this.currentValue === 1;
  }
}

export default Metric;
