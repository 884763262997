import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {loader, t} from 'shared/core';
import {CheckboxWithLabel, Panel} from 'components';
import companyStore from 'stores/company/CompanyStore';
import CompanyFeaturesState from 'company_settings/company_features/state/CompanyFeaturesState';

const CompanyFeaturesContainer = observer(({uiState}) => {
  const {company} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('company_settings.company_features.Features')}/>
      <CheckboxWithLabel
        label='company_settings.company_features.Org Charts'
        checked={company.orgChartEnabled}
        onChange={(e) => uiState.toggleOrgChart(e.checked)}/>
      <CheckboxWithLabel
        label='company_settings.company_features.ENABLE_BANKING'
        checked={company.bankingEnabled}
        onChange={(e) => uiState.toggleBankingEnabled(e.checked)}/>
      <CheckboxWithLabel
        label='company_settings.company_features.ENABLE_SIN'
        checked={company.sinEnabled}
        onChange={(e) => uiState.toggleSinEnabled(e.checked)}/>
      <CheckboxWithLabel
        label='company_settings.company_features.ENABLE_EMPLOYEE_ANNOUNCEMENTS'
        checked={company.employeeAnnouncementsEnabled}
        onChange={(e) => uiState.toggleEmployeeAnnouncementsEnabled(e.checked)}/>
      <CheckboxWithLabel
        label='company_settings.company_features.ENABLE_PTO_CALENDAR_TYPE'
        checked={company.showPTOCalendarType}
        onChange={(e) => uiState.toggleShowPtoCalendarType(e.checked)}/>
    </Panel>
  );
});

async function load() {
  const uiState = new CompanyFeaturesState(companyStore);
  await uiState.load();
  return {uiState};
}

export default loader(CompanyFeaturesContainer, load);
