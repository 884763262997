import {observable, action, computed} from 'mobx';
import {DomainObject} from 'shared/store';
import AttachedDocument from 'stores/documents/AttachedDocument';
import _ from 'lodash';

class SecureFile extends DomainObject {
  @observable id;
  @observable awsFiles = [];

  @action merge(other) {
    super.merge(other, {
      awsFiles: [AttachedDocument]
    });
  }

  @computed get hasFiles() {
    return !_.isEmpty(this.awsFiles);
  }
}

export default SecureFile;
