import {DomainStore} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {setupAutosaveDraft, successAlert} from 'shared/tools';
import {endpoints, types, t} from 'shared/core';
import {Goal, GoalComment, Metric} from 'stores/performance_goals';
import MetricViewModel from './MetricViewModel';
import _ from 'lodash';

class ViewGoalContainerState {
  store = new DomainStore();

  @observable goal;
  @observable comment;
  @observable errors = {};
  @observable deleteCommentModalOpen = false;
  @observable isEditingComment = false;
  @observable editingComment;
  @observable editingCommentChanged = false;

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    await this.loadGoal();
  }

  @action async loadGoal() {
    this.store.invalidate(types.PERFORMANCE_GOALS.GOAL);

    await this.store._compose([
      endpoints.PERFORMANCE_GOALS.GOAL.with(this.match.params.goalId)
    ]);

    this.goal = new Goal(this.store._getSingle(
      types.PERFORMANCE_GOALS.GOAL,
      {id: this.match.params.goalId}
    ));
    this.goal.metrics = this.goal.metrics.map(metric => new MetricViewModel(metric));
    await this.initializeComment();
  }

  @action async initializeComment() {
    this.comment = new GoalComment({
      goalId: this.goal.id,
      text: ''
    });
    await setupAutosaveDraft(
      this.comment,
      {id: this.goal.id, type: types.PERFORMANCE_GOALS.TIMELINE.COMMENT}
    );
  }

  @action async deleteGoal() {
    await this.store.destroy(this.goal);
    this.closeDeleteGoalModal();
    successAlert(t('goals.Goal deleted'));
    this.onBackClick();
  }

  @action editComment(comment) {
    this.editingComment = new GoalComment(comment);
    this.isEditingComment = true;
  }

  @action cancelEditingComment() {
    this.editingComment = null;
    this.editingCommentChanged = false;
    this.isEditingComment = false;
  }

  @action toggleEditingMetric(metric) {
    if (metric.isEditing) {
      this.cancelEditingMetric(metric);
    } else {
      this.goal.metrics.forEach(metric => this.cancelEditingMetric(metric));
      metric.editingValue = metric.data.currentValue;
      metric.isEditing = true;
    }
  }

  @action cancelEditingMetric(metric) {
    metric.isEditing = false;
  }

  @action async saveComment() {
    const {model, errors} = await this.store.patch(this.editingComment);

    this.errors = errors;

    if (model) {
      const existingComment = _.find(this.goal.goalTimelineEntries, {id: this.editingComment.id});
      existingComment.text = this.editingComment.text;
      this.cancelEditingComment();
    }
  }

  @action async saveMetric(metric) {
    let editedMetric = new Metric(metric.data);
    editedMetric.currentValue = metric.editingValue;

    const {model, errors} = await this.store.post(
      metric.data.link('updateProgress'),
      types.PERFORMANCE_GOALS.METRIC,
      editedMetric,
    );

    this.errors = errors;

    if (model) {
      await this.loadGoal();
    }
  }

  @action updateComment(text) {
    this.comment.text = text;
    this.comment.autosaver.autosave();
  }

  @action updateEditingComment(text) {
    this.editingComment.text = text;
    this.editingCommentChanged = true;
  }

  @action async createComment() {
    const {model, errors} = await this.store.post(
      this.goal.link('createComment'),
      types.PERFORMANCE_GOALS.TIMELINE.COMMENT,
      this.comment
    );
    this.errors = errors;

    if (model) {
      this.addCommentToGoal(model);
      await this.initializeComment();
    }
  }

  @action addCommentToGoal(model) {
    const comment = new GoalComment(model);
    this.goal.goalTimelineEntries.unshift(comment);
  }

  @action openDeleteCommentModal(comment) {
    this.deleteCommentModalOpen = true;
    this.cancelEditingComment();
    this.editingComment = comment;
  }

  @action closeDeleteCommentModal() {
    this.deleteCommentModalOpen = false;
    this.editingComment = null;
  }

  @action async goToGoal(goal) {
    this.history.push(`/view/${goal.id}`);
  }

  @action goToDuplicateGoalPage() {
    this.history.push({
      pathname: '/new',
      search: `?duplicate_goal_id=${this.goal.id}`,
      state: {
        fromViewPage: true
      }
    });
  }

  @action async goToEditGoal() {
    this.history.push({
      pathname: `/edit/${this.goal.id}`,
      state: {
        fromViewPage: true
      }
    });
  }

  @action async deleteComment() {
    const comment = this.editingComment;
    await this.store.destroy(this.editingComment);
    this.closeDeleteCommentModal();
    successAlert(t('goals.Comment deleted'));
    this.goal.goalTimelineEntries.remove(comment);
  }

  @computed get changesMade() {
    return this.comment.text !== '';
  }

  @computed get canEdit() {
    return this.goal.canPatch;
  }

  onBackClick() {
    const fromEditPage = _.get(this.history.location.state, 'fromEditPage');
    const fromViewPage = _.get(this.history.location.state, 'fromViewPage');

    if (fromEditPage && fromViewPage) {
      this.history.go(-3);
    } else if (fromEditPage) {
      this.history.go(-2);
    } else {
      this.history.goBack();
    }
  }
}

export default ViewGoalContainerState;
