import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {observer} from 'mobx-react';
import {SurveyDirectory, EmployeeStatusDirectory, SurveyFlow, WriteSurveyContainer, SurveyViewPage} from './components';
import {withState} from 'shared/core';
import {SurveysPageState} from './state';

const AdminSurveysPage = observer(({match}) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/`} component={SurveyDirectory}/>
      <Route path={`${match.path}/survey/:id/edit`} component={SurveyFlow}/>
      <Route path={`${match.path}/survey/:id`} component={SurveyViewPage}/>
    </Switch>
  );
});

const SurveysPage = observer(({uiState}) => {
  const {scope} = uiState;

  return (
    <Router basename='/surveys'>
      <Switch>
        {scope.mySurveys && <Route exact path='/' component={EmployeeStatusDirectory}/>}
        {scope.mySurveys && <Route path='/write/:id' component={WriteSurveyContainer}/>}
        {scope.manage && <Route path='/manage' component={AdminSurveysPage}/>}
        {!scope.mySurveys && scope.manage && <Redirect exact from='/' to='/manage'/>}
      </Switch>
    </Router>
  );
});

export default withState(SurveysPage, SurveysPageState);
