import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import WorkTimeIllustration from 'img/hr-upgrade/work-time@2x.png';
import RealTimeSyncIllustration from 'img/hr-upgrade/real-time-sync@2x.png';
import TeamIllustration from 'img/team@2x.png';

const PayrollInfo = observer(({uiState}) => {
  return (
    <div>
      <div className='h2 py2'>
        <FormattedMessage id='hr_upgrade.learn_more.Why should I make the switch?'/>
      </div>
      <div className='flex flex-col md-flex-row mxn2'>
        <div className='col col-12 mb2 mx2 md-col-4 md-pr1'>
          <img className='mt2 mb3' height="100px" src={RealTimeSyncIllustration} alt='real_time_sync'/>
          <div className='h3 mb1 medium'>
            <FormattedMessage id='hr_upgrade.learn_more.Save time with more functionality'/>
          </div>
          <div className='h4 mb2 jumbo'>
            <FormattedMessage id='hr_upgrade.learn_more.Collage HR offers modules to help you automate your entire HR process and unlock new HR capabilities'/>
          </div>
        </div>
        <div className='col col-12 mb2 mx2 md-col-4 md-pl1'>
          <img className='mt2 mb3' height="100px" src={TeamIllustration} alt='team'/>
          <div className='h3 mb1 medium'>
            <FormattedMessage id='hr_upgrade.learn_more.Improve your employee experience'/>
          </div>
          <div className='h4 mb2 jumbo'>
            <FormattedMessage id='hr_upgrade.learn_more.Improve employee engagment, retention and productivity by delivering a seamless, digital HR experience from the day they are hired'/>
          </div>
        </div>
        <div className='col col-12 mb2 mx2 md-col-4 md-pl1'>
          <img className='mt2 mb3' height="100px" src={WorkTimeIllustration} alt='work_time'/>
          <div className='h3 mb1 medium'>
            <FormattedMessage id='hr_upgrade.learn_more.Unlock payroll services'/>
          </div>
          <div className='h4 mb2 jumbo'>
            <FormattedMessage id='hr_upgrade.learn_more.Do you spend too much time on payroll tasks? Clients of Collage HR can choose to add managed payroll services, freeing them of the burden of setting up, updating and running payrroll'/>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PayrollInfo;
