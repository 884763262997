import React from 'react';
import {observer} from 'mobx-react';
import {Layout, Panel} from 'components';
import CoverageList from './CoverageList';
import CoverageHeader from './CoverageHeader';
import CoverageDetails from './CoverageDetails';
import Links from './Links';
import Documents from './Documents';
import EmptyCoverages from './EmptyCoverages';

const Coverages = observer(({benefitClasses, onCoverageSelected, currentCoverage, currentClass, children}) => {
  if (!currentCoverage) return <EmptyCoverages />;

  return (
    <Layout>
      <Layout.Section secondary>
        <CoverageList
          benefitClasses={benefitClasses}
          currentCoverage={currentCoverage}
          onCoverageSelected={onCoverageSelected}
        />
      </Layout.Section>
      <Layout.Section>
        <Panel.Stack>
          <CoverageHeader currentClass={currentClass} currentCoverage={currentCoverage} />
          <CoverageDetails currentCoverage={currentCoverage} />
          {children}
          <Links currentClass={currentClass} />
          <Documents currentClass={currentClass} />
        </Panel.Stack>
      </Layout.Section>
    </Layout>
  );
});

export default Coverages;
