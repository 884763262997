import {observable, action, computed} from 'mobx';

class BenefitEnrolmentViewModel {
  @observable selectedToKeep;
  @observable enrolment;

  constructor(enrolment) {
    this.enrolment = enrolment;
    this.selectedToKeep = false;
  }

  @action updateEndDate(endDate) {
    this.enrolment.merge({endDate});
  }

  @action updateReasonForUnenrolment(reasonForUnenrolment) {
    this.enrolment.merge({reasonForUnenrolment});
  }

  @computed get id() {
    return this.enrolment.id;
  }

  @computed get benefitClass() {
    return this.enrolment.benefitClass;
  }

  @computed get endDate() {
    return this.enrolment.endDate;
  }

  @computed get reasonForUnenrolment() {
    return this.enrolment.reasonForUnenrolment;
  }
}

export default BenefitEnrolmentViewModel;
