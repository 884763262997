import {action, computed, observable} from 'mobx';
import {DomainObject} from 'shared/store';
import {Employee} from 'stores/employees';
import {calendarDate} from 'shared/tools';
import _ from 'lodash';

class CheckIn extends DomainObject {
  @observable answers = [];
  @observable completedAt;
  @observable createdAt;
  @observable reviewerNotes;
  @observable sharedReviewerNotes;
  @observable reviewer;

  @computed get date() {
    return calendarDate(this.createdAt);
  }

  @computed get completed() {
    return !!this.completedAt;
  }

  @computed get canComplete() {
    return this.hasLink('complete');
  }

  @computed get canUncomplete() {
    return this.hasLink('uncomplete');
  }

  @action merge(other) {
    super.merge(other, {
      reviewer: Employee,
      _dates: ['createdAt', 'completedAt']
    });

    if (other.answers) {
      this.answers = _.orderBy(other.answers, 'question.order');
    }
  }

  @action regenerateAnswersForQuestions(questions) {
    let newAnswers = [];
    let currentAnswers = _.keyBy(this.answers, 'question.id');

    _.forEach(questions, q => {
      let answer = {question: q};
      if (currentAnswers[q.id] !== undefined) {
        answer.content = currentAnswers[q.id].content;
      }
      newAnswers.push(answer);
    });

    this.answers = newAnswers;
  }
}

export default CheckIn;
