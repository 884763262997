import {observable, action, computed} from 'mobx';
import {t, endpoints, types} from 'shared/core';
import _ from 'lodash';
import {calendarDate} from 'shared/tools';
import {TypePolicyConfirmationSummary} from 'stores/time_off';
import {DomainStore} from 'shared/store';
/*global $*/

function isPolicyCompleted() {
  return $("#completed").val() === 'true';
}

function typePolicyId() {
  return $("#policy_id").val();
}

function formElement() {
  return $(`#edit_pto_type_policy_${typePolicyId()}`);
}

function serializeFormToUrl() {
  return formElement().serialize();
}

class PolicyEditState {
  store = new DomainStore();

  @observable modalOpen = false;
  @observable effectiveDateOptions = [];
  @observable selectedOption = null;
  @observable confirmationSummary = null;
  @observable hoursPerWorkday;
  @observable hoursPerWorkedChanged = false;

  @action async load() {
    this.hoursPerWorkday = $(".js-work-day-hours").val();
  }

  @action async loadConfirmationSummary() {
    if (!isPolicyCompleted()) return null;
    this.confirmationSummary = null;

    const model = await this.store.fetch(
      `${endpoints.TIME_OFF.TYPE_POLICIES.with(typePolicyId()).confirmationSummary}?${serializeFormToUrl()}`,
      types.TIME_OFF.TYPE_POLICY_CONFIRMATION_SUMMARY
    );
    const confirmationSummary = new TypePolicyConfirmationSummary(model);
    if (!confirmationSummary.effectiveDateRequired) return null;
    this.confirmationSummary = confirmationSummary;

    switch (this.confirmationSummary.accrualStart) {
      case 'policy_start_date':
        const policyStartDate = this.confirmationSummary.policyStartDate;
        const nextExpectedAccrualDate = this.confirmationSummary.nextExpectedAccrualDate;

        this.effectiveDateOptions = [
          {
            type: 'policyStartDate',
            name: t('time_off.policy.edit.confirmation.POLICY_START_DATE', {date: calendarDate(policyStartDate)}),
            value: policyStartDate
          },
          {
            type: 'nextAccrualPeriod',
            name: t('time_off.policy.edit.confirmation.NEXT_ACCRUAL_PERIOD', {date: calendarDate(nextExpectedAccrualDate)}),
            value: nextExpectedAccrualDate
          }
        ];
        break;
      case 'policy_holder_start_date':
        break;
      default:
        throw new Error(`Accrual start option ${this.confirmationSummary.accrualStart} not supported.`);
    }
  }

  @action async showModal() {
    await this.loadConfirmationSummary();
    if (this.confirmationSummary) {
      this.selectedOption = null;
      this.modalOpen = true;
    } else {
      this.confirm();
    }
  }

  @action confirm() {
    if (this.effectiveDate) {
      $('#change_effective_date').val(this.effectiveDate);
    }
    formElement().submit();
  }

  @action hideModal() {
    this.modalOpen = false;
  }

  @action updateEffectiveDateOption(type) {
    this.selectedOption = _.find(this.effectiveDateOptions, {type});
  }

  @action setHoursPerWorkday(value) {
    this.hoursPerWorkday = value;
    $(".js-work-day-hours").val(value);
    this.hoursPerWorkedChanged = true;
  }

  @computed get effectiveDate() {
    return _.get(this.selectedOption, 'value') || null;
  }

  @computed get effectiveDateType() {
    return _.get(this.selectedOption, 'type') || null;
  }

  @computed get confirmEnabled() {
    return !!this.effectiveDate || _.get(this.confirmationSummary, 'accrualStart') === 'policy_holder_start_date';
  }
}

export default PolicyEditState;
