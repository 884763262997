import React from 'react';
import {observer} from 'mobx-react';
import {Input} from 'components';
import {Modal, ModalButtons} from 'components/modals';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const UpdateLoginEmailModal = observer(({uiState}) => {
  const {editingUser, errors} = uiState;

  return (
    <Modal
      size='sm'
      isOpen={uiState.updateLoginEmailModalOpen}
      onHide={() => uiState.closeUpdateLoginEmailModal()}
      title={t('company_settings.users.EDIT_LOGIN_EMAIL')}
      translateTitle={false}
    >
      <div className='pb2 h3'>
        <FormattedMessage
          id='company_settings.users.EDIT_LOGIN_EMAIL_DESCRIPTION'
          values={{ name: editingUser.name }}
        />
      </div>
      {editingUser.provider && <div className='pb2 jumbo'>
        <FormattedMessage id='company_settings.users.This user will be disconnected from Google Sign-In, and they will receive an email to reset their password.'/>
      </div>}
      <Input
        value={editingUser.email}
        errorMessage={errors.email}
        onChange={(e) => editingUser.merge({email: e.target.value})}
      />
      <ModalButtons
        onSave={() => uiState.updateLoginEmail()}
        onCancel={() => uiState.closeUpdateLoginEmailModal()}
      />
    </Modal>
  );
});

export default UpdateLoginEmailModal;
