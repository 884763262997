import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {SwitchAccountPageState} from '../state';
import {Layout, Panel} from 'components';
import AccountsList from './AccountsList';

const SwitchAccountPage = observer(({uiState}) => {
  return (
    <div className='mt4'>
      <Layout centered>
        <Layout.Section>
          <Panel>
            <Panel.Header title={'Switch Accounts'}/>
            <AccountsList uiState={uiState}/>
          </Panel>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(SwitchAccountPage, SwitchAccountPageState);
