import React from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import Button from './buttons/Button';

class FilterDropdownToggle extends React.Component {
  onToggle = () => {
    const {isOpen, onOpen, onCancel} = this.props;
    return isOpen ? onCancel() : onOpen();
  }

  render() {
    const {trait, className, children, disabled} = this.props;

    return (
      <Button trait={trait || 'default'} className={className} onClick={() => this.onToggle()} disabled={disabled}>{children}</Button>
    );
  }
}

FilterDropdownToggle.propTypes = {
  children: PropTypes.node.isRequired,
  onOpen: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  disabled: PropTypes.bool
};

export default observer(FilterDropdownToggle);
