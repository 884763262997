import React from 'react';
/*global $*/

class DelayRender extends React.Component {
  componentDidMount() {
    $(".js-delay-render").hide().removeClass('hide').fadeIn(200);
  }

  render() {
    return <div></div>;
  }
}

export default DelayRender;
