import {action, computed, observable} from 'mobx';
import _ from 'lodash';
import {DomainStore} from 'shared/store';
import {endpoints, types, api, t} from 'shared/core';
import {redirect} from 'shared/tools';

class ClientsContainerState {
  store = new DomainStore();
  history;

  @observable modalOpen = false;
  @observable selectedItem = {};
  @observable broker;

  receiveProps({history}) {
    this.history = history;
  }

  @action async load() {
    await this.store._compose([
      endpoints.BROKER_PORTAL.CURRENT_BROKER
    ]);

    this.broker = this.store._getSingle(types.BROKER);
  }

  onStatusClicked(data) {
    this.selectedItem = data;
    this.openModal();
  }

  openModal() {
    this.modalOpen = true;
  }

  closeModal() {
    this.modalOpen = false;
  }

  onboardingStatuses() {
    return [
      {
        id: 'in_review',
        display: t('broker_dashboard.status.in_review.name'),
        description: t('broker_dashboard.status.in_review.description')
      },
      {
        id: 'pending_approval',
        display: t('broker_dashboard.status.pending_approval.name'),
        description: t('broker_dashboard.status.pending_approval.description')
      },
      {
        id: 'data_collection',
        display: t('broker_dashboard.status.data_collection.name'),
        description: t('broker_dashboard.status.data_collection.description')
      },
      {
        id: 'platform_setup',
        display: t('broker_dashboard.status.platform_setup.name'),
        description: t('broker_dashboard.status.platform_setup.description')
      },
      {
        id: 'client_training',
        display: t('broker_dashboard.status.client_training.name'),
        description: t('broker_dashboard.status.client_training.description')
      },
      {
        id: 'pending_launch',
        display: t('broker_dashboard.status.pending_launch.name'),
        description: t('broker_dashboard.status.pending_launch.description')
      },
      {
        id: 'live',
        display: t('broker_dashboard.status.live.name'),
        description: t('broker_dashboard.status.live.description')
      }
    ];
  }

  filterOptions() {
    const viewAllOption = {
      id: 'view_all',
      display: t('broker_dashboard.View All')
    };

    return [viewAllOption, ...this.onboardingStatuses()];
  }

  @action async impersonateCompany(company) {
    await api.put(
      endpoints.BROKER_PORTAL.IMPERSONATE_COMPANY.with(company.typeId)
    );

    return redirect('/');
  }

  @action navigateToNewClient() {
    this.history.push('/clients/new');
  }

  @computed get indexOfCurrentStatus() {
    return this.onboardingStatuses().map(status => status.id).indexOf(this.selectedItem.status);
  }
}

export default ClientsContainerState;
