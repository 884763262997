import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';

const RevokeReasonModal = observer(({uiState}) => {
  const {selectedOffer} = uiState;

  if (!selectedOffer) return null;

  return (
    <Modal
      title='offers.directory.revoke_offer.Reason for Revoking'
      size='sm'
      isOpen={uiState.revokeReasonModalOpen} 
      onHide={() => uiState.closeRevokeReasonModal()}
    >
      <div className='mb3 jumbo'>
        {selectedOffer.revokeReason}
      </div>
    </Modal>
  );
});

export default RevokeReasonModal;
