import React from 'react';
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {HrUpgradeLearnMore} from './learn_more';

const HrUpgradePage = observer(() => {
  return (
    <Router basename='/hr'>
      <Switch>
        <Route path='/learn_more' component={HrUpgradeLearnMore}/>
        <Redirect from='/' to='/learn_more'/>
      </Switch>
    </Router>
  );
});

export default HrUpgradePage;
