import {
  $applyNodeReplacement,
  TextNode,
} from 'lexical';
import _ from 'lodash';
import {addClassNamesToElement} from '@lexical/utils';
import theme from 'components/lexical_editor/Theme';

const DATA_ENTITY_TYPE = 'mention';

function convertMentionElement(domNode) {
  const textContent = domNode.textContent;
  let dataKey = domNode.getAttribute('data-key');

  if (textContent !== null) {
    return {
      node: $createMentionNode(textContent, dataKey)
    };
  }

  return null;
}

export class MentionNode extends TextNode {
  constructor(mentionName, dataKey, text, key) {
    super(_.isNil(text) ? mentionName : text, key);
    this.__mention = mentionName;
    this.__dataKey = dataKey;
  }

  static getType() {
    return 'mention';
  }

  static clone(node) {
    return new MentionNode(node.__mention, node.__dataKey, node.__text, node.__key);
  }

  static importJSON(serializedNode) {
    const node = $createMentionNode(serializedNode.mentionName, serializedNode.dataKey);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      mentionName: this.__mention,
      type: this.getType(),
      dataKey: this.__dataKey
    };
  }

  createDOM(config) {
    const element = super.createDOM(config);
    element.setAttribute('data-entity-type', DATA_ENTITY_TYPE);
    element.setAttribute('data-key', this.__dataKey);
    addClassNamesToElement(element, theme[this.getType()]);
    return element;
  }

  static importDOM() {
    return {
      span: (node) => {
        if (node.getAttribute('data-entity-type') === DATA_ENTITY_TYPE) {
          return {
            conversion: convertMentionElement,
            priority: 4,
          };
        }
        return null;
      },
    };
  }

  isTextEntity() {
    return true;
  }

  canInsertTextBefore() {
    return false;
  }

  canInsertTextAfter() {
    return false;
  }
}

export function $createMentionNode(mentionName, dataKey) {
  const mentionNode = new MentionNode(mentionName, dataKey);
  mentionNode.setMode('segmented');
  return $applyNodeReplacement(mentionNode);
}

export function $isMentionNode(node) {
  return node instanceof MentionNode;
}
