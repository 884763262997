import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';
import PositionStatus from "recruiting/components/PositionStatus";

const PositionName = observer(({model}) => {
  const position = model;
  const title = position.title || t('recruiting.Untitled Position');

  return (
    <div>
      <div className='table'>
        <div className='mr2 left table-cell'>
          {title}
        </div>
        <div className='left table-cell'>
          <PositionStatus position={position}/>
        </div>
      </div>
      <div className='waterloo h5'>
        <FormattedMessage
          id='recruiting.position.CANDIDATE_COUNT'
          values={{candidateCount: position.candidateCount}}
        />
      </div>
    </div>
  );
});

export default PositionName;
