import React from 'react';
import {t} from 'shared/core';
import {observer} from 'mobx-react';
import {Display} from 'components';

const SubNav = observer(({title, toolbar}) => {
  return (
    <Display
      title={title}
      backTitle={t('company_settings.Settings')}
      toolbar={toolbar}
    />
  );
});

export default SubNav;
