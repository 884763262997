import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import {Avatar} from 'components';
import {FormattedMessage} from 'react-intl';

const MoreAvatarsModal = observer(({uiState}) => {
  const {limit, employees, moreEmployeesList, linkable} = uiState;
  if (!moreEmployeesList) return null;

  return (
    <Modal
      size='sm'
      isOpen={uiState.moreAvatarsModalOpen} 
      onHide={() => uiState.closeMoreAvatarsModal()}>
      <div className='center black medium mb2 h2'>
        <FormattedMessage 
          id='components.avatar_list.NUMBER_EMPLOYEES'
          values={{employees: employees.length - limit}}/>
      </div>
      <div className='clearfix mxn2'>
        {moreEmployeesList.map(employee => <div key={employee.id} className='col col-3 px2 my2'>
          <Avatar linkable={linkable} employee={employee} />
        </div>)}
      </div>
    </Modal>
  );
});

export default MoreAvatarsModal;
