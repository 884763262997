import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {calendarDate} from 'shared/tools';
import BenefitUpdateForEmployee from './BenefitUpdateForEmployee';

const UpdateDigestList = observer(({uiState}) => {
  const {updatesByDate, refreshing} = uiState;

  return (
    updatesByDate.map((updateWithDate, index) => (
      <Panel.Stack className='relative mb2' key={index}>
        <div className={`${refreshing ? 'TableOverlay' : ''}`}></div>
        <Panel>
          <Panel.Header title={calendarDate(updateWithDate.date)}/>
        </Panel>
        {updateWithDate.items.map(i => <BenefitUpdateForEmployee update={i} key={i.id}/>)}
      </Panel.Stack>
    ))
  );
});

export default UpdateDigestList;
