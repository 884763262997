import React from 'react';
import {FormattedMessage} from 'react-intl';
import Icon  from 'containers/performance/img/speedometer-icon@2x.png';

const RevieweesListEmptyState = () => {
  return (
    <div className='Container'>
      <div className='bg-white rounded box-shadow-1 p3 my3 center'>
        <div className='clearfix'>
          <div className='col-8 mx-auto'>
            <div className='center'>
              <img width='100px' className='my3' src={Icon} role='presentation'/>
            </div>
            <div className='h1'>
              <FormattedMessage id="performance.A simple framework for one-on-one conversations"/>
            </div>
            <div className='h3 mt2 mb4 max-width-3 px3 mx-auto'>
              <FormattedMessage id="performance.Performance Check-Ins help you have frequent and productive one-on-ones with your employees. To get started, speak with your Collage administrator." />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevieweesListEmptyState;

