import _ from 'lodash';
import {action, observable, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints} from 'shared/core';
import {calendarDate} from 'shared/tools';
import {PayrollDiff} from 'stores/payroll';

class EmployeeDiffsState {
  store = new DomainStore();
  match;

  @observable diffs;
  @observable payPeriod;
  @observable employee;
  @observable employeeFiles;

  receiveProps({match}) {
    this.match = match;
  }

  @action async load() {
    const employeeId = _.get(this.match.params, 'employee_id');
    const periodId = _.get(this.match.params, 'period_id');

    await this.store._compose([
      endpoints.PAY_PERIOD.WITH_EMPLOYEES.with(periodId),
      endpoints.PAYROLL_DIFFS.with(employeeId, periodId)
    ]);

    this.payPeriod = this.store._getSingle(types.PAY_PERIOD, {id: periodId});
    this.diffs = this.store._getAll(types.PAYROLL_DIFF, PayrollDiff);
    this.employee = _.find(this.payPeriod.employees, { 'id': employeeId });
  }

  @computed get calendarStartDate() {
    return calendarDate(this.payPeriod.periodStartDate);
  }

  @computed get calendarEndDate() {
    return calendarDate(this.payPeriod.periodEndDate);
  }

  @computed get calendarCutoffDate() {
    return calendarDate(this.payPeriod.cutoffDate);
  }

  @computed get calendarPayDate() {
    return calendarDate(this.payPeriod.payDate);
  }
}

export default EmployeeDiffsState;
