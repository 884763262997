import React from 'react';
import {observer} from 'mobx-react';
import FileDefaultState from 'img/file-large-default@2x.png';
import FileDownloadState from 'img/file-large-download@2x.png';
import {FormattedMessage} from 'react-intl';
import ActionLink from './links/ActionLink';

const RemoveLink = ({onClick}) => {
  return (
    <div className='table-cell align-middle full-width right-align'>
      <ActionLink onClick={() => onClick()} className='waterloo'>
        <i className='material-icons h3 ml2 align-middle'>{'close'}</i>
      </ActionLink>
    </div>
  );
};

const AttachedDocumentDownload = observer(({doc, name, className, removeLink, onRemoveClick}) => {
  const documentName = name || doc.fileName;
  const url = doc.hasLink('url') ? doc.link('url') : doc.preview;

  return (
    <a href={url} target='_blank' className={`block ${className ? className : ''}`}>
      <div className='HoverAnimation-trigger'>
        <div className='table-cell align-middle'>
          <div className='relative'>
            <img src={FileDefaultState} alt='file' height='35px'/>
            <img src={FileDownloadState} alt='open' height='35px' className='HoverAnimation-target--fade-up absolute left-0' />
          </div>
        </div>
        <div className='table-cell align-top full-width pl1'>
          <div>{documentName}</div>
          <div className='HoverAnimation-target--hide h6 waterloo'>{doc.fileSizeInBytes}</div>
          <div className='HoverAnimation-target--show h6 waterloo'>
            <FormattedMessage id='components.pdf.Click to download'/>
          </div>
        </div>
        {removeLink && <RemoveLink onClick={onRemoveClick}/>}
      </div>
    </a>
  );
});

export default AttachedDocumentDownload;
