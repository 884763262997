import _ from 'lodash';
import {Steps} from 'shared/tools';

 const STEPS = [
  {
    name: 'surveys.edit.steps.General',
    location: 'general'
  },
  {
    name: 'surveys.edit.steps.Questions',
    location: 'questions'
  },
  {
    name: 'surveys.edit.steps.Review',
    location: 'review'
  }
].map((s, i) => ({
  ...s,
  index: i + 1
}));

 const singleton = new Steps(STEPS);

 export default singleton;
