import React from 'react';
import {observer} from 'mobx-react';
import ReactPlayer from 'react-player';

const VideoPlayer = observer(({url, className}) => {
  if (!url) {
    return null;
  }

  return (
    <div className={`player-wrapper ${className}`}>
      <ReactPlayer
        className='react-player'
        width='100%'
        height='100%'
        url={url}
        controls
      />
    </div>
  );
});

export default VideoPlayer;
