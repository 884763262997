import {DomainObject} from 'shared/store';
import {observable, action} from 'mobx';
import {User} from 'stores/users';

class AnnouncementComment extends DomainObject {
  @observable id;
  @observable content;
  @observable createdAt;
  @observable updatedAt;
  @observable announcementId

  @action merge(other) {
    super.merge(other, {
      _dates: ['createdAt', 'updatedAt'],
      user: User
    });
  }
}

export default AnnouncementComment;
