import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {Table, Panel} from 'components';
import InterviewGuideState from 'recruiting/state/InterviewGuideState';

const COLUMNS = [
  {
    header: 'recruiting.interview_guides.Name',
    attribute: 'name',
    width: 11
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'recruiting.interview_guides.Remove Interview Guide',
    subHeader: 'recruiting.interview_guides.Are you sure you want to remove this interview guide?',
    body: 'recruiting.interview_guides.Interview guides already in use will not be removed.'
  },
  addModel: 'recruiting.interview_guides.+ Add an Interview Guide',
  emptyState: 'recruiting.interview_guides.No interview guides to display'
};

const InterviewGuideDirectoryWithState = observer(({history, uiState}) => {
  const {interviewGuides} = uiState;

  return (
    <div>
      <div className='Container clearfix'>
        <div className='col-12 lg-col-10'>
          <Panel>
            <Panel.Header title={t('recruiting.interview_guides.Interview Guides')} />
            <Table models={interviewGuides}
                   columns={COLUMNS}
                   localization={LOCALIZATION}
                   onRemove={(model) => uiState.deleteInterviewGuide(model)}
                   onAdd={() => history.push('/interview-guides/edit')}
                   editRoute='interview-guides/edit/:id' />
          </Panel>
        </div>
      </div>
    </div>
  );
});

export default withState(InterviewGuideDirectoryWithState, InterviewGuideState);
