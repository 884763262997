import {action, observable} from 'mobx';
import {DomainStore} from 'shared/store';
import SecureFile from 'stores/secure_upload/SecureFile';
import {endpoints, types, t} from 'shared/core';
import {successAlert} from 'shared/tools';
import _ from 'lodash';

class CompanyBenefitsUploadState {
  store = new DomainStore();
  history;

  @observable secureFile = new SecureFile();
  @observable isUploading = false;
  @observable submitted = false;
  @observable errors = {};

  receiveProps({history}) {
    this.history = history;
  }

  @action onUploadStarted() {
    this.isUploading = true;
  }

  @action onUploadFinished() {
    this.isUploading = false;
  }

  @action async saveFiles() {
    if (!this.validate()) return null;

    const {model, errors} = await this.store.post(
      endpoints.SECURE_UPLOAD.NEW,
      types.SECURE_FILE,
      this.secureFile
    );

    this.errors = errors;

    if (model) {
      this.submitted = true;
      successAlert(t('broker_portal.signup.company.Successfully uploaded files to Collage.'));
      this.history.push('/processing');
    }
  }

  validate() {
    this.errors = {};
    if (!this.secureFile.hasFiles) {
      this.errors.awsFiles = t('broker_portal.signup.company.Choose a file to upload');
    }

    return _.isEmpty(this.errors);
  }
}

export default CompanyBenefitsUploadState;

