import React from 'react';
import {observer} from 'mobx-react';
import {auth} from 'shared/core';
import {EmployeeLink} from 'components/tables';

const EmployeeEnrolmentLink = observer(({model, uiState}) => {
  const options = {};
  if (auth.moduleEnabled('directory')) {
    options.to = uiState.routeForEnrolment(model);
  } else {
    options.href = `/employees/${model.employee.id}/benefits`;
  }

  return (
    <EmployeeLink {...options} model={model} />
  );
});

export default EmployeeEnrolmentLink;
