import React from 'react';
import {observer} from 'mobx-react';
import {Button} from 'components';
import {FormattedMessage} from 'react-intl';

const DocumentTask = observer(({uiState}) => {
  const {task, isAssignee} = uiState;

  return (
    <div>
      <div className='mb3 jumbo'>
        <FormattedMessage id='inbox.document_task_explanation'/>
      </div>
      {isAssignee && !task.completed &&
        <Button onClick={() => window.location.href = `/pending_documents/${task.relatedTo.id}`} className={task.canDelete ? 'mr2' : ''}>
          <FormattedMessage id='inbox.View Document'/>
        </Button>}
      {task.canDelete && <Button trait='destructive' onClick={() => uiState.deleteTask()}>
        <FormattedMessage id='inbox.Delete Task'/>
      </Button>}
    </div>
  );
});

export default DocumentTask;
