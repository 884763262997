import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel} from 'components';
import _ from 'lodash';

const Links = observer(({currentClass}) => {
  const {attachedLinks} = currentClass;
  if (_.isEmpty(attachedLinks)) return null;

  return (
    <Panel>
      <Panel.Header title={t('components.coverages_list.Important Links')}/>
      {attachedLinks.map((link, index) => 
        <div className={index === 0 ? '' : 'mt2'} key={link.id}>
          <div className='table-cell align-middle px1'>
            <i className='material-icons inline h3'>{'public'}</i>
          </div>
          <div className='table-cell align-middle px1'>
            <a href={link.url} className='block' target='_blank'>{link.linkTitle}</a>
            <div className='h6 jumbo'>{link.title}</div>
          </div>
        </div>)}
    </Panel>
  );
});

export default Links;

