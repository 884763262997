import React from 'react';
import {observer} from 'mobx-react';
import Input from './Input';
import Checkbox from './Checkbox';
import FilterDropdownButtons from './FilterDropdownButtons';
import PropTypes from 'prop-types';

class FilterDropdownMenu extends React.Component {
  state = {
    shouldResize: true
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.searchValue !== nextProps.searchValue) {
      this.dropdown.firstChild.scrollTop = 0;
      this.setState({ shouldResize: false });
    } else {
      this.setState({ shouldResize: true });
    }
  }

  getMaxHeight = () => {
    if (this.props.isOpen && !this.dropdown) return 'none';
    if (this.props.isOpen && !this.state.shouldResize) return this.dropdown.parentElement.style.maxHeight;

    return this.props.isOpen ? `${parseInt(this.dropdown.offsetHeight) + 70}px` : 0;
  }

  getScrollBar = () => {
    if (!this.props.scrollable) return 'visible';
    const height = this.props.isOpen ? parseInt(this.dropdown.offsetHeight) : 0;
    return (height > 390) ? 'auto' : 'none';
  }

  render() {
    const {isOpen, onCancel, onApply, position, showDropdownButtons, searchable, searchValue, onChange, scrollable, wide, children} = this.props;

    return (
      <div className='relative'>
        <div className={`FilterDropdown-container ${position}-0 ${isOpen ? 'is-opened' : 'is-closed'}`} style={{maxHeight: this.getMaxHeight()}}>
          {searchable && <div className='pt2 px2'>
            <Input type='search'
                   value={searchValue}
                   onChange={e => onChange(e.target.value)}
                   className='InputSearch full-width'
                   placeholder='reports.Search'/>
          </div>}
          <div className={`FilterDropdown${wide ? '--wide' : ''}`} ref={dropdown => this.dropdown = dropdown}>
            <div className='FilterDropdown-list' style={{overflowY: this.getScrollBar(), maxHeight: scrollable ? '320px' : 'none'}}>{children}</div>
            {showDropdownButtons && <FilterDropdownButtons onCancel={onCancel} onApply={onApply} />}
          </div>
        </div>
      </div>
    );
  }
}

FilterDropdownMenu.Checkbox = observer(({children, checked, className, onChange}) => {
  return (
    <label className={`clearfix table clickable ${className}`}>
      <div className='col-1 table-cell align-middle pr1'>
        <Checkbox checked={checked} onChange={onChange}/>
      </div>
      <div className={`col-11 table-cell align-middle pl1 word-break ${checked ? 'tuatara' : 'jumbo'}`}>
        {children}
      </div>
    </label>
  );
});

FilterDropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onApply: PropTypes.func,
  position: PropTypes.string,
  onChange: PropTypes.func,
  searchable: PropTypes.bool,
  searchValue: PropTypes.string
};

FilterDropdownMenu.defaultProps = {
  position: 'left',
  showDropdownButtons: true,
  searchable: false,
  scrollable: true
};

FilterDropdownMenu.Checkbox.defaultProps = {
  className: 'pb2'
};

export default observer(FilterDropdownMenu);
