import React from 'react';
import {withRouter} from 'react-router-dom';
import {Display, Layout, IndexTable, Panel} from 'components';
import {t, auth} from 'shared/core';
import GeneralIcon from 'img/settings/settings-general@2x.png';
import DocumentsIcon from 'img/settings/settings-documents@2x.png';
import RemindersIcon from 'img/settings/settings-reminders@2x.png';
import EmployeeGroupsIcon from 'img/settings/settings-employee-groups@2x.png';
import CalendarsIcon from 'img/settings/settings-calendars@2x.png';
import PermissionsIcon from 'img/settings/settings-permissions@2x.png';
import CompanyLinksIcon from 'img/settings/settings-company-links@2x.png';
import CompanyFilesIcon from 'img/settings/settings-company-files@2x.png';
import CustomFieldsIcon from 'img/settings/settings-custom-fields@2x.png';
import LeaveTypesIcon from 'img/settings/settings-leave-types@2x.png';
import BillingIcon from 'img/settings/settings-billing@2x.png';
import DepartmentsIcon from 'img/settings/settings-departments@2x.png';
import ExternalUsersIcon from 'img/settings/settings-external-users@2x.png';
import JazzHRIcon from 'img/integrations/Integration-JazzHR@2x.jpg';
import GreenhouseIcon from 'img/integrations/Integration-Greenhouse@2x.png';
import IndeedIcon from 'img/integrations/Integration-Indeed@2x.png';
import SlackIcon from 'img/integrations/Integration-Slack@2x.jpg';
import ZapierIcon from 'img/integrations/Integration-Zapier@2x.png';
import OnboardingIcon from 'img/settings/settings-onboarding@2x.png';
import OffboardingIcon from 'img/settings/settings-offboarding@2x.png';
import DocumentFoldersIcon from 'img/settings/settings-folders@2x.png';
import EmailsIcon from 'img/settings/settings-emails@2x.png';
import OfferTemplatesIcon from 'img/settings/settings-offer-templates@2x.png';
import MFAIcon from 'img/settings/settings-mfa@2x.png';
import GoogleLogo from 'img/google-logo@2x.png';
import OktaLogo from 'img/okta-logo@2x.png';
import MicrosoftLogo from 'img/microsoft-logo@2x.png';

const CompanySettingsIndex = ({history}) => {
  if (
    !auth.hasAccess('::MANAGE_CALENDARS') &&
    !auth.hasAccess('::MANAGE_FOLDERS') &&
    !auth.hasAccess('::MANAGE_GENERAL_INFO') &&
    !auth.hasAccess('::MANAGE_REMINDERS') &&
    !auth.hasAccess('::MANAGE_DOCUMENTS')
  )
    return null;

  return (
    <IndexTable title={t('company_settings.Company')}>
      {auth.moduleEnabled('account_management') && auth.hasAccess('::MANAGE_GENERAL_INFO') &&
        <IndexTable.Row
          icon={GeneralIcon}
          title={t('company_settings.subnav.General Info')}
          description={t('company_settings.Edit company name, contact information, logo, and locations.')}
          showRoute='/general'
        />
      }
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_GENERAL_INFO') &&
        <IndexTable.Row
          icon={DepartmentsIcon}
          title={t('company_settings.subnav.Departments')}
          description={t('company_settings.Add, edit or delete company departments.')}
          showRoute='/departments'
        />
      }
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_DOCUMENTS') &&
        <IndexTable.Row
          icon={DocumentsIcon}
          title={t('company_settings.subnav.Documents')}
          description={t('company_settings.Manage and send company documents to your employees.')}
          showRoute='/documents'
        />
      }
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_FOLDERS') &&
        <IndexTable.Row
          icon={DocumentFoldersIcon}
          title={t('company_settings.subnav.Document Folders')}
          description={t('company_settings.document_folders.Organize company documents into folders.')}
          showRoute='/document_folders'
        />
      }
      {auth.moduleEnabled('pto') && auth.hasAccess('::MANAGE_CALENDARS') &&
        <IndexTable.Row
          icon={CalendarsIcon}
          title={t('company_settings.subnav.Calendars')}
          description={t('company_settings.Configure company calendar feeds.')}
          showRoute='/calendars'
        />
      }
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_REMINDERS') &&
        <IndexTable.Row
          icon={RemindersIcon}
          title={t('company_settings.subnav.Reminders')}
          description={t('company_settings.Set up reminders to keep track of important events.')}
          showRoute='/reminders'
        />
      }
    </IndexTable>
  );
};

const PlatformSettingsIndex = () => {
  if (
    !auth.hasAccess('::MANAGE_LEAVE_TYPES') &&
    !auth.hasAccess('::MANAGE_EMAIL_TEMPLATES') &&
    !auth.hasAccess('::MANAGE_TASK_TEMPLATES') &&
    !auth.hasAccess('::MANAGE_OFFER_TEMPLATES') &&
    !auth.user.admin
  )
    return null;

  return (
    <IndexTable title={t('company_settings.Platform')}>
      {auth.user.admin && <IndexTable.Row
        icon={CustomFieldsIcon}
        title={t('company_settings.subnav.Visibility Preferences')}
        description={t('company_settings.Configure what widgets and pages are visible to your employees.')}
        showRoute='/visibility'
      />}
      {auth.hasAccess('::MANAGE_LEAVE_TYPES') && <IndexTable.Row
        icon={LeaveTypesIcon}
        title={t('company_settings.subnav.Leave Types')}
        description={t('company_settings.Add, edit, or delete leave types.')}
        showRoute='/leave_types'
      />}
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_TASK_TEMPLATES') && <IndexTable.Row
        icon={OnboardingIcon}
        title={t('company_settings.subnav.Onboarding Tasks')}
        description={t('company_settings.Add, edit, or delete reusable onboarding tasks.')}
        showRoute='/tasks/onboarding'
      />}
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_TASK_TEMPLATES') && <IndexTable.Row
        icon={OffboardingIcon}
        title={t('company_settings.subnav.Offboarding Tasks')}
        description={t('company_settings.Add, edit, or delete reusable offboarding tasks.')}
        showRoute='/tasks/offboarding'
      />}
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_EMAIL_TEMPLATES') && <IndexTable.Row
        icon={EmailsIcon}
        title={t('company_settings.subnav.Email Templates')}
        description={t('company_settings.Customize automated email templates.')}
        showRoute='/company_email_templates'
      />}
      {auth.moduleEnabled('directory') && auth.hasAccess('::MANAGE_OFFER_TEMPLATES') && <IndexTable.Row
        icon={OfferTemplatesIcon}
        title={t('company_settings.subnav.Offer Templates')}
        description={t('company_settings.Customize offers sent to prospective hires.')}
        showRoute='/offer_templates'
      />}
    </IndexTable>
  );
};

const CustomFieldSettingsIndex = () => {
  if (
    !auth.user.admin
  )
    return null;

  return (
    <IndexTable title={t('company_settings.Custom Fields')}>
      {auth.user.admin && <IndexTable.Row
        icon={CustomFieldsIcon}
        title={t('company_settings.subnav.Custom Fields')}
        description={t('company_settings.Configure custom fields to store additional information about your employees.')}
        showRoute='/custom_fields'
      />}
      {auth.moduleEnabled('directory') && auth.user.admin &&
        <IndexTable.Row
          icon={EmployeeGroupsIcon}
          title={t('company_settings.subnav.Employee Groups')}
          description={t('company_settings.Create groups of employees for assigning custom fields.')}
          showRoute='/employee_groups'
        />
      }
    </IndexTable>
  );
};

const DashboardSettingsIndex = () => {
  if (
    !auth.user.admin || auth.company.enrolmentOnly
  )
    return null;

  return (
    <IndexTable title={t('company_settings.Home Page')}>
      {auth.user.admin && <IndexTable.Row
        icon={CompanyLinksIcon}
        title={t('company_settings.subnav.Company Links')}
        description={t('company_settings.Configure company links visible to all employees.')}
        showRoute='/company_links'
      />}
      {auth.user.admin && <IndexTable.Row
        icon={CompanyFilesIcon}
        title={t('company_settings.subnav.Company Files')}
        description={t('company_settings.Configure company files visible to all employees.')}
        showRoute='/company_files'
      />}
    </IndexTable>
  );
};

const SecurityAndBillingIndex = () => {
  const showRolesAndPermissions = auth.moduleEnabled('directory') && auth.user.admin;
  const showSecurityAndBilling = auth.moduleEnabled('billing') && auth.hasAccess('::MANAGE_BILLING');
  const companyAdmin = auth.user.admin;

  if (!companyAdmin && !showSecurityAndBilling && !showRolesAndPermissions)
    return null;

  return (
    <IndexTable title={t('company_settings.Security & Billing')}>
      {showRolesAndPermissions &&
        <IndexTable.Row
          icon={PermissionsIcon}
          title={t('company_settings.subnav.Roles & Permissions')}
          description={t('company_settings.Control what information and features your employees can access in the platform.')}
          showRoute='/permissions'
        />
      }
      {companyAdmin && <IndexTable.Row
        icon={ExternalUsersIcon}
        title={t('company_settings.subnav.Users')}
        description={t('company_settings.Allow and revoke access to the platform for people outside your company (e.g, HR consultants).')}
        showRoute='/users'
      />}
      {companyAdmin && <IndexTable.Row
        icon={MFAIcon}
        title={t('company_settings.subnav.Authentication')}
        description={t('company_settings.Configure multi-step authentication for your company.')}
        showRoute='/authentications'
      />}
      {showSecurityAndBilling &&
        <IndexTable.Row
          icon={BillingIcon}
          title={t('company_settings.subnav.Billing')}
          description={t('company_settings.View your statement history and change your payment details.')}
          showRoute='/billing'
        />
      }
    </IndexTable>
  );
};

const Integrations = () => {
  const showIntegrations = auth.moduleEnabled('directory') && auth.user.admin;
  if (!showIntegrations) return null;

  return (
    <IndexTable title={t('company_settings.Integrations')}>
      <IndexTable.Row
        icon={ZapierIcon}
        trait='custom'
        title={t('company_settings.subnav.Zapier')}
        description={t('company_settings.Automatically sync information to Zapier from Collage.')}
        showRoute='/apps/zapier'
      />
      <IndexTable.Row
        icon={OktaLogo}
        trait='custom'
        title={t('company_settings.subnav.Okta')}
        description={t('company_settings.Use Okta for single sign-on.')}
        showRoute='/apps/okta'
      />
      <IndexTable.Row
        icon={GoogleLogo}
        trait='custom'
        title={t('company_settings.subnav.Google')}
        description={t('company_settings.Use Google for single sign-on.')}
        showRoute='/apps/google'
      />
      <IndexTable.Row
        icon={MicrosoftLogo}
        trait='custom'
        title={t('company_settings.subnav.Microsoft')}
        description={t('company_settings.Use Microsoft for single sign-on.')}
        showRoute='/apps/microsoft'
      />
      <IndexTable.Row
        icon={SlackIcon}
        trait='custom'
        title={t('company_settings.subnav.Slack')}
        description={t('company_settings.Connect your Slack workplace to Collage.')}
        showRoute='/apps/slack'
      />
      <IndexTable.Row
        icon={JazzHRIcon}
        trait='custom'
        title={t('company_settings.subnav.JazzHR')}
        description={t('company_settings.Automatically sync applicants hired on JazzHR to Collage.')}
        showRoute='/apps/jazz_hr'
      />
      <IndexTable.Row
        icon={GreenhouseIcon}
        trait='custom'
        title={t('company_settings.subnav.Greenhouse')}
        description={t('company_settings.Automatically sync applicants hired on Greenhouse to Collage.')}
        showRoute='/apps/greenhouse'
      />
      <IndexTable.Row
        icon={IndeedIcon}
        trait='custom'
        title={t('company_settings.subnav.Indeed')}
        description={t('company_settings.Automatically sync jobs created on Collage to Indeed.')}
        showPath='/recruiting/job-site'
      />
    </IndexTable>
  );
};

const SettingsIndex = withRouter(() => {
  return (
    <div>
      <Display title={t('company_settings.Settings')}/>
      <Layout>
        <Layout.Section>
          <Panel.Stack loose>
            <CompanySettingsIndex/>
            <PlatformSettingsIndex/>
            <CustomFieldSettingsIndex/>
            <DashboardSettingsIndex/>
            <SecurityAndBillingIndex/>
            <Integrations/>
          </Panel.Stack>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default SettingsIndex;
