import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Employee',
  'Department',
  'Location',
  'Manager'
];

const EmergencyContactsReport = () => {
  return (
    <CompositeReport filters={filters}>
      <Report name={t('reports.EMERGENCY_CONTACT_INFO_REPORT')}
              url='/reports/emergency_contacts'>
      </Report>
    </CompositeReport>
  );
};

export default EmergencyContactsReport;
