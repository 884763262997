import {DomainObject} from 'shared/store';
import {observable, action} from 'mobx';

class Location extends DomainObject {
  @observable id;
  @observable name = '';
  @observable regionCode;
  @observable countryCode;
  @observable address;
  @observable city;
  @observable postalCode;

  @action updateCountry(countryCode) {
    if (this.countryCode !== countryCode) {
      this.regionCode = '';
    }

    this.countryCode = countryCode;
  }
}

export default Location;
