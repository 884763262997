import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints, t} from 'shared/core';
import {successAlert} from 'shared/tools';
import GoogleCredentials from 'stores/sso/GoogleCredentials';
import {User} from 'stores/users';

class GoogleConfigurationContainerState {
  store = new DomainStore();
  @observable credentials;
  @observable users = [];

  @action async load() {
    await this.store._compose([
      endpoints.SSO.GOOGLE_CREDENTIALS
    ]);

    this.credentials = new GoogleCredentials(
      this.store._getSingle(types.SSO.GOOGLE_CREDENTIALS)
    );

    if (this.credentials.domain) {
      await this.store._compose([
        endpoints.USERS.ACTIVE_WITH_DOMAIN_MISMATCH.with('google')
      ]);
      this.users = this.store._getAll(types.USER, User);
    }
  }

  @action async toggleSSO() {
    let model;
    if (this.company.ssoEnabled) {
      model = (await this.store.patch(
        endpoints.COMPANY.with(this.company.id),
        types.COMPANY,
        {...this.company, ssoProviderName: null}
      )).model;
    } else {
      model = (await this.store.post(
        endpoints.COMPANY.ENABLE_GOOGLE_SSO,
        types.COMPANY
      )).model;
    }

    this.company.update(model);
    successAlert(t('company_settings.applications.Configuration updated.'));
    this.closeSSOConfirmationModal();
  }

  @computed get company() {
    return this.credentials.company;
  }
}

export default GoogleConfigurationContainerState;
