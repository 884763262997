import {DomainObject} from 'shared/store';
import {action, observable, computed} from 'mobx';
import {AttachedDocument} from 'stores/documents';
import BrokerClientInsuranceCarrier from './BrokerClientInsuranceCarrier';
import BrokerClientPlanAdmin from './BrokerClientPlanAdmin';
import _ from 'lodash';

class BrokerClient extends DomainObject {
  @observable name;
  @observable numberOfLives;
  @observable hasFlexPlan;
  @observable comment;
  @observable interestedInServices;

  @observable benefitsCarriers = [];
  @observable pensionsCarriers = [];
  @observable attachedFiles = [];
  @observable planAdmins = [];

  constructor(brokerClient) {
    super(brokerClient);

    if (_.isEmpty(this.benefitsCarriers)) {
      this.benefitsCarriers.push(
        new BrokerClientInsuranceCarrier({serviceType: 'benefits'})
      );
    }

    if (_.isEmpty(this.planAdmins)) {
      this.planAdmins.push(
        new BrokerClientPlanAdmin({primary: true})
      );
    }
  }

  @action merge(other) {
    super.merge(other, {
      benefitsCarriers: [BrokerClientInsuranceCarrier],
      pensionsCarriers: [BrokerClientInsuranceCarrier],
      attachedFiles: [AttachedDocument],
      planAdmins: [BrokerClientPlanAdmin]
    });
  }

  @computed get sortedPlanAdmins() {
    return _.sortBy(this.planAdmins, 'id');
  }
}

export default BrokerClient;
