import React from 'react';
import {observer} from 'mobx-react';
import EmployeeHasSpouse from './EmployeeHasSpouse';
import SpouseForm from './SpouseForm';
import SpouseInsurerFields from './SpouseInsurerFields';
import {FormLayout} from 'components';

const SpouseFields = observer(({benefitInfo, errors}) => {
  return (
    <FormLayout>
      <EmployeeHasSpouse benefitInfo={benefitInfo} errors={errors}/>
      <SpouseForm benefitInfo={benefitInfo} errors={errors}/>
      <SpouseInsurerFields benefitInfo={benefitInfo} errors={errors}/>
    </FormLayout>
  );
});

export default SpouseFields;
