import React from 'react';
import {observer} from 'mobx-react';
import DueDateSelector from './DueDateSelector';
import AssignToSelector from './AssignToSelector';
import {FormLayout, Input, LexicalEditor} from 'components';

const TaskTemplateFields = observer(({taskTemplateForm, errors}) => {
  const {taskTemplate} = taskTemplateForm;
  const {config} = taskTemplate;

  return (
    <FormLayout>
      <Input
        value={taskTemplate.name}
        onChange={e => taskTemplate.merge({name: e.target.value})}
        errorMessage={errors.name}
        label='company_settings.tasks.Name' />
      <AssignToSelector taskTemplateForm={taskTemplateForm}/>
      <DueDateSelector taskTemplateForm={taskTemplateForm} config={config} errorMessage={errors.dueDate}/>
      <LexicalEditor
        label={'tasks.Description'}
        onChange={description => taskTemplate.merge({description: description.html, lexicalState: description.state})}
        initialEditorState={taskTemplate.lexicalState}
        initialHTML={taskTemplate.description}
        errorMessage={errors.description}
      />
    </FormLayout>
  );
});

export default TaskTemplateFields;
