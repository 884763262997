import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {ResponseContainerState} from '../state';
import {Display, Layout, Panel} from 'components';
import {QuestionFieldFactory} from 'components/forms/question/components';

const ResponseForm = observer(({uiState}) => {
  const {response} = uiState;

  return (
      <Panel.Stack>
        {response.sortedAnswers.map((answer) =>
          <Panel key={answer.id}>
            <QuestionFieldFactory
              answer={answer}
              disabled
            />
          </Panel>)
        }
      </Panel.Stack>
  );
});

const ResponseContainer = observer(({uiState}) => {
  const {survey, response} = uiState;

  return (
    <React.Fragment>
      <Display
        title={`${survey.name}: ${response.employee.name}`}
        backPath={`/manage/survey/${survey.id}/admin`}
      />
      <Layout>
        <Layout.Section>
          <ResponseForm uiState={uiState}/>
        </Layout.Section>
      </Layout>
    </React.Fragment>
  );
});

export default withState(ResponseContainer, ResponseContainerState);
