import {computed, observable} from 'mobx';
import TimelineEntry from 'stores/performance_goals/timeline_entries/TimelineEntry';
import {FormattedMessage} from 'react-intl';
import React from 'react';
import {fullName} from 'shared/tools';

class GoalComment extends TimelineEntry {
  @observable text;

  @computed get actionDescription() {
    return (
      <div>
        <span className='h3 medium'>
          {fullName(this.user)}
        </span>
        &nbsp;
        <span className='jumbo'>
          <FormattedMessage id='goals.left a comment'/>
        </span>
      </div>
    );
  }
}

export default GoalComment;
