import React from 'react';
import {observer} from 'mobx-react';
import {FormError, Button, TextArea, AutosaveStatus} from 'components';
import {FormattedMessage} from 'react-intl';

const CommentForm = observer(({uiState}) => {
  const {comment, changesMade, errors} = uiState;

  return (
    <div>
      <TextArea
        placeholder='goals.Add comment'
        value={comment.text}
        onChange={e => uiState.updateComment(e.target.value)}
      />
      <FormError message={errors.base} />
      <div className='right-align mt2'>
        <AutosaveStatus autosaver={comment.autosaver} className='mr2' />
        <Button size='sm' onClick={() => uiState.createComment()} disabled={!changesMade}>
          <FormattedMessage id='goals.Add comment'/>
        </Button>
      </div>
    </div>
  );
});

export default CommentForm;
