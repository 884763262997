import React from 'react';
import {observer} from 'mobx-react';
import {ReorderModal} from 'components/modals';

const ReorderCustomFieldsModal = observer(({uiState}) => {
  return (
    <ReorderModal
      isOpen={uiState.reorderCustomFieldsModalOpen}
      onHide={() => uiState.closeReorderCustomFieldsModal()}
      title={'company_settings.custom_fields.Reorder Fields'}
      models={uiState.reorderingFields}
      onSave={() => uiState.saveOrders()}
      onCancel={() => uiState.closeReorderCustomFieldsModal()}
    />
  );
});

export default ReorderCustomFieldsModal;
