import {observable, action, computed} from 'mobx';
import HeaderViewModel from './HeaderViewModel';
import handleJsLink from './handleJsLink';
import _ from 'lodash';

class TableState {
  onAdd;
  onRemove;
  onEdit;
  editRoute;
  onRowClicked;
  showRoute;
  showPath;
  clickable;
  sortable;
  sortingUpdated;
  modelsUpdated;
  history;

  @observable modelBeingRemoved = null;
  @observable removeModalOpen = false;
  @observable headers = [];
  @observable showAddLink;

  receiveProps({onAdd, showAddLink, onRemove, onEdit, editRoute, onRowClicked, clickable, columns, sortable, sortingUpdated, modelsUpdated, showRoute, showPath, history}) {
    this.onAdd = onAdd;
    this.showAddLink = showAddLink;
    this.onRemove = onRemove;
    this.onEdit = onEdit;
    this.editRoute = editRoute;
    this.onRowClicked = onRowClicked;
    this.showRoute = showRoute;
    this.showPath = showPath;
    this.clickable = clickable || onRowClicked || showRoute || showPath;
    this.sortable = sortable;
    this.sortingUpdated = sortingUpdated;
    this.modelsUpdated = modelsUpdated;
    this.history = history;
    this.rebuildHeaders(columns);
  }

  @action rebuildHeaders(columns) {
    // only rebuild headers when they have changed
    const existingHeaders = this.headers.map(h => h.column.header);
    const newHeaders = columns.map(c => c.header);
    if (_.isEqual(existingHeaders, newHeaders)) return;

    this.headers = columns.map(c => new HeaderViewModel(c, this.sortable));
  }

  isModelClickable(model) {
    if (!_.isBoolean(model.clickable)) return this.clickable;

    return model.clickable;
  }

  @action removeModel(model) {
    this.modelBeingRemoved = model;
    this.removeModalOpen = true;
  }

  @action editModel(model) {
    this.onEdit(model);
  }

  @action cancelRemove() {
    this.modelBeingRemoved = null;
    this.removeModalOpen = false;
  }

  @action async confirmRemove() {
    await this.onRemove(this.modelBeingRemoved);
    if (this.modelsUpdated) {
      await this.modelsUpdated();
    }
    this.modelBeingRemoved = null;
    this.removeModalOpen = false;
  }

  @action clickRow(e, model) {
    if (!this.clickable) return;

    if (this.onRowClicked) {
      this.onRowClicked(model);
      return;
    }

    const path = model.showRoute || model.showPath || this.showRoute || this.showPath;
    if (!path) return;

    const pathToNavigateTo = _.isFunction(path)
      ? path(model)
      : path.replace(':id', model.id);

    return handleJsLink(e, pathToNavigateTo, (this.showRoute || model.showRoute) ? this.history : null);
  }

  @action clickHeader(header) {
    if (!header.sortingAllowed) return;

    switch (header.sort) {
      case 'asc':
        this.__sortBy(header, 'desc');
        break;
      case 'desc':
      case '':
        this.__sortBy(header, 'asc');
        break;
      default:
        throw new Error(`Unexpected sort argument ${header.sort}`);
    }
  }

  __sortBy(header, direction) {
    const sorting = {};
    for (const h of this.headers) {
      sorting[h.sortBy] = '';
      h.sort = '';
    }
    sorting[header.sortBy] = direction;
    header.sort = direction;

    this.sortingUpdated(sorting);
  }

  @computed get showAdd() {
    return this.showAddLink && !!this.onAdd;
  }
}

export default TableState;
