import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {ActionLink, Label, DownloadLink} from 'components';
import {auth, t} from 'shared/core';

const BankingInfo = observer(({uiState}) => {
  const {employee} = uiState;

  if (!auth.company.bankingEnabled) return null;

  return (
    <React.Fragment>
      <div className='clearfix mxn1 pb1'>
        <div className='col col-6 px1'>
          <FormattedMessage id='employees.profile.banking.Bank Account Nickname' />
        </div>
        <div className='col col-6 px1'>
          <Label
            model={employee}
            path='bankAccount.nickname'
            render={value => uiState.formatMaskedValue(value, uiState.bankingInformationHidden)}
          />
        </div>
      </div>
      <div className='clearfix mxn1 pb1'>
        <div className='col col-6 px1'>
          <FormattedMessage id='employees.profile.banking.Institution Number' />
        </div>
        <div className='col col-6 px1'>
          <Label
            model={employee}
            path='bankAccount.institutionNumber'
            render={value => uiState.formatMaskedValue(value, uiState.bankingInformationHidden)}
          />
        </div>
      </div>
      <div className='clearfix mxn1 pb1'>
        <div className='col col-6 px1'>
          <FormattedMessage id='employees.profile.banking.Transit Number' />
        </div>
        <div className='col col-6 px1'>
          <Label
            model={employee}
            path='bankAccount.transitNumber'
            render={value => uiState.formatMaskedValue(value, uiState.bankingInformationHidden)}
          />
        </div>
      </div>
      <div className='clearfix mxn1 pb1'>
        <div className='col col-6 px1'>
          <FormattedMessage id='employees.profile.banking.Account Number' />
        </div>
        <div className='col col-6 px1'>
          <Label
            model={employee}
            path='bankAccount.accountNumber'
            render={value => uiState.formatMaskedValue(value, uiState.bankingInformationHidden)}
          />
        </div>
      </div>
      {!uiState.bankingInformationHidden && employee.bankAccount.cheque && <div className='clearfix mxn1 pb1'>
        <div className='col col-6 px1'>
          <FormattedMessage id='employees.profile.banking.Uploaded Cheque' />
        </div>
        <div className='col col-6 px1'>
          <DownloadLink
            href={employee.bankAccount.cheque.link('url')}
            title={t('employees.profile.banking.Download')}
          />
        </div>
      </div>}
      <ActionLink onClick={() => uiState.openBankingChequeHelper()} className='dodger TextLink block mt2'>
        <FormattedMessage id='employees.profile.banking.How do I find my banking information from a cheque?'/>
      </ActionLink>
    </React.Fragment>
  );
});

export default BankingInfo;
