import {action, observable} from 'mobx';
import {Goal} from 'stores/performance_goals';
import _ from 'lodash';

class GoalViewModel {
  @observable data;

  @observable subgoalViewModels = [];
  @observable isExpanded = false;

  constructor(data) {
    this.data = new Goal(data);
    this.subgoalViewModels = this.data.sortedSubgoals.map(subgoal => new GoalViewModel(subgoal));
  }

  @action expand() {
    this.isExpanded = true;
  }

  @action collapse() {
    this.isExpanded = false;
    this.subgoalViewModels.forEach(subgoal => subgoal.collapse());
  }

  showBorder(metricIndex) {
    return metricIndex !== this.data.metrics.length - 1 || this.data.subgoals.length > 0;
  }
}

export default GoalViewModel;
