import React from 'react';
import {observer} from 'mobx-react';
import {Panel, RadioButtonGroup, RadioButton, Input, TextArea, LabelWithHeader, FormLayout} from 'components';
import {FormattedMessage} from 'react-intl';
import {t, auth, withState} from 'shared/core';
import {CommissionFormState} from '../state';

const CommissionForm = observer(({uiState}) => {
  const {model, earningsFormatter} = uiState;

  return (
    <div>
      <Panel.Separator/>
      <Panel.Header title={t('employees.profile.role.Commission')}/>
      <FormLayout>
        <RadioButtonGroup
          className='mb2'
          label='employees.profile.role.Employee earns commission'
          name='commission'
          selectedValue={model.commission}
          onChange={(name, value) => model.merge({commission: value, annualizedCommission: '0'})}
        >
          <RadioButton className='table-cell pr2' value={false}>
            <FormattedMessage id='employees.profile.role.No'/>
          </RadioButton>
          <RadioButton className='table-cell pl2' value={true}>
            <FormattedMessage id='employees.profile.role.Yes'/>
          </RadioButton>
        </RadioButtonGroup>
        {model.commission &&
          <TextArea
            label='employees.profile.role.Commission Details'
            value={model.commissionStructure}
            onChange={(e) => model.merge({commissionStructure: e.target.value})}
            placeholder='employees.profile.role.Enter the commission details here'
            className='full-width' minRows={3}
          />
        }
        {auth.company.benefitsEnabled && model.commission &&
          <Input
            className='mb1'
            label='employees.profile.role.Estimated Yearly Commission'
            value={model.annualizedCommission}
            onChange={e => model.merge({annualizedCommission: e.target.value})}
            errorMessage={uiState.errors.annualizedCommission}
            disabled={!model.commission}
            helpLink={{
              explanation: {
                header: 'employees.profile.role.Commission and Insurable Earnings',
                paragraphs: [
                  <b><FormattedMessage id='employees.profile.role.Estimated Yearly Commission'/></b>,
                  t('employees.profile.role.ESTIMATED_YEARLY_COMMISSION_EXPLANATION_1'),
                  <b><FormattedMessage id='employees.profile.role.Yearly Insurable Earnings'/></b>,
                  t('employees.profile.role.INSURABLE_EARNINGS_EXPLANATION_1')
                ]
              }
            }}
          />
        }
      </FormLayout>
      {auth.company.benefitsEnabled &&
        <div>
          <Panel.Separator/>
          <LabelWithHeader
            header={t('employees.profile.role.Total Yearly Insurable Earnings')}
            model={
              <FormattedMessage
                id='employees.profile.role.TOTAL_INSURABLE_EARNINGS'
                values={{
                  base: earningsFormatter.formattedBaseInsurableEarnings,
                  commission: earningsFormatter.formattedAnnualizedCommission,
                  insurableEarnings: earningsFormatter.formattedTotalInsurableEarnings
                }}
              />
            }
          />
        </div>
      }
    </div>
  );
});

export default withState(CommissionForm, CommissionFormState);
