import React from 'react';
import {observer} from 'mobx-react';
import CandidateList from './CandidateList';
import CandidateDetails from './CandidateDetails';

const StageContainer = observer(({uiState, ...rest}) => {
  return (
    <div className='rounded min-height-800'>
      <div className='clearfix'>
        <CandidateList uiState={uiState} {...rest}/>
        <CandidateDetails uiState={uiState}/>
      </div>
    </div>
  );
});

export default StageContainer;
