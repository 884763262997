import React from 'react';
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import PendingDocumentsContainer from './PendingDocumentsContainer';

const PendingDocumentsPage = observer(() => {
  return (
    <Router basename='pending_documents'>
      <Switch>
        <Route path='/:id' component={PendingDocumentsContainer} />
      </Switch>
    </Router>
  );
});

export default PendingDocumentsPage;
