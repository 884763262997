import {DomainObject, oneOf} from 'shared/store';
import {action, observable} from 'mobx';
import {InsuranceCarrierRep} from 'stores/broker_portal';

class BrokerClientInsuranceCarrier extends DomainObject {
  @observable policyNumber;
  @observable nextRenewalDate;
  @observable serviceType;
  @observable carrier;
  @observable insuranceCarrierRep;

  @action merge(other) {
    super.merge(other, {
      serviceType: oneOf(['benefits', 'pensions']),
      insuranceCarrierRep: InsuranceCarrierRep,
      _dates: ['nextRenewalDate']
    });
  }
}

export default BrokerClientInsuranceCarrier;
