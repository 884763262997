import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Tag} from 'components';

const AwaitingTag = ({status}) => {
  return (
    <div>
      {status === 'awaiting' && <Tag>
        <FormattedMessage id={`performance.${status}`} />
      </Tag>}
    </div>
  );
};

export default AwaitingTag;
