import React from 'react';
import {observer} from 'mobx-react';
import DraggableItem from './DraggableItem';
import ItemContent from './ItemContent';
import ReadOnlyItem from './ReadOnlyItem';

const Item = observer(({uiState, model, display, component, readOnly, readOnlyDisplay, readOnlyComponent, errors, errorMessage}) => {
  if (readOnly) {
    return (
      <ReadOnlyItem key={model.order} errorMessage={errorMessage}>
        <ItemContent
          model={model}
          display={readOnlyDisplay}
          component={readOnlyComponent}
          sortedModels={uiState.sortedModels}
          errors={errors}
        />
      </ReadOnlyItem>
    );
  }

  return (
    <DraggableItem key={model.order} uiState={uiState} model={model} errorMessage={errorMessage}>
      <ItemContent
        model={model}
        display={display}
        component={component}
        sortedModels={uiState.sortedModels}
        errors={errors}
      />
    </DraggableItem>
  );
});

export default Item;
