import {DomainStore} from 'shared/store';
import {action, observable, computed} from 'mobx';
import {endpoints, types} from 'shared/core';
import _ from 'lodash';
import {EmployeeBenefitEnrollment} from 'stores/benefits';
import OutstandingEnrolmentItemViewModel from 'pa_dashboard/state/OutstandingEnrolmentItemViewModel';
import {computeDataSeries} from 'components/charts';
import {BenefitEnrolmentSummary} from 'stores/benefits';

class OverviewContainerState {
  store = new DomainStore();
  parentState;
  match;
  hideProfileLink = true;
  enrolmentDataSeries;

  @observable outstandingEnrolments;

  receiveProps({parentState, match}) {
    this.parentState = parentState;
    this.match = match;
  }

  @action async load() {
    await this.store._compose([
      endpoints.BROKER_PORTAL.OUTSTANDING_ENROLMENTS.withFilter(
        this.parentState.match.params.id
      ),
      endpoints.BROKER_PORTAL.BENEFIT_ENROLMENTS.withFilter(this.parentState.match.params.id)
    ]);

    this.outstandingEnrolments = this.store._getAll(
      types.EMPLOYEE_BENEFIT_ENROLLMENT, EmployeeBenefitEnrollment
    );

    this.benefitEnrolments = this.store._getAll(
      types.ENROLMENT_SUMMARY,
      BenefitEnrolmentSummary
    );
    this.enrolmentDataSeries = computeDataSeries(this.benefitEnrolments);
  }

  @computed get enrolments() {
    const enrolments = _.groupBy(this.outstandingEnrolments, 'employee.id');
    const enrolmentViewModels = Object.keys(enrolments).map(enrolmentKey => {
      return new OutstandingEnrolmentItemViewModel(enrolments[enrolmentKey]);
    });

    return enrolmentViewModels;
  }

  @computed get sortedEnrolments() {
    return _.sortBy(this.enrolments, 'earliestEnrolmentDate');
  }

  @computed get showEnrolmentGraph() {
    return this.enrolmentDataSeries.length > 1;
  }
}

export default OverviewContainerState;
