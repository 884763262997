import React from 'react';
import {observer} from 'mobx-react';
import BasicInformation from './BasicInformation';
import AddressInformation from './AddressInformation';
import ContactInformation from './ContactInformation';
import EmployeeInfoCustomFields from './EmployeeInfoCustomFields';
import RestrictedInformation from './RestrictedInformation';
import EmployeeProfileContainer from '../../EmployeeProfileContainer';
import AvatarSection from '../../AvatarSection';

const MobileEmployeeSummary = observer(({uiState}) => {
  return (
    <div className='sm-and-above-hide mb2'>
      <AvatarSection uiState={uiState} />
    </div>
  );
});

const PersonalContainer = observer(({uiState}) => {
  if (!uiState.viewDataPermission('::PERSONAL_INFORMATION')) return (
    <EmployeeProfileContainer uiState={uiState}>
      <MobileEmployeeSummary uiState={uiState} />
      <RestrictedInformation uiState={uiState}/>
    </EmployeeProfileContainer>
  );

  return (
    <EmployeeProfileContainer uiState={uiState}>
      <div className='fs-hide'>
        <MobileEmployeeSummary uiState={uiState} />
        <BasicInformation uiState={uiState} />
        <AddressInformation uiState={uiState} />
        <ContactInformation uiState={uiState} />
        <EmployeeInfoCustomFields uiState={uiState} />
      </div>
    </EmployeeProfileContainer>
  );
});

export default PersonalContainer;
