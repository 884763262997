import {DomainObject} from 'shared/store';
import {observable, action} from 'mobx';
import Suggestion from 'stores/global_search/Suggestion';

class GlobalSearch extends DomainObject {
  @observable queriedAt;

  @action merge(other) {
    super.merge(other, {
      results: [Suggestion]
    });
  }
}

export default GlobalSearch;
