import React, {useMemo} from 'react';
import Table from 'components/tables/components/Table';
import {withState} from 'shared/core';
import {TaskTemplateSelectorState} from '../state';
import {observer} from 'mobx-react';
import Assignee from './Assignee';
import DueDateView from './DueDateView';
import AddTaskTemplatesModal from './AddTaskTemplatesModal';
import EditTaskTemplateModal from './EditTaskTemplateModal';
import TaskHiddenFields from './TaskHiddenFields';
import {FormattedMessage} from 'react-intl';

const TaskName = observer(({model}) => {
  return (
    <div className='flex flex-col'>
      <div>
        {model.name}
      </div>
      {model.default && <div className='waterloo h5'>
        <FormattedMessage
              id='tasks.DEFAULT_EXPLAINER'
              values={{
                employeeGroup: model.employeeGroupName
              }}/>
      </div>}
    </div>
  );
});

const COLUMNS = [
  {
    header: 'tasks.Task Name',
    component: TaskName,
    width: 5
  },
  {
    header: 'tasks.Assignee',
    component: Assignee,
    width: 3
  },
  {
    header: 'tasks.Due',
    component: DueDateView,
    width: 3
  }
];

const TaskList = observer(({uiState}) => {
  const {taskTemplates, localization, config, taskTemplatesWithDefaults} = uiState;

  const customLinks = useMemo(() => {
    return [{
      text: 'Edit',
      action: taskTemplate => uiState.openEditTaskTemplateModal(taskTemplate)
    },
    {
      text: 'Remove',
      action: taskTemplate => uiState.removeTaskTemplate(taskTemplate)
    }];
  }, []);

  return (
    <div>
      <Table
        uiState={uiState}
        models={taskTemplatesWithDefaults}
        columns={COLUMNS}
        localization={localization}
        onAdd={() => uiState.openAddTaskTemplateModal()}
        customLinks={customLinks}
        onlyShowCustomLinks
        customTableActionLinks={[{onClick: () => uiState.openCreateTaskTemplateModal(), label: config.addTaskTitle}]}
      />
      <AddTaskTemplatesModal uiState={uiState}/>
      <EditTaskTemplateModal uiState={uiState}/>
      {config.templateType === 'offboarding' && <TaskHiddenFields taskTemplates={taskTemplates}/>}
    </div>
  );
});

export default withState(TaskList, TaskTemplateSelectorState);
