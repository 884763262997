import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {RadioButtonGroup, RadioButton} from 'components';

const EmployeeHasSpouse = observer(({benefitInfo, errors}) => {
  return (
    <div className='clearfix table full-width'>
      <div className='col-6 table-cell align-middle pr2'>
        <FormattedMessage id='components.benefits.Do you have a spouse?'/>
        <div className='waterloo'>
          <FormattedMessage id='onboard.family_status.(married, common-law or civil union)'/>
        </div>
      </div>
      <div className='col-6 table-cell align-middle pl2'>
        <RadioButtonGroup 
          name='hasSpouse' 
          onChange={(name, value) => benefitInfo.merge({hasSpouse: value})} 
          selectedValue={benefitInfo.hasSpouse}
        >
          <RadioButton value={true}><FormattedMessage id='Yes'/></RadioButton>
          <RadioButton className='ml3' value={false}><FormattedMessage id='No'/></RadioButton>
        </RadioButtonGroup>
      </div>
    </div>
  );
});

export default EmployeeHasSpouse;

