import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';

const QuestionAndAnswerRow = ({answer, index}) => {
  return (
    <div className='my3 pb1'>
      <div className='h3'>{`${index}. ${answer.question.text}`}</div>
      <div className='h5 my2 fs-hide whitespace-pre-wrap'>{answer.content}</div>
    </div>
  );
};

const QuestionsAndAnswers = ({answers}) => {
  return (
    <div>
      {_.map(answers, (answer, index) => <QuestionAndAnswerRow key={index} answer={answer} index={index + 1} />)}
    </div>
  );
};

export default observer(QuestionsAndAnswers);
