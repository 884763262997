import React from 'react';
import {observer} from 'mobx-react';
import {Panel, FormLayout, Input, QuickTip} from 'components';
import {PolicyEditState} from '../state';
import {withState, t} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const HoursPerWorkday = observer(({uiState}) => {
  return (
    <div className='my4'>
      <Panel>
        <FormLayout>
          <Input
            type='number'
            step='0.01'
            min='0'
            max='24'
            label='time_off.policy.edit.Hours per workday'
            value={uiState.hoursPerWorkday}
            onChange={e => uiState.setHoursPerWorkday(e.target.value)}
          />
        </FormLayout>
        {uiState.hoursPerWorkedChanged &&
          <QuickTip
            trait='caution'
            header={t('time_off.policy.edit.HOURS_PER_WEEK_CHANGING_HEADER')}
            paragraphs={[
              <FormattedMessage id='time_off.policy.edit.HOURS_PER_WEEK_CHANGING_EXPLANATION_1'/>,
              <FormattedMessage id='time_off.policy.edit.HOURS_PER_WEEK_CHANGING_EXPLANATION_2'/>
            ]}
          />
        }
      </Panel>
    </div>
  );
});

export default withState(HoursPerWorkday, PolicyEditState);
