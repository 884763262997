import {DomainStore} from 'shared/store';
import {endpoints} from 'shared/core';
import _ from 'lodash';

class ManagerDataSource {
  store = new DomainStore();

  async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.MANAGERS
    ]);

    return this.store.getEmployees();
  }
}

export default ManagerDataSource;
