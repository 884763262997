import {action, observable} from 'mobx';
import {successAlert} from 'shared/tools';
import {t, endpoints, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import DocumentAction from 'stores/documents/DocumentAction';
import CompanyDocument from 'stores/documents/CompanyDocument';
import _ from 'lodash';

class DocumentActionsState {
  store = new DomainStore();
  history;
  match;

  @observable companyDocument;
  @observable documentActions = [];
  @observable addAssigneeModalOpen = false;
  @observable agent;

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    const documentId = this.match.params.id;
    await this.store._compose([
      endpoints.DOCUMENT_ACTIONS.with(documentId),
      endpoints.COMPANY_DOCUMENT.with(documentId)
    ]);

    this.companyDocument = new CompanyDocument(
      this.store._getSingle(types.COMPANY_DOCUMENT, { id: documentId })
    );
    this.documentActions = _.orderBy(
      _.filter(
        this.store._getAll(types.DOCUMENT_ACTION, DocumentAction),
        { companyDocumentParent: { id: documentId } }
      ),
      'createdAt',
      'desc'
    );
  }

  @action setInteractiveAgent(agent) {
    this.agent = agent;
  }

  @action openAddAssigneeModal() {
    this.addAssigneeModalOpen = true;
  }

  @action closeAddAssigneeModal() {
    this.addAssigneeModalOpen = false;
  }

  @action async onAssigneesAdded() {
    await this.agent.refresh();
    successAlert(t('company_settings.documents.alerts.Document successfully assigned.'));
    this.closeAddAssigneeModal();
  }

  @action async destroyDocumentAction(model) {
    await this.store.destroy(model);
  }

  customLinksFor(model) {
    const links = [];

    if (model.hasLink('download')) {
      links.push({
        order: 0,
        text: 'company_settings.documents.Download',
        downloadLink: model.link('download')
      });
    }

    return links;
  }
}

export default DocumentActionsState;
