import React from 'react';
import ModalDangerButtons from 'components/modals/ModalDangerButtons';
import AlertModal from 'components/modals/AlertModal';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {Spinner} from 'components';

const DocumentsToDelete = observer(({documentsToDelete}) => {
  if (documentsToDelete.length === 0) {
    return null;
  }

  return (
    <div>
      <FormattedMessage id='company_settings.custom_fields.cannot_remove'/>
      {documentsToDelete.map(document => {
        return <Link className='block' to={`/documents/edit/${document.id}`} target='_blank'>
          {document.name}
        </Link>;
      })}
    </div>
  );
});

const RemoveCustomFieldModal = observer(({uiState}) => {
  const {documentsToDelete, removeModalOpen, fetchingDocumentsToDelete, noDocumentsToDelete} = uiState;

  return (
    <AlertModal mode='danger' size='sm'
                header={'company_settings.custom_fields.Remove Custom Field?'}
                subHeader={noDocumentsToDelete && 'company_settings.custom_fields.Are you sure you want to remove this custom field?'}
                body={noDocumentsToDelete && 'company_settings.custom_fields.This will also delete all the data stored for this custom field for all your employees.'}
                onHide={() => uiState.closeRemoveModal()} isOpen={removeModalOpen}>
      {fetchingDocumentsToDelete && <Spinner/>}
      <DocumentsToDelete documentsToDelete={documentsToDelete}/>
      <ModalDangerButtons disabled={!noDocumentsToDelete} onCancel={() => uiState.closeRemoveModal()} onSave={() => uiState.deleteCustomField()}/>
    </AlertModal>
  );
});

RemoveCustomFieldModal.defaultProps = {
  saveCaption: 'Remove',
  documentsToDelete: []
};

export default RemoveCustomFieldModal;
