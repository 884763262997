import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {DragSource, DropTarget} from 'react-dnd';
import {FormError, TextArea} from 'components';

const questionSource = {
  beginDrag(props) {
    return {
      index: props.question.order
    };
  }
};

const questionTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.question.order;

    if (dragIndex === hoverIndex) return;

    props.onQuestionDrag(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  }
};

const collectSource = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
};

const collectTarget = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};

const Question = ({question, onQuestionDrag, onQuestionDelete, isDragging, isOver, connectDragSource, connectDropTarget, connectDragPreview, errors = {}}) => {
  return (
    <div className='col col-12 py2'>
      <div className='h3 medium mb2'>
        <FormattedMessage id='performance.QUESTION_ORDER' values={{order: question.order}}/>
      </div>
        {connectDragPreview(connectDragSource(connectDropTarget(
          <div className='border rounded box-shadow-1' style={{backgroundColor: isOver ? '#DFE3E6' : '#FFFFFF', opacity: isOver ? 0.5 : 1}}>
            <div className={`py2 px1 relative Question ${isDragging && 'is-dragging'}`}>
              <div className='clearfix table'>
                <div className='table-cell col-11 align-middle'>
                  <TextArea 
                    onChange={(e) => question.text = e.target.value}
                    value={question.text} 
                    className='pb2 pl3 full-width border-none overflow-hidden' 
                    placeholder={question.text}
                    translatePlaceholder={false}
                    style={{backgroundColor: isOver ? '#DFE3E6' : '#FFFFFF', opacity: isOver ? 0.5 : 1, transition: 'none'}} 
                  />
                </div>
                <div className='table-cell col-1 align-middle pr2 right-align'>
                  <div className='px1 dropdown inline-block'>
                    <i className='HoverAnimation-trigger--image material-icons submarine align-top' data-toggle='dropdown'>{'more_horiz'}</i>
                    <ul className='dropdown-menu right-0'>
                      <li><a href='#' className='py1 px3 regular jumbo' onClick={(e) => { e.preventDefault(); onQuestionDelete(question); }}>
                        <FormattedMessage id='performance.Delete'/>
                      </a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <FormError message={errors.text} />
          </div>
        )))}
    </div>
  );
};

const dragSource = DragSource('question', questionSource, collectSource)(observer(Question));
export default DropTarget('question', questionTarget, collectTarget)(dragSource);
