import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const DeleteReviewModal = observer(({uiState}) => {
  return (
    <AlertModal mode='danger' size='sm'
                header='performance_reviews.directory.Remove performance review?'
                subHeader='performance_reviews.directory.Are you sure you want to remove this performance review?'
                body='performance_reviews.directory.This performance review, including any feedback already submitted, will be permanently deleted.'
                onHide={() => uiState.closeDeleteCycleModal()}
                isOpen={uiState.deleteCycleModalOpen}
                onConfirm={() => uiState.deleteCycle()}>
      <ModalDangerButtons saveCaption='Remove'
                          onCancel={() => uiState.closeDeleteCycleModal()}
                          onSave={() => uiState.deleteCycle()}/>
    </AlertModal>
  );
});

export default DeleteReviewModal;
