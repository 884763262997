import React from 'react';
import {observer} from 'mobx-react';
import {Steps} from './steps';
import steps from 'recruiting/state/steps';
import {loader, t} from 'shared/core';
import RecruitingState from 'recruiting/state/RecruitingState';
import {Flow} from 'components';

const RecruitingEditContainer = observer(({match, uiState}) => {
  const {position} = uiState;
  return (
    <Flow
      name={position.title || t('recruiting.Untitled Position')}
      backTitle={t('recruiting.edit.Recruiting')}
      backRoute='/'
      steps={steps}
      component={Steps}
      uiState={uiState}
      baseUrl={`/positions/${match.params.id}`}
      onStepSubmitted={step => uiState.onStepSubmitted(step)}
      onStepChanged={step => uiState.onStepChanged(step)}
    />
  );
});

async function load({match}) {
  const uiState = RecruitingState.instance.editState;
  await uiState.load(match.params.id);
  return {uiState, match};
}

export default loader(RecruitingEditContainer, load);
