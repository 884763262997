import React from 'react';
import Subnav from 'performance_reviews/components/Subnav';
import {observer} from 'mobx-react';
import PerformanceTrendsWithState from './PerformanceTrendsWithState';

const PerformanceTrends = observer(({scope}) => {
  return (
    <div>
      <Subnav scope={scope}/>
      <PerformanceTrendsWithState/>
    </div>
  );
});

export default PerformanceTrends;
