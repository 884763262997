import React from 'react';
import {observer} from 'mobx-react';
import {Table} from 'components';
import {FormattedMessage} from 'react-intl';

const COLUMNS = [
  {
    header: 'benefits.updates.Field',
    width: 6,
    attribute: 'name'
  },
  {
    header: 'benefits.updates.New value',
    width: 6,
    render: model => <div className='fs-hide'>{model.value}</div>
  }
];

const Change = observer(({change}) => {
  return (
    <div className='mb3'>
      <div className='black medium mb1'>
        <FormattedMessage id={`benefits.updates.changes.${change.recordType}.${change.recordAction}`}/>
      </div>
      {change.fields.length &&
        <Table
          trait='xcompact'
          showLinks={false}
          columns={COLUMNS}
          models={change.fields}
        />
      }
    </div>
  );
});

const ChangesForUpdate = observer(({update}) => {
  return update.changes.map((c, index) => <Change change={c} key={index}/>);
});

export default ChangesForUpdate;
