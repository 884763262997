import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {PolicyEditFlowState, steps} from '../state';
import {Flow} from 'components';
import {Steps} from './steps';

const PolicyEditFlow = observer(({uiState}) => {
  const {policy, match} = uiState;

  return (
    <Flow
      name={policy.name || t('time_tracking.manage.policies.Untitled policy')}
      backTitle={t('time_tracking.manage.Policies')}
      backRoute='/manage'
      steps={steps}
      component={Steps}
      uiState={uiState}
      baseUrl={`/manage/policies/${match.params.id}/edit`}
      onStepSubmitted={step => uiState.onStepSubmitted(step)}
      onStepChanged={step => uiState.onStepChanged(step)}
    />
  );
});

export default withState(PolicyEditFlow, PolicyEditFlowState);
