import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const DeleteGreenhouseKeyModal = observer(({uiState}) => {
  return (
    <AlertModal 
      size='sm' 
      mode='danger' 
      header='company_settings.applications.greenhouse.Delete Key'
      subHeader="company_settings.applications.greenhouse.Are you sure you want to delete the Greenhouse API key?"
      body='company_settings.applications.greenhouse.You will have to remove the Web Hook from Greenhouse.'
      isOpen={uiState.deleteModalOpen} 
      onHide={() => uiState.cancelDeleteModal()}>
      <ModalDangerButtons 
        onSave={() => uiState.deleteGreenhouseKey()}
        onCancel={() => uiState.cancelDeleteModal()}/>
    </AlertModal>
  );
});

export default DeleteGreenhouseKeyModal;
