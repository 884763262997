import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, UploadFiles} from 'components';

const AttachedFiles = observer(({uiState}) => {
  const {brokerClient, errors, isUploading} = uiState;

  return (
    <Panel>
      <Panel.Header
        title={t('broker_portal.clients.edit.Documents')}
        description={t('broker_portal.clients.edit.Upload any additional documents.')}
      />
      <div className='clearfix'>
        <UploadFiles
          defaultFiles={brokerClient.attachedFiles}
          onChange={(files) => brokerClient.merge({attachedFiles: files})}
          onUploadStarted={() => uiState.onUploadStarted()}
          onUploadFinished={() => uiState.onUploadFinished()}
          errorMessage={errors.attachedFiles}
          isUploading={isUploading}
          modelType='broker_portal/broker_client'
        />
      </div>
    </Panel>
  );
});

export default AttachedFiles;
