import React from 'react';
import {observer} from 'mobx-react';

const StyleGuideColour = observer(() => {
  return (
    <div>
      <div className='bg-white box-shadow-1 rounded p4'>
        <div className='h1 medium'>{'Colour Palette'}</div>
        <div className='h2 my2 jumbo max-width-3'>{'Colours convey different context to the text you are reading.'}</div>
      </div>

      <div className='bg-white box-shadow-1 rounded p4 my3'></div>
    </div>
  );
});

export default StyleGuideColour;
