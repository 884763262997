import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const DraggableFieldContent = observer(({model}) => {
  const field = model;

  return (
    <div>
      <span className='mr1'>
        {field.view}
      </span>
      <span className='waterloo'>
        <FormattedMessage id={`reports.custom.edit.sections.${field.group}`}/>
      </span>
    </div>
  );
});

export default DraggableFieldContent;
