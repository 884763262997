import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';
import _ from 'lodash';
import {RELATIVE_START_DATE, RELATIVE_END_DATE} from 'reports/tools';
import {coloursBySize} from 'containers/reports/charts/colours';

const filters = [
  'Department',
  'Employment Type',
  'Gender',
  'Location',
  'Manager',
  'Terminated Employee',
  'Termination Type'
];

const PIE_CHART_OPTIONS = {
  legendAlignBottom: true,
  fixedChartHeight: true,
  disabledDataLabels: true,
  highcharts: {
    chart: {
      height: 300,
      marginTop: 0,
      spacingRight: 0
    },
    legend: {
      itemMarginTop: 5
    },
    colors: coloursBySize(12).reverse()
  }
};

const TurnoverReport = () => {
  return (
    <CompositeReport dateFilter='range' dateFilterFormat='month' defaultStartDate={RELATIVE_START_DATE} defaultEndDate={RELATIVE_END_DATE} filters={filters}>
      <Report
        name={t('reports.TURNOVER_REPORT')}
        view='chart'
        options={{
          stacking: 'normal',
          highcharts: {
            tooltip: {
              formatter: function() {
                return `${this.point.category}<br>${this.point.label}: <b>${this.point.y}% (${this.point.count})</b>`;
              }
            },
            yAxis: {
              title: {
                text: t('reports.turnover.Terminations as a percentage of the workforce')
              },
              labels: {
                format: '{value}%'
              },
              stackLabels: {
                enabled: true,
                verticalAlign: 'top',
                format: '{total}%'
              }
            }
          },
          legendAlignBottom: true
        }}
        url='/reports/turnover_chart'
      />
      <Report.Group>
        <Report
          name={t('reports.Departments')}
          url='/reports/turnover_by_department_chart'
          view='chart' chartType='pie'
          options={PIE_CHART_OPTIONS}
          className='float-left third-width'
        />
        <Report
          name={t('reports.Locations')}
          url='/reports/turnover_by_location_chart'
          view='chart' chartType='pie'
          options={PIE_CHART_OPTIONS}
          className='float-left third-width'
        />
        <Report
          name={t('reports.Managers')}
          url='/reports/turnover_by_manager_chart'
          view='chart' chartType='pie'
          options={PIE_CHART_OPTIONS}
          className='float-left third-width'
        />
      </Report.Group>
      <Report
        url='/reports/turnover'
        name={t('reports.TURNOVER_REPORT')}
        showName={false}
      />
    </CompositeReport>
  );
};

export default TurnoverReport;
