import React from 'react';
import {noop} from 'shared/tools';
import {observable} from 'mobx';
import {observer} from 'mobx-react';

class ActionLink extends React.Component {
  @observable processing = false;

  onClick = async (e, cb) => {
    if (this.processing || this.props.disabled) return null;

    e.preventDefault();
    this.processing = true;
    this._handleCallback(e, cb);
  };

  _handleCallback = async (e, cb) => {
    try {
      await Promise.resolve(cb(e));
    } finally {
      if (!this.props.staysDisabledAfterClick) {
        this.processing = false;
      }
    }
  }

  render() {
    const {onClick, children, href, subdued, scarlet, className, loadingText, disabled, ...rest} = this.props;

    return (
      <a
        href={href}
        className={className ?
          `${className} ${disabled ? 'disabled' : ''}` :
          `${colour(scarlet, subdued)} TextLink ${this.processing ? 'loading' : ''} ${disabled ? 'disabled' : ''}`
        }
        onClick={async (e) => this.onClick(e, onClick)}
        {...rest}
      >
        {this.processing && loadingText ? loadingText : children}
      </a>
    );
  }
}

function colour(scarlet, subdued) {
  if (scarlet) return 'scarlet';
  if (subdued) return 'subdued';

  return 'dodger';
}

ActionLink.defaultProps = {
  href: '#',
  onClick: noop
};

export default observer(ActionLink);
