import React from 'react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {withState, auth} from 'shared/core';
import DemoBannerState from '../state/DemoBannerState';
import ActionLink from '../../links/ActionLink';

const DemoBannerModal = observer (({uiState}) => {
  return (
    <Modal title='components.demo_banner.Ready to make Collage your own?'
           isOpen={uiState.showModal}
           closeButton={false}
           size='md'>
      <div>
        <FormattedMessage id='components.demo_banner.demo_banner_explanation'/>
      </div>
      <div className='mt2'>
        <FormattedMessage id='components.demo_banner.This may take several minutes.'/>
      </div>
      <ModalButtons
        cancelCaption='components.demo_banner.Continue Exploring'
        saveCaption='components.demo_banner.Start using Collage'
        onCancel={() => uiState.closeDemoBannerModal()}
        onSave={() => uiState.beginConfiguration()}/>
    </Modal>
  );
});

const RequestDemoButton = () => {
  return (
    <a className='Btn Btn--lagoon Btn--lg Btn--circle fixed z-index-3 sm-and-below-hide'
       href='https://calendly.com/collage-sales-to/15-minute-q-a'
       style={{right: '100px', bottom: '25px'}}
       target='_blank'>
      <span className='Btn--content' tabIndex='-1'>
        <FormattedMessage id='components.demo_banner.Request a demo'/>
      </span>
    </a>
  );
};

const DemoBanner = observer(({uiState}) => {
  return (
    <div>
      <div className='bg-meadow white' style={{padding: '0.8rem'}}>
        <div className='Container center'>
          {auth.company.enrolmentOnly &&
            <FormattedMessage id='components.demo_banner.VIEWING_DEMO_ACCOUNT_FOR_COMPANY' values={{company: auth.company.name}}/>
          }
          {!auth.company.enrolmentOnly &&
            <div className='block mx-auto relative'>
              <span>
                <FormattedMessage id='components.demo_banner.Using Demo Data.'/>
              </span>
              <ActionLink className='mx1 white medium underline' onClick={() => uiState.openDemoBannerModal()}>
                <FormattedMessage id='components.demo_banner.Clear data and add your own.'/>
              </ActionLink>
            </div>
          }
        </div>
      </div>
      <DemoBannerModal uiState={uiState} />
      <RequestDemoButton />
    </div>
  );
});

export default withState(DemoBanner, DemoBannerState);
