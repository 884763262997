import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import ActionLink from 'components/links/ActionLink';

const ModelActionLink = observer(({text, action, downloadLink, model, translate=true}) => {
  const data = model.data || {};

  const message = translate ? <FormattedMessage id={text}/> : text;

  if (downloadLink) {
    return (
      <a className='py1 px2 regular jumbo' download target='_blank' href={downloadLink}>
        {message}
      </a>
    );
  }

  if (_.isString(action)) {
    return (
      <Link className='py1 px2 regular jumbo' to={action.replace(':id', data.id || model.id)}>
        {message}
      </Link>
    );
  }

  if (!_.isFunction(action)) {
    throw new Error(`Action "${text}" is expected to be a string (which is interpreted as a react router route) or function(model) {}. Instead, received: ${action}`);
  }

  return (
    <ActionLink className='py1 px2 regular jumbo'
                onClick={() => action(model)}>
      {message}
    </ActionLink>
  );
});

export default ModelActionLink;
