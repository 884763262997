import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints, auth, t} from 'shared/core';
import {EmployeeBenefitEnrollment} from 'stores/benefits';
import {redirect} from 'shared/tools';
import _ from 'lodash';
import OutstandingEnrolmentItemViewModel from './OutstandingEnrolmentItemViewModel';
import {computeDataSeries} from 'components/charts';
import {BenefitEnrolmentSummary} from 'stores/benefits';

class PaDashboardPageState {
  store = new DomainStore();
  enrolmentDataSeries;

  @observable outstandingEnrolments = [];

  @action async load() {
    await this.store._compose([
      endpoints.PA_DASHBOARD.OUTSTANDING_ENROLMENTS,
      endpoints.BENEFIT_ENROLMENTS.ALL
    ]);

    this.outstandingEnrolments = this.store._getAll(types.EMPLOYEE_BENEFIT_ENROLLMENT, EmployeeBenefitEnrollment);

    this.benefitEnrolments = this.store._getAll(
      types.ENROLMENT_SUMMARY,
      BenefitEnrolmentSummary
    );
    this.enrolmentDataSeries = computeDataSeries(this.benefitEnrolments);
  }

  @action async goToNewEnrolment() {
    return redirect('/benefits/benefit_enrolments/enrol');
  }

  @computed get outstandingEnrolmentViewModels() {
    const enrolments = _.groupBy(this.outstandingEnrolments, 'employee.id');
    const enrolmentViewModels = Object.keys(enrolments).map(enrolmentKey => {
      return new OutstandingEnrolmentItemViewModel(enrolments[enrolmentKey]);
    });

    return enrolmentViewModels;
  }

  @computed get sortedEnrolments() {
    return _.sortBy(this.outstandingEnrolmentViewModels, 'earliestEnrolmentDate');
  }

  @computed get showEnrolmentGraph() {
    return this.enrolmentDataSeries.length > 1;
  }

  get greeting() {
    const currentHours = new Date().getHours();
    if (currentHours < 12) {
      return t('pa_dashboard.greetings.GOOD_MORNING', {name: auth.user.firstName});
    }
    if (currentHours < 18) {
      return t('pa_dashboard.greetings.GOOD_AFTERNOON', {name: auth.user.firstName});
    }

    return t('pa_dashboard.greetings.GOOD_EVENING', {name: auth.user.firstName});
  }
}

export default PaDashboardPageState;
