import React from 'react';
import {Modal} from 'components/modals';
import {Button} from 'components';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const ErrorMessageModal = observer(({modalOpen, uiState}) => {
  const {selectedPayrollHoursSummary} = uiState;

  return (
    <Modal
      isOpen={modalOpen}
      onHide={() => uiState.closeErrorMessageModal()}
      size='md'
      title={'time_tracking.payroll_hours.Error Approving Timesheet'}
      preventCloseOnOverlayClick={true}
    >
      {selectedPayrollHoursSummary.employee && <div className='mb3'>
        <FormattedMessage
          id={'time_tracking.payroll_hours.CANT_APPROVE_FOR_EMPLOYEE'}
          values={{employeeName: selectedPayrollHoursSummary.employee.name}}
        />
        <div className='my2 scarlet'>{selectedPayrollHoursSummary.errorMessage}</div>
        <FormattedMessage id="time_tracking.payroll_hours.Please correct the issue in the Timesheets tab and try again."/>
      </div>}
      <Button className='right' trait='default' onClick={() => uiState.closeErrorMessageModal()}>
        <FormattedMessage id='time_tracking.payroll_hours.OK'/>
      </Button>
    </Modal>
  );
});

export default ErrorMessageModal;
