import React, {useEffect} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {DropdownSelect, FormError, Button, AutosaveStatus} from 'components';
import {QuestionFieldFactory} from 'components/forms';
import {observer} from 'mobx-react';
import {auth, t} from 'shared/core';
import _ from 'lodash';
import $ from 'jquery';

const NewInterviewGuide = injectIntl(observer(({detailsState, sectionViewModel}) => {
  useEffect( () => {
    if(sectionViewModel.showInterviewGuide) {
      $('html, body').animate({scrollTop: $('.js-interview-guide').offset().top - 150}, 500);
    }
  }, [sectionViewModel.showInterviewGuide]);

  if (!sectionViewModel.showInterviewGuide) return null;
  const {interviewGuides} = detailsState.hiringFunnelState;
  const {newInterviewGuide} = detailsState;

  const errors = detailsState.newInterviewGuideErrors;

  const selectedGuide = newInterviewGuide.interviewGuide ? newInterviewGuide.interviewGuide.name : t('recruiting.hiring_funnel.Select an interview guide');
  return (
    <div className='clearfix border-top pt3 px3'>
      <div className='mb3 jumbo js-interview-guide'>
        <FormattedMessage id='recruiting.hiring_funnel.INTERVIEW_GUIDE_DESCRIPTION'/>
      </div>
      <div className='flex mb3'>
        <div>
          <DropdownSelect selected={selectedGuide} buttonTrait='default' buttonSize='sm'>
            {interviewGuides.map(guide => <DropdownSelect.Option key={guide.id} text={guide.name} onClick={() => detailsState.selectInterviewGuide(guide)}/>)}
            {auth.hasAccess('::MANAGE_ATS') &&
                <DropdownSelect.Option key='createNewInterviewGuide' text={t('recruiting.hiring_funnel.+ Create New Interview Guide')} onClick={() => detailsState.createNewInterviewGuide()}/>}
          </DropdownSelect>
        </div>
        {!newInterviewGuide.interviewGuide && <Button size='sm' className='pl2' trait='default' onClick={() => detailsState.cancelInput()}>
          <FormattedMessage id='recruiting.hiring_funnel.Cancel'/>
        </Button>}
      </div>
      {newInterviewGuide.sortedAnswers.map((answer, index) =>
        <div className='my3' key={answer.question.id}>
          <QuestionFieldFactory
            errorMessage={_.get(errors, `answers.${index}.content`)}
            answer={answer}
            required={detailsState.calculateQuestionRequired(answer.question)}
            uiState={detailsState}
            onChanged={() => newInterviewGuide.autosaver.autosave()}
          />
        </div>
      )}
      <div className='col col-12 pl1'>
        <FormError message={errors.base}/>
        {newInterviewGuide.interviewGuide && <div className='flex align-items-center my2 right'>
          <AutosaveStatus autosaver={newInterviewGuide.autosaver}/>
          <Button size='sm' className='pl2' trait='default' onClick={() => detailsState.cancelInput()}>
            <FormattedMessage id='recruiting.hiring_funnel.Cancel'/>
          </Button>
          {newInterviewGuide.interviewGuide && <Button size='sm' className='pl2' disabled={detailsState.isUploading} onClick={() => detailsState.addInterviewGuide()}>
            <FormattedMessage id='recruiting.hiring_funnel.Add Feedback'/>
          </Button>}
        </div>}
      </div>
    </div>
  );
}));

export default NewInterviewGuide;
