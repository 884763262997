import {DomainObject} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {Employee} from 'stores/employees';
import {rangeDate} from 'shared/tools';
import TimelineEntry from 'stores/performance_goals/timeline_entries/TimelineEntry';
import Metric from './Metric';
import {formatPercentage, t} from 'shared/core';
import _ from 'lodash';

class Goal extends DomainObject {
  @observable title;
  @observable description;
  @observable startDate;
  @observable endDate;
  @observable completeness;
  @observable createdAt;
  @observable completedAt;
  @observable updatedAt;
  @observable employee;
  @observable parentGoalTitle;
  @observable metrics = [];
  @observable subgoals = [];
  @observable goalTimelineEntries = [];

  @action merge(other) {
    super.merge(other, {
      employee: Employee,
      metrics: [Metric],
      parentGoal: Goal,
      subgoals: [Goal],
      _dates: ['startDate', 'endDate', 'createdAt', 'completedAt', 'updatedAt']
    });

    if (other.goalTimelineEntries) {
      this.goalTimelineEntries = other.goalTimelineEntries.map(entry => TimelineEntry.fromJS(entry));
    }
  }

  @computed get formattedCompleteness() {
    return formatPercentage(this.completeness/100);
  }

  @computed get complete() {
    return this.status === 'complete';
  }

  @computed get inProgress() {
    return this.status === 'in_progress';
  }

  @computed get upcoming() {
    return this.status === 'upcoming';
  }

  @computed get incomplete() {
    return this.status === 'incomplete';
  }

  @computed get sortedMetrics() {
    return _.sortBy(this.metrics, 'order');
  }

  @computed get sortedMetricViewModels() {
    return _.sortBy(this.metrics, 'data.order');
  }

  @computed get sortedSubgoals() {
    return _.sortBy(this.subgoals, 'order');
  }

  @computed get startDateView() {
    return rangeDate(this.startDate);
  }

  @computed get endDateView() {
    return rangeDate(this.endDate);
  }

  @computed get updatedAtView() {
    return rangeDate(this.updatedAt);
  }

  @computed get titleView() {
    if (this.title) {
      return this.title;
    } else {
      return t('goals.Untitled Goal');
    }
  }

  @computed get sortedGoalTimelineEntries() {
    return _.orderBy(this.goalTimelineEntries, 'createdAt', 'desc');
  }

  @computed get progressBarItems() {
    return [
      { label: 1, value: this.completeness, color: '#08BAD3' },
      { label: 2, value: 100 - this.completeness, color: '#EBEFF1' }
    ];
  }
}

export default Goal;
