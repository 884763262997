import { useState, useEffect } from 'react';
import { fetchModel } from 'shared/store';

const useFetchModel = (endpoint, modelType) => {
  const [model, setModel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const fetchedModel = await fetchModel(endpoint, modelType);
      setModel(fetchedModel);
      setIsLoading(false);
    };
    fetchData();
  }, [endpoint, modelType]);

  return [model, isLoading];
};

export default useFetchModel;
