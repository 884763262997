import React from 'react';
import {observer} from 'mobx-react';
import {Tag, Panel, Avatar, EditableAvatar, ActionLinks} from 'components';
import {t} from 'shared/core';
import Dotdotdot from 'react-dotdotdot';
import MailIcon from 'img/mail-icon-link@2x.png';
import PhoneIcon from 'img/phone-icon-link@2x.png';
import {statusTagColour} from 'employees/tools';
import {FormattedMessage} from 'react-intl';
import useModal from 'shared/hooks/useModal';
import DestroyEmployeeModal from 'containers/employees/profile/components/DestroyEmployeeModal';

const EmployeeWorkEmail = observer(({employee}) => {
  if (!employee.workEmail) return null;

  return (
    <ProfileLink icon={MailIcon}>
      <a className='TextLink word-break' href={`mailto:${employee.workEmail}`}>
        {employee.workEmail}
      </a>
    </ProfileLink>
  );
});

const EmployeeWorkPhone = observer(({employee}) => {
  if (!employee.workPhone) return null;

  return (
    <ProfileLink icon={PhoneIcon}>
      <PhoneNumberWithExtension phoneNumber={employee.workPhone} phoneNumberExtension={employee.workPhoneExtension}/>
    </ProfileLink>
  );
});

const PhoneNumberWithExtension = observer(({phoneNumber, phoneNumberExtension}) => {
  if (!phoneNumberExtension) {
    return (
      <a className='TextLink word-break' href={`tel:${phoneNumber}`}>
        {phoneNumber}
      </a>
    );
  }

  return (
    <a className='TextLink word-break' href={`tel:${phoneNumber},${phoneNumberExtension}`}>
      <FormattedMessage
        id='employees.profile.phone_number_with_extension'
        values={{phoneNumber, phoneNumberExtension}}
      />
    </a>
  );
});

const ProfileLink = observer(({icon, children}) => {
  return (
    <div className={`pl1 pt2 border-top mt2`}>
      <div className='table-cell align-middle pr2'>
        <img src={icon} height='16' alt='icon'/>
      </div>
      <div className='table-cell align-middle'>
        <Dotdotdot clamp={1}>
          {children}
        </Dotdotdot>
      </div>
    </div>
  );
});

const ProfileLinks = observer(({employee}) => {
  return (
    <div className='mxn1'>
      <EmployeeWorkEmail employee={employee}/>
      <EmployeeWorkPhone employee={employee}/>
    </div>
  );
});

const AvatarSection = observer(({uiState}) => {
  const destroyEmployeeModalOpen = useModal(uiState, 'DestroyEmployeeModal');
  const {employee} = uiState;

  return (
    <React.Fragment>
      <Panel>
        <div className='mb2'>
          <div className='clearfix table-cell full-width'>
            {uiState.editDataPermission('::PERSONAL_INFORMATION') ?
              <EditableAvatar onClick={() => uiState.uploadAvatar()} employee={employee} size='lg'/> :
              <div className='left'><Avatar preview={true} employee={employee} size='lg'/></div>}
          </div>
          <div className='table-cell align-top right-align'>
            {employee.canDelete && <div className='table-cell align-top right-align pl1'>
              <ActionLinks
                model={employee}
                customLinks={[{
                  text: 'employees.profile.delete_employee.Delete',
                  action: () => uiState.openDestroyEmployeeModal()
                }]}
              />
            </div>}
          </div>
        </div>
        <div>
          <div className='flex flex-wrap align-items-center'>
            <div className='h3 medium tuatara mb1 mr1'>{employee.name}</div>
            {employee.statusTags.map((status, index) =>
              <Tag key={index} colour={statusTagColour(status)} className='mb1 mr1'>
                <FormattedMessage id={`employees.profile.${status}`}/>
              </Tag>)}
          </div>
          <div className='h4 jumbo'>{employee.jobTitle}</div>
          <div className='h4 jumbo'>
            {`${employee.location.name} · ${employee.onLeave ? t('employees.profile.On leave') : t(employee.employmentType)}`}
          </div>
          <ProfileLinks employee={employee}/>
        </div>
      </Panel>
      <DestroyEmployeeModal uiState={uiState} modalOpen={destroyEmployeeModalOpen}/>
    </React.Fragment>
  );
});

export default AvatarSection;
