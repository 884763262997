import React from 'react';
import {observer} from 'mobx-react';
import JobMassUpdateEditor from './JobMassUpdateEditor';
import PersonalMassUpdateEditor from './PersonalMassUpdateEditor';

const MassUpdateEditorFactory = observer(({uiState}) => {
  const {massUpdate} = uiState;

  switch (massUpdate.massUpdateType) {
    case 'job':
      return <JobMassUpdateEditor uiState={uiState}/>;
    case 'personal':
      return <PersonalMassUpdateEditor uiState={uiState}/>;
    default:
      return null;
  }
});

export default MassUpdateEditorFactory;
