import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';

 const ReviewRow = observer(({title, value, editRoute, lastRow, children}) => {
  return (
    <div className={`clearfix HoverAnimation-trigger py2 ${lastRow ? '' : 'border-bottom'}`}>
      <div className='col col-12 py1'>
        <div className='clearfix'>
          <div className='col col-3 medium'>
            <FormattedMessage id={title}/>
          </div>
          <div className='col col-7'>
            {value ? value : children}
          </div>
          {editRoute && <div className='col col-2 right-align'>
            <Link to={editRoute} className='waterloo HoverAnimation-target--show'>
              <FormattedMessage id='performance.Edit'/>
            </Link>
          </div>}
        </div>
      </div>
    </div>
  );
});

 export default ReviewRow;

 // ReviewRow is a uniform way to show a quick overview of all 
// the steps you configured throughout the Flow component.
//
// props:
//
// - title: name of setting that was configured
//
// - value: value of the field
//
// - editRoute: route of step where this field is configured
//
// - lastRow: enable on last row to hide border
//
// - children: non-string values that need to be shown, in replacement of the value prop
