import React from 'react';
import {observer} from 'mobx-react';
import {Button, HtmlRender} from 'components';
import {FormattedMessage} from 'react-intl';

const TextDescriptionTask = observer(({uiState}) => {
  const {task} = uiState;

  return (
    <div>
      {task.description &&
        <HtmlRender
          className='jumbo mb3 whitespace-pre-line'
          html={task.description}
        />
      }
      {!task.description  &&
        <div className='jumbo mb3'>
          <FormattedMessage id='inbox.No description provided.'/>
        </div>
      }
      {task.hasLink('complete') &&
        <Button onClick={() => uiState.completeTask()} className={task.canDelete ? 'mr2' : ''}>
          <FormattedMessage id='inbox.Mark Complete'/>
        </Button>}
      {task.canDelete && <Button trait='destructive' onClick={() => uiState.deleteTask()}>
        <FormattedMessage id='inbox.Delete Task'/>
      </Button>}
    </div>
  );
});

export default TextDescriptionTask;
