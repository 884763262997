import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {SegmentCalendar} from 'stores/calendars';
import {types, endpoints} from 'shared/core';
import {successAlert} from 'shared/tools';
import {t} from 'shared/core';
import _ from 'lodash';
import {Segment, Rule} from 'stores/segmentation';

class CalendarEditState {
  store = new DomainStore();
  history;
  match;

  @observable errors = {};
  @observable segmentCalendar;
  @observable participantEmployees = [];
  @observable subscriptionEmployees = [];

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    const id = _.get(this.match.params, 'id');

    await this.store._compose([
      endpoints.EMPLOYEES.ALL
    ]);

    if (id) {
      await this.store._compose([
        endpoints.SEGMENT_CALENDAR.with(id)
      ]);
    }

    this.segmentCalendar = new SegmentCalendar(
      this.store._getSingle(types.SEGMENT_CALENDAR, { id })
    );
    this.employees = this.store.getEmployees();

    this.initializeSegmentCalendar();
    this.initializeSelectedEmployees();
  }

  initializeSegmentCalendar() {
    if (!this.segmentCalendar.isNew) return;

    this.segmentCalendar.merge({
      participantSegment: new Segment({rules: [new Rule({ruleType: 'employee'})]}),
      subscriptionSegment: new Segment({rules: [new Rule({ruleType: 'employee'})]})
    });
  }

  initializeSelectedEmployees() {
    const participantEmployeeIds = this.segmentCalendar.participantSegment.rules[0].parameters;
    const subscriptionEmployeeIds = this.segmentCalendar.subscriptionSegment.rules[0].parameters;

    this.participantEmployees = _.chain(this.store.getEmployees())
      .filter(e => _.includes(participantEmployeeIds, e.id))
      .value();

    this.subscriptionEmployees = _.chain(this.store.getEmployees())
      .filter(e => _.includes(subscriptionEmployeeIds, e.id))
      .value();
  }

  @action changeParticipantEmployees(employees) {
    this.participantEmployees = employees;
  }

  @action changeSubscriptionEmployees(employees) {
    this.subscriptionEmployees = employees;
  }

  setSegmentParameters() {
    const participantEmployeeIds = this.participantEmployees.map(e => e.id);
    const subscriptionEmployeeIds = this.subscriptionEmployees.map(e => e.id);

    this.segmentCalendar.participantSegment.rules[0].parameters.replace(participantEmployeeIds);
    this.segmentCalendar.subscriptionSegment.rules[0].parameters.replace(subscriptionEmployeeIds);
  }

  @action async saveSegmentCalendar() {
    this.setSegmentParameters();

    const {model, errors} = this.segmentCalendar.isNew ?
      await this.createSegmentCalendar() :
      await this.store.patch(this.segmentCalendar);
    this.errors = errors;

    if (model) {
      successAlert(t('company_settings.calendars.Custom calendar saved.'));
      this.history.push('/calendars');
    }
  }

  @action async createSegmentCalendar() {
    return this.store.post(
      endpoints.SEGMENT_CALENDARS,
      types.SEGMENT_CALENDAR,
      this.segmentCalendar
    );
  }

  @action goBack() {
    this.history.push('/calendars');
  }
}

export default CalendarEditState;
