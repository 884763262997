import React from 'react';
import {observer} from 'mobx-react';
import {ObjectSelect2} from 'components';

const CreateReminderFor = observer(({uiState}) => {
  const {errors} = uiState;

  return (
    <ObjectSelect2 value={uiState.reminderTarget}
                   onChange={value => uiState.updateTarget(value)}
                   searchable={true}
                   items={uiState.targets}
                   label='company_settings.reminders.Reminder for'
                   placeholder='company_settings.reminders.e.g., First day of work'
                   errorMessage={errors.reminderType}
    />
  );
});

export default CreateReminderFor;
