import React from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';
import {observer} from 'mobx-react';
import ReportIndexState from 'reports/state/ReportIndexState';
import ReportsIndex from './ReportsIndex';
import ReportsContainer from './ReportsContainer';
import HiresAndTerminationsReport from './reports/HiresAndTerminationsReport';
import ActiveEmployeesReport from './reports/ActiveEmployeesReport';
import TerminatedEmployeesReport from './reports/TerminatedEmployeesReport';
import EmergencyContactsReport from './reports/EmergencyContactsReport';
import TimeOffBalancesReport from './reports/TimeOffBalancesReport';
import VacationLiabilityReport from './reports/VacationLiabilityReport';
import ManualAdjustmentsReport from './reports/ManualAdjustmentsReport';
import TimeOffRequestsReport from './reports/TimeOffRequestsReport';
import TimeOffPayrollReconciliationReport from './reports/TimeOffPayrollReconciliationReport';
import ProjectHoursReport from './reports/ProjectHoursReport';
import AgeDemographicsReport from './reports/AgeDemographicsReport';
import GenderDemographicsReport from './reports/GenderDemographicsReport';
import LegalGenderDemographicsReport from './reports/LegalGenderDemographicsReport';
import TenureReport from './reports/TenureReport';
import EmployeesByDepartmentReport from './reports/EmployeesByDepartmentReport';
import CustomReport from './reports/CustomReport';
import DependentEligibilityReport from './reports/DependentEligibilityReport';
import OnLeaveEmployeesReport from './reports/OnLeaveEmployeesReport';
import EmployeeGoalsReport from './reports/EmployeeGoalsReport';
import CheckinsReport from './reports/CheckinsReport';
import CandidatesReport from './reports/CandidatesReport';
import CandidatesBySourceReport from './reports/CandidatesBySourceReport';
import PerformanceReviewsReport from './reports/PerformanceReviewsReport';
import SurveysReport from './reports/SurveysReport';
import AnonymousSurveysReport from './reports/AnonymousSurveysReport';
import TrainingSummaryReport from './reports/TrainingSummaryReport';
import TrainingResponsesReport from './reports/TrainingResponsesReport';
import HeadcountReport from './reports/HeadcountReport';
import TurnoverReport from './reports/TurnoverReport';
import HiringVelocityReport from './reports/HiringVelocityReport';
import {ReportEditContainer} from 'reports/custom';
import {CustomReportsIndex} from 'reports/custom';
import {withState} from 'shared/core';

const ReportSwitch = ({uiState}) => {
  return (
    <ReportsContainer>
      <Switch>
        {uiState.activeEmployees && <Route path='/personnel' component={ActiveEmployeesReport} />}
        {uiState.headcount && <Route path='/headcount' component={HeadcountReport} />}
        {uiState.turnover && <Route path='/turnover' component={TurnoverReport} />}
        {uiState.onLeaveEmployees && <Route path='/employees-on-leave' component={OnLeaveEmployeesReport} />}
        {uiState.terminatedEmployees && <Route path='/terminated-employees' component={TerminatedEmployeesReport} />}
        {uiState.emergencyContacts && <Route path='/emergency-contacts' component={EmergencyContactsReport} />}
        {uiState.newHiresAndTerminations && <Route path='/employees/new-hires-and-terminations' component={HiresAndTerminationsReport} />}
        {uiState.timeOffBalances && <Route path='/time-off/balances' component={TimeOffBalancesReport} />}
        {uiState.vacationLiability && <Route path='/time-off/liability' component={VacationLiabilityReport} />}
        {uiState.manualAdjustments && <Route path='/time-off/manual-adjustments' component={ManualAdjustmentsReport} />}
        {uiState.timeOffRequests && <Route path='/time-off/requests' component={TimeOffRequestsReport} />}
        {uiState.payrollReconciliation && <Route path='/time-off/reconciliation' component={TimeOffPayrollReconciliationReport} />}
        {uiState.projectHours && <Route path='/time-tracking/project-hours' component={ProjectHoursReport} />}
        {uiState.ageProfile && <Route path='/employees/age-demographics' component={props => <AgeDemographicsReport showDepartmentReport={uiState.scope.ageByDepartmentChart}/>} />}
        {uiState.genderProfile && <Route path='/employees/gender-demographics' component={GenderDemographicsReport} />}
        {uiState.legalGenderProfile && <Route path='/employees/legal-gender-demographics' component={LegalGenderDemographicsReport} />}
        {uiState.tenureProfile && <Route path='/employees/tenure' component={props => <TenureReport showDepartmentReport={uiState.scope.tenureByDepartmentChart}/>} />}
        {uiState.departmentProfile && <Route path='/employees/departments' component={EmployeesByDepartmentReport} />}
        {uiState.dependentEligibility && <Route path='/benefits/dependent-eligibility' component={DependentEligibilityReport} />}
        {uiState.employeeGoals && <Route path='/performance/employee-goals' component={EmployeeGoalsReport} />}
        {uiState.checkins && <Route path='/performance/checkins' component={CheckinsReport} />}
        {uiState.candidates && <Route path='/recruiting/candidates' component={CandidatesReport} />}
        {uiState.candidatesBySource && <Route path='/recruiting/candidates-by-source' component={CandidatesBySourceReport} />}
        {uiState.hiringVelocity && <Route path='/recruiting/hiring-velocity' component={HiringVelocityReport} />}
        {uiState.performanceReviews && <Route path='/performance/performance-reviews' component={PerformanceReviewsReport} />}
        {uiState.surveys && <Route path='/surveys' component={SurveysReport} />}
        {uiState.anonymousSurveys && <Route path='/anonymous-surveys' component={AnonymousSurveysReport} />}
        {uiState.trainingSummary && <Route path='/training/summary' component={TrainingSummaryReport} />}
        {uiState.trainingResponses && <Route path='/training/responses' component={TrainingResponsesReport} />}
        {uiState.activeEmployees && <Redirect from='/active-employees' to='/personnel'/>}
      </Switch>
    </ReportsContainer>
  );
};

const CustomReportContainer = observer(({match}) => {
  return (
    <Switch>
      <Route path={`${match.path}/edit`} component={ReportEditContainer}/>
      <ReportsContainer backPath='/custom'>
        <Route exact path={match.path} component={CustomReport}/>
      </ReportsContainer>
    </Switch>
  );
});

const ReportsPage = ({uiState}) => {
  const {scope} = uiState;

  return (
    <Router basename='/reports'>
      <Switch>
        {scope.standardReports && <Route exact path='/' render={() => <ReportsIndex uiState={uiState} scope={scope}/>}/>}
        {scope.custom && <Route path='/custom/:id' component={CustomReportContainer}/>}
        {scope.custom && <Route path='/custom' render={({history}) => <CustomReportsIndex history={history} uiState={uiState} scope={scope}/>}/>}
        {scope.standardReports && <Route render={() => <ReportSwitch uiState={uiState}/>}/>}
        {!scope.standardReports && scope.custom && <Redirect exact from='/' to='/custom'/>}
      </Switch>
    </Router>
  );
};

export default withState(ReportsPage, ReportIndexState);
