import React from 'react';
import {observer} from 'mobx-react';
import {withState, endpoints, types, t} from 'shared/core';
import {Layout, InteractiveTable, Panel} from 'components';
import {PerformanceReviewReviewee} from 'stores/performance_reviews';
import ReviewInProgressSummaryPanel from './ReviewInProgressSummaryPanel';
import ReviewEndedSummaryPanel from './ReviewEndedSummaryPanel';
import {AdminContainerState} from '../state';
import {SelectReviewersModal} from 'performance_reviews/components/reviews/components/manage/components/reviewees/components/select_reviewers';
import {AddRevieweeModal} from 'performance_reviews/components/reviews/components/manage/components/reviewees/components//add_reviewee';
import {MANAGER_REVIEW_COLUMNS, NON_MANAGER_REVIEW_COLUMNS} from './columns';
import useModal from 'shared/hooks/useModal';
import ShareWithRevieweeModal from 'containers/performance_reviews/components/reviews/components/view/components/ShareWithRevieweeModal';
import UnshareRevieweeModal from 'containers/performance_reviews/components/reviews/components/view/components/UnshareRevieweeModal';

const LOCALIZATION = {
  interactive: {
    addModel: 'performance_reviews.view.Add Participant'
  },
  removeModal: {
    header: 'performance_reviews.view.Remove Participant',
    subHeader: 'performance_reviews.view.Are you sure you want to remove this participant from the performance review?',
    body: 'performance_reviews.view.All reviews for this employee will be permanently deleted.'
  },
  editModel: 'performance_reviews.view.Edit Reviewers'
};

const ReviewSummaryPanel = ({uiState}) => {
  const {cycle} = uiState;

  if (cycle.state === 'ended') {
    return <ReviewEndedSummaryPanel uiState={uiState}/>;
  } else {
    return <ReviewInProgressSummaryPanel uiState={uiState}/>;
  }
};

const AdminContainer = observer(({uiState}) => {
  const shareWithRevieweeModalOpen = useModal(uiState, 'ShareWithRevieweeModal', 'selectedReviewee');
  const unshareRevieweeModalOpen = useModal(uiState, 'UnshareRevieweeModal', 'selectedReviewee');
  const {cycle, match, selectedReviewee} = uiState;

  return (
    <Layout>
      <Panel.Stack loose>
        <ReviewSummaryPanel uiState={uiState}/>
        <InteractiveTable
          title={t('performance_reviews.view.Review Participants')}
          uiState={uiState}
          backPath='admin'
          match={match}
          columns={cycle.managerType ? MANAGER_REVIEW_COLUMNS : NON_MANAGER_REVIEW_COLUMNS}
          localization={LOCALIZATION}
          onMount={agent => uiState.setInteractiveAgent(agent)}
          onEdit={(model) => uiState.openReviewerSelectionModal(model)}
          onRemove={(model) => uiState.removeReviewee(model)}
          showAddButton={!(cycle.state === 'ended')}
          onAdd={() => uiState.openAddRevieweeModal()}
          customLinks={(model) => uiState.customLinksFor(model)}
          proxy={{
            type: 'async',
            modelType: types.PERFORMANCE_REVIEWS.REVIEWEE,
            model: PerformanceReviewReviewee,
            endpoint: endpoints.PERFORMANCE_REVIEWS.REVIEWEES.ADMIN.with(match.params.cycleId)
          }}/>
        <SelectReviewersModal
          cycle={cycle}
          reviewee={selectedReviewee}
          isOpen={uiState.reviewerSelectionModalOpen}
          onHide={() => uiState.closeReviewerSelectionModal()}
          onSave={() => uiState.onReviewersSelected()}
        />
        <AddRevieweeModal
          cycle={cycle}
          isOpen={uiState.addRevieweeModalOpen}
          onHide={() => uiState.closeAddRevieweeModal()}
          onSave={() => uiState.onRevieweeAdded()}
        />
        <ShareWithRevieweeModal
          uiState={uiState}
          isOpen={shareWithRevieweeModalOpen}
        />
        <UnshareRevieweeModal
          uiState={uiState}
          isOpen={unshareRevieweeModalOpen}
        />
      </Panel.Stack>
    </Layout>
  );
});

export default withState(AdminContainer, AdminContainerState);
