import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {observer} from 'mobx-react';
import {auth, t} from 'shared/core';
import {DocumentTitle} from 'components';
import {TrainingAdminDirectoryContainer} from './manage';
import {TrainingEditFlow} from './edit';
import {TrainingSummaryContainer} from './summary';
import {TrainingStatusesDirectoryContainer} from './directory';
import {TrainingProgramEmployeeContainer} from './view';

const AdminTrainingPage = observer(({match}) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/programs`} component={TrainingAdminDirectoryContainer}/>
      <Route path={`${match.path}/programs/:id/edit`} component={TrainingEditFlow}/>
      <Route path={`${match.path}/programs/:id/summary`} component={TrainingSummaryContainer}/>
      <Redirect exact from={match.path} to={`${match.path}/programs`}/>
    </Switch>
  );
});

const TrainingPage = () => {
  return (
    <DocumentTitle title={t('training.Training')}>
      <Router basename='/training'>
        <Switch>
          {!!auth.employee.id && <Route exact path='/programs' render={() => <TrainingStatusesDirectoryContainer scopeToEmployee={true}/>}/>}
          {auth.hasAccess('::MANAGE_TRAINING') && <Route exact path='/programs/company' component={TrainingStatusesDirectoryContainer}/>}
          <Route path='/programs/:id' component={TrainingProgramEmployeeContainer}/>
          {auth.hasAccess('::MANAGE_TRAINING') && <Route path='/admin' component={AdminTrainingPage}/>}
          {!!auth.employee.id && <Redirect exact from='/' to='/programs'/>}
          {!auth.employee.id && <Redirect exact from='/' to='/programs/company'/>}
        </Switch>
      </Router>
    </DocumentTitle>
  );
};

export default observer(TrainingPage);
