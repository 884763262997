import React from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {fullName} from 'shared/tools';

const AvatarImage = observer(({avatar}) => {
  return (
    <img alt='employee profile' className='align-top Avatar-img' src={avatar}/>
  );
});

const AvatarInitials = observer(({initials}) => {
  return (
    <div className='center white'>{initials}</div>
  );
});

const AvatarHelper = observer(({name}) => {
  return (
    <div className='Avatar-tooltip'>{name}</div>
  );
});

const Avatar = observer(({employee, size, className, onClick}) => {
  const avatar = _.get(employee, 'links.profilePicture');
  return (
    <div className='inline-block relative' onClick={onClick}>
      <div className={`Avatar Avatar--editable clickable Avatar--${size} ${!avatar && employee.avatarColour} mx-auto ${className}`}>
        {avatar ? <AvatarImage avatar={avatar}/> : <AvatarInitials initials={employee.avatarInitials}/>}
        <div className='md-and-below-hide'>
          <div className='Avatar-edit p2'>
            <i className='material-icons white'>{'mode_edit'}</i>
          </div>
        </div>
        <div className='md-and-above-hide Avatar-edit'>
          <i className='material-icons white'>{'mode_edit'}</i>
        </div>
      </div>
      <AvatarHelper name={fullName(employee)} />
    </div>
  );
});

Avatar.propTypes = {
  employee: PropTypes.shape({
    avatarColour: PropTypes.string.isRequired,
    avatarInitials: PropTypes.string.isRequired,
    className: PropTypes.string,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg'])
  }),
};

Avatar.defaultProps = {
  size: 'md'
};

export default Avatar;
