import {observable, action} from 'mobx';
import {t, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import {successAlert} from 'shared/tools';

class TeamContainerState {
  scope;
  match;
  store = new DomainStore();

  @observable cycle;
  @observable selectedReviewee = {};

  receiveProps({match, cycle, scope}) {
    this.match = match;
    this.scope = scope;
    this.cycle = cycle;
  }

  @action async shareWithReviewee(reviewee) {
    const review = await this.store.post(
      reviewee.link('share'),
      types.PERFORMANCE_REVIEWS.REVIEWEE
    );

    if (review) {
      this.interactiveAgent.refresh();
      this.closeShareWithRevieweeModal();
      successAlert(t('performance_reviews.view.alerts.Review shared.'));
    }
  }

  @action async unshareReviewee(reviewee) {
    const review = await this.store.post(
      reviewee.link('unshare'),
      types.PERFORMANCE_REVIEWS.REVIEWEE
    );

    if (review) {
      this.interactiveAgent.refresh();
      this.closeUnshareRevieweeModal();
      successAlert(t('performance_reviews.view.alerts.Review unshared.'));
    }
  }

  setInteractiveAgent(interactiveAgent) {
    this.interactiveAgent = interactiveAgent;
  }

  canShareReviewee(reviewee) {
    if (!reviewee) {
      return false;
    }

    return reviewee.hasLink('share');
  }

  canUnshareReviewee(reviewee) {
    if (!reviewee) {
      return false;
    }

    return reviewee.hasLink('unshare');
  }

  writePathForReviewee(reviewee) {
    return `/reviews/reviews/${this.cycle.id}/reviewee/${reviewee.id}/employee/${reviewee.employee.id}/${reviewee.employee.urlSlug}/write`;
  }

  goToWriteReview(reviewee) {
    window.location = this.writePathForReviewee(reviewee);
  }

  customLinksFor(reviewee) {
    const links = [];

    if (reviewee.hasLink('unshare')) {
      links.push(
        {
          text: 'performance_reviews.view.Unshare',
          action: reviewee => this.openUnshareRevieweeModal(reviewee)
        }
      );
    }

    return links;
  }
}

export default TeamContainerState;
