import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import ReportColumn from './ReportColumn';
import FilterRow from './FilterRow';
import _ from 'lodash';
import CustomReportShare from 'stores/reports/CustomReportShare';
import {User} from 'stores/users';

class CustomReport extends DomainObject {
  @observable id;
  @observable name;
  @observable columns;
  @observable filter;
  @observable employmentRecordsShowHistory;
  @observable shares;
  @observable sharedBy;
  @observable user;

  @action merge(other) {
    if (other.filter) {
      this.filter = [];
    }

    super.merge(other, {
      columns: [ReportColumn],
      filter: [FilterRow],
      shares: [CustomReportShare],
      sharedBy: User,
      user: User
    });
  }

  @action addShare(share) {
    this.shares.push(share);
  }

  @action removeShare(share) {
    this.shares.remove(_.find(this.shares, {userId: share.userId}));
  }

  @computed get sortedColumns() {
    return _.sortBy(
      this.columns,
      'order'
    );
  }

  @computed get usersWithAccess() {
    const sharedWith = _.map(this.shares, (share) => share.user);
    return [this.user, ...sharedWith];
  }
}

export default CustomReport;
