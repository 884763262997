import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import _ from 'lodash';

class CountryStore extends DomainStore {
  async loadCountries() {
    await this._compose([endpoints.COUNTRIES]);
    return _.sortBy(this._getAll(types.COUNTRY), 'name');
  }

  async getOrLoadRegions(countryCode, prefix = true) {
    if (_.isEmpty(this.__getRegions(countryCode, prefix))) {
      await this.__loadRegions(countryCode);
    }

    return this.__getRegions(countryCode, prefix);
  }

  __getRegions(countryCode, prefix) {
    const allRegions = this._getAll(types.REGION);
    const selectedRegions = _.filter(allRegions, (region) => {
      return _.startsWith(region.id, `${countryCode}-`);
    });
    return _.map(selectedRegions, r => prefix ? r : {id: r.id.replace(countryCode + '-', ''), name: r.name});
  }

  async __loadRegions(countryCode) {
    await this._compose([endpoints.REGIONS.with(countryCode)]);
  }

  getAllRegions() {
    return this._getAll(types.REGION);
  }
}

const singleton = new CountryStore();

export default singleton;
