import React from 'react';
import { observer } from 'mobx-react';
import { DatePicker, FormError, ObjectSelect2, FormLayout } from 'components';
import { ModalButtons } from 'components/modals';
import { FormattedMessage } from 'react-intl';

const AddAssigneeForm = observer(({ uiState }) => {
  const {companyDocumentAssignment, companyDocuments, errors} = uiState;

  return (
    <div>
      <div className='h2 mb3'><FormattedMessage id='employees.profile.documents.Assign Document'/></div>
      <div className='jumbo mb2'>
        <FormattedMessage id='employees.profile.documents.This employee will be notified with a task to complete the document.'/>
      </div>
      <FormLayout>
        <ObjectSelect2
          label='employees.profile.documents.Company Document'
          value={companyDocumentAssignment.companyDocument}
          items={companyDocuments}
          onChange={value => companyDocumentAssignment.merge({companyDocument: value})}
        />
        <DatePicker
          label='employees.profile.documents.Due Date'
          value={companyDocumentAssignment.dueDate}
          onChange={date => companyDocumentAssignment.merge({dueDate: date})}
          errorMessage={errors.dueDate}
          optional
        />
        <FormError message={errors.base} />
      </FormLayout>
      <ModalButtons
        saveEnabled={!!companyDocumentAssignment.companyDocument}
        onSave={() => uiState.assignExistingDocument()}
        onCancel={() => uiState.closeAssignDocumentModal()}
      />
    </div>
  );
});

export default AddAssigneeForm;
