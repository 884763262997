export const onboarding = {
  templateType: 'onboarding',
  addTaskTitle: '+ Add new onboarding task',
  selectTasksTitle: '+ Select onboarding tasks',
  title: 'Onboarding Tasks',
  modalTitle: 'Add an Onboarding Task',
  modalEditTitle: 'Edit Onboarding Task',
  dueOnDateOption: 'On Start Date',
  saveTaskAsPresetTitle: 'Save onboarding task as preset for later use',
  dateString: 'start date',
  selectTemplatesToUse: 'Select tasks to be completed',
  emptyState: 'No tasks have been created',
  fetchTasks: true
};

export const offboarding = {
  templateType: 'offboarding',
  addTaskTitle: '+ Add new offboarding task',
  selectTasksTitle: '+ Select offboarding tasks',
  title: 'Offboarding Tasks',
  modalTitle: 'Add an Offboarding Task',
  modalEditTitle: 'Edit Offboarding Task',
  dueOnDateOption: 'On Termination Date',
  saveTaskAsPresetTitle: 'Save offboarding task as preset for later use',
  dateString: 'termination',
  selectTemplatesToUse: 'Select tasks to be completed',
  emptyState: 'No tasks have been created',
  fetchTasks: false
};
