import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {fullName} from 'shared/tools';
import _ from 'lodash';
import {Panel, Avatar, Checkbox, FormError} from 'components';
import {t} from 'shared/core';

const ParticipantRow = ({uiState, reviewer}) => {
  const {manager} = reviewer;
  return (
    <label className='col col-12 p2 border-bottom'>
      <div className='clearfix table px1'>
        <div className='col-1 table-cell align-middle center'>
          <Checkbox checked={reviewer.checked} onChange={() => reviewer.checked = !reviewer.checked}/>
        </div>
        <div className='col-6 table-cell align-middle'>
          <div className='clearfix pl2'>
            <div className='table-cell align-middle pr1'>
              <Avatar employee={manager} size='sm'/>
            </div>
            <div className='table-cell align-middle pl1'>
              <div>{fullName(manager)}</div>
            </div>
          </div>
        </div>
        <div className='col-5 table-cell align-middle'>
          <div>{_.get(manager, 'department.name')}</div>
        </div>
      </div>
    </label>
  );
};

const Reviewers = observer(({uiState}) => {
  const {cycle, errors} = uiState;

  return (
    <div>
      <Panel.Header title={t('performance.Choose the managers who will lead the check-ins')}/>
      <div className='col-12 pb1'>
        <div className='h4 waterloo mb3 max-width-2'>
          <FormattedMessage id='performance.Choose the managers you want leading these one on ones. Next, you will be able to specify the employees who will check-in with them.'/>
        </div>
        <div className='clearfix border rounded height-250 mt1'>
          <div className='height-250 overflow-auto'>
            {cycle.selectableReviewers.map(r => <ParticipantRow key={r.manager.id} reviewer={r} uiState={uiState}/>)}
          </div>
          <FormError message={errors.reviewers} />
        </div>
      </div>
    </div>
  );
});

export default Reviewers;

