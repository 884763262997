import React from 'react';

const FormLayout = ({children}) => {
  return React.Children.map(children, c => {
    if (!c) return null;

    if (c.props.wrapper && c.props.wrapper.name === 'FormLayout') {
      return c;
    } else {
      return (
        <div className='FormRow'>
          {c}
        </div>
      );
    }
  });
};

// use FormLayout to arrange fields within a form using standard spacing.
// by default it stacks them vertically but also supports horizontal groups of fields
// (use FormLayout.Group for that)

export default FormLayout;
