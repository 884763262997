import _ from 'lodash';
import chart from './chart.js';
import defaultConfig from './defaultConfig';

function pieChart({rows, meta}, options = {}) {
  const {category, columns, series} = meta;
  const seriesColumn = _.find(columns, c => c.id === series[0]);

  const data = _.chain(rows)
    .map(r => ({
      name: _.get(r, `values.${category}`),
      y: _.get(r, `values.${series[0]}`)
    }))
    .filter(r => _.size(rows) === 1 || r.y || meta.showEmptySeries)
    .value();

  const highchartsSeries = [{
    colorByPoint: true,
    name: seriesColumn.label,
    data: _.orderBy(data, 'y', 'desc')
  }];

  let pieChart = {
    ...defaultConfig(rows, options),
    chart: {
      ...chart('pie'),
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b> (<b>{point.percentage:.1f}%</b>)'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          softConnector: false,
          connectorColor: '#C9CFD1',
          connectorPadding: 4,
          style: {
            fontFamily: 'GT Walsheim, sans-serif',
            fontWeight: 200,
            fontSize: '16px',
            color: '#212B36',
            textOutline: 'none',
          },
          formatter: function() {
            if (this.y > 0) {
              return this.y;
            }
          }
        },
        states: {
          hover: {
            halo: false
          }
        },
        showInLegend: true
      }
    },
    series: highchartsSeries
  };

  if (options.disabledDataLabels) {
    pieChart.plotOptions.pie.dataLabels.enabled = false;
  }

  if (options.fixedChartHeight) {
    pieChart.chart.events = {
      load: function() {
        const legendSpace = this.legend.legendHeight - this.legend.padding;

        if (legendSpace) {
          this.setSize(null, this.chartHeight + legendSpace);
        }
      }
    };
  }

  _.merge(pieChart, options.highcharts);
  pieChart.colors = _.get(options, 'highcharts.colors') || pieChart.colors;

  return pieChart;
}

export default pieChart;
