import $ from 'jquery';
import _ from 'lodash';
import {renderIfNeeded} from 'shared/tools';
import {auth} from 'shared/core';
/*global document*/

let timeout = null;
let cancellationPending = false;
let lastActiveDate = null;

/*
TIMEOUT_MS is set in milliseconds.
TIMEOUT_MS should be set to the following:
TIMEOUT_MS = config.session_timeout - 30 seconds
e.g., if config.session_timeout == 3600, TIMEOUT_MS should be = 3,570,000
*/
const TIMEOUT_MS = 3570000;
const SESSION_EXPIRING_WINDOW = 60000;
const CHECK_INTERVAL = 1000;

const SVG = '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="blur-svg"> <defs> <filter id="blur-filter"> <feGaussianBlur stdDeviation="3"></feGaussianBlur> </filter> </defs> </svg>';

function getExpiryDate() {
  return new Date(lastActiveDate.getTime() + TIMEOUT_MS);
}

function handleSessionExpiration() {
  let sessionExpiryContainer = $('#cr-session-expiry');
  if (!sessionExpiryContainer.length) {
    sessionExpiryContainer = $("<div id='cr-session-expiry'>").append(SVG);
    $(document.body).append(sessionExpiryContainer);
  }

  const RenewSessionContainer = require('../components/RenewSessionContainer').default;
  renderIfNeeded(RenewSessionContainer, 'cr-session-expiry', {expiryDate: getExpiryDate()});
}

function checkIfSessionExpired() {
  const ms = new Date() - lastActiveDate;
  if (ms >= TIMEOUT_MS - SESSION_EXPIRING_WINDOW) {
    handleSessionExpiration();
    clearInterval(timeout);
  }
}

export function resetSessionExpirationTimeout() {
  if (!auth.user.id) return null;
  cancellationPending = true;

  clearInterval(timeout);
  setSessionExpirationTimeout();
}

export function setSessionExpirationTimeout() {
  if (_.startsWith(window.location.pathname, '/user_session')) return;
  if (!auth.user.id) return null;

  lastActiveDate = new Date();
  timeout = setInterval(checkIfSessionExpired, CHECK_INTERVAL);
}

export function cancelLoginPending() {
  return cancellationPending;
}

export function onLoginShown() {
  cancellationPending = false;
}
