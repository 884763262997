import {action, observable, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {auth, t, endpoints, types, urls} from 'shared/core';
import {BenefitInfoChangeRequest} from 'stores/benefits';
import createUpdateSummaries from './createUpdateSummaries';
import _ from 'lodash';
import {calendarDate} from 'shared/tools';
import {Employee} from 'stores/employees';

class BenefitInfoEditState {
  match;
  history;
  store = new DomainStore();
  baseUrl;
  backPath;
  backTitle;

  @observable benefitInfoChangeRequest;
  @observable employee;
  @observable errors = {};
  @observable updateSummaries = [];
  @observable isOnTheLastStep = false;
  @observable confirmationModalOpen = false;

  receiveProps({match, history, baseUrl, backPath, backTitle}) {
    this.match = match;
    this.history = history;
    this.baseUrl = baseUrl || `/benefit_info/${match.params.id}/edit`;

    if (backPath) {
      this.backPath = backPath;
    }
    if (backTitle) {
      this.backTitle = backTitle;
    }
  }

  @action async load() {
    await this.store._compose([
      endpoints.BENEFITS.BENEFIT_INFO_CHANGE_REQUEST.with(this.match.params.id)
    ]);

    this.benefitInfoChangeRequest = new BenefitInfoChangeRequest(
      this.store._getSingle(types.BENEFITS.BENEFIT_INFO_CHANGE_REQUEST)
    );

    if (!this.isSelf) {
      await this.store._compose([
        endpoints.EMPLOYEE_DETAILS.with(this.benefitInfoChangeRequest.updatedBenefitInfo.employeeId)
      ]);

      this.employee = new Employee(
        this.store._getSingle(types.EMPLOYEE_DETAILS)
      );
    }

    this.backTitle = this.backTitle || this.defaultBackTitle;
    this.backPath = this.backPath || this.defaultBackPath;
  }

  @action async onStepChanged({location}) {
    switch (location) {
      case 'review':
        this.initUpdateSummaries();
        break;
      default:
        break;
    }

    this.isOnTheLastStep = location === 'review';
  }

  initUpdateSummaries() {
    this.updateSummaries.replace(createUpdateSummaries(this.benefitInfoChangeRequest.benefitInfoChanges));
  }

  async submitStep({changeRequest, benefitInfo}) {
    const _changeRequest = this.benefitInfoChangeRequest.pick(changeRequest || []);
    _changeRequest.updatedBenefitInfo = this.benefitInfo.pick(benefitInfo);
    const {model, errors} = await this.store.patch(_changeRequest);
    this.errors = errors;
    if (!model) return false;

    this.benefitInfoChangeRequest.update(model);
  }

  @action async submitReason() {
    return this.submitStep({
      changeRequest: [
        'reasonForChange',
        'reasonText',
        'effectiveDate'
      ]
    });
  }

  @action async submitSpouse() {
    return this.submitStep({
      benefitInfo: [
        'hasSpouse',
        'spouseFirstName',
        'spouseLastName',
        'spousalGender',
        'spousalRelationship',
        'spouseBirthDate',
        'spouseMarriageDate',
        'spousalHealthCoverage',
        'spousalDentalCoverage',
        'spousalVisionCoverage',
        'spouseInsurerName',
        'spousePolicyNumber'
      ]
    });
  }

  @action async submitDependants() {
    return this.submitStep({
      benefitInfo: [
        'dependents'
      ]
    });
  }

  @action async submitCoverage() {
    return this.submitStep({
      benefitInfo: [
        'hasCanadianVisaOrPermit',
        'visaOrPermitExpiryDate',
        'hasProvincialHealthPlan',
        'healthCoverage',
        'dentalCoverage',
        'taxExempt',
        'inacNumber'
      ]
    });
  }

  @action async finish() {
    const {model, errors} = await this.store.patch(
      this.benefitInfoChangeRequest.link('submit'),
      types.BENEFITS.BENEFIT_INFO_CHANGE_REQUEST,
      this.benefitInfoChangeRequest
    );

    this.errors = errors;

    if (model) {
      this.confirmationModalOpen = true;
    }
  }

  @action redirectBack() {
    window.location = this.backPath;
  }

  @action async onStepSubmitted({location}) {
    switch (location) {
      case 'reason': return this.submitReason();
      case 'spouse': return this.submitSpouse();
      case 'dependants': return this.submitDependants();
      case 'coverage': return this.submitCoverage();
      case 'review': return this.finish();
      default:
        throw new Error(`Step ${location} is not supported.`);
    }
  }

  @computed get benefitInfo() {
    return this.benefitInfoChangeRequest.updatedBenefitInfo;
  }

  @computed get generalSummaryItems() {
    return [
      {
        title: t('benefits.benefit_info.edit.review.Effective date'),
        value: calendarDate(this.benefitInfoChangeRequest.effectiveDate)
      },
      {
        title: t('benefits.benefit_info.edit.review.Reason for change'),
        value: t(`models.benefit_info_change_requests.reasons_for_change.${this.benefitInfoChangeRequest.reasonForChange}`)
      },
      {
        title: t('benefits.benefit_info.edit.review.Reason for change - additional information'),
        value: this.benefitInfoChangeRequest.reasonText
      }
    ];
  }

  @computed get hasChanges() {
    return !!this.updateSummaries.length;
  }

  @computed get nextEnabled() {
    if (this.isOnTheLastStep) {
      return this.hasChanges;
    }

    return true;
  }

  @computed get isSelf() {
    return auth.employee.id === this.benefitInfoChangeRequest.updatedBenefitInfo.employeeId.toString();
  }

  @computed get defaultBackTitle() {
    return this.isSelf ?
      t('benefits.benefit_info.edit.My Profile') :
      this.employee.name;
  }

  @computed get defaultBackPath() {
    const benefitsModuleUrl = this.isSelf ?
      urls.MY_PROFILE.BENEFITS :
      `/employees/${this.employee.id}/benefits`;

    const directoryModuleUrl = this.isSelf ?
      '/benefits/my_enrolment' :
      `/benefits/benefit_enrolments/${this.employee.id}/`;

    return auth.moduleEnabled('directory') ? directoryModuleUrl : benefitsModuleUrl;
  }
}

export default BenefitInfoEditState;
