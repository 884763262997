import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {LeaveType} from 'stores/leave_types';
import {types, endpoints} from 'shared/core';
import {successAlert} from 'shared/tools';
import {t} from 'shared/core';
import _ from 'lodash';

class LeaveTypeEditState {
  store = new DomainStore();
  history;
  match;

  @observable errors = {};
  @observable leaveType;

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    const id = _.get(this.match.params, 'id');

    if (id) {
      await this.store._compose([
        endpoints.LEAVE_TYPE.with(id)
      ]);
    }

    this.leaveType = new LeaveType(
      this.store._getSingle(types.LEAVE_TYPE, { id })
    );
  }

  @action async saveLeaveType() {
    const {model, errors} = this.leaveType.isNew ? 
      await this.createLeaveType() : 
      await this.store.patch(this.leaveType);
    this.errors = errors;

    if (model) {
      successAlert(t('company_settings.leave_types.Leave type saved'));
      this.history.push('/leave_types');
    }
  }

  @action async createLeaveType() {
    return this.store.post(
      endpoints.LEAVE_TYPES,
      types.LEAVE_TYPE,
      this.leaveType
    );
  }

  @action goBack() {
    this.history.push('/leave_types');
  }
}

export default LeaveTypeEditState;
