import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {Select2, TextArea, Avatar} from 'components';
import {auth} from 'shared/core';
import {t} from 'shared/core';
import _ from 'lodash';

const InputForm = observer(({detailsState}) => {
  return (
    <div className='clearfix'>
      <div className='col col-1 pr1'>
        <Avatar employee={auth.user} size='sm' className='left'/>
      </div>
      <div className='col col-11 pl1'>
        <TextArea
          minRows={3}
          value={detailsState.newPositionChangeText}
          onChange={(e) => detailsState.newPositionChangeText = e.target.value}
          placeholder='recruiting.candidate.Reason for position change...'
          className='full-width js-comment-textarea'
        />
      </div>
    </div>
  );
});

const MovePositionModal = observer(({uiState}) => {
  const {availablePositions, selectedCandidatesCount, detailsState} = uiState;
  const {newPositionId, bulkMovingCandidates, movePositionModalOpen} = detailsState;

  return (
    <Modal
      isOpen={movePositionModalOpen}
      onHide={() => detailsState.closeMovePositionModal()}
      size='md'
      closeButton={false}
      title={bulkMovingCandidates ? t('recruiting.candidate.MOVE_CANDIDATES_POSITION_HEADER', {count: selectedCandidatesCount}) :
        t('recruiting.hints.Move candidate to another position')}
    >
      <div className='mb2'>
        <Select2
          value={newPositionId}
          label="recruiting.subnav.Positions"
          onChange={(e) => detailsState.newPositionId = e.target.value}
          errorMessage={_.get(detailsState.errors, 'position')}
          searchable
        >
          {availablePositions.map(position =>
            <option key={position.id} value={position.id}>{position.title}</option>)}
        </Select2>
      </div>
      <InputForm detailsState={detailsState}/>
      <ModalButtons
        onSave={() => detailsState.savePositionChange()}
        onCancel={() => detailsState.closeMovePositionModal()}
      />
    </Modal>
  );
});

export default MovePositionModal;
