import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Department',
  'Employment Type',
  'Location',
  'Manager'
];

const LegalGenderDemographicsReport = () => {
  return (
    <CompositeReport filters={filters}>
      <Report name={t('reports.Gender Demographics')}
              view='chart' chartType='pie'
              url='/reports/legal_gender_demographics_chart'>
      </Report>
    </CompositeReport>
  );
};

export default LegalGenderDemographicsReport;
