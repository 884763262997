import React from 'react';
import {observer} from 'mobx-react';
import PersonalInfo from './PersonalInfo';
import AddressInfo from './AddressInfo';
import ContactInfo from './ContactInfo';
import EmployeeInfoCustomFields from './EmployeeInfoCustomFields';
import {Panel} from 'components';
import EmployeeProfileContainer from '../../../../../../EmployeeProfileContainer';

const PersonalTab = observer(({uiState}) => {
  return (
    <EmployeeProfileContainer uiState={uiState}>
      <Panel.Stack loose>
        <PersonalInfo uiState={uiState}/>
        <AddressInfo uiState={uiState}/>
        <ContactInfo uiState={uiState}/>
        <EmployeeInfoCustomFields uiState={uiState}/>
      </Panel.Stack>
    </EmployeeProfileContainer>
  );
});

export default PersonalTab;
