import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import {Input} from 'components';

const FormRow = observer(({name, className, children}) => {
  return (
    <div className={`${className ? className : 'my3'}`}>
      <div className='h4 medium mb1'>
        <FormattedMessage id={name}/>
      </div>
      {children}
    </div>
  );
});

const EditCompanyModal = observer(({uiState}) => {
  const {editingCompany, errors} = uiState;

  return (
    <Modal isOpen={uiState.editCompanyModalOpen} onHide={() => uiState.closeEditCompanyModal()} size='md'>
      <div className='h2 medium mb3'>
        <FormattedMessage id='company_settings.general.Edit Company Information'/>
      </div>
      <form>
        <FormRow name='company_settings.general.Company Name'>
          <Input value={editingCompany.name}
                 errorMessage={errors.name}
                 onChange={(e) => editingCompany.merge({name: e.target.value})}
           />
        </FormRow>
        <FormRow name='company_settings.general.Company Phone Number'>
          <Input value={editingCompany.phoneNumber}
                 errorMessage={errors.phoneNumber}
                 onChange={(e) => editingCompany.merge({phoneNumber: e.target.value})}
           />
        </FormRow>
        <ModalButtons onSave={() => uiState.saveCompany()} onCancel={() => uiState.closeEditCompanyModal()}/>
      </form>
    </Modal>
  );
});

export default EditCompanyModal;
