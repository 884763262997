import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, FormLayout, Select2} from 'components';
import PayScheduleForm from './PayScheduleForm';
import PayScheduleDetails from './PayScheduleDetails';
import _ from 'lodash';

const PaySchedule = observer(({uiState}) => {
  const {selectedPaySchedule, paySchedules, errors} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('time_tracking.manage.policies.edit.steps.Pay Schedule')}
        description={t('time_tracking.manage.policies.edit.Choose the pay schedule that will be used for this policy.')} />
      <FormLayout>
        {!_.isEmpty(paySchedules) && <Select2
          value={selectedPaySchedule ? selectedPaySchedule.id : ''}
          placeholder={t('time_tracking.manage.policies.edit.Select Pay Schedule')}
          onChange={e => uiState.payScheduleSelected(e.target.value)}
          errorMessage={errors.paySchedule}
        >
          {paySchedules.map((paySchedule) =>
            <option key={paySchedule.id} value={paySchedule.id}>{paySchedule.name}</option>
          )}
          <option value='-1'>{t('time_tracking.manage.policies.edit.+ Create New Pay Schedule')}</option>
        </Select2>}
        <PayScheduleForm uiState={uiState} errors={_.get(errors, 'paySchedule') || {}}/>
      </FormLayout>
      <PayScheduleDetails uiState={uiState}/>
    </div>
  );
});

export default PaySchedule;
