import {DomainStore} from 'shared/store';
import {action, observable, computed} from 'mobx';
import {endpoints, types, t} from 'shared/core';
import {successAlert} from 'shared/tools';
import {TimeTrackingPaySchedule, TimeTrackingProject} from 'stores/time_tracking';
import _ from 'lodash';

class PayrollHoursContainerState {
  store = new DomainStore();
  @observable projects = [];

  @observable paySchedules = [];
  @observable selectedPaySchedule = {};
  @observable selectedPayPeriod = {};

  @observable selectedPayrollHoursSummary = {};
  @observable lastApprovedSummary = {};

  @action async load() {
    await this.store._compose([
      endpoints.TIME_TRACKING.PROJECTS.ALL,
      endpoints.TIME_TRACKING.PAY_SCHEDULES.ALL
    ]);

    this.projects = this.store._getAll(types.TIME_TRACKING.PROJECT, TimeTrackingProject);
    this.paySchedules = this.store._getAll(types.TIME_TRACKING.PAY_SCHEDULE, TimeTrackingPaySchedule);
    if (_.isEmpty(this.paySchedules)) {
      window.location = '/time_tracking';
    } else {
      this.selectPaySchedule(this.paySchedules[0]);
    }
  }

  @action setInteractiveAgent(agent) {
    this.agent = agent;
  }

  @action selectPaySchedule(paySchedule) {
    this.selectedPaySchedule = paySchedule;
    this.selectPayPeriod(this.selectedPaySchedule.previousPayPeriod);
  }

  @action selectPayPeriod(payPeriod) {
    this.selectedPayPeriod = payPeriod;
  }

  @action async approve(payrollHoursSummary) {
    this.lastApprovedSummary.errors = {};

    const {model, errors} = await this.store.post(
      endpoints.TIME_TRACKING.APPROVE.with(this.selectedPayPeriod.id, payrollHoursSummary.employee.id),
      types.TIME_TRACKING.TIMESHEET
    );

    if (model) {
      await this.agent.refresh();
      successAlert(t('time_tracking.payroll_hours.Timesheet successfully approved'));
    } else {
      payrollHoursSummary.errors = errors;
      this.openErrorMessageModal(payrollHoursSummary);
    }
    this.lastApprovedSummary = payrollHoursSummary;
  }

  @computed get showAccordion() {
    return !_.isEmpty(this.projects);
  }
}

export default PayrollHoursContainerState;
