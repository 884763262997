import React from 'react';
import {observer} from 'mobx-react';
import FilterComponent from './FilterComponent';

const ReviewEmployee = observer(({models, filter, filterKey, filterUpdated, filterDeleted, Wrapper}) => {
  const options = models.map(employee => {
    return { label: employee.name, value: employee.id };
  });

  return <FilterComponent
    filter={filter}
    filterKey={filterKey}
    options={options}
    filterUpdated={filterUpdated}
    filterDeleted={filterDeleted}
    Wrapper={Wrapper}
    searchable
  />;
});

export default ReviewEmployee;
