import {DomainStore} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {auth, endpoints, types} from 'shared/core';
import {EmployeeCoverage} from 'stores/benefits';
import _ from 'lodash';
import EmployeeDetails from 'stores/employees/EmployeeDetails';

class MyCoverageContainerState {
  store = new DomainStore();
  match;
  history;

  @observable employeeCoverage;
  @observable currentEnrolment;
  @observable currentCoverage;
  @observable employee;

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    await this.store._compose([
      endpoints.BENEFITS.EMPLOYEE_COVERAGES,
      endpoints.EMPLOYEE_DETAILS.with(auth.employee.id)
    ]);
    this.employeeCoverage = new EmployeeCoverage(
      this.store._getSingle(types.EMPLOYEE_COVERAGE)
    );
    this.employee = new EmployeeDetails(
      this.store._getSingle(types.EMPLOYEE_DETAILS, {id: auth.employee.id})
    );

    this.initializeCurrentEnrolment();
  }

  @action initializeCurrentEnrolment() {
    this.currentEnrolment = _.head(this.employeeCoverage.employeeBenefitEnrollments);

    const defaultCoverage = _.head(this.currentClass.sortedGroupBenefitCoverages);
    this.currentCoverage = this.findSelectedCoverage() || defaultCoverage;
    if (this.currentCoverage) {
      this.history.push(`/my_coverages/${this.currentCoverage.coverageType}`);
    }
  }

  @action viewCoverage(coverage, benefitClass) {
    this.history.push(`/my_coverages/${coverage.coverageType}`);
    this.currentCoverage = coverage;
    this.currentEnrolment = _.find(
      this.employeeCoverage.employeeBenefitEnrollments,
      { benefitClass: { id: benefitClass.id } }
    );
  }

  @computed get currentClass() {
    return _.get(this.currentEnrolment, 'benefitClass');
  }

  @computed get currentDivision() {
    return _.get(this.currentClass, 'benefitDivision');
  }

  @computed get currentCarrier() {
    return _.get(this.currentDivision, 'companyInsuranceCarrier');
  }

  @computed get currentInsuranceNumber() {
    return _.find(
      this.employeeCoverage.employeeInsuranceNumbers, {
        companyInsuranceCarrier: { id: this.currentCarrier.id }
      }
    );
  }

  findSelectedCoverage() {
    if (!this.match.params.coverageType) return null;

    return _.find(this.currentClass.sortedGroupBenefitCoverages, (coverage) => {
      return coverage.coverageType === this.match.params.coverageType;
    });
  }
}

export default MyCoverageContainerState;
