import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Panel, Avatar, Checkbox, RadioButton, RadioButtonGroup} from 'components';
import {observer} from 'mobx-react';
import {fullName} from 'shared/tools';
import {t} from 'shared/core';

const ParticipantRow = ({reviewee}) => {
  const {employee} = reviewee;
  return (
    <label className='col col-12 p2 border-bottom'>
      <div className='clearfix table px1'>
        <div className='col-1 table-cell align-middle center'>
          <Checkbox checked={reviewee.checked} onChange={() => reviewee.checked = !reviewee.checked}/>
        </div>
        <div className='col-6 table-cell align-middle'>
          <div className='clearfix pl2'>
            <div className='table-cell align-middle pr1'>
              <Avatar employee={employee} size='sm'/>
            </div>
            <div className='table-cell align-middle pl1'>
              <div>{fullName(employee)}</div>
            </div>
          </div>
        </div>
        <div className='col-5 table-cell align-middle'>
          <div>{reviewee.departmentName}</div>
        </div>
      </div>
    </label>
  );
};

const EmployeeList = observer(({reviewer}) => {
  const {reviewees} = reviewer;
  return (
    <div className='clearfix border rounded height-250 mt1'>
      <div className='height-250 overflow-auto'>
        {reviewees.map(r => <ParticipantRow reviewee={r} key={r.employee.id} />)}
      </div>
    </div>
  );
});

const RevieweeSelector = observer(({reviewer}) => {
  return (
    <div className='border-bottom mb2 pb2'>
      <div className='my2'>
        <div className='table-cell align-middle pr2'>
          <Avatar employee={reviewer.employee}/>
        </div>
        <div className='table-cell align-middle'>
          <div className='h3'>{fullName(reviewer.employee)}</div>
          <div className='h6 waterloo medium'>
            <FormattedMessage id='performance.Check-In Leader'/>
          </div>
        </div>
      </div>
      <div className='my2 waterloo'>
        <FormattedMessage id='performance.WHO_SHOULD_HAVE_CHECKIN_WITH' values={{name: fullName(reviewer.employee)}}/>
      </div>
      <div className="mt2">
        <RadioButtonGroup name={`segmentation_rule${reviewer.employee.id}`} selectedValue={reviewer.rule} onChange={(name, value) => reviewer.rule = value}>
          <RadioButton value={'direct_report'} className='block py1'><FormattedMessage id='performance.DIRECT_REPORTS_OF' values={{name: fullName(reviewer.employee)}} /></RadioButton>
          <RadioButton value={'employee'} className='block py1'><FormattedMessage id='performance.Custom'/></RadioButton>
        </RadioButtonGroup>
      </div>
      {reviewer.rule === 'employee' && <EmployeeList reviewer={reviewer}/>}
    </div>
  );
});


const Reviewees = observer(({uiState}) => {
  const {cycle} = uiState;

  return (
    <div>
      <Panel.Header title={t('performance.Choose who should be meeting with each check-in leader')}/>
      {cycle.reviewers.map(r => <RevieweeSelector key={r.employee.id} reviewer={r}/>)}
    </div>
  );
});

export default Reviewees;

