import React from 'react';
import {FormattedMessage} from 'react-intl';
import Icon  from 'containers/performance/img/speedometer-icon@2x.png';
import {Button} from 'components';

const RevieweeDetailsEmptyState = ({reviewee, cycleId}) => {
  return (
    <div className='Container'>
      <div className='bg-white rounded box-shadow-1 p3 my3 center'>
        <div className='clearfix'>
          <div className='center'>
            <img width='100px' className='my3' src={Icon} role='presentation'/>
          </div>
          <div className='h1'>
            <FormattedMessage id='performance.REVIEWEE_HAS_NOT_SUBMITTED_A_CHECKIN' values={{name: reviewee.firstName}}/>
          </div>
          <div className='h3 mt2 max-width-3 px3 mx-auto'>
            <FormattedMessage id='performance.PAGE_WHERE_CHECKIN_SUBMITTED' values={{name: reviewee.firstName}}/>
          </div>
          <div className='mt3 mb4'>
            <Button size='lg' onClick={() => window.location.href = `/performance/cycles/${cycleId}/reviewees`}>
              <FormattedMessage id="performance.Got it"/>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevieweeDetailsEmptyState;

