import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Program',
  'Department',
  'Location',
  'Manager',
  'Gender',
  'Employee',
  'Employment Status',
  'Employment Type',
];

const TrainingResponsesReport = () => {
  return (
    <CompositeReport filters={filters}>
      <Report name={t('reports.TRAINING_RESPONSES_REPORT')}
              url='/reports/training_responses'>
      </Report>
    </CompositeReport>
  );
};

export default TrainingResponsesReport;
