import {DomainObject} from 'shared/store';
import {observable, computed, action} from 'mobx';
import {CompanyInsuranceCarrier} from 'stores/benefits';
import {AdminContact} from 'stores/users';
import {CompanyContact} from 'stores/pa_dashboard';
import {calendarDate} from 'shared/tools';
import AttachedDocument from 'stores/documents/AttachedDocument';
import _ from 'lodash';

class Company extends DomainObject {
  @observable name;
  @observable phoneNumber;
  @observable payrollEnabled;
  @observable managedPayroll;
  @observable syncedPayroll;
  @observable orgChartEnabled;
  @observable jazzHRApiKey;
  @observable greenhouseApiKey;
  @observable bankingEnabled;
  @observable sinEnabled;
  @observable ssoEnabled;
  @observable ssoProviderName;
  @observable mfaSmsEnabled;
  @observable mfaAuthenticatorEnabled;
  @observable companyInsuranceCarriers = [];
  @observable companyContacts = [];
  @observable adminContacts = [];
  @observable employeeAnnouncementsEnabled;
  @observable slackEnabled;

  @observable status;
  @observable nextRenewalDate;
  @observable allowsImpersonation = false;
  @observable enrolmentOnly;
  @observable enrolmentsCount;

  @observable maxUnconditionalEligibilityAge;
  @observable maxConditionalEligibilityAge;
  @observable maxQcConditionalEligibilityAge;

  @observable birthdayCelebrationVisibility;
  @observable startDateCelebrationVisibility;
  @observable anniversaryCelebrationVisibility;
  @observable staffCountVisibility;
  @observable peopleOffTodayVisibility;

  @observable logo;

  @observable broker;

  @action merge(other) {
    super.merge(other, {
      _dates: ['nextRenewalDate'],
      companyInsuranceCarriers: [CompanyInsuranceCarrier],
      logo: AttachedDocument,
      companyContacts: [CompanyContact],
      adminContacts: [AdminContact]
    });
  }

  @computed get nextRenewalDateView() {
    return calendarDate(this.nextRenewalDate);
  }

  @computed get awaitingLaunch() {
    return this.status === 'awaiting_launch';
  }

  @computed get logoUrl() {
    return _.get(this, 'links.logo');
  }
}

export default Company;
