import {action, observable} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, types, t} from 'shared/core';
import {Role, ExternalUser} from 'stores/users';
import {successAlert} from 'shared/tools';
import ExternalUserViewModel from './ExternalUserViewModel';
import _ from 'lodash';

class UsersPageState {
  pendingInvitationsInteractiveAgent;
  history;
  store = new DomainStore();

  @observable addModalOpen = false;
  @observable errors = {};
  @observable userViewModel;
  @observable roles = [];

  @action async load() {
    await this.store._compose([
      endpoints.ROLES
    ]);

    this.roles = _.chain(this.store._getAll(types.ROLE))
      .map(r => new Role(r))
      .filter(r => r.isAdmin || r.isCustom)
      .value();
  }

  async inviteUser(user) {
    const {model, errors} = await this.store.post(endpoints.ADMIN_INVITATIONS, types.ADMIN_INVITATION, user);

    if (model) {
      successAlert(t('company_settings.external_users.Invitation sent successfully'));
      await this.refreshPendingInvitations();
      this.cancelAddModal();
    }

    this.errors = errors;
  }

  async updateUser(user) {
    const {model, errors} = await this.store.patch(user); 

    if (model) {
      successAlert(t('company_settings.external_users.Successfully saved'));
      this.cancelAddModal();
    }

    this.errors = errors;
  }

  @action addUser() {
    this.userViewModel = new ExternalUserViewModel(
      new ExternalUser(),
      {
        allRoles: this.roles
      }
    );
    this.errors = {};
    this.addModalOpen = true;
  }

  @action async saveExternalUser() {
    if (this.userViewModel.user.isNew) {
      this.inviteUser(this.userViewModel.user);
    } else {
      this.updateUser(this.userViewModel.user);
    }
  }

  @action cancelAddModal() {
    this.addModalOpen = false;
  }

  receiveProps({history}) {
    this.history = history;
  }

  async refreshPendingInvitations() {
    if (this.history.location.pathname === '/users/pending') {
      await this.pendingInvitationsInteractiveAgent.refresh();
    } else {
      this.history.push('/users/pending');
      return null;
    }
  }

  setPendingInvitationsInteractiveAgent(agent) {
    this.pendingInvitationsInteractiveAgent = agent;
  }
}

export default UsersPageState;
