import React from 'react';
import {FormattedMessage} from 'react-intl';
import Dropzone from 'react-dropzone';
import {observer} from 'mobx-react';

const baseStyle = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  left: '0',
  top: '0',
  backgroundColor: '#fafafa',
  textAlign: 'center',
  color: '#1776F5',
  borderWidth: '1px',
  borderStyle: 'dashed',
  borderColor: '#C9CFD1',
  transition: 'border .24s ease-in-out'
};

const acceptStyle = {
  ...baseStyle,
  ...{borderColor: '#1776F5'}
};

const rejectStyle = {
  ...baseStyle,
  ...{borderColor: '#ff1744'}
};

const DragToUploadToCollage = () => {
  return (
    <div>
      <i style={{'fontSize': '30px', 'display': 'block'}} className='material-icons dodger'>{'cloud_upload'}</i>
      <span className='center top-0 bottom-0 h3 dodger'>
        <FormattedMessage id='components.dropzone.Drop to upload to Collage'/>
      </span>
    </div>
  );
};

const InvalidFileTypeWarning = () => {
  return (
    <div>
      <span className='center top-0 bottom-0 h3 scarlet'>
        <FormattedMessage id='components.dropzone.Invalid file type'/>
      </span>
    </div>
  );
};

const DisabledWarning = () => {
  return (
    <div>
      <span className='center top-0 bottom-0 h3 submarine'>
        <FormattedMessage id='components.dropzone.Document uploading is disabled'/>
      </span>
    </div>
  );
};

const DragAndDropToUpload = ({children}) => {
  return (
    <div>
      <i className='material-icons submarine align-bottom mr1'>{'cloud_upload'}</i>
      <span className='jumbo center'>
        {children ?
          children :
          <FormattedMessage id='components.dropzone.DROP_DOCUMENT_HERE_MAX_FILE_SIZE' values={{maxFileSizeText: '50 MB'}}/>
        }
      </span>
    </div>
  );
};

class CollageDropzone extends React.Component {
  dropzone;

  onOpenClick = () => {
    this.dropzone.open();
  };

  onDrop = (files) => {
    const {onDrop} = this.props;
    onDrop(files);
  };

  setRef = (node) => {
    this.dropzone = node;
  };

  render() {
    const {onOpenClick, onDrop, setRef} = this;
    const {accept, multiple, children, disabled} = this.props;

    return (
      <div className='relative height-125 clickable center' onClick={onOpenClick}>
        <Dropzone
          ref={(node) => setRef(node)}
          onDrop={files => onDrop(files)}
          multiple={multiple}
          style={baseStyle}
          acceptStyle={acceptStyle}
          rejectStyle={rejectStyle}
          accept={accept}
          disabled={disabled}
        >
          {({isDragActive, isDragAccept, isDragReject}) => {
            return(
              <div className='px2 height-125 flex align-items-center justify-content-center'>
                {disabled && <DisabledWarning/>}
                {!isDragActive && !disabled && <DragAndDropToUpload children={children}/>}
                {isDragActive && isDragReject && !disabled &&  <InvalidFileTypeWarning/>}
                {isDragActive && isDragAccept && !isDragReject && !disabled && <DragToUploadToCollage/>}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  }
}

CollageDropzone.defaultProps = {
  disabled: false
};

export default observer(CollageDropzone);
