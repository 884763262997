import React, {useEffect} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {Avatar, DropdownSelect, FormError, Button, Input, AutosaveStatus, LexicalEditor} from 'components';
import {observer} from 'mobx-react';
import {t, auth} from 'shared/core';
import {UploadFiles} from 'components';
import acceptedAttachmentTypes from '../../state/acceptedAttachmentTypes';
import _ from 'lodash';
import $ from 'jquery';

const NewEmail = injectIntl(observer(({detailsState, sectionViewModel}) => {
  useEffect( () => {
    if(sectionViewModel.showEmailInput) {
      $('html, body').animate({scrollTop: $('.js-email-textarea').offset().top - 200}, 500);
      $('.js-email-textarea').focus();
    }
  }, [sectionViewModel.showEmailInput]);

  if (!sectionViewModel.showEmailInput) return null;

  const {emailTemplates} = detailsState.hiringFunnelState;
  const {newEmail, templateCounter} = detailsState;

  const errors = detailsState.newEmailErrors;

  return (
    <div className='clearfix border-top pt3 px3'>
      {!_.isEmpty(emailTemplates) && <div className="mb3 right-align">
        <DropdownSelect selected={t('recruiting.hiring_funnel.Use email template')} buttonTrait='default' buttonSize='sm'>
          {emailTemplates.map(template => <DropdownSelect.Option key={template.id} text={template.name} onClick={() => detailsState.selectEmailTemplate(template)}/>)}
        </DropdownSelect>
      </div>}
      <div className='col col-1 pr1'>
        <Avatar employee={auth.user} size='sm' className='left'/>
      </div>
      <div className='col col-11 pl1'>
        <Input
          placeholder='recruiting.hiring_funnel.Type your subject here...'
          className='full-width mb2 js-email-textarea'
          value={newEmail.subject}
          errorMessage={errors.subject}
          onChange={e => detailsState.updateEmail({subject: e.target.value})}
        />
        <LexicalEditor
          placeholder={'recruiting.hiring_funnel.Type your email here...'}
          onChange={body => detailsState.updateEmail({body: body.html, lexicalState: body.lexicalState})}
          initialEditorState={newEmail.lexicalState}
          initialHTML={newEmail.body}
          key={templateCounter}
          errorMessage={errors.body}
        />
        <div className='mt2'>
          <FormattedMessage id='recruiting.hiring_funnel.Add Attachments'/>
        </div>
        <UploadFiles
          modelType='ats_timeline_entries_email'
          defaultFiles={newEmail.attachments}
          onChange={files => detailsState.updateEmail({attachments: files})}
          onUploadStarted={() => detailsState.onUploadStarted()}
          onUploadFinished={() => detailsState.onUploadFinished()}
          accept={acceptedAttachmentTypes}
          isUploading={detailsState.isUploading}
        >
          <FormattedMessage
            id='components.dropzone.DROP_DOCUMENT_HERE_CUMULATIVE_MAX_FILE_SIZE'
            values={{maxFileSizeText: '15 MB'}}
          />
        </UploadFiles>
        <FormError message={errors.base}/>
        <div className='flex align-items-center my2 right'>
          <AutosaveStatus autosaver={newEmail.autosaver}/>
          <Button size='sm' className='px2' trait='default' onClick={() => detailsState.cancelInput()}>
            <FormattedMessage id='recruiting.hiring_funnel.Cancel'/>
          </Button>
          <Button size='sm' disabled={detailsState.isUploading} onClick={() => detailsState.addEmail()}>
            <FormattedMessage id='recruiting.hiring_funnel.Send Email'/>
          </Button>
        </div>
      </div>
    </div>
  );
}));

export default NewEmail;
