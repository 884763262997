import React from 'react';
import {observer} from 'mobx-react';
import EmployeeNameWithAvatar from 'components/EmployeeNameWithAvatar';
import {statusTagColour} from 'employees/tools';
import {t} from 'shared/core';

const EmployeeLink = observer(({model, href, to}) => {
  const employee = model.employee || model;
  const tags = (employee.statusTags || []).map(status => {
    return {
      content: t(`employees.profile.${status}`),
      colour: statusTagColour(status)
    };
  });

  return <EmployeeNameWithAvatar href={href} to={to} employee={employee} tags={tags} />;
});

export default EmployeeLink;
