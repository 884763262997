import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {t, withDisplay, withState} from 'shared/core';
import {Layout, Panel} from 'components';
import {CalendarDirectoryState} from '../state';
import SubNav from 'company_settings/SubNav';
import LegacyCalendarList from './LegacyCalendarList';
import SegmentCalendarList from './SegmentCalendarList';
import CalendarSettingsPanel from './CalendarSettingsPanel';

const CalendarDirectoryContainer = observer(({uiState}) => {
  return (
    <Layout>
      <Panel.Stack loose>
        <CalendarSettingsPanel/>
        <LegacyCalendarList uiState={uiState}/>
        <SegmentCalendarList uiState={uiState}/>
      </Panel.Stack>
    </Layout>
  );
});

export default withDisplay(withState(CalendarDirectoryContainer, CalendarDirectoryState), () => <SubNav title={t('company_settings.subnav.Calendars')}/>);
