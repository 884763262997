import {action, computed, observable} from 'mobx';
import Note from 'stores/notes/Note';
import _ from 'lodash';
import {DomainStore} from 'shared/store';
import {types, endpoints} from 'shared/core';

class EmployeeNoteState {
  store = new DomainStore();
  parentState;
  employeeId;

  @observable notes = [];
  @observable deleteModalOpen = false;
  @observable editModalOpen = false;
  @observable deletingNote = new Note();
  @observable editingNote = new Note();
  @observable newNote = new Note();
  @observable errors = {};

  receiveProps({parentState}) {
    this.parentState = parentState;
    this.employeeId = parentState.match.params.id;
  }

  @action async load() {
    await this.store._compose([
      endpoints.NOTES.with(this.employeeId)
    ]);

    this.notes = this.store._getAll(types.NOTES, Note);
  }

  @action openDeleteModal(note) {
    this.deleteModalOpen = true;
    this.deletingNote = note;
  }

  @action openEditModal(note) {
    this.editModalOpen = true;
    this.editingNote = new Note(note);
    this.errors = {};
  }

  @action resetModals() {
    this.deleteModalOpen = false;
    this.editModalOpen = false;
    this.editingNote = null;
    this.deletingNote = null;
  }

  @action async createNote() {
    const {model, errors} = await this.store.post(
      endpoints.NOTES.with(this.employeeId),
      types.NOTES,
      this.newNote
    );
    this.errors = errors;

    if (model) {
      const note = new Note(model);
      this.notes.push(note);
      this.newNote = new Note();
    }
  }

  @action async updateNote() {
    const {model, errors} = await this.store.patch(this.editingNote);
    this.errors = errors;

    if (model) {
      const note = _.find(this.notes, { id: model.id });
      note.update(model);
      this.resetModals();
    }
  }

  @action async deleteNote() {
    await this.store.destroy(this.deletingNote);
    this.resetModals();
    await this.load();
  }

  @computed get sortedNotes() {
    return _.orderBy(this.notes, ['createdAt'], ['desc']);
  }
}

export default EmployeeNoteState;
