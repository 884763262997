import React from 'react';
import {Modal, ModalButtons} from 'components/modals';
import {FormLayout, Input, SegmentSelector, FormError} from 'components';
import {observer} from 'mobx-react';

const EditEmployeeGroupModal = observer(({uiState}) => {
  const {
    editEmployeeGroupModalOpen,
    editingEmployeeGroup,
    errors
  } = uiState;

  return (
    <Modal
      title={editingEmployeeGroup.isNew
        ? 'company_settings.employee_groups.edit.Add Employee Group'
        : 'company_settings.employee_groups.edit.Edit Employee Group'
      }
      isOpen={editEmployeeGroupModalOpen}
      onHide={() => uiState.closeEditEmployeeGroupModal()}
      size='md'
    >
      <FormLayout>
        <Input
          label='company_settings.employee_groups.edit.Name'
          value={editingEmployeeGroup.name}
          onChange={e => editingEmployeeGroup.name = e.target.value}
          placeholder='company_settings.employee_groups.edit.e.g. Full-time Employees'
          errorMessage={errors.name}
        />
        <SegmentSelector
          segment={editingEmployeeGroup.segment}
          onChange={segment => editingEmployeeGroup.segment = segment}
        />
        <FormError message={errors.base} />
      </FormLayout>
      <ModalButtons
        onSave={() => uiState.saveEmployeeGroup()}
        onCancel={() => uiState.closeEditEmployeeGroupModal()}
      />
    </Modal>
  );
});

export default EditEmployeeGroupModal;
