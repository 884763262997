import React from 'react';
import Spinner from './spinners/Spinner';

class AbsoluteRedirect extends React.Component {
  componentDidMount() {
    window.location.href = this.props.path;
  }

  render() {
    return <Spinner/>;
  }
}

AbsoluteRedirect.defaultProps = {
  path: '/404'
};

export default AbsoluteRedirect;
