import React from 'react';
import CompositeReportState from 'reports/state/CompositeReportState';
import {observer} from 'mobx-react';
import ReportFilter from './ReportFilter';

@observer class CompositeReport extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    const {uiState} = this.props;

    uiState.applyFilters();
  }

  render() {
    const {children, uiState} = this.props;

    return (
      <div>
        <ReportFilter uiState={uiState}/>
        {React.Children.map(children, child =>
          child ? React.cloneElement(child, {uiState}) : null)}
      </div>
    );
  }
}

export default ({children, filters, dateFilter, dateFilterFormat, defaultStartDate, defaultEndDate, filterRows, disableFilterEditing}) => {
  const uiState = new CompositeReportState(filters, dateFilter, dateFilterFormat, defaultStartDate, defaultEndDate, filterRows, disableFilterEditing);

  return (
    <CompositeReport uiState={uiState}>
      {children}
    </CompositeReport>
  );
};
