import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {ObjectSelect2} from 'components';
import {FormattedMessage} from 'react-intl';

const ShareReportModal = observer(({uiState}) => {
  const {selectedReport} = uiState;
  if (!selectedReport) return null;

  return (
    <Modal
      size='md'
      isOpen={uiState.shareReportModalOpen}
      onHide={() => uiState.closeShareReportModal()}
      title='reports.custom.Share Report'
    >
      <div className='jumbo mb2'>
        <FormattedMessage id='reports.custom.share_explainer'/>
      </div>
      <ObjectSelect2
        value={selectedReport.shares}
        by='userId'
        label='reports.custom.Users with view access'
        items={uiState.availableShares}
        onAdd={value => selectedReport.addShare(value)}
        onRemove={value => selectedReport.removeShare(value)}
        placeholder='reports.custom.Share with user' multiple={true}
      />
      <ModalButtons
        saveCaption='reports.custom.Share'
        onSave={() => uiState.shareReport()}
        onCancel={() => uiState.closeShareReportModal()}
      />
    </Modal>
  );
});

export default ShareReportModal;
