import {withState} from 'shared/core';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Input} from 'components';
import DeleteExplainerState from '../state/DeleteExplainerState';
import React from 'react';

const ExplainerValue = observer(({explainerValue}) => {
  if (!explainerValue.values.bolded_part.values.count) {
    return null;
  }

  return (
    <li key={explainerValue.id}>
      <FormattedMessage id={`employees.profile.delete_employee.explainer.${explainerValue.id}`}
                        values={{
                          name: explainerValue.values.name,
                          bolded_part: <span className={explainerValue.values.bolded_part.values.count ? 'medium scarlet' : ''}>
                                      <FormattedMessage id={explainerValue.values.bolded_part.id} values={explainerValue.values.bolded_part.values}/>
                                    </span>
                        }}/>
    </li>
  );
});

const DeleteExplainer = observer(({uiState, parentState}) => {
  const {deleteExplainerAttributes, deleteExplainerRelatedModels, hasRelatedModels, employee} = uiState;

  return (
    <div>
      <div className='mb2'>
        <FormattedMessage id={`employees.profile.delete_employee.explainer.${deleteExplainerAttributes[0].id}`}
                          values={deleteExplainerAttributes[0].values}/>
      </div>
      <div className='mb2'>
        <FormattedMessage id={`employees.profile.delete_employee.explainer.${deleteExplainerAttributes[1].id}`}
                          values={deleteExplainerAttributes[1].values}/>
      </div>
      <ul className='ml3'>
        {deleteExplainerAttributes.slice(2).map(explainerValue => (
          <ExplainerValue explainerValue={explainerValue}/>
        ))}
      </ul>
      <div className='my2'>
        <FormattedMessage id='employees.profile.delete_employee.explainer.RELATED_MODELS' values={{name: employee.name}}/>
        {hasRelatedModels && <FormattedMessage id='employees.profile.delete_employee.explainer.. This includes but is not limited to:'/>}
      </div>
      <ul className='ml3'>
        {deleteExplainerRelatedModels.map(explainerValue => (
          <ExplainerValue explainerValue={explainerValue}/>
        ))}
      </ul>
      <div className='mt2'>
        <Input
          label="employees.profile.delete_employee.Confirm deletion by typing the employee's first and last names"
          onChange={e => parentState.deleteConfirmationName = e.target.value}
          value={parentState.deleteConfirmationName}
          onPaste={e => {
            e.preventDefault();
            return false;
          }}
        />
      </div>
    </div>
  );
});

export default withState(DeleteExplainer, DeleteExplainerState);
