import React from 'react';
import {observer} from 'mobx-react';
import Celebration from './Celebration';
import {FormattedMessage} from 'react-intl';

const FirstDay = observer(({firstDay, Emoji}) => {
  return (
    <Celebration
      employee={firstDay.employee}
      date={firstDay.publishedAt}
    >
      <FormattedMessage
        id='hr_dashboard.feed.celebrations.FIRST_DAY_MESSAGE'
        values={{
          link: <a href={`/employees/${firstDay.employee.id}`}>
                  {firstDay.employee.name}
                </a>
        }}
      />
      {Emoji && <span className='ml1'>
        <Emoji emoji={'wave'} size={22}/>
      </span>}
    </Celebration>
  );
});

export default FirstDay;
