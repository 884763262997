import React from 'react';
import _ from 'lodash';
import {withState} from 'shared/core';
import {DragAndDrop} from 'components';
import {observer} from 'mobx-react';
import OnboardingDocumentState from 'employees/hiring/state/OnboardingDocumentState';
import AddOnboardingDocumentModal from './documents/AddOnboardingDocumentModal';
import OnboardingDocumentEditModal from './documents/OnboardingDocumentEditModal';
import ExistingCompanyDocumentsModal from './documents/ExistingCompanyDocumentsModal';

const LOCALIZATION = {
  addModel: 'employees.hiring.documents.+ Add documents'
};

const DraggableFieldContent = observer(({model}) => {
  return (
    <div>{model.name}</div>
  );
});

const OnboardingDocumentsContainer = observer(({uiState}) => {
  const {selectedDocuments, errors} = uiState;

  return (
    <div>
      <DragAndDrop localization={LOCALIZATION}
        models={selectedDocuments}
        component={DraggableFieldContent} 
        onMove={() => uiState.emitOnChange()}
        onRemove={(document) => uiState.removeSelectedDocument(document)}
        onAdd={() => uiState.openExistingDocumentsModal()}
        errors={errors}
        errorPath={(index => `onboardingDocuments[${index}].originalFile`)}/>
      <ExistingCompanyDocumentsModal uiState={uiState} />
      <AddOnboardingDocumentModal uiState={uiState} />
      <OnboardingDocumentEditModal uiState={uiState} />
    </div>
  );
});

export default withState(OnboardingDocumentsContainer, OnboardingDocumentState);
