import React from 'react';
import {observer} from 'mobx-react';
import {Name, OvertimeRules, PaySchedule, Employees, Assignee, Review} from './components';

const Steps = observer(({step, uiState}) => {
  switch (step) {
    case 'name':
      return <Name uiState={uiState}/>;
    case 'pay_schedule':
      return <PaySchedule uiState={uiState}/>;
    case 'overtime':
      return <OvertimeRules uiState={uiState}/>;
    case 'employees':
      return <Employees uiState={uiState}/>;
    case 'assignee':
      return <Assignee uiState={uiState}/>;
    case 'review':
      return <Review uiState={uiState}/>;
    default:
      throw new Error(`Location ${step} is not supported.`);
  }
});

export default Steps;
