import React from 'react';
import PropTypes from 'prop-types';
import FormLayout from './FormLayout';

const FormLayoutGroup = ({children, secondary}) => {
  const childrenCount = React.Children.count(children);
  if (childrenCount > 2) {
    throw new Error(`Only two inputs are allowed per row in FormLayout. You have defined ${childrenCount} inputs.`);
  }
  let validChildrenCount = 0;
  React.Children.forEach(children, c => { if (c) validChildrenCount += 1; });
  const childWidth = secondary ? 6 : 12 / validChildrenCount;

  return (
    <div className='FormLayoutGroup clearfix'>
      {React.Children.map(children, c => (
        <div className={`FormLayoutGroupItem col col-${childWidth}`}>{c}</div>
      ))}
    </div>
  );
};

FormLayout.propTypes = {
  secondary: PropTypes.bool
};

export default FormLayoutGroup;
