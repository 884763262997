import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import Signature from './Signature';

class SignatureStore extends DomainStore {
  async loadSignatures() {
    await this._compose([
      endpoints.COMPANY_SIGNATURES
    ]);

    return this._getAll(types.COMPANY_SIGNATURE, Signature);
  }

  async loadSignature(id) {
    await this._compose([
      endpoints.COMPANY_SIGNATURE.with(id)
    ]);

    return this._getSingle(types.COMPANY_SIGNATURE, Signature);
  }

  async postSignature(signature) {
    return await this.post(
      endpoints.COMPANY_SIGNATURES,
      types.COMPANY_SIGNATURE,
      signature
    );
  }
}

const singleton = new SignatureStore();

export default singleton;
