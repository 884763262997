import _ from 'lodash';
import {observable, action} from 'mobx';
import {api, endpoints} from 'shared/core';

class HrUpgradeLearnMoreState {
  @observable interestedInHrModalOpen = false;

  @action openInterestedInHrModal() {
    this.interestedInHrModalOpen = true;
  }

  @action closeInterestedInHrModal() {
    this.interestedInHrModalOpen = false;
  }

  @action async submit() {
    await api.post(endpoints.HR.INTERESTED);
    this.openInterestedInHrModal();
  }
}

export default HrUpgradeLearnMoreState;
