import MultiSelectFilterRowViewModel from './MultiSelectFilterRowViewModel';
import StaticDataSource from './StaticDataSource';
import PAY_RATE_UNIT_TYPES from 'stores/employees/payRateUnitTypes';

function createPayRateUnitViewModel(model) {
  return new MultiSelectFilterRowViewModel({
    define: {
      category: 'Pay Rate Unit',
      attribute: 'pay_rate_unit',
      operators: ['is', 'is_not']
    },
    dataSource: new StaticDataSource(PAY_RATE_UNIT_TYPES.map(t => t.id)),
    translatable: true,
    mapItem: (payRateUnitType) => ({
      id: payRateUnitType,
      name: payRateUnitType
    })
  });
}

export default createPayRateUnitViewModel;
