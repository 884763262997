import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {endpoints, types} from 'shared/core';
import {SurveyResponse} from 'stores/surveys';

class ResponseContainerState {
  store = new DomainStore();
  match;

  @observable survey;
  @observable response;

  receiveProps({match, survey}) {
    this.match = match;
    this.survey = survey;
  }

  @action async load() {
    await this.store._compose([
      endpoints.SURVEYS.RESPONSE.withEmployeeId(this.survey.id, this.match.params.employeeId)
    ]);

    this.response = new SurveyResponse(
      this.store._getSingle(types.SURVEYS.RESPONSE)
    );
  }
}

export default ResponseContainerState;
