import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {Table, Button, Checkbox} from 'components';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const CompanyDocumentRow = observer(({model}) => {
  return <Checkbox checked={model.checked} onChange={(e) => model.checked = e.checked} />;
});

const CompanyDocumentEditLink = observer(({model}) => {
  const {companyDocument} = model;
  if (companyDocument.documentType === 'static_document') return null;

  const previewUrl = _.get(companyDocument, 'originalFile.previewUrl');
  if (!previewUrl) return null;

  return (
    <Button size='sm' className='right' onClick={() => model.uiState.openAnnotatorModal(companyDocument)}>
      <FormattedMessage id='employees.hiring.documents.Edit'/>
    </Button>
  );
});

const COLUMNS = [
  {
    header: '',
    component: CompanyDocumentRow,
    width: 1,
  },
  {
    header: 'employees.hiring.documents.Name',
    attribute: 'companyDocument.name',
    width: 5
  },
  {
    header: 'employees.hiring.documents.Document Type',
    attribute: 'companyDocument.typeView',
    width: 4
  },
  {
    component: CompanyDocumentEditLink,
    width: 2
  }
];

const LOCALIZATION = {
  addModel: 'employees.hiring.documents.+ Add a document',
  emptyState: 'employees.hiring.documents.No documents to display'
};

const ExistingCompanyDocumentsModal = observer(({uiState}) => {
  return (
    <Modal 
      size='md'
      isOpen={uiState.existingDocumentModalOpen} 
      onHide={() => uiState.closeExistingDocumentsModal()}
      title='employees.hiring.documents.Select documents for employee'
    >
      <Table 
        showLinks={false}
        indexBy='companyDocument.id'
        models={uiState.unselectedDocuments}
        columns={COLUMNS}
        localization={LOCALIZATION}
        onAdd={() => uiState.openAddDocumentModal()} 
      />
      <ModalButtons 
        onSave={() => uiState.saveSelectedDocuments()}
        onCancel={() => uiState.closeExistingDocumentsModal()}
      />
    </Modal>
  );
});

export default ExistingCompanyDocumentsModal;
