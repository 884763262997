import React from 'react';
import {observer} from 'mobx-react';
import {Button} from 'components';
import {FormattedMessage} from 'react-intl';
import {redirect} from 'shared/tools';

const PerformanceSurveyTask = observer(({uiState}) => {
  const {task, isAssignee} = uiState;

  return (
    <div>
      <div className='mb3 jumbo'>
        <FormattedMessage id='inbox.survey_explanation'/>
      </div>
      {isAssignee && !task.completed && <Button onClick={async () => { return redirect(`/surveys/write/${task.relatedTo.id}`); }}>
        <FormattedMessage id='inbox.View Survey'/>
      </Button>}
    </div>
  );
});

export default PerformanceSurveyTask;
