import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {OrgChartContainerState} from '../state';
import OrgChart from './orgchart/org-chart';
import {Link} from 'react-router-dom';
import CloseIcon from 'img/close-icon@2x.png';
import {Select2, Button} from 'components';

const CloseButton = observer(() => {
  return (
    <Link className='OrgChartCloseButton' aria-label='Close' to='/directory'>
      <img src={CloseIcon} className='img-hover' alt='close' width='18px'/>
    </Link>
  );
});

const RootSelector = observer(({uiState}) => {
  return (
    <div style={{width: '200px'}} className='inline-block'>
      <Select2
        value={uiState.rootId}
        onChange={e => uiState.changeRootId(e.target.value)}
        searchable
      >
        {uiState.allEmployees.map(e => <option key={e.id} value={e.id}>{e.name}</option>)}
      </Select2>
    </div>
  );
});

const Toolbar = observer(({uiState}) => {
  return (
    <div className='border-bottom p2 bg-white'>
      <RootSelector uiState={uiState}/>
      <CloseButton/>
    </div>
  );
});

const Controls = observer(({uiState}) => {
  return (
    <div className='bottom-0 fixed m3 z-index-3'>
      <Button trait='default' className='Btn--icon Btn--circle' onClick={() => uiState.resetChart()}>
        <i className='h2 material-icons'>{'refresh'}</i>
      </Button>
      <Button trait='default' className='Btn--icon Btn--circle ml1' onClick={() => uiState.downloadPdf()}>
        <i className='h2 material-icons'>{'file_download'}</i>
      </Button>
      <Button trait='default' className='Btn--icon Btn--circle mx1' onClick={() => uiState.zoomIn()}>
        <i className='h2 material-icons'>{'add'}</i>
      </Button>
      <Button trait='default' className='Btn--icon Btn--circle' onClick={() => uiState.zoomOut()}>
        <i className='h2 material-icons'>{'remove'}</i>
      </Button>
    </div>
  );
});

const OrgChartContainer = observer(({uiState}) => {
  const {employees, rootId} = uiState;
  return (
    <div className='OrgChartOverlay'>
      <Toolbar uiState={uiState}/>
      <OrgChart
        rootNodeID={rootId}
        employees={employees}
        registerUpdateZoomFn={fn => uiState.registerUpdateZoomFn(fn)}
      />
      <Controls uiState={uiState}/>
    </div>
  );
});

export default withState(OrgChartContainer, OrgChartContainerState);
