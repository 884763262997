import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class AdminContact extends DomainObject {
  @observable name;
  @observable email;
  @observable avatarColour;
  @observable avatarInitials;
}

export default AdminContact;

