import {t} from 'shared/core';
import exporting from './exporting';

function nineboxOptions(selectedQuestion1, selectedQuestion2, nineboxResult, Highcharts) {
  const sliceCounts = {};

  // The purpose of this is to create a series of svg render functions that will render a
  // slice of a circle, sized and positioned according to how many points there are with
  // the same value (total slices in circle) and which point is being rendered (first,
  // second, etc.)
  Object.keys(nineboxResult.trend.sliceCounts).forEach(key => {
    const totalSliceCount = nineboxResult.trend.sliceCounts[key];
    if (totalSliceCount <= 1) {
      return;
    }

    for (let sliceNumber = 1; sliceNumber <= totalSliceCount; sliceNumber++) {
      Highcharts.SVGRenderer.prototype.symbols[`pie${sliceNumber}${totalSliceCount}`] = function (x, y, diameter) {

        const r = diameter / 2;
        const startAngle = ((sliceNumber - 1) / totalSliceCount) * 2 * Math.PI;
        const endAngle = (sliceNumber / totalSliceCount) * 2 * Math.PI;
        return ['M', x + r, y + r, 'L', x + Math.cos(startAngle) * r + r, y + Math.sin(startAngle) * r + r, 'A', r, r, 0, 0, 1, x + Math.cos(endAngle) * r + r, y + Math.sin(endAngle) * r + r, 'L', x + r, y + r, 'z'];
      };
    }
  });

  return {
    title: null,
    colors: [
      '#00B7B3',
      '#217AF2',
      '#1C17B8',
      '#9A3BE8',
      '#D61CBB',
      '#F64A6C',
      '#FD6C21',
      '#F4AC29',
      '#80D137',
      '#159488',
      '#1FC1B6',
      '#1876F2',
      '#1C16B6',
      '#9836E7',
      '#C71AAD',
      '#F52952',
      '#FD5D0D',
      '#F3A616',
      '#75C82D',
      '#148F83'
    ],
    legend: {
      itemStyle: {
        fontSize: '14px',
        fontFamily: 'GT Walsheim, sans-serif',
        color: '#717B89',
        fontWeight: '200'
      },
      symbolHeight: 10,
      symbolWidth: 10,
      symbolPadding: 10,
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      x: 0,
      y: 0,
      margin: 0,
      itemWidth: 0,
      maxHeight: 90,
      itemMarginTop: 10,
      itemMarginBottom: 0
    },
    chart: {
      height: '100%',
      colorCount: 20
    },
    xAxis: {
      title: {
        text: selectedQuestion1.name
      },
      plotLines: [
        {
          width: 1,
          color: '#DDDDDD',
          dashStyle: 'ShortDot',
          value: 2.3
        },
        {
          width: 1,
          color: '#DDDDDD',
          dashStyle: 'ShortDot',
          value: 3.7
        }
      ],
      min: 0.5,
      max: 5.5,
      minPadding: 0,
      maxPadding: 0,
      allowDecimals: false,
      gridLineWidth: 0,
      tickWidth: 0
    },
    yAxis: {
      title: {
        text: selectedQuestion2.name
      },
      plotLines: [
        {
          width: 1,
          color: '#DDDDDD',
          dashStyle: 'ShortDot',
          value: 2.3
        },
        {
          width: 1,
          color: '#DDDDDD',
          dashStyle: 'ShortDot',
          value: 3.7
        }
      ],
      startOnTick: false,
      endOnTick: false,
      min: 0.5,
      max: 5.5,
      allowDecimals: false,
      minPadding: 0,
      maxPadding: 0,
      gridLineWidth: 0,
      lineWidth: 1,
      tickWidth: 0,
      tickInterval: 1
    },
    tooltip: {
      useHTML: true,
      hideDelay: 200,
      formatter: function () {

        const employeeList = nineboxResult.trend.data.filter(point => point.x === this.point.x && point.y === this.point.y);

        let tooltip = `<span style='color: ${this.point.color}'>\u25CF</span> `;

        if (employeeList.length > 1) {
          tooltip += `<span>${t('performance_reviews.view.cycle_results.Scored by the following employees: ')}</span>`;
          tooltip += '<div style="padding-top: 8px">';
          employeeList.forEach(employee => tooltip += `<div>${employee.name}</div>`);
          tooltip += '</div>';
        } else {
          tooltip += this.point.name;
        }

        tooltip += '<div style="padding-top: 8px">';
        tooltip += `<div>${this.series.xAxis.axisTitle.textStr}: <strong>${this.point.x} (${this.point.custom.questionOption1})</strong></div>`;
        tooltip += `<div>${this.series.yAxis.axisTitle.textStr}: <strong>${this.point.y} (${this.point.custom.questionOption2})</strong></div>`;
        tooltip += '</div>';

        return tooltip;
      }
    },
    plotOptions: {
      scatter: {
        states: {
          hover: {
            enabled: false
          }
        },
        marker: {
          radius: 12,
          symbol: 'circle'
        },
        events: {
          legendItemClick: () => false
        }
      }
    },
    credits: {
      enabled: false
    },
    series: nineboxResult.trend.data.map(data => {
      const sliceCountIndex = `${data.x}${data.y}`;
      if (!(sliceCountIndex in sliceCounts)) {
        sliceCounts[sliceCountIndex] = 0;
      }

      sliceCounts[sliceCountIndex] += 1;

      return {
        name: data.name,
        type: 'scatter',
        data: [
          {
            ...data,
            marker: {
              symbol: `pie${sliceCounts[sliceCountIndex]}${nineboxResult.trend.sliceCounts[sliceCountIndex]}`,
              lineWidth: 0
            }
          }
        ]
      };
    }),
    exporting
  };
}

export default nineboxOptions;
