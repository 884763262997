import {observable, action, computed} from 'mobx';
import {DomainObject} from 'shared/store';
import OfferTemplate from './OfferTemplate';
import {AttachedDocument} from '../documents';
import {Employee} from '../employees';

class Offer extends DomainObject {
  @observable firstName;
  @observable lastName;
  @observable fields = [];
  @observable expiresAt;
  @observable sentAt;
  @observable email;
  @observable offerTemplate;
  @observable renderedContent;
  @observable attachments;
  @observable employee;
  @observable acceptedAt;
  @observable revokedAt;
  @observable hiredAt;
  @observable revokeReason;
  @observable viewStatus;
  @observable viewStatusUpdatedAt;
  @observable candidateId;
  @observable isExpired;
  @observable isAccepted;
  @observable isRevoked;

  @action merge(other) {
    super.merge(other, {
      _dates: ['expiresAt', 'acceptedAt', 'revokedAt', 'hiredAt'],
      offerTemplate: OfferTemplate,
      attachments: [AttachedDocument],
      employee: Employee,
    });
  }

  @computed get name() {
    return `${this.firstName} ${this.lastName}`;
  }
}

export default Offer;
