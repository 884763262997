import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {DependantFieldsState} from '../state';
import DependantsEmptyState from './DependantsEmptyState';
import DependantList from './DependantList';
import AddDependantLink from './AddDependantLink';

const DependantFields = observer(({uiState}) => {
  return (
    <div>
      <DependantsEmptyState uiState={uiState}/>
      <DependantList uiState={uiState}/>
      <AddDependantLink uiState={uiState}/>
    </div>
  );
});

export default withState(DependantFields, DependantFieldsState);
