import React from 'react';
import {observer} from 'mobx-react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {PendingInvitationsContainer} from './pending_invitations';
import {UsersContainer} from './users';
import {UsersPageState} from '../state';
import UserSettingsSubnav from './UserSettingsSubnav';
import {Layout} from 'components';
import {withState} from 'shared/core';
import AddExternalUserModal from './AddExternalUserModal';

const UsersPage = observer(({uiState, match}) => {
  return (
    <div>
      <UserSettingsSubnav uiState={uiState} match={match}/>
      <Layout>
        <Layout.Section>
          <Switch>
            <Route path={`${match.url}/users`} render={() => <UsersContainer roles={uiState.roles} />}/>
            <Route path={`${match.url}/pending`} render={
              () => <PendingInvitationsContainer onMount={agent => uiState.setPendingInvitationsInteractiveAgent(agent)}/>
            }/>
            <Redirect exact from={`${match.url}`} to={`${match.url}/users`}/>
          </Switch>
        </Layout.Section>
      </Layout>
      <AddExternalUserModal uiState={uiState}/>
    </div>
  );
});

export default withState(UsersPage, UsersPageState);
