import BenefitClass from './BenefitClass';
import BenefitDivision from './BenefitDivision';
import EffectiveDate from './EffectiveDate';
import EnrolmentStatus from './EnrolmentStatus';
import AdvisorPortalEmployeeName from './AdvisorPortalEmployeeName';

const COLUMNS = [
  {
    header: 'benefit_enrolment.Name',
    component: AdvisorPortalEmployeeName,
    width: 4,
    sortBy: 'name'
  },
  {
    header: 'benefit_enrolment.Division',
    component: BenefitDivision,
    width: 2,
    sortable: false,
    className: 'xs-hide'
  },
  {
    header: 'benefit_enrolment.Class',
    component: BenefitClass,
    width: 2,
    sortable: false,
    className: 'xs-hide'
  },
  {
    header: 'benefit_enrolment.Effective Date',
    component: EffectiveDate,
    width: 2,
    className: 'md-and-below-hide'
  },
  {
    header: 'benefit_enrolment.Status',
    component: EnrolmentStatus,
    width: 2
  }
];

export default COLUMNS;
