import React from 'react';
import {action} from 'mobx';
import {QuickTip, Layout} from 'components';
import {auth, t, endpoints, withState, api} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {redirect} from 'shared/tools';

const StopImpersonation = ({uiState}) => {
  return (
    <Layout>
      <div className='pt2'>
        <QuickTip
          trait='caution'
          header={auth.user.name}
          paragraphs={[
            <FormattedMessage id='components.stop_impersonation.VIEWING_AS' values={{
              user: auth.user.name
            }}/>
          ]}
          actions={[
            {
              caption: t('components.stop_impersonation.Return to Users'),
              onClick: () => uiState.stopImpersonation(),
              loadingText: t('components.stop_impersonation.Returning to Users...')
            }
          ]}
        />
      </div>
    </Layout>
  );
};

class StopImpersonationState {
  @action async stopImpersonation() {
    await api.put(endpoints.USERS.STOP_IMPERSONATING);
    return redirect('/company_settings/users');
  }
}

export default withState(StopImpersonation, StopImpersonationState);
