import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {InteractiveTable} from 'components';
import {t, types, endpoints} from 'shared/core';
import PayrollUpload from 'stores/payroll/PayrollUpload';

const DownloadLink = observer(({model}) => {
  const {payrollFile} = model;

  return (
    <a href={payrollFile.link('url')} target='_blank' className='dodger'>
      {payrollFile.fileName}
    </a>
  );
});

const COLUMNS = [
  {
    header: 'payroll.directory.File Name',
    render: model => <DownloadLink model={model}/>,
    sortBy: 'fileName',
    width: 8
  },
  {
    header: 'payroll.directory.Date Uploaded',
    attribute: 'dateView',
    width: 4
  }
];

const LOCALIZATION = {
  emptyState: 'payroll.directory.No payroll files have been uploaded yet',
  interactive: {
    addModel: 'payroll.directory.Upload New File'
  }
};

const PayrollUploadTable = observer(({uiState}) => {
  return (
    <InteractiveTable
      uiState={uiState}
      title={t('payroll.directory.Payroll Documents')}
      columns={COLUMNS}
      searchable={false}
      showLinks={false}
      localization={LOCALIZATION}
      onAdd={() => uiState.openUploadFileModal()}
      onMount={agent => uiState.setInteractiveAgent(agent)}
      proxy={{
        type: 'async',
        modelType: types.PAYROLL_UPLOAD,
        model: PayrollUpload,
        endpoint: endpoints.PAYROLL_UPLOADS
      }}>
    </InteractiveTable>
  );
});

export default PayrollUploadTable;
