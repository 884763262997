import {action, observable, computed} from 'mobx';
import {DomainObject} from 'shared/store';
import {calendarDate} from 'shared/tools';
import moment from 'moment';

class Dependent extends DomainObject {
  @observable benefitInfoId;
  @observable firstName;
  @observable lastName;
  @observable student = false;
  @observable gender;
  @observable disabledAdult = false;
  @observable birthDate;
  @observable _destroy = false;
  @observable institutionName;
  @observable educationStartDate;
  @observable expectedGraduationDate;
  @observable eligible;

  @action merge(other) {
    super.merge(other, {
      _dates: ['birthDate', 'educationStartDate', 'expectedGraduationDate']
    });
  }

  @computed get birthDateView() {
    return calendarDate(this.birthDate);
  }

  @computed get educationStartDateView() {
    return moment(this.educationStartDate).format('MMM YYYY');
  }

  @computed get expectedGraduationDateView() {
    return moment(this.expectedGraduationDate).format('MMM YYYY');
  }
}

export default Dependent;
