import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import _ from 'lodash';
import {action, observable} from 'mobx';

class RevieweesContainerState extends DomainStore {
  store = new DomainStore();

  @observable pairs;
  @observable checkins;

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.PERFORMANCE.REVIEWERS,
      endpoints.PERFORMANCE.CYCLES
    ]);

    this.pairs = this.store._getAll(types.PERFORMANCE.CHECKIN_RELATIONS);
  }
}

export default RevieweesContainerState;
