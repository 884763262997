import React from 'react';
import {observer} from 'mobx-react';
import {auth} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree.js';

const CompanySetupContainer = ({uiState}) => {
  return (
    <div className='my4'>
      <div className='Container mb3'>
        <div className='clearfix'>
          <div className='col-12'>
            <div className='h1 medium black'>
              <FormattedMessage id='company_onboard.WELCOME_NAME' values={{name: auth.user.firstName}} />
            </div>
            <div className='pt1 h3'>
              <FormattedMessage id='company_onboard.Get started by adding and inviting your employees to Collage.'/>
            </div>
          </div>
        </div>
      </div>
      <div className='Container'>
        <div className='clearfix'>
          <div className='col-12 md-col-9 lg-col-7'>
            <StepOne uiState={uiState} />
            <StepTwo uiState={uiState} />
            <StepThree uiState={uiState} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(CompanySetupContainer);
