import React from 'react';
import {observable, action, computed} from 'mobx';
import _ from 'lodash';

class HorizontalScrollState {
  @observable element;
  @observable scrolling;
  @observable fixedArrows

  @observable top;

  receiveProps({fixedArrows}) {
    this.fixedArrows = fixedArrows;
  }

  mount() {
    this.element = React.createRef();
  }

  @action scrollPosition(e) {
    const rect = e.target.getBoundingClientRect();
    this.top = e.clientY - rect.top - 30;
  }

  @action scrollRight(e) {
    e.preventDefault();
    const element = this.element.current;
    const scrolling = setInterval(function() {
      element.scrollLeft += 15;
    }, 26);

    this.scrolling = scrolling;
  }

  @action scrollLeft(e) {
    e.preventDefault();
    const element = this.element.current;
    const scrolling = setInterval(function() {
      element.scrollLeft -= 15;
    }, 26);

    this.scrolling = scrolling;
  }

  @action clearScrolling() {
    clearInterval(this.scrolling);
  }

  @computed get showScrollBars() {
    if (!_.get(this.element, 'current')) return false;
    return this.element.current.scrollWidth > this.element.current.clientWidth;
  }
}

export default HorizontalScrollState;
