import {DomainObject, oneOf} from 'shared/store';
import {action, observable, computed} from 'mobx';
import {Dependent, OptionalCoverageEnrolment} from 'stores/benefits';
import {calendarDate} from 'shared/tools';
import BeneficiaryForm from './BeneficiaryForm';

class BenefitInfo extends DomainObject {
  @observable employeeId;
  @observable gender;
  @observable hasSpouse;
  @observable healthCoverage;
  @observable dentalCoverage;
  @observable spouseFirstName;
  @observable spouseLastName;
  @observable spousalGender;
  @observable spousalRelationship;
  @observable spouseBirthDate;
  @observable spouseMarriageDate;
  @observable spousalHealthCoverage;
  @observable spousalDentalCoverage;
  @observable spousalVisionCoverage;
  @observable spouseInsurerName;
  @observable spousePolicyNumber;
  @observable dependents;
  @observable hasCanadianVisaOrPermit;
  @observable visaOrPermitExpiryDate;
  @observable hasProvincialHealthPlan;
  @observable optionalCoverageEnrolments;
  @observable healthCoverageOptions;
  @observable dentalCoverageOptions;
  @observable healthCoverageHelperText;
  @observable dentalCoverageHelperText;
  @observable languagePreference;
  @observable taxExempt;
  @observable inacNumber;
  @observable canMakeChangeRequest = false;
  @observable beneficiaryForms;

  @action merge(other) {
    super.merge(other, {
      dependents: [Dependent],
      optionalCoverageEnrolments: [OptionalCoverageEnrolment],
      gender: oneOf(['male', 'female']),
      spousalGender: oneOf(['male', 'female']),
      healthCoverage: oneOf(['family', 'single', 'waived', 'single_parent', 'couple']),
      dentalCoverage: oneOf(['family', 'single', 'waived', 'single_parent', 'couple']),
      spousalHealthCoverage: oneOf(['family', 'single', 'waived', 'none']),
      spousalDentalCoverage: oneOf(['family', 'single', 'waived', 'none']),
      spousalVisionCoverage: oneOf(['family', 'single', 'waived', 'none']),
      beneficiaryForms: [BeneficiaryForm],
      _dates: ['visaOrPermitExpiryDate', 'spouseBirthDate', 'spouseMarriageDate']
    });
  }

  @computed get visaOrPermitExpiryDateView() {
    return calendarDate(this.visaOrPermitExpiryDate);
  }

  @computed get spouseBirthDateView() {
    return calendarDate(this.spouseBirthDate);
  }

  @computed get spouseMarriageDateView() {
    return calendarDate(this.spouseMarriageDate);
  }

  @computed get spouseHasInsurer() {
    return this.spousalHealthCoverage !== 'none' ||
      this.spousalDentalCoverage !== 'none' ||
      this.spousalVisionCoverage !== 'none';
  }
}

export default BenefitInfo;
