import React from 'react';
import {observer} from 'mobx-react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {PersonalTab} from './personal';
import {EmployeeRoleSwitch} from './role';
import {BenefitInfoContainer} from 'components/benefits';
import EmployeeProfileContainer from './EmployeeProfileContainer';

const BenefitsTab = observer(({uiState}) => {
  return (
    <EmployeeProfileContainer uiState={uiState}>
      <BenefitInfoContainer employeeId={uiState.employee.id}/>
    </EmployeeProfileContainer>
  );
});

const BrokerEmployeeProfileSwitch = observer(({uiState}) => {
  const {match} = uiState;

  return (
    <div>
      <Switch>
        <Route path={`${match.url}/personal`} render={() => <PersonalTab uiState={uiState} />} />
        {(uiState.viewDataPermission('::ROLE_INFORMATION') || uiState.viewDataPermission('::COMPENSATION_INFORMATION')) &&
            <Route path={`${match.url}/job`} render={() => <EmployeeRoleSwitch uiState={uiState} />} />}
        <Route path={`${match.url}/benefits`} render={() => <BenefitsTab uiState={uiState}/>} />
        <Redirect from={`${match.url}/`} to={`${match.url}/personal`}/>
      </Switch>
    </div>
  );
});

export default BrokerEmployeeProfileSwitch;
