import {useState, useRef, useEffect} from 'react';

export default function (horizontalScroll, containerRef) {
  const [showShadow, setShowShadow] = useState(false);
  const [showActionLinksShadow, setShowActionLinksShadow] = useState(false);
  const stickyColumnRef = useRef(null);
  const actionLinksRef = useRef(null);

  useEffect(() => {
    if (!horizontalScroll) return;

    const container = containerRef.current;
    const stickyColumn = stickyColumnRef.current;
    const actionLinks = actionLinksRef.current;
    let intersectionObserver = null;
    let actionLinksObserver = null;

    if (stickyColumn) {
      intersectionObserver = new IntersectionObserver(
        ([entry]) => setShowShadow(!entry.isIntersecting),
        { root: container, threshold: 1 }
      );
      intersectionObserver.observe(stickyColumn);
    }

    if (actionLinks) {
      actionLinksObserver = new IntersectionObserver(
        ([entry]) => setShowActionLinksShadow(!entry.isIntersecting),
        { root: container, threshold: 1 }
      );
      actionLinksObserver.observe(actionLinks);
    }

    return () => {
      if (intersectionObserver) intersectionObserver.disconnect();
      if (actionLinksObserver) actionLinksObserver.disconnect();
    };
  }, []);

  return [stickyColumnRef, showShadow, actionLinksRef, showActionLinksShadow];
}
