import React from 'react';
import {observer} from 'mobx-react';
import Subnav from 'benefits/components/Subnav';
import MyCoverageContainerWithState from './MyCoverageContainerWithState';

const MyCoverageContainer = observer(({scope, match, history}) => {
  return (
    <div>
      <Subnav scope={scope}/>
      <MyCoverageContainerWithState match={match} history={history}/>
    </div>
  );
});

export default MyCoverageContainer;
