import React from 'react';

const BrokerLoginSvg = () => {
  return (
    <svg width="972" height="597" viewBox="0 0 972 597" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.35">
      <path opacity="0.2" d="M134.138 529.689C134.457 531.509 134.928 533.299 135.548 535.039C147.002 531.36 158.571 527.868 170.255 524.565C170.183 524.09 170.11 523.582 170.022 523.091C169.192 517.958 167.031 513.132 163.752 509.096C159.489 504.004 153.72 501.281 147.934 502.345C142.148 503.408 137.652 507.984 135.355 514.301C133.622 519.238 133.203 524.541 134.138 529.689Z" fill="#3F3D56"/>
      <path opacity="0.2" d="M150.867 521.076C160.819 519.502 167.179 507.434 165.072 494.122C162.966 480.81 153.19 471.294 143.238 472.868C133.286 474.442 126.926 486.51 129.033 499.823C131.139 513.135 140.915 522.651 150.867 521.076Z" fill="#3F3D56"/>
      <path opacity="0.2" d="M145.789 491.695C155.741 490.121 162.101 478.053 159.994 464.741C157.888 451.429 148.112 441.913 138.16 443.487C128.208 445.061 121.848 457.129 123.955 470.442C126.061 483.754 135.837 493.27 145.789 491.695Z" fill="#3F3D56"/>
      <path opacity="0.2" d="M140.719 462.305C150.671 460.731 157.031 448.663 154.925 435.351C152.818 422.038 143.043 412.523 133.091 414.097C123.139 415.671 116.779 427.739 118.885 441.051C120.992 454.364 130.767 463.879 140.719 462.305Z" fill="#3F3D56"/>
      <path opacity="0.2" d="M135.641 432.924C145.593 431.35 151.953 419.282 149.847 405.97C147.74 392.657 137.965 383.142 128.013 384.716C118.061 386.29 111.701 398.358 113.807 411.67C115.914 424.983 125.689 434.498 135.641 432.924Z" fill="#3F3D56"/>
      <path opacity="0.2" d="M164.243 195.374C166.146 191.732 167.797 187.963 169.183 184.094L120.673 184.852L171.73 175.852C174.937 163.358 175.453 150.324 173.245 137.615C171.324 126.364 167.282 115.581 161.334 105.839L99.1975 154.72L154.09 95.6393C134.686 72.1216 103.887 59.5772 72.1058 65.4264C24.6348 74.1519 -7.04209 120.656 1.34652 169.222C4.66103 188.844 14.4408 206.795 29.1313 220.221C26.1651 225.908 23.8072 231.893 22.0964 238.075L88.9152 259.353L19.7918 248.984C18.3514 258.626 18.4656 268.438 20.1302 278.044C23.4466 297.668 33.226 315.621 47.915 329.052C38.6385 346.795 35.4747 367.103 38.914 386.827C47.3026 435.409 92.5817 467.717 140.053 458.975C187.524 450.234 219.201 403.77 210.812 355.18C207.496 335.556 197.716 317.603 183.027 304.172C192.304 286.429 195.468 266.121 192.028 246.397C188.717 226.767 178.937 208.808 164.243 195.374Z" className="wl-branded-svg-path"/>
      <path opacity="0.1" d="M20.1307 278.028C23.4471 297.652 33.2264 315.605 47.9155 329.036C38.6389 346.779 35.4751 367.087 38.9144 386.811C47.303 435.393 92.5822 467.701 140.053 458.959C187.524 450.217 219.201 403.754 210.812 355.164C207.496 335.54 197.717 317.587 183.028 304.156C191.964 287.068 18.4224 268.151 20.1307 278.028Z" fill="black"/>
      </g>
      <path opacity="0.1" d="M298.729 233.888C298.034 232.894 297.653 231.716 297.633 230.504C297.633 223.164 312.605 217.21 331.075 217.21C349.544 217.21 364.516 223.164 364.516 230.504C364.48 231.881 363.993 233.208 363.13 234.283C383.695 236.571 397.555 240.873 397.555 245.812C397.555 253.152 366.886 259.105 329.06 259.105C291.235 259.105 260.565 253.152 260.565 245.812C260.582 240.583 276.166 236.063 298.729 233.888Z" className="wl-branded-svg-path"/>
      <g opacity="0.2">
      <path opacity="0.2" d="M786.549 528.07C786.255 530.168 786.139 532.286 786.202 534.403C800.245 534.629 814.347 535.101 828.508 535.821C828.604 535.273 828.709 534.701 828.79 534.129C829.698 528.222 829.017 522.179 826.816 516.622C823.898 509.516 818.459 504.432 811.65 503.449C804.841 502.466 798.201 505.818 793.406 511.796C789.708 516.511 787.341 522.131 786.549 528.07Z" fill="#3F3D56"/>
      <path opacity="0.2" d="M833.68 500.371C835.875 485.105 828.198 471.371 816.533 469.695C804.868 468.018 793.633 479.034 791.438 494.3C789.243 509.565 796.92 523.299 808.585 524.976C820.25 526.652 831.485 515.636 833.68 500.371Z" fill="#3F3D56"/>
      <path opacity="0.2" d="M838.542 466.594C840.737 451.329 833.06 437.595 821.395 435.918C809.73 434.242 798.495 445.258 796.3 460.523C794.105 475.789 801.782 489.523 813.447 491.199C825.112 492.876 836.348 481.86 838.542 466.594Z" fill="#3F3D56"/>
      <path opacity="0.2" d="M843.396 432.808C845.59 417.543 837.913 403.809 826.249 402.132C814.584 400.456 803.348 411.472 801.154 426.737C798.959 442.003 806.636 455.737 818.301 457.414C829.966 459.09 841.201 448.074 843.396 432.808Z" fill="#3F3D56"/>
      <path opacity="0.2" d="M848.25 399.031C850.445 383.765 842.768 370.031 831.103 368.355C819.438 366.678 808.203 377.694 806.008 392.96C803.813 408.225 811.49 421.959 823.155 423.636C834.82 425.312 846.056 414.296 848.25 399.031Z" fill="#3F3D56"/>
      <path opacity="0.35" d="M939.381 176.143C942.798 172.877 945.983 169.377 948.914 165.669L894.731 148.621L954.684 157.685C962.239 145.988 967.329 132.873 969.641 119.142C971.953 105.41 971.44 91.3522 968.133 77.8262L881.612 108.015L963.709 64.1055C958.48 51.0866 950.626 39.2836 940.635 29.432C930.645 19.5804 918.733 11.8911 905.64 6.84333C892.548 1.79552 878.556 -0.502715 864.537 0.0917917C850.518 0.686298 836.771 4.16081 824.153 10.2989C811.535 16.4369 800.317 25.1071 791.197 35.7691C782.077 46.4311 775.25 58.8566 771.142 72.2715C767.035 85.6864 765.734 99.8033 767.321 113.743C768.909 127.682 773.35 141.145 780.368 153.294C775.051 158.353 770.296 163.972 766.186 170.052L832.835 217.812L759.739 181.009C752.056 196.173 748.276 213.013 748.74 230.005C749.204 246.997 753.899 263.605 762.399 278.327C748.518 291.558 738.641 308.428 733.898 327.007C729.154 345.587 729.736 365.126 735.575 383.39C741.415 401.655 752.277 417.908 766.92 430.291C781.563 442.674 799.396 450.687 818.379 453.415C837.363 456.143 856.731 453.475 874.269 445.716C891.807 437.957 906.808 425.42 917.556 409.539C928.304 393.658 934.365 375.074 935.047 355.911C935.729 336.748 931.003 317.78 921.411 301.176C937.996 285.361 948.784 264.433 952.042 241.752C955.301 219.071 950.842 195.953 939.381 176.111V176.143Z" className="wl-branded-svg-path"/>
      <path opacity="0.1" d="M749.738 212.753C746.441 235.433 750.901 258.563 762.398 278.391C748.517 291.623 738.64 308.493 733.897 327.072C729.153 345.651 729.735 365.19 735.574 383.455C741.414 401.719 752.276 417.972 766.919 430.355C781.562 442.738 799.395 450.752 818.378 453.48C837.362 456.207 856.73 453.539 874.268 445.78C891.806 438.021 906.807 425.485 917.555 409.604C928.303 393.723 934.364 375.138 935.046 355.976C935.728 336.813 931.002 317.845 921.41 301.24C937.438 285.949 751.366 201.369 749.738 212.753Z" fill="black"/>
      </g>
      <path opacity="0.2" d="M466 597C712.319 597 912 582.897 912 565.5C912 548.103 712.319 534 466 534C219.681 534 20 548.103 20 565.5C20 582.897 219.681 597 466 597Z" className="wl-branded-svg-path"/>
      <path opacity="0.1" d="M286.642 74.3479C286.078 73.535 285.767 72.5735 285.748 71.5844C285.748 65.5902 297.964 60.74 313.025 60.74C328.086 60.74 340.31 65.574 340.31 71.5844C340.283 72.7051 339.889 73.786 339.19 74.6621C355.943 76.5393 367.224 80.044 367.224 84.0724C367.224 90.0585 342.204 94.9168 311.349 94.9168C280.494 94.9168 255.465 90.0827 255.465 84.0724C255.465 79.8023 268.157 76.0962 286.642 74.3479Z" className="wl-branded-svg-path"/>
      <path opacity="0.1" d="M582.597 89.3415C581.702 88.5252 581.073 87.4584 580.792 86.2799C579.18 79.1094 592.509 70.0455 610.535 66.0332C628.561 62.021 644.476 64.5669 646.072 71.7374C646.336 73.0883 646.152 74.4887 645.548 75.7255C666.112 73.4938 680.577 74.6781 681.649 79.4961C683.26 86.6586 654.605 99.1385 617.682 107.356C580.76 115.574 549.534 116.444 547.939 109.274C546.802 104.174 561.001 96.3831 582.597 89.3415Z" className="wl-branded-svg-path"/>
      <path opacity="0.1" d="M456.05 76.9019C454.173 75.8142 451.634 76.8535 450.345 78.6018C449.056 80.3501 448.733 82.6302 448.371 84.7491C447.654 89.5107 447.13 94.9812 450.345 98.5745C445.091 82.6463 429.982 70.4403 413.277 68.6598" stroke="#CB2B38" strokeWidth="3" strokeMiterlimit="10"/>
      <path opacity="0.1" d="M662.365 152.837C661.157 152.136 659.529 152.837 658.699 153.932C657.963 155.127 657.526 156.481 657.426 157.88C656.966 160.95 656.62 164.463 658.699 166.743C656.929 161.629 653.759 157.116 649.549 153.716C645.339 150.316 640.259 148.166 634.887 147.511" stroke="#CB2B38" strokeWidth="3" strokeMiterlimit="10"/>
      <path opacity="0.1" d="M362.196 182.445C360.988 181.753 359.36 182.445 358.53 183.541C357.793 184.738 357.356 186.095 357.257 187.497C356.797 190.559 356.451 194.071 358.53 196.359C356.758 191.247 353.588 186.734 349.378 183.335C345.169 179.935 340.089 177.784 334.718 177.128" stroke="#CB2B38" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M169.933 527.288C169.939 529.389 169.723 531.485 169.288 533.54C169.208 533.927 169.119 534.306 169.03 534.676C166.742 543.853 160.287 550.564 152.583 550.991C152.326 550.991 152.068 550.991 151.778 550.991C143.631 550.991 136.741 543.966 134.469 534.29C134.404 534.032 134.348 533.774 134.291 533.484C133.857 531.429 133.641 529.333 133.647 527.232C133.647 514.124 141.761 503.505 151.778 503.505C161.794 503.505 169.933 514.18 169.933 527.288Z" fill="#3F3D56"/>
      <path opacity="0.1" d="M190.629 529.233C190.635 531.334 190.419 533.429 189.984 535.485C189.904 535.871 189.815 536.25 189.727 536.621C189.453 536.621 189.187 536.621 188.921 536.621C186.925 536.513 184.942 536.244 182.99 535.815C181.261 536.349 179.493 536.753 177.704 537.023C176.331 537.23 174.949 537.359 173.562 537.41H172.16C170.499 537.41 168.841 537.3 167.196 537.08C164.535 536.725 161.922 536.076 159.403 535.146C158.025 535.605 156.62 535.982 155.197 536.274C155.133 536.016 155.076 535.759 155.02 535.469C154.585 533.413 154.369 531.317 154.375 529.216C154.375 516.108 162.49 505.489 172.506 505.489C182.523 505.489 190.629 516.124 190.629 529.233Z" fill="black"/>
      <path d="M208.612 483.089C208.62 477.321 207.267 471.632 204.664 466.484L183.342 473.115L202.045 462.109C198.902 457.585 194.785 453.823 189.996 451.1C185.207 448.377 179.868 446.762 174.373 446.374C173.497 445.046 172.536 443.776 171.496 442.571L140.875 452.103L165.992 437.335C161.188 433.648 155.548 431.202 149.573 430.213C143.597 429.224 137.47 429.724 131.734 431.667C125.997 433.61 120.828 436.936 116.684 441.352C112.539 445.768 109.548 451.138 107.973 456.985L134.203 487.174L105.709 466.597C98.5011 471.708 93.3699 479.238 91.2501 487.815C89.1303 496.392 90.1631 505.445 94.1602 513.325C98.1573 521.204 104.853 527.386 113.026 530.743C121.2 534.1 130.308 534.409 138.691 531.615C146.274 534.402 154.56 534.631 162.286 532.267C167.671 533.452 173.254 533.415 178.623 532.159C183.993 530.902 189.012 528.458 193.312 525.006C197.612 521.554 201.083 517.182 203.47 512.212C205.857 507.241 207.099 501.799 207.105 496.286C207.105 495.48 207.105 494.675 207.025 493.869C208.087 490.374 208.622 486.741 208.612 483.089Z" className="wl-branded-svg-path"/>
      <path opacity="0.1" d="M207.976 489.921C196.715 487.787 185.168 487.64 173.857 489.486C161.053 491.605 147.966 496.262 135.444 492.862C128.094 490.864 121.745 486.239 114.565 483.669C107.637 481.253 100.175 480.806 93.0089 482.38C90.2782 488.912 89.4861 496.091 90.7271 503.061C91.9682 510.03 95.1899 516.495 100.007 521.683C104.825 526.871 111.035 530.563 117.894 532.317C124.754 534.071 131.974 533.813 138.691 531.575C146.274 534.362 154.56 534.591 162.285 532.227C167.671 533.412 173.254 533.375 178.623 532.118C183.993 530.862 189.012 528.418 193.312 524.966C197.612 521.514 201.083 517.142 203.47 512.171C205.857 507.201 207.099 501.759 207.105 496.246C207.105 495.44 207.105 494.634 207.025 493.829C207.413 492.545 207.731 491.24 207.976 489.921Z" fill="black"/>
      <path d="M818.539 498.188L851.4 478.852C846.954 471.266 841.276 464.472 834.599 458.75L772.607 478.03L821.182 449.477C809.153 442.889 795.616 439.547 781.903 439.78C768.19 440.013 754.773 443.813 742.975 450.805C731.177 457.797 721.404 467.741 714.618 479.658C707.832 491.574 704.267 505.052 704.273 518.765C704.273 562.368 739.633 563.883 783.244 563.883C826.855 563.883 862.215 562.368 862.215 518.765C862.228 507.73 859.919 496.816 855.438 486.731L818.539 498.188Z" className="wl-branded-svg-path"/>
      <path opacity="0.1" d="M825.941 540.575C822.588 536.941 818.422 534.169 814.296 531.43C808.325 527.474 802.153 523.43 795.126 522.084C788.945 520.9 782.571 521.899 776.294 521.536C765.365 520.871 755.077 516.157 747.437 508.315C742.949 503.675 739.419 497.978 734.02 494.433C728.202 490.615 720.821 489.793 713.923 490.743C711.919 491.036 709.934 491.442 707.976 491.96C704.879 500.551 703.3 509.616 703.311 518.749C703.311 562.352 738.67 563.867 782.281 563.867C803.442 563.867 822.661 563.512 836.843 558.162L834.474 553.731C832.008 549.107 829.494 544.434 825.941 540.575Z" fill="black"/>
    </svg>
  );
};

export default BrokerLoginSvg;
