import {useState} from 'react';

export default function (uiState, modalName, selectedModel, ModelClass, errorName) {
  const [modalOpen, setModalOpen] = useState(false);

  const existingOpenFunction = uiState[`open${modalName}`];
  uiState[`open${modalName}`] = (model) => {
    if (existingOpenFunction) existingOpenFunction.apply(uiState);
    if (selectedModel) {
      uiState[selectedModel] = ModelClass ? new ModelClass(model) : model;
    }
    if (errorName) uiState[errorName] = {};
    setModalOpen(true);
  };

  const existingCloseFunction = uiState[`close${modalName}`];
  uiState[`close${modalName}`] = () => {
    setModalOpen(false);
    if (existingCloseFunction) existingCloseFunction.apply(uiState);
    if (errorName) uiState[errorName] = {};
  };

  return modalOpen;
}
