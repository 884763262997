import {observable, action, computed} from 'mobx';
import {noop} from 'shared/tools';
import _ from 'lodash';
import SignatureViewModel from './SignatureViewModel';
import MERGE_FIELDS from 'stores/documents/mergeFields';
import MergeFieldViewModel from './MergeFieldViewModel';
import FormElementViewModel from './FormElementViewModel';
import {fromFabricToModel} from '../tools/converters';
import {
  createEmployeeSignature,
  createCompanySignature,
  createMergeField,
  createInputField
}
from '../tools/annotations';

class AnnotatablePdfPreviewState {
  fabric;
  store;
  pdfUrl;
  annotationModels = [];
  callbacks = {
    onAnnotationsAdded: noop,
    onPageRefreshed: noop,
    onSave: noop,
    onCancel: noop
  };
  lastKnownBestCoords;

  @observable annotations = [];
  @observable pageNumber = 1;
  @observable signatures = [];
  @observable mergeFields = [];
  @observable inputFields = [];
  @observable isLoaded = false;
  @observable canvasWidth;
  @observable canvasHeight;

  constructor({pdfUrl, store, onSave = noop, onCancel = noop, annotations, fabric}) {
    this.pdfUrl = pdfUrl;
    this.callbacks.onSave = onSave;
    this.callbacks.onCancel = onCancel;
    this.store = store;
    this.annotationModels = annotations || [];
    this.fabric = fabric;
    this.lastKnownBestCoords = {};
  }

  fromModelToDescriptor(annotation) {
    switch (annotation.annotationType) {
      case 'employee_signature':
        return SignatureViewModel.employeeSignature(
          annotation.annotationArgs.id
        );
      case 'company_signature':
        return SignatureViewModel.fromCompanySignature({
          id: annotation.annotationArgs.signatureId,
          name: annotation.state.signatureName,
          signatureData: annotation.state.signatureData
        });
      case 'custom_field':
      case 'variable':
        const key = annotation.annotationArgs.name === 'commitment' ?
          'employment_type' : annotation.annotationArgs.name;
        return _.find(this.mergeFields, {key});
      case 'input':
        return FormElementViewModel.fromHash({
          id: annotation.annotationArgs.id,
          inputType: annotation.annotationArgs.inputType,
          isRequired: annotation.annotationArgs.isRequired
        });
      default:
        throw new Error(`Annotation type ${annotation.annotationType} not supported.`);
    }
  }

  @action async load() {
    await this.store.load();

    const companySignatures = this.store.getCompanySignatures();
    this.signatures = [
      SignatureViewModel.employeeSignature(),
      ...companySignatures.map(SignatureViewModel.fromCompanySignature)
    ];

    this.mergeFields = MERGE_FIELDS.map(MergeFieldViewModel.fromHash);

    this.addCustomFieldsToMergeFields();

    this.inputFields = [
      FormElementViewModel.textField(),
      FormElementViewModel.optionalTextField(),
      FormElementViewModel.textAreaField(),
      FormElementViewModel.optionalTextAreaField(),
      FormElementViewModel.checkboxField()
    ];

    const descriptorsAndModels = this.annotationModels.map(
      model => (
        {
          descriptor: this.fromModelToDescriptor(model),
          model
        }
      )
    );

    await Promise.all(
      descriptorsAndModels.map(
        t => this.addAnnotation(t.descriptor, t.model)
      )
    );
  }

  addCustomFieldsToMergeFields() {
    const customFieldMergeFields = _.chain(this.store.getCustomFields())
      .filter('employeeCanView')
      .map(customField => MergeFieldViewModel.fromHash({
        label: customField.name, key: `custom_field_${customField.id}`, annotationType: 'custom_field'
      }))
      .sortBy('name')
      .value();
    this.mergeFields = this.mergeFields.concat(customFieldMergeFields);
  }

  @action async addAnnotation(descriptor, model = {}) {
    const annotation = await this.createAnnotation(descriptor, model);
    annotation.pageNumber = model.pageNumber || this.pageNumber;
    annotation.descriptor = descriptor;
    this.annotations.push(annotation);
    if (this.isLoaded) {
      this.callbacks.onAnnotationsAdded([annotation]);
    }
  }

  @action onLoaded() {
    this.isLoaded = true;
  }

  @action changePage(pageNumber, canvasWidth, canvasHeight) {
    this.canvasWidth = canvasWidth;
    this.canvasHeight = canvasHeight;
    this.pageNumber = pageNumber;
    this.callbacks.onAnnotationsAdded(_.filter(this.annotations, {pageNumber: this.pageNumber}));
    this.callbacks.onPageRefreshed();
  }

  @action async save() {
    return this.callbacks.onSave(
      this.annotations.map(i => fromFabricToModel(i, this.canvasWidth, this.canvasHeight))
    );
  }

  @action cancel() {
    this.callbacks.onCancel();
  }

  async createAnnotation(descriptor, model) {
    switch (descriptor.annotationType) {
      case 'employee_signature':
        return createEmployeeSignature(descriptor, model, this.fabric);
      case 'company_signature':
        return createCompanySignature(descriptor, model, this.fabric);
      case 'variable':
      case 'custom_field':
        return createMergeField(descriptor, model, this.fabric);
      case 'input':
        return createInputField(descriptor, model, this.fabric);
      default:
        throw new Error(`Annotation type ${descriptor.annotationType} is not supported.`);
    }
  }

  @action onAnnotationDeleted(annotation) {
    this.annotations.remove(annotation);
  }

  @computed get customFieldMergeFields() {
    return this.mergeFields.filter(field => field.annotationType === 'custom_field');
  }

  registerCallback(event, callback) {
    switch (event) {
      case 'onAnnotationsAdded':
      case 'onDeleteKeyPressed':
      case 'onPageRefreshed':
        this.callbacks[event] = callback;
        break;
      default:
        throw new Error(`Callback ${event} is not supported.`);
    }
  }
}

export default AnnotatablePdfPreviewState;
