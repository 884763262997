import React from 'react';
import {observer} from 'mobx-react';
import ReviewCycleName from '../../ReviewCycleName';
import {Link} from 'react-router-dom';
import ReviewStatusTag from './ReviewStatusTag';

const DirectoryCycleName = observer(({model, uiState}) => {
  const cycle = model;

  return (
    <span>
      <div className='table-cell align-middle'>
        <Link to={uiState.cyclePath(cycle)}>
          <ReviewCycleName cycle={cycle}/>
        </Link>
      </div>
      <div className='table-cell align-middle pl1'>
        <ReviewStatusTag cycle={cycle}/>
      </div>
    </span>
  );
});

export default DirectoryCycleName;
