import {DomainObject} from 'shared/store';
import {action, computed, observable} from 'mobx';
import moment from 'moment';
import {t} from 'shared/core';

class Absence extends DomainObject {
  @observable id;
  @observable startTime;
  @observable endTime;
  @observable date;
  @observable amount;

  @action merge(other) {
    super.merge(other, {
      _dates: ['date']
    });
  }

  @computed get timeRange() {
    if (!this.startTime || !this.endTime) {
      return null;
    }

    return `${moment(this.startTime).format(t('components.dates.TIMESTAMP_FORMAT'))} - ${moment(this.endTime).format(t('components.dates.TIMESTAMP_FORMAT'))}`;
  }
}

export default Absence;
