import {DomainObject} from 'shared/store';
import {observable, computed, action} from 'mobx';
import _ from 'lodash';
import FeaturePermission from 'stores/users/FeaturePermission';
import DataPermission from 'stores/users/DataPermission';
import dataPermissions from 'stores/users/dataPermissions';
import {t} from 'shared/core';

class Role extends DomainObject {
  @observable id;
  @observable name;
  @observable featurePermissions = [];
  @observable employeeDataPermissions = [];
  @observable users = [];
  @observable locations = [];
  @observable departments = [];
  @observable employmentTypes = [];
  @observable scope;
  @observable roleType;

  @computed get nameView() {
    switch (this.roleType) {
      case 'admin':
        return t('company_settings.permissions.default_roles.Super Admin');
      case 'manager':
        return t('company_settings.permissions.default_roles.Manager');
      case 'employee':
        return t('company_settings.permissions.default_roles.Employee');
      case 'custom':
      default:
        return this.name;
    }
  }

  @computed get isCustom() {
    return this.roleType === 'custom';
  }

  @computed get isAdmin() {
    return this.roleType === 'admin';
  }

  @computed get isManager() {
    return this.roleType === 'manager';
  }

  @computed get employeeCount() {
    return this.users.length;
  }

  @action addUser(user) {
    const existingUser = _.find(this.users, {id: user.id});
    if (!existingUser) {
      this.users.push(user);
    }
  }

  @action removeUser(user) {
    this.users.remove(_.find(this.users, {id: user.id}));
  }

  @action addLocation(location) {
    const existingLocation = _.find(this.locations, {id: location.id});
    if (!existingLocation) {
      this.locations.push(location);
    }
  }

  @action removeLocation(location) {
    this.locations.remove(_.find(this.locations, {id: location.id}));
  }

  @action handleEmploymentTypeChange(change) {
    if (change.action === 'add') {
      this.addEmploymentType(change.value);
    } else if (change.action === 'remove') {
      this.removeEmploymentType(change.value);
    }
  }

  @action addEmploymentType(employmentType) {
    const existingEmploymentType = this.employmentTypes.includes(employmentType);
    if (!existingEmploymentType) {
      this.employmentTypes.push(employmentType);
    }
  }

  @action removeEmploymentType(employmentType) {
    this.employmentTypes.remove(employmentType);
  }

  @action addDepartment(department) {
    const existingDepartment = _.find(this.departments, {id: department.id});
    if (!existingDepartment) {
      this.departments.push(department);
    }
  }

  @action removeDepartment(department) {
    this.departments.remove(_.find(this.departments, {id: department.id}));
  }

  @action merge(other) {
    super.merge(other, {
      featurePermissions: [FeaturePermission],
      employeeDataPermissions: [DataPermission]
    });
    this.updateDataPermissions();
  }

  @action updateDataPermissions() {
    const givenDataPermissions = this.employeeDataPermissions;

    this.employeeDataPermissions = dataPermissions;
    for (const p of this.employeeDataPermissions) {
      const existing = _.find(givenDataPermissions, {name: p.name});

      if (existing) {
        p.merge(existing);
      }
    }
  }
}

export default Role;
