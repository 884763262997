import {DomainObject} from 'shared/store';
import {observable, action} from 'mobx';
import {Employee} from 'stores/employees';

class TimeTrackingProject extends DomainObject {
  @observable name;
  @observable employees;

  @action merge(other) {
    super.merge(other, {
      employees: [Employee],
    });
  }
}

export default TimeTrackingProject;
