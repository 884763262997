import LocalDataProxy from './LocalDataProxy';
import AsyncDataProxy from './AsyncDataProxy';
import ProxyDescriptor from './ProxyDescriptor';

function createDataProxy(proxyDescriptor) {
  const descriptor = new ProxyDescriptor(proxyDescriptor);
  switch (descriptor.type) {
    case 'local':
      return new LocalDataProxy(proxyDescriptor);
    case 'async':
      return new AsyncDataProxy(proxyDescriptor);
    default:
      throw new Error(`Proxy type ${proxyDescriptor.type} is not supported.`);
  }
}

export default createDataProxy;
