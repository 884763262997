import {DomainObject} from 'shared/store';
import {observable, computed} from 'mobx';
import {t} from 'shared/core';
import _ from 'lodash';

class ReportColumn extends DomainObject {
  @observable key;
  @observable order;
  @observable name;

  @computed get group() {
    return _.split(this.key, '.')[0];
  }

  @computed get translatable() {
    return this.group !== 'custom_fields';
  }

  @computed get view() {
    return this.translatable ? t(`reports.custom.edit.fields.${this.key}`) : this.name;
  }

  @computed get customFieldId() {
    if (this.group !== 'custom_fields') return '';
    return _.split(this.key, '.')[1];
  }
}

export default ReportColumn;
