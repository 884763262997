import {observable, computed} from 'mobx';
import {DomainStore} from 'shared/store';

class InsightsState {
  store = new DomainStore();
  Emoji;

  @observable inboxSummary;
  @observable ptoSummary;
  @observable newHireSummary;
  @observable jobApplicantSummary;

  receiveProps({inboxSummary, newHireSummary, ptoSummary, jobApplicantSummary, Emoji}) {
    this.inboxSummary = inboxSummary;
    this.newHireSummary = newHireSummary;
    this.ptoSummary = ptoSummary;
    this.jobApplicantSummary = jobApplicantSummary;
    this.Emoji = Emoji;
  }

  @computed get hasNoInsights() {
    return this.inboxSummary.itemsCount === 0 &&
      this.newHireSummary.newHireCount === 0 &&
      this.ptoSummary.requestsCount === 0 &&
      this.jobApplicantSummary.applicantCount === 0 &&
      this.jobApplicantSummary.staleApplicantCount === 0;
  }
}

export default InsightsState;
