import React from 'react';
import {observer} from 'mobx-react';
import {Announcement} from './announcement';
import {FirstDay, Anniversary, Birthday} from './celebrations';
import {types} from 'shared/core';

const FeedItemFactory = observer(({feedItem, ...rest}) => {
  switch (feedItem._type) {
    case 'announcements':
      return <Announcement announcement={feedItem} {...rest}/>;
    case types.HR_DASHBOARD.FIRST_DAY:
      return <FirstDay firstDay={feedItem} {...rest}/>;
    case types.HR_DASHBOARD.ANNIVERSARY:
      return <Anniversary anniversary={feedItem} {...rest}/>;
    case types.HR_DASHBOARD.BIRTHDAY:
      return <Birthday birthday={feedItem} {...rest}/>;
    default:
      throw new Error(`FeedItem type ${feedItem._type} not supported.`);
  }
});

export default FeedItemFactory;
