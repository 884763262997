import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {HelpLink} from 'components/links';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import Reviewers from './Reviewers';
import QuestionEmptyState from './QuestionEmptyState';
import {dateFormatter} from 'shared/tools';

const CurrentResults = observer(({questionSummary}) => {
  const {question, options, ratingMood, sentiment, periodDate, currentTrend, uiState} = questionSummary;
  const {Highcharts, HighchartsReact} = uiState;

  if (!currentTrend) {
    return <QuestionEmptyState/>;
  }

  return (
    <React.Fragment>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      <div className='medium mb3 relative'>
        <div className='table-cell align-middle'>
          <FormattedMessage id='surveys.view.General employee sentiment: '/>
        </div>
        <div className='table-cell align-middle h3 px1' style={{color: ratingMood.color}}>
          {`${ratingMood.text} (${sentiment})`}
        </div>
        <div className='table-cell align-middle'>
          <HelpLink explanation={{
            header: t('surveys.view.Weighted Average'),
            paragraphs: [
              t('surveys.view.WEIGHTED_AVG_1'),
              t('surveys.view.WEIGHTED_AVG_2'),
              t('surveys.view.WEIGHTED_AVG_3')
            ]
          }}/>
        </div>
      </div>
      <div className='clearfix mxn2'>
        <div className='col col-4 px2'>
          <Reviewers uiState={uiState} question={question} option={question.options[0]} numericValue={1} periodDate={periodDate} currentTrend={currentTrend}/>
        </div>
        <div className='col col-4 px1'>
          <Reviewers uiState={uiState} question={question} option={question.options[1]} numericValue={2} periodDate={periodDate} currentTrend={currentTrend}/>
        </div>
        <div className='col col-4 px2'>
          <Reviewers uiState={uiState} question={question} option={question.options[2]} numericValue={3} periodDate={periodDate} currentTrend={currentTrend}/>
        </div>
      </div>
      <div className='clearfix mxn2'>
        <div className='col col-4 px2'>
          <Reviewers uiState={uiState} question={question} option={question.options[3]} numericValue={4} periodDate={periodDate} currentTrend={currentTrend}/>
        </div>
        <div className='col col-4 px1'>
          <Reviewers uiState={uiState} question={question} option={question.options[4]} numericValue={5} periodDate={periodDate} currentTrend={currentTrend}/>
        </div>
      </div>
    </React.Fragment>
  );
});

const SentimentSummary = observer(({questionSummary}) => {
  const {question, trendsOptions, uiState} = questionSummary;
  const {Highcharts, HighchartsReact} = uiState;

  return (
    <Panel>
      <Panel.Header title={question.text} />
      {trendsOptions && uiState.hasTrends && <React.Fragment>
        <div className='medium mb2'>
          <FormattedMessage id='surveys.view.Past results'/>
        </div>
        <SentimentTrendsGraph
          options={trendsOptions}
          Highcharts={Highcharts}
          HighchartsReact={HighchartsReact}
        />
        <div className='medium my2'>
          <FormattedMessage
            id='surveys.view.PERIOD_RESULTS'
            values={{periodDate: dateFormatter(questionSummary.periodDate)}}
          />
        </div>
      </React.Fragment>}
      <CurrentResults questionSummary={questionSummary}/>
    </Panel>
  );
});

const SentimentTrendsGraph = observer(({options, Highcharts, HighchartsReact}) => {
  return <HighchartsReact highcharts={Highcharts} options={options}/>;
});

export default SentimentSummary;
