import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {Input, CheckboxWithLabel} from 'components';
import {auth} from 'shared/core';
import _ from 'lodash';

const ShareWithEmployeeCheckbox = observer(({uiState}) => {
  const {editingDocument} = uiState;

  if (String(_.get(editingDocument, 'employee.id')) === auth.employee.id || !_.get(editingDocument, 'canShare')) return null;

  return (
    <CheckboxWithLabel
      className='mt2'
      label='employees.profile.documents.Share with employee'
      name='shareWithEmployee'
      checked={editingDocument.shareWithEmployee}
      onChange={(e) => editingDocument.merge({shareWithEmployee: e.checked})} />
  );
});

const EditDocumentModal = observer(({uiState}) => {
  const {editingDocument} = uiState;

  return (
    <Modal isOpen={uiState.editDocumentModalOpen} onHide={() => uiState.closeEditDocumentModal()} size='sm' title='employees.profile.documents.Edit Document'>
      <form>
        <Input
          name='fileName' placeholder='employees.profile.documents.Document Name'
          value={editingDocument.fileName}
          onChange={(e) => editingDocument.merge({fileName: e.target.value})} />
        <ShareWithEmployeeCheckbox uiState={uiState} />
        <ModalButtons onCancel={() => uiState.closeEditDocumentModal()} onSave={() => uiState.updateDocument()} />
      </form>
    </Modal>
  );
});

export default EditDocumentModal;
