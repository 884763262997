import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {AdminContainerState} from '../state';
import Subnav from '../../Subnav';
import {Layout, Panel, OverviewPanel} from 'components';
import ResponseTable from './ResponseTable';
import EmployeeSelectorModal from './EmployeeSelectorModal';

const AdminContainerWithState = withState(observer(({uiState}) => {
  const {survey} = uiState;

  return (
    <Layout>
      <Layout.Section>
        <Panel.Stack loose>
          <OverviewPanel
            title='surveys.overview.Survey Overview'
            completed={survey.completeStatusCount}
            total={survey.totalStatusCount}
            items={[
              {
                title: 'components.overview_panel.Launch Date',
                value: survey.launchedAtView
              }
            ]}
            tags={uiState.surveyTags}
          />
          <ResponseTable uiState={uiState} />
          <EmployeeSelectorModal uiState={uiState} />
        </Panel.Stack>
      </Layout.Section>
    </Layout>
  );
}), AdminContainerState);

const AdminContainer = observer(({match, history, scope, survey}) => {
  return (
    <div>
      <Subnav {...{survey, match, scope}} />
      <AdminContainerWithState {...{match, history, scope, survey}} />
    </div>
  );
});

export default AdminContainer;
