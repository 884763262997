import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const PositionTitleView = observer(({position}) => {
  if (position.title) return <span>{position.title}</span>;
  if (!position.title) return <FormattedMessage id='recruiting.Untitled Position' />;
});

export default PositionTitleView;
