import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {PerformanceReviewTemplate} from 'stores/performance_reviews';
import {t} from 'shared/core';

class PerformanceReviewType extends DomainObject {
  @observable id;
  @observable kind;
  @observable template;

  @action merge(other) {
    super.merge(other, {
      template: PerformanceReviewTemplate
    });
  }

  @computed get displayKind() {
    return t(`models.performance_reviews.type.${this.kind}`);
  }
}

export default PerformanceReviewType;
