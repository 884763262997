import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Button, CheckboxWithLabel} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import InterestedInBenefitsModal from './InterestedInBenefitsModal';

const BenefitsNotEnabled = observer(({uiState}) => {
  const {notInterestedInBenefits, benefitsNotificationSent} = uiState;

  return (
    <div>
      <Panel.Header title={t('employees.hire.benefits.not_enabled.How should benefits be managed for this employee?')}/>
      <div className='flex justify-content-center align-items-middle'>
        <div className='flex flex-col'>
          <div>
            <FormattedMessage id='employees.hire.benefits.not_enabled.Did you know that Collage can automatically sync your benefits with almost any benefits provider?'/>
            <div className='ml1 mt1 clearfix'>
              <ul className='CheckmarkList col col-6 pr2'>
                <li><FormattedMessage id='employees.hire.benefits.not_enabled.features.One click plan assignment'/></li>
                <li><FormattedMessage id='employees.hire.benefits.not_enabled.features.Complete enrollment during HR onboarding'/></li>
                <li><FormattedMessage id='employees.hire.benefits.not_enabled.features.Multi-provider support'/></li>
                <li><FormattedMessage id='employees.hire.benefits.not_enabled.features.Enrollment automatically synced to carriers'/></li>
                <li><FormattedMessage id='employees.hire.benefits.not_enabled.features.Dependent Management'/></li>
              </ul>
              <ul className='CheckmarkList col col-6'>
                <li><FormattedMessage id='employees.hire.benefits.not_enabled.features.Switch providers without re-enrollment'/></li>
                <li><FormattedMessage id='employees.hire.benefits.not_enabled.features.Benefits coverage, dependent status, and custom benefits reports'/></li>
                <li><FormattedMessage id='employees.hire.benefits.not_enabled.features.Benefits plan summary portal for employees'/></li>
                <li><FormattedMessage id='employees.hire.benefits.not_enabled.features.Built-in benefits compliance'/></li>
              </ul>
            </div>
            <div className='mt1'>
              <FormattedMessage id="employees.hire.benefits.not_enabled.If you don't already have a benefits plan, we can set you up with a partner advisor who can take care of your benefits needs, and then sync your plan with Collage HR."/>
            </div>
          </div>
          {!benefitsNotificationSent && <div className='mt2 flex align-items-middle'>
            <Button className='Btn--lagoon mr2' onClick={() => uiState.sendBenefitsNotification()} disabled={notInterestedInBenefits}>
              <FormattedMessage id='employees.hire.benefits.not_enabled.Upgrade'/>
            </Button>
            <CheckboxWithLabel
              label="employees.hire.benefits.not_enabled.No, thanks. I'll manage benefits myself."
              checked={notInterestedInBenefits}
              onChange={(e) => uiState.updateBenefitsInterest(e.checked)}
            />
          </div>}
        </div>
      </div>
      <InterestedInBenefitsModal uiState={uiState}/>
    </div>
  );
});

export default BenefitsNotEnabled;
