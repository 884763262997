import React from 'react';
import {observer} from 'mobx-react';
import {Button} from 'components';
import {FormattedMessage} from 'react-intl';

const SummaryButtons = observer(({uiState}) => {
  return (
    <div className='table mt2'>
      <div className='table-cell col-6'>
        {uiState.trainingProgram.canDelete && <Button trait='destructive' onClick={() => uiState.openDeleteProgramModal()}>
          <FormattedMessage id='Delete'/>
        </Button>}
      </div>
      <div className='table-cell col-6 right-align'>
        {uiState.trainingProgram.canPatch && <Button trait='default' onClick={() => uiState.navigateToEdit()}>
            <FormattedMessage id='training.summary.Edit'/>
        </Button>}
      </div>
    </div>
  );
});

export default SummaryButtons;
