import EmploymentRecord from './EmploymentRecord';
import {action, computed, observable} from 'mobx';
import {types} from 'shared/core';
import {LeaveType} from 'stores/leave_types';
import {calendarDate} from 'shared/tools';

class OnLeaveRecord extends EmploymentRecord {
  @observable leaveType;
  @observable expectedReturnDate;
  @observable lastDayOfWorkBeforeLeave;
  @observable skipAccruals;

  @action merge(other) {
    super.merge(other, {
      leaveType: LeaveType,
      _dates: [
        'expectedReturnDate',
        'lastDayOfWorkBeforeLeave'
      ]
    });

    this._type = types.ON_LEAVE_RECORD;
  }

  @computed get expectedReturnDateView() {
    return calendarDate(this.expectedReturnDate);
  }
}

export default OnLeaveRecord;
