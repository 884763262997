import React from 'react';
import {observer} from 'mobx-react';
import Avatar from 'components/Avatar';
import {urls} from 'shared/core';

const EmployeeAvatar = observer(({employee, hideProfileLink}) => {
  if (hideProfileLink) return <Avatar employee={employee} size='sm'/>;

  return (
    <a href={urls.EMPLOYEE.with(employee.id)}>
      <Avatar employee={employee} size='sm'/>
    </a>
  );
});

const TableRowWithAvatar = observer(({employee, hideProfileLink, children}) => {
  return (
    <div>
      <div className='table-cell align-top pr2' style={{paddingTop: '3px'}}>
        <EmployeeAvatar employee={employee} hideProfileLink={hideProfileLink}/>
      </div>
      <div className='table-cell align-top'>
        {children}
      </div>
    </div>
  );
});

export default TableRowWithAvatar;
