import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import TimeOffType from './TimeOffType';

class TimeOffAccount extends DomainObject {
  @observable id;
  @observable timeOffType;
  @observable balance;
  @observable unlimited;

  @action merge(other) {
    super.merge(other, {
      timeOffType: TimeOffType
    });
  }

  @computed get balanceView() {
    return this.unlimited ? '∞' : this.balance;
  }

  @computed get balanceDescription() {
    return this.unlimited ? 'employees.profile.time_off.Unlimited' : 'employees.profile.time_off.Days Available';
  }
}

export default TimeOffAccount;
