import React from 'react';
import {observer} from 'mobx-react';
import {Avatar} from 'components';
import {t} from 'shared/core';

const ReviewerDetails = observer(({reviewer, reviewType}) => {
  return (
    <div>
      <div className='table-cell align-middle pr2 print-no-pad'>
        <Avatar employee={reviewer}/>
      </div>
      <div className='table-cell align-middle'>
        <div className='h3 medium black'>
          {`${reviewer.name} (${t(`models.performance_reviews.reviewer.type.${reviewType}`)})`}
        </div>
        <div className='h4 regular jumbo'>{reviewer.jobTitle}</div>
      </div>
    </div>
  );
});

export default ReviewerDetails;

