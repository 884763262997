import React from 'react';
import {FormattedMessage} from 'react-intl';
import Logo from 'img/collage-logo--blue@2x.png';
import {Button, UploadFiles} from 'components';
import SecureUploadState from 'secure_upload/state/SecureUploadState';
import secureUploadStore from 'stores/secure_upload/SecureUploadStore';
import {observer} from 'mobx-react';
import SuccessfullyUploaded from './SuccessfullyUploaded';

const SecureUploadForm = observer(({uiState}) => {
  const {secureFile, errors} = uiState;

  return (
    <div>
      <div className='bg-white border-bottom center py1'>
        <a href='https://collage.co' className='inline-block p2'>
          <img src={Logo} alt='Collage' height='26'/>
        </a>
      </div>
      <div className='ResponsiveContainer'>
        <div className='clearfix my4'>
          <div className='col-12 md-col-8 lg-col-6 max-width-3 mx-auto bg-white rounded box-shadow-2 px3 sm-px4 py3'>
            <div className='h1 medium center pb3 pt1 line-height-2'>
              <div className='pb1'>
                <FormattedMessage id='secure_upload.Securely upload and send files to Collage'/>
              </div>
            </div>
            <div className='clearfix'>
              <UploadFiles
                defaultFiles={secureFile.awsFiles}
                onUploadStarted={() => uiState.onUploadStarted()}
                onUploadFinished={() => uiState.onUploadFinished()}
                errorMessage={errors.awsFiles}
                isUploading={uiState.isUploading}
                onChange={files => secureFile.merge({awsFiles: files})}
                modelType='secure_upload/secure_file'
              />
              <div className='col col-12 my2'>
                <Button size='lg' className='full-width' disabled={uiState.isUploading} onClick={() => uiState.sendToCollage()}>
                  <FormattedMessage id='secure_upload.Upload to Collage'/>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const SecureUploadPage = observer(({uiState}) => {
  if (uiState.submitted) {
    return <SuccessfullyUploaded uiState={uiState}/>;
  }

  return <SecureUploadForm uiState={uiState}/>;
});

const uiState = new SecureUploadState(secureUploadStore);

export default () => <SecureUploadPage uiState={uiState}/>;
