import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Label, Panel} from 'components';
import {t} from 'shared/core';

const RestrictedInformation = observer(({uiState}) => {
  const {employee} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.personal.Basic Information')}/>
      <div className='col-12 sm-col-10'>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Name'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='name'/>
          </div>
        </div>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Work Email'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='workEmail'/>
          </div>
        </div>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Work Phone'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='workPhone'/>
          </div>
        </div>
        <div className='clearfix mxn1 pb1'>
          <div className='col col-6 px1'>
            <FormattedMessage id='employees.profile.personal.Work Phone Extension'/>
          </div>
          <div className='col col-6 px1'>
            <Label model={employee} path='workPhoneExtension'/>
          </div>
        </div>
      </div>
    </Panel>
  );
});

export default RestrictedInformation;
