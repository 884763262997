import {DomainObject, oneOf} from 'shared/store';
import {observable, action} from 'mobx';

class CompanyPlan extends DomainObject {
  @observable type;
  @observable title;
  @observable carrier;
  @observable subtitle;
  @observable createdAt;
  @observable tpa;

  @action merge(other) {
    super.merge(other, {
      type: oneOf([
        'benefit_class'
      ])
    });
  }
}

export default CompanyPlan;
