import React from 'react';
import {FormattedMessage} from 'react-intl';
import BlueCheckmark from 'img/circle-checkmark--blue@2x.png';

const SuccessfullyUploaded = ({uiState}) => {
  const {secureFile} = uiState;
  return (
    <div className='Container py4 slide-up'>
      <div className='clearfix'>
        <div className='col-6 mx-auto'>
          <div className='my4 p4 bg-white box-shadow-1 center'>
            <img className='mt3' src={BlueCheckmark} alt='checkmark' height='40'/>
            {secureFile.awsFiles.length === 1 && <div className='h2 my2'>
              <FormattedMessage id='secure_upload.File successfully sent to Collage!'/>
            </div>}
            {secureFile.awsFiles.length > 1 &&
            <div className='h2 my2'>
              <FormattedMessage id='secure_upload.FILES_SUCCESSFULLY_UPLOADED_TO_COLLAGE' values={{count: secureFile.awsFiles.length}} />
            </div>}
            <div className='jumbo block mb3'>
              <FormattedMessage id='secure_upload.Your Collage Account Manager will be in contact with you shortly. You can close this browser window.'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessfullyUploaded;

