import React from 'react';
import {observer} from 'mobx-react';
import {ActionLink} from 'components';
import {FormattedMessage} from 'react-intl';

const AddDependantLink = observer(({uiState}) => {
  return (
    <div className='mt2'>
      <ActionLink onClick={() => uiState.addNewDependent()}>
        <FormattedMessage id='+ Add a dependant'/>
      </ActionLink>
    </div>
  );
});

export default AddDependantLink;
