import React from 'react';
import {observer} from 'mobx-react';
import {Table} from 'components';
import DayOffHoursAndDays from 'components/DayOffHoursAndDays';
import moment from 'moment';
import {calendarDate} from 'shared/tools';

const DayOffDate = observer(({model}) => {
  const {date} = model;

  return (
    <div>
      <div>
        {calendarDate(date)}
      </div>
      <div className='jumbo'>
        {`${moment(date).format('dddd')} ${model.timeRange ? model.timeRange : ''}`}
      </div>
    </div>
  );
});

const COLUMNS = [
  {
    width: 6,
    component: DayOffDate
  },
  {
    width: 6,
    component: DayOffHoursAndDays
  },
];

const CustomHours = observer(({models}) => {
  return (
    <Table
      indexBy='date'
      showHeaders={false}
      showLinks={false}
      models={models}
      columns={COLUMNS}
      trait='xcompact'
    />
  );
});

export default CustomHours;
