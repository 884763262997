import React from 'react';
import {observer} from 'mobx-react';
import {FormLayout, Input, RadioButtonGroup, RadioButton} from 'components';
import {FormattedMessage} from 'react-intl';
import {spousalCoverageTypes} from 'stores/benefits';

const SpouseInsurerFields = observer(({benefitInfo, errors}) => {
  if (!benefitInfo.hasSpouse) return null;

  return (
    <FormLayout>
      <div>
        <div className='black'>
          <FormattedMessage id="What type of coverage does your spouse have?"/>
        </div>
        <div className='waterloo'>
          <FormattedMessage id="components.benefits.spouse_fields.SPOUSE_COVERAGE_TEXT"/>
        </div>
      </div>
      {spousalCoverageTypes.map(type => (
        <RadioButtonGroup
          key={type.attribute}
          name={type.attribute}
          label={type.label}
          onChange={(name, value) => benefitInfo.merge({[type.attribute]: value})}
          selectedValue={benefitInfo[type.attribute]}
          errorMessage={errors[type.attribute]}>
          <RadioButton className='block mb1' value='single'><FormattedMessage id='components.benefits.spouse_fields.Single'/></RadioButton>
          <RadioButton className='block my1' value='family'><FormattedMessage id='components.benefits.spouse_fields.Family'/></RadioButton>
          <RadioButton className='block my1' value='waived'><FormattedMessage id='components.benefits.spouse_fields.Waived'/></RadioButton>
          <RadioButton className='block my1' value='none'><FormattedMessage id='components.benefits.spouse_fields.None'/></RadioButton>
        </RadioButtonGroup>
      ))}
      {benefitInfo.spouseHasInsurer && <FormLayout.Group>
        <Input
          label="components.benefits.Spouse's Insurer"
          errorMessage={errors.spouseInsurerName}
          onChange={e => benefitInfo.merge({spouseInsurerName: e.target.value})}
          value={benefitInfo.spouseInsurerName}
        />
        <Input
          label="components.benefits.Spouse's Policy Number"
          errorMessage={errors.spousePolicyNumber}
          onChange={e => benefitInfo.merge({spousePolicyNumber: e.target.value})}
          value={benefitInfo.spousePolicyNumber}
        />
      </FormLayout.Group>}
    </FormLayout>
  );
});

export default SpouseInsurerFields;
