import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {MassUpdateEditorState} from '../state';
import {
  AutosaveStatus,
  FormError,
  Button,
  Display,
  Layout,
  Panel,
  Tag,
} from 'components';
import {FormattedMessage} from 'react-intl';
import MassUpdateEmployeeEditTable from './MassUpdateEmployeeEditTable';
import AddEmployeesModal from './AddEmployeesModal';
import PublishConfirmationModal from './PublishConfirmationModal';
import _ from 'lodash';
import BulkEditModal from 'containers/mass_updates/edit/components/BulkEditModal';
import {MassUpdateEditorFactory} from './mass_update_editor';

const PublishButton = observer(({uiState}) => {
  const {massUpdate} = uiState;

  if (!massUpdate.hasLink('publish')) {
    return null;
  }

  return (
    <div className='right'>
      <AutosaveStatus autosaver={massUpdate.autosaver}/>
      <Button className='pl2' onClick={() => uiState.openPublishConfirmationModal()}>
        <FormattedMessage id='mass_updates.edit.Publish'/>
      </Button>
    </div>
  );
});

const MassUpdateHeader = observer(({massUpdate}) => {
  return (
    <div className='flex align-items-middle'>
      <FormattedMessage id='mass_updates.edit.Mass Edit Employees'/>
      {massUpdate.running && <Tag className='h4 ml1 align-text-top' colour='meadow'>
        <FormattedMessage id='mass_updates.edit.Running'/>
      </Tag>}
    </div>
  );
});

const MassUpdateEditor = observer(({uiState}) => {
  const {massUpdate, errors} = uiState;

  return (
    <div>
      <Display
        title={<MassUpdateHeader massUpdate={massUpdate}/>}
        backPath='/'
      />
      <Layout>
        <Panel.Stack loose>
          <MassUpdateEditorFactory uiState={uiState}/>
          <MassUpdateEmployeeEditTable uiState={uiState}/>
          <FormError message={errors.base} alignRight/>
          <PublishButton uiState={uiState}/>
        </Panel.Stack>
      </Layout>
      <AddEmployeesModal uiState={uiState}/>
      <PublishConfirmationModal uiState={uiState}/>
      <BulkEditModal uiState={uiState}/>
    </div>
  );
});

export default withState(MassUpdateEditor, MassUpdateEditorState);
