import {action, computed, observable} from 'mobx';
import _ from 'lodash';
import moment from 'moment';

class FeedItemsState {
  @observable feedItems;
  @observable nextLink;
  @observable loadMore;
  @observable feedState;

  receiveProps({feedItems, nextLink, loadMore, feedState}) {
    this.feedItems = feedItems;
    this.nextLink = nextLink;
    this.loadMore = loadMore;
    this.feedState = feedState;
  }

  @action async load() {
    await this.loadMore();
  }

  @computed get sortedFeedItems() {
    return _.orderBy(this.feedItems, 'publishedAt', 'desc');
  }

  @computed get nextWeeksFeedItems() {
    return _.filter(this.sortedFeedItems, feedItem => {
      return feedItem.publishedAt > moment().toDate();
    });
  }

  @computed get currentFeedItems() {
    return _.filter(this.sortedFeedItems, feedItem => {
      return moment(feedItem.publishedAt).isSameOrBefore(moment(), 'day') && moment(feedItem.publishedAt).isSameOrAfter(moment().subtract(7, 'days'), 'day');
    });
  }

  @computed get olderFeedItems() {
    return _.filter(this.sortedFeedItems, feedItem => {
      return moment(feedItem.publishedAt).isSameOrBefore(moment().subtract(8, 'days'), 'day');
    });
  }
}

export default FeedItemsState;
