import React from 'react';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Panel, Button} from 'components';
import BenefitsSyncIllustration from 'img/benefits/benefits_sync_illustration@2x.png';
import BenefitsAdvisorIllustration from 'img/benefits/benefits_advisor_illustration@2x.png';

const BenefitsInfo = observer(({uiState}) => {
  return (
    <div>
      <div className='h2 py2 medium'>
        <FormattedMessage id='benefits.not_enabled.How Can Collage Help?'/>
      </div>
      <div className='flex flex-col md-flex-row'>
        <div className='col col-12 mb2 md-col-6 md-pr1'>
          <Panel className='full-height grid'>
            <div className='center mb4'>
              <Panel.Header title={t('benefits.not_enabled.You have a benefits plan already')}/>
              <img className='mt2' src={BenefitsSyncIllustration} alt='benefits_sync'/>
            </div>
            <div className='mb2'>
              <FormattedMessage id="benefits.not_enabled.BENEFITS_SYNC_DESC_1"/>
            </div>
            <FormattedMessage id="benefits.not_enabled.To get started, we'll need to speak with you to initiate the setup of benefits sync."/>
            <div className='mt2 center align-self-end'>
              <Button onClick={() => uiState.submit('benefits_sync')}>
                <FormattedMessage id="benefits.not_enabled.Learn More"/>
              </Button>
            </div>
          </Panel>
        </div>
        <div className='col col-12 mb2 md-col-6 md-pl1'>
          <Panel className='full-height grid'>
            <div className='center mb4'>
              <Panel.Header title={t("benefits.not_enabled.You don't have a benefits plan, but want one")}/>
              <img className='mt2' src={BenefitsAdvisorIllustration} alt='benefits_advisor'/>
            </div>
            <div className='mb2'>
              <FormattedMessage id="benefits.not_enabled.You'll need to speak with a benefits advisor, who will help you design a plan and get the best price."/>
            </div>
            <FormattedMessage id="benefits.not_enabled.Collage HR is not an advisor or broker ourselves, but we can set you up with a partner advisor who can take care of your benefits needs, and then sync your plan with Collage HR."/>
            <div className='mt2 center align-self-end'>
              <Button onClick={() => uiState.submit('benefits_advisor')}>
                <FormattedMessage id="benefits.not_enabled.Learn More"/>
              </Button>
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
});

export default BenefitsInfo;
