import React from 'react';
import {observer} from 'mobx-react';
import ActionLink from 'components/links/ActionLink';
import {FormattedMessage} from 'react-intl';

const AddNewModelLink = observer(({uiState, text}) => {
  if (!uiState.showAdd) return null;

  return (
    <ActionLink className='TextLink dodger pl1 py1 inline-block' onClick={() => uiState.onAdd()}>
      <FormattedMessage id={text}/>
    </ActionLink>
  );
});

export default AddNewModelLink;
