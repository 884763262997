import {action, observable, computed} from 'mobx';
import {redirect, successAlert} from 'shared/tools';
import {t, urls, endpoints, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import {Offer} from 'stores/offers';
import {auth} from 'shared/core';

class InboxState {
  store = new DomainStore();
  agent;

  @observable modalOpen = false;
  @observable task;

  @action async uncompleteTask() {
    const {model} = await this.store.patch(
      this.task.link('uncomplete'),
      this.task._type,
      this.task
    );

    if (model) {
      await this.agent.refresh();
      if (model.completed) {
        throw new Error('Failed to uncomplete the task.');
      } else {
        successAlert(t('inbox.UNCOMPLETED_TASK', {taskName: model.name}));
      }
      this.closeTaskModal();
    }
  }

  @action async completeTask() {
    const {model} = await this.store.patch(
      this.task.link('complete'),
      this.task._type,
      this.task
    );

    if (model) {
      await this.agent.refresh();
      if (model.completed) {
        successAlert(t('inbox.COMPLETED_TASK', {taskName: model.name}));
      } else {
        throw new Error('Failed to complete the task.');
      }
      this.closeTaskModal();
    }
  }

  @action async deleteTask() {
    await this.store.destroy(this.task);
    await this.agent.refresh();

    successAlert(t('inbox.TASK_DELETED', {taskName: this.task.name}));
    this.closeTaskModal();
  }

  @action viewTraining(task) {
    const trainingPath = urls.TRAINING.EMPLOYEE_STATUS.with(task.relatedTo.id);
    window.location.href = trainingPath;
  }

  @action async createEmployee(task) {
    await this.store._compose([endpoints.OFFER.with(task.relatedTo.id)]);
    const offer = new Offer(this.store._getSingle(types.OFFER));

    const {model} = await this.store.post(offer.link('hire'), types.EMPLOYEE_DETAILS);
    return redirect(urls.EMPLOYEE.with(model.id));
  }

  @action openTaskModal(task) {
    this.task = task;
    this.modalOpen = true;
  }

  @action closeTaskModal() {
    this.modalOpen = false;
  }

  @computed get isAssignee() {
    return this.task.assigneeEmployee.id === auth.employee.id;
  }

  setInteractiveAgent(agent) {
    this.agent = agent;
  }
}

export default InboxState;
