import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {rangeDate} from 'shared/tools';
import {Employee} from 'stores/employees';

class EmployeeTrainingStatusSummary extends DomainObject {
  @observable id;
  @observable employee;
  @observable dueDate;
  @observable completedAt;
  @observable completed;
  @observable programName;
  @observable totalAnswerCount;
  @observable correctAnswerCount;
  @observable hasIncompleteTraining;

  @action merge(other) {
    super.merge(other, {
      employee: Employee,
      _dates: ['dueDate', 'completedAt']
    });
  }

  @computed get dueDateView() {
    return rangeDate(this.dueDate);
  }

  @computed get completedAtView() {
    return rangeDate(this.completedAt);
  }

  @computed get quizScore() {
    if (!this.totalAnswerCount) return '--';

    return Math.round(this.correctAnswerCount / this.totalAnswerCount * 100);
  }

  @computed get quizResultsView() {
    if (!this.totalAnswerCount) return '--';

    return `${this.quizScore}% [${this.correctAnswerCount}/${this.totalAnswerCount}]`;
  }
}

export default EmployeeTrainingStatusSummary;
