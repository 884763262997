import React from 'react';
import {observer} from 'mobx-react';
import BrokerSignupContainer from './BrokerSignupContainer';
import BrokerMergeAccountState from './BrokerMergeAccountState';
import {withState, auth} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {Button, Panel, Layout, Avatar, InputError} from 'components';
import _ from 'lodash';

const SignedInAs = observer(() => {
  return (
    <div>
      <div className='table-cell align-middle pr2'>
        <Avatar employee={auth.user}/>
      </div>
      <div className={`table-cell align-middle`}>
        <div className='pb1'>
          <FormattedMessage id='broker_portal.signup.broker.SIGNED_IN_AS' values={{
            user: <b>{auth.user.name}</b>
          }}/>
        </div>
        <div>
          <a href='/user_session/new'>
            <FormattedMessage id='broker_portal.signup.broker.Not you?'/>
          </a>
        </div>
      </div>
    </div>
  );
});

const BrokerMergeAccount = observer(({uiState}) => {
  const {form} = uiState;

  return (
    <BrokerSignupContainer>
      <Layout centered>
        <Layout.Section secondary>
          <Panel>
            <div className='h2 medium center mb2'>
              <FormattedMessage id='broker_portal.signup.broker.get_started'/>
            </div>
            <div className='jumbo center'>
              <FormattedMessage
                id='broker_portal.signup.broker.MANAGE_BROKERAGE'
                values={{
                  brokerage: form.broker.name
                }}
              />
            </div>
            <div>
              <InputError message={_.get(uiState.errors, 'base')}/>
            </div>
            <div className='my3 border rounded bg-haze p2'>
              <SignedInAs/>
            </div>
            <Button onClick={() => uiState.acceptInvitation()} className='full-width'>
              <FormattedMessage id='broker_portal.signup.broker.Accept Invitation'/>
            </Button>
          </Panel>
        </Layout.Section>
      </Layout>
    </BrokerSignupContainer>
  );
});

export default withState(BrokerMergeAccount, BrokerMergeAccountState);
