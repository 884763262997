import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import LogoSpinner from 'components/spinners/logo/LogoSpinner';
import {Button} from 'components';

const Pending = observer(({name}) => {
  return (
    <div>
      <div className='waterloo pb2'>
        <FormattedMessage id='reports.REPORT_PREPARING'
                          values={{
                            reportName: <span className='dodger'>{name}</span>
                          }}/>
      </div>
      <div className='relative py4'>
        <LogoSpinner height='120' width='54' animate={true}/>
      </div>
    </div>
  );
});

const Failed = observer(({uiState}) => {
  return (
    <div>
      <div className='pb2'>
        <FormattedMessage id='reports.An error occurred while generating your report. Please try again.'/>
      </div>
      <div className='center py2'>
        <Button onClick={() => uiState.requestDownload()}>
          <FormattedMessage id='reports.Try again'/>
        </Button>
      </div>
    </div>
  );
});

const Ready = observer(({uiState}) => {
  const {reportDownload} = uiState;

  if (reportDownload.status === 'failed') {
    return (
      <Failed uiState={uiState}/>
    );
  }

  return (
    <div>
      <div className='waterloo pb2'>
        <FormattedMessage id='reports.Your export is ready. Click the button below to download it.'/>
      </div>
      <div className='center py2'>
        <a className='Btn Btn--primary' href={reportDownload.link('reportFile')} onClick={() => uiState.closeModal()} download>
          <span className='Btn--content'>
            <FormattedMessage id='reports.Download'/>
          </span>
        </a>
      </div>
    </div>
  );
});

const ModalContent = observer(({uiState, name}) => {
  if (uiState.pending) {
    return (
      <Pending name={name}/>
    );
  }

  return (
    <Ready uiState={uiState}/>
  );
});

const DownloadModal = observer(({uiState, name}) => {
  return (
    <Modal isOpen={uiState.modalOpen}
           onHide={() => uiState.closeModal()}
           closeButton={!uiState.pending}
           size='md'>
      <div className='h2 mb3'>
        <FormattedMessage id='reports.EXPORT_REPORT'
                          values={{
                            reportName: name
                          }}/>
      </div>
      <ModalContent uiState={uiState} name={name}/>
    </Modal>
  );
});

export default DownloadModal;
