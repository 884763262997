import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Avatar, ActionLinks, HtmlRender, Tooltip, TextArea, Button} from 'components';
import CommentForm from 'containers/performance_goals/components/view/components/CommentForm';
import {dateFormatter, longTimestampDate} from 'shared/tools';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const TimelineEntry = observer(({timelineEntry, uiState}) => {
  const {isEditingComment, editingComment, editingCommentChanged} = uiState;

  return (<div className='GoalTimelineEntry'>
    <div className='col col-1 pr1'>
      <Avatar employee={timelineEntry.user} size='sm'/>
    </div>
    <div className='col col-11 pl2'>
      <div className='col col-12'>
        <ActionLinks
          model={timelineEntry}
          action={() => uiState.editComment(timelineEntry)}
          editingComment={editingComment}
          onRemoveClicked={() => uiState.openDeleteCommentModal(timelineEntry)}
        />
        {timelineEntry.actionDescription}
        <Tooltip content={longTimestampDate(timelineEntry.createdAt)}>
          <div className='h5 jumbo'>{dateFormatter(timelineEntry.createdAt)}</div>
        </Tooltip>
      </div>
      {timelineEntry.text && <div className='col col-12 pt2 whitespace-pre-line word-break'>
        {isEditingComment && editingComment.id === timelineEntry.id ?
          <div>
            <TextArea
              placeholder='goals.Edit comment'
              value={editingComment.text}
              onChange={e => uiState.updateEditingComment(e.target.value)}
            />
            <div className='mt1 right-align'>
              <Button trait='default' size='sm' className='px2' onClick={() => uiState.cancelEditingComment()}>
                <FormattedMessage id='goals.Cancel'/>
              </Button>
              <Button size='sm' onClick={() => uiState.saveComment()} disabled={!editingCommentChanged}>
                <FormattedMessage id='goals.Update Comment'/>
              </Button>
            </div>
          </div>
          :
          <HtmlRender html={timelineEntry.text}/>
        }
      </div>}
    </div>
  </div>);
});

const Timeline = observer(({uiState}) => {
  const {goal} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('goals.Timeline')}/>
      <CommentForm uiState={uiState}/>
      {goal.sortedGoalTimelineEntries.map(timelineEntry =>
        <TimelineEntry key={timelineEntry.id} timelineEntry={timelineEntry} uiState={uiState}/>)}
    </Panel>
  );
});

export default Timeline;
