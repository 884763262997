import React from 'react';
import {observer} from 'mobx-react';
import {ObjectSelect2, FormLayout, FormError} from 'components';
import {withState, t} from 'shared/core';
import {Spinner} from 'components';
import {SelectReviewersState} from '../state';
import {ModalButtons} from 'components/modals';
import RevieweeSummary from './RevieweeSummary';

const LoadingState = observer(({isOpen, reviewee}) => {
  return (
    <div className='height-400'>
      <RevieweeSummary reviewee={reviewee}/>
      <Spinner/>
    </div>
  );
});

const SelectManager = observer(({uiState}) => {
  return (
    <ObjectSelect2
      label='performance_reviews.manage.Manager reviewer'
      helpLink={{
        title: t('components.help_link.Who is this?'),
        explanation: {
          header: 'performance_reviews.manage.MANAGER_HELP_HEADER',
          paragraphs: [
            t('performance_reviews.manage.MANAGER_HELP_DESC_1'),
            t('performance_reviews.manage.MANAGER_HELP_DESC_2'),
            t('performance_reviews.manage.MANAGER_HELP_DESC_3')
          ]
        }
      }}
      items={uiState.employeesWithReviewers}
      value={uiState.selectedManager}
      onChange={manager => uiState.updateManagerReviewer(manager)}
      searchable
      allowClear
    />
  );
});

const SelectPeers = observer(({uiState}) => {
  if (!uiState.showPeerSelector) return null;

  return (
    <ObjectSelect2
      label='performance_reviews.manage.Peer reviewers'
      items={uiState.employeesWithReviewers}
      value={uiState.selectedPeers}
      onAdd={peer => uiState.addPeer(peer)}
      onRemove={peer => uiState.removePeer(peer)}
      placeholder='performance_reviews.manage.Add peer'
      multiple
    />
  );
});

const SelectReviewers = observer(({uiState}) => {
  const {errors} = uiState;
  return (
    <div>
      <FormLayout>
        <RevieweeSummary reviewee={uiState.reviewee}/>
        <SelectManager uiState={uiState}/>
        <SelectPeers uiState={uiState}/>
        <FormError message={errors.base}/>
      </FormLayout>
      <ModalButtons onCancel={uiState.onHide} onSave={() => uiState.saveReviewers()}/>
    </div>
  );
});

export default withState(
  SelectReviewers,
  SelectReviewersState,
  {
    spinner: LoadingState
  }
);
