import React from 'react';
import {observer} from 'mobx-react';
import {Avatar} from 'components';
import {FormattedMessage} from 'react-intl';

const Assignee = observer(({model}) => {
  if (!model.isSpecificPerson) {
    return <FormattedMessage id={model.assigneeType}/>;
  }

  return (
    <span>
      <div className='table-cell'><Avatar employee={model.assignee} size='sm'/></div>
      <div className='table-cell pl2'>{model.assigneeName}</div>
    </span>
  );
});

export default Assignee;
