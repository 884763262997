import React from 'react';
import {PanelTooltip} from 'components';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const GraphTabs = observer(({uiState}) => {
  const {selectedGraph, enableComparativeGraphs} = uiState;

  return (
    <ul className='Subnav-list list-reset mb2-important'>
      <li>
        <a onClick={() => uiState.selectGraph('barGraph')} className={selectedGraph === 'barGraph' ? 'active' : ''}>
          <FormattedMessage id='performance_reviews.view.cycle_results.Bar Graph'/>
        </a>
      </li>
      <li>
        <a onClick={() => uiState.selectGraph('distribution')} className={selectedGraph === 'distribution' ? 'active' : ''}>
          <FormattedMessage id='performance_reviews.view.cycle_results.Distribution'/>
        </a>
      </li>
      <li>
        <a
          onClick={() => enableComparativeGraphs && uiState.selectGraph('heatmap')}
          className={`${selectedGraph === 'heatmap' ? 'active' : ''} ${!enableComparativeGraphs ? 'disabled' : ''}`}
        >
          <FormattedMessage id='performance_reviews.view.cycle_results.Heatmap'/>
          {!enableComparativeGraphs && <span className='tuatara'>
            <PanelTooltip text='performance_reviews.view.cycle_results.You must have 2 unique scored questions in your cycle to view this graph.'/>
          </span>}
        </a>
      </li>
      <li>
        <a
          onClick={() => enableComparativeGraphs && uiState.selectGraph('ninebox')}
          className={`${selectedGraph === 'ninebox' ? 'active' : ''} ${!enableComparativeGraphs ? 'disabled' : ''}`}
        >
          <FormattedMessage id='performance_reviews.view.cycle_results.9-Box'/>
          {!enableComparativeGraphs && <span className='tuatara'>
            <PanelTooltip text='performance_reviews.view.cycle_results.You must have 2 unique scored questions in your cycle to view this graph.'/>
          </span>}
        </a>
      </li>
    </ul>
  );
});

export default GraphTabs;
