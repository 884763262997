import React from 'react';
import {observer} from 'mobx-react';
import {Button, Checkbox} from 'components';
import SignedInAs from './SignedInAs';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const UserRow = observer(({user}) => {
  return (
    <div className='col col-12 p2 border-bottom'>
      <div className='clearfix table px1'>
        <div className='col-1 table-cell align-middle center'>
          <Checkbox checked={user.selected} onChange={() => user.selected = !user.selected}/>
        </div>
        <div className='col-6 table-cell align-middle'>
          <div className='clearfix pl2'>
            <div className='table-cell align-middle pl1'>
              <div>{user.name}</div>
            </div>
          </div>
        </div>
        <div className='col-5 table-cell align-middle'>
          <div>{user.email}</div>
        </div>
      </div>
    </div>
  );
});

const SelectAllCheckbox = observer(({uiState}) => {
  return (
    <div className='clearfix table px2 py1 border-top border-right border-left'>
      <div className='clearfix table px1'>
        <div className='col-1 table-cell align-middle center'>
          <Checkbox value={uiState.selectAll} checked={uiState.selectAll} onChange={() => uiState.toggleSelectAll()}/>
        </div>
        <div className='col-6 table-cell align-middle'>
          <div className='clearfix pl2'>
            <div className='jumbo pl1'>{uiState.selectAll ? t('employees.google_import.Deselect All') : t('employees.google_import.Select All')}</div>
          </div>
        </div>
        <div className='col-5 table-cell'></div>
      </div>
    </div>
  );
});

const UserList = observer(({uiState}) => {
  return (
    <div>
      <SelectAllCheckbox uiState={uiState}/>
      <div className='clearfix border height-250'>
        <div className='height-250 overflow-auto'>
          {uiState.googleUsers.map(user => <UserRow user={user} key={user.email}/>)}
        </div>
      </div>
    </div>
  );
});

const SelectEmployeesScreen = ({uiState}) => {
  return (
    <div>
      <SignedInAs uiState={uiState}/>
      <div className='waterloo pb2'>
        <FormattedMessage id='employees.google_import.SELECT_EMPLOYEES'/>
      </div>
      <UserList uiState={uiState}/>
      <div className='mt3 right-align'>
        <Button trait='default' onClick={() => uiState.cancelImport()} className='mr2'>
          <FormattedMessage id='employees.google_import.Cancel'/>
        </Button>
        <Button onClick={() => uiState.addEmployees()}>
          <FormattedMessage id='employees.google_import.Import'/>
        </Button>
      </div>
    </div>
  );
};

export default observer(SelectEmployeesScreen);
