/* eslint-disable */
import React, {Component} from 'react';
import {observer, PropTypes as MobxP} from 'mobx-react';
import P from 'prop-types';

import {NODE_HEIGHT, NODE_WIDTH} from '../../constants';

const AvatarInitials = ({initials, colour}) => {
  return (
    <div className={`node__initials ${colour}`}>
      {initials}
    </div>
  );
};

const AvatarImage = ({src}) => {
  return (
    <div className="node__avatar">
      <img src={src} alt="avatar"/>
    </div>
  );
};

const Avatar = ({employee}) => {
  const avatar = _.get(employee, 'links.profilePicture');
  if (avatar) {
    return <AvatarImage src={avatar}/>;
  }

  return <AvatarInitials initials={employee.avatarInitials} colour={employee.avatarColour}/>;
};

@observer class Node extends Component {
  toggleChildren = e => {
    e.stopPropagation()
    if (this.props.employee.children && this.props.employee.children.length) {
      this.props.toggleChildren(this.props.employee.id)
    }
  }

  hl = () => {
    this.props.highlight(this.props.employee.id)
  }

  render() {
    const {employee, left, top} = this.props;

    return (
      <div className={`node__content ${this.props.isActive
        ? 'state_active'
        : ''}`} onClick={this.hl} style={{
        width: NODE_WIDTH,
        height: NODE_HEIGHT,
        transform: `translate(${left}px, ${top}px)`
      }}>
        <Avatar employee={employee}/>
        {!employee.profileUrl && <span className="node__name">{employee.name}</span>}
        {employee.profileUrl && <a className="node__name" href={employee.profileUrl} target='_blank'>{employee.name}</a>}
        <span className="node__job-title">{employee.jobTitle}</span>

        {employee.children.length
          ? <div className="node__toggle-open" onClick={this.toggleChildren}>
              <span>{employee.children.length}</span>
            </div>
          : null
}
      </div>
    )
  }
}

Node.propTypes = {
  left: P.number,
  top: P.number,
  isActive: P.bool,
  employee: P.shape({
    id: P.string.isRequired,
    name: P.string,
    links: P.shape({profilePicture: P.string}),
    children: MobxP.arrayOrObservableArrayOf(P.string).isRequired
  }).isRequired,
  toggleChildren: P.func.isRequired,
  highlight: P.func.isRequired
}

export default Node;
