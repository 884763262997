import {DomainStore} from 'shared/store';
import ChargifyDirectParameters from './ChargifyDirectParameters';
import {endpoints, types} from 'shared/core';

class ChargifyStore extends DomainStore {
  async loadChargifyDirectParameters(callId) {
    await this._compose([
      endpoints.BILLING.CHARGIFY_DIRECT.with(callId)
    ]);

    return new ChargifyDirectParameters(
      this._getSingle(types.BILLING.CHARGIFY_DIRECT_PARAMETERS)
    );
  }

  async checkChargifyDirectCallResult(callId) {
    return this.post(
      endpoints.BILLING.CHARGIFY_DIRECT.with(callId),
      types.BILLING.CHARGIFY_DIRECT_PARAMETERS
    );
  }
}

const singleton = new ChargifyStore();

export default singleton;
