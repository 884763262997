import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Tag} from 'components';

const ReportName = observer(({report}) => {
  const {name} = report;

  if (!name) {
    return <FormattedMessage id='reports.custom.Untitled report'/>;
  }

  return (
    <div>
      <span>
        {name}
      </span>
      {!report.sharedBy && report.shares.length > 0 && <span className='pl1'>
        <Tag colour='turquoise'>
          <FormattedMessage id={'reports.custom.Shared'} />
        </Tag>
      </span>}
    </div>
  );
});

export default ReportName;
