import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {BenefitClassCoveragesState} from '../state';
import {Coverages} from 'components/benefits';
import CardDetails from './CardDetails';
import {Display} from 'components';

const BenefitClassCoverages = observer(({uiState}) => {
  const {benefitClasses, currentCoverage, currentClass, backPath} = uiState;

  return (
    <div>
      <Display nested
        title={currentClass.name} 
        backPath={backPath}
        backTitle={t('benefit_enrolment.All Classes')}
      />
      <Coverages
        benefitClasses={benefitClasses}
        onCoverageSelected={(coverage) => uiState.viewCoverage(coverage)}
        currentCoverage={currentCoverage}
        currentClass={currentClass}>
        <CardDetails uiState={uiState} />
      </Coverages>
    </div>
  );
});

export default withState(BenefitClassCoverages, BenefitClassCoveragesState);
