import React from 'react';
import {observer} from 'mobx-react';
import {t, types, endpoints, withState } from 'shared/core';
import {InteractiveTable} from 'components';
import AdminInvitation from 'stores/users/AdminInvitation';
import PendingInvitationsState from './PendingInvitationsState';
import {calendarDate} from 'shared/tools';

const COLUMNS = [
  {
    header: 'company_settings.external_users.Name',
    attribute: 'name',
    width:  3
  },
  {
    header: 'company_settings.external_users.Email',
    attribute: 'email',
    width: 5
  },
  {
    header: 'company_settings.external_users.Expires On',
    width: 3,
    render: model => calendarDate(model.expiresAt)
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'company_settings.external_users.Remove External User from Collage?',
    subHeader: 'company_settings.external_users.Are you sure you want to remove this external user from Collage?',
    body: 'company_settings.external_users.This user will no longer have access to Collage.'
  },
  emptyState: 'company_settings.external_users.No external users to display'
};

const PendingInvitationsContainer = observer(({uiState}) => {
  return (
    <InteractiveTable
      onMount={agent => uiState.setInteractiveAgent(agent)}
      title={t('company_settings.external_users.Pending Invitations')}
      columns={COLUMNS}
      searchable={false}
      showLinks={true}
      localization={LOCALIZATION}
      onRemove={(invitation) => uiState.destroyPendingInvitation(invitation)}
      customLinks={invitation => uiState.customLinksFor(invitation)}
      proxy={{
        type: 'async',
        modelType: types.ADMIN_INVITATION,
        model: AdminInvitation,
        endpoint: endpoints.ADMIN_INVITATIONS
      }}
    />
  );
});

export default withState(PendingInvitationsContainer, PendingInvitationsState);
