import {DomainStore} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {endpoints, types, t, auth} from 'shared/core';
import {successAlert} from 'shared/tools';
import {BenefitClass, CompanyInsuranceCarrier, EmployeeBenefitEnrollment} from 'stores/benefits';
import {Employee} from 'stores/employees';
import _ from 'lodash';
import EnrolmentViewModel from './EnrolmentViewModel';

class EmployeeBenefitChangeForm {
  store = new DomainStore();
  employeeId;
  history;

  @observable employee;
  @observable enrolmentViewModels = [];
  @observable carriers = [];
  @observable errors = {};

  receiveProps({employeeId, history}) {
    this.employeeId = employeeId;
    this.history = history;
  }

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEE_DETAILS.with(this.employeeId),
      endpoints.EMPLOYEE.EMPLOYEE_BENEFIT_ENROLMENTS.with(this.employeeId),
      endpoints.COMPANY_INSURANCE_CARRIERS.WITH_OPTIONAL
    ]);

    const enrolments = this.store._getAll(
      types.EMPLOYEE_BENEFIT_ENROLLMENT, EmployeeBenefitEnrollment
    );

    this.enrolmentViewModels = enrolments.map(e => new EnrolmentViewModel(e));

    this.carriers = this.store._getAll(
      types.COMPANY_INSURANCE_CARRIER, CompanyInsuranceCarrier
    );

    this.benefitClasses = this.store._getAll(
      types.BENEFIT_CLASS, BenefitClass
    );

    this.employee = new Employee(
      this.store._getSingle(types.EMPLOYEE_DETAILS, { id: this.employeeId })
    );
  }

  @action async saveChanges() {
    const viewModels = _.filter(this.enrolmentViewModels, viewModel => viewModel.enrolmentChanged);
    const enrolments = viewModels.map(viewModel => viewModel.enrolment);

    if (_.isEmpty(enrolments)) {
      return this.history.push('/benefit_enrolments');
    }

    const {model, errors} = await this.store.post(
      endpoints.EMPLOYEE.CHANGE_ENROLMENTS.with(this.employeeId),
      types.EMPLOYEE_DETAILS,
      { employeeBenefitEnrollments: enrolments }
    );

    this.errors = errors;
    if (model) {
      successAlert(t('benefit_enrolment.change.Benefit enrolment classes changed'));
      this.history.push('/benefit_enrolments');
    }
  }

  @computed get platform() {
    if (auth.company.enrolmentOnly) return 'Benefits HQ';

    return 'Collage';
  }

  benefitClassesForCarrier(enrolment) {
    const carrier = _.find(
      this.carriers, c => _.find(c.benefitClasses, benefitClass => benefitClass.id === enrolment.benefitClass.id)
    );
    return carrier.benefitClasses;
  }
}

export default EmployeeBenefitChangeForm;
