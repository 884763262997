import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {HtmlRender, Avatar, ActionLink, AttachedDocumentDownload} from 'components';

const SenderAvatar = observer(({entry, candidate}) => {
  let sender;
  if (entry.automated) sender = {};
  else if (entry.external) sender = { avatarColour: 'bg-waterloo', avatarInitials: entry.externalUserInitials };
  else if (entry.outbound) sender = entry.user;
  else if (entry.inbound) sender = candidate;

  return <Avatar employee={sender} collageAvatar={entry.automated} size='sm' className='left'/>;
});

const ReplyMenu = ({entry, detailsState}) => {
  if (!entry.inbound || entry.external) return null;

  return (
    <div onClick={async () => detailsState.reply(entry)} className='right py1 px3 regular jumbo clickable'>
      <i className='material-icons submarine align-middle' data-toggle='dropdown'>{'reply'}</i>
    </div>
  );
};

const FromSender = observer(({entry, candidate}) => {
  let from;
  if (entry.automated) from = 'Collage HR';
  else if (entry.external) from = entry.from;
  else if (entry.inbound) from = candidate.name;
  else if (entry.outbound) from = entry.user.name;

  return (
    <div className='h5 jumbo'>
      <FormattedMessage id='recruiting.hiring_funnel.SENDER' values={{from}}/>
    </div>
  );
});

const ToReceiver = observer(({entry, candidate}) => {
  const to = entry.outbound ? candidate.name : entry.name;

  return (
    <div className='h5 jumbo'>
      <FormattedMessage id='recruiting.hiring_funnel.RECEIVER' values={{to}} />
    </div>
  );
});

const EmailEntry = observer(({entry, detailsState, candidate}) => {
  return (
    <div className='clearfix border-top p3'>
      <div className='col col-1 pr1'>
        <SenderAvatar entry={entry} candidate={candidate} />
      </div>
      <div className='col col-11 pl1'>
        <div className='clearfix'>
          <div ref={r => detailsState.setRefFor(entry, r)} className='col col-12 overflow-hidden'>
            <ReplyMenu entry={entry} detailsState={detailsState} />
            <div className='pr1'>
              <div>
                <span className='h3 medium'>
                  {entry.inbound && <FormattedMessage id='recruiting.hiring_funnel.Received Email' />}
                  {entry.outbound && <FormattedMessage id='recruiting.hiring_funnel.Sent Email' />}
                </span>
                <span className='h5 jumbo pl1'>{entry.date}</span>
                {entry.status && <span className={`h5 jumbo right ${entry.statusColour}`}>
                  <FormattedMessage id={`recruiting.hiring_funnel.email_status.${entry.status}`}/>
                </span>}
              </div>
              <FromSender entry={entry} candidate={candidate} />
              <ToReceiver entry={entry} candidate={candidate} />
            </div>
            <div className='col col-12 pt2 h3 medium word-break'>{entry.subject}</div>
            <HtmlRender className='max-height-500' html={entry.body}/>
          </div>
          <div className='col col-12 space-y mt2'>
            {entry.attachments.map(attachment => <AttachedDocumentDownload key={attachment.id} doc={attachment}/>)}
          </div>
        </div>
        {detailsState.displayViewEmailLinks && detailsState.emailOverflowing(entry) && <div className='pt2'>
          <ActionLink onClick={() => detailsState.openEmailModal(entry)}>
            <FormattedMessage id='recruiting.hiring_funnel.View Entire Email'/>
          </ActionLink>
        </div>}
      </div>
    </div>
  );
});

export default EmailEntry;
