import React from 'react';
import {observer} from 'mobx-react';
import {Panel, FormError, Spinner} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import ReviewType from './ReviewType';
import _ from 'lodash';

const ReviewTypes = observer(({uiState}) => {
  const {loadingTemplates, reviewTypes, errors} = uiState;
  if (loadingTemplates) return <Spinner/>;

  return (
    <React.Fragment>
      <div className='jumbo mb2'>
        <FormattedMessage id='performance_reviews.edit.choose_questions'/>
      </div>
      {reviewTypes.map((viewModel, i) =>
        <ReviewType
          key={viewModel.data.kind}
          viewModel={viewModel}
          errors={_.get(errors, ['types', viewModel.data.kind], {})}
        />
      )}
      <div className='border-top'>
        <FormError message={errors.types}/>
      </div>
    </React.Fragment>
  );
});

const Questions = observer(({uiState}) => {
  return (
    <div>
      <Panel.Header title={t('performance_reviews.edit.Review Types & Question Sets')}/>
      <ReviewTypes uiState={uiState}/>
    </div>
  );
});

export default Questions;
