import {action, computed, observable} from 'mobx';
import {DomainObject} from 'shared/store';
import Employee from 'stores/employees/Employee';
import CompanyDocument from 'stores/documents/CompanyDocument';
import {calendarDate} from 'shared/tools';

class DocumentAction extends DomainObject {
  @observable id;
  @observable createdAt;
  @observable actionedAt;
  @observable dueDate;

  @observable employee;
  @observable companyDocumentParent;
  @observable companyDocument;

  @action merge(other) {
    super.merge(other, {
      employee: Employee,
      companyDocumentParent: CompanyDocument,
      companyDocument: CompanyDocument,
      _dates: ['actionedAt']
    });
  }

  @computed get sentDate() {
    return calendarDate(this.createdAt);
  }

  @computed get completedDate() {
    return calendarDate(this.actionedAt);
  }

  @computed get formattedDueDate() {
    return calendarDate(this.dueDate);
  }
}

export default DocumentAction;
