import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {EmployeeNameWithAvatar, ActionLink, Accordion, Panel} from 'components';
import {TextFieldResultState} from '../state';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';

const TextFieldResult = observer(({uiState}) => {
  const {question, reviews, reviewerViewModels} = uiState;

  if (_.isEmpty(reviews)) {
    return (
      <div className='Table mb2'>
        <Panel.Header title={`${question.order + 1}. ${question.name}`}/>
        <div className='Table-emptyState py2 px1 center rounded jumbo'>
          <FormattedMessage id='There are no results to show.'/>
        </div>
      </div>
    );
  }

  return (
    <div className='mb2'>
      <Panel.Header title={`${question.order + 1}. ${question.name}`}/>
      {reviewerViewModels.map(viewModel => {
        const {reviewer} = viewModel;

        return (
          <div key={reviewer.id}
            onClick={() => viewModel.toggleExpansion()}
            className={`border HoverAnimation-trigger ${viewModel.expanded ? 'my2' : ''}`}>
            <div className='table px2 py1'>
              <div className='col-6 table-cell align-middle'>
                <div className='table-cell align-middle pr1'>
                  <EmployeeNameWithAvatar employee={reviewer}/>
                </div>
                <div className='table-cell align-middle waterloo'>
                  <FormattedMessage
                    id='performance_reviews.summary.SUBMITTED_REVIEWS'
                    values={{count: viewModel.reviews.length}}
                  />
                </div>
              </div>
              <div className='col-6 table-cell align-middle right-align'>
                <ActionLink subdued>
                  <span className='HoverAnimation-target--show'>
                    <FormattedMessage id={`performance_reviews.summary.${viewModel.expanded ? 'HIDE' : 'VIEW'}`}/>
                  </span>
                </ActionLink>
              </div>
            </div>
            <Accordion active={viewModel.expanded}>
              {viewModel.reviews.map(review => {
                const answer = _.find(review.answers, { question: { id: question.id } });
                return (
                  <div key={answer.id} className='p2 border-top'>
                    <EmployeeNameWithAvatar size='xs' employee={review.reviewee.employee}/>
                    <div className='mt1 jumbo'>{answer.value}</div>
                  </div>
                );
              })}
            </Accordion>
          </div>
        );
      })}
    </div>
  );
});

export default withState(TextFieldResult, TextFieldResultState);
