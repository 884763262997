import React from 'react';
import {observer} from 'mobx-react';
import Position from 'stores/recruiting/Position';
import {withState, t, endpoints, types} from 'shared/core';
import {Display, Layout, InteractiveTable, Button} from 'components';
import {FormattedMessage} from 'react-intl';
import {ReferralDirectoryState} from '../state';

const EmploymentType = observer(({model}) => {
  return (
    <FormattedMessage id={model.employmentType}/>
  );
});

const SubmitReferral = observer(({model, uiState}) => {
  return (
    <div className='flex justify-content-end'>
      <Button trait='default' onClick={() => uiState.goToCandidateCreation(model)}>
          <FormattedMessage id={'referrals.directory.Submit Referral'}/>
      </Button>
    </div>
  );
});

const COLUMNS = [
  {
    header: 'referrals.directory.Title',
    width: 3,
    render: model => <a target='_blank' href={model.viewPostingLink}>{model.title}</a>,
    sortBy: 'title'
  },
  {
    header: 'referrals.directory.Department',
    width: 2,
    attribute: 'department.name',
  },
  {
    header: 'referrals.directory.Location',
    width: 2,
    attribute: 'location.name'
  },
  {
    header: 'referrals.directory.Employment Type',
    width: 2,
    component: EmploymentType,
    sortBy: 'employmentType'
  },
  {
    width: 3,
    component: SubmitReferral,
  }
];

const ReferralDirectory = observer(({uiState}) => {
  return (
    <div>
      <Display
        title={t('referrals.directory.Job Referrals')}
      />
      <Layout>
        <InteractiveTable
          uiState={uiState}
          columns={COLUMNS}
          showLinks={false}
          proxy={{
            type: 'async',
            modelType: types.RECRUITING.POSITION,
            model: Position,
            endpoint: endpoints.RECRUITING.POSITIONS_FOR_REFERRALS
          }}
        />
      </Layout>
    </div>
  );
});

export default withState(ReferralDirectory, ReferralDirectoryState);
