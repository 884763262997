import _ from 'lodash';
import * as Sentry from "@sentry/react";

export function extractGoogleError(e) {
  if (_.get(e, 'error') === 'popup_closed_by_user') {
    return {
      code: e.error
    };
  }

  return {
    code: _.get(e, 'result.error.errors[0].reason') || '',
    message: _.get(e, 'result.error.errors[0].message') || ''
  };
}

// these errors are gracefully handled on the front end; no need to report them to Sentry
const IGNORED_GOOGLE_ERRORS = [
  'popup_closed_by_user',
  'domainCannotUseApis',
  'notFound'
];

export function reportError(errorCode) {
  if (_.includes(IGNORED_GOOGLE_ERRORS, errorCode)) return;

  Sentry.captureException(new Error(`Google Import Failure: ${errorCode}`));
}
