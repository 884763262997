import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class Document extends DomainObject {
  @observable createdAt;
  @observable fileName;
  @observable contentType;
  @observable shareWithEmployee;
  @observable canShare;
  @observable fileUrl;
  @observable fileSize;
  @observable fileType;
  @observable key;
  @observable folder;
  @observable category;
}

export default Document;
