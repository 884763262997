import {observable, action} from 'mobx';
import {DomainStore} from 'shared/store';
import getPerformanceReviewTypes from './performanceReviewTypes';
import {
  PerformanceReviewTemplate,
  PerformanceReviewCycle,
  PerformanceReviewReviewee
} from 'stores/performance_reviews';
import {endpoints, types} from 'shared/core';
import _ from 'lodash';

class ReviewEditState {
  store = new DomainStore();
  match;
  history;

  @observable cycle = new PerformanceReviewCycle();
  @observable errors = {};
  @observable reviewTypes = [];
  @observable availableTemplates = [];
  @observable selectedEmployees = [];
  @observable loadingTemplates = false;

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    await this.store._compose([
      endpoints.PERFORMANCE_REVIEWS.CYCLE_WITH_REVIEWEES.with(this.match.params.cycleId)
    ]);

    this.cycle = new PerformanceReviewCycle(
      this.store._getSingle(types.PERFORMANCE_REVIEWS.CYCLE)
    );

    this.redirectIfAlreadyLaunched();
  }

  redirectIfAlreadyLaunched() {
    switch (this.cycle.state) {
      case 'launched':
      case 'ended':
        this.history.push(`/reviews/${this.cycle.id}`);
        break;
      case 'created':
      case 'configured':
      default:
        break;
    }
  }

  @action async finish() {
    this.history.push(`/reviews/${this.cycle.id}/admin`);
  }

  async submitStep(props) {
    const cycle = this.cycle.pick(props);
    const {model, errors} = await this.store.patch(cycle);
    this.errors = errors;
    if (!model) return false;

    this.cycle.update(model);
  }

  @action async submitName() {
    return this.submitStep([
      'name'
    ]);
  }

  @action async submitQuestions() {
    const selectedTypes = _.chain(this.reviewTypes)
      .filter('checked')
      .map('data')
      .value();

    this.cycle.types = selectedTypes;
    return this.submitStep([
      'types'
    ]);
  }

  @action async submitParticipants() {
    this.cycle.reviewees = this.selectedEmployees.map(e => new PerformanceReviewReviewee({employee: e}));
    return this.submitStep([
      'reviewees'
    ]);
  }

  @action async onStepSubmitted({location}) {
    switch (location) {
      case 'name': return this.submitName();
      case 'questions': return this.submitQuestions();
      case 'participants': return this.submitParticipants();
      case 'review': return this.finish();
      default:
        throw new Error(`Location ${location} is not supported.`);
    }
  }

  @action async onStepChanged({location}) {
    switch (location) {
      case 'questions':
        await this.initializeReviewTemplates();
        this.initializeReviewTypes();
        break;
      case 'participants':
        this.initializeEmployees();
        break;
      default:
        break;
    }
  }

  @action initializeEmployees() {
    this.selectedEmployees = _.map(this.cycle.reviewees, 'employee');
  }

  @action updateSelectedEmployees(employees) {
    this.selectedEmployees.replace(employees);
  }

  @action async initializeReviewTemplates() {
    this.loadingTemplates = true;
    await this.store._compose([endpoints.REVIEW_TEMPLATES.ALL]);
    this.availableTemplates = this.store._getAll(types.PERFORMANCE_REVIEWS.TEMPLATE, PerformanceReviewTemplate);
    this.loadingTemplates = false;
  }

  @action initializeReviewTypes() {
    const reviewTypes = getPerformanceReviewTypes(this);

    for (const type of reviewTypes) {
      const existingType = _.find(this.cycle.types, { kind: type.data.kind });
      if (!existingType) continue;

      type.data.merge(existingType);
      type.checked = true;
    }

    this.reviewTypes = reviewTypes;
  }
}

export default ReviewEditState;
