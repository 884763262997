import {observer} from 'mobx-react';
import SearchBox from 'components/tables/interactive/components/search_box/SearchBox';
import ScopeFilter from 'components/tables/interactive/components/scope_filter/ScopeFilter';
import FancyFilterPills from 'components/tables/interactive/components/fancy_filter/FancyFilterPills';
import React from 'react';

export const FilterRow = observer(({children, fancyFiltersEnabled, searchable, scopeOptions, ...rest}) => {
  return (
    <div className={`FilterRow flex ${fancyFiltersEnabled ? 'align-items-start' : 'align-items-center'} ${(searchable || fancyFiltersEnabled) ? 'mb2 pb2 px2 mxn2 border-bottom' : 'FilterRow-unsearchable'}`}>
      <div className='flex flex-wrap flex-grow row-gap-1 align-self-center'>
        {searchable && <SearchBox {...rest}/>}
        {scopeOptions && <div className='flex align-items-center'>
          <ScopeFilter options={scopeOptions} {...rest}/>
        </div>}
        {fancyFiltersEnabled && <div className='flex align-items-start'>
          <FancyFilterPills {...rest}/>
        </div>}
      </div>
      <div className={`${searchable ? 'FilterGroup-searchable' : ''} flex align-items-center flex-wrap ml2`}>
        {React.Children.map(children, c => c && React.cloneElement(c, {...rest}))}
      </div>
    </div>
  );
});
