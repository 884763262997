import {DomainObject} from 'shared/store';
import {action, computed, observable} from 'mobx';
import _ from 'lodash';

class QAAnswer extends DomainObject {
  @observable value;
  @observable question;
  @observable correct;

  @action updateValue(value) {
    this.value = value;
  }

  @computed get formattedValue() {
    if (this.question.questionType === 'multi_select') {
      return _.join(this.value || [], ', ');
    } else {
      return this.value;
    }
  }
}

export default QAAnswer;
