import {DomainStore} from 'shared/store';
import {types, endpoints} from 'shared/core';
import Presigner from './Presigner';
import AttachedDocument from './AttachedDocument';
import {api, auth} from 'shared/core';

/*
  The purpose of this store is uploading files to S3, agnostically of
  where those files are actually used.

  Usually, after using this store to upload a file to S3,
  you associate said file with your model in your own store and save the model.

  For an example, see secure upload.
*/

function parseXMLKey(xmlFile) {
  const parser = new DOMParser();
  const xml = parser.parseFromString(xmlFile.data, 'text/xml');
  return xml.getElementsByTagName('Key')[0].childNodes[0].nodeValue;
}

class AwsFileStore extends DomainStore {
  async uploadFile(file, modelType) {
    const presigner = await this.fetchPresigner(modelType);

    const {url, data, headers} = presigner.getAwsRequestParams(file);
    const aws = await api.post(url, data, { headers });
    const key = parseXMLKey(aws);
    const contentType = file.type || 'application/octet-stream';

    return new AttachedDocument({
      key,
      fileName: file.name,
      fileSize: file.size,
      contentType: contentType,
      label: file.label,
      previewUrl: file.preview
    });
  }

  async fetchPresigner(modelType = 'secure_upload/secure_file') {
    const route = auth.broker ?
      endpoints.BROKER_PORTAL.SECURE_UPLOAD.PRESIGNER.with(modelType) :
      endpoints.SECURE_UPLOAD.PRESIGNER.with(modelType);
    const presigner = await this.fetch(
      route, types.PRESIGNER
    );
    return new Presigner(presigner);
  }
}

export default AwsFileStore;
