import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Tag} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import Field from './Field';
import _ from 'lodash';

const DependentFields = observer(({dependent, order}) => {
  return (
    <div className={`clearfix ${order > 1 ? 'pt2 mt2 border-top' : ''}`}>
      <div className='mb1'>
        <div className='table-cell align-middle medium'>
          <FormattedMessage id='benefit_enrolment.DEPENDANT_ORDER' values={{order}}/>
        </div>
        {!dependent.eligible && <div className='table-cell align-middle px2'>
          <Tag colour='rose'>
            <FormattedMessage id='benefit_enrolment.Ineligible'/>
          </Tag>
        </div>}
      </div>
      <Field title="First Name" value={dependent.firstName} translate={false}/>
      <Field title="Last Name" value={dependent.lastName} translate={false}/>
      <Field title="Date of Birth" value={dependent.birthDateView} translate={false}/>
      <Field title="Sex at Birth" value={t(`benefit_enrolment.${dependent.gender}`)}/>
      <Field title="Student" value={t(`benefit_enrolment.${dependent.student}`)}/>
      {dependent.student && <Field title="Institution Name" value={dependent.institutionName}/>}
      {dependent.student && <Field title="Education Start Date" value={dependent.educationStartDateView}/>}
      {dependent.student && <Field title="Expected Graduation Date" value={dependent.expectedGraduationDateView}/>}
      <Field title="Disabled Adult" value={t(`benefit_enrolment.${dependent.disabledAdult}`)}/>
    </div>
  );
});

const DependantInfo = observer(({benefitInfo}) => {
  if (_.isEmpty(benefitInfo.dependents)) {
    return (
      <span className='jumbo'>
        <FormattedMessage id='benefit_enrolment.Employee does not have any dependants.'/>
      </span>
    );
  }

  return benefitInfo.dependents.map((d, index) => <DependentFields key={d.id} order={index + 1} dependent={d}/>);
});

const Dependants = observer(({uiState}) => {
  const {benefitInfo} = uiState;

  return (
    <Panel>
      <div className='medium mb2'>
        <FormattedMessage id='employees.profile.benefits.Dependants'/>
      </div>
      <DependantInfo benefitInfo={benefitInfo}/>
    </Panel>
  );
});

export default Dependants;
