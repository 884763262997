import React from 'react';
import {observer} from 'mobx-react';
import {CustomField, EmployeeCustomFields} from 'components';
import _ from 'lodash';

const EmploymentRecordCustomFields = observer(({editRecord, errors}) => {
  return (
    <EmployeeCustomFields employeeCustomFields={editRecord.employmentRecordCustomFields} errors={errors} category='job' useFormLayout>
      {(employmentRecordCustomField, error) => (
        <CustomField
          customField={employmentRecordCustomField}
          key={employmentRecordCustomField.id}
          errorMessage={error ? error.value : null}
        />
      )}
    </EmployeeCustomFields>
  );
});

export default EmploymentRecordCustomFields;
