import {computed, observable} from 'mobx';
import {payPeriodsPerYear} from 'shared/constants';
import numeral from 'numeral';
import {formatCurrency} from 'shared/core';

class EarningsFormatter {
  @observable model;

  constructor(model) {
    this.model = model;
  }

  @computed get annualizedSalary() {
    return payPeriodsPerYear(this.model.payRateUnit, this.model.hoursPerWeek) * numeral(this.model.payRate).value();
  }

  @computed get formattedBaseInsurableEarnings() {
    return formatCurrency(this.model.payRateCurrency, this.annualizedSalary);
  }

  @computed get formattedAnnualizedCommission() {
    return formatCurrency(this.model.payRateCurrency, numeral(this.model.annualizedCommission).value());
  }

  @computed get formattedTotalInsurableEarnings() {
    const totalEarnings = this.annualizedSalary + numeral(this.model.annualizedCommission).value();

    return formatCurrency(this.model.payRateCurrency, totalEarnings);
  }
}

export default EarningsFormatter;
