import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Select2, AvatarList} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';
import QuestionSummaryFactory from './QuestionSummaryFactory';
import ReviewersModal from './ReviewersModal';

const ReviewTypeNav = observer(({uiState}) => {
  const {types, currentType} = uiState;

  return (
    <div id='employee-navigation'>
      <div className='Employee-nav h1 medium black table-cell'>
        <Select2
          value={currentType.id}
          onChange={e => uiState.setCurrentType(e.target.value)}
          placeholder={`models.performance_reviews.type.${currentType.kind}`}
        >
          {types.map(type =>
            <option key={type.id} value={type.id}>
              {t(`models.performance_reviews.type.${type.kind}`)}
            </option>)}
        </Select2>
      </div>
    </div>
  );
});

const QuestionSummaryList = observer(({uiState}) => {
  const {currentQuestions, currentReviews, currentReviewers} = uiState;

  return (
    <Panel.Stack loose>
      <Panel>
        <Panel.Header
          title={<ReviewTypeNav uiState={uiState}/>}
          description={
            <FormattedMessage
              id='performance_reviews.summary.SUBMITTED_COUNT'
              values={{count: currentReviews.length}}
            />
          }
        />
        <AvatarList linkable moreModalEnabled limit={4} type='separate' employees={currentReviewers} />
      </Panel>
      {currentQuestions.map(question => <Panel key={question.id}>
        <QuestionSummaryFactory key={question.id} {...{question, uiState}} />
      </Panel>)}
      <ReviewersModal uiState={uiState} />
    </Panel.Stack>
  );
});

export default QuestionSummaryList;
