import _ from 'lodash';
import {observable, action} from 'mobx';
import {api, endpoints} from 'shared/core';

class BenefitsNotEnabledState {
  @observable interestedInBenefitsModalOpen = false;

  @action openInterestedInBenefitsModal() {
    this.interestedInBenefitsModalOpen = true;
  }

  @action closeInterestedInBenefitsModal() {
    this.interestedInBenefitsModalOpen = false;
  }

  @action async submit(benefitServiceType) {
    await api.post(endpoints.BENEFITS.INTERESTED, {benefitServiceType, source: 'benefits_page'});
    this.openInterestedInBenefitsModal();
  }
}

export default BenefitsNotEnabledState;
