import React from 'react';
import {observer} from 'mobx-react';
import ActionButtons from './ActionButtons';
import ReviewerAvatars from './ReviewerAvatars';
import ManagerAvatar from './ManagerAvatar';
import {InteractiveTable, EmployeeNameWithAvatar} from 'components';
import {t, types, withState} from 'shared/core';
import {PerformanceReviewReviewee} from 'stores/performance_reviews';
import {SelectReviewersModal} from './select_reviewers';
import {RevieweeDirectoryState} from '../state';

const COLUMNS = [
  {
    header: 'performance_reviews.manage.Reviewee',
    width: 3,
    render: model => <EmployeeNameWithAvatar employee={model.employee}/>
  },
  {
    header: 'performance_reviews.manage.Manager',
    width: 2,
    component: ManagerAvatar
  },
  {
    header: 'performance_reviews.manage.Reviewers',
    width: 4,
    component: ReviewerAvatars
  },
  {
    width: 3,
    className: 'right-align',
    component: ActionButtons
  }
];

const LOCALIZATION = {
  interactive: {
    addModel: 'performance_reviews.manage.+ Add Participant'
  }
};

const RevieweeDirectory = observer(({uiState}) => {
  const {cycle} = uiState;

  return (
    <div>
      <InteractiveTable
        uiState={uiState}
        title={t('performance_reviews.manage.Participants')}
        description={t('performance_reviews.manage.DESCRIPTION')}
        showLinks={false}
        columns={COLUMNS}
        localization={LOCALIZATION}
        onAdd={() => uiState.goToEditParticipants()}
        onMount={agent => uiState.setInteractiveAgent(agent)}
        proxy={{
          type: 'async',
          modelType: types.PERFORMANCE_REVIEWS.REVIEWEE,
          model: PerformanceReviewReviewee,
          endpoint: cycle.link('reviewees')
        }}>
      </InteractiveTable>
      <SelectReviewersModal
        cycle={cycle}
        reviewee={uiState.selectedReviewee}
        isOpen={uiState.reviewerSelectionModalOpen}
        onHide={() => uiState.closeReviewerSelectionModal()}
        onSave={() => uiState.onReviewersSelected()}
      />
    </div>
  );
});

export default withState(RevieweeDirectory, RevieweeDirectoryState);
