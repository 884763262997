import React from 'react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const ArchiveProjectModal = ({uiState, modalOpen}) => {
  return (
    <Modal
      title='time_tracking.manage.projects.archive.Archive Project'
      size='sm'
      isOpen={modalOpen}
      onHide={() => uiState.closeArchiveProjectModal()}
    >
      <div className='mb3 jumbo'>
        <FormattedMessage id={'time_tracking.manage.projects.archive.DESCRIPTION'}/>
      </div>
      <ModalButtons
        saveCaption='time_tracking.manage.projects.archive.Archive'
        onSave={() => uiState.archiveProject()}
        onCancel={() => uiState.closeArchiveProjectModal()}/>
    </Modal>
  );
};

export default ArchiveProjectModal;
