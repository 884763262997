import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {FormError, Panel, ObjectSelect2} from 'components';
import _ from 'lodash';

const IncludedFieldsForGroup = observer(({fieldGroup, uiState}) => {
  const {massUpdate, fields, updatingIncludedFields} = uiState;

  const includedFields = fields.filter(
    field => _.get(field, 'groupId') === fieldGroup.id && _.includes(massUpdate.includedFields, field.id)
  );

  if (_.isEmpty(includedFields)) return null;

  return (
    <div className='mb1'>
      <div className='medium mb1'>{fieldGroup.name}</div>
      <div className='flex flex-wrap'>
        {includedFields.map(field => (
          <div key={field.id} className='fields-pill mr1 mb1'>
            <div className='whitespace-nowrap'>{field.name}</div>
            {!massUpdate.readOnly && <i className={`material-icons h4 ml1 ${updatingIncludedFields ? 'disabled' : ''}`} onClick={() => uiState.removeIncludedField(field.id)}>{'close'}</i>}
          </div>
        ))}
      </div>
    </div>
  );
});

const PersonalMassUpdateEditor = observer(({uiState}) => {
  const {massUpdate, fields, fieldGroups, updatingIncludedFields, errors} = uiState;

  return (
    <Panel>
      <Panel.Header
        title={t('mass_updates.edit.Attributes')}
        description={t('mass_updates.edit.attributes_explainer')}
      />
      {!massUpdate.readOnly && <div className='mb2'>
        <ObjectSelect2
          value={fields.filter(field => _.includes(massUpdate.includedFields, field.id))}
          multiple
          items={fields}
          groups={fieldGroups}
          onAdd={field => uiState.addIncludedField(field.id)}
          placeholder=' '
          disabled={massUpdate.readOnly || updatingIncludedFields}
          hideSelected
        />
        <FormError message={errors.includedFields}/>
      </div>}
      {fieldGroups.map(fieldGroup => <IncludedFieldsForGroup fieldGroup={fieldGroup} uiState={uiState}/>)}
    </Panel>
  );
});

export default PersonalMassUpdateEditor;
