import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const DisconnectInstructionsModal = observer(({uiState}) => {
  return (
    <AlertModal
      size='md'
      mode='danger'
      isOpen={uiState.modalOpen}
      onHide={() => uiState.hideModal()}
      header='company_settings.applications.slack.Disconnect Workspace'
      subHeader='company_settings.applications.slack.Are you sure you want to disconnect your Slack workspace?'
      body='company_settings.applications.slack.This will disconnect the Slack account of every user.'
    >
      <ModalDangerButtons
        saveCaption='company_settings.applications.slack.Disconnect'
        onCancel={() => uiState.hideModal()}
        onSave={() => uiState.disconnect()}
      />
    </AlertModal>
  );
});

export default DisconnectInstructionsModal;
