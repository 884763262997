import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import chargifyStore from 'stores/billing/ChargifyStore';
import countryStore from 'stores/countries/CountryStore';
import ChargifyState from 'company_settings/billing/state/ChargifyState';
import {loader} from 'shared/core';
import {Input, FormError, ObjectSelect2, MonthSelect, Select2} from 'components';
import {FormattedMessage} from 'react-intl';

const FormRow = observer(({record, name, className, children}) => {
  return (
    <div className={`${className ? className : 'my3'}`}>
      <div className='h4 medium mb1'><FormattedMessage id={`company_settings.billing.${name}`}/></div>
      {children}
    </div>
  );
});

const Errors = observer(({errors}) => {
  if (!errors.base) return null;

  return (
    <div className='mb3 border rounded bg-haze p2'>
      <div className='h3 mb2 tuatara medium'>
        <FormattedMessage id='There was a problem updating your credit card:'/>
      </div>
      <FormError message={errors.base} className='relative'/>
    </div>
  );
});

const HiddenChargifyFields = observer(({params}) => {
  return (
    <div>
      <input type='hidden' name='secure[api_id]' value={params.apiId} />
      <input type='hidden' name='secure[timestamp]' value={params.timestamp} />
      <input type='hidden' name='secure[nonce]' value={params.nonce} />
      <input type='hidden' name='secure[data]' value={params.data} />
      <input type='hidden' name='secure[signature]' value={params.signature} />
    </div>
  );
});

const ChargifyPaymentFormContent = observer(({uiState}) => {
  const params = uiState.chargifyDirectParameters;
  const {submitting, errors} = uiState;

  useEffect(() => {
    uiState.publishEvent('resize');
  }, [params]);

  return (
    <form method='post' action={params.link('cardUpdate')} className='px2'>
      <Errors errors={errors}/>
      <HiddenChargifyFields params={params}/>

      <div className='clearfix'>
        <FormRow name='First Name' className='col col-6 pr2'>
          <Input
                 name='payment_profile[first_name]'
                 value={params.firstName}
                 onChange={e => params.merge({firstName: e.target.value})}
                 errorMessage={errors.firstName}/>
        </FormRow>
        <FormRow name='Last Name' className='col col-6 pl2'>
          <Input
                 name='payment_profile[last_name]'
                 value={params.lastName}
                 onChange={e => params.merge({lastName: e.target.value})}
                 errorMessage={errors.lastName}/>
        </FormRow>
      </div>
      <div className='clearfix my3'>
        <FormRow name='Card Number' className='col col-8 pr2'>
          <Input
                 name='payment_profile[card_number]'
                 value={params.cardNumber}
                 onChange={e => params.merge({cardNumber: e.target.value})}
                 errorMessage={errors.cardNumber}/>
        </FormRow>
        <FormRow name='CVV' className='col col-4 pl2'>
          <Input
                 name='payment_profile[cvv]'
                 value={params.cvv}
                 onChange={e => params.merge({cvv: e.target.value})}
                 errorMessage={errors.cvv}/>
        </FormRow>
      </div>
      <div className='clearfix my3'>
        <FormRow name='Expiration Month' className='col col-6 pr2'>
          <input type='hidden' name='payment_profile[expiration_month]' value={params.expirationMonth}/>
          <MonthSelect selectedMonth={params.expirationMonthIndex}
                       showNumbers={true}
                       onChange={e => params.merge({expirationMonthIndex: e.target.value})}
          />
        </FormRow>
        <FormRow name='Expiration Year' className='col col-6 pl2'>
          <Select2 name='payment_profile[expiration_year]'
                   searchable={true}
                   value={params.expirationYear}
                   onChange={e => params.merge({expirationYear: e.target.value})}
                   errorMessage={errors.expirationYear}>
            {uiState.years.map(year => <option value={year} key={year}>{year}</option>)}
          </Select2>
        </FormRow>
      </div>
      <FormRow name='Billing Address (line 1)'>
        <Input
               name='payment_profile[billing_address]'
               value={params.billingAddress}
               onChange={e => params.merge({billingAddress: e.target.value})}
               errorMessage={errors.billingAddress}/>
      </FormRow>
      <FormRow name='Billing Address (line 2)'>
        <Input
               name='payment_profile[billing_address_2]'
               value={params.billingAddress2}
               onChange={e => params.merge({billingAddress2: e.target.value})}
               errorMessage={errors.billingAddress2}/>
      </FormRow>
      <div className='clearfix'>
        <FormRow name='Country' className='col col-6 pr2'>
          <ObjectSelect2
            name='payment_profile[billing_country]'
            value={params.billingCountry}
            searchable={true}
            onChange={country => uiState.changeCountry(country)}
            items={uiState.countries}
            errorMessage={errors.billingCountry}
          />
        </FormRow>
        <FormRow name='Province' className='col col-6 pl2'>
          <ObjectSelect2
            name='payment_profile[billing_state]'
            value={params.billingState}
            searchable={true}
            onChange={region => params.merge({billingState: region})}
            items={uiState.regions}
            errorMessage={errors.billingState}
          />
        </FormRow>
      </div>
      <div className='clearfix my3'>
        <FormRow name='City' className='col col-6 pr2'>
          <Input
                 name='payment_profile[billing_city]'
                 value={params.billingCity}
                 onChange={e => params.merge({billingCity: e.target.value})}
                 errorMessage={errors.billingCity}/>
        </FormRow>
        <FormRow name='Postal Code' className='col col-6 pl2'>
          <Input
                 name='payment_profile[billing_zip]'
                 value={params.billingZip}
                 onChange={e => params.merge({billingZip: e.target.value})}
                 errorMessage={errors.billingZip}/>
        </FormRow>
      </div>
      <div className='mt2 right-align'>
        <button disabled={submitting} className='Btn Btn--primary' type='submit' onClick={() => uiState.submitForm()}>
          <span className='Btn--content' tabIndex='-1'>
            <FormattedMessage id='Submit'/>
          </span>
        </button>
      </div>
    </form>
  );
});

async function load() {
  const uiState = new ChargifyState({
    chargifyStore,
    countryStore
  });
  await uiState.load();
  return {uiState};
}

export default loader(ChargifyPaymentFormContent, load);
