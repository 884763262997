import {observable} from 'mobx';
import _ from 'lodash';

class SectionViewModel {
  @observable caption;
  @observable suggestionViewModels = [];
  @observable priority;

  constructor(suggestions, query) {
    this.suggestionViewModels = suggestions;
    this.caption = this.suggestionViewModels[0].data.suggestionType;

    this.priority = _.startsWith(this.suggestionViewModels[0].data.searchableCaption, query.toLowerCase()) ? 1 : 0;
    this.priority += this.suggestionViewModels[0].data.weight;
  }
}

export default SectionViewModel;
