import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import {BenefitUpdate} from 'stores/benefits';
import _ from 'lodash';
import moment from 'moment';
import animateScrollTo from 'animated-scroll-to';

class BenefitUpdatesState {
  store = new DomainStore();
  @observable updates = [];
  @observable hasResults;

  @action async load() {
    await this.store._compose([
      endpoints.BENEFITS.UPDATES
    ]);

    this.updates = this.store._getAll(
      types.BENEFITS.UPDATE,
      BenefitUpdate
    );

    if (this.updates.length) {
      this.hasResults = true;
    }
  }

  @action onPagination(data) {
    this.updates = data;
    this.scrollToTopOfPage();
  }

  @computed get updatesByDate() {
    const groupedUpdates = _.groupBy(
      this.updates,
      u => moment(u.createdAt).format('YYYYMMDD')
    );

    const groups = _.map(groupedUpdates,
      items => ({
        date: items[0].createdAt,
        items
      })
    );

    return _.orderBy(groups, 'date', 'desc');
  }

  scrollToTopOfPage() {
    animateScrollTo(0, {minDuration: 500, maxDuration: 1000});
  }
}

export default BenefitUpdatesState;
