import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {ClientsDirectory} from './directory';
import {ClientProfilePage} from './profile';
import {ClientEditContainer} from './edit';

const ClientsPage = ({match, history}) => {
  return (
    <Switch>
      <Route exact path={`${match.url}/directory`} component={ClientsDirectory}/>
      <Route exact path={`${match.url}/new`} component={ClientEditContainer}/>
      <Route path={`${match.url}/edit/:id`} component={ClientEditContainer}/>
      <Route path={`${match.url}/:id`} component={ClientProfilePage}/>
      <Redirect exact from={match.url} to={`${match.url}/directory`}/>
    </Switch>
  );
};

export default ClientsPage;
