import {action, computed, observable} from 'mobx';
import {endpoints, api, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import _ from 'lodash';
import {CompanyLink} from 'stores/company_links';

class CompanyLinkListState {
  store = new DomainStore();

  @observable errors = {};
  @observable companyLinks = [];  
  @observable newCompanyLink;
  @observable reorderingLinks;
  @observable editCompanyLinkModalOpen = false;
  @observable reorderLinksModalOpen = false;

  @action async load() {
    await this.store._compose([
      endpoints.COMPANY_LINKS.ALL
    ]);

    this.companyLinks = this.store._getAll(types.COMPANY_LINK, CompanyLink);
  }

  @action openReorderLinksModal() {
    this.reorderingLinks = this.companyLinks.map(link => new CompanyLink(link));
    this.reorderLinksModalOpen = true;
  }

  @action closeReorderLinksModal() {
    this.reorderLinksModalOpen = false;
  }

  @action openEditCompanyLinkModal(companyLink) {
    this.newCompanyLink = new CompanyLink(companyLink);
    this.editCompanyLinkModalOpen = true;
  }

  @action closeEditCompanyLinkModal() {
    this.editCompanyLinkModalOpen = false;
    this.errors = {};
  }

  @action async deleteCompanyLink(companyLink) {
    await this.store.destroy(companyLink);
    await this.load();
  }

  @action async saveLinkOrders() {
    const linkParams = {};
    this.reorderingLinks.forEach(link => linkParams[link['id']] = link['order']);

    await api.patch(endpoints.COMPANY_LINKS.REORDER, {'company_links': linkParams});

    await this.load();
    this.closeReorderLinksModal();
  }

  @action async saveCompanyLink() {
    const {model, errors} = this.newCompanyLink.isNew ?
      await this.store.post(endpoints.COMPANY_LINKS.ALL, types.COMPANY_LINK, this.newCompanyLink) :
      await this.store.patch(this.newCompanyLink);
    if(model){
      this.closeEditCompanyLinkModal();
      await this.load();
    }
    this.errors = errors;
  }

  @computed get sortedCompanyLinks(){
    return _.sortBy(this.companyLinks, 'order');
  }
}

export default CompanyLinkListState;
