import React from 'react';
import {FormattedMessage} from 'react-intl';

const CandidateDetailsEmptyState = () => {
  return (
    <div className='col col-8 box-shadow-1 min-height-800 overflow-auto bg-white relative'>
      <div className='h2 center jumbo abs-center'>
        <FormattedMessage id='recruiting.hiring_funnel.No candidate has been selected'/>
      </div>
    </div>
  );
};

export default CandidateDetailsEmptyState;
