import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Tag} from 'components';
import {calendarDate} from 'shared/tools';
import {FormattedMessage} from 'react-intl';
import PayrollSections from './PayrollSections';
import DetailsPanel from '../DetailsPanel';

const DateHeader = observer(({diff}) => {
  return (
    <div>
      {calendarDate(diff.recordedOn)}
      {diff.newHire && <Tag className='ml2'>
        <FormattedMessage id='payroll.diffs.New Hire'/>
      </Tag>}
      {diff.terminated && <Tag colour='rose' className='ml2'>
        <FormattedMessage id='payroll.diffs.Termination'/>
      </Tag>}
    </div>
  );
});

const PayrollDiffs = observer(({uiState}) => {
  return(
    <Panel.Stack loose>
      <DetailsPanel uiState={uiState} />
      {uiState.diffs.map(diff =>
          <Panel.Stack key={diff.id}>
            <Panel>
              <Panel.Header title={<DateHeader diff={diff}/>}/>
            </Panel>
            <PayrollSections diff={diff} />
          </Panel.Stack>
      )}
    </Panel.Stack>
  );
});

export default PayrollDiffs;
