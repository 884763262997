import React from 'react';
import {observer} from 'mobx-react';
import {Layout, InteractiveTable, DropdownFilter} from 'components';
import {t, auth, endpoints, types} from 'shared/core';
import {EmployeeTrainingStatus} from 'stores/training';
import {EmployeeTrainingStatus as EmployeeTrainingStatusView} from 'training/components/EmployeeTrainingStatus';
import {LOCALIZATION, getFilterOptions} from 'training/tools';

const COLUMNS = [
  {
    header: 'training.directory.Training Program',
    attribute: 'program.name',
    width: 4,
    sortBy: 'name'
  },
  {
    header: 'training.directory.Complete',
    render: model => <EmployeeTrainingStatusView completed={model.completed}/>,
    width: 2
  },
  {
    header: 'training.directory.Due Date',
    render: model => model.dueDateView,
    width: 3,
    sortBy: 'dueDate'
  },
  {
    header: 'training.directory.Completed Date',
    render: model => model.completedAtView,
    width: 3,
    sortBy: 'completedDate'
  }
];

const EmployeeTrainingStatusesDirectory = observer(() => {
  return (
    <div>
      <Layout>
        <InteractiveTable
          title={t('training.directory.My Training Programs')}
          searchable={false}
          columns={COLUMNS}
          localization={LOCALIZATION}
          showLinks={false}
          saveStateToUrl={true}
          showRoute='/programs/:id'
          proxy={{
            type: 'async',
            endpoint: endpoints.TRAINING.EMPLOYEE_TRAINING_STATUSES.with(auth.employee.id),
            modelType: types.TRAINING.EMPLOYEE_TRAINING_STATUS,
            model: EmployeeTrainingStatus
          }}
        >
          <DropdownFilter options={getFilterOptions()} attr='status'/>
        </InteractiveTable>
      </Layout>
    </div>
  );
});

export default EmployeeTrainingStatusesDirectory;
