import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const ResetMFAModal = observer(({uiState}) => {
  const {editingUser} = uiState;

  return (
    <Modal
      size='sm'
      isOpen={uiState.resetMFAModalOpen}
      onHide={() => uiState.closeResetMFAModal()}
      title={t('company_settings.users.CLEAR_MFA_FOR', { name: editingUser.name })}
      translateTitle={false}
    >
      <FormattedMessage id='company_settings.users.CLEAR_MFA_DESCRIPTION' />
      <ModalButtons
        onSave={() => uiState.clearMFAFields()}
        onCancel={() => uiState.closeResetMFAModal()}
      />
    </Modal>
  );
});

export default ResetMFAModal;
