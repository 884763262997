import FilterRow from './FilterRow';
import {observable, action} from 'mobx';
import _ from 'lodash';

class InequalityFilterRow extends FilterRow {
  @observable value;
  @observable range = {
    lower_bound: null,
    upper_bound: null
  };

  @action merge(other) {
    super.merge(other);

    if (_.get(other, 'values') && !_.isEmpty(other.values)) {
      if (other.operator === 'between') {
        this.updateRangeLowerBound(other.values.lowerBound);
        this.updateRangeUpperBound(other.values.upperBound);
      } else {
        this.updateValue(other.values);
      }

      this.values = null;
    }
  }

  @action updateValue(value) {
    this.value = this.parseValue(value);
  }

  @action updateRangeLowerBound(value) {
    this.range.lower_bound = this.parseValue(value);
  }

  @action updateRangeUpperBound(value) {
    this.range.upper_bound = this.parseValue(value);
  }

  toJS() {
    const json = super.toJS();
    return [{
      attribute: json.attribute,
      operator: json.operator,
      values: this.operator === 'between' ? json.range : json.value
    }];
  }
}

export default InequalityFilterRow;
