import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {TextArea} from 'components';
import {FormattedMessage} from 'react-intl';

const RevokeOfferModal = observer(({uiState}) => {
  const {selectedOffer} = uiState;

  if (!selectedOffer) return null;

  return (
    <Modal
      title='offers.directory.revoke_offer.Revoke Offer'
      size='sm'
      isOpen={uiState.revokeOfferModalOpen} 
      onHide={() => uiState.closeRevokeOfferModal()}
    >
      <div className='mb3 jumbo'>
        <FormattedMessage id={'offers.directory.revoke_offer.This will disable the offer link sent to the candidate. The candidate will not be notified.'}/>
      </div>
      <TextArea
        value={selectedOffer.revokeReason}
        onChange={(e) => selectedOffer.merge({revokeReason: e.target.value})}
        label='offers.directory.revoke_offer.Reason for revoking'
        placeholder='offers.directory.revoke_offer.e.g. Candidate rejected offer'
      />
      <ModalButtons
        saveCaption='offers.directory.revoke_offer.Revoke'
        onSave={() => uiState.revokeOffer()}
        onCancel={() => uiState.closeRevokeOfferModal()}/>
    </Modal>
  );
});

export default RevokeOfferModal;
