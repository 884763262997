import {observable, action}  from 'mobx';
import {DomainObject} from 'shared/store';
import {Segment} from 'stores/segmentation';

class EmployeeGroup extends DomainObject {
  @observable name;
  @observable readOnly;
  @observable default;
  @observable segment;

  @action merge(other) {
    super.merge(other, {
      segment: Segment
    });
  }
}

export default EmployeeGroup;
