import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {dateSpan} from 'shared/tools';
import moment from 'moment';

class TimeTrackingPayPeriod extends DomainObject {
  @observable startDate;
  @observable endDate;

  @observable previousStartDate;
  @observable previousEndDate;
  @observable nextStartDate;
  @observable nextEndDate;

  @action merge(other) {
    super.merge(other, {
      _dates: [
        'startDate',
        'endDate',
        'previousStartDate',
        'previousEndDate',
        'nextStartDate',
        'nextEndDate'
      ],
      previousPayPeriod: TimeTrackingPayPeriod,
      nextPayPeriod: TimeTrackingPayPeriod
    });
  }

  @computed get effectiveDateView() {
    return dateSpan(this.startDate, this.endDate, true);
  }

  @computed get isCurrentPeriod() {
    const now = moment();
    return now.isBetween(this.startDate, this.endDate, 'day', '[]');
  }
}

export default TimeTrackingPayPeriod;
