import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import _ from 'lodash';

class ChargifyDirectParameters extends DomainObject {
  @observable id;

  @observable apiId;
  @observable timestamp;
  @observable nonce;
  @observable data;
  @observable signature;

  @observable firstName;
  @observable lastName;

  @observable cardNumber;
  @observable expirationMonthIndex;
  @observable expirationYear;
  @observable cvv;

  @observable billingAddress;
  @observable billingAddress2;
  @observable billingCity;
  @observable billingState;
  @observable billingZip;
  @observable billingCountry;

  @action merge(other) {
    super.merge(other);

    if (other.billingCountry && _.isString(other.billingCountry)) {
      this.billingCountry = {id: other.billingCountry};
    }

    if (other.billingState && _.isString(other.billingState)) {
      this.billingState = {id: other.billingState};
    }
  }

  @computed get expirationMonth() {
    const i = parseInt(this.expirationMonthIndex);
    if (i === 0 || i) {
      return i + 1;
    }
    return '';
  }

  set expirationMonth(value) {
    const i = parseInt(value);

    if (i) {
      this.expirationMonthIndex = i - 1;
    } else {
      this.expirationMonthIndex = '';
    }
  }
}

export default ChargifyDirectParameters;
