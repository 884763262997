import Autolinker from 'autolinker';
import sanitizeHtml from 'sanitize-html';
import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {fullName} from 'shared/tools';
import {Employee} from 'stores/employees';
import {AttachedDocument} from 'stores/documents';

class Task extends DomainObject {
  @observable completeToggled;
  @observable name = '';
  @observable description = '';
  @observable dueDate;
  @observable taskType;
  @observable completed;
  @observable subjectEmployee = {};
  @observable assigneeEmployee = {};
  @observable relatedTo;
  @observable attachedFiles = [];

  @action merge(other) {
    super.merge(other, {
      assigneeEmployee: Employee,
      subjectEmployee: Employee,
      attachedFiles: [AttachedDocument]
    });
  }

  @computed get training() {
    return this.taskType === 'training';
  }

  @computed get assigneeName() {
    return fullName(this.assigneeEmployee);
  }

  @computed get subjectName() {
    return fullName(this.subjectEmployee);
  }

  @computed get sanitizedDescription() {
    const anchorText = Autolinker.link(this.description, {
      email: false,
      phone: false,
      stripPrefix: false,
      stripTrailingSlash: false,
      className: 'dodger'
    });

    return {
      __html: sanitizeHtml(anchorText, {
        allowedTags: ['a', 'strong', 'p'],
        allowedClasses: {
          a: ['dodger']
        },
        allowedAttributes: {
          a: ['href', 'target', 'className']
        }
      })
    };
  }
}

export default Task;
