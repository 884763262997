import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {calculatePercentage} from 'shared/tools';

const LegendElement = observer(({item}) => {
  return (
    <div className='inline-block pr3'>
      <div className='table-cell align-middle col-1 pr1'>
        <div className='circle' style={{backgroundColor: item.color, padding: '0.45rem'}}/>
      </div>
      <div className='table-cell align-middle col-11'>{item.label}</div>
    </div>
  );
});

const Legend = observer(({items}) => {
  return (
    <div className='pt2'>
      {items.map(item => <LegendElement key={item.label} item={item}/>)}
    </div>
  );
});

const DisplayedItem = observer(({item, total}) => {
  const width = calculatePercentage(item.value, total);

  const style = {
    'backgroundColor': item.color,
    'width': `${width}%`,
    'lineHeight': '18px'
  };

  return (
    !!width && <div key={item.label} className='table-cell' style={style}>&nbsp;</div>
  );
});

const ProgressIndicator = observer(({items, displayLegend}) => {
  const total = _.sumBy(items, 'value');

  return (
    <div>
      <div className='table rounded overflow-hidden'>
        {items.map(item => <DisplayedItem key={item.label} item={item} total={total}/>)}
      </div>
      {displayLegend && <Legend items={items}/>}
    </div>
  );
});

export default ProgressIndicator;
