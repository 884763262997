import React from 'react';
import {observer} from 'mobx-react';
import {Avatar, ActionLinks} from 'components';
import {FormattedMessage} from 'react-intl';
import {longTimestampDate} from 'shared/tools';

const CommentCard = observer(({viewModel}) => {
  return (
    <div className='flex pb3 justify-between'>
      <div className='align-middle'>
        <div className='pr2 table-cell align-top'>
          {viewModel.data.employeeId ?
            <a href={`/employees/${viewModel.data.employeeId}`}>
              <Avatar size='sm' employee={viewModel.data.user}/>
            </a> :
            <Avatar size='sm' employee={viewModel.data.user}/>
          }
        </div>
        <div className='table-cell align-top'>
          <div className='mb1 jumbo'>
            {viewModel.data.employeeId ?
              <a href={`/employees/${viewModel.data.employeeId}`}>
                {viewModel.data.user.name}
              </a> :
              <span>{viewModel.data.user.name}</span>
            }
            <span className='h6'>
              <span className='mx1'>{'-'}</span>
              <span className='DashboardTooltip'>
                {viewModel.createdTimeAgo}
                <span className='tooltiptext dateTooltip'>
                  {longTimestampDate(viewModel.data.createdAt)}
                </span>
              </span>
              {viewModel.hasBeenUpdated &&
                <span>
                  <span className='mx1'>{'-'}</span>
                  <span className='DashboardTooltip'>
                    <FormattedMessage
                      id="hr_dashboard.feed.announcements.UPDATED_TIME_AGO"
                      values={{timeAgo: viewModel.updatedTimeAgo}}
                    />
                    <span className='tooltiptext dateTooltip'>
                      {longTimestampDate(viewModel.data.updatedAt)}
                    </span>
                  </span>
                </span>
              }
            </span>
          </div>
          <div>{viewModel.data.content}</div>
        </div>
      </div>
      <div className='relative'>
        <ActionLinks
          model={viewModel}
          action={() => viewModel.editCommentClicked()}
          onRemoveClicked={() => viewModel.deleteCommentClicked()}
        />
      </div>
    </div>
  );
});

const CommentsList = observer(({commentViewModels}) => {
  return (
    <div>
      {commentViewModels.map(comment =>
        <CommentCard
          key={comment.data.id}
          viewModel={comment}
        />
      )}
    </div>
  );
});

export default CommentsList;
