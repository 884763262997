import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import DateFilter from 'containers/reports/components/DateFilter';
import DateRange from 'containers/reports/components/DateRange';
import {FilterDropdownMenu, FilterDropdownToggle, Panel} from 'components';
import FilterRow from 'containers/reports/components/filters/FilterRow';
import {FormattedMessage} from 'react-intl';

const SingleDateFilter = observer(({uiState}) => {
  if (uiState.dateFilterType !== 'single') return null;

  return (
    <div className='mb2'>
      <DateFilter filters={{date: uiState.date}} onChange={({date}) => uiState.updateDate(date)}/>
    </div>
  );
});

const RangeDateFilter = observer(({uiState}) => {
  if (uiState.dateFilterType !== 'range') return null;

  return (
    <div className='mb2'>
      <DateRange
        defaultStartDate={uiState.defaultStartDate}
        defaultEndDate={uiState.defaultEndDate}
        dateFilterFormat={uiState.dateFilterFormat}
        filters={{startDate: uiState.startDate, endDate: uiState.endDate}}
        onChange={({startDate, endDate}) => uiState.updateDateRange(startDate, endDate)}
      />
    </div>
  );
});

const FilterCategories = ({uiState}) => {
  return (
    <div>
      {uiState.availableFilterCategories.map(c =>
        <FilterDropdownMenu.Checkbox key={c}
                                     onChange={(e) => uiState.toggleFilterRow(c, e.checked)}
                                     checked={uiState.filterCategorySelected(c)}
                                     className='bg-hover-haze py1 px2 rounded'>
          <FormattedMessage id={`reports.${c}`}/>
        </FilterDropdownMenu.Checkbox>
      )}
    </div>
  );
};

const FilterButton = observer(({uiState}) => {
  if (!uiState.hasFilterCategories) return <div className='col-2'>&nbsp;</div>;

  return (
    <div className='col-2 table-cell align-middle right-align'>
      <FilterDropdownToggle
        isOpen={uiState.categoryFilterOpen}
        onOpen={() => uiState.openCategoryFilter()}
        onCancel={() => uiState.closeCategoryFilter()}
        disabled={uiState.disableFilterEditing}
      >
          <i className='material-icons waterloo h2'>{'filter_list'}</i>
          <span className='pl1'><FormattedMessage id='reports.Filter'/></span>
          <i className='material-icons waterloo h3'>{'arrow_drop_down'}</i>
      </FilterDropdownToggle>
      <FilterDropdownMenu position='right'
                          isOpen={uiState.categoryFilterOpen}
                          onCancel={() => uiState.closeCategoryFilter()}
                          showDropdownButtons={false}
                          scrollable={false}>
        <FilterCategories uiState={uiState}/>
      </FilterDropdownMenu>
    </div>
  );
});

const ReportFilter = observer(({uiState}) => {
  if (!uiState.hasFilters) return null;

  return (
    <Panel className='clearfix mb2'>
      <div className='col col-12'>
        <div className='clearfix table'>
          <div className='col-10 table-cell align-middle'>
            {uiState.hasDateFilter && <div className='table-cell align-middle pr2'>
              <FilterDropdownToggle isOpen={uiState.dateFilterOpen} onOpen={() => uiState.openDateFilter()} onCancel={() => uiState.closeDateFilter()}>
                <span className='pr1'>{uiState.dateFilterView}</span>
                <i className='material-icons h3 align-top inline'>{'keyboard_arrow_down'}</i>
              </FilterDropdownToggle>
              <FilterDropdownMenu wide={uiState.dateFilterType === 'range'} isOpen={uiState.dateFilterOpen} onCancel={() => uiState.closeDateFilter()} onApply={() => uiState.applyFilters()}>
                <SingleDateFilter uiState={uiState}/>
                <RangeDateFilter uiState={uiState}/>
              </FilterDropdownMenu>
            </div>}
            {uiState.hasDateFilter && <div className='table-cell align-middle border-right'>
              <div className='h2 pt1 waterloo'>&nbsp;</div>
            </div>}
            {uiState.hasCount && <div className={`table-cell align-middle ${uiState.hasDateFilter ? 'pl3' : ''}`}>
              <div className='h3 medium'>
                <FormattedMessage {...uiState.resultCountView}/>
              </div>
            </div>}
          </div>
          <FilterButton uiState={uiState}/>
        </div>
      </div>
      {uiState.showFilterRows && <div className='col col-12 mt2 pt2 border-top'>
        <div className='pb1 jumbo'>
          <FormattedMessage id='reports.Displaying results that match the following criteria'/>
        </div>
        {uiState.filterRows.map(row => <FilterRow key={row.category} uiState={uiState} viewModel={row}/>)}
      </div>}
    </Panel>
  );
});

export default ReportFilter;
