import React from 'react';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import ScopeFilterState from './ScopeFilterState';

const Pill = observer(({option, onRemove}) => {
  if (!option.selected) return null;

  return (
    <div className='Tag Tag--rounded Tag--scope'>
      <span className='flex align-items-middle'>
        <div className='inline whitespace-nowrap'>
          {option.display}
        </div>
        <i className='material-icons inline z-3 h4 ml1' onClick={onRemove}>{'close'}</i>
      </span>
    </div>
  );
});

@observer class ScopeFilter extends React.Component {
  @observable uiState = new ScopeFilterState();

  componentWillMount() {
    this.uiState.receiveProps(this.props);
  }

  render() {
    if(this.uiState.noOptionsSelected) return null;

    const {options} = this.uiState;

    return (
      <div className='mr1'>
        {options.map(option => (
          <Pill
            key={option.scope}
            option={option}
            onRemove={() => this.uiState.removeScope(option)}
          />
        ))}
      </div>
    );
  }
}

Pill.defaultProps = {
  colour: 'whale'
};

export default ScopeFilter;
