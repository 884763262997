import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {Employee} from 'stores/employees';
import _ from 'lodash';

class TimeTrackingPayrollHoursSummary extends DomainObject {
  @observable employee;
  @observable regular;
  @observable overtime;
  @observable doubleOvertime;
  @observable holidaysTotal;
  @observable total;
  @observable ptoTotal;
  @observable approved;

  @observable errors = {};

  @action merge(other) {
    super.merge(other, {
      employee: Employee,
    });
  }

  @computed get errorMessage() {
    if (_.isEmpty(this.errors)) return '';

    return this.errors.base || _.find(this.errors.entries, entry => !!entry).endTime;
  }
}

export default TimeTrackingPayrollHoursSummary;
