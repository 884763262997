import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {ActionLink} from 'components';

const HELPER_HEIGHT = 34;
const BORDER_WIDTH = 1;

function calculateAnnotationCenter(state) {
  const annotationHeight = parseFloat(state.height) * parseFloat(state.scaleY);
  const midpoint = (annotationHeight - HELPER_HEIGHT) / 2;

  return parseFloat(state.top) + midpoint + 2 + 2 * BORDER_WIDTH;
}

const AnnotationHelperButton = observer(({text, onClick}) => {
  return (
    <ActionLink className='white' onClick={onClick}>
      <div className='table-cell align-middle'>
        <FormattedMessage id={text}/>
      </div>
      <div className='table-cell align-middle'>
        <i className='material-icons align-bottom h2'>{'keyboard_arrow_right'}</i>
      </div>
    </ActionLink>
  );
});

const DifferentPageHelper = observer(({uiState}) => {
  const text = uiState.readyToComplete ? 'onboard.documents.Complete' : 'onboard.documents.Go to field';
  return (
    <AnnotationHelperButton
      text={text}
      onClick={() => uiState.goToNextAnnotation()}/>
  );
});

const CurrentPageHelper = observer(({uiState}) => {
  const {currentAnnotation} = uiState;
  const text = uiState.readyToComplete ? 'onboard.documents.Complete' : 'onboard.documents.Next';

  return (
    currentAnnotation.isValid
      ? <AnnotationHelperButton
          text={text}
          onClick={() => uiState.goToNextAnnotation()}/>
      : <FormattedMessage id='onboard.documents.Fill this field'/>
  );
});

const AnnotationHelper = observer(({uiState}) => {
  const {currentAnnotation, helperDirectsToDifferentPage, markedComplete} = uiState;

  if (markedComplete) return null;

  return (
    <div className='AnnotationHelper' style={{top: currentAnnotation ?
        calculateAnnotationCenter(currentAnnotation.annotation.state) : 0}}>
      <div className='AnnotationHelperContent'>
        {helperDirectsToDifferentPage
          ? <DifferentPageHelper uiState={uiState} />
          : <CurrentPageHelper uiState={uiState} />
        }
      </div>
    </div>
  );
});

export default AnnotationHelper;
