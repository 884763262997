import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {Panel, Table, Layout} from 'components';
import SubNav from 'company_settings/SubNav';
import CompanyEmailTemplatesState from '../state/CompanyEmailTemplatesState';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';

const COLUMNS = [
  
  {
    header: 'company_settings.company_email_templates.Email Template',
    render: model =>
    <Link to={`company_email_templates/${model.id}`} className='TextLink'>
      <FormattedMessage id={`company_settings.company_email_templates.${model.emailType}`}/>
    </Link>,
    width: 8
  },
  {
    header: 'company_settings.company_email_templates.Category',
    render: model => <FormattedMessage id={`company_settings.company_email_templates.${model.emailType}_category`}/>,
    width: 4
  }
];

const LOCALIZATION = {
  emptyState: 'company_settings.company_email_templates.No email templates to display'
};

const CompanyEmailTemplatesContainer = observer(({uiState}) => {
  return (
    <div>
      <SubNav title={t('company_settings.subnav.Email Templates')}/>
      <Layout>
        <Layout.Section>
          <Panel>
            <Panel.Header title={t('company_settings.company_email_templates.Email Templates')}/>
            <Table models={uiState.emailTemplates}
                   columns={COLUMNS}
                   localization={LOCALIZATION}
                   showLinks={false}
                   showPath='/company_settings/company_email_templates/:id'/>
          </Panel>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(CompanyEmailTemplatesContainer, CompanyEmailTemplatesState);
