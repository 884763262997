import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {LeaveTypeEditState} from '../state';
import {Display, Layout, Panel, FormLayout, Input} from 'components';
import {ModalButtons} from 'components/modals';
import {t} from 'shared/core';

const LeaveTypeEditContainer = observer(({uiState}) => {
  const {leaveType, errors} = uiState;

  return (
    <div>
      <Display backPath='/leave_types'/>
      <Layout>
        <Panel>
          <Panel.Header 
            title={t('company_settings.leave_types.Leave Types')}
            description={t('company_settings.leave_types.Create leave types to specify what kind of leave an employee is on. (e.g. maternity/paterinity, bereavement)')} />
          <FormLayout>
            <Input value={leaveType.name}
              onChange={e => leaveType.merge({name: e.target.value})}
              label='company_settings.leave_types.Name of leave type'
              placeholder='company_settings.leave_types.e.g., Maternity Leave'
              errorMessage={errors.name} />
          </FormLayout>
          <ModalButtons
            onSave={() => uiState.saveLeaveType()}
            saveCaption={`company_settings.leave_types.${leaveType.isNew ? 'Create' : 'Save'}`}
            onCancel={() => uiState.goBack()}
          />
        </Panel>
      </Layout>
    </div>
  );
});

export default withState(LeaveTypeEditContainer, LeaveTypeEditState);
