import {DomainObject} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {Employee} from 'stores/employees';
import _ from 'lodash';
import {t} from 'shared/core';

class TimeOffAccountSummary extends DomainObject {
  @observable id;
  @observable employee;
  @observable typeId;
  @observable typeName;
  @observable unlimited;
  @observable workDayLength;
  @observable accrualBalance;
  @observable adjustment = 0;
  @observable adjustBy = 'days';

  @action merge(other) {
    super.merge(other, {
      employee: Employee
    });
  }

  @computed get accruedInDays() {
    if (this.unlimited) return t('time_off.account_summaries.N/A');
    return this.__renderBalance(this.accrualBalance.accrued);
  }

  @computed get availableInDays() {
    if (this.unlimited) return '∞';
    return this.__renderBalance(this.accrualBalance.available);
  }

  @computed get usedInDays() {
    return this.__renderBalance(this.accrualBalance.used);
  }

  @computed get scheduledInDays() {
    return this.__renderBalance(this.accrualBalance.scheduled);
  }

  @computed get adjustedAvailableInDays() {
    if (this.unlimited) return this.availableInDays;
    if (_.isNaN(parseFloat(this.adjustment))) return this.availableInDays;

    const adjustment = this.adjustByDays ? this.adjustment : this.adjustmentInHours;
    return (parseFloat(this.availableInDays) + parseFloat(adjustment)).toFixed(2);
  }

  @computed get adjustmentInHours() {
    return parseFloat(this.adjustment) * 3600 / this.workDayLength;
  }

  @computed get adjustByDays() {
    return this.adjustBy === 'days';
  }

  __renderBalance(seconds) {
    return parseFloat(seconds / this.workDayLength).toFixed(2);
  }
}

export default TimeOffAccountSummary;
