import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {Link} from 'react-router-dom';
import {ReactSelect, DropdownSelect, PanelTooltip, DocumentTitle} from 'components';
import {auth, t} from 'shared/core';
import PositionTitleView from 'recruiting/components/PositionTitleView';
import DeletePositionModal from '../directory/DeletePositionModal';

const StatusTooltip = observer(() => {
  return (
    <div>
      <div className='mb2'>
        <FormattedMessage id='recruiting.hints.position_status.header'/>
      </div>
      <ul>
        <li className='mb1'>
          <FormattedMessage
            id='recruiting.hints.position_status.PUBLISHED_BULLET'
            values={{
              publishedPositions: <span className='medium'><FormattedMessage id='recruiting.hints.position_status.published'/></span>
            }}/>
        </li>
        <li className='mb1'>
          <FormattedMessage
            id='recruiting.hints.position_status.INTERNAL_BULLET'
            values={{
              internalPositions: <span className='medium'><FormattedMessage id='recruiting.hints.position_status.internal'/></span>
            }}/>
        </li>
        <li>
          <FormattedMessage
            id='recruiting.hints.position_status.CLOSED_BULLET'
            values={{
              closedPositions: <span className='medium'><FormattedMessage id='recruiting.hints.position_status.closed'/></span>
            }}/>
        </li>
      </ul>
    </div>
  );
});

const AddCandidateButton = observer(({position}) => {
  if (position.isClosed) return null;

  return (
    <Link to={`/positions/${position.id}/add-candidate`} className='Btn Btn--primary'>
      <span className='Btn--content' tabIndex='-1'>
        <i className='material-icons align-middle mr1'>{'person_add'}</i>
        <FormattedMessage id='recruiting.hiring_funnel.Add Candidate'/>
      </span>
    </Link>
  );
});

const HiringFunnelSubNav = observer(({uiState, intl}) => {
  const {position} = uiState;

  return (
    <div className='Subnav Subnav--react'>
      {position.title && <DocumentTitle title={position.title}/>}
      <div className='Container'>
        <div className='clearfix table'>
          <div className='col-6 table-cell align-middle'>
            <div className='Subnav-header'>
              <PositionTitleView position={position} />
            </div>
          </div>
          <div className='col-6 table-cell align-middle right-align'>
            {auth.hasAccess('::MANAGE_ATS') && <div className='inline-block align-middle'>
              <PanelTooltip><StatusTooltip/></PanelTooltip>
              <ReactSelect selected={intl.formatMessage({id: `recruiting.hiring_funnel.${position.status}`})} className='inline-block'>
                <ReactSelect.Option active={position.isPublished}
                                    text={intl.formatMessage({id: 'recruiting.hiring_funnel.published'})}
                                    onClick={() => uiState.changePositionStatus('published')} />
                <ReactSelect.Option active={position.isInternal}
                                    text={intl.formatMessage({id: 'recruiting.hiring_funnel.internal'})}
                                    onClick={() => uiState.changePositionStatus('internal')} />
                <ReactSelect.Option active={position.isClosed}
                                    text={intl.formatMessage({id: 'recruiting.hiring_funnel.closed'})}
                                    onClick={() => uiState.changePositionStatus('closed')} />
              </ReactSelect>
            </div>}
            <div className='inline-block align-middle mx2'>
              <AddCandidateButton position={position}/>
            </div>
            <div className='inline-block align-middle'>
              <DropdownSelect selected={t('recruiting.hiring_funnel.More')}>
                {position.showViewPosting && <DropdownSelect.Option key={1} text={intl.formatMessage({id: 'recruiting.hiring_funnel.View Posting'})} icon='launch' onClick={() => uiState.goToPosting(position)}/>}
                {position.canPatch && <DropdownSelect.Option key={2} text={intl.formatMessage({id: 'recruiting.hiring_funnel.Edit Posting'})} icon='edit' onClick={() => uiState.history.push(`/positions/${position.id}`)}/>}
                {position.canDelete && <DropdownSelect.Option key={3} text={intl.formatMessage({id: 'recruiting.hiring_funnel.Delete Posting'})} icon='delete' onClick={() => uiState.openDeleteModal()}/>}
              </DropdownSelect>
            </div>
          </div>
        </div>
      </div>
      <DeletePositionModal uiState={uiState}/>
    </div>
  );
});

export default injectIntl(HiringFunnelSubNav);
