import {action, observable, toJS} from 'mobx';
import _ from 'lodash';

class FilterRowViewModel {
  @observable category;
  @observable operators = [];
  @observable row;
  @observable editingRow;
  @observable viewOnly = false;

  constructor({category, attribute, operators}) {
    this.category = category;
    this.operators = [...operators];
    this.row = this.createRow();
    this.row.attribute = attribute;
    this.row.operator = this.operators[0];
    this.editingRow = this.createRow(this.row);
  }

  createRow(row) {
    throw new Error('Child class must implement createRow()');
  }

  @action editOperator(operator) {
    this.editingRow.operator = operator;
  }

  @action updateRow() {
    this.row = this.createRow(this.editingRow);
  }

  @action resetRow() {
    this.editingRow = this.createRow(_.cloneDeep(toJS(this.row)));
    setTimeout(() => {
      this.searchFilter = '';
    }, 200);
  }
}

export default FilterRowViewModel;
