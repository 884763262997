import {TextNode} from 'lexical';
import {addClassNamesToElement} from '@lexical/utils';

const IS_TOKEN = 1;
const DATA_ENTITY_TYPE = 'MERGE_FIELD';

export class MergeFieldNode extends TextNode {
  constructor(text, dataKey, key) {
    super(text, key);
    this.__dataKey = dataKey;
    this.__mode = IS_TOKEN;
  }

  get entityType() {
    return DATA_ENTITY_TYPE;
  }

  static getType() {
    return 'merge-field';
  }

  static clone(node) {
    return new MergeFieldNode(node.__text, node.__dataKey, node.__key);
  }

  createDOM(config) {
    const element = super.createDOM(config);
    element.setAttribute('data-entity-type', this.entityType);
    element.setAttribute('data-key', this.__dataKey);
    addClassNamesToElement(element, this.getType());
    return element;
  }

  static importJSON(serializedNode) {
    const node = $createMergeFieldNode(serializedNode.text, serializedNode.dataKey);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON() {
    return {
      detail: this.getDetail(),
      format: this.getFormat(),
      mode: this.getMode(),
      style: this.getStyle(),
      text: this.getTextContent(),
      type: this.getType(),
      dataKey: this.__dataKey
    };
  }

  static importDOM() {
    return {
      span: (node) => {
        if (node.getAttribute('data-entity-type') === DATA_ENTITY_TYPE) {
          return {
            conversion: convertMergeFieldElement,
            priority: 4
          };
        }
        return null;
      }
    };
  }
}

function convertMergeFieldElement(domNode) {
  let textContent = domNode.textContent || '';
  let dataKey = domNode.getAttribute('data-key');

  return {
    node: $createMergeFieldNode(textContent, dataKey)
  };
}

export function $createMergeFieldNode(text, dataKey) {
  return new MergeFieldNode(text, dataKey);
}

export function $isMergeFieldNode(node) {
  return node instanceof MergeFieldNode;
}
