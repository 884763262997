/* eslint-disable */
import React, { Component} from 'react';
import { observer, PropTypes as MobxP } from 'mobx-react';
import P from 'prop-types';

import {
	TOP_MARGIN,
	NODE_WIDTH,
	ARC_SIZE
} from '../../constants';
import NodeArc from './node-arc';
import SiblingLine from './sibling-line';
import ParentArc from './parent-arc';

import Bezier from 'bezier-easing';
const EASING = [0, 0, 0.58, 1]
const DURATION = 400
const bezier = Bezier(...EASING)

@observer class Lines extends Component {
	constructor(props){
		super(props)

		this.genConnectorLines()
	}
	getSnapshotBeforeUpdate(prevProps){
		// snapshot left for first and last nodes to determine if width changed
		return {
			prevFirstNodeLeft: prevProps.firstNodeLeft,
			prevLastNodeLeft: prevProps.lastNodeLeft,
		}
	}
	componentDidUpdate(prevProps, prevState, snapshot){
		const oldWidth = this.props.lastNodeLeft - this.props.firstNodeLeft
		const newWidth = snapshot.prevLastNodeLeft - snapshot.prevFirstNodeLeft
		// check if width changed, if so animate connector lines
		if (this.props.nodes.length > 1 && oldWidth !== newWidth){
			this.animateLines();
		}
		if (!prevProps.highlightLinePath && this.props.highlightLinePath) {
			this.props.finishHighlighting();
		}
	}

	animateLines(){
		const line0 = this.line0
		const line1 = this.line1

		// for first line, only x2 animates
		const oldLine0x2 = this.line0x2
		const newLine0x2 = this.calcLine0X2();

		// for second line, both x1 and x2 animate
		const oldLine1x1 = this.line1x1
		const oldLine1x2 = this.line1x2
		const line1NewX = this.calcLine1()
		const newLine1x1 = line1NewX.x1
		const newLine1x2 = line1NewX.x2

		// create animation sub functions (take in bezier progress and set the x attribute on line)
		const anim0 = this._animateLine0(line0, oldLine0x2, newLine0x2);
		const anim1 = this._animateLine1(line1, oldLine1x1, oldLine1x2, newLine1x1, newLine1x2);

		// actually animate
		this.animateConnector(anim0, anim1);

		this.line0x2 = newLine0x2
		this.line1x1 = newLine1x1
		this.line1x2 = newLine1x2
	}

	_animateFn = null;
	animateConnector(line0Anim, line1Anim){
		let startTime;
		this._animateFn = function(now){
			if (!startTime){
				startTime = now;
			}
			// determine how much time is left
			const progress = (now - startTime) / DURATION;

			// convert to % bezier completion
			const val = bezier(progress)

			// pass bezier progess to animation functions
			line0Anim(val)
			line1Anim(val)

			if (now - startTime < DURATION){
				this._animate()
			}
		}.bind(this)

		this._animate();
	}
	_animate(){
		this.RF = window.requestAnimationFrame(this._animateFn)
	}
	_animateLine0(line, initialX, finalX){
		const delta = finalX - initialX
		return function(val){
			const toSet = initialX + (delta * val)
			line.setAttribute('x2', toSet)
		}.bind(this)
	}
	_animateLine1(line, oldX1, oldX2, newX1, newX2){
		const delta1 = newX1 - oldX1
		const delta2 = newX2 - oldX2
		return function(val){
			const toSet1 = oldX1 + (delta1 * val)
			const toSet2 = oldX2 + (delta2 * val)
			line.setAttribute('x1', toSet1)
			line.setAttribute('x2', toSet2)
		}.bind(this)
	}

	// connector line
	connectorLines = [];
	line0x1 = 0;
	line1x1 = 0;
	line1x2 = 0;
	genConnectorLines(){
		if (this.props.nodes.length < 2){
			return false;
		}
		// line 0
		const line0x1 = NODE_WIDTH / 2 + ARC_SIZE
		this.line0x2 = this.calcLine0X2()

		// line 1
		const line1 = this.calcLine1()
		this.line1x1 = line1.x1
		this.line1x2 = line1.x2

		this.connectorLines = [
			{id: 0, x1: line0x1, x2: this.line0x2},
			{id: 1, x1: this.line1x1, x2: this.line1x2},
		]
	}
	// TODO - should eventually convert this into computed since it's used in several places
	// but need to be careful because need to have access to old value before setting
	// new/updated version
	calcLine0X2(){
		const { firstNodeLeft, parent } = this.props
		return - firstNodeLeft + parent.left + NODE_WIDTH / 2 - ARC_SIZE
	}
	calcLine1(){
		const { firstNodeLeft, lastNodeLeft } = this.props
		return {
			x1: this.calcLine0X2() + TOP_MARGIN,
			x2: - firstNodeLeft + lastNodeLeft + NODE_WIDTH / 2 - ARC_SIZE
		}
	}

	render(){
		const { firstNodeLeft, parentTop, rowWidth, parent, nodes, lastNodeLeft } = this.props
		const cl = this.connectorLines;

		return (
			<svg
				className="row__svg"
				style={{
					left: firstNodeLeft,
					top: parentTop - TOP_MARGIN,
					// transform: `translate(${firstNodeLeft}px ${parentTop - TOP_MARGIN}px)`,
					width: rowWidth,
				}}
			>

				{/* line connecting all arcs */}
				{cl ?
					cl.map(cl => {
						return <line
							className="row__main-arc"
							ref={el => this['line' + cl.id] = el }
							key={cl.id}
							x1={cl.x1}
							y1={ARC_SIZE}
							x2={cl.x2}
							y2={ARC_SIZE}
							strokeWidth="2"
						/>
					})
					:
					null
				}

				{/* parent node arcs */}
				<ParentArc
					nodes={nodes}
					parentLeft={parent.left}
					firstNodeLeft={firstNodeLeft}
					lastNodeLeft={lastNodeLeft}
				/>

				{/* child node arcs */}
				{this.props.nodes.map(node => {
					return <NodeArc
						key={node.id}
						left={node.left}
						parentLeft={parent.left}
						firstNodeLeft={firstNodeLeft}
					/>
				})}

				{this.props.nodes.map((node, index) => {
					if (node.drawLeftLine) {
						return <SiblingLine
							key={node.id}
							left={this.props.nodes[index - 1].left + NODE_WIDTH}
							right={node.left}
							firstNodeLeft={firstNodeLeft}
						/>
					}
				})}

				{this.props.highlightLinePath ?
					<path
						d={this.props.highlightLinePath}
						strokeWidth="3"
						className="row__highlight-line"
						ref={this.props.highlightLineEl}
					/>
					: null
				}
			</svg>
		)
	}
}

Lines.propTypes = {
	rowWidth: P.number,
	parentTop: P.number,
	firstNodeLeft: P.number,
	lastNodeLeft: P.number,
	parent: P.shape({}),
	nodes: MobxP.arrayOrObservableArray
}

export default Lines;
