import React from 'react';
import {Display} from 'components';
import {t} from 'shared/core';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

const CustomFieldSubnav = () => {
  return (
    <Display
      title={t('company_settings.subnav.Custom Fields')}
      backTitle={t('company_settings.Settings')}
    >
      <NavLink to='/custom_fields/personal' activeClassName='active'><FormattedMessage id='company_settings.custom_fields.Personal'/></NavLink>
      <NavLink to='/custom_fields/job' activeClassName='active'><FormattedMessage id='company_settings.custom_fields.Job'/></NavLink>
    </Display>
  );
};

export default CustomFieldSubnav;
