import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';

function resizeIframe(iframe) {
  iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
}

const STYLES = {
  height: '200px',
  width: '100%',
  border: 'none',
  margin: '0 -1rem'
};

@observer class ChargifyPaymentForm extends React.Component {
  iframe;

  messageReceived = (e) => {
    if (!this.iframe) return;
    if (e.source !== this.iframe.contentWindow) return;
    if (_.get(e, 'data.source') !== 'chargify') return;
    const event = _.get(e, 'data.event', '');

    switch (event) {
      case 'success':
        this.props.onSuccess && this.props.onSuccess();
        break;
      case 'resize':
        resizeIframe(this.iframe);
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    window.addEventListener('message', this.messageReceived, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageReceived);
  }

  render() {
    return (
      <iframe src='/payment_method' style={STYLES} ref={iframe => this.iframe = iframe}>
      </iframe>
    );
  }
}

export default ChargifyPaymentForm;
