import React from 'react';
import {observer} from 'mobx-react';
import {Switch, Route} from 'react-router-dom';
import EmployeeGroupsDirectory from './EmployeeGroupsDirectory';

const EmployeeGroupsPage = observer(() => {
  return (
    <Switch>
      <Route exact path='/employee_groups' component={EmployeeGroupsDirectory}/>
      <Route exact path='/employee_groups/new' render={() => <EmployeeGroupsDirectory action='new'/>}/>
    </Switch>
  );
});

export default EmployeeGroupsPage;
