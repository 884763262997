import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {ActionLink} from 'components';
import {redirect} from 'shared/tools';
import {urls} from 'shared/core';

const OverdueItems = ({overdueCount}) => {
  if (overdueCount === 0) return null;

  return (
    <span>
      <FormattedMessage id='hr_dashboard.insights.inbox.WARNING' 
        values={{
          num: overdueCount,
          link: <ActionLink scarlet href={urls.COMPANY_INBOX.OVERDUE} onClick={() => redirect(urls.COMPANY_INBOX.OVERDUE)}>
                  <FormattedMessage id='hr_dashboard.insights.inbox.WARNING_LINK' values={{num: overdueCount}}/>
                </ActionLink>
        }}
      />
    </span>
  );
};

const InboxText = observer(({inboxSummary}) => {
  const {itemsCount, personCount, overdueCount} = inboxSummary;

  if (itemsCount === 0) return null;

  return (
    <li>
      <FormattedMessage id='hr_dashboard.insights.inbox.MESSAGE'
        values={{
          itemsCount: itemsCount,
          personCount: personCount,
          link: <ActionLink href={urls.COMPANY_INBOX.ACTIONABLE} onClick={() => redirect(urls.COMPANY_INBOX.ACTIONABLE)}>
                  <FormattedMessage id='hr_dashboard.insights.inbox.MESSAGE_LINK' values={{num: itemsCount}}/>
                </ActionLink>
        }}
      />
      <OverdueItems overdueCount={overdueCount}/>
    </li>
  );
});

export default InboxText;
