import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import BlueCheckmark from 'img/circle-checkmark--blue@2x.png';

const JobApplicationSubmitted = observer(({match}) => {
  return (
    <div className='Container py4 slide-up'>
      <div className='my4 p4 bg-white box-shadow-1 center'>
        <img className='mt3' src={BlueCheckmark} alt='checkmark' height='40'/>
        <div className='h2 my2'>
          <FormattedMessage id='recruiting.job_application.Your application has been submitted!'/>
        </div>
        <a className='jumbo TextLink block mb3' href={`/jobs/${match.params.slug}`}>
          <FormattedMessage id='recruiting.job_application.Return to job board'/>
        </a>
      </div>
    </div>
  );
});

export default JobApplicationSubmitted;
