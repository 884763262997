import React from 'react';
import {observable} from 'mobx';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Input, UploadFiles, TextArea} from 'components';

const ACCEPTED_RESUME_TYPES = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'text/rtf'
];

const CandidateFields = observer(({candidate, errors}) => {
  if (!candidate) return null;

  return (
    <div>
      <div className='my3'>
        <div className='h4 mb1'>
          <FormattedMessage id='recruiting.candidate.First Name'/>
        </div>
        <Input value={candidate.firstName}
               onChange={(e) => candidate.firstName = e.target.value}
               errorMessage={errors.firstName}
        />
      </div>
      <div className='my3'>
        <div className='h4 mb1'>
          <FormattedMessage id='recruiting.candidate.Last Name'/>
        </div>
        <Input value={candidate.lastName}
               onChange={(e) => candidate.lastName = e.target.value}
               errorMessage={errors.lastName}
        />
      </div>
      <div className='my3'>
        <div className='h4 mb1'>
          <FormattedMessage id='recruiting.candidate.Email Address'/>
        </div>
        <Input value={candidate.email}
               onChange={(e) => candidate.email = e.target.value}
               errorMessage={errors.email}
        />
      </div>
      <div className='my3'>
        <div className='h4 mb1'>
          <FormattedMessage id='recruiting.candidate.Phone Number'/>
          <span className='waterloo regular pl1'>
            <FormattedMessage id='recruiting.candidate.(Optional)'/>
          </span>
        </div>
        <Input value={candidate.phoneNumber}
               onChange={(e) => candidate.phoneNumber = e.target.value}
               errorMessage={errors.phoneNumber}
        />
      </div>
      <div className='my3'>
        <div className='h4 mb1'>
          <FormattedMessage id='recruiting.candidate.Additional Information'/>
          <span className='waterloo regular pl1'>
            <FormattedMessage id='recruiting.candidate.(Optional)'/>
          </span>
        </div>
        <TextArea
          minRows={3}
          value={candidate.additionalInformation || ''}
          onChange={(e) => candidate.additionalInformation = e.target.value}
          name='additionalInformation'
          className='full-width'
        />
      </div>
      <div className='my3'>
        <div className='h4 mb1'>
          <FormattedMessage id='recruiting.candidate.Add Resume'/>
          <span className='waterloo regular pl1'>
            <FormattedMessage id='recruiting.candidate.(Optional)'/>
          </span>
        </div>
        <UploadFiles
          defaultFiles={candidate.resume ? observable([candidate.resume]) : observable([])}
          onChange={files => candidate.updateResume(files[0])}
          modelType='resume'
          maxFiles={1}
          accept={ACCEPTED_RESUME_TYPES}/>
      </div>
    </div>
  );
});

export default CandidateFields;
