import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {EmployeeBenefitState} from '../state';
import {BenefitInfoContainer} from 'components/benefits';
import {Display} from 'components';

const EmployeeBenefitEnrolment = observer(({uiState}) => {
  const {employee} = uiState;

  return (
    <div>
      <Display title={employee.name} backPath='/benefit_enrolments'/>
      <div className='Container'>
        <BenefitInfoContainer employeeId={employee.id}/>
      </div>
    </div>
  );
});

export default withState(EmployeeBenefitEnrolment, EmployeeBenefitState);
