import React from 'react';
import {Panel, Input, LabelHelper} from 'components';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';
import FieldList from './FieldList';

const ChooseFields = observer(({uiState}) => {
  const {report, errors} = uiState;

  return (
    <div>
      <Panel.Header title={t('reports.custom.edit.Choose fields')}/>
      <div className='mb3'>
        <Input placeholder='reports.custom.edit.Add a report name'
                errorMessage={errors.name}
                value={report.name}
                label='reports.custom.edit.Report name'
                onChange={e => report.merge({name: e.target.value})}
                className='large'/>
      </div>
      <div className='my3'>
        <LabelHelper label={t('reports.custom.edit.Fields')}/>
        <div className='waterloo'>
          <FormattedMessage id='reports.custom.edit.Select fields to include in the report.'/>
        </div>
        <FieldList uiState={uiState}/>
      </div>
    </div>
  );
});

export default ChooseFields;
