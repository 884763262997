import {endpoints, types} from 'shared/core';
import {refresh} from 'shared/tools';
import {DomainStore} from 'shared/store';

class DemoDataDeletionState {
  store = new DomainStore();

  load() {
    this.startPolling();
  }

  startPolling() {
    this.interval = setInterval(() => this.poll(), 2000);
  }

  async poll() {
    const model = await this.store.fetch(
      endpoints.CURRENT_COMPANY,
      types.COMPANY
    );

    if (!model.demoAccount) {
      clearInterval(this.interval);

      await refresh();
    }
  }
}

export default DemoDataDeletionState;
