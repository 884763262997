/* eslint-disable */
import React, { Component } from 'react';
import { observable } from 'mobx';
import { observer, PropTypes as MobxP } from 'mobx-react';
import P from 'prop-types';

// padding distance between edge of chart that pan disables
const BUFFER = 7

@observer class OrgChartPan extends Component {
	@observable isPanning = false;
	start = [];
	bounds = {};

	constructor(props){
		super(props)

		this.panEl = React.createRef();
	}

	componentDidMount(){
		this.panEl.current.addEventListener('mousedown', this.mousedown)
	}

	mousedown = e => {
		this.isPanning = true;
		this.start = [e.offsetX - this.props.translateCoords[0], e.offsetY - this.props.translateCoords[1]]

		const chart = this.props.getChartEl();
		this.bounds = {
			maxX: chart.offsetWidth - BUFFER,
			maxY: chart.offsetHeight - BUFFER
		}

		const el = this.panEl.current
		el.addEventListener('mousemove', this.mousemove)
		el.addEventListener('mouseup', this.cancelMouseMove)
	}
	mousemove = e => {
		const ox = e.offsetX
		const oy = e.offsetY

		// if mouse moves out of org chart, cancel panning
		if (ox < BUFFER || ox > this.bounds.maxX || oy < BUFFER || oy > this.bounds.maxY){
			this.cancelMouseMove()
			e.preventDefault()
			return false;
		}

		// update translate on parent
		const x = ox - this.start[0];
		const y = oy - this.start[1];
		this.props.updateChartTranslate(x, y);
	}
	cancelMouseMove = () => {
		const el = this.panEl.current
		this.isPanning = false;

		el.removeEventListener('mousemove', this.mousemove)
		el.removeEventListener('mouseup', this.cancelMouseMove)
	}

	componentWillUnmount(){
		const el = this.panEl.current
		el.removeEventListener('mousedown', this.mousedown)
		el.removeEventListener('mousemove', this.mousemove)
		el.removeEventListener('mouseup', this.cancelMouseMove)
	}

	render(){
		return (
			<div className={`org-chart__pan ${this.isPanning ? 'state_active' : ''}`} ref={this.panEl}></div>
		)
	}
}

OrgChartPan.propTypes = {
	getChartEl: P.func.isRequired,
	updateChartTranslate: P.func.isRequired,
	translateCoords: MobxP.arrayOrObservableArrayOf(P.number).isRequired
}

export default OrgChartPan;
