import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {ReportDirectory} from './components';
import {Layout, Display} from 'components';
import CensusReport from './components/CensusReport';

const ReportSwitch = ({match, history, parentState}) => {
  return (
    <div>
      <Display backPath={match.url} />
      <Layout>
        <Switch>
          <Route path={`${match.url}/census`} render={() => <CensusReport match={parentState.match} />} />
        </Switch>
      </Layout>
    </div>
  );
};

const ReportsPage = ({match, history, parentState}) => {
  return (
    <Switch>
      <Route exact path={match.url} render={() => <ReportDirectory {...{match, history, parentState}} />}/>
      <Route render={({match, history}) => <ReportSwitch {...{match, history, parentState}}/>}/>
    </Switch>
  );
};

export default ReportsPage;
