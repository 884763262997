import React from 'react';
import {observer} from 'mobx-react';
import {Layout, InteractiveTable, HelperPanel} from 'components';
import {endpoints, types, withState, t} from 'shared/core';
import {TemplateState} from '../state';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {PerformanceReviewTemplate} from 'stores/performance_reviews';
import PlaceholderHelper from 'img/placeholder-helper.png';

const Name = ({model}) => {
  return (
    <span>
      <div className='table-cell align-middle'>
        <Link to={`question_sets/${model.id}`} className='dodger TextLink'>{model.name}</Link>
      </div>
    </span>
    );
  };

const COLUMNS = [
  {
    header: 'performance.templates.Question Set Name',
    render: model => <Name model={model}/>,
    sortBy: 'name',
    width: 6
  },
  {
    header: 'performance.templates.Details',
    render: model => <FormattedMessage id='performance.templates.QUESTION_COUNT' values={{questions: model.questionCount}}/>,
    width: 5
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'performance.templates.Remove Question Set',
    subHeader: 'performance.templates.Are you sure you want to remove this question set?',
    body: 'performance.templates.Performance reviews using this question set will remain within the review.'
  },
  emptyState: 'performance.templates.No question sets to display',
  interactive: {
    addModel: 'performance.templates.Create New Question Set',
  }
};

const TemplateDirectoryWithLoader = observer(({uiState}) => {
  return (
    <Layout>
      <Layout.Section>
        <InteractiveTable
          title={t('performance.Question Sets')}
          searchable={false}
          proxy={{
            type: 'async',
            modelType: types.PERFORMANCE_REVIEWS.TEMPLATE,
            model: PerformanceReviewTemplate,
            endpoint: endpoints.REVIEW_TEMPLATES.PAGINATED
          }}
          customLinks={[
            {
              order: 2,
              text: 'performance_reviews.templates.Duplicate',
              action: model => uiState.duplicateTemplate(model)
            }]
          }
          columns={COLUMNS}
          localization={LOCALIZATION}
          onAdd={() => uiState.createNewTemplate()}
          onRemove={model => uiState.removeTemplate(model)}
          onEdit={model => uiState.goToTemplate(model.id)}
        />
      </Layout.Section>
      <Layout.Section secondary>
        <HelperPanel img={PlaceholderHelper}
          title={t('performance_reviews.templates.What are Question Sets?')}
          descriptionItems={[t('performance_reviews.templates.Successful performance reviews start with meaningful questions. Question Sets are customizable and re-usable questionnaire templates that you can assign to participants within a Review.')]}
        />
      </Layout.Section>
    </Layout>
  );
});

export default withState(TemplateDirectoryWithLoader, TemplateState);
