import {observable, action, toJS} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints} from 'shared/core';
import {formatFilterDates} from 'reports/tools';
import ReportDownload from 'stores/reports/ReportDownload';

const POLL_INTERVAL = 2000;

class ReportDownloadState {
  downloadUrl;
  filters;
  interval;
  store = new DomainStore();

  @observable modalOpen = false;
  @observable pending = true;
  @observable reportDownload = null;
  @observable selectedFileType = 'csv';

  receiveProps({downloadUrl, filters}) {
    this.downloadUrl = `${downloadUrl}/download`;
    this.filters = filters;
  }

  @action closeModal() {
    this.modalOpen = false;
  }

  @action async requestDownload(fileType = this.selectedFileType) {
    clearInterval(this.interval);

    this.selectedFileType = fileType;

    await this.postDownloadRequest();
    this.startPolling();
    this.modalOpen = true;
  }

  async postDownloadRequest() {
    this.filters.exportType = this.selectedFileType;
    this.pending = true;
    const {model} = await this.store.post(
      this.downloadUrl,
      types.REPORT_DOWNLOAD,
      {
        reportDownloadParams: toJS(formatFilterDates(this.filters))
      }
    );

    this.reportDownload = model;
  }

  async poll() {
    const endpoint = this.reportDownload.brokerPortalReport ?
      endpoints.BROKER_PORTAL.REPORT_DOWNLOAD.with(this.reportDownload.company.id, this.reportDownload.id) :
      endpoints.REPORT_DOWNLOAD.with(this.reportDownload.id);

    const response = (await this.store._compose([endpoint]))[0];
    this.reportDownload = new ReportDownload(response.data);

    if (this.reportDownload.isReady) {
      clearInterval(this.interval);
      this.pending = false;
    }
  }

  startPolling() {
    this.interval = setInterval(() => this.poll(), POLL_INTERVAL);
  }
}

export default ReportDownloadState;
