import _ from 'lodash';
/*global window*/

function metaKey() {
  const platform = _.get(window, 'navigator.platform') || '';
  if (platform.indexOf('Mac') > -1) {
    return 'metaKey';
  }

  return 'ctrlKey';
}

function handleJsLink(e, path, history) {
  if (e.isDefaultPrevented()) return;
  if (!path) return;

  if (e[metaKey()]) {
    window.open(history ? history.createHref({pathname: path, search: '', hash: ''}) : path, '_blank');
  } else {
    if (history) {
      history.push(path);
    } else {
      window.location = path;
    }
  }
}

export default handleJsLink;
