import React from 'react';
import {DomainStore} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {endpoints, types} from 'shared/core';
import {TimeTrackingPaySchedule, TimeTrackingPayPeriod} from 'stores/time_tracking';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';
import moment from 'moment';

class ViewPayScheduleContainerState {

  interactiveAgent;
  store = new DomainStore();

  @observable paySchedule;
  @observable payPeriod;
  @observable editingPayPeriod;
  @observable editPayPeriodModalOpen = false;
  @observable errors = {};
  @observable errors = {};

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  @action async load() {
    await this.store._compose([
      endpoints.TIME_TRACKING.PAY_SCHEDULES.with(this.match.params.id)
    ]);

    this.paySchedule = new TimeTrackingPaySchedule(this.store._getSingle(
      types.TIME_TRACKING.PAY_SCHEDULE,
      {id: this.match.params.id}
    ));
  }

  @action async removePayPeriod(payPeriod) {
    await this.store.destroy(payPeriod);
  }

  @action async createNextPayPeriod() {
    const {model} = await this.store.post(
      endpoints.TIME_TRACKING.PAY_PERIODS.FOR_PAY_SCHEDULE.with(this.paySchedule.id),
      types.TIME_TRACKING.PAY_PERIOD
    );

    if (model) {
      this.interactiveAgent.refresh();
    }
  }

  @action async savePayPeriod() {
    const {model, errors} = await this.store.patch(this.editingPayPeriod);
    this.errors = errors;

    if (model) {
      this.closeEditPayPeriodModal();
      await this.interactiveAgent.refresh();
    }
  }

  @action closeEditPayPeriodModal() {
    this.payPeriod = null;
    this.editingPayPeriod = null;
    this.editPayPeriodModalOpen = false;
  }

  @action openEditPayPeriodModal(payPeriod) {
    this.payPeriod = payPeriod;
    this.editingPayPeriod = new TimeTrackingPayPeriod(payPeriod);
    this.editPayPeriodModalOpen = true;
  }

  @computed get editedDateImpactsPreviousPeriod() {
    if (!this.editingPayPeriod || !this.editingPayPeriod.previousEndDate) return false;
    return this.editingPayPeriod.startDate.getTime() !== this.payPeriod.startDate.getTime();
  }

  @computed get editedDateImpactsNextPeriod() {
    if (!this.editingPayPeriod || !this.editingPayPeriod.nextStartDate) return false;
    return this.editingPayPeriod.endDate.getTime() !== this.payPeriod.endDate.getTime();
  }

  @computed get updatedDatesMessage() {
    if (!this.editedDateImpactsPreviousPeriod && !this.editedDateImpactsNextPeriod) return null;

    if (this.editedDateImpactsPreviousPeriod && this.editedDateImpactsNextPeriod) {
      const newPreviousDate = moment(this.editingPayPeriod.startDate).subtract(1, 'days').format('MMM D');
      const newNextDate = moment(this.editingPayPeriod.endDate).add(1, 'days').format('MMM D');

      return (
        <FormattedMessage
          id='time_tracking.manage.pay_schedules.view.UPDATED_DATES_MESSAGE'
          values={{
            previousChange: <FormattedMessage id='time_tracking.manage.pay_schedules.view.PREVIOUS_PERIOD_IMPACT' values={{newDate: <b>{newPreviousDate}</b>}}/>,
            nextChange: <FormattedMessage id='time_tracking.manage.pay_schedules.view.NEXT_PERIOD_IMPACT' values={{newDate: <b>{newNextDate}</b>}}/>,
          }}
        />
      );
    }

    if (this.editedDateImpactsPreviousPeriod) {
      const newDate = moment(this.editingPayPeriod.startDate).subtract(1, 'days').format('MMM D');
      return (
        <FormattedMessage
          id='time_tracking.manage.pay_schedules.view.UPDATED_DATE_MESSAGE'
          values={{
            changes: <FormattedMessage id='time_tracking.manage.pay_schedules.view.PREVIOUS_PERIOD_IMPACT' values={{newDate: <b>{newDate}</b>}}/>
          }}
        />
      );
    }

    if (this.editedDateImpactsNextPeriod) {
      const newDate = moment(this.editingPayPeriod.endDate).add(1, 'days').format('MMM D');
      return (
        <FormattedMessage
          id='time_tracking.manage.pay_schedules.view.UPDATED_DATE_MESSAGE'
          values={{
            changes: <FormattedMessage id='time_tracking.manage.pay_schedules.view.NEXT_PERIOD_IMPACT' values={{newDate: <b>{newDate}</b>}}/>
          }}
        />
      );
    }
  }
}

export default ViewPayScheduleContainerState;
