import React from 'react';
import {observer} from 'mobx-react';
import OnboardingDocumentsContainer from 'employees/hiring/components/OnboardingDocumentsContainer';
import {FormattedMessage} from 'react-intl';
import {Panel, FormError} from 'components';
import {t} from 'shared/core';
import _ from 'lodash';

const Title = observer(() => {
  return (
    <FormattedMessage
      id='employees.hire.documents.ONBOARDING_DOCUMENTS_HEADER'
      values={{
        optional: <span className='waterloo'>{t('employees.hire.documents.(Optional)')}</span>
      }}
    />
  );
});

const Documents = observer(({uiState}) => {
  const {errors} = uiState;
  return (
    <div>
      <Panel.Header
        title={<Title/>}
        description={t('employees.hire.documents.onboarding_documents_desc')}
      />
      <OnboardingDocumentsContainer
        onChange={documents => uiState.setSelectedDocuments(documents)}
        employeeId={uiState.employee.id}
        errors={errors}
      />
      <div className='mt2'>
        {!_.isEmpty(errors) ? _.map(errors, (value, index) => <FormError key={index} message={value}/>) : null}
      </div>
    </div>
  );
});

export default Documents;
