import React from 'react';
import {observer} from 'mobx-react';
import {auth, withState} from 'shared/core';
import {action} from 'mobx';
import {Spinner} from 'components';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

class ReactionState {
  Emoji;

  @action async load() {
    const module = await import(/* webpackChunkName: 'emoji-mart-async' */ 'emoji-mart')
      .catch(e => {
        if (!(/Loading chunk .* failed/.test(e.message))) {
          throw e;
        }
      });
    if (module) {
      this.Emoji = module.Emoji;
    }
  }
}

const ReactionUsers = ({reactions}) => {
  const users = _.map(reactions, reaction => reaction.user.name);
  const names = (_.size(users) > 1) ?
    users.slice(0,-1).join(', ') :
    users;

  return (
    <span className={`tooltiptext reactionTooltip ${_.size(reactions) === 1 ? 'nowrap max-width-none' : ''}`}>
      <FormattedMessage id='hr_dashboard.feed.announcements.REACTED_USERS' values={{numUsers: _.size(reactions), names: names, lastReaction: users.slice(-1)}}/>
    </span>
  );
};

const Reaction = observer(({groupedReaction, uiState, toggleReaction}) => {
  const {Emoji} = uiState;

  if (!Emoji) {
    return null;
  }

  const currentUserReactions = groupedReaction.reactions.find(r => r.user.id === auth.user.id);
  const border = currentUserReactions ? 'border border--dodger' : '';

  return (
    <div className='DashboardTooltip col mr1'>
      <div
        className={`bg-hover-smoke bg-haze px1 rounded2 table ${border}`}
        onClick={() => toggleReaction({emojiKey: groupedReaction.emojiKey, userReaction: currentUserReactions})}
      >
        <div className='table-cell align-middle' style={{paddingTop: '2px', paddingRight: '0.25rem', lineHeight: 'normal'}}>
          <Emoji emoji={groupedReaction.emojiKey} size={18}/>
        </div>
        <div className='h6 jumbo table-cell align-middle ml1'>{groupedReaction.reactions.length}</div>
      </div>
      <ReactionUsers reactions={groupedReaction.reactions}/>
    </div>
  );
});

const Reactions = observer(({uiState, announcement, toggleReaction}) => {
  return (
    <div className='clearfix'>
      {announcement.groupedReactions.map((groupedReaction, index) =>
        <Reaction
          key={index}
          groupedReaction={groupedReaction}
          uiState={uiState}
          toggleReaction={toggleReaction}
        />
      )}
    </div>
  );
});

export default withState(Reactions, ReactionState, {
  spinner: Spinner
});
