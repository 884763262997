import React from 'react';
import {observer} from 'mobx-react';
import {PerformanceReviewCycle} from 'stores/performance_reviews';
import DirectoryCycleName from './DirectoryCycleName';
import {CycleDirectoryState} from '../state';
import {Layout, InteractiveTable, EmptyState, HelperPanel} from 'components';
import {types, endpoints, withState} from 'shared/core';
import {withRouter} from 'react-router-dom';
import {t, auth} from 'shared/core';
import PerformanceReviewEmptyState from 'img/performance-review-empty-state@2x.png';

const COLUMNS = [
  {
    header: 'performance_reviews.directory.Review name',
    width: 11,
    component: DirectoryCycleName
  }
];

const LOCALIZATION = {
  emptyState: 'performance_reviews.directory.No performance reviews have been created yet',
  removeModal: {
    header: 'performance_reviews.directory.Remove performance review?',
    subHeader: 'performance_reviews.directory.Are you sure you want to remove this performance review?',
    body: 'performance_reviews.directory.This performance review, including any feedback already submitted, will be permanently deleted.'
  },
  interactive: {
    addModel: 'performance_reviews.directory.Create New Review'
  }
};

const LINKS = [
  {
    translations: {
      en: 'http://support.collage.co/performance-reviews/creating-a-performance-review'
    },
    title: 'performance_reviews.What is a Performance Review?'
  },
  {
    translations: {
      en: 'http://support.collage.co/performance-reviews/creating-a-question-set'
    },
    title: 'performance_reviews.What are Question Sets?'
  },
  {
    translations: {
      en: 'http://support.collage.co/performance-reviews/launching-a-performance-review'
    },
    title: 'performance_reviews.Launching a Performance Review'
  },
  {
    translations: {
      en: 'http://support.collage.co/performance-reviews/frequently-asked-questions/what-is-the-difference-between-reviews-and-check-ins'
    },
    title: 'performance_reviews.Reviews vs. Check-Ins'
  },
  {
    translations: {
      en: 'http://support.collage.co/en/articles/1976341-closing-and-sharing-performance-reviews'
    },
    title: 'performance_reviews.Closing and Sharing Performance Reviews'
  }
];

const Empty = observer(({uiState}) => {
  return (
    <EmptyState
      img={PerformanceReviewEmptyState}
      title={t('performance_reviews.directory.empty_state.TITLE')}
      descriptionItems={[t('performance_reviews.directory.empty_state.DESCRIPTION')]}
      callToAction={{
        onClick: () => uiState.addNewCycle(),
        title: t('performance_reviews.directory.empty_state.Start a Review')
      }}
    />
  );
});

const ReviewCycleDirectory = observer(({uiState, managesPerformance}) => {
  return (
    <InteractiveTable
      uiState={uiState}
      title={t('performance_reviews.Performance review cycles')}
      columns={COLUMNS}
      saveStateToUrl={true}
      searchable={true}
      localization={LOCALIZATION}
      onAdd={managesPerformance ? () => uiState.addNewCycle() : null}
      onRemove={cycle => uiState.removeCycle(cycle)}
      editRoute='/reviews/:id/edit'
      empty={managesPerformance ? Empty : null}
      proxy={{
        type: 'async',
        modelType: types.PERFORMANCE_REVIEWS.CYCLE,
        model: PerformanceReviewCycle,
        endpoint: endpoints.PERFORMANCE_REVIEWS.CYCLES.DIRECTORY
      }}>
    </InteractiveTable>
  );
});

const ReviewCycleList = observer(({uiState}) => {
  const managesPerformance = auth.hasAccess('::MANAGE_PERFORMANCE_MANAGEMENT');

  if (managesPerformance) {
    return (
      <Layout>
        <Layout.Section>
          <ReviewCycleDirectory uiState={uiState} managesPerformance={managesPerformance}/>
        </Layout.Section>
        <Layout.Section secondary>
          <HelperPanel links={LINKS}/>
        </Layout.Section>
      </Layout>
    );
  }

  return (
    <Layout>
      <ReviewCycleDirectory uiState={uiState} managesPerformance={managesPerformance}/>
    </Layout>
  );
});

export default withRouter(withState(ReviewCycleList, CycleDirectoryState));
