import {observable, action} from 'mobx';
import {DomainStore} from 'shared/store';
import {successAlert} from 'shared/tools';
import {t, endpoints, types} from 'shared/core';

class TrainingAdminDirectoryState {
  history;
  match;
  interactiveAgent;
  store = new DomainStore();

  @observable closeProgramModalOpen = false;
  @observable selectedProgram;

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  @action async destroyProgram(program) {
    await this.store.destroy(program);
    successAlert(t('training.manage.Training program deleted.'));
  }

  @action async createProgram() {
    const {model} = await this.store.post(endpoints.TRAINING.PROGRAMS, types.TRAINING.PROGRAM);
    this.goToEditTraining(model);
  }

  @action async duplicateProgram(program) {
    const {model} = await this.store.post(
      endpoints.TRAINING.PROGRAM_DUPLICATION.with(program.id),
      types.TRAINING.PROGRAM,
      program
    );

    this.goToEditTraining(model);
  }

  @action async closeProgram() {
    const {model} = await this.store.patch(
      endpoints.TRAINING.CLOSE.with(this.selectedProgram.id),
      types.TRAINING.PROGRAM,
      this.selectedProgram
    );

    if (model) {
      this.interactiveAgent.refresh();
      successAlert(t('training.directory.close_program.Training program closed.'));
      this.exitCloseProgramModal();
    }
  }

  @action async reopenProgram() {
    const {model} = await this.store.patch(
      this.selectedProgram.link('reopen'),
      types.TRAINING.PROGRAM,
      this.selectedProgram
    );

    if (model) {
      this.interactiveAgent.refresh();
      successAlert(t('training.directory.reopen_program.Training program reopened.'));
      this.closeReopenProgramModal();
    }
  }

  @action async openCloseProgramModal(program) {
    this.selectedProgram = program;
    this.closeProgramModalOpen = true;
  }

  @action async exitCloseProgramModal() {
    this.closeProgramModalOpen = false;
    this.selectedProgram = null;
  }

  goToEditTraining(model) {
    this.history.push(`${this.match.path}/${model.id}/edit`);
  }

  customLinksFor(program) {
    const links = [
      {
        order: 3,
        text: 'training.directory.Duplicate',
        action: program => this.duplicateProgram(program)
      }
    ];

    if (program.hasLink('close')) {
      links.push({
        order: 4,
        text: 'training.directory.Close',
        action: program => this.openCloseProgramModal(program)
      });
    }
    if (program.hasLink('reopen')) {
      links.push({
        order: 0,
        text: 'training.directory.Reopen',
        action: program => this.openReopenProgramModal(program)
      });
    }

    return links;
  }
}

export default TrainingAdminDirectoryState;
