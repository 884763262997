import {DomainObject, oneOf} from 'shared/store';
import {observable, action} from 'mobx';

class Calendar extends DomainObject {
  @observable calendarType;
  @observable enabled;

  @action merge(other, fields) {
    super.merge(other, {
      ...fields,
      calendarType: oneOf(['pto_company', 'pto_department', 'birthdays_company', 'work_anniversaries_company'])
    });
  }
}

export default Calendar;
