import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {MyCoverageContainerState} from '../state';
import CardDetails from './CardDetails';
import {Coverages} from 'components/benefits';

const MyCoverageContainerWithState = observer(({uiState}) => {
  const {employeeCoverage, currentCoverage, currentClass} = uiState;

  return (
    <Coverages
      benefitClasses={employeeCoverage.allBenefitClasses}
      onCoverageSelected={(coverage, benefitClass) => uiState.viewCoverage(coverage, benefitClass)}
      currentCoverage={currentCoverage}
      currentClass={currentClass}>
      <CardDetails uiState={uiState} />
    </Coverages>
  );
});

export default withState(MyCoverageContainerWithState, MyCoverageContainerState);
