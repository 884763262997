import _ from 'lodash';
import {getSeriesAndCategories} from './tools';
import chart from './chart';
import defaultConfig from './defaultConfig';

function areaChart({rows, meta}, options = {}) {
  const {series} = meta;
  const {categories, highchartsSeries} = getSeriesAndCategories(rows, meta);

  return {
    ...defaultConfig(series),
    chart: {
      ...chart('area')
    },
    plotOptions: {
      area: {
        stacking: options.stacking,
        marker: {
          enabled: false
        },
        lineWidth: 3
      }
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      title: {
        text: null
      }
    },
    series: highchartsSeries
  };
}

export default areaChart;
