import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {t, endpoints, types} from 'shared/core';
import {PerformanceReviewCycle, PerformanceReviewQuestionWithType, PerformanceReviewResult} from 'stores/performance_reviews';
import {nineboxOptions, heatmapOptions, barGraphOptions, distributionOptions} from './highcharts_options';
import _ from 'lodash';

class CycleResultsState {
  match;
  store = new DomainStore();

  @observable cycle;
  @observable selectedGraph = 'barGraph';

  @observable result = new PerformanceReviewResult({});
  @observable endpoint;
  @observable selectedQuestion1;
  @observable selectedQuestion2;

  @observable selectedOption;
  @observable selectedEmployeeIds = [];

  receiveProps({match, history, scope}) {
    this.match = match;
    this.history = history;
    this.scope = scope;
  }

  @action async load() {
    const id = this.match.params.cycleId;

    await this.store._compose([
      `${endpoints.PERFORMANCE_REVIEWS.CYCLE.with(id)}?include=reviewees,reviewees.employee,types.template.questions`
    ]);

    this.cycle = new PerformanceReviewCycle(
      this.store._getSingle(types.PERFORMANCE_REVIEWS.CYCLE)
    );
    this.employees = _.map(this.cycle.reviewees, 'employee');

    this.selectQuestion1(this.questions[0]);
    this.selectQuestion2(this.questions[1]);

    const [
      highcharts,
      highchartsReact,
      highchartsHeatmap,
      highchartsExporting,
      highchartsOfflineExporting
    ] = await Promise.all([
      import(/* webpackChunkName: 'highcharts-async' */ 'highcharts'),
      import(/* webpackChunkName: 'highcharts-react-official-async' */ 'highcharts-react-official'),
      import(/* webpackChunkName: 'highcharts-heatmap' */ 'highcharts/modules/heatmap'),
      import('highcharts/modules/exporting'),
      import('highcharts/modules/offline-exporting')
    ]);

    this.Highcharts = highcharts.default;
    this.HighchartsReact = highchartsReact.default;
    highchartsHeatmap.default(this.Highcharts);
    highchartsExporting.default(highcharts.default);
    highchartsOfflineExporting.default(highcharts.default);
  }

  @action async selectGraph(graphType) {
    this.selectedGraph = graphType;
    this.updateEndpoint();
  }

  @action selectQuestion1(question) {
    this.selectedQuestion1 = question;
    this.updateEndpoint();
  }

  @action selectQuestion2(question) {
    this.selectedQuestion2 = question;
    this.updateEndpoint();
  }

  @action updateResults(interactiveContextState) {
    if (this.selectedGraph !== _.camelCase(interactiveContextState.data.resultType)) return;

    this.result = new PerformanceReviewResult(interactiveContextState.data);
  }

  @computed get barGraphOptions() {
    return barGraphOptions();
  }

  @computed get distributionOptions() {
    return _.merge(distributionOptions(), {
      plotOptions: {
        column: {
          events: {
            click: (e) => {
              this.selectedEmployeeIds = e.point.custom.employeeIds;
              this.selectedOption = e.point.category;
              this.openEmployeeAnswersModal();
            }
          }
        }
      }
    });
  }

  @computed get heatmapOptions() {
    return heatmapOptions(this.result);
  }

  @computed get nineboxOptions() {
    if (!this.showNinebox) return {};

    return nineboxOptions(this.selectedQuestion1, this.selectedQuestion2, this.result, this.Highcharts);
  }

  barGraphTrend(answers, questionOptions) {
    const data = answers.map(answer => {
      return {
        name: answer.employeeId,
        y: answer.score
      };
    });

    return {
      ...this.barGraphOptions,
      series: [{
        data
      }],
      tooltip: {
        useHTML: true,
        formatter: function() {
          const employeeName = this.x;

          let tooltip = `<strong>${employeeName}</strong>`;
          tooltip += `<div style="padding-top: 8px">${t('performance_reviews.view.cycle_results.BAR_GRAPH_TOOLTIP', {score: this.point.y, value: questionOptions[Math.round(this.point.y - 1)]})}</div>`;

          return tooltip;
        }
      },
      xAxis: {
        ...this.barGraphOptions.xAxis,
        categories: _.map(answers, 'employeeName')
      }
    };
  }

  distributionTrend(answers, questionOptions) {
    const data = _.map(answers, (employeeIds, answer) => {
      return {
        name: answer,
        y: employeeIds.length,
        custom: { employeeIds }
      };
    });

    return {
      ...this.distributionOptions,
      series: [{
        data
      }],
      tooltip: {
        useHTML: true,
        formatter: function() {
          return `<span>${t('performance_reviews.view.cycle_results.DISTRIBUTION_TOOLTIP', {count: this.y, option: this.x})}</span>
            <strong>${this.x}</strong>`;
        }
      },
      xAxis: {
        ...this.distributionOptions.xAxis,
        categories: questionOptions
      },
    };
  }

  @computed get showNinebox() {
    return _.get(this.selectedQuestion1, 'id') && _.get(this.selectedQuestion2, 'id');
  }

  @computed get answeredEmployees() {
    return _.chain(this.employees)
      .filter(employee => _.includes(this.selectedEmployeeIds, parseInt(employee.id)))
      .sortBy('name')
      .value();
  }

  @action updateEndpoint() {
    if (this.selectedGraph === 'ninebox' && !this.showNinebox) {
      return;
    }

    switch (this.selectedGraph) {
      case 'barGraph':
        this.endpoint = endpoints.PERFORMANCE_REVIEWS.CYCLE_RESULTS.BAR_GRAPH.with(this.cycle.id);
        break;
      case 'distribution':
        this.endpoint = endpoints.PERFORMANCE_REVIEWS.CYCLE_RESULTS.DISTRIBUTION.with(this.cycle.id);
        break;
      case 'heatmap':
        this.endpoint = endpoints.PERFORMANCE_REVIEWS.CYCLE_RESULTS.HEATMAP.with(this.cycle.id);
        break;
      case 'ninebox':
        this.endpoint = endpoints.PERFORMANCE_REVIEWS.CYCLE_RESULTS.NINEBOX.with(
          this.cycle.id,
          this.selectedQuestion1.id,
          this.selectedQuestion2.id,
        );
        break;
      default:
        throw new Error('Unrecognized graph type');
    }
  }

  @computed get questions() {
    const typedQuestions = [];

    this.cycle.types.forEach(type => {
      typedQuestions.push(
        ..._.chain(type.template.questions)
          .filter({questionType: 'scored'})
          .map(question => {
            return new PerformanceReviewQuestionWithType({
              id: `${question.id}-${type.kind}`,
              questionName: question.name,
              questionOptions: question.options,
              type: type.kind,
              typeId: type.id
            });
          })
          .value()
      );
    });

    return typedQuestions;
  }

  @computed get enableComparativeGraphs() {
    return this.questions.length > 1;
  }

  trendsForType(type) {
    return _.filter(this.result.trend, {reviewType: type.kind});
  }
}

export default CycleResultsState;
