import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, auth, t} from 'shared/core';
import _ from 'lodash';
import PDF from './org-chart-export';
import CompanyIcon from 'img/company-avatar-placeholder@2x.png';
import uuid from 'uuid';

function createCompanyNode(allEmployees) {
  return {
    id: uuid.v4(),
    name: auth.unescapedCompanyName,
    jobTitle: t('employees.orgchart.Organization'),
    links: {
      profilePicture: CompanyIcon
    },
    children: _.reject(allEmployees, 'managerId').map(e => e.id),
    left: 0
  };
}

function createEmployeeNode(e, allEmployees) {
  return {
    id: e.id,
    name: e.name,
    jobTitle: e.jobTitle || '',
    children: _.filter(allEmployees, {managerId: e.id}).map(e => e.id),
    links: e.links,
    avatarColour: e.avatarColour,
    avatarInitials: e.avatarInitials,
    profileUrl: `/employees/${e.id}`
  };
}

class OrgChartContainerState {
  store = new DomainStore();
  companyNode;

  @observable employees = {};
  @observable allEmployees;
  @observable rootId;

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL
    ]);

    this.resetChart();
  }

  registerUpdateZoomFn(fn) {
    // todo: this is super fucking weird -- refactor this
    this.updateZoom = fn;
  }

  @action changeRootId(rootId) {
    this.rootId = rootId;
  }

  @action async downloadPdf() {
    const pdf = new PDF(this.rootId, this.employees);
    await pdf.downloadPDF(t('employees.orgchart.ORGCHART_NAME', {
      company: auth.unescapedCompanyName,
      employee: this.rootEmployee.name
    }));
  }

  @action resetRoot() {
    this.changeRootId(this.companyNode.id);
  }

  @action resetChart() {
    this.allEmployees = this.store.getEmployees();
    this.employees = _.fromPairs(this.allEmployees.map(e => [
      e.id,
      createEmployeeNode(e, this.allEmployees)
    ]));

    this.companyNode = createCompanyNode(this.allEmployees);
    this.employees[this.companyNode.id] = this.companyNode;

    this.resetRoot();
  }

  @action zoomIn() {
    if (!this.updateZoom) return;

    this.updateZoom(0.1);
  }

  @action zoomOut() {
    if (!this.updateZoom) return;

    this.updateZoom(-0.1);
  }

  @computed get rootEmployee() {
    return this.employees[this.rootId];
  }
}

export default OrgChartContainerState;
