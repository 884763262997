import {DomainObject, oneOf} from 'shared/store';
import _ from 'lodash';
import {observable, action, computed} from 'mobx';
import {calendarDate} from 'shared/tools';
import PerformanceReviewType from './PerformanceReviewType';
import PerformanceReviewReviewee from './PerformanceReviewReviewee';

class PerformanceReviewCycle extends DomainObject {
  @observable id;
  @observable name;
  @observable state;
  @observable types;
  @observable reviewees;
  @observable dueDate;
  @observable submittedReviewsCount;
  @observable pendingReviewsCount;
  @observable totalReviewsCount;
  @observable reviewersCount;
  @observable createdAt;
  @observable completedReviewees;
  @observable startedReviewees;

  @action merge(other) {
    super.merge(other, {
      types: [PerformanceReviewType],
      reviewees: [PerformanceReviewReviewee],
      state: oneOf(['created', 'configured', 'launched', 'ended']),
      _dates: ['dueDate']
    });
  }

  @computed get isLaunched() {
    return this.state === 'launched';
  }

  @computed get hasEnded() {
    return this.state === 'ended';
  }

  @computed get managerType() {
    return _.find(this.types, {kind: 'manager'});
  }

  @computed get selfType() {
    return _.find(this.types, {kind: 'self'});
  }

  @computed get peerType() {
    return _.find(this.types, {kind: 'peer'});
  }

  @computed get directReportType() {
    return _.find(this.types, {kind: 'direct_report'});
  }

  @computed get dueDateView() {
    return calendarDate(this.dueDate);
  }

  @computed get submittedReviewsPercentage() {
    if (this.totalReviewsCount === 0) return 0;

    return _.ceil((this.submittedReviewsCount / this.totalReviewsCount) * 100);
  }

  @computed get pendingReviewsPercentage() {
    if (this.totalReviewsCount === 0) return 0;

    return _.ceil((this.pendingReviewsCount / this.totalReviewsCount) * 100);
  }

  @computed get completedRevieweesPercentage() {
    if (this.reviewees.length === 0) return 100;

    return _.ceil((this.completedReviewees / this.reviewees.length) * 100);
  }
}

export default PerformanceReviewCycle;
