import {DomainObject} from 'shared/store';
import {action, observable} from 'mobx';
import {Employee} from '../employees';

class Celebration extends DomainObject {
  @observable employee;
  @observable publishedAt;

  @action merge(other) {
    super.merge(other, {
      employee: Employee,
      _dates: ['publishedAt']
    });
  }
}

export default Celebration;
