import React from 'react';
import {observer} from 'mobx-react';
import Name from './Name';
import {Questions} from './questions';
import {Participants} from './participants';
import {Review} from './review';

@observer class Step extends React.Component {
  render() {
    const {step, uiState} = this.props;

    switch (step) {
      case 'name':
        return <Name uiState={uiState}/>;
      case 'questions':
        return <Questions uiState={uiState}/>;
      case 'participants':
        return <Participants uiState={uiState}/>;
      case 'review':
        return <Review uiState={uiState}/>;
      default:
        throw new Error(`Location ${step} is not supported.`);
    }
  }
}

export default Step;
