import React from 'react';
import {observer} from 'mobx-react';
import {action, observable} from 'mobx';
import InteractiveContext from './InteractiveContext';
import Pagination from './pagination/Pagination';
import {FilterRow} from 'components/tables/interactive/components/FilterRow';
import TableOverlay from 'components/tables/components/TableOverlay';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';
import {Panel} from 'components';

const SelectableList = observer((props) => {
  const {
    columns,
    models,
    isUpdating,
    selectedModel,
    emptyStateMessage,
    onRowClicked,
    ...rest
  } = props;

  if (_.isEmpty(models)) {
    return (<div className='center jumbo rounded h5 py2 px1 Table-emptyState'>
      <FormattedMessage id={emptyStateMessage}/>
    </div>);
  }

  return <div className={`${isUpdating ? 'relative' : ''}`}>
    <TableOverlay isUpdating={isUpdating}/>
    <table className='full-width'>
      <tbody>
      {models.map(model =>
        <tr className={`TableRow-normal clickable ${selectedModel && model.id === selectedModel.id ? 'bg-sky-important border-right--dodger' : ''}`} onClick={() => onRowClicked(model)}>
          {columns.map((column, index) => (
            <td
              key={index}
              className={`col-${column.width}`}
            >
              <column.component model={model} {...rest}/>
            </td>
          ))}
        </tr>
      )}
      </tbody>
    </table>
  </div>;
});

@observer class InteractiveSelectableList extends React.Component {
  @observable isEmpty = false;
  @observable isLoading = true;

  @action handleOnMount = (agent) => {
    this.isEmpty = agent.isEmpty;
    this.props.onMount && this.props.onMount(agent);
    if (agent.models.length > 0) {
      this.props.onRowClicked(agent.models[0]);
    } else {
      this.props.onEmptyLoad();
    }
    this.isLoading = false;
  };

  render() {
    const {
      searchable,
      proxy,
      children,
      initialFilter,
      initialSorting,
      hidePaginationInfo,
      fancyFiltersEnabled,
      onFilterUpdated,
      scopeOptions,
      title,
      ...rest
    } = this.props;

    return (
      <div>
        {title && <Panel.Header title={title}/>}
        <div className='Table'>
          <InteractiveContext
            proxy={proxy}
            onMount={this.handleOnMount}
            initialFilter={initialFilter}
            initialSorting={initialSorting}
            onFilterUpdated={onFilterUpdated}
          >
            <FilterRow searchable={searchable} fancyFiltersEnabled={fancyFiltersEnabled} scopeOptions={scopeOptions}>
              {children}
            </FilterRow>
            <SelectableList {...rest}/>
            <Pagination hidePaginationInfo={hidePaginationInfo}/>
          </InteractiveContext>
        </div>
      </div>
    );
  }
}

InteractiveSelectableList.defaultProps = {
  searchable: true,
  paginatable: true,
  onFilterUpdated: () => {},
  localization: {}
};

export default InteractiveSelectableList;
