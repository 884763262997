import {action, observable, computed} from 'mobx';
import {DomainObject} from 'shared/store';
import AttachedDocument from '../documents/AttachedDocument';
import FilesIcon from 'img/settings/settings-documents@2x.png';

class CompanyFile extends DomainObject {
  @observable id;
  @observable name;
  @observable order;

  @observable file;
  @observable companyFileFolderId

  @action merge(other) {
    super.merge(other, {
      file: AttachedDocument
    });
  }

  @computed get icon() {
    return FilesIcon;
  }

  @computed get key() {
    return `file-${this.id}`;
  }
}

export default CompanyFile;
