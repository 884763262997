import * as Sentry from "@sentry/react";
import _ from 'lodash';
/*global window */

const setupSentry = () => {
  if (process.env.__PROD__) {
    Sentry.init({
      dsn: 'https://de0fba07a7d64086a7da204e2b64c06e@o38063.ingest.sentry.io/129114',
      allowUrls: [/secure\.collage\.co/, /assets-secure\.collage\.co\/.*\.js/],
      ignoreErrors: [
        'Request failed with status code',
        'Network Error',
        'timeout of 0ms exceeded',
        'Request aborted'
      ],
      release: window.release
    });

    if (window.auth) {
      Sentry.setUser({
        ...window.auth.user,
        companyId: window.auth.company.id,
        companyName: window.auth.company.name
      });
      Sentry.setTags({
        company_id: window.auth.company.id,
        company_name: window.auth.company.name,
        user_id: window.auth.user.id
      });
    }
  }
};

export default setupSentry;
