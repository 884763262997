import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {ClientsByCarrierChartState} from '../state';
import {Panel, Spinner} from 'components';
import {t} from 'shared/core';

const ClientsByCarrierChart = observer(({uiState}) => {
  const {Highcharts, HighchartsReact, chartOptions} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('broker_portal.dashboard.Clients by Insurance Carrier')} />
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </Panel>
  );
});

const ClientSpinner = () => {
  return (
    <Panel>
      <Panel.Header
        title={t('broker_portal.dashboard.Clients by Insurance Carrier')}
        description={t('broker_portal.dashboard.Loading data...')}
      />
      <div className='Report-empty-placeholder py3'>
        <Spinner />
      </div>
    </Panel>
  );
};

export default withState(ClientsByCarrierChart, ClientsByCarrierChartState, {spinner: ClientSpinner});
