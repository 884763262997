import React from 'react';
import {observer} from 'mobx-react';
import {auth, withState} from 'shared/core';
import {Layout, Panel} from 'components';
import {CustomReportIndexState} from 'reports/custom/list/state';
import MyReportsTable from './MyReportsTable';
import SharedReportsTable from './SharedReportsTable';

const CustomReportsIndexWithState = observer(({uiState}) => {
  return (
    <Layout>
      <Layout.Section secondary>
        <Panel.Stack loose>
          {auth.hasAccess('::CUSTOM_REPORT') && <MyReportsTable uiState={uiState}/>}
          <SharedReportsTable uiState={uiState}/>
        </Panel.Stack>
      </Layout.Section>
    </Layout>
  );
});

export default withState(CustomReportsIndexWithState, CustomReportIndexState);
