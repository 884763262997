import React from 'react';
import {observer} from 'mobx-react';
import {Checkbox, Panel} from 'components';
import {FormattedMessage} from 'react-intl';
import Description from './Description';
import ReportPermissions from './ReportPermissions';
import _ from 'lodash';
import {t} from 'shared/core';

const PermissionRow = observer(({viewModel}) => {
  return (
    <div className='clearfix p2 border-top clickable bg-hover-smoke' onClick={() => viewModel.toggle()}>
      <div className='col col-1'>
        <Checkbox checked={viewModel.checked} onChange={() => viewModel.toggle()}/>
      </div>
      <div className='col col-5 pr4'>
        <div className='h3 medium'>
          <FormattedMessage id={`company_settings.permissions.${viewModel.featurePermission.name}`}/>
        </div>
      </div>
      <div className='col col-6'>
        <Description descriptionItems={viewModel.featurePermission.descriptionItems}/>
      </div>
    </div>
  );
});

const FeaturePermissions = observer(({uiState}) => {
  return (
    <Panel>
      <Panel.Header title={t('company_settings.permissions.What feature access should be permitted?')}/>
      <div className='h4 waterloo mb2'>
        <FormattedMessage id='company_settings.permissions.Choose the feature permissions accessible to users with this role.'/>
      </div>
      <div className='clearfix px2 py1'>
        <div className='col col-1'>
          &nbsp;
        </div>
        <div className='col col-5 waterloo h5'>
          <FormattedMessage id='company_settings.permissions.Type' />
        </div>
        <div className='col col-6 waterloo h5'>
          <FormattedMessage id='company_settings.permissions.This grants permission to:' />
        </div>
      </div>
      <div>
        {uiState.flatPermissions.map((viewModel) =>
          <PermissionRow key={viewModel.featurePermission.name} viewModel={viewModel} />)}
        <ReportPermissions uiState={uiState}/>
      </div>
    </Panel>
  );
});

export default FeaturePermissions;
