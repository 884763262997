import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {ActionLink, Panel, DragAndDrop, EditQuestionModal, FormError} from 'components';
import DragAndDropContext from 'components/dnd/DragAndDropContext';
import {t} from 'shared/core';
import QUESTION_TYPES from 'stores/recruiting/questionTypes';
import EditDefaultQuestionModal from './EditRequireResumeModal';

const QuestionContent = observer(({model}) => {
  return (
    <div>
      {model.name && <span className='mr1'>{model.name}</span>}
      <span className='waterloo'>
        {t(`models.job_application_question.question_type.${model.questionType}`)}
      </span>
      <span className='ml1 waterloo'>
        {model.required
          ? t('recruiting.edit.steps.(required)')
          : t('recruiting.edit.steps.(optional)')
        }
      </span>
    </div>
  );
});

const DefaultQuestionItem = observer(({question}) => {
  return (
    <div key={question.name} className={`clearfix table ${question.onEdit ? 'HoverAnimation-trigger' : ''}`}>
      <div className='col-10 table-cell align-middle pr1'>
        <div className='p2 my2 bg-athens rounded'>
          <div className='inline-block'>
            <span className='mr1'><FormattedMessage id={question.name}/></span>
            <span className='waterloo'>
              {t(`models.job_application_question.question_type.${question.questionType}`)}
            </span>
            <span className='ml1 waterloo'>
              {question.optional
                ? t('recruiting.edit.steps.(optional)')
                : t('recruiting.edit.steps.(required)')
              }
            </span>
          </div>
        </div>
      </div>
      <div className='col-2 table-cell align-middle pl1'>
        {question.onEdit && <div className='HoverAnimation-target--show'>
          <ActionLink onClick={() => question.onEdit()}>
            <i className='material-icons h2 align-middle submarine pr1'>{'edit'}</i>
          </ActionLink>
        </div>}
      </div>
    </div>
  );
});

const DefaultQuestionList = observer(({questions}) => {
  return questions.map(question => <DefaultQuestionItem question={question}/>);
});

const LOCALIZATION = {
  title: {
    add: 'recruiting.edit.steps.Add question',
    edit: 'recruiting.edit.steps.Edit question'
  },
  question: 'recruiting.edit.steps.Question',
  questionType: 'recruiting.edit.steps.Type',
  required: 'recruiting.edit.steps.Require candidates to answer this question'
};

const Questions = observer(({uiState}) => {
  const {position, editingQuestion, defaultQuestions, errors} = uiState;

  return (
    <div>
      <Panel.Header title={t('recruiting.edit.steps.Application Form')}/>
      <div className='jumbo max-width-2'>
        <div><FormattedMessage id='recruiting.edit.steps.Customize the application form for this job.'/></div>
        <div><FormattedMessage id='recruiting.edit.steps.Drag and drop the questions using the handles to specify the order.'/></div>
      </div>
      <DefaultQuestionList questions={defaultQuestions}/>
      <DragAndDrop
        models={position.questions}
        component={QuestionContent}
        onEdit={(question) => uiState.editQuestion(question)}
        onRemove={(question) => uiState.removeQuestion(question)}
        errors={errors}
        errorPath={(index => `questions[${index}].name`)}
      />
      <FormError message={errors.base}/>
      <ActionLink onClick={() => uiState.addQuestion()}>
        <FormattedMessage id='recruiting.edit.steps.+ Add new field' />
      </ActionLink>
      <EditQuestionModal
        question={editingQuestion}
        questionTypes={QUESTION_TYPES}
        isOpen={uiState.editQuestionModalOpen}
        onClose={() => uiState.closeQuestionModal()}
        onSave={() => uiState.saveQuestion()}
        localization={LOCALIZATION}
        errorMessage={errors.options}
        showDescription
      />
      <EditDefaultQuestionModal uiState={uiState}/>
    </div>
  );
});

export default DragAndDropContext(Questions);
