// creates a FilterRowViewModel using either a category or model
// category represents a category in the dropdown menu (e.g., 'Department')
// and is used to create new filter rows as user add new filters
// model (i.e., json object that came from server) represents a previously saved filter row
// and is used to pre-populate reports with saved filters, or restore
// the state of the configuration flow that includes filters
import createPayRateViewModel from './createPayRateViewModel';
import createPayRateUnitViewModel from './createPayRateUnitViewModel';
import createRowForCategory from './createRowForCategory';
import getCategoryForAttribute from './getCategoryForAttribute';

function filterRowFactory({category, model, customReportId}) {
  if (category) {
    return createRowForCategory(category, customReportId);
  }

  if (model) {
    switch (model.attribute) {
      case 'pay_rate':
        return createPayRateViewModel(model);
      case 'pay_rate_unit':
        return createPayRateUnitViewModel(model);
      default:
        const category = getCategoryForAttribute(model.attribute);
        return createRowForCategory(category, customReportId);
    }
  }

  throw new Error('Either the category or model must be provided.');
}

export default filterRowFactory;
