import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {auth, t} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const PasswordResetModal = observer(({uiState}) => {
  const {editingUser} = uiState;

  return (
    <Modal
      size='sm'
      isOpen={uiState.passwordResetModalOpen}
      onHide={() => uiState.closePasswordResetModal()}
      title={t(`company_settings.users.${auth.company.ssoEnabled ? 'SEND_SSO_CONNECTION_FOR' : 'RESET_PASSWORD_FOR'}`, { name:  editingUser.name })}
      translateTitle={false}
    >
      <FormattedMessage
        id={`company_settings.users.${auth.company.ssoEnabled ? 'SEND_SSO_CONNECTION_DESCRIPTION' : 'SEND_PASSWORD_RESET_DESCRIPTION'}`}
        values={{ email: <a>{editingUser.email}</a> }}
      />
      <ModalButtons
        onSave={() => uiState.sendPasswordReset()}
        onCancel={() => uiState.closePasswordResetModal()}
        saveCaption='company_settings.users.Send'
      />
    </Modal>
  );
});

export default PasswordResetModal;
