import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {BenefitInfoEditState, steps} from '../state';
import {Flow, QuickTip, Layout} from 'components';
import Step from './steps';
import {FormattedMessage} from 'react-intl';

const LOCALIZATION = {
  finish: 'benefits.benefit_info.edit.Submit'
};

const WarningMessage = ({uiState}) => {
  const {isSelf, employee} = uiState;
  if (isSelf) return null;

  return (
    <div className='mt3 mb2'>
      <Layout>
        <QuickTip trait='caution' header={t("benefits.benefit_info.edit.CHANGING_EMPLOYEE_INFORMATION", {name: employee.name})}
          paragraphs={[<FormattedMessage id="benefits.benefit_info.edit.CHANGING_ON_BEHALF_OF" values={{name: employee.name}}/>]}/>
      </Layout>
    </div>
  );
};

const BenefitInfoEditFlow = observer(({uiState}) => {
  const {backPath, backTitle, baseUrl} = uiState;

  return (
    <div>
      <WarningMessage uiState={uiState} />
      <Flow
        name={t('benefits.benefit_info.edit.Changing my benefit enrolment')}
        backTitle={backTitle}
        backPath={backPath}
        steps={steps}
        component={Step}
        uiState={uiState}
        baseUrl={baseUrl}
        onStepChanged={step => uiState.onStepChanged(step)}
        onStepSubmitted={step => uiState.onStepSubmitted(step)}
        nextEnabled={uiState.nextEnabled}
        localization={LOCALIZATION}
      />
    </div>
  );
});

export default withState(BenefitInfoEditFlow, BenefitInfoEditState);
