import {DomainObject} from 'shared/store';
import {observable} from 'mobx';

class Folder extends DomainObject {
  @observable name;
  @observable createdAt;
  @observable updatedAt;
}

export default Folder;
