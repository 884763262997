import React from 'react';
import {observer} from 'mobx-react';
import {Layout, Panel, Pagination, InteractiveContext} from 'components';
import {withState, types, endpoints, t} from 'shared/core';
import {BenefitUpdatesState} from '../state';
import {calendarDate} from 'shared/tools';
import BenefitUpdateForEmployee from './BenefitUpdateForEmployee';
import BenefitUpdatesEmptyState from './BenefitUpdatesEmptyState';
import {BenefitUpdate} from 'stores/benefits';
import _ from 'lodash';

const SearchBox = observer(({filter, filterUpdated}) => {
  return (
    <Panel className='mb2'>
      <input
        type='search'
        className='large'
        defaultValue={filter ? filter.get('search') : ''}
        placeholder={t('benefits.updates.Search by member name')}
        onChange={e => filterUpdated({search: e.target.value})}
      />
    </Panel>
  );
});

const BenefitUpdateDigest = observer(({uiState}) => {
  const {updatesByDate, hasResults} = uiState;

  if (_.isEmpty(updatesByDate)) {
    return <BenefitUpdatesEmptyState hasResults={hasResults} />;
  }

  return (
    updatesByDate.map(updateWithDate => (
      <Panel.Stack className='mb2' key={updateWithDate.date}>
        <Panel>
          <Panel.Header title={calendarDate(updateWithDate.date)}></Panel.Header>
        </Panel>
        {updateWithDate.items.map(i => <BenefitUpdateForEmployee update={i} key={i.id}/>)}
      </Panel.Stack>
    )));
});

const BenefitUpdates = observer(({uiState}) => {
  return (
    <Layout>
      <div className='relative'>
        <InteractiveContext
          proxy={{
            type: 'async',
            modelType: types.BENEFITS.UPDATE,
            model: BenefitUpdate,
            endpoint: endpoints.BENEFITS.UPDATES,
            pageSize: 50
          }}
          initialFilter={{search: ''}}
          onRefresh={({data}) => uiState.onPagination(data)}>
          <SearchBox />
          <BenefitUpdateDigest uiState={uiState}/>
          <Pagination />
        </InteractiveContext>
      </div>
    </Layout>
  );
});

export default withState(BenefitUpdates, BenefitUpdatesState);
