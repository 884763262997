import React from 'react';
import {observer} from 'mobx-react';
import {TimeTrackingSubnav} from 'containers/time_tracking';
import MyTimesheetContainerWithState from 'containers/time_tracking/components/my_timesheet/components/MyTimesheetContainerWithState';

const MyTimesheetContainer = observer(({scope}) => {
  return (
    <div>
      <TimeTrackingSubnav scope={scope} />
      <MyTimesheetContainerWithState/>
    </div>
  );
});

export default MyTimesheetContainer;
