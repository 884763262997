import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import {Input, CopyableField} from 'components';

const EditUserApiKeyModal = observer(({uiState, modalOpen}) => {
  const {selectedUserApiKey, displayApiKey, errors} = uiState;
  const header = selectedUserApiKey.isNew ? 'company_settings.applications.zapier.Generate API Key' : 'company_settings.applications.zapier.Edit API Key';

  return (
    <Modal
      title={header}
      size='md'
      isOpen={modalOpen}
      onHide={() => uiState.closeEditUserApiKeyModal()}
    >
      <Input
        label='company_settings.applications.zapier.Label'
        placeholder='company_settings.applications.zapier.e.g. Zapier API Key'
        value={selectedUserApiKey.label}
        errorMessage={errors.label}
        onChange={(e) => selectedUserApiKey.merge({label: e.target.value})}
      />
      {displayApiKey && <div className='mt2'>
        <div className='h4 jumbo mb1'>
          <FormattedMessage id='company_settings.applications.zapier.Copy your API key below and use it to integrate with Collage in Zapier. This is the only time you will be able to see this API key.'/>
        </div>
        <div className='full-width'>
          <CopyableField fieldValue={selectedUserApiKey.apiKey}/>
        </div>
      </div>}
      <ModalButtons
        onSave={() => selectedUserApiKey.value ? uiState.closeEditUserApiKeyModal() : uiState.saveUserApiKey()}
        onCancel={() => uiState.closeEditUserApiKeyModal()}
      />
    </Modal>
  );
});

export default EditUserApiKeyModal;
