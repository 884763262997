const ATS_MERGE_FIELDS = [
  {
    key: 'position_name',
    label: 'company_settings.company_email_templates.merge_fields.Position Name',
    placeholder: 'company_settings.company_email_templates.merge_fields.#[Position Name]'
  },
  {
    key: 'candidate_first_name',
    label: 'company_settings.company_email_templates.merge_fields.Candidate First Name',
    placeholder: 'company_settings.company_email_templates.merge_fields.#[Candidate First Name]',
  },
  {
    key: 'candidate_last_name',
    label: 'company_settings.company_email_templates.merge_fields.Candidate Last Name',
    placeholder: 'company_settings.company_email_templates.merge_fields.#[Candidate Last Name]',
  },
  {
    key: 'current_date',
    label: 'company_settings.company_email_templates.merge_fields.Current Date',
    placeholder: 'company_settings.company_email_templates.merge_fields.#[Current Date]'
  },
  {
    key: 'company_name',
    label: 'company_settings.company_email_templates.merge_fields.Company Name',
    placeholder: 'company_settings.company_email_templates.merge_fields.#[Company Name]'
  }
];

export default ATS_MERGE_FIELDS;
