import React from 'react';
import LayoutContext from './LayoutContext';
import PropTypes from 'prop-types';

function calculateWidth(layout, secondary) {
  if (layout.centered) return 6;

  switch (layout.childrenCount) {
    case 1:
      if (secondary) return 8;
      return 12;
    case 2:
      if (!secondary && !layout.hasSecondary) return 6;
      if (layout.condensed) {
        if (secondary) return 3;
        return 9;
      }
      if (secondary) return 4;
      return 8;
    case 3:
      return 4;
    default:
      throw new Error(`Layout can contain up to three sections. You have defined a Layout with ${layout.childrenCount} sections, which is invalid.`);
  }
}

const LayoutSection = ({secondary, children}) => {
  return (
    <LayoutContext.Consumer>
      {layout => (
        <div className={`${!layout.centered ? 'col' : 'mx-auto'} col-12 md-col-${calculateWidth(layout, secondary)} px1`}>
          {children}
        </div>
      )}
    </LayoutContext.Consumer>
  );
};

LayoutSection.propTypes = {
  secondary: PropTypes.bool
};

LayoutSection.defaultProps = {
  secondary: false
};

/*
  LayoutSection defines a section (or column) in Layout.

  default behaviour (with no props defined on Layout):
  1 column: takes the full width of the Layout, unless marked as `secondary`, in which case it takes 2/3 of the width
  2 columns: each takes 1/2 width, unless one is marked as `secondary` and the other is not marked as `secondary`.
    in that case the secondary column takes 1/3 of the width, and the other column takes 2/3 of the width.
    (if both are secondary, each is 1/2 width.)
  3 columns: each takes 1/3 of the width.
  more than 3 columns: not allowed.

  centered layout behaviour: (<Layout centered>):
  1 column: takes 1/2 of the width and is horizontally centered.
  more than 1 column: not allowed.

  condensed layout behaviour: (<Layout condensed>):
  the same rules as in the default behaviour, but any time a column would take 1/3 of the width, it takes 1/4 instead,
  and any time it would take 2/3 of the width, it takes 3/4 instead.

  note that `centered` takes precedence over `condensed`.

  example of usage:
  <Layout>
    <Layout.Section secondary>...</Layout.Section>
    <Layout.Section>...</Layout.Section>
  </Layout>
*/

export default LayoutSection;
