import React from 'react';
import _ from 'lodash';
import {Route, Switch} from 'react-router-dom';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import AdminRevieweesContainer from './checkins/admin/AdminRevieweesContainer';
import AdminRevieweeDetailsContainer from './checkins/admin/AdminRevieweeDetailsContainer';
import CycleDirectory from './cycles/landing/CycleDirectory';
import CheckInState from '../state/checkins/CheckInState';
import CycleFlow from './cycles/CycleFlow';

const CyclesSwitch = observer(({scope, uiState}) => {
  return (
    <Switch>
      <Route exact path='/cycles' render={() => <CycleDirectory scope={scope}/>}/>
      <Route exact path='/cycles/:id/reviewees' component={AdminRevieweesContainer}/>
      <Route exact path='/cycles/:id/reviewees/:employeeId' render={(props) => <AdminRevieweeDetailsContainer {...props} checkinStore={uiState}/>}/>
      <Route path='/cycles/:id' component={CycleFlow}/>
    </Switch>
  );
});

export default withState(CyclesSwitch, CheckInState);
