import _ from 'lodash';
import getNavigationItems from './navigationItems';
import {types, endpoints} from 'shared/core';
import {DomainStore, fetchModel} from 'shared/store';
import accentFold from 'stores/global_search/accentFold';
import GlobalSearch from 'stores/global_search/GlobalSearch';

class GlobalSearchStore extends DomainStore {
  mostRecentQueryAt = 0;

  fetchSuggestions(query, onSuggestionsReceived) {
    const _query = accentFold(query.toLowerCase().replace(/ +/g, ' ').trim());

    return this.fetchSuggestionsFromServer(_query, onSuggestionsReceived);
  }

  async fetchSuggestionsFromServer(query, onSuggestionsReceived) {
    const searchResults = new GlobalSearch(await fetchModel(
      `${endpoints.GLOBAL_SEARCH}?query=${query}&queried_at=${new Date().getTime()}`,
      types.GLOBAL_SEARCH
    ));

    if (searchResults.queriedAt < this.mostRecentQueryAt) {
      return;
    }

    this.mostRecentQueryAt = searchResults.queriedAt;

    const navigationItems = _.filter(
      getNavigationItems(),
      i => i.searchableCaption.indexOf(query.replace(/ /g, '')) > -1
    );

    const results = _.chain([...navigationItems, ...searchResults.results])
      .orderBy(s => !_.startsWith(s.searchableCaption, query))
      .orderBy(s => s.weight * -1)
      .take(10)
      .value();

    onSuggestionsReceived(results);
  }
}

const singleton = new GlobalSearchStore();

export default singleton;
