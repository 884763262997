import {DomainObject} from 'shared/store';
import {action, computed, observable} from 'mobx';
import {Employee} from 'stores/employees';
import SurveyAnswer from './SurveyAnswer';
import _ from 'lodash';
import {generateAnonymousEmployee} from 'shared/tools';

class SurveyResponse extends DomainObject {
  @observable employee = new Employee(generateAnonymousEmployee());
  @observable answers;

  @action merge(other) {
    super.merge(other, {
      employee: Employee,
      answers: [SurveyAnswer]
    });
  }

  @computed get readOnly() {
    return !this.hasLink('submit');
  }

  @computed get sortedAnswers() {
    return _.sortBy(this.answers, 'question.order');
  }

  @action regenerateAnswersForQuestions(questions) {
    let newAnswers = [];
    let currentAnswers = _.keyBy(this.answers, 'question.id');

    _.forEach(questions, q => {
      let answer = new SurveyAnswer({question: q});
      if (currentAnswers[q.id] !== undefined) {
        answer.content = currentAnswers[q.id].content;
      }
      newAnswers.push(answer);
    });

    this.answers = newAnswers;
  }
}

export default SurveyResponse;
