import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';
import {CheckboxWithLabel} from 'components';

const SendBulkDisqualifiedEmailModal = observer(({uiState, modalOpen}) => {
  const {selectedCandidatesCount} = uiState;
  return (
    <AlertModal
      isOpen={modalOpen}
      onHide={() => uiState.closeSendBulkDisqualifiedEmailModal()}
      size='sm'
    >
      <form>
        <div className='h3 pb2'>
          <FormattedMessage id={`recruiting.candidate.Disqualify Candidate${selectedCandidatesCount === 1 ? '' : 's'}?`}/>
        </div>
        <div className='h2 pb2'>
          <FormattedMessage id='recruiting.candidate.DISQUALIFY_CONFIRMATION' values={{count: selectedCandidatesCount}}/>
        </div>
        <CheckboxWithLabel
          label={selectedCandidatesCount === 1 ? 'recruiting.candidate.Send disqualification email to candidate?' : 'recruiting.candidate.Send disqualification email to these candidates?'}
          checked={uiState.sendBulkDisqualifiedEmail}
          onChange={e => uiState.sendBulkDisqualifiedEmail = e.checked}
        />
        <ModalDangerButtons
          onSave={() => uiState.bulkDisqualify()}
          saveCaption='recruiting.candidate.Disqualify'
          onCancel={() => uiState.closeSendBulkDisqualifiedEmailModal()}
        />
      </form>
    </AlertModal>
  );
});

export default SendBulkDisqualifiedEmailModal;
