import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {CustomField} from 'components';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const EditCustomFieldModal = observer(({uiState}) => {
  const {editingCustomField, editCustomFieldModalOpen, errors} = uiState;
  if (!_.get(editingCustomField, 'type')) return null;
  const customFieldError = _.get(errors, `employeeCustomFields.${editingCustomField.customFieldId}`);

  return (
    <Modal isOpen={editCustomFieldModalOpen} onHide={() => uiState.resetModals()} size='md'>
      <div className='h2 mb3'>
        <FormattedMessage id='employees.profile.personal.Edit Custom Field'/>
      </div>
      <form>
        <div className='my3'>
          <CustomField customField={editingCustomField} allowClear={false} errorMessage={customFieldError ? customFieldError.value : null}/>
        </div>
        <ModalButtons onSave={() => uiState.saveCustomField()} onCancel={() => uiState.resetModals()} />
      </form>
    </Modal>
  );
});

export default EditCustomFieldModal;
