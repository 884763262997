import {DomainObject} from 'shared/store';
import {action, observable} from 'mobx';
import User from '../users/User';
import uuid from 'uuid';

class PerformanceReviewNote extends DomainObject {
  @observable text;
  @observable lexicalState;
  @observable visibleToReviewee = false;
  @observable createdByUser;

  @action merge(other) {
    this.id = this.id ? this.id : uuid.v4();
    super.merge(other, {
      createdByUser: User
    });
  }
}

export default PerformanceReviewNote;
