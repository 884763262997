import areIntlLocalesSupported from 'intl-locales-supported';
import {addLocaleData} from 'react-intl';
import getMessages from 'messages';
import _ from 'lodash';

const supportedLocales = [
  'en', 'fr'
];

class Internationalization {
  __messages;
  locale;

  init(locale = 'en') {
    this.locale = locale;

    if (global.Intl) {
      if (!areIntlLocalesSupported(supportedLocales)) {
        const IntlPolyfill = require('intl');
        Intl.NumberFormat = IntlPolyfill.NumberFormat;
        Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
      }
    } else {
      global.Intl = require('intl');
    }

    if (this.locale === 'test') {
      // This local was copied from react-intl
      // eslint-disable-next-line eqeqeq
      addLocaleData({"locale":"test","pluralRuleFunction":function (n,ord){var s=String(n).split("."),v0=!s[1],t0=Number(s[0])==n,n10=t0&&s[0].slice(-1),n100=t0&&s[0].slice(-2);if(ord)return n10==1&&n100!=11?"one":n10==2&&n100!=12?"two":n10==3&&n100!=13?"few":"other";return n==1&&v0?"one":"other";},"fields":{"year":{"displayName":"Year","relative":{"0":"this year","1":"next year","-1":"last year"},"relativeTime":{"future":{"one":"in {0} year","other":"in {0} years"},"past":{"one":"{0} year ago","other":"{0} years ago"}}},"month":{"displayName":"Month","relative":{"0":"this month","1":"next month","-1":"last month"},"relativeTime":{"future":{"one":"in {0} month","other":"in {0} months"},"past":{"one":"{0} month ago","other":"{0} months ago"}}},"day":{"displayName":"Day","relative":{"0":"today","1":"tomorrow","-1":"yesterday"},"relativeTime":{"future":{"one":"in {0} day","other":"in {0} days"},"past":{"one":"{0} day ago","other":"{0} days ago"}}},"hour":{"displayName":"Hour","relativeTime":{"future":{"one":"in {0} hour","other":"in {0} hours"},"past":{"one":"{0} hour ago","other":"{0} hours ago"}}},"minute":{"displayName":"Minute","relativeTime":{"future":{"one":"in {0} minute","other":"in {0} minutes"},"past":{"one":"{0} minute ago","other":"{0} minutes ago"}}},"second":{"displayName":"Second","relative":{"0":"now"},"relativeTime":{"future":{"one":"in {0} second","other":"in {0} seconds"},"past":{"one":"{0} second ago","other":"{0} seconds ago"}}}}});
    } else if (this.locale !== 'en') {
      // 'en' locale is loaded by default, no need to load it again
      addLocaleData(require(`react-intl/locale-data/${this.locale}`));
    }
  }

  get messages() {
    if (!this.__messages) {
      this.__messages = getMessages(this.locale);
      if (window.PHRASEAPP_CONFIG) {
        this.__messages = _.transform(this.__messages, (result, _value, key) => {
          result[key] = `{{__phrase_${key}__}}`;
        });
      }

    }

    return this.__messages;
  }
}

const singleton = new Internationalization();

export default singleton;
