import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {types} from 'shared/core';
import {CompanyInsuranceCarrier} from 'stores/benefits';

class PlanDirectoryState {
  store = new DomainStore();
  match;
  endpoint;

  @observable companyInsuranceCarriers;

  receiveProps({match, endpoint}) {
    this.match = match;
    this.endpoint = endpoint;
  }

  @action async load() {
    await this.store._compose([this.endpoint]);

    this.companyInsuranceCarriers = this.store._getAll(
      types.COMPANY_INSURANCE_CARRIER, CompanyInsuranceCarrier
    );
  }
}

export default PlanDirectoryState;
