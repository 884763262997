import React from 'react';
import {observer} from 'mobx-react';
import {
  AddEmployees,
  Details,
  Name,
  Review,
  StartDate,
  // TimeOffRules,
  TimeOffTypes,
  WorkWeek
} from './components';

const Step = observer(({step, uiState}) => {
  switch(step) {
    case 'details':
      return <Details uiState={uiState} />;
    case 'name':
      return <Name uiState={uiState} />;
    case 'policy_types':
      return <TimeOffTypes uiState={uiState} />;
    case 'work_week':
      return <WorkWeek uiState={uiState} />;
    case 'policy_start_date':
      return <StartDate uiState={uiState} />;
    case 'add_employees':
      return <AddEmployees uiState={uiState} />;
    case 'review':
      return <Review uiState={uiState} />;
    default:
      throw new Error(`Location ${step} is not supported.`);
  }
});

export default Step;

