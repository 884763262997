import {endpoints, types} from '../core';
import {fetchModel} from '../store';
import {Draft} from 'stores/drafts';
import {computed, observable} from 'mobx';
import setupAutosave from './autosave';
import _ from 'lodash';

class DraftAutosaver {
  @observable draft;
  @observable model;

  constructor(model) {
    this.model = model;
  }

  async setup(draftParams) {
    const draftModel = await fetchModel(endpoints.DRAFTS.with(draftParams), types.DRAFT);
    this.draft = new Draft(draftModel);
    this.draft.previouslyAutosavedAt = this.draft.autosavedAt;
    this.model.merge(_.omit(this.draft.draftObject, (draftParams.omittedAttributes || []).concat('links')));
    this.model.draftableId = draftParams.id;
    this.model.draftableType = draftParams.type;

    this.autosaver = setupAutosave(this.draft);
  }

  async autosave() {
    this.draft.draftObject = _.omit(this.model, 'links');
    this.autosaver.autosave();
  }

  async autosaveImmediately() {
    this.draft.draftObject = _.omit(this.model, 'links');
    this.autosaver.autosaveImmediately();
  }

  clearAutosaver() {
    delete this.model.draftableId;
    delete this.model.draftableType;
    delete this.model.autosaver;
  }

  @computed get status() {
    return this.autosaver.status;
  }
}

export default async function setupAutosaveDraft(model, draftParams) {
  if (model.autosaver) {
    throw new Error('Model already has an autosaver');
  }

  const draftAutosaver = new DraftAutosaver(model);
  await draftAutosaver.setup(draftParams);

  Object.defineProperty(
    model,
    'autosaver',
    {
      configurable: true,
      get: () => draftAutosaver
    }
  );
}
