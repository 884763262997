import React from 'react';
import {FormattedMessage} from 'react-intl';
import {QuickTip} from 'components';
import {auth} from 'shared/core';

const DemoAccountWarning = () => {
  if (!auth.company.demoAccount) return null;

  return (
    <div className='mb2'>
      <QuickTip
        trait='caution'
        header={<FormattedMessage id='components.demo_account_warning.Creating Demo Data'/>}
        paragraphs={[
          <FormattedMessage id="components.demo_account_warning.Anything you create now will be deleted when you clear demo data."/>
        ]}
      />
    </div>
  );
};

export default DemoAccountWarning;
