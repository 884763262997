import React from 'react';
import {observer} from 'mobx-react';

const LoginContainerWrapper = observer(({children}) => {
  return (
    <div className='SignInContainerOuterWrapper'>
      <div className='SignInContainerWrapper'>
        <div className='SignInContainer'>
          {children}
        </div>
      </div>
    </div>
  );
});

export default LoginContainerWrapper;
