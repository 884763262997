import React from 'react';
import {observer} from 'mobx-react';
import {Offer, ActionLink, Spinner} from 'components';
import {FormattedMessage} from 'react-intl';
import {FullscreenModal} from 'components/modals';
import CloseIcon from 'img/close-icon-white@2x.png';
import _ from 'lodash';

const PreviewBanner = observer(({offer, onHide}) => {
  let bannerColour = 'bg-meadow';
  let bannerText = 'components.offers.Previewing Candidate View';

  if (offer.isExpired) {
    bannerColour = 'bg-scarlet';
    bannerText = 'components.offers.Viewing Expired Offer';
  }
  if (offer.isAccepted) {
    bannerColour = 'bg-grass';
    bannerText = 'components.offers.Viewing Accepted Offer';
  }
  if (offer.isRevoked) {
    bannerColour = 'bg-scarlet';
    bannerText = 'components.offers.Viewing Revoked Offer';
  }

  return (
    <div className={`${bannerColour} white flex`} style={{padding: '0.8rem'}}>
      <div className='clearfix table'>
        <div className='col-4 table-cell align-middle'/>
        <div className='col-4 table-cell align-middle center'>
          <FormattedMessage id={bannerText}/>
        </div>
        <div className='col-4 table-cell align-middle right-align'>
          <ActionLink className='Link-icon align-middle' onClick={() => onHide()}>
            <img src={CloseIcon} className='img-hover' alt='close' width='14px'/>
          </ActionLink>
        </div>
      </div>
    </div>
  );
});

const OfferPreviewModal = observer(({offer, company, isOpen, onHide}) => {
  return (
    <FullscreenModal isOpen={isOpen}>
      {offer ?
        <React.Fragment>
          <PreviewBanner offer={offer} onHide={onHide}/>
          <Offer isPreview jobOffer={offer} logoUrl={company.logoUrl}/>
        </React.Fragment> :
        <Spinner/>}
    </FullscreenModal>
  );
});

export default OfferPreviewModal;
