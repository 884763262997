import React from 'react';
import {observer} from 'mobx-react';
import {Select2} from 'components';
import StyleGuideTitle from './StyleGuideTitle';
import StyleGuideExample from './StyleGuideExample';
import StyleGuideParameterRows from './StyleGuideParameterRows';

const PARAMS = [
  { name: 'allowClear', type: 'boolean', description: 'Allow selected value to be cleared', values: '[true, false]' },
  { name: 'children*', type: '<option>', description: 'List of options that you can pick from', values: '<option key="Daniel Hwang" value="0"/>' },
  { name: 'className', type: 'string', description: 'Class names to position the component', values: 'Ex: absolute' },
  { name: 'disabled', type: 'boolean', description: 'Enable or disable the component', values: '[true, false]' },
  { name: 'dropdownClass', type: 'string', description: 'Class names to style the list of items', values: 'Ex: select2-animation' },
  { name: 'errorMessage', type: 'string', description: 'Message specifying the error', values: 'Ex: errors.path' },
  { name: 'label', type: 'string', description: 'Translation of label for select component', values: 'Ex: translation.path' },
  { name: 'multiple', type: 'boolean', description: 'Enables selection of multiple options', values: '[true, false]' },
  { name: 'onChange', type: 'function', description: 'Callback to handle selection event', values: 'Ex: uiState.changeEmployee()' },
  { name: 'placeholder', type: 'string', description: 'Placeholder to be displayed', values: 'Ex: Employee Name' },
  { name: 'translatePlaceholder', type: 'boolean', description: 'Specify if placeholder should be translated', values: '[true, false]' },
  { name: 'searchable', type: 'boolean', description: 'Turn on ability to search values in select2', values: '[true, false]' },
  { name: 'value*', type: 'string/integer', description: 'Value that is currently selected', values: 'Ex: domainObject.value' }
];

const StyleGuideSelect2 = observer(() => {
  return (
    <div>
      <StyleGuideTitle title='Select2' description='Select2 is a library we use as our primary dropdown. It has many different functionalities but this section is only reserved for simple select inputs.'/>

      <div className='bg-white box-shadow-1 rounded p4 my3'>
        <StyleGuideExample title='Select2 (with label)' description='This component is used to select a single value from a list of values.'>
          <div className='col-6 sm-col-4'>
            <Select2 label='employee' value='0' searchable={true} dropdownClass='select2-animation' allowClear={true}>
              <option key='Daniel Hwang' value='0'>{'Daniel Hwang'}</option>
              <option key='Martin Sienawski' value='1'>{'Martin Sienawski'}</option>
              <option key='Christopher Ciufo' value='2'>{'Christopher Ciufo'}</option>
              <option key='Bob Reid' value='3'>{'Bob Reid'}</option>
              <option key='Greg Vorobyev' value='4'>{'Greg Vorobyev'}</option>
              <option key='Steve Hanna' value='5'>{'Steve Hanna'}</option>
            </Select2>
          </div>
        </StyleGuideExample>

        <StyleGuideExample title='Select2 Disabled' description='Disable any interaction with the Select2 dropdown.'>
          <div className='col-6 sm-col-4'>
            <Select2 label='employee' value='0' disabled={true}>
              <option key='Daniel Hwang' value='0'>{'Daniel Hwang'}</option>
            </Select2>
          </div>
        </StyleGuideExample>

        <StyleGuideExample title='Select2 Errors' description='Displaying errors on your Select2 components.'>
          <div className='col-6 sm-col-4'>
            <Select2 label='employee' value='0' errorMessage='This field is mandatory'>
              <option key='Daniel Hwang' value='0'>{'Daniel Hwang'}</option>
            </Select2>
          </div>
        </StyleGuideExample>

        <StyleGuideExample title='Select2 Help Message' description='Display help mesages to clarify what to do with Select2.'>
          <div className='col-6 sm-col-4'>
            <Select2 label='employee' value='0' helpMessage='Choose employee to view'>
              <option key='Daniel Hwang' value='0'>{'Daniel Hwang'}</option>
            </Select2>
          </div>
        </StyleGuideExample>

        <div className='mb3'>
          <div className='h2 medium mb2'>{'Parameters'}</div>
          <div className='clearfix mb1'>
            <div className='col col-3 pr1 h5 caps'>{'Name'}</div>
            <div className='col col-2 pr1 h5 caps'>{'Type'}</div>
            <div className='col col-4 pr1 h5 caps'>{'Description'}</div>
            <div className='col col-3 pl1 h5 caps'>{'Values'}</div>
          </div>
          <ul className='StyleGuide-params list-reset'>
            {PARAMS.map((p, i) => <StyleGuideParameterRows key={i} index={i} {...p}/>)}
          </ul>
        </div>
      </div>
    </div>
  );
});

export default StyleGuideSelect2;
