import React from 'react';
import {Modal, ModalButtons} from 'components/modals';
import {FormLayout, Input} from 'components';
import {observer} from 'mobx-react';
import {UploadFiles} from 'components';
import {observable} from 'mobx';

const EditCompanyFileModal = observer(({uiState}) => {
  const {editCompanyFileModalOpen, selectedCompanyFile, errors} = uiState;
  const header = selectedCompanyFile.isNew ? 'company_settings.company_files.Add a Company File' : 'company_settings.company_files.Edit Company File';

  return (
    <Modal isOpen={editCompanyFileModalOpen}
           onHide={() => uiState.closeEditCompanyFileModal()}
           size='md'
           title={header}>
      <FormLayout>
        <UploadFiles
          defaultFiles={selectedCompanyFile.file ? observable([selectedCompanyFile.file]) : observable([])}
          onChange={(files) => uiState.updateFile(files) }
          maxFiles={1}
          errorMessage={errors.file}
          modelType='company_files'
        />
        <Input label='company_settings.company_files.Displayed Name (optional):'
               value={selectedCompanyFile.name}
               onChange={e => selectedCompanyFile.name = e.target.value}
               placeholder='company_settings.company_files.e.g. Company Handbook'
               errorMessage={errors.name}
        />
      </FormLayout>
      <ModalButtons
        onSave={() => uiState.saveCompanyFile()}
        saveCaption='company_settings.company_files.Save'
        onCancel={() => uiState.closeEditCompanyFileModal()}
        cancelCaption='company_settings.company_files.Cancel'
      />
    </Modal>
  );
});

export default EditCompanyFileModal;
