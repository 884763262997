import React from 'react';
import {observer} from 'mobx-react';
import BrokerSignupContainer from './BrokerSignupContainer';
import {withState, t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {Input, Button, Panel, Layout, QuickTip, FormLayout, FormError} from 'components';
import BrokerSignupFormState from './BrokerSignupFormState';
import CollageLogo from 'img/collage-logo--light@2x.png';

const EmailAlreadyTaken = observer(({uiState}) => {
  const {form, errors} = uiState;

  if (!errors.email) return null;

  return (
    <div className='mb3'>
      <QuickTip
        trait='error'
        header={t('broker_portal.signup.broker.This email has already been taken.')}
        paragraphs={[
          <FormattedMessage id='broker_portal.signup.broker.IF_THIS_IS_YOUR_EMAIL' values={{
            email: <b>{form.email}</b>,
            signIn: <a href={`/user_session/new?return_to_url=${uiState.returnToUrl}`}>{t('broker_portal.signup.broker.Sign In')}</a>
          }}/>
        ]}
      />
    </div>
  );
});

const BrokerSignupForm = observer(({uiState}) => {
  const {form, errors} = uiState;

  return (
    <BrokerSignupContainer>
      <Layout centered>
        <Layout.Section secondary>
          <Panel>
            <div className='h2 medium center mb2'>
              <FormattedMessage id='broker_portal.signup.broker.get_started'/>
            </div>
            <div className='jumbo center'>
              <FormattedMessage
                id='broker_portal.signup.broker.MANAGE_BROKERAGE'
                values={{
                  brokerage: form.broker.name
                }}
              />
            </div>
            <form>
              <div className='clearfix mt3'>
                <EmailAlreadyTaken uiState={uiState}/>
                <FormLayout>
                  <FormLayout.Group>
                    <Input
                      value={form.firstName}
                      placeholder='broker_portal.signup.company.First Name'
                      onChange={e => form.merge({firstName: e.target.value})}
                      errorMessage={errors.firstName}
                    />
                    <Input
                      value={form.lastName}
                      placeholder='broker_portal.signup.company.Last Name'
                      onChange={e => form.merge({lastName: e.target.value})}
                      errorMessage={errors.lastName}
                    />
                  </FormLayout.Group>
                  <Input
                    value={form.email}
                    placeholder='broker_portal.signup.company.Work Email'
                    onChange={e => form.merge({email: e.target.value})}
                    errorMessage={errors.email}
                    hasError={!!errors.email}
                  />
                  <Input
                    value={form.emailConfirmation}
                    placeholder='broker_portal.signup.company.Confirm Email'
                    onChange={e => form.merge({emailConfirmation: e.target.value})}
                    errorMessage={errors.emailConfirmation}
                  />
                  <Input type='password'
                    value={form.password}
                    placeholder='broker_portal.signup.company.Password'
                    onChange={e => form.merge({password: e.target.value})}
                    errorMessage={errors.password}
                  />
                  <FormError message={errors.base} />
                </FormLayout>
                <Button className='full-width' onClick={() => uiState.signUp()}>
                  <FormattedMessage id='broker_portal.signup.company.Sign Up'/>
                </Button>
                <div className='h5 waterloo center mt3'>
                  <FormattedMessage id='signup.SERVICE_AGREEMENT_AND_PRIVACY_POLICY' values={{
                    terms: <a href='https://collage.co/service-agreement' target='_blank' className='TextLink dodger'><FormattedMessage id='signup.Terms'/></a>,
                    privacyPolicy: <a href='https://collage.co/privacy-policy' target='_blank' className='TextLink dodger'><FormattedMessage id='signup.Privacy Policy'/></a>
                  }}/>
                </div>
              </div>
            </form>
          </Panel>
          <div className='center mt3'>
            <img src={CollageLogo} className='ml1' width='67' alt='Collage'/>
          </div>
        </Layout.Section>
      </Layout>
    </BrokerSignupContainer>
  );
});

export default withState(BrokerSignupForm, BrokerSignupFormState);
