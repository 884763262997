import React from 'react';
import {observer} from 'mobx-react';
import {ActionLink, Label, EmployeeCustomFields} from 'components';
import EditCustomFieldModal from './EditCustomFieldModal';
import {calendarDate} from 'shared/tools';
import _ from 'lodash';
import moment from 'moment';

const CustomFieldRow = observer(({customField, uiState}) => {
  const {name, description, employeeCanEdit} = customField;
  const {userIsEmployee, userIsAdmin} = uiState;
  const render = customField.type === 'date' ? (value) => calendarDate(new Date(moment(value))) : null;
  const canEdit = userIsAdmin || (employeeCanEdit && userIsEmployee) || (uiState.editDataPermission('::PERSONAL_INFORMATION') && !userIsEmployee);

  return (
    <div className={`CustomFieldRow clearfix mxn1`}>
      <div className='col col-5 px1'>
        <div>{name}</div>
        {description && <div className='pt1 whitespace-pre-wrap waterloo'>{description}</div>}
      </div>
      <div className='col col-6 px1'>
        <Label model={customField} path='value' render={render}/>
      </div>
      <div className='col col-1 px1 right-align'>
        {canEdit && <ActionLink className='submarine' onClick={() => uiState.openCustomFieldModal(customField)}>
          <i className='material-icons h2'>{'mode_edit'}</i>
        </ActionLink>}
      </div>
    </div>
  );
});

const EmployeeInfoCustomFields = observer(({uiState}) => {
  const {sortedCustomFields, employeeCustomFields} = uiState.employee;
  if (_.isEmpty(sortedCustomFields)) return null;

  return (
    <div className='pt1'>
      <EmployeeCustomFields employeeCustomFields={employeeCustomFields} category='personal'>
        {employeeCustomField => (
          <CustomFieldRow
            uiState={uiState}
            customField={employeeCustomField}
            key={employeeCustomField.id}/>)
        }
      </EmployeeCustomFields>
      <EditCustomFieldModal uiState={uiState} />
    </div>
  );
});

export default EmployeeInfoCustomFields;
