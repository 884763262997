import React from 'react';

const InputError = ({message, style, className}) => {
  if (!message) return null;

  return (
    <div style={style} className={`Error-message ${className}`}>{message}</div>
  );
};

InputError.defaultProps = {
  className: ''
};

export default InputError;
