import React from 'react';
import {observer} from 'mobx-react';
import {ReportDownloadState} from '../state';
import {withState} from 'shared/core';
import DownloadModal from './DownloadModal';
import {DropdownSelect} from 'components';

const DownloadForm = observer(({uiState, name}) => {
  const DOWNLOAD_OPTIONS = [
    {
      name: 'CSV',
      fileType: 'csv'
    },
    {
      name: 'Excel',
      fileType: 'xlsx'
    }
  ];

  return (
    <div>
      {DOWNLOAD_OPTIONS.map(option => {
        return (
          <DropdownSelect.Option
            key={option.fileType}
            onClick={() => uiState.requestDownload(option.fileType)}
            text={`${option.name} (.${option.fileType})`}
          />
        );
      })}
      <DownloadModal uiState={uiState} name={name}/>
    </div>
  );
});

export default withState(DownloadForm, ReportDownloadState);
