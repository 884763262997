import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Button} from 'components';
import {AlertModal} from 'components/modals';

const InterestedInPayrollModal = observer(({uiState}) => {
  const {employee} = uiState;

  return (
    <AlertModal
      size='sm'
      header='employees.hire.payroll.Great!'
      isOpen={uiState.interestedInPayrollModalOpen}
      onHide={() => uiState.closeInterestedInPayrollModal()}
    >
      <div className='mb3 space-y jumbo'>
        <div><FormattedMessage id='employees.hire.payroll.Our payroll team has been notified and will contact you shortly.'/></div>
        <div><FormattedMessage id='employees.hire.payroll.INTEREST_MODAL_MESSAGE' values={{employeeName: employee.name}}/></div>
      </div>
      <Button className='right' onClick={() => uiState.closeInterestedInPayrollModal()}>
        <FormattedMessage id='employees.hire.payroll.Got it!'/>
      </Button>
    </AlertModal>
  );
});

export default InterestedInPayrollModal;
