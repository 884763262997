import React from 'react';
import _ from 'lodash';
import {Modal, ModalButtons} from 'components/modals';
import {Input, CheckboxWithLabel, LabelHelper, FormError} from 'components';
import {observer} from 'mobx-react';
import {t} from 'shared/core';

const AssignRoles = observer(({uiState}) => {
  const {userViewModel, errors} = uiState;

  return (
    <div className='clearfix pt1 table'>
      <LabelHelper label={t('company_settings.external_users.Assign this user the following roles')}/>
      <div className='mt1'>
        {userViewModel.roles.map(viewModel =>
          <div key={viewModel.role.id}>
            <CheckboxWithLabel
              onChange={() => viewModel.toggle()}
              value={viewModel.role.id}
              checked={viewModel.checked}
              label={viewModel.role.name || 'company_settings.permissions.Untitled Role'}
              translatable={!viewModel.role.name}/>
          </div>
        )}
        <FormError message={errors.assignedRoles}/>
      </div>
    </div>
  );
});

const SendAdminInviteForm = observer(({uiState}) => {
  const {userViewModel, errors} = uiState;
  const {user} = userViewModel;

  return (
    <div>
      <div className='clearfix pb1 table'>
        <Input label='company_settings.external_users.First Name'
               value={user.firstName}
               errorMessage={errors.firstName}
               onChange={e => user.merge({firstName: e.target.value})}/>
      </div>
      <div className='clearfix py1 table'>
          <Input label='company_settings.external_users.Last Name'
                 type='text'
                 value={user.lastName}
                 errorMessage={errors.lastName}
                 onChange={e => user.merge({lastName: e.target.value})}/>
      </div>
      <div className='clearfix py1 table'>
          <Input label='company_settings.external_users.Email Address'
                 value={user.email}
                 errorMessage={errors.email}
                 onChange={e => user.merge({email: e.target.value})}/>
      </div>
      <AssignRoles uiState={uiState}/>
    </div>
  );
});

const AddExternalUserModal = observer(({uiState}) => {
  return (
    <Modal isOpen={uiState.addModalOpen} onHide={() => uiState.cancelAddModal()} size='md' title='company_settings.external_users.Add External User'>
      <SendAdminInviteForm uiState={uiState}/>
      <ModalButtons onCancel={() => uiState.cancelAddModal()}
        onSave={() => uiState.saveExternalUser()}/>
    </Modal>
  );
});

export default AddExternalUserModal;
