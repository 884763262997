import {t} from 'shared/core';

const generateTitle = (labels, filterKey, fallbackTitle) => {
  const baseTitle = t(`components.tables.fancy_filters.keys.${filterKey}`);

  if (labels.length === 0) return baseTitle;
  if (labels.length === 1) return `${labels[0]}`;
  if (fallbackTitle) return fallbackTitle;

  return t('components.tables.fancy_filters.FILTER_TITLE', { option: labels[0], num: labels.length - 1 });
};

export default generateTitle;
