import React from 'react';
import {DocumentTypeSelector, DocumentUpload} from 'components';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';

const DocumentUploadForm = observer(({uiState}) => {
  const {editingCompanyDocument, errors, acceptedMimeTypes} = uiState;

  return <DocumentUpload
    document={editingCompanyDocument}
    errors={errors}
    accept={acceptedMimeTypes}
    onSave={() => uiState.saveUploadedFile()}
    onCancel={() => uiState.closeAddDocumentModal()}
  />;
});

const AddOnboardingDocumentModal = observer(({uiState}) => {
  const {choosingDocumentType} = uiState;

  return (
    <Modal isOpen={uiState.addDocumentModalOpen} onHide={() => uiState.closeAddDocumentModal()} size='md' closeButton={false}>
      {choosingDocumentType ? <DocumentTypeSelector onTypeSelected={(type) => uiState.selectDocumentType(type)}/> : <DocumentUploadForm uiState={uiState}/>}
    </Modal>
  );
});

export default AddOnboardingDocumentModal;
