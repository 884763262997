import React from 'react';
import {observer} from 'mobx-react';
import useModal from 'shared/hooks/useModal';
import {Layout, InteractiveTable, AvatarList, DropdownFilter} from 'components';
import {endpoints, types, withState, t} from 'shared/core';
import {dateSpan} from 'shared/tools';
import {BlockedDatesState} from '../state';
import {TimeOffBlockedDates} from 'stores/time_off';
import EditBlockedDatesModal from './EditBlockedDatesModal';

const COLUMNS = [
  {
    header: 'time_off.blocked_dates.Name',
    width: 3,
    attribute: 'name'
  },
  {
    header: 'time_off.blocked_dates.Date range',
    width: 2,
    sortBy: 'startDate',
    render: model => dateSpan(model.startDate, model.endDate, true)
  },
  {
    header: 'time_off.blocked_dates.Types',
    width: 4,
    render: model => model.typeNames
  },
  {
    header: 'time_off.blocked_dates.Employees',
    width: 2,
    render: model => <AvatarList employees={model.employees} moreModalEnabled/>
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'time_off.blocked_dates.Delete Blocked Dates',
    subHeader: 'time_off.blocked_dates.Are you sure you want to delete these blocked dates?',
    body: 'time_off.blocked_dates.Deleting these blocked dates will allow employees to make time off requests during this time.'
  },
  interactive: {
    addModel: 'time_off.blocked_dates.Add Blocked Dates'
  }
};

function statusFilters() {
  return [
    {
      id: 'past',
      display: t('time_off.blocked_dates.Past')
    },
    {
      id: 'upcoming',
      display: t('time_off.blocked_dates.Upcoming')
    }
  ];
}

const BlockedDatesContainerWithState = observer(({uiState}) => {
  const editBlockedDatesModalOpen = useModal(uiState, 'EditBlockedDatesModal', 'editingBlockedDates', TimeOffBlockedDates, 'errors');

  return (
    <Layout>
      <Layout.Section>
        <InteractiveTable
          uiState={uiState}
          title={t('time_off.blocked_dates.Blocked Dates')}
          onMount={agent => uiState.setInteractiveAgent(agent)}
          columns={COLUMNS}
          searchable
          localization={LOCALIZATION}
          onAdd={() => uiState.openEditBlockedDatesModal()}
          onEdit={(blockedDates) => uiState.openEditBlockedDatesModal(blockedDates)}
          onRemove={blockedDates => uiState.removeBlockedDates(blockedDates)}
          proxy={{
            type: 'async',
            modelType: types.TIME_OFF.BLOCKED_DATES,
            model: TimeOffBlockedDates,
            endpoint: endpoints.TIME_OFF.BLOCKED_DATES.ALL
          }}
        >
          <DropdownFilter options={statusFilters()} attr='status' defaultFilter={1}/>
          </InteractiveTable>
        <EditBlockedDatesModal uiState={uiState} modalOpen={editBlockedDatesModalOpen} />
      </Layout.Section>
    </Layout>
  );
});

export default withState(BlockedDatesContainerWithState, BlockedDatesState);
