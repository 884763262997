import React from 'react';
import {observer} from 'mobx-react';
import ActionLink from './links/ActionLink';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import PanelTooltip from './PanelTooltip';

const LabelAction = observer(({action}) => {
  if (!action) return null;

  if (action.url) {
    return (
      <a target='_blank' className='right' href={action.url}>{action.title}</a>
    );
  }

  return (
    <ActionLink className='right' onClick={action.onClick}>{action.title}</ActionLink>
  );
});

const LabelHelper = observer(({label, translateLabel, className, action, children, optional, tooltip}) => {
  if (!label) return null;

  return (
    <label className={`block ${className}`} style={{color: '#212B36'}}>
      <span className={children ? 'mr1' : ''}>{translateLabel ? t(label) : label}</span>{children}
      {optional && <span className="waterloo regular pl1">
        <FormattedMessage id="recruiting.candidate.(Optional)"/>
        </span>}
      {tooltip && <PanelTooltip>
        {tooltip.paragraphs.map((p, index) => (
          <div className='pb1' key={index}>{p}</div>
        ))}
      </PanelTooltip>}
      <LabelAction action={action}/>
    </label>
  );
});

LabelHelper.defaultProps = {
  className: '',
  translateLabel: false,
  optional: false,
};

/*
props:

action: object {title, url, onClick}. if url is provided, an <a> will be rendered.
otherwise, an ActionLink with the onClick handler will be used.
*/


export default LabelHelper;
