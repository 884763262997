import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Spinner, Table} from 'components';
import {calendarDate} from 'shared/tools';
import {t} from 'shared/core';

const PredictedAccountsSummary = observer(({uiState}) => {
  if (!uiState.newPolicy) return null;
  if (uiState.isLoading) return <Spinner/>;

  return (
    <div>
      <Panel.Subheader title={t('employees.profile.time_off.BALANCES_AS_OF_TODAY', {
        employeeName: uiState.employee.name, date: calendarDate(new Date())})}
      />
      <Table
        models={uiState.predictedAccountRows}
        columns={uiState.predictedAccountColumns}
        showLinks={false}
      />
    </div>
  );
});

export default PredictedAccountsSummary;
