import React from 'react';
import {observer} from 'mobx-react';
import {Panel, DragAndDrop, EditQuestionModal, FormError} from 'components';
import {t} from 'shared/core';
import DragAndDropContext from 'components/dnd/DragAndDropContext';

const QuestionOptions = observer(({question}) => {
  if (question.questionType !== 'quiz') return null;

  return (
    <React.Fragment>
      <div className='medium mt1'>{t('recruiting.edit.steps.Possible answers')}</div>
      {question.options.map(o => <div>{o.value}</div>)}
      <div className='medium mt1'>{t('components.question_form.Correct answer')}</div>
      {question.quizCorrectOption.value}
    </React.Fragment>
  );
});

const QuestionContent = observer(({model}) => {
  return (
    <div>
      <div>
        {model.text && <span className='mr1'>{model.text}</span>}
        <span className='waterloo'>
          {t(`components.question_form.question_types.${model.questionType}`)}
        </span>
      </div>
      <QuestionOptions question={model}/>
    </div>
  );
});

const LOCALIZATION = {
  addModel: 'performance.+ Add a Question'
};

const Questions = observer(({uiState}) => {
  const {errors, sortedFolders} = uiState;

  return (
    <div>
      <Panel.Header
        title={t('training.edit.Questions')}
        description={t("training.edit.Quiz your employees on the training content you've provided. You will see their results after they've completed their training.")}
      />
      <DragAndDrop
        models={uiState.questions}
        component={QuestionContent}
        readOnlyComponent={QuestionContent}
        onAdd={() => uiState.addQuestion()}
        onEdit={(question) => uiState.editQuestion(question)}
        onRemove={(question) => uiState.removeQuestion(question)}
        localization={LOCALIZATION}
        errors={errors}
        errorPath={(index => `questions[${index}].name`)}
      />
      <FormError message={errors.base}/>
      <EditQuestionModal
        questionTypes={['quiz', 'attachment']}
        isOpen={uiState.editQuestionModalOpen}
        onClose={() => uiState.closeEditQuestionModal()}
        onSave={() => uiState.saveQuestion()}
        question={uiState.editingQuestion}
        showAttachmentOptions
        showDescription
        showRequired={false}
        folders={sortedFolders}
        errorMessage={errors.options}
      />
    </div>
  );
});

export default DragAndDropContext(Questions);
