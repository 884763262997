const MERGE_FIELDS = [
  {
    key: 'first_name',
    label: 'components.lexical.merge_fields.First Name',
    placeholder: 'components.lexical.merge_fields.#[First Name]',
    alwaysInclude: true
  },
  {
    key: 'last_name',
    label: 'components.lexical.merge_fields.Last Name',
    placeholder: 'components.lexical.merge_fields.#[Last Name]',
    alwaysInclude: true
  },
  {
    key: 'email',
    label: 'components.lexical.merge_fields.Email',
    placeholder: 'components.lexical.merge_fields.#[Email]',
    alwaysInclude: true
  },
  {
    key: 'expires_at',
    label: 'components.lexical.merge_fields.Offer Expiry Date',
    placeholder: 'components.lexical.merge_fields.#[Offer Expiry Date]',
    alwaysInclude: true,
    fieldType: 'date'
  },
  {
    key: 'bonus_structure',
    label: 'components.lexical.merge_fields.Bonus Structure',
    placeholder: 'components.lexical.merge_fields.#[Bonus Structure]',
    fieldType: 'text_area'
  },
  {
    key: 'commission_structure',
    label: 'components.lexical.merge_fields.Commission Structure',
    placeholder: 'components.lexical.merge_fields.#[Commission Structure]',
    fieldType: 'text_area'
  },
  {
    key: 'job_title',
    label: 'components.lexical.merge_fields.Job Title',
    placeholder: 'components.lexical.merge_fields.#[Job Title]',
    alwaysInclude: true,
  },
  {
    key: 'salary',
    label: 'components.lexical.merge_fields.Salary (or pay rate)',
    placeholder: 'components.lexical.merge_fields.#[Salary]',
    fieldType: 'salary',
    defaultValue: {
      payRateUnit: 'year',
      payRateCurrency: 'CAD'
    }
  },
  {
    key: 'start_date',
    label: 'components.lexical.merge_fields.Start Date',
    placeholder: 'components.lexical.merge_fields.#[Start Date]',
    fieldType: 'date',
    separator: true
  },
];

export default MERGE_FIELDS;
