import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {FormLayout, ActionLink, Select2, Input, BirthdayPicker, RadioButton, RadioButtonGroup} from 'components';
import DependantStudentFields from './DependantStudentFields';
import {t} from 'shared/core';

const DependantForm = observer(({uiState, dependent, index, errors}) => {
  const {
    maxUnconditionalEligibilityAge,
    maxConditionalEligibilityAge,
    maxQcConditionalEligibilityAge
  } = uiState.company;

  return (
    <div className='pt2'>
      <div className='clearfix table full-width pb1'>
        <div className='col-6 table-cell align-middle pr2 black medium'>
          <FormattedMessage id='DEPENDANT_TITLE' values={{index: index + 1}} />
        </div>
        <div className='col-6 table-cell align-middle pl2 right-align'>
          <ActionLink subdued onClick={() => uiState.removeDependent(dependent)}>
            <FormattedMessage id='Remove'/>
          </ActionLink>
        </div>
      </div>
      <FormLayout>
        <FormLayout.Group>
          <Input
            label='components.benefits.First Name'
            value={dependent.firstName}
            errorMessage={errors.firstName}
            onChange={e => dependent.merge({firstName: e.target.value})} />
          <Input
            label='components.benefits.Last Name'
            value={dependent.lastName}
            errorMessage={errors.lastName}
            onChange={e => dependent.merge({lastName: e.target.value})} />
        </FormLayout.Group>
        <FormLayout.Group>
          <BirthdayPicker
            maxAge={Math.max(maxConditionalEligibilityAge, maxQcConditionalEligibilityAge)}
            value={dependent.birthDate}
            label='components.benefits.Birthdate'
            className='full-width'
            options={{startView: 2}}
            errorMessage={errors.birthDate}
            onChange={date => dependent.merge({birthDate: date})} />
          <Select2 name={`gender[${index}]`}
            label={t("components.benefits.Sex at Birth")}
            translateLabel={false}
            value={dependent.gender || ''}
            onChange={(e) => dependent.merge({gender: e.target.value})}
            errorMessage={errors.gender}>
            <option key='Male' value='male'>{t('Male')}</option>
            <option key='Female' value='female'>{t('Female')}</option>
          </Select2>
        </FormLayout.Group>
        <RadioButtonGroup name={`student[${index}]`}
          translateLabel={false}
          label={
            t(
              'components.benefits.DEPENDENT_BETWEEN_AGES',
              {maxUnconditionalEligibilityAge, maxConditionalEligibilityAge}
            )
          }
          helpLink={{
            explanation: {
              header: t('components.benefits.help.What is a dependant?'),
              paragraphs: [
                t(
                  'components.benefits.help.what_is_a_dependant_explanation_1',
                  {maxUnconditionalEligibilityAge, maxConditionalEligibilityAge}
                ),
                t('components.benefits.help.what_is_a_dependant_explanation_2')
              ]
            }
          }}
          selectedValue={dependent.student}
          errorMessage={errors.student}
          onChange={(name, value) => dependent.merge({student: value})}>
          <RadioButton value={true}><FormattedMessage id='Yes'/></RadioButton>
          <RadioButton value={false} className='pl3'><FormattedMessage id='No'/></RadioButton>
        </RadioButtonGroup>
        <DependantStudentFields dependent={dependent} errors={errors} uiState={uiState}/>
        <RadioButtonGroup name={`disabledAdult[${index}]`}
          label='components.benefits.Is this dependant a disabled adult?'
          helpLink={{
            explanation: {
              header: t('components.benefits.help.What is a disabled adult?'),
              paragraphs: [
                t(
                  'components.benefits.help.what_is_a_disabled_adult_explanation_1',
                  {maxUnconditionalEligibilityAge}
                ),
                t('components.benefits.help.what_is_a_disabled_adult_explanation_2')
              ]
            }
          }}
          selectedValue={dependent.disabledAdult}
          errorMessage={errors.disabledAdult}
          onChange={(name, value) => dependent.merge({disabledAdult: value})}>
          <RadioButton value={true}><FormattedMessage id='Yes'/></RadioButton>
          <RadioButton value={false} className='pl3'><FormattedMessage id='No'/></RadioButton>
        </RadioButtonGroup>
      </FormLayout>
      <hr />
    </div>
  );
});

export default DependantForm;
