import {DomainStore, serializeModel} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {MFAUser} from 'stores/authy';
import {endpoints, types, api, auth} from 'shared/core';
import {redirect} from 'shared/tools';
import _ from 'lodash';
import {RecoveryCode} from 'stores/recovery_codes';

class MFASetupContainerState {
  store = new DomainStore();

  STATES = {
    optionSelection: 0,
    userCreation: 1,
    verification: 2,
    recoveryCode: 3
  };

  @observable mfaUser = new MFAUser();
  @observable errors = {};
  @observable currentState = 0;
  @observable qrCode;
  @observable redirectUrl;
  @observable recoveryCode = new RecoveryCode();

  @action goToOptionSelection() {
    this.currentState = this.STATES.optionSelection;
    this.mfaUser.update({mfaType: null});
  }

  @action async goToSetupForType() {
    if (this.mfaUser.mfaType === 'sms') {
      this.goToUserCreation();
    } else {
      await this.setupAuthenticator();
    }
  }

  @action goToUserCreation() {
    this.currentState = this.STATES.userCreation;
  }

  @action goToVerification() {
    this.currentState = this.STATES.verification;
  }

  @action async setupAuthenticator() {
    const {model, errors} = await this.store.post(
      endpoints.MFA.SETUP_AUTHENTICATOR,
      types.AUTHENTICATION_RESULT,
      this.mfaUser
    );

    this.errors = errors;

    if (model) {
      this.mfaUser.merge(model);
      this.goToVerification();
    }
  }

  @action async goToRecoveryCode(response) {
    await this.store.post(endpoints.RECOVERY_CODES, types.RECOVERY_CODE);

    this.recoveryCode.merge(
      this.store._getSingle(types.RECOVERY_CODE)
    );
    this.currentState = this.STATES.recoveryCode;
    this.redirectUrl = response.headers.location;
  }

  @action async setupSMS() {
    const {model, errors} = await this.store.post(
      endpoints.MFA.SETUP_SMS,
      types.AUTHENTICATION_RESULT,
      this.mfaUser
    );

    this.errors = errors;

    if (model) {
      this.mfaUser.merge(model);
      this.goToVerification();
    }
  }

  @action async verifyPhoneNumber() {
    const payload = serializeModel(this.mfaUser);
    try {
      const response = await api.post(
        endpoints.MFA.VERIFY_MFA_METHOD,
        payload
      );
      this.errors = {};
      await this.goToRecoveryCode(response);
    } catch (e) {
      this.errors = e.formErrors;
    }
  }

  @action async finish() {
    let url = '/';

    if (this.redirectUrl) {
      url = this.redirectUrl;
    }

    await redirect(url);
  }

  @action async logout() {
    const response = await api.delete(endpoints.SESSIONS.new);
    this.redirectUrl = response.headers.location;

    await this.finish();
  }

  @computed get companyMfaEnabled() {
    return auth.company.mfaSmsEnabled || auth.company.mfaAuthenticatorEnabled;
  }

  @computed get smsEnabled() {
    return auth.company.mfaSmsEnabled;
  }

  @computed get authenticatorEnabled() {
    return auth.company.mfaAuthenticatorEnabled;
  }
}

export default MFASetupContainerState;
