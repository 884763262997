import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import onboardDocumentStore from 'stores/onboard/OnboardDocumentStore';
import FillablePdfState from '../state/FillablePdfState';
import {Button, Spinner, FormError, SignaturePad} from 'components';
import SideNavigation from 'containers/onboard/documents/components/SideNavigation';
import DocumentViewer from './DocumentViewer';

const SignatureForm = observer(({uiState}) => {
  const {currentDocument, employeeSignature, errors} = uiState;
  const {companyDocument} = currentDocument;

  if (!companyDocument.signatureRequired || currentDocument.checked) return null;

  return (
    <div className='my3'>
      <SignaturePad signatureData={employeeSignature.signatureData} width={300} height={150} clearStyle='right' onChange={(dataURL) => employeeSignature.merge({signatureData: dataURL})} />
      <FormError message={errors.signature} />
    </div>
  );
});

const DocumentButtons = observer(({uiState}) => {
  const {inputAnnotations, validAnnotations, currentAnnotations} = uiState;

  return (
    <div className='my3'>
      {!_.isEmpty(inputAnnotations) && !_.isEmpty(validAnnotations) && <div className='h3 jumbo mb3'>
        <FormattedMessage id='onboard.documents.FIELDS_COMPLETED' values={{complete: validAnnotations.length, total: inputAnnotations.length}}/>
      </div>}
      <Button onClick={() => uiState.markCurrentDocumentComplete()} disabled={currentAnnotations ? !uiState.allAnnotationsValid : false}>
        <FormattedMessage id='onboard.documents.Complete'/>
      </Button>
    </div>
  );
});

const FillableDocumentContainer = observer(({uiState}) => {
  if (uiState.processing) return <Spinner />;

  return (
    <div className='clearfix my3 mxn3'>
      <div className='col col-12 px3'>
        <SideNavigation uiState={uiState}/>
        {uiState.displaySignatureForm && <SignatureForm uiState={uiState}/>}
      </div>
      <div className='col col-12 px3'>
        <DocumentViewer uiState={uiState}/>
        <DocumentButtons uiState={uiState}/>
      </div>
    </div>
  );
});

export default ({documentViewModels, callbacks}) => {
  if (_.isEmpty(documentViewModels)) return null;

  const uiState = new FillablePdfState(onboardDocumentStore, documentViewModels, callbacks);

  return <FillableDocumentContainer uiState={uiState} />;
};
