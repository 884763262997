import React from 'react';
import {observer} from 'mobx-react';
import {Route, Switch} from 'react-router-dom';
import CompanyDocumentsContainer from './CompanyDocumentsContainer';
import CompanyDocumentUpload from './CompanyDocumentUpload';
import CompanyDocumentEditor from './CompanyDocumentEditor';
import DocumentActionsContainer from './DocumentActionsContainer';

const CompanyDocumentsPage = observer(() => {
  return (
    <Switch>
      <Route exact path='/documents' component={CompanyDocumentsContainer}/>
      <Route path='/documents/:id/actions' component={DocumentActionsContainer}/>
      <Route path='/documents/upload/:documentType' component={CompanyDocumentUpload}/>
      <Route path='/documents/edit/:id' component={CompanyDocumentEditor}/>
    </Switch>
  );
});

export default CompanyDocumentsPage;
