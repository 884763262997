export default {
  admin: {
    text: 'company_settings.permissions.The Super Admin Role allows complete access to all employee records and features in the system. Super Admins can also view the platform as a user that is not a Super Admin.',
    link: 'http://support.collage.co/employee-management/roles-and-permissions/admin-role'
  },
  manager: {
    text: 'company_settings.permissions.All employees who have a direct report are assigned the Manager Role. Edit the permissions granted by this role to adjust the baseline access for all managers in Collage.',
    link: 'http://support.collage.co/employee-management/roles-and-permissions/manager-role'
  },
  employee: {
    text: 'company_settings.permissions.The Employee Role is automatically assigned to all employees except external users. Only this role’s permissions can be edited. It cannot be deleted or manually assigned to employees.',
    link: 'http://support.collage.co/employee-management/roles-and-permissions/employee-role'
  }
};
