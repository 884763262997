import React from 'react';
import {ActionLink} from 'components';
import {FormattedMessage} from 'react-intl';

const ForgotPasswordLink = () => {
  return (
    <div className='h5 mt3 mb1 center'>
      <ActionLink onClick={() => window.location.href = '/password_resets/new'}>
        <FormattedMessage id='login.Forgot your password?'/>
      </ActionLink>
    </div>
  );
};

export default ForgotPasswordLink;
