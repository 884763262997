import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {fullName} from 'shared/tools';
import Modal from './modals/Modal';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import CollageAvatar from '../img/collage-avatar@2x.png';

const PreviewModal = observer(({previewModalOpen, avatar, initials, onHide}) => {
  return (
    <Modal
      imagePreview
      size='sm'
      isOpen={previewModalOpen}
      onHide={onHide}
      closeButton={false}>
      <img alt={initials} src={avatar} className='circle' height='300'/>
    </Modal>
  );
});

const AvatarImage = ({avatar, initials}) => {
  return <img alt={initials} className='align-top Avatar-img' src={avatar}/>;
};

const AvatarInitials = ({initials}) => {
  return <div className='center white'>{initials}</div>;
};

const AvatarHelper = ({name}) => {
  return <div className='Avatar-tooltip'>{name}</div>;
};

const AvatarContent = ({employee, size, className, mode, linkable, collageAvatar, zIndex}) => {
  const avatar = collageAvatar ? CollageAvatar : _.get(employee, 'links.profilePicture');
  const computedClassNames = `Avatar Avatar--${size} ${(!avatar || mode === 'initials') && employee.avatarColour} mx-auto block ${className}`;
  const component = (avatar && mode === 'auto') ?
    <AvatarImage initials={employee.avatarInitials} avatar={avatar}/> :
    <AvatarInitials initials={employee.avatarInitials}/>;

  if (linkable) {
    return (
      <a href={`/employees/${employee.id}`} className={computedClassNames} style={{zIndex}}>{component}</a>
    );
  }

  return <div className={computedClassNames} style={{zIndex}}>{component}</div>;
};

class Avatar extends React.Component {
  @observable previewModalOpen = false;

  openPreviewModal = () => {
    this.previewModalOpen = true;
  }

  closePreviewModal = () => {
    this.previewModalOpen = false;
  }

  render() {
    const {employee, preview} = this.props;
    const avatar = _.get(employee, 'links.profilePicture');
    const hasPreview = avatar && preview;

    return (
      <div>
        <div className={`relative print-hide ${hasPreview ? 'clickable' : ''}`} onClick={hasPreview ? () => this.openPreviewModal() : () => {}}>
          <AvatarContent {...this.props}/>
          <AvatarHelper name={fullName(employee)} />
        </div>
        <PreviewModal
          avatar={avatar}
          previewModalOpen={this.previewModalOpen}
          initials={employee.avatarInitials}
          onHide={() => this.closePreviewModal()} />
      </div>
    );
  }
}

Avatar.propTypes = {
  employee: PropTypes.shape({
    avatarColour: PropTypes.string.isRequired,
    avatarInitials: PropTypes.string.isRequired,
  }),

  className: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  mode: PropTypes.oneOf(['auto', 'initials']),
  zIndex: PropTypes.number
};

Avatar.defaultProps = {
  size: 'md',
  mode: 'auto',
  preview: false,
  linkable: false,
  collageAvatar: false
};

export default observer(Avatar);
