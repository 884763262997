import {action, observable, computed} from 'mobx';
import {successAlert, refresh} from 'shared/tools';
import {endpoints, types, t, auth} from 'shared/core';
import userStore from 'stores/users/UserStore';
import {RecoveryCode} from 'stores/recovery_codes';

class UserSettingsState {
  store = userStore;

  @observable user;
  @observable recoveryCode = new RecoveryCode();
  @observable recoveryCodeModalOpen = false;
  @observable modalOpen = false;
  @observable errors = {};
  @observable passwordValues = {
    currentPassword: '',
    password: '',
    passwordConfirmation: ''
  };

  @action async load() {
    this.user = await this.store.loadUser();
  }

  @action showModal() {
    this.modalOpen = true;
  }

  @action hideModal() {
    this.modalOpen = false;
  }

  @action async payrollUpdateEmailNotification(value) {
    await this.updateUser('email_notification', {payrollUpdateEmailNotification: value});
  }

  @action async benefitUpdateEmailNotification(value) {
    await this.updateUser('email_notification', {benefitUpdateEmailNotification: value});
  }

  @action async updateEmailNotification(value) {
    await this.updateUser('email_notification', {recruitingEmailNotification: value});
  }

  @action async updateBirthdaySharedInternally(value) {
    await this.updateUser('privacy_setting', {birthdaySharedInternally: value});
  }

  @action async saveAccountPreferences() {
    const {model, errors} = await this.store.patch(this.user.pick(['email', 'emailNotificationType', 'preferredLocale']));
    this.errors = errors;

    if (model) {
      this.user.merge(model);
      successAlert(t('user_settings.account_preferences.Account preferences saved.'));
    }
  }

  @action async updatePassword() {
    const {model, errors} = await this.store.patch(
      this.user.link('updatePassword'),
      types.USER_DETAIL,
      this.passwordValues
    );
    this.errors = errors;

    if (model) {
      successAlert(t('user_settings.password.Password updated'));
    }
  }

  @action async updateEmailUnsubscribe(value) {
    if (value === this.user.unsubscribedFromEmails) return null;

    this.user.merge({unsubscribedFromEmails: value});

    const {model, errors} = await this.store.patch(
      this.user.link('updateEmailSubscription'),
      types.USER_DETAIL,
      this.user
    );
    this.errors = errors;

    if (model) {
      successAlert(t('user_settings.email_preferences.Email preferences saved.'));
    }
  }

  @action async updateUser(updateType, updateObject) {
    this.user.merge(updateObject);
    const {model, errors} = await this.store.patch(this.user.pick(Object.keys(updateObject)));
    this.errors = errors;

    if (model) {
      switch (updateType) {
        case 'email_notification':
          successAlert(t('user_settings.email_preferences.Email preferences saved.'));
          break;
        case 'privacy_setting':
          successAlert(t('user_settings.privacy_settings.Privacy settings saved.'));
          break;
        default:
      }
    }
  }

  @action async disconnectSlackAccount() {
    const {model} = await this.store.post(
      endpoints.SLACK.DISCONNECT_USER,
      types.USER_DETAIL
    );

    if (model) {
      this.user.merge(model);
      this.hideModal();
      successAlert(t('user_settings.slack.Slack account disconnected.'));
    }
  }

  get slackAuthenticityToken() {
    return document.getElementsByName('csrf-token')[0].content;
  }

  @action async disconnectMFA() {
    await this.store.post(
      endpoints.MFA.DISCONNECT,
      types.USER_DETAIL
    );

    await refresh();
  }

  @action async generateRecoveryCode() {
    this.store.invalidate(types.RECOVERY_CODE);

    await this.store.post(endpoints.RECOVERY_CODES, types.RECOVERY_CODE);
    this.recoveryCode.merge(
      this.store._getSingle(types.RECOVERY_CODE)
    );
    this.recoveryCodeModalOpen = true;
  }

  @action closeRecoveryCodeModal() {
    this.recoveryCodeModalOpen = false;
  }

  @computed get showUnsubscribeToggle() {
    return this.user.hasLink('updateEmailSubscription');
  }

  @computed get showRecruitingToggle() {
    return !this.user.unsubscribedFromEmails && auth.moduleEnabled('ats');
  }

  @computed get showPayrollToggle() {
    return !this.user.unsubscribedFromEmails && (auth.hasAccess('::PAYROLL') || auth.hasAccess('::PAYROLL_UPDATES_REPORT'));
  }

  @computed get showBenefitToggle() {
    return !this.user.unsubscribedFromEmails && (auth.hasAccess('::MANAGE_BENEFITS') || auth.hasAccess('::BENEFIT_UPDATES_REPORT'));
  }

  @computed get notificationEmailOptions() {
    const options = [
      {
        value: 'login',
        label: `${this.user.email} (${t('user_settings.account_preferences.Login Email')})`
      }
    ];

    if (this.user.employee) {
      options.push({
        value: 'work',
        label: this.user.employee.workEmail ? `${this.user.employee.workEmail} (${t('user_settings.account_preferences.Work Email')})` : t('user_settings.account_preferences.Work Email (not set)'),
        disabled: !this.user.employee.workEmail
      });
    }

    return options;
  }
}

export default UserSettingsState;
