import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalButtons} from 'components/modals';
import {FormError} from 'components';

const AddEmployeeToPayrollModal = observer(({uiState}) => {
  const {employee, errors} = uiState;

  if (!employee) return null;

  return (
    <Modal
      size='sm'
      title='payroll.employees.Add Employee to Payroll?'
      isOpen={uiState.addEmployeeToPayrollModal}
      onHide={() => uiState.closeAddEmployeeToPayrollModal()}
    >
      <FormattedMessage
        id='payroll.employees.ADD_EMPLOYEE_CONFIRMATION'
        values={{employeeName: employee.name}}
      />
      <FormError message={errors.base || errors.hoursPerWeek || errors.payRateUnit} />
      <ModalButtons
        onSave={() => uiState.enableEmployeePayroll()}
        onCancel={() => uiState.closeAddEmployeeToPayrollModal()}
      />
    </Modal>
  );
});

export default AddEmployeeToPayrollModal;
