import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {TextArea} from 'components';

const EditNoteModal = ({uiState}) => {
  const {editingNote, errors} = uiState;

  return (
    <Modal
      title='employees.profile.notes.Edit Note'
      isOpen={uiState.editModalOpen}
      onHide={() => uiState.resetModals()}
      size='md'
    >
      <TextArea
        value={_.get(editingNote, 'content') || ''}
        onChange={(e) => editingNote.merge({content: e.target.value})}
        errorMessage={errors.content}
        className='full-width'
      />
      <ModalButtons
        onSave={() => uiState.updateNote()}
        onCancel={() => uiState.resetModals()}
      />
    </Modal>
  );
};

export default observer(EditNoteModal);
