import React from 'react';
import {observer} from 'mobx-react';
import Subnav from '../../Subnav';
import {Panel, Layout, InteractiveTable} from 'components';
import {withState, endpoints, types, t} from 'shared/core';
import {PerformanceReviewReviewee, PerformanceReviewReview} from 'stores/performance_reviews';
import ShareWithRevieweeModal from 'performance_reviews/components/reviews/components/view/components/ShareWithRevieweeModal';
import {TeamContainerState} from '../state';
import {getMyTeamColumns, MY_REVIEWS_COLUMNS} from './columns';
import useModal from 'shared/hooks/useModal';
import UnshareRevieweeModal
  from 'containers/performance_reviews/components/reviews/components/view/components/UnshareRevieweeModal';

const TeamContainer = observer(({ uiState}) => {
  const shareWithRevieweeModalOpen = useModal(uiState, 'ShareWithRevieweeModal', 'selectedReviewee');
  const unshareRevieweeModalOpen = useModal(uiState, 'UnshareRevieweeModal', 'selectedReviewee');
  const {cycle, match, scope} = uiState;

  return (
    <div>
      <Subnav title={cycle.name} match={match} scope={scope}/>
      <Layout>
        <Panel.Stack loose>
          {scope.team && <InteractiveTable
            title={t('performance_reviews.view.My Team')}
            columns={getMyTeamColumns(cycle)}
            uiState={uiState}
            onMount={agent => uiState.setInteractiveAgent(agent)}
            showPath={(model) => uiState.writePathForReviewee(model)}
            customLinks={model => uiState.customLinksFor(model)}
            onlyShowCustomLinks
            proxy={{
              type: 'async',
              modelType: types.PERFORMANCE_REVIEWS.REVIEWEE,
              model: PerformanceReviewReviewee,
              endpoint: endpoints.PERFORMANCE_REVIEWS.REVIEWEES.TEAM.with(match.params.cycleId)
            }}
          />}
          <InteractiveTable
            title={t('performance_reviews.view.My Self and Peer Reviews')}
            showLinks={false}
            columns={MY_REVIEWS_COLUMNS}
            uiState={uiState}
            showPath={(model) => uiState.writePathForReviewee(model.reviewee)}
            proxy={{
              type: 'async',
              modelType: types.PERFORMANCE_REVIEWS.REVIEW,
              model: PerformanceReviewReview,
              endpoint: endpoints.PERFORMANCE_REVIEWS.REVIEWS.NON_MANAGER.with(match.params.cycleId)
            }}
          />
        </Panel.Stack>
      </Layout>
      <ShareWithRevieweeModal
        uiState={uiState}
        isOpen={shareWithRevieweeModalOpen}
      />
      <UnshareRevieweeModal
        uiState={uiState}
        isOpen={unshareRevieweeModalOpen}
      />
    </div>
  );
});

export default withState(TeamContainer, TeamContainerState);
