import {observable, action, computed} from 'mobx';

class FolderViewModel {
  @observable uiState;
  @observable data;

  constructor(data, uiState) {
    this.data = data;
    this.uiState = uiState;
  }

  @action openAddDocumentToFolderModal() {
    this.uiState.openAddDocumentToFolderModal(this);
  }

  @action async select() {
    this.uiState.isUpdating = true;

    this.uiState.currentFolder = this.data;
    await this.uiState.load();

    this.uiState.isUpdating = false;
  }

  @computed get icon() {
    return 'folder';
  }

  @computed get clickable() {
    return true;
  }

  @computed get downloadUrl() {
    return null;
  }

  @computed get name() {
    return this.data.name;
  }

  @computed get createdAt() {
    return null;
  }

  @computed get customLinks() {
    return null;
  }
}

export default FolderViewModel;
