import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import CustomFieldGroupListState from '../state/CustomFieldGroupListState';
import CustomFieldGroup from './CustomFieldGroup';
import {Button, Panel} from 'components';
import {FormattedMessage} from 'react-intl';
import EditGroupModal from './EditGroupModal';
import ReorderGroupsModal from './ReorderGroupsModal';
import RemoveModal from 'components/modals/RemoveModal';
import RemoveCustomFieldModal from './RemoveCustomFieldModal';
import ReorderCustomFieldsModal from './ReorderCustomFieldsModal';
import EditCustomFieldModal from './EditCustomFieldModal';
import _ from 'lodash';

const ReorderGroupsButton = observer(({uiState, group}) => {
  return (
    <div className='table-cell'>
      <Button size='sm' trait='default' onClick={() => uiState.openReorderGroupsModal(group)}>
        <FormattedMessage id='company_settings.custom_fields.Reorder Groups'/>
      </Button>
    </div>
  );
});

const AddGroupButton = observer(({uiState}) => {
  return (
    <div className='table-cell'>
      <Button className='ml2' size='sm' onClick={() => uiState.openEditGroupModal()}>
        <FormattedMessage id='company_settings.custom_fields.+ Add Custom Field Group'/>
      </Button>
    </div>
  );
});

const CustomFieldGroupsContainer = observer(({uiState}) => {
  const {sortedGroups, selectedGroupHasFields, REMOVE_MODAL_LOCALIZATION, category} = uiState;

  return (
    <div>
      <div className='mb2 flex justify-content-end'>
        <ReorderGroupsButton uiState={uiState}/>
        <AddGroupButton uiState={uiState}/>
      </div>
      {_.isEmpty(sortedGroups) ?
        <Panel>
          <div className='center jumbo rounded h5 py2 px1 Table-emptyState' style={{marginBottom: 0}}>
            <FormattedMessage id={'company_settings.custom_fields.NO_GROUPS_MESSAGE'} values={{category}}/>
          </div>
        </Panel>
        :
        <Panel.Stack loose>
            {sortedGroups.map(group => <CustomFieldGroup uiState={uiState} group={group} key={group.id}/>)}
        </Panel.Stack>
      }
      <EditGroupModal uiState={uiState}/>
      <ReorderGroupsModal uiState={uiState}/>
      <RemoveCustomFieldModal uiState={uiState}/>
      <ReorderCustomFieldsModal uiState={uiState}/>
      <EditCustomFieldModal uiState={uiState}/>
      <RemoveModal
        isOpen={uiState.deleteGroupModalOpen}
        onHide={() => uiState.closeDeleteGroupModal()}
        {...REMOVE_MODAL_LOCALIZATION}
        onConfirm={() => uiState.deleteCustomFieldGroup()}
        disabled={selectedGroupHasFields}
      />
    </div>
  );
});

export default withState(CustomFieldGroupsContainer, CustomFieldGroupListState);
