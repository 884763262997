import React from 'react';

const Tick = ({tick, count, showLabels}) => {
  const tickStyle = {
    left: `${tick.percent}%`
  };

  const labelStyle = {
    marginLeft: `${-(100 / count) / 2}%`,
    width: `${100 / count}%`,
    left: `${tick.percent}%`,
  };

  return (
    <div>
      <div className='SliderBar-tick' style={tickStyle}/>
      {showLabels && <div className='SliderBar-tickLabel' style={labelStyle}>{tick.value}</div>}
    </div>
  );
};

export default Tick;
