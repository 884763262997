import React from 'react';
import {t} from 'shared/core';
import {NavLink} from 'react-router-dom';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Display} from 'components';

const Subnav = observer(({scope}) => {
  return (
    <Display title={t('benefit_enrolment.Benefits')}>
      {scope.benefitEnrolments && <NavLink activeClassName='active' to='/benefit_enrolments'>
        <FormattedMessage id='benefit_enrolment.Benefit Enrolments'/>
      </NavLink>}
      {scope.companyCoverages && <NavLink activeClassName='active' to='/company_plans'>
        <FormattedMessage id='benefit_enrolment.Company Plans'/>
      </NavLink>}
      {scope.coverage && <NavLink activeClassName='active' to='/my_coverages'>
        <FormattedMessage id='benefit_enrolment.My Coverage'/>
      </NavLink>}
      {scope.enrolment && <NavLink to='/my_enrolment'>
        <FormattedMessage id='benefit_enrolment.My Enrolment'/>
      </NavLink>}
      {scope.updates && <NavLink to='/updates'>
        <FormattedMessage id='benefit_enrolment.Updates'/>
      </NavLink>}
    </Display>
  );
});

export default Subnav;
