import {observable}  from 'mobx';
import {DomainObject} from 'shared/store';
import _ from 'lodash';

class CompanyLink extends DomainObject {
  @observable id;
  @observable name;
  @observable url;
  @observable order;
}

export default CompanyLink;
