import React from 'react';
import PropTypes from 'prop-types';
import withSideEffect from 'react-side-effect';
import {withCompanyName} from 'shared/tools';
import {createRoot} from 'react-dom/client';
import _ from 'lodash';
import {IntlProvider} from 'react-intl';
import i18n from 'shared/i18n';

const DocumentTitle = ({children}) => {
  if (children) {
    return React.Children.only(children);
  } else {
    return null;
  }
};

DocumentTitle.propTypes = {
  title: PropTypes.node.isRequired
};

function reducePropsToState(propsList) {
  var innermostProps = propsList[propsList.length - 1];
  if (innermostProps) {
    return innermostProps.title;
  }
}

function handleStateChangeOnClient(title) {
  if (!title) return;

  if (_.isString(title)) {
    document.title = withCompanyName(title);
  }

  function Title({ callback }) {
    return (
      <IntlProvider locale={i18n.locale} messages={i18n.messages} ref={callback}>
        <span>{title}</span>
      </IntlProvider>
    );
  }

  const div = document.createElement('div');
  const root = createRoot(div);
  root.render(
    <Title callback={() => {
      const renderedTitle = div.textContent;
      if (renderedTitle) {
        document.title = withCompanyName(renderedTitle);
      }
    }}/>
  );
}

export default withSideEffect(
  reducePropsToState,
  handleStateChangeOnClient
)(DocumentTitle);
