import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints} from 'shared/core';
import moment from 'moment';
import {EmployeePlatformUsage} from 'stores/employees';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

class DeleteExplainerState {
  store = new DomainStore();
  @observable employee;
  @observable platformUsage;

  receiveProps({employee}) {
    this.employee = employee;
  }

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEE.PLATFORM_USAGE.with(this.employee.id)
    ]);

    this.platformUsage = new EmployeePlatformUsage(this.store._getSingle(types.EMPLOYEE_PLATFORM_USAGE));
  }

  @computed get deleteExplainerRelatedModels() {
    return [
      {
        id: 'REVIEWS',
        values: {
          name: this.employee.name,
          bolded_part: {
            id: 'employees.profile.delete_employee.explainer.REVIEWS_BOLDED_PART',
            values: {count: this.platformUsage.reviewsCount}
          }
        }
      },
      {
        id: 'TRAINING',
        values: {
          name: this.employee.name,
          bolded_part: {
            id: 'employees.profile.delete_employee.explainer.TRAINING_BOLDED_PART',
            values: {count: this.platformUsage.trainingsCount}
          }
        }
      },
      {
        id: 'TIME_OFF_REQUESTS',
        values: {
          name: this.employee.name,
          bolded_part: {
            id: 'employees.profile.delete_employee.explainer.TIME_OFF_REQUESTS_BOLDED_PART',
            values: {count: this.platformUsage.timeOffRequestsCount}
          }
        }
      },
      {
        id: 'HIRING_COLLABORATORS',
        values: {
          name: this.employee.name,
          bolded_part: {
            id: 'employees.profile.delete_employee.explainer.HIRING_COLLABORATORS_BOLDED_PART',
            values: {count: this.platformUsage.hiringCollaboratorsCount}
          }
        }
      },
    ];
  }

  @computed get deleteExplainerAttributes() {
    return [
      {
        id: 'MAIN',
        values: {
          name: this.employee.name,
          employeeAge: moment().diff(moment(this.employee.createdAt), 'days'),
          bolded_part_1: <span className='medium'><FormattedMessage
            id='employees.profile.delete_employee.explainer.MAIN_BOLDED_ONE'/></span>,
          bolded_part_2: <span className='medium'><FormattedMessage
            id='employees.profile.delete_employee.explainer.MAIN_BOLDED_TWO'/></span>,
        }
      },
      {
        id: 'TERMINATION_WARNING',
        values: {
          bolded_part: <span className='medium'><FormattedMessage
            id='employees.profile.delete_employee.explainer.TERMINATION_WARNING_BOLDED_PART'/></span>
        }
      },
      {
        id: 'DIRECT_REPORTS',
        values: {
          name: this.employee.name,
          bolded_part: {
            id: 'employees.profile.delete_employee.explainer.DIRECT_REPORTS_BOLDED_PART',
            values: {count: this.platformUsage.directReportsCount}
          }
        }
      },
      {
        id: 'TIME_OFF_POLICY',
        values: {
          name: this.employee.name,
          bolded_part: {
            id: 'employees.profile.delete_employee.explainer.is enrolled',
            values: {count: this.platformUsage.enrolledInTimeOff}
          }
        }
      },
      {
        id: 'BENEFITS',
        values: {
          name: this.employee.name,
          bolded_part: {
            id: 'employees.profile.delete_employee.explainer.is enrolled',
            values: {count: this.platformUsage.enrolledInBenefits}
          }
        }
      },
      {
        id: 'PAYROLL',
        values: {
          name: this.employee.name,
          bolded_part: {
            id: 'employees.profile.delete_employee.explainer.is enrolled',
            values: {count: this.platformUsage.enrolledInPayroll}
          }
        }
      },
    ];
  }

  @computed get hasRelatedModels() {
    return _.some(this.deleteExplainerRelatedModels, (related) => related.values.bolded_part.values.count > 0);
  }
}

export default DeleteExplainerState;
