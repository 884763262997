import React from 'react';
import {FormattedMessage} from 'react-intl';
import {NavLink} from 'react-router-dom';

const PerformanceSubnav = ({scope}) => {
  return (
    <div className="Subnav Subnav--react">
      <div className="Container">
        <div className="Subnav-header">
          <FormattedMessage id='performance.Performance Management'/>
        </div>
        <ul className='Subnav-list list-reset'>
          {scope.reviewees && <li><NavLink to='/reviewees' activeClassName='active'>
            <FormattedMessage id='performance.Check-Ins I Lead'/>
          </NavLink></li>}
          {scope.checkins && <li><NavLink to='/checkins' activeClassName='active'>
            <FormattedMessage id='performance.My Check-Ins'/>
          </NavLink></li>}
          {scope.cycles && <li><NavLink to='/cycles' activeClassName='active'>
            <FormattedMessage id='performance.Check-In Cycles'/>
          </NavLink></li>}
        </ul>
      </div>
    </div>
  );
};

export default PerformanceSubnav;
