import {observable, action} from 'mobx';
import {DomainStore} from 'shared/store';
import {setupAutosaveDraft, successAlert} from 'shared/tools';
import {auth, endpoints, t, types} from 'shared/core';
import PerformanceReviewNote from 'stores/performance_reviews/PerformanceReviewNote';
import _ from 'lodash';

class ReviewNotesState {
  store = new DomainStore();

  @observable review;
  @observable newNote = {};
  @observable newNoteErrors = {};
  @observable editingNote = {};
  @observable editingNoteErrors = {};

  receiveProps({review}) {
    this.review = review;
  }

  @action async load() {
    await this.setupNewNote();
  }

  @action editNote(note) {
    this.editingNote = new PerformanceReviewNote(note);
  }

  @action cancelEditing() {
    this.editingNote = {};
  }

  async setupNewNote() {
    this.newNote = new PerformanceReviewNote({visibleToReviewee: this.review.reviewee.employee.id === auth.employee.id});
    await setupAutosaveDraft(
      this.newNote,
      {id: this.review.id, type: types.PERFORMANCE_REVIEWS.NOTE}
    );
  }

  @action async updateNewNoteText(text) {
    this.newNote.text = text.html;
    this.newNote.lexicalState = text.state;

    if (this.newNote.autosaver) {
      this.newNote.autosaver.autosave();
    }
  }

  @action async updateEditingNoteText(text) {
    this.editingNote.text = text.html;
    this.editingNote.lexicalState = text.state;
  }


  @action async updateNewNoteVisibility(visibleToReviewee) {
    this.newNote.visibleToReviewee = visibleToReviewee;

    if (this.newNote.autosaver) {
      this.newNote.autosaver.autosave();
    }
  }

  @action async updateEditingNoteVisibility(visibleToReviewee) {
    this.editingNote.visibleToReviewee = visibleToReviewee;
  }

  @action async addNote() {
    const {errors, model} = await this.store.post(
      endpoints.PERFORMANCE_REVIEWS.NOTES.NEW.with(this.review.id),
      types.PERFORMANCE_REVIEWS.NOTE,
      this.newNote
    );
    this.newNoteErrors = errors;
    if (model) {
      successAlert(t('performance_reviews.view.notes.Note added'));
      this.review.notes.push(new PerformanceReviewNote(model));
      await this.setupNewNote();
    }
  }

  @action async updateNote() {
    const {errors, model} = await this.store.patch(this.editingNote);
    this.editingNoteErrors = errors;
    if (model) {
      successAlert(t('performance_reviews.view.notes.Note updated'));
      _.find(this.review.notes, {id: this.editingNote.id}).merge(model);
      this.editingNote = {};
    }
  }

  @action async removeNote(note) {
    await this.store.destroy(note);
    successAlert(t('performance_reviews.view.notes.Note removed'));
    _.remove(this.review.notes, {id: note.id});
  }

  customLinksFor(note) {
    return [
      {
        text: 'Edit',
        order: 0,
        action: () => this.editNote(note)
      },
      {
        text: 'Remove',
        order: 1,
        action: () => this.removeNote(note)
      }
    ];
  }
}

export default ReviewNotesState;
