import React from 'react';
import {observer} from 'mobx-react';
import {UploadFiles, FormError} from 'components';
import {observable} from 'mobx';
import {scrollsIntoViewIfErrors, noop} from 'shared/tools';
import Description from './Description';

const ACCEPTED_TYPES = [
  'audio/*',
  'video/*',
  'image/*',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'text/rtf'
];

const AttachmentField = observer(({answer, required, onChanged, disabled, errorMessage, modelType}) => {

  return (
    <div className={`${errorMessage ? 'AttachmentField--hasErrors' : ''}`}>
      <Description answer={answer} required={required} />
      <UploadFiles
        defaultFiles={answer.attachment ? observable([answer.attachment]) : observable([])}
        onChange={files => {answer.updateFile(files); onChanged();}}
        disabled={disabled}
        modelType={modelType}
        maxFiles={1}
        accept={ACCEPTED_TYPES.join()}
      />
      {errorMessage && <FormError message={errorMessage}/>}
    </div>
  );
});

AttachmentField.defaultProps = {
  onChanged: noop
};

export default scrollsIntoViewIfErrors(AttachmentField, '.AttachmentField--hasErrors');
