import {DomainStore} from 'shared/store';
import {action} from 'mobx';
import {t, types} from 'shared/core';
import {successAlert} from 'shared/tools';

class PendingRequestsListState {
  store = new DomainStore();
  history;
  match;
  agent;
  statusOptions = [
    {
      id: 'all',
      display: t('time_off.requests.All')
    },
    {
      id: 'assigned_to_me',
      display: t('time_off.requests.Assigned to me')
    }
  ];

  customLinks = [
    {
      order: 1,
      text: 'time_off.requests.Approve',
      action: model => this.approveRequest(model)
    },
    {
      order: 2,
      text: 'time_off.requests.Reject',
      action: model => this.rejectRequest(model)
    }
  ];

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action setInteractiveAgent(agent) {
    this.agent = agent;
  }

  @action viewRequest(request) {
    this.history.push(`${this.match.path}/${request.id}`);
  }

  @action async approveRequest(request) {
    await this.store.patch(
      request.link('approve'),
      types.TIME_OFF.REQUEST,
      request
    );
    this.agent.refresh();
    successAlert(t('time_off.requests.Request has been approved'));
  }

  @action async rejectRequest(request) {
    await this.store.patch(
      request.link('reject'),
      types.TIME_OFF.REQUEST,
      request
    );
    this.agent.refresh();
    successAlert(t('time_off.requests.Request has been rejected'));
  }

  @action redirectToEdit(request) {
    window.location = `/employees/${request.employee.id}/time-off/request/${request.id}`;
  }

  @action async deleteRequest(request) {
    await this.store.destroy(request);
    await this.agent.refresh();
  }
}

export default PendingRequestsListState;
