import React from 'react';
import {observer} from 'mobx-react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {BenefitsNotEnabled} from './not_enabled';

const BenefitsNotEnabledSwitch = observer(() => {
  return (
    <Switch>
      <Route path='/learn_more' component={BenefitsNotEnabled}/>
      <Redirect from='/' to='/learn_more'/>
    </Switch>
  );
});

export default BenefitsNotEnabledSwitch;
