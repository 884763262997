import columnChart from './columnChart';
import pieChart from './pieChart';
import areaChart from './areaChart';

function chartFactory(chartType, data, options) {
  switch (chartType) {
    case 'column':
      return columnChart(data, options);
    case 'pie':
      return pieChart(data, options);
    case 'area':
      return areaChart(data, options);
    default:
      throw new Error(`Chart of type ${chartType} is not supported.`);
  }
}

export default chartFactory;
