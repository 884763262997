import _ from 'lodash';
import {action, computed, observable} from 'mobx';
import {DomainStore} from 'shared/store';
import {t, endpoints, types} from 'shared/core';
import {Question} from 'stores/training';
import Folder from 'stores/folders/Folder';
import {TrainingProgram, EmployeeTrainingStatusSummary} from 'stores/training';
import {successAlert} from 'shared/tools';
import ReactPlayer from 'react-player';

class TrainingEditFlowState {
  store = new DomainStore();
  history;
  match;

  @observable selectedEmployees = [];
  @observable trainingProgram;
  @observable submitting;
  @observable selectAll = false;
  @observable errors = {};
  @observable isUploading = false;
  @observable questions = [];
  @observable editQuestionModalOpen = false;
  @observable editingQuestion = new Question();
  @observable previewingVideo = false;

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    const trainingProgramId = this.match.params.id;

    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.TRAINING.PROGRAM.with(trainingProgramId)
    ]);

    this.trainingProgram = new TrainingProgram(this.store._getSingle(types.TRAINING.PROGRAM));
    this.questions = this.trainingProgram.questions;
  }

  @action async onStepChanged({location}) {
    switch (location) {
      case 'questions':
        await this.loadFolders();
        break;
      default:
        break;
    }
  }

  @action async loadFolders() {
    this.store.invalidate(types.FOLDER);

    await this.store._compose([
      endpoints.FOLDERS.ALL
    ]);

    this.folders = this.store._getAll(types.FOLDER, Folder);
  }

  @action updateDescription(description) {
    this.trainingProgram.description = description.html;
    this.trainingProgram.lexicalState = description.state;
  }

  @action onUploadStarted() {
    this.isUploading = true;
  }

  @action onUploadFinished() {
    this.isUploading = false;
  }

  @action displayVideoPreview() {
    this.previewingVideo = true;
  }

  async submitStep(props) {
    const trainingProgram = _.pick(
      this.trainingProgram,
      ['links', 'id', '_type', ...props]
    );
    const {model, errors} = await this.store.patch(
      endpoints.TRAINING.PROGRAM.with(trainingProgram.id),
      types.TRAINING.PROGRAM,
      trainingProgram
    );

    this.errors = errors;

    if (!model) return false;

    this.trainingProgram.update(model);
  }

  @action changeVideoUrl(url) {
    this.previewingVideo = false;
    this.errors.video = null;
    this.trainingProgram.videoUrl = url;
  }

  @action updateEmployeeTrainingStatuses() {
    const trainingStatuses = [];

    for (const employee of this.selectedEmployees) {
      const existingEmployeeStatus = _.find(this.trainingProgram.employeeTrainingStatuses, { employee: { id: employee.id }});
      const employeeStatus = _.isEmpty(existingEmployeeStatus) ?
        new EmployeeTrainingStatusSummary({employee}) :
        existingEmployeeStatus;

      trainingStatuses.push(employeeStatus);
    }

    this.trainingProgram.employeeTrainingStatuses = trainingStatuses;
  }

  @action submitOverview() {
    return this.submitStep([
      'name',
      'availableInHiring',
      'relativeDueDateUnit',
      'relativeDueDateValue',
      'description',
      'lexicalState'
    ]);
  }

  videoIsUnplayable() {
    return this.trainingProgram.videoUrl && !ReactPlayer.canPlay(this.trainingProgram.videoUrl);
  }

  @action submitDocuments() {
    if (this.videoIsUnplayable()){
      this.errors = {...this.errors, video: t('training.edit.Unsupported video')};
      return false;
    } else {
      return this.submitStep([
        'attachedFiles',
        'videoUrl'
      ]);
    }
  }

  @action submitQuestions() {
    this.trainingProgram.merge({questions: this.questions});
    return this.submitStep([
      'questions'
    ]);
  }

  @action submitEmployees() {
    this.updateEmployeeTrainingStatuses();

    this.submitStep([
      'employeeTrainingStatuses'
    ]);
  }

  @action async finish() {
    if (!this.trainingProgram.launched) {
      await this.launchProgram();
    } else {
      successAlert(t('training.edit.Saved training program'));
    }

    this.history.push(`/admin/programs/${this.trainingProgram.id}/summary`);
  }

  @action async launchProgram() {
    await this.store.patch(
      this.trainingProgram.link('launch'),
      types.TRAINING.PROGRAM,
      this.trainingProgram
    );

    successAlert(t('training.edit.Training program created'));
  }

  @action async onStepSubmitted({location}) {
    switch (location) {
      case 'overview': return this.submitOverview();
      case 'documents': return this.submitDocuments();
      case 'questions': return this.submitQuestions();
      case 'employees': return this.submitEmployees();
      case 'review': return this.finish();
      default:
        throw new Error(`Location ${location} is not supported.`);
    }
  }

  @action toggleSelectAll() {
    this.selectAll = !this.selectAll;
    _.forEach(this.statuses, status => {
      status.checked = this.selectAll;
    });
  }

  @action removeQuestion(question) {
    this.questions.remove(question);
  }

  @action closeEditQuestionModal() {
    this.editQuestionModalOpen = false;
  }

  @computed get dueDate() {
    if (this.trainingProgram.formattedDueDate == null) {
      return t('training.edit.None');
    }

    return t(
      'training.edit.DUE_DATE',
      { time: this.trainingProgram.formattedDueDate }
    );
  }

  @computed get sortedFolders() {
    return _.orderBy(this.folders, 'order');
  }

  redirectToProgramList() {
    this.history.push('/admin/programs');
  }

  @computed get selectedDueDateOption() {
    if (this.trainingProgram.relativeDueDateValue) {
      return 'offset';
    } else {
      return 'none';
    }
  }

  set selectedDueDateOption(value) {
    if (value === 'offset') {
      this.trainingProgram.relativeDueDateUnit = 'days';
      this.trainingProgram.relativeDueDateValue = 1;
    } else {
      this.trainingProgram.relativeDueDateUnit = null;
      this.trainingProgram.relativeDueDateValue = null;
    }
  }

  @computed get selectedUnit() {
    if (!this.trainingProgram.relativeDueDateValue) {
      return '';
    }

    if (!this.trainingProgram.relativeDueDateUnit) {
      this.trainingProgram.relativeDueDateUnit = 'days';
    }

    return this.trainingProgram.relativeDueDateUnit;
  }

  set selectedUnit(value) {
    if (value) {
      if (this.trainingProgram.relativeDueDateValue === null) {
        this.trainingProgram.relativeDueDateValue = 1;
      }
      this.trainingProgram.relativeDueDateUnit = value;
    }
  }

  @action saveQuestion() {
    if (!this.validateQuestion()) return;

    const existingQuestion = _.find(this.questions, q => q.order === this.editingQuestion.order);

    if (existingQuestion) {
      existingQuestion.update(this.editingQuestion);
    } else {
      this.questions.push(this.editingQuestion);
    }
    this.closeEditQuestionModal();
  }

  @action editQuestion(question) {
    this.editingQuestion = new Question(question);
    this.editQuestionModalOpen = true;
  }

  @action addQuestion() {
    this.editQuestion({
      text: '',
      order: this.questions.length,
      questionType: 'quiz'
    });
  }

  @action validateQuestion() {
    this.errors = this.editingQuestion.validate();

    return _.isEmpty(this.errors);
  }
}

export default TrainingEditFlowState;
