import {observable, action, computed} from 'mobx';
import _ from 'lodash';

class RolesListState {
  store;
  history;

  @observable roles;

  constructor(store, history) {
    this.store = store;
    this.history = history;
  }

  @action async load() {
    this.roles = await this.store.load();
  }

  @action async postRole() {
    const id = await this.store.postRole();
    this.history.push(`/permissions/${id}`);
  }

  @action async destroyRole(role) {
    await this.store.destroy(role);
    await this.load();
  }

  @computed get defaultRoles() {
    return _.reject(this.roles, r => r.isCustom);
  }

  @computed get customRoles() {
    return _.filter(this.roles, r => r.isCustom);
  }
}

export default RolesListState;
