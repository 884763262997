import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Display} from 'components';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

const GoalSubnav = observer(({scope}) => {
  return (
    <Display title={t('goals.Performance Goals')}>
      {scope.overview && <NavLink exact activeClassName='active' to='/'>
        <FormattedMessage id='goals.Overview'/>
      </NavLink>}
      {scope.myGoals && <NavLink activeClassName='active' to='/my-goals'>
        <FormattedMessage id='goals.My Goals'/>
      </NavLink>}
    </Display>
  );
});

export default GoalSubnav;
