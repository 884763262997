import React from 'react';
import {observer} from 'mobx-react';
import {ObjectSelect2} from 'components';

const SendReminderTo = observer(({uiState}) => {
  const {errors, reminder} = uiState;

  return (
    <ObjectSelect2 value={reminder.recipients}
                   onAdd={recipient => uiState.addRecipient(recipient)}
                   onRemove={recipient => uiState.removeRecipient(recipient)}
                   searchable={true}
                   multiple
                   items={uiState.recipientOptions}
                   by='value'
                   label='company_settings.reminders.Send reminder to'
                   errorMessage={errors.recipients}
                   placeholder='company_settings.reminders.e.g., Employee'/>
  );
});

export default SendReminderTo;
