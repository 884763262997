import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import EmployeeBenefitEnrollment from './EmployeeBenefitEnrollment';
import EmployeeInsuranceNumber from './EmployeeInsuranceNumber';
import _ from 'lodash';

class EmployeeCoverage extends DomainObject {
  @observable employeeBenefitEnrollments;
  @observable employeeInsuranceNumbers;

  @action merge(other) {
    super.merge(other, {
      employeeBenefitEnrollments: [EmployeeBenefitEnrollment],
      employeeInsuranceNumbers: [EmployeeInsuranceNumber]
    });
  }

  @computed get allBenefitClasses() {
    return _.map(this.employeeBenefitEnrollments, e => e.benefitClass);
  }
}

export default EmployeeCoverage;
