import React from 'react';
import {observer} from 'mobx-react';

const CheckboxComplete = observer(({checked, onToggleComplete, className}) => {
  return (
    <div className={`CompleteAnimation ${!!className && className}`} onClick={onToggleComplete}>
      <input type='checkbox' className='checkbox-img'/>
      <div className={`CompleteAnimation-image ${checked ? 'checked' : ''}`}></div>
    </div>
  );
});

export default CheckboxComplete;
