import React from 'react';
import EmployeeTimeOffContainerWithState from './EmployeeTimeOffContainerWithState';
import EmployeeTimeOffContainerNoPolicy from './EmployeeTimeOffContainerNoPolicy';
import {observer} from 'mobx-react';
import EmployeeProfileContainer from '../../EmployeeProfileContainer';

const EmployeeTimeOffContainer = observer(({uiState}) => {
  return (
    <EmployeeProfileContainer uiState={uiState}>
      {uiState.employee.timeOffPolicy
        ? <EmployeeTimeOffContainerWithState parentState={uiState}/>
        : <EmployeeTimeOffContainerNoPolicy employee={uiState.employee}/>
      }
    </EmployeeProfileContainer>
  );
});

export default EmployeeTimeOffContainer;
