import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';

const TableFooter = observer(({models}) => {
  if (_.isEmpty(models)) return null;

  return (
    <div className='pb1'></div>
  );
});

export default TableFooter;

