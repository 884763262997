import _ from 'lodash';
import {action, computed, observable} from 'mobx';
import {DomainObject} from 'shared/store';
import {t} from 'shared/core';
import TimelineEntry from './TimelineEntry';
import {dateFormatter, fullName} from 'shared/tools';
import {FunnelStage} from './FunnelStage';
import AttachedDocument from 'stores/documents/AttachedDocument';
import Answer from './Answer';
import Position from './Position';
import CandidateTag from './CandidateTag';
import {Offer} from '../offers';
import {User} from '../users';

class Candidate extends DomainObject {
  id;
  @observable createdAt;
  @observable firstName;
  @observable lastName;
  @observable email;
  @observable phoneNumber;
  @observable additionalInformation;
  @observable resume;
  @observable currentStage;
  @observable disqualified;
  @observable linkedinUrl;
  @observable timelineEntries = [];
  @observable avatarInitials;
  @observable avatarColour;
  @observable position;
  @observable answers = [];
  @observable origin;
  @observable recaptchaResponse;
  @observable sendDisqualifiedEmail;
  @observable offer;
  @observable canSendOffer;
  @observable canEditOffer;
  @observable score;
  @observable referredByUser;
  @observable candidateTags = [];

  @computed get hasResume() {
    return _.has(this.resume, 'fileName');
  }

  @action clearResume() {
    this.resume = null;
  }

  @action updateResume(file) {
    if (file) {
      this.merge({resume: file});
    } else {
      this.clearResume();
    }
  }

  @action merge(candidate) {
    super.merge(candidate, {
      currentStage: FunnelStage,
      resume: AttachedDocument,
      answers: [Answer],
      position: Position,
      offer: Offer,
      referredByUser: User,
      candidateTags: [CandidateTag]
    });

    if (candidate.timelineEntries) {
      this.timelineEntries = candidate.timelineEntries.map(entry => TimelineEntry.fromJS(entry));
    }
  }

  @computed get date() {
    return dateFormatter(this.createdAt);
  }

  @computed get urlSlug() {
    return _.kebabCase(this.name);
  }

  @computed get name() {
    return fullName(this);
  }

  @computed get sortedAnswers() {
    return _.orderBy(this.answers, 'question.order');
  }

  @computed get simplifiedOrigin() {
    if (this.origin === 'sourced') return this.origin;

    return 'applied';
  }

  @computed get originView() {
    if (_.includes(['sourced', 'applied', 'linkedin'], this.origin)) {
      return t(`recruiting.candidate.origins.${this.origin}`);
    } else {
      return _.capitalize(this.origin);
    }
  }

  @computed get mostRecentRating() {
    return _.chain(this.timelineEntries)
      .filter((entry) => entry.isRating)
      .orderBy('createdAt', 'desc')
      .head()
      .value();
  }
}

export default Candidate;
