import React from 'react';
import {observer} from 'mobx-react';
import {CheckboxWithLabel} from 'components';

const AnonymousField = observer(({uiState}) => {
  const {survey, errors} = uiState;

  return (
    <React.Fragment>
      <CheckboxWithLabel
        value={survey.anonymous}
        disabled={survey.isLaunched}
        checked={survey.anonymous}
        onChange={() => uiState.toggleSurveyAnonymous()}
        label={'surveys.edit.Create Anonymous Survey'}
        errorMessage={errors.anonymous}
      />
    </React.Fragment>
  );
});

export default AnonymousField;
