import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';
import {CheckboxWithLabel} from 'components';

const SendDisqualifiedEmailModal = observer(({uiState}) => {
  const {candidate} = uiState.detailsState;

  return (
    <AlertModal
      isOpen={uiState.sendDisqualifiedEmailModalOpen}
      onHide={() => uiState.closeSendDisqualifiedEmailModal()}
      size='sm'
    >
      <form>
        <div className='h3 pb2'>
          <FormattedMessage id='recruiting.candidate.Disqualify Candidate?'/>
        </div>
        <div className='h2 pb2'>
          <FormattedMessage id='recruiting.candidate.Are you sure you want to disqualify this candidate?'/>
        </div>
        <CheckboxWithLabel
          label='recruiting.candidate.Send disqualification email to candidate?'
          checked={candidate.sendDisqualifiedEmail}
          onChange={e => candidate.merge({sendDisqualifiedEmail: e.checked})}
        />
        <ModalDangerButtons
          onSave={() => uiState.doDisqualifyCandidate()}
          saveCaption='recruiting.candidate.Disqualify'
          onCancel={() => uiState.closeSendDisqualifiedEmailModal()}
        />
      </form>
    </AlertModal>
  );
});

export default SendDisqualifiedEmailModal;
