import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import User from 'stores/users/User';
import _ from 'lodash';
import {calendarDate} from 'shared/tools';

class JobTimelineEntry extends DomainObject {
  @observable id;
  @observable employee;
  @observable effectiveDate;
  @observable current;
  @observable initial;
  @observable createdAt;
  @observable updatedAt;
  @observable author;
  @observable comment;

  @action merge(other, fields) {
    super.merge(other, {
      ...fields,
      author: User,
      _dates: [
        'effectiveDate',
        ...(_.get(fields, '_dates') || [])
      ]
    });
  }

  @computed get wasUpdated() {
    return this.updatedAt > this.createdAt;
  }

  @computed get effectiveDateView() {
    return calendarDate(this.effectiveDate);
  }
}

export default JobTimelineEntry;
