import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {LivesChartState} from '../state';
import {Panel, Spinner} from 'components';
import {t} from 'shared/core';

const LivesChart = observer(({uiState}) => {
  const {Highcharts, HighchartsReact, livesChartOptions} = uiState;

  return (
    <Panel>
      <Panel.Header
        title={t('pa_dashboard.lives_chart.Total Portfolio Enrolment')}
        description={t('pa_dashboard.lives_chart.Click and drag in the plot area to zoom in.')}
      />
      <HighchartsReact
        highcharts={Highcharts}
        options={livesChartOptions}
      />
    </Panel>
  );
});

const ChartSpinner = () => {
  return (
    <Panel>
      <Panel.Header
        title={t('pa_dashboard.lives_chart.Total Portfolio Enrolment')}
        description={t('pa_dashboard.lives_chart.Loading data...')}
      />
      <div className='Report-empty-placeholder py3'>
        <Spinner />
      </div>
    </Panel>
  );
};

export default withState(LivesChart, LivesChartState, {spinner: ChartSpinner});

