import React from 'react';
import Modal from 'react-modal';

const FullscreenModal = ({isOpen, onHide, children}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onHide}
      className='FullScreenModalContent'
      overlayClassName='FullScreenModalOverlay'
    >
      {children}
    </Modal>
  );
};

export default FullscreenModal;
