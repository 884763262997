import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Survey',
  'Period Date',
  'Department',
  'Location',
  'Manager',
  'Gender',
  'Employee',
  'Employment Status',
  'Employment Type',
];

const SurveysReport = () => {
  return (
    <CompositeReport filters={filters}>
      <Report name={t('reports.SURVEY_RESPONSES')}
              url='/reports/surveys'>
      </Report>
    </CompositeReport>
  );
};

export default SurveysReport;
