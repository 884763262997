import BaseReportDataSource from './BaseReportDataSource';

class BrokerReportDataSource extends BaseReportDataSource {
  employeeDataSource() {
    // Implement 
  }

  departmentDataSource(options) {
    // Implement
  }

  locationDataSource(options) {
    // Implement
  }

  managerDataSource() {
    // Implement
  }

  terminatedEmployeeDataSource() {
    // Implement
  }
}

export default BrokerReportDataSource;
