import React from 'react';
import {FormattedMessage} from 'react-intl';

const PositiveSentiment = () => {
  return (
    <div className='col col-12 mt3 grass'>
      <i className='material-icons h3 align-text-top pr1'>{'thumb_up'}</i>
      <span className='h6 caps medium align-top'>
        <FormattedMessage id='recruiting.hiring_funnel.Positive'/>
      </span>
    </div>
  );
};

const NegativeSentiment = () => {
  return (
    <div className='col col-12 mt3 scarlet'>
      <i className='material-icons h3 align-text-top pr1'>{'thumb_down'}</i>
      <span className='h6 caps medium align-top'>
        <FormattedMessage id='recruiting.hiring_funnel.Negative'/>
      </span>
    </div>
  );
};

const FeedbackSentiment = ({isPositive}) => {
  return (
    <div>
      {isPositive ? <PositiveSentiment /> : <NegativeSentiment />}
    </div>
  );
};

export default FeedbackSentiment;
