import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import QuestionEmptyState from './QuestionEmptyState';

const OptionBar = observer(({percentage}) => {
  const answeredStyle = {
    'width': `${percentage}%`,
  };
  const unansweredStyle = {
    'width': `${100 - percentage}%`,
  };

  return (
    <div className='col col-11 pr2 flex user-select-none'>
      {!!percentage && <div className={`rounded-left bg-meadow ${percentage === 100 && 'rounded-right'}`} style={answeredStyle}>&nbsp;</div>}
      {percentage !== 100 && <div className={`rounded-right bg-submarine ${percentage === 0 && 'rounded-left'}`} style={unansweredStyle}>&nbsp;</div>}
    </div>
  );
});

const NonRecurringResults = observer(({currentTrend, uiState, question}) => {
  if (!currentTrend || !currentTrend.value.length) {
    return <QuestionEmptyState/>;
  }

  return (
    currentTrend.value.map((option, index) =>
      <div
        className={`pb2 ${!uiState.survey.anonymous && 'clickable'}`}
        key={index}
        onClick={() => uiState.openReviewersModal(question.id, option.label, null)}
      >
        <div className='pb1 h3'>
          {option.label}
        </div>
        <div className='clearfix align-middle flex align-items-center'>
          <OptionBar percentage={option.percentage}/>
          <div className='col col-1 bold'>
            {`${option.percentage}%`}
          </div>
        </div>
      </div>
    )
  );
});

const MultipleChoiceTrendsGraph = observer(({options, Highcharts, HighchartsReact}) => {
  if (!options) {
    return <QuestionEmptyState/>;
  }

  return <HighchartsReact highcharts={Highcharts} options={options}/>;
});

const MultipleChoiceSummary = observer(({questionSummary}) => {
  const {question, trendsOptions, currentTrend, uiState} = questionSummary;
  const {Highcharts, HighchartsReact, survey} = uiState;

  return (
    <Panel>
      <Panel.Header title={question.text} />
      {survey.isRecurring ?
        <MultipleChoiceTrendsGraph
          options={trendsOptions}
          Highcharts={Highcharts}
          HighchartsReact={HighchartsReact}
        /> :
        <NonRecurringResults
          currentTrend={currentTrend}
          uiState={uiState}
          question={question}
        />
      }
    </Panel>
  );
});

export default MultipleChoiceSummary;
