import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {LexicalEditor} from 'components';
import {AnnotatablePdfPreview} from 'components/pdf';
import {ModalButtons} from 'components/modals';

const RichTextEditor = observer(({uiState}) => {
  return (
    <div>
      <LexicalEditor
        placeholder={'company_settings.documents.Add template content'}
        label={'company_settings.documents.Company Document Content'}
        onChange={content => uiState.updateContent(content)}
        mergeFields={uiState.mergeFields}
        signatures={uiState.signatureList}
        initialEditorState={uiState.companyDocument.lexicalState}
        initialHTML={uiState.companyDocument.richText}
      />
      <ModalButtons onCancel={() => uiState.cancelCompanyDocumentEdit()} onSave={() => uiState.saveRichTextTemplate()}/>
    </div>
  );
});

const PDFTemplateEditor = observer(({uiState}) => {
  return (
    <div className='clearfix mt3'>
      <div className='col col-12'>
        <div className='h6 waterloo caps mb1'>
          <FormattedMessage id='company_settings.documents.Company Document Annotations'/>
        </div>
      </div>
      <div className='col col-12'>
        <AnnotatablePdfPreview
          pdfUrl={uiState.companyDocument.originalFile.link('url')}
          annotations={uiState.companyDocument.annotations}
          onSave={annotations => uiState.savePdfTemplate(annotations)}
          onCancel={() => uiState.goBack()}
        />
      </div>
    </div>
  );
});

const CompanyDocumentEditorFactory = observer(({uiState}) => {
  const {companyDocument} = uiState;

  switch (companyDocument.documentType) {
    case 'rich_text_template':
      return <RichTextEditor uiState={uiState}/>;
    case 'pdf_template':
      return <PDFTemplateEditor uiState={uiState}/>;
    case 'static_document':
      return <ModalButtons onCancel={() => uiState.cancelCompanyDocumentEdit()}
                           onSave={() => uiState.saveCompanyDocument()}/>;
    default:
      throw new Error(`Unsupported document type of ${companyDocument.documentType}`);
  }
});

export default CompanyDocumentEditorFactory;
