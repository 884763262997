import {DomainObject, oneOf} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {fullName} from 'shared/tools';
import _ from 'lodash';
import accentFold from './accentFold';

class Suggestion extends DomainObject {
  @observable id;
  @observable suggestionType;
  @observable args;
  @observable searchableCaption;
  @observable keywords = [];
  @observable weight = 0;

  @action merge(other) {
    super.merge(other, {
      suggestionType: oneOf([
        'employee',
        'candidate',
        'navigation'
      ])
    });

    if (this.args.weight) {
      this.weight = this.args.weight;
    }

    this.searchableCaption = this.getSearchableCaption();
  }

  getSearchableCaption() {
    return accentFold((
      this.keywords.join('')
      + this.caption
      + (this.detail || '')
    ).toLowerCase().replace(/ /g, ''));
  }

  @computed get link() {
    switch (this.suggestionType) {
      case 'employee':
        return `/employees/${this.args.employeeId}`;
      case 'candidate':
        return `/recruiting/positions/${this.args.positionId}/funnel/${this.args.candidateId}/${_.kebabCase(fullName(this.args))}`;
      case 'navigation':
        return this.args.url;
      default:
        throw new Error(`Suggestion type ${this.suggestionType} is not supported.`);
    }
  }

  @computed get caption() {
    switch (this.suggestionType) {
      case 'employee':
      case 'candidate':
        return fullName(this.args);
      case 'navigation':
        return this.args.caption;
      default:
        throw new Error(`Suggestion type ${this.suggestionType} is not supported.`);
    }
  }

  @computed get detail() {
    switch (this.suggestionType) {
      case 'employee':
        return this.args.jobTitle;
      case 'candidate':
        return this.args.positionName;
      case 'navigation':
        return this.args.detail;
      default:
        throw new Error(`Suggestion type ${this.suggestionType} is not supported.`);
    }
  }
}

export default Suggestion;
