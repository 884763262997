import React from 'react';
import {observer} from 'mobx-react';
import {QuickTip, ContactUsLink} from 'components';
import {FormattedMessage} from 'react-intl';
import {calendarDate} from 'shared/tools';

const HelpArticleLink = () => {
  return (
    <a target='_blank' href='http://support.collage.co/time-off/updating-a-time-off-policy'>
      <FormattedMessage id='time_off.policy.edit.confirmation.Read more'/>
    </a>
  );
};

const Explanation = observer(({uiState}) => {
  if (uiState.confirmationSummary.accrualStart === 'policy_holder_start_date') {
    let paragraphs = [
      <FormattedMessage id='time_off.policy.edit.confirmation.POLICY_HOLDER_START_DATE_EXPLANATION_2' values={{contactUs: <ContactUsLink/>}}/>
    ];
    if (uiState.confirmationSummary.accrualFrequency === 'annual') {
      paragraphs = [
        <FormattedMessage id='time_off.policy.edit.confirmation.POLICY_HOLDER_START_DATE_EXPLANATION_1'/>,
        ...paragraphs
      ];
    }
    return (
      <QuickTip
        header={<FormattedMessage id='time_off.policy.edit.confirmation.POLICY_HOLDER_START_DATE_HEADER'/>}
        trait='caution'
        paragraphs={paragraphs}
      />
    );
  }

  if (!uiState.selectedOption) return null;
  const date = <span className='medium'>{calendarDate(uiState.effectiveDate)}</span>;

  switch (uiState.selectedOption.type) {
    case 'policyStartDate':
      return (
        <QuickTip
          header={<FormattedMessage id='time_off.policy.edit.confirmation.POLICY_START_DATE_HEADER' values={{date}}/>}
          trait='caution'
          paragraphs={[
            <FormattedMessage id='time_off.policy.edit.confirmation.POLICY_START_DATE_EXPLANATION_1'/>,
            <ul className='pl2'>
              <li><FormattedMessage id='time_off.policy.edit.confirmation.POLICY_START_DATE_EXPLANATION_2' values={{date}}/></li>
              <li><FormattedMessage id='time_off.policy.edit.confirmation.POLICY_START_DATE_EXPLANATION_3' values={{date}}/></li>
              <li><FormattedMessage id='time_off.policy.edit.confirmation.POLICY_START_DATE_EXPLANATION_4' values={{date}}/></li>
              <li><FormattedMessage id='time_off.policy.edit.confirmation.POLICY_START_DATE_EXPLANATION_5' values={{
                date,
                link: <HelpArticleLink/>
              }}/></li>
            </ul>
          ]}
        />
      );
    case 'nextAccrualPeriod':
      return (
        <QuickTip
          trait='caution'
          header={<FormattedMessage id='time_off.policy.edit.confirmation.NEXT_ACCRUAL_PERIOD_HEADER' values={{date}}/>}
          paragraphs={[
            <FormattedMessage id='time_off.policy.edit.confirmation.NEXT_ACCRUAL_PERIOD_EXPLANATION_1' values={{date}}/>,
            <FormattedMessage id='time_off.policy.edit.confirmation.NEXT_ACCRUAL_PERIOD_EXPLANATION_2' values={{
              date,
              link: <HelpArticleLink/>
            }}/>
          ]}
        />
      );
    default:
      return null;
  }
});

export default Explanation;
