import React from 'react';
import {observer} from 'mobx-react';
import {Input, FormLayout, Panel} from 'components';
import {t} from 'shared/core';

 const Name = observer(({uiState}) => {
  const {survey, errors} = uiState;

   return (
    <div>
      <Panel.Header title={t('surveys.edit.steps.General')}/>
      <FormLayout>
        <Input value={survey.name}
          label='surveys.edit.Survey Name'
          helpMessage='surveys.edit.Choose a name that accurately represents the focus of the survey.'
          onChange={e => survey.merge({name: e.target.value})}
          errorMessage={errors.name}
        />
      </FormLayout>
    </div>
  );
});

 export default Name;
