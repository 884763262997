import {DomainObject} from 'shared/store';
import {computed, observable, action} from 'mobx';
import PerformanceReviewCycle from './PerformanceReviewCycle';
import PerformanceReviewType from './PerformanceReviewType';
import PerformanceReviewReviewee from './PerformanceReviewReviewee';
import PerformanceReviewAnswer from './PerformanceReviewAnswer';
import Employee from 'stores/employees/Employee';
import _ from 'lodash';
import PerformanceReviewNote from 'stores/performance_reviews/PerformanceReviewNote';
import {auth} from 'shared/core';

class PerformanceReviewReview extends DomainObject {
  @observable id;
  @observable cycle;
  @observable type;
  @observable reviewee;
  @observable reviewer;
  @observable answers = [];
  @observable submitted;
  @observable submittedAt;
  @observable notes = [];

  @action merge(other) {
    super.merge(other, {
      cycle: PerformanceReviewCycle,
      type: PerformanceReviewType,
      reviewer: Employee,
      reviewee: PerformanceReviewReviewee,
      answers: [PerformanceReviewAnswer],
      notes: [PerformanceReviewNote]
    });
  }

  @computed get readOnly() {
    return !this.hasLink('submit');
  }

  @computed get canUnsubmit() {
    return this.hasLink('unsubmit');
  }

  @computed get isManagerReview() {
    return this.type.kind === 'manager';
  }

  @computed get sortedAnswers() {
    return _.orderBy(this.answers, ['question.order'], ['asc']);
  }

  @action regenerateAnswersForQuestions(questions) {
    let newAnswers = [];
    let currentAnswers = _.keyBy(this.answers, 'question.id');

    _.forEach(questions, q => {
      let answer = new PerformanceReviewAnswer({question: q});
      if (currentAnswers[q.id] !== undefined) {
        answer.value = currentAnswers[q.id].value;
      }
      newAnswers.push(answer);
    });

    this.answers = newAnswers;
  }

  @computed get currentUserIsReviewee() {
    return auth.employee.id === this.reviewee.employee.id;
  }
}

export default PerformanceReviewReview;
