import React from 'react';
import {Button} from 'components';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {Modal} from 'components/modals';
import CircleIcon from 'containers/performance/img/circle-checkmark--blue@2x.png';
import {observer} from 'mobx-react';

const PublishPositionModal = observer(({uiState}) => {
  const {publishedPosition = {}} = uiState;

  return (
    <Modal isOpen={uiState.publishModalOpen} onHide={() => uiState.closePublishModal()} size='md' closeButton={false}>
      <div className='py3 px4 mx-auto center'>
        <img src={CircleIcon} alt='checkmark' width='36px'/>
        <div className='h2 my2 medium'>
          <FormattedMessage id='recruiting.directory.Job Position Created!'/>
        </div>
        <div className='h3 my2 jumbo'>
          <FormattedMessage id="recruiting.directory.Here's a link to view the job posting. Copy and paste it to job boards to get some exposure!"/>
        </div>
        <div className="h5 mt3 mb1 jumbo">
          <FormattedMessage id='recruiting.directory.Job position link:'/>
        </div>
        {publishedPosition.position && publishedPosition.position.hasLink('posting') ?
          <a href={publishedPosition.position.viewPostingLink} target='_blank' className='dodger mb3 h4'>{publishedPosition.position.viewPostingLink}</a>
          : <Link to='/job-site' className='waterloo'><FormattedMessage id='recruiting.directory.Configure job site URL to view job listings'/></Link>
        }
        <div className='max-width-1 mx-auto px4 mt4'>
          <Button className='full-width' size='lg' onClick={() => uiState.closePublishModal()}>
            <FormattedMessage id='recruiting.directory.Got it'/>
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export default PublishPositionModal;
