import React from 'react';
import {observer} from 'mobx-react';
import {Table} from 'components';
import {withState, auth} from 'shared/core';
import _ from 'lodash';
import TimeOffPolicySelectorState from './TimeOffPolicySelectorState';
import EnrolInPolicyModal from './EnrolInPolicyModal';
import RemoveFromPolicyModal from './RemoveFromPolicyModal';

const COLUMNS = [
  {
    attribute: 'name',
    width: 11,
    header: 'Time Off Policy'
  }
];

const LOCALIZATION = {
  addModel: 'employees.profile.time_off.Enrol in a Time Off Policy',
  emptyState: 'employees.profile.time_off.Not enrolled in a time off policy'
};

const TimeOffPolicySelector = observer(({uiState}) => {
  if (!auth.hasAccess('::MANAGE_TIME_OFF')) return null;

  return (
    <div className='mt2'>
      <Table
        models={uiState.employeePolicies}
        indexBy='name'
        columns={COLUMNS}
        customLinks={uiState.actionLinks}
        localization={LOCALIZATION}
        onAdd={uiState.showEnrolLink ? () => uiState.openEnrolInPolicyModal() : null}
      />
      <RemoveFromPolicyModal uiState={uiState}/>
      <EnrolInPolicyModal uiState={uiState}/>
    </div>
  );
});

export default withState(TimeOffPolicySelector, TimeOffPolicySelectorState);
