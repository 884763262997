import {observable, action, computed} from 'mobx';
import {auth, types, endpoints} from 'shared/core';
import EmployeeDetails from 'stores/employees/EmployeeDetails';
import {DomainStore} from 'shared/store';
import _ from 'lodash';
import {redirect} from 'shared/tools';

class OnboardBankingState {
  store = new DomainStore();

  @observable employee;
  @observable bankingChequeHelperOpen = false;
  @observable errors = {};

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEE_DETAILS.with(auth.employee.id)
    ]);

    this.employee = new EmployeeDetails(
      this.store._getSingle(types.EMPLOYEE_DETAILS, {id: auth.employee.id})
    );
  }

  @action openBankingChequeHelperModal() {
    this.bankingChequeHelperOpen = true;
  }

  @action resetModals() {
    this.bankingChequeHelperOpen = false;
  }

  @action async nextStep() {
    const {model, errors} = await this.store.patch(
      endpoints.EMPLOYEE_ONBOARD.BANKING_INFO.with(auth.employee.id),
      types.EMPLOYEE_DETAILS,
      this.employee
    );
    this.errors = errors;

    if (model) {
      return redirect('/onboard');
    }
  }

  @action removeFile() {
    this.employee.bankAccount.cheque = null;
  }

  @action mergeFile(file) {
    this.employee.bankAccount.merge({cheque: file});
  }

  @action updateFile(files) {
    const file = _.head(files);
    file ? this.mergeFile(file) : this.removeFile();
  }

  @computed get isTemporaryResident() {
    return _.startsWith(this.employee.sin, '9');
  }

  @computed get sinExpiryDate() {
    return this.isTemporaryResident ? this.employee.sinExpiryDate : '';
  }
}

export default OnboardBankingState;
