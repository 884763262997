import React from 'react';
import {observer} from 'mobx-react';
import {InteractiveTable} from 'components';
import {CompanyContact} from 'stores/pa_dashboard';
import {types, endpoints, t} from 'shared/core';
import CompanyContactItem from './CompanyContactItem';

const COLUMNS = [
  {
    component: CompanyContactItem,
    width: 12
  }
];

const KeyContacts = observer(() => {
  return (
    <InteractiveTable
      title={t('pa_dashboard.Key Contacts')}
      columns={COLUMNS}
      showLinks={false}
      searchable={false}
      showHeaders={false}
      hidePaginationInfo
      proxy={{
        type: 'async',
        modelType: types.PA_DASHBOARD.COMPANY_CONTACT,
        model: CompanyContact,
        endpoint: endpoints.PA_DASHBOARD.COMPANY_CONTACTS
      }}
    />
  );
});

export default KeyContacts;
