import {DomainObject} from 'shared/store';
import {computed, observable, action} from 'mobx';
import {User} from 'stores/users';
import moment from 'moment';

class Note extends DomainObject {
  @observable content = '';
  @observable createdAt;
  @observable updatedAt;

  @action merge(other) {
    super.merge(other, {
      _dates: ['createdAt', 'updatedAt'],
      author: User
    });
  }

  @computed get createdTimeAgo() {
    return moment(this.createdAt).fromNow();
  }

  @computed get updatedTimeAgo() {
    return moment(this.updatedAt).fromNow();
  }

  @computed get hasBeenUpdated() {
    return !moment(this.updatedAt).isSame(this.createdAt);
  }
}

export default Note;
