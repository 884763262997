import React from 'react';
import {CompanyBenefitsUploader, ProcessingScreen} from './company';
import {BrokerSignupForm, BrokerMergeAccount} from './broker';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {auth} from 'shared/core';
import {AbsoluteRedirect} from 'components';

const BrokerPortalSignupPage = () => {
  return (
    <Router basename='/advisor'>
      <Switch>
        {!auth.user.id && <Route path='/signup/:benefitsBroker/:token' component={BrokerSignupForm}/>}
        {auth.user.id && <Route path='/signup/:benefitsBroker/:token' component={BrokerMergeAccount}/>}
        {auth.user.id && <Route path='/benefits_data' component={CompanyBenefitsUploader}/>}
        {auth.user.id && <Route path='/processing' component={ProcessingScreen}/>}
        {auth.user.id && <Redirect from='/' to='/benefits_data'/>}
        <AbsoluteRedirect/>
      </Switch>
    </Router>
  );
};

export default BrokerPortalSignupPage;
