/* eslint-disable */
import times from 'lodash/times';
import { WIDTH as NODE_WIDTH, HEIGHT as NODE_HEIGHT, BORDER_RADIUS, BORDER_COLOR } from './node';
export default class Leaf {
    constructor(employees, parentRow) {
        // parentRow: Row;
        this.size = null; // [width, height]
        this.coords = [0, 0];
        // lines: [[number, number], [number, number]][] = [];
        this.nodesToRender = [];
        this.borderPath = '';
        this.employees = employees;
        // this.parentRow = parentRow;
    }
    calculateSize() {
        const m = this.matrix;
        this.size = [NODE_WIDTH * m[1], NODE_HEIGHT * m[0]];
    }
    // returns an array of rows by columns
    get matrix() {
        const len = this.employees.length;
        const MAX_COLUMNS = 4;
        const sr = Math.floor(Math.sqrt(len));
        const columns = Math.min(sr, MAX_COLUMNS);
        return [
            Math.ceil(len / columns),
            columns
        ];
    }
    setCoords(coords) {
        this.coords = coords;
        let x = coords[0];
        let y = coords[1];
        const maxCol = this.matrix[1];
        this.nodesToRender = this.employees.map((e, i) => {
            const rowIndex = Math.floor(i / maxCol);
            const colIndex = i % maxCol;
            return Object.assign({
                x: x + colIndex * NODE_WIDTH,
                y: y + rowIndex * NODE_HEIGHT,
            }, e);
        });
        this.setBorderPath();
    }
    setBorderPath() {
        /*
            responsible for drawing border around all employees:
            since designs contain rounded rectangles, adding a separate
            border for each employee node doesn't look great, so instead
            leaf takes care of drawing border over all nodes
        */
        const x = this.coords[0];
        const y = this.coords[1];
        const m = this.matrix;
        const rows = m[0];
        const cols = m[1];
        const BR2 = BORDER_RADIUS * 2;
        // top left to bottom left
        const y0 = y + BORDER_RADIUS;
        let p = `M ${x} ${y0}
			l 0 ${rows * NODE_HEIGHT - BR2}
			a ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS}
		`;
        // bottom left to right top
        const leftover = this.nodesToRender.length % cols;
        if (leftover) {
            const w = (cols - leftover) * NODE_WIDTH - BR2;
            const remainingW = leftover * NODE_WIDTH - BORDER_RADIUS;
            const remainingH = (rows - 1) * NODE_HEIGHT - BR2;
            p += `l ${w} 0
				a ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0 0 ${BORDER_RADIUS} ${-BORDER_RADIUS}
				l 0 -${NODE_HEIGHT - BORDER_RADIUS}
				l ${remainingW} 0
				a ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0 0 ${BORDER_RADIUS} ${-BORDER_RADIUS}
				l 0 ${-remainingH}
			`;
        }
        else {
            const w = cols * NODE_WIDTH - BR2;
            const h = rows * NODE_HEIGHT - BR2;
            // can definitely clean up code for less repitition, but verbosity
            // i think helps make things more clear
            p += `l ${w} 0
				a ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0 0 ${BORDER_RADIUS} ${-BORDER_RADIUS}
				l 0 ${-h}
			`;
        }
        // top right to top left to close rect
        const topW = cols * NODE_WIDTH - BR2;
        p += `a ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0 0 ${-BORDER_RADIUS} ${-BORDER_RADIUS}
			l ${-topW} 0
			a ${BORDER_RADIUS} ${BORDER_RADIUS} 0 0 0 ${-BORDER_RADIUS} ${BORDER_RADIUS}
		`;
        // lines separating nodes
        times(cols - 1, i => {
            const m1 = x + (i + 1) * NODE_WIDTH;
            const delta = rows * NODE_HEIGHT;
            p += `M ${m1} ${y}
				l 0 ${delta}
			`;
        });
        const maxRowLines = rows - 1;
        times(maxRowLines, i => {
            const m2 = y + (i + 1) * NODE_HEIGHT;
            const delta = (i + 1) === maxRowLines && leftover ?
                // fix for line going outside border if there are leftovers
                (cols - leftover) * NODE_WIDTH
                :
                    cols * NODE_WIDTH;
            p += `M ${x} ${m2}
				l ${delta} 0
			`;
        });
        this.borderPath = p;
    }
    render(doc, drawNode) {
        this.nodesToRender.forEach(node => {
            drawNode(node);
        });
        doc.path(this.borderPath)
            .lineWidth(1)
            .stroke(BORDER_COLOR);
    }
}
