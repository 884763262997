import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import {t} from 'shared/core';
import {
  EmployeeSelector,
  FormError,
  LabelHelper,
  FormLayout,
  Input,
  Button,
  LexicalEditor
} from 'components';
import {ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const DescriptionHTML = observer(({uiState}) => {
  const {editingAnnouncement, errors} = uiState;

  return (
    <LexicalEditor
      label={'hr_dashboard.feed.announcements.Description'}
      onChange={description => uiState.updateDescription(description)}
      initialEditorState={editingAnnouncement.lexicalState}
      initialHTML={editingAnnouncement.description}
      errorMessage={errors.description}
    />
  );
});

const ImageUpload = observer(({uiState}) => {
  const {editingAnnouncement} = uiState;

  return (
    <div>
      <LabelHelper label={t('hr_dashboard.feed.announcements.Image')} />
      {editingAnnouncement.image && <img
        className='max-width-full'
        src={editingAnnouncement.imageUrl}
        alt={t('hr_dashboard.feed.announcements.Image')}
      />}
      <div>
        <Button className='mt2 mr2' trait='default' size='sm' onClick={() => uiState.openFileStack()}>
          <FormattedMessage id='hr_dashboard.feed.announcements.Upload New Image'/>
        </Button>
        {editingAnnouncement.image && <Button className='mt2' trait='default' size='sm' onClick={() => uiState.clearImage()}>
          <FormattedMessage id='hr_dashboard.feed.announcements.Clear Image'/>
        </Button>}
      </div>
    </div>
  );
});

const EmployeeVisibility = observer(({uiState}) => {
  const {editingAnnouncement, errors, selectedEmployees} = uiState;

  if (!editingAnnouncement.isNew) return null;

  return (
    <div>
      <LabelHelper label={t('hr_dashboard.feed.announcements.Visibility')} />
      <EmployeeSelector
        selectedEmployees={selectedEmployees}
        onChange={employees => uiState.updateSelectedEmployees(employees)}
      />
      <FormError message={errors.employees}/>
    </div>
  );
});

const AnnouncementEditContainer = observer(({uiState}) => {
  const {editingAnnouncement, errors} = uiState;

  return (
    <div>
      <FormLayout>
        <Input
          value={editingAnnouncement.name}
          errorMessage={errors.name}
          onChange={e => editingAnnouncement.merge({name: e.target.value})}
          label='hr_dashboard.feed.announcements.Title' />
        <DescriptionHTML uiState={uiState}/>
        <ImageUpload uiState={uiState}/>
        <EmployeeVisibility uiState={uiState}/>
      </FormLayout>
      <ModalButtons
        onSave={() => uiState.saveAnnouncement()}
        onCancel={() => uiState.closeEditAnnouncementModal()}
        saveCaption={editingAnnouncement.isNew ?
          'hr_dashboard.feed.announcements.Publish' :
          'hr_dashboard.feed.announcements.Update'}
      />
    </div>
  );
});

const EditAnnouncementModal = observer(({uiState}) => {
  if (!uiState.editAnnouncementModalOpen) return null;

  const {editingAnnouncement} = uiState;

  return (
    <Modal
      size='md'
      isOpen={uiState.editAnnouncementModalOpen}
      title={editingAnnouncement.isNew ?
        'hr_dashboard.feed.announcements.Create New Announcement' :
        'hr_dashboard.feed.announcements.Edit Announcement'}
      closeButton={false}
    >
      <AnnouncementEditContainer uiState={uiState}/>
    </Modal>
  );
});

export default EditAnnouncementModal;
