import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormattedMessage} from 'react-intl';

const CancelPayrollModal = observer(({uiState}) => {
  return (
    <Modal
      size='md'
      isOpen={uiState.cancelPayrollModalOpen}
      onHide={() => uiState.closeCancelPayrollModal()}
      title='company_settings.billing.cancel_subscription.cancel_payroll_modal.Cancel Payroll'
    >
      <div className='pb2'>
        <FormattedMessage id="company_settings.billing.cancel_subscription.cancel_payroll_modal.Before we can cancel your Collage subscription, we have to cancel your subscription to Collage's payroll service."/>
      </div>
      <div className='pb2'>
        <FormattedMessage id='company_settings.billing.cancel_subscription.cancel_payroll_modal.After your payroll subscription is cancelled, you can cancel your subscription to Collage by returning to this page.'/>
      </div>
      <div>
        <FormattedMessage id='company_settings.billing.cancel_subscription.cancel_payroll_modal.Click the button below and a member of our payroll team will be in touch with you shortly.'/>
      </div>
      <ModalButtons
        onSave={() => uiState.notifyPayroll()}
        saveCaption='company_settings.billing.cancel_subscription.cancel_payroll_modal.Request to Cancel Payroll'
        trait='destructive'
        onCancel={() => uiState.closeCancelPayrollModal()}
      />
    </Modal>
  );
});

export default CancelPayrollModal;
