import InequalityFilterRow from './InequalityFilterRow';
import {action} from 'mobx';
import moment from 'moment';

class DateInequalityFilterRow extends InequalityFilterRow {
  @action parseValue(value) {
    return moment(value).toDate();
  }

  toJS() {
    const json = super.toJS()[0];
    let formattedValues;

    if (this.operator === 'between') {
      formattedValues = {
        lower_bound: moment(this.range.lower_bound).format('YYYY-MM-DD'),
        upper_bound: moment(this.range.upper_bound).format('YYYY-MM-DD')
      };
    } else {
      formattedValues = moment(this.value).format('YYYY-MM-DD');
    }

    return [{
      attribute: json.attribute,
      operator: json.operator,
      values: formattedValues
    }];
  }
}

export default DateInequalityFilterRow;
