import React from 'react';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import ErrorIcon from 'img/error-field@2x.png';

const AutosaveStatus = observer(({autosaver, className}) => {
  if (!autosaver || !autosaver.status) return null;

  return (
    <span className={`${autosaver.status === 'error' ? 'scarlet' : 'waterloo'} ${className}`}>
      {autosaver.status === 'error' &&
        <img src={ErrorIcon} alt='error' height='16' style={{margin: '0px 4px 3px 0'}}/>
      }
      <FormattedMessage id={`components.autosave.status.${autosaver.status}`}/>
    </span>
  );
});

AutosaveStatus.propTypes = {
  autosaver: PropTypes.object
};

export default AutosaveStatus;
