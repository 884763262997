import {DomainObject} from 'shared/store';
import {action, observable, computed} from 'mobx';
import CompanyInsuranceCarrier from './CompanyInsuranceCarrier';
import _ from 'lodash';

class BenefitDivision extends DomainObject {
  @observable name;
  @observable companyInsuranceCarrier;

  @action merge(other) {
    super.merge(other, {
      companyInsuranceCarrier: CompanyInsuranceCarrier
    });
  }

  @computed get displayName() {
    return `${this.companyInsuranceCarrier.name} - ${this.name}`;
  }
}

export default BenefitDivision;
