import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {GoalTableState} from '../state';
import GoalView from './GoalView';
import _ from 'lodash';

const GoalTableOverlay = observer(({isUpdating = false}) => {
  if (!isUpdating) return null;

  return (
    <div className='AccordionTableOverlay'>
    </div>
  );
});

const GoalTableHeaders = observer(({uiState}) => {
  const getSortingClass = (header) => {
    if (!header.sortingAllowed) return '';
    switch (header.sort) {
      case 'asc': return 'sorting_asc';
      case 'desc': return 'sorting_desc';
      default: return 'sorting';
    }
  };

  return (
    <tr className='AccordionTableHeaders'>
      {uiState.headers.map(header => (
        <th
          key={header.key}
          className={`${header.key}-column ${getSortingClass(header)} ${!!header.sortingAllowed ? 'clickable' : ''}`}
          onClick={() => uiState.clickHeader(header)}
        >
          <FormattedMessage id={header.label}/>
        </th>
      ))}
    </tr>
  );
});

const GoalTable = observer(({models, isUpdating, uiState}) => {
  const {parentState} = uiState;

  if (_.isEmpty(models)) {
    return (
      <div className='center jumbo rounded h5 py2 px1 Table-emptyState'>
        <FormattedMessage id={'goals.No goals to display'}/>
      </div>
    );
  }

  return (
    <div className='AccordionTable'>
      <GoalTableOverlay isUpdating={isUpdating}/>
      <GoalTableHeaders uiState={uiState}/>
      {models.map(goalViewModel => <div className='AccordionTableView'>
        <GoalView model={goalViewModel} uiState={parentState}/>
      </div>)}
    </div>
  );
});

export default withState(GoalTable, GoalTableState);
