import React from 'react';
import {observer} from 'mobx-react';
import {CompanyLogo, EditableCompanyLogo} from 'components';
import {auth} from 'shared/core';
import _ from 'lodash';

const CompanyLogoSection = observer(({uiState}) => {
  const {company} = uiState;

  return (
    <div className={!company.logoUrl ? 'mb1' : null}>
      {auth.user.admin ?
        <EditableCompanyLogo onClick={() => window.open('/company_settings/general', '_blank')} company={company} /> :
        <CompanyLogo preview={true} company={company}/>}
    </div>
  );
});

export default CompanyLogoSection;
