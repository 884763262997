import React from 'react';
import {t, auth} from 'shared/core';
import {Display, HtmlRender, Button, Panel, Layout, FormError} from 'components';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import OfferAttachmentIcon from 'img/offer-attachment@2x.png';

const OfferHeader = ({jobOffer, logoUrl, isPreview}) => {
  const companyName = isPreview ? auth.unescapedCompanyName : jobOffer.companyName;

  if (logoUrl) {
    return <img alt='company logo' className='align-top CompanyLogo-img' src={logoUrl}/>;
  }

  return companyName;
};

const AcceptButton = ({jobOffer, onAcceptOffer, isPreview}) => {
  if (isPreview) {
    return (
      <Button disabled onClick={() => {}}>
        <FormattedMessage id='components.offers.I Accept'/>
      </Button>
    );
  }

  return (
    <Button onClick={() => onAcceptOffer()} disabled={!jobOffer.acceptable}>
      <FormattedMessage id='components.offers.I Accept'/>
    </Button>
  );
};

const OfferAttachments = ({attachments}) => {
  return (
    <div>
      {attachments.map(attachment =>
        <div key={attachment.id} className='mb1'>
          <a href={attachment.hasLink('url') ? attachment.link('url') : attachment.previewUrl} target='_blank'>
            <span className='mr1'>
              <img src={OfferAttachmentIcon} height='13' width='13' alt='document'/>
            </span>
            <span className='align-middle'>
              {attachment.fileName}
            </span>
          </a>
        </div>
      )}
    </div>
  );
};

const Offer = observer(({jobOffer, logoUrl, onAcceptOffer, isPreview, errors}) => {
  return (
    <div>
      <Panel className='offer-header'>
        <div className='center'>
          <Display
            documentTitle={t('components.offers.Review job offer')}
          />
          <div className='h1 medium py1'>
            <OfferHeader jobOffer={jobOffer} logoUrl={logoUrl} isPreview={isPreview}/>
          </div>
        </div>
        <hr className='center bg-waterloo' style={{'borderTop': '1px solid', width: '10rem'}}/>
        <div className='center mb2'><FormattedMessage id='components.offers.We are pleased to present you with an offer!'/></div>
        <div className='center'><FormattedMessage id='components.offers.If you choose to accept, please indicate using the button at the bottom of the document.'/></div>
      </Panel>
      <Layout>
        <Panel className='my3'>
          <div className={`ml3 my3 scrollable pr2 ${isPreview && 'preview'}`}>
            <HtmlRender html={jobOffer.renderedContent}/>
          </div>
        </Panel>
        <div className='left pb2'>
          {jobOffer.attachments.length > 0 && <div className='flex md-flex-row full-width'>
            <div>
              <div className='medium mb1'>
                <FormattedMessage id='components.offers.Offer Attachments'/>
              </div>
              <OfferAttachments attachments={jobOffer.attachments}/>
            </div>
          </div>}
        </div>
        <div className='right pb3'>
          <div className='flex md-flex-row full-width justify-content-end'>
            {jobOffer.expiresAtDate && <span className='mr2 right-align'>
              <div className='medium'>
                <FormattedMessage id='components.offers.Offer expires at:'/>
              </div>
              <div>{jobOffer.expiresAtDate}</div>
            </span>}
            <span>
              <AcceptButton jobOffer={jobOffer} isPreview={isPreview} onAcceptOffer={onAcceptOffer}/>
            </span>
          </div>
          {errors && <div style={{'minWidth': '217px'}}>
            <FormError message={errors.base}/>
          </div>}
        </div>
      </Layout>
    </div>
  );
});

export default Offer;
