import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {t} from 'shared/core';
import {Panel} from 'components';
import {BenefitEnrolmentForCarrier} from 'components/benefits';

const BenefitsPlanSection = observer(({uiState}) => {
  const {enrollmentViewModels, errors} = uiState;

  return (
    <Panel.Stack className='mt2'>
      <Panel>
        <Panel.Header
          title={t('benefit_enrolment.enrol.Benefits Plan')}
          description={t('benefit_enrolment.enrol.enrol_description')}
        />
      </Panel>
      {enrollmentViewModels.map((viewModel, index) =>
        <Panel key={viewModel.record.companyInsuranceCarrier.id}>
          <BenefitEnrolmentForCarrier
            key={viewModel.record.companyInsuranceCarrier.id}
            viewModel={viewModel}
            enrolmentErrors={_.get(errors, `employeeBenefitEnrollments[${index}]`)}
            insuranceNumberErrors={_.get(errors, `employeeInsuranceNumbers[${viewModel.insuranceNumberIndex}]`)}
            showEnrolmentDate={false}
          />
        </Panel>
      )}
    </Panel.Stack>
  );
});

export default BenefitsPlanSection;
