import React from 'react';
import {observer} from 'mobx-react';
import {endpoints, types, withState, t} from 'shared/core';
import {Layout, InteractiveTable, Tag} from 'components';
import {FormattedMessage} from 'react-intl';
import EmployeeGroupsDirectoryState from '../state/EmployeeGroupsDirectoryState';
import {EmployeeGroup} from 'stores/employee_groups';
import SubNav from 'company_settings/SubNav';
import EditEmployeeGroupModal from './EditEmployeeGroupModal';

const EmployeeGroupName = observer(({model}) => {
  return (
    <div className='flex'>
      <div className='mr1'>{model.name}</div>
      {model.default && <span className='pr1'>
        <Tag colour='waterloo'>
          <FormattedMessage id='company_settings.employee_groups.Default'/>
        </Tag>
      </span>}
      {model.readOnly && <Tag colour='meadow'>
        <FormattedMessage id='company_settings.employee_groups.In Use'/>
      </Tag>}
    </div>
  );
});

const COLUMNS = [
  {
    header: 'company_settings.employee_groups.Name',
    component: EmployeeGroupName,
    width: 11
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'company_settings.employee_groups.Remove Employee Group',
    subHeader: 'company_settings.employee_groups.Are you sure you want to remove this employee group?',
    body: 'company_settings.employee_groups.You will no longer be able to assign this group to any custom fields.'
  },
  interactive: {
    addModel: 'company_settings.employee_groups.+ Add Employee Group',
  },
  emptyState: 'company_settings.employee_groups.No employee groups to display'
};

const EmployeeGroupsContainer = observer(({uiState}) => {
  return (
    <div>
      <SubNav title={t('company_settings.subnav.Employee Groups')}/>
      <Layout>
        <InteractiveTable
          title={t('company_settings.employee_groups.Employee Groups')}
          uiState={uiState}
          searchable={false}
          columns={COLUMNS}
          localization={LOCALIZATION}
          onMount={agent => uiState.setInteractiveAgent(agent)}
          onAdd={() => uiState.openAddEmployeeGroupModal()}
          onEdit={model => uiState.openEditEmployeeGroupModal(model)}
          onRemove={model => uiState.destroyEmployeeGroup(model)}
          proxy={{
            type: 'async',
            modelType: types.EMPLOYEE_GROUP,
            model: EmployeeGroup,
            endpoint: endpoints.EMPLOYEE_GROUPS
          }}
        />
        <EditEmployeeGroupModal uiState={uiState}/>
      </Layout>
    </div>
  );
});

export default withState(EmployeeGroupsContainer, EmployeeGroupsDirectoryState);
