import React from 'react';
import {observer} from 'mobx-react';
import {Layout, InteractiveTable, DropdownFilter, EmployeeNameWithAvatar} from 'components';
import {endpoints, types} from 'shared/core';
import {EmployeeTrainingStatus} from 'stores/training';
import {EmployeeTrainingStatus as EmployeeTrainingStatusView} from 'training/components/EmployeeTrainingStatus';
import {LOCALIZATION, getFilterOptions} from 'training/tools';

const COLUMNS = [
  {
    header: 'employee',
    render: model => <EmployeeNameWithAvatar employee={model.employee}/>,
    width: 3,
    sortBy: 'name'
  },
  {
    header: 'training.directory.Training Program',
    attribute: 'programName',
    width: 4,
    sortBy: 'title'
  },
  {
    header: 'training.directory.Complete',
    render: model => <EmployeeTrainingStatusView completed={model.completed}/>,
    width: 1
  },
  {
    header: 'training.directory.Due Date',
    render: model => model.dueDateView,
    width: 2,
    sortBy: 'dueDate'
  },
  {
    header: 'training.directory.Completed Date',
    render: model => model.completedAtView,
    width: 2,
    sortBy: 'completedDate'
  }
];

const CompanyTrainingStatusesDirectory = observer(() => {
  return (
    <Layout>
      <InteractiveTable
        columns={COLUMNS}
        localization={LOCALIZATION}
        showLinks={false}
        saveStateToUrl={true}
        showRoute='/programs/:id'
        proxy={{
          type: 'async',
          endpoint: endpoints.TRAINING.EMPLOYEE_TRAINING_STATUSES.ALL,
          modelType: types.TRAINING.EMPLOYEE_TRAINING_STATUS_SUMMARY,
          model: EmployeeTrainingStatus
        }}
      >
        <DropdownFilter options={getFilterOptions()} attr='status'/>
      </InteractiveTable>
    </Layout>
  );
});

export default CompanyTrainingStatusesDirectory;
