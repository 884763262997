import _ from 'lodash';
import {Steps} from 'shared/tools';

const STEPS = [
  {
    name: 'benefits.benefit_info.edit.steps.Reason',
    location: 'reason',
  },
  {
    name: 'benefits.benefit_info.edit.steps.Spouse',
    location: 'spouse',
  },
  {
    name: 'benefits.benefit_info.edit.steps.Dependants',
    location: 'dependants',
  },
  {
    name: 'benefits.benefit_info.edit.steps.Coverage Details',
    location: 'coverage',
  },
  {
    name: 'benefits.benefit_info.edit.steps.Review',
    location: 'review',
  }
].map((s, i) => ({
  ...s,
  index: i + 1
}));

const singleton = new Steps(STEPS);

export default singleton;

