import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {rangeDate} from 'shared/tools';
import {Employee} from 'stores/employees';
import TrainingProgram from './TrainingProgram';
import Answer from './Answer';
import _ from 'lodash';

class EmployeeTrainingStatus extends DomainObject {
  @observable id;
  @observable employee;
  @observable program;
  @observable dueDate;
  @observable completedAt;
  @observable completed;

  @action merge(other) {
    super.merge(other, {
      employee: Employee,
      program: TrainingProgram,
      answers: [Answer],
      _dates: ['dueDate', 'completedAt']
    });
  }

  @computed get readOnly() {
    return !this.hasLink('complete');
  }

  @computed get dueDateView() {
    return rangeDate(this.dueDate);
  }

  @computed get completedAtView() {
    return rangeDate(this.completedAt);
  }

  @computed get quizScore() {
    if (!this.totalAnswerCount) return '--';

    return Math.round(this.correctAnswerCount / this.totalAnswerCount * 100);
  }

  @computed get quizResultsView() {
    if (!this.totalAnswerCount) return '--';

    return `${this.quizScore}% [${this.correctAnswerCount}/${this.totalAnswerCount}]`;
  }

  @action regenerateAnswersForQuestions(questions) {
    let newAnswers = [];
    let currentAnswers = _.keyBy(this.answers, 'question.id');

    _.forEach(questions, q => {
      let answer = new Answer({question: q});
      if (currentAnswers[q.id] !== undefined) {
        answer.value = currentAnswers[q.id].value;
      }
      newAnswers.push(answer);
    });

    this.answers = newAnswers;
  }
}

export default EmployeeTrainingStatus;
