import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const ReviewCycleName = observer(({cycle}) => {
  const {name} = cycle;

  if (!name) {
    return <FormattedMessage id='performance_reviews.Untitled performance review'/>;
  }

  return (
    <span>{name}</span>
  );
});

export default ReviewCycleName;
