import React from 'react';
import {observer} from 'mobx-react';
import {AvatarList} from 'components';

const ReviewerAvatars = observer(({model}) => {
  const reviewee = model;

  return (
    <AvatarList limit={4} moreModalEnabled employees={reviewee.selfAndPeerReviewers}/>
  );
});

export default ReviewerAvatars;
