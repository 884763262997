import {computed, observable} from 'mobx';
import _ from 'lodash';
import {QAQuestion} from '../questions';

class SurveyQuestion extends QAQuestion {
  @observable name;
  @observable required = true;

  @computed get commaSeparatedOptions() {
    return _.chain(this.options)
      .map('value')
      .join(', ')
      .value();
  }
}

export default SurveyQuestion;
