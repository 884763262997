import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {DependantFields} from 'components/benefits';
import {t} from 'shared/core';

const Dependents = observer(({uiState}) => {
  const {benefitInfo, errors} = uiState;

  return (
    <div>
      <Panel.Header title={t('benefits.benefit_info.edit.steps.Dependants')}/>
      <DependantFields benefitInfo={benefitInfo} errors={errors.updatedBenefitInfo}/>
    </div>
  );
});

export default Dependents;
