import _ from 'lodash';
import {action, observable} from 'mobx';
import Department from 'stores/departments/Department';
import {successAlert} from 'shared/tools';
import {t} from 'shared/core';

class DepartmentState {
  store;

  @observable departments;
  @observable editingDepartment = {};
  @observable errors = {};
  @observable editDepartmentModalOpen = false;

  constructor(store) {
    this.store = store;
  }

  @action async load() {
    this.departments = await this.store.loadDepartments();
  }

  @action async saveDepartment() {
    const {model, errors} = this.editingDepartment.isNew
      ? await this.store.postDepartment(this.editingDepartment)
      : await this.store.patch(this.editingDepartment);
    this.errors = errors;

    if (model) {
      await this.load();
      this.closeEditDepartmentModal();
      successAlert(t('company_settings.departments.alerts.Department information saved.'));
    }
  }

  @action closeEditDepartmentModal() {
    this.editDepartmentModalOpen = false;
  }

  @action openEditDepartmentModal(department) {
    this.editDepartmentModalOpen = true;
    this.editingDepartment = new Department(department);
  }

  @action addDepartment() {
    this.openEditDepartmentModal(new Department());
  }

  @action async removeDepartment(department) {
    await this.store.destroy(department);
    await this.load();
    successAlert(t('company_settings.departments.alerts.Department deleted successfully.'));
  }
}

export default DepartmentState;
