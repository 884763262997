import React from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import {auth, withState, endpoints, types} from 'shared/core';
import {Layout, Display, InteractiveTable, Button, CompanyAvatar, DropdownFilter} from 'components';
import {Modal} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import {ClientsContainerState} from '../state';
import {ClientCompany} from 'stores/client_companies';

const CompanyName = observer(({model}) => {
  return (
    <div>
      <div className='table-cell align-middle pr2'>
        <CompanyAvatar company={model}/>
      </div>
      <div className='table-cell align-middle'>
        <Link className='TextLink' to={model.link}>{model.name}</Link>
      </div>
    </div>
  );
});

const ImpersonationButton = observer(({uiState, model}) => {
  if (!model.allowsImpersonation) return null;

  return (
    <Button size='sm' trait='default' onClick={() => uiState.impersonateCompany(model)}>
      <FormattedMessage id='broker_dashboard.View'/>
    </Button>
  );
});

const Status = observer(({uiState, model}) => {
  if (!model.status) return null;

  if (model.status === 'live') {
    return <FormattedMessage id={`broker_dashboard.status.live.name`}/>;
  }

  return (
    <a className='TextLink word-break' onClick={() => uiState.onStatusClicked(model)}>
      <FormattedMessage id={`broker_dashboard.status.${model.status}.name`}/>
    </a>
  );
});

const StatusModal = observer(({uiState}) => {
  const {indexOfCurrentStatus} = uiState;
  return (
    <Modal size='md' isOpen={uiState.modalOpen} closeButton={false} onHide={() => uiState.closeModal()}>
      <div className='h1 mb2'>{uiState.selectedItem.name}</div>
      <div className='mt3 mb2 h3 medium'><FormattedMessage id='broker_dashboard.Onboard Progress:'/></div>
      <ul className='Navigation-sidebar'>
        {uiState.onboardingStatuses().map((status, index) => {
          if (index < indexOfCurrentStatus) {
            return <li key={status.id}><span className='complete'>{status.display}</span></li>;
          }
          if (index === indexOfCurrentStatus) {
            return (
              <li key={status.id}>
                <span className='active'>{status.display}</span>
                <div className='ml3 my2 jumbo'>{status.description}</div>
              </li>
            );
          }
          return <li key={status.id}><span>{status.display}</span></li>;
        })}
      </ul>
    </Modal>
  );
});

const COLUMNS = [
  {
    header: 'broker_dashboard.Company',
    component: CompanyName,
    sortBy: 'name',
    width: 3
  },
  {
    header: 'broker_dashboard.Carriers',
    attribute: 'carriers',
    width: 5
  },
  {
    header: 'Status',
    sortBy: 'status',
    component: Status,
    width: 2
  },
  {
    component: ImpersonationButton,
    width: 2,
    className: 'right-align'
  }
];

const LOCALIZATION = {
  emptyState: 'broker_dashboard.No clients to display'
};

const AddClientButton = observer(({uiState}) => {
  const {broker} = uiState;

  if (broker.mgaPlus && !auth.user.impersonating) return null;

  return (
    <div className='inline-block right'>
      <div className='table-cell align-middle pl2'>
        <Button size='sm' onClick={() => uiState.navigateToNewClient()}>
          <FormattedMessage id='broker_dashboard.+ Add Client'/>
        </Button>
      </div>
    </div>
  );
});

const ClientsContainer = observer(({uiState}) => {
  return (
    <div>
      <Display
        title='Client List'
        backPath='/dashboard'
        toolbar={<AddClientButton uiState={uiState}/>}
      />
      <Layout>
        <Layout.Section>
          <InteractiveTable
            columns={COLUMNS}
            localization={LOCALIZATION}
            showLinks={false}
            uiState={uiState}
            proxy={{
              type: 'async',
              model: ClientCompany,
              modelType: types.BROKER_PORTAL.CLIENT_COMPANY,
              endpoint: endpoints.BROKER_PORTAL.CLIENTS
            }}
          >
            <DropdownFilter attr='status' options={uiState.filterOptions()} />
          </InteractiveTable>
        </Layout.Section>
      </Layout>
      <StatusModal uiState={uiState}/>
    </div>
  );
});

export default withState(ClientsContainer, ClientsContainerState);
