import React from 'react';
import {endpoints, types} from 'shared/core';
import {observer} from 'mobx-react';
import FilterComponent from './FilterComponent';
import useFetchModels from 'shared/hooks/useFetchModels';
import {Employee} from 'stores/employees';

const GoalEmployee = observer(({filter, filterKey, filterUpdated, filterDeleted, Wrapper}) => {
  const [models, isLoading] = useFetchModels(endpoints.PERFORMANCE_GOALS.FILTERABLE_EMPLOYEES, types.EMPLOYEE);
  const options = models.map(employee => {
    employee = new Employee(employee);
    return { label: employee.name, value: employee.id };
  });

  return <FilterComponent
    filter={filter}
    filterKey={filterKey}
    options={options}
    filterUpdated={filterUpdated}
    filterDeleted={filterDeleted}
    Wrapper={Wrapper}
    isLoading={isLoading}
    searchable
  />;
});

export default GoalEmployee;
