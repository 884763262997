import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Input, Submit, FormLayout} from 'components';

const RecoveryCodeDownload = observer(({uiState}) => {
  const {recoveryCode} = uiState;

  return (
    <div>
      <div className='h1 center medium pb3 pt1'>
        <div className='pb1'>
          <FormattedMessage id='user_settings.authentications.Save your recovery code' />
        </div>
      </div>
      <div className='h4 jumbo pb3'>
        <FormattedMessage id='user_settings.authentications.NEW_RECOVERY_CODE_GENERATED' />
      </div>
      <FormLayout>
        <Input
          label='user_settings.authentications.Recovery Code'
          disabled
          value={recoveryCode.code}
          helpMessage='user_settings.authentications.Download or write down your recovery code and keep it safe.'
        />
      </FormLayout>
      <div>
        <Submit trait='default' size='lg' className='full-width mt2' onClick={() => recoveryCode.downloadCode()}>
          <FormattedMessage id='Download'/>
        </Submit>
        <Submit size='lg' className='full-width mt2' onClick={() => uiState.redirectAfterLogin()}>
          <FormattedMessage id='Finish'/>
        </Submit>
      </div>
    </div>
  );
});


export default RecoveryCodeDownload;

