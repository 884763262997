import {DomainObject, oneOf} from 'shared/store';
import {observable, action} from 'mobx';
import {Employee} from 'stores/employees';
import {User} from 'stores/users';
import TimeTrackingPaySchedule from './TimeTrackingPaySchedule';

class TimeTrackingPolicy extends DomainObject {
  @observable name;
  @observable state;

  @observable overtimePolicy;
  @observable weeklyOvertimeThreshold;
  @observable dailyOvertimeThreshold;
  @observable dailyDoubleOvertimeThreshold;
  @observable weekStartDay;
  @observable sendApprovedTimesheetEditedNotification;
  @observable sendPayPeriodEndedNotification;
  @observable sendEmployeeTimesheetReminder;

  @observable paySchedule;
  @observable employees;
  @observable assigneeType;
  @observable assigneeUser;

  @action merge(other) {
    super.merge(other, {
      paySchedule: TimeTrackingPaySchedule,
      employees: [Employee],
      assigneeUser: User,
      overtimePolicy: oneOf(['standard', 'custom', 'exempt']),
      assigneeType: oneOf(['manager', 'specific_user'])
    });
  }
}

export default TimeTrackingPolicy;
