import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints} from 'shared/core';
import {MyAccount} from 'stores/users';
import {redirect} from 'shared/tools';
import _ from 'lodash';

const API = {
  BROKER: {
    ALL_ACCOUNTS: endpoints.BROKER_PORTAL.MY_ACCOUNTS.ALL,
    ACTIVATE: endpoints.BROKER_PORTAL.MY_ACCOUNTS.ACTIVATE
  },
  COMPANY: {
    ALL_ACCOUNTS: endpoints.MY_ACCOUNTS.ALL,
    ACTIVATE: endpoints.MY_ACCOUNTS.ACTIVATE
  }
};

class SwitchAccountPageState {
  api = API.COMPANY;
  store = new DomainStore();

  @observable accounts = [];

  receiveProps({brokerView}) {
    this.api = brokerView ? API.BROKER : API.COMPANY;
  }

  @action async load() {
    await this.store._compose([
      this.api.ALL_ACCOUNTS
    ]);

    this.accounts = this.store._getAll(
      types.MY_ACCOUNT,
      MyAccount
    );
  }

  @action async switchToAccount(account) {
    await this.store.put(
      this.api.ACTIVATE.with(account.id),
      types.MY_ACCOUNT,
      account
    );

    return redirect('/');
  }

  @computed get sortedAccounts() {
    return _.reverse(_.sortBy(this.accounts, 'current'));
  }
}

export default SwitchAccountPageState;
