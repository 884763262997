import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {timestampDate} from 'shared/tools';
import {User} from 'stores/users';

class UserApiKey extends DomainObject {
  @observable id;
  @observable label;
  @observable apiKey;
  @observable maskedApiKey;
  @observable lastUsedAt;
  @observable createdAt;
  @observable active;

  @action merge(other) {
    super.merge(other, {
      _dates: ['createdAt', 'lastUsedAt'],
      user: User
    });
  }

  @computed get lastUsedAtView() {
    return timestampDate(this.lastUsedAt);
  }

  @computed get createdAtView() {
    return timestampDate(this.createdAt);
  }
}

export default UserApiKey;
