import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Layout, Panel, InteractiveTable, EmployeeNameWithAvatar, Avatar, DropdownFilter} from 'components';
import {withState, endpoints, types, t} from 'shared/core';
import CheckIn from 'stores/performance/CheckIn';
import {fullName, dateFormatter} from 'shared/tools';
import _ from 'lodash';
import MyCheckInsContainerState from './state/MyCheckInsContainerState';

const CheckInLink = ({status, lastCheckinId, cycle, reviewer}) => {
  if (status === 'awaiting') {
    return <UpdateCheckInLink lastCheckinId={lastCheckinId}/>;
  } else {
    return <CreateCheckInLink cycle={cycle} reviewer={reviewer}/>;
  }
};

const UpdateCheckInLink = ({lastCheckinId}) => {
  return (
    <a href={`/performance/checkins/${lastCheckinId}`} className='Btn Btn--primary'>
      <FormattedMessage id='performance.Update Check-In'/>
    </a>
  );
};

const CreateCheckInLink = ({cycle, reviewer}) => {
  return (
    <a href={`/performance/checkins/cycle/${cycle.id}/reviewer/${reviewer.id}`} className='Btn Btn--primary'>
      <FormattedMessage id='performance.Check-In'/>
    </a>
  );
};

const CheckinTag = ({firstName, status, percentage}) => {
  if (status === 'awaiting')
    return null;

  return (
    <div className='inline-block'>
      {percentage >= 100 &&
        <div className='MessageTag MessageTag--tangerine'><FormattedMessage id="performance.DUE_FOR_CHECKIN_WITH" values={{name: firstName}} /></div>}
      {(percentage < 100 && percentage >= 80) &&
        <div className='MessageTag MessageTag--ufo'><FormattedMessage id="performance.GREAT_TIME_TO_CHECKIN_WITH" values={{name: firstName}} /></div>}
    </div>
  );
};

const CheckinRow = ({reviewer, cycle, firstName, lastName, jobTitle, status, lastCheckinId, daysSinceLastCheckin, totalDays, border}) => {
  return (
    <div className={`clearfix ${border ? 'border-bottom' : ''}`}>
      <div className='clearfix table mxn1 py2'>
        <div className='col-6 table-cell align-middle pr1'>
          <div className='table-cell align-middle pr1'>
            <Avatar employee={reviewer} size='md' className='left'/>
          </div>
          <div className='table-cell align-middle pl1'>
            <div className='h3 medium'>{fullName({firstName, lastName})}</div>
            <div className='h5'>
              <FormattedMessage id='performance.JOB_TITLE_CYCLE_NAME' values={{
                jobTitle: jobTitle,
                cycleName: cycle.name
              }}/>
            </div>
          </div>
        </div>
        <div className='col-6 table-cell align-middle pl1 right-align'>
          <CheckinTag firstName={firstName} status={status} percentage={daysSinceLastCheckin * 100 / totalDays}/>
          <CheckInLink lastCheckinId={lastCheckinId} cycle={cycle} status={status} reviewer={reviewer}/>
        </div>
      </div>
    </div>
  );
};

const CheckinLeader = ({model}) => {
  const tags = [];

  if (!model.completed) {
    tags.push({
      content: t('performance.awaiting'),
      colour: 'dodger'
    });
  }

  return <EmployeeNameWithAvatar employee={model.reviewer} tags={tags}/>;
};

const COLUMNS = [
  {
    header: 'performance.Leader',
    width: 6,
    component: CheckinLeader
  },
  {
    header: 'performance.Submitted',
    width: 3,
    render: model => dateFormatter(model.createdAt)
  },
  {
    header: 'performance.Completed',
    width: 3,
    render: model => dateFormatter(model.completedAt)
  }
];

const LOCALIZATION = {
  emptyState: 'performance.No previous check-ins to display'
};

function statusFilters() {
  return [
    {
      id: 'active',
      display: t('performance.Active')
    },
    {
      id: 'archived',
      display: t('performance.Archived')
    }
  ];
}

const MyCheckInsContainerWithState = ({uiState}) => {
  const {pairs} = uiState;

  return (
    <Layout>
      <Panel.Stack loose>
        {!_.isEmpty(pairs) && <Panel>
          {pairs.map((pair, index) =>
            <CheckinRow {...pair.reviewer} {...pair} key={pair.id} border={pairs.length > index + 1} />
          )}
        </Panel>}
        <InteractiveTable
          sortable={false}
          searchable={false}
          showLinks={false}
          showRoute='/checkins/:id'
          title={t('performance.Previous Check-Ins')}
          columns={COLUMNS}
          localization={LOCALIZATION}
          proxy={{
            type: 'async',
            modelType: types.PERFORMANCE.CHECKIN,
            model: CheckIn,
            endpoint: endpoints.PERFORMANCE.CHECKINS
          }}
        >
          <DropdownFilter options={statusFilters()} attr='status'/>
        </InteractiveTable>
      </Panel.Stack>
    </Layout>
  );
};



export default withState(MyCheckInsContainerWithState, MyCheckInsContainerState);
