import {DomainStore} from 'shared/store';
import {observable, action, computed} from 'mobx';
import _ from 'lodash';
import {t, endpoints, types} from 'shared/core';
import {successAlert} from 'shared/tools';
import EmployeeDetails from 'stores/employees/EmployeeDetails';
import Location from 'stores/locations/Location';
import CompanyInsuranceCarrier from 'stores/benefits/CompanyInsuranceCarrier';
import {EmployeeBenefitEnrollmentViewModel, EmployeeInsuranceNumber} from 'stores/benefits';
import {EmployeeCustomField} from 'stores/employee_custom_fields';

class BenefitEnrolmentFormState {

  store = new DomainStore();
  history;

  @observable employee = new EmployeeDetails({payRateCurrency: 'CAD', payRateUnit: 'year'});
  @observable locations = [];
  @observable companyInsuranceCarriers = [];
  @observable enrollmentViewModels = [];
  @observable employeeInsuranceNumbers = [];
  @observable errors = {};

  receiveProps({history}) {
    this.history = history;
  }

  @action async load() {
    await this.store._compose([
      endpoints.LOCATIONS.ALL,
      endpoints.COMPANY_INSURANCE_CARRIERS.WITHOUT_OPTIONAL,
      endpoints.EMPLOYEE_CUSTOM_FIELDS.ENROLMENT
    ]);

    this.locations = this.store._getAll(types.LOCATION, Location);
    this.companyInsuranceCarriers = this.store._getAll(
      types.COMPANY_INSURANCE_CARRIER, CompanyInsuranceCarrier
    );
    const employeeCustomFields = this.store._getAll(
      types.EMPLOYEE_CUSTOM_FIELD, EmployeeCustomField
    );
    this.employee.merge({employeeCustomFields});

    this.employeeInsuranceNumbers = _(this.companyInsuranceCarriers)
      .filter(carrier => carrier.selfAssignedCertificatesEnabled)
      .map(carrier => new EmployeeInsuranceNumber({companyInsuranceCarrier: carrier}))
      .value();
    this.enrollmentViewModels = this.companyInsuranceCarriers.map(carrier => {
      const viewModel = EmployeeBenefitEnrollmentViewModel.createFor(carrier, this.employeeInsuranceNumbers);
      viewModel.record.merge({startDate: this.startDate});
      viewModel.allowNotEligible = this.allowNotEligible;

      return viewModel;
    });
  }

  @action async createEmployee() {
    this.employee.merge({
      employeeBenefitEnrollments: this.enrolments,
      employeeInsuranceNumbers: this.insuranceNumbers,
      commissionStructure: this.employee.commission ? this.employee.commissionStructure : null,
      bonusStructure: this.employee.bonus ? this.employee.bonusStructure : null
    });

    const {model, errors} = await this.store.post(
      endpoints.EMPLOYEE.CREATE_FOR_ENROLMENT,
      types.EMPLOYEE_DETAILS,
      this.employee
    );

    this.errors = errors;

    if (model) {
      successAlert(t('benefit_enrolment.enrol.Enrollment successfully created.'));
      this.returnToEnrolments();
    }
  }

  @action returnToEnrolments() {
    this.history.push('/benefit_enrolments');
  }

  @computed get allowNotEligible() {
    return this.companyInsuranceCarriers.length > 1;
  }

  @computed get enrolments() {
    return this.enrollmentViewModels.map(e => e.record);
  }

  @computed get insuranceNumbers() {
    return _(this.enrollmentViewModels).map(m => m.insuranceNumber).compact().value();
  }

  @action onDateChanged(value) {
    this.employee.merge({startDate: value});
    for (const e of this.enrollmentViewModels) {
      e.record.merge({enrolmentDate: value});
    }
  }
}

export default BenefitEnrolmentFormState;
