const chart = (type) => {
  return {
    type: type,
    marginTop: 20,
    spacingRight: -20,
    height: 400
  };
};

export default chart;
