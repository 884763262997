import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, FormLayout} from 'components';

const WorkWeek = observer(({uiState}) => {
  return (
    <div>
      <Panel.Header
        title={t('time_off.policy.edit.Work Week')}
        description={t('time_off.policy.edit.Tell us what days of the week the employees work and how many hours constitutes a full day of work.')}
      />
      <FormLayout>
        <div></div>
      </FormLayout>
    </div>
  );
});

export default WorkWeek;

