import React from 'react';
import {observer} from 'mobx-react';
import {Route, Switch} from 'react-router-dom';
import {ReviewEditFlow} from './edit';
import {ReviewViewPage} from './view';
import {ReviewDirectory} from './directory';
import {ManageReview} from './manage';

const ReviewsPage = observer(({match, scope}) => {
  return (
    <Switch>
      <Route path={`/reviews/:cycleId/edit`} component={ReviewEditFlow}/>
      <Route path={`/reviews/:cycleId/manage`} component={ManageReview}/>
      <Route path={`/reviews/:cycleId`} component={ReviewViewPage} />
      <Route path='/reviews' exact render={() => <ReviewDirectory scope={scope}/>}/>
    </Switch>
  );
});

export default ReviewsPage;
