import React from 'react';
import {observer} from 'mobx-react';
import {endpoints, types, withDisplay, withState, t} from 'shared/core';
import SubNav from 'company_settings/SubNav';
import {Layout, InteractiveTable} from 'components';
import {LeaveTypeDirectoryState} from '../state';
import {LeaveType} from 'stores/leave_types';

const COLUMNS = [
  {
    header: 'company_settings.leave_types.Name',
    attribute: 'name',
    width: 11
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'company_settings.leave_types.Remove Leave Type?',
    subHeader: 'company_settings.leave_types.Are you sure you want to remove this leave type?'
  },
  emptyState: 'company_settings.leave_types.No leave types to display',
  interactive: {
    addModel: 'company_settings.leave_types.+ Add Leave Type'
  }
};

const LeaveTypeDirectory = observer(({uiState}) => {
  return (
    <Layout>
      <Layout.Section>
        <InteractiveTable
          saveStateToUrl
          uiState={uiState}
          title={t('company_settings.leave_types.Leave Types')}
          columns={COLUMNS}
          localization={LOCALIZATION}
          onRemove={(leaveType) => uiState.removeLeaveType(leaveType)}
          onAdd={() => uiState.goToEditLeaveType()}
          editRoute='/leave_types/edit/:id'
          proxy={{
            type: 'async',
            modelType: types.LEAVE_TYPE,
            model: LeaveType,
            endpoint: endpoints.LEAVE_TYPES
          }}/>
      </Layout.Section>
    </Layout>
  );
});

export default withDisplay(withState(LeaveTypeDirectory, LeaveTypeDirectoryState), () => <SubNav title={t('company_settings.subnav.Leave Types')}/>);
