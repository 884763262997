import React from 'react';
import {observer} from 'mobx-react';
import {Flow} from 'components';
import {withState, t} from 'shared/core';
import ChangePolicyFlowState from './ChangePolicyFlowState';
import Step from './steps';

const ChangePolicyFlow = observer(({uiState}) => {
  const {employee} = uiState;

  return (
    <Flow
      name={t('employees.profile.time_off.Move to a different policy')}
      backTitle={employee.name}
      backRoute={`/${employee.id}/time-off`}
      steps={uiState.steps}
      component={Step}
      uiState={uiState}
      baseUrl={`/${employee.id}/change-time-off-policy`}
      onStepSubmitted={step => uiState.onStepSubmitted(step)}
      nextEnabled={uiState.nextEnabled}
    />
  );
});

export default withState(ChangePolicyFlow, ChangePolicyFlowState);
