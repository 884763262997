import {action, observable} from 'mobx';
import {DomainObject} from 'shared/store';
import Employee from 'stores/employees/Employee';
import CompanyDocument from 'stores/documents/CompanyDocument';

class CompanyDocumentAssignment extends DomainObject {
  @observable id;
  @observable companyDocument;
  @observable selectAllEmployees = false;
  @observable employees = [];
  @observable dueDate;

  @action merge(other) {
    super.merge(other, {
      companyDocument: CompanyDocument,
      employees: [Employee]
    });
  }
}

export default CompanyDocumentAssignment;
