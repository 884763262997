import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {endpoints, types, t} from 'shared/core';
import _ from 'lodash';
import Cycle from 'stores/performance/Cycle';
import UiState from '../../../state/cycles/UiState';
import {successAlert} from 'shared/tools';

class CycleFlowState {
  store = new DomainStore();
  match;
  history;

  @observable questionState = new UiState();
  @observable allEmployees = [];
  @observable cycle;
  @observable errors = {};
  @observable managerWelcomeModal = false;
  @observable employeeWelcomeModal = false;
  @observable managerReminderModal = false;
  @observable employeeReminderModal = false;

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    const cycleId = this.match.params.id;
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.PERFORMANCE.CYCLE.with(cycleId),
      endpoints.COMPANY_EMAIL_TEMPLATES,
    ]);

    this.allEmployees = this.store.getEmployees();
    this.cycle = this.createCycle(this.store._getSingle(types.PERFORMANCE.CYCLE));

    this.managerWelcomeEmailPreview = this.store._getSingle(types.COMPANY_EMAIL_TEMPLATE, {emailType: 'checkin_leader_welcome'});
    this.employeeWelcomeEmailPreview = this.store._getSingle(types.COMPANY_EMAIL_TEMPLATE, {emailType: 'checkin_employee_welcome'});
    this.employeeReminderEmailPreview = this.store._getSingle(types.COMPANY_EMAIL_TEMPLATE, {emailType: 'checkin_employee_reminder'});
  }

  createCycle(json) {
    const cycle = new Cycle(json);
    cycle.init(this.allEmployees);

    return cycle;
  }

  @action async onStepSubmitted({location}) {
    switch (location) {
      case 'name': return this.submitName();
      case 'leaders': return this.submitReviewers();
      case 'employees': return this.submitReviewees();
      case 'frequency': return this.submitFrequency();
      case 'questions': return this.submitQuestions();
      case 'review': return this.launchCycle();
      default:
        throw new Error(`Location ${location} is not supported`);
    }
  }

  @action async submitStep(props) {
    const cycle = this.cycle.pick(props);
    cycle.init(this.allEmployees);
    const {model, errors} = await this.store.patch(cycle);

    this.errors = errors;
    if (!model) return false;

    this.cycle = this.createCycle(model);
    return true;
  }

  @action async submitName() {
    return this.submitStep(['name']);
  }

  @action async submitReviewers() {
    return this.submitStep(['selectableReviewers']);
  }

  @action async submitReviewees() {
    return this.submitStep(['reviewers']);
  }

  @action async submitFrequency() {
    return this.submitStep(['frequency']);
  }

  @action async submitQuestions() {
    return this.submitStep(['questions']);
  }

  @action async launchCycle() {
    this.cycle.merge({complete: true});

    const modelUpdated = await this.submitStep(
      [
        'complete',
        'sendManagerWelcomeNotifications',
        'sendEmployeeWelcomeNotifications',
        'sendManagerNotifications',
        'sendEmployeeNotifications',
      ]
    );
    if (modelUpdated) {
      successAlert(t('performance.Check-In Cycle Successfully Created'));
      this.history.push(`/cycles/${this.match.params.id}/reviewees`);
    }
  }
}

export default CycleFlowState;
