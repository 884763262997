const EMPLOYEE_INVITATION_MERGE_FIELDS = [
  {
    key: 'employee_first_name',
    label: 'company_settings.company_email_templates.merge_fields.Employee First Name',
    placeholder: 'company_settings.company_email_templates.merge_fields.#[Employee First Name]'
  },
  {
    key: 'employee_last_name',
    label: 'company_settings.company_email_templates.merge_fields.Employee Last Name',
    placeholder: 'company_settings.company_email_templates.merge_fields.#[Employee Last Name]'
  },
  {
    key: 'position_name',
    label: 'company_settings.company_email_templates.merge_fields.Position Name',
    placeholder: 'company_settings.company_email_templates.merge_fields.#[Position Name]'
  },
  {
    key: 'current_date',
    label: 'company_settings.company_email_templates.merge_fields.Current Date',
    placeholder: 'company_settings.company_email_templates.merge_fields.#[Current Date]'
  },
  {
    key: 'company_name',
    label: 'company_settings.company_email_templates.merge_fields.Company Name',
    placeholder: 'company_settings.company_email_templates.merge_fields.#[Company Name]'
  }
];

export default EMPLOYEE_INVITATION_MERGE_FIELDS;
