import {observable, computed, action} from 'mobx';
import _ from 'lodash';

class MultipleChoiceFormState {
  @observable question;

  receiveProps({question}) {
    this.question = question;

    if (_.isEmpty(this.question.options)) {
      _.set(this.question, 'args.options', observable([]));
      this.addOption();
    } else {
      this.question.args.options = observable(this.question.args.options);
    }
  }

  @computed get options() {
    return _.get(this.question.args, 'options');
  }

  @action addOption() {
    this.question.args.options.push({value: ''});
  }

  @action removeOption(option) {
    this.question.args.options.remove(option);
  }
}

export default MultipleChoiceFormState;
