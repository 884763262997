import React from 'react';
import {DatePicker} from 'components';
import {DEFAULT_DATE} from 'reports/tools';

// this component is a simple date filter and contains a single date picker
// if you want a range, use DateRange component

class DateFilter extends React.Component {
  state = {
    date: this.props.filters.date || DEFAULT_DATE
  };

  componentDidMount() {
    this.props.onChange({...this.state});
  }

  dateChanged = (date) => {
    this.setState({date: date || DEFAULT_DATE}, () => {
      this.props.onChange({...this.state});
    });
  };

  componentWillReceiveProps({filters}) {
    const {date} = filters;
    if (date) {
      this.setState({date});
    }
  }

  render() {
    const {date} = this.state;
    return (
      <DatePicker withIcon={true} className='full-width' value={date} onChange={this.dateChanged}/>
    );
  }
}

export default DateFilter;
