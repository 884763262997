function sentimentTrendsOptions(options = {}) {
  return {
    title: null,
    legend: {
      enabled: false
    },
    chart: {
      height: 350
    },
    yAxis: {
      title: {
        enabled: false
      },
      labels: {
        enabled: false
      },
      min: 1,
      max: 5
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      line: {
        minPointLength: 2,
        cursor: options.onClick ? 'pointer' : 'auto',
        events: {
          click: options.onClick
        }
      }
    }
  };
}

export default sentimentTrendsOptions;
