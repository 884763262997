import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {DatePicker, FormError, FormLayout, TextArea, Panel, ObjectSelect2} from 'components';
import _ from 'lodash';

const IncludedFieldsForGroup = observer(({fieldGroup, uiState}) => {
  const {massUpdate, fields, updatingIncludedFields} = uiState;

  const includedFields = fields.filter(
    field => _.get(field, 'groupId') === fieldGroup.id && _.includes(massUpdate.includedFields, field.id)
  );

  if (_.isEmpty(includedFields)) return null;

  return (
    <div className='mb1'>
      <div className='medium mb1'>{fieldGroup.name}</div>
      <div className='flex flex-wrap'>
        {includedFields.map(field => (
          <div key={field.id} className='fields-pill mr1 mb1'>
            <div className='whitespace-nowrap'>{field.name}</div>
            {!massUpdate.readOnly && <i className={`material-icons h4 ml1 ${updatingIncludedFields ? 'disabled' : ''}`} onClick={() => uiState.removeIncludedField(field.id)}>{'close'}</i>}
          </div>
        ))}
      </div>
    </div>
  );
});

const Attributes = observer(({uiState}) => {
  const {massUpdate, fields, fieldGroups, updatingIncludedFields, errors} = uiState;

  return (
    <React.Fragment>
      <Panel.Header
        title={t('mass_updates.edit.Attributes')}
        description={t('mass_updates.edit.attributes_explainer')}
      />
      {!massUpdate.readOnly && <div className='mb2'>
        <ObjectSelect2
          value={fields.filter(field => _.includes(massUpdate.includedFields, field.id))}
          multiple
          items={fields}
          groups={fieldGroups}
          onAdd={field => uiState.addIncludedField(field.id)}
          placeholder=' '
          disabled={massUpdate.readOnly || updatingIncludedFields}
          hideSelected
        />
        <FormError message={errors.includedFields}/>
      </div>}
      {fieldGroups.map(fieldGroup => <IncludedFieldsForGroup fieldGroup={fieldGroup} uiState={uiState}/>)}
    </React.Fragment>
  );
});

const JobMassUpdateEditor = observer(({uiState}) => {
  const {massUpdate, errors} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('mass_updates.edit.Effective Date')}/>
      <FormLayout>
        <DatePicker
          value={massUpdate.effectiveDate}
          onChange={(effectiveDate) => uiState.updateMassUpdate({effectiveDate})}
          options={{startView: 1}}
          className='full-width'
          disabled={massUpdate.readOnly}
          errorMessage={errors.effectiveDate}
        />
      </FormLayout>
      <Panel.Header
        title={t('mass_updates.edit.Additional Notes')}
        description={t('mass_updates.edit.additional_notes_desc')}
      />
      <TextArea
        label='mass_updates.edit.Comment (not visible to the employee)'
        value={massUpdate.comment}
        onChange={(e) => uiState.updateMassUpdate({comment: e.target.value})}
        placeholder='mass_updates.edit.Enter your comment here'
        className='full-width mb2' minRows={3}
        disabled={massUpdate.readOnly}
      />
      <Attributes uiState={uiState}/>
    </Panel>
  );
});

export default JobMassUpdateEditor;
