import React from 'react';
import {observer} from 'mobx-react';
import {Panel, DemoAccountWarning} from 'components';
import NavigationButtons from './NavigationButtons';
import {loader} from 'shared/core';

class FlowStep extends React.Component {
  render() {
    const {match, step, component, rest} = this.props;
    const Step = component;

    return <Step match={match} step={step} {...rest}/>;
  }
}

const StepWrapper = observer(({children, useCustomLayout}) => {
  if (useCustomLayout) {
    return (children);
  }

  return (
    <Panel>
      {children}
    </Panel>
  );
});

const StepWithButtons = observer(({uiState, step, match, component, rest, useCustomLayout}) => {
  return (
    <div>
      <DemoAccountWarning/>
      <StepWrapper useCustomLayout={useCustomLayout}>
        <FlowStep uiState={uiState} match={match} step={step} component={component} rest={rest}/>
      </StepWrapper>
      <NavigationButtons uiState={uiState}/>
    </div>
  );
});

async function load(props) {
  const {step, uiState} = props;
  await uiState.setCurrentStep(step);
  return {uiState, ...props};
}

export default loader(StepWithButtons, load);
