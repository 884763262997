import React from 'react';
import {observer} from 'mobx-react';
import {Button, Panel, TextArea} from 'components';
import {FormattedMessage} from 'react-intl';

const NewNote = ({uiState, content, onContentChange, onCreate}) => {
  const {newNote, errors} = uiState;

  return (
    <Panel className='clearfix'>
      <form>
        <TextArea
          value={newNote.content}
          onChange={(e) => newNote.merge({content: e.target.value})}
          placeholder='employees.profile.notes.Take a note (not visible to the employee)'
          className='full-width'
          errorMessage={errors.content}
        />
        <Button type='submit' className='right mt2' onClick={() => uiState.createNote()}>
          <FormattedMessage id='employees.profile.notes.Submit'/>
        </Button>
      </form>
    </Panel>
  );
};

export default observer(NewNote);
