import React from 'react';
import {Panel} from 'components';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';

const ReviewEndedSummaryPanel = ({uiState}) => {
  const {cycle} = uiState;
  const {dueDateView, completedRevieweesPercentage, completedReviewees, reviewees} = cycle;

  return (
    <Panel>
      <Panel.Header title={t('performance_reviews.view.Review Overview')}/>
      <div className='clearfix col-6'>
        <div className='col col-6'>
          <div className='medium'>
            <FormattedMessage id='performance_reviews.view.Review Ended'/>
          </div>
          <div className='waterloo'>
            {dueDateView}
          </div>
        </div>
        <div className='col col-6'>
          <div className='medium'>
            <FormattedMessage id='performance_reviews.view.Completion'/>
          </div>
          <div className='waterloo'>
            <FormattedMessage
              id='performance_reviews.view.RATIO_AND_PERCENTAGE_COMPLETION'
              values={
                {
                  completedReviewees: completedReviewees,
                  totalReviewees: reviewees.length,
                  completedRevieweesPercentage: completedRevieweesPercentage
                }
              }
            />
          </div>
        </div>
      </div>
    </Panel>
  );
};

export default ReviewEndedSummaryPanel;
