import React from 'react';
import {observer} from 'mobx-react';
import StyleGuideButtons from './StyleGuideButtons';
import StyleGuideColour from './StyleGuideColour';
import StyleGuideTextInput from './StyleGuideTextInput';
import StyleGuideCheckbox from './StyleGuideCheckbox';
import StyleGuideRadiobutton from './StyleGuideRadiobutton';
import StyleGuideSelect2 from './StyleGuideSelect2';
import StyleGuideLinks from './StyleGuideLinks';

const StyleGuideGeneralContainer = observer(() => {
  return (
    <div className='bg-white box-shadow-1 p4 rounded'>
      <div className='h1 medium'>{'Collage Style Guide'}</div>
      <div className='h2 my2 jumbo max-width-3'>{'A styleguide that we hope one day will become fully flushed out and comprehensive.'}</div>
    </div>
  );
});

import {BrowserRouter as Router, Route, Switch, NavLink} from 'react-router-dom';
const Sidebar = observer(() => {
  return (
    <div>
      <ul className='StyleGuide-navigation list-reset bg-white'>
        <li className='pt4'></li>
        <li><NavLink to='/'>{'Home'}</NavLink></li>
        <li><NavLink to='/buttons'>{'Buttons'}</NavLink></li>
        <li><NavLink to='/links'>{'Links'}</NavLink></li>
        <li><NavLink to='/input'>{'Input'}</NavLink></li>
        <li><NavLink to='/select2'>{'Select2'}</NavLink></li>
        <li><NavLink to='/checkbox'>{'Checkbox'}</NavLink></li>
        <li><NavLink to='/radio-button'>{'Radiobutton'}</NavLink></li>
        <li><NavLink to='/colours'>{'Colours'}</NavLink></li>
      </ul>
    </div>
  );
});


const StyleGuideContainer = observer(() => {
  return (
    <div className='overflow-hidden'>
      <div className='clearfix mxn3'>
        <div className='col col-4 md-col-3 px3'>
          <Sidebar />
        </div>
        <div className='col col-8 md-col-9 py4 px3'>
          <div className='mr4'>
            <Switch>
              <Route exact path='/' component={StyleGuideGeneralContainer}/>
              <Route path='/buttons' component={StyleGuideButtons}/>
              <Route path='/links' component={StyleGuideLinks}/>
              <Route path='/input' component={StyleGuideTextInput}/>
              <Route path='/select2' component={StyleGuideSelect2}/>
              <Route path='/checkbox' component={StyleGuideCheckbox}/>
              <Route path='/radio-button' component={StyleGuideRadiobutton}/>
              <Route path='/colours' component={StyleGuideColour}/>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
});

const StyleGuidePage = observer(() => {
  return (
    <Router basename='/styleguide'>
      <Route path='/' component={StyleGuideContainer} />
    </Router>
  );
});
export default StyleGuidePage;
