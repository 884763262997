import {observable, action, computed} from 'mobx';
import {DomainObject} from 'shared/store';
import {calendarDate} from 'shared/tools';
import AttachedDocument from 'stores/documents/AttachedDocument';
import _ from 'lodash';

class PayrollUpload extends DomainObject {
  @observable id;
  @observable createdAt;
  @observable payrollFile;

  @action merge(other) {
    super.merge(other, {
      payrollFile: AttachedDocument
    });
  }

  @computed get hasFiles() {
    return !_.isEmpty(this.payrollFiles);
  }

  @computed get dateView() {
    return calendarDate(this.createdAt);
  }

  @computed get payrollFiles() {
    if (!this.payrollFile) return observable([]);

    return observable([this.payrollFile]);
  }

  set payrollFiles(value) {
    if (_.isEmpty(value)) {
      this.payrollFile = null;
    } else {
      this.payrollFile = value[0];
    }
  }
}

export default PayrollUpload;
