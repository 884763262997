import {observable, action} from 'mobx';
import {endpoints, types} from 'shared/core';
import {PerformanceReviewCycle} from 'stores/performance_reviews';
import {DomainStore} from 'shared/store';

class ManageReviewState {
  match;
  history;
  store = new DomainStore();

  @observable errors = {};
  @observable cycle;
  @observable launchCycleModalOpen = false;
  @observable deleteCycleModalOpen = false;
  @observable isLaunching = false;
  @observable emailPreview;

  receiveProps({match, history}) {
    this.match = match;
    this.history = history;
  }

  @action async load() {
    const id = this.match.params.cycleId;

    await this.store._compose([
      endpoints.PERFORMANCE_REVIEWS.CYCLE.with(id),
      endpoints.COMPANY_EMAIL_TEMPLATES
    ]);

    this.cycle = new PerformanceReviewCycle(
      this.store._getSingle(types.PERFORMANCE_REVIEWS.CYCLE)
    );

    this.emailPreview = this.store._getSingle(types.COMPANY_EMAIL_TEMPLATE, {emailType: 'performance_review_launch'});

    this.redirectIfNotConfigured();
  }

  redirectIfNotConfigured() {
    switch (this.cycle.state) {
      case 'created':
        this.history.push(`/reviews/${this.cycle.id}/edit`);
        break;
      case 'launched':
      case 'ended':
        this.history.push(`/reviews/${this.cycle.id}`);
        break;
      case 'configured':
      default:
        break;
    }
  }

  @action openLaunchCycleModal() {
    this.launchCycleModalOpen = true;
  }

  @action closeLaunchCycleModal() {
    this.launchCycleModalOpen = false;
  }

  @action async launchCycle() {
    this.isLaunching = true;

    const {model, errors} = await this.store.post(
      this.cycle.link('launch'),
      types.PERFORMANCE_REVIEWS.CYCLE,
      this.cycle
    );

    this.errors = errors;

    if (this.errors) {
      this.isLaunching = false;
    }

    if (model) {
      this.history.push(`/reviews/${this.cycle.id}`);
    }
  }

  @action openDeleteCycleModal() {
    this.deleteCycleModalOpen = true;
  }

  @action closeDeleteCycleModal() {
    this.deleteCycleModalOpen = false;
  }

  @action async deleteCycle() {
    await this.store.destroy(this.cycle);
    this.closeDeleteCycleModal();
    this.history.push('/reviews');
  }
}

export default ManageReviewState;
