import React from 'react';
import {observer} from 'mobx-react';
import LoginForm from './LoginForm';
import LoginFormHeader from './LoginFormHeader';
import ForgotPasswordLink from './ForgotPasswordLink';

const UsernamePasswordForm = observer(({uiState}) => {
  return (
    <div>
      <LoginFormHeader message='login.Sign into Collage' />
      <LoginForm uiState={uiState} />
      <ForgotPasswordLink />
    </div>
  );
});

export default UsernamePasswordForm;
