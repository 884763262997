import {observable, action, computed} from 'mobx';
import filterRowFactory from './filterRowFactory';
import {calendarDate} from 'shared/tools';
import _ from 'lodash';
import {DEFAULT_DATE, DEFAULT_START_DATE, DEFAULT_END_DATE} from 'reports/tools';

class CompositeReportState {
  @observable filters = {};
  @observable availableFilterCategories = [];
  @observable dateFilterType;
  @observable dateFilterFormat;
  @observable defaultStartDate;
  @observable defaultEndDate;
  @observable count;
  @observable reports = [];
  @observable filterRows = [];
  @observable categoryFilterOpen = false;
  @observable dateFilterOpen = false;
  @observable disableFilterEditing = false;

  @observable date;
  @observable startDate;
  @observable endDate;

  constructor(filterCategories, dateFilterType, dateFilterFormat, defaultStartDate, defaultEndDate, filterRows, disableFilterEditing) {
    this.availableFilterCategories = filterCategories || [];
    this.dateFilterType = dateFilterType;
    this.dateFilterFormat = dateFilterFormat;
    this.defaultStartDate = defaultStartDate || DEFAULT_START_DATE;
    this.defaultEndDate = defaultEndDate || DEFAULT_END_DATE;
    this.disableFilterEditing = disableFilterEditing;

    if (!_.isEmpty(filterRows)) {
      this.filterRows = filterRows;
    }
  }

  filterCategorySelected(category) {
    return !!_.find(this.filterRows, {category});
  }

  @action resetChanges() {
    this.date = this.filters.date;
    this.startDate = this.filters.startDate;
    this.endDate = this.filters.endDate;
  }

  @action closeFilters() {
    this.categoryFilterOpen = false;
    this.dateFilterOpen = false;
    this.resetChanges();
    for (const row of this.filterRows) {
      row.closeDropdowns();
    }
  }

  @action openCategoryFilter() {
    this.closeFilters();
    this.resetChanges();
    this.categoryFilterOpen = true;
  }

  @action openDateFilter() {
    this.closeFilters();
    this.resetChanges();
    this.dateFilterOpen = true;
  }

  @action closeCategoryFilter() {
    this.resetChanges();
    this.categoryFilterOpen = false;
  }

  @action closeDateFilter() {
    this.resetChanges();
    this.dateFilterOpen = false;
  }

  @action applyFilters() {
    this.reports.clear();

    if (this.invalidDate) this.fallBackToDefaultDate();
    if (this.invalidDateRange) this.fallBackToDefaultDateRange();

    this.filters = {
      date: this.date,
      startDate: this.startDate,
      endDate: this.endDate,
      filter: _.flatten(this.filterRows.map(viewModel => viewModel.row.toJS()))
    };
    this.closeFilters();
  }

  @action fallBackToDefaultDate() {
    if (this.dateFilterType !== 'single') return;

    this.date = DEFAULT_DATE;
  }

  @action fallBackToDefaultDateRange() {
    if (this.dateFilterType !== 'range') return;

    this.startDate = this.startDate || this.defaultStartDate;
    this.endDate = this.endDate || this.defaultEndDate;
  }

  @action updateDate(date) {
    if (!_.get(this.filters, 'date')) {
      this.filters = {
        date: date
      };
    }

    this.date = date;
  }

  @action updateDateRange(startDate, endDate) {
    if (!_.get(this.filters, 'startDate')) {
      this.filters = {
        startDate: startDate,
        endDate: endDate
      };
    }

    this.startDate = startDate;
    this.endDate = endDate;
  }

  @action updateCount(count) {
    this.count = count;
  }

  @action addReport(report) {
    this.reports.push(report);
  }

  @action toggleFilterRow(filterCategory, checked) {
    if (checked) {
      const rows = filterRowFactory({category: filterCategory});
      if (_.isArray(rows)) {
        this.filterRows.push(...rows);
      } else {
        this.filterRows.push(rows);
      }
      this.closeCategoryFilter();
    } else {
      const [removedRow] = _.remove(this.filterRows, {category: filterCategory});
      if (_.find(this.filters.filter, {attribute: removedRow.row.attribute})) {
        this.applyFilters();
      }
    }
  }

  @computed get invalidDate() {
    return !this.date;
  }

  @computed get invalidDateRange() {
    return !this.startDate || !this.endDate;
  }

  @computed get hasFilters() {
    return this.dateFilterType || this.hasFilterCategories || this.hasCount;
  }

  @computed get resultCountView() {
    return {
      id: 'reports.RESULT_COUNT',
      values: {
        count: this.reportWithCount.meta.totalCount
      }
    };
  }

  @computed get hasCount() {
    return !!this.reportWithCount;
  }

  @computed get reportWithCount() {
    return _.find(this.reports, r => _.isNumber(_.get(r, 'meta.totalCount')) && r.reportType !== 'metric');
  }

  @computed get dateFilterView() {
    const options = {dateFilterFormat: this.dateFilterFormat};
    return this.dateFilterType === 'single' ? calendarDate(this.date, options) : `${calendarDate(this.startDate, options)} - ${calendarDate(this.endDate, options)}`;
  }

  @computed get hasDateFilter() {
    return !!this.dateFilterType;
  }

  @computed get hasFilterCategories() {
    return !_.isEmpty(this.availableFilterCategories);
  }

  @computed get hasFiltersApplied() {
    return !_.isEmpty(this.filters.filter);
  }

  @computed get showFilterRows() {
    return !_.isEmpty(this.filterRows);
  }
}

export default CompositeReportState;
