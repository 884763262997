import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {Input} from 'components';

const EditDepartmentModal = observer(({uiState}) => {
  const {errors, editingDepartment} = uiState;

  return (
    <Modal isOpen={uiState.editDepartmentModalOpen} onHide={() => uiState.closeEditDepartmentModal()} size='sm'>
      <div className='h2 medium mb3'>
        <FormattedMessage id='company_settings.departments.Edit Department'/>
      </div>
      <Input value={editingDepartment.name}
             errorMessage={errors.name}
             onChange={(e) => editingDepartment.merge({name: e.target.value})}
       />
      <ModalButtons onSave={() => uiState.saveDepartment()} onCancel={() => uiState.closeEditDepartmentModal()}/>
    </Modal>
  );
});

export default EditDepartmentModal;
