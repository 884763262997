import React from 'react';
import {observer} from 'mobx-react';
import {DropdownSelect} from 'components';
import {t} from 'shared/core';

const Signatures = observer(({uiState}) => {
  return (
    <DropdownSelect selected={t('components.pdf.annotate.toolbar.Signatures')} buttonSize='sm' buttonTrait='default' align='left'>
      {uiState.signatures.map((signature, index) =>
        <DropdownSelect.Option
          key={index}
          onClick={() => uiState.addAnnotation(signature)}
          text={signature.name}
          separator={signature.annotationType === 'employee_signature'}
        />
      )}
    </DropdownSelect>
  );
});

const MergeFields = observer(({uiState}) => {
  return (
    <DropdownSelect selected={t('components.pdf.annotate.toolbar.Dynamic Fields')} buttonSize='sm' buttonTrait='default' align='left' size='sm'>
      {uiState.mergeFields.map((field, index) =>
        <DropdownSelect.Option
          key={index}
          onClick={() => uiState.addAnnotation(field)}
          text={field.name}
          separator={uiState.customFieldMergeFields.length > 0 &&
            (index === uiState.mergeFields.length - uiState.customFieldMergeFields.length - 1)}
        />
      )}
    </DropdownSelect>
  );
});

const InputFields = observer(({uiState}) => {
  return (
    <DropdownSelect selected={t('components.pdf.annotate.toolbar.Forms')} buttonSize='sm' buttonTrait='default' align='left' size='sm'>
      {uiState.inputFields.map((field, index) =>
        <DropdownSelect.Option
          key={index}
          onClick={() => uiState.addAnnotation(field)}
          text={field.name}
        />
      )}
    </DropdownSelect>
  );
});

const Toolbar = observer(({uiState}) => {
  return (
    <div className='inline-block relative mb3'>
      <div className='inline-block'>
        <Signatures uiState={uiState}/>
      </div>
      <div className='inline-block pl2'>
        <MergeFields uiState={uiState}/>
      </div>
      <div className='inline-block pl2'>
        <InputFields uiState={uiState}/>
      </div>
    </div>
  );
});

export default Toolbar;
