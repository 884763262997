import React from 'react';
import {observer} from 'mobx-react';
import {Table} from 'components';
import {FormattedMessage} from 'react-intl';
import {endpoints} from 'shared/core';

const columns = (recordType) => {
  return [
    {
      header: 'payroll_updates.Field',
      width: 6,
      attribute: 'name'
    },
    {
      header: 'payroll_updates.New value',
      width: 6,
      render: model => <Value model={model} recordType={recordType}/>
    }
  ];
};

const Value = observer(({model, recordType}) => {
  if (recordType === 'employee_file')
    return (
      <a href={endpoints.EMPLOYEE_FILES.with(model.value)} target='blank'>{model.name}</a>
    );
  else {
    return (
      <div>{model.value}</div>
    );
  }
});

const Change = observer(({change}) => {
  return (
    <div className='mb3'>
      <div className='mb1'>
        <FormattedMessage id={`payroll_updates.changes.${change.recordType}.${change.recordAction}`}/>
      </div>
      {change.recordType !== 'tax_info' && change.fields.length &&
        <Table
          indexBy='name'
          trait='xcompact'
          showLinks={false}
          columns={columns(change.recordType)}
          models={change.fields}
        />
      }
    </div>
  );
});

const ChangesForUpdate = observer(({update}) => {
  return (
    <div>
      {update.changes.map((c, index) => <Change change={c} key={index}/>)}
    </div>
  );
});

export default ChangesForUpdate;
