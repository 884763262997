import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {t} from 'shared/core';

const filters = [
  'Department',
  'Employment Type',
  'Gender',
  'Location',
  'Manager',
  'Pay Rate',
  'Start Date'
];

const HeadcountReport = () => {
  return (
    <CompositeReport dateFilter='single' filters={filters}>
      <Report name={t('reports.HEADCOUNT_REPORT')}
              view='chart'
              options={{
                legendDisabled: true
              }}
              url='/reports/headcount_chart'>
      </Report>
      <Report url='/reports/headcount_table'
              name={t('reports.HEADCOUNT_REPORT')}
              showName={false}>
      </Report>
    </CompositeReport>
  );
};

export default HeadcountReport;
