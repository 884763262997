import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Tag} from 'components';

const UserNameAndEmail = observer(({model}) => {
  return (
    <div className='table-cell align-middle'>
      <div className='flex'>
        <div className='pr2'>{model.name}</div>
        {model.disabled &&
        <Tag colour='rose'>
          <FormattedMessage id='company_settings.external_users.Disabled'/>
        </Tag>}
      </div>
      <a href={`mailto:${model.email}`}>{model.email}</a>
    </div>
  );
});

const Roles = observer(({model}) => {
  return (
    <div>
      {model.roleNames.join(', ')}
    </div>
  );
});

const MFAType = observer(({model}) => {
  if (!model.mfaType) {
    return <FormattedMessage id='company_settings.external_users.Disabled'/>;
  }

  return (
    <FormattedMessage id={`company_settings.external_users.${model.mfaType}`}/>
  );
});

const OAuth = observer(({model}) => {
  if (model.provider) {
    return <div>{model.provider}</div>;
  }

  return <FormattedMessage id='company_settings.external_users.Disabled'/>;
});

const EmailNotifications = observer(({model}) => {
  if (model.unsubscribedFromEmails) {
    return <FormattedMessage id='company_settings.external_users.Unsubscribed'/>;
  }

  return (
    <FormattedMessage id={`company_settings.external_users.Subscribed`}/>
  );
});

const COLUMNS = [
  {
    header: 'company_settings.external_users.Name',
    component: UserNameAndEmail,
    width: 4
  },
  {
    header: 'company_settings.external_users.Roles',
    component: Roles,
    width: 3
  },
  {
    header: 'company_settings.external_users.User Type',
    render: model => <FormattedMessage
      id={`${model.external ? 'company_settings.external_users.External' : 'company_settings.external_users.Employee'}`}/>,
    width: 1
  },
  {
    header: 'company_settings.external_users.OAuth',
    component: OAuth,
    width: 1
  },
  {
    header: 'company_settings.external_users.2FA',
    component: MFAType,
    width: 1
  },
  {
    header: 'company_settings.external_users.Emails',
    component: EmailNotifications,
    width: 1
  }
];

export default COLUMNS;
