import React from 'react';
import {observer} from 'mobx-react';
import {urls} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {calendarDate} from 'shared/tools';
import {Table} from 'components';

const RecentActivityItem = observer(({model, hideProfileLink}) => {
  const {employee} = model;

  return (
    <Table.RowWithAvatar employee={employee} hideProfileLink={hideProfileLink}>
      {model.changes.map((change, i) => (
        <div key={i}>
          <FormattedMessage
            key={i}
            id={`pa_dashboard.recent_activity.changes.${change.recordType}.${change.recordAction}`}
            values={{
              employee: hideProfileLink ? 
                employee.name : <a href={urls.EMPLOYEE.with(employee.id)}>{employee.name}</a>
            }}
          />
        </div>
      ))}
      <div className='jumbo'>
        {calendarDate(model.createdAt)}
      </div>
    </Table.RowWithAvatar>
  );
});

export default RecentActivityItem;
