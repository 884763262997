import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {NumberInput} from 'components';
import {t} from 'shared/core';

const EditEntryBreakModal = observer(({isOpen, uiState}) => {
  const {editingEntry} = uiState;

  return (
    <Modal
      size='sm'
      onHide={() => uiState.closeEditEntryBreakModal()}
      isOpen={isOpen}
      title='time_tracking.timesheet.Edit Unpaid Break'
    >
      <NumberInput
        value={editingEntry.unpaidBreakMinutes}
        onChange={e => editingEntry.unpaidBreakMinutes = e.target.value}
        connectedRight={
          <div className='InputGroup'>
            <span className='InputGroup-tag small'>{t('time_tracking.timesheet.minutes')}</span>
          </div>
        }
      />
      <ModalButtons
        onCancel={() => uiState.closeEditEntryBreakModal()}
        onSave={() => uiState.saveEntryBreak()}
      />
    </Modal>
  );
});

export default EditEntryBreakModal;
