import React from 'react';
import {observer} from 'mobx-react';
import {withState, t, types, endpoints} from 'shared/core';
import {OfferDirectoryState} from '../state';
import {FormattedMessage} from 'react-intl';
import {OfferPreviewModal, Panel, Avatar, ActionLink, Display, Layout, Button, InteractiveTable, DropdownFilter, Tag, Tooltip} from 'components';
import {absoluteDateFormatter, absoluteTimestampDate} from 'shared/tools';
import RevokeOfferModal from './RevokeOfferModal';
import RevokeReasonModal from './RevokeReasonModal';
import {OfferSummary} from 'stores/offers';

const OfferName = observer(({model}) => {
  return (
    <div className='flex'>
      <span>{model.name}</span>
      {model.isAccepted && <div>
        <span className='pl1'>
          <Tag colour='grass'>
            <FormattedMessage id={'offers.directory.Accepted'}/>
          </Tag>
        </span>
      </div>}
      {model.isExpired && <div>
        <span className='pl1'>
          <Tag colour='scarlet'>
            <FormattedMessage id={'offers.directory.Expired'}/>
          </Tag>
        </span>
      </div>}
    </div>
  );
});

const OfferViewStatus = observer(({model, uiState}) => {
  if (model.deliveryFailedAt) {
    return (
      <Tooltip content={absoluteTimestampDate(model.deliveryFailedAt)}>
        <span className='scarlet'><FormattedMessage id={`offers.directory.view_status.Delivery failed`}/></span>
      </Tooltip>
    );
  }

  return (
    <div>
      <div>
        <Tooltip content={absoluteTimestampDate(model.emailOpenedAt)}>
          {model.emailOpenedAt ?
            <span><FormattedMessage id={`offers.directory.view_status.Email opened`}/></span> :
            <span className='waterloo'><FormattedMessage id={`offers.directory.view_status.Email unopened`}/></span>}
        </Tooltip>
      </div>
      <div>
        <Tooltip content={absoluteTimestampDate(model.viewedAt)}>
          {model.viewedAt ?
            <span><FormattedMessage id={`offers.directory.view_status.Offer viewed`}/></span> :
            <span className='waterloo'><FormattedMessage id={`offers.directory.view_status.Offer not viewed`}/></span>}
        </Tooltip>
      </div>
      {model.isRevoked && model.revokeReason && <ActionLink onClick={() => uiState.openRevokeReasonModal(model)}>
        <FormattedMessage id='offers.directory.Notes'/>
      </ActionLink>}
    </div>
  );
});

const COLUMNS = [
  {
    header: 'offers.directory.Name',
    component: OfferName,
    width: 2
  },
  {
    header: 'offers.directory.Job Title',
    attribute: 'jobTitle',
    width: 2
  },
  {
    header: 'offers.directory.Date Sent',
    render: (model) => absoluteDateFormatter(model.sentAt),
    width: 2
  },
  {
    header: 'offers.directory.Sent By',
    render: (model) => <div className='flex'>
      <Avatar employee={model.createdByUser} size='sm'/>
    </div>,
    width: 1
  },
  {
    header: 'offers.directory.Status',
    component: OfferViewStatus,
    width: 2
  },
  {
    header: 'offers.directory.Expiry Date',
    render: (model) => absoluteTimestampDate(model.expiresAt),
    width: 2
  }
];

const LOCALIZATION = {
  emptyState: 'offers.directory.No offers to display',
  removeModal: {
    header: 'offers.directory.Remove offer?',
    subHeader: 'offers.directory.This will permanently delete the offer details from Collage.'
  }
};

function statusFilters() {
  return [
    {
      id: 'in_progress',
      display: t('offers.directory.In Progress')
    },
    {
      id: 'hired',
      display: t('offers.directory.Hired')
    },
    {
      id: 'revoked',
      display: t('offers.directory.Revoked')
    }
  ];
}


const OfferInfoPanel = () => {
  return (
    <Panel className='bg-sky border-left--dodger'>
      <Panel.Header title={t('offers.directory.INFO_PANEL_TITLE')}/>
      <div className='clearfix'>
        <FormattedMessage id='offers.directory.INFO_PANEL_BODY'/>&nbsp;
        <a className='nowrap' href={t('offers.directory.INFO_PANEL_SUPPORT_LINK')} target='_blank'><FormattedMessage id='Learn more'/></a>
      </div>
    </Panel>
  );
};

const NewOfferButton = observer(({uiState}) => {
  return (
    <div className='clearfix'>
      <div className='right my3'>
        <Button onClick={() => uiState.goToNewOfferPage()}>
          <FormattedMessage id={'offers.directory.Send New Offer'}/>
        </Button>
      </div>
    </div>
  );
});

const OfferDirectory = observer(({uiState}) => {
  const {selectedOffer, company, offerPreviewModalOpen} = uiState;

  return (
    <div>
      <Display
        title={t('offers.directory.Offers')}
      />
      <Layout>
        <OfferInfoPanel/>
        <NewOfferButton uiState={uiState}/>
        <InteractiveTable
          uiState={uiState}
          title={t('offers.directory.Offers')}
          columns={COLUMNS}
          localization={LOCALIZATION}
          searchable={true}
          saveStateToUrl={true}
          sortable={false}
          editRoute='/:id'
          onRemove={offer => uiState.removeOffer(offer)}
          customLinks={offer => uiState.customLinksFor(offer)}
          onMount={agent => uiState.setInteractiveAgent(agent)}
          proxy={{
            type: 'async',
            modelType: types.OFFER_SUMMARY,
            model: OfferSummary,
            endpoint: endpoints.OFFERS
          }}
        >
          <DropdownFilter options={statusFilters()} attr='status'/>
        </InteractiveTable>
      </Layout>
      <RevokeOfferModal uiState={uiState}/>
      <RevokeReasonModal uiState={uiState}/>
      <OfferPreviewModal
        offer={selectedOffer}
        company={company}
        isOpen={offerPreviewModalOpen}
        onHide={() => uiState.closeOfferPreviewModal()}
      />
    </div>
  );
});

export default withState(OfferDirectory, OfferDirectoryState);
