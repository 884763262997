import {DomainObject} from 'shared/store';
import {action, observable} from 'mobx';
import TimeOffTypePolicy from './TimeOffTypePolicy';

class TimeOffType extends DomainObject {
  @observable id;
  @observable name;
  @observable typePolicy;
  @observable createdAt;
  @observable order;
  @observable includedInTimeTracking = true;

  @action merge(other) {
    super.merge(other, {
      typePolicy: TimeOffTypePolicy
    });
  }
}

export default TimeOffType;
