import React from 'react';
import PropTypes from 'prop-types';

const EmailPreview = ({children}) => {
  return (
    <div className='border px3 py2 bg-haze rounded'>
      {React.Children.map(children, child =>
        <div className='h4 tuatara py1'>{child}</div>
      )}
    </div>
  );
};

EmailPreview.propTypes = {
  children: PropTypes.node.isRequired
};

export default EmailPreview;
