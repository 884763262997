import {observable, computed} from 'mobx';

class HeaderViewModel {
  @observable sortable;
  @observable column;
  @observable sort = '';

  constructor(column, sortable) {
    this.column = column;
    this.sortable = sortable;
  }

  @computed get sortingAllowed() {
    return this.column.sortable !== false && this.sortable && this.sortBy;
  }

  @computed get sortBy() {
    return this.column.sortBy || this.column.attribute;
  }
}

export default HeaderViewModel;
