import React from 'react';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import {observer} from 'mobx-react';
import {withState, responsive, t} from 'shared/core';
import {Button, Panel, TextArea, AutosaveStatus} from 'components';
import MyCheckInResponseContainerState from './state/MyCheckInResponseContainerState';
import {Display} from 'components';

const CheckinHeader = observer(() => {
  return (
    <div className='table-cell align-middle'>
      <span><FormattedMessage id="performance.Check-In"/> </span>
      <span className='waterloo'>{moment().format('MMMM Do, YYYY')}</span>
    </div>
  );
});

const MyCheckInResponseForm = observer(({checkin, uiState}) => {
  return (
    <Panel className='lg-col-10'>
      <Panel.Header title={<CheckinHeader/>}/>
      {checkin.answers.map((answer, index) =>
        <div className='clearfix mb1' key={index}>
          <div className='medium'>{`${index + 1}. ${answer.question.text}`}</div>
          {
            checkin.completed ?
              (<div className='jumbo my2 fs-hide'>{answer.content}</div>) :
              (
                <TextArea
                  value={answer.content || ''}
                  onChange={(e) => uiState.updateAnswerContent(e, answer)}
                  placeholder='performance.Add a response...'
                  className='border-none pl0 full-width jumbo'
                />
              )
          }
        </div>
      )}
      <div className='right-align'>
        {!checkin.completed &&
        <div>
          <AutosaveStatus autosaver={checkin.autosaver}/>
          <Button className='ml2' onClick={uiState.onSubmit}>
            <FormattedMessage id="performance.Submit Check-In"/>
          </Button>
        </div>
        }
      </div>
    </Panel>
  );
});

const MyCheckInResponseContainer = observer(({uiState}) => {
  const {checkin} = uiState;

  return (
    <div>
      <Display backPath='/checkins' backTitle={t('performance.My Check-Ins')}/>
      <div className='Container'>
        <div className='clearfix'>
          <MyCheckInResponseForm
            checkin={checkin}
            uiState={uiState}
          />
        </div>
      </div>
    </div>
  );
});

export default responsive(withState(withRouter(MyCheckInResponseContainer), MyCheckInResponseContainerState));
