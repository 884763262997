import _ from 'lodash';
import {Steps} from 'shared/tools';

const STEPS = [
  {
    name: 'reports.custom.edit.Choose fields',
    location: 'fields',
  },
  {
    name: 'reports.custom.edit.Field order',
    location: 'reorder',
  },
  {
    name: 'reports.custom.edit.Filter results',
    location: 'filters',
  },
  {
    name: 'reports.custom.edit.Review',
    location: 'review',
  }
].map((s, i) => ({
  ...s,
  index: i + 1
}));

const singleton = new Steps(STEPS);

export default singleton;
