import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Modal, ModalDangerButtons} from 'components/modals';
import DeleteExplainer from './DeleteExplainer';

const Title = observer(({employee}) => {
  return <span className='scarlet'>
    <FormattedMessage
      id='employees.profile.delete_employee.MODAL_TITLE'
      values={{employeeName: employee.name}}
    />
  </span>;
});

const DestroyEmployeeModal = observer(({uiState, modalOpen}) => {
  const {employee} = uiState;

  return (
    <Modal
      size='sm'
      title={<Title employee={employee}/>}
      translateTitle={false}
      isOpen={modalOpen}
      onHide={() => uiState.closeDestroyEmployeeModal()}
    >
      <DeleteExplainer employee={employee} parentState={uiState}/>
      <ModalDangerButtons
        onSave={() => uiState.deleteEmployee()}
        onCancel={() => uiState.closeDestroyEmployeeModal()}
        disabled={!uiState.deleteConfirmationMatch}
      />
    </Modal>
  );
});

export default DestroyEmployeeModal;
