import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const EmployeeTrainingStatus = observer(({completed}) => {
  return (
    <FormattedMessage
      id={completed ? 'Yes' : 'No'}
    />
  );
});

export {
  EmployeeTrainingStatus
};
