import holidays2021 from './2021';
import holidays2022 from './2022';
import holidays2023 from './2023';
import holidays2024 from './2024';
import holidays2025 from './2025';
import _ from 'lodash';

const calendar = {
  2021: holidays2021,
  2022: holidays2022,
  2023: holidays2023,
  2024: holidays2024,
  2025: holidays2025
};

export default {
  for(year) {
    if (this.has(year)) {
      return calendar[year];
    } else {
      return [];
    }
  },

  has(year) {
    return year in calendar;
  },

  years() {
    return _.keys(calendar);
  }
};
