import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import Spinner from 'components/spinners/Spinner';

const ButtonSpinner = observer(({processing}) => {
  if (!processing) return null;

 return (
    <div className='absolute'>
      <Spinner size='Btn'/>
    </div>
  );
});

const ButtonSpan = observer(({children, processing}) => {
  return (
    <span className={`Btn--content ${processing ? 'loading' : ''}`} tabIndex='-1'>
      {children}
      <ButtonSpinner processing={processing}/>
    </span>
  );
});

class Button extends React.Component {
  @observable processing = false;

  onClick = async (e, cb) => {
    if (this.processing) return null;

    e.preventDefault();
    this.processing = true;
    this._handleCallback(e, cb);
  };

  _handleCallback = async (e, cb) => {
    try {
      await Promise.resolve(cb(e));
    } finally {
      if (!this.props.staysDisabledAfterClick) {
        this.processing = false;
      }
    }
  }

  getButtonClasses = () => {
    const {size, trait, className, hint, disabled, useDefaultStyles} = this.props;

    if (!useDefaultStyles) {
      return className;
    }

    return `Btn Btn--${size} Btn--${trait} ${className} ${hint && !disabled ? 'HintContainer' : ''}`;
  };

  render() {
    const {
      // eslint-disable-next-line no-unused-vars
      size,
      // eslint-disable-next-line no-unused-vars
      trait,
      // eslint-disable-next-line no-unused-vars
      children,
      // eslint-disable-next-line no-unused-vars
      className,
      // eslint-disable-next-line no-unused-vars
      useDefaultStyles,
      // eslint-disable-next-line no-unused-vars
      staysDisabledAfterClick,

      disabled,
      onClick,
      hint,
      ...rest
    } = this.props;

    return (
      <button className={this.getButtonClasses()}
              disabled={disabled}
              onClick={async (e) => this.onClick(e, onClick)}
              {...rest}>
        <ButtonSpan processing={this.processing}>
          {children}
        </ButtonSpan>
        {hint && <div className='Hint'>{hint}</div>}
      </button>
    );
  }
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.array
  ]),
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  trait: PropTypes.oneOf([
    'primary',
    'default',
    'destructive',
    'filter',
    'fancy-filter'
  ]),
  staysDisabledAfterClick: PropTypes.bool
};

Button.defaultProps = {
  className: '',
  disabled: false,
  size: 'md',
  trait: 'primary',
  hint: '',
  useDefaultStyles: true,
  staysDisabledAfterClick: false
};

export default observer(Button);
