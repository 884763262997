import React from 'react';
import DatePicker from 'components/DatePicker';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {withState} from 'shared/core';
import BirthdayPickerState from './BirthdayPickerState';

const BirthdayPicker = observer(({uiState, ...rest}) => {
  const {age, showAge, validAge, value, errorMessage} = uiState;

  return (
    <div>
      <DatePicker {...rest} value={value} errorMessage={errorMessage} />
      {showAge && <div className={`${validAge ? 'jumbo' : 'scarlet'}`} style={{paddingTop: '4px'}}>
        <FormattedMessage id='components.birthday_picker.CURRENT_AGE' values={{age}} />
      </div>}
    </div>
  );
});

export default withState(BirthdayPicker, BirthdayPickerState);

// BirthdayPicker is simply a DatePicker that shows you the age of the currently selected date
// Examples include: Employee Profile (personal information), Spouse Fields (benefit info change requests)
//
// props:
//
// - minAge: number that specifies the acceptable lower range of the age
//
// - maxAge: number that specifies the acceptable upper range of the age
//
// Note on minAge/maxAge:
// This range does not necessarily correspond to the validation done on the backend.
// It simply shows the "Current Age" in red if the age exceeds the limits you have provided.
// This is to ensure that users are aware that they must also specify the year they were born.
