import React from 'react';
import {observer} from 'mobx-react';
import {EmployeeNameWithAvatar, Pagination, Panel, Table} from 'components';
import {t} from 'shared/core';

const DirectReportsSection = observer(({uiState}) => {
  const {paginatedDirectReports} = uiState;

  if (paginatedDirectReports.length === 0) return;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.Direct Reports')}/>
      <Table
        models={paginatedDirectReports}
        trait='xcompact'
        columns={
          [
            {
              width: 12,
              render: model => <EmployeeNameWithAvatar href={`/employees/${model.id}`} employee={model}/>
            }
          ]
        }
        showHeaders={false}
        showLinks={false}
      />
      <Pagination
        pagination={uiState.directReportsPagination}
        paginationUpdated={pagination => uiState.updateDirectReportsPagination(pagination)}
      />
    </Panel>
  );
});

export default DirectReportsSection;
