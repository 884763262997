import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {t} from 'shared/core';

const PayRate = observer(({record}) => {
  return (
    <div>
      <FormattedMessage
        id='employees.profile.role.PAY_RATE'
        values={{
          payRate: record.payRateFormatted,
          payRateUnit: t(record.payRateUnitView)
        }}
      />
    </div>
  );
});

export default PayRate;
