import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {EmployeeBenefitChangeFormState} from '../state';
import {Display, Layout, Panel, Button} from 'components';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';
import EnrolmentForCarrier from './EnrolmentForCarrier';

const EmployeeBenefitChangeForm = observer(({uiState}) => {
  const {enrolmentViewModels, employee, errors} = uiState;

  return (
    <div>
      <Display
        backTitle={t('benefits.benefit_info.edit.Benefit Enrolments')}
        backPath={'/benefit_enrolments'}
        title={t('benefit_enrolment.change.CHANGE_ENROLMENT', { name: employee.name })}
      />
      <Layout>
        <Panel.Stack>
          <Panel>
            <Panel.Header
              title={t('benefit_enrolment.change.Changing Benefits Enrolment')}
              description={t('benefit_enrolment.change.change_description', { platform: uiState.platform })}
            />
          </Panel>
          {enrolmentViewModels.map(enrolmentViewModel =>
            <EnrolmentForCarrier
              key={enrolmentViewModel.enrolment.id}
              enrolmentViewModel={enrolmentViewModel}
              uiState={uiState}
              errors={_.get(errors, `employeeBenefitEnrollments[${enrolmentViewModel.enrolment.id}]`)}
            />
          )}
          <Panel>
            <div className='flex justify-content-end'>
              <Button onClick={() => uiState.saveChanges()}>
                <FormattedMessage id='Save'/>
              </Button>
            </div>
          </Panel>
        </Panel.Stack>
      </Layout>
    </div>
  );
});

export default withState(EmployeeBenefitChangeForm, EmployeeBenefitChangeFormState);
