import React from 'react';
import {observer} from 'mobx-react';
import Step from './steps';
import steps from 'reports/custom/edit/state/steps';
import {withState, t} from 'shared/core';
import ReportEditState from 'reports/custom/edit/state/ReportEditState';
import {Flow} from 'components';

const ReportEditContainer = observer(({uiState}) => {
  return (
    <Flow
      name={uiState.report.name || t('reports.custom.Untitled report')}
      backTitle={t('reports.custom.edit.Reports')}
      backRoute='/custom'
      steps={steps}
      component={Step}
      uiState={uiState}
      baseUrl={`/custom/${uiState.match.params.id}/edit`}
      report={uiState.report}
      onStepSubmitted={step => uiState.onStepSubmitted(step)}
      onStepChanged={step => uiState.onStepChanged(step)}
    />
  );
});

export default withState(ReportEditContainer, ReportEditState);
