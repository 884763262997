import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Layout, FormLayout, Submit, Panel, Input} from 'components';
import {withState, t} from 'shared/core';
import OnboardEmergencyState from '../state/OnboardEmergencyState';

const OnboardEmployeeEmergencyInfoContainer = observer(({uiState}) => {
  const {employee} = uiState;

  return (
    <Layout>
      <Panel.Stack>
        <Panel>
          <Panel.Header title={t('onboard.emergency.Emergency Contact Information')} />
          <FormLayout>
            <Input value={employee.ec1Name}
              label='onboard.emergency.Emergency Contact #1'
              placeholder='onboard.emergency.Name'
              onChange={e => employee.merge({ec1Name: e.target.value})} />
            <Input value={employee.ec1Relationship}
              label='onboard.emergency.Relationship'
              placeholder='onboard.emergency.ex. Father'
              onChange={e => employee.merge({ec1Relationship: e.target.value})} />
            <Input value={employee.ec1Phone}
              label='onboard.emergency.Phone'
              onChange={e => employee.merge({ec1Phone: e.target.value})} />
          </FormLayout>
        </Panel>
        <Panel>
          <FormLayout>
            <Input value={employee.ec2Name}
              label='onboard.emergency.Emergency Contact #2'
              placeholder='onboard.emergency.Name'
              onChange={e => employee.merge({ec2Name: e.target.value})} />
            <Input value={employee.ec2Relationship}
              label='onboard.emergency.Relationship'
              placeholder='onboard.emergency.ex. Father'
              onChange={e => employee.merge({ec2Relationship: e.target.value})} />
            <Input value={employee.ec2Phone}
              label='onboard.emergency.Phone'
              onChange={e => employee.merge({ec2Phone: e.target.value})} />
          </FormLayout>
        </Panel>
      </Panel.Stack>
      <div className='my3'>
        <Submit size='lg' onClick={() => uiState.nextStep()}>
          <FormattedMessage id='onboard.employee_info.Next step'/>
        </Submit>
      </div>
    </Layout>
  );
});

export default withState(OnboardEmployeeEmergencyInfoContainer, OnboardEmergencyState);
