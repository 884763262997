import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {WriteSurveyContainerState} from '../state';
import {AutosaveStatus, Display, Layout, Panel, Button} from 'components';
import {FormattedMessage} from 'react-intl';
import {QuestionFieldFactory} from 'components/forms/question/components';

const WriteSurveyForm = observer(({uiState}) => {
  const {response, errors} = uiState;

  return (
      <Panel>
        {response.sortedAnswers.map((answer, index) =>
          <div className='mb3' key={answer.id}>
            <QuestionFieldFactory
              errorMessage={_.get(errors, `answers.${index}.answer`)}
              answer={answer}
              required={answer.question.required}
              disabled={!response.hasLink('submit')}
              onChanged={() => uiState.autosaveResponse()}
            />
          </div>)
        }
        {response.hasLink('submit') && <div className='right-align'>
          <AutosaveStatus autosaver={response.autosaver}/>
          <Button className='pl2' onClick={() => uiState.submitResponse()}>
            <FormattedMessage id={uiState.submitMessage}/>
          </Button>
        </div>}
      </Panel>
  );
});

const SubmittedSurveyForm = (() => {
  return (
    <Panel>
      <Panel.Header title={t('surveys.view.Thank you for your response.')}/>
      <FormattedMessage id='surveys.view.Your response has been submitted anonymously'/>
    </Panel>
  );
});


const WriteSurveyContainer = observer(({uiState}) => {
  const {response, survey} = uiState;

  const showSurveyForm = !survey.anonymous || response.hasLink('submit');
  const showSubmittedSurveyForm = !showSurveyForm;

  return (
    <React.Fragment>
      <Display
        title={`${survey.name}`}
        backPath={`/`}
      />
      <Layout>
        <Layout.Section>
          {showSurveyForm && <WriteSurveyForm uiState={uiState}/>}
          {showSubmittedSurveyForm && <SubmittedSurveyForm/>}
        </Layout.Section>
      </Layout>
    </React.Fragment>
  );
});

export default withState(WriteSurveyContainer, WriteSurveyContainerState);
