import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {Panel, EmployeeNameWithAvatar, Tag, QuickTip} from 'components';
import {HelpLink} from 'components/links';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import ChangesForUpdate from './ChangesForUpdate';

const UpdateStatus = observer(({update}) => {
  if (!_.includes(['in_review', 'rejected'], update.status)) return null;

  return (
    <Tag className='ml2 align-text-top' colour='scarlet'>
      <FormattedMessage id={`benefits.updates.${update.status}`}/>
    </Tag>
  );
});

const RejectedUpdateHelpLink = observer(({update}) => {
  if (update.status !== 'rejected') return null;

  return (
    <div className='pl2 inline h4'>
      <HelpLink
        title={t('benefits.updates.help.What does rejected mean?')}
        explanation={
          {
            header: t('benefits.updates.help.What is a rejected update?'),
            paragraphs: [
              t('benefits.updates.help.what_is_a_rejected_update_explanation_1'),
              t('benefits.updates.help.what_is_a_rejected_update_explanation_2')
            ]
          }
        }
      />
    </div>
  );
});

const RejectedUpdateComment = observer(({update}) => {
  if (update.status !== 'rejected') return null;
  if (!update.comment) return null;

  return (
    <div className='mb2 h4'>
      <QuickTip
        trait='error'
        header={t('benefits.updates.The contents of this update could not be processed and were not synced with your carrier for the following reasons:')}
        paragraphs={[
          <div className='whitespace-pre-wrap'>{update.comment}</div>
        ]}
      />
    </div>
  );
});

const UpdateHeader = observer(({update}) => {
  return (
    <div>
      <FormattedMessage id={`models.benefit_updates.actions.${update.action}`}/>
      <UpdateStatus update={update}/>
      <RejectedUpdateHelpLink update={update}/>
    </div>
  );
});

const BenefitUpdateForEmployee = observer(({update}) => {
  return (
    <Panel>
      <Panel.Header title={<UpdateHeader update={update}/>}/>
      <div className='clearfix'>
        <RejectedUpdateComment update={update}/>
        <div className='col col-4'>
          <div className='black medium mb1'>
            <FormattedMessage id='benefits.updates.Employee'/>
          </div>
          <div>
            <EmployeeNameWithAvatar employee={update.employee}/>
          </div>
        </div>
        <div className='col col-8'>
          <ChangesForUpdate update={update}/>
        </div>
      </div>
    </Panel>
  );
});

export default BenefitUpdateForEmployee;
