import React from 'react';
import {observer} from 'mobx-react';
import {withState, withDisplay, t} from 'shared/core';
import {GreenhouseConfigurationContainerState} from '../state';
import {Layout, Panel, Button, FormLayout, Input} from 'components';
import SubNav from 'company_settings/SubNav';
import {FormattedMessage} from 'react-intl';
import DeleteGreenhouseKeyModal from './DeleteGreenhouseKeyModal';

const Instructions = () => {
  return (
    <Panel>
      <Panel.Header title={t('company_settings.applications.jazz_hr.How does it work?')}/>
      <div className='mb2'>
        <FormattedMessage id='company_settings.applications.greenhouse.explanation_1'/>&nbsp;
        <FormattedMessage id='company_settings.applications.greenhouse.explanation_2'/>&nbsp;
        <FormattedMessage id='company_settings.applications.greenhouse.explanation_3'/>&nbsp;
      </div>
      <Panel.Header title={t('company_settings.applications.greenhouse.How to configure Greenhouse to work with Collage')}/>
      <ul className='ml2'>
        <li><FormattedMessage id='company_settings.applications.greenhouse.Log in to your Greenhouse account.'/></li>
        <li><FormattedMessage id='company_settings.applications.greenhouse.CLICK_SETTINGS'/></li>
        <li>
          <FormattedMessage id='company_settings.applications.greenhouse.CLICK_DEV_CENTER' values={{
              devcenter: <span className='medium'><FormattedMessage id='company_settings.applications.greenhouse.Dev Center'/></span>,
          }}/>
        </li>
        <li>
          <FormattedMessage id='company_settings.applications.greenhouse.CLICK_WEB_HOOKS' values={{
            hook: <span className='medium'><FormattedMessage id='company_settings.applications.greenhouse.Web Hooks'/></span>,
          }}/>
        </li>
        <li><FormattedMessage id='company_settings.applications.greenhouse.NAME_WEB_HOOK'/></li>
        <li>
          <FormattedMessage id='company_settings.applications.greenhouse.WHEN_EXPLANATION' values={{
            when: <span className='medium'><FormattedMessage id='company_settings.applications.greenhouse.When'/></span>,
            hired: <span className='medium'><FormattedMessage id='company_settings.applications.greenhouse.Candidate has been hired'/></span>
          }}/>
        </li>
        <li>
          <FormattedMessage id='company_settings.applications.greenhouse.SET_VALUES_EXPLANATION' values={{
            endpoint: <span className='medium'><FormattedMessage id='company_settings.applications.greenhouse.Endpoint'/></span>,
            secretkey: <span className='medium'><FormattedMessage id='company_settings.applications.greenhouse.Secret Key'/></span>
          }}/>
        </li>
        <li>
          <FormattedMessage id='company_settings.applications.greenhouse.CLICK_CREATE_WEB_HOOK' values={{
            create: <span className='medium'><FormattedMessage id='company_settings.applications.greenhouse.Create Web Hook'/></span>
          }}/>
        </li>
      </ul>
    </Panel>
  );
};

const GreenhouseConfigurationContainer = observer(({uiState}) => {
  const {company} = uiState;

  return (
    <div>
      <Layout>
        <Layout.Section secondary>
          <Panel.Stack loose>
            <Panel>
              <Panel.Header title={t('company_settings.applications.greenhouse.Connect your Greenhouse account')}/>
              <FormLayout>
                <Input
                  label='company_settings.applications.greenhouse.URL Endpoint'
                  value={uiState.webhookUrl}
                  disabled={true}
                  onChange={() => {}}
                />
                <Input
                  label='company_settings.applications.greenhouse.Secret Key'
                  value={uiState.greenhouseApiKey}
                  disabled={true}
                  onChange={() => {}}
                />
              </FormLayout>
              <div className='right-align pt2'>
              {!company.greenhouseApiKey &&
                <Button onClick={() => uiState.generateGreenhouseKey()}>
                  <FormattedMessage id='company_settings.applications.greenhouse.Generate Key'/>
                </Button>}
              {company.greenhouseApiKey &&
                <Button onClick={() => uiState.openDeleteModal()}>
                  <FormattedMessage id='company_settings.applications.greenhouse.Delete Key'/>
                </Button>}
            </div>
            </Panel>
            <Instructions/>
          </Panel.Stack>
        </Layout.Section>
      </Layout>
      <DeleteGreenhouseKeyModal uiState={uiState}/>
    </div>
  );
});

export default withDisplay(
  withState(GreenhouseConfigurationContainer, GreenhouseConfigurationContainerState),
  () => <SubNav title={t('company_settings.subnav.Greenhouse')}/>
);
