import {observable, computed} from 'mobx';
import {DomainObject} from 'shared/store';
import _ from 'lodash';

export class Reviewee extends DomainObject {
  @observable reviewer;
  @observable employee;

  @computed get departmentName() {
    return _.get(this.employee, 'department.name');
  }

  @computed get checked() {
    const {parameters} = this.reviewer.rules[0];
    return _.includes(parameters, this.employee.id);
  }

  set checked(value) {
    const {parameters} = this.reviewer.rules[0];

    if (value) {
      if (!_.includes(parameters, this.employee.id)) {
        parameters.push(this.employee.id);
      }
    } else {
      _.remove(parameters, id => id === this.employee.id);
    }
  }
}

export class Reviewer extends DomainObject {
  allEmployees;
  reviewerId;
  @observable rules;
  @observable employee;
  @observable reviewees = null;

  @computed get rule() {
    if (this.reviewees === null) {
      return 'direct_report';
    }

    return 'employee';
  }

  set rule(value) {
    if (value === 'direct_report') {
      this.reviewees = null;
      this.rules = [{ruleType: 'direct_report', parameters: [this.reviewerId]}];
    } else {
      this.reviewees = this.reviewees || this.allEmployees.filter(e => e.id !== this.reviewerId).map(e => new Reviewee({employee: e, reviewer: this}));
      this.rules = [{ruleType: 'employee', parameters: []}];
    }
  }

  constructor(json, allEmployees) {
    super(json);
    this.allEmployees = allEmployees;
    if (_.isEmpty(this.rules) || this.rules[0].ruleType === 'direct_report') {
      this.rule = 'direct_report';
    } else if (this.rules[0].ruleType === 'employee') {
      this.reviewees = this.allEmployees.filter(e => e.id !== this.reviewerId).map(e => new Reviewee({employee: e, reviewer: this}));
      for (const reviewee of this.reviewees) {
        reviewee.checked = _.includes(this.rules[0].parameters, reviewee.employee.id);
      }
    } else {
      throw new Error(`Rule type ${this.rules[0].ruleType} is not supported.`);
    }
  }

  toJS() {
    const json = super.toJS();
    return _.omit(json, ['reviewees', 'employee', 'allEmployees']);
  }
}

export class SelectableReviewer {
  constructor(manager, allEmployees) {
    this.manager = manager;
    this.allEmployees = allEmployees;
  }

  reviewer;
  manager;

  @computed get checked() {
    return !!this.reviewer && !this.reviewer._destroy;
  }

  set checked(value) {
    this.reviewer = this.reviewer || new Reviewer({reviewerId: this.manager.id}, this.allEmployees);
    if (value) {
      delete this.reviewer._destroy;
    } else {
      if (this.reviewer.id) {
        this.reviewer._destroy = true;
      } else {
        delete this.reviewer;
      }
    }
  }
}
