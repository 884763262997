import React from 'react';
import {observer} from 'mobx-react';
import {NavLink} from 'react-router-dom';
import {Display} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const Subnav = observer(({survey, match, scope}) => {
  return (
    <Display title={survey.name} backTitle={t('surveys.Surveys')} backPath='/manage'>
      {scope.summary && <NavLink activeClassName='active' to={`/manage/survey/${match.params.id}/summary`}>
        <FormattedMessage id='surveys.view.Results'/>
      </NavLink>}
      {scope.overview && <NavLink activeClassName='active' to={`/manage/survey/${match.params.id}/admin`}>
        <FormattedMessage id='surveys.view.Admin'/>
      </NavLink>}
    </Display>
  );
});

export default Subnav;
