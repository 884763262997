import React from 'react';
import {observer} from 'mobx-react';
import {Label} from 'components';

const TextFieldView = observer(({answer}) => {
  return (
    <div>
      <div className='medium h3'>{answer.question.name}</div>
      <div><Label model={answer} path='content'/></div>
    </div>
  );
});

export default TextFieldView;
