import React from 'react';

const ButtonGroup = ({children}) => {
  return (
    <div className='ButtonGroup'>
      {children}
    </div>
  );
};

// use this to render buttons outside of a panel

export default ButtonGroup;
