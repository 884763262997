import {DomainStore} from 'shared/store';
import {endpoints, types, t} from 'shared/core';
import {action, observable} from 'mobx';
import Cycle from 'stores/performance/Cycle';
import {successAlert} from 'shared/tools';

class CycleDirectoryState {
  store = new DomainStore();
  history;

  @observable selectedCycle = null;

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL
    ]);

    this.allEmployees = this.store.getEmployees();
  }

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  setInteractiveAgent(agent) {
    this.interactiveAgent = agent;
  }

  buildCycle(json) {
    const cycle = new Cycle(json);
    cycle.init(this.allEmployees);

    return cycle;
  }

  @action async createCycle() {
    const {model} = await this.store.post(
      endpoints.PERFORMANCE.CYCLES,
      types.PERFORMANCE.CYCLE
    );

    this.history.push(`/cycles/${model.id}`);
  }

  async archiveCycle() {
    const {model} = await this.store.post(
      this.selectedCycle.links.archive.href,
      types.PERFORMANCE.CYCLE
    );

    if (model) {
      this.interactiveAgent.refresh();
      successAlert(t('performance.Check-In Cycle Archived'));
      this.closeArchiveCycleModal();
    }
  }

  async destroyCycle(cycle) {
    await this.store.destroy(cycle);
  }

  goToCycle(cycle) {
    this.history.push(`/cycles/${cycle.id}`);
  }

  customLinksFor(cycle) {
    const links = [];
    if (cycle.hasLink('archive')) {
      links.push({
        order: 0,
        text: 'performance.Archive',
        action: model => this.openArchiveCycleModal(model)
      });
    }

    return links;
  }
}

export default CycleDirectoryState;
