import InfiniteScroll from 'react-infinite-scroll-component';
import React from 'react';
import {observer} from 'mobx-react';
import {withState, auth} from 'shared/core';
import {Spinner} from 'components';
import {FormattedMessage} from 'react-intl';
import FeedItemFactory from './FeedItemFactory';
import FeedItemsState from '../state/FeedItemsState';

const FeedSection = observer(({message, items, feedState}) => {
  if (items.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      <div className='FeedSectionTitle medium h3 mb1'>
        <FormattedMessage id={message}/>
      </div>
      <div className='TimelineSeparator'/>
      <div>
        {items.map((feedItem, index) =>
          <FeedItemFactory
            key={index}
            feedItem={feedItem}
            Emoji={feedState.Emoji}
            openEditAnnouncementModal={(announcement) => feedState.openEditAnnouncementModal(announcement)}
            openDeleteAnnouncementModal={(announcement) => feedState.openDeleteAnnouncementModal(announcement)}
          />)
        }
      </div>
    </React.Fragment>
  );
});

const EmptyState = observer(({uiState}) => {
  const {feedItems} = uiState;
  if (feedItems.length > 0) {
    return null;
  }

  if (!auth.user.admin || (auth.user.anniversaryCelebrationVisibility &&
    auth.user.startDateCelebrationVisibility && auth.user.birthdayCelebrationVisibility)) {
    return (
      <div className='center jumbo rounded h5 py2 px1 Table-emptyState'>
        <FormattedMessage id='hr_dashboard.feed.No items to display'/>
      </div>
    );
  } else {
    return (
      <div className='center jumbo rounded h5 py2 px1 Table-emptyState'>
        <FormattedMessage
          id='hr_dashboard.feed.No items to display. You can enable more items in Company Settings/Visibility Settings.'
        />
      </div>
    );
  }
});

const FeedItems = observer(({uiState}) => {
  const {
    nextWeeksFeedItems,
    currentFeedItems,
    olderFeedItems,
    nextLink,
    feedState
  } = uiState;

  return (
    <InfiniteScroll
      next={() => uiState.loadMore()}
      style={{overflow: 'unset'}}
      hasMore={!!nextLink}
      loader={<Spinner/>}>
      <EmptyState uiState={uiState}/>
      <FeedSection
        message='hr_dashboard.feed.Next 7 days'
        items={nextWeeksFeedItems}
        feedState={feedState}
      />
      <FeedSection message='hr_dashboard.feed.Current' items={currentFeedItems} feedState={feedState}/>
      <FeedSection message='hr_dashboard.feed.Older' items={olderFeedItems} feedState={feedState}/>
    </InfiniteScroll>
  );
});

export default withState(FeedItems, FeedItemsState);
