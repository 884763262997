import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {OfferDirectory} from '../directory';
import {OfferEditor} from '../edit';

const OffersPage = () => {
  return (
    <Router basename='/offers'>
      <Switch>
        <Route exact path='/' component={OfferDirectory}/>
        <Route exact path='/new' render={(props) => <OfferEditor {...props} action='new'/>}/>
        <Route exact path='/:id' render={(props) => <OfferEditor {...props} action='edit'/>}/>
      </Switch>
    </Router>
  );
};

export default OffersPage;
