import {DomainObject} from 'shared/store';
import {observable, action, computed} from 'mobx';
import PerformanceReviewCycle from './PerformanceReviewCycle';
import Employee from 'stores/employees/Employee';
import _ from 'lodash';

class PerformanceReviewRevieweeSummary extends DomainObject {
  @observable id;
  @observable cycle;
  @observable employee;
  @observable reviewers = [];
  @observable completedReviews;
  @observable totalReviews;
  @observable completedEmployeeReviews;
  @observable totalEmployeeReviews;

  @action merge(other) {
    super.merge(other, {
      cycle: PerformanceReviewCycle,
      employee: Employee,
      reviewers: [Employee],
    });
  }

  @computed get link() {
    return `/reviews/${this.cycle.id}/reviewee/${this.id}/employee/${this.employee.id}/${this.employee.urlSlug}`;
  }
}

export default PerformanceReviewRevieweeSummary;
