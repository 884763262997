import React from 'react';
import PropTypes from 'prop-types';
import {fetchReport, shouldRefetchReport} from 'reports/tools';
import {Spinner} from 'components';
import {loader} from 'shared/core';
import _ from 'lodash';
import ReportEmptyState from './ReportEmptyState';

const MetricReport = ({rows, meta}) => {
  if (_.isEmpty(meta)) return null;

  if (_.isEmpty(rows)) {
    return <ReportEmptyState/>;
  }
  const {columns} = meta;

  return _.map(columns, (column) => (
    <div className='my1'>
      <span>{column.label}</span>
      <div className='h1'>
        {_.get(rows[0].values, column.id)}
      </div>
    </div>
  ));
};

MetricReport.propTypes = {
  children: PropTypes.element,
  rows: PropTypes.array,
  chartType: PropTypes.string,
  meta: PropTypes.object
};

MetricReport.defaultProps = {
  chartType: 'column'
};

export default loader(MetricReport, fetchReport, shouldRefetchReport, Spinner);
