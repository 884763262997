import {endpoints, types} from 'shared/core';
import Reminder from 'stores/reminders/Reminder';
import CustomField from 'stores/custom_fields/CustomField';
import {DomainStore} from 'shared/store';

class ReminderEditStore extends DomainStore {
  async load(id) {
    const endpointsToLoad = [
      endpoints.CUSTOM_FIELDS.ALL,
      endpoints.USERS.ACTIVE
    ];
    if (id) {
      endpointsToLoad.push(endpoints.REMINDER.with(id));
    }

    await this._compose(endpointsToLoad);
  }

  getCustomFieldsWithDates() {
    return this._getAll(
      types.CUSTOM_FIELD,
      t => t.fieldType === 'date',
      CustomField
    );
  }

  getReminder(id) {
    return this._getAll(types.REMINDER, {id}, Reminder)[0];
  }

  async postReminder(reminder) {
    return this.post(
      endpoints.REMINDERS,
      types.REMINDER,
      reminder
    );
  }
}

const singleton = new ReminderEditStore();

export default singleton;
