import i18n from 'shared/i18n';

const LOCALE_MAPPING = {
  'en': 'en-CA',
  'fr': 'fr-CA'
};

const formatNumber = (value, minDigits = 0, maxDigits = 2) => {
  const locale = LOCALE_MAPPING[i18n.locale] || i18n.locale;
  return new Intl.NumberFormat(locale,
    {minimumFractionDigits: minDigits, maximumFractionDigits: maxDigits}
  ).format(value);
};

export default formatNumber;
