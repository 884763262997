import {observable, action, computed} from 'mobx';
import qs from 'qs';
import _ from 'lodash';

class ChargifyState {
  chargifyStore;
  countryStore;

  @observable chargifyDirectParameters;
  @observable errors = {};
  @observable submitting = false;
  @observable countries = [];
  @observable regions = [];

  constructor(stores) {
    this.chargifyStore = stores.chargifyStore;
    this.countryStore = stores.countryStore;
  }

  publishEvent(event) {
    window.parent.postMessage({event, source: 'chargify' }, location.origin);
  }

  @action async loadChargifyParams(callId) {
    this.chargifyDirectParameters = await this.chargifyStore.loadChargifyDirectParameters(callId);
    if (this.chargifyDirectParameters.billingCountry) {
      await this.loadRegions();
    } else {
      await this.selectCanadaByDefault();
    }
  }

  @action async selectCanadaByDefault() {
    this.chargifyDirectParameters.billingCountry = {
      id: 'CA'
    };
    await this.loadRegions();
  }

  @action async load() {
    this.countries = await this.countryStore.loadCountries();

    const callId = qs.parse(location.search.replace('?', ''))['call_id'];

    if (callId) {
      await this.loadChargifyParams(callId);
      const {model, errors} = await this.chargifyStore.checkChargifyDirectCallResult(callId);
      _.forOwn(errors, (v, k) => {
        const prefix = _.capitalize(_.startCase(k)) + ' ';
        errors[k] = v.replace(prefix, '');
      });
      this.errors = errors;

      if (model) {
        this.publishEvent('success');
      }
    } else {
      await this.loadChargifyParams(null);
    }
  }

  @action submitForm() {
    setTimeout(() => {
      this.submitting = true;
    }, 0);
  }

  @action async loadRegions() {
    this.regions = await this.countryStore.getOrLoadRegions(this.chargifyDirectParameters.billingCountry.id, false);
  }

  @action async changeCountry(country) {
    this.chargifyDirectParameters.merge({billingCountry: country});
    await this.loadRegions();
  }

  @computed get years() {
    const CURRENT_YEAR = new Date().getFullYear();
    return _.range(CURRENT_YEAR, CURRENT_YEAR + 11).map(i => i.toString());
  }
}

export default ChargifyState;
