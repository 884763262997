import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import React from 'react';

const WorkHoursAndDays = observer(({hours, days}) => {
  return (
    <FormattedMessage
      id='employees.profile.time_off.request.custom_hours.HOURS_AND_DAYS'
      values={{
        hours,
        days: (
          <span className='waterloo'>
            <FormattedMessage id='employees.profile.time_off.request.custom_hours.DAYS' values={{days}}/>
          </span>
        )
      }}
    />
  );
});

const DayOffHoursAndDays = observer(({model}) => {
  const {hours, days, hasHoliday, hasHalfDayHoliday, holidayName, hasCompanyDayOff} = model;

  if (hasCompanyDayOff) {
    return (
      <span className='jumbo'>
        <FormattedMessage id='employees.profile.time_off.request.custom_hours.Non-work Day'/>
      </span>
    );
  }

  if (hasHoliday) {
    return (
      <div>
        {hasHalfDayHoliday && <div>
          <WorkHoursAndDays hours={hours} days={days}/>
        </div>}
        <span className='jumbo'>{holidayName}</span>
      </div>
    );
  }

  return <WorkHoursAndDays hours={hours} days={days}/>;
});

export default DayOffHoursAndDays;
