import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Table} from 'components';
import {HtmlRender} from 'components';
import {t} from 'shared/core';

const COLUMNS = [
  {
    header: 'payroll.diffs.Field',
    attribute: 'name',
    width: 4
  },
  {
    header: 'payroll.diffs.Old Value',
    render: (model) => <HtmlRender html={model.oldValue}/>,
    width: 3
  },
  {
    header: 'payroll.diffs.New Value',
    render: (model) => <HtmlRender html={model.newValue}/>,
    width: 3
  },
  {
  header: 'payroll.diffs.Status',
  render: (model) => t(`payroll.diffs.${model.status}`),
  width: 2
  }
];

const PayrollSections = observer(({diff}) => {
  return (
    diff.payrollSections.map(section =>
      <Panel key={section.id}>
        <Panel.Header title={section.name}/>
        <Table
          columns={COLUMNS}
          models={section.payrollFields}
          showLinks={false}
        />
      </Panel>
    )
  );
});

export default PayrollSections;
