import React from 'react';
import {t} from 'shared/core';
import {observer} from 'mobx-react';
import {Display, Layout} from 'components';
import {withState} from 'shared/core';
import PayrollNotEnabledState from '../state/PayrollNotEnabledState';
import InterestedInPayrollModal from './InterestedInPayrollModal';
import PayrollInfo from './PayrollInfo';

const PayrollNotEnabled = observer(({uiState}) => {
  return (
    <div>
      <Display title={t('payroll.Payroll')}/>
      <Layout>
        <PayrollInfo uiState={uiState}/>
        <InterestedInPayrollModal uiState={uiState}/>
      </Layout>
    </div>
  );
});

export default withState(PayrollNotEnabled, PayrollNotEnabledState);
