import _ from 'lodash';
import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {t} from 'shared/core';
import {QuestionFieldFactory, AnswerResults} from 'components/forms';

const Quiz = observer(({uiState}) => {
  const {trainingStatusDetails, sortedAnswers, errors} = uiState;

  return (
    <React.Fragment>
      <Panel.Header title={t('training.view.Quiz')} />
      {sortedAnswers.map((answer, index) =>
        <div className='mb2' key={answer.id}>
          <QuestionFieldFactory
            disabled={!trainingStatusDetails.hasLink('complete')}
            answer={answer}
            required={true}
            errorMessage={_.get(errors, `answers.${index}.value`)}
            onChanged={() => trainingStatusDetails.autosaver.autosave()}
            modelType={'training/answer'}
          />
        </div>)}
    </React.Fragment>
  );
});

const Results = observer(({uiState}) => {
  const {trainingStatusDetails, sortedAnswers} = uiState;

  return (
    <React.Fragment>
      <Panel.Header
        title={t('training.view.Quiz Results', { percentage: '20', correct: '2', total: '10' })}
        description={trainingStatusDetails.totalAnswerCount > 0 ?
                       trainingStatusDetails.quizResultsView : null
                    }
      />
      <AnswerResults answers={sortedAnswers} />
    </React.Fragment>
  );
});

const Questions = observer(({uiState}) => {
  const {trainingStatusDetails} = uiState;
  if (!trainingStatusDetails.answers.length) return null;

  return trainingStatusDetails.completed ?
    <Results uiState={uiState} /> :
    <Quiz uiState={uiState} />;
});

export default Questions;
