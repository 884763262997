import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {TextArea, FormLayout, EmployeeNameWithAvatar, Select2, Input} from 'components';
import {Modal, ModalButtons} from 'components/modals';

const AvailableDaysMessage = observer(({adjustmentAccount}) => {
  if (adjustmentAccount.unlimited) {
    return (
      <FormattedMessage id='time_off.account_summaries.UNLIMITED_AVAILABLE_DAYS'
        values={{ type: adjustmentAccount.typeName }}
      />
    );
  }

  return (
    <FormattedMessage id='time_off.account_summaries.AVAILABLE_DAYS'
      values={{
        days: adjustmentAccount.adjustedAvailableInDays,
        type: adjustmentAccount.typeName
      }}
    />
  );
});

const BalanceAdjustmentModal = observer(({uiState}) => {
  const {adjustmentAccount, adjustmentNotes, errors} = uiState;
  if (!adjustmentAccount) return null;

  return (
    <Modal isOpen={uiState.balanceAdjustmentModalOpen} onHide={() => uiState.closeBalanceAdjustmentModal()} size='sm'>
      <div className='h2 mb3'>
        <FormattedMessage id='time_off.account_summaries.Adjust Time-Off Balance'/>
      </div>
      <FormLayout>
        <EmployeeNameWithAvatar size='md' employee={adjustmentAccount.employee} />
        <div>
          <Input
            value={adjustmentAccount.adjustment}
            onChange={e => adjustmentAccount.merge({adjustment: e.target.value})}
            label='time_off.account_summaries.Adjust by'
            errorMessage={errors.adjustment}
            connectedRight={
              <Select2
                value={adjustmentAccount.adjustBy}
                onChange={e => adjustmentAccount.merge({adjustBy: e.target.value})}
              >
                <option value={'days'}><FormattedMessage id='time_off.account_summaries.Days'/></option>
                <option value={'hours'}><FormattedMessage id='time_off.account_summaries.Hours'/></option>
              </Select2>
            }
          />
          <div className='jumbo' style={{paddingTop: '4px'}}>
            <AvailableDaysMessage adjustmentAccount={adjustmentAccount}/>
          </div>
        </div>
        <TextArea
          value={adjustmentNotes}
          onChange={e => uiState.updateAdjustment(e.target.value)}
          label='time_off.account_summaries.Adjustment notes'
        />
      </FormLayout>
      <ModalButtons
        onSave={() => uiState.saveBalanceAdjustment()}
        onCancel={() => uiState.closeBalanceAdjustmentModal()}/>
    </Modal>
  );
});

export default BalanceAdjustmentModal;
