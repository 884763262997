import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import {EditGoalContainerState} from '../state';
import {Display, Button, Layout, Panel} from 'components';
import {FormattedMessage} from 'react-intl';
import MetricForm from './MetricForm';
import GoalForm from './GoalForm';
import DeleteMetricModal from './DeleteMetricModal';

const EditGoalContainer = observer(({uiState}) => {
  const {title, goal, deleteMetricModalOpen} = uiState;

  return (
    <div>
      <Display title={title} onBackClick={() => uiState.history.goBack()}/>
      <Layout>
        <Layout.Section secondary>
          <Panel.Stack loose>
            <GoalForm uiState={uiState}/>
            <MetricForm uiState={uiState}/>
          </Panel.Stack>
          <div className='right'>
            <Button className='pl2' onClick={() => uiState.save()}>
              <FormattedMessage id={goal.isNew ? 'Create' : 'Save'}/>
            </Button>
          </div>
        </Layout.Section>
      </Layout>
      <DeleteMetricModal modalOpen={deleteMetricModalOpen} uiState={uiState}/>
    </div>
  );
});

export default withState(EditGoalContainer, EditGoalContainerState);
