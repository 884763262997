import React from 'react';
import {observer} from 'mobx-react';
import {Input, FormLayout, Panel} from 'components';
import {t} from 'shared/core';

 const Name = observer(({uiState}) => {
  const {policy, errors} = uiState;

   return (
    <div>
      <Panel.Header
        title={t('time_tracking.manage.policies.edit.Policy Name')}
        description={t("time_tracking.manage.policies.edit.Choose a name for your time tracking policy.")}
      />
      <FormLayout>
        <Input
          value={policy.name}
          label='time_tracking.manage.policies.edit.Policy Name'
          onChange={e => policy.merge({name: e.target.value})}
          errorMessage={errors.name}
        />
      </FormLayout>
    </div>
  );
});

 export default Name;
