import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import Step from './steps';
import {withState, t} from 'shared/core';
import {ReviewEditState, steps} from '../state';
import {Flow} from 'components';
import ReviewCycleName from '../../ReviewCycleName';

const ReviewEditFlow = observer(({uiState}) => {
  const {cycle, match} = uiState;

  return (
    <Flow
      name={ReviewCycleName}
      backTitle={t('performance_reviews.Performance reviews')}
      backRoute='/reviews'
      steps={steps}
      component={Step}
      uiState={uiState}
      baseUrl={`/reviews/${match.params.cycleId}/edit`}
      cycle={cycle}
      onStepSubmitted={step => uiState.onStepSubmitted(step)}
      onStepChanged={step => uiState.onStepChanged(step)}
    />
  );
});

export default withState(ReviewEditFlow, ReviewEditState);