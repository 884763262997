import _ from 'lodash';
import {DomainStore} from 'shared/store';
import {observable, action, computed} from 'mobx';
import {t, endpoints, types} from 'shared/core';
import {successAlert} from 'shared/tools';
import CompanyInsuranceCarrier from 'stores/benefits/CompanyInsuranceCarrier';
import {
  EmployeeBenefitEnrollment,
  EmployeeBenefitEnrollmentViewModel,
  EmployeeInsuranceNumber
} from 'stores/benefits';
import {Employee} from 'stores/employees';

class EmployeeBenefitEnrolmentFormState {
  store = new DomainStore();
  history;
  match;

  @observable employee;
  @observable companyInsuranceCarriers = [];
  @observable errors = {};
  @observable employeeBenefitEnrolments;
  @observable enrolmentViewModels = [];
  @observable employeeInsuranceNumbers;

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEE_DETAILS.with(this.match.params.id),
      endpoints.COMPANY_INSURANCE_CARRIERS.WITHOUT_OPTIONAL,
      endpoints.EMPLOYEE.EMPLOYEE_BENEFIT_ENROLMENTS.with(this.match.params.id)
    ]);

    this.companyInsuranceCarriers = this.store._getAll(
      types.COMPANY_INSURANCE_CARRIER, CompanyInsuranceCarrier
    );
    this.employee = new Employee(this.store._getSingle(types.EMPLOYEE_DETAILS, {id: this.match.params.id}));
    this.employeeBenefitEnrolments = this.store._getAll(
      types.EMPLOYEE_BENEFIT_ENROLLMENT, EmployeeBenefitEnrollment
    );

    this.employeeInsuranceNumbers = _(this.companyInsuranceCarriers)
      .filter(carrier => carrier.selfAssignedCertificatesEnabled)
      .map(carrier => new EmployeeInsuranceNumber({companyInsuranceCarrier: carrier}))
      .value();
    this.enrolmentViewModels = this.carriersWithoutExistingEnrolments.map(carrier => {
      const viewModel = EmployeeBenefitEnrollmentViewModel.createFor(carrier, this.employeeInsuranceNumbers);
      viewModel.record.merge({startDate: this.startDate});
      viewModel.allowNotEligible = this.allowNotEligible;

      return viewModel;
    });
  }

  @action async enrolEmployee() {
    const {model, errors} = await this.store.post(
      endpoints.EMPLOYEE.ENROL.with(this.match.params.id),
      types.EMPLOYEE_DETAILS,
      {
        employeeBenefitEnrollments: this.enrolments,
        employeeInsuranceNumbers: this.insuranceNumbers
      }
    );

    this.errors = errors;

    if (model) {
      successAlert(t('benefit_enrolment.new.Employee successfully enrolled in benefits.'));
      this.history.push('/benefit_enrolments');
    }
  }

  @computed get allowNotEligible() {
    return this.companyInsuranceCarriers.length > 1;
  }

  @computed get benefitClasses() {
    return this.employeeBenefitEnrolments.map(enrolment => enrolment.benefitClass);
  }

  @computed get carriersWithoutExistingEnrolments() {
    return _.filter(
      this.companyInsuranceCarriers,
      carrier => !this.enrolmentExistsForCarrier(carrier.benefitClasses)
    );
  }

  @computed get enrolments() {
    return this.enrolmentViewModels.map(e => e.record);
  }

  @computed get insuranceNumbers() {
    return _(this.enrolmentViewModels).map(m => m.insuranceNumber).compact().value();
  }

  enrolmentExistsForCarrier(insuranceCarrierBenefitClasses) {
    const benefitClasses = _.intersectionWith(
      insuranceCarrierBenefitClasses,
      this.benefitClasses,
      (insuranceClass, enrolmentClass) => insuranceClass.id === enrolmentClass.id);

    return !_.isEmpty(benefitClasses);
  }
}

export default EmployeeBenefitEnrolmentFormState;
