import React from 'react';
import {observer} from 'mobx-react';
import {Panel, TextArea} from 'components';
import {t} from 'shared/core';

const Notes = observer(({uiState}) => {
  const record = uiState.editRecord;
  const {parentState} = uiState;
  if (!parentState.editDataPermission('::ROLE_INFORMATION')) return null;

  return (
    <Panel>
      <Panel.Header
        title={t('employees.profile.role.Additional Notes')}
        description={t('employees.profile.role.additional_notes_desc')}
      />
      <TextArea
        label='employees.profile.role.Comment (not visible to the employee)'
        value={record.comment}
        onChange={(e) => record.merge({comment: e.target.value})}
        placeholder='employees.profile.role.Enter your comment here'
        className='full-width' minRows={3}
      />
    </Panel>
  );
});

export default Notes;
