import React from 'react';
import {observer} from 'mobx-react';
import TextFieldView from './TextFieldView';
import TextAreaView from './TextAreaView';
import AttachmentView from './AttachmentView';
import MultipleChoiceView from './MultipleChoiceView';

const CandidateCustomField = observer(({answer}) => {
  switch (answer.question.questionType) {
    case 'text_field':
      return <TextFieldView answer={answer}/>;
    case 'text_area':
      return <TextAreaView answer={answer}/>;
    case 'attachment':
      return <AttachmentView answer={answer}/>;
    case 'multiple_choice':
    case 'multi_select':
      return <MultipleChoiceView answer={answer}/>;
    default:
      throw new Error(`Question type ${answer.question.questionType} is not supported.`);
  }
});

export default CandidateCustomField;
