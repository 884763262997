import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {PersonalSettingsContainerState} from '../state';
import {Layout, Panel, Display, FormLayout, Input, Select2, Button} from 'components';
import {FormattedMessage} from 'react-intl';

const PersonalSettingsContainer = observer(({uiState}) => {
  const {user, errors} = uiState;

  return (
    <div>
      <Display title={t('broker_portal.personal_settings.Personal Settings')} />
      <Layout>
        <Layout.Section secondary>
          <Panel.Stack loose>
            <Panel>
              <Panel.Header title={t('broker_portal.personal_settings.Account Preferences')}/>
              <FormLayout>
                <Select2
                  value={user.preferredLocale}
                  label='broker_portal.personal_settings.Language'
                  onChange={e => user.merge({preferredLocale: e.target.value})}
                  errors={errors.preferredLocale}>
                  <option value='en'>{'English'}</option>
                  <option value='fr'>{'French'}</option>
                </Select2>
              </FormLayout>
              <Button onClick={() => uiState.savePreferredLocale()}>
                <FormattedMessage id='broker_portal.personal_settings.Save'/>
              </Button>
            </Panel>
            <Panel>
              <Panel.Header title={t('broker_portal.personal_settings.Security')}/>
              <FormLayout>
                <Input
                  type='password'
                  value={user.currentPassword}
                  label='broker_portal.personal_settings.Current Password'
                  errorMessage={errors.currentPassword || errors.base}
                  onChange={e => user.merge({currentPassword: e.target.value})}/>
                <Input
                  type='password'
                  value={user.newPassword}
                  label='broker_portal.personal_settings.New Password'
                  errorMessage={errors.newPassword}
                  onChange={e => user.merge({newPassword: e.target.value})}
                />
                <Input
                  type='password'
                  value={user.confirmPassword}
                  label='broker_portal.personal_settings.Confirm New Password'
                  errorMessage={errors.confirmPassword}
                  onChange={e => user.merge({confirmPassword: e.target.value})}
                />
              </FormLayout>
              <Button onClick={() => uiState.changePassword()}>
                <FormattedMessage id='broker_portal.personal_settings.Change Password'/>
              </Button>
            </Panel>
          </Panel.Stack>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(PersonalSettingsContainer, PersonalSettingsContainerState);
