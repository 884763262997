import React from 'react';
import BackToIndex from './BackToIndex';

// Wrapper for every report component.
// This component is used by the React Router; don't use it directly in your reports.
const ReportsContainer = ({backPath, children}) => {
  return (
    <div>
      <BackToIndex backPath={backPath}/>
      <div className="Container">
        {children}
      </div>
    </div>
  );
};

export default ReportsContainer;
