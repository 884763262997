import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const DeleteGoalModal = observer(({modalOpen, uiState}) => {
  return (
    <AlertModal
      mode='danger'
      size='sm'
      header='goals.Remove Goal?'
      subHeader='goals.Are you sure you want to delete this goal?'
      body='goals.This goal and all the associated comments and progress updates will be deleted forever.'
      onHide={() => uiState.closeDeleteGoalModal()}
      isOpen={modalOpen}
    >
      <ModalDangerButtons
        saveCaption={'Remove'}
        onCancel={() => uiState.closeDeleteGoalModal()}
        onSave={() => uiState.deleteGoal()}
      />
    </AlertModal>
  );
});

export default DeleteGoalModal;
