import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {FormLayout, Input} from 'components';
import {FormattedMessage} from 'react-intl';

const AddUserModal = observer(({uiState}) => {
  const {invitation, errors} = uiState;
  return (
    <Modal
      size='sm'
      isOpen={uiState.addUserModalOpen}
      onHide={() => uiState.closeAddUserModal()}
      title='broker_portal.broker_settings.Invite user to brokerage'
    >
      <FormattedMessage id='broker_portal.broker_settings.This user will have access to:'/>
      <div className='ml3 mb3'>
        <ul>
          <li><FormattedMessage id='broker_portal.broker_settings.Invite new users'/></li>
          <li><FormattedMessage id='broker_portal.broker_settings.Revoke access for existing users'/></li>
          <li><FormattedMessage id='broker_portal.broker_settings.Add new clients'/></li>
          <li><FormattedMessage id='broker_portal.broker_settings.View client data and download reports'/></li>
        </ul>
      </div>
      <FormLayout>
        <Input
          value={invitation.email}
          onChange={e => invitation.merge({email: e.target.value})}
          label='Email Address'
          errorMessage={errors.email}
        />
      </FormLayout>
      <ModalButtons
        saveCaption='benefit_enrolment.enrol.Send Invite'
        onSave={() => uiState.sendUserInvitation()}
        onCancel={() => uiState.closeAddUserModal()}
      />
    </Modal>
  );
});

export default AddUserModal;
