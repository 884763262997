import {DomainObject, oneOf} from 'shared/store';
import {observable, action, computed} from 'mobx';
import User from 'stores/users/User';
import {t} from 'shared/core';

class Recipient extends DomainObject {
  @observable id;
  @observable recipientType;
  @observable user;

  @action merge(other) {
    super.merge(other, {
      recipientType: oneOf(['employee', 'manager', 'specific_user']),
      user: User
    });
  }

  @computed get value() {
    switch (this.recipientType) {
      case 'specific_user':
        return this.user.id;
      case 'employee':
      case 'manager':
        return this.recipientType;
      default:
        throw new Error(`Recipient type ${this.reminderType} not supported.`);
    }
  }

  @computed get name() {
    switch (this.recipientType) {
      case 'specific_user':
        return this.user.name;
      case 'employee':
      case 'manager':
        return t(`models.reminders.recipients.${this.recipientType}`);
      default:
        throw new Error(`Recipient type ${this.reminderType} not supported.`);
    }
  }
}

export default Recipient;
