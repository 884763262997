import {observable, action} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints} from 'shared/core';
import {TimeTrackingReportDownload} from 'stores/time_tracking';

const POLL_INTERVAL = 2000;

class ReportDownloadState {
  interval;
  store = new DomainStore();

  @observable modalOpen = false;
  @observable pending = true;
  @observable reportDownload = {};
  @observable selectedFileType;

  @observable reportType;
  @observable name;
  @observable params;

  receiveProps({reportType, name, params}) {
    this.reportType = reportType;
    this.name = name;
    this.params = params;
  }

  @action closeModal() {
    this.modalOpen = false;
  }

  @action async requestDownload(fileType = this.selectedFileType) {
    clearInterval(this.interval);

    this.selectedFileType = fileType;

    await this.postDownloadRequest();
    this.startPolling();
    this.modalOpen = true;
  }

  async postDownloadRequest() {
    this.pending = true;
    this.store.invalidate(types.TIME_TRACKING.REPORT_DOWNLOAD);

    const {model} = await this.store.post(
      endpoints.TIME_TRACKING.REPORT_DOWNLOADS.REQUEST_DOWNLOAD.with(this.reportType),
      types.TIME_TRACKING.REPORT_DOWNLOAD,
      {
        reportDownloadParams: {
          ...this.params,
          exportType: this.selectedFileType
        }
      }
    );

    this.reportDownload = model;
  }

  async poll() {
    await this.store._compose([
      endpoints.TIME_TRACKING.REPORT_DOWNLOADS.with(this.reportDownload.id)
    ]);

    this.reportDownload = new TimeTrackingReportDownload(this.store._getSingle(types.TIME_TRACKING.REPORT_DOWNLOAD));

    if (this.reportDownload.isFinished) {
      clearInterval(this.interval);
      this.pending = false;
    }
  }

  startPolling() {
    this.interval = setInterval(() => this.poll(), POLL_INTERVAL);
  }
}

export default ReportDownloadState;
