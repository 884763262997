import React from 'react';
import Spinner from 'components/spinners/Spinner';
import Panel from './Panel';

const PanelSpinner = () => {
  return (
    <Panel>
      <Spinner/>
    </Panel>
  );
};

export default PanelSpinner;
