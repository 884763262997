import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import FilterComponent from './FilterComponent';

const GoalStatus = observer(({filter, filterKey, filterUpdated, filterDeleted, Wrapper}) => {
  const options = [
    { value: 'in_progress', label: t('components.tables.fancy_filters.selectors.In Progress') },
    { value: 'upcoming', label: t('components.tables.fancy_filters.selectors.Upcoming') },
    { value: 'past_due', label: t('components.tables.fancy_filters.selectors.Past Due') },
    { value: 'complete', label: t('components.tables.fancy_filters.selectors.Complete') }
  ];

  return <FilterComponent filter={filter} filterKey={filterKey} options={options} filterUpdated={filterUpdated} filterDeleted={filterDeleted} Wrapper={Wrapper}/>;
});

export default GoalStatus;
