import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, FormLayout, DatePicker, Select2, TextArea, RadioButtonGroup, RadioButton} from 'components';
import {FormattedMessage} from 'react-intl';

const ROELabel = () => {
  return (
    <div>
      <FormattedMessage id='employees.profile.role.ROE Code'/>
      <a className='ml1' href='https://support.collage.co/en/articles/8125309' target='_blank'><FormattedMessage id="employees.profile.role.What's this?"/></a>
    </div>
  );
};

const ROECodes = observer(({uiState}) => {
  const {editRecord: record, payrollEnabled, voluntaryROECodes, involuntaryROECodes, errors} = uiState;

  if (!payrollEnabled || !record.terminationType) return null;

  return (
    <Select2
      label={<ROELabel/>}
      translateLabel={false}
      value={record.roeCode}
      onChange={e => uiState.updateROECode(e.target.value)}
      errorMessage={errors.roeCode}
      helpMessage='employees.profile.role.ROE_WARNING'
    >
      {record.terminationType === 'voluntary' && voluntaryROECodes.map((code) => <option value={code}>{t(`models.employee.roe_code.${code}`)}</option>)}
      {record.terminationType === 'involuntary' && involuntaryROECodes.map((code) => <option value={code}>{t(`models.employee.roe_code.${code}`)}</option>)}
      <option value='other'>{t('models.employee.roe_code.other')}</option>
    </Select2>
  );
});

const TerminationDetailsForm = observer(({uiState}) => {
  const {editRecord: record, payrollEnabled, validReasonsForQuitting, errors} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.role.Termination Details')} />
        <FormLayout>
          <DatePicker
            label='employees.profile.role.Last Day of Work'
            value={record.terminatedAt}
            onChange={date => uiState.setTerminatedAtAndLastDayOfBenefits(date)}
            errorMessage={errors.terminatedAt || errors.effectiveDate}
            options={{startView: 1}}
            className='full-width'
          />
          <Select2
            label='employees.profile.role.Termination Category'
            value={record.terminationType}
            onChange={e => uiState.updateTerminationType(e.target.value)}
            errorMessage={errors.terminationType}
          >
            <option value='voluntary'>{t('models.employee.termination_type.voluntary')}</option>
            <option value='involuntary'>{t('models.employee.termination_type.involuntary')}</option>
          </Select2>
          <ROECodes uiState={uiState}/>
          {record.roeCode === 'e' && <Select2
            label='employees.profile.role.Reason for Quitting'
            value={record.reasonForQuitting}
            onChange={e => record.reasonForQuitting = e.target.value}
            errorMessage={errors.reasonForQuitting}
          >
            {validReasonsForQuitting.map((reason) => <option value={reason}>{t(`models.employee.reason_for_quitting.${reason}`)}</option>)}
          </Select2>}
          {record.roeCode === 'm' && <RadioButtonGroup name='terminatedOnProbation'
            label='employees.profile.role.Was the employee suspended during a probationary period?'
            selectedValue={record.terminatedOnProbation}
            errorMessage={errors.terminatedOnProbation}
            onChange={(name, value) => record.terminatedOnProbation = value}
          >
            <RadioButton className='table-cell pr2' value={true}>{t('employees.profile.role.Yes')}</RadioButton>
            <RadioButton className='table-cell pl2' value={false}>{t('employees.profile.role.No')}</RadioButton>
          </RadioButtonGroup>}
          <TextArea
            label={`${payrollEnabled ? 'employees.profile.role.Comments' : 'employees.profile.role.Reason for Termination'}`}
            value={record.terminationReason}
            onChange={(e) => record.merge({terminationReason: e.target.value})}
            placeholder='employees.profile.role.Enter termination reason here'
            className='full-width' minRows={3}
            errorMessage={errors.terminationReason}
          />
        </FormLayout>
      </Panel>
  );
});

export default TerminationDetailsForm;
