import {DomainObject} from 'shared/store';
import {action, observable} from 'mobx';
import {User} from '../users';
import {Employee} from '../employees';

class MassUpdateSummary extends DomainObject {
  @observable createdAt;
  @observable createdByUser;
  @observable employees;
  @observable effectiveDate;
  @observable status;
  @observable errorCount;

  @action merge(other, fields) {
    super.merge(other, {
      _dates: ['effectiveDate', 'createdAt'],
      createdByUser: User,
      employees: [Employee]
    });
  }
}

export default MassUpdateSummary;
