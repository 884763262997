import {DomainStore} from 'shared/store';
import {types, endpoints} from 'shared/core';
import Role from './Role';

class RoleStore extends DomainStore {
  async load() {
    await this._compose([
      endpoints.ROLES
    ]);

    return this._getAll(types.ROLE).map(r => new Role(r));
  }

  async loadSingle(id) {
    await this._compose([
      endpoints.ROLE.with(id),
      endpoints.USERS.ACTIVE
    ]);

    return new Role(this._getSingle(types.ROLE, {id}));
  }

  async postRole() {
    const {model} = await this.post(
      endpoints.ROLES,
      types.ROLE
    );

    return model.id;
  }
}

const singleton = new RoleStore();

export default singleton;
