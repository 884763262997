import React, {useState, useRef, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {observer} from 'mobx-react';
import {t} from 'shared/core';

const TooltipContent = ({children, title, showTooltip, position, tooltipContentRef}) => {
  return (
    createPortal(
      <div
        className={`PanelTooltip-box ${showTooltip ? 'active' : ''}`}
        style={position}
        ref={tooltipContentRef}
      >
        {title && <div className='h3 mb2'>{title}</div>}
        {children}
      </div>,
      document.body
    )
  );
};

const PanelTooltip = observer(({title, text, icon, children}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const tooltipRef = useRef(null);
  const iconRef = useRef(null);
  const tooltipContentRef = useRef(null);

  const handleMouseMove = (e) => {
    if (iconRef.current && tooltipContentRef.current) {
      const rect = iconRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const tooltipWidth = tooltipContentRef.current.offsetWidth;

      let left = rect.left + window.scrollX + 20;

      if (left + tooltipWidth > windowWidth) {
        left = rect.right + window.scrollX - tooltipWidth - 20;
      }

      if (left < 0) {
        left = (windowWidth - tooltipWidth) / 2;
      }

      setPosition({
        top: rect.bottom + window.scrollY + 20,
        left: left
      });
    }
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  useEffect(() => {
    const tooltipElement = tooltipRef.current;
    if (tooltipElement) {
      tooltipElement.style.top = `${position.top}px`;
      tooltipElement.style.left = `${position.left}px`;
    }
  }, [position]);

  return (
    <span>
      <i
        className='material-icons h4 align-middle jumbo PanelTooltip ml1'
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        ref={iconRef}
      >
        {icon}
      </i>
      <TooltipContent
        showTooltip={showTooltip}
        title={title}
        position={position}
        ref={tooltipRef}
        tooltipContentRef={tooltipContentRef}
      >
        {children || t(text)}
      </TooltipContent>
    </span>
  );
});

PanelTooltip.defaultProps = {
  icon: 'info_outline'
};

export default PanelTooltip;
