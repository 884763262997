import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {t, auth} from 'shared/core';
import CompletedIcon from 'img/tuatara-checkmark@2x.png';
import IncompleteIcon from 'img/circle--jumbo@2x.png';
import CurrentIcon from 'img/circle--dodger@2x.png';
import CloseIcon from 'img/close-icon-red@2x.png';
import RemoveDocumentModal from './RemoveDocumentModal';
import RemoveBankInfoStepModal from './RemoveBankInfoStepModal';

const OnboardingStep = observer(({uiState, step, employee, documentActions}) => {
  if (!step.name) {
    return null;
  }

  let iconSrc;
  if (employee.onboard.isStepCompleted(step)) {
    iconSrc = CompletedIcon;
  } else if (employee.onboard.isStepCurrent(step)) {
    iconSrc = CurrentIcon;
  } else {
    iconSrc = IncompleteIcon;
  }
  return (
    <div>
      <div className={`mb1 flex align-items-middle ${iconSrc === CurrentIcon ? 'dodger' : 'jumbo'}`}>
        <img className='mr1' src={iconSrc} width='10' alt='status'/>
        {step.name}
        {step.id === 'bank_info' && !employee.onboard.isStepCompleted(step) && <img
          className='ml1 clickable'
          src={CloseIcon}
          width='10'
          onClick={() => uiState.openRemoveBankInfoStepModal()}
          alt='remove-bank-info-step'
        />}
      </div>
      {step.id === 'sign_documents' && documentActions.map(documentAction => {
        if (!documentAction.actionedAt && employee.onboard.isStepCompleted(step)) {
          return null;
        }
        return <div
          className={`ml2 mb1 flex align-items-middle ${documentAction.actionedAt || !employee.onboard.isStepCurrent(step) ? 'jumbo' : 'dodger'}`}
          key={documentAction.id}
        >
          <img
            className='mr1'
            src={documentAction.actionedAt ? CompletedIcon : (employee.onboard.isStepCurrent(step) ? CurrentIcon : IncompleteIcon )}
            width='10'
            alt='status'
          />
          {documentAction.companyDocument.name}
          {!documentAction.actionedAt && <img
            className='ml1 clickable'
            src={CloseIcon}
            width='10'
            onClick={() => uiState.openRemoveDocumentModal(documentAction)}
            alt='remove-document'
          />}
        </div>;
      })}
    </div>
  );
});

const OnboardingSection = observer(({uiState}) => {
  const {employee, documentActions} = uiState;

  if (!auth.hasAccess('::HIRE_EMPLOYEES') || employee.onboardingComplete || !auth.moduleEnabled('directory')) {
    return null;
  }

  return (
    <React.Fragment>
      <Panel>
        <Panel.Header title={t('employees.profile.Onboarding Progress')}/>
        <div className='mb2'>
          {employee.onboard.steps.map((step, index) => {
            return <OnboardingStep
              uiState={uiState}
              key={index}
              step={step}
              employee={employee}
              documentActions={documentActions}
            />;
          })}
        </div>
      </Panel>
      <RemoveDocumentModal uiState={uiState}/>
      <RemoveBankInfoStepModal uiState={uiState}/>
    </React.Fragment>
  );
});

export default OnboardingSection;
