import {observable, action, computed} from 'mobx';
import _ from 'lodash';
import {auth} from 'shared/core';

class DocumentViewModel {
  @observable uiState;
  @observable data;

  constructor(data, uiState) {
    this.data = data;
    this.uiState = uiState;
  }

  @action openAddDocumentToFolderModal() {
    this.uiState.openAddDocumentToFolderModal(this);
  }

  @action select() {
    // empty implementation
  }

  @computed get icon() {
    return 'document';
  }

  @computed get clickable() {
    return false;
  }

  @computed get downloadUrl() {
    return this.data.links.fileUrl;
  }

  @computed get sharedStatus() {
    const hasSharedStatusColumn = this.data.links.self && auth.employee.id !== _.get(this.data, 'employee.id');
    if (!hasSharedStatusColumn) return null;

    if (!this.data.canShare || this.data.shareWithEmployee) return 'yes';
    return 'no';
  }

  @computed get name() {
    return this.data.fileName;
  }

  @computed get createdAt() {
    return this.data.createdAt;
  }

  @computed get customLinks() {
    if (_.isEmpty(this.uiState.allFolders)) return [];
    if (!this.data.hasLink('self')) return [];

    return [{
        order: 1,
        text: 'employees.profile.documents.Move to Folder...',
        action: viewModel => viewModel.uiState.openMoveDocumentModal(viewModel),
    }];
  }
}

export default DocumentViewModel;
