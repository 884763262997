import {DomainStore} from 'shared/store';
import {endpoints} from 'shared/core';
import _ from 'lodash';

class TerminatedEmployeeDataSource {
  store = new DomainStore();

  async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.TERMINATED
    ]);

    return this.store.getEmployees();
  }
}

export default TerminatedEmployeeDataSource;
