import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Button, EmployeeNameWithAvatar} from 'components';
import {FormattedMessage} from 'react-intl';
import {calendarDate} from 'shared/tools';
import {t, urls} from 'shared/core';
import ChangesForUpdate from './ChangesForUpdate';

const PayrollUpdateForEmployee = observer(({uiState, update}) => {
  return (
    <Panel>
      <Panel.Header title={t(`models.payroll_updates.actions.${update.action}`)}>
        <Button
          onClick={() => uiState.updatePayrollUpdateStatus(update)}
          size='sm'
          trait='default'
        >
          <FormattedMessage id={`payroll_updates.actions.${update.status}`}/>
        </Button>
      </Panel.Header>
      <div className='clearfix'>
        <div className='col col-4'>
          <div className='black medium mb1'>
            <FormattedMessage id='payroll_updates.Employee'/>
          </div>
          <div>
            <EmployeeNameWithAvatar
              employee={update.employee}
              href={urls.EMPLOYEE.with(update.employee.id)}
            />
          </div>
        </div>
        <div className='col col-8'>
          <ChangesForUpdate update={update}/>
        </div>
      </div>
    </Panel>
  );
});

const PayrollUpdateDigest = observer(({uiState, payrollUpdate}) => {
  return (
    <Panel.Stack className='mb2' key={payrollUpdate.date}>
      <Panel>
        <Panel.Header title={calendarDate(payrollUpdate.date)}/>
      </Panel>
      {payrollUpdate.items.map(update => <PayrollUpdateForEmployee key={update.id} uiState={uiState} update={update}/>)}
    </Panel.Stack>
  );
});

export default PayrollUpdateDigest;
