import React from 'react';
import {FormattedMessage} from 'react-intl';
import ConfirmationIcon from 'company_settings/billing/img/confirmation-icon@2x.png';
import {observer} from 'mobx-react';

const Confirmation = observer(({uiState}) => {
  return (
    <div className='bg-white p4 box-shadow-2 rounded'>
      <div className='col-12 sm-col-5 my3 mx-auto center'>
        <img src={ConfirmationIcon} alt='confirmation-icon' width='156' />
        <div className='h3 dodger medium'><FormattedMessage id="company_settings.billing.Success!"/></div>
        <div className='h1 my2'><FormattedMessage id="company_settings.billing.You're all set."/></div>
        <div className='h3 jumbo'><FormattedMessage id="company_settings.billing.You can find your billing information and statements under the Billing tab in your company settings." /></div>
        <a href={uiState.origin} className='Btn Btn--primary Btn--lg mt3'><FormattedMessage id="company_settings.billing.Got it!" /></a>
      </div>
    </div>
  );
});

export default Confirmation;
