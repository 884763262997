import {DomainObject} from 'shared/store';
import {observable, action} from 'mobx';
import PerformanceReviewQuestion from './PerformanceReviewQuestion';

class PerformanceReviewTemplate extends DomainObject {
  @observable name;
  @observable questions = [];
  @observable questionCount;

  @action merge(other) {
    super.merge(other, {
      questions: [PerformanceReviewQuestion]
    });
  }
}

export default PerformanceReviewTemplate;

