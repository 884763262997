import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';

const BenefitDivision = observer(({model}) => {
  if (model.enrolmentStatus === 'not_enrolled') return null;

  return <div>{model.benefitDivisionName}</div>;
});

export default BenefitDivision;

