import StaticDataSource from './StaticDataSource';
import EMPLOYMENT_STATUSES from 'stores/employees/employmentStatuses';
import EMPLOYMENT_TYPES from 'stores/employees/employmentTypes';
import GENDERS from 'stores/employees/genders';
import TERMINATION_TYPES from 'stores/employees/terminationTypes';
import CANDIDATE_STATUSES from 'stores/recruiting/candidateStatuses';
import POSITION_STATUSES from 'stores/recruiting/positionStatuses';

class BaseReportDataSource {
  customReportId;

  constructor(customReportId) {
    this.customReportId = customReportId;
  }

  departmentDataSource() {
    throw new Error('departmentDataSrouce() undefined in subclass');
  }

  locationDataSource() {
    throw new Error('locationsDataSource() undefined in subclass');
  }

  employeeDataSource() {
    throw new Error('employeeDatSource() undefined in subclass');
  }

  managerDataSource() {
    throw new Error('managerDataSource() undefined in subclass');
  }

  positionDataSource() {
    throw new Error('positionDataSource() undefined in subclass');
  }

  terminatedEmployeeDataSource() {
    throw new Error('terminatedEmployeeDataSource() undefined in subclass');
  }

  timeOffTypeDataSource() {
    throw new Error('timeOffTypeDataSource() undefined in subclass');
  }

  userDataSource() {
    throw new Error('userDataSource() undefined in subclass');
  }

  projectDataSource() {
    throw new Error('projectDataSource() undefined in subclass');
  }

  employmentStatusDataSource() {
    return new StaticDataSource(EMPLOYMENT_STATUSES);
  }

  employmentTypeDataSource() {
    return new StaticDataSource(EMPLOYMENT_TYPES);
  }

  genderDataSource() {
    return new StaticDataSource(GENDERS);
  }

  terminationTypeDataSource() {
    return new StaticDataSource(TERMINATION_TYPES);
  }

  candidateStatusDataSource() {
    return new StaticDataSource(CANDIDATE_STATUSES);
  }

  positionStatusDataSource() {
    return new StaticDataSource(POSITION_STATUSES);
  }
}

export default BaseReportDataSource;
