import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {DatePicker, FormError} from 'components';
import {t} from 'shared/core';

const ReassignTrainingModal = observer(({uiState}) => {
  const {errors} = uiState;

  return (
    <Modal
      size='md'
      isOpen={uiState.reassignTrainingModalOpen}
      onHide={() => uiState.closeModals()}
      title={t('training.summary.reassign_training_for_employee', {employee_name: uiState.selectedEmployeeForReassignment.name})}
      translateTitle={false}
    >
      <DatePicker
        label='training.summary.Due Date'
        value={uiState.reassignmentDueDate}
        onChange={date => uiState.reassignmentDueDate = date}
        optional
      />
      <FormError message={errors.base} />
      <ModalButtons
        onSave={() => uiState.reassignEmployee()}
        saveCaption='training.summary.Reassign'
        onCancel={() => uiState.closeModals()}
      />
    </Modal>
  );
});

export default ReassignTrainingModal;
