import _ from 'lodash';
import {Steps} from 'shared/tools';

const STEPS = [
  {
    name: 'recruiting.steps.Details',
    location: 'details',
  },
  {
    name: 'recruiting.steps.Description',
    location: 'description',
  },
  {
    name: 'recruiting.steps.Application',
    location: 'questions'
  },
  {
      name: 'recruiting.steps.Rules',
      location: 'rules'
  },
  {
    name: 'recruiting.steps.Settings',
    location: 'settings',
  },
  {
    name: 'recruiting.steps.Review',
    location: 'review',
  }
].map((s, i) => ({
  ...s,
  index: i + 1
}));

const singleton = new Steps(STEPS);

export default singleton;
