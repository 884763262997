import React from 'react';
import {observer} from 'mobx-react';

const CompanyContactItem = observer(({model}) => {
  return (
    <div>
      <div>
        {model.name}
      </div>
      <div className='jumbo'>
        {model.position}
      </div>
      {model.email && (
        <div>
          <a href={`mailto:${model.email}`}>{model.email}</a>
        </div>
      )}
      {model.phone && (
        <div>
          <a href={`tel:${model.phone}`}>{model.phone}</a>
        </div>
      )}
    </div>
  );
});

export default CompanyContactItem;
