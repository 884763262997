import React from 'react';
import {observer} from 'mobx-react';

const CheckInSummaryList = ({children}) => {
  return (
    <div className='col-4 bg-athens min-height-800 overflow-auto border-right'>
      {children}
    </div>
  );
};

export default observer(CheckInSummaryList);
