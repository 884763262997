import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {withState, t, auth} from 'shared/core';
import {EmployeeDirectoryContainer} from './directory';
import {OrgChartContainer} from './org-chart';
import {EmployeeDirectoryPageState} from '../state';
import {DocumentTitle} from 'components';
import {HireFlow} from 'employees/hire';

const EmployeeDirectoryPage = ({uiState}) => {
  const {scope} = uiState;

  return (
    <DocumentTitle title={t('employees.directory.People')}>
      <Router basename='/employees'>
        <Switch>
          <Route path='/directory' render={() => <EmployeeDirectoryContainer scope={scope}/>}/>
          {auth.company.orgChartEnabled && <Route path='/orgchart' render={() => <OrgChartContainer scope={scope}/>}/>}
          <Route path='/hire/:id' component={HireFlow}/>
          <Route exact path='/hire' component={HireFlow}/>
          <Redirect exact from='/' to='/directory'/>
        </Switch>
      </Router>
    </DocumentTitle>
  );
};

export default withState(EmployeeDirectoryPage, EmployeeDirectoryPageState);
