import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {VideoPlayer, Display, Layout, Panel, Button, AttachedDocumentDownload, Avatar, HtmlRender, AutosaveStatus} from 'components';
import {t, auth, withState} from 'shared/core';
import {TrainingProgramsEmployeeContainerState} from '../state';
import Questions from './Questions';

const EmployeePanel = observer(({employee}) => {
  if (auth.employee.id === employee.id) return null;

  return (
    <div>
      <div className='mb3'>
        <Panel.Header title={t('training.view.Employee')}/>
        <div className='table-cell align-middle pr2'>
          <Avatar employee={employee} />
        </div>
        <div className='table-cell align-middle'>
          <div className='black'>{employee.name}</div>
          <div className='jumbo'>{employee.jobTitle}</div>
        </div>
      </div>
      <Panel.Separator/>
    </div>
  );
});

const TrainingDocuments = observer(({program}) => {
  if (!program.attachedFiles.length) return null;

  return (
    <Panel>
      <Panel.Header title={t('training.view.Attached Documents')}/>
      <div className='space-y'>
        {program.attachedFiles.map(attachedFile =>
          <div key={attachedFile.id}>
            <AttachedDocumentDownload doc={attachedFile} name={attachedFile.fileName}/>
          </div>
        )}
      </div>
    </Panel>
  );
});

const TrainingCompletionButton = observer(({uiState}) => {
  const {trainingStatusDetails} = uiState;

  if (trainingStatusDetails.completed) return null;
  if (!trainingStatusDetails.hasLink('complete')) return null;

  return (
    <div className='mt3 right-align'>
      <AutosaveStatus autosaver={trainingStatusDetails.autosaver}/>
      <Button className='ml2' onClick={() => uiState.completeTraining()}>
        <FormattedMessage id='training.view.Complete Training'/>
      </Button>
    </div>
  );
});

const TrainingProgramEmployeeContainer = observer(({uiState}) => {
  const {trainingStatusDetails} = uiState;
  const {program, employee} = trainingStatusDetails;
  const {name} = program;

  return (
    <div>
      <Display
        onBackClick={() => uiState.history.goBack()}
        title={name}
      />
      <Layout>
        <Panel.Stack loose>
          <Panel>
            <EmployeePanel employee={employee}/>
            <Panel.Header title={t('training.view.Description')}/>
            <HtmlRender html={program.description}/>
            <VideoPlayer className='mt2' url={program.videoUrl}/>
          </Panel>
          <TrainingDocuments program={program}/>
          <Panel>
            <Questions uiState={uiState}/>
            <TrainingCompletionButton uiState={uiState}/>
          </Panel>
        </Panel.Stack>
      </Layout>
    </div>
  );
});

export default withState(TrainingProgramEmployeeContainer, TrainingProgramsEmployeeContainerState);
