import React from 'react';
import {observer} from 'mobx-react';
import {Button} from 'components';
import {FormattedMessage} from 'react-intl';
import CompletedIcon from 'img/list-element-complete@2x.png';

const ApproveButton = observer(({uiState, model}) => {
  if (model.approved){
    return (
      <div className='flex align-items-center justify-content-end'>
        <div className='mr1'>
          <FormattedMessage id='time_tracking.payroll_hours.Approved'/>
        </div>
        <img src={CompletedIcon} width='18' alt='complete'/>
      </div>
    );
  } else {
    return (
      <Button trait='default' size='sm' onClick={(e) => { e.stopPropagation(); uiState.approve(model); }}>
        <FormattedMessage id='time_tracking.payroll_hours.Approve'/>
      </Button>
    );
  }
});

export default ApproveButton;
