import React from 'react';
import {observer} from 'mobx-react';
import {Panel, FormLayout, DatePicker, ObjectSelect2, CheckboxWithLabel} from 'components';
import {t} from 'shared/core';
import {CompensationInformation, EmploymentDetails, EmploymentRecordCustomFields, Notes} from './shared';

const LeaveDetails = observer(({uiState}) => {
  const record = uiState.editRecord;
  const {errors, employee} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('employees.profile.role.Leave Details')}/>
      <FormLayout>
        <DatePicker
          label='employees.profile.role.Last Day of Work'
          value={record.lastDayOfWorkBeforeLeave}
          onChange={(date) => record.merge({lastDayOfWorkBeforeLeave: date})}
          errorMessage={errors.lastDayOfWorkBeforeLeave || errors.effectiveDate}
          options={{startView: 1}}
        />
        <ObjectSelect2
          label='employees.profile.role.Leave Type'
          value={record.leaveType}
          onChange={leaveType => record.merge({leaveType: leaveType})}
          items={uiState.leaveTypes}
          errorMessage={errors.leaveTypeId}
          action={{
            title: t('employees.profile.role.+ Add leave type'),
            url: '/company_settings/leave_types'
          }}
        />
        <DatePicker
          label='employees.profile.role.Expected Return Date'
          value={record.expectedReturnDate}
          onChange={(date) => record.merge({expectedReturnDate: date})}
          errorMessage={errors.expectedReturnDate}
          options={{startView: 1}}
        />
        {employee.hasTimeOff && <CheckboxWithLabel
          checked={record.skipAccruals}
          onChange={e => record.merge({skipAccruals: e.checked})}
          label='employees.profile.role.Skip time off accruals while on leave'
        />}
      </FormLayout>
    </Panel>
  );
});

const OnLeaveRecordForm = observer(({uiState}) => {
  const {editRecord, errors} = uiState;

  return (
    <form>
      <Panel.Stack loose>
        <LeaveDetails uiState={uiState}/>
        <EmploymentDetails uiState={uiState}/>
        <CompensationInformation uiState={uiState}/>
        <EmploymentRecordCustomFields editRecord={editRecord} errors={errors}/>
        <Notes uiState={uiState}/>
      </Panel.Stack>
    </form>
  );
});

export default OnLeaveRecordForm;
