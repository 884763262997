import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import BlueCheckmark from 'img/circle-checkmark--blue@2x.png';
import BhqCompanyContainer from './BhqCompanyContainer';

const ProcessingScreen = observer(() => {
  return (
    <BhqCompanyContainer>
      <div className='Container py4 slide-up'>
        <div className='clearfix'>
          <div className='col-12 sm-col-7 mx-auto p4 bg-white box-shadow-1 rounded center'>
            <img className='mb2' src={BlueCheckmark} alt='checkmark' height='40'/>
            <div className='h2 medium mb1'>
              <FormattedMessage id='broker_portal.signup.company.All done!'/>
            </div>
            <div className='jumbo'>
              <FormattedMessage id="broker_portal.signup.company.We'll notify you by email once your account setup is complete. You'll then be ready to sign in and begin using Collage to help manage your benefits."/>
            </div>
          </div>
        </div>
      </div>
    </BhqCompanyContainer>
  );
});

export default ProcessingScreen;
