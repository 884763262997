import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import JobApplicationForm from './JobApplicationForm';

const JobApplicationPage = () => {
  return (
    <Router>
      <Route exact path='/jobs/:slug/:id/apply' component={JobApplicationForm} />
    </Router>
  );
};

export default JobApplicationPage;
