import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {ActionLink, FilterDropdown, FilterDropdownMenu, RadioButtonGroup, RadioButton, Spinner} from 'components';
import {noop} from 'shared/tools';
import {FormattedMessage, injectIntl} from 'react-intl';

const MultiSelectFilterRow = injectIntl(observer(({uiState, viewModel, intl}) => {
  const {editingRow, viewOnly} = viewModel;

  return (
    <div className='py1'>
      <div className='table-cell align-top pr1'>
        <FilterDropdown isOpen={false}
                        onOpen={noop}
                        onCancel={noop}
                        selected={[intl.formatMessage({id: `reports.${viewModel.category}`})]}
                        disabled={true} />
      </div>
      <div className='table-cell align-top pr1'>
        <FilterDropdown isOpen={viewModel.operatorOpen}
                        onOpen={() => { uiState.closeFilters(); viewModel.openOperator(); }}
                        onCancel={() => viewModel.closeOperator()}
                        selected={[intl.formatMessage({id: `reports.${editingRow.operator}`})]}
                        onApply={() => { viewModel.updateRow(); uiState.applyFilters(); }}
                        disabled={viewOnly}>
          <RadioButtonGroup name={`${viewModel.category}_operator`}
                            onChange={(name, value) => viewModel.editOperator(value)}
                            selectedValue={editingRow.operator}>
            {viewModel.operators.map(o =>
              <RadioButton className='block pb1' key={o} value={o}><FormattedMessage id={`reports.${o}`}/></RadioButton>
            )}
          </RadioButtonGroup>
        </FilterDropdown>
      </div>
      <div className='table-cell align-top pr1'>
        <FilterDropdown isOpen={viewModel.optionsOpen}
                        onOpen={() => { uiState.closeFilters(); viewModel.openOptions(); }}
                        onCancel={() => viewModel.closeOptions()}
                        selected={viewModel.selectedValues.map(v => viewModel.translatable ? intl.formatMessage({id: `reports.${v}`}) : v)}
                        onApply={() => { viewModel.updateRow(); uiState.applyFilters(); }}
                        searchable={viewModel.searchable}
                        searchValue={viewModel.searchFilter}
                        onChange={(value) => viewModel.applySearchFilter(value)}
                        disabled={viewOnly}>
          {viewModel.loading && <Spinner />}
          {!viewModel.loading && viewModel.filteredOptions.map(o =>
            <FilterDropdownMenu.Checkbox key={o.id} title={o.name}
                                         checked={!!_.find(editingRow.values, (id) => { return id === o.id; })}
                                         onChange={(e) => viewModel.editValues(o, e.checked)}>
              {viewModel.translatable ? <FormattedMessage id={`reports.${o.name}`}/> : o.name}
            </FilterDropdownMenu.Checkbox>
          )}
        </FilterDropdown>
      </div>
      {!viewOnly && <div className='table-cell align-middle pl1'>
        <ActionLink onClick={() => uiState.toggleFilterRow(viewModel.category, false)} className='meadow'>
          <i className='material-icons h3 align-middle'>{'close'}</i>
        </ActionLink>
      </div>}
    </div>
  );
}));

export default MultiSelectFilterRow;
