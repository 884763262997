import React from 'react';
import {observer} from 'mobx-react';
import {NumberInput, Input, DatePicker, TextArea, Select2} from 'components';
import moment from 'moment';

const CustomFieldContentFactory = observer(({customField, type, value, onChange, disabled, allowClear, errorMessage}) => {
  switch (type) {
    case 'text_field': return (
      <Input
        value={value}
        onChange={e => onChange(e.target.value)}
        translateLabel={false}
        disabled={disabled}
        errorMessage={errorMessage}
      />
    );
    case 'number': return (
      <NumberInput
        value={value}
        onChange={e => onChange(e.target.value)}
        translateLabel={false}
        disabled={disabled}
        errorMessage={errorMessage}
      />
    );
    case 'text_area': return (
      <TextArea
        value={value}
        translateLabel={false}
        onChange={e => onChange(e.target.value)}
        disabled={disabled}
        errorMessage={errorMessage}
      />
    );
    case 'date': return (
      <DatePicker
        value={moment(value)}
        onChange={date => onChange(date)}
        translateLabel={false}
        disabled={disabled}
        errorMessage={errorMessage}
      />
    );
    case 'multiple_choice': return (
      <Select2
        allowClear={allowClear}
        value={value}
        onChange={e => onChange(e.target.value)}
        translateLabel={false}
        disabled={disabled}
        errorMessage={errorMessage}
      >
        {customField.sortedOptions.map(option => <option value={option.value} key={option.id}>{option.value}</option>)}
      </Select2>
    );
    default:
      throw new Error(`Custom field ${type} not supported.`);
  }
});

export default CustomFieldContentFactory;
