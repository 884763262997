import _ from 'lodash';
import {action} from 'mobx';
import Answer from 'stores/recruiting/Answer';
import CandidateFormState from './CandidateFormState';

class InternalCandidateFormState extends CandidateFormState {
  @action async load() {
    this.position = await this.store.loadSingle(this.match.params.id);

    for (const question of this.position.questions) {
      this.candidate.answers.push(new Answer({question: question}));
    }
  }
}

export default InternalCandidateFormState;
