import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const QuestionDetails = observer(({type, icon, model}) => {
  if (model && model.options) {
    return (
      model.options.map((option, index) => <div key={index}>
        {icon ?
          <i className='material-icons h3 align-middle jumbo'>{icon}</i> :
          <div className='inline-block jumbo align-middle' style={{width: 12}}>{index + 1}{'.'}</div>}
        <span className='jumbo align-middle pl1' key={index}>{option.value}</span>
      </div>)
    );
  }

  return (
    <div>
      <i className='material-icons h3 align-middle jumbo'>{icon}</i>
      <span className='jumbo align-middle pl1'>
        <FormattedMessage id={`components.question_form.question_types.${type}`} />
      </span>
    </div>
  );
});

const QuestionDetailsFactory = observer(({model}) => {
  const params = { type: model.questionType };
  switch (model.questionType) {
    case 'text_field':
      params.icon = 'short_text';
      break;
    case 'description':
      return null;
    case 'text_area':
      params.icon = 'notes';
      break;
    case 'multiple_choice':
      params.icon = 'radio_button_unchecked';
      params.model = model;
      break;
    case 'multi_select':
      params.icon = 'check_box_outline_blank';
      params.model = model;
      break;
    case 'scored':
      params.model = model;
      break;
    default:
      throw new Error(`Unknown question type: ${model.questionType}`);
  }

  return <QuestionDetails {...params} />;
});

const DraggableFieldContent = observer(({model, sortedModels}) => {
  const firstSection = _.find(sortedModels, { 'questionType': 'description' });

  if(model.questionType === 'description') {
    return (
      <div className='p1'>
        <div className='h3 medium my2'>{model.name}</div>
        <QuestionDetailsFactory model={model} />
      </div>
    );
  }

  return (
    <div className={`p1 ${_.get(firstSection, 'order') < model.order ? 'pl3' : ''}`}>
      <div className='h3 mb1'>{model.name}</div>
      <QuestionDetailsFactory model={model} />
    </div>
  );
});

export default DraggableFieldContent;
