import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const CardDetails = observer(({uiState}) => {
  const {currentClass} = uiState;
  const {companyInsuranceCarrier} = currentClass.benefitDivision;

  return (
    <Panel>
      <Panel.Header title={t('components.coverages_list.Overview')} />
      <div className='clearfix mxn2 mb1'>
        <div className='col col-6 px2 jumbo'>
          <FormattedMessage id='components.coverages_list.Provider'/>
        </div>
        <div className='col col-6 px2'>
          {companyInsuranceCarrier.name}
        </div>
      </div>
      <div className='clearfix mxn2 mb1'>
        <div className='col col-6 jumbo px2'>
          <FormattedMessage id='components.coverages_list.Group ID'/>
        </div>
        <div className='col col-6 px2'>
          {companyInsuranceCarrier.externalId}
        </div>
      </div>
      <div className='clearfix mxn2 mb1'>
        <div className='col col-6 jumbo px2'>
          <FormattedMessage id='components.coverages_list.Division'/>
        </div>
        <div className='col col-6 px2'>
          {currentClass.benefitDivision.name}
        </div>
      </div>
      <div className='clearfix mxn2 mb1'>
        <div className='col col-6 jumbo px2'>
          <FormattedMessage id='components.coverages_list.Class'/>
        </div>
        <div className='col col-6 px2'>
          {currentClass.name}
        </div>
      </div>
      <div className='clearfix mxn2 mb1'>
        <div className='col col-6 jumbo px2'>
          <FormattedMessage id='components.coverages_list.Number of Enrolments'/>
        </div>
        <div className='col col-6 px2'>
          {currentClass.enrolmentCount}
        </div>
      </div>
    </Panel>
  );
});

export default CardDetails;


