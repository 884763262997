import React from 'react';
import {observer} from 'mobx-react';
import PdfViewer from './PdfViewer';
import {AttachedDocumentDownload} from 'components';
import DOCUMENT_TYPES from 'stores/documents/documentTypes';

const DocumentViewer = observer(({uiState}) => {
  const {companyDocument} = uiState.currentDocument;

  switch (companyDocument.previewFile.documentType) {
    case DOCUMENT_TYPES.PDF:
      return <PdfViewer uiState={uiState}/>;
    case DOCUMENT_TYPES.OFFICE:
    case DOCUMENT_TYPES.OTHER:
    default:
      return <AttachedDocumentDownload className='py2 pl1' doc={companyDocument.previewFile} name={companyDocument.name}/>;
  }
});

export default DocumentViewer;
