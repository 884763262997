import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import {endpoints, types} from 'shared/core';
import {sleep} from 'shared/tools';
import qs from 'qs';
import PublicOffer from 'stores/offers/PublicOffer';

class JobOfferContainerState {
  store = new DomainStore();

  @observable jobOffer;
  @observable token;
  @observable errors;
  @observable displayAnimation = false;

  @action async load() {
    this.token = qs.parse(location.search.replace('?', ''))['token'];

    await this.store._compose([
      endpoints.OFFER.PUBLIC.with(this.token)
    ]);
    this.jobOffer = new PublicOffer(this.store._getSingle(
      types.OFFER
    ));
  }

  @action async acceptOffer() {
    const {model, errors} = await this.store.patch(
      endpoints.OFFER.PUBLIC.ACCEPT.with(this.token),
      types.OFFER,
      this.jobOffer
    );

    this.errors = errors;

    if (model) {
      // delay offer acceptance artificially for UX
      await sleep(500);
      this.jobOffer = model;
      this.displayAnimation = true;
    }
  }
}

export default JobOfferContainerState;
