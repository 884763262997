import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {useEffect, useState} from 'react';
import {$getNodeByKey, $setSelection} from 'lexical';
import {RenderedFieldNode} from '../nodes/RenderedFieldNode';
import moment from 'moment';
import {calendarDate} from 'shared/tools';
import {formatCurrency, t} from 'shared/core';

export default function RenderedFieldsPlugin({fields}) {
  const [editor] = useLexicalComposerContext();
  const [nodeKeyMapping] = useState({});

  if (!fields) return null;

  editor.registerMutationListener(
    RenderedFieldNode,
    (mutatedNodes) => {
      editor.update(() => {
        for (let [nodeKey, mutation] of mutatedNodes) {
          if (mutation === 'created') {
            const node = $getNodeByKey(nodeKey);
            if (!nodeKeyMapping[node.__dataKey]) {
              nodeKeyMapping[node.__dataKey] = [];
            }
            nodeKeyMapping[node.__dataKey].push(nodeKey);
            fields.forEach(field => {
              if (field.key === node.__dataKey) {
                setNodeText(node, field);
              }
            });
          }
        }
      });
    }
  );

  function formatField(field) {
    let value = field.value;
    if (field.fieldType === 'date') {
      const date = moment(field.value);
      if (date.isValid()) {
        field.value = date;
        value = calendarDate(field.value);
      }
    } else if (field.fieldType === 'salary') {
      if (!field.value.payRate) {
        value = '';
      } else {
        const formattedPayRate = formatCurrency(field.value.payRateCurrency, field.value.payRate);
        const payRateUnit = ` ${t(`offers.editor.fields.per_unit.${field.value.payRateUnit}`)}`;
        value = `${formattedPayRate}${payRateUnit}`;
      }
    }
    return value || `<${field.label}>`;
  }

  function setNodeText(node, field) {
    const formattedValue = formatField(field);
    if (node.getTextContent() === formattedValue) return;

    $setSelection(null);
    node.setTextContent(formattedValue);
  }

  useEffect(() => {
    editor.update(() => {
      fields.forEach(field => {
        const nodeKeys = nodeKeyMapping[field.key];
        if (!nodeKeys) return;

        nodeKeys.forEach((nodeKey) => {
          const node = $getNodeByKey(nodeKey);
          if (!node) return;

          setNodeText(node, field);
        });
      });
    });
  }, [fields]);

  return null;
}
