import React from 'react';
import {observer} from 'mobx-react';
import {withState, t} from 'shared/core';
import {AuthenticationsContainerState} from '../state';
import {Layout, Panel, Toggle, LabelHelper, QuickTip} from 'components';
import SubNav from 'company_settings/SubNav';
import {FormattedMessage} from 'react-intl';

const AuthenticationsContainer = observer(({uiState}) => {
  const {company} = uiState;

  return (
    <div>
      <SubNav title={t('company_settings.subnav.Authentication')} />
      <Layout>
        <Layout.Section>
          <Panel>
            <Panel.Header
              title={t('company_settings.authentications.Multi-step Authentication')}
              description={t('company_settings.authentications.Force all employees to authenticate via multi-step authentication')}
            />
            <div className='col-12 sm-col-6 mb2'>
              <QuickTip
                trait='info'
                header={t('company_settings.authentications.Google Sign-In & 2FA')}
                paragraphs={[t('company_settings.authentications.SSO_AND_MFA')]}
              />
            </div>
            <div className='medium'>
              <FormattedMessage id='company_settings.authentications.Select which method of two-step verification must be used by your employees' />
            </div>
            <div className='mb2 jumbo h5'>
              <FormattedMessage id='company_settings.authentications.By enabling both options, an employee may choose between using one or the other' />
            </div>
            <div className='mb2'>
              <LabelHelper translateLabel={true} label='company_settings.authentications.SMS Authentication' />
              <Toggle
                enabled={company.mfaSmsEnabled}
                onChange={value => uiState.toggleMfaEnabled({mfaSmsEnabled: value})}
              />
            </div>
            <div>
              <LabelHelper translateLabel={true} label='company_settings.authentications.Authenticator App' />
              <Toggle
                enabled={company.mfaAuthenticatorEnabled}
                onChange={value => uiState.toggleMfaEnabled({mfaAuthenticatorEnabled: value})}
              />
            </div>
          </Panel>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default withState(AuthenticationsContainer, AuthenticationsContainerState);
