import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, LabelWithHeader} from 'components';

const DetailsPanel = observer(({uiState}) => {
  const {calendarStartDate, calendarEndDate, calendarCutoffDate, calendarPayDate} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('payroll.Details')}/>
      <div className='clearfix'>
        <div className='col col-6'>
          <LabelWithHeader
            header={t('payroll.diffs.Pay Period Dates')}
            model={`${calendarStartDate} - ${calendarEndDate}`}
          />
        </div>
        <div className='col col-3'>
          <LabelWithHeader
            header={t('payroll.periods.Cutoff Date')}
            model={calendarCutoffDate}
          />
        </div>
        <div className='col col-3'>
          <LabelWithHeader
            header={t('payroll.periods.Pay Date')}
            model={calendarPayDate}
          />
        </div>
      </div>
    </Panel>
  );
});

export default DetailsPanel;