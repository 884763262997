import React from 'react';
import {observer} from 'mobx-react';
import {Button, Panel, Layout} from 'components';
import {auth, withState} from 'shared/core';
import { FormattedMessage } from 'react-intl';
import HrUpgradeLearnMoreState from '../state/HrUpgradeLearnMoreState';
import InterestedInHrModal from './InterestedInHrModal';
import HrUpgradeInfo from './HrUpgradeInfo';
import HrUpgradeFeatures from './HrUpgradeFeatures';
import HrUpgradePricing from './HrUpgradePricing';
import BannerRecruitingEnglish from 'img/hr-upgrade/banner-english-recruiting@1x.png';
import BannerRecruitingFrench from 'img/hr-upgrade/banner-french-recruiting@1x.png';

function getRecruitingBannerImage() {
  switch (auth.locale) {
    case 'en': 
      return BannerRecruitingEnglish;
    case 'fr':
      return BannerRecruitingFrench;
    default:
      return BannerRecruitingEnglish;
  }
}

const HrUpgradeLearnMore = observer(({uiState}) => {
  return (
    <div>
      <Layout>
        <Panel.Stack loose>
          <div className='flex flex-col md-flex-row align-items-center justify-content-center mxn2'>
            <div className='col col-12 mb2 mx2 mt3 md-col-6 md-pr1'>
              <div className='h1 py2 medium'>
                <FormattedMessage id='hr_upgrade.learn_more.Upgrade to'/> <span className='dodger'><FormattedMessage id='hr_upgrade.learn_more.Collage HR'/></span>
              </div>
              <div className='pb1'>
                <FormattedMessage id='hr_upgrade.learn_more.You’re already using Benefits HQ to manage your benefits enrolment, so why not take the next step to manage HR, benefits and payroll all in one place?'/>
              </div>
              <div className='pb1'>
                <FormattedMessage id='hr_upgrade.learn_more.You can upgrade your Benefits HQ account to Collage HR - the HR, payroll and benefits management platform loved by thousands of Canadian businesses'/>
              </div>
              <div className='mt2 mb3 left'>
                <Button onClick={() => uiState.submit('managed_payroll') }>
                  <FormattedMessage id="hr_upgrade.learn_more.I'm Interested!"/>
                </Button>
              </div>
            </div>
            <div className='col col-12 mb2 mx2 mt3 md-col-6 md-pl1 center'>
              <img src={getRecruitingBannerImage()} width='425px' alt='recruiting_banner'/>
            </div>
          </div>
        
          <Panel.Separator/>
          <HrUpgradeInfo uiState={uiState}/>
          <Panel.Separator/>
          <HrUpgradeFeatures uiState={uiState}/> 
          <Panel.Separator/>
          <HrUpgradePricing uiState={uiState}/>
        </Panel.Stack>

        <InterestedInHrModal uiState={uiState}/>
      
      </Layout>
    </div>
  );
});

export default withState(HrUpgradeLearnMore, HrUpgradeLearnMoreState);
