import React from 'react';
import {observer} from 'mobx-react';
import {loader, t} from 'shared/core';
import ReminderEditState from 'company_settings/reminders/state/ReminderEditState';
import CreateReminderFor from './CreateReminderFor';
import SendReminderTo from './SendReminderTo';
import ReminderDateSelector from './ReminderDateSelector';
import {FormattedMessage} from 'react-intl';
import {TextArea, Button, Display, FormLayout, Layout, Panel} from 'components';
import reminderEditStore from 'company_settings/reminders/state/ReminderEditStore';

const ReminderEditContainer = observer(({uiState}) => {
  const {reminder} = uiState;

  return (
    <div>
      <Display
        title={t(`company_settings.reminders.${reminder.isNew ? 'Add' : 'Edit'} Reminder`)}
        backTitle={t('company_settings.reminders.Reminders')}
        backPath='/reminders'
      />
      <Layout>
        <Layout.Section secondary>
          <Panel>
            <FormLayout>
              <CreateReminderFor uiState={uiState}/>
              <TextArea
                label='company_settings.reminders.Description'
                type='text'
                value={reminder.description}
                placeholder={'company_settings.reminders.Add a description...'}
                onChange={(e) => reminder.merge({description: e.target.value})}
                className='full-width'
              />
              <SendReminderTo uiState={uiState}/>
              <ReminderDateSelector uiState={uiState}/>
            </FormLayout>
          </Panel>
          <Button className={`Btn Btn--large mt3 right`} onClick={() => uiState.saveReminder()}>
            <FormattedMessage id='company_settings.reminders.Save reminder'/>
          </Button>
        </Layout.Section>
      </Layout>
    </div>
  );
});

async function load({match, history}) {
  const id = match.params.id;
  const uiState = new ReminderEditState(reminderEditStore, history);
  await uiState.load(id === 'new' ? undefined : id);
  return {uiState};
}

export default loader(ReminderEditContainer, load);
