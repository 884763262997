import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {Table} from 'components';
import {FormattedMessage} from 'react-intl';
import {rangeDate} from 'shared/tools';
import TableOverlay from 'components/tables/components/TableOverlay';
import DocumentFoldersIcon from 'img/settings/settings-folders@2x.png';
import DocumentsIcon from 'img/settings/settings-documents@2x.png';

const Yes = observer(() => {
  return (
    <FormattedMessage id='employees.profile.documents.shared_status.Yes'/>
  );
});

const No = observer(() => {
  return (
    <FormattedMessage id='employees.profile.documents.shared_status.No'/>
  );
});

const SharedStatus = ({model}) => {
  switch (model.sharedStatus) {
    case 'yes':
      return <Yes/>;
    case 'no':
      return <No/>;
    default:
      return null;
  }
};

const Icon = ({model}) => {
  switch (model.icon) {
    case 'folder':
      return <img src={DocumentFoldersIcon} height='20' width='20' alt='folder'/>;
    case 'document':
      return <img src={DocumentsIcon} height='20' width='20' alt='document'/>;
    default:
      return null;
  }
};

const Name = ({model}) => {
  if (!model.downloadUrl) return <span>{model.name}</span>;

  return <a href={model.downloadUrl} target='_blank' className='dodger'>{model.name}</a>;
};

const CreatedAtDate = ({model}) => {
  if (!model.createdAt) return null;

  return <span>{rangeDate(model.createdAt)}</span>;
};

const COLUMNS_WITHOUT_SHARED = [
  {
    component: Icon,
    width: 1
  },
  {
    header: 'employees.profile.documents.Name',
    component: Name,
    sortBy: 'fileName',
    className: 'breakword',
    width: 8
  },
  {
    header: 'employees.profile.documents.Date Added',
    component: CreatedAtDate,
    sortBy: 'createdAt',
    width: 2
  }
];

const COLUMNS_WITH_SHARED = [
  {
    component: Icon,
    width: 1
  },
  {
    header: 'employees.profile.documents.Name',
    component: Name,
    sortBy: 'fileName',
    className: 'breakword',
    width: 6
  },
  {
    header: 'employees.profile.documents.Date Added',
    component: CreatedAtDate,
    sortBy: 'createdAt',
    width: 2
  },
  {
    header: 'employees.profile.documents.Shared with employee',
    component: SharedStatus,
    width: 2
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'employees.profile.documents.Delete Document',
    subHeader: 'employees.profile.documents.Are you sure you want to delete this document?',
    saveCaption: 'employees.profile.documents.Delete'
  },
  emptyState: 'company_settings.documents.No documents to display',
  interactive: {
    addModel: 'employees.profile.documents.Add Document'
  },
  removeModel: 'employees.profile.documents.Delete'
};

const EmployeeDocumentList = observer(({uiState}) => {
  const {entries, hasSharedStatusColumn} = uiState;
  const builtColumns = hasSharedStatusColumn ? COLUMNS_WITH_SHARED : COLUMNS_WITHOUT_SHARED;

  return (
    <div className='relative'>
      <TableOverlay isUpdating={uiState.isUpdating}/>
      <Table
        models={entries}
        columns={builtColumns}
        localization={LOCALIZATION}
        customLinks={viewModel => viewModel.customLinks}
        onEdit={viewModel => uiState.openEditDocumentModal(viewModel)}
        onRemove={viewModel => uiState.deleteDocument(viewModel)}
        onRowClicked={viewModel => viewModel.select()}
        sortable
        sortingUpdated={sort => uiState.updateSort(sort)}
      />
    </div>
  );
});

export default EmployeeDocumentList;
