import {observable, action, computed} from 'mobx';
import {DomainObject} from 'shared/store';
import {User} from 'stores/users';
import {AttachedDocument} from '../documents';

class OfferSummary extends DomainObject {
  @observable firstName;
  @observable lastName;
  @observable expiresAt;
  @observable sentAt;
  @observable acceptedAt;
  @observable revokedAt;
  @observable hiredAt;
  @observable email;
  @observable revokeReason;
  @observable viewStatus;
  @observable viewStatusUpdatedAt;
  @observable candidateId;
  @observable attachments;
  @observable renderedContent;
  @observable createdByUser;
  @observable isExpired;
  @observable isAccepted;
  @observable isRevoked;

  @action merge(other) {
    super.merge(other, {
      _dates: ['expiresAt', 'acceptedAt', 'revokedAt', 'hiredAt'],
      attachments: [AttachedDocument],
      offerDocument: AttachedDocument,
      createdByUser: User
    });
  }

  @computed get name() {
    return `${this.firstName} ${this.lastName}`;
  }
}

export default OfferSummary;
