import React from 'react';
import {observer} from 'mobx-react';
import Avatar from './Avatar';

const Note = observer(({title, detail, author, children}) => {
  return (
    <div>
      <div className='h3 medium'>
        {title}
      </div>
      {detail && <div className='h5 waterloo'>
        {detail}
      </div>}
      <div className='clearfix table'>
        <div className='col-1 table-cell align-top pr2 pt2'>
          <Avatar employee={author} size='sm' className='left'/>
        </div>
        <div className='col-11 table-cell pt2'>
          <div className='h4 whitespace-pre-wrap'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Note;
