import React from 'react';
import {FormattedMessage} from 'react-intl';
import {withState, t} from 'shared/core';
import {Avatar, Panel} from 'components';
import {fullName} from 'shared/tools';
import dataTablesLocalization from 'messages/dataTables';
import AdminRevieweesContainerState from './state/AdminRevieweesContainerState';
import {Display} from 'components';
import {Layout} from 'components';
/*global $*/

const EmployeeRow = ({reviewee, reviewer, daysSinceLastCheckin, cycleId}) => {
  return (
    <tr className='js-link'
        data-link={`/performance/cycles/${cycleId}/reviewees/${reviewee.id}`}>
      <td>
        <div className="table-cell align-middle pr2">
          <Avatar size='sm' employee={reviewee}/>
        </div>
        <div className="table-cell align-middle">
          {fullName(reviewee)}
        </div>
      </td>
      <td>
        <div className='table-cell align-middle'>
          <Avatar employee={reviewer} className='' size='sm'/>
        </div>
        <div className='table-cell align-middle'>
          <span className='ml2'>{fullName(reviewer)}</span>
        </div>
      </td>
      {daysSinceLastCheckin != null && <td className='h6 waterloo caps'>
        <FormattedMessage id="performance.DAYS" values={{numberOfDays: daysSinceLastCheckin}}/>
      </td>}
      {daysSinceLastCheckin == null && <td className='h6 waterloo'>
        <FormattedMessage id="performance.NO_CHECK_INS"/>
      </td>}
    </tr>
  );
};

class AdminRevieweesContainer extends React.Component {
  componentDidMount() {
    $(this.refs.table).dataTable({
      bLengthChange: false,
      language: dataTablesLocalization.EMPLOYEE_SEARCH
    });
  }

  componentWillUnmount() {
    $(this.refs.table).DataTable().destroy();
  }

  render() {
    const {uiState, match} = this.props;
    const {pairs, name} = uiState;
    const cycleId = match.params.id;

    return (
      <React.Fragment>
        <Display backPath='/cycles' backTitle={t('performance.Check-In Cycles')} title={name}/>
        <Layout>
          <Panel>
            <div className='Table'>
              <table ref='table' className='full-width'>
                <thead>
                <tr>
                  <th><FormattedMessage id='performance.NAME'/></th>
                  <th><FormattedMessage id='performance.LEADER'/></th>
                  <th><FormattedMessage id='performance.DAYS_SINCE_LAST_CHECK_IN'/></th>
                </tr>
                </thead>
                <tbody>
                {pairs.map((pair) => <EmployeeRow key={pair.id} {...pair} cycleId={cycleId}/>)}
                </tbody>
              </table>
            </div>
          </Panel>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withState(AdminRevieweesContainer, AdminRevieweesContainerState);
