import React from 'react';
import {Spinner, ActionLink} from 'components';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';

const CreateDocumentChoice = observer(({uiState}) => {
  if (uiState.processing) return <Spinner />;
  return (
    <div className='col-10 mx-auto'>
      <div className='h3 jumbo pt3 pb2 max-width-2 center'>
        <FormattedMessage id='company_settings.documents.Are you uploading an existing file or creating one from scratch?'/>
      </div>
      <div className='clearfix mxn3 pt3 pb4'>
        <div className='col col-12 sm-col-6 px3'>
          <ActionLink onClick={() => uiState.uploadExistingDocuments()} className='block p3 bg-white bg-hover-smoke border rounded center'>
            <div className='mb2'>
              <i className="material-icons h1 dodger">{'computer'}</i>
            </div>
            <span className='waterloo'>
              <FormattedMessage id='company_settings.documents.Upload a file from your computer' />
            </span>
          </ActionLink>
        </div>
        <div className='col col-12 sm-col-6 px3'>
          <ActionLink onClick={() => uiState.createNewCompanyDocument()} className='block p3 bg-white bg-hover-smoke border rounded center'>
            <div className='mb2'>
              <i className="material-icons h1 dodger">{'add_circle'}</i>
            </div>
            <span className='waterloo'>
              <FormattedMessage id='company_settings.documents.Create a custom document' />
            </span>
          </ActionLink>
        </div>
      </div>
    </div>
  );
});

const UploadDocumentChoice = observer(({uiState}) => {
  return (
    <div className='col-10 mx-auto'>
      <div className='h3 jumbo pt3 pb2 max-width-2 center'>
        <FormattedMessage id='company_settings.documents.Do you want to upload an annotatable PDF or do a regular file upload?'/>
      </div>

      <div className='clearfix mxn3 pt3 pb4'>
        <div className='col col-6 px3'>
          <Link to='/documents/upload/pdf_template' className='block p3 bg-white bg-hover-smoke border rounded center'>
            <div className='mb2'>
              <i className="material-icons h1 dodger">{'tune'}</i>
            </div>
            <span className='waterloo'>
              <FormattedMessage id='company_settings.documents.Upload and annotate a PDF' />
            </span>
          </Link>
        </div>
        <div className='col col-6 px3'>
          <Link to='/documents/upload/static_document' className='block p3 bg-white bg-hover-smoke border rounded center'>
            <div className='mb2'>
              <i className="material-icons h1 dodger">{'attach_file'}</i>
            </div>
            <span className='waterloo'>
              <FormattedMessage id='company_settings.documents.Regular file upload (cannot be annotated)' />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
});

const AddCompanyDocumentModal = observer(({uiState}) => {
  return (
    <Modal isOpen={uiState.addDocumentModalOpen} onHide={() => uiState.closeAddDocumentModal()} size='md' closeButton={false}>
      {uiState.uploadMethod ? <UploadDocumentChoice uiState={uiState}/> : <CreateDocumentChoice uiState={uiState}/>}
    </Modal>
  );
});

export default AddCompanyDocumentModal;
