import {observable, action, computed} from 'mobx';

class PaginationState {
  paginationUpdated;
  hidePaginationInfo;

  @observable pagination;

  constructor({pagination, paginationUpdated, hidePaginationInfo}) {
    this.pagination = pagination;
    this.paginationUpdated = paginationUpdated;
    this.hidePaginationInfo = hidePaginationInfo;
  }

  @action async nextPage() {
    if (!this.nextEnabled) return;

    await this.paginationUpdated({
      currentPage: this.pagination.currentPage + 1
    });
  }

  @action async prevPage() {
    if (!this.prevEnabled) return;

    await this.paginationUpdated({
      currentPage: this.pagination.currentPage - 1
    });
  }

  @action updatePagination(pagination) {
    this.pagination = pagination;
  }

  @computed get startEntryIndex() {
    return (this.pagination.currentPage - 1) * this.pagination.pageSize + 1;
  }

  @computed get endEntryIndex() {
    return Math.min(
      this.pagination.currentPage * this.pagination.pageSize,
      this.pagination.totalCount
    );
  }

  @computed get visible() {
    return !!this.pagination.totalCount &&
      (this.buttonsVisible || !this.hidePaginationInfo);
  }

  @computed get buttonsVisible() {
    return this.nextEnabled || this.prevEnabled;
  }

  @computed get nextEnabled() {
    return this.pagination.currentPage < this.pagination.totalPages;
  }

  @computed get prevEnabled() {
    return this.pagination.currentPage > 1;
  }
}

export default PaginationState;
