import {observable} from 'mobx';
import _ from 'lodash';

class RoleAssignmentViewModel {
  @observable user;
  @observable role;
  @observable checked;

  constructor(user, role) {
    this.user = user;
    this.role = role;
    this.checked = !!this.findExistingAssignment();
  }

  findExistingAssignment() {
    return _.find(
      this.user.roleAssignments,
      assignment => assignment.role.id === this.role.id
    );
  }

  toJS() {
    const existingAssignment = this.findExistingAssignment();
    return {
      id: _.get(existingAssignment, 'id'),
      role_id: this.role.id
    };
  }
}

export default RoleAssignmentViewModel;
