import React from 'react';
import {endpoints, types} from 'shared/core';
import {observer} from 'mobx-react';
import FilterComponent from './FilterComponent';
import useFetchModels from 'shared/hooks/useFetchModels';

const Department = observer(({filter, filterKey, filterUpdated, filterDeleted, Wrapper}) => {
  const [models, isLoading] = useFetchModels(endpoints.DEPARTMENTS, types.DEPARTMENT);
  const options = models.map(department => ({ label: department.name, value: department.id }));

  return <FilterComponent
    filter={filter}
    filterKey={filterKey}
    options={options}
    filterUpdated={filterUpdated}
    filterDeleted={filterDeleted}
    Wrapper={Wrapper}
    isLoading={isLoading}
    searchable
  />;
});

export default Department;
