import React from 'react';
import LogoSpinner from 'components/spinners/logo/LogoSpinner';
import {observer} from 'mobx-react';
import {DemoDataDeletionState} from '../state';
import {withState} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const DemoDataDeletion = observer(({uiState}) => {
  return (
    <div>
      <div className='mx-auto bg-white box-shadow-1 rounded' style={{width: '650px'}}>
        <div className='h2 mt4 pt3 center'>
          <FormattedMessage id="demo_data_deletion.Please wait while we configure your account."/>
        </div>
        <div className='waterloo mt1 center'>
          <FormattedMessage id="demo_data_deletion.Once this process is complete, you will redirected automatically."/>
        </div>
        <div className='relative mx-auto' style={{height: '255px'}}>
          <LogoSpinner height='120' width='54' animate={true}/>
        </div>
      </div>
    </div>
  );
});

export default withState(DemoDataDeletion, DemoDataDeletionState);
