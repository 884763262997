import React from 'react';
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {auth} from 'shared/core';
import BenefitsNotEnabledSwitch from './BenefitsNotEnabledSwitch';
import BenefitsEnabledSwitch from './BenefitsEnabledSwitch';


const BenefitsPage = observer(() => {
  return (
    <Router basename='/benefits'>
      <Switch>
        {auth.company.benefitsEnabled
          ? <BenefitsEnabledSwitch />
          : <BenefitsNotEnabledSwitch/>
        }
      </Switch>
    </Router>
  );
});

export default BenefitsPage;
