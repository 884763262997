import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {BenefitEnrolmentFormState} from '../state';
import EmployeeInfoSection from './EmployeeInfoSection';
import BenefitsPlanSection from './BenefitsPlanSection';
import InvitationSection from './InvitationSection';
import {withState, t} from 'shared/core';
import {Display, Button, FormError} from 'components';

const BenefitEnrolmentForm = observer(({uiState}) => {
  const {errors} = uiState;

  return (
    <div>
      <Display
        backTitle={t('benefit_enrolment.enrol.Overview')}
        backPath={'/benefit_enrolments'}
        title={t('benefit_enrolment.enrol.New Enrolment')}
      />
      <div className="Container">
        <div className='clearfix'>
          <div className='col col-12 sm-col-10'>
            <EmployeeInfoSection uiState={uiState}/>
            <BenefitsPlanSection uiState={uiState}/>
            <InvitationSection uiState={uiState}/>
            <FormError message={errors.base} alignRight/>
            <div className='mt2 right'>
              <Button onClick={() => uiState.createEmployee()}>
                <FormattedMessage id='benefit_enrolment.enrol.Send Invite'/>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default withState(BenefitEnrolmentForm, BenefitEnrolmentFormState);
