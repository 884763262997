import React from 'react';
import {observer} from 'mobx-react';
import {Panel} from 'components';
import NewNote from './NewNote';
import NoteList from './NoteList';
import DeleteNoteModal from './DeleteNoteModal';
import EditNoteModal from './EditNoteModal';
import {withState} from 'shared/core';
import EmployeeNoteState from './state/EmployeeNoteState';

const NotesContainerWithState = observer(({uiState}) => {
  const {parentState} = uiState;

  return (
    <Panel.Stack loose>
      {parentState.editDataPermission('::NOTES') && <NewNote uiState={uiState} />}
      <NoteList uiState={uiState} />
      {parentState.editDataPermission('::NOTES') && <EditNoteModal uiState={uiState} />}
      {parentState.editDataPermission('::NOTES') && <DeleteNoteModal uiState={uiState} />}
    </Panel.Stack>
  );
});

export default withState(NotesContainerWithState, EmployeeNoteState);
