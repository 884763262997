import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {Input, FormLayout, Select2} from 'components';

const EditContactDetailsModal = observer(({uiState}) => {
  const {subscription, editSubscription, selectedEmails, errors} = uiState;
  if (!editSubscription) return null;

  return (
    <Modal isOpen={uiState.editContactDetailsModalOpen}
           onHide={() => uiState.closeEditContactDetailsModal()}
           size='md'
           title='company_settings.billing.Edit Contact Details'>
      <FormLayout>
        <Input
          value={editSubscription.firstName}
          label='company_settings.billing.First Name'
          errorMessage={errors.firstName}
          onChange={(e) => editSubscription.merge({firstName: e.target.value})}
        />
        <Input
          value={editSubscription.lastName}
          label='company_settings.billing.Last Name'
          errorMessage={errors.lastName}
          onChange={(e) => editSubscription.merge({lastName: e.target.value})}
        />
        <Input
          value={editSubscription.email}
          label='company_settings.billing.Email'
          errorMessage={errors.email}
          onChange={(e) => editSubscription.merge({email: e.target.value})}
        />
        <Select2
          multiple
          allowTagCreation
          label='company_settings.billing.CC Emails'
          placeholder='company_settings.billing.Add CC emails'
          errorMessage={errors.ccEmails}
          value={selectedEmails}
          onChange={(e) => uiState.onCcEmailsChange(e)}
        >
          {subscription.ccEmails.slice().map(email => (
            <option value={email} key={email}>{email}</option>
          ))}
        </Select2>
      </FormLayout>
      <ModalButtons onSave={() => uiState.saveContactDetails()} onCancel={() => uiState.closeEditContactDetailsModal()} />
    </Modal>
  );
});

export default EditContactDetailsModal;
