import {endpoints} from 'shared/core';
import {observable, action} from 'mobx';

class ManagerCheckInState {
  checkinStore;
  match;
  history;

  @observable isFormDirty = false;

  receiveProps({checkinStore, match, history}) {
    this.checkinStore = checkinStore;
    this.match = match;
    this.history = history;
  }

  @action async load() {
    await this.checkinStore._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.PERFORMANCE.REVIEWEE_CHECKINS.with(this.match.params.employeeId),
    ]);

    this.checkinStore.allCheckins = this.checkinStore.sortAllCheckins();
    this.checkinStore.selectedReviewee = this.checkinStore.getEmployee(this.match.params.employeeId);
    await this.checkinStore.selectFirstCheckin();
  }

  @action updateReviewerNotes(e, checkin) {
    checkin.reviewerNotes = e.target.value;
    checkin.autosaver.autosave();
  }

  @action updateSharedReviewerNotes(e, checkin) {
    checkin.sharedReviewerNotes = e.target.value;
    checkin.autosaver.autosave();
  }

  onCheckInSelected = async (checkin) => {
    await this.checkinStore.selectCheckIn(checkin);
  };

  onMarkCompleted = async () => {
    await this.checkinStore.markComplete(this.match.params.employeeId);
    this.history.push(`/reviewees/${this.match.params.employeeId}`);
  };

  onMarkUncompleted = async () => {
    await this.checkinStore.markUncomplete(this.match.params.employeeId);
    this.history.push(`/reviewees/${this.match.params.employeeId}`);
  };
}

export default ManagerCheckInState;
