import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, FormLayout, EmployeeSelector, FormError} from 'components';

const Participants = observer(({uiState}) => {
  const {errors} = uiState;

  return (
    <div>
      <Panel.Header 
        title={t('performance_reviews.edit.Participants')}
        description={t('performance_reviews.edit.choose_participants')} />
      <FormLayout>
        <EmployeeSelector 
          selectedEmployees={uiState.selectedEmployees}
          onChange={employees => uiState.updateSelectedEmployees(employees)} 
        />
        <FormError message={errors.reviewees} />
      </FormLayout>
    </div>
  );
});

export default Participants;
