import React from 'react';
import TimelineEntry from './TimelineEntry';
import {observer} from 'mobx-react';
import NewEntry from './NewEntry';
import NewEmail from './NewEmail';
import FileUpload from './FileUpload';
import NewInterviewGuide from './NewInterviewGuide';

const TimelineSection = ({detailsState, viewModel}) => {
  if (!viewModel.enabled) return null;

  return (
    <div className='border rounded my3'>
      <div className='px3 py1 bg-athens '>
        <div className='h3 medium inline-block'>{viewModel.stage.name}</div>
        <div className='inline-block right right-align'>
          <div className='table-cell align-middle grass px2'>
            <i className='material-icons h3 align-text-top mr1'>{'thumb_up'}</i>
            <span className='align-top'>{viewModel.positiveSentimentCount}</span>
          </div>
          <div className='table-cell align-middle scarlet pl1'>
            <i className='material-icons h3 align-text-top mr1'>{'thumb_down'}</i>
            <span className='align-top'>{viewModel.negativeSentimentCount}</span>
          </div>
        </div>
      </div>
      <NewEntry detailsState={detailsState} sectionViewModel={viewModel}/>
      <NewEmail detailsState={detailsState} sectionViewModel={viewModel}/>
      <NewInterviewGuide detailsState={detailsState} sectionViewModel={viewModel}/>
      <FileUpload detailsState={detailsState} sectionViewModel={viewModel} />
      {viewModel.orderedEntries.map(entry =>
        <TimelineEntry candidate={viewModel.candidate} key={entry.id} entry={entry} detailsState={detailsState} sectionViewModel={viewModel}/>)}
    </div>
  );
};

export default observer(TimelineSection);

