import React from 'react';
import {observer} from 'mobx-react';
import SubNav from 'company_settings/SubNav';
import {withState, t} from 'shared/core';
import {Button, Panel, Table, Layout} from 'components';
import {FormattedMessage} from 'react-intl';
import ReorderLinksModal from './ReorderLinksModal';
import CompanyLinkListState from '../state/CompanyLinkListState';
import EditCompanyLinkModal from './EditCompanyLinkModal';

const ReorderLinksButton = observer(({uiState, group}) => {
  return (
    <div className='table-cell'>
      <Button size='sm' trait='default' onClick={() => uiState.openReorderLinksModal(group)}>
        <FormattedMessage id='company_settings.company_links.Reorder Links'/>
      </Button>
    </div>
  );
});

const AddCompanyLinkButton = observer(({uiState}) => {
  return (
    <div className='table-cell'>
      <Button className='ml2' size='sm' onClick={() => uiState.openEditCompanyLinkModal()}>
        <FormattedMessage id='company_settings.company_links.+ Add Link'/>
      </Button>
    </div>
  );
});

const CompanyLink = observer(({model}) => {
  return (
    <a className='break-word' href={model.url} target='_blank'>{model.url}</a>
  );
});

const COLUMNS = [
  {
    header: 'company_settings.company_links.Link Title',
    attribute: 'name',
    width: 4
  },
  {
    header: 'company_settings.company_links.Hyperlink',
    component: CompanyLink,
    width: 7
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'company_settings.company_links.Remove Company Link?',
    subHeader: 'company_settings.company_links.Are you sure you want to remove this company link?'
  }
};

const CompanyLinksContainer = observer(({uiState}) => {
  const {sortedCompanyLinks} = uiState;

  return (
    <div>
      <SubNav title={t('company_settings.subnav.Company Links')} toolbar={
        <div className='inline-block'>
        </div>
      }/>
      <Layout>
        <Panel>
          <Panel.Header title={t('company_settings.subnav.Company Links')}>
            <div className='inline-block'>
              <ReorderLinksButton uiState={uiState}/>
              <AddCompanyLinkButton uiState={uiState}/>
            </div>
          </Panel.Header>
          <Table
            localization={LOCALIZATION}
            columns={COLUMNS}
            models={sortedCompanyLinks}
            onEdit={companyLink => uiState.openEditCompanyLinkModal(companyLink)}
            onRemove={companyLink => uiState.deleteCompanyLink(companyLink)}
          />
        </Panel>
      </Layout>
      <EditCompanyLinkModal uiState={uiState}/>
      <ReorderLinksModal uiState={uiState}/>
    </div>
  );
});

export default withState(CompanyLinksContainer, CompanyLinkListState);
