import React from 'react';
import {observer} from 'mobx-react';
import {withState, t, types, endpoints} from 'shared/core';
import {MassUpdatesDirectoryState} from '../state';
import {FormattedMessage} from 'react-intl';
import {EmployeeNameWithAvatar, AvatarList, Display, Layout, InteractiveTable, Panel} from 'components';
import {absoluteDateFormatter} from 'shared/tools';
import {MassUpdateSummary} from 'stores/mass_updates';
import useModal from 'shared/hooks/useModal';
import CreateMassUpdateModal from './CreateMassUpdateModal';

const COLUMNS = [
  {
    header: 'mass_updates.directory.Created By',
    render: (model => <EmployeeNameWithAvatar employee={model.createdByUser} size='sm'/>),
    width: 2
  },
  {
    header: 'mass_updates.directory.Employees',
    render: (model => <AvatarList employees={model.employees}/>),
    width: 2
  },
  {
    header: 'mass_updates.directory.Date Started',
    render: (model) => absoluteDateFormatter(model.createdAt),
    width: 2
  },
  {
    header: 'mass_updates.directory.Type',
    render: (model) => <FormattedMessage id={`mass_updates.directory.${model.massUpdateType}`}/>,
    width: 1
  },
  {
    header: 'mass_updates.directory.Effective Date',
    render: (model) => absoluteDateFormatter(model.effectiveDate),
    width: 2
  },
  {
    header: 'mass_updates.directory.Status',
    render: (model) => <FormattedMessage
      id={`mass_updates.directory.status.${model.status}`}
      values={{errorCount: model.errorCount}}
    />,
    width: 2
  },
];

const LOCALIZATION = {
  emptyState: 'mass_updates.directory.No mass updates to display',
  removeModal: {
    header: 'mass_updates.directory.Remove mass update?',
  },
  interactive: {
    addModel: 'mass_updates.directory.Make Changes',
  },
};

const MassUpdatesDirectory = observer(({uiState}) => {
  const createMassUpdateModalOpen = useModal(uiState, 'CreateMassUpdateModal');

  return (
    <div>
      <Display
        title={t('mass_updates.directory.Mass Updates')}
      />
      <Layout>
        <Panel.Stack loose>
          <Panel className='bg-sky border-left--dodger'>
            <Panel.Header title={t('mass_updates.directory.What are mass updates?')}/>
            <FormattedMessage id={'mass_updates.directory.EXPLANATION'}/>
          </Panel>
          <InteractiveTable
            uiState={uiState}
            columns={COLUMNS}
            localization={LOCALIZATION}
            searchable={false}
            saveStateToUrl={true}
            sortable={false}
            showRoute='/:id'
            onAdd={() => uiState.openCreateMassUpdateModal()}
            onRemove={model => uiState.removeMassUpdate(model)}
            proxy={{
              type: 'async',
              modelType: types.MASS_UPDATE_SUMMARY,
              model: MassUpdateSummary,
              endpoint: endpoints.MASS_UPDATES.ALL
            }}
          />
        </Panel.Stack>
        <CreateMassUpdateModal modalOpen={createMassUpdateModalOpen} uiState={uiState}/>
      </Layout>
    </div>
  );
});

export default withState(MassUpdatesDirectory, MassUpdatesDirectoryState);
