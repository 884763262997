import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {FormattedMessage} from 'react-intl';
import {HelpLink} from 'components/links';
import {endpoints, types, withState} from 'shared/core';
import {DomainStore} from 'shared/store';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';

const filters = [
  'Department',
  'Employee',
  'Employment Status',
  'Employment Type',
  'Gender',
  'Location',
  'Manager',
  'Pay Rate',
  'Start Date'
];

class ReportTitleState {
  store = new DomainStore();

  @observable company;

  @action async load() {
    await this.store._compose([
      endpoints.CURRENT_COMPANY
    ]);

    this.company = this.store._getSingle(types.COMPANY);
  }
}

const ReportTitle = withState(observer(({uiState}) => {
  const {
    maxUnconditionalEligibilityAge,
    maxConditionalEligibilityAge,
    maxQcConditionalEligibilityAge
  } = uiState.company;

  return (
    <div>
      <FormattedMessage id='reports.DEPENDENT_ELIGIBILITY_REPORT'/>
      <span className='ml1 h4'>
        <HelpLink explanation={{
          header: 'reports.Dependent Benefits Eligibility Report',
          paragraphs: [
            <FormattedMessage
              id='reports.DEPENDENTS_EXPLAINER_1'
              values={{
                maxUnconditionalEligibilityAge,
                maxConditionalEligibilityAge,
                maxQcConditionalEligibilityAge
              }}
            />,
            <FormattedMessage
              id='reports.DEPENDENTS_EXPLAINER_2'
              values={{
                maxUnconditionalEligibilityAge,
                maxConditionalEligibilityAge,
                maxQcConditionalEligibilityAge
              }}
            />
          ]
        }}/>
      </span>
    </div>
  );
}), ReportTitleState);

const DependentEligibilityReport = () => {
  return (
    <CompositeReport filters={filters}>
      <Report name={<ReportTitle/>}
        url='/reports/dependent_eligibility' />
    </CompositeReport>
  );
};

export default DependentEligibilityReport;
