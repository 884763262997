import {observable} from 'mobx';
import {DomainStore} from 'shared/store';

class CompanyLinksState {
  store = new DomainStore();
  Emoji;

  @observable companyLinks;

  receiveProps({companyLinks, Emoji}) {
    this.companyLinks = companyLinks;
    this.Emoji = Emoji;
  }
}

export default CompanyLinksState;
