import React from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import EmployeeRoleContainer from './EmployeeRoleContainer';
import {EditEmploymentRoleContainer} from './edit';

const EmployeeRoleSwitch = withRouter(({uiState, match}) => {
  return (
    <Switch>
      <Route exact path={`${match.url}/`} render={() => <EmployeeRoleContainer uiState={uiState}/>}/>
      <Route exact path={`${match.url}/records/create`} render={() => <EditEmploymentRoleContainer action='new' parentState={uiState}/>}/>
      <Route exact path={`${match.url}/records/leave`} render={() => <EditEmploymentRoleContainer action='placeOnLeave' parentState={uiState}/>}/>
      <Route exact path={`${match.url}/records/reactivate`} render={() => <EditEmploymentRoleContainer action='reactivate' parentState={uiState}/>}/>
      <Route exact path={`${match.url}/records/terminate`} render={() => <EditEmploymentRoleContainer action='terminate' parentState={uiState}/>}/>
      <Route path={`${match.url}/records/:recordId`} render={() => <EditEmploymentRoleContainer action='edit' parentState={uiState}/>}/>
    </Switch>
  );
});

export default EmployeeRoleSwitch;
