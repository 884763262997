import _ from 'lodash';
import {observable, action, computed} from 'mobx';
import moment from 'moment';
import {calendarDate} from 'shared/tools';
import {t} from 'shared/core';
import { EmployeeBenefitEnrollment } from 'stores/benefits';

class EmployeeBenefitEnrollmentViewModel {
  allowNotEligible;

  @observable record;
  @observable hasProbation;
  @observable insuranceNumberRecord;
  @observable insuranceNumberIndex;

  constructor({record, insuranceNumberRecord, insuranceNumberIndex, allowNotEligible = true}) {
    this.record = record;
    this.allowNotEligible = allowNotEligible;
    this.insuranceNumberRecord = insuranceNumberRecord;
    this.insuranceNumberIndex = insuranceNumberIndex;
  }

  static createFor(carrier, employeeInsuranceNumbers) {
    const enrolment = new EmployeeBenefitEnrollment({companyInsuranceCarrier: carrier});

    if (!carrier.selfAssignedCertificatesEnabled) {
      return new EmployeeBenefitEnrollmentViewModel({record: enrolment});
    }

    const insuranceNumberForCarrier = _.find(employeeInsuranceNumbers, (insuranceNumber) => {
      return insuranceNumber.companyInsuranceCarrier.id === carrier.id;
    });
    const insuranceNumberIndex = _.findIndex(employeeInsuranceNumbers, (insuranceNumber) => {
      return insuranceNumberForCarrier.companyInsuranceCarrier.id === carrier.id;
    });

    return new EmployeeBenefitEnrollmentViewModel({
      record: enrolment,
      insuranceNumberRecord: insuranceNumberForCarrier,
      insuranceNumberIndex: insuranceNumberIndex,
    });
  }

  @action setHasProbation(value) {
    this.hasProbation = value;

    if (this.hasProbation === "probation") {
      this.record.merge({probation: this.record.benefitClass.defaultProbation || 0});
    } else if (this.hasProbation === "waived") {
      this.record.merge({probation: null});
    }

    this.record.merge({probationTimePeriod: this.record.benefitClass.probationTimePeriod});
  }

  @action updateBenefitClass(value) {
    this.record.merge({benefitClass: value});
    this.setHasProbation("probation");

    if (this.insuranceNumberRecord) {
      this.insuranceNumberRecord.externalId = '';
    }
  }

  @action updateEnrolmentDate(value) {
    this.record.merge({enrolmentDate: value});
  }

  @computed get eligibleDate() {
    const {record} = this;
    const date = moment(this.enrolmentDate);

    if (record.probationTimePeriod === 'months') {
      date.add(record.probation, 'M');
    } else {
      date.add(record.probation, 'd');
    }

    return calendarDate(date);
  }

  @computed get benefitClassOptions() {
    const NOT_ELIGIBLE_OPTION = {
      id: '-1',
      name: t('components.benefits.benefit_enrolments.Not eligible'),
      defaultProbation: 0
    };
    const options = this.record.companyInsuranceCarrier.benefitClasses.map(benefitClass => ({
      id: benefitClass.id,
      name: benefitClass.fullName,
      defaultProbation: benefitClass.defaultProbation,
      probationTimePeriod: benefitClass.probationTimePeriod
    }));

    if (this.allowNotEligible) {
      return [NOT_ELIGIBLE_OPTION, ...options];
    }

    return options;
  }

  @computed get showProbationaryPeriod() {
    return !!this.record.benefitClass;
  }

  @computed get probationOptions() {
    const options = [];

    if (this.showProbationaryPeriod && !this.notEligibleSelected) {
      options.push({
        value: "probation",
        text: t(`components.benefits.${this.record.benefitClass.probationTimePeriod}`, { probation: this.record.benefitClass.defaultProbation })
      });
      options.push({ value: "waived", text: t('components.benefits.Waive Probation')});
    }

    return options;
  }

  @computed get notEligibleSelected() {
    return _.get(this.record, 'benefitClass.id') === '-1';
  }

  @computed get showEligibilityMessage() {
    return (!!this.record.enrolmentDate || !!this.record.startDate) &&
      !this.notEligibleSelected &&
      this.showProbationaryPeriod;
  }

  @computed get enrolmentDate() {
    const {record} = this;
    const date = record.startDate || record.enrolmentDate;

    return date;
  }

  @computed get insuranceNumber() {
    if (!this.record.companyInsuranceCarrier.selfAssignedCertificatesEnabled) return null;
    if (this.notEligibleSelected || !this.record.benefitClass) return null;

    return this.insuranceNumberRecord;
  }
}

export default EmployeeBenefitEnrollmentViewModel;
