import React from 'react';
import {ModalDangerButtons, AlertModal} from 'components/modals';
import {observer} from 'mobx-react';
import {types} from 'shared/core';

function localization(type) {
  switch (type) {
    case types.TERMINATION_RECORD:
      return {
        subHeader: 'employees.profile.role.Are you sure you want to remove this termination record?',
        body: 'employees.profile.role.REMOVE_TERMINATION_INFO'
      };
    case types.REACTIVATION_RECORD:
    return {
      subHeader: 'employees.profile.role.Are you sure you want to remove this reactivation record?',
      body: 'employees.profile.role.Removing this reactivation record will cause the employee to be terminated again.'
    };
    case types.EMPLOYMENT_RECORD:
    case types.ON_LEAVE_RECORD:
    default:
      return {
        subHeader: 'employees.profile.role.Are you sure you want to remove this employment record?',
        body: 'employees.profile.role.This record will be removed from the employment history.'
      };
  }
}

const DeleteEmploymentRecordModal = ({uiState}) => {
  const {deleteModalOpen} = uiState;

  return (
    <AlertModal mode='danger' size='sm'
      {...localization(uiState.recordType)}
      onHide={() => uiState.cancelDeleteModal()}
      isOpen={deleteModalOpen}>
      <ModalDangerButtons
        saveCaption='Remove'
        onCancel={() => uiState.cancelDeleteModal()}
        onSave={() => uiState.destroyEmploymentRecord()}
      />
    </AlertModal>
  );
};

export default observer(DeleteEmploymentRecordModal);
