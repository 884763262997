const METRIC_TYPE_OPTIONS = [
  'percentage',
  'currency',
  'number',
  'boolean'
];

export default METRIC_TYPE_OPTIONS.map(t => ({
  id: t,
  label: `models.performance_goals.metrics.type.${t}`
}));
