import {DomainObject} from 'shared/store';
import {action, computed, observable} from 'mobx';
import moment from 'moment';
import _ from 'lodash';
import uuid from 'uuid';
import TimeTrackingProject from './TimeTrackingProject';

class TimesheetEntry extends DomainObject {
  @observable date;
  @observable showDate = true;
  @observable startTime = null;
  @observable endTime = null;
  @observable startTimeString = '';
  @observable endTimeString = '';
  @observable note;
  @observable approved = false;
  @observable approvedStartTime = null;
  @observable approvedEndTime = null;
  @observable autosavedAt;
  @observable errors = {};
  @observable saveStatus;
  @observable saveStatusTimeout;
  @observable unpaidBreakMinutes = 0;
  @observable project;

  @action merge(other) {
    this.uuid = this.uuid ? this.uuid : uuid.v4();

    super.merge(other, {
      project: TimeTrackingProject,
      _dates: ['date']
    });
  }

  @computed get hours() {
    if (!this.startTimeString || !this.endTimeString) {
      return null;
    }

    return Math.max(Math.round((((this.endTimeFromString - this.startTimeFromString) / 3600000) - (this.unpaidBreakMinutes / 60)) * 100) / 100, 0);
  }

  @computed get hasNote() {
    return !_.isEmpty(this.note);
  }

  @computed get hasBreak() {
    return !!this.unpaidBreakMinutes;
  }

  @computed get startTimeFromString() {
    if (!this.startTimeString) {
      return null;
    }

    // The frontend works in browser local time for everything, but we want the backend to store the time in UTC
    // .utc(true) sets the timezone to UTC without changing the time, so the 9:00a that the user enters
    // gets saved as 9:00am UTC regardless of the browser's timezone
    return moment(`${moment(this.date).format('YYYY-MM-DD')} ${this.startTimeString}`, ['YYYY-MM-DD h:m a']).utc(true);
  }

  @computed get endTimeFromString() {
    if (!this.endTimeString) {
      return null;
    }

    const date = moment(this.date);
    if (moment(this.endTimeString, ['h:m a', 'H:m']) < moment(this.startTimeString, ['h:m a', 'H:m'])) {
      date.add(1, 'd');
    }
    return moment(`${date.format('YYYY-MM-DD')} ${this.endTimeString}`, ['YYYY-MM-DD h:m a']).utc(true);
  }

  @computed get noStartTime() {
    return !this.startTime;
  }
}

export default TimesheetEntry;
