import React from 'react';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';
import {observer} from 'mobx-react';
import DragAndDropContext from 'components/dnd/DragAndDropContext';
import Question from './Question';
import {Panel, ActionLink, FormError} from 'components';
import {t} from 'shared/core';

const Questions = ({uiState}) => {
  const {cycle, errors} = uiState;
  const {questions} = cycle;

  return (
    <div>
      <Panel.Header title={t('performance.Choose the questions employees answer for each Check-In')}/>
      <div className='clearfix'>
        <div className='col col-12'>
          <div className='h4 waterloo mb3'>
            <FormattedMessage id='performance.You can add, edit, remove, and change the order of the questions.'/>
          </div>
          {cycle.orderedQuestions.map((question) => (
            <Question
              key={question.order}
              question={question}
              onQuestionDrag={(dragIndex, hoverIndex) => uiState.questionState.moveQuestion(cycle, dragIndex, hoverIndex)}
              onQuestionDelete={() => uiState.questionState.deleteQuestion(questions, question)}
              errors={_.get(errors, `questions[${questions.indexOf(question)}]`)}
            />
          ))}
          <FormError message={errors.questions} />
        </div>
        <div className='col col-12 mt2'>
          <ActionLink onClick={() => uiState.questionState.addQuestion(cycle)}>
            <FormattedMessage id='performance.+ Add a Question'/>
          </ActionLink>
        </div>
      </div>
    </div>
  );
};

export default DragAndDropContext(observer(Questions));
