import React from 'react';
import {observer} from 'mobx-react';
import {TimeTrackingSubnav} from 'containers/time_tracking';
import PayrollHoursContainerWithState from './PayrollHoursContainerWithState';

const PayrollHoursContainer = observer(({scope}) => {
  return (
    <div>
      <TimeTrackingSubnav scope={scope}/>
      <PayrollHoursContainerWithState/>
    </div>
  );
});

export default PayrollHoursContainer;
