import React from 'react';
import {observer} from 'mobx-react';
import RecruitingSubnav from 'recruiting/components/RecruitingSubnav';
import InterviewGuideDirectoryWithState from 'containers/recruiting/components/interview_guides/InterviewGuideDirectoryWithState';

const InterviewGuideDirectory = observer(({history}) => {
  return (
    <div>
      <RecruitingSubnav />
      <InterviewGuideDirectoryWithState history={history} />
    </div>
  );
});

export default InterviewGuideDirectory;
