import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import DocumentActionsState from '../state/DocumentActionsState';
import DocumentActionsTable from './DocumentActionsTable';
import {Display} from 'components';

const DocumentActionsContainer = observer(({uiState}) => {
  return (
    <div>
      <Display backPath='/documents'/>
      <div className='Container clearfix pb4'>
        <DocumentActionsTable uiState={uiState} />
      </div>
    </div>
  );
});

export default withState(DocumentActionsContainer, DocumentActionsState);
