import React from 'react';
import {t} from 'shared/core';
import {NavLink} from 'react-router-dom';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Display} from 'components';

const Subnav = observer(({scope}) => {
  return (
    <Display title={t('payroll.Payroll')}>
      {scope.updates && <NavLink activeClassName='active' to='/updates'>
        <FormattedMessage id='payroll.Updates'/>
      </NavLink>}
      {scope.uploads && <NavLink activeClassName='active' to='/uploads'>
        <FormattedMessage id='payroll.Payroll Uploads'/>
      </NavLink>}
      {scope.employees && <NavLink activeClassName='active' to='/employees'>
        <FormattedMessage id='payroll.Employees'/>
      </NavLink>}
    </Display>
  );
});

export default Subnav;
