export default [
  {
    name: "New Year's Day",
    date: "2025-01-01"
  },
  {
    name: "Islander Day",
    date: "2025-02-17",
    appliesTo: ['PE']
  },
  {
    name: "Louis Riel Day",
    date: "2025-02-17",
    appliesTo: ['MB']
  },
  {
    name: "Nova Scotia Heritage Day",
    date: "2025-02-17",
    appliesTo: ['NS']
  },
  {
    name: "Family Day",
    date: "2025-02-17",
    appliesTo: ['AB', 'BC', 'NB', 'ON', 'SK']
  },
  {
    name: "Good Friday",
    date: "2025-04-18"
  },
  {
    name: "Victoria Day",
    date: "2025-05-19",
    doesNotApplyTo: ['NB', 'NS', 'NL', 'PE']
  },
  {
    name: "National Indigenous Peoples Day",
    date: "2025-06-21",
    appliesTo: ['NT', 'YT']
  },
  {
    name: "Discovery Day (NL)",
    date: "2025-06-24",
    appliesTo: ['NL']
  },
  {
    name: "St. Jean Baptiste Day",
    date: "2025-06-24",
    appliesTo: ['QC']
  },
  {
    name: "Canada Day",
    date: "2025-07-01"
  },
  {
    name: "Nunavut Day",
    date: "2025-07-09",
    appliesTo: ['NU']
  },
  {
    name: "Civic Holiday",
    date: "2025-08-04",
    appliesTo: ['BC', 'SK', 'ON', 'NB', 'NU', 'NT']
  },
  {
    name: "Alberta Heritage Day",
    date: "2025-08-04",
    appliesTo: ['AB']
  },
  {
    name: "Discovery Day (YT)",
    date: "2025-08-18",
    appliesTo: ['YT']
  },
  {
    name: "Labour Day",
    date: "2025-09-01"
  },
  {
    name: "National Day for Truth and Reconciliation",
    date: "2025-09-30",
    appliesTo: ['BC', 'MB', 'YT', 'PE', 'NT']
  },
  {
    name: "Thanksgiving",
    date: "2025-10-13",
    doesNotApplyTo: ['NB', 'NS', 'NL', 'PE']
  },
  {
    name: "Remembrance Day",
    date: "2025-11-11",
    doesNotApplyTo: ['MB', 'ON', 'QC', 'NS']
  },
  {
    name: "Christmas Day",
    date: "2025-12-25"
  },
  {
    name: "Boxing Day",
    date: "2025-12-26",
    appliesTo: ['ON']
  }
];
