import React from 'react';
import {observer} from 'mobx-react';
import RecruitingSubnav from 'recruiting/components/RecruitingSubnav';
import JobSiteForm from './JobSiteForm';

const JobSiteContainer = observer(() => {
  return (
    <div>
      <RecruitingSubnav />
      <JobSiteForm />
    </div>
  );
});

export default JobSiteContainer;
