import React from 'react';
import {observer} from 'mobx-react';
import {ActionLink} from 'components';
import {FormattedMessage} from 'react-intl';
import CloseIcon from 'img/close-icon@2x.png';

const FullScreenControls = observer(({uiState}) => {
  return (
    <React.Fragment>
      <div className='col-4 table-cell align-middle'>
        {uiState.title &&
        <div className='h2'>
          {uiState.title}
        </div>}
      </div>
      <div className='col-4 table-cell align-middle center'>
        <div className='clearfix table'>
          <div className='col-4 table-cell align-middle center'>
            <PrevButton uiState={uiState}/>
          </div>
          <div className='col-4 table-cell align-middle center'>
            <FormattedMessage
              id='components.pdf.preview.PAGE_NUMBER'
              values={{
                pageNumber: uiState.pageNumber,
                totalPages: uiState.totalPages
              }}
            />
          </div>
          <div className='col-4 table-cell align-middle center'>
            <NextButton uiState={uiState}/>
          </div>
        </div>
      </div>
      <div className='col-4 table-cell align-middle right-align'>
        <ActionLink className='Link-icon align-middle' onClick={() => uiState.exitFullScreen()}>
          <img src={CloseIcon} className='img-hover' alt='close' width='14px'/>
        </ActionLink>
      </div>
    </React.Fragment>
  );
});

const StandardControls = observer(({uiState}) => {
  return (
    <React.Fragment>
      <div className='col-4 table-cell align-middle left-align'>
        <PrevButton uiState={uiState}/>
      </div>
      <div className='col-4 table-cell align-middle center'>
        <FormattedMessage
          id='components.pdf.preview.PAGE_NUMBER'
          values={{
            pageNumber: uiState.pageNumber,
            totalPages: uiState.totalPages
          }}
        />
        {uiState.fullScreenOption && !uiState.fullScreen &&
        <ActionLink className='Link-icon align-middle' onClick={() => uiState.enterFullScreen()}>
          <i className='material-icons'>{'fullscreen'}</i>
        </ActionLink>}
      </div>
      <div className='col-4 table-cell align-middle right-align'>
        <NextButton uiState={uiState}/>
      </div>
    </React.Fragment>
  );
});

const NextButton = observer(({uiState}) => {
  if (!uiState.nextVisible) {
    return null;
  }

  return (
    <ActionLink className='Link-icon Link-icon--forward dodger' onClick={() => uiState.nextPage()}>
      <FormattedMessage id='components.pdf.preview.Next'/>
    </ActionLink>
  );
});

const PrevButton = observer(({uiState}) => {
  if (!uiState.prevVisible) {
    return null;
  }

  return (
    <ActionLink className='Link-icon Link-icon--back dodger' onClick={() => uiState.previousPage()}>
      <FormattedMessage id='components.pdf.preview.Previous'/>
    </ActionLink>
  );
});

const PageControls = observer(({uiState}) => {
  if (uiState.loading) return null;

  return (
    <div className={(uiState.fullScreen ? '' : 'border-top border-left border-right ') + 'bg-white p2 rounded-top'}>
      <div className='clearfix table'>
        {uiState.fullScreen && <FullScreenControls uiState={uiState}/>}
        {!uiState.fullScreen && <StandardControls uiState={uiState}/>}
      </div>
    </div>
  );
});

export default PageControls;
