import React from 'react';
import {observer} from 'mobx-react';
import CustomReportFilterState from '../state/CustomReportFilterState';
import FilterList from './FilterList';
import AddFilterButton from './AddFilterButton';
import {withState} from 'shared/core';

const CustomReportFilter = observer(({uiState}) => {
  return (
    <div>
      <AddFilterButton uiState={uiState}/>
      <FilterList uiState={uiState}/>
    </div>
  );
});

export default withState(CustomReportFilter, CustomReportFilterState);
