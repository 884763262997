import {observable, action} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints} from 'shared/core';
import {redirect} from 'shared/tools';
import _ from 'lodash';
import VENDORS from './vendors';

const POLL_INTERVAL = 2000;

class ExternalCandidatesContainerState {
  interval;
  store = new DomainStore();

  @observable vendor;
  @observable syncComplete = false;
  @observable syncFailed = false;
  @observable externalCandidates;

  receiveProps({vendor}) {
    this.vendor = _.find(VENDORS, {key: vendor});
  }

  @action async load() {
    switch (this.vendor.key) {
      case 'jazz_hr':
        clearInterval(this.interval);
        this.requestVendorSync();
        this.startPolling();
        break;
      case 'greenhouse':
        break;
      default:
        throw new Error(`Vendor ${this.vendor.key} not supported.`);
    }
  }

  @action async hireCandidate(candidate) {
    const {model} = await this.store.post(
      candidate.link('convertToDraftEmployee'),
      types.INTEGRATIONS.EXTERNAL_CANDIDATE,
      candidate
    );

    await redirect(`/employees/hire/${model.employeeId}`);
  }

  @action async destroyCandidate(candidate) {
    await this.store.destroy(candidate);
  }

  @action async requestVendorSync() {
    await this.store.put(
      endpoints.INTEGRATIONS.EXTERNAL_CANDIDATES_SYNC,
      types.INTEGRATIONS.EXTERNAL_CANDIDATES_SYNC_STATUS
    );
  }

  async poll() {
    const store = new DomainStore();
    const model = await store.fetch(
      endpoints.INTEGRATIONS.EXTERNAL_CANDIDATES_SYNC_STATUS,
      types.INTEGRATIONS.EXTERNAL_CANDIDATES_SYNC_STATUS
    );

    if (model.isCompleted) {
      clearInterval(this.interval);
      this.syncComplete = true;

      if (model.status === 'failed') {
        this.syncFailed = true;
      }
    }
  }

  startPolling() {
    this.interval = setInterval(() => this.poll(), POLL_INTERVAL);
  }
}

export default ExternalCandidatesContainerState;
