import React from 'react';
import {observer} from 'mobx-react';
import {Reason, Spouse, Dependents, Coverage, Review} from './components';

@observer class Step extends React.Component {
  render() {
    const {step, uiState} = this.props;

    switch (step) {
      case 'reason':
        return <Reason uiState={uiState}/>;
      case 'spouse':
        return <Spouse uiState={uiState}/>;
      case 'dependants':
        return <Dependents uiState={uiState}/>;
      case 'coverage':
        return <Coverage uiState={uiState}/>;
      case 'review':
        return <Review uiState={uiState}/>;
      default:
        throw new Error(`Location ${step} is not supported.`);
    }
  }
}

export default Step;
