export const statusTagColour = (status) => {
  switch (status) {
    // employment_status
    case 'terminated':
      return 'scarlet';
    case 'on_leave':
      return 'whale';
    // onboarding_status
    case 'invite_scheduled':
    case 'invite_sent':
    case 'onboarding':
      return 'turquoise';
    case 'invite_skipped':
    case 'invite_not_sent':
    case 'invite_not_sent_after_company_launch':
      return 'whale';
    case 'draft':
      return 'dodger';
    default:
      throw new Error(`No support for employee status of ${status}`);
  }
};
