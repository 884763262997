import React from 'react';
import {observer} from 'mobx-react';
import {Panel, FormLayout, Select2, TextArea, Input, ObjectSelect2} from 'components';
import {auth, t} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const SubmitRequestForm = observer(({uiState}) => {
  return (
    <Panel className='mt1'>
      <Panel.Header title={t('submit_request.Submit Request')}/>

      <form
        method='POST'
        name='fa-form-1'
        action={process.env.FRONTAPP_WEBHOOK}
        encType='multipart/form-data'
        acceptCharset='utf-8'
        id='submit-request-form'
      >
        <FormLayout>
          <Select2
            value={uiState.selectedSubjectIndex}
            label='submit_request.Subject'
            onChange={e => uiState.setSubject(e.target.value)}
            errorMessage={uiState.errors.subject}
          >
            {uiState.subjectOptions().map((option, index) =>
              <option
                key={option.key}
                value={index}>
                {option.text}
              </option>
            )}
          </Select2>

          {uiState.canEditSubject &&
            <Input
              value={uiState.otherSubject}
              onChange={e => uiState.setOtherSubject(e.target.value)}
              label={'submit_request.Specify subject'}
              errorMessage={uiState.errors.otherSubject}
            />
          }

          <ObjectSelect2
            label='submit_request.Employee'
            value={uiState.selectedEmployee}
            items={uiState.employees}
            searchable={true}
            onChange={value => uiState.setEmployee(value)}
            errorMessage={uiState.errors.employee}
          />

          <input type='hidden' name='name' value={auth.user.name}/>
          <input type='hidden' name='email' value={auth.user.email}/>
          <input type='hidden' name='company_name' value={auth.company.name}/>
          <input type='hidden' name='company_id' value={auth.company.id}/>
          <input type='hidden' name='user_id' value={auth.user.id}/>
          <input type='hidden' name='subject' value={uiState.subjectWithEmployeeAndCompanyName}/>
          <input type='hidden' name='employee_name' value={uiState.selectedEmployee.name}/>
          <input type='hidden' name='employee_id' value={uiState.selectedEmployee.id}/>

          <TextArea
            helpMessage='submit_request.BODY_LABEL'
            label='submit_request.Description'
            minRows={8}
            value={uiState.body}
            name='body'
            onChange={e => uiState.setBody(e.target.value)}
            errorMessage={uiState.errors.body}
          />

          <div>
            <label htmlFor='attachment'>
              <FormattedMessage id='submit_request.Attach a file'/>
            </label>
            <input type='file' name='attachment' multiple/>
          </div>

          <div className='mt2' id='html_element'></div>
        </FormLayout>
      </form>
    </Panel>
  );
});

export default SubmitRequestForm;
