import React from 'react';
import {FormattedMessage} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Display} from 'components';
import {observer} from 'mobx-react';
import {t} from 'shared/core';

const TimeOffSubnav = observer(({scope}) => {
  return (
    <Display title={t('time_off.subnav.Time Off')}>
      {scope.accountSummaries && <NavLink to='/account_summaries' activeClassName='active'><FormattedMessage
        id='time_off.subnav.Summary'/></NavLink>}
      {scope.pendingRequests && <NavLink to='/pending_requests' activeClassName='active'><FormattedMessage
        id='time_off.subnav.Pending Requests'/></NavLink>}
      {scope.policies &&
        <NavLink to='/policies' activeClassName='active'><FormattedMessage id='time_off.subnav.Policies'/></NavLink>}
      {scope.types &&
        <NavLink to='/types' activeClassName='active'><FormattedMessage id='time_off.subnav.Types'/></NavLink>}
      {scope.holidays &&
        <NavLink to='/holidays' activeClassName='active'><FormattedMessage id='time_off.subnav.Holidays'/></NavLink>}
      {scope.blockedDates && <NavLink to='/blocked_dates' activeClassName='active'><FormattedMessage
        id='time_off.subnav.Blocked Dates'/></NavLink>}
    </Display>
  );
});

export default TimeOffSubnav;
