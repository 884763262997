import Panel from './Panel';
import PanelHeader from './PanelHeader';
import PanelStack from './PanelStack';
import PanelSeparator from './PanelSeparator';
import PanelSubheader from './PanelSubheader';
import PanelEmptyState from './PanelEmptyState';
import PanelSpinner from './PanelSpinner';

Panel.Header = PanelHeader;
Panel.Stack = PanelStack;
Panel.Separator = PanelSeparator;
Panel.Subheader = PanelSubheader;
Panel.EmptyState = PanelEmptyState;
Panel.Spinner = PanelSpinner;

export {
  Panel
};
