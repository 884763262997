import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import {DocumentAnnotationForm} from 'components';

const AssignDocumentEditModal = observer(({uiState}) => {
  const {assigningDocument, errors} = uiState;

  if (!assigningDocument.originalFile) return null;

  return (
    <Modal isOpen={uiState.annotatorModalOpen} closeButton={false} size='lg'>
      <DocumentAnnotationForm
        document={assigningDocument}
        errors={errors}
        onSave={(annotations) => uiState.addAnnotations(annotations)}
        onCancel={() => uiState.closeAnnotatorModal()}
      />
    </Modal>
  );
});

export default AssignDocumentEditModal;
