import {DomainStore} from 'shared/store';
import {observable, action} from 'mobx';
import Employee from 'stores/employees/Employee';
import {types, endpoints} from 'shared/core';

class EmployeeBenefitState {
  store = new DomainStore();
  match;

  @observable employee;

  receiveProps({match}) {
    this.match = match;
  }

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEE_DETAILS.with(this.match.params.id),
    ]);

    this.employee = new Employee(
      this.store._getSingle(types.EMPLOYEE_DETAILS)
    );
  }
}

export default EmployeeBenefitState;
