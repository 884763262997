import React from 'react';
import Subnav from '../../Subnav';
import EmployeeList from './EmployeeList';

const EmployeeDirectoryContainer = ({scope}) => {
  return (
    <div>
      <Subnav scope={scope}/>
      <EmployeeList/>
    </div>
  );
};

export default EmployeeDirectoryContainer;
