import {action, observable} from 'mobx';
import {endpoints, api} from 'shared/core';
import {redirect} from 'shared/tools';

class DemoBannerState {
  @observable showModal = false;

  @action closeDemoBannerModal() {
    this.showModal = false;
  }

  @action openDemoBannerModal() {
    this.showModal = true;
  }

  @action async beginConfiguration() {
    await api.patch(endpoints.COMPANY_BEGIN_CONFIGURATION);

    return redirect('/account_setup_in_progress');
  }
}

export default DemoBannerState;
