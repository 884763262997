import React from 'react';
import {withRouter} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {t, withState} from 'shared/core';
import {Button, Display, Panel, Layout, FormError} from 'components';
import {BenefitEnrolmentForCarrier} from 'components/benefits';
import {EmployeeBenefitEnrolmentFormState} from '../state';

const EmployeeBenefitEnrolmentForm = observer(({uiState}) => {
  const {enrolmentViewModels, employee, errors} = uiState;

  return (
    <div>
      <Display
        backTitle={t('benefits.benefit_info.edit.Benefit Enrolments')}
        backPath={'/benefit_enrolments'}
        title={t('benefit_enrolment.new.ENROL_IN_BENEFITS', { name: employee.name })}
      />
      <Layout>
        <Panel.Stack>
          <Panel>
            <Panel.Header
              title={t('benefit_enrolment.enrol.Benefits Plan')}
              description={t('benefit_enrolment.new.enrol_description')}
            />
          </Panel>
          {enrolmentViewModels.map((viewModel, index) =>
            <Panel key={viewModel.record.companyInsuranceCarrier.id}>
              <BenefitEnrolmentForCarrier
                viewModel={viewModel}
                enrolmentErrors={_.get(errors, `employeeBenefitEnrollments[${index}]`)}
                insuranceNumberErrors={_.get(errors, `employeeInsuranceNumbers[${viewModel.insuranceNumberIndex}]`)}
              />
            </Panel>
          )}
          <Panel>
            <FormError message={errors.base} alignRight/>
            <div className='flex justify-content-end'>
              <Button onClick={() => uiState.enrolEmployee()}>
                <FormattedMessage id='benefit_enrolment.new.Save'/>
              </Button>
            </div>
          </Panel>
        </Panel.Stack>
      </Layout>
    </div>
  );
});

export default withRouter(withState(EmployeeBenefitEnrolmentForm, EmployeeBenefitEnrolmentFormState));
