import React from 'react';
import {observer} from 'mobx-react';
import Logo from 'img/collage-logo--blue@2x.png';

const TopNav = observer(({uiState}) => {
  return (
    <div className='bg-white border-bottom center py1'>
      <a className='inline-block p2' href='https://collage.co'>
        <img src={Logo} alt='Collage' height='26px'/>
      </a>
    </div>
  );
});

export default TopNav;
