import {oneOf} from 'shared/store';
import {observable, action, computed} from 'mobx';
import QUESTION_TYPES from './questionTypes';
import QAQuestion from '../questions/QAQuestion';
import _ from 'lodash';

class InterviewGuideQuestion extends QAQuestion {
  @observable id;
  @observable name;
  @observable order;
  @observable questionType;
  @observable args = {};

  @action merge(other) {
    super.merge(other, {
      questionType: oneOf(QUESTION_TYPES)
    });
  }

  @action updateText(value) {
    this.merge({name: value});
  }

  @computed get options() {
    return _.get(this.args, 'options');
  }

  @computed get questionName() {
    return this.name;
  }
}

export default InterviewGuideQuestion;
