import React from 'react';
import {observer} from 'mobx-react';
import {DropdownFilter, DropdownSelect, InteractiveTable} from 'components';
import {types, endpoints, t} from 'shared/core';
import getColumns from '../../columns';
import MassUpdateEmployeeViewModel from '../state/MassUpdateEmployeeViewModel';
import _ from 'lodash';

const LOCALIZATION = {
  emptyState: 'mass_updates.edit.No employees to show',
  removeModal: {
    header: 'mass_updates.edit.Remove employee from update?',
  },
  interactive: {
    addModel: 'mass_updates.edit.Add Employees',
  },
};

function filters() {
  return [
    {
      id: 'successful',
      display: t('mass_updates.edit.Successful')
    },
    {
      id: 'errored',
      display: t('mass_updates.edit.Errored')
    }
  ];
}

const BulkEditDropdown = observer(({uiState}) => {
  const {massUpdate} = uiState;

  if (_.isEmpty(massUpdate.bulkEditFields)) return null;

  return (
    <div className='inline-block mr2'>
      <DropdownSelect selected={t('mass_updates.edit.Bulk Edit')} >
        {massUpdate.bulkEditFields.map(field =>
          <DropdownSelect.Option key={field} text={t(`mass_updates.edit.fields.${field}`)}
                                 onClick={() => uiState.openBulkEditModal(field)}/>
        )}
      </DropdownSelect>
    </div>
  );
});

const MassUpdateEmployeeEditTable = observer(({uiState}) => {
  const {match, massUpdate} = uiState;

  return (
    <InteractiveTable
      uiState={uiState}
      columns={getColumns(massUpdate, uiState)}
      localization={LOCALIZATION}
      saveStateToUrl={true}
      editRoute='/:id'
      showLinks={!massUpdate.readOnly}
      onAdd={massUpdate.readOnly ? null : () => uiState.openAddEmployeesModal()}
      onRemove={model => uiState.removeMassUpdateEmployee(model.data)}
      onMount={agent => uiState.setInteractiveAgent(agent)}
      tableActionButtons={<BulkEditDropdown uiState={uiState}/>}
      proxy={{
        type: 'async',
        modelType: types.MASS_UPDATE_EMPLOYEE,
        modelCreator: json => new MassUpdateEmployeeViewModel(json, uiState),
        endpoint: endpoints.MASS_UPDATES.EMPLOYEES.with(match.params.id, massUpdate.effectiveDate)
      }}
      horizontalScroll
    >
      {massUpdate.readOnly && !massUpdate.running && <DropdownFilter options={filters()} attr='status'/>}
    </InteractiveTable>
  );
});

export default MassUpdateEmployeeEditTable;
