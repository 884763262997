import React from 'react';
import {t} from 'shared/core';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Panel} from 'components';

const HrUpgradePricing = observer(({uiState}) => {
  return (
    <div>
       <div className='h2 py2'>
        <FormattedMessage id='hr_upgrade.learn_more.Pricing and FAQs'/>
      </div>

      <div className='flex flex-col md-flex-row'>
        <div className='col col-12 md-col-6 md-pr1'>
          <div className='h3 my2'>
            <FormattedMessage id='hr_upgrade.learn_more.Collage HR'/>
          </div>
        </div>
        <div className='col col-12 md-col-6 md-pl1'>
          <div className='h3 my2'>
            <FormattedMessage id='hr_upgrade.learn_more.Payroll and Benefits Services'/>
          </div>
        </div>
      </div>

      <div className='flex flex-col md-flex-row'>
        <div className='col col-12 md-col-6 md-pr1 mb2'>
          <Panel className='full-height grid'>
            <div className='flex md-flex-row align-items-center justify-content-center'>
              <span className='mr3 center'>
                <div className='medium pt2 px2' style={{fontSize: '36px'}}>
                  <FormattedMessage id='hr_upgrade.learn_more.HR_MONTHLY_COST'/>
                </div>
                <div className='jumbo' style={{fontSize: '12px'}}>
                  <FormattedMessage id='hr_upgrade.learn_more.Per user per month'/>
                </div>
              </span>
              <span>
                <Panel.Header title={t('hr_upgrade.learn_more.All of our core features, one great price')}/>
                <div className='jumbo'>
                  <FormattedMessage id='hr_upgrade.learn_more.Access all of our feature modules at an affordable price that scales with your team.'/>
                </div>
              </span>
            </div>
          </Panel>
        </div>
        <div className='col col-12 md-col-6 md-pl1 mb2'>
          <Panel className='full-height grid'>
            <div className='flex md-flex-row align-items-center justify-content-center'>
              <span className='mr3 center'>
                <div className='jumbo' style={{fontSize: '12px'}}>
                  <FormattedMessage id='hr_upgrade.learn_more.Starting at'/>
                </div>
                <div className='medium px2' style={{fontSize: '36px'}}>
                  <FormattedMessage id='hr_upgrade.learn_more.PAYROLL_MONTHLY_COST'/>
                </div>
                <div className='jumbo' style={{fontSize: '12px'}}>
                  <FormattedMessage id='hr_upgrade.learn_more.Per user per month'/>
                </div>
              </span>
              <span>
                <Panel.Header title={t('hr_upgrade.learn_more.Add on payroll sync, benefits sync and/or managed payroll')}/>
                <div className='jumbo'>
                  <FormattedMessage id='hr_upgrade.learn_more.Are you looking for added support and convenience? We offer a variety of services at custom prices to make your life easier.'/>
                </div>
              </span>
            </div>
          </Panel>
        </div>
      </div>

      <div className='clearfix mxn2 mt1'>
        <div className='col col-12 sm-col-4 my3 px2'>
          <div className='h3 mb1 medium'>
            <FormattedMessage id="hr_upgrade.learn_more.What is the difference between Benefits HQ and Collage HR?"/>
          </div>
          <div className='h4 jumbo'>
            <FormattedMessage id="hr_upgrade.learn_more.Think of Benefits HQ as a “light” version of Collage HR. Benefits HQ functionality is limited to benefits administration. Collage HR offers the same benefits functionality, plus full HR management features in one platform."/>
          </div>
        </div>
        <div className='col col-12 sm-col-4 my3 px2'>
          <div className='h3 mb1 medium'>
            <FormattedMessage id="hr_upgrade.learn_more.How do I convert from Benefits HQ to Collage HR?"/>
          </div>
          <div className='h4 jumbo'>
            <FormattedMessage id="hr_upgrade.learn_more.Simply click the “I’m Interested” button on this page, and our team will reach out to schedule a time to begin the process. Once everyone is aligned on Collage HR as the right solution for you, our team will begin the conversion process on our back end. Turning your HQ account into HR usually takes less than a week! You may be asked to provide some additional employee data that will make your transition to Collage HR more complete."/>
          </div>
        </div>
        <div className='col col-12 sm-col-4 my3 px2'>
          <div className='h3 mb1 medium'>
            <FormattedMessage id="hr_upgrade.learn_more.Is there a minimum term for a Collage HR subscription?"/>
          </div>
          <div className='h4 jumbo'>
            <FormattedMessage id="hr_upgrade.learn_more.We offer both monthly and annual subscriptions to our clients. The minimum term is one month, and you can choose to change or cancel your subscription any time. Clients get a 10% discount for subscribing annually."/>
          </div>
        </div>
      </div>
    </div>
  );
});

export default HrUpgradePricing;
