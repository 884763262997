import React from 'react';
import {observer} from 'mobx-react';
import {ReorderModal} from 'components/modals';

const ReorderGroupsModal = observer(({uiState}) => {
  return (
    <ReorderModal
      isOpen={uiState.reorderGroupsModalOpen}
      onHide={() => uiState.closeReorderGroupsModal()}
      title={'company_settings.custom_fields.Reorder Groups'}
      models={uiState.reorderingGroups}
      onSave={() => uiState.saveGroupOrders()}
      onCancel={() => uiState.closeReorderGroupsModal()}
    />
  );
});

export default ReorderGroupsModal;
