import React from 'react';
import moment from 'moment';
import Select2 from './Select2';

const MonthSelect = ({selectedMonth = 0, showNumbers = false, onChange, ...rest}) => {
  const months = moment.months().map((month, index) => {return {month, index};});

  return (
    <Select2 value={selectedMonth} onChange={onChange} searchable={true} dropdownClass='select2-animation' placeholder='Month' {...rest}>
      {months.map(({month, index}) => {
        const monthView = showNumbers ? `${index + 1} - ${month}` : month;
        return <option value={index} key={index}>{monthView}</option>;
      })}
    </Select2>
  );
};

export default MonthSelect;
