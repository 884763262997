import InequalityFilterRow from './InequalityFilterRow';
import {action, observable} from 'mobx';
import _ from 'lodash';

class NumericInequalityFilterRow extends InequalityFilterRow {
  @observable value = '0';
  @observable range = {
    lower_bound: '0',
    upper_bound: '0'
  };

  @action parseValue(value) {
    value = value.replace(/[^0-9\\.]+/g, '').replace(/^0+/, '').replace(/\./, '');

    if (_.isEmpty(value)) return '0';

    return value;
  }
}

export default NumericInequalityFilterRow;
