import React from 'react';
import {observer} from 'mobx-react';
import {Checkbox} from 'components';
import Description from './Description';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const SensitiveDataWarning = ({uiState}) => {
  if (!uiState.reportsChecked) return null;

  return (
    <div className='clearfix mt2'>
      <div className='col col-1 pt2'></div>
      <div className='col col-11'>
        <i className='material-icons sun inline align-middle pr1'>{'error'}</i>
        <div className='inline align-middle jumbo'>
          <FormattedMessage id='company_settings.permissions.Employees with this role will have access to all data in the selected reports regardless of data permissions.'/>
        </div>
      </div>
    </div>
  );
};

const Section = observer(({name, permissions}) => {
  if (_.isEmpty(permissions)) return null;

  return (
    <div>
      <div className='h4 waterloo'>
        <FormattedMessage id={`company_settings.permissions.${name}`}/>
      </div>
      <div className='pb2'>
        {permissions.map(viewModel =>
          <div className='pt1' key={viewModel.featurePermission.name}>
            <label>
              <div className='inline-block pr2'>
                <Checkbox checked={viewModel.checked} onChange={() => viewModel.toggle()}/>
              </div>
              <div className='h4 medium inline-block clickable' onClick={() => viewModel.toggle()}>
                <FormattedMessage id={`company_settings.permissions.${viewModel.featurePermission.name}`}/>
              </div>
            </label>
          </div>
        )}
      </div>
    </div>
  );
});

const PermissionSections = observer(({uiState}) => {
  const {reportPermissions} = uiState;

  return (
    <div className='clearfix pt2'>
      <div className='col col-1'>&nbsp;</div>
      <div className='col col-11'>
        <div className='clearfix'>
          <div className='col col-6 pr2'>
            <Section name='Company Reports' permissions={reportPermissions.company}/>
            <Section name='Time Off Reports' permissions={reportPermissions.timeoff}/>
            <Section name='Time Tracking Reports' permissions={reportPermissions.timeTracking}/>
            <Section name='Recruiting Reports' permissions={reportPermissions.recruiting}/>
            <Section name='Performance Reports' permissions={reportPermissions.performance}/>
            <Section name='Survey Reports' permissions={reportPermissions.surveys}/>
          </div>
          <div className='col col-6 pl2'>
            <Section name='Training Reports' permissions={reportPermissions.training}/>
            <Section name='Headcount & Turnover Reports' permissions={reportPermissions.headcountTurnover}/>
            <Section name='Employee Reports' permissions={reportPermissions.employees}/>
            <Section name='General Reports' permissions={reportPermissions.general}/>
          </div>
        </div>
      </div>
    </div>
  );
});

const ReportPermissions = observer(({uiState}) => {
  return (
    <div className='px2 pt2 border-top'>
      <div className='clearfix'>
        <div className='col col-1'>
          <Checkbox checked={uiState.reportsChecked} onChange={() => uiState.toggleReports()}/>
        </div>
        <div className='col col-5 pr4'>
          <div className='h3 medium'>
            <FormattedMessage id='company_settings.permissions.Reporting'/>
          </div>
        </div>
        <div className='col col-6'>
          <Description descriptionItems={['Access and download data for select reports']}/>
        </div>
      </div>
      <SensitiveDataWarning uiState={uiState}/>
      <PermissionSections uiState={uiState}/>
    </div>
  );
});

export default ReportPermissions;
