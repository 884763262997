import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {ActionLink, Label, Panel} from 'components';
import EditEmergencyContactInfoModal from './EditEmergencyContactInfoModal';
import {t} from 'shared/core';
import EmployeeProfileContainer from '../../EmployeeProfileContainer';

const EmergencyContainer = observer(({uiState}) => {
  const {employee} = uiState;

  return (
    <EmployeeProfileContainer uiState={uiState}>
      <Panel>
        <Panel.Header title={t('employees.profile.emergency.Emergency Contact Information')}>
          {uiState.editDataPermission('::EMERGENCY_CONTACT_INFORMATION') &&
            <ActionLink onClick={() => uiState.openEmergencyContactModal()}>
              <FormattedMessage id='employees.profile.emergency.Edit'/>
            </ActionLink>}
        </Panel.Header>
        <div className='clearfix fs-hide'>
          <div className='col col-12 sm-col-10 pt1'>
            <div className='medium mb1'>
              <FormattedMessage id='employees.profile.emergency.Emergency Contact #1'/>
            </div>
            <div className='clearfix mxn1 pb1'>
              <div className='col col-6 px1'>
                <FormattedMessage id='employees.profile.emergency.Full Name' />
              </div>
              <div className='col col-6 px1'>
                <Label model={employee} path='ec1Name' />
              </div>
            </div>
            <div className='clearfix mxn1 pb1'>
              <div className='col col-6 px1'>
                <FormattedMessage id='employees.profile.emergency.Relationship' />
              </div>
              <div className='col col-6 px1'>
                <Label model={employee} path='ec1Relationship' />
              </div>
            </div>
            <div className='clearfix mxn1 pb1'>
              <div className='col col-6 px1'>
                <FormattedMessage id='employees.profile.emergency.Phone' />
              </div>
              <div className='col col-6 px1'>
                <Label model={employee} path='ec1Phone' />
              </div>
            </div>
            <div className='medium my1 pt1'>
              <FormattedMessage id='employees.profile.emergency.Emergency Contact #2'/>
            </div>
            <div className='clearfix mxn1 pb1'>
              <div className='col col-6 px1'>
                <FormattedMessage id='employees.profile.emergency.Full Name' />
              </div>
              <div className='col col-6 px1'>
                <Label model={employee} path='ec2Name' />
              </div>
            </div>
            <div className='clearfix mxn1 '>
              <div className='col col-6 px1'>
                <FormattedMessage id='employees.profile.emergency.Relationship' />
              </div>
              <div className='col col-6 px1'>
                <Label model={employee} path='ec2Relationship' />
              </div>
            </div>
            <div className='clearfix mxn1'>
              <div className='col col-6 px1'>
                <FormattedMessage id='employees.profile.emergency.Phone' />
              </div>
              <div className='col col-6 px1'>
                <Label model={employee} path='ec2Phone' />
              </div>
            </div>
          </div>
        </div>
        <EditEmergencyContactInfoModal uiState={uiState} />
      </Panel>
    </EmployeeProfileContainer>
  );
});

export default EmergencyContainer;
