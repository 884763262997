import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {Input} from 'components';

const EditDocumentFolderModal = observer(({uiState}) => {
  const {errors, editingFolder} = uiState;

  return (
    <Modal isOpen={uiState.editDocumentFolderModalOpen} onHide={() => uiState.closeEditDocumentFolderModal()} size='sm'>
      <div className='h2 medium mb3'>
        <FormattedMessage id={uiState.editMessage}/>
      </div>
      <Input
        label='company_settings.document_folders.Folder Name'
        value={editingFolder.name}
        errorMessage={errors.name}
        onChange={(e) => editingFolder.merge({name: e.target.value})}
      />
      <ModalButtons onSave={() => uiState.saveDocumentFolder()} onCancel={() => uiState.closeEditDocumentFolderModal()}/>
    </Modal>
  );
});

export default EditDocumentFolderModal;
