import {DomainStore, fetchModels} from 'shared/store';
import {endpoints, types, t} from 'shared/core';
import {action, observable, computed} from 'mobx';
import _ from 'lodash';
import Holiday from 'stores/holidays/Holiday';
import Location from 'stores/locations/Location';
import calendar from '../calendar';
import moment from 'moment';

class HolidaysState {
  store = new DomainStore();

  @observable errors = {};
  @observable holidays = [];
  @observable locations = [];
  @observable statutoryHolidays = [];
  @observable currentHoliday = {};
  @observable year = moment().year();
  @observable holidayModalOpen = false;
  @observable calendarModalOpen = false;

  @action async load() {
    await this.store._compose([
      endpoints.LOCATIONS.ALL,
      endpoints.HOLIDAYS.FOR_YEAR.with(this.year)
    ]);

    this.locations = this.store._getAll(types.LOCATION, Location);
    this.holidays = this.store._getAll(types.HOLIDAY, Holiday);
  }

  @action async fetchHolidays() {
    const models = await fetchModels(
      endpoints.HOLIDAYS.FOR_YEAR.with(this.year),
      types.HOLIDAY
    );

    this.holidays = models.map((model) => new Holiday(model));
  }

  @action updateDuration(duration) {
    this.currentHoliday.merge({duration});
    if (duration === 'half_day') this.currentHoliday.includedInTimeTracking = false;
  }

  @computed get sortedHolidays() {
    return _.sortBy(this.holidays, ['date']);
  }

  @action async deleteHoliday(holiday) {
    await this.store.destroy(holiday);
    await this.fetchHolidays();
  }

  @action async saveHoliday() {
    const {model, errors} = await (this.currentHoliday.isNew ? this.postHoliday() : this.patchHoliday());
    this.errors = errors;

    if (model) {
      await this.fetchHolidays();
      this.closeEditHolidayModal();
    }
  }

  @action async patchHoliday() {
    return await this.store.patch(this.currentHoliday);
  }

  @action async postHoliday() {
    return await this.store.post(
      endpoints.HOLIDAYS.ALL,
      types.HOLIDAY,
      this.currentHoliday
    );
  }

  @action openCalendarModal() {
    this.calendarModalOpen = true;
    this.statutoryHolidays = this.availableStatutoryHolidays;
  }

  @action closeCalendarModal() {
    this.calendarModalOpen = false;
    this.statutoryHolidays = null;
    this.errors = {};
  }

  @action async saveStatutoryHolidays() {
    const data = this.statutoryHolidays.filter(holiday => holiday.selected).map(holiday => {
      return {
        name: t(`time_off.holidays.${holiday.name}`),
        date: holiday.date,
        location_ids: (holiday.locations && holiday.locations.map(loc => loc.id)) || null
      };
    });

    const {errors} = await this.store.post(
      endpoints.HOLIDAYS.BATCH,
      types.HOLIDAY,
      { holidays: data }
    );

    this.errors = errors;

    if (_.isEmpty(errors)) {
      this.closeCalendarModal();
      await this.fetchHolidays();
    }
  }

  @action async handleYearChange(year) {
    this.year = parseInt(year);
    await this.fetchHolidays();
  }

  @computed get availableStatutoryHolidays() {
    return _.differenceWith(
      this.getHolidays(this.year, this.locations),
      _.map(this.holidays),
      (a, b) => a.name === b.name && a.date === moment(b.date).format('YYYY-MM-DD')
    );
  }

  @computed get currentHolidayFor() {
    return this.currentHoliday.locationIds ? 'some' : 'all';
  }

  getHolidays(year, locations) {
    const NO_LOCATIONS = 'NO_LOCATIONS';
    const ALL_LOCATIONS = null;

    const canadianLocations = locations.filter(loc => loc.countryCode === 'CA'); // only support Canadian locations

    const locationsForHoliday = (holiday) => {
      if (holiday.appliesTo && holiday.doesNotApplyTo) {
        throw new Error('Only one of the following must be specified: appliesTo, doesNotApplyTo.');
      }

      let selectedLocations = [];

      if (holiday.appliesTo) {
        selectedLocations = canadianLocations.filter(loc => holiday.appliesTo.includes(loc.regionCode));
      } else if (holiday.doesNotApplyTo) {
        selectedLocations = canadianLocations.filter(loc => !holiday.doesNotApplyTo.includes(loc.regionCode));
      } else {
        selectedLocations = canadianLocations;
      }

      if (selectedLocations.length === locations.length) {
        return ALL_LOCATIONS;
      } else if (!selectedLocations.length) {
        return NO_LOCATIONS;
      }

      return selectedLocations;
    };

    const holidays = calendar.for(year).map((holiday) => {
      return {...holiday, locations: locationsForHoliday(holiday), selected: true};
    });

    return holidays.filter((holiday) => holiday.locations !== NO_LOCATIONS);
  }

  @action handleDateChange(date) {
    this.currentHoliday.date = moment(date).format('YYYY-MM-DD');
  }

  @action onAllEmployeesChange(value) {
    const locationIds = (value === 'all') ? null : [];
    this.currentHoliday.merge({ locationIds: locationIds });
  }

  @action handleLocationToggle(checkbox) {
    const id = checkbox.value;
    let locationIds = this.currentHoliday.locationIds;
    if (checkbox.checked) {
      locationIds.push(id);
    } else {
      locationIds.splice(locationIds.indexOf(id), 1);
    }
    this.currentHoliday.merge({ locationIds: locationIds });
  }
}

export default HolidaysState;
