import React from 'react';
import {endpoints, types} from 'shared/core';
import {observer} from 'mobx-react';
import FilterComponent from './FilterComponent';
import useFetchModels from 'shared/hooks/useFetchModels';

const CandidatePositions = observer(({filter, filterKey, filterUpdated, filterDeleted, Wrapper}) => {
  const [models, isLoading] = useFetchModels(endpoints.RECRUITING.POSITIONS.APPLIABLE, types.RECRUITING.POSITION);
  const options = models.map(position => ({ label: position.title, value: position.id }));

  return <FilterComponent
    filter={filter}
    filterKey={filterKey}
    options={options}
    filterUpdated={filterUpdated}
    filterDeleted={filterDeleted}
    Wrapper={Wrapper}
    isLoading={isLoading}
    searchable
  />;
});

export default CandidatePositions;
