import {DomainStore} from 'shared/store';
import {action, observable} from 'mobx';
import {types, endpoints} from 'shared/core';

class SurveysPageState {
  store = new DomainStore();
  @observable scope;

  @action async load() {
    await this.store._compose([
      endpoints.SURVEYS.NAVIGATION
    ]);

    this.scope = this.store._getSingle(types.NAVIGATION_SCOPE).tabs;
  }
}

export default SurveysPageState;
