import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import Suggestion from './suggestions';
import NoResults from './NoResults';
import $ from 'jquery';

const Section = observer(({title, children}) => {
  return (
    <div>
      <div className='border-bottom'>
        <div className='GlobalSearch-section'>
          <FormattedMessage id={`global_search.sections.${title}`}/>
        </div>
      </div>
      <div>
        {children}
      </div>
    </div>
  );
});

const Content = observer(({uiState}) => {
  if (uiState.isEmpty) {
    return <NoResults uiState={uiState}/>;
  }

  return (
    <div>
      {uiState.sections.map(section =>
        <Section key={section.caption} title={section.caption}>
          {section.suggestionViewModels.map(item =>
            <Suggestion key={item.data.id} viewModel={item} query={uiState.query}/>
          )}
        </Section>
      )}
    </div>
  );
});

@observer class SuggestionList extends React.Component {
  _mounted = false;

  state = {status: 'is-open'};

  componentDidMount() {
    this._mounted = true;
    $(document).one('click', () => {
      if (this._mounted) {
        this.setState({status: 'is-closed'});
        setTimeout(() => this.props.uiState.componentWillUnmount(), 400);
      }
    });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    const {uiState} = this.props;

    return (
      <div className={`GlobalSearch-list ${this.state.status}`}>
        <div className='bg-white rounded box-shadow-2 border'>
          <Content uiState={uiState}/>
        </div>
      </div>
    );
  }
}

export default SuggestionList;
