import React from 'react';
import {observer} from 'mobx-react';
import Panel from 'components/panels/Panel';
import AddButton from './AddButton';

const Header = observer(({showAddButton, onAdd, title, description, loading, actionButtons, localization = {}}) => {
  const SHOW_ADD_BUTTON = showAddButton && onAdd && localization.addModel;
  if (!title && !SHOW_ADD_BUTTON) return null;

  return (
    <Panel.Header title={title} description={description}>
      {actionButtons}
      {SHOW_ADD_BUTTON && <AddButton onAdd={onAdd} localization={localization} loading={loading}/>}
    </Panel.Header>
  );
});

export default Header;
