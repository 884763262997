import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {loader} from 'shared/core';
import PendingDocumentsState from '../state/PendingDocumentsState';
import pendingDocumentStore from '../state/PendingDocumentStore';
import BackToIndex from './BackToIndex';
import FillableDocumentContainer from 'components/pdf/fillable/components/FillableDocumentContainer';

const PendingDocumentsContainer = observer(({uiState}) => {
  const {documentViewModels, onAllDocumentsCompleted, errors} = uiState;

  if (errors.document) {
    return (
      <div className='Container pt4'>
        <div className='h2 bg-white center'>
          {errors.document}
        </div>
      </div>
    );
  }

  return (
    <div>
      <BackToIndex />
      <div className='Container'>
        <FillableDocumentContainer documentViewModels={documentViewModels}
                              callbacks={{onAllDocumentsCompleted: onAllDocumentsCompleted}}/>
      </div>
    </div>
  );
});

async function load({match}) {
  const uiState = new PendingDocumentsState(pendingDocumentStore, match);
  await uiState.load();
  return {uiState};
}

export default loader(PendingDocumentsContainer, load);
