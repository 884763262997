import React from 'react';
import {observer} from 'mobx-react';
import {AttachmentFormState} from '../state';
import {withState} from 'shared/core';
import {CheckboxWithLabel, ObjectSelect2} from 'components';

const AttachmentForm = observer(({uiState}) => {
  const {question, showAttachmentOptions, folders, showFolderSelector} = uiState;

  if (!showAttachmentOptions) return null;

  return (
    <div>
      <CheckboxWithLabel
        label={'components.question_form.attachment.Upload to employee profile upon completion'}
        checked={question.uploadToEmployeeProfile}
        onChange={(e) => question.merge({uploadToEmployeeProfile: e.checked})}
      />
      {question.uploadToEmployeeProfile && showFolderSelector && <div className='mt2'>
        <ObjectSelect2
          label={'components.question_form.attachment.Upload to folder'}
          allowClear
          value={question.attachmentFolder}
          onChange={value => question.merge({attachmentFolder: value})}
          items={folders}
        />
      </div>}
    </div>
  );
});


export default withState(AttachmentForm, AttachmentFormState);
