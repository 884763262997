import _ from 'lodash';
import {DomainStore} from 'shared/store';
import {endpoints} from 'shared/core';
import {action} from 'mobx';

class AdminCheckInState extends DomainStore {
  checkinStore;
  match;

  receiveProps({checkinStore, match}) {
    this.checkinStore = checkinStore;
    this.match = match;
  }

  @action async load() {
    await this.checkinStore._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.PERFORMANCE.CYCLE_REVIEWEES.with(this.match.params.id, this.match.params.employeeId)
    ]);

    this.checkinStore.allCheckins = this.checkinStore.sortAllCheckins();
    this.checkinStore.selectedReviewee = this.checkinStore.getEmployee(this.match.params.employeeId);
    await this.checkinStore.selectFirstCheckin();
  }

  onCheckInSelected = async (checkin) => {
    return this.checkinStore.selectCheckIn(checkin);
  };
}

export default AdminCheckInState;
