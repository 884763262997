import {DomainStore} from 'shared/store';
import {observable, observe, action, computed} from 'mobx';
import {endpoints} from 'shared/core';
import _ from 'lodash';

class EmployeeSelectorState {
  store = new DomainStore();
  onChange;

  @observable employees;
  @observable locations;
  @observable departments;
  @observable errors = {};

  @observable allEmployees = true;
  @observable selectedEmployees = [];
  @observable defaultEmployees = [];
  @observable previouslySelectedEmployees = [];

  receiveProps({onChange, selectedEmployees}) {
    this.onChange = onChange;
    this.defaultEmployees = selectedEmployees || [];
  }

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL,
      endpoints.LOCATIONS.ALL,
      endpoints.DEPARTMENTS
    ]);

    this.employees = this.store.getEmployees();
    this.locations = this.store.getLocations();
    this.departments = this.store.getDepartments();
    this.initSelectedEmployees();

    observe(this, 'uniqueSelectedEmployees', () => this.onChange(this.uniqueSelectedEmployees), true);
  }

  @action initSelectedEmployees() {
    if (this.defaultEmployees.length > 0) {
      const employeeIds = _.map(this.defaultEmployees, 'id');
      this.selectedEmployees = _.filter(this.employees, e => _.includes(employeeIds, e.id));
    } else {
      this.selectedEmployees = this.employees;
    }

    this.allEmployees = this.selectedEmployees.length === this.employees.length;
  }

  @action toggleAllEmployees() {
    this.allEmployees = !this.allEmployees;

    if (this.allEmployees) {
      this.previouslySelectedEmployees = this.selectedEmployees;
      this.selectedEmployees = this.employees;
    } else {
      this.selectedEmployees = this.previouslySelectedEmployees;
    }
  }

  @action selectLocation(location) {
    this.selectedEmployees = this.selectedEmployees.concat(
      this.employeesForLocation(location)
    );
  }

  @action selectDepartment(department) {
    this.selectedEmployees = this.selectedEmployees.concat(
      this.employeesForDepartment(department)
    );
  }

  @action selectManager(manager) {
    this.selectedEmployees = this.selectedEmployees.concat(
      this.employeesForManager(manager)
    );
  }

  @action selectEmployee(employee) {
    this.selectedEmployees.push(employee);
  }

  @action removeEmployee(employee) {
    this.selectedEmployees = _.reject(this.selectedEmployees, { id: employee.id });
  }

  @action clearEmployees() {
    this.selectedEmployees = [];
  }

  @computed get uniqueSelectedEmployees() {
    return _.uniqBy(this.selectedEmployees, 'id');
  }

  @computed get managers() {
    const managerIds = _.chain(this.employees).map('managerId').uniq().value();
    return _.filter(this.employees, e => managerIds.includes(e.id));
  }

  employeesForLocation(location) {
    return _.filter(this.employees, { location: { id: location.id }});
  }

  employeesForDepartment(department) {
    return _.filter(this.employees, { department: { id: department.id }});
  }

  employeesForManager(manager) {
    return _.filter(this.employees, { managerId: manager.id });
  }
}

export default EmployeeSelectorState;
