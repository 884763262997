import React                              from 'react';
import PropTypes from 'prop-types';
import _                                  from 'lodash';
import {fetchReport, shouldRefetchReport} from 'containers/reports/tools';
import {loader}                           from 'shared/core';
import {observer} from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Spinner, HorizontalScroll} from 'components';
import ReportEmptyState from './ReportEmptyState';
import {FormattedMessage} from 'react-intl';

// One row in a report table.
// Takes an hash of values {key: value} and an array of columns [{id, label}].
// For each column, renders an attribute from values with the key === column.id.
// The order of rendered values corresponds to the order of columns.
const TableReportRow = ({values, columns}) => {
  return <tr>
    {columns.map(col => (
      <td className='p2 Report-border' key={col.id}>
        <div className='px1 whitespace-pre-line'>{values[col.id]}</div>
      </td>
    ))}
  </tr>;
};

TableReportRow.propTypes = {
  values: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired
};

// Headers in a report table.
// Takes an array of headers [label] (an array of strings)
const TableReportHeaders = ({headers}) => {
  return (
    <thead>
    <tr className='bg-haze'>
      {headers.map((name, index) => {
        return (
          <td className='p2' key={index}>
            <div className='px1 whitespace-pre'>{name}</div>
          </td>
        );
      })}
    </tr>
    </thead>
  );
};

TableReportHeaders.propTypes = {
  headers: PropTypes.array.isRequired
};

const AllShown = ({totalCount, url, filters, name}) => {
  return (
    <div className='center pt3 pb3'>
      <div className='py1'>
        <FormattedMessage id='reports.ALL_RESULTS_SHOWN' values={{totalCount}}/>
      </div>
    </div>
  );
};

// Component that renders a table for a report. Includes headers.
// Takes an array of rows [{values: {}}] and an array of columns [{id, label}].
@observer class TableReport extends React.Component {
  state = {...this.props};

  loadMore = async () => {
    const {rows, nextLink} = this.state;
    const {filters} = this.props;

    const report = await fetchReport({
      url: nextLink,
      filters: {
        ...filters
      }
    });

    this.setState({...report, rows: [...rows, ...report.rows]});
  }

  render() {
    const {rows, csvUrl, nextLink, meta, filters, name} = this.state;
    if (_.isEmpty(meta)) return null;
    const {columns, totalCount} = meta;

    if (!totalCount) {
      return <ReportEmptyState/>;
    }

    return (
      <div className='pt2'>
        <HorizontalScroll fixedArrows>
          <InfiniteScroll
            next={this.loadMore}
            style={{overflow: 'unset'}}
            hasMore={!!nextLink}
            scrollThreshold={0.4}
            endMessage={<AllShown totalCount={totalCount} url={csvUrl} filters={filters} name={name}/>}
            loader={<Spinner/>}>
            <table className='border full-width overflow-auto'>
              <TableReportHeaders headers={_.map(columns, 'label')}/>
              <tbody>
                {rows.map((row, index) => <TableReportRow key={index} values={row.values} columns={columns}/>)}
              </tbody>
            </table>
          </InfiniteScroll>
        </HorizontalScroll>
      </div>
    );
  }
}

TableReport.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  csvUrl: PropTypes.string,
  totalCount: PropTypes.number
};

export default loader(TableReport, fetchReport, shouldRefetchReport);
