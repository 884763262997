import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';

const Description = observer(({descriptionItems}) => {
  if (_.isEmpty(descriptionItems)) return null;

  return (
    <ul>
      {descriptionItems.map((item, index) =>
        <li key={index} className='jumbo h5 pb1'>
          <FormattedMessage id={`company_settings.permissions.${item}`}/>
        </li>
      )}
    </ul>
  );
});

export default Description;
