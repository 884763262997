import React from 'react';
import {observer} from 'mobx-react';
import DragAndDropState from '../state/DragAndDropState';
import DragAndDropContext from '../DragAndDropContext';
import ActionLink from 'components/links/ActionLink';
import {FormattedMessage} from 'react-intl';
import Item from './Item';
import _ from 'lodash';

const DragAndDrop = observer(({uiState, display, localization, component, readOnly, readOnlyDisplay, readOnlyComponent, errorPath, errors, showErrorMessage, ...rest}) => {
  return (
    <div>
      {uiState.sortedModels.map((m, index) =>
        <Item
          {...rest}
          key={m.order}
          uiState={uiState}
          model={m}
          display={display}
          component={component}
          readOnly={readOnly}
          readOnlyDisplay={readOnlyDisplay}
          readOnlyComponent={readOnlyComponent}
          errors={errorPath && _.get(errors, errorPath(uiState.models.indexOf(m)))}
          errorMessage={showErrorMessage && errorPath && _.get(errors, errorPath(uiState.models.indexOf(m)))}
        />
      )}
      {uiState.onAdd && !readOnly && <ActionLink className='TextLink dodger inline-block' onClick={() => uiState.addModel()}>
        <FormattedMessage id={localization.addModel}/>
      </ActionLink>}
    </div>
  );
});

DragAndDrop.defaultProps = {
  showErrorMessage: true
};

// drag and drop component which allows to reorder an array of models
// props:

// - models: an array of observable objects, typically a MobX array of DomainObjects

// - display: the name of the attribute on a model to render inside a draggable item

// - component: a component to render inside a draggable item. this component will
//   receive the following props: {model}

// - localization: an object containing the following keys: {addModel}.

// - addModel: a function to call when the add a model link is clicked.
//   if this function returns false, the model won't be added. if the model is added,
//   it will automatically be assigned the correct order ( = max(order) + 1).

// - removeModel: a function to call when the remove model link is clicked. if this
//   function returns false, the model won't be removed. if the model is removed,
//   all models coming after the deleted model will have their order automatically
//   shifted down by 1.

// - readOnly: enables the read-only mode

// - readOnlyDisplay: like display, but used in read only mode

// - readOnlyComponent: like component, but used in read only mode

// notes:
// 1. either display or component is required. use display when you just need to render an attribute from the model,
//    e.g. model.name; use component when you need to render something more complicated than that, e.g.:
//    <DragAndDrop models={models} component={MyFancyComponent}/>
//    const MyFancyComponent = observer(({model}) => { return <div>{model.name}</div> });
// 2. each model is expected to have an @observable order;
// 3. this component modifies objects in the `models` array in place by assigning new values to the `order` attribute.
//    the array itself is not modified (i.e., items are not moved around in the array)

class DragAndDropWithState extends React.Component {
  render() {
    const {models, onAdd, onEdit, onRemove, onMove} = this.props;
    const uiState = new DragAndDropState(models, onAdd, onEdit, onRemove, onMove);
    return <DragAndDrop uiState={uiState} {...this.props}/>;
  }
}

export default DragAndDropContext(DragAndDropWithState);
