import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';
import {t} from 'shared/core';

const RemoveBankInfoStepModal = observer(({uiState}) => {
  const {employee} = uiState;

  return (
    <AlertModal
      size='sm'
      mode='danger'
      header={t('employees.profile.Skip Bank Info Onboarding Step')}
      subHeader={
        t(`employees.profile.REMOVE_BANK_INFO_STEP_MODAL_SUBHEADER`, {employeeName: employee.name})
      }
      body={t("employees.profile.This information can still be entered in the employee's profile after onboarding.")}
      translate={false}
      isOpen={uiState.removeBankInfoStepModalOpen}
      onHide={() => uiState.resetModals()}>
      <ModalDangerButtons
        onSave={() => uiState.confirmRemoveBankInfoStep()}
        onCancel={() => uiState.resetModals()}
        cancelCaption='employees.profile.Skip'
      />
    </AlertModal>
  );
});

export default RemoveBankInfoStepModal;
