import React from 'react';
import {observer} from 'mobx-react';
import Details from './details';
import Description from './description';
import Settings from './settings';
import Review from './review';
import Questions from './questions';
import Rules from './rules';

@observer class Steps extends React.Component {

  render() {
    const {step, uiState} = this.props;

    switch (step) {
      case 'details':
        return <Details uiState={uiState}/>;
      case 'description':
        return <Description uiState={uiState}/>;
      case 'questions':
        return <Questions uiState={uiState}/>;
      case 'rules':
        return <Rules uiState={uiState}/>;
      case 'settings':
        return <Settings uiState={uiState}/>;
      case 'review':
        return <Review uiState={uiState}/>;
      default:
        throw new Error(`Location ${step} is not supported.`);
    }
  }
}

export default Steps;
