import {observable, computed, action} from 'mobx';
import moment from 'moment';
import {t} from 'shared/core';
import _ from 'lodash';

class DayOffViewModel {
  @observable json;
  @observable unit;
  @observable editable;
  @observable showErrorMessage = false;

  constructor(json, editable=true) {
    this.json = json;
    this.unit = this.guessUnit();
    this.editable = editable;
  }

  equalTo(other) {
    return this.json.date === other.json.date;
  }

  guessUnit() {
    if (this.json.work_day_length_in_seconds === Math.round(this.json.cost * 3600)) return 'full';
    if (this.json.work_day_length_in_seconds === Math.round(this.json.cost * 3600 * 2)) return 'half';
    return 'custom';
  }

  @action updateUnit(unit) {
    this.showErrorMessage = false;
    this.unit = unit;

    switch (unit) {
      case 'full':
        if (!!this.json.start_time) {
          this.json.end_time = moment(moment(this.json.start_time, ['h:m a', 'H:m']) + (this.json.work_day_length_in_seconds * 1000)).format(t('components.dates.TIMESTAMP_FORMAT'));
        }
        break;
      case 'half':
        if (!!this.json.start_time) {
          this.json.end_time = moment(moment(this.json.start_time, ['h:m a', 'H:m']) + (this.json.work_day_length_in_seconds / 2 * 1000)).format(t('components.dates.TIMESTAMP_FORMAT'));
        }
        break;
      case 'custom':
      default:
        break;
    }
  }

  @action updateStartTime(value) {
    this.showErrorMessage = false;
    this.json.start_time = value;
    if (this.unit === 'half' && this.json.start_time) {
      this.json.end_time = moment(moment(this.json.start_time, ['h:m a', 'H:m']) + (this.json.work_day_length_in_seconds / 2 * 1000)).format(t('components.dates.TIMESTAMP_FORMAT'));
    }
  }

  @action updateEndTime(value) {
    this.showErrorMessage = false;
    this.json.end_time = value;
    if (this.unit === 'half') {
      this.json.start_time = moment(moment(this.json.end_time, ['h:m a', 'H:m']) - (this.json.work_day_length_in_seconds / 2 * 1000)).format(t('components.dates.TIMESTAMP_FORMAT'));
    }
  }

  @action updateHours(value, start_time, end_time) {
    this.json.cost = value;
    this.json.start_time = start_time;
    this.json.end_time = end_time;
  }

  @computed get days() {
    if (this.hasFullDayHoliday || this.hasCompanyDayOff) {
      return 0;
    }

    if (this.unit === 'full' && !this.json.start_time && !this.json.end_time) {
      return 1;
    } if (this.unit === 'half' && this.hasHalfDayHoliday && !this.json.start_time && !this.json.end_time) {
      return 0.5;
    }

    let start_time = moment(this.json.start_time, ['h:m a', 'H:m']);
    let end_time = moment(this.json.end_time, ['h:m a', 'H:m']);

    return Math.round((end_time - start_time) / this.json.work_day_length_in_seconds / 10) / 100;
  }

  @computed get hoursExact() {
    if (this.hasFullDayHoliday || this.hasCompanyDayOff) {
      return 0;
    }

    if (this.unit === 'full' && !this.json.start_time && !this.json.end_time) {
      return this.json.work_day_length_in_seconds / 3600;
    } if (this.unit === 'half' && this.hasHalfDayHoliday && !this.json.start_time && !this.json.end_time) {
      return this.json.work_day_length_in_seconds / 3600 / 2;
    }

    if (!this.json.start_time || !this.json.end_time) {
      return null;
    }

    let start_time = moment(this.json.start_time, ['h:m a', 'H:m']);
    let end_time = moment(this.json.end_time, ['h:m a', 'H:m']);

    return (end_time - start_time) / 3600000;
  }

  @computed get hours() {
    if (_.isNull(this.hoursExact)) {
      return null;
    }
    return Math.round(this.hoursExact * 100) / 100;
  }

  @computed get hasHoliday() {
    return !!this.json.holiday;
  }

  @computed get hasFullDayHoliday() {
    return this.json.holiday_duration === 'full_day';
  }

  @computed get hasHalfDayHoliday() {
    return this.json.holiday_duration === 'half_day';
  }

  @computed get workDayLengthHours() {
    return this.json.work_day_length_in_seconds / 3600;
  }

  @computed get holidayName() {
    return this.json.holiday;
  }

  @computed get hasCompanyDayOff() {
    return this.json.day_off;
  }

  @computed get date() {
    return new Date(moment(this.json.date));
  }

  @computed get canUpdateHours() {
    return !this.hasCompanyDayOff && !this.hasFullDayHoliday && this.editable;
  }

  @computed get clickable() {
    return this.canUpdateHours;
  }

  @computed get timeRange() {
    if (!this.json.start_time) {
      return null;
    }

    return `${moment(this.json.start_time, ['h:m a', 'H:m']).format(t('components.dates.TIMESTAMP_FORMAT'))} - ${moment(this.json.end_time, ['h:m a', 'H:m']).format(t('components.dates.TIMESTAMP_FORMAT'))}`;
  }

  @computed get errorMessage() {
    if (!(this.unit === 'full') && (!this.json.start_time || !this.json.end_time)) {
      return t('employees.profile.time_off.request.Times are required');
    } else if (Math.round(this.hoursExact * 3600) > this.json.work_day_length_in_seconds) {
      return t('employees.profile.time_off.request.Absence cannot be longer than one day');
    } else if (this.hasHalfDayHoliday && Math.round(this.hoursExact * 3600 * 2) > this.json.work_day_length_in_seconds) {
      return t('employees.profile.time_off.request.Absence cannot be longer than half a day');
    } else if (this.hoursExact < 0){
      return t('employees.profile.time_off.request.End time cannot be before start time');
    }

    return null;
  }
}

export default DayOffViewModel;
