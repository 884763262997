import React from 'react';
import Report from '../Report';
import CompositeReport from '../CompositeReport';
import {CURRENT_MONTH_START_DATE, RELATIVE_END_DATE} from 'reports/tools';
import {t} from 'shared/core';

const filters = [
  'Project',
  'Employee',
  'Department',
  'Location',
  'Employment Status',
  'Employment Type',
  'Manager'
];

const ProjectHoursReport = () => {
  return (
    <CompositeReport dateFilter='range' defaultStartDate={CURRENT_MONTH_START_DATE} defaultEndDate={RELATIVE_END_DATE} filters={filters}>
      <Report
        view='metric'
        showName={false}
        url='/reports/total_project_hours'
      />
      <Report
        name={t('reports.PROJECT_HOURS_REPORT')}
        url='/reports/project_hours'
      />
    </CompositeReport>
  );
};

export default ProjectHoursReport;
