import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {Panel, Input, Select2, NumberInput, FormError, DragAndDrop} from 'components';
import METRIC_TYPE_OPTIONS from 'stores/performance_goals/metricTypeOptions';
import _ from 'lodash';

const MetricContent = observer(({model, errors}) => {
  return (
    <div className='my2'>
      <div className='mb2'>
        <Input
          value={model.name}
          onChange={e => model.merge({name: e.target.value})}
          label='goals.Name'
          errorMessage={_.get(errors, 'name')}
          placeholder='goals.e.g. Website Traffic Growth'
        />
      </div>
      <div className='clearfix mb2 left-align'>
        <div className='col-2 table-cell mr2'>
          <Select2
            allowClear={false}
            value={model.metricType}
            disabled={!model.isNew}
            onChange={e => model.merge({metricType: e.target.value})}
            label='goals.Type'
          >
            {METRIC_TYPE_OPTIONS.map((type) =>
              <option value={type.id} key={type.id}>{t(type.label)}</option>
            )}
          </Select2>
        </div>
        <div className='col-5 table-cell pl2'>
          {!model.isBoolean &&
            <NumberInput
            value={model.startValue}
            onChange={e => model.merge({startValue: e.target.value})}
            errorMessage={_.get(errors, 'startValue')}
            label='goals.Start'/>}
        </div>
        <div className='col-5 table-cell pl2'>
          {!model.isBoolean &&
            <NumberInput
            value={model.targetValue}
            onChange={e => model.merge({targetValue: e.target.value})}
            errorMessage={_.get(errors, 'targetValue')}
            label='goals.Target'/>}
        </div>
      </div>
    </div>
  );
});

const LOCALIZATION = {
  addModel: 'goals.+ Add a metric'
};

const MetricForm = observer(({uiState}) => {
  const {goal, errors} = uiState;

  return (
    <Panel>
      <Panel.Header
        title={t('goals.Metrics')}
        description={t('goals.METRIC_DESCRIPTION')}
      />
      <FormError className='mb2' message={_.get(errors, 'base')}/>
      <DragAndDrop
        models={goal.sortedMetrics}
        component={MetricContent}
        errors={errors}
        localization={LOCALIZATION}
        onAdd={() => uiState.addMetric()}
        onRemove={(metric) => uiState.onMetricDelete(metric)}
        errorPath={(index => `metrics[${index}]`)}
        showErrorMessage={false}
      />
    </Panel>
  );
});

export default MetricForm;
