import React from 'react';
import {observer} from 'mobx-react';
import {Table, Checkbox} from 'components';
import {FormattedMessage} from 'react-intl';
import {withState} from 'shared/core';
import TrainingListState from './TrainingListState';

const TrainingCheckbox = observer(({model}) => {
  return (
    <Checkbox
      checked={model.selected}
      onChange={() => model.toggle()}
    />
  );
});

const DueDate = observer(({model}) => {
  if (model.data.relativeDueDateValue === null) return '';

  switch (model.data.relativeDueDateUnit) {
    case 'days': return (
        <FormattedMessage id='employees.hire.training.DUE_DATE_IN_DAYS' values={{
            number: model.data.relativeDueDateValue
          }}
        />
      );
    case 'weeks': return (
        <FormattedMessage id='employees.hire.training.DUE_DATE_IN_WEEKS' values={{
            number: model.data.relativeDueDateValue
          }}
        />
      );
    default:
      throw new Error(`Relative date unit ${model.data.relativeDueDateUnit} not supported.`);
  }
});

const COLUMNS = [
  {
    component: TrainingCheckbox,
    width: 1
  },
  {
    header: 'employees.hire.training.Training',
    attribute: 'data.name',
    width: 7
  },
  {
    header: 'employees.hire.training.Due Date',
    component: DueDate,
    width: 4
  }
];

const TrainingList = observer(({uiState}) => {
  return (
    <Table
      showLinks={false}
      columns={COLUMNS}
      models={uiState.viewModels}
    />
  );
});

export default withState(TrainingList, TrainingListState);
