import React from 'react';
import {observer} from 'mobx-react';
import TextField from './TextField';
import TextAreaField from './TextAreaField';
import AttachmentField from './AttachmentField';
import MultipleChoiceField from './MultipleChoiceField';
import SentimentField from './SentimentField';
import Description from 'components/forms/question/components/Description';

const QuestionFieldFactory = observer(({answer, ...rest}) => {
  switch (answer.question.questionType) {
    case 'text_field':
      return <TextField answer={answer} {...rest} />;
    case 'text_area':
      return <TextAreaField answer={answer} {...rest} />;
    case 'attachment':
      return <AttachmentField answer={answer} {...rest} />;
    case 'sentiment':
    case 'scored':
      return <SentimentField answer={answer} {...rest} />;
    case 'multiple_choice':
    case 'multi_select':
    case 'quiz':
      return <MultipleChoiceField answer={answer} {...rest} />;
    case 'description':
      return <Description answer={answer} /> ;
    default:
      throw new Error(`Question type ${answer.question.questionType} is not supported.`);
  }
});

export default QuestionFieldFactory;
