import React from 'react';
import TableReport from './TableReport';
import ChartReport from './ChartReport';
import MetricReport from './MetricReport';
import {observer} from 'mobx-react';
import {observable, computed} from 'mobx';
import {api, t} from 'shared/core';
import _ from 'lodash';
import {Panel, DropdownSelect} from 'components';
import {DownloadForm} from './download';
import {FormattedMessage} from 'react-intl';
import DownloadChart from './download/components/DownloadChart';

const ReportContent = ({view, status, reportRef, ...rest}) => {
  if (status === 'timeout') {
    return <ReportTimeout/>;
  }
  switch (view) {
    case 'chart':
      return <ChartReport reportRef={reportRef} view={view} {...rest}/>;
    case 'metric':
      return <MetricReport view={view} {...rest}/>;
    case 'table':
    default:
      return <TableReport view={view} {...rest}/>;
  }
};

const ReportTimeout = observer(() => {
  return (
    <div className='center pt3 pb3'>
      <div className='py1'>
        <FormattedMessage id='reports.report_timed_out'/>
      </div>
    </div>
  );
});

const ReportHeader = observer(({name, view, csvUrl, hasExport, filters, downloadUrl, showName = true, panelHeader = true, reportRef}) => {
  if (!hasExport && !name) return null;

  if(panelHeader) {
    return (
      <Panel.Header title={showName ? name : null}>
        {hasExport &&
          <DropdownSelect selected={t('reports.Export')}>
            {view === 'chart' ?
              <DownloadChart
                name={name}
                reportRef={reportRef}
              /> :
              <DownloadForm
                name={name}
                filters={filters}
                csvUrl={csvUrl}
                downloadUrl={downloadUrl}
              />
            }
          </DropdownSelect>
        }
      </Panel.Header>
    );
  }

  return (
    <div className='PanelHeader center'>
      <div className='PanelTitle'>{name}</div>
    </div>
  );
});

const Group = observer(({children, ...rest}) => {
  return (
    <Panel className='clearfix mb3 fs-hide'>
      {React.Children.map(children, child => {
        return(
          React.cloneElement(child, {
            noWrapper: true,
            panelHeader: false,
            ...rest
          })
        );
      })}
    </Panel>
  );
});

const ReportWrapper = ({noWrapper, className, children}) => {
  if (noWrapper) {
    return <div className={className}>{children}</div>;
  }
  return <Panel className='clearfix mb2 fs-hide'>{children}</Panel>;
};

@observer class Report extends React.Component {
  @observable report = {};
  reportRef = React.createRef();

  @computed get hasExport() {
    return this.props.view === 'table' || this.props.view === 'chart';
  }

  onReportLoaded = (report) => {
    this.report = report;
    this.props.uiState.addReport(report);
  }

  downloadReport = async () => {
    await api.post(this.report.csvUrl, this.props.uiState.filters);
  };

  render() {
    const {name, url, view, showName, chartType, options, uiState, noWrapper, className, panelHeader = true} = this.props;
    const {filters, nextLink} = uiState;
    const {csvUrl} = this.report;

    return(
      <ReportWrapper noWrapper={noWrapper} className={className}>
        <ReportHeader
          name={name}
          view={view}
          csvUrl={csvUrl}
          hasExport={this.hasExport}
          filters={filters}
          showName={showName}
          downloadUrl={url}
          panelHeader={panelHeader}
          reportRef={this.reportRef}
        />
        <ReportContent
          name={name}
          view={view}
          url={url}
          filters={filters}
          ready={true}
          onLoaded={this.onReportLoaded}
          nextLink={nextLink}
          chartType={chartType}
          options={options}
          status={this.report.status}
          reportRef={this.reportRef}
        />
      </ReportWrapper>
    );
  }
}

Report.defaultProps = {
  view: 'table',
  noWrapper: false
};

Report.Group = Group;

export default Report;
