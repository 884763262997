/* eslint-disable */
import sampleSize from 'lodash/sampleSize';
import times from 'lodash/times';
import startCase from 'lodash/startCase';
import drawNode from './tree/node';
import Tree from './tree';
const Document = window.PDFDocument;
const blobStream = window.blobStream;
export default class PDF {
  constructor(rootEmployeeID, employeesMap, meta = {}) {
      // generate tree structure
      this.tree = new Tree(rootEmployeeID, employeesMap);
      this.meta = meta;
  }
  async setIframe(iframe) {
      const stream = await this._render();
      iframe.src = stream.toBlobURL('application/pdf');
  }
  async downloadPDF(pdfName) {
      const stream = await this._render();
      const blob = stream.toBlob('application/pdf');
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${pdfName}.pdf`;
      link.click();
      setTimeout(() => {
          // necessary for firefox to delay revoking the ObjectURL
          window.URL.revokeObjectURL(blob);
      }, 100);
  }
  async _render() {
      const tree = this.tree;
      // calc size
      tree.calculateSize();
      // layout all items
      tree.layoutItems();
      this.doc = new Document({
          layout: 'landscape',
          info: this.meta,
          // invert w + h due to landscape
          size: [tree.pdfSize[1], tree.pdfSize[0]],
          // need to set to 0 to avoid weird functionality (where pdfkit blocks)
          margin: 0
      });
      // this._drawGrid(tree.size[0], tree.size[1])
      // draw items on pdf
      await tree.draw(this.doc);
      return this._end();
  }
  _end() {
      return new Promise((resolve, reject) => {
          const stream = this.doc.pipe(window.blobStream());
          this.doc.end();
          stream.on('finish', () => {
              resolve(stream);
          });
          // TODO - see if there's an onError event
      });
  }
}
