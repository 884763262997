import {observer} from 'mobx-react';
import {Input, Select2} from 'components';
import React from 'react';
import LabelHelper from 'components/LabelHelper';

export const PayRateBulkEditInput = observer(({uiState}) => {
  const {bulkEditValue} = uiState;

  return (
    <React.Fragment>
      <LabelHelper label='mass_updates.edit.fields.pay_rate' translateLabel/>
      <Input
        value={bulkEditValue}
        onChange={e => uiState.setBulkEditValue(e.target.value)}
        connectedRight={
          <div className='border p1 user-select-none bg-white' style={{borderRadius: '0 8px 8px 0', padding: '7px'}}>
            {'%'}
          </div>
        }
      />
    </React.Fragment>
  );
});

export const ManagerBulkEditInput = observer(({uiState}) => {
  const {employees, bulkEditValue} = uiState;

  return (
    <React.Fragment>
      <LabelHelper label='mass_updates.edit.fields.manager' translateLabel/>
      <Select2 searchable allowClear
               value={bulkEditValue}
               onChange={e => uiState.setBulkEditValue(e.target.value)}>
        {employees.map(employee => {
          return <option key={employee.id} value={employee.id}>{employee.name}</option>;
        })}
      </Select2>
    </React.Fragment>
  );
});


const BulkEditInputFactory = observer(({field, ...rest}) => {
  switch (field) {
    case 'manager':
      return <ManagerBulkEditInput {...rest} />;
    case 'payRate':
      return <PayRateBulkEditInput {...rest} />;
    default:
      return null;
  }
});

export default BulkEditInputFactory;
