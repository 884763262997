import React from 'react';
import {observer} from 'mobx-react';
import CommentInput from '../comments/CommentInput';
import {Modal} from 'components/modals';

const EditCommentModal = observer(({uiState}) => {
  if (!uiState.editCommentModalOpen) return null;

  return (
    <Modal
      size='sm'
      isOpen={uiState.editCommentModalOpen}
      title='hr_dashboard.feed.announcements.Edit Comment'
      onHide={() => uiState.closeCommentEditModal()}
    >
      <CommentInput viewModel={uiState.editingCommentViewModel} uiState={uiState}/>
    </Modal>
  );
});

export default EditCommentModal;
