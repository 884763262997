import {action} from 'mobx';
import {t, types} from 'shared/core';
import {DomainStore} from 'shared/store';
import {successAlert} from 'shared/tools';

class PendingInvitationsState {
  agent;
  onMount;
  store = new DomainStore();

  receiveProps({onMount}) {
    this.onMount = onMount;
  }

  setInteractiveAgent(agent) {
    this.agent = agent;
    if (this.onMount) {
      this.onMount(agent);
    }
  }

  @action async destroyPendingInvitation(invitation) {
    await this.store.destroy(invitation);
    successAlert(t('company_settings.external_users.Successfully revoked invitation'));
  }

  @action async resendPendingInvitation(invitation) {
    const {model} = await this.store.put(
      invitation.link('resend'),
      types.ADMIN_INVITATION,
      invitation
    );

    if (model) {
      invitation.update(model);
    }

    successAlert(t('company_settings.external_users.Successfully resent invitation'));
  }

  customLinksFor(model) {
    if (!model.hasLink('resend')) {
      return [];
    }

    return [{
      order: 1,
      text: 'company_settings.external_users.Resend',
      action: invitation => this.resendPendingInvitation(invitation)
    }];
  }
}

export default PendingInvitationsState;
