import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';
import {withState} from 'shared/core';
import CandidateRaterState from '../../state/CandidateRaterState';

const CandidateRater = observer(({uiState}) => {
  const {scoreToDisplay} = uiState;

  if (!uiState.onRating && !scoreToDisplay) {
    return null;
  }

  return (
    <div className='flex h2'>
      {_.times(5, (i) => {
        const starNumber = i + 1;

        return <div
          key={i}
          className={`${scoreToDisplay >= starNumber ? 'sun' : 'waterloo'} ${uiState.onRating ? 'clickable' : 'nonclickable'}`}
          style={{paddingRight: '1px'}}
          onMouseEnter={() => uiState.onStarMouseEnter(starNumber)}
          onMouseLeave={() => uiState.onStarMouseLeave()}
          onClick={() => uiState.rateCandidate(starNumber)}
        >
          {'\u2605'}
        </div>;
      })}
    </div>
  );
});


export default withState(CandidateRater, CandidateRaterState);
