import React from 'react';
import {observer} from 'mobx-react';
import EmployeeRoleContainerWithState from './EmployeeRoleContainerWithState';
import EmployeeProfileContainer from '../../EmployeeProfileContainer';

const EmployeeRoleContainer = observer(({uiState}) => {
  return (
    <EmployeeProfileContainer uiState={uiState}>
      <EmployeeRoleContainerWithState parentState={uiState}/>
    </EmployeeProfileContainer>
  );
});

export default EmployeeRoleContainer;
