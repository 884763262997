import React from 'react';
import {observer} from 'mobx-react';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const DeleteCommentModal = observer(({uiState}) => {
  return (
    <AlertModal
      size='sm'
      mode='danger'
      isOpen={uiState.deleteCommentModalOpen}
      onHide={() => uiState.closeDeleteCommentModal()}
      header='goals.Remove Comment?'
      subHeader='goals.Are you sure you want to delete this comment?'>
      <ModalDangerButtons
        onSave={() => uiState.deleteComment()}
        onCancel={() => uiState.closeDeleteCommentModal()} />
    </AlertModal>
  );
});

export default DeleteCommentModal;

