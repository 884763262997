import React from 'react';
import {observer} from 'mobx-react';
import {Layout, InteractiveTable, Tag, DropdownFilter} from 'components';
import {endpoints, types, t, withState} from 'shared/core';
import {TrainingProgram} from 'stores/training';
import TrainingSubnav from 'training/components/TrainingSubnav';
import {TrainingAdminDirectoryState} from '../state';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import useModal from 'shared/hooks/useModal';
import {calendarDate} from 'shared/tools';
import CloseProgramModal from './CloseProgramModal';
import ReopenProgramModal from './ReopenProgramModal';

const TrainingProgramName = observer(({program}) => {
  const destination = program.launched ? 'summary' : 'edit';

  return (
    <div>
      <Link to={`/admin/programs/${program.id}/${destination}`}>
        {program.name ? program.name : <FormattedMessage id='training.manage.Untitled Training Program'/>}
      </Link>
      {!program.launched && <Tag className='ml2'><FormattedMessage id='training.manage.Draft'/></Tag>}
    </div>
  );
});

const EmployeeStatusSummary = observer(({program}) => {
  if (!program.totalEmployees) {
    return (
      <FormattedMessage id='training.manage.No employees are enrolled in this program'/>
    );
  }

  return (
    <FormattedMessage
      id='training.manage.COMPLETED_BY_EMPLOYEES'
      values={{
        totalEmployeesCompleted: program.totalEmployeesCompleted,
        totalEmployees: program.totalEmployees
      }}
    />
  );
});

const COLUMNS = [
  {
    header: 'training.manage.Training Program',
    width: 4,
    render: model => <TrainingProgramName program={model}/>,
    sortBy: 'title'
  },
  {
    header: 'training.manage.Status',
    width: 5,
    render: model => <EmployeeStatusSummary program={model}/>
  },
  {
    header: 'training.manage.Launch Date',
    width: 2,
    render: model => calendarDate(model.launchedAt),
    sortBy: 'launchedAt'
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'training.manage.Delete Training Program',
    subHeader: 'training.manage.Are you sure you want to delete this training program?'
  },
  emptyState: 'training.manage.No training programs to display',
  interactive: {
    addModel: 'training.manage.Create a Training Program'
  }
};

function statusFilters() {
  return [
    {
      id: 'active',
      display: t('training.directory.Active')
    },
    {
      id: 'closed',
      display: t('training.directory.Closed')
    }
  ];
}

const TrainingAdminDirectoryContainer = observer(({uiState}) => {
  const {match} = uiState;
  const reopenProgramModalOpen = useModal(uiState, 'ReopenProgramModal', 'selectedProgram');

  return (
    <div>
      <TrainingSubnav/>
      <Layout>
        <InteractiveTable
          saveStateToUrl
          uiState={uiState}
          title={t('training.manage.Training Programs')}
          columns={COLUMNS}
          localization={LOCALIZATION}
          onRemove={program => uiState.destroyProgram(program)}
          onAdd={() => uiState.createProgram()}
          editRoute={`${match.path}/:id/edit`}
          customLinks={program => uiState.customLinksFor(program)}
          onMount={agent => uiState.setInteractiveAgent(agent)}
          proxy={{
            type: 'async',
            modelType: types.TRAINING.PROGRAM,
            model: TrainingProgram,
            endpoint: endpoints.TRAINING.PROGRAMS
          }}
        >
          <DropdownFilter options={statusFilters()} attr='status'/>
        </InteractiveTable>
      </Layout>
      <CloseProgramModal uiState={uiState}/>
      <ReopenProgramModal uiState={uiState} modalOpen={reopenProgramModalOpen}/>
    </div>
  );
});

export default withState(TrainingAdminDirectoryContainer, TrainingAdminDirectoryState);
