import {observer} from 'mobx-react';
import React from 'react';
import {Input} from 'components';
import {SentimentFormState} from '../state';
import {withState} from 'shared/core';
import {FormattedMessage} from 'react-intl';

const SentimentForm = observer(({question}) => {
  return (
    <div>
      {question.questionType === 'scored' && <div className='jumbo mb2' style={{marginTop: '-1rem'}}>
        <FormattedMessage id='components.question_form.Scored questions allow you to graph responses to performance review questions'/>
      </div>}
      <div className='mb1'>
        <FormattedMessage id='components.question_form.Options'/>
      </div>
      <div className='table pb2 border-bottom'>
        <div className='table-cell full-width align-middle pr1'>
          <div className='InputScale'>
            {question.options.map((option, index) => <div key={index}>
              <span/>
              <Input
                className='small mb1'
                value={option.value}
                onChange={e => option.value = e.target.value}
              />
            </div>)}
          </div>
        </div>
      </div>
    </div>
  );
});


export default withState(SentimentForm, SentimentFormState);
