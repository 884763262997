import React from 'react';
import {observer} from 'mobx-react';
import {Layout, Display, Button, Input, Panel, DragAndDrop, FormError, ActionLink, EditQuestionModal} from 'components';
import {t, withState} from 'shared/core';
import _ from 'lodash';
import {EditTemplateState} from '../state';
import DraggableFieldContent from './DraggableFieldContent';
import {FormattedMessage} from 'react-intl';
import {QUESTION_TYPES} from 'stores/performance_reviews';
import {SECTION_TYPES} from 'stores/performance_reviews';

const LOCALIZATION = {
  title: {
    add: 'performance.templates.Add section',
    edit: 'performance.templates.Edit section'
  },
  question: 'performance.templates.Title',
  description: 'performance.templates.Description'
};

const EditTemplateContainer = observer(({uiState}) => {
  const {editingQuestion, reviewTemplate, errors} = uiState;

  return (
    <div>
      <Display
        title={reviewTemplate.name || t('performance_reviews.Untitled question set')}
        backTitle={t('performance_reviews.templates.Back')}
        backPath='/question_sets'/>
      <Layout>
        <Layout.Section>
          <Panel>
            <Panel.Header
              title={t('performance_reviews.templates.Question Set Name')}
              description={t('performance_reviews.templates.Choose a name for your question set that will make it easy to recognize and select when it is time to create a review.')}
            />
            <Input value={reviewTemplate.name}
              onChange={e => reviewTemplate.merge({name: e.target.value})}
              helpMessage='performance_reviews.templates.e.g., Peer Review Questions'
              className='full-width'
              errorMessage={errors.name}
            />
          </Panel>
          <Panel.Stack>
            <Panel className='mt2'>
              <Panel.Header
                title={t('performance_reviews.templates.Questions')}
                description={t('performance_reviews.templates.These questions will be asked when using this set in a review.')}
              />
            </Panel>
            <Panel>
              <DragAndDrop
                models={reviewTemplate.questions}
                onRemove={model => uiState.removeQuestion(model)}
                onEdit={model => uiState.editQuestion(model)}
                component={DraggableFieldContent}
                errors={errors}
                errorPath={(index) => `questions[${index}].name`}
              />
              <div>
                <ActionLink className='TextLink dodger inline-block' onClick={() => uiState.openQuestionModal()}>
                  <FormattedMessage id='performance.templates.+ Add a question'/>
                </ActionLink>
                <ActionLink className='TextLink dodger inline-block pl3' onClick={() => uiState.openTitleModal()}>
                  <FormattedMessage id='performance.templates.+ Add a section'/>
                </ActionLink>
              </div>
              <FormError message={errors.base}/>
            </Panel>
          </Panel.Stack>
          <div className='flex justify-content-end mt3'>
            <Button onClick={() => uiState.updateTemplate()}>
              <FormattedMessage id='performance.templates.Save'/>
            </Button>
          </div>
        </Layout.Section>
      </Layout>
      {editingQuestion && <EditQuestionModal
        questionTypes={QUESTION_TYPES}
        isOpen={uiState.questionModalOpen}
        onClose={() => uiState.closeQuestionModal()}
        onSave={() => uiState.updateQuestion()}
        question={editingQuestion}
        showDescription={true}
        errorMessage={errors.options}
      />}
      {editingQuestion && <EditQuestionModal
        questionTypes={SECTION_TYPES}
        isOpen={uiState.titleModalOpen}
        onClose={() => uiState.closeTitleModal()}
        onSave={() => uiState.updateQuestion()}
        question={editingQuestion}
        localization={LOCALIZATION}
        showDescription={true}
        showQuestionType={false}
        showRequired={false}
        errorMessage={errors.options}
      />}
    </div>
  );
});

export default withState(EditTemplateContainer, EditTemplateState);
