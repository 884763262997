import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import Company from 'stores/company/Company';

class CompanyStore extends DomainStore {
  async loadCompany() {
    await this._compose([
      endpoints.CURRENT_COMPANY
    ]);

    return new Company(this._getSingle(types.COMPANY));
  }

  async patchCompany(model) {
    return await this.patch(
      endpoints.COMPANY.with(model.id),
      types.COMPANY,
      model
    );
  }

  async postLocation(model) {
    return await this.post(
      endpoints.LOCATIONS.ALL,
      types.LOCATION,
      model
    );
  }

  async patchLocation(model) {
    return await this.patch(
      endpoints.LOCATION.with(model.id),
      types.LOCATION,
      model
    );
  }
}

const singleton = new CompanyStore();

export default singleton;
