import React from 'react';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {Input, FormLayout, Select2} from 'components';
import _ from 'lodash';

const AddRepModal = observer(({uiState, errors}) => {
    return (
      <Modal
        isOpen={uiState.addRepModalOpen}
        closeButton={false}
        title='broker_portal.carrier_reps.edit.Add Insurance Carrier Rep'
      >
        <FormLayout>
          <Select2
            value={uiState.newInsuranceCarrierRep.carrier}
            onChange={e => uiState.newInsuranceCarrierRep.merge({carrier: e.target.value})}
            label='broker_portal.carrier_reps.edit.Carrier'
            errorMessage={_.get(errors, 'carrier')}
          >
            {uiState.carriers().map(carrier =>
              <option value={carrier.key} key={carrier.key}>{carrier.name}</option>
            )}
          </Select2>
          <FormLayout.Group>
            <Input
              value={uiState.newInsuranceCarrierRep.firstName}
              label='broker_portal.carrier_reps.edit.First Name'
              onChange={e => uiState.newInsuranceCarrierRep.merge({firstName: e.target.value})}
              errorMessage={_.get(errors, 'firstName')}
            />
            <Input
              value={uiState.newInsuranceCarrierRep.lastName}
              label='broker_portal.carrier_reps.edit.Last Name'
              onChange={e => uiState.newInsuranceCarrierRep.merge({lastName: e.target.value})}
              errorMessage={_.get(errors, 'lastName')}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <Input
              value={uiState.newInsuranceCarrierRep.jobTitle}
              label='broker_portal.carrier_reps.edit.Job Title'
              onChange={e => uiState.newInsuranceCarrierRep.merge({jobTitle: e.target.value})}
              errorMessage={_.get(errors, 'jobTitle')}
            />
            <Input
              label='broker_portal.carrier_reps.edit.Email'
              value={uiState.newInsuranceCarrierRep.email}
              onChange={e => uiState.newInsuranceCarrierRep.merge({email: e.target.value})}
              errorMessage={_.get(errors, 'email')}
            />
          </FormLayout.Group>
        </FormLayout>
        <ModalButtons
          onSave={() => uiState.saveInsuranceCarrierRep()}
          onCancel={() => uiState.closeAddRepModal()}
        />
      </Modal>
    );
});

export default AddRepModal;
