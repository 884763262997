import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import ActionLink from './ActionLink';

const ContactUsLink = observer(({label}) => {
  const message = label ? label : t('components.contact_us_link.Contact us');
  return (
    <ActionLink onClick={() => window.Intercom && window.Intercom('show')}>
      {message}
    </ActionLink>
  );
});

export default ContactUsLink;
