import React from 'react';
import {EmployeeNameWithAvatar} from 'components';
import _ from 'lodash';
import { FieldEditor } from './edit/components/field_editor';

const getColumns = (massUpdate, uiState) => {
  const columns = [
    {
      header: 'mass_updates.edit.Employee',
      render: model => <EmployeeNameWithAvatar href={`/employees/${model.data.employee.id}/`} employee={model.data.employee} size='sm'/>,
      width: 3,
      sortBy: 'employeeName',
      sticky: true
    }
  ];

  if (!massUpdate.running) {
    const includedFields = uiState.fields.filter(field => _.includes(massUpdate.includedFields, field.id));

    includedFields.forEach(field => {
      columns.push(
        {
          header: header(field.id),
          width: 3,
          render: model => <FieldEditor field={field.id} viewModel={model}/>
        }
      );
    });
  }

  if (massUpdate.complete) {
    columns.push(
      {
        header: 'mass_updates.edit.Error Message',
        attribute: 'errorMessage',
        width: 3
      }
    );
  }

  function header(field) {
    if (_.startsWith(field, 'cf')) {
      return uiState.customFields.find(customField => customField.id === field.substring(2)).name;
    }

    return `mass_updates.edit.fields.${field}`;
  }

  return columns;
};

export default getColumns;
