import React from 'react';
import {observer} from 'mobx-react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {auth, withState} from 'shared/core';
import {BenefitsPageState} from '../state';
import {BenefitEnrolmentsPage} from './benefit_enrolments';
import {BenefitUpdatesPage} from './updates';
import {BenefitInfoEditFlow} from './benefit_info';
import {MyCoverageContainer} from './my_coverages';
import {CompanyPlansContainer} from './company_coverages';
import {MyEnrolmentInfo} from './my_enrolment_info';

const BenefitsEnabledSwitch = observer(({uiState}) => {
  const {scope} = uiState;

  return (
    <Switch>
      <Route path='/company_plans' render={({match, history}) => <CompanyPlansContainer {...{scope, match, history}}/>}/>
      <Route path='/benefit_enrolments' render={() => <BenefitEnrolmentsPage scope={scope}/>}/>
      <Route path='/updates' render={() => <BenefitUpdatesPage scope={scope}/>}/>
      <Route path='/benefit_info/:id/edit' component={BenefitInfoEditFlow}/>
      <Route path='/my_coverages/:coverageType?' render={({match, history}) => <MyCoverageContainer scope={scope} match={match} history={history}/>} />
      <Route path='/my_enrolment' render={() => <MyEnrolmentInfo scope={scope}/>}/>
      {auth.user.admin
        ? <Redirect exact from='/' to='/benefit_enrolments'/>
        : <Redirect exact from='/' to='/my_coverages'/>
      }
    </Switch>
  );
});

export default withState(BenefitsEnabledSwitch, BenefitsPageState);
