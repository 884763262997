import EmploymentRecord from './EmploymentRecord';
import {EmployeeBenefitEnrollment, EmployeeInsuranceNumber} from 'stores/benefits';
import {action, observable} from 'mobx';
import {types} from 'shared/core';

class ReactivationRecord extends EmploymentRecord {
  @observable employeeBenefitEnrollments = [];
  @observable employeeInsuranceNumbers = [];
  @observable payrollEnabled;

  @action merge(other) {
    super.merge(other, {
      employeeBenefitEnrollments: [EmployeeBenefitEnrollment],
      employeeInsuranceNumbers: [EmployeeInsuranceNumber]
    });

    this._type = types.REACTIVATION_RECORD;
  }
}

export default ReactivationRecord;
