import React from 'react';
import {Button, NumberInput} from 'components';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';


const ActionButton = observer(({model, onSave, saveEnabled}) => {
  if (model.data.isBoolean) {
    return (
      <Button onClick={() => {
          model.editingValue = model.data.booleanTrue ? 0 : 1;
          onSave();
        }}
      >
        <FormattedMessage id={`goals.${model.data.booleanTrue ? 'Mark incomplete' : 'Mark complete'}`}/>
      </Button>
    );
  }

  return (
    <Button onClick={() => onSave()} disabled={!saveEnabled}>
      <FormattedMessage id='Save'/>
    </Button>
  );
});


const UpdateMetric = observer(({model, saveEnabled, onSave}) => {
  return (
    <div className='flex align-items-middle justify-content-end pt2'>
      {!model.data.isBoolean && <React.Fragment>
        <div className='jumbo mr2 right-align'>
          <FormattedMessage
            id='goals.VALUE_RANGE'
            values={{
              startValue: model.data.formattedStartValue,
              targetValue: model.data.formattedTargetValue
            }}
          />
        </div>
        <div className='pr1'>
          <NumberInput
            value={model.editingValue}
            onChange={(e) => model.editingValue = e.target.value}
            connectedLeft={
              <div className='InputGroup'>
                <span className='InputGroup-tag small'>{model.data.symbol}</span>
              </div>
            }
          />
        </div>
      </React.Fragment>}
      <div className='right-align'>
        <ActionButton model={model} onSave={onSave} saveEnabled={saveEnabled}/>
      </div>
    </div>
  );
});

export default UpdateMetric;
