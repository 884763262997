import React from 'react';
import {observer} from 'mobx-react';
import {QuickTip} from 'components';
import {t} from 'shared/core';
import {AlertModal, ModalDangerButtons} from 'components/modals';

const RemoveFromPolicyModal = observer(({uiState}) => {
  return (
    <AlertModal
      size='sm'
      mode='danger'
      header='employees.profile.time_off.Remove Employee From Time Off Policy'
      subHeader='employees.profile.time_off.Are you sure you want to remove this employee from the time off policy?'
      body='employees.profile.time_off.This employee will no longer be able to accrue time off or submit time off requests.'
      isOpen={uiState.removeFromPolicyModalOpen}
      onHide={() => uiState.cancelRemoveFromPolicyModal()}>
      <QuickTip
        trait='error'
        header={t('employees.profile.time_off.Outstanding requests')}
        paragraphs={[t('employees.profile.time_off.All pending requests for this employee will be deleted as a result of removing them from this policy.')]}
      />
      <ModalDangerButtons
        onSave={() => uiState.removeFromPolicy()}
        onCancel={() => uiState.cancelRemoveFromPolicyModal()}
        saveCaption='employees.profile.time_off.Remove from policy'
      />
    </AlertModal>
  );
});

export default RemoveFromPolicyModal;
