import React from 'react';
import {observer} from 'mobx-react';
import {withState, t, endpoints, types} from 'shared/core';
import {Panel, OverviewPanel, Display, Layout, InteractiveTable} from 'components';
import {TrainingSummaryContainerState} from '../state';
import SummaryButtons from './SummaryButtons';
import DeleteProgramModal from './DeleteProgramModal';
import EmployeeSelectorModal from './EmployeeSelectorModal';
import {columnsForProgram} from '../tools';
import EmployeeTrainingStatusSummary from 'stores/training/EmployeeTrainingStatusSummary';
import ReassignTrainingModal from './ReassignTrainingModal';

const LOCALIZATION = {
  emptyState: 'training.summary.No employee training statuses to display',
  interactive: {
    addModel: 'company_settings.documents.+ Add Assignees',
  },
  removeModal: {
    header: 'training.summary.Remove employee from training program?',
    subHeader: 'training.summary.Are you sure you want to remove them from this program? This will revoke their access to this program and delete all their progress.'
  }
};

const TrainingSummaryContainer = observer(({uiState}) => {
  const {trainingProgram} = uiState;

  return (
    <div>
      <Display backPath='/admin/programs/' title={trainingProgram.name} />
      <Layout>
        <Panel.Stack loose>
          <OverviewPanel
            items={[
              { title: 'training.summary.Launch Date', value: trainingProgram.launchedAtView }
            ]}
            total={trainingProgram.totalEmployees}
            completed={trainingProgram.totalEmployeesCompleted}
            tags={uiState.programTags}
          />
          <InteractiveTable
            saveStateToUrl
            title={t('training.summary.Assigned Employees')}
            uiState={uiState}
            initialSorting={{name: 'asc'}}
            columns={columnsForProgram(trainingProgram)}
            localization={LOCALIZATION}
            onAdd={() => uiState.openSelectEmployeesModal()}
            showAddButton={trainingProgram.hasLink('addAssignees')}
            onRemove={status => uiState.removeTrainingStatus(status)}
            onMount={agent => uiState.setAgent(agent)}
            customLinks={status => uiState.customLinksFor(status)}
            showPath='/training/programs/:id'
            proxy={{
              type: 'async',
              modelType: types.TRAINING.EMPLOYEE_TRAINING_STATUS_SUMMARY,
              model: EmployeeTrainingStatusSummary,
              endpoint: endpoints.TRAINING.EMPLOYEE_TRAINING_STATUSES.PROGRAM.with(uiState.trainingProgramId)
            }}
          />
        </Panel.Stack>
        <SummaryButtons uiState={uiState} />
      </Layout>
      <ReassignTrainingModal uiState={uiState} />
      <EmployeeSelectorModal uiState={uiState} />
      <DeleteProgramModal uiState={uiState} />
    </div>
  );
});

export default withState(TrainingSummaryContainer, TrainingSummaryContainerState);
