import React, {useState} from 'react';
import {t} from 'shared/core';
import Input from './Input';
import moment from 'moment';

const TimeInput = ({
  initialValue, onChange, onFormat, lowerBound, ...rest
}) => {
  const [value, setValue] = useState(initialValue);
  const [errored, setErrored] = useState(false);

  const handleFormatting = () => {
    const [result, error] = format(value, lowerBound);
    setValue(result);
    setErrored(error);
    onFormat({value: result, error});
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleFormatting();
    }
  };

  return (
    <Input
      value={value}
      onChange={e => setValue(e.target.value)}
      onBlur={handleFormatting}
      onKeyPress={handleKeyPress}
      onFocus={e => e.target.select()}
      hasError={errored}
      {...rest}
    />
  );
};

function format(raw, lowerBound) {
  if (raw === '' || raw == null) return ['', false];

  let i = raw;
  if (String(parseInt(raw)).length === 3) {
    i = `0${raw}`;
  }

  const m = moment(i, ['h:m a', 'H:m']);
  let result = m.format(t('components.dates.TIMESTAMP_FORMAT'));

  if (result === 'Invalid date') {
    return [`${raw}`, true];
  } else {
    const lowerBoundMoment = moment(lowerBound, ['h:m a', 'H:m']);
    if (lowerBound && m.isBefore(lowerBoundMoment) && !raw.includes('a') && m.hours() + 12 >= lowerBoundMoment.hours()) {
      m.add('12', 'hours');
      result = m.format(t('components.dates.TIMESTAMP_FORMAT'));
    }
    return [result, false];
  }
}

export default TimeInput;
