import React from 'react';
import TrainingList from './training/TrainingList';

const OnboardingTrainingContainer = (props) => {
  return (
    <div className='clearfix'>
      <div className='col col-12 lg-col-10 my2'>
        <TrainingList {...props}/>
      </div>
    </div>
  );
};

export default OnboardingTrainingContainer;
