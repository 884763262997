import _ from 'lodash';
import {observable, computed, action} from 'mobx';
import {MassUpdateEmployee} from 'stores/mass_updates';

class MassUpdateEmployeeViewModel {
  @observable data;
  fields;
  uiState;

  constructor(json, uiState) {
    this.data = new MassUpdateEmployee(json);
    this.uiState = uiState;

    const fields = Object.entries(this.data.fields).reduce((acc, [key, value]) => {
      acc[key] = observable.map(value);
      return acc;
    }, {});
    this.fields = observable.map(fields);

    this._mergeNewValues();
  }

  @action _mergeNewValues() {
    const newValues = this.uiState.massUpdate.newValues.get(this.data.id);
    if (!newValues) return;

    for (const [key, value] of newValues) {
      if (this.fields.get(key)) this.fields.get(key).merge({newValue: value});
    }
  }

  @action set(field, value) {
    this.uiState.updateValue(value, this.data.id, field);
    this._mergeNewValues();
  }

  get(field, bulkEvaluator = _.identity) {
    return this.fields.get(field).merge(
      { value: this._getValue(field, bulkEvaluator) }
    );
  }

  @computed get disabled() {
    return this.uiState.massUpdate.readOnly;
  }

  @computed get errorMessage() {
    return this.data.errorMessage;
  }

  _getValue(attribute, bulkEvaluator) {
    const field = this.fields.get(attribute);

    if (field.has('newValue')) return field.get('newValue');

    const oldValue = field.get('oldValue');

    const bulkValue = this.uiState.massUpdate.bulkEditValues.get(attribute);
    if (this.uiState.massUpdate.bulkEditValues.has(attribute)) return bulkEvaluator(bulkValue, oldValue);

    return oldValue;
  }
}

export default MassUpdateEmployeeViewModel;
