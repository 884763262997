import React from 'react';
import {observer} from 'mobx-react';
import Layout from 'components/layouts/Layout';

const Tabs = observer(({children}) => {
  if (!React.Children.count(children)) return null;
  let hasChildren = false;
  React.Children.forEach(children, c => {
    if (c) {
      hasChildren = true;
    }
  });
  if (!hasChildren) return false;

  return (
    <ul className='Subnav-list list-reset'>
      {React.Children.map(children, c => c && <li>{c}</li>)}
    </ul>
  );
});

const SubNav = observer(({title, className, toolbar, children}) => {
  return (
    <div className={`Subnav Subnav--react ${className}`}>
      <Layout>
        <div className='Subnav-header'>
          <div className='clearfix table'>
            <div className={`col-${toolbar ? '8' : '12'} table-cell align-middle`}>
              {title}
            </div>
            {toolbar && <div className='col-4 table-cell align-middle right-align'>
              {toolbar}
            </div>}
          </div>
        </div>
        <Tabs>{children}</Tabs>
      </Layout>
    </div>
  );
});

export default SubNav;
