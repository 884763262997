import React from 'react';
import {observer} from 'mobx-react';
import {withState} from 'shared/core';
import LoginContainerState from '../state/LoginContainerState';
import _ from 'lodash';
import BrokerLoginSvg from './BrokerLoginSvg';
import LoginForm from './LoginForm';
import LoginLocaleSelector from './LoginLocaleSelector';
import LoginFormHeader from './LoginFormHeader';
import ForgotPasswordLink from './ForgotPasswordLink';
import LoginContainerWrapper from './LoginContainerWrapper';

const BrokerBackground = observer(({uiState}) => {
  return (
    <div className='absolute full-width center bottom-0 wl-broker-branded-container overflow-hidden'>
      <BrokerLoginSvg/>
    </div>
  );
});

const BrokerLoginLogo = observer(({uiState}) => {
  return (
    <div className='center mb3'>
      <div className='wl-big-logo mx-auto'></div>
    </div>
  );
});

const BrokerLoginContainer = observer(({uiState}) => {
  return (
    <div>
      <BrokerBackground uiState={uiState} />
      <LoginLocaleSelector />

      <LoginContainerWrapper>
        <LoginFormHeader message='broker_portal.login.Sign in to Benefits HQ'>
          <BrokerLoginLogo uiState={uiState}/>
        </LoginFormHeader>
        <LoginForm uiState={uiState} />
        <ForgotPasswordLink />
      </LoginContainerWrapper>
    </div>
  );
});

export default withState(BrokerLoginContainer, LoginContainerState);
