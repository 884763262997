import React from 'react';
import {FormattedMessage} from 'react-intl';
import {observer} from 'mobx-react';
import {loader, t} from 'shared/core';
import RoleDetailState from 'company_settings/permissions/state/RoleDetailState';
import {Button, Panel, Display, DemoAccountWarning} from 'components';
import roleStore from 'stores/users/RoleStore';
import GeneralInfo from './details/GeneralInfo';
import FeaturePermissions from './details/FeaturePermissions';
import EmployeeDataPermissions from './details/EmployeeDataPermissions';
import Assignees from './details/Assignees';
import Note from './details/Note';
import RoleName from './RoleName';

const RolesDetailsContainer = observer(({uiState}) => {
  const {role} = uiState;

  return (
    <div>
      <Display backTitle={t('company_settings.subnav.Roles & Permissions')}
               backPath='/permissions'
               title={<RoleName name={role.nameView}/>}/>
      <div className='Container'>
        <div className='clearfix col-12 lg-col-10'>
          <Panel.Stack loose>
            <Note role={role}/>
            <DemoAccountWarning/>
            {uiState.showGeneralInfo && <GeneralInfo uiState={uiState}/>}
            {uiState.showPermissions && !uiState.featurePermissionsLocked && <FeaturePermissions uiState={uiState}/>}
            {uiState.showPermissions && <EmployeeDataPermissions uiState={uiState}/>}
            {uiState.showAssignees && <Assignees uiState={uiState}/>}
          </Panel.Stack>
        </div>
        <Button className='Btn Btn--large'
                onClick={() => uiState.saveRole()}>
                <FormattedMessage id='company_settings.permissions.Save'/>
        </Button>
      </div>
    </div>
  );
});

async function load({match, history}) {
  const uiState = new RoleDetailState(roleStore, history);
  await uiState.load(match.params.id);
  return {uiState};
}

export default loader(RolesDetailsContainer, load);
