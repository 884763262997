import _ from 'lodash';

// see https://github.com/aristus/accent-folding/blob/master/accent-fold.js
// for more accents; supporting only French for now to reduce the bundle size
const ACCENTS = {
  'â': 'a',
  'à': 'a',
  'ç': 'c',
  'é': 'e',
  'ê': 'e',
  'ë': 'e',
  'è': 'e',
  'î': 'i',
  'ï': 'i',
  'ô': 'o',
  'û': 'u',
  'ù': 'u'
};

function accentFold(s) {
  if (!s) return s;

  return _.map(s, c => ACCENTS[c] ? ACCENTS[c] : c).join('');
}

export default accentFold;
