import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import Department from 'stores/departments/Department';

class DepartmentStore extends DomainStore {
  async loadDepartments() {
    await this._compose([
      endpoints.DEPARTMENTS
    ]);

    return this._getAll(types.DEPARTMENT).map(d => new Department(d));
  }

  async postDepartment(model) {
    return await this.post(
      endpoints.DEPARTMENTS,
      types.DEPARTMENT,
      model
    );
  }
}

const singleton = new DepartmentStore();

export default singleton;
