import React from 'react';
import {observer} from 'mobx-react';
import {AvatarList, InteractiveTable} from 'components';
import {endpoints, types, t} from 'shared/core';
import EditLocationModal from 'containers/company_settings/general/components/EditLocationModal';
import Location from 'stores/locations/Location';

const Address = observer(({model, uiState}) => {
  return (
    <div>
      <div>{model.address}</div>
      <div>
        {
          [
            model.city,
            uiState.regionView(model.countryCode, model.regionCode),
            uiState.countryView(model.countryCode)
          ].filter(Boolean).join(', ')
        }
      </div>
      <div>{model.postalCode}</div>
    </div>
  );
});

const Employees = observer(({model, uiState}) => {
  return <AvatarList employees={uiState.employeesForLocation(model)}/>;
});

const COLUMNS = [
  {
    header: 'company_settings.general.Name',
    attribute: 'name',
    width: 4
  },
  {
    header: 'company_settings.general.Address',
    component: Address,
    width: 4
  },
  {
    header: 'company_settings.general.Employees',
    component: Employees,
    width: 3
  }
];

const LOCALIZATION = {
  removeModal: {
    header: 'company_settings.general.Delete Location',
    subHeader: 'company_settings.general.Are you sure you want to delete this location?',
    body: 'company_settings.general.Employees in this location would remain in it until you move them to another location.'
  },
  interactive: {
    addModel: 'company_settings.general.+ Add a Location',
  },
};

const LocationsTable = observer(({uiState}) => {
  return (
    <div className='mb2'>
      <InteractiveTable
        title={t('company_settings.general.Company Offices')}
        uiState={uiState}
        columns={COLUMNS}
        localization={LOCALIZATION}
        onRemove={model => uiState.deleteLocation(model)}
        onAdd={() => uiState.createNewLocation()}
        onEdit={(model) => uiState.openEditLocationModal(model)}
        onMount={agent => uiState.setInteractiveAgent(agent)}
        sortable={true}
        proxy={{
          type: 'async',
          modelType: types.LOCATION,
          model: Location,
          endpoint: endpoints.LOCATIONS.DIRECTORY
        }}
      />
      <EditLocationModal uiState={uiState} />
    </div>
  );
});

export default LocationsTable;
