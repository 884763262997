import React from 'react';
import {observer} from 'mobx-react';
import Subnav from '../../Subnav';
import RevieweesDirectory from './RevieweesDirectory';

const RevieweesPage = observer(({scope}) => {
  return (
    <div>
      <Subnav scope={scope}/>
      <RevieweesDirectory/>
    </div>
  );
});

export default RevieweesPage;
