import React from 'react';
import {observer} from 'mobx-react';
import {Panel, Table} from 'components';
import {t} from 'shared/core';
import MetricRow from './MetricRow';

const COLUMNS = [
  {
    component: MetricRow,
    width: 12
  }
];

const LOCALIZATION = {
  emptyState: 'goals.No metrics to display'
};

const MetricDetails = observer(({uiState}) => {
  const {goal} = uiState;

  return (
    <Panel>
      <Panel.Header title={t('goals.Metrics')}/>
      <Panel.Stack>
        <Table
          uiState={uiState}
          models={goal.sortedMetricViewModels}
          columns={COLUMNS}
          localization={LOCALIZATION}
          showLinks={false}
          showHeaders={false}
        />
      </Panel.Stack>
    </Panel>
  );
});

export default MetricDetails;
