import React from 'react';
import PropTypes from 'prop-types';
import {withState} from 'shared/core';
import Input from '../Input';
import NumberInputState from './NumberInputState';
import {observer} from 'mobx-react';

const NumberInput = observer(({uiState, onChange, errorMessage, ...rest}) => {
  const {numberInputErrorMessage, value} = uiState;

  return (
    <Input value={value} onChange={(e) => uiState.onInputChange(e)} errorMessage={numberInputErrorMessage} {...rest}/>
  );
});

NumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default withState(NumberInput, NumberInputState);
