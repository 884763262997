import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

const EmployeeCount = observer(({count}) => {
  if (!count) {
    return <FormattedMessage id='No Users'/>;
  }

  return <FormattedMessage id='user_count' values={{count}}/>;
});

export default EmployeeCount;
