import React from 'react';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {ActionLink, ObjectSelect2, PanelTooltip, FormLayout, Panel} from 'components';
import {t} from 'shared/core';
import Stage from './Stage';
import DragAndDropContext from 'components/dnd/DragAndDropContext';

const StageList = observer(({stages, uiState}) => {
  const {position} = uiState;

  if (!stages) return null;

  return (
    <div>
      {stages.map((stage) =>
        <Stage
          key={stage.order}
          stage={stage}
          errors={_.get(uiState.errors, `funnelStages[${position.funnelStages.indexOf(stage)}]`)}
          onStageDrag={(dragIndex, hoverIndex) => uiState.moveStage(dragIndex, hoverIndex)}
          onStageDelete={(stage) => uiState.removeStage(stage)} />
      )}
    </div>
  );
});

const Settings = ({uiState}) => {
  const {position} = uiState;
  return (
    <div>
      <Panel.Header title={t('recruiting.edit.steps.Settings')}/>
      <div className='h4 waterloo mb3 max-width-2'>
        <div><FormattedMessage id='recruiting.edit.steps.Choose the format for your interview process and add collaborators.'/></div>
        <div><FormattedMessage id='recruiting.edit.steps.Drag and drop the stages using the handles to specify the order.'/></div>
      </div>
      <FormLayout>
        <div>
          <FormattedMessage id='recruiting.edit.steps.Hiring collaborators'/>
          <PanelTooltip text='recruiting.edit.steps.Collaborators can edit and comment on candidates for this job. They will receive a daily digest of new and updated candidates.'/>
          <ObjectSelect2
            value={position.selectedCollaborators}
            by='clientId'
            items={uiState.availableCollaborators}
            onAdd={value => position.addCollaborator(value)}
            onRemove={value => position.removeCollaborator(value)}
            placeholder='recruiting.edit.steps.Add collaborator' multiple={true} />
          </div>
        <div>
          <div className='medium mb1'>
            <FormattedMessage id='recruiting.edit.steps.Interview Process'/>
          </div>
          <StageList stages={position.stages} uiState={uiState} />
        </div>
        <ActionLink onClick={() => uiState.addStage()}>
          <FormattedMessage id='recruiting.edit.steps.+ Add new stage'/>
        </ActionLink>
        <StageList stages={position.finalStages} uiState={uiState} />
      </FormLayout>
    </div>
  );
};

export default DragAndDropContext(observer(Settings));
