import React from 'react';
import ModalDangerButtons from 'components/modals/ModalDangerButtons';
import AlertModal from 'components/modals/AlertModal';
import {observer} from 'mobx-react';

const RemoveModal = observer(({onHide, isOpen, onConfirm, header, subHeader, body, saveCaption, disabled}) => {
  return (
    <AlertModal mode='danger' size='sm'
                header={header}
                subHeader={subHeader}
                body={body}
                onHide={onHide} isOpen={isOpen}>
      <ModalDangerButtons saveCaption={saveCaption} onCancel={onHide} onSave={onConfirm} disabled={disabled}/>
    </AlertModal>
  );
});

RemoveModal.defaultProps = {
  saveCaption: 'Remove'
};

export default RemoveModal;
