import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {noop} from 'shared/tools';
import {FormattedMessage, injectIntl} from 'react-intl';
import {ActionLink, FilterDropdown, Input, RadioButtonGroup, RadioButton} from 'components';

const SingleInputFilter = observer(({viewModel}) => {
  if (viewModel.isRangeOperator) return null;
  const {value} = viewModel.editingRow;

  return (
    <Input value={value} onChange={(e) => viewModel.updateInput(e.target.value)}/>
  );
});

const RangeInputFilter = observer(({viewModel}) => {
  if (!viewModel.isRangeOperator) return null;
  const {lower_bound, upper_bound} = viewModel.editingRow.range;

  return (
    <div className='clearfix table'>
      <div className='col-5 table-cell align-middle'>
        <Input
          value={lower_bound}
          onChange={(e) => viewModel.updateInputStartRange(e.target.value)}
        />
      </div>
      <div className='col-1 table-cell align-middle center px2'>
        <FormattedMessage id='reports.to'/>
      </div>
      <div className='col-5 table-cell align-middle'>
        <Input
          value={upper_bound}
          onChange={(e) => viewModel.updateInputEndRange(e.target.value)}
        />
      </div>
    </div>
  );
});

const InputFilterRow = injectIntl(observer(({uiState, viewModel, intl}) => {
  const { editingRow } = viewModel;

  return (
    <div className='py1'>
      <div className='table-cell align-top pr1'>
        <FilterDropdown isOpen={false}
                        onOpen={noop}
                        onCancel={noop}
                        selected={[intl.formatMessage({id: `reports.${viewModel.category}`})]}
                        disabled={true}/>
      </div>
      <div className='table-cell align-top pr1'>
        <FilterDropdown isOpen={viewModel.operatorOpen}
                        onOpen={() => { uiState.closeFilters(); viewModel.openOperator(); }}
                        onCancel={() => viewModel.closeOperator()}
                        selected={[intl.formatMessage({id: `reports.${viewModel.operatorView(editingRow.operator)}`})]}
                        onApply={() => { viewModel.updateRow(); uiState.applyFilters(); }}>
          <RadioButtonGroup name={`${viewModel.category}_operator`}
                            onChange={(name, value) => viewModel.editOperator(value)}
                            selectedValue={editingRow.operator}>
            {viewModel.operators.map(o =>
              <RadioButton className='block pb1' key={o} value={o}><FormattedMessage id={`reports.${viewModel.operatorView(o)}`}/></RadioButton>
            )}
          </RadioButtonGroup>
        </FilterDropdown>
      </div>
      <div className='table-cell align-top pr1'>
        <FilterDropdown isOpen={viewModel.inputOpen}
                        onOpen={() => { uiState.closeFilters(); viewModel.openInput(); }}
                        onCancel={() => viewModel.closeInput()}
                        selected={viewModel.isRangeOperator ? viewModel.inputRangeView : viewModel.inputView}
                        onApply={() => { viewModel.updateRow(); uiState.applyFilters(); }}
                        placeholder={''}
                        separator={<FormattedMessage id='reports.to' />}>
          <div className='mb1'>
            <SingleInputFilter viewModel={viewModel}/>
            <RangeInputFilter viewModel={viewModel}/>
          </div>
        </FilterDropdown>
      </div>
      <div className='table-cell align-middle pl1'>
        <ActionLink onClick={() => uiState.toggleFilterRow(viewModel.category, false)} className='meadow'>
          <i className='material-icons h3 align-middle'>{'close'}</i>
        </ActionLink>
      </div>
    </div>
  );
}));

export default InputFilterRow;
