import {action, observable} from 'mobx';
import _ from 'lodash';
import FilterRow from './FilterRow';

class MultiSelectFilterRow extends FilterRow {
  @observable values = [];

  @action add(value) {
    this.values.push(value);
  }

  @action remove(value) {
    _.pull(this.values, value);
  }

  toJS(options = {}) {
    if (_.isEmpty(this.values) && !options.showEmptyValues) return [];

    const json = super.toJS();
    return [json];
  }
}

export default MultiSelectFilterRow;
