import React from 'react';
import {FormattedMessage} from 'react-intl';

const QuestionEmptyState = () => {
  return (
    <div className='center jumbo rounded h5 py2 px1 Table-emptyState'>
      <FormattedMessage id={'surveys.view.There are no submitted responses for this question yet'}/>
    </div>
  );
};

export default QuestionEmptyState;
