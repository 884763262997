import {observable, action} from 'mobx';
import {DomainStore} from 'shared/store';
import {t, endpoints, types} from 'shared/core';
import {successAlert} from 'shared/tools';
import {PerformanceReviewTemplate, PerformanceReviewQuestion} from 'stores/performance_reviews';
import _ from 'lodash';

class EditTemplateState {
  store = new DomainStore();
  history;
  match;

  @observable reviewTemplate;
  @observable editingQuestion;
  @observable questionModalOpen = false;
  @observable titleModalOpen = false;
  @observable errors = {};

  receiveProps({history, match}) {
    this.history = history;
    this.match = match;
  }

  @action async load() {
    await this.store._compose([
      endpoints.REVIEW_TEMPLATE.with(this.match.params.id)
    ]);

    this.reviewTemplate = new PerformanceReviewTemplate(
      this.store._getSingle(types.PERFORMANCE_REVIEWS.TEMPLATE)
    );
  }

  @action openQuestionModal() {
    this.editingQuestion = this.generateNewQuestion();
    this.questionModalOpen = true;
  }

  @action openTitleModal() {
    this.editingQuestion = this.generateNewTitle();
    this.titleModalOpen = true;
  }

  @action editQuestion(question) {
    this.editingQuestion = new PerformanceReviewQuestion(question);
    if (this.editingQuestion.questionType === 'description') {
      this.titleModalOpen = true;
    } else {
      this.questionModalOpen = true;
    }
  }

  @action updateQuestion() {
    if (!this.validateQuestion()) return;

    const question = _.find(this.reviewTemplate.questions, { order: this.editingQuestion.order });

    if (question) {
      question.update(this.editingQuestion);
    } else {
      this.reviewTemplate.questions.push(this.editingQuestion);
    }

    this.closeQuestionModal();
    this.closeTitleModal();
  }

  @action closeQuestionModal() {
    this.questionModalOpen = false;
  }

  @action closeTitleModal() {
    this.titleModalOpen = false;
  }

  @action async updateTemplate() {
    const {model, errors} = await this.store.patch(this.reviewTemplate);
    this.errors = errors;

    if (model) {
      successAlert(t('performance.templates.Question set updated.'));
      this.history.push('/question_sets');
    }
  }

  @action addNewQuestion(order) {
    const reviewQuestion = new PerformanceReviewQuestion(
      {
        name: '',
        order: order,
        questionType: 'text_field'
      }
    );
    this.reviewTemplate.questions.push(reviewQuestion);
  }

  @action removeQuestion(question) {
    this.reviewTemplate.questions.remove(question);
  }

  @action validateQuestion() {
    this.errors = this.editingQuestion.validate();

    return _.isEmpty(this.errors);
  }

  generateNewQuestion() {
    return new PerformanceReviewQuestion({
      name: '',
      description: '',
      order: this.reviewTemplate.questions.length,
      questionType: 'text_area'
    });
  }

  generateNewTitle() {
    return new PerformanceReviewQuestion({
      name: '',
      description: '',
      order: this.reviewTemplate.questions.length,
      questionType: 'description'
    });
  }
}

export default EditTemplateState;
