import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import _ from 'lodash';

const SearchBox = observer(({filter, filterUpdated}) => {
  return (
    <div className='dataTables_filter'>
      <input
        type='search'
        className='full-width'
        defaultValue={filter ? filter.get('search') : ''}
        placeholder={t('components.tables.Search')}
        onChange={_.debounce(e => filterUpdated({search: e.target.value}), 300)}
      />
    </div>
  );
});

export default SearchBox;
