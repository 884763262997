import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {redirect} from 'shared/tools';
import {urls} from 'shared/core';
import {ActionLink} from 'components';

const HiresPastStart = ({pastStartCount}) => {
  if (pastStartCount === 0) return null;

  return (
    <span>
      <FormattedMessage id='hr_dashboard.insights.new_hire.WARNING' 
        values={{
          num: pastStartCount,
          link: <ActionLink scarlet href={urls.EMPLOYEE_DIRECTORY.LATE} onClick={() => redirect(urls.EMPLOYEE_DIRECTORY.LATE)}>
                  <FormattedMessage id='hr_dashboard.insights.new_hire.WARNING_LINK' values={{num: pastStartCount}}/>
                </ActionLink>
        }}
      />
    </span>
  );
};

const NewHireText = observer(({newHireSummary}) => {
  const {newHireCount, pastStartCount} = newHireSummary;

  if (newHireCount === 0) return null;

  return (
    <li>
      <FormattedMessage id='hr_dashboard.insights.new_hire.MESSAGE'
        values={{
          num: pastStartCount,
          link: <ActionLink href={urls.EMPLOYEE_DIRECTORY.NEW} onClick={() => redirect(urls.EMPLOYEE_DIRECTORY.NEW)}>
                  <FormattedMessage id='hr_dashboard.insights.new_hire.MESSAGE_LINK' values={{num: newHireCount}}/>
                </ActionLink>
        }}
      />
      <HiresPastStart pastStartCount={pastStartCount}/>
    </li>
  );
});

export default NewHireText;
