import {DomainObject} from 'shared/store';
import {observable, computed} from 'mobx';
import {fullName} from 'shared/tools';

class InsuranceCarrierRep extends DomainObject {
  @observable firstName;
  @observable lastName;
  @observable jobTitle;
  @observable email;
  @observable carrier;

  @computed get name() {
    return fullName(this);
  }
}

export default InsuranceCarrierRep;
