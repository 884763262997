import React from 'react';
import {observer} from 'mobx-react';
import {CoverageFields} from 'components/benefits';
import _ from 'lodash';

const Coverage = observer(({uiState}) => {
  const {benefitInfo, errors} = uiState;

  return <CoverageFields benefitInfo={benefitInfo} errors={_.get(errors, 'updatedBenefitInfo') || {}}/>;
});

export default Coverage;
