import React from 'react';
import {observer} from 'mobx-react';
import {onboarding} from 'components/tasks';
import {t} from 'shared/core';
import {Panel, FormError} from 'components';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';
import {TaskTemplateSelector} from 'components/tasks';

const Title = observer(() => {
  return (
    <FormattedMessage
      id='employees.hire.tasks.ONBOARDING_TASKS_HEADER'
      values={{
        optional: <span className='waterloo'>{t('employees.hire.tasks.(Optional)')}</span>
      }}
    />
  );
});

const Errors = observer(({uiState}) => {
  const {employee, errors} = uiState;
  if (!employee.onboardingTasks) return null;

  return employee.onboardingTasks.map((t, i) =>
    <FormError key={i} message={_.get(errors, `onboardingTaskTemplates[${i}].base`)}/>
  );
});

const Tasks = observer(({uiState}) => {
  return (
    <div>
      <Panel.Header
        title={<Title/>}
        description={t('employees.hire.tasks.onboarding_tasks_desc')}
      />
      <TaskTemplateSelector
        config={onboarding}
        onChange={(tasks) => uiState.setSelectedTasks(tasks)}
        employeeId={uiState.employee.id}
        hasManager={!!uiState.employee.managerId}
      />
      <Errors uiState={uiState}/>
    </div>
  );
});

export default Tasks;
