import React from 'react';
import {observer} from 'mobx-react';
import {DropdownSelect} from 'components';

const DownloadGraph = observer(({name, reportRef}) => {
  const DOWNLOAD_OPTIONS = [
    {
      name: 'PNG',
      extension: '.png',
      fileType: 'image/png'
    },
    {
      name: 'JPEG',
      extension: '.jpeg',
      fileType: 'image/jpeg'
    },
    {
      name: 'PDF',
      extension: '.pdf',
      fileType: 'application/pdf'
    }
  ];

  const handleDownloadClick = (fileType) => {
    if (reportRef.current && reportRef.current.chart) {
      reportRef.current.chart.exportChartLocal(
        {
          type: fileType,
          filename: name
        },
        {
          title: name,
          chart: {
            spacing: [20, 20, 20, 20]
          }
        }
      );
    }
  };

  return (
    <div>
      {[...DOWNLOAD_OPTIONS].map(option => (
        <DropdownSelect.Option
          key={option.fileType}
          onClick={() => handleDownloadClick(option.fileType)}
          text={`${option.name} (${option.extension})`}
        />
      ))}
    </div>
  );
});

export default DownloadGraph;
