import React from 'react';
import {observer} from 'mobx-react';
import PdfPreview from 'components/pdf/preview/components/PdfPreview';
import {AttachedDocumentDownload} from 'components';
import FillablePdfPreviewState from 'components/pdf/fillable/state/FillablePdfPreviewState';
import {loader} from 'shared/core';
import AnnotatableInputFactory from 'components/pdf/fillable/tools/AnnotatableInputFactory';
import AnnotationHelper from './AnnotationHelper';
/*global document*/

@observer class FillablePdfPreview extends React.Component {
  componentDidMount() {
    this.props.uiState.registerCallback('onEmployeeSignatureUpdated', this.props.onEmployeeSignatureUpdated);
  }

  onPageChanged = (pageNumber) => {
    const {uiState} = this.props;

    uiState.changePage(pageNumber);
  }

  onPdfLoaded = ({viewerElement, selectPage}) => {
    this.viewerElement = viewerElement;
    this.props.uiState.onLoaded(selectPage);
  };

  render() {
    const {uiState} = this.props;
    const {currentPageAnnotations, pageNumber, companyDocument} = uiState;

    return (
      <div className='relative'>
        {uiState.isLoaded && <div className='relative' style={{top: '49px'}}>
          <AnnotationHelper uiState={uiState}/>
          {currentPageAnnotations.map((viewModel) =>
            <AnnotatableInputFactory
              uiState={uiState}
              viewModel={viewModel}
              key={viewModel.annotation.annotationArgs.id}
              onFocus={(viewModel) => uiState.setAnnotation(viewModel)}
              onEnter={() => uiState.goToNextAnnotation()}/>
          )}
        </div>}
        <PdfPreview
          pdfUrl={uiState.pdfUrl}
          pageNumber={pageNumber}
          callbacks={{
            onLoaded: this.onPdfLoaded,
            onPageChanging: this.onPageChanging,
            onPageChanged: this.onPageChanged,
          }}
          renderOnPasswordError={() => <AttachedDocumentDownload className='pl1 py2' doc={companyDocument.previewFile} name={companyDocument.name}/>}
        />
      </div>
    );
  }
}

async function load({pdfUrl, annotations, pageNumber, companyDocument}) {
  const uiState = new FillablePdfPreviewState(pdfUrl, annotations, pageNumber, companyDocument);
  return {uiState};
}

function shouldReload(oldProps, nextProps) {
  return nextProps.pdfUrl !== oldProps.pdfUrl;
}

export default loader(FillablePdfPreview, load, shouldReload);
