import React from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {Modal, ModalButtons} from 'components/modals';
import {DatePicker, Input, Select2} from 'components';

const FormRow = observer(({name, className, children}) => {
  return (
    <div className={`${className ? className : 'my3'}`}>
      {name && <div className='h4 medium mb1'>
        <FormattedMessage id={name}/>
      </div>}
      {children}
    </div>
  );
});

const EditBasicInfoModal = injectIntl(observer(({uiState, intl}) => {
  const {editEmployee, errors} = uiState;

  return (
    <Modal isOpen={uiState.basicInfoModalOpen} onHide={() => uiState.resetModals()} size='md'>
      <div className='h2 mb3'><FormattedMessage id='employees.profile.personal.Edit Basic Information'/></div>
      <form>
        <FormRow>
          <Input
            value={editEmployee.firstName}
            label='employees.profile.personal.First Name'
            errorMessage={errors.firstName}
            onChange={(e) => editEmployee.merge({firstName: e.target.value})}
          />
        </FormRow>
        <FormRow name='employees.profile.personal.Preferred Name'>
          <Input value={editEmployee.preferredName}
                 errorMessage={errors.preferredName}
                 onChange={(e) => editEmployee.merge({preferredName: e.target.value})}
          />
        </FormRow>
        <FormRow name='employees.profile.personal.Last Name'>
          <Input value={editEmployee.lastName}
                 errorMessage={errors.lastName}
                 onChange={(e) => editEmployee.merge({lastName: e.target.value})}
          />
        </FormRow>
        <FormRow name='employees.profile.personal.Preferred Last Name'>
          <Input value={editEmployee.preferredLastName}
                 errorMessage={errors.preferredLastName}
                 onChange={(e) => editEmployee.merge({preferredLastName: e.target.value})}
          />
        </FormRow>
        <FormRow name='employees.profile.personal.Gender'>
          <Select2 value={editEmployee.gender}
                   onChange={(e) => editEmployee.merge({gender: e.target.value})}>
            <option value='female'>{intl.formatMessage({id: 'models.employee.gender.female'})}</option>
            <option value='male'>{intl.formatMessage({id: 'models.employee.gender.male'})}</option>
            <option value='other'>{intl.formatMessage({id: 'models.employee.gender.other'})}</option>
            <option value='prefer_not_to_say'>{intl.formatMessage({id: 'models.employee.gender.prefer_not_to_say'})}</option>
          </Select2>
        </FormRow>
        <FormRow name='employees.profile.personal.Birthdate'>
          <DatePicker value={editEmployee.birthDate}
                      errorMessage={errors.birthDate}
                      options={{startView: 2}}
                      onChange={(date) => editEmployee.merge({birthDate: date})}
                      className='full-width'/>
        </FormRow>
        <ModalButtons onSave={() => uiState.saveBasicInfo()} onCancel={() => uiState.resetModals()} />
      </form>
    </Modal>
  );
}));

export default EditBasicInfoModal;
