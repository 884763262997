import React from 'react';
import {observer} from 'mobx-react';
import {withState, t, auth, warnUnsavedChanges} from 'shared/core';
import {OfferEditorState} from '../state';
import { UploadFiles, Display, Layout, Panel, Select2, Spinner, Button, OfferPreviewModal, AutosaveStatus } from 'components';
import OfferDetailsForm from './OfferDetailsForm';
import {FormattedMessage} from 'react-intl';
import SendOfferModal from './SendOfferModal';

const OfferEditor = observer(({uiState}) => {
  const {templatesWithDeleted, offer, company, fetchingTemplateDetails} = uiState;

  return (
    <div>
      <Display
        title={offer && !offer.isNew ? t('offers.editor.Edit Offer') : t('offers.editor.Send New Offer')}
        backPath='/'
      />
      <Layout>
        <Panel.Stack loose>
          <Panel>
            <Panel.Header title={t('offers.editor.Template')}/>
            <Select2
              value={offer && offer.offerTemplate ? offer.offerTemplate.id : null}
              placeholder='offers.editor.Select Offer Template'
              onChange={e => uiState.templateSelected(e.target.value)}
              onSelecting={e => uiState.templateSelecting(e)}
            >
              {templatesWithDeleted.map((template) =>
                <option key={template.id} value={template.id}>{template.name}</option>
              )}
              {auth.hasAccess('::MANAGE_OFFER_TEMPLATES') &&
                <option value={'createNewTemplate'}>{t('offers.editor.+ Create New Template')}</option>}
            </Select2>
          </Panel>
          {offer && offer.offerTemplate &&
            <Panel.Stack>
              <Panel>
                {fetchingTemplateDetails ?
                  <Spinner/> :
                  <OfferDetailsForm uiState={uiState}/>}
              </Panel>
              {!fetchingTemplateDetails &&
                <Panel>
                <Panel.Header title={t('offers.editor.Email Attachments')}/>
                <div className='waterloo pb1'>
                  <FormattedMessage
                    id='offers.editor.Upload files that will be attached to this offer.'/>
                </div>
                <UploadFiles
                  defaultFiles={offer.attachments}
                  onChange={files => uiState.updateEditorAttachments(files)}
                  modelType='offers/attachment'
                />
              </Panel>}
            </Panel.Stack>}
        </Panel.Stack>
        {offer && offer.offerTemplate && <div className='mt1 right'>
          <AutosaveStatus autosaver={offer.autosaver} className='mr2' />
          <Button trait='default' className='mr1' onClick={() => uiState.openOfferPreviewModal()}>
            <FormattedMessage id='offers.editor.Preview Offer'/>
          </Button>
          <Button onClick={() => uiState.openSendOfferModal()}>
            <FormattedMessage id='offers.editor.Send Offer'/>
          </Button>
          <OfferPreviewModal
            offer={offer}
            company={company}
            isOpen={uiState.showOfferPreviewModal}
            onHide={() => uiState.closeOfferPreviewModal()}
          />
        </div>}
      </Layout>
      <SendOfferModal uiState={uiState}/>
    </div>
  );
});

export default warnUnsavedChanges(withState(OfferEditor, OfferEditorState));
