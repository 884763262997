import {observable, computed} from 'mobx';
import {DomainObject} from 'shared/store';
import {t} from 'shared/core';

export function defaultFunnelStages() {
  return [
    { order: 0, name: t('models.funnel_stage.default_names.Sourced'), stageType: 'sourced' },
    { order: 1, name: t('models.funnel_stage.default_names.Applied'), stageType: 'applied' },
    { order: 2, name: t('models.funnel_stage.default_names.Screening'), stageType: 'custom' },
    { order: 3, name: t('models.funnel_stage.default_names.Interview (1)'), stageType: 'custom' },
    { order: 4, name: t('models.funnel_stage.default_names.Interview (2)'), stageType: 'custom' },
    { order: 5, name: t('models.funnel_stage.default_names.Reference Check'), stageType: 'custom' },
    { order: 6, name: t('models.funnel_stage.default_names.Offer'), stageType: 'offer' },
    { order: 7, name: t('models.funnel_stage.default_names.Hire'), stageType: 'hired' }
  ];
}

export class FunnelStage extends DomainObject {
  id;
  @observable name;
  @observable order;
  @observable _destroy;
  @observable stageType = 'custom';
  @observable activeCandidatesCount;
  @observable disqualifiedCandidatesCount;
  @observable stateChanged = '';

  @computed get isRequired() {
    return this.stageType !== 'custom';
  }
}
