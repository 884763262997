import {DomainObject} from 'shared/store';
import {observable, computed} from 'mobx';
import _ from 'lodash';

class GroupBenefitCoverage extends DomainObject {
  @observable coverageType;
  @observable descriptions;
  @observable subCategories;
  @observable title;
  @observable tooltip;

  @computed get isOtherCoverage() {
    return this.coverageType === 'other';
  }

  @computed get subCategoriesWithIndex() {
    return _.map(this.subCategories, (category, i) => {
      return {index: i + 1, ...category};
    });
  }
}
export default GroupBenefitCoverage;
