import {computed, observable} from 'mobx';
import setupAutosave from 'shared/tools/autosave';

class ModelAutosaver {
  @observable model;

  constructor(model) {
    this.model = model;
  }

  setup(callback) {
    this.autosaver = setupAutosave(this.model, callback);
  }

   autosave() {
    this.autosaver.autosave();
  }

   autosaveImmediately() {
    this.autosaver.autosaveImmediately();
  }

  clearAutosaver() {
    delete this.model.autosaver;
  }

  async flush() {
    await this.autosaver.autosave.flush();
  }

  @computed get status() {
    return this.autosaver.status;
  }
}

export default function setupAutosaveModel(model, callback) {
  if (model.autosaver) {
    throw new Error('Model already has an autosaver');
  }

  const modelAutosaver = new ModelAutosaver(model);
  modelAutosaver.setup(callback);

  Object.defineProperty(
    model,
    'autosaver',
    {
      configurable: true,
      get: () => modelAutosaver
    }
  );
}
