import React from 'react';
import _ from 'lodash';
import {action, observable} from 'mobx';
import Answer from 'stores/recruiting/Answer';
import CandidateFormState from './CandidateFormState';

class PublicCandidateFormState extends CandidateFormState {
  @observable recaptchaRef;

  @action async load() {
    this.position = await this.store.loadPublicPosition(
      this.match.params.slug, this.match.params.id
    );

    for (const question of this.position.questions) {
      this.candidate.answers.push(new Answer({question: question}));
    }

    this.recaptchaRef = React.createRef();    
  }

  @action executeRecaptcha() {
    this.recaptchaRef.current.execute();
  }

  @action submitPublicApplication(token) {
    if(token) {
      this.candidate.merge({recaptchaResponse: token});
      this.submitApplication();  
    }
    this.recaptchaRef.current.reset();
  }  
}

export default PublicCandidateFormState;
