import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {t} from 'shared/core';

const SideNavigationItem = withRouter(({name, path, disabled, current, match, translateName}) => {
  const displayName = translateName ? t(name) : name;

  return (
    <li>
      {disabled ? 
        <span>{displayName}</span> :
        <NavLink 
          to={`${match.url}/${path}`} 
          className={current ? 'current' : 'complete'}
          activeClassName='active'>
          {displayName}
        </NavLink>}
    </li>
  );
});

const SideNavigation = ({steps, currentStep}) => {
  return (
    <ul className='Navigation-sidebar'>
      {steps.all().map(step =>
        <SideNavigationItem 
          disabled={step.index > currentStep}
          current={step.index === currentStep}
          name={step.name} 
          path={step.location}
          translateName={step.translateName}
          key={step.location}
        />
      )}
    </ul>
  );
};

SideNavigationItem.defaultProps = {
  translateName: true
};

export default SideNavigation;
