import React, {useRef} from 'react';
import {observer} from 'mobx-react';
import {Layout, Panel, DropdownSelect, InteractiveContext, FancyFilter, CheckboxWithLabel, Spinner} from 'components';
import {FilterRow} from 'components/tables/interactive/components/FilterRow';
import {withState, t, endpoints} from 'shared/core';
import {PerformanceTrendsState} from '../state';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';
import DownloadGraph from './DownloadGraph';

const EmptyState = observer(() => {
  return (
    <div className='center jumbo rounded h5 py2 px1 Table-emptyState'>
      <FormattedMessage id='performance_reviews.performance_trends.There are no submitted answers to display'/>
    </div>
  );
});

const GraphView = observer(({uiState, isUpdating, reportRef}) => {
  const {Highcharts, HighchartsReact, result, trendsOptions} = uiState;

  if (_.isEmpty(result.trend.cycles)) return <EmptyState/>;
  if (isUpdating) return <Spinner/>;

  return <HighchartsReact ref={reportRef} highcharts={Highcharts} options={trendsOptions}/>;
});

const AverageSelectors = observer(({uiState}) => {
  const {groupByQuestions, groupByEmployees, result} = uiState;

  if (_.isEmpty(result.trend.cycles)) return null;

  return (
    <div className='flex'>
      <CheckboxWithLabel
        className='mr2'
        label='performance_reviews.performance_trends.Group by questions'
        checked={groupByQuestions}
        onChange={(e) => uiState.toggleGroupByQuestions(e.checked)}
      />
      <CheckboxWithLabel
        label='performance_reviews.performance_trends.Group by employees'
        checked={groupByEmployees}
        onChange={(e) => uiState.toggleGroupByEmployees(e.checked)}
      />
    </div>
  );
});

const PerformanceTrendsWithState = observer(({uiState}) => {
  const {groupByEmployees, groupByQuestions, employees, questions} = uiState;
  const reportRef = useRef(null);

  return (
    <Layout>
      <Panel>
        <Panel.Header title={t('performance_reviews.Performance Trends')}/>
        <InteractiveContext
          onRefresh={args => uiState.updateResults(args)}
          uiState={uiState}
          proxy={{
            type: 'async',
            endpoint: endpoints.PERFORMANCE_REVIEWS.CYCLES.TRENDS.with(groupByEmployees, groupByQuestions)
          }}
          fancyFilterModels={{review_employee: employees, review_question: questions}}
          saveStateToUrl
          fancyFiltersEnabled
        >
          <FilterRow>
            <FancyFilter filters={['review_cycle', 'review_employee', 'location', 'department', 'employment_type', 'review_question']}/>
            <div className='ml2'>
              <DropdownSelect selected={t('reports.Export')}>
                <DownloadGraph
                  name={t('performance_reviews.Performance Trends')}
                  reportRef={reportRef}
                />
              </DropdownSelect>
            </div>
          </FilterRow>
          <GraphView uiState={uiState} reportRef={reportRef}/>
          <AverageSelectors uiState={uiState}/>
        </InteractiveContext>
      </Panel>
    </Layout>
  );
});

export default withState(PerformanceTrendsWithState, PerformanceTrendsState);
