/*global window*/

export const successAlert = (message) => {
  if (window && window.collage && window.collage.alertBox) {
    window.collage.alertBox('success', message);
  }
};

export const errorAlert = (message) => {
  if (window && window.collage && window.collage.alertBox) {
    window.collage.alertBox('error', message);
  }
};
