import React from 'react';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import {Panel} from 'components';
import {t} from 'shared/core';

const CalendarSettingsPanel = observer(({uiState}) => {
  return (
    <Panel>
      <Panel.Header title={t('company_settings.calendars.Settings')}/>
      <div className='jumbo'>
        <FormattedMessage id='company_settings.calendars.PTO_TYPES_NOTICE'
          values={{
            link: <a href='/company_settings/permissions' target='_blank'>
                    <FormattedMessage id="company_settings.calendars.Roles & Permissions"/>
                  </a>
          }}
        />
      </div>
    </Panel>
  );
});

export default CalendarSettingsPanel;
