const UPDATE_SUMMARY_TYPES = {
  ADDED_SPOUSE: 'ADDED_SPOUSE',
  REMOVED_SPOUSE: 'REMOVED_SPOUSE',
  UPDATED_SPOUSE: 'UPDATED_SPOUSE',
  ADDED_DEPENDANT: 'ADDED_DEPENDANT',
  REMOVED_DEPENDANT: 'REMOVED_DEPENDANT',
  UPDATED_DEPENDANT: 'UPDATED_DEPENDANT',
  UPDATED_COVERAGE: 'UPDATED_COVERAGE'
};

export default UPDATE_SUMMARY_TYPES;
