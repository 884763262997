import React from 'react';
import {observer} from 'mobx-react';
import MultiSelectFilterRow from './MultiSelectFilterRow';
import DateFilterRow from './DateFilterRow';
import InputFilterRow from './InputFilterRow';

const FilterRow = observer(({uiState, viewModel}) => {
  switch (viewModel.category) {
    case 'Department':
    case 'Location':
    case 'Employment Type':
    case 'Gender':
    case 'Termination Type':
    case 'Manager':
    case 'Employee':
    case 'Employment Status':
    case 'Terminated Employee':
    case 'Pay Rate Unit':
    case 'Time Off Type':
    case 'Candidate Status':
    case 'Position':
    case 'Position Status':
    case 'Cycle':
    case 'Review':
    case 'Reviewer':
    case 'Leader':
    case 'Survey':
    case 'Anonymous Survey':
    case 'Program':
    case 'Created By':
    case 'Project':
      return <MultiSelectFilterRow uiState={uiState} viewModel={viewModel}/>;
    case 'Period Date':
    case 'Start Date':
    case 'Last Day of Work':
    case 'Last Day of Benefits':
    case 'Termination Date':
    case 'Due Date':
    case 'Application Date':
      return <DateFilterRow uiState={uiState} viewModel={viewModel}/>;
    case 'Pay Rate':
      return <InputFilterRow uiState={uiState} viewModel={viewModel}/>;
    default:
      throw new Error(`Filter category ${viewModel.category} not supported.`);
  }
});

export default FilterRow;
