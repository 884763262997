import React from 'react';
import {withState} from 'shared/core';
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import CompanyOnboardState from '../state/CompanyOnboardState';
import CompanyOnboardContainer from './CompanyOnboardContainer';
import AddEmployeesContainer from './AddEmployeesContainer';

const CompanyOnboardPage = observer(({uiState}) => {
  return (
    <Router basename='/company_onboard'>
      <Switch>
        <Route path='/dashboard' render={() => <CompanyOnboardContainer uiState={uiState}/>} />
        <Route path='/employees' render={() => <AddEmployeesContainer uiState={uiState}/>} />
        <Redirect exact from='/' to='/dashboard' />
      </Switch>
    </Router>
  );
});

export default withState(CompanyOnboardPage, CompanyOnboardState);
