import {action, computed, observable} from 'mobx';
import _ from 'lodash';
import CandidateViewModel from './CandidateViewModel';
import CandidateDetailsState from './CandidateDetailsState';
import Candidate from 'stores/recruiting/Candidate';
import {successAlert, redirect} from 'shared/tools';
import {api, endpoints, t, types, urls} from 'shared/core';
import moment from 'moment';

class HiringFunnelState {
  store;
  history;
  requestedCandidateId;

  @observable position;
  @observable positions;
  @observable candidates = [];
  @observable detailsState;
  @observable loadingCandidate = false;
  @observable candidateIsBeingMoved = false;
  @observable deletePosition = false;
  @observable emailTemplates = [];
  @observable interviewGuides = [];
  @observable selectFirstPending = false;
  @observable interactiveContextState = {};
  @observable listUpdatePending = false;
  @observable fullCandidateCache;
  @observable agent;
  @observable sendDisqualifiedEmailModalOpen = false;
  @observable taggableUsers = [];
  @observable sendBulkDisqualifiedEmail = true;
  @observable allCandidatesSelected = false;
  @observable newStageId;
  @observable candidatesLastFetchedAt;
  @observable navigatedToCandidate = null;
  @observable match;

  constructor(recruitingStore, history) {
    this.store = recruitingStore;
    this.history = history;
    this.detailsState = new CandidateDetailsState(this);
  }

  @action setInteractiveAgent(agent) {
    this.agent = agent;
  }

  @action async load(positionId, candidateId) {
    this.position = await this.store.loadSingle(positionId);
    this.taggableUsers = await this.store.loadTaggableUsers(positionId);
    this.emailTemplates = await this.store.loadEmailTemplates();
    this.interviewGuides = await this.store.loadInterviewGuides();
    this.navigatedToCandidate = null;
    await this.store.loadAll();
    this.positions = this.store.getAll();

    if (candidateId) {
      await this.loadCandidate(candidateId);
    } else {
      await this.showActiveCandidates();
    }
  }

  @action async goToEmployee(employeeId) {
    return redirect(`/employees/${employeeId}`);
  }

  @action async loadCandidate(candidateId) {
    const fullCandidate = await this.store.loadFullCandidate(this.position.id, candidateId);
    this.fullCandidateCache = fullCandidate;
    this.navigatedToCandidate = new CandidateViewModel(new Candidate(fullCandidate));
    await this.showActiveCandidates(false);
    await this.selectCandidate(this.navigatedToCandidate, false);
  }

  @action async selectCandidateById(id) {
    const candidateToSelect = _.find(this.candidates, (candidateViewModel) => candidateViewModel.candidate.id === id);
    if (candidateToSelect) {
      await this.selectCandidate(candidateToSelect);
    }
  }

  @action openSendDisqualifiedEmailModal() {
    this.sendDisqualifiedEmailModalOpen = true;
  }

  @action closeSendDisqualifiedEmailModal() {
    this.sendDisqualifiedEmailModalOpen = false;
  }

  @action async showActiveCandidates(selectFirst = true) {
    if (this.candidateIsBeingMoved) return null;

    this.selectFirstPending = selectFirst;
  }

  @action createCandidateViewModel(obj) {
    return new CandidateViewModel(new Candidate(obj));
  }

  @action async updateCandidateList(interactiveContextState) {
    this.candidates = interactiveContextState.data;
    this.interactiveContextState = interactiveContextState;
    this.candidatesLastFetchedAt = moment();
    this.listUpdatePending = false;
    this.allCandidatesSelected = false;
    if (this.selectFirstPending) {
      await this.selectCandidate(_.head(this.candidates));
    } else if (this.fullCandidateCache) {
      const viewModel = _.find(this.candidates, viewModel => viewModel.candidate.id === this.fullCandidateCache.id)
        || this.createCandidateViewModel(this.fullCandidateCache);

      viewModel.candidate.update(this.fullCandidateCache);
      await this.selectCandidate(viewModel, false);
      this.fullCandidateCache = null;
    }
  }

  @action async changePositionStatus(status) {
    if (this.candidateIsBeingMoved) return null;

    const {model} = await this.store.patchPosition({status}, this.position.id);
    if (model) {
      this.position.status = status;

      let message;
      switch (status) {
        case 'closed':
          message = 'recruiting.alerts.Position closed successfully';
          break;
        case 'published':
          message = 'recruiting.alerts.Position published successfully';
          break;
        case 'internal':
          message = 'recruiting.alerts.Position made internal successfully';
          break;
        default:
          message = 'recruiting.alerts.Position updated successfully';
          break;
      }

      successAlert(t(message));
    }
  }

  @action async selectCandidate(candidateViewModel, reload = true) {
    this.loadingCandidate = true;

    if (candidateViewModel) {
      for (const c of this.candidates) {
        c.active = false;
      }
      candidateViewModel.active = true;
    }

    if (candidateViewModel && reload) {
      const {candidate} = candidateViewModel;
      this.requestedCandidateId = candidate.id;
      const fullCandidate = await this.store.loadFullCandidate(this.position.id, candidate.id);
      if (this.requestedCandidateId !== fullCandidate.id) return;

      candidate.update(fullCandidate);

      const newUrl = `/positions/${this.position.id}/funnel/${candidate.id}/${candidate.urlSlug}`;
      if (this.history.location.pathname !== newUrl) {
        this.history.push(newUrl + window.location.search);
      }
    }

    this.detailsState.resetRefs();
    this.detailsState.candidateViewModel = candidateViewModel;

    if (candidateViewModel) {
      this.detailsState.cancelInput();
      this.detailsState.cancelFeedback();
    }

    await this.detailsState.setupNewFeedbackAutosave();

    this.loadingCandidate = false;
    this.selectFirstPending = false;
  }

  @action async moveCandidateToStage(stage) {
    const {candidateViewModel} = this.detailsState;
    const {candidate} = candidateViewModel;

    if (this.candidateIsBeingMoved || candidate.disqualified) return null;
    this.candidateIsBeingMoved = true;

    const {model} = await this.store.patchCandidate(
      {currentStage: stage},
      this.position.id,
      candidate.id
    );

    candidate.update(model);
    await this.updateStageCounts();
    this.candidateIsBeingMoved = false;
    this.listUpdatePending = true;
  }

  @action async disqualifyCandidate() {
    if (this.position.sendDisqualifiedEmailToApplicants) {
      this.detailsState.candidate.sendDisqualifiedEmail = true;
      this.openSendDisqualifiedEmailModal();
    } else {
      this.doDisqualifyCandidate();
    }
  }

  @action async doDisqualifyCandidate() {
    if (this.candidateIsBeingMoved) return null;
    this.candidateIsBeingMoved = true;

    await this.__toggleQualifyCandidate(true);
    await this.updateStageCounts();
    this.sendDisqualifiedEmailModalOpen = false;
  }

  @action async bulkDisqualificationAction() {
    if (this.position.sendDisqualifiedEmailToApplicants) {
      this.openSendBulkDisqualifiedEmailModal();
    } else {
      await this.bulkDisqualify();
    }
  }

  @action async bulkQualify() {
    const qualifiedCount = await this.bulkAction({disqualified: false});
    successAlert(t('recruiting.alerts.BULK_QUALIFY', {count: qualifiedCount}));
  }

  @action async bulkDisqualify() {
    const disqualifiedCount = await this.bulkAction({disqualified: true});
    this.closeSendBulkDisqualifiedEmailModal();
    successAlert(t('recruiting.alerts.BULK_DISQUALIFY', {count: disqualifiedCount}));
  }

  @action async bulkMoveStage() {
    const movedCount = await this.bulkAction({newStageId: this.newStageId});
    const newStage = _.find(this.position.funnelStages, {id: this.newStageId});
    this.closeBulkMoveStageModal();
    successAlert(t('recruiting.alerts.BULK_MOVE', {
      count: movedCount, new: newStage.name
    }));
  }

  @action async bulkAction(updatedProperties) {
    const response = await api.post(
      endpoints.RECRUITING.CANDIDATES.BULK_ACTION,
      {
        selected_candidate_ids: _.map(_.filter(this.candidates, 'selected'), 'candidate.id'),
        all_candidates_selected: this.allCandidatesSelected,
        position_id: this.position.id,
        new_stage_id: updatedProperties.newStageId,
        new_position_id: updatedProperties.newPositionId,
        new_position_text: updatedProperties.newPositionText,
        newly_disqualified: updatedProperties.disqualified,
        timeline_entries: updatedProperties.timelineEntries,
        added_tags: updatedProperties.addedTags,
        send_disqualified_email: this.sendBulkDisqualifiedEmail,
        candidates_last_fetched_at: this.candidatesLastFetchedAt,
        search: this.searchFilter,
        status: this.candidateStatusFilter,
        stage_id: this.candidateStageFilter,
        tags: this.candidateTagsFilter,
        ratings: this.candidateRatingsFilter
      }
    );
    this.updateStageCounts();
    this.selectFirstPending = true;
    this.agent.refresh();
    return response.data;
  }

  @action async updateStageCounts() {
    const response = await api.get(
      endpoints.RECRUITING.POSITION.CANDIDATE_COUNTS_BY_STAGE.with(this.position.id)
    );
    _.forEach(this.position.funnelStages, (stage) => {
      stage.activeCandidatesCount = response.data[stage.id] || 0;
    });
    return response.data;
  }

  @action setCandidateChecked(selected, candidateViewModel) {
    this.allCandidatesSelected = false;
    candidateViewModel.selected = selected;
  }

  @action selectAllCandidates() {
    this.allCandidatesSelected = true;
    _.forEach(this.candidates, candidate => candidate.selected = true);
  }

  @action toggleCurrentPageCandidateSelection(checked) {
    this.allCandidatesSelected = false;
    _.forEach(this.candidates, candidate => { candidate.selected = checked; });
  }

  @action async qualifyCandidate() {
    if (this.candidateIsBeingMoved) return null;
    this.candidateIsBeingMoved = true;

    await this.__toggleQualifyCandidate(false);
    await this.updateStageCounts();
  }

  async __toggleQualifyCandidate(disqualified) {
    const {candidateViewModel} = this.detailsState;
    const {candidate} = candidateViewModel;
    const {model, errors} = await this.store.patchCandidate(
      {
        disqualified: disqualified,
        send_disqualified_email: candidate.sendDisqualifiedEmail
      },
      this.position.id,
      candidate.id
    );

    this.errors = errors;

    if (model) {
      candidate.merge(model);
      await this.agent.refresh();
      this.candidateIsBeingMoved = false;
      return candidateViewModel;
    }
  }

  @action async onFilterUpdated() {
    await this.selectCandidate(_.head(this.candidates));
  }

  @action async updateCandidate() {
    const candidate = this.detailsState.editingCandidate.pick([
      'answers',
      'firstName',
      'lastName',
      'email',
      'phoneNumber',
      'additionalInformation',
      'resume'
    ]);

    this.store.invalidate(types.RECRUITING.CANDIDATE);
    const {errors, model} = await this.store.patchCandidate(
      candidate,
      this.position.id,
      candidate.id
    );

    if (model) {
      this.detailsState.candidateViewModel = new CandidateViewModel(new Candidate(model));
      this.detailsState.closeEditCandidateModal();
      successAlert(t('recruiting.alerts.Candidate successfully updated'));
    }

    this.detailsState.errors = errors;
  }

  @action async saveEntry(entry) {
    if (entry.isNew) {
      return this.store.postEntry(
        this.position.id,
        this.detailsState.candidate.id,
        entry
      );
    } else {
      return this.store.patch(entry);
    }
  }

  @action async destroyEntry(entry) {
    return this.store.destroy(entry);
  }

  @action async changePosition(updatedProperties) {
    const {candidateViewModel} = this.detailsState;
    const {candidate} = candidateViewModel;
    const {errors, model} = await this.store.patch(
      candidate.link('changePosition'),
      types.RECRUITING.CANDIDATE,
      {
        position: updatedProperties.position,
        changePositionText: updatedProperties.changePositionText
      }
    );

    if (model) {
      this.detailsState.closeMovePositionModal();
      await this.agent.refresh();

      const nextCandidate = _.find(
        this.candidates,
        c => parseInt(c.candidate.id) !== parseInt(candidate.id)
      );

      await this.updateStageCounts();
      await this.selectCandidate(nextCandidate);

      if (!nextCandidate) {
        this.history.push(`/positions/${this.position.id}/funnel`);
      }

      successAlert(
        t(
          'recruiting.alerts.MOVED_CANDIDATE_TO_NEW_POSITION',
          {position: candidate.position.title}
        )
      );
    }

    this.detailsState.errors = errors;
  }

  @action async deleteCandidate(candidate) {
    await this.store.destroyCandidate(candidate);
    await this.agent.refresh();

    const nextCandidate = _.find(
      this.candidates,
      c => parseInt(c.candidate.id) !== parseInt(candidate.id)
    );

    await this.updateStageCounts();
    await this.selectCandidate(nextCandidate);

    if (!nextCandidate) {
      this.history.push(`/positions/${this.position.id}/funnel`);
    }

    successAlert(t('recruiting.alerts.Candidate successfully deleted'));
  }

  @action goToPosting(position) {
    window.open(position.viewPostingLink, '_blank');
  }

  @action openDeleteModal() {
    this.deletePosition = true;
  }

  @action cancelDeleteModal() {
    this.deletePosition = false;
  }

  @action async confirmDeletePosition() {
    await this.store.destroyPosition(this.position);
    this.deletePosition = false;
    this.history.push('/');
  }

  @action async hireCandidate(candidate) {
    const {model} = await this.store.post(
      candidate.link('hire'),
      types.EMPLOYEE_DETAILS
    );
    await redirect(urls.EMPLOYEE.with(model.id));
  }

  @action async sendOffer(candidate) {
    if (candidate.canEditOffer) {
      await redirect(`/offers/${candidate.offer.id}`);
    } else if (candidate.canSendOffer) {
      await redirect(`/offers/new?candidate_id=${candidate.id}&position_id=${this.position.id}`);
    }
  }

  @computed get availablePositions() {
    return _.reject(this.positions, ['id', this.position.id]);
  }

  @computed get showMovePositionButton() {
    return !_.isEmpty(this.availablePositions);
  }

  @computed get newStageOptions() {
    return _.filter(this.position.funnelStages, stage => stage.id !== this.candidateStageFilter);
  }

  @computed get selectedCandidates() {
    return _.filter(this.candidates, 'selected');
  }

  @computed get currentPageCandidatesAllChecked() {
    return this.candidates.length > 0 && _.every(this.candidates, 'selected');
  }

  @computed get selectedCandidatesCount() {
    return this.allCandidatesSelected ? this.totalCandidatesCount : this.selectedCandidates.length;
  }

  @computed get totalCandidatesCount() {
    return this.interactiveContextState.pagination.totalCount;
  }

  @computed get candidatesListPageSize() {
    return this.interactiveContextState.pagination.pageSize;
  }

  @computed get candidateHasEmployee() {
    return !!this.detailsState.candidateViewModel.candidate.employee;
  }

  @computed get candidateStatusFilter() {
    const candidateStatuses = this.interactiveContextState.filter.get('candidate_status');
    return candidateStatuses ? _.split(candidateStatuses, ',') : [];
  }

  @computed get candidateStageFilter() {
    return this.interactiveContextState.filter.get('candidate_stage');
  }

  @computed get candidateTagsFilter() {
    const candidateTags =  this.interactiveContextState.filter.get('candidate_tags');
    return candidateTags ? _.split(candidateTags, ',') : [];
  }

  @computed get candidateRatingsFilter() {
    const candidateRatings =  this.interactiveContextState.filter.get('candidate_ratings');
    return candidateRatings ? _.split(candidateRatings, ',') : [];
  }

  @computed get initialFilter() {
    if (!this.navigatedToCandidate) return {candidate_status: 'active'};

    const candidateStatus = this.navigatedToCandidate.candidate.disqualified ? 'disqualified' : 'active';
    return {candidate_status: candidateStatus, candidate_stage: this.navigatedToCandidate.candidate.currentStage.id};
  }

  @computed get searchFilter() {
    return this.interactiveContextState.filter.get('search');
  }

  @computed get displayDisqualifyBulkAction() {
    return this.candidateStatusFilter.length === 1 && this.candidateStatusFilter.includes('active');
  }

  @computed get displayQualifyBulkAction() {
    return this.candidateStatusFilter.length === 1 && this.candidateStatusFilter.includes('disqualified');
  }

  isActiveStage(stage) {
    return !!stage && _.get(this, 'stage.id') === _.get(stage, 'id');
  }

  @computed get mentions() {
    return _.sortBy(this.taggableUsers.map(user => {
      return {
        name: user.name,
        user: user,
        id: user.id
      };
    }), 'name');
  }
}

export default HiringFunnelState;
