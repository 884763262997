import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {types, endpoints, t} from 'shared/core';
import {Company} from 'stores/company';
import {successAlert} from 'shared/tools';

class SlackConfigurationContainerState {
  store = new DomainStore();
  @observable company;
  @observable modalOpen = false;
  @observable errors = {};

  @action async load() {
    await this.store._compose([
      endpoints.CURRENT_COMPANY
    ]);

    this.company = new Company(
      this.store._getSingle(types.COMPANY)
    );
  }

  @action showModal() {
    this.modalOpen = true;
  }

  @action hideModal() {
    this.modalOpen = false;
  }

  @action async disconnect() {
    const {model} = await this.store.post(
      endpoints.SLACK.DISCONNECT_WORKSPACE,
      types.COMPANY
    );

    if (model) {
      this.company.merge(model);
      this.hideModal();
      successAlert(t('company_settings.applications.slack.Slack workspace disconnected.'));
    }
  }

  @computed get authenticityToken() {
    return document.getElementsByName('csrf-token')[0].content;
  }
}

export default SlackConfigurationContainerState;
