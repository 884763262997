import React from 'react';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

const BrokerPortalSideNavigation = () => {
  return (
    <div className='BrokerPortalNavigationBody'>
      <ul className='list-reset'>
        <li>
          <NavLink activeClassName='active' to='/dashboard'>
            <i className='material-icons h3 align-text-top pr1'>{'dashboard'}</i>
            <FormattedMessage id='Dashboard'/>
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName='active' to='/clients'>
            <i className='material-icons h3 align-text-top pr1'>{'people'}</i>
            <FormattedMessage id='broker_dashboard.Clients'/>
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName='active' to='/settings'>
            <i className='material-icons h3 align-text-top pr1'>{'settings'}</i>
            <FormattedMessage id='broker_portal.navigation.Settings'/>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default BrokerPortalSideNavigation;
