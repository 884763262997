import {observable, action, computed} from 'mobx';
import _ from 'lodash';
import SectionViewModel from './SectionViewModel';
import SuggestionViewModel from './SuggestionViewModel';
/*global window*/

class GlobalSearchState {
  store;
  @observable query;
  @observable autoFilledQuery;
  @observable suggestions = [];
  @observable isInitializing = true;

  constructor(store) {
    this.store = store;
  }

  @action updateQuery(query) {
    this.query = query;
    this.autoFilledQuery = null;

    if (!query.length) {
      this.isInitializing = true;
    }

    this.store.fetchSuggestions(query, suggestions => this.onSuggestionsReceived(suggestions));
  }

  @action onSuggestionsReceived(suggestions) {
    const viewModels = _.map(
      suggestions,
      s => new SuggestionViewModel(s)
    );
    if (this.selectedSuggestion) {
      const newSuggestion = _.find(viewModels, v => v.data.equals(this.selectedSuggestion.data));
      if (newSuggestion) {
        newSuggestion.selected = true;
      }
    }
    this.suggestions = viewModels;
    this.isInitializing = false;
    setTimeout(() => this.__selectFirstItem(), 0);
  }

  @action __selectFirstItem() {
    if (this.sortedSuggestions.length) {
      for (const s of this.suggestions) {
        s.selected = false;
      }
      this.sortedSuggestions[0].selected = true;
    }
  }

  @action moveSelection(e) {
    if (!this.selectedSuggestion) {
      this.__selectFirstItem();
      if (!this.selectedSuggestion) return null;
    }

    switch (e.key) {
      case 'ArrowUp':
        if (this.suggestionsVisible) {
          e.preventDefault();
          this.__selectPrevSuggestion();
          this.updateQueryFromSelected();
        }
        break;
      case 'ArrowDown':
        if (this.suggestionsVisible) {
          e.preventDefault();
          this.__selectNextSuggestion();
          this.updateQueryFromSelected();
        }
        break;
      case 'Enter':
        e.preventDefault();
        window.location = this.selectedSuggestion.data.link;
        break;
      default:
        break;
    }
  }

  @action __selectNextSuggestion() {
    this.__selectSuggestion(1);
  }

  @action __selectPrevSuggestion() {
    this.__selectSuggestion(-1);
  }

  @action updateQueryFromSelected() {
    if (!this.selectedSuggestion) return null;

    this.autoFilledQuery = this.selectedSuggestion.data.caption;
  }

  @action __selectSuggestion(shift) {
    const currentSelection = this.selectedSuggestion;
    const suggestions = this.sortedSuggestions;

    for (var i = 0; i < suggestions.length; i++) {
      if (suggestions[i] === currentSelection) {
        if (shift === 1) {
          if (i === suggestions.length - 1) {
            return;
          } else {
            currentSelection.selected = false;
            suggestions[i + 1].selected = true;
            return;
          }
        } else if (shift === -1) {
          if (i === 0) {
            return;
          } else {
            currentSelection.selected = false;
            suggestions[i - 1].selected = true;
            return;
          }
        }
      }
    }
  }

  @action componentWillUnmount() {
    this.isInitializing = true;
  }

  @action clearQuery() {
    this.query = '';
    this.autoFilledQuery = ''; 
  }

  @computed get suggestionsVisible() {
    return !!this.query && !this.isInitializing;
  }

  @computed get sections() {
    const suggestionsByType = _.groupBy(
      this.suggestions,
      s => s.data.suggestionType
    );

    return _.chain(suggestionsByType)
      .map(items => new SectionViewModel(items, this.query))
      .orderBy(v => v.priority * -1)
      .value();
  }

  @computed get sortedSuggestions() {
    return _.chain(this.sections)
      .map(section => section.suggestionViewModels.slice())
      .flatten()
      .value();
  }

  @computed get selectedSuggestion() {
    return _.find(this.suggestions, 'selected');
  }

  @computed get isEmpty() {
    return !this.sections.length;
  }

  @computed get closeIconVisible() {
    return !!this.query;
  }
}

export default GlobalSearchState;
