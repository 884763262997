import React from 'react';
import {observer} from 'mobx-react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Layout} from 'components';
import CustomFieldGroupsContainer from './CustomFieldGroupsContainer';
import CustomFieldSubnav from './CustomFieldSubnav';

const CustomFieldPage = observer(({match}) => {
  return (
    <div>
      <CustomFieldSubnav/>
      <Layout>
        <Layout.Section>
          <Switch>
            <Route path={`${match.url}/personal`} render={() => <CustomFieldGroupsContainer category='personal' key='personal'/>}/>
            <Route path={`${match.url}/job`} render={() => <CustomFieldGroupsContainer category='job' key='job'/>}/>
            <Redirect exact from={`${match.url}`} to={`${match.url}/personal`}/>
          </Switch>
        </Layout.Section>
      </Layout>
    </div>
  );
});

export default CustomFieldPage;
