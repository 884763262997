import React from 'react';
import {observer} from 'mobx-react';
import {Button} from 'components';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

const ReviewActionButtons = observer(({uiState}) => {
  const {cycle} = uiState;

  return (
    <div className='table mt2'>
      <div className='table-cell col-6'>
        <Button trait='destructive' onClick={() => uiState.openDeleteCycleModal()}>
          <FormattedMessage id='performance_reviews.manage.Delete review'/>
        </Button>
      </div>
      <div className='table-cell col-6 right-align'>
        <Link className='Btn Btn--default' to={`/reviews/${uiState.cycle.id}/edit`}>
          <span className='Btn--content' tabIndex='-1'>
            <FormattedMessage id='performance_reviews.manage.Edit review'/>
          </span>
        </Link>
        {cycle.state === 'configured' && <Button className='ml2' onClick={() => uiState.openLaunchCycleModal()}>
          <FormattedMessage id='performance_reviews.manage.Launch review'/>
        </Button>}
      </div>
    </div>
  );
});

export default ReviewActionButtons;
