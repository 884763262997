import {DomainStore} from 'shared/store';
import {action} from 'mobx';
import {endpoints, types} from 'shared/core';
import {redirect} from 'shared/tools';

class OnboardingContainerState {
  store = new DomainStore();

  @action async createPolicy() {
    const {model} = await this.store.post(
      endpoints.TIME_TRACKING.POLICIES.ALL,
      types.TIME_TRACKING.POLICY
    );

    window.history.replaceState(null, null, '/time_tracking/manage');

    return redirect(`/time_tracking/manage/policies/${model.id}/edit`);
  }
}

export default OnboardingContainerState;
