import React from 'react';
import {observer} from 'mobx-react';
import Section from './Section';

const FieldList = observer(({uiState}) => {
  return (
    <div className='mt2'>
      {uiState.fieldSections.map(sectionViewModel =>
        <Section key={sectionViewModel.data.group} uiState={uiState} sectionViewModel={sectionViewModel}/>
      )}
    </div>
  );
});

export default FieldList;
