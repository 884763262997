import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Button} from 'components';
import {observer} from 'mobx-react';

const NavigationButtons = observer(({uiState}) => {
  return (
    <div className='table mt2'>
      <div className='col-6 table-cell align-middle left-align pt1'>
        {uiState.deleteEnabled && <Button trait='destructive' onClick={() => uiState.openDeleteModal()}>
          <FormattedMessage id={uiState.localization.delete || 'components.flow.Delete'}/>
        </Button>}
      </div>
      <div className='col-6 table-cell align-middle right-align pt1'>
        {uiState.backEnabled && <Button trait='default' onClick={() => uiState.goBack()}>
          <FormattedMessage id={uiState.localization.back || 'components.flow.Back'}/>
        </Button>}
        <Button className='ml2' onClick={() => uiState.submitStep()} disabled={!uiState.nextEnabled}>
          {!uiState.isFinalStep && <FormattedMessage id={uiState.localization.continue || 'components.flow.Continue'}/>}
          {uiState.isFinalStep && <FormattedMessage id={uiState.localization.finish || 'components.flow.Finish'}/>}
        </Button>
      </div>
    </div>
  );
});

export default NavigationButtons;
