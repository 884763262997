import {DomainObject} from 'shared/store';
import {observable} from 'mobx';
import _ from 'lodash';

class SurveyResult extends DomainObject {
  @observable trend;
  @observable currentPeriodDate;
}

export default SurveyResult;
