import { useState, useEffect } from 'react';
import { fetchModels } from 'shared/store';

const useFetchModels = (endpoint, modelType) => {
  const [models, setModels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const fetchedModels = await fetchModels(endpoint, modelType);
      setModels(fetchedModels);
      setIsLoading(false);
    };
    fetchData();
  }, [endpoint, modelType]);

  return [models, isLoading];
};

export default useFetchModels;
