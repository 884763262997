import {DomainObject} from 'shared/store';
import {action, observable} from 'mobx';
import {Company} from 'stores/users';

class MicrosoftCredentials extends DomainObject {
  @observable organizationDomains;
  @observable organizationName;
  @observable company;

  @action merge(other) {
    super.merge(other, {
      company: Company
    });
  }}

export default MicrosoftCredentials;
