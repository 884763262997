import {observable, action, computed} from 'mobx';
import {DomainStore} from 'shared/store';
import {endpoints, types} from 'shared/core';
import {PerformanceReviewCycle} from 'stores/performance_reviews';
import {PerformanceReviewReview} from 'stores/performance_reviews';
import _ from 'lodash';
import {withDeleted} from 'shared/tools';

class SelectReviewersState {
  onHide;
  onSave;
  cycle;
  employees;
  store = new DomainStore();

  @observable isOpen = false;
  @observable reviewee;
  @observable selectedManager;
  @observable selectedPeers = [];
  @observable errors = {};

  @action receiveProps({isOpen, onHide, reviewee, cycle, onSave}) {
    this.isOpen = isOpen;
    this.onHide = onHide;
    this.onSave = onSave;
    this.reviewee = reviewee;
    this.cycle = cycle;
  }

  @action async load() {
    await this.store._compose([
      endpoints.EMPLOYEES.ALL
    ]);

    this.employees = _.reject(
      this.store.getEmployees(),
      employee => employee.equals(this.reviewee.employee)
    );

    this.selectedManager = this.reviewee.reviewManager;

    if (this.cycle.peerType) {
      this.selectedPeers = _.chain(this.reviewee.reviews)
        .filter(r => r.type.equals(this.cycle.peerType))
        .map(r => _.find(this.employeesWithReviewers, {id: r.reviewer.id}))
        .value();
    } else {
      this.selectedPeers = [];
    }
  }

  @action addPeer(peer) {
    this.selectedPeers.push(peer);
  }

  @action removePeer(peer) {
    this.selectedPeers.remove(peer);
  }

  @action updateManagerReviewer(manager) {
    this.selectedManager = manager;
  }

  @action async saveReviewers() {
    const reviewee = this.reviewee.pick();

    if (this.selectedManager) {
      reviewee.merge({reviewManager: this.selectedManager});
    }

    if (this.cycle.managerType && reviewee.reviewManager) {
      const managerReview = _.get(this.currentManagerReview, 'reviewer.id') === reviewee.reviewManager.id ?
        this.currentManagerReview :
        new PerformanceReviewReview({
          reviewer: reviewee.reviewManager,
          type: this.cycle.managerType
        });

      // TODO: Remove this once migration is run
      reviewee.merge({reviewManager: this.selectedManager});
      reviewee.reviews.push(managerReview);
    }

    if (this.cycle.peerType) {
      for (const peer of this.selectedPeers) {
        const existingPeerReview = _.find(this.currentPeerReviews, r => r.reviewer.id === peer.id);
        const peerReview = _.isEmpty(existingPeerReview) ?
          new PerformanceReviewReview({reviewer: peer, type: this.cycle.peerType}) :
          existingPeerReview;

        reviewee.reviews.push(peerReview);
      }
    }

    const {model, errors} = await this.store.patch(reviewee);
    this.errors = errors;
    await this.__updateCycle();

    if (model) {
      this.onSave(model);
    }
  }

  @computed get currentManagerReview() {
    return _.find(this.reviewee.reviews, r => r.type.equals(this.cycle.managerType));
  }

  @computed get employeesWithReviewers() {
    return withDeleted(this.employees, this.reviewee.reviews.map(review => review.reviewer));
  }

  @computed get currentPeerReviews() {
    return _.filter(this.reviewee.reviews, r => r.type.equals(this.cycle.peerType));
  }

  @computed get showManagerSelector() {
    return !!this.cycle.managerType;
  }

  @computed get showPeerSelector() {
    return !!this.cycle.peerType;
  }

  async __updateCycle() {
    await this.store._compose([
      endpoints.PERFORMANCE_REVIEWS.CYCLE.with(this.cycle.id)
    ]);

    const newCycle = new PerformanceReviewCycle(
      this.store._getSingle(types.PERFORMANCE_REVIEWS.CYCLE)
    );

    this.cycle.update(newCycle);
  }
}

export default SelectReviewersState;
