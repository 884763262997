import React from 'react';
import {observer} from 'mobx-react';
import {Modal} from 'components/modals';
import {DocumentAnnotationForm} from 'components';

const OnboardingDocumentEditModal = observer(({uiState}) => {
  const {editingCompanyDocument, errors} = uiState;
  if (!editingCompanyDocument.originalFile) return null;

  return (
    <Modal isOpen={uiState.annotatorModalOpen} closeButton={false} size='lg'>
      <DocumentAnnotationForm
        document={editingCompanyDocument}
        errors={errors}
        onSave={(annotations) => uiState.addAnnotations(annotations)}
        onCancel={() => uiState.closeAnnotatorModal()}
      />
    </Modal>
  );
});

export default OnboardingDocumentEditModal;
