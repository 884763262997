import {observable, computed} from 'mobx';
import _ from 'lodash';

class TimelineSectionViewModel {
  @observable entries = [];
  @observable showEmailInput = false;
  @observable showFileUpload = false;
  @observable showInterviewGuide = false;
  @observable stage;
  @observable candidate;

  constructor(candidate, entries) {
    this.candidate = candidate;

    if (entries) {
      this.entries = entries;
      this.stage = this.entries[0].funnelStage;
    }
  }

  @computed get positiveSentimentCount() {
    return _.filter(this.entries, { isFeedback: true, isPositiveSentiment: true }).length;
  }

  @computed get negativeSentimentCount() {
    return _.filter(this.entries, { isFeedback: true, isNegativeSentiment: true }).length;
  }

  @computed get isEmpty() {
    return !this.entries.length;
  }

  @computed get orderedEntries() {
    return _.orderBy(this.entries, ['createdAt'], ['desc']);
  }

  @computed get enabled() {
    return !this.isEmpty || this.showEmailInput || this.showFileUpload || this.showInterviewGuide;
  }
}

export default TimelineSectionViewModel;
