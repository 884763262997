import React from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import GoogleImportContainer from 'employees/google_import/components/GoogleImportContainer';
import AddEmployeeForm from './AddEmployeeForm';
import AddedEmployee from './AddedEmployee';

const EmployeeList = observer(({uiState}) => {
  return (
    <div>
      {!uiState.newEmployees.length && <div className='col-8 mx-auto center h3 medium p4 mt4 border rounded jumbo'>
        <FormattedMessage id='company_onboard.Added Employees will show up here!'/>
      </div>}
      <div className='col-8 mx-auto mt3'>
        {uiState.sortedNewEmployees.map(employee => <AddedEmployee employee={employee} key={`new_${employee.id}`} />)}
        <div className='h3 medium jumbo center mt4 mb3 pb2 border-bottom'>
          <FormattedMessage id='company_onboard.Previously Added Employees' />
        </div>
        {uiState.sortedEmployees.map(employee => <AddedEmployee employee={employee} key={`existing_${employee.id}`} />)}
      </div>
    </div>
  );
});

const AddEmployeesContainer = observer(({uiState}) => {
  return (
    <div>
      <div className='py4'>
        <div className='center'>
          <div className='h1 black'>
            <FormattedMessage id='company_onboard.Add existing employees'/>
          </div>
          <GoogleImportContainer onEmployeesAdded={(employees) => uiState.addNewEmployees(employees)} />
        </div>
      </div>
      <div className='Container mb4'>
        <AddEmployeeForm uiState={uiState} />
        <EmployeeList uiState={uiState} />
        <div className="mt3 center">
          <Link to='dashboard' className='Btn Btn--primary Btn--lg Btn--circle'>
            <FormattedMessage id='company_onboard.Next Step'/>
          </Link>
        </div>
      </div>
    </div>
  );
});

export default AddEmployeesContainer;
